import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => {
  return {
    containerCardTransactionItemSkeleton: {
      padding: "12px 24px",
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      backgroundColor:theme.palette.background.paper,
      [theme.breakpoints.down("sm")]: {
        display: "flex",
        flexDirection: "column",
      },
    },
    transactionInfoContainerSkeleton: {
      display: "flex",
      gap: "12px",
      flexDirection: "column",
    },
    topTransactionInfoBoxSkeleton: {
      display: "flex",
      flexDirection: "column",
      gap: "2px",
    },
    cardwithborder: {
        border: '1px solid', 
        borderRadius: '12px',
        borderColor:theme.palette.border.secondary
    },
    cardwithPadding:{
        padding: '20px 0px',
    },
    transactionTypeBoxSkeleton: {
      display: "flex",
      alignItems: "flex-start",
      flexDirection: "row",
      gap: "8px",
    },
    transactionBoxIdSkeleton: {
      display: "flex",
      alignItems: "flex-start",
      flexDirection: "row",
      gap: "8px",
    },
    transactionBoxIdSkeleton: {
      display: "flex",
      alignItems: "flex-start",
      flexDirection: "row",
      gap: "8px",
    },
    bottomTransactionInfoBoxSkeleton: {
      display: "flex",
      flexDirection: "row",
      gap: "20px",
      [theme.breakpoints.down("sm")]: {
        display: "flex",
        flexDirection: "column",
      },
    },
    leadInfoBoxSkeleton: {
      display: "flex",
      gap: "12px",
      alignItems: "flex-start",
      flexDirection: "row",
    },
    unitIdBoxSkeleton: {
      display: "flex",
      flexDirection: "row",
      gap: "8px",
    },
    containerLeadTypeSkeleton: {
      display: "flex",
      flexDirection: "row",
      gap: "8px",
      justifyContent: "start",
      alignItems: "start",
    },
    containerLeadSketlon: {
      display: "flex",
      flexDirection: "row",
      gap: "8px",
    },
    leadInfoSkeleton: {
      display: "flex",
      alignItems: "flex-start",
      gap: "2px",
      flexDirection: "column",
    },
    containerTime_Dateskeleton: {
      display: "flex",
      flexDirection: "row",
      gap: "10px",
      justifyContent: "end",
      [theme.breakpoints.down("sm")]: {
        justifyContent: "start",
      },
    },
    container_ProprtyNameSkeleton: {
      display: "flex",
      flexDirection: "row",
      gap: "8px",
      fontSize: "14px",
      color: theme.palette.text.primary,
    },
    containerUnitSkeleton: {
      display: "flex",
      flexDirection: "column",
      gap: "2px",
    },
    containerUnitIdSketlon: {
      display: "flex",
      flexDirection: "row",
      gap: "8px",
    },
    containerUnitTypeTypeSkeleton: {
      display: "flex",
      flexDirection: "row",
      gap: "8px",
    },
  };
});
