import React from 'react';
import { useTranslation } from 'react-i18next';
import { DeleteBusinessGroup } from '../../../../../../Services/BusinessGroupsServices';
import { showError, showSuccess } from '../../../../../../Helper';
import { DialogComponent, Spinner } from '../../../../../../Components';

const DeleteDialog = (props) => {
  const { t } = useTranslation('BusinessGroupsView');
  const [loading, setloading] = React.useState(false);
  const handleDeleteButton = async () => {
    setloading(true);
    const res = await DeleteBusinessGroup(props.deletedId);
    if ((res === undefined || res === null)) {
      props.close();
      showError(t('DeleteDialog.DELETE_RELATED_TEAMS_BEFORE_BUSINESS_GROUP'));
      setloading(false);
    } else {
      props.close();
      props.reloadData();
      showSuccess(t('DeleteDialog.NotificationDeleteGroup'));
      setloading(false);
    }
  };

  return (
    <DialogComponent
      titleText={`${t('.confirm-message')}`}
      saveText={t('.Confirm')}
      saveType='button'
      maxWidth='sm'
      dialogContent={(
        <div className='d-flex-column-center'>
          <Spinner isActive={loading} isAbsolute />
          <span className='mdi mdi-close-octagon c-danger mdi-48px' />
          <span>
            {t('DeleteDialog.DeleteText')}
            {' '}
            {props.name}
            ?
          </span>
        </div>
      )}
      saveClasses='btns theme-solid bg-danger w-100 mx-2 mb-2'
      isOpen={props.open}
      onSaveClicked={() => handleDeleteButton()}
      onCloseClicked={() => props.close()}
      onCancelClicked={() => props.close()}
      parentTranslationPath='BusinessGroupsView'
      translationPath='DeleteDialog'
    />

  );
};
export default DeleteDialog;
