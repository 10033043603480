import { ListingShortageView   } from '../../Views/Home/Inquires/ListingShortage' ; 
import { ListingShortageViewManagementView } from '../../Views/Home/Inquires/ListingShortage/ListingShortageUtilities/ListingShortageManagementView' ; 
import {  ListingShortageProfileManagementView } from '../../Views/Home/Inquires/ListingShortage/ListingShortageUtilities/ListingShortageProfileManagementView' ; 

export const ListingShortageInquiresRoutes = [
  {
    path: '/view',
    name: 'Inquires:listing-shortage',
    component: ListingShortageView,
    layout: '/home/listing-shortage',
    default: true,
    isRoute: true,
    authorize: true,
    roles: [],
    isDisabled: false,
    isExact: true,
    breadcrumbs: [
      {
        name: 'Inquires:listing-shortage',
        isDisabled: false,
        route: '/home/listing-shortage/view',
        groupName: 'inquiries',
      },
    ],
  },
  {
    path: '/add',
    name : 'Inquires:listing-shortage' , 
    component: ListingShortageViewManagementView,
    layout: '/home/listing-shortage',
    default: false,
    isRoute: true,
    authorize: true,
    roles: [],
    isDisabled: false,
    isExact: true,
    breadcrumbs: [
      {
        name : 'Inquires:listing-shortage' , 
        isDisabled: false,
        route: '/home/listing-shortage/view',
        groupName: 'inquiries',
      },
      {
        name: 'Inquires:listing-shortage-profile-add',
        isDisabled: true,
        route: '/home/listing-shortage/add',
      },
    ],
  },
  {
    path: '/listing-shortage-profile-edit',
    name : 'Inquires:listing-shortage-profile-edit' , 
    component: ListingShortageProfileManagementView ,
    layout: '/home/listing-shortage',
    default: false,
    isRoute: true,
    authorize: true,
    roles: [],
    isDisabled: false,
    isExact: true,
    breadcrumbs: [
      {
        name : 'Inquires:listing-shortage' , 
        isDisabled: false,
        route: '/home/listing-shortage/view',
        groupName: 'inquiries',
      },
      {
        name: 'Inquires:listing-shortage-profile-edit',
        isDisabled: true,
        route: '/home/listing-shortage/listing-shortage-profile-edit',
      },
    ],
  },
];

