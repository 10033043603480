import React, {
  useEffect,
  useState,
  useReducer,
  useCallback,
  useRef,
} from "react";
import { PowerBIEmbed } from "powerbi-client-react";
import { models } from "powerbi-client";
import "../MasterStyleDashborads/MasterStyleDashboards.scss";
import { showError } from "../../../../Helper";
import { MicrosoftToken } from "../../../../Services";
import { DateRangePickerComponent } from "../../../../Components/Controls/DateRangePickerComponent/DateRangePickerComponent";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { ButtonBase, ButtonGroup } from "@material-ui/core";
import { CellSpinnerBi } from "../CellSpinnerBi/CellSpinnerBi";
import { PowerBiEnum } from "../../../../Enums";
import "../AdminMarketing/Navbar.scss";
const parentTranslationPath = "LeadsView";
const translationPath = "";

export const AICallInsights = () => {
  const userid = JSON.parse(localStorage.getItem("session")).userId;

  const { t } = useTranslation(parentTranslationPath);
  const [ShowReport2, setShowReport2] = useState(false);
  const [report, setReport] = useState();
  const [render, setRender] = useState(false);
  const [ShowReport, setShowReport] = useState(false);
  const [DateAppaer, setDateAppaer] = useState(false);
  const [Appuser, setAppuser] = useState();
  const [datefilters, setdatefilter] = useState(true);

  const [datepicker, setdatepicker] = useState();
  const [activeButton, setActiveButton] = useState("One");
  const [activeGroupButton, setactiveGroupButton] = useState(true);
  const [monthsAgo, setMonthsAgo] = useState(1);

  const currentDate = new Date();
  const countmonth = new Date(
    currentDate.setMonth(currentDate.getMonth() - monthsAgo)
  );

  const today = new Date();

  const count = new Date(new Date().setDate(today.getDate()));
  const count2 = new Date(new Date().setDate(today.getDate()));
  const datetimes = moment(count)
    .startOf("month")
    .format("YYYY-MM-DDTHH:mm:ss");
  const datetimes2 = moment(count2).format("YYYY-MM-DDTHH:mm:ss");

  const startDate = datetimes ? new Date(datetimes) : null;
  const endDate = datetimes2 ? new Date(datetimes2) : null;

  const dateRangeDefault = {
    startDate: null,
    endDate: null,
    key: "selection",
  };

  const [dateFilter, setDateFilter] = useState(dateRangeDefault);
  const [data, setdata] = useState([]);
  const reducer = (state, action) => {
    if (action.id !== "edit") return { ...state, [action.id]: action.value };
    if (action.id === "edit") {
      return {
        ...action.value,
      };
    }
    return undefined;
  };

  const [state, setState] = useReducer(reducer, {
    fromDate: null,
    toDate: null,
  });

  const scrollToTop = useCallback(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  const [anchorEl, setAnchorEl] = useState(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const horiSelectorRef = useRef(null);
  const navbarRef = useRef(null);

  useEffect(() => {
    const updateSelector = () => {
      if (navbarRef.current && horiSelectorRef.current) {
        const tabs = navbarRef.current.querySelectorAll("li");
        if (tabs.length > 0) {
          const activeItem = tabs[activeIndex];
          const activeWidth = activeItem.offsetWidth;
          const itemPosLeft = activeItem.offsetLeft;
          horiSelectorRef.current.style.left = `${itemPosLeft}px`;
          horiSelectorRef.current.style.width = `${activeWidth}px`;
        }
      }
    };

    updateSelector();
  }, [activeIndex]);

  const handleItemClick = (index) => {
    setActiveIndex(index);
  };

  useEffect(() => {
    const currentState = {
      ...state,
      fromDate:
        (dateFilter.startDate &&
          moment(dateFilter.startDate)
            .startOf("day")
            .format("YYYY-MM-DDTHH:mm:ss")) ||
        null,
      toDate:
        (dateFilter.endDate &&
          moment(dateFilter.endDate)
            .endOf("day")
            .format("YYYY-MM-DDTHH:mm:ss")) ||
        null,
    };
    setState({ value: currentState });
    setdata(currentState);
  }, [dateFilter]);

  const onClearedAllFiltersClick = useCallback(() => {
    setDateFilter(dateRangeDefault);
    applyBookmark("Bookmark5b52d9aa7c75d4e88fa1");
    setdatepicker(0);
    setdatefilter(true);
  }, []);

  const [bookmarks, setBookmarks] = useState([]);
  const [error, setError] = useState(null);
  const [MenuButton, setMenuButton] = useState("One");

  const handleMenuButtonClick = (buttonName) => {
    setMenuButton(buttonName);
  };

  const GeneralPage = () => {
    handleMenuButtonClick("One");
    setAnchorEl(null);
    Pagenavi("ReportSection6eaf615e56ee0fe885da");
  };
  const TransactionPage = () => {
    handleMenuButtonClick("Two");
    Pagenavi("ReportSectionfabb05623e665c4320c0");
    setAnchorEl(null);
  };

  const loadBookmarks = async () => {
    const report = window.report;
    let pages = await report.getPages();
    if (report) {
      try {
        const bookmarksManager = report.bookmarksManager;
        const fetchedBookmarks = await bookmarksManager.getBookmarks();
        setBookmarks(fetchedBookmarks);
        console.log("Bookmarks:", fetchedBookmarks);
        console.log("pages:", pages);
      } catch (error) {
        console.error("Error loading bookmarks:", error);
        setError(error);
      }
    }
  };
  const applyBookmark = async (bookmarkName) => {
    const report = window.report;
    if (report) {
      try {
        const bookmarksManager = report.bookmarksManager;
        bookmarksManager.apply(bookmarkName);
        // console.log("Bookmark applied successfully:", bookmarkName);
      } catch (error) {
        console.error("Error applying bookmark:", error);
        setError(error);
      }
    }
  };

  const handleButtonClick = (buttonName) => {
    setActiveButton(buttonName);
  };

  const ChartView = () => {
    applyBookmark("Bookmarkf623b3e01f3ba3e56a23");
    handleButtonClick("One");
  };
  const TableView = () => {
    applyBookmark("Bookmark7901e45cf8f0a3712730");
    handleButtonClick("Two");
  };

  const [filterButton, setfilterButton] = useState(false);

  const FilterHide = () => {
    applyBookmark("Bookmarke8bdfc92671bb77c202b");
    setfilterButton(false);
  };
  const FilterView = () => {
    applyBookmark("Bookmark4a47b9d51da010b8d401");
    setfilterButton(true);
  };

  const Pagenavi = async (pagestate) => {
    const report = window.report;
    let pages = await report.getPages();

    if (report) {
      try {
        report.setPage(pagestate);
      } catch (error) {
        console.error("Error applying:", error);
      }
    }
  };

  const handleRefreshClick = useCallback(() => {
    window.report
      .refresh()
      .then(() => {
        setShowReport2(false);
      })
      .catch((error) => {});
  }, []);

  const Microsoft = async (isExpired) => {
    const res = await MicrosoftToken(isExpired);
    if (!(res && res.status && res.status !== 200)) {
      setReport(res);
      setRender(true);
      setAppuser(userid);
      setShowReport(true);
    } else {
      setReport("");
      showError("Contact Your Bi Admin For Help");
    }
  };
  const advancedFilter = {
    $schema: "http://powerbi.com/product/schema#advanced",
    target: {
      table: "CallAnalyses",
      column: "ActivityDate",
    },
    filterType: 0,
    logicalOperator: "And",
    conditions: [
      {
        operator: "GreaterThanOrEqual",
        value: data.fromDate,
      },
      {
        operator: "LessThanOrEqual",
        value: data.toDate,
      },
    ],
    filterType: models.FilterType.AdvancedFilter,
  };

  useEffect(() => {
    Microsoft(false);
  }, []);

  return (
    <div className="dashboardMain-PowerBIEmbed">
      <CellSpinnerBi isActive={!ShowReport} blue />
      <div>
        <div id="navbar-animmenu" ref={navbarRef}>
          <ul className="show-dropdown main-navbar">
            <div className="hori-selector" ref={horiSelectorRef}>
              <div className="left"></div>
              <div className="right"></div>
            </div>
            <li
              className={activeIndex === 0 ? "active" : ""}
              onClick={() => {
                handleItemClick(0);
                GeneralPage();
              }}
            >
              <a>
                <i className={`mdi mdi-account-details-outline mdi-24px `}></i>
                {t(`${translationPath}general-menu`)}
              </a>
            </li>
            {/* <li
              className={activeIndex === 1 ? "active" : ""}
              onClick={() => {
                handleItemClick(1);
                TransactionPage();
              }}
            >
              <a>
                <i className={`mdi mdi-book-account-outline mdi-24px `}></i>
                {t(`${translationPath}transaction-menu`)}
              </a>
            </li> */}
          </ul>
        </div>
      </div>
      <br />

      {render && (
        <div className="mainCardDashboards">
          <div className="dashboardMain cardDashboard">
            <br />

            {DateAppaer && (
              <div>
                <br />
                <div className="MainFiltersBi">
                  <CellSpinnerBi isActive={!ShowReport2} isAbsolute blue />

                  <div className="selecterbi">
                    <div>
                      <DateRangePickerComponent
                        onClearClicked={() => {
                          setDateFilter({
                            startDate: null,
                            endDate: null,
                            key: "selection",
                          });
                        }}
                        ranges={[dateFilter]}
                        onDateChanged={(selectedDate) => {
                          const newStartDate =
                            selectedDate.selection &&
                            selectedDate.selection.startDate;
                          const newEndDate = new Date(
                            moment(
                              selectedDate.selection &&
                                selectedDate.selection.endDate
                            ).endOf("day")
                          );

                          setDateFilter({
                            startDate: newStartDate,
                            endDate: newEndDate,
                            key: "selection",
                          });
                        }}
                      />
                    </div>
                  </div>
                  {!filterButton ? (
                    <div className="DashboardFilter">
                      <ButtonBase
                        variant="contained"
                        className="btns theme-solid bg-primary-dark Dashboardbtn"
                        style={{ color: "white" }}
                        onClick={FilterView}
                        disabled={
                          activeButton == "Two" || activeGroupButton == false
                        }
                      >
                        <span className="mdi mdi-filter-menu m-1" />
                      </ButtonBase>
                    </div>
                  ) : (
                    <div className="DashboardFilter">
                      <ButtonBase
                        variant="contained"
                        className="btns theme-solid bg-danger Dashboardbtn"
                        style={{ color: "white" }}
                        onClick={FilterHide}
                      >
                        <span className="mdi mdi-filter-minus-outline m-1" />
                      </ButtonBase>
                    </div>
                  )}
                  <div className="DashboardFilter">
                    <ButtonBase
                      onClick={onClearedAllFiltersClick}
                      id="onClearedAllFiltersref"
                      className="btns theme-solid bg-primary-dark clear-all-btn Dashboardbtn"
                      disabled={activeGroupButton == false}
                    >
                      <span className="mdi mdi-filter-remove m-1" />
                    </ButtonBase>
                  </div>
                  <div className="DashboardFilter">
                    <ButtonBase
                      onClick={handleRefreshClick}
                      id="onClearedAllFiltersref"
                      className="btns theme-solid bg-primary-dark clear-all-btn Dashboardbtn"
                    >
                      <span className="mdi mdi-refresh m-1" />
                    </ButtonBase>
                  </div>
                  {activeGroupButton ? (
                    <div className="DashboardFilter">
                      <ButtonGroup>
                        <ButtonBase
                          onClick={ChartView}
                          className={`btns theme-solid  clear-all-btn Dashboardbtn ${
                            activeButton === "One"
                              ? "chartActive"
                              : "chartNotActive"
                          }`}
                        >
                          <span className="mdi mdi-chart-pie m-1" />
                        </ButtonBase>

                        <ButtonBase
                          onClick={TableView}
                          className={`btns theme-solid  clear-all-btn Dashboardbtn ${
                            activeButton === "Two"
                              ? "chartActive"
                              : "chartNotActive"
                          }`}
                        >
                          <span className="mdi mdi-format-list-bulleted-square m-1" />
                        </ButtonBase>
                      </ButtonGroup>
                    </div>
                  ) : (
                    <div></div>
                  )}
                </div>
              </div>
            )}
            <br />

            <PowerBIEmbed
              embedConfig={{
                type: "report",
                id: PowerBiEnum.Ai_Call.reportid,
                embedUrl: PowerBiEnum.Ai_Call.url,
                accessToken: report,
                filters: [advancedFilter],
                settings: {
                  customLayout: {
                    displayOption: models.DisplayOption.FitToWidth,
                  },
                  filterPaneEnabled: false,
                  navContentPaneEnabled: false,
                  panes: {
                    filters: {
                      expanded: false,
                      visible: false,
                    },
                  },
                  background: models.BackgroundType.Transparent,
                },
              }}
              eventHandlers={
                new Map([
                  [
                    "loaded",
                    (event) => {
                
                      setDateAppaer(true);
                      setShowReport2(false);
                    },
                  ],
                  [
                    "dataSelected",

                    (event) => {
                      setShowReport2(false);
                    },
                  ],

                  [
                    "rendered",
                    (event) => {
                      setShowReport2(true);
                      setRender(true);
                      loadBookmarks();
                    },
                  ],

                  [
                    "pageChanged",
                    (event) => {
                      const newPageName = event.detail.newPage.displayName;
                      switch (newPageName) {
                    
                        case "Page 1":
                    
                          setShowReport2(false);
                          setactiveGroupButton(false);

                          setTimeout(() => {
                            scrollToTop();
                          }, 5);
                          break;
                        default:
                         
                          setactiveGroupButton(true);
                          setShowReport2(false);
              
                          setTimeout(() => {
                            scrollToTop();
                          }, 5);
                          break;
                      }
                    },
                  ],

                  [
                    "error",
                    (event) => {
                      const error = event.detail;
                      if (
                        error.detailedMessage === "Get report failed" ||
                        error.detailedMessage ===
                          "Access token has expired, resubmit with a new access token" ||
                        error.detailedMessage ===
                          "Fail to initialize - Could not resolve cluster"
                      ) {
                        Microsoft(true);
                        setRender(false);
                      } else showError(error.detailedMessage);
                    },
                  ],
                ])
              }
              cssClassName={
                ShowReport
                  ? "report-style-class-Show"
                  : "report-style-class-hide"
              }
              getEmbeddedComponent={(embeddedReport) => {
                window.report = embeddedReport;
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
};
