export const AutoFillLeadZeroMatchingRule = async (item, setRerender, leadId, itemList, setData, isSaleZeroMatching) => {
    if (item.field.id !== 'lead') return;

    item.data.isReadonly = true;
    const leadDetails = JSON.parse(localStorage.getItem('leadDetails'));

    if (leadDetails) {
      const leadData = {
        leadId,
        name: (leadDetails.contact_name && leadDetails.contact_name.name) || null,
        leadType: leadDetails.lead_type_id,
        leadClass: leadDetails.leadClass || null,
        budgetFrom: leadDetails.budgetFrom || null,
        budgetTo: leadDetails.budgetTo || null,
        bedrooms: (leadDetails.bedrooms && leadDetails.bedrooms.length > 0 && leadDetails.bedrooms) || null,
        sizeAreaFrom: leadDetails.sizeAreaFrom || null,
        sizeAreaTo: leadDetails.sizeAreaTo || null,
        community: leadDetails.community || null,
        sub_community: (leadDetails.sub_community) || null,
        property: leadDetails.property_name || null,
        unitType: leadDetails.propertyunit_type || null,
        primaryView: leadDetails.view || null,
      };

      const getFieldIndex = (fieldId) => itemList.findIndex((f) => f.field.id === fieldId);

      const leadIndex = getFieldIndex('lead');
      const unitTypeIndex = getFieldIndex('unit_type');
      const bedroomIndex = getFieldIndex('bedroom');
      const budgetFromIndex = getFieldIndex('client_budget-from');
      const budgetToIndex = getFieldIndex('client_budget-to');
      const communityIndex = getFieldIndex('community');
      const propertyIndex = getFieldIndex('property');
      const areaSizeFrom = getFieldIndex('area_size-fromsqft');
      const areaSizeTo = getFieldIndex('area_size-tosqft');
      const primaryViewIndex = getFieldIndex('primary_view');
      const subCommunityIndex = getFieldIndex('sub_community');

      if (leadIndex !== -1 && leadData)
        setData(itemList[leadIndex].field.id, leadData);

      if (unitTypeIndex !== -1 && leadData)
        setData(itemList[unitTypeIndex].field.id, leadData.unitType);

      if (bedroomIndex !== -1)
        setData(itemList[bedroomIndex].field.id, leadData.bedrooms);

      if (communityIndex !== -1)
        setData(itemList[communityIndex].field.id, leadData.community);

      if (propertyIndex !== -1)
        setData(itemList[propertyIndex].field.id, leadData.property);

      if (budgetFromIndex !== -1) {
        setData(itemList[budgetFromIndex].field.id, leadData.budgetFrom);
        setData('client_budget_from', leadData.budgetFrom);
      }

      if (budgetToIndex !== -1) {
        setData(itemList[budgetToIndex].field.id, leadData.budgetTo);
        setData('client_budget_to', leadData.budgetTo);
      }

      if (areaSizeFrom !== -1) {
        setData(itemList[areaSizeFrom].field.id, leadData.sizeAreaFrom);
        setData('area_size_fromsqft', leadData.sizeAreaFrom);
      }

      if (areaSizeTo !== -1) {
        setData(itemList[areaSizeTo].field.id, leadData.sizeAreaTo);
        setData('area_size_tosqft', leadData.sizeAreaTo);
      }

      if (primaryViewIndex !== -1)
        setData(itemList[primaryViewIndex].field.id, leadData.primaryView);

      if (subCommunityIndex !== -1)
        setData(itemList[subCommunityIndex].field.id, leadData.sub_community || {});
    }

    setRerender(Math.random());
  };
