export const CampaignConfigurationPermissions = {

  ViewCampaignConfiguration: {
    permissionsId: 'f749c221-4a35-4713-db09-08dbf16cf01d',
    permissionsName: 'View Campaign Configuration',
    componentsId: null,
    components: null
  },

};
