export const TicketsPriorityEnum = {
    Low: {
        key: 1,
        label: 'Low',
        iconClass: 'mdi mdi-chevron-down royalblue'
    },
    Medium: {
        key: 2,
        label: 'Medium',
        iconClass: 'mdi mdi-currency-eth Gold'
    },
    High: {
        key: 3,
        label: 'High',
        iconClass: 'mdi mdi-chevron-up red'
    },
    Urgent: {
        key: 4,
        label: 'Urgent',
        iconClass: 'mdi mdi-chevron-double-up red'
    },
}
