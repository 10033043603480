import React, { useState, useCallback, useEffect, useRef } from "react";
import { ButtonBase } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import {
  GlobalHistory,
  bottomBoxComponentUpdate,
  showError,
  GlobalTranslate,
  showSuccess,
  returnPropsByPermissions,
  sideMenuComponentUpdate,
  sideMenuIsOpenUpdate,
} from "../../../../Helper";
import {
  ViewTypes,
  AutocompleteComponent,
  SelectComponet,
  Spinner,
  PermissionsComponent,
  NoDataFoundComponent,
  NoContentComponent,
  NoSearchResultComponent,
} from "../../../../Components";
import {
  ActionsEnum,
  ViewTypesEnum,
  ActionsButtonsEnum,
  TableActions,
  AgentRoleEnum,
  InquiryStatusEnum,
} from "../../../../Enums";
import {
  OrganizationUserSearch,
  LeadsAdvanceSearchTest,
  GetAllInquiriesServices,
} from "../../../../Services";
import { ListingShortagePermissions } from "../../../../Permissions";
import { PaginationComponent } from "../../../../Components/PaginationComponent/PaginationComponent";
import { ActiveItemActions } from "../../../../store/ActiveItem/ActiveItemActions";
import { useTitle } from "../../../../Hooks";
import { GlobalOrderFilterActions } from "../../../../store/GlobalOrderFilter/GlobalOrderFilterActions";
import { ListingShortageTableView } from "./ListingShortageUtilities";
import { InquiresCardComponent } from "../InquiresCardComponent/InquiresCardComponent";
import { InquiriesMapper } from "../InquiresMapper";

const parentTranslationPath = "Inquires";
const translationPath = "";

export const ListingShortageView = () => {
  const { t } = useTranslation(parentTranslationPath);
  const orderFilter = useSelector((state) => state.GlobalOrderFilterReducer);
  const [sortBy, setSortBy] = useState(null);

  const dispatch = useDispatch();
  const [list, setList] = useState([
    {
      enum: TableActions.openFile.key,
    },
  ]);

  // eslint-disable-next-line no-unused-vars
  const localStorageFilter = localStorage.getItem("GlobalFilter");
  const [activeItem, setActiveItem] = useState(null);
  const [inquiry, setInquiry] = useState(null);
  const [isLoading, setIsLoading] = useState({
    Inquiries: false,
    assignTo: false,
    createdBy: false,
    inquiry: false,
    allLeads: false,
  });

  useTitle(t(`${translationPath}listing-shortage`));

  const [filterSectionLists, setFilterSectionLists] = useState({
    allAssignTo: [],
    allCreatrdBy: [],
    allleads: [],
  });

  const [allListingShortage, setAllListingShortage] = useState({
    result: [],
    totalCount: 0,
  });

  const [timer, setTimer] = useState(null);
  const [searchedItem, setSearchedItem] = useState("");
  const searchTimer = useRef(null);
  const [viewTypes, setViewTypes] = useState(ViewTypesEnum.cards.key);
  const [criteria, setCriteria] = useState({});

  // eslint-disable-next-line no-unused-vars

  const loginResponse = useSelector((state) => state.login.loginResponse);

  const [activeActionType, setActiveActionType] = useState(
    (localStorage.getItem("ViewType") &&
      JSON.parse(localStorage.getItem("ViewType")).leadsLeaseView) ||
      ViewTypesEnum.cards.key
  );

  const [orderByToggler, setOrderByToggler] = useState(false);

  const [selectedOrderBy, setSelectedOrderBy] = useState({
    filterBy:
      (orderFilter &&
        orderFilter.listingShortage &&
        orderFilter.listingShortage.filterBy) ||
      null,
    orderBy:
      (orderFilter &&
        orderFilter.listingShortage &&
        orderFilter.listingShortage.orderBy) ||
      null,
  });

  const [filtersListingShortage, setFiltersListingShortage] = useState({
    inquiryTypeId:
      (orderFilter &&
        orderFilter.listingShortage &&
        orderFilter.listingShortage.inquiryTypeId) ||
      null,
    inquiryStatus:
      (orderFilter &&
        orderFilter.listingShortage &&
        orderFilter.listingShortage.inquiryStatus) ||
      null,
    assignTo:
      (orderFilter &&
        orderFilter.listingShortage &&
        orderFilter.listingShortage.assignTo) ||
      null,
    createdBy:
      (orderFilter &&
        orderFilter.listingShortage &&
        orderFilter.listingShortage.createdBy &&
        orderFilter.listingShortage.createdBy) ||
      null,
    activeActionType:
      (orderFilter &&
        orderFilter.listingShortage &&
        orderFilter.listingShortage.activeActionType) ||
      null,
    pageSize:
      (orderFilter &&
        orderFilter.listingShortage &&
        orderFilter.listingShortage.pageSize) ||
      null,
  });
  const [orderBy, setOrderBy] = useState(
    selectedOrderBy.filterBy
      ? selectedOrderBy
      : { filterBy: "CreatedOn", orderBy: 2 }
  );

  const [filter, setFilter] = useState({
    pageSize: parseInt(localStorage.getItem("Pagination")) || 25,
    pageIndex: 0,
    inquiryTypeId:
      (orderFilter &&
        orderFilter.listingShortage &&
        orderFilter.listingShortage.inquiryTypeId) ||
      null,
    assignedTo:
      (orderFilter &&
        orderFilter.listingShortage &&
        orderFilter.listingShortage.assignTo &&
        orderFilter.listingShortage.assignTo.id) ||
      null,
    inquieryStatusId:
      (orderFilter &&
        orderFilter.listingShortage &&
        orderFilter.listingShortage.inquiryStatus) ||
      null,
    filterBy: orderBy.filterBy || null,
    orderBy: orderBy.orderBy || null,
    withZeroMatching: false,
    createdBy:
      (orderFilter &&
        orderFilter.listingShortage &&
        orderFilter.listingShortage.createdBy &&
        orderFilter.listingShortage.createdBy.id) ||
      null,
  });

  const onTypeChanged = useCallback(
    (activeType) => {
      setViewTypes(activeType);
    },
    [setViewTypes]
  );

  const onPageIndexChanged = (pageIndex) => {
    setFilter((item) => ({ ...item, pageIndex }));
  };

  const onPageSizeChanged = (pageSize) => {
    setFilter((item) => ({ ...item, pageIndex: 0, pageSize }));
  };

  const filterByChanged = (value) => {
    setSelectedOrderBy((item) => ({ ...item, filterBy: value }));
  };
  const orderByChanged = (value) => {
    setSelectedOrderBy((item) => ({ ...item, orderBy: value }));
  };
  const orderBySubmitted = (event) => {
    event.preventDefault();
    if (!selectedOrderBy.filterBy || !selectedOrderBy.orderBy) {
      if (orderBy.filterBy || orderBy.orderBy) setOrderBy({});
      return;
    }
    dispatch(
      GlobalOrderFilterActions.globalOrderFilterRequest({
        ...orderFilter,
        listingShortage: {
          ...orderFilter.listingShortage,
          filterBy: selectedOrderBy.filterBy,
          orderBy: selectedOrderBy.orderBy,
        },
      })
    );
    setOrderBy({
      filterBy: selectedOrderBy.filterBy,
      orderBy: selectedOrderBy.orderBy,
    });
    setOrderByToggler(false);
  };
  
  const reverseTableCapitalization = (filterKey) => (filterKey[0].toLowerCase() + filterKey.slice(1))

  const getAllInquiries = useCallback(async () => {
    setIsLoading((item) => ({ ...item, Inquiries: true }));
    if (
      returnPropsByPermissions(
        ListingShortagePermissions.ViewInquiry.permissionsId
      )
    ) {
      const body = {
        ...filter,
        filterBy: orderBy.filterBy? reverseTableCapitalization(orderBy.filterBy) : null,
        orderBy: orderBy.orderBy,
        pageIndex: filter.pageIndex + 1,
        criteria,
      };
      const res = await GetAllInquiriesServices(body);
      if (!(res && res.status && res.status !== 200)) {
        const result = ((res && res.result) || []).map(
          (item) =>
            item.inquiryJson &&
            InquiriesMapper(item, JSON.parse(item.inquiryJson).inquiry)
        );
        setAllListingShortage({
          result: result || [],
          totalCount: res.totalCount || 0,
        });
      } else {
        setAllListingShortage({
          result: [],
          totalCount: 0,
        });
      }
    }
    setIsLoading((item) => ({ ...item, Inquiries: false }));
  }, [filter, orderBy,criteria]);

  const GetUsers = useCallback(async (searchItem) => {
    setIsLoading((item) => ({ ...item, createdBy: true }));

    const res = await OrganizationUserSearch({
      pageSize: 25,
      pageIndex: 0,
      name: searchItem,
      userStatusId: 2,
    });
    if (!(res && res.status && res.status !== 200))
      setFilterSectionLists((item) => ({
        ...item,
        allCreatrdBy: res?.result || [],
      }));
    else setFilterSectionLists((item) => ({ ...item, allCreatrdBy: [] }));

    setIsLoading((item) => ({ ...item, createdBy: false }));
  });

  const GetAssignTo = useCallback(async (searchItem) => {
    setIsLoading((item) => ({ ...item, assignTo: true }));

    const res = await OrganizationUserSearch({
      pageSize: 25,
      pageIndex: 0,
      name: searchItem,
      userStatusId: 2,
      userTypeId:
        filter.inquiryTypeId === 4
          ? AgentRoleEnum.LeaseListingAgent.value
          : filter.inquiryTypeId === 3
          ? AgentRoleEnum.SaleListingAgent.value
          : null,
    });
    if (!(res && res.status && res.status !== 200))
      setFilterSectionLists((item) => ({
        ...item,
        allAssignTo: res?.result || [],
      }));
    else setFilterSectionLists((item) => ({ ...item, allAssignTo: [] }));

    setIsLoading((item) => ({ ...item, assignTo: false }));
  });

  useEffect(() => {
    GetUsers();
  }, []);

  useEffect(() => {
    GetAssignTo();
  }, [filter.inquiryTypeId]);

  useEffect(() => {
    getAllInquiries();
  }, [filter, orderBy, criteria]);

  useEffect(() => {
    if (
      returnPropsByPermissions(
        ListingShortagePermissions.ViewInquiry.permissionsId
      )
    ) {
      bottomBoxComponentUpdate(
        <PaginationComponent
          pageIndex={filter.pageIndex}
          pageSize={filter.pageSize}
          totalCount={allListingShortage.totalCount}
          onPageIndexChanged={onPageIndexChanged}
          onPageSizeChanged={onPageSizeChanged}
        />
      );
    }
  });

  useEffect(
    () => () => {
      bottomBoxComponentUpdate(null);
      sideMenuComponentUpdate(null);
      sideMenuIsOpenUpdate(false);
    },
    []
  );
  useEffect(() => {
    if (sortBy) {
      setOrderBy((item) => ({
        ...item,
        filterBy: sortBy.filterBy,
        orderBy: sortBy.orderBy,
        fieldType: sortBy.fieldType,
      }));
    }
  }, [sortBy]);

  return (
    <div className="view-wrapper leads leads-wrapper">
      <Spinner isActive={isLoading.Inquiries} isAbsolute />
      <div className="d-flex-column">
        <div className="header-section">
          <div className="filter-section">
            <div className="section">
              <PermissionsComponent
                permissionsList={Object.values(ListingShortagePermissions)}
                permissionsId={
                  ListingShortagePermissions.AddNewInquiry.permissionsId
                }
              >
                <ButtonBase
                  className="btns theme-solid"
                  idRef="AddNewZeroMatchingInquiry"
                  onClick={() => {
                    GlobalHistory.push("/home/listing-shortage/add");
                  }}
                >
                  <span>{t(`${translationPath}add-new-listing-shortage`)}</span>
                </ButtonBase>
              </PermissionsComponent>
            </div>
            <PermissionsComponent
              permissionsList={Object.values(ListingShortagePermissions)}
              permissionsId={
                ListingShortagePermissions.ViewInquiry.permissionsId
              }
            >
              <div className="section autocomplete-section">
                <div className="d-flex-column px-2 w-100 p-relative mb-2">
                  <div className="agentSection pl-5-reversed">
                    <div className="mr-1-reversed">
                      <SelectComponet
                        idRef="listing-shortageRef}"
                        data={[
                          { inquiryTypeId: 3, title: "Sale" },
                          { inquiryTypeId: 4, title: "Lease" },
                        ]}
                        valueInput="inquiryTypeId"
                        value={filtersListingShortage.inquiryTypeId}
                        textInput="title"
                        onSelectChanged={(value) => {
                          setFilter((item) => ({
                            ...item,
                            inquiryTypeId: value,
                            assignedTo: null,
                          }));
                          dispatch(
                            GlobalOrderFilterActions.globalOrderFilterRequest({
                              ...orderFilter,
                              listingShortage: {
                                ...orderFilter.listingShortage,
                                inquiryTypeId: value,
                                assignTo: null,
                              },
                            })
                          );
                          setFiltersListingShortage((item) => ({
                            ...item,
                            inquiryTypeId: value,
                            assignTo: null,
                          }));
                        }}
                        emptyItem={{
                          value: null,
                          text: "select-inquiry_type",
                          isDisabled: false,
                        }}
                        translationPathForData={translationPath}
                        parentTranslationPath={parentTranslationPath}
                        translationPath={translationPath}
                      />
                    </div>
                    <div className="mr-1-reversed">
                      <SelectComponet
                        data={Object.values(InquiryStatusEnum)}
                        emptyItem={{
                          value: null,
                          text: "select-status",
                          isDisabled: false,
                        }}
                        valueInput="id"
                        textInput="name"
                        onSelectChanged={(value) => {
                          setFiltersListingShortage((item) => ({
                            ...item,
                            inquiryStatus: value,
                          }));
                          setFilter((item) => ({
                            ...item,
                            inquieryStatusId: value,
                          }));
                          dispatch(
                            GlobalOrderFilterActions.globalOrderFilterRequest({
                              ...orderFilter,
                              listingShortage: {
                                ...orderFilter.listingShortage,
                                inquiryStatus: value,
                              },
                            })
                          );
                        }}
                        value={filtersListingShortage.inquiryStatus}
                        wrapperClasses="w-auto"
                        themeClass="theme-default"
                        idRef="inquiry_status"
                        parentTranslationPath={parentTranslationPath}
                        translationPath={translationPath}
                        translationPathForData={translationPath}
                      />
                    </div>
                    <div className="w-100 mr-1-reversed">
                      <AutocompleteComponent
                        idRef="assignToRef"
                        isLoading={isLoading.assignTo}
                        withLoader
                        inputPlaceholder={t(`${translationPath}assignTo`)}
                        selectedValues={filtersListingShortage.assignTo}
                        data={
                          filterSectionLists && filterSectionLists.allAssignTo
                        }
                        multiple={false}
                        displayLabel={(option) =>
                          (option && option.fullName) || ""
                        }
                        chipsLabel={(option) =>
                          (option && option.fullName) || ""
                        }
                        withoutSearchButton
                        parentTranslationPath={parentTranslationPath}
                        translationPath={translationPath}
                        onChange={(event, newValue) => {
                          setFiltersListingShortage((item) => ({
                            ...item,
                            assignTo: newValue,
                          }));
                          setFilter((item) => ({
                            ...item,
                            assignedTo: (newValue && newValue.id) || "",
                          }));
                          dispatch(
                            GlobalOrderFilterActions.globalOrderFilterRequest({
                              ...orderFilter,
                              listingShortage: {
                                ...orderFilter.listingShortage,
                                assignTo: newValue,
                              },
                            })
                          );
                        }}
                        onInputKeyUp={(e) => {
                          const { value } = e.target;
                          if (searchTimer.current)
                            clearTimeout(searchTimer.current);
                          searchTimer.current = setTimeout(() => {
                            GetAssignTo(value);
                          }, 700);
                        }}
                      />
                    </div>
                    <div className="w-100 mr-1-reversed">
                      <AutocompleteComponent
                        idRef="createdByRef"
                        inputPlaceholder={t(`${translationPath}createdBy`)}
                        isLoading={isLoading.createdBy}
                        withLoader
                        data={
                          filterSectionLists && filterSectionLists.allCreatrdBy
                        }
                        selectedValues={filtersListingShortage.createdBy}
                        multiple={false}
                        displayLabel={(option) =>
                          (option && option.fullName) || ""
                        }
                        chipsLabel={(option) =>
                          (option && option.fullName) || ""
                        }
                        withoutSearchButton
                        parentTranslationPath={parentTranslationPath}
                        translationPath={translationPath}
                        onChange={(event, newValue) => {
                          setFiltersListingShortage((item) => ({
                            ...item,
                            createdBy: newValue,
                          }));
                          setFilter((item) => ({
                            ...item,
                            createdBy: (newValue && newValue.id) || "",
                          }));
                          dispatch(
                            GlobalOrderFilterActions.globalOrderFilterRequest({
                              ...orderFilter,
                              listingShortage: {
                                ...orderFilter.listingShortage,
                                createdBy: newValue,
                              },
                            })
                          );
                        }}
                        onInputKeyUp={(e) => {
                          const { value } = e.target;
                          if (searchTimer.current)
                            clearTimeout(searchTimer.current);
                          searchTimer.current = setTimeout(() => {
                            GetUsers(value);
                          }, 700);
                        }}
                      />
                    </div>
                  </div>
                </div>

                <div className="view-search-wrapper">
                  <ViewTypes
                    onTypeChanged={onTypeChanged}
                    initialActiveType={activeActionType}
                    activeTypes={[
                      ViewTypesEnum.tableView.key,
                      ViewTypesEnum.cards.key,
                    ]}
                    className="mb-3"
                  />
                </div>
              </div>
            </PermissionsComponent>
          </div>
          <PermissionsComponent
            permissionsList={Object.values(ListingShortagePermissions)}
            permissionsId={ListingShortagePermissions.ViewInquiry.permissionsId}
          >
            <div className="d-flex px-2">
              <span className="mx-2 mt-1">
                {t(`${translationPath}Inquires`)}
              </span>
              <span className="separator-v s-primary s-reverse s-h-25px mt-1" />
              <span className="px-2 d-flex">
                <span className="texts-large mt-1">
                  {t(`${translationPath}order-by`)}:
                </span>
                <div className="px-2">
                  <SelectComponet
                    idRef="filterByRef"
                    data={[
                      { id: "CreatedOn", filterBy: "created-on" },
                      { id: "UpdateOn", filterBy: "last-updated" },
                    ]}
                    value={selectedOrderBy.filterBy}
                    onSelectChanged={filterByChanged}
                    wrapperClasses="mb-3"
                    isRequired
                    valueInput="id"
                    textInput="filterBy"
                    emptyItem={{
                      value: null,
                      text: "select-filter-by",
                      isDisabled: false,
                    }}
                    parentTranslationPath={parentTranslationPath}
                    translationPath={translationPath}
                    translationPathForData={translationPath}
                  />
                </div>
                <div className="px-2">
                  <SelectComponet
                    idRef="orderByRef"
                    data={[
                      { id: 1, orderBy: "ascending" },
                      { id: 2, orderBy: "descending" },
                    ]}
                    value={selectedOrderBy.orderBy}
                    onSelectChanged={orderByChanged}
                    wrapperClasses="mb-3"
                    isRequired
                    valueInput="id"
                    textInput="orderBy"
                    emptyItem={{
                      value: null,
                      text: "select-sort-by",
                      isDisabled: false,
                    }}
                    parentTranslationPath={parentTranslationPath}
                    translationPath={translationPath}
                    translationPathForData={translationPath}
                  />
                </div>
                <div className="mt-1">
                  <ButtonBase
                    className="btns theme-solid"
                    onClick={orderBySubmitted}
                    id="action_apply"
                    disabled={
                      !selectedOrderBy.filterBy || !selectedOrderBy.orderBy
                    }
                  >
                    <span>{t(`${translationPath}apply`)}</span>
                  </ButtonBase>
                </div>
              </span>
            </div>
          </PermissionsComponent>
        </div>
        <>
          <PermissionsComponent
            permissionsList={Object.values(ListingShortagePermissions)}
            permissionsId={ListingShortagePermissions.ViewInquiry.permissionsId}
          >
            {((viewTypes === ViewTypesEnum.tableView.key && (
                <>
                  <div className="view-wrapers">
                    <ListingShortageTableView
                      translationPath={translationPath}
                      data={allListingShortage}
                      filter={filter}
                      parentTranslationPath={parentTranslationPath}
                      setActiveItem={setActiveItem}
                      setFilter={() =>
                        setFilter((item) => ({ ...item, pageIndex: 0 }))
                      }
                      activeItem={activeItem}
                      isLoading={isLoading}
                      setSortBy={setSortBy}
                      setCriteria={setCriteria}
                    />
                  </div>
                </>
              )) ||
              (viewTypes === ViewTypesEnum.cards.key && (
                <>
                  <div className="view-wrapers">
                    <InquiresCardComponent
                      parentTranslationPath={parentTranslationPath}
                      translationPath={translationPath}
                      data={allListingShortage && allListingShortage.result}
                      filter={filter}
                      setFilter={() =>
                        setFilter((item) => ({ ...item, pageIndex: 0 }))
                      }
                      isLoading={isLoading}
                      inquiryType="Listing Shortage"
                    />
                  </div>
                </>
              ))
            )}
          </PermissionsComponent>
        </>
      </div>
    </div>
  );
};
