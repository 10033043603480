import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  modalContainer: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 1000,
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
  text: {
    fontSize: "18px",
    fontWeight: 600,
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
    },
  },
  modalPaper: {
    display: "flex",
    flexDirection: "column",
    maxWidth: "1000px",
    borderRadius: "12px",
    background: theme.palette.background.paper,
    boxShadow:
      "0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03)",

    "& p": {
      color: theme.palette.text.tertiary,
      fontWeight: 400,
      marginBottom: "16px",
      fontSize: "16px",
      [theme.breakpoints.down("sm")]: {
        fontSize: "12px",
      },
    },
  },
  container: {
    display: "flex",
    gap: "16px",

    padding: "48px 80px",
    [theme.breakpoints.down("sm")]: {
      padding: "24px 16px",
      flexDirection: "column",
    },
  },
  content: {
    padding: "24px 24px 20px 24px",
    borderBottom: `1px solid ${theme.palette.border.secondary}`,
  },
  card: {
    display: "flex",
    padding: "24px",
    flexDirection: "column",
    alignItems: "center",
    gap: "8px",
    flex: "1 0 0",
    textAlign: "center",
    borderRadius: "12px",
    background: theme.palette.background.paper,
    cursor: "pointer",
    border: `2px solid ${theme.palette.border.secondary}`,

    "& span": {
      fontSize: "24px",
      fontWeight: 600,
      [theme.breakpoints.down("sm")]: {
        fontSize: "18px", // Adjust card title font size for mobile
      },
    },

    "&:hover": {
      border: `2px solid ${theme.palette.border.brandSolid}`,
    },
  },
}));
