import React, { useCallback, useEffect, useReducer, useState } from "react";
import { useSelector } from "react-redux";
import "./BankAccountDetailsCard.scss";
import btnLoader from "../../../../../assets/images/GIF_images/btn-loader.gif";
import downloadIcon from "../../../../../assets/images/Propxicon/download-01.svg";
import shareIcon from "../../../../../assets/images/Propxicon/share-04.svg";
import { ButtonBase } from "@material-ui/core";
import { PopoverComponent } from "../../../../../Components";
import {
  GetBranchInfoById,
  GetReceiptDefaultPaymentDetails,
  ShareReceiptPaymentDetails,
  lookupItemsGetId,
} from "../../../../../Services";
import { StaticLookupsIds } from "../../../../../assets/json/StaticLookupsIds";
import { UnitsOperationTypeEnum } from "../../../../../Enums";
import { showError, showSuccess } from "../../../../../Helper";
import { AddCustomAccountDialog } from "./AddCustomAccountDialog";
import { useTranslation } from "react-i18next";

export const BankAccountDetailsCard = ({
  invoiceId,
  customAccountDetails,
  setCustomAccountDetails,
  unitTransactionId,
  parentTranslationPath,
}) => {
  const { t } = useTranslation(parentTranslationPath);
  const translationPath = "Utilities."
  const loginResponse = useSelector((state) => state.login.loginResponse);
  const reducer = useCallback((state, action) => {
    if (action.id !== "edit") return { ...state, [action.id]: action.value };
    return {
      ...action.value,
    };
  }, []);

  const [isDialogOpen, setIsDialogOpen] = useReducer(reducer, {
    CustomAccount: false,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [selectedPopoverAction, setSelectedPopoverAction] = useState(null);
  const [data, setData] = useReducer(reducer, {
    defaultBank: null,
    contactTypes: [],
  });

  const getDefaultPaymentDetails = async () => {

    const res = await GetReceiptDefaultPaymentDetails(unitTransactionId);
    if (!(res && res.status && res.status !== 200))
      setData({ id: "defaultBank", value: res || [] });
    else setData({ id: "defaultBank", value: [] });
  };

  const getContactTypes = async () => {
    const res = await lookupItemsGetId({
      lookupTypeId: StaticLookupsIds.ContactTypes,
      lookupParentId: UnitsOperationTypeEnum.rent.key,
    });
    if (!(res && res.status && res.status !== 200))
      setData({ id: "contactTypes", value: res || [] });
    else setData({ id: "contactTypes", value: [] });
  };

  const getSharedACCBody = (ACCdata, contactTypeId) => {
    let body = {};
    if (ACCdata) {
      body = {
        invoiceId,
        invoiceContactTypeId: contactTypeId,
        accountName: ACCdata.accountName || "",
        accountNumber: ACCdata.accountNumber || "",
        bank: ACCdata.bank || "",
        ibanCode: ACCdata.ibanCode || "",
        currency: ACCdata.currency || "",
        swiftCode: ACCdata.swiftCode || "",
      };
    }

    return body;
  };

  const shareReceiptPaymentDetails = async (contactTypeId) => {
    setIsLoading(true);
    const ACCdata = customAccountDetails || data.defaultBank;
    const body = getSharedACCBody(ACCdata, contactTypeId);

    const res = await ShareReceiptPaymentDetails(body);
    if (res == true) {
      showSuccess("Bank account information sent successfully!");
    } else showError("Sharing bank account information failed..");
    setIsLoading(false);

  };

  const handlePopoverOpen = useCallback((event) => {
    event.preventDefault();
    setSelectedPopoverAction(event.currentTarget);
  }, []);

  const handlePopoverClose = () => {
    setSelectedPopoverAction(null);
  };

  const shareBankAccountHandler = (contactTypeId) => {
    shareReceiptPaymentDetails(contactTypeId);
    handlePopoverClose();
  };

  useEffect(() => {
    if(unitTransactionId) getDefaultPaymentDetails();
  }, [unitTransactionId]);

  useEffect(() => {
    if (data.contactTypes?.length == 0) getContactTypes();
  }, [data.contactTypes]);

  return (
    <div className="Bank-Account-Card">
      <div className="d-flex-v-center-h-between w-100 b-bottom pb-3">
        <p className="mb-0 fz-18 fw-simi-bold">
          {/* {data.defaultBank?.branchName || "-"} */}
        </p>
        <div className="d-flex-default d-flex-center flex-wrap">
          <div
            className="d-flex-default d-flex-center gap-4 pointer  mb-1"
            onClick={(event) => handlePopoverOpen(event)}
          >
            {isLoading ? (
              <img src={btnLoader} className="dark-loader ml-1" width="12" height="12" />
            ) : (
              <img src={shareIcon} />
            )}
            <span className="light-text fw-simi-bold text-wrap">{t(`${translationPath}Share-Account-Details`)}</span>
            <span className="mdi mdi-chevron-down mx-2" />
          </div>
          {/* <ButtonBase className="btns theme-propx outlined px-0 py-1 miw-40px mb-1">
            <span>
              <img src={downloadIcon} />
            </span>
          </ButtonBase> */}
          <ButtonBase
            onClick={() =>
              setIsDialogOpen({ id: "CustomAccount", value: true })
            }
            className="btns theme-propx outlined  py-2"
          >
            {t(`${translationPath}Add-custom-account`)}
          </ButtonBase>
        </div>
      </div>
      <div className="d-flex w-100 gap-8">
        <div className="w-50 mt-3">
          <p className="mb-1">
            <span className="light-text">{t(`${translationPath}Account-name`)}</span>
            <span>: </span>
            <span className="light-text fw-simi-bold">
              {customAccountDetails
                ? customAccountDetails.accountName || "-"
                : data.defaultBank?.accountName || "-"}
            </span>
          </p>
          <p className="mb-1">
            <span className="light-text">{t(`${translationPath}Bank-name`)}</span>
            <span>: </span>
            <span className="light-text fw-simi-bold">
              {customAccountDetails
                ? customAccountDetails.bank  || "-"
                : data.defaultBank?.bank  || "-"}
            </span>
          </p>
          <p className="mb-0">
            <span className="light-text">{t(`${translationPath}Currency`)}</span>
            <span>: </span>
            <span className="light-text fw-simi-bold">
              {customAccountDetails
                ? customAccountDetails.currency || "-"
                : data.defaultBank?.currency || "-"}
            </span>
          </p>
        </div>
        <div className="w-50 mt-3">
          <p className="mb-1">
            <span className="light-text">{t(`${translationPath}Account-number`)}</span>
            <span>: </span>
            <span className="light-text fw-simi-bold">
              {customAccountDetails
                ? customAccountDetails.accountNumber || "-"
                : data.defaultBank?.accountNumber || "-"}
            </span>
          </p>
          <p className="mb-1">
            <span className="light-text">{t(`${translationPath}IBAN-code`)}</span>
            <span>: </span>
            <span className="light-text fw-simi-bold">
              {customAccountDetails
                ? customAccountDetails.ibanCode || "-"
                : data.defaultBank?.ibanCode || "-"}
            </span>
          </p>
          <p className="mb-0">
            <span className="light-text">{t(`${translationPath}Swift-code`)}</span>
            <span>: </span>
            <span className="light-text fw-simi-bold">
              {customAccountDetails
                ? customAccountDetails.swiftCode || "-"
                : data.defaultBank?.swiftCode || "-"}
            </span>
          </p>
        </div>
      </div>
      <PopoverComponent
        idRef={`defaultBankRef`}
        attachedWith={selectedPopoverAction}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        popoverClasses="release-note-popover"
        handleClose={handlePopoverClose}
        component={
          <>
            {data.contactTypes?.map((item) => (
              <div
                key={item.lookupItemName}
                className="popover-item"
                onClick={() => shareBankAccountHandler(item.lookupItemId)}
              >
                <span className="option-name">{item.lookupItemName}</span>
              </div>
            ))}
          </>
        }
      />
      <AddCustomAccountDialog
        onClose={() => setIsDialogOpen({ id: "CustomAccount", value: false })}
        onSubmit={(details) => {
          setCustomAccountDetails(details);
          setIsDialogOpen({ id: "CustomAccount", value: false });
        }}
        isOpen={isDialogOpen.CustomAccount}
        parentTranslationPath={parentTranslationPath}
      />
    </div>
  );
};