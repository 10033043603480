import React, {
  useCallback, useState, useEffect
} from 'react';
import Button from '@material-ui/core/Button';
import { PropTypes } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Tooltip } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { ActiveItemActions } from '../../../../../store/ActiveItem/ActiveItemActions';

import { ContactTypeEnum, ActionsEnum, LeadsStatusEnum, LeadTypeIdEnum } from '../../../../../Enums';
import {
   showSuccess, sideMenuIsOpenUpdate, GlobalHistory, returnPropsByPermissions,
  getDownloadableLink,
  CheckContactConsent
} from '../../../../../Helper';
import { Calendar, DialogComponent, LeadsStagesBreadcrumb } from '../../../../../Components';
import { archiveLeadsPut, CheckContactCommunication } from '../../../../../Services';
import { ArchiveState } from '../../../../../assets/json/StaticValue.json';
import { CloseLeadDailog } from '../../..';
import { ActivitiesManagementDialog } from '../../../../../SharedComponents/ActivitiesManagementDialog/ActivitiesManagementDialog';
import { CopyToClipboardComponents } from '../../../../../ReusableComponents/UtilityComponents/CopyToClipboardComponents/CopyToClipboardComponents';
import { LeadsCAllCenterPermissions } from '../../../../../Permissions';
import { UnqualifiedLeadsDialog } from '../../../../DFMSharedComponents/Unqualified/UnqualifiedLeadsDialog';
import ActivityDetailsComponent from '../../../../DFMSharedComponents/ActivityDetails/ActivityDetailsComponent';
import { ConsentRequestDialog } from '../../../../../SharedComponents/ConsentRequestDialog/ConsentRequestDialog';
import SearchAndMatchDialog from '../../../LeadsLeaseView/LeadsLeaseUtilities/Dialogs/SearchAndMatchDialog/SearchAndMatchDialog';
import { StaticLookupsIds } from '../../../../../assets/json/StaticLookupsIds';
const translationPath = 'utilities.cardDetailsComponent.';
function CardDetailsComponent({
  activeData, cardDetailsActionClicked, relodedata, onActionClicked,
  displyOpenFileButton,
  displyMatchButton,
  displyCloseLead,
  checkIfSensitiveField,
  closeSideMenu,
  parentTranslationPath
}) {
  const { t } = useTranslation('LeadsView');
  const [searchDialog, setSearchDialog] = useState(false);
  const [schedule, setSchedule] = useState(false);
  const dispatch = useDispatch();
  const [contactType, setContactType] = useState(null);
  const [dataFile, setDataFile] = useState(true);
  const [open, setopen] = React.useState(false);
  const [isConsentDialogOpen, setIsConsentDialogOpen] = useState(false);
  const [isOpenclosed, setIsOpenclosed] = useState(false);
  const [addActivity, setAddActivity] = useState(false);
  const [activitydetails ,setActivityDetails]=useState(false);
  const [contactConsent, setContactConsent] = useState(null);

  const [hide, sethide] = React.useState(false);
  const getDefaultContactImage = (contactType) => ContactTypeEnum[contactType].defaultImg;

  const archiveLeads = useCallback(async () => {
    await archiveLeadsPut(activeData.id);
    showSuccess(t(`${translationPath}Successarchive`));
    setopen(false);
    sideMenuIsOpenUpdate(false);
    relodedata();
  }, []);
  const hideLeadsbbt = useCallback(async () => {
    sethide(true);
  }, []);

  useEffect(() => {
    sethide(false);
  }, [activeData.id]);

  
  const checkContactConsent = async (leadId) => setContactConsent(await CheckContactConsent({ leadId }));

  useEffect(() => {
    if (activeData) checkContactConsent(activeData?.id);
  }, [activeData]);

  return (
    <div className='leads-card-detaild-wrapper'>
      <div className='archive-bbt'>
        <Button
          onClick={() => setopen(true)}
          className='MuiButtonBase-root MuiButton-root MuiButton-text btns-icon theme-solid mx-2 mb-2'
          title={t(`${translationPath}ArchiveLead`)}
          disabled={ArchiveState}
        >
          <span className='MuiButton-label'>
            <span className='mdi mdi-inbox-multiple' />
          </span>
          <span className='MuiTouchRipple-root' />
        </Button>
        <Button
          onClick={() => setIsConsentDialogOpen(true) }
          disabled={!(contactConsent?.canSendConsent) || activeData?.status?.lookupItemName ==='Closed'}
          className='MuiButtonBase-root MuiButton-root MuiButton-text btns-icon theme-solid mx-2 mb-2'
          title={t(`${translationPath}Ask-For-Consent`)}
        >
          <span className='MuiButton-label'>
            <span className='mdi mdi-comment-check' />
          </span>
          <span className='MuiTouchRipple-root' />
        </Button>
        {LeadTypeIdEnum.Seeker.leadTypeId === activeData.leadTypeId  && 
          activeData.status.lookupItemName?.toLowerCase() === 'open' && 
          returnPropsByPermissions(LeadsCAllCenterPermissions.SearchAndMatch.permissionsId) && (
          <Button
            onClick={() => setSearchDialog(true)}
            className='MuiButtonBase-root MuiButton-root MuiButton-text btns-icon theme-solid mx-2 mb-2'
            title={t(`${translationPath}search-and-match`)}
          >
            <span className='MuiButton-label'>
              <span class='mdi mdi-magnify' />
            </span>
            <span className='MuiTouchRipple-root' />
          </Button>
        )}
      </div>
      {activeData && (
        <div className='side-menu-wrapper'>
          <div>
            <div className='d-flex-center mb-3'>
              <img
                src={activeData && activeData.contact_name && activeData.contact_name.contact_image && getDownloadableLink(activeData.contact_name.contact_image.uuid ,200) ||
                  getDefaultContactImage(activeData.type)}
                className='details-img'
                alt={t(`${translationPath}lead-image`)}
              />
            </div>
            <div className='properety-plan d-flex-center mb-3'>{activeData.name}</div>
            <div className='d-flex-center flex-wrap mb-2'>
              {(activeData && activeData.contactPreferences && activeData.contactPreferences.some((c) => c === 'Email') && activeData.contact_name && activeData.contact_name.email_address && checkIfSensitiveField && checkIfSensitiveField('Email', activeData && activeData.contact_name, activeData && activeData.contact_name && activeData.contact_name.type) && (
                <Button
                  disabled={((activeData && activeData.status &&  activeData.status.lookupItemId) === LeadsStatusEnum.Closed.status )
                  }
                  className={`${ActionsEnum.emailSolid.buttonClasses}  mx-2 mb-2`}
                  onClick={onActionClicked(ActionsEnum.emailSolid.key, activeData)}
                >
                  <span className={ActionsEnum.emailSolid.icon} />
                </Button>
              )) || ''}
              { activeData && activeData.contactPreferences && activeData.contactPreferences.some((c) => c === 'WhatsApp') && activeData.contact_name && activeData.contact_name.mobile && checkIfSensitiveField('WhatsApp', activeData && activeData.contact_name, activeData && activeData.contact_name && activeData.contact_name.type) && (
                <Button
                  disabled={((activeData && activeData.status &&  activeData.status.lookupItemId) === LeadsStatusEnum.Closed.status )
                  }
                  className={`${ActionsEnum.whatsappSolid.buttonClasses}  mx-2 mb-2`}
                  onClick={onActionClicked(ActionsEnum.whatsapp.key, activeData)}
                >
                  <span className={ActionsEnum.whatsappSolid.icon} />
                </Button>
              )}
              { activeData && activeData.contactPreferences && activeData.contactPreferences.some((c) => c === 'SMS') && activeData.contact_name && activeData.contact_name.mobile && checkIfSensitiveField && checkIfSensitiveField('SMS', activeData && activeData.contact_name, activeData && activeData.contact_name && activeData.contact_name.type) && (
                <Button
                  disabled={((activeData && activeData.status &&  activeData.status.lookupItemId) === LeadsStatusEnum.Closed.status )
                  }
                  className={`${ActionsEnum.smsSolid.buttonClasses}  mx-2 mb-2`}
                  onClick={onActionClicked(ActionsEnum.smsSolid.key, activeData)}
                >
                  <span className={ActionsEnum.smsSolid.icon} />
                </Button>
              )}
            </div>
            <div className='mb-3 '>
              <div className='data-schedule-button'>
                <div className={`data-file-button ${dataFile ? 'selected' : ''}`}>
                  <Button
                    onClick={() => {
                      setSchedule(false);
                      setDataFile(true);
                      setActivityDetails(false)

                    }}
                    className={`btns ${dataFile ? 'theme-outline' : ''}`}
                  >
                    {t(`${translationPath}data-file`)}
                  </Button>
                </div>
                <div className={`schedule-button ${schedule ? 'selected' : ''}`}>
                  <Button
                    onClick={() => {
                      setSchedule(true);
                      setDataFile(false);
                      setActivityDetails(false)

                    }}
                    className={`btns ${schedule ? 'theme-outline' : ''}`}
                  >
                    {t(`${translationPath}schedule`)}
                  </Button>
                </div>
                {
                   returnPropsByPermissions(LeadsCAllCenterPermissions.AddNewActivity.permissionsId) &&
                   activeData && activeData.status && activeData.status.lookupItemName && activeData.status.lookupItemName
                   && activeData.status.lookupItemName === LeadsStatusEnum.Open.key &&
                   (
                   <div className={`add-activity-button ${addActivity ? 'selected' : ''}`}>
                     <Button
                       onClick={() => {
                        setSchedule(false);
                        setDataFile(false);
                        setAddActivity(true);
                        setActivityDetails(false)

                     }}
                       className={`btns ${addActivity ? 'theme-outline' : ''}`}
                     >
                       {t(`${translationPath}add-activity`)}

                     </Button>
                   </div>
                   )
                }
                <div className={`details-button ${activitydetails ? 'selected' : ''}`}>
                <Button
                  onClick={() => {
                    setActivityDetails(true)
                    setSchedule(false);
                    setDataFile(false);
                  }}
                  className={`btns ${activitydetails ? 'theme-outline' : ''}`}
                >
                  {t(`${translationPath}ActivityDetails`)}
                </Button>
              </div>
              </div>
            </div>
            <LeadsStagesBreadcrumb
              currentStage={activeData?.lead_stage?.lookupItemId} 
              contactId={activeData?.contact_name?.id} 
              leadId={activeData.id}
              reload={()=> {relodedata(); closeSideMenu()}}
              contactType={activeData.contact_name?.type}
              leadStatus ={activeData?.status?.lookupItemName}
            />
            {dataFile && (
              <div className='px-3 mb-3 slider-data'>
                <div className='items-title mb-3'>
                  {t(`${translationPath}personal-information`)}
                </div>
                <div className='d-flex  contact-btn-div'>
                  <div className='d-flex lead-id-area'>
                    <span className='texts gray-primary-bold'>
                      {t(`${translationPath}id`)}
                      :
                    </span>
                    <span className='texts s-gray-primary mb-2' />

                    <Tooltip title={t(`${translationPath}copy`)}>
                      <CopyToClipboardComponents
                        data={activeData && activeData.id}
                        childrenData={activeData && activeData.id}
                      />
                    </Tooltip>
                  </div>

                  <Button
                    className='btns view-contact'
                    id='view-contact-ref'
                    onClick={() => {
                          const contact = activeData.contact_name;
                          dispatch(ActiveItemActions.activeItemRequest({ name: contact.name, id: contact.id }));
                          window.open(`/home/Contacts-CRM/contact-profile-edit?formType=${contact.type}&id=${contact.id}`, '_blank').focus();
                        }}
                  >
                    <span className='mdi mdi-attachment' />
                    <span className=''>
                      {t(`${translationPath}view_contact`)}
                    </span>
                  </Button>

                </div>
                {activeData.details &&
                  activeData.details.map((item, index) => (
                    <React.Fragment key={`detailsRef${index + 1}}`}>
                      {item.value && (
                        <div className='mb-3'>
                          <span className='texts gray-primary-bold'>
                            {t(`${translationPath}${item.title}`)}
                            :
                          </span>
                          <span className='texts s-gray-primary'>{`  ${item.value}`}</span>
                        </div>
                      )}
                    </React.Fragment>
                  ))}
                <div className='mb-3'>
                  <span className='texts gray-primary-bold'>
                    {t(`${translationPath}country`)}
                    :
                  </span>
                  <span className='texts s-gray-primary'>
                    {`  ${activeData.country && activeData.country.lookupItemName ?
                      activeData.country.lookupItemName :
                      'N/A'
                      }`}

                  </span>
                </div>
                <div className='mb-3'>
                  <span className='texts gray-primary-bold'>
                    {t(`${translationPath}city`)}
                    :
                  </span>
                  <span className='texts s-gray-primary'>
                    {`  ${activeData.city && activeData.city.lookupItemName ? activeData.city.lookupItemName : 'N/A'
                      }`}

                  </span>
                </div>
                <div className='mb-3'>
                  <span className='texts gray-primary-bold'>
                    {t(`${translationPath}bathrooms`)}
                    :
                  </span>
                  <span className='texts s-gray-primary'>
                    {`  ${activeData.bathrooms || 'N/A'
                      }`}

                  </span>
                </div>
                <div className='mb-3'>
                  <span className='texts gray-primary-bold'>
                    {t(`${translationPath}bedrooms`)}
                    :
                  </span>
                  <span className='texts s-gray-primary'>
                    {`  ${activeData.bedrooms || 'N/A'
                      }`}

                  </span>
                </div>
                <div className='mb-3'>
                  <span className='texts gray-primary-bold'>
                    {t(`${translationPath}budget`)}
                    :
                  </span>
                  <span className='texts s-gray-primary'>
                    {` ${activeData.budget || 'N/A'}`}

                  </span>
                </div>
                <div className='mb-3'>
                  <span className='texts gray-primary-bold'>
                    {t(`${translationPath}leadClass`)}
                    :
                  </span>
                  <span className='texts s-gray-primary'>
                    {`  ${activeData.leadClass || 'N/A'
                      }`}

                  </span>
                </div>
                <div className='mb-3'>
                  <span className='texts gray-primary-bold'>
                    {t(`${translationPath}referredby`)}
                    :
                  </span>
                  <span className='texts s-gray-primary'>
                    {`  ${activeData.referredby ? activeData.referredby.name : 'N/A'
                      }`}

                  </span>
                </div>
                <div className='mb-3'>
                  <span className='texts gray-primary-bold'>
                    {t(`${translationPath}referredto`)}
                    :
                  </span>
                  <span className='texts s-gray-primary'>
                    {`  ${activeData.referredto ? activeData.referredto.name : 'N/A'
                      }`}

                  </span>
                </div>
                {activeData && activeData.contact_name && activeData.contact_name && activeData.contact_name.mobile && (
                  <div className='d-flex lead-id-area'>
                    <span className='texts gray-primary-bold'>
                      {t(`${translationPath}Mobile`)}
                      :
                    </span>
                    <span className='texts s-gray-primary mb-2' />
                    {activeData && activeData.contact_name && activeData.contact_name && activeData.contact_name.mobile &&
                      (<Tooltip title={t(`${translationPath}Mobile`)}>
                        <CopyToClipboardComponents
                          data={activeData && activeData.contact_name && activeData.contact_name && activeData.contact_name.mobile}
                          childrenData={activeData && activeData.contact_name && activeData.contact_name && activeData.contact_name.mobile || ''}
                        /></Tooltip>) || 'N/A'
                    }
                  </div>
                )}
                {activeData && activeData.whatsAppMobile && (<div className='d-flex lead-id-area'>
                  <span className='texts gray-primary-bold'>
                    {t(`${translationPath}whatsAppMobile`)}
                    :
                  </span>
                  <span className='texts s-gray-primary mb-2' />
                  {activeData && activeData?.whatsAppMobile && (<Tooltip title={t(`${translationPath}whatsAppMobile`)}>
                    <CopyToClipboardComponents
                      data={activeData && activeData?.whatsAppMobile}
                      childrenData={activeData && activeData?.whatsAppMobile || ''}
                    />
                  </Tooltip>) || 'N/A'}
                </div>)}
              </div>
            )}
            {schedule && (
              <div className='slider-data'>
                <div className='priority-types mb-3'>
                  <div className='priority-type ml-2 mr-2'>
                    <div className='high-badge ml-2 mr-2' />
                    {t(`${translationPath}high`)}
                  </div>
                  <div className='priority-type ml-2 mr-2'>
                    <div className='medium-badge ml-2 mr-2' />
                    {t(`${translationPath}medium`)}
                  </div>
                  <div className='priority-type ml-2 mr-2'>
                    <div className='low-badge ml-2 mr-2' />
                    {t(`${translationPath}low`)}
                  </div>
                </div>
                <Calendar
                  selectedDateChanged={() => { }}
                  selectedDate={new Date()}
                  events={{ selectedDays: [] }}
                  wrapperClasses='transparent-calender w-100'
                />
                {/* <div className='calender-type px-2'>
                  <div className='calender-type-name'>
                    <div className='low-badge ml-2 mr-2' />
                    <div className='badge-info px-1'>
                      <div className='badge-name'>Meeting with Ahmad</div>
                      <div className='badge-time mt-1'>Today</div>
                    </div>
                  </div>
                  <div className='low-badge-time'>15:00</div>
                </div>
                <div className='pl-4-reversed pr-3-reversed mb-3'>
                  <span className='separator-h d-flex' />
                </div>

                <div className='calender-type px-2'>
                  <div className='calender-type-name'>
                    <div className='medium-badge ml-2 mr-2' />
                    <div className='badge-info px-1'>
                      <div className='badge-name'>Call Ahmad</div>
                      <div className='badge-time mt-1'>Today</div>
                    </div>
                  </div>
                  <div className='medium-badge-time'>18:00</div>
                </div>
                <div className='pl-4-reversed pr-3-reversed mb-3'>
                  <span className='separator-h d-flex' />
                </div> */}
              </div>
            )}
          </div>
        {  activitydetails && (

          
            <ActivityDetailsComponent
            data={activeData}
            translationPath={translationPath}
            parentTranslationPath='LeadsView'
            type={'lead'}
            cardId={activeData.id}
            />
          
          )}
          {
            (displyOpenFileButton || displyMatchButton || displyCloseLead) &&
            (
              <div className='side-menu-actions'>
                <div className='d-flex-center'>
                  {
                    displyOpenFileButton && (
                      <Button
                        className='btns theme-solid mx-2 mb-2'
                        onClick={cardDetailsActionClicked(ActionsEnum.folder.key, activeData)}
                      >
                        <span className='icons i-folder-white' />
                        <span className='mx-2'>{t(`${translationPath}open-file`)}</span>
                      </Button>
                    )
                  }
                  {(hide === false && displyCloseLead && (
                    activeData.status.lookupItemName === 'Open' ? (
                      <Button className='btns mx-2 mb-2' onClick={() => setIsOpenclosed(true)}>
                        <span className='mdi mdi-close-box-multiple-outline' />
                        <span className='mx-2'>{t(`${translationPath}UnqualifiedLeads`)}</span>
                      </Button>
                    ) : null
                  ))}
                </div>

              </div>
            )
          }
        </div>
      )}
      <DialogComponent
        isOpen={open}
        onCancelClicked={() => setopen(false)}
        translationPath={translationPath}
        parentTranslationPath='LeadsView'
        onCloseClicked={() => setopen(false)}
        titleText='ArchiveLeads'
        onSubmit={(e) => {
          e.preventDefault();
          archiveLeads();
        }}
        maxWidth='sm'
        dialogContent={<span>{t(`${translationPath}MassageLeadsproperty`)}</span>}
      />
      {
        addActivity && (
          <ActivitiesManagementDialog
            open={addActivity}
            onSave={() => {
              setAddActivity(false);
              setDataFile(true);
            }}
            close={() => {
              setAddActivity(false);
              setDataFile(true);
            }}
            actionItemId={activeData?.id}
            translationPath=''
            parentTranslationPath='LeadsProfileManagementView'
            leadClass={activeData?.leadClass}
            leadStageId={activeData?.lead_stage?.lookupItemId}
          />
        )
      }
      {
        isOpenclosed && (
          <UnqualifiedLeadsDialog
            isOpen={isOpenclosed}
           checkedCardsIds={[activeData.id]}
            onSave={(item) => {
              setIsOpenclosed(false);
              relodedata();
            }}
            onClose={() => {
              setIsOpenclosed(false);
            }}
          />
        )
      }
      {isConsentDialogOpen &&
        <ConsentRequestDialog
          open={isConsentDialogOpen}
          onSave={() => {
            setIsConsentDialogOpen(false);
            closeSideMenu();
          }}
          onClose={() => {
            setIsConsentDialogOpen(false);
            closeSideMenu();
          }}
          activeRecipient={activeData}
          recipientType={"Lead"}
        />
      }
       {searchDialog && (
        <SearchAndMatchDialog
          open={searchDialog}
          onClose={() => setSearchDialog(false)}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          activeData={activeData}
          operationType={activeData.operation_type?.lookupItemName?.toLowerCase() === 'buy' ? StaticLookupsIds.Sales : StaticLookupsIds.Rent}
        />
      )}
    </div>
  );
}

CardDetailsComponent.propTypes = {
  activeData: PropTypes.instanceOf(Object),
  cardDetailsActionClicked: PropTypes.func,
  displyOpenFileButton: PropTypes.bool,
  displyMatchButton: PropTypes.bool,
  displyCloseLead: PropTypes.bool,
};
CardDetailsComponent.defaultProps = {
  activeData: null,
  cardDetailsActionClicked: () => { },
  displyOpenFileButton: false,
  displyMatchButton: false,
  displyCloseLead: false,
};

export { CardDetailsComponent };
