import { createReducer } from '@reduxjs/toolkit';
import { TOGGLE_LAYOUT } from './Actions';

const initialState = {
  isNewLayout: false,
};

const Reducer = createReducer(initialState, {
  [TOGGLE_LAYOUT]: (state) => ({
    ...state,
    isNewLayout: !state.isNewLayout,
  })
});

export default Reducer;
