import { useSelector } from "react-redux";
import { useIsAr } from "./useIsAr";

export const useIsArabicLayout = () => {
  const theme = useSelector((store) => store.theme);

  const { isAr } = useIsAr(theme.themeDirection);

  return { isArabicLayout: isAr };
};
