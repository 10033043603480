export const ConsentSentByEnum = {
  Email: {
    id: 1,
    name: "Email",
  },
  SMS: {
    id: 2,
    name: "SMS",
  },
  WhatsApp: {
    id: 3,
    name: "WhatsApp",
  },
};
