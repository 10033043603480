import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { ButtonBase } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

export const PrintRepertDialogView = ({
  activeReport,
  translationPath,
  parentTranslationPath,
  LoaclFilter,
  onClose
}) => {
  const { t } = useTranslation(parentTranslationPath);
  const [ActiveButton, setActiveButton] = useState(false);
  const [state, setState] = useState({
    ActiveData: true,
    ActiveView: true,
    FilterSearchType: 'alldata',
    disabledFilterSeach: false
  });
  useEffect(() => {
    if (LoaclFilter !== null)
      localStorage.setItem('PrintRepertLoaclFilter', JSON.stringify((LoaclFilter)));
    else {
      localStorage.removeItem('PrintRepertLoaclFilter');
      setState({ ...state, disabledFilterSeach: true });
    }
  }, [LoaclFilter]);
  return (
    <div className='PrintRepertDialog-view'>
      <div className='outer-select-container'>
        <div className='outer-left'>
          <div className='inner-left'>
            {t(`${translationPath}Export`)}
          </div>
        </div>
        <div className='outer-left'>
          <div className='inner-left'>
            {t(`${translationPath}PDF`)}
          </div>
        </div>
      </div>

      <div>
        <div className='Active-btt-menu    pt-3'>
          <ButtonBase
            disabled={state && state.disabledFilterSeach}
            className={`menu-button ${ActiveButton === true ? 'is-active' : ''}`}
            onClick={() => {
              setActiveButton(true);
              setState({ ...state, ActiveData: true });
              setState({ ...state, FilterSearchType: 'likefilter' });
            }}
          >
            <span className='mdi mdi-filter-menu-outline' />
            {t(`${translationPath}like-filter-search`)}
          </ButtonBase>
          <ButtonBase
            className={`menu-button ${ActiveButton === false ? 'is-active' : ''}`}
            onClick={() => {
              setActiveButton(false);
              setState({ ...state, ActiveData: false });
              setState({ ...state, FilterSearchType: 'alldata' });
            }}
          >
            <span className='mdi mdi-filter-off-outline' />
            {t(`${translationPath}all-data`)}
          </ButtonBase>
        </div>
        <div className='Active-btt-menu    pt-3'>
          <ButtonBase
            className={`menu-button ${state.ActiveView === true ? 'is-active' : ''}`}
            onClick={() => {
              setState({ ...state, ActiveView: true });
            }}
          >
            <span className='mdi mdi-card-bulleted-outline' />
            {t(`${translationPath}card-view`)}
          </ButtonBase>
          {/* <ButtonBase
            className={`menu-button ${state.ActiveView === false ? 'is-active' : ''}`}
            onClick={() => {
                 setState({ ...state, ActiveView: false });
              }}
          >
            <span className='mdi mdi-table-large' />
             {t(`${translationPath}tabel-view`)}
          </ButtonBase> */}
        </div>
      </div>
      <div className='MuiDialogActions-root MuiDialogActions-spacing'>
        <div className='MuiDialogActions-root dialog-footer-wrapper  MuiDialogActions-spacing'>
          <div className='save-cancel-wrapper d-flex-v-center-h-end flex-wrap p-2'>
            <div className='cancel-wrapper d-inline-flex-center'>
              <ButtonBase onClick={() => onClose()} className='MuiButtonBase-root MuiButtonBase-root MuiButtonBase-root MuiButton-root MuiButton-text btns c-danger' tabIndex='0' type='button'>
                <span className='MuiButton-label'>
                  <span>
                    {' '}
                    {t(`${translationPath}cancel`)}
                    {' '}
                  </span>
                </span>
                <span className='MuiTouchRipple-root' />
                <span className='MuiTouchRipple-root' />
              </ButtonBase>
            </div>
            <div className='save-wrapper d-inline-flex-center'>
              <a
                href={(`/share/ReportPdfView?activeReport=${activeReport && activeReport.endpointName}&ReportType=${state && state.FilterSearchType}`)}
                target='_blank'
                rel='noreferrer'
              >
                <ButtonBase className='MuiButtonBase-root MuiButtonBase-root MuiButton-root MuiButton-text save-btn-wrapper btns theme-solid bg-primary w-100 mx-2 mb-2' tabIndex='0' type='button'>
                  <span className='MuiButton-label'>
                    <span>
                      {' '}
                      {t(`${translationPath}Continue`)}
                      {' '}
                    </span>
                  </span>
                  <span className='MuiTouchRipple-root' />
                  <span className='MuiTouchRipple-root' />
                </ButtonBase>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default PrintRepertDialogView;
