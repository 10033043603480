import { ContactsMergingHistoryMapperDto } from "./ContactsMergingHistoryMapperDto";

export const ContactsMergingHistoryMapper = (mergedContacts) => {
  const mappedData = extractValues(
    mergedContacts.contact,
    ContactsMergingHistoryMapperDto
  );
  return mappedData;
};

function extractValues(data, keyArray) {
  const result = {};
  keyArray.forEach((keyItem) => {
    const { formFieldName, displayPath } = keyItem;
    if (displayPath) {
      const keys = displayPath.split(".");
      let value = data;
      for (const key of keys) {
        if (value && value.hasOwnProperty(key)) {
          value = value[key];
        } else {
          value = undefined;
          break;
        }
      }
      result[
        formFieldName
          .replace("_", " ")
          .replace("_", " ")
          .replace("_", " ")
          .replace("_", " ")
      ] = value;
    }
  });
  return result;
}
