
import React, { useState }  from 'react';
import { useTranslation } from 'react-i18next';
import Rating from '@material-ui/lab/Rating';

  export const PodiumParking = ({
    parentTranslationPath ,
    translationPath ,
    onChangeSelectedItem , 
    selectedValues ,
    setSelectValues ,
   } ) => { 

    const { t } = useTranslation(parentTranslationPath);
    
    return (
      <>
        <div className="">
        <span className="fw-bold"> {t(`${translationPath}podium-parking`)}</span>
        </div> 
        <div className='w-75 parking-distribution'>
        <div className="d-flex d-flex-v-center parking-distribution-card mt-2 mb-2">
            <div>
            </div>
            <div>
            <Rating  
                size="large"
                max={10}
                value={(selectedValues &&  selectedValues.podiumParking && selectedValues.podiumParking.score) || 0}
                precision={1}
                onChange={(event, newValue) => 
                {
                  onChangeSelectedItem((selectedValues.podiumParking?.lookupItemName) || 'Podium Parking' , newValue ||0 );
                  const newUpdateObj = selectedValues.podiumParking ? {...selectedValues.podiumParking , score : (newValue || 0 ) } : null
                  setSelectValues((item)=> ({...item , podiumParking : newUpdateObj }))
                  
                }}
                />     
            </div>
         </div>  
        </div>
      </>
    );
  } ; 
  
