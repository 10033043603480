import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { Box } from "@material-ui/core";
import { ContactItemList, InfoItem, InfoSection } from "..";
import { useSelectedTheme, useTranslate } from "../../../../../Hooks";

// Icons
import { EmailIcon, PhoneIcon, SMSIcon, WhatsappIcon } from "../../../../../assets/icons";

// Styles
import useStyles from "./styles";

function ContactProfileDetails({ activeItem }) {
  const {
    theme: { palette },
  } = useSelectedTheme();

  const { translate } = useTranslate("NewContactsView");

  const styles = useStyles();

  return (
    <>
      <InfoSection title={translate("CONTACT_INFORMATION")}>
        <InfoItem
          label={translate("NATIONALITY")}
          value={activeItem?.details?.find((item) => item.title === 'nationality')?.value}
        />
        <InfoItem
          label={translate("LANGUAGE")}
          value={activeItem?.language?.lookupItemName || "N/A"}
        />
        <InfoItem
          label={translate("COUNTRY")}
          value={activeItem?.country?.lookupItemName || "N/A"}
          countryCode={activeItem?.country?.lookupItemCode}
        />
        <InfoItem
          label={translate("CITY")}
          value={activeItem?.city?.lookupItemName || "N/A"}
        />
        <InfoItem
          label={translate("COMMUNITY")}
          value={activeItem?.community?.lookupItemName || "N/A"}
        />
        <InfoItem
          label={translate("SUB_COMMUNITY")}
          value={
            activeItem?.allDetails?.["Residence Address"]?.find(
              (activeItem) => activeItem?.title === "sub_community"
            )?.value
          }
        />
        <InfoItem
          label={translate("DATE_OF_BIRTH")}
          value={
            activeItem?.date_of_birth ? moment(activeItem?.date_of_birth).format("DD-MM-YYYY") : "N/A"
          }
        />
        <InfoItem
          label={translate("CREATED_BY")}
          value={activeItem?.createdBy}
        />
        <InfoItem
          label={translate("CREATED_DATE")}
          value={moment(activeItem?.createdOn).format("DD-MM-YYYY")}
        />
      </InfoSection>

      <InfoSection title={translate("CONTACTS")}>
        <ContactItemList
          labelIcon={
            <Box className={styles.iconWrapper}>
              <PhoneIcon
                width="20"
                height="20"
                fill={palette.utility.utility_gray_500}
              />
            </Box>
          }
          label={translate("PHONE")}
          values={[
            activeItem?.mobile?.phone ||
              activeItem?.landline_number?.phone ||
              "",
            ...(activeItem?.other_contact_mobile_no?.others ||
              activeItem?.landline_number?.others ||
              []),
          ].filter((item) => !!item)}
        />
        <ContactItemList
          labelIcon={
            <Box className={styles.iconWrapper}>
              <EmailIcon
                width="20"
                height="20"
                fill={palette.utility.utility_gray_500}
              />
            </Box>
          }
          label={translate("EMAIL")}
          values={[
            activeItem?.email_address?.email ||
              activeItem?.general_email?.email ||
              "",
            ...(activeItem?.general_email?.others ||
              activeItem?.email_address?.others ||
              []),
          ].filter((item) => !!item)}
        />
        <ContactItemList
          labelIcon={
            <Box className={styles.iconWrapper}>
              <WhatsappIcon
                width="20"
                height="20"
                fill={palette.utility.utility_gray_500}
              />
            </Box>
          }
          label={translate("WHATSAPP")}
          values={[
            activeItem?.whatsapp_mobile?.phone || "",
            ...(activeItem?.whatsapp_mobile?.others || []),
          ].filter((item) => !!item)}
        />
        <ContactItemList
          labelIcon={
            <Box className={styles.iconWrapper}>
              <SMSIcon
                width="20"
                height="20"
                fill={palette.utility.utility_gray_500}
              />
            </Box>
          }
          label={translate("SMS")}
          values={[
            activeItem?.mobile?.phone ||
              activeItem?.landline_number?.phone ||
              "",
            ...(activeItem?.other_contact_mobile_no?.others ||
              activeItem?.landline_number?.others ||
              []),
          ].filter((item) => !!item)}
        />
      </InfoSection>
    </>
  );
}

ContactProfileDetails.propTypes = {
  activeItem: PropTypes.objectOf(PropTypes.any),
};

ContactProfileDetails.defaultProps = {
  activeItem: {},
};

export default ContactProfileDetails;
