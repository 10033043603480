import React from 'react';
import { useLayoutDetection } from '../../Hooks';
import { NewHomeLayout } from './NewHomeLayout';
import { OldHomeLayout } from './OldHomeLayout';

const HomeLayout = () => {
  const { isNewLayout, hasNewLayout } = useLayoutDetection();

  const layout = (isNewLayout && hasNewLayout) ? <NewHomeLayout /> : <OldHomeLayout />;

  return layout;
};

export { HomeLayout };
