import React, {
    useState,
  } from "react";
  import { useTranslation } from "react-i18next";
  import { AutocompleteComponent } from "../../../../Components";
  import { GetLookupItemsByLookupTypeName } from '../../../../Services' ; 

  const parentTranslationPath = "UnitsView";
    const translationPath = "";
  
  export const CompletionStatusFilter = ({  setResaleFilters  , resaleFilters , resaleChangeFilters ,
    selected , 
    setSelected ,

  }) => {
    const { t } = useTranslation(parentTranslationPath);

    const [allCompletionStatus, setAllCompletionStatus] = useState([]);

    const getUnitTypeLookups = async () => {
      const res = await GetLookupItemsByLookupTypeName({
        lookUpName: "Property Plan",
        pageSize: 100,
        pageIndex: 1,
      });
      if (!(res && res.status && res.status !== 200)) {
        setAllCompletionStatus(res.result || []);
      } else setAllCompletionStatus([]);
    };

    return (
        <div className="w-50 m-1">
          <AutocompleteComponent
            idRef="completion-status-Ref"
            wrapperClasses="w-min-unset m-2 mr-1"
            inputPlaceholder={t(`${translationPath}completion-status`)}
            labelValue={t(`${translationPath}completion-status`)}
            selectedValues={selected.completionStatus}
            data={allCompletionStatus || []}
            displayLabel={(option) =>  (option && option.lookupItemName) || '' }
            multiple={false}
            withoutSearchButton
            onChange={(e, newValue) => 
            { 
              resaleChangeFilters('PropertyPlanId' , (newValue && newValue.lookupItemId));
              setSelected({id :'completionStatus'  , value : newValue })

             
            }}
            onOpen={() => {
              if (allCompletionStatus &&  allCompletionStatus.length == 0)
                getUnitTypeLookups();
            }}
          />
          </div>
    );
  };
  