import React from "react";

function FilterLessThan({ fill, ...restProps }) {
  return (
    <svg
      viewBox="0 0 16 16"
      fill={fill ?? ""}
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <path
        d="M8.933 2.023a1.174 1.174 0 0 0-.147.064c-.043.024-.723.688-1.51 1.478-1.544 1.548-1.489 1.483-1.489 1.768 0 .286-.055.221 1.489 1.769.818.821 1.474 1.458 1.532 1.488a.63.63 0 0 0 .756-.122c.159-.155.242-.369.21-.544-.047-.254-.089-.302-1.248-1.464L7.401 5.333l1.125-1.126c1.159-1.162 1.201-1.211 1.248-1.465.042-.23-.133-.546-.37-.667-.116-.059-.365-.086-.471-.052M4.447 10.04a.718.718 0 0 0-.362.304c-.061.104-.072.153-.072.323 0 .171.011.218.075.327a.72.72 0 0 0 .394.313c.066.018 1.217.025 3.349.02l3.249-.007.11-.059c.377-.201.478-.7.207-1.017a.885.885 0 0 0-.207-.172l-.11-.059-3.267-.005c-2.725-.004-3.283.001-3.366.032m0 2.667a.718.718 0 0 0-.362.304c-.061.104-.072.153-.072.322 0 .172.011.219.075.328a.724.724 0 0 0 .394.313c.066.018 1.217.024 3.349.02l3.249-.007.11-.059c.377-.202.478-.701.207-1.017a.885.885 0 0 0-.207-.172l-.11-.059-3.267-.005c-2.725-.004-3.283.001-3.366.032"
        fill={fill ?? ""}
      />
    </svg>
  );
}

export default FilterLessThan;
