import React, { useEffect, useState } from 'react';
import { useLocalStorage } from '../../../../../../Hooks';
import { Spinner } from '../../../../../../Components';
import UsersViewer from './components/UsersViewer';
import { useTranslation } from 'react-i18next';
import FieldsViewer from './components/FieldsViewer';
import NewValuesViewer from './components/NewValuesViewer';
import {
  bottomBoxComponentUpdate,
  GlobalHistory,
  showError,
  showSuccess,
  sideMenuComponentUpdate,
  sideMenuIsOpenUpdate,
} from '../../../../../../Helper';
import { fields } from './fields';
import { BulkUpdateUsers } from '../../../../../../Services/userServices';
import { ButtonBase } from '@material-ui/core';

const parentTranslationPath = 'UsersView';
function UsersBulkUpdate() {
  const { t } = useTranslation(parentTranslationPath);
  const [users] = useLocalStorage('bulk-update-users');
  const [selectedUsers, setSelectedUsers] = useState(users);
  const [isLoading, setIsLoading] = useState(false);
  const [formFieldIds, setFormFieldIds] = useState([]);
  const [userDetails, setUserDetails] = useState({});

  const [userStatus, setUserStatus] = useState({
    failure: [],
    success: [],
  });
  const selectUserHandler = (user) => {
    setSelectedUsers((prevUsers) => prevUsers.concat(user));
  };
  const deselectUserHandler = (user) => {
    setSelectedUsers((prevUsers) => prevUsers.filter((u) => u.id !== user.id));
  };

  const saveHandler = async () => {
    try {
      setIsLoading(true);
      const body = {
        userAreaOfFocusCountries: userDetails.country?.map((country) => ({
          countryId: country.id,
        })),
        userAreaOfFocusCities: userDetails.city?.map((city) => ({
          cityId: city.id,
        })),
        userAreaOfFocusCommunities: userDetails.community?.map((community) => ({
          communityId: community.id,
        })),
        userAreaOfFocusSubCommunities: userDetails.subCommunity?.map(
          (subCommunity) => ({
            subCommunityId: subCommunity.id,
          })
        ),
        userAreaOfFocusDistricts: userDetails.district?.map((district) => ({
          districtId: district.id,
        })),
        userLanguages: userDetails.languages?.map((language) => ({
          languageId: language.id,
        })),
        userDevelopers: userDetails.developers?.map((developer) => ({
          developerId: developer.id,
        })),
        userRoles: userDetails.roles
          ?.map((role) => {
            return users.map((user) => ({
              usersId: user.id,
              rolesId: role.id,
            }));
          })
          .flat(),
      };

      delete userDetails.country;
      delete userDetails.city;
      delete userDetails.community;
      delete userDetails.subCommunity;
      delete userDetails.district;
      delete userDetails.languages;
      delete userDetails.developers;
      delete userDetails.roles;

      fields.forEach((value) => {
        if (userDetails[value.fieldKey]) {
          if (value.returnKey) {
            body[value.fieldKey] = userDetails[value.fieldKey][value.returnKey];
          } else {
            body[value.fieldKey] = userDetails[value.fieldKey];
          }
        }
      });
      await BulkUpdateUsers({
        bulkUpdateUserProfileDto: body,
        userIds: users.map((user) => ({ userId: user.id })),
      });
      showSuccess(t('updated-successfully'));
      GlobalHistory.goBack();
    } catch (error) {
      showError(t('something-went-wrong'));
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    bottomBoxComponentUpdate(
      <div className='bulk-actions-wrapper w-100'>
        <ButtonBase
          className='btns c-danger'
          onClick={() => GlobalHistory.goBack()}
        >
          <span>{t(`cancel`)}</span>
        </ButtonBase>
        <ButtonBase className='btns theme-solid' onClick={saveHandler}>
          <span className='px-3'>
            {userStatus.failure.length > 0
              ? t(`update-failed-users`)
              : t(`save-changes`)}
          </span>
        </ButtonBase>
      </div>
    );
  });
  useEffect(
    () => () => {
      bottomBoxComponentUpdate(null);
      sideMenuComponentUpdate(null);
      sideMenuIsOpenUpdate(false);
    },
    []
  );
  return (
    <div className='px-5 units-bulk-assign-view-wrapper'>
      <Spinner isActive={isLoading} isAbsolute />
      <div className='bulk-section-wrapper'>
        <UsersViewer
          users={users}
          parentTranslationPath={parentTranslationPath}
          userStatus={userStatus}
          selectedUsers={selectedUsers}
          selectUserHandler={selectUserHandler}
          deselectUserHandler={deselectUserHandler}
        />
      </div>
      <span className='section-seperator mdi mdi-arrow-collapse-right c-gray' />
      <div className='bulk-section-wrapper'>
        <FieldsViewer
          parentTranslationPath={parentTranslationPath}
          formFields={fields}
          formFieldIds={formFieldIds}
          setFormFieldIds={setFormFieldIds}
          userDetails={userDetails}
          setUserDetails={setUserDetails}
        />
      </div>
      <span className='section-seperator mdi mdi-arrow-collapse-right c-gray' />
      <div className='bulk-section-wrapper'>
        <NewValuesViewer
          formData={formFieldIds}
          userDetails={userDetails}
          setUserDetails={setUserDetails}
          parentTranslationPath={parentTranslationPath}
        />
      </div>
    </div>
  );
}

export default UsersBulkUpdate;
