import React, { useState, useCallback, useEffect } from "react";
import { PropTypes } from "prop-types";
import { ButtonBase } from "@material-ui/core";
import { TableActions } from "../../../../Enums/TableActions.Enum";
import {
  AutocompleteComponent,
  DialogComponent,
  Tables,
} from "../../../../Components";
import { showError, showSuccess } from "../../../../Helper";
import { ListShareUnitAccesseses, PauseMultiShareUnits, PauseShareUnits } from "../../../../Services";
import { useTranslation } from "react-i18next";

const translationPath = "";
export const PauseShareUnitsComponent = ({
  data,
  isDialogOpen,
  setisDialogOpen,
  parentTranslationPath,
  translationPath,
  onsave,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [openHistory, setOpenHistory] = useState(false);
  const [activityHistory, setActivityHistory] = useState();
  // const [activeItem, setActiveItem] = useState(null);
  const [openReply, setOpenReply] = useState(false);
  const [edit, setisEdit] = useState(false);
  const { t } = useTranslation(parentTranslationPath);
  const [dataUser, setDataUser] = useState(null);
  const [Datatemplte, setDatatemplte] = useState([]);
  const [SelectedTemplate, setSelectedTemplte] = useState([]);

  const GetListShareUnitAccesseses = async (unitId, unitOperationSource) => {
    const res = await ListShareUnitAccesseses({ unitId, unitOperationSource });
    if (!(res && res.status && res.status !== 200)) {
      setDatatemplte(res);
      setSelectedTemplte(res);
    } else setDatatemplte([]);
  };

  const DeleteSavedShareUnits = useCallback(async (Alldata, SelectedTemplate) => {
    const FinalDto = Alldata.map((item) => (
      {
        "unitId": item.unitId,
        "targetSystemId": item.targetSystemId,
        "unitOperationSource": item.unitOperationSource,
      }
  ));
    const res = await PauseMultiShareUnits(FinalDto);
    if (!(res && res.status && res.status !== 200)) {
      showSuccess(t("Deleted-Successfully"));
      onsave();
    } else {
      showError(t("Deleting-Has-Failed"));
    }
  }, []);


  // const DeleteSavedShareUnits = useCallback(async (data, SelectedTemplate) => {
  //   const FinalDto = SelectedTemplate.map((item) => ({
  //     unitId: data.unitId,
  //     userId: item.user,
  //     branchId: item.branch,
  //     unitOperationSource: data.unitOperationSource,
  //   }));

  //   const res = await PauseShareUnits(FinalDto);
  //   if (!(res && res.status && res.status !== 200)) {
  //     showSuccess(t("Deleted-Successfully"));
  //     onsave();
  //   } else {
  //     showError(t("Deleting-Has-Failed"));
  //   }
  // }, []);



  // useEffect(() => {
  //   GetListShareUnitAccesseses(data.unitId, data.unitOperationSource);
  // }, [data]);

  return (
    <div>
      <DialogComponent
        titleTextClasses={"release-confirm-text"}
        titleText=""
        saveText="confirm"
        saveType="button"
        maxWidth="sm"
        dialogContent={
          <div className="d-flex-column-center">
            <span className="mdi mdi-close-octagon c-danger mdi-48px" />
            <span>{t("Are-You-Sure-You-Want-To-Delete-Slected-user")}</span>
            {data.map((item) => (
                  <div className="fw-bold">
                    {item.unitName || item.property_name || ""}
                  </div>
                ))}
            {/* <AutocompleteComponent
              idRef="tempSlected-uselteRef"
              // labelValue={t(`${translationPath}Template`)}
              // inputPlaceholder={t(`${translationPath}Slect-Template`)}
              selectedValues={SelectedTemplate}
              data={Datatemplte || []}
              getOptionSelected={(option) =>
                SelectedTemplate &&
                SelectedTemplate.findIndex(
                  (item) =>
                    item && item.shareUnitAccessId === option.shareUnitAccessId
                ) !== -1
              }
              chipsLabel={(option) =>
                option.sharedByName || option.branch
                  ? `${option.sharedByName || option.branch} (${
                      option.shareUnitAccessId
                    })`
                  : ""
              }
              displayLabel={(option) =>
                option.sharedByName || option.branch
                  ? `${option.sharedByName || option.branch} (${
                      option.shareUnitAccessId
                    })`
                  : ""
              }
              renderOption={(option) =>
                option.sharedByName || option.branch
                  ? `${option.sharedByName || option.branch} (${
                      option.shareUnitAccessId
                    })`
                  : ""
              }
              multiple
              withoutSearchButton
              onChange={(event, newValue) => {
                const Var = newValue && newValue.map((item) => item);
                setSelectedTemplte(Var || []);
              }}
            /> */}
          </div>
        }
        saveClasses="btns theme-solid bg-danger w-100 mx-2 mb-2"
        isOpen={isDialogOpen}
        onSaveClicked={() => DeleteSavedShareUnits(data, SelectedTemplate)}
        onCloseClicked={() => {
          setisDialogOpen(false);
        }}
        onCancelClicked={() => {
          setisDialogOpen(false);
        }}
      />
    </div>
  );
};

PauseShareUnitsComponent.propTypes = {
  data: PropTypes.shape({
    result: PropTypes.instanceOf(Array),
    totalCount: PropTypes.number,
  }).isRequired,
  filter: PropTypes.instanceOf(Object).isRequired,
};
