import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => {
  return {
    footer: {
      background: "transparent",
      borderTop: `1px solid ${theme.palette.border.secondary}`,
      padding: "16px 32px",
      color: theme.palette.text.placeholder,
      fontSize: "14px",
      lineHeight: "20px",
      fontWeight: 500,
    },
  };
});
