import React, { useEffect, useState } from 'react';
import { DialogComponent } from '../../../../../../Components';
import { useTranslation } from 'react-i18next';
import {
  UnitsOperationTypeEnum,
  UnitsStatusEnum,
} from '../../../../../../Enums';
import { CreateBulkUnitRequestToPublishOrUnPublish } from '../../../../../../Services';
import { showError, showSuccess } from '../../../../../../Helper';

export const FieldsNeedsApprovalDialogBulkUnits = ({
  parentTranslationPath,
  translationPath,
  isDialogOpen,
  setIsDialogOpen,
  checkedCards,
  reloadData,
  onUnitsBulkCloseDialogHandler,
  unitsIdUpdateArray,
  initialData,
  reload,
  unitCards,
  isUpdatedState,
}) => {
  const { t } = useTranslation(parentTranslationPath);
  const [originalValues, setOriginalValues] = useState([]);
  const isLease =
    window.location.pathname === '/home/units-lease/unit-bulk-update';

  const updatedFieldDataStatus = unitsIdUpdateArray?.map((unitId) => ({
    unitId,
    status: UnitsStatusEnum.Available.key,
    note: '',
    OperationType: isLease
      ? UnitsOperationTypeEnum.rent.key
      : UnitsOperationTypeEnum.sale.key,
  }));

  const originalFieldDateStatus = unitsIdUpdateArray?.map((unitId) => ({
    unitId,
    status: UnitsStatusEnum.Draft.key,
    note: '',
    OperationType: isLease
      ? UnitsOperationTypeEnum.rent.key
      : UnitsOperationTypeEnum.sale.key,
  }));

  const updatedUnitJson = checkedCards?.map((unit) => {
    const originalUnit = originalValues?.find((ov) => ov.id === unit.id);
    // Create a new object without the id field
    const { id, ...originalUnitWithoutId } = originalUnit || {};

    // Replace null values with undefined in initialData
    const transformedInitialData = initialData
      ? Object.fromEntries(
          Object.entries(initialData).map(([key, value]) => {
            if (key === 'builtup_area_sqft') {
              const builtupAreaSqFtValue = value;
              initialData.builtup_area_sqm = builtupAreaSqFtValue
                ? parseFloat((0.09290303997 * builtupAreaSqFtValue).toFixed(2))
                : 0;
            }
            return [key, value === null ? undefined : value];
          })
        )
      : {};

    return {
      unitId: unit.id,
      updatedFieldsData: isUpdatedState
        ? {
            Status: updatedFieldDataStatus?.find(
              (field) => field.unitId === unit.id
            ),
          }
        : transformedInitialData,
      marketingData: { isForLease: isLease },
      originalUnitFields: isUpdatedState
        ? {
            Status: originalFieldDateStatus?.find(
              (filed) => filed.unitId === unit.id
            ),
          }
        : originalUnitWithoutId,
    };
  });
  const handleCreateUnitRequestToPublishOrUnPublish = async () => {
    const body = {
      typeOfRequest: 3,
      unitMarketingRequests: [],
      updatedUnitJson,
    };
    const res = await CreateBulkUnitRequestToPublishOrUnPublish(body);
    if (!(res && res.status && res.status !== 200)) {
      showSuccess(t(`${translationPath}create-request-massage`));
      if (reload) reload();
      if (reloadData) reloadData();
      if (onUnitsBulkCloseDialogHandler) onUnitsBulkCloseDialogHandler(false);
      setIsDialogOpen(false);
      return res;
    } else {
      showError(
        t(`${translationPath}cannot-set-unit-as-published-without-price`)
      );
      setIsDialogOpen(false);
    }
  };
  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };

  useEffect(() => {
    if (unitCards && initialData) {
      const originalValues = unitCards.map((card) => {
        const validFields = Object.entries(initialData)
          .filter(([key, value]) => !!value)
          .map(([key]) => key);

        const combinedObject = validFields.reduce((acc, key) => {
          acc[key] = card[key] !== undefined ? card[key] : null;
          if (key === 'builtup_area_sqft' && initialData.builtup_area_sqft) {
            acc.builtup_area_sqm =
              card.builtup_area_sqm !== undefined ? card.builtup_area_sqm : 0;
          }
          return acc;
        }, {});

        return { ...combinedObject, id: card.id };
      });
      setOriginalValues(originalValues);
    }
  }, [unitCards, initialData]);

  return (
    <>
      <DialogComponent
        translationPath={translationPath}
        parentTranslationPath={parentTranslationPath}
        isOpen={isDialogOpen}
        titleText={'Approval Required'}
        maxWidth={'sm'}
        saveType='button'
        saveClasses='btns theme-solid w-100 mx-2 mb-2'
        onSaveClicked={handleCreateUnitRequestToPublishOrUnPublish}
        onCancelClicked={handleCloseDialog}
        onCloseClicked={handleCloseDialog}
        dialogContent={
          <div>
            <p>{t(`${translationPath}approval-message`)}</p>
          </div>
        }
      />
    </>
  );
};
