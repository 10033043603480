import React, { useCallback, useMemo } from 'react';
import { LoadableImageComponant } from '../../../../../Components';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { getDownloadableLink } from '../../../../../Helper';
import { CopyToClipboardComponents } from '../../../../../ReusableComponents/UtilityComponents';
import { ButtonBase, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ContactTypeEnum, LoadableImageEnum } from '../../../../../Enums';
import { useTranslation } from 'react-i18next';
import './UnitsByProperty.scss';

const useStyles = makeStyles((theme) => ({
  main: {
    position: 'relative',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    margin: 'auto',
    alignItems: 'center',
    overflowY: 'auto',
    gap: '20px',
    maxHeight: '65vh',
  },
  title: {
    textAlign: 'center',
  },
  root: {
    display: 'flex',
    height: 'fit-content',
    overflow: 'visible',
    flexDirection: 'column',
    width: '95%',
    padding: '0 5px',
    maxWidth: 300,
  },
  details: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    padding: '0px 10px',
  },
  content: {
    paddingBottom: '16px !important',
  },
  cover: {
    width: 151,
    height: 50,
  },
  controls: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  playIcon: {
    height: 38,
    width: 38,
    margin: 'auto',
  },
  flatContentsWrapper: {
    display: 'flex',
    margin: 'auto',
    justifyContent: 'center',
    justifyItems: 'center',
    marginBottom: '10px',
    gap: '5px',
  },
  flatContentsItem: {
    backgroundColor: '#f2f2f7',
    borderRadius: '100px',
    padding: 2,
  },
  paginationContainer: {
    display: 'flex',
    justifyContent: 'center',
    position: 'absolute',
    bottom: 0,
  },
}));
const UnitCard = ({
  item,
  onRemoveCard,
  onSelectCard,
  selectedCards,
  parentTranslationPath,
  translationPath,
  isLease,
}) => {
  const { t } = useTranslation(parentTranslationPath);
  const classes = useStyles();

  const getDefaultUnitImage = useCallback(
    (unitType) => ContactTypeEnum[unitType]?.defaultImg,
    []
  );
  const projectUnit = ({
    id,
    allunitImages,
    refNo,
    unitStatus,
    flatContent,
    unitTypeId,
    operation_type,
    type,
  }) => ({
    id,
    allunitImages,
    refNo,
    unitStatus,
    flatContent,
    unitTypeId,
    operation_type,
    type,
  });
  const isSelected = useMemo(
    () => !!selectedCards.find((unit) => unit.id === item?.id),
    [selectedCards, item]
  );
  const projectedUnit = projectUnit(item);
  return (
    <Card className={classes.root}>
      <div className={classes.details}>
        <input
          type='checkbox'
          id={item.id}
          checked={isSelected}
          onChange={() =>
            isSelected ? onRemoveCard(item.id) : onSelectCard(projectedUnit)
          }
        />
        <LoadableImageComponant
          classes={classes.playIcon}
          type={LoadableImageEnum.image.key}
          alt={'any'}
          src={
            (item.allunitImages &&
              getDownloadableLink(item.allunitImages.fileId, 200, true)) ||
            getDefaultUnitImage(item.type)
          }
        />
        <CardContent className={classes.content}>
          <div className='contact-id-wrapper'>
            {t(`${translationPath}ref-#`)}:
            <Tooltip title={t(`${translationPath}copy`)}>
              <CopyToClipboardComponents
                data={item.refNo}
                childrenData={item.refNo}
              />
            </Tooltip>
          </div>
        </CardContent>
      </div>
      <div className='body-image-item-wrapper'>
        <p className='body-title-wrapper'>
          <span
            className={`body-status ${
              item.unitStatus?.classes || 'bg-warning'
            }`}
          >
            <span className='body-status-type'>
              {t(`${translationPath}status`)}
            </span>
            {' : '}
            {(item.unitStatus?.value &&
              t(
                `${translationPath}${
                  item.unitStatus.value === 'sale'
                    ? 'sold'
                    : item.unitStatus.value
                }`
              )) ||
              'N/A'}
          </span>
        </p>
      </div>
      <div className={classes.flatContentsWrapper}>
        {item.flatContent.map((subItem, subIndex) => (
          <div
            key={`flatContentsItemRef${subIndex + 1}`}
            className={classes.flatContentsItem}
          >
            <span
              className={`flat-content-icon ${subItem.iconClasses} mdi-18px`}
            />
            <span className='px-1'>
              {subItem.value === 'Any' ? '0' : subItem.value}
            </span>
            <span>
              {subItem.title && (
                <span className='flat-content-text'>
                  {t(`${translationPath}${subItem.title}`)}
                </span>
              )}
            </span>
          </div>
        ))}
      </div>
      <ButtonBase
        className='btns theme-transparent mx-0 maxWidth_100'
        onClick={() =>
          window.open(
            `/home/units-${
              isLease ? 'lease' : 'sales'
            }/unit-profile-edit?formType=${item.unitTypeId}&id=${
              item.id
            }&operationType=${item.operation_type?.lookupItemId}`
          )
        }
      >
        <span className='mdi mdi-folder-outline' />
        <span className='px-1'>{t('open-file')}</span>
      </ButtonBase>
    </Card>
  );
};

export default UnitCard;
