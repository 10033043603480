import { DescriptionsView, WebPortal, PublishedImages } from '../Sections';
import { UnitsSalesPermissions } from '../../../../../../../Permissions';
import { MPIView } from '../Sections/MPI';

export const UnitProfileMarketingTabsData = [
  { label: 'descriptions', component: DescriptionsView },
  {
    label: 'web-portal',
    component: WebPortal,
    permissionsList: Object.values(UnitsSalesPermissions),
    permissionsId: UnitsSalesPermissions.ShowWebPortals.permissionsId,
  },
  {
    label: 'MPI',
    component: MPIView,
    permissionsList: Object.values(UnitsSalesPermissions),
    permissionsId: UnitsSalesPermissions.ViewUnitMPI.permissionsId,
  },
  { label: 'published-images', component: PublishedImages },
];
