import {
  PropertyMarketingLuxury,
  PropertyMarketingStyle,
  PropertyMarketingFinance,
  PropertyMarketingRemarks,
  PropertyMarketingViews,
  PropertyMarketingCampaigns,
  PropertyMarketingOffers
} from '../Sections';

export const PropertiesProfileMarketingTabsData = [
  { label: 'remarks', component: PropertyMarketingRemarks },
  { label: 'offers', component: PropertyMarketingOffers },
  { label: 'luxury', component: PropertyMarketingLuxury },
  { label: 'finance-usp', component: PropertyMarketingFinance },
  { label: 'style', component: PropertyMarketingStyle },
  { label: 'views', component: PropertyMarketingViews },
  { label: 'campaigns', component: PropertyMarketingCampaigns },
];
