import React from 'react';

export const LeadsUnitsTableHeader = [
  {
    id: 1,
    label: 'Id',
    fieldKey: 'unitId',
    input: 'unitId',
    isSortable: true,
    isHiddenFilter: true,
    isNumber: true,
    component: (item) => (item && item.id) || <span />
  },
  {
    id: 2,
    label: 'Name',
    input: 'UnitName',
    isHiddenFilter: false,
    isDefaultFilterColumn: true,
    isSortable: true,
    component: (item) => (item && item.name) || <span />
  },
  {
    id: 3,
    label: 'Created By',
    input: 'createdBy',
    fieldKey: 'createdBy',
    isHiddenFilter: false,
    isDefaultFilterColumn: true,
    isSortable: true,
  },
  {
    id: 4,
    label: 'Creation On',
    input: 'creationDate',
    fieldKey: 'createdOn',
    isHiddenFilter: false,
    isDate: true,
    isSortable: true,
  },
  {
    id: 5,
    label: 'Updated By',
    input: 'updatedBy',
    isHiddenFilter: false,
    isDefaultFilterColumn: true,
    isSortable: true,
  },
  {
    id: 6,
    label: 'Progress',
    input: 'data_completed',
    textInputType: 'number',
    fieldKey: 'data_completed',
    key: 'data_completed',
    textInputMax: 100,
    textInputMin: 0,
    isHiddenFilter: true,
    isDefaultFilterColumn: true,
    isSortable: true,
    isNumber: true,
    component: (item) => (item && item.progressWithPercentage) || <span />,
  },
  {
    id: 7,
    label: 'Status',
    isHiddenFilter: false,
    isDefaultFilterColumn: true,
    input: 'StringStatus',
    isSortable: true,
    component: (item) => (item && item.unitStatus && item.unitStatus.name) || <span />,
  },
  {
    id: 8,
    label: 'Status Date',
    isDefaultFilterColumn: true,
    input: 'StatusDate',
    isHiddenFilter: false,
    isDate: true,
    isSortable: true,
    component: (item) => (item && item.statusDate) || <span />,
  },
  {
    id: 9,
    label: 'Updated Owner Date',
    isDefaultFilterColumn: true,
    input: 'LastUpdatedOwner',
    isHiddenFilter: false,
    isDate: true,
    isSortable: true,
    dateFormat: 'DD/MM/YYYY',
    component: (item) => (item && item.updatedOwnerDate) || <span />,
  },
  {
    id: 10,
    label: 'Sale Price',
    isHiddenFilter: false,
    input: 'selling_price',
    isSortable: true,
    isDefaultFilterColumn: true,
    isNumber: true,
    component: (item) => (item && item.selling_price_agency_fee && item.selling_price_agency_fee.salePrice) || <span />,
  },
  {
    id: 11,
    label: 'Listing Agent',
    isSortable: true,
    fieldKey: 'listing_agent',
    input: 'listing_agent.name',
    isDefaultFilterColumn: true,
    component: (item) => (
      <span>
        {(item && item.details && item.details.map((el) => el.title === 'listing-agent' && el.value)) || (
          <span />
        )}
      </span>
    ),
  },
  {
    id: 12,
    label: 'Sale Publish Date',
    isDefaultFilterColumn: false,
    input: 'lastSalePublishDate',
    isHiddenFilter: false,
    isDate: true,
    isSortable: true,
  },
  {
    id: 13,
    label: 'Transacted By',
    input: 'statusCreatedByName',
    isHiddenFilter: false,
    isDefaultFilterColumn: false,
    isSortable: true,
  },
  {
    id: 14,
    label: 'Amenities',
    isSortable: true,
    isDefaultFilterColumn: false,
    component: (item) => (
      <span>
        {(item.amenities &&
          ((Array.isArray(item.amenities) &&
            item.amenities.map(
              (element, index) =>
                `${element.lookupItemName}${(index < item.amenities.length - 1 && ',') || ''
                } `
            )) ||
            'N/A')) ||
          'N/A'}
      </span>
    ),
  },
  {
    id: 15,
    label: 'Fitting And Fixtures',
    isSortable: true,
    isDefaultFilterColumn: false,
    component: (item) => (
      <span>
        {(item.fitting_and_fixtures &&
          ((Array.isArray(item.fitting_and_fixtures) &&
            item.fitting_and_fixtures.map(
              (element, index) =>
                `${element.lookupItemName}${(index < item.fitting_and_fixtures.length - 1 && ',') || ''
                } `
            )) ||
            'N/A')) ||
          'N/A'}
      </span>
    ),
  },
];
