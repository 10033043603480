import React from 'react';
import statues from '../statuses';
import { SelectComponet } from '../../../../../Components';

function StatusSelector({
  data,
  value,
  parentTranslationPath,
  isDisabled,
  label,
  onChange,
  className,
}) {
  return (
    <SelectComponet
      wrapperClasses={className}
      data={data ?? statues}
      valueInput={'id'}
      textInput={'name'}
      labelValue={label}
      value={value}
      isDisabled={isDisabled}
      parentTranslationPath={parentTranslationPath}
      translationPath={''}
      onSelectChanged={onChange}
    />
  );
}

export default StatusSelector;
