import { put, takeEvery } from 'redux-saga/effects';
import { MarketingCampaignsActions } from './Actions';
import { MarketingCampaignsStatus } from './Status';

function* fetchMarketingCampaignsInfo(action) {
  try {
    localStorage.setItem('MarketingCampaignsInfo', JSON.stringify(action.payload));
    yield put(MarketingCampaignsActions.marketingCampaignsSuccess(action.payload));
  } catch (err) {
    yield put(MarketingCampaignsActions.marketingCampaignsError(err));
  }
}
export function* watchMarketingCampaignsInfo() {
  yield takeEvery(MarketingCampaignsStatus.REQUEST, fetchMarketingCampaignsInfo);
}
