



import React , { useCallback , useEffect , useRef , useState }  from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { CheckboxesComponent , AutocompleteComponent } from '../../../../../../../Components' ; 
import { OrganizationUserSearch } from '../../../../../../../Services' ;  


export const LeadsReferredTo  = ({
  parentTranslationPath,
  translationPath,
  state,
  setState , 
  checkAtLeastOneSelected , 
  checkAtLeastOneSelected2 , 
  setDefaultSelected , 

}) => {
    const { t } = useTranslation(parentTranslationPath);

    const [allUsers , setAllUsers] =  useState([]) ;
    const [isLoading ,   setIsLoading ] = useState(false) ;  

    const searchTimer = useRef(null);
    let timer = null; 

    const getAllOrganizationUserSearch = useCallback(async (searchValue) => {
      setIsLoading(true);
      const res = await OrganizationUserSearch({ pageIndex: 0, pageSize: 10, name: searchValue  , userStatusId : 2 });
      if (!(res && res.status && res.status !== 200)) 
      setAllUsers(res.result.map((item) => ({
        id :  item.id , 
        fullName : item.fullName  , 
        branch : item.branch  || null , 
      })));
       else 
       setAllUsers([]);  

       setIsLoading(false);

    }, []); 


    useEffect(() => {
      getAllOrganizationUserSearch() ;
    }, []); 
 
  return (
    <div className='content'>
     <div className='w-100 px-2 mt-2 checkBoxsPart'>
     <div className='checkboxesItem'>
         <CheckboxesComponent
          idRef='LeadsReferredToViewAllLeadsRef'
          label={t(`${translationPath}view-all-leads`)}
          parentTranslationPath={parentTranslationPath}
          translationPathForData={translationPath}
          singleChecked={state.leads.referredTo.allUsers}
          onSelectedCheckboxClicked={(e)=> {
              const checkIfCanSelected  = checkAtLeastOneSelected({ ...state.leads.referredTo ,  allUsers : e.target.checked } );
               
              if(checkAtLeastOneSelected2(state.leads.referredTo.specificUsers));
                else if(!checkIfCanSelected)
                  return;
                
            const leadsReferredTo  =  { ...state.leads.referredTo ,
               allUsers : (e.target.checked)   , 
               myUser  :  false   , 
               myTeamUsers : false , 
               myBusinessGroupUsers : false , 
               myBranchUsers :false , 
               myManageUsers : false ,
               specificUsers :[]  , 
               myTeamLeadUsers : false , 
               isEmpty : false , 
               isEmptyAndReferredByMyBranch : false , 
             } ;
          setState({id : 'leads' , value : { ...state.leads , referredTo  :   leadsReferredTo  } });
         }}
         />
         </div>
         <div className='checkboxesItem'>
         <CheckboxesComponent
          idRef='leadsReferredToViewMyLeadsRef'
          label={t(`${translationPath}view-my-leads`)}
          parentTranslationPath={parentTranslationPath}
          translationPathForData={translationPath}
          singleChecked={state.leads.referredTo.myUser}
          onSelectedCheckboxClicked={(e)=> {
              const checkIfCanSelected  = checkAtLeastOneSelected({ ...state.leads.referredTo ,  myUser : e.target.checked } );
              
              if(checkAtLeastOneSelected2(state.leads.referredTo.specificUsers));
                else if(!checkIfCanSelected)
                  return;
                
            const leadsReferredTo  =  { ...state.leads.referredTo ,
               allUsers : false   , 
               myUser  :  (e.target.checked)   , 
               myTeamUsers : false , 
               myBusinessGroupUsers : false , 
             } ;
          setState({id : 'leads' , value : { ...state.leads , referredTo  :   leadsReferredTo  } });
         }}
         />
         </div>
         <div className='checkboxesItem'>
         <CheckboxesComponent
          idRef='LeadsReferredToViewMyTeamLeadsRef'
          label={t(`${translationPath}view-my-team-leads`)}
          parentTranslationPath={parentTranslationPath}
          translationPathForData={translationPath}
          singleChecked={state.leads.referredTo.myTeamUsers}
          onSelectedCheckboxClicked={(e)=> {
            const checkIfCanSelected  = checkAtLeastOneSelected({ ...state.leads.referredTo ,  myTeamUsers : e.target.checked } );
            
            if(checkAtLeastOneSelected2(state.leads.referredTo.specificUsers));
                else if(!checkIfCanSelected)
                  return;
            
            const leadsReferredTo  =  { ...state.leads.referredTo ,
               allUsers : false   , 
               myUser  :  false   , 
               myTeamUsers : (e.target.checked)  , 
               myBusinessGroupUsers : false , 
             } ;
          setState({id : 'leads' , value : { ...state.leads , referredTo  :   leadsReferredTo  } });
         }}
         />
         </div>
         <div className='checkboxesItem'>
         <CheckboxesComponent
          idRef='LeadsReferredToViewMyBusinessGroupRef'
          label={t(`${translationPath}view-my-business-group-leads`)}
          parentTranslationPath={parentTranslationPath}
          translationPathForData={translationPath}
          singleChecked={state.leads.referredTo.myBusinessGroupUsers}
          onSelectedCheckboxClicked={(e)=> {
            const checkIfCanSelected  = checkAtLeastOneSelected({ ...state.leads.referredTo ,  myBusinessGroupUsers : e.target.checked } );
            
            if(checkAtLeastOneSelected2(state.leads.referredTo.specificUsers));
                else if(!checkIfCanSelected)
                  return;
            
            const leadsReferredTo  =  { ...state.leads.referredTo ,
               allUsers : false    , 
               myUser  :  false   , 
               myTeamUsers : false , 
               myBusinessGroupUsers : (e.target.checked) , 
             } ;
          setState({id : 'leads' , value : { ...state.leads , referredTo  :   leadsReferredTo  } });
         }}
         />
         </div>
         <div className='checkboxesItem'>
         <CheckboxesComponent
          idRef='LeadsReferredToViewMyBranchLeadsRef'
          label={t(`${translationPath}view-my-branch-leads`)}
          parentTranslationPath={parentTranslationPath}
          translationPathForData={translationPath}
          singleChecked={state.leads.referredTo.myBranchUsers}
          onSelectedCheckboxClicked={(e)=> {
            const checkIfCanSelected  = checkAtLeastOneSelected({ ...state.leads.referredTo ,  myBranchUsers : e.target.checked } );

            if(checkAtLeastOneSelected2(state.leads.referredTo.specificUsers));
                else if(!checkIfCanSelected)
                  return;
            
            const leadsReferredTo  =  { ...state.leads.referredTo ,
               allUsers : false   , 
               myBranchUsers :(e.target.checked) , 
             } ;
          setState({id : 'leads' , value : { ...state.leads , referredTo  :   leadsReferredTo  } });
         }}
         />
         </div>
         <div className='checkboxesItem'>
         <CheckboxesComponent
          idRef='LeadsReferredToViewMyManagedTeamLeadsRef'
          label={t(`${translationPath}view-my-managed-team-leads`)}
          parentTranslationPath={parentTranslationPath}
          translationPathForData={translationPath}
          singleChecked={state.leads.referredTo.myManageUsers}
          onSelectedCheckboxClicked={(e)=> {
            const checkIfCanSelected  = checkAtLeastOneSelected({ ...state.leads.referredTo ,  myManageUsers : e.target.checked } );
           
            if(checkAtLeastOneSelected2(state.leads.referredTo.specificUsers));
                else if(!checkIfCanSelected)
                  return;  

            const leadsReferredTo  =  { ...state.leads.referredTo ,
               allUsers :  false   , 
               myManageUsers : (e.target.checked) ,
             } ;
          setState({id : 'leads' , value : { ...state.leads , referredTo  :   leadsReferredTo  } });
         }}
         />
        </div>
        <div className='checkboxesItem'>
         <CheckboxesComponent
          idRef='LeadsReferredToMyTeamLeadUsersRef'
          label={t(`${translationPath}view-my-team-lead-users`)}
          parentTranslationPath={parentTranslationPath}
          translationPathForData={translationPath}
          singleChecked={state.leads.referredTo.myTeamLeadUsers}
          onSelectedCheckboxClicked={(e)=> {
            const checkIfCanSelected  = checkAtLeastOneSelected({ ...state.leads.referredTo ,  myTeamLeadUsers : e.target.checked } );
           
            if(checkAtLeastOneSelected2(state.leads.referredTo.specificUsers));
                else if(!checkIfCanSelected)
                  return;  

            const leadsReferredTo  =  { ...state.leads.referredTo ,
               allUsers :  false   , 
               myTeamLeadUsers : (e.target.checked) ,
             } ;
          setState({id : 'leads' , value : { ...state.leads , referredTo  :   leadsReferredTo  } });
         }}
         />
        </div>
        <div className='checkboxesItem'>
         <CheckboxesComponent
          idRef='LeadsReferredToIsEmptyRef'
          label={t(`${translationPath}is-empty`)}
          parentTranslationPath={parentTranslationPath}
          translationPathForData={translationPath}
          singleChecked={(state.leads.referredTo && state.leads.referredTo.isEmpty )}
          onSelectedCheckboxClicked={(e)=> {
            const checkIfCanSelected  = checkAtLeastOneSelected({ ...state.leads.referredTo ,  isEmpty : e.target.checked } );
           
            if(checkAtLeastOneSelected2(state.leads.referredTo.specificUsers));
                else if(!checkIfCanSelected)
                  return;  

            const leadsReferredTo  =  { ...state.leads.referredTo ,
               allUsers :  false   , 
               isEmpty : (e.target.checked) ,
             } ;
          setState({id : 'leads' , value : { ...state.leads , referredTo  :   leadsReferredTo  } });
         }}
         />
        </div>
        <div className='checkboxesItem'>
         <CheckboxesComponent
          idRef='isEmptyAndReferredByMyBranchRef'
          label={t(`${translationPath}isEmptyAndReferredByMyBranch`)}
          parentTranslationPath={parentTranslationPath}
          translationPathForData={translationPath}
          singleChecked={(state.leads.referredTo &&  state.leads.referredTo.isEmptyAndReferredByMyBranch  ) ||false }
          onSelectedCheckboxClicked={(e)=> {
            const checkIfCanSelected  = checkAtLeastOneSelected({ ...state.leads.referredTo ,  isEmptyAndReferredByMyBranch : e.target.checked } );
           
            if(checkAtLeastOneSelected2(state.leads.referredTo.specificUsers));
                else if(!checkIfCanSelected)
                  return;  

            const leadsReferredTo  =  { ...state.leads.referredTo ,
               allUsers :  false   , 
               isEmptyAndReferredByMyBranch : (e.target.checked) ,
             } ;
          setState({id : 'leads' , value : { ...state.leads , referredTo  :   leadsReferredTo  } });
         }}
         />
        </div>
        <div className='w-100 px-2 mt-2 mb-2'>
          <AutocompleteComponent
              idRef='leadsReferredToSpecificsUsersRef'
              labelValue={t(`${translationPath}specific_users`)}
              data={ allUsers || []}
              selectedValues={state.leads.referredTo.specificUsers}
              multiple
              displayLabel={(option) =>
              (option && option.fullName) || ''}
              chipsLabel={(option) => (option && option.fullName) || ''}
              withoutSearchButton
              parentTranslationPath={parentTranslationPath}
              translationPath={translationPath}
              onInputKeyUp={(e) => {
                const { value } = e.target;
                 if (searchTimer.current) clearTimeout(searchTimer.current);
                   searchTimer.current = setTimeout(() => {
                    getAllOrganizationUserSearch(value) ;  
                }, 700); 
                }}
                renderOption={
                  ((option) =>
                  <div className='d-flex-column'>
                   <div className='d-flex-v-center-h-between w-100 texts-truncate'>
                    {option.fullName}
                   </div>
                   <span className='c-gray-secondary'> {option.branch} </span>
                 </div>
               )}
              isDisabled={state.leads.referredTo.allUsers}
              value={state.leads.referredTo.specificUsers}
              onChange={(e, newValues) => 
              {
                let leadsReferredTo = { ...state.leads.referredTo  , specificUsers : newValues };
                if( newValues && !(newValues.length) && setDefaultSelected({ ...state.leads.referredTo , specificUsers :false }))
                  leadsReferredTo = { ...state.leads.referredTo  , specificUsers : newValues , allUsers : true };

                setState({ id: 'leads', value: { ...state.leads , referredTo  : leadsReferredTo } })
              }}
             isLoading={isLoading} 
             withLoader
             filterOptions={(options) => {
              const isFind = (id) => state.leads.referredTo.specificUsers.findIndex((w) => w.id === id) === -1;
             return options.filter((w) => isFind(w.id));
            }}
         /> 
        </div>
     </div>
  </div>
  );
};

LeadsReferredTo.propTypes = {
  parentTranslationPath: PropTypes.string.isRequired,
  translationPath: PropTypes.string.isRequired,
  state:PropTypes.instanceOf(Object).isRequired,
  setState : PropTypes.func.isRequired,
  checkAtLeastOneSelected : PropTypes.func.isRequired, 
  checkAtLeastOneSelected2 :PropTypes.func.isRequired, 
  setDefaultSelected:PropTypes.func.isRequired, 
};
