import React from 'react';
import PropTypes from 'prop-types';
import { useTranslate } from '../../Hooks/useTranslate';

function FormattedMessage({ id, path }) {
    const { translate } = useTranslate(path);
    if (!id)
        return null;

    return (
      <>
        {translate(id)}
      </>
    );
}
FormattedMessage.propTypes = {
    id: PropTypes.string.isRequired,
    path: PropTypes.string.isRequired
};

export default FormattedMessage;
