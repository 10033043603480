import React, { useState, useEffect, useRef, useReducer } from 'react';
import { ApplicationUserSearch } from '../../../../../../../../Services/userServices';
import {
  GetLookupItemsByLookupTypeName,
  getProperties,
  getUnits,
  GetContacts,
  GetlookupTypeItems
} from '../../../../../../../../Services';
import { useTranslation } from 'react-i18next';
import { AutocompleteComponent, SelectComponet, Inputs } from '../../../../../../../../Components';
import { UnitMapper } from '../../../../../../UnitsView/UnitMapper/UnitMapper';
import { ClassificationsContactEnum, SubCommunityTypeIdEnum, CityTypeIdEnum, CommunityTypeIdEnum, CampaignRelatedToEnum} from '../../../../../../../../Enums';

export function MainInformationStep({
  parentTranslationPath,
  translationPath,
  selected,
  setSelected,
  isReadOnly,
  activeItem
}) {
  const searchTimer = useRef(null);
  const { t } = useTranslation(parentTranslationPath);
  const reducer = (state, action) => {
    if (action.id !== 'edit') return { ...state, [action.id]: action.value };
    return {
      ...action.value,
    };
  };

  const [isLoading, setIsLoading] = useReducer(reducer, {
    users: false,
    branches: false,
    platformNames: false,
    projectCategories: false,
    targetAudiences: false,
    mediaRequirements: false,
    marketingMaterials: false,
    campaignReasons: false,
    campaignLanguages: false,
    Developer: false,
    Community: false,
    SubCommunity: false,
    Property: false,
    Unit: false,
    campaignUsps: false,
  });
  const [data, setData] = useReducer(reducer, {
    users: [],
    branches: [],
    platformNames: [],
    projectCategories: [],
    targetAudiences: [],
    mediaRequirements: [],
    marketingMaterials: [],
    campaignReasons: [],
    campaignLanguages: [],
    campaignUsps: [],
  });
  const [relatedToSearchValue, setRelatedToSearchValue] = useState(null);

  const getAllUsers = async (searchValue) => {
    setIsLoading({ id: 'users', value: true });
    const res = await ApplicationUserSearch({
      pageIndex: 0,
      pageSize: 25,
      name: searchValue || '',
    });
    if (!(res && res.status && res.status !== 200)) {
      const localValue = (res && res.result) || [];
      if (localValue.length > 0) {
        setData({ id: 'users', value: localValue });
      } else {
        setData({ id: 'users', value: [] });
      }
      setIsLoading({ id: 'users', value: false });
    }
  };


  const getAllProperties = async () => {
    setIsLoading({ id: 'Property', value: true });
    const res = await getProperties({
      pageIndex: 0,
      pageSize: 25,
      search: relatedToSearchValue || '',
    });
    if (!(res && res.status && res.status !== 200))
      setData({ id: 'Property', value: res.result || [] });
    else setData({ id: 'Property', value: [] });

    setIsLoading({ id: 'Property', value: false });
  };

  const getAllUnits = async () => {
    setIsLoading({ id: 'Unit', value: true });

    const response = await getUnits({
      pageIndex: 0,
      pageSize: 25,
      search: relatedToSearchValue,
    });
    if (!(response && response.status && response.status !== 200)) {
      const unitsMapped = (response && response.result.map((item) => UnitMapper(item))) || [];

      setData({ id: 'Unit', value: unitsMapped || [] });
    } else setData({ id: 'Unit', value: [] });

    setIsLoading({ id: 'Unit', value: false });
  };

  const getAllDevelopers = async () => {
    setIsLoading({ id: 'Developer', value: true });

    const response = await GetContacts({
      pageIndex: 0,
      pageSize: 25,
      search: relatedToSearchValue,
      classificationId: ClassificationsContactEnum[0].Id,
    });
    if (!(response && response.status && response.status !== 200))
      setData({ id: 'Developer', value: response.result || [] });
    else setData({ id: 'Developer', value: [] });

    setIsLoading({ id: 'Developer', value: false });
  };

  const getPlatformNameLookups = async () => {
    setIsLoading({ id: 'platformNames', value: true });
    const res = await GetLookupItemsByLookupTypeName({
      lookUpName: 'PlatformName',
      pageSize: 25,
      pageIndex: 1,
    });
    if (!(res && res.status && res.status !== 200)) {
      setData({ id: 'platformNames', value: res.result || [] });
    } else setData({ id: 'platformNames', value: [] });
    setIsLoading({ id: 'platformNames', value: false });
  };

  const getProjectCategoryLookups = async () => {
    setIsLoading({ id: 'projectCategories', value: true });
    const res = await GetLookupItemsByLookupTypeName({
      lookUpName: 'ProjectCategory',
      pageSize: 25,
      pageIndex: 1,
    });
    if (!(res && res.status && res.status !== 200)) {
      setData({ id: 'projectCategories', value: res.result || [] });
    } else setData({ id: 'projectCategories', value: [] });
    setIsLoading({ id: 'projectCategories', value: false });
  };

  const getTargetAudienceLookups = async () => {
    setIsLoading({ id: 'targetAudiences', value: true });
    const res = await GetLookupItemsByLookupTypeName({
      lookUpName: 'TargetAudience',
      pageSize: 25,
      pageIndex: 1,
    });
    if (!(res && res.status && res.status !== 200)) {
      setData({ id: 'targetAudiences', value: res.result || [] });
    } else setData({ id: 'targetAudiences', value: [] });
    setIsLoading({ id: 'targetAudiences', value: false });
  };

  const getMediaRequirementsLookups = async () => {
    setIsLoading({ id: 'mediaRequirements', value: true });
    const res = await GetLookupItemsByLookupTypeName({
      lookUpName: 'MediaRequirements',
      pageSize: 25,
      pageIndex: 1,
    });
    if (!(res && res.status && res.status !== 200)) {
      setData({ id: 'mediaRequirements', value: res.result || [] });
    } else setData({ id: 'mediaRequirements', value: [] });
    setIsLoading({ id: 'mediaRequirements', value: false });
  };

  const getMarketingMaterialLookups = async () => {
    setIsLoading({ id: 'marketingMaterials', value: true });
    const res = await GetLookupItemsByLookupTypeName({
      lookUpName: 'MarketingMaterial',
      pageSize: 25,
      pageIndex: 1,
    });
    if (!(res && res.status && res.status !== 200)) {
      setData({ id: 'marketingMaterials', value: res.result || [] });
    } else setData({ id: 'marketingMaterials', value: [] });
    setIsLoading({ id: 'marketingMaterials', value: false });
  };

  const getCampaignReasonLookups = async () => {
    setIsLoading({ id: 'campaignReasons', value: true });
    const res = await GetLookupItemsByLookupTypeName({
      lookUpName: 'CampaignReason',
      pageSize: 25,
      pageIndex: 1,
    });
    if (!(res && res.status && res.status !== 200)) {
      setData({ id: 'campaignReasons', value: res.result || [] });
    } else setData({ id: 'campaignReasons', value: [] });
    setIsLoading({ id: 'campaignReasons', value: false });
  };

  const getCampaignLanguagesLookups = async () => {
    setIsLoading({ id: 'campaignLanguages', value: true });
    const res = await GetLookupItemsByLookupTypeName({
      lookUpName: 'language',
      pageSize: 25,
      pageIndex: 1,
    });
    if (!(res && res.status && res.status !== 200)) {
      setData({ id: 'campaignLanguages', value: res.result || [] });
    } else setData({ id: 'campaignLanguages', value: [] });
    setIsLoading({ id: 'campaignLanguages', value: false });
  };

  const getCommunityLookups = async () => {
    setIsLoading({ id: 'Community', value: true });
    const res = await GetlookupTypeItems({
      pageIndex: 0,
      pageSize: 25,
      lookupTypeId: CommunityTypeIdEnum.lookupTypeId,
      search: relatedToSearchValue || ''
    });
    if (!(res && res.status && res.status !== 200)) {
      setData({ id: 'Community', value: res.result || [] });
    } else setData({ id: 'Community', value: [] });
    setIsLoading({ id: 'Community', value: false });
  };

  const getSubCommunityLookups = async () => {
    setIsLoading({ id: 'SubCommunity', value: true });
    const res = await GetlookupTypeItems({
      pageIndex: 0,
      pageSize: 25,
      lookupTypeId: SubCommunityTypeIdEnum.lookupTypeId,
      search: relatedToSearchValue || ''
    });
    if (!(res && res.status && res.status !== 200)) {
      setData({ id: 'SubCommunity', value: res.result || [] });
    } else setData({ id: 'SubCommunity', value: [] });
    setIsLoading({ id: 'SubCommunity', value: false });
  };

  const getCityLookups = async () => {
    setIsLoading({ id: 'City', value: true });
    const res = await GetlookupTypeItems({
      pageIndex: 0,
      pageSize: 25,
      lookupTypeId: CityTypeIdEnum.lookupTypeId,
      search: relatedToSearchValue || ''
    });
    if (!(res && res.status && res.status !== 200)) {
      setData({ id: 'City', value: res.result || [] });
    } else setData({ id: 'City', value: [] });
    setIsLoading({ id: 'City', value: false });
  };

  const getCampaignUspsLookups = async () => {
    setIsLoading({ id: 'campaignUsps', value: true });
    const res = await GetLookupItemsByLookupTypeName({
      lookUpName: 'CampaignUSPs',
      pageSize: 10,
      pageIndex: 1,
    });
    if (!(res && res.status && res.status !== 200)) {
      setData({ id: 'campaignUsps', value: res.result || [] });
    } else setData({ id: 'campaignUsps', value: [] });
    setIsLoading({ id: 'campaignUsps', value: false });
  };


  useEffect(() => {
    getAllUsers();
  }, []);

  useEffect(() => {
    if (!isReadOnly) {
      if (selected.relatedToType === 'Developer') getAllDevelopers();
      else if (selected.relatedToType === 'Community') getCommunityLookups();
      else if (selected.relatedToType === 'SubCommunity') getSubCommunityLookups();
      else if (selected.relatedToType === 'Property') getAllProperties();
      else if (selected.relatedToType === 'Unit') getAllUnits();
      else if (selected.relatedToType === 'City') getCityLookups();
    }
  }, [selected.relatedToType, relatedToSearchValue, isReadOnly]);

  return (
    <div className='camp-req-main-info-f1'>
      <div>
        <div className='mr-2 my-3'>
          <AutocompleteComponent
            idRef='campaignRequesterRef'
            labelClasses='Requierd-Color'
            labelValue={t(`${translationPath}CampaignRequester`)}
            selectedValues={selected.campaignRequester}
            isDisabled={isLoading.users || isReadOnly}
            data={data.users || []}
            displayLabel={(option) => option.fullName || ''}
            multiple={false}
            withoutSearchButton
            onChange={(event, newValue) => {
              setSelected({ id: 'campaignRequester', value: newValue });
            }}
            onInputKeyUp={(e) => {
              const { value } = e.target;
              if (searchTimer) clearTimeout(searchTimer.current);
              searchTimer.current = setTimeout(() => {
                getAllUsers(value);
              }, 1200);
            }}
            onKeyDown={() => {
              setSelected({ id: 'campaignRequester', value: null });
            }}
            isLoading={isLoading.users}
          />
        </div>
        <div className='mr-2 my-3'>
          <AutocompleteComponent
            idRef='targetAudienceRef'
            labelValue={t(`${translationPath}Target-audience`)}
            selectedValues={selected.targetAudience || []}
            isDisabled={isLoading.targetAudiences || isReadOnly}
            data={data.targetAudiences || []}
            displayLabel={(option) => option.lookupItemName || ''}
            chipsLabel={(option) => option.lookupItemName || ''}
            multiple
            withoutSearchButton
            onChange={(event, newValue) => {
              setSelected({ id: 'targetAudience', value: newValue });
            }}
            onOpen={() => {
              if (data.targetAudiences && data.targetAudiences.length == 0)
                getTargetAudienceLookups();
            }}
            isLoading={isLoading.targetAudiences}
          />
        </div>
        <div className='mr-2 my-3'>
          <AutocompleteComponent
            idRef='marketingMaterialsRef'
            labelValue={t(`${translationPath}Marketing-material`)}
            selectedValues={selected.marketingMaterial || []}
            isDisabled={isLoading.marketingMaterials || isReadOnly}
            data={data.marketingMaterials || []}
            displayLabel={(option) => option.lookupItemName || ''}
            chipsLabel={(option) => option.lookupItemName || ''}
            multiple
            withoutSearchButton
            onChange={(event, newValue) => {
              setSelected({ id: 'marketingMaterial', value: newValue });
            }}
            onOpen={() => {
              if (data.marketingMaterials && data.marketingMaterials.length == 0)
                 getMarketingMaterialLookups();
            }}
            
            isLoading={isLoading.marketingMaterials}
          />
        </div>
        <div className='dialog-content-item mr-2 '>
          <Inputs
            idRef='campaignKeyMessagesRef'
            labelValue='Campaign-key-messages'
            value={selected.campaignKeyMessages || ''}
            isDisabled={isReadOnly}
            isWithError
            multiline
            rows={4}
            parentTranslationPath={parentTranslationPath}
            translationPath={translationPath}
            onInputChanged={(event) => {
              const msgValue = event.target.value;
              setSelected({ id: 'campaignKeyMessages', value: msgValue });
            }}
          />
        </div>
      </div>
      <div>
        {activeItem ?
          <div className='mr-2 mt-3'>
            <AutocompleteComponent
              idRef='campaignCreatorRef'
              labelValue={t(`${translationPath}CampaignCreator`)}
              selectedValues={selected.campaignCreator}
              isDisabled
              data={data.users || []}
              displayLabel={(option) => option.fullName || ''}
              multiple={false}
              withoutSearchButton
              onChange={(event, newValue) => {
                setSelected({ id: 'campaignCreator', value: newValue });
              }}
              onInputKeyUp={(e) => {
                const { value } = e.target;
                if (searchTimer) clearTimeout(searchTimer.current);
                searchTimer.current = setTimeout(() => {
                  getAllUsers(value);
                }, 1200);
              }}
              onKeyDown={() => {
                setSelected({ id: 'campaignCreator', value: null });
              }}
              isLoading={isLoading.users}
            />
          </div> :
          null
        }

        <div className={`${selected.relatedToType && selected.relatedToType !== 'Unit' ? 'd-flex' : ''}`}>
          <div className={`pr-2 mt-3 w-100 ${selected.relatedToType && selected.relatedToType !== 'Unit' ? 'w-50' : 'w-100'}`}>
            <SelectComponet
              data={Object.values(CampaignRelatedToEnum)}
              labelValue='Related-To-Type'
              labelClasses='Requierd-Color'
              emptyItem={{ value: null, text: 'select-type', isDisabled: false }}
              value={selected.relatedToType}
              valueInput='name'
              textInput='name'
              onSelectChanged={(newValue) => {
                if(!isReadOnly){
                  setSelected({
                    id: 'edit',
                    value: {
                      ...selected,
                      relatedToValue: null,
                      generalTypeText: '',
                      relatedToType: newValue,
                    },
                  });
                  setRelatedToSearchValue(null);
                }
              }}
              themeClass='theme-default'
              idRef='relatedToTypeRef'
              parentTranslationPath={parentTranslationPath}
              translationPath={translationPath}
            />
          </div>
          <div className='d-flex'>
            {((selected.relatedToType === 'GeneralCampaign' ||
              selected.relatedToType === 'Unit') ? (
              <div className='mr-2 mt-3 w-100'>
                <Inputs
                  idRef='campaignNameRef'
                  labelClasses='Requierd-Color'
                  labelValue='Campaign-Name'
                  value={selected.generalTypeText || ''}
                  isDisabled={isReadOnly}
                  parentTranslationPath={parentTranslationPath}
                  translationPath={translationPath}
                  onInputChanged={(event) => {
                    const name = event.target.value;
                    setSelected({ id: 'generalTypeText', value: name });
                  }}
                />
              </div>
            ) : null)}
            {selected.relatedToType &&
              (selected.relatedToType !== 'GeneralCampaign') && (
                <div className='pr-2 mt-3 w-100'>
                <AutocompleteComponent
                  idRef='relatedToValueRef'
                  labelClasses='Requierd-Color'
                  labelValue={t(`${translationPath}${selected.relatedToType}`)}
                  selectedValues={selected.relatedToType == 'Unit'? (selected.relatedToValue || []) : selected.relatedToValue}
                  isDisabled={isLoading[selected.relatedToType] || isReadOnly}
                  chipsDisabled={() => (selected.relatedToType == 'Unit' && isReadOnly)}
                  data={data[selected.relatedToType] || []}
                  displayLabel={(option) =>
                    option.lookupItemName ||
                    option?.property?.property_name ||
                    option.unitRefNo ||
                    option.name ||
                    option?.contact?.company_name ||
                    (option?.contact?.first_name &&
                      `${option?.contact?.first_name} ${option?.contact?.last_name}`) ||
                    ''
                  }
                  chipsLabel={(option) =>  option.unitRefNo || option.name || ''}
                  multiple={selected.relatedToType == 'Unit'}
                  withoutSearchButton
                  onChange={(event, newValue) => {
                    setSelected({ id: 'relatedToValue', value: newValue });
                  }}
                  onInputKeyUp={(e) => {
                    if (
                      selected.relatedToType !== 'Community' ||
                      selected.relatedToType !== 'SubCommunity'
                    ) {
                      const { value } = e.target;
                      if (searchTimer.current) clearTimeout(searchTimer.current);
                      searchTimer.current = setTimeout(() => {
                        setRelatedToSearchValue(value);
                      }, 1200);
                    }
                  }}
                  onKeyDown={() => {
                    if(selected.relatedToType == 'Unit')
                    setSelected({ id: 'relatedToValue', value: [] });
                    else setSelected({ id: 'relatedToValue', value: null });

                  }}
                  isLoading={isLoading[selected.relatedToType]}
                />
              </div>
            )}
              </div>
        </div>

        <div className='mr-2 my-3'>
          <AutocompleteComponent
            idRef='platformNameRef'
            labelValue={t(`${translationPath}Platform-name`)}
            selectedValues={selected.platformName || []}
            isDisabled={isLoading.platformNames || isReadOnly}
            data={data.platformNames || []}
            displayLabel={(option) => option.lookupItemName || ''}
            chipsLabel={(option) => option.lookupItemName || ''}
            multiple
            withoutSearchButton
            onChange={(event, newValue) => {
              setSelected({ id: 'platformName', value: newValue });
            }}
            onOpen={() => {
              if (data.platformNames && data.platformNames.length == 0)
                getPlatformNameLookups();
            }}
            isLoading={isLoading.platformNames}
          />
        </div>
        <div className='mr-2 my-3'>
          <AutocompleteComponent
            idRef='campaignReasonsRef'
            labelValue={t(`${translationPath}Campaign-reasons`)}
            selectedValues={selected.campaignReason || []}
            isDisabled={isLoading.campaignReasons || isReadOnly}
            data={data.campaignReasons || []}
            displayLabel={(option) => option.lookupItemName || ''}
            chipsLabel={(option) => option.lookupItemName || ''}
            multiple
            withoutSearchButton
            onChange={(event, newValue) => {
              setSelected({ id: 'campaignReason', value: newValue });
            }}
            onOpen={() => {
              if (data.campaignReasons && data.campaignReasons.length == 0)
                getCampaignReasonLookups();
            }}
            isLoading={isLoading.campaignReasons}
          />
        </div>
      </div>
      <div>
        <div className='mr-2 my-3'>
          <AutocompleteComponent
            idRef='ProjectCategoryRef'
            labelValue={t(`${translationPath}Project-category`)}
            selectedValues={selected.projectCategory}
            isDisabled={isLoading.projectCategories  || isReadOnly}
            data={data.projectCategories || []}
            displayLabel={(option) => option.lookupItemName || ''}
            multiple={false}
            withoutSearchButton
            onChange={(event, newValue) => {
              setSelected({ id: 'projectCategory', value: newValue });
            }}
            onOpen={() => {
              if (data.projectCategories && data.projectCategories.length == 0)
              getProjectCategoryLookups();
            }}
            isLoading={isLoading.projectCategories}
          />
        </div>
        <div className='mr-2 my-3'>
          <AutocompleteComponent
            idRef='departmentApprovalUserRef'
            labelValue={t(`${translationPath}Media-requirements`)}
            selectedValues={selected.mediaRequirements || []}
            isDisabled={isLoading.mediaRequirements || isReadOnly}
            data={data.mediaRequirements || []}
            displayLabel={(option) => option.lookupItemName || ''}
            chipsLabel={(option) => option.lookupItemName || ''}
            multiple
            withoutSearchButton
            onChange={(event, newValue) => {
              setSelected({ id: 'mediaRequirements', value: newValue });
            }}
            onOpen={() => {
              if (data.mediaRequirements && data.mediaRequirements.length == 0)
                getMediaRequirementsLookups();
            }}
            isLoading={isLoading.mediaRequirements}
          />
        </div>
        <div className='mr-2 my-3'>
          <AutocompleteComponent
            idRef='campaignLanguageRef'
            labelValue={t(`${translationPath}Campaign-language`)}
            selectedValues={selected.campaignLanguage || []}
            isDisabled={isLoading.campaignLanguages || isReadOnly}
            data={data.campaignLanguages || []}
            displayLabel={(option) => option.lookupItemName || ''}
            chipsLabel={(option) => option.lookupItemName || ''}
            multiple
            withoutSearchButton
            onChange={(event, newValue) => {
              setSelected({ id: 'campaignLanguage', value: newValue });
            }}
            onOpen={() => {
              if (data.campaignLanguages && data.campaignLanguages.length == 0)
                getCampaignLanguagesLookups();
            }}
            isLoading={isLoading.campaignLanguages}
          />
        </div>
        <div className='mr-2 my-3'>
        <AutocompleteComponent
          idRef='campaignUspRef'
          labelValue={t(`${translationPath}Campaign-USPs`)}
          selectedValues={selected.campaignUsp || []}
          isDisabled={isLoading.campaignUsps || isReadOnly}
          data={data.campaignUsps || []}
          displayLabel={(option) => option.lookupItemName || ''}
          chipsLabel={(option) => option.lookupItemName || ''}
          multiple
          withoutSearchButton
          onChange={(event, newValue) => {
            setSelected({ id: 'campaignUsp', value: newValue });
          }}
          onOpen={() => {
            if (data.campaignUsps && data.campaignUsps.length == 0)
            getCampaignUspsLookups();
          }}
          isLoading={isLoading.campaignUsps}
        />
      </div>
      </div>
    </div>
  );
}