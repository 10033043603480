import { createAction } from '@reduxjs/toolkit';
const CONTACTS_GET = createAction('CONTACTS_GET');
const CONTACTS_GET_SUCCESS = createAction('CONTACTS_GET_SUCCESS');
const CONTACTS_GET_FAIL = createAction('CONTACTS_GET_FAIL');
const CONTACTS_RESET = createAction('CONTACTS_RESET');


const CONTACTS_DETAILS_GET = createAction('CONTACTS_DETAILS_GET');
const CONTACTS_DETAILS_GET_SUCCESS = createAction('CONTACTS_DETAILS_GET_SUCCESS');
const CONTACTS_DETAILS_GET_FAIL = createAction('CONTACTS_DETAILS_GET_FAIL');

const CONTACTS_DETAILS_GET_DIALOG = createAction('CONTACTS_DETAILS_DIALOG_GET');
const CONTACTS_DETAILS_GET_DIALOG_SUCCESS = createAction('CONTACTS_DETAILS_GET_DIALOG_SUCCESS');
const CONTACTS_DETAILS_GET_DIALOG_FAIL = createAction('CONTACTS_DETAILS_GET_DIALOG_FAIL');

const CONTACTS_DETAILS_RESET = createAction('CONTACTS_DETAILS_RESET');

const CONTACTS_DETAILS_FIELD_GET = createAction('CONTACTS_DETAILS_FIELD_GET');
const CONTACTS_DETAILS_FIELD_GET_SUCCESS = createAction('CONTACTS_DETAILS_FIELD_GET_SUCCESS');
const CONTACTS_DETAILS_FIELD_GET_FAIL = createAction('CONTACTS_DETAILS_FIELD_GET_FAIL');

const CONTACTS_DETAILS_PUT = createAction('CONTACTS_DETAILS_PUT');
const CONTACTS_DETAILS_PUT_SUCCESS = createAction('CONTACTS_DETAILS_PUT_SUCCESS');
const CONTACTS_DETAILS_PUT_FAIL = createAction('CONTACTS_DETAILS_PUT_FAIL');

const CONTACTS_POST = createAction('CONTACTS_POST');
const CONTACTS_POST_SUCCESS = createAction('CONTACTS_POST_SUCCESS');
const CONTACTS_POST_FAIL = createAction('CONTACTS_POST_FAIL');

const CONTACTS_BY_ID_PUT = createAction('CONTACTS_PUT');
const CONTACTS_BY_ID_PUT_SUCCESS = createAction('CONTACTS_PUT_SUCCESS');
const CONTACTS_BY_ID_PUT_FAIL = createAction('CONTACTS_PUT_FAIL');

const CONTACTS_SEARCH_GET = createAction('CONTACTS_SEARCH_GET');
const CONTACTS_SEARCH_GET_SUCCESS = createAction('CONTACTS_SEARCH_GET_SUCCESS');
const CONTACTS_SEARCH_FAIL = createAction('CONTACTS_SEARCH_FAIL');


export {
	CONTACTS_GET,
	CONTACTS_GET_SUCCESS,
	CONTACTS_GET_FAIL,
	CONTACTS_RESET,
	CONTACTS_DETAILS_GET,
	CONTACTS_DETAILS_GET_SUCCESS,
	CONTACTS_DETAILS_GET_FAIL,
	CONTACTS_DETAILS_GET_DIALOG,
	CONTACTS_DETAILS_GET_DIALOG_SUCCESS,
	CONTACTS_DETAILS_GET_DIALOG_FAIL,
	CONTACTS_DETAILS_FIELD_GET,
	CONTACTS_DETAILS_FIELD_GET_SUCCESS,
	CONTACTS_DETAILS_FIELD_GET_FAIL,
	CONTACTS_DETAILS_RESET,
	CONTACTS_DETAILS_PUT,
	CONTACTS_DETAILS_PUT_SUCCESS,
	CONTACTS_DETAILS_PUT_FAIL,
	CONTACTS_POST,
	CONTACTS_POST_SUCCESS,
	CONTACTS_POST_FAIL,
	CONTACTS_BY_ID_PUT,
	CONTACTS_BY_ID_PUT_SUCCESS,
	CONTACTS_BY_ID_PUT_FAIL,
	CONTACTS_SEARCH_GET,
	CONTACTS_SEARCH_GET_SUCCESS,
	CONTACTS_SEARCH_FAIL
};