import { RolesView, RolesListView } from '../../Views/Home';
import { RolesManagementView } from '../../Views/Home/Administration/RolesView/RolesManagement/RolesManagementView';

export const RolesRoutes = [
  {
    id: 13,
    path: '/View',
    name: 'SideMenuView.Administration.Roles',
    component: RolesListView,
    layout: '/home/Roles',
    default: true,
    isRoute: true,
    authorize: true,
    roles: [],
    groupId: 4,
    order: 3,
    icon: 'icons i-contacts-gray',
    iconActive: 'icons i-contacts-blue-dark',
    isDisabled: false,
    showInMenu: false,
    isExact: true,
    breadcrumbs: [
      {
        name: 'SideMenuView.Administration.Roles',
        isDisabled: false,
        route: '/home/Roles/View',
        groupName: 'system-admin',
      },
    ],
  },
  {
    id: 12,
    path: '/Details',
    name: 'SideMenuView.Administration.Roles',
    component: RolesView,
    layout: '/home/Roles',
    default: false,
    isRoute: true,
    authorize: true,
    roles: [],
    groupId: 4,
    order: 3,
    icon: 'icons i-contacts-gray',
    iconActive: 'icons i-contacts-blue-dark',
    isDisabled: false,
    showInMenu: false,
    isExact: true,
    breadcrumbs: [
      {
        name: 'SideMenuView.Administration.Roles',
        isDisabled: false,
        route: '/home/Roles/View',
        groupName: 'system-admin',
      },
      {
        name: 'SideMenuView.Administration.RolesDetails',
        isDisabled: false,
        route: '/home/Roles/Details',
      },
    ],
  },
  {
    id: 12,
    path: '/role-management',
    name: 'RolesManagementView:role-management',
    component: RolesManagementView,
    layout: '/home/Roles',
    default: false,
    isRoute: true,
    authorize: true,
    roles: [],
    groupId: 4,
    order: 3,
    icon: 'icons i-contacts-gray',
    iconActive: 'icons i-contacts-blue-dark',
    // routerLink: '/home/contacts',
    isDisabled: false,
    showInMenu: false,
    isExact: true,
    breadcrumbs: [
      {
        name: 'SideMenuView.Administration.Roles',
        isDisabled: false,
        route: '/home/Roles/View',
        groupName: 'system-admin',
      },
      {
        name: 'RolesManagementView:role-management',
        isDisabled: false,
        route: '/home/Roles/role-management',
      },
    ],
  },
];
