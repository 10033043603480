import React from "react";
import { Box } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { CustomCard } from "../../../../Components";

// Styles
import useStyles from "./styles";

function SocialProfilesSkeleton({ numberOfCards }) {
  const styles = useStyles();

  const skeletonCards = Array.from({ length: numberOfCards }, (_, index) => (
    <Box key={index} className={styles.socialItem}>
      <Skeleton
        variant="circle"
        width={25}
        height={25}
        style={{ minWidth: "25px", minHeight: "25px" }}
      />
      <Skeleton variant="text" width={80} height={20} />
      <Skeleton variant="text" width={"100%"} height={20} />
    </Box>
  ));

  return (
    <CustomCard
      boxShadow="xs"
      borderRadius="xl"
      borderColor="secondary"
      classes={styles.contactOverViewCard}
    >
      <Box className={styles.socialCardTitleWrapper}>
        <div className={styles.titleContainer}>
          <Skeleton
            variant="text"
            width={52.5}
            height={28}
            style={{ borderRadius: "4px" }}
            animation={false}
          />
        </div>

        <div>
          <Skeleton variant="rectangular" width={64} height={20} />
        </div>
      </Box>

      <Box className={styles.socialContainer}>{skeletonCards}</Box>
    </CustomCard>
  );
}

export default SocialProfilesSkeleton;
