import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => {
  return {
    container_Task_Card_Skeleton: {
      padding: "20px 16px",
      display: "flex",
      flexDirection: "column",
      width: "314px",
      gap: "2px",
      [theme.breakpoints.down(321)]: {
        width: "280px",
      },
    },
    header_card_Container: {
      display: "flex",
      flexDirection: "column",
    },
    container_Date: {
      display: "flex",
      flexDirection: "row",
      gap: "10px",
    },
    Status_Task_Container: {
      display: "flex",
      flexDirection: "row",
      gap: "6px",
      alignItems: "center",
      margin: "6px 0px 6px 0px",
    },
    Details_Task_Container: {
      display: "flex",
      flexDirection: "column",
    },
    Deatails_Row: {
      display: "flex",
      flexDirection: "row",
      gap: "6px",
      alignItems: "center",
    },
    container_Avatar_Value_Asignto: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      gap: "10px",
    },
    Progress_Task_Container: {
      marginTop: "5px",
      [theme.breakpoints.down(321)]: {
        width: "100%",
      },
    },
    Creator_Task_Container: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      marginTop: "4px",
      gap: "10px",
    },
  };
});
