
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import './LeadInfoStyle.scss';
import PropTypes from 'prop-types';

export const LeadInfo = ({ parentTranslationPath, translationPath , leadOwner  }) => {
  const { t } = useTranslation([parentTranslationPath, 'Shared']);

  

  return (
    <div>
   { leadOwner && leadOwner.leadAuditroId &&
     ( 
      <div className='leadInfo'>
      <div className='card'>
        <div>
          <span className='titleSection'>
            <span className='mdi mdi-account-multiple-outline' />
            {t('leadOwner')}
          </span>
        </div>
        <div className='leadInfoContant'>
          <div className='sectionItem'>
            <span className='mdi mdi-account-tie' />
            {' '}
              <a >
                <span className='navbar-item' activeClassName='is-active'>
                  { leadOwner && leadOwner.leadAuditroName } 
    
                </span>
              </a>
          </div>
        </div>
      </div>
     </div>
    )
  }  
    </div>
  );
};

LeadInfo.propTypes = {
  parentTranslationPath: PropTypes.string.isRequired,
  translationPath: PropTypes.string.isRequired,
  leadOwner: PropTypes.instanceOf(Object).isRequired,
};
