import React, { useEffect, useRef } from 'react';
import { GoogleMapsComponent, Spinner } from '../../../../Components';
import { bottomBoxComponentUpdate } from '../../../../Helper';

function MapView({
  locations,
  setMap,
  location,
  onPinClick,
  isLoading,
  totalCount,
}) {
  const scaleChangeTimer = useRef();

  useEffect(() => {
    bottomBoxComponentUpdate();
  }, []);
  const scaleChangeHandler = (newMap) => {
    if (scaleChangeTimer.current) {
      clearTimeout(scaleChangeTimer.current);
      scaleChangeTimer.current = undefined;
    }
    scaleChangeTimer.current = setTimeout(() => setMap(newMap), 1500);
  };

  useEffect(() => {
    setMap({
      Lat: '24.414833592365972',
      Long: '54.59777364239554',
      ZoomScale: '2',
    });
  }, []);
  const Content = ({ children }) => {
    return (
      <div className='d-flex d-flex-v-center fj-end px-3 w-50'>{children}</div>
    );
  };
  return (
    <div className='px-3'>
      <GoogleMapsComponent
        propertyView
        locations={locations ?? []}
        onScaleChange={scaleChangeHandler}
        hideLatitudeLongitude
        searchPlace={location}
        onPinClick={onPinClick} 
        reCenterOption
        isLoading={isLoading}
        loadingIndicator
        loadingOnContent={
          <Content>
            <strong>loading....</strong>
          </Content>
        }
        loadingOffContent={
          <Content>
            <strong>{`${totalCount} properties`}</strong>
          </Content>
        }
      />
    </div>
  );
}

export default MapView;
