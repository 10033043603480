import React, { useState , useEffect  } from 'react';
import { TabsComponent } from '../../../../Components';
import { tabsPermissionsHelper } from '../../../../Helper/ExceptionPermissions.Helper' ; 
import { DashboardListingTabs } from '../DashboardListingTabs/DashboardListingTabs';
import "./DashboardListingDubai.scss";

export const DashboardListingDubai = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [dashboardTabsList, setDashboardTabsList] = useState([]);


  const onTabChanged = (e, newTap) => {
    setActiveTab(newTap);
  };


  useEffect(() => {
   const  list =   tabsPermissionsHelper(DashboardListingTabs) ; 
   setDashboardTabsList(list) ; 

  }, [DashboardListingTabs]);


  return (
    <div className='mainTab'>
      <TabsComponent
        data={dashboardTabsList} 
        labelInput='label'
        themeClasses='theme-solid'
        currentTab={activeTab}
        onTabChanged={onTabChanged}
        dynamicComponentProps={{
          setActiveTab,
          dynamicComponentProps: Object.values(DashboardListingTabs).find(
            (element) => element.index === activeTab
          ),
        }}
      />
    </div>
  );
};
