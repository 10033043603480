import React from "react";

function FilterEqualIcon({ fill, ...restProps }) {
  return (
    <svg
      viewBox="0 0 16 16"
      fill={fill ?? ""}
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <path
        d="M3.13 5.371a.691.691 0 0 0-.378.307c-.061.103-.072.153-.072.322 0 .171.011.219.074.326.083.142.212.25.359.303.149.053 9.625.053 9.774 0a.693.693 0 0 0 .359-.303c.063-.107.074-.155.074-.326 0-.169-.011-.219-.072-.322a.677.677 0 0 0-.388-.308c-.175-.053-9.562-.051-9.73.001m0 4a.691.691 0 0 0-.378.307c-.061.103-.072.153-.072.322 0 .171.011.219.074.326.083.142.212.25.359.303.149.053 9.625.053 9.774 0a.693.693 0 0 0 .359-.303c.063-.107.074-.155.074-.326 0-.169-.011-.219-.072-.322a.677.677 0 0 0-.388-.308c-.175-.053-9.562-.051-9.73.001"
        fill={fill ?? ""}
      />
    </svg>
  );
}

export default FilterEqualIcon;
