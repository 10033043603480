import React, { useEffect, useCallback, useState, useReducer, useRef } from 'react';
import { Tables, Spinner, DatePickerComponent, AutocompleteComponent } from '../../../Components';
import { GetAllLostLeads, OrganizationUserSearch, GetLeads } from '../../../Services';
import { GetAdvanceSearchRotationScheme } from '../../../Services/RotaionSchemaService/RotationSchemaService';
import { bottomBoxComponentUpdate } from '../../../Helper';
import { PaginationComponent } from '../../../Components/PaginationComponent/PaginationComponent';
import { DateRangePickerComponent } from '../../../Components/Controls/DateRangePickerComponent/DateRangePickerComponent';

import moment from 'moment';

export const LostLeadsView = ({ }) => {
  const translationPath = '';
  const parentTranslationPath = 'UsersLoginHistoryView';
  const dateRangeDefault = {
    startDate: null,
    endDate: null,
    key: 'selection',
  };
  const searchTimer = useRef(null);
  const [isLoading, setIsLoading] = useState({
    leads: false,
    agents: false,
    lostLeads: false,
    rotationSchemas: false,
  });
  const [dateFilter, setDateFilter] = useState(dateRangeDefault);
  const [sortBy, setSortBy] = useState({});

  const reducer = useCallback((state, action) => {
    if (action.id !== 'edit') return { ...state, [action.id]: action.value };
    return {
      ...action.value,
    };
  }, []);
  const [data, setData] = useReducer(reducer, {
    lostLeads: [],
    agents: [],
    leads: [],
    rotationSchemas: [],
  });
  const [filter, setFilter] = useReducer(reducer, {
    pageIndex: 0,
    pageSize: 25,
    leadName: null,
    agentId: null,
    rotationSchemeId: null,
    assignmentDateFrom: null,
    assignmentDateTo: null,
  });

  const [selected, setSelected] = useReducer(reducer, {
    lead: null,
    agent: null,
    rotationSchema: null,
    assignmentDateFrom: null,
    assignmentDateTo: null,
  });

  const onPageIndexChanged = (newValue) => {
    setFilter({
      id: 'pageIndex',
      value: newValue,
    });
  };
  const onPageSizeChanged = (newValue) => {
    setFilter({
      id: 'pageSize',
      value: newValue,
    });
  };

  const getAllLostLeads = useCallback(async () => {
    setIsLoading((item) => ({ ...item, lostLeads: true }));

    const body = {
      ...filter,
      filterBy: sortBy?.filterBy,
      orderBy: sortBy?.orderBy,
      pageIndex: filter.pageIndex + 1,
    };

    const res = await GetAllLostLeads(body);
    if (!(res && res.status && res.status !== 200)) {
      setData({ id: 'lostLeads', value: res || {} });
    }
    setIsLoading((item) => ({ ...item, lostLeads: false }));
  }, [filter, sortBy]);


  const getAllAgents = useCallback(async () => {
    setIsLoading((item) => ({ ...item, agents: true }));

    const res = await OrganizationUserSearch({});
    if (!(res && res.status && res.status !== 200)) {
      setData({ id: 'agents', value: res?.result || [] });
    }
    setIsLoading((item) => ({ ...item, agents: false }));
  }, []);

  const getAllLeads = useCallback(
    async (value) => {
      setIsLoading((item) => ({ ...item, leads: true }));
      const response = await GetLeads({
        pageIndex: 0,
        pageSize: 10,
        search: value,
      });
      if (!(response && response.status && response.status !== 200))
        setData({ id: 'leads', value: (response && response.result) || [] });
      else setData({ id: 'leads', value: [] });

      setIsLoading((item) => ({ ...item, leads: false }));
    },
    [filter]
  );

  const getAllRotationSchemas = useCallback(async () => {
    setIsLoading((item) => ({ ...item, rotationSchemas: true }));
    const res = await GetAdvanceSearchRotationScheme({ pageSize: 25, pageIndex: 1 });
    if (!(res && res.status && res.status !== 200)) setData({ id: 'rotationSchemas', value: res?.result || [] });
    else setData({ id: 'rotationSchemas', value: [] });

    setIsLoading((item) => ({ ...item, rotationSchemas: false }));
  }, []);

  useEffect(() => {
    getAllLostLeads();
  }, [getAllLostLeads]);

  useEffect(() => {
    getAllRotationSchemas();
    getAllAgents();
    getAllLeads();
  }, []);


  useEffect(() => {
    bottomBoxComponentUpdate(
      <PaginationComponent
        pageIndex={filter.pageIndex}
        pageSize={filter.pageSize}
        totalCount={data?.lostLeads?.totalCount || 0}
        onPageIndexChanged={onPageIndexChanged}
        onPageSizeChanged={onPageSizeChanged}
      />
    );
  });
  useEffect(
    () => () => {
      bottomBoxComponentUpdate(null);
    }, []
  );

  return (
    <div className='lost-leads-wrapper'>
      <Spinner isActive={isLoading.lostLeads} isAbsolute />
      <div className='fa-end d-flex-column m-2'>
        <div className='d-flex w-50'>
          <div className='mr-2-reversed w-100'>
            <AutocompleteComponent
              idRef='LeadNameRef'
              isLoading={isLoading.leads}
              multiple={false}
              data={data.leads || []}
              value={selected.lead}
              chipsLabel={(option) => option?.lead?.contact_name?.name || ''}
              displayLabel={(option) => option?.lead?.contact_name?.name || ''}
              withoutSearchButton
              inputPlaceholder={`Lead`}
              parentTranslationPath={parentTranslationPath}
              translationPath={translationPath}
              onKeyDown={() => {
                setSelected({ id: 'lead', value: null });
              }}
              onInputKeyUp={(e) => {
                const searchText = e?.target?.value;
                if (searchTimer.current) clearTimeout(searchTimer.current);
                searchTimer.current = setTimeout(() => {
                  getAllLeads(searchText);
                }, 700);
              }}
              onChange={(event, newValue) => {
                setFilter({ id: 'leadName', value: newValue?.lead?.contact_name?.name || null })
                setSelected({ id: 'lead', value: newValue })
              }}
            />
          </div>
          <div className='mr-2-reversed w-100'>
            <AutocompleteComponent
              idRef='agentIdRef'
              isLoading={isLoading.agents}
              multiple={false}
              data={data.agents || []}
              value={selected.agent}
              chipsLabel={(option) => option.fullName || ''}
              displayLabel={(option) => option.fullName || ''}
              withoutSearchButton
              inputPlaceholder={`Agent`}
              parentTranslationPath={parentTranslationPath}
              translationPath={translationPath}
              onChange={(event, newValue) => {
                setFilter({ id: 'agentId', value: newValue?.id || null })
                setSelected({ id: 'agent', value: newValue })
              }}
            />
          </div>
        </div>
        <div className='d-flex my-2 w-50'>
          <div className='mr-2-reversed w-100'>
            <AutocompleteComponent
              idRef='rotationSchemaRef'
              inputPlaceholder={`Roration Schemes`}
              data={data.rotationSchemas || []}
              selectedValues={selected.rotationSchema}
              multiple={false}
              displayLabel={(option) =>
                (option && option.label) || ''}
              chipsLabel={(option) => (option && option.label) || ''}
              isLoading={isLoading.rotationSchemas}
              withoutSearchButton
              parentTranslationPath={translationPath}
              translationPath={''}
              onChange={(e, newValue) => {
                setFilter({ id: 'rotationSchemeId', value: newValue?.rotationSchemeId || null })
                setSelected({ id: 'rotationSchema', value: newValue })
              }}

            />
          </div>
          <div className='lost-leads-date mr-2-reversed w-100'>
            <DateRangePickerComponent
              idRef='daterangeRef'
              onClearClicked={() => setDateFilter(dateRangeDefault)}
              ranges={[dateFilter]}
              parentTranslationPath={parentTranslationPath}
              translationPath={translationPath}
              onDateChanged={(selectedDate) => {
                setDateFilter({ ...selectedDate.selection });

                const endDate = selectedDate?.selection?.endDate;
                const startDate = selectedDate?.selection?.startDate;

                setFilter({
                  id: 'edit',
                  value: {
                    ...filter,
                    assignmentDateFrom: startDate ? moment(startDate).startOf('day').format('YYYY-MM-DDTHH:mm:ss') : null,
                    assignmentDateTo: endDate ? moment(endDate).endOf('day').format('YYYY-MM-DDTHH:mm:ss') : null,
                  }
                });
              }}
            />
           
          </div>
        </div>
      </div>
      <div className='m-4'>
        <Tables
          data={data?.lostLeads?.result || []}
          headerData={[
            {
              id: 1,
              label: 'Lead Id',
              input: 'leadId',
              isSortable: true,
            },
            {
              id: 2,
              label: 'Lead Name',
              input: 'lostLeadName',
              isSortable: true,
            },
            {
              id: 3,
              label: 'Lead Assignment Date',
              input: 'createdOn',
              component: (item) => (
                <span>{item.assignmentDate && moment(item.assignmentDate).format("DD/MM/YYYY")}</span>
              ),
              isDate: true,
              dateFormat: 'DD/MM/YYYY',
              isSortable: true,
            },
            {
              id: 4,
              label: 'Agent Name',
              input: 'agentName',
              isSortable: true,
            },
            {
              id: 5,
              label: 'Rotation Schema',
              component: (item) => (
                <span>
                  {item.agentSchemaMatchingDtos &&
                    item.agentSchemaMatchingDtos.map((item) => `${item.rotationSchemeName},`)}
                </span>
              ),
              // isSortable: true,
            },
          ]}
          setSortBy={setSortBy}
          itemsPerPage={filter.pageSize}
          activePage={filter.pageIndex}
          translationPath={translationPath}
          parentTranslationPath={parentTranslationPath}
          defaultActions={[]}
          onPageIndexChanged={onPageIndexChanged}
          onPageSizeChanged={onPageSizeChanged}
          totalItems={data?.lostLeads?.totalCount || 0}
        />
      </div>
    </div>
  );
};
