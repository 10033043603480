
export const CommunicationActivitiesLogPermissions = {
  GetAllLostLeadsLog: {
    permissionsId: "fec9413a-7bbe-4ea6-2b14-08dbca2fdea8",
    permissionsName: "Get All Communication Activities Log",
    Description: null,
    componentsId: null,
    components: null
  }
};
