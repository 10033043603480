import React, { useState } from "react";
import { makeStyles } from '@material-ui/core/styles';
import { Box, Typography, List, ListItem, ListItemText, Button } from '@material-ui/core';
import FormattedMessage from "../../../../SharedComponents/FormattedComponent/FormattedMessage";

const parentTranslationPath = "LearningAndDevelopmentView";

const useStyles = makeStyles({
  parentList: {
    paddingTop: "0px",
    '& .MuiListItem-root': {
      paddingBottom: "0px",
    },
  },
  subList: {
    listStyleType: 'disc',
    marginLeft: '20px',
    '& .MuiListItem-root': {
      paddingInlineStart: "0px",
      display: 'list-item',
    },
  },
});

const MAX_WORDS_SIZE = 30;

export function CourseDialog({ data }) {
  const [showFullDescription, setShowFullDescription] = useState(false);
  const [showFullOverview, setShowFullOverview] = useState(false);
  const classes = useStyles();

  const getContent = (content = "", showFullText) => {
    const allContent = content && content.split(" ");
    return allContent && allContent.length > MAX_WORDS_SIZE && !showFullText
      ? `${allContent.slice(0, MAX_WORDS_SIZE).join(" ")}...`
      : content;
  };

  return (
    <Box>
      <List aria-label="course details" className={classes.parentList}>
        <ListItem>
          <ListItemText primary={<Typography><b>
          <FormattedMessage id="course-name" path={parentTranslationPath} />
          </b> {data?.courseName}</Typography>} />
        </ListItem>
        <ListItem>
          <ListItemText 
            primary={
              <>
                <Typography><b>
                <FormattedMessage id="course-description" path={parentTranslationPath} />
                </b> {getContent(data?.courseDescription, showFullDescription)}</Typography>
                {data?.courseDescription?.split(" ").length > MAX_WORDS_SIZE && (
                  <Button size="small" onClick={() => setShowFullDescription(!showFullDescription)} color="primary">
                    {showFullDescription ? <FormattedMessage id={"show-less"} path={parentTranslationPath} /> : <FormattedMessage id={"show-more"} path={parentTranslationPath} />}
                  </Button>
                )}
              </>
            } 
          />
        </ListItem>
        <ListItem>
          <ListItemText 
            primary={
              <>
                <Typography><b>
                    <FormattedMessage id="overview-description" path={parentTranslationPath} />
                </b> {getContent(data?.overviewDescription, showFullOverview)}</Typography>
                {data?.overviewDescription?.split(" ").length > MAX_WORDS_SIZE && (
                  <Button size="small" onClick={() => setShowFullOverview(!showFullOverview)} color="primary">
                    {showFullOverview ? <FormattedMessage id={"show-less"} path={parentTranslationPath} /> : <FormattedMessage id={"show-more"} path={parentTranslationPath} />}
                  </Button>
                )}
              </>
            } 
          />
        </ListItem>
        <ListItem style={{ paddingBottom: 0 }}>
          <ListItemText primary={
            <Typography>
              <b>
                  <FormattedMessage id="modules" path={parentTranslationPath} />
              </b>
              <List className={classes.subList}>
                {data?.courseModuleName?.map((module, idx) => (
                  <ListItem key={idx}>
                    <ListItemText primary={module} />
                  </ListItem>
                ))}
              </List>
            </Typography>
          } />
        </ListItem>
        <ListItem>
          <ListItemText primary={<Typography><b>
            <FormattedMessage id="terms-and-conditions" path={parentTranslationPath} />
          </b> Lorem Ipsum is simply dummy text of the printing</Typography>} />
        </ListItem>
        <ListItem>
          <ListItemText 
            primary={
              <a
                href={data?.courseLink}
                target="_blank" 
                rel="noopener noreferrer" 
                style={{ textDecoration: 'none', color: 'inherit', fontWeight: 'bold' }}
                className="c-primary"
              >
                <FormattedMessage id="course-link" path={parentTranslationPath} />
              </a>
            }
          />
        </ListItem>
      </List>
    </Box>
  );
}
