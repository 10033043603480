import React, { useEffect, useRef, useState } from 'react';
import { AutocompleteComponent, DialogComponent  , Spinner} from '../../../../Components';
import { ButtonBase, DialogActions } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import {
  GetAgentsForConvoloRotationAPI , 
  AssignAgentToConvoloRotationScheme
} from '../../../../Services';
import { showError, showSuccess  } from '../../../../Helper';

 export function AssignConvoloAgentsDialog({
  isOpen , 
  onClose,
  onSave , 
  parentTranslationPath  , 
  translationPath ,
  convoloRotationSchemeId , 
}) {
  const { t } = useTranslation(parentTranslationPath);
  const searchTimer = useRef(null);

  const [selectConvoloAgent, setSelectConvoloAgent ] = useState(null);
  const [allAgentsForConvoloRotation , setAllAgentsForConvoloRotation] = useState({result : [] , totalCount :0 }) ;
  const [isLoading ,setIsLoading] = useState({isLoadingSave : false , isLoadingAgents : false }); 

  const GetAgentsForConvoloRotation = async (search) => {
    setIsLoading((item)=> ({...item , isLoadingAgents : true }));

    const result = await GetAgentsForConvoloRotationAPI({pageIndex : 1 , pageSize : 50  , agentName : search || ''});
    if (!(result && result.status && result.status !== 200)) 
      {
        setAllAgentsForConvoloRotation({
        result: result.result,
        totalCount: result.totalCount,
      });
    } else {
      setAllAgentsForConvoloRotation({ res: [], totalCount: 0 });
    }

    setIsLoading((item)=> ({...item , isLoadingAgents : false }));
  };

  const  saveHandler = async () => {
    setIsLoading((item)=> ({...item , isLoadingSave : true }));
    if(!convoloRotationSchemeId ||  !(selectConvoloAgent?.agentId))
    {
      showError(t('please-select-convolo-agent'));
      setIsLoading((item)=> ({...item , isLoadingSave : false }));
      return ; 

    }
    const result =   await   AssignAgentToConvoloRotationScheme(convoloRotationSchemeId , selectConvoloAgent && selectConvoloAgent.agentId )  ;
    if (!(result && result.status && result.status !== 200)) {
      showSuccess(t('assign-convolo-agent-add-sucessfully'));
      onSave();
    } else {
      setIsLoading((item)=> ({...item , isLoadingSave : false }));
      showError(t('assign-convolo-agent-add-failed'));
    }
    setIsLoading((item)=> ({...item , isLoadingSave : false }));
  };

  useEffect(() => {
    GetAgentsForConvoloRotation();
  }, []);


  return (
    <>
      <Spinner isActive={(isLoading.isLoadingSave) } isAbsolute />
      <DialogComponent
        dialogTitle={t('assign-convolo-agent')}
        isOpen={isOpen}
        maxWidth='sm'
        dialogContent={
          <>
            <div className='my-4'>
              <AutocompleteComponent
                idRef='AssignConvoloAgentRef'
                labelValue={t(`${translationPath}select-convolo-agent`)}
                isDisabled={isLoading.isLoadingAgents}
                multiple={false}
                selectedValues={selectConvoloAgent}
                data={allAgentsForConvoloRotation.result || []}
                displayLabel={(option) => option.agentName || ''}
                renderOption={(option) => option.agentName || ''}
                withoutSearchButton
                isLoading={isLoading.isLoadingAgents}
                parentTranslationPath={parentTranslationPath}
                translationPath={translationPath}
                onChange={(event, newValue) => 
                {
                  setSelectConvoloAgent(newValue);
                }}
                onInputKeyUp={(event) => {
                  const { value } = event.target;
                  if (searchTimer.current) clearTimeout(searchTimer.current);
                  searchTimer.current = setTimeout(() => {
                    GetAgentsForConvoloRotation(value);
                  }, 700);
                }}
                helperText={t(`${translationPath}select-convolo-agent`)}
                error={!selectConvoloAgent}
                isWithError
              />
            </div>

            <DialogActions>
              <div className=''>
                <ButtonBase
                  className='MuiButtonBase-root btns bg-cancel'
                  onClick={onClose}
                >
                  {t(`${translationPath}cancel`)}
                </ButtonBase>
                <ButtonBase
                  className='MuiButtonBase-root btns theme-solid '
                  onClick={()=> saveHandler()}
                  disabled={!selectConvoloAgent}
                >
                  {t(`${translationPath}save`)}
                </ButtonBase>
              </div>
            </DialogActions>
          </>
        }
      />
    </>




  );
}

