import React, { useEffect, useState } from 'react';
import './TaskDetailsView.scss';
import '../KenbanBoardView/KenbanBoardView.scss';
import { ButtonBase } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useTitle } from '../../../../Hooks';
import { Tabs, Tab, makeStyles } from '@material-ui/core';
import { sideMenuComponentUpdate, sideMenuIsOpenUpdate, GetParams, GlobalHistory, showError } from '../../../../Helper';
import { TaskDetailsTab, CampaignDetailsTab, UploadedTaskDetailsTab } from './TaskDetailsTabs';
import { bottomBoxComponentUpdate } from '../../../../Helper/Middleware.Helper';
import { GetTaskDetails, ApproveACampaignTask } from '../../../../Services';
import {CampaignTaskRejectDialog} from '../Utilities/TaskDetailsSideMenu/CampaignTaskRejectDialog'

export const TaskDetailsView = () => {
  const parentTranslationPath = 'Campaign';
  const translationPath = 'campaign-task.';
  const { t } = useTranslation(parentTranslationPath);

  useTitle(t(`${translationPath}task-details`));

  const useStyles = makeStyles((theme) => ({
    tabsRoot: {
      height: '40px',
      minHeight: '40px',
      textTransform: 'capitalize',
      marginBottom: '1.25rem',
    },
    indicator: {
      backgroundColor: '#498a8b',
    },
    selectedTab: {
      color: '#498a8b !important',
      fontWeight: 600,
    },
    tabRoot: {
      minWidth: 'fit-content',
      padding: 0,
      marginRight: 16,
      fontSize: '12px',
      textTransform: 'capitalize',
    },
  }));

  const sideMenuButtons = {
      Reject: {
        key: 'Reject',
        buttonClasses: 'marketing-buttons outlined',
        isDisabled: false,
      },
      Approve: {
        key: 'Approve',
        buttonClasses: 'marketing-buttons solid',
        isDisabled: false,
      },
  };

  const classes = useStyles();

  const taskId = +GetParams('id');
  const [tabIndex, setTabIndex] = useState(0);
  const [allowedActions, setAllowedActions] = useState([]);
  const [openRejectDialog, setOpenRejectDialog] = useState(false);

  const handleTabClick = (event, newValue) => {
    setTabIndex(newValue);
  };


  const actionButtonClickHandler = (actionKey) => {
    const approveHandler = () => approveACampaignTask();
    const rejectHandler = () => setOpenRejectDialog(true);

    if (actionKey === sideMenuButtons.Approve?.key) approveHandler();
    else if (actionKey === sideMenuButtons.Reject?.key) rejectHandler();
  };


  const getAllowedActions = (data) => {
    let allowedActionsLocal = { ...sideMenuButtons};
      if (data?.showApproveButton === false) allowedActionsLocal['Approve'].isDisabled = true;
      if (data?.showRejectButton === false) allowedActionsLocal['Reject'].isDisabled = true;

    setAllowedActions(Object.values(allowedActionsLocal));
  };

  
  const getTaskDetails = async () => {
    const res = await GetTaskDetails({
      taskId,
    });
    if (!(res && res.status && res.status !== 200)) {
      getAllowedActions(res);
    }
  };


  const approveACampaignTask = async () => {

    const res = await ApproveACampaignTask(taskId);
    if (!(res && res.status && res.status !== 200)) {
      reloadData();
    } else {
      showError(t(`${translationPath}proccess-failed`));
    }

  };

  const reloadData = () => {
    GlobalHistory.push(`/home/kenban-board/view`);
  }

  useEffect(() => {
    getTaskDetails();
  }, []);



  useEffect(() => {
    sideMenuComponentUpdate(null);
    sideMenuIsOpenUpdate(false);
    bottomBoxComponentUpdate(null);

  }, []);

  useEffect(() => {
      bottomBoxComponentUpdate(
        <div className='marketing-module-view d-flex-v-center-h-between d-flex-row-reverse'>
          <div className=' d-flex fj-end'>
            {allowedActions &&
              allowedActions.map((item) => (
                <ButtonBase
                  onClick={() => actionButtonClickHandler(item.key)}
                  disabled={item.isDisabled}
                  className={`${item.buttonClasses} ${item.isDisabled ? 'disabled' : ''}`}>
                  <span>{t(`${translationPath}${item.key}`)}</span>
                </ButtonBase>
              ))}
          </div>
      </div>
    );
  });

  return (
    <div className='marketing-module-view TaskDetailsView px-4 py-3'>
      <p className='fw-simi-bold fz-30'>{t(`${translationPath}Task`)}</p>
      <div className='mx-2'>
        <Tabs
          value={tabIndex}
          onChange={handleTabClick}
          aria-label='icon label tabs'
          textColor='primary'
          classes={{
            root: classes.tabsRoot,
            indicator: classes.indicator,
          }}>
          <Tab
            classes={{
              root: classes.tabRoot,
              selected: classes.selectedTab,
            }}
            label={t(`${translationPath}Task`)}
          />
          <Tab
            classes={{
              root: classes.tabRoot,
              selected: classes.selectedTab,
            }}
            label={t(`${translationPath}Campaign-details`)}
          />
          <Tab
            classes={{
              root: classes.tabRoot,
              selected: classes.selectedTab,
            }}
            label={t(`${translationPath}Task-deliverable`)}
          />
        </Tabs>

        {tabIndex === 0 && (
          <TaskDetailsTab
            parentTranslationPath={parentTranslationPath}
            translationPath={translationPath}
          />
        )}
        {tabIndex === 1 && (
          <CampaignDetailsTab
            parentTranslationPath={parentTranslationPath}
            translationPath={translationPath}
          />
        )}
        {tabIndex === 2 && (
          <UploadedTaskDetailsTab
            parentTranslationPath={parentTranslationPath}
            translationPath={translationPath}
          />
        )}
      </div>
      {openRejectDialog &&
        <CampaignTaskRejectDialog
          isOpen={openRejectDialog}
          close={() => {
            setOpenRejectDialog(false);
          }}
          taskId={taskId}
          reloadData={reloadData}
        />
      }
    </div>
  );
};
