import React from "react";

function UnqualifiedIcon({ fill, ...restProps }) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill={fill ?? ""}
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <path
        d="M7.833 1.704a4.723 4.723 0 0 0-1.752.568c-1.155.66-1.954 1.766-2.237 3.095-.085.399-.085 1.367 0 1.766.432 2.027 2.039 3.471 4.083 3.668 2.078.2 4.059-1.09 4.734-3.084.182-.537.222-.805.222-1.467 0-.453-.014-.67-.06-.883-.428-2.006-2.032-3.463-4.037-3.664a3.707 3.707 0 0 0-.953.001m1.299 1.729c.76.218 1.461.803 1.818 1.517a2.88 2.88 0 0 1 .283 1.6c-.044.422-.11.655-.283 1-.36.72-1.058 1.3-1.836 1.523-.224.064-.342.076-.781.076-.438 0-.556-.012-.781-.076-.922-.265-1.717-1.024-2.001-1.911a3.316 3.316 0 0 1-.015-1.779c.299-1.007 1.226-1.823 2.292-2.018.278-.051 1.027-.012 1.304.068m-4.549 8.69a4.132 4.132 0 0 0-3.635 3.222c-.073.344-.122 1.141-.109 1.792.01.516.019.594.083.713.163.306.417.464.745.464s.583-.159.744-.464c.065-.123.072-.204.092-1.067.012-.513.04-1.016.062-1.116.022-.101.113-.334.201-.518a2.46 2.46 0 0 1 1.229-1.19c.43-.195.467-.197 3.455-.215 2.661-.016 2.738-.019 2.893-.085a.827.827 0 0 0 .441-.505c.062-.209.062-.267-.002-.482a.79.79 0 0 0-.432-.494l-.167-.077-2.733.005c-1.503.003-2.793.011-2.867.017m8.89.44a.803.803 0 0 0-.539.77c0 .329.077.436.946 1.3l.787.783-.799.8c-.87.872-.933.959-.934 1.284 0 .362.233.681.572.782.217.064.272.064.496-.002.169-.05.236-.108 1.007-.874l.825-.82.825.821c.771.767.836.824 1.005.873.224.066.281.066.488.004a.815.815 0 0 0 .58-.784c0-.325-.063-.412-.933-1.284l-.799-.8.787-.783c.588-.585.807-.825.866-.95a.817.817 0 0 0-.492-1.132c-.217-.064-.272-.064-.497.002-.169.05-.234.106-1.006.874l-.825.821-.825-.821c-.771-.768-.836-.824-1.006-.874-.229-.067-.31-.065-.529.01"
        fill-rule="evenodd"
        fill={fill ?? ""}
      />
    </svg>
  );
}

export default UnqualifiedIcon;
