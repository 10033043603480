import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Inputs } from "../../../../../../../Components";
import { Avatar } from "@material-ui/core";
import { getFirstLastNameLetters } from "../../../../../../../Helper";

export const CommissionSection = ({
  isReadOnly,
  selected,
  onSelectedChanged,
  parentTranslationPath,
  translationPath,
}) => {
  const { t } = useTranslation(parentTranslationPath);

  return (
    <div>
      <div className="d-flex fj-start b-bottom">
        <div className="w-33 my-2 pt-1">
          <span className="fw-simi-bold">
            {t(`${translationPath}Landlord-agent-commission`)}
          </span>
        </div>
        <div className="d-flex-column gap-8  d-flex-default w-50 mt-2 mb-3 pt-1">
          {selected.agents &&
            selected.agents.map((item, index) => (
              <div className="d-flex  gap-8">
                <div className="agent_commission_widget w-50">
                  <Avatar
                    classes={{ root: `fz-12 team-avatar xs-theme pointer` }}
                    src={""}
                  >
                    {getFirstLastNameLetters(item.fullName || "")}
                  </Avatar>
                  <span className="fw-simi-bold light-text fz-12 ecilipse-text">{item.fullName || ""}</span>
                </div>
                <Inputs
                  idRef="agent-commission"
                  wrapperClasses="w-25 m-0"
                  inputPlaceholder={`0`}
                  value={selected.agents[index]?.agentCommission || 0}
                  withNumberFormat
                  endAdornment={<span className="px-2">%</span>}
                  type="number"
                  min={0}
                  max={100}
                  isWithError
                  // isSubmitted={isSubmitted}
                  parentTranslationPath={parentTranslationPath}
                  translationPath={translationPath}
                  onInputChanged={(event) => {
                    const { value } = event.target;
                    const localAgents = [...selected.agents];
                    // const changedAgentIndex = localAgents.findIndex((agent) => agent.userId === item.id);
                    // const currentAgent = localAgents[index];
                    localAgents[index] = {
                      ...item,
                      agentCommission: value,
                    };
                    onSelectedChanged({ id: "agents", value: localAgents });
                  }}
                  isDisabled={isReadOnly}
                />
              </div>
            ))}
        </div>
      </div>
      <div className="d-flex fj-start b-bottom">
        <div className="w-33 my-2 pt-1">
          <span className="fw-simi-bold">
            {t(`${translationPath}Tenant-agent-commission`)}
          </span>
        </div>
        <div className="d-flex-column gap-8  d-flex-default w-50 mt-2 mb-3 pt-1">
          {selected.listingAgents &&
            selected.listingAgents.map((item, index) => (
              <div className="d-flex gap-8">
                <div className="agent_commission_widget w-50">
                  <Avatar
                    classes={{ root: `fz-12 team-avatar xs-theme pointer` }}
                    src={""}
                  >
                    {getFirstLastNameLetters(item.fullName || "")}
                  </Avatar>
                  <span className="fw-simi-bold light-text ecilipse-text fz-12">{item.fullName || ""}</span>
                </div>
                <Inputs
                  withNumberFormat
                  idRef="listing-agent-commission"
                  wrapperClasses="w-25 m-0"
                  inputPlaceholder={`0`}
                  value={
                    selected.listingAgents[index]?.listingAgentCommission || 0
                  }
                  endAdornment={<span className="px-2">%</span>}
                  type="number"
                  min={0}
                  max={100}
                  isWithError
                  // isSubmitted={isSubmitted}
                  parentTranslationPath={parentTranslationPath}
                  translationPath={translationPath}
                  onInputChanged={(event) => {
                    const { value } = event.target;
                    const localListingAgents = [...selected.listingAgents];

                    localListingAgents[index] = {
                      ...item,
                      listingAgentCommission: value,
                    };
                    onSelectedChanged({
                      id: "listingAgents",
                      value: localListingAgents,
                    });
                  }}
                  isDisabled={isReadOnly}
                />
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

CommissionSection.propTypes = {};
