import React, { useEffect, useRef, useState } from "react";
import Joi from "joi";
import {
  AutocompleteComponent,
  DialogComponent,
  Inputs,
  Spinner,
  SwitchComponent,
} from "../../../../Components";
import { ButtonBase, DialogActions } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import {
  GetAllCampaigns,
  CreateConvoloRotationSchemeAPI,
  GetConvoloRotationSchemeByIdAPI,
  UpdateConvoloRotationSchemeAPI,
} from "../../../../Services";
import { showError, showSuccess, getErrorByName } from "../../../../Helper";

export function ConvoloRotationSchemeDialog({
  isOpen,
  onClose,
  onSave,
  convoloRotationSchemeItem,
  parentTranslationPath,
  translationPath,
}) {
  const { t } = useTranslation(parentTranslationPath);
  const searchTimer = useRef(null);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [convoloAgent, setConvoloAgent] = useState({
    convoloRotationSchemeName: "",
    propertyCampaignId: null,
    isActive: false,
  });

  const [selectConvoloAgent, setSelectConvoloAgent] = useState({
    propertyCampaign: null,
  });

  const schema = Joi.object({
    convoloRotationSchemeName: Joi.string()
      .required()
      .messages({
        "string.base": t(
          `${translationPath}convolo-rotation-scheme-name-is-required`
        ),
        "string.empty": t(
          `${translationPath}convolo-rotation-scheme-name-is-required`
        ),
      }),
    propertyCampaignId: Joi.any()
      .required()
      .messages({
        "any.base": t(`${translationPath}property-campaign-is-required`),
        "any.empty": t(`${translationPath}property-campaign-is-required`),
        "any.required": t(`${translationPath}property-campaign-is-required`),
      }),
  })
    .options({
      abortEarly: false,
      allowUnknown: true,
    })
    .validate(convoloAgent);

  const [allCampaigns, setAllCampaigns] = useState({
    result: [],
    totalCount: 0,
  });
  const [isLoading, setIsLoading] = useState({
    isLoadingSave: false,
    isLoadingCampaigns: false,
  });

  const saveConvoloRotationScheme = async () => {
    setIsLoading((item) => ({ ...item, isLoadingSave: true }));
    const result = !convoloRotationSchemeItem
      ? await CreateConvoloRotationSchemeAPI(convoloAgent)
      : await UpdateConvoloRotationSchemeAPI(
          convoloAgent && convoloAgent.convoloRotationSchemeId,
          convoloAgent
        );
    if (!(result && result.status && result.status !== 200)) {
      showSuccess(
        !convoloRotationSchemeItem
          ? t("convolo-Rotation-scheme-add-sucessfully")
          : t("convolo-Rotation-scheme-update-sucessfully")
      );
      onSave();
    } else {
      showError(
        !convoloRotationSchemeItem
          ? t("convolo-rotation-scheme-create-failed")
          : t("convolo-rotation-scheme-update-failed")
      );
    }
    setIsLoading((item) => ({ ...item, isLoadingSave: false }));
  };

  const GetAllCampaignsAPI = async (search) => {
    setIsLoading(true);
    const result = await GetAllCampaigns({
      pageIndex: 1,
      pageSize: 50,
      propertyCampaignName: search || "",
    });
    if (result?.status !== 200) {
      setAllCampaigns({
        result: result.result,
        totalCount: result.totalCount,
      });
    } else {
      setAllCampaigns({ res: [], totalCount: 0 });
    }
    setIsLoading(false);
  };

  const GetConvoloRotationSchemeById = async () => {
    setIsLoading((item) => ({ ...item, isLoadingSave: true }));
    const result = await GetConvoloRotationSchemeByIdAPI(
      convoloRotationSchemeItem?.convoloRotationSchemeId
    );
    if (!(result && result.status && result.status !== 200)) {
      setConvoloAgent(result);
      setSelectConvoloAgent((item) => ({
        ...item,
        propertyCampaign: result.propertyCampaignId
          ? {
              propertyCampaignId: result.propertyCampaignId,
              name: result.propertyCampaignName,
            }
          : null,
      }));
    } else {
      setConvoloAgent({
        convoloRotationSchemeName: "",
        propertyCampaignId: null,
        isActive: false,
      });
      setSelectConvoloAgent((item) => ({ ...item, propertyCampaign: null }));
    }
    setIsLoading((item) => ({ ...item, isLoadingSave: false }));
  };

  useEffect(() => {
    GetAllCampaignsAPI();
  }, []);

  useEffect(() => {
    if (convoloRotationSchemeItem) GetConvoloRotationSchemeById();
  }, [convoloRotationSchemeItem]);

  return (
    <>
      <Spinner isActive={isLoading.isLoadingSave} isAbsolute />
      <DialogComponent
        dialogTitle={
          convoloRotationSchemeItem
            ? t("edit-convolo-agent")
            : t("add-convolo-agent")
        }
        isOpen={isOpen}
        maxWidth="sm"
        dialogContent={
          <>
            <div className="my-4">
              <Inputs
                idRef="convoloRotationSchemeNameRef"
                labelValue="convoloRotationSchemeName"
                value={convoloAgent.convoloRotationSchemeName || ""}
                helperText={
                  getErrorByName(schema, "convoloRotationSchemeName").message
                }
                error={
                  getErrorByName(schema, "convoloRotationSchemeName").error
                }
                isWithError
                isSubmitted={isSubmitted}
                parentTranslationPath={parentTranslationPath}
                translationPath={translationPath}
                onInputChanged={(event) => {
                  let { value } = event.target;
                  setConvoloAgent((item) => ({
                    ...item,
                    convoloRotationSchemeName: value,
                  }));
                }}
              />
              <AutocompleteComponent
                idRef="campaignRef"
                labelValue={t(`${translationPath}select-campaign`)}
                multiple={false}
                selectedValues={selectConvoloAgent.propertyCampaign}
                data={allCampaigns.result || []}
                displayLabel={(option) => option.name || ""}
                renderOption={(option) => option.name || ""}
                withoutSearchButton
                withLoader
                isLoading={isLoading}
                onChange={(event, newValue) => {
                  setConvoloAgent((item) => ({
                    ...item,
                    propertyCampaignId: newValue?.propertyCampaignId,
                  }));
                  setSelectConvoloAgent((item) => ({
                    ...item,
                    propertyCampaign: newValue,
                  }));
                }}
                helperText={
                  getErrorByName(schema, "propertyCampaignId").message
                }
                error={getErrorByName(schema, "propertyCampaignId").error}
                isWithError
                isSubmitted={isSubmitted}
                parentTranslationPath={parentTranslationPath}
                translationPath={translationPath}
                onInputKeyUp={(event) => {
                  const { value } = event.target;
                  if (searchTimer.current) clearTimeout(searchTimer.current);
                  searchTimer.current = setTimeout(() => {
                    GetAllCampaignsAPI(value);
                  }, 700);
                }}
              />
              <div className="mt-2 px-2">
                <SwitchComponent
                  idRef={`convoloAgentIsActiveRef`}
                  isChecked={convoloAgent.isActive || false}
                  labelClasses="px-0"
                  onChangeHandler={() => {
                    setConvoloAgent((item) => ({
                      ...item,
                      isActive: !item.isActive,
                    }));
                  }}
                  themeClass="theme-line"
                  labelValue={
                    (convoloAgent.isActive && `${t("Active")}`) ||
                    `${t("Deactive")}`
                  }
                />
              </div>
            </div>

            <DialogActions>
              <div className="unit-template-action-btns">
                <ButtonBase
                  className="MuiButtonBase-root btns bg-cancel"
                  onClick={onClose}
                >
                  {t(`${translationPath}cancel`)}
                </ButtonBase>
                <ButtonBase
                  className="MuiButtonBase-root btns theme-solid "
                  onClick={saveConvoloRotationScheme}
                  disabled={schema.error}
                >
                  {t(`${translationPath}save`)}
                </ButtonBase>
              </div>
            </DialogActions>
          </>
        }
      />
    </>
  );
}
