import { useEffect } from "react";
import { useSelector } from "react-redux";

const useNewLayoutStyles = () => {
  const { isDarkMode } = useSelector((store) => store.theme);

  useEffect(() => {
    document.body.classList.add("new-layout");

    if (isDarkMode) {
      document.body.classList.add("new-layout__dark-mode");
    } else {
      document.body.classList.remove("new-layout__dark-mode");
    }

    return () => {
      document.body.classList.remove("new-layout");
      document.body.classList.remove("new-layout__dark-mode");
    };
  }, [isDarkMode]);
};

export { useNewLayoutStyles };
