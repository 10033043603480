import React from "react";

function LinkIcon({ fill, ...restProps }) {
  return (
    <svg
      viewBox="0 0 24 24"
      fill={fill ?? ""}
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <path
        d="M16.06 1.138c-.705.119-1.06.228-1.657.508-.757.354-1.099.618-2.28 1.76-1.316 1.272-1.364 1.335-1.362 1.787a.975.975 0 0 0 .996.975c.124 0 .296-.029.381-.065.103-.043.496-.401 1.178-1.075 1.071-1.059 1.336-1.277 1.864-1.54a4.015 4.015 0 0 1 3.349-.084 4.065 4.065 0 0 1 1.937 1.783c.354.673.473 1.224.443 2.051-.017.471-.041.65-.127.94-.127.429-.39.947-.659 1.302-.108.143-.968 1.027-1.91 1.964-1.812 1.802-1.985 1.949-2.612 2.221-.575.249-.866.307-1.561.311-.685.004-.961-.043-1.51-.259a4.025 4.025 0 0 1-1.6-1.163c-.312-.373-.506-.501-.811-.537a.987.987 0 0 0-1.063 1.29c.135.409 1.008 1.297 1.714 1.746a5.982 5.982 0 0 0 5.926.302c.809-.403 1.015-.579 3.008-2.572 2.001-2 2.168-2.196 2.583-3.013 1.038-2.047.821-4.47-.566-6.33A7.222 7.222 0 0 0 20.6 2.323a6.101 6.101 0 0 0-2.432-1.125c-.501-.108-1.634-.14-2.108-.06m-6.9 6.924c-1.221.171-2.288.682-3.249 1.555-.823.747-3.448 3.433-3.698 3.783a6.02 6.02 0 0 0-1.128 3.063 5.98 5.98 0 0 0 3.919 6.1 5.567 5.567 0 0 0 2.036.356 5.83 5.83 0 0 0 3.06-.819c.462-.267.857-.608 1.875-1.616 1.169-1.158 1.264-1.283 1.264-1.664 0-.37-.269-.777-.608-.918-.191-.08-.591-.08-.782 0-.095.04-.506.416-1.149 1.053-.55.545-1.122 1.082-1.271 1.194a4.44 4.44 0 0 1-1.29.636c-.335.103-.407.11-1.079.11-.669 0-.745-.008-1.08-.109a4.122 4.122 0 0 1-2.018-1.333 3.905 3.905 0 0 1-.824-1.773c-.101-.498-.064-1.372.078-1.852a4.47 4.47 0 0 1 .555-1.168c.177-.265 3.388-3.517 3.753-3.801a4.234 4.234 0 0 1 1.596-.766c.221-.053.453-.071.88-.07.731.002 1.118.09 1.745.396a3.88 3.88 0 0 1 1.322 1.023c.315.377.523.515.821.545a.99.99 0 0 0 1.056-1.294c-.14-.423-1.041-1.331-1.753-1.767-1.25-.764-2.633-1.061-4.031-.864"
        fill-rule="evenodd"
        fill={fill ?? ""}
      />
    </svg>
  );
}

export default LinkIcon;
