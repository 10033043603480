import React, { useEffect } from 'react';
import { SwitchRoute } from '../../../Components/Route/SwitchRoute';
import { LeadOwnerUnitLeaseRoutes } from '../../../routes';

export const LeadOwnerUnitLeaseLayout = () => {
  useEffect(() => {
    return () => {
      localStorage.removeItem("leadOwnerUnitLeaseCurrentPageIndex");
    }
  }, []);
  return (
    <SwitchRoute routes={LeadOwnerUnitLeaseRoutes} />
  );
};

