
import React, { useState , useCallback  , useEffect  , useReducer }  from 'react';
import { useTranslation } from 'react-i18next';
import Button from "@material-ui/core/Button";
import { PageHeading } from "../../../../../SharedComponents";
import { Spinner } from '../../../../../Components' ; 
import { showError  , showSuccess  , bottomBoxComponentUpdate } from '../../../../../Helper' ; 
import { PropertyViewsContent } from './PropertyViewsContent' ; 
import { GetAllPropertyViewScoresAPI , UpdatePropertyRatingViewScoresAPI  } from '../../../../../Services' ; 

  export const PropertyViewsView = ({parentTranslationPath , translationPath  , ratingPercentage} ) => { 
    const { t } = useTranslation(parentTranslationPath);

    const[isLoading ,setIsLoading] = useState(false);
    const [propertyViewsRatingPercentage ,setPropertyViewsRatingPercentage]  = useState(0) ;
    const reducer = (state, action) => {
        if (action.id !== 'edit') return { ...state, [action.id]: action.value };
        if (action.id === 'edit') {
          return {
            ...action.value,
          };
        }
        return undefined;
      };

      const [state, setState] = useReducer(reducer, {
        updatePropertyRatingViewsDtos : []
      });

    const GetAllPropertyViewScores = useCallback(async (search) => 
    {
        setIsLoading(true);
        const res = await GetAllPropertyViewScoresAPI();
        if (!((res && res.data && res.data.ErrorId) || !res)) 
             setState({ id: 'edit', value:res });
         else setState({ id: 'edit', value: null });
         setIsLoading(false);
    });


    const reloadData = () => 
    {
        GetAllPropertyViewScores(); 
    };

    const saveHandler = async () => {
      setIsLoading(true);

      const res = await UpdatePropertyRatingViewScoresAPI(state); 
      if (!((res && res.status && res.status !== 200) || !res)) {
        showSuccess(t(`${translationPath}property-views-scores-updated-successfully`));
          reloadData();
      }
      else  showError(t(`${translationPath}property-views-scores-updated-failed`));
       
      setIsLoading(false) ;
  }; 

    useEffect(() => {
      reloadData();
      if(ratingPercentage)
        {
          const rating = ratingPercentage.find((f) => f.propertyRatingTabName === "Property views");
          setPropertyViewsRatingPercentage((rating && rating.score) || 0 ) ; 
        }
    }, [ratingPercentage]);
    
    useEffect( () => {
      bottomBoxComponentUpdate(null);
    },
    []
  );

    return (
      <div className="w-100 mt-4">
        <Spinner isActive={isLoading} isAbsolute />
        <div className="d-flex-v-center-h-between">
          <PageHeading
            parentTranslationPath={parentTranslationPath}
            translationPath={translationPath}
            headerTitle={"property-views"}
            wrapperClasses={"px-4 pt-2 w-75"}
            headerTitleClasses={''}
            subTitleClasses={"fz-12 m-0"}
            subTitle={''}
            titleWithPercentage={`${propertyViewsRatingPercentage}%`}
          />
          
          <div className="w-25 px-4 d-flex-center fj-end">
          <Button
              className="btns theme-propx outlined"
              id="btn-cancel"
              onClick={() => {
               reloadData()
              }}
            >
              {t(`${translationPath}cancel`)}
            </Button>
            <Button
              className="btns theme-propx solid"
              id="btn-save"
              onClick={() => saveHandler()}
            >
              {t(`${translationPath}save-changes`)}
            </Button>
           
          </div>
         </div>
        <div className="property-rating-container px-4">
          <div className='property-views-view  mt-2 mb-2'>
             <PropertyViewsContent
                parentTranslationPath={parentTranslationPath}
                translationPath={translationPath}
                state={state}  
                setState={setState}
              /> 
            
          </div>   
        </div>
      </div>
    );
  } ; 
  