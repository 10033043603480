

import React , {useState , useCallback , useEffect , useRef  } from 'react';
import { useTranslation } from 'react-i18next'; 
import { AutocompleteComponent } from '../../../../../../../../../../../../../Components' ;
import { GetAllBranches  , GetPortalAccountByBranchId } from '../../../../../../../../../../../../../Services' ; 
import { getErrorByName } from '../../../../../../../../../../../../../Helper' ; 



export const BranchComponent = ({

   setState ,
   state ,
   translationPath ,
   parentTranslationPath ,
   selected ,
   setSelected ,
   isSubmitted ,
   schema  , 

  }) => {
   const { t } = useTranslation(parentTranslationPath);
   const [isLoading , setIsLoading ] = useState(false);
   const searchTimer = useRef(null);


   const [branches, setBranches] = useState({
      result: [],
      totalCount: 0,
    });

   
    const GetAllBranchesAPI = useCallback(async () => {
      setIsLoading(true) ; 
      const res = await GetAllBranches({ pageSize: 1000, pageIndex: 1 });
        if (!(res && res.status && res.status !== 200)) setBranches({
            result: res.result,
            totalCount: res.totalCount
          });
        else setBranches({
            result: [],
            totalCount: 0
          });

          setIsLoading(false) ; 
         }, []);

  return (
    <>
        <div className='dialog-content-item mt-2'>
             <AutocompleteComponent
                idRef='branchIdRef'
                labelValue='branch'
                multiple={false}
                isDisabled
                value={selected.branch}
                selectedValues={selected.branch}
                data={(branches && branches.result) || []}
                chipsLabel={(option) => (option && option.branchName) || ''}
                displayLabel={(option) => (option && option.branchName) || ''}
                withoutSearchButton
                parentTranslationPath={parentTranslationPath}
                translationPath={translationPath}
                onChange={(event, newValue) => 
                {
                    setState({ id: 'branchId', value: ( newValue && newValue.branchId)  });
                    setSelected((item) => ({...item ,  branch : newValue }))

                }}
                onInputKeyUp={(e) => {
                  const { value } = e.target;
                  if (searchTimer.current)
                    clearTimeout(searchTimer.current);
                  searchTimer.current = setTimeout(() => {
                    GetAllBranchesAPI(value)
                  }, 700);
                }}
                helperText={getErrorByName(schema, 'branchId').message}
                error={getErrorByName(schema, 'branchId').error}
                isWithError
                isSubmitted={isSubmitted}
                withLoader 
                isLoading={isLoading}
             />
         </div>
     </>
  );
};
