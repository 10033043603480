import React, { useState, useEffect } from 'react';
import { PropTypes } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { showError, showSuccess } from '../../../../../Helper';
import { DialogComponent, Inputs, Spinner } from '../../../../../Components';
import { CreateSystemTemplateFolder, UpdateSystemTemplateFolder } from '../../../../../Services';
import CreateFolder from '../../../../../assets/images/GIF_images/Create_Folder.gif';
export const TemplateCreateFolderDialog = ({
  isOpen,
  isOpenChanged,
  reloadData,
  parentTranslationPath,
  translationPath,
  isEdit,
  FolderId
}) => {
  const { t } = useTranslation(parentTranslationPath);
  const [isLoading, setIsLoading] = useState(false);
  const [FolderName, setFolderName] = useState('');

  const CreateFolderHandler = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const res = (isEdit === true) &&
      (await UpdateSystemTemplateFolder(FolderId && FolderId.systemTemplateFolderId, {
        systemTemplateFolderName: FolderName
      })) || (isEdit === false) &&
      (await CreateSystemTemplateFolder(
        {
          systemTemplateFolderName: FolderName
        }
      ))
    if (!(res && res.status && res.status !== 200)) {
      reloadData();
      if (isEdit) { showSuccess(t(`${translationPath}update-Name-Folder-successfully`)) }
      else
        showSuccess(t(`${translationPath}Create-System-Folder-successfully`))



    } else showError(t(`${translationPath}Create-System-Folder-failed`));
    setIsLoading(false);
    isOpenChanged();
  };


  useEffect(() => {
    if (isEdit) { setFolderName(FolderId?.systemTemplateFolderName || ''); }
    else {
      setFolderName('');
    }
  }, [FolderId]);

  return (
    <DialogComponent
      titleText='confirm-message'
      saveText='confirm'
      saveType='button'
      maxWidth='sm'
      dialogContent={(
        <div className='d-flex-column-center folder-plus'>
          <Spinner isActive={isLoading} isAbsolute />
          <img alt='CreateFolder' src={CreateFolder || null} style={{ width: '99px', height: '82px' }} />
          <Inputs
            idRef='CreateSystemTemplateFolderid'
            labelValue={isEdit && 'Edit-Folder-Name' || 'Folder-Name'}
            inputPlaceholde='Folder-Name'
            value={FolderName || ''}
            parentTranslationPath={parentTranslationPath}
            translationPath={translationPath}
            onInputChanged={(event) => {
              const value = event && event.target && event.target.value;
              setFolderName(value);
            }}
          />
        </div>
      )}
      saveClasses='btns theme-solid bg-primary w-100 mx-2 mb-2'
      isOpen={isOpen}
      onSaveClicked={CreateFolderHandler}
      onCloseClicked={isOpenChanged}
      onCancelClicked={isOpenChanged}
      parentTranslationPath={parentTranslationPath}
      translationPath={translationPath}
    />
  );
};

