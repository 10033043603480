
export const AMLContactOpportunityPermissions = {
    ViewContactOpportunity :  {
         permissionsId: "98c61613-96f3-47d5-796b-08dcaf947ea8",
         permissionsName: "View Contact Opportunity",
         description: null,
         componentsId: null,
         components: null
    }
  };
  