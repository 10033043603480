import React, { useState, useRef, useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import {  AutocompleteComponent , Spinner   } from '../../Components'
import { ButtonBase, Tooltip } from '@material-ui/core';
import { GlobalAdvanceSearchActions } from '../../store/GlobalAdvanceSearch/GlobalAdvanceSearchActions';
import { TableFilterTypesEnum, TableFilterOperatorsEnum } from '../../Enums';
import { showError} from '../../Helper';
import { useDispatch, useSelector } from 'react-redux';
import '../../assets/theme-style/master.scss'
import { AddFilter } from './AddFilter'
import { ApplyFilter } from './ApplyFilter'
import { DeleteFilter } from './DeleteFilter'
import { OperatorsComponent } from './OperatorsComponent'
import { SelectItemComponent , DateItemFilter , SearchItemComponent , TextItemComponent  } from '../AdvanceSearchSecondVersion';  
import './AdvanceSeacrhSecondVersionStyle.scss' ; 
 

export const AdvanceSearchSecondVersion = ({
    translationPath,
    parentTranslationPath,
    onFilterValuesChanged,
    localStorageKey,
    searchCriteriaTypeId,
    setIsAdvanceSearchActive , 
    fields , 
    selectFieldsOnAdvanceSearch , 
    setSelectFieldsOnAdvanceSearch , 
    setFilterValuesFromAdvanceSearch ,  
    filterValuesFromAdvanceSearch , 
    searchAdvanceSearch , 
    unitType , 
    isAdvanceSearchCleared , 
    setIsAdvanceSearchCleared , 
}) => {

    const dispatch = useDispatch();
    const { t } = useTranslation('Shared');
    const [filterValue, setFilterValue] = useState(null);

    const advanceSearch = useSelector((state) => state.GlobalAdvanceSearchReducer);
    const [searchValue, setSearchValue] = useState(advanceSearch && advanceSearch[localStorageKey] || null);
    const [activeItem, setActiveItem] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isOpenAddFilter, setIsOpenAddFilter] = useState(false);
    const [isOpenApplyFilter, setIsOpenApplyFilter] = useState(false);
    const [selectColumnToDispalyFilter ,setSelectColumnToDispalyFilter ]= useState(null) ; 
    const [isOpenDeleteFilter, setIsOpenDeleteFilter] = useState(false);
    const searchTimer = useRef({
        initialHit: false,
        onChangeHit: false
    });
    const [selectAll  , setSelectAll]  = useState([]) ; 
    const [textFields , setTextFields] = useState([ 'text','textarea','textField','Phone' , 'phone' , 'communication' , 'Email' , 'SocialMedia' ,'number']); 

    const removeFilter = (index , item ) => 
    {
        let updatelist = [...selectAll];
        updatelist.splice(index, 1) ; 
        setSelectAll([...updatelist]) ; 
        for (const key in searchValue) {
            const element = searchValue[key];
            let elementKey = element && ( element.searchableKey || element.displayPath );
            if (elementKey && elementKey === (item &&  (item.searchableKey  ||  item.displayPath ||  item.input  ))) 
            {
                 const select = {...searchValue} 
                 delete select[elementKey]  ; 
                 setSearchValue(select ? {...select} : null) ; 
                 setFilterValue(select ? {...select} : null) ; 

            }        
        }
    };

    const FilterKey = (item) => 
    {
        return   item.searchableKey || item.input  || item.displayPath ;    
    };

  
    const clearOperator = () => 
    {
        for (const key in searchValue) {
            const element = searchValue[key];
            let elementKey = element.searchableKey ||  element.input  || element.displayPath ;
            let activeItemKey = activeItem.searchableKey ||  activeItem.input  || activeItem.displayPath  ;
            if (elementKey === activeItemKey) 
            {
                searchValue[key].operator = null ; 
                searchValue[key].value = null   ; 
                const index =  selectAll.findIndex(x=> x.searchableKey === elementKey)  ; 
                const updatelist = [...selectAll];
                if(index !== -1 )
                {
                updatelist[index] = {
                  ...updatelist[index],
                  displayValue :  null  , 
                  displayObj: null, 
                };
                setSelectAll([...updatelist]) ; 
             }
            }
                  
        }
    }

    useEffect(() => 
    { 
        setSelectAll([...selectFieldsOnAdvanceSearch]) ; 

    }, [selectFieldsOnAdvanceSearch]);


    useEffect(() => 
    {
         setSearchValue(filterValuesFromAdvanceSearch) ;  
         setFilterValue(filterValuesFromAdvanceSearch) ; 

    }, [filterValuesFromAdvanceSearch]); 


    return (
        <>
            <div className='view-wrapper'>
                <div className='advance-seacrh-second-version-wrapper'>
                    <Spinner isActive={isLoading} isAbsolute />
                    <div className='body-title-wrapper'>
                        <span className='body-title'>{t(`${translationPath}AdvanceSearch`)}</span>
                        <span className='body-title'>
                        <AutocompleteComponent
                          idRef='AdvanceSearchRef'
                          key={'AdvanceSearch'}
                          multiple={false}
                          data={((fields.filter(item1 => !selectAll.some(item2 => (item2 && item2.id === item1.id)) && !item1.isHidden))) || []}
                          selectedValues={selectColumnToDispalyFilter}
                          chipsLabel={(option) => option.label || ''}
                          displayLabel={(option) => option.label || ''}
                          withoutSearchButton
                          labelValue={t('Shared:select-columns')}
                          parentTranslationPath={parentTranslationPath}
                          translationPath={translationPath}
                          onChange={(event, newValue) => 
                        {
                          const newSelectedColumns = newValue ? [{...newValue , displayObj : null, displayValue : null}] : [] ; 
                         if(newValue &&  !selectAll.find((x)=> x.label  === (newValue && newValue.label)))
                              setSelectAll([...selectAll ,   ...newSelectedColumns ]) ;

                          else if(!newValue)
                                setSelectAll([...selectAll]) ;
                         
                         setSelectColumnToDispalyFilter({ id : 0 , label : ''}) ; 
                        
                      }}
                    />
                     </span>
                    <hr/>
                 </div>
                 

                 <div className='search-area'> 
                 {  
                   selectAll && selectAll.length > 0  && selectAll.map((item, index) => 
                    ( 
                     <div className='filter' key={item.id}> 
                      {(item.fieldType === 'select') && 
                     (
                        <>
                        <div className='search-field-wrapper'>
                           <div className='search-field'>
                              <SelectItemComponent
                                 key={item.id}
                                 translationPath={translationPath}
                                 parentTranslationPath={parentTranslationPath}
                                 item={item}
                                 index={index}
                                 filtersWithValues={selectAll}
                                 setFiltersWithValues ={setSelectAll}
                                 changeOperator={(value)=> 
                                    {
                                       let operatoerKey = searchValue && searchValue[FilterKey(item)] && searchValue[FilterKey(item)].operator
                                       if (onFilterValuesChanged) {
                                           searchTimer.current.initialHit = true
                                           let displayPath = item.displayPath || item.input;
                                           let searchableKey = item.searchableKey || item.input;
                                           const localFilterValues = (filterValue && { ...filterValue }) || {};
                                           const isBlank =  operatoerKey === (TableFilterOperatorsEnum.isBlank.key) || operatoerKey === (TableFilterOperatorsEnum.isNotBlank.key)  ; 


                                           localFilterValues[FilterKey(item)] = {
                                               value,
                                               operator: isBlank && operatoerKey ? (TableFilterOperatorsEnum.equal.key)  : ( operatoerKey || TableFilterOperatorsEnum.equal.key)  , 
                                               displayPath,
                                               searchableKey
                                           };
                                          setFilterValue(localFilterValues);
                                          setSearchValue(localFilterValues);
                                      }
                                    }}
                                 unitType={unitType}
                                />
                             <div>
                          </div>
                         </div> 
                         
                       </div> 
                    <div className='operatoerArea'>
                     <OperatorsComponent
                       selectedValue={searchValue && searchValue[FilterKey(item)]}
                       setActiveItem={setActiveItem}
                       item={item}
                       clearOperator={clearOperator}
                       searchValue={searchValue}
                       setSearchValue={setSearchValue}
                       setFilterValue={setFilterValue}
                       activeItem={activeItem}
                       translationPath={translationPath}
                       onFilterValuesChanged={onFilterValuesChanged}
                       fieldType={item.fieldType}
                       filtersWithValues={selectAll}
                       setFiltersWithValues ={setSelectAll}
                       
                       />  
                       <ButtonBase onClick={()=> removeFilter(index , item)}>  
                        <span className='mdi mdi-trash-can' />
                      </ButtonBase>
                      </div>
                       </>       
                     )} 

                {item.fieldType === 'searchField' && 
                     ( 
                        <>
                        <div className='search-field-wrapper' key={item.id}>
                           <span className='search-field'>
                              <SearchItemComponent
                                 key={item.id}
                                 translationPath={translationPath}
                                 parentTranslationPath={parentTranslationPath}
                                 item={item}
                                 index={index}
                                 filtersWithValues={selectAll}
                                 setFiltersWithValues ={setSelectAll}
                                 changeOperator={(value)=> 
                                    {
                                       let operatoerKey = searchValue && searchValue[FilterKey(item)] && searchValue[FilterKey(item)].operator
                                       if (onFilterValuesChanged) {
                                           searchTimer.current.initialHit = true
                                           let displayPath = item.displayPath || item.input;
                                           let searchableKey = item.searchableKey || item.input;
                                           const localFilterValues = (filterValue && { ...filterValue }) || {};
                                           const isBlank =  operatoerKey === (TableFilterOperatorsEnum.isBlank.key) || operatoerKey === (TableFilterOperatorsEnum.isNotBlank.key)  ; 

                                           localFilterValues[FilterKey(item)] = {
                                               value,
                                               operator: isBlank && operatoerKey ? (TableFilterOperatorsEnum.contains.key)  : ( operatoerKey || TableFilterOperatorsEnum.contains.key)  , 
                                               displayPath,
                                               searchableKey
                                           };
                                          setFilterValue(localFilterValues);
                                          setSearchValue(localFilterValues);
                                      }
                                    }}
                                 
                                />
                             <div>
                          </div>
                         </span>  
                       </div> 
                       <div className='operatoerArea'>
                       <OperatorsComponent
                         selectedValue={searchValue && searchValue[FilterKey(item)]}
                         setActiveItem={setActiveItem}
                         item={item}
                         clearOperator={clearOperator}
                         searchValue={searchValue}
                         setSearchValue={setSearchValue}
                         setFilterValue={setFilterValue}
                         activeItem={activeItem}
                         translationPath={translationPath}
                         onFilterValuesChanged={onFilterValuesChanged}
                         fieldType={item.fieldType}
                         filtersWithValues={selectAll}
                         setFiltersWithValues ={setSelectAll}
                       />  
                       <ButtonBase onClick={()=> removeFilter(index , item )}>  
                        <span className='mdi mdi-trash-can-outline' />
                      </ButtonBase>
                      </div>

                       </> 
                     )}
                     
                   { (textFields.some(t=> t === item.fieldType )|| ( item &&  item.data &&  item.data.uiType &&  item.data.uiType === 'text')) && 
                     ( <>
                        <div className='search-field-wrapper'  key={item.id}>
                           <span className='search-field'>
                              <TextItemComponent
                                 key={item.id}
                                 translationPath={translationPath}
                                 parentTranslationPath={parentTranslationPath}
                                 item={item}
                                 index={index}
                                 filtersWithValues={selectAll}
                                 setFiltersWithValues ={setSelectAll}
                                 changeOperator={(value)=> 
                                    {
                                       let operatoerKey = searchValue && searchValue[FilterKey(item)] && searchValue[FilterKey(item)].operator
                                       if (onFilterValuesChanged) {
                                           searchTimer.current.initialHit = true
                                           let displayPath = item.displayPath || item.input;
                                           let searchableKey = item.searchableKey || item.input;
                                           const localFilterValues = (filterValue && { ...filterValue }) || {};
                                           const isBlank =  operatoerKey === (TableFilterOperatorsEnum.isBlank.key) || operatoerKey === (TableFilterOperatorsEnum.isNotBlank.key)  ; 
                                           let  selectOprater = (operatoerKey || TableFilterOperatorsEnum.equal.key) ; 
                                           if(isBlank && !item.isNumber )
                                           selectOprater = TableFilterOperatorsEnum.contains.key ; 

                                          else if(!isBlank && !item.isNumber)
                                          selectOprater = (operatoerKey || TableFilterOperatorsEnum.contains.key)

                                          if(isBlank && item.isNumber )
                                          selectOprater = TableFilterOperatorsEnum.equal.key ; 

                                         else if(!isBlank && item.isNumber)
                                         selectOprater = (operatoerKey || TableFilterOperatorsEnum.equal.key)


                                           localFilterValues[FilterKey(item)] = {
                                               value,
                                               operator: selectOprater , 
                                               displayPath,
                                               searchableKey
                                           };
                                          setFilterValue(localFilterValues);
                                          setSearchValue(localFilterValues);
                                      }
                                    }}
                                />
                             <div>
                          </div>
                         </span>  
                       </div>  
                       <div className='operatoerArea'>
                       <OperatorsComponent
                         selectedValue={searchValue && searchValue[FilterKey(item)]}
                         setActiveItem={setActiveItem}
                         item={item}
                         clearOperator={clearOperator}
                         searchValue={searchValue}
                         setSearchValue={setSearchValue}
                         setFilterValue={setFilterValue}
                         activeItem={activeItem}
                         translationPath={translationPath}
                         onFilterValuesChanged={onFilterValuesChanged}
                         fieldType={item.fieldType}
                         filtersWithValues={selectAll}
                         setFiltersWithValues ={setSelectAll}
                       />  
                       <ButtonBase onClick={()=> removeFilter(index ,item)}>  
                        <span className='mdi mdi-trash-can-outline' />
                      </ButtonBase>
                      </div>
                       </>
                     )}

                     {item.fieldType === 'alt-date' && 
                     (
                        <>
                        <div className='search-field-wrapper'  key={item.id}>
                               <span className='search-field'>
                                  <DateItemFilter
                                     key={item.id}
                                     translationPath={translationPath}
                                     parentTranslationPath={parentTranslationPath}
                                     item={item}
                                     index={index}
                                     filtersWithValues={selectAll}
                                     setFiltersWithValues ={setSelectAll}
                                     changeOperator={(value)=> 
                                        {
                                           let operatoerKey = searchValue && searchValue[FilterKey(item)] && searchValue[FilterKey(item)].operator
                                           if (onFilterValuesChanged) {
                                               searchTimer.current.initialHit = true
                                               let displayPath = item.displayPath || item.input;
                                               let searchableKey = item.searchableKey || item.input;
                                               const localFilterValues = (filterValue && { ...filterValue }) || {};
                                               
                                               const isBlank =  operatoerKey === (TableFilterOperatorsEnum.isBlank.key) || operatoerKey === (TableFilterOperatorsEnum.isNotBlank.key)  ; 

                                               localFilterValues[FilterKey(item)] = {
                                                   value,
                                                   operator: isBlank && operatoerKey ? (TableFilterOperatorsEnum.equalData.key)  : ( operatoerKey || TableFilterOperatorsEnum.equalData.key)  , 
                                                   displayPath,
                                                   searchableKey
                                               };
                                              setFilterValue(localFilterValues);
                                              setSearchValue(localFilterValues);
                                          }
                                        }}
                                    />
                                 <div>
                             </div>
                            </span>  
                        </div>
                        <div className='operatoerArea'>
                        <OperatorsComponent
                          selectedValue={searchValue && searchValue[FilterKey(item)]}
                          setActiveItem={setActiveItem}
                          item={item}
                          clearOperator={clearOperator}
                          searchValue={searchValue}
                          setSearchValue={setSearchValue}
                          setFilterValue={setFilterValue}
                          activeItem={activeItem}
                          translationPath={translationPath}
                          onFilterValuesChanged={onFilterValuesChanged}
                          fieldType={item.fieldType}
                          filtersWithValues={selectAll}
                          setFiltersWithValues ={setSelectAll}
                        />  
                       <ButtonBase onClick={()=> removeFilter(index , item )}>  
                        <span className='mdi mdi-trash-can-outline' />
                      </ButtonBase>
                      </div>
                         </>
                     )} 
                    </div>
                   )
                          
                 )}       
                 </div>

                    <div className='action-wrapper  mt-4 advance-search-btns'>
                        <div className='upper-btns'>
                        <ButtonBase
                                className='btns theme-solid is-active-filter clear-all-btn'

                                onClick={() => 
                                     {
                                        if(!searchValue)
                                        {
                                           
                                            showError(t(`${translationPath}select-filter-for-all-selected-columns`)); 
                                            return ; 
                                           
                                        }
        
                                        setSelectFieldsOnAdvanceSearch([...selectAll]) ; 
                                        setFilterValuesFromAdvanceSearch(filterValue)  ;
                                        searchAdvanceSearch(filterValue) ;

                                     
                                     }}
                            >
                            <span>{t(`${translationPath}search`)}</span>
                            </ButtonBase>

                            <ButtonBase
                                className='btns w-0 '
                                onClick={() => {
                                    if(filterValue)
                                    {
                                       if((Object.keys(filterValue).length === 0) ||  [...Object.values(filterValue)].some(x=> !(x.operator)))
                                       {

                                        showError('select-filter-for-all-selected-columns');
                                        return ; 

                                       }
                                     }
                                     setIsOpenAddFilter(true);
                                     }}
                            >
                                <Tooltip title={t(`${translationPath}add-filter`)}>
                                    <span className='mdi mdi-content-save-outline' />
                                </Tooltip>
                            </ButtonBase>
                            <ButtonBase
                                onClick={() => { setIsOpenApplyFilter(true); }}
                                className='btns  w-0 '
                            >
                                <Tooltip title={t(`${translationPath}apply-filter`)}>
                                    <span className='mdi mdi-filter-menu-outline' />
                                </Tooltip>
                            </ButtonBase>
                            <ButtonBase
                                onClick={() => { setIsOpenDeleteFilter(true); }}
                                className='btns delete'
                            >
                                <Tooltip title={t(`${translationPath}delete-filter`)}>
                                    <span className='mdi mdi-trash-can' />
                                </Tooltip>
                            </ButtonBase>

                        </div>
                        <ButtonBase
                            onClick={() => {
                                onFilterValuesChanged(null);
                                setIsAdvanceSearchActive(false);
                                setSelectColumnToDispalyFilter([]);
                                setSelectAll([]) ; 
                                setFilterValue(null) ;
                                setSearchValue(null) ; 
                                setSelectFieldsOnAdvanceSearch([]) ; 
                                setFilterValuesFromAdvanceSearch(null); 
                                setIsAdvanceSearchCleared(true);
                                dispatch(
                                    GlobalAdvanceSearchActions.globalAdvanceSearchRequest({
                                        ...advanceSearch,
                                        [localStorageKey]: null
                                    })
                                );

                            }}
                            className='btns theme-solid bg-danger clear-all-btn'
                        >
                            <span className='mdi mdi-filter-remove' />
                            <span className='mx-2'>{t(`${translationPath}clear`)}</span>
                        </ButtonBase>
                    </div>
                </div>
              
                {isOpenAddFilter && (
                    <AddFilter
                        data={{allValues : [...selectAll] , search: searchValue }}
                        isOpen={isOpenAddFilter}
                        setIsOpenAddFilter={setIsOpenAddFilter}
                        setIsLoading={setIsLoading}
                        searchCriteriaTypeId={searchCriteriaTypeId}
                        isLoading={isLoading}
                    />
                )}
                {isOpenApplyFilter && (
                    <ApplyFilter
                        isOpen={isOpenApplyFilter}
                        setIsOpenApplyFilter={setIsOpenApplyFilter}
                        setIsLoading={setIsLoading}
                        searchCriteriaTypeId={searchCriteriaTypeId}
                        isLoading={isLoading}
                        setFilterValue={setFilterValue}
                        setSearchValue={setSearchValue}
                        searchTimer={searchTimer}
                        setSelectAll={setSelectAll}
                    />
                )}
                {isOpenDeleteFilter && (
                    <DeleteFilter
                        isOpen={isOpenDeleteFilter}
                        setIsOpenDeleteFilter={setIsOpenDeleteFilter}
                        searchCriteriaTypeId={searchCriteriaTypeId}
                    />
                )}
            </div>
        </>
    )
}
