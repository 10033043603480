import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from "react-i18next";
import { ButtonBase } from "@material-ui/core";
import { GlobalHistory } from '../../../../../Helper';
import btnLoader from '../../../../../assets/images/GIF_images/btn-loader.gif';
import arrowLeft from "../../../../../assets/images/icons/arrow-left.png";

export const   TransactionActionButtons = ({
    isSubmitDisabled,
    submitButton,
    backButtonHandler,
    isWithoutCancel,
    isWithBackButton,
    cancelRoute,
    parentTranslationPath,
}) => {
    const translationPath = '';
    const { t } = useTranslation(parentTranslationPath);

    const cancelClickHandler = () => GlobalHistory.push(cancelRoute);
    const saveHandler =  () => { submitButton.handler()}

    return (
      <div>
        <div className="d-flex-center fj-end py-0 pt-3">
          {isWithBackButton ? (
            <ButtonBase
            onClick={backButtonHandler}
            className="btns theme-propx transparent"
          >
            <img src={arrowLeft}/>{t(`${translationPath}back`)}
          </ButtonBase>
          ) : null}
          {!isWithoutCancel && (
            <ButtonBase
              onClick={cancelClickHandler}
              className="btns theme-propx outlined"
            >
              {t(`${translationPath}cancel`)}
            </ButtonBase>
          )}

          <ButtonBase
            className="btns theme-propx solid mr-0"
            onClick={saveHandler}
            disabled={isSubmitDisabled || submitButton.isLoading} 
          >
            {t(`${translationPath}${submitButton.label}`)}
            {submitButton.isLoading ? (
              <img src={btnLoader} className="ml-1" width="12" height="12" />
            ) : (
              null
            )}
          </ButtonBase>
        </div>
      </div>
    );
}

TransactionActionButtons.propTypes = {
    selected: PropTypes.object,
    submitButton: PropTypes.shape({
        label: PropTypes.string,
        handler: PropTypes.func,
        isLoading: PropTypes.bool,
    }).isRequired,
    isSubmitDisabled: PropTypes.bool,
    isWithoutCancel: PropTypes.bool,
    cancelRoute: PropTypes.string,
}

TransactionActionButtons.defaultProps = {
    submitButton: PropTypes.shape({
        label: 'next',
        handler: () => { },
        isLoading: false,
    }),
    backButtonHandler: ()=>{},
    isWithoutCancel: false,
    isSubmitDisabled: false,
    isWithBackButton: false,
    cancelRoute: `/home/units-lease/view`
}