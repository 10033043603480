import React from 'react'
import { RadiosGroupComponent } from '../../../../../../Components';
import { useTranslation } from 'react-i18next';


export const SLAExpirationPeriodDependOn = ({
    parentTranslationPath,
    translationPath,
    state,
    setState,
}) => {
    const { t } = useTranslation(parentTranslationPath);

    return (
        <RadiosGroupComponent
            data={[
                { id: 2, value: t(`${translationPath}creation-date`) },
                { id: 1, value: t(`${translationPath}activity-date`) },
            ]}
            labelClasses='Requierd-Color'
            valueInput='id'
            labelInput='value'
            value={state.slaExpirationPeriodComparisonDate}
            onSelectedRadioChanged={(e, newValue) => {
                setState(+newValue);
            }}
            name='radioGroups'
            titleClasses='texts gray-primary-bold'
            wrapperClasses='details-tab-radio-btns'
            parentTranslationPath={parentTranslationPath}
            translationPath={translationPath}
            labelValue='depend-on'
        />)
}

