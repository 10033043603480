import React, { useState, useEffect, useRef, useCallback } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import {
  CheckboxesComponent,
  AutocompleteComponent,
} from "../../../../../../../Components";
import { OrganizationUserSearch } from "../../../../../../../Services";

export const LeaseTransactedBy = ({
  parentTranslationPath,
  translationPath,
  state,
  setState,
  checkAtLeastOneSelected,
  checkAtLeastOneSelected2,
  setDefaultSelected,
}) => {
  const { t } = useTranslation(parentTranslationPath);

  const searchTimer = useRef(null);
  let timer = null;
  const [allUsers, setAllUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const getAllOrganizationUserSearch = useCallback(async (searchValue) => {
    setIsLoading(true);
    const res = await OrganizationUserSearch({
      pageIndex: 0,
      pageSize: 10,
      name: searchValue,
      userStatusId: 2,
    });
    if (!(res && res.status && res.status !== 200))
      setAllUsers(
        res.result.map((item) => ({
          id: item.id,
          fullName: item.fullName,
          branch: item.branch || null,
        }))
      );
    else setAllUsers([]);

    setIsLoading(false);
  }, []);

  useEffect(() => {
    getAllOrganizationUserSearch();
  }, []);

  return (
    <div className="content">
      <div className="w-100 px-2 mt-2 checkBoxsPart">
        <div className="checkboxesItem">
          <CheckboxesComponent
            idRef="UnitsCreatedByViewAllUnitsRef"
            label={t(`${translationPath}view-all-units`)}
            parentTranslationPath={parentTranslationPath}
            translationPathForData={translationPath}
            singleChecked={state?.units?.leaseTransactedBy?.allUsers || false}
            onSelectedCheckboxClicked={(e) => {
              const checkIfCanSelected = checkAtLeastOneSelected({
                ...state.units.leaseTransactedBy,
                allUsers: e.target.checked,
              });
              if (
                checkAtLeastOneSelected2(
                  state?.units?.leaseTransactedBy?.specificUsers
                )
              );
              else if (!checkIfCanSelected) return;

              const unitsCreatedBy = {
                ...state.units.leaseTransactedBy,
                allUsers: e.target.checked,
                myUser: false,
                myTeamUsers: false,
                myBusinessGroupUsers: false,
                myBranchUsers: false,
                myManageUsers: false,
                specificUsers: [],
                myTeamLeadUsers: false,
              };
              setState({
                id: "units",
                value: { ...state.units, leaseTransactedBy: unitsCreatedBy },
              });
            }}
          />
        </div>
        <div className="checkboxesItem">
          <CheckboxesComponent
            idRef="UnitsCreatedByViewMyUnitsRef"
            label={t(`${translationPath}view-my-units`)}
            parentTranslationPath={parentTranslationPath}
            translationPathForData={translationPath}
            singleChecked={state?.units?.leaseTransactedBy?.myUser || false}
            onSelectedCheckboxClicked={(e) => {
              const checkIfCanSelected = checkAtLeastOneSelected({
                ...state.units.leaseTransactedBy,
                myUser: e.target.checked,
              });
              if (
                checkAtLeastOneSelected2(
                  state?.units?.leaseTransactedBy?.specificUsers
                )
              );
              else if (!checkIfCanSelected) return;

              const unitsCreatedBy = {
                ...state.units.leaseTransactedBy,
                allUsers: false,
                myUser: e.target.checked,
                myTeamUsers: false,
                myBusinessGroupUsers: false,
              };
              setState({
                id: "units",
                value: { ...state.units, leaseTransactedBy: unitsCreatedBy },
              });
            }}
          />
        </div>
        <div className="checkboxesItem">
          <CheckboxesComponent
            idRef="UnitsCreatedByViewMyTeamUnitsRef"
            label={t(`${translationPath}view-my-team-units`)}
            parentTranslationPath={parentTranslationPath}
            translationPathForData={translationPath}
            singleChecked={
              state?.units?.leaseTransactedBy?.myTeamUsers || false
            }
            onSelectedCheckboxClicked={(e) => {
              const checkIfCanSelected = checkAtLeastOneSelected({
                ...state.units.leaseTransactedBy,
                myTeamUsers: e.target.checked,
              });
              if (
                checkAtLeastOneSelected2(
                  state?.units?.leaseTransactedBy?.specificUsers
                )
              );
              else if (!checkIfCanSelected) return;

              const unitsCreatedBy = {
                ...state.units.leaseTransactedBy,
                allUsers: false,
                myUser: false,
                myTeamUsers: e.target.checked,
                myBusinessGroupUsers: false,
              };
              setState({
                id: "units",
                value: { ...state.units, leaseTransactedBy: unitsCreatedBy },
              });
            }}
          />
        </div>
        <div className="checkboxesItem">
          <CheckboxesComponent
            idRef="UnitsCreatedByViewMyBusinessGroupRef"
            label={t(`${translationPath}view-my-business-group-units`)}
            parentTranslationPath={parentTranslationPath}
            translationPathForData={translationPath}
            singleChecked={
              state?.units?.leaseTransactedBy?.myBusinessGroupUsers || false
            }
            onSelectedCheckboxClicked={(e) => {
              const checkIfCanSelected = checkAtLeastOneSelected({
                ...state.units.leaseTransactedBy,
                myBusinessGroupUsers: e.target.checked,
              });
              if (
                checkAtLeastOneSelected2(
                  state?.units?.leaseTransactedBy?.specificUsers
                )
              );
              else if (!checkIfCanSelected) return;

              const unitsCreatedBy = {
                ...state.units.leaseTransactedBy,
                allUsers: false,
                myUser: false,
                myTeamUsers: false,
                myBusinessGroupUsers: e.target.checked,
              };
              setState({
                id: "units",
                value: { ...state.units, leaseTransactedBy: unitsCreatedBy },
              });
            }}
          />
        </div>
        <div className="checkboxesItem">
          <CheckboxesComponent
            idRef="UnitsCreatedByViewMyBranchUnitsRef"
            label={t(`${translationPath}view-my-branch-units`)}
            parentTranslationPath={parentTranslationPath}
            translationPathForData={translationPath}
            singleChecked={
              state?.units?.leaseTransactedBy?.myBranchUsers || false
            }
            onSelectedCheckboxClicked={(e) => {
              const checkIfCanSelected = checkAtLeastOneSelected({
                ...state.units.leaseTransactedBy,
                myBranchUsers: e.target.checked,
              });

              if (
                checkAtLeastOneSelected2(
                  state?.units?.leaseTransactedBy?.specificUsers
                )
              );
              else if (!checkIfCanSelected) return;

              const unitsCreatedBy = {
                ...state.units.leaseTransactedBy,
                allUsers: false,
                myBranchUsers: e.target.checked,
              };
              setState({
                id: "units",
                value: { ...state.units, leaseTransactedBy: unitsCreatedBy },
              });
            }}
          />
        </div>
        <div className="checkboxesItem">
          <CheckboxesComponent
            idRef="UnitsCreatedByViewMyManagedTeamUnitsRef"
            label={t(`${translationPath}view-my-managed-team-units`)}
            parentTranslationPath={parentTranslationPath}
            translationPathForData={translationPath}
            singleChecked={
              state?.units?.leaseTransactedBy?.myManageUsers || false
            }
            onSelectedCheckboxClicked={(e) => {
              const checkIfCanSelected = checkAtLeastOneSelected({
                ...state.units.leaseTransactedBy,
                myManageUsers: e.target.checked,
              });
              if (
                checkAtLeastOneSelected2(
                  state?.units?.leaseTransactedBy?.specificUsers
                )
              );
              else if (!checkIfCanSelected) return;

              const unitsCreatedBy = {
                ...state.units.leaseTransactedBy,
                allUsers: false,
                myManageUsers: e.target.checked,
              };
              setState({
                id: "units",
                value: { ...state.units, leaseTransactedBy: unitsCreatedBy },
              });
            }}
          />
        </div>
        <div className="checkboxesItem">
          <CheckboxesComponent
            idRef="UnitsCreatedByViewMyTeamLeadUsersRef"
            label={t(`${translationPath}view-my-team-lead-users`)}
            parentTranslationPath={parentTranslationPath}
            translationPathForData={translationPath}
            singleChecked={
              state?.units?.leaseTransactedBy?.myTeamLeadUsers || false
            }
            onSelectedCheckboxClicked={(e) => {
              const checkIfCanSelected = checkAtLeastOneSelected({
                ...state.units.leaseTransactedBy,
                myTeamLeadUsers: e.target.checked,
              });
              if (
                checkAtLeastOneSelected2(
                  state?.units?.leaseTransactedBy?.specificUsers
                )
              );
              else if (!checkIfCanSelected) return;

              const unitsCreatedBy = {
                ...state.units.leaseTransactedBy,
                allUsers: false,
                myTeamLeadUsers: e.target.checked,
              };
              setState({
                id: "units",
                value: { ...state.units, leaseTransactedBy: unitsCreatedBy },
              });
            }}
          />
        </div>
        <div className="w-100 px-2 mt-2 mb-2">
          <AutocompleteComponent
            idRef="unitsSpecificsUsersRef"
            labelValue={t(`${translationPath}specific_users`)}
            data={allUsers || []}
            selectedValues={
              state?.units?.leaseTransactedBy?.specificUsers || []
            }
            multiple
            displayLabel={(option) => (option && option.fullName) || ""}
            chipsLabel={(option) => (option && option.fullName) || ""}
            withoutSearchButton
            parentTranslationPath={parentTranslationPath}
            translationPath={translationPath}
            onInputKeyUp={(e) => {
              const { value } = e.target;
              if (searchTimer.current) clearTimeout(searchTimer.current);
              searchTimer.current = setTimeout(() => {
                getAllOrganizationUserSearch(value);
              }, 700);
            }}
            renderOption={(option) => (
              <div className="d-flex-column">
                <div className="d-flex-v-center-h-between w-100 texts-truncate">
                  {option.fullName}
                </div>
                <span className="c-gray-secondary"> {option.branch} </span>
              </div>
            )}
            value={state?.units?.leaseTransactedBy?.specificUsers || []}
            onChange={(e, newValues) => {
              let unitsCreatedBy = {
                ...state.units.leaseTransactedBy,
                specificUsers: newValues,
              };
              if (
                !newValues.length &&
                setDefaultSelected({
                  ...state.units.leaseTransactedBy,
                  specificUsers: false,
                })
              )
                unitsCreatedBy = {
                  ...state.units.leaseTransactedBy,
                  specificUsers: newValues,
                  allUsers: true,
                };

              setState({
                id: "units",
                value: { ...state.units, leaseTransactedBy: unitsCreatedBy },
              });
            }}
            isLoading={isLoading}
            withLoader
            filterOptions={(options) => {
              const isFind = (id) => {
                const specificUsers = state?.units?.leaseTransactedBy?.specificUsers || [];
                return specificUsers.findIndex((w) => w.id === id) === -1;
              };
              return options.filter((w) => isFind(w.id));
            }}
            isDisabled={state?.units?.leaseTransactedBy?.allUsers}
          />
        </div>
      </div>
    </div>
  );
};

LeaseTransactedBy.propTypes = {
  parentTranslationPath: PropTypes.string.isRequired,
  translationPath: PropTypes.string.isRequired,
  state: PropTypes.instanceOf(Object).isRequired,
  setState: PropTypes.func.isRequired,
  checkAtLeastOneSelected: PropTypes.func.isRequired,
  checkAtLeastOneSelected2: PropTypes.func.isRequired,
  setDefaultSelected: PropTypes.func.isRequired,
};
