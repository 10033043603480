import React from "react";
import clsx from "clsx";
import { Box, Typography } from "@material-ui/core";
import { getDownloadableLink } from "../../../../../../../Helper";
import {
  CustomCopyToClipboard,
  LeadTypeBadges,
} from "../../../../../../../Components";
import { useTranslate } from "../../../../../../../Hooks";

// Styles
import { leadsCardStyles } from "./styles";

function ContactOverviewLeadsCard({ leadItems, totalCount }) {
  const styles = leadsCardStyles();

  const { translate } = useTranslate("NewContactsView");
  const { translate: sharedTranslate } = useTranslate("SharedV2");

  return (
    <Box className={styles.leadsContainer}>
      {leadItems.map((lead) => {
        return (
          <Box key={lead?.leadId} className={styles.leadWrapper}>
            <Box className={styles.statusSection}>
              <LeadTypeBadges leadTypesList={lead?.leadClassId?.toString()} />
              <Box className={styles.statusInfoWrapper}>
                <Typography
                  className={clsx(
                    styles.status,
                    lead?.statusId === 457 ? styles.open : styles.close
                  )}
                  variant="body1"
                >
                  {lead?.statusId === 457
                    ? translate("OPEN")
                    : translate("close")}
                </Typography>
                <Typography className={styles.statusInfo} variant="body1">
                  {lead?.leadStage}
                </Typography>
              </Box>
            </Box>
            <Box className={styles.infoSection}>
              <Box display="flex" style={{ gap: "10px", alignItems: "center" }}>
                <Typography className={styles.label} variant="body1">
                  {translate("referred-to")}
                </Typography>
                {lead?.referredToImg && (
                  <span className={styles.referredToImgWrapper}>
                    <Box
                      className={styles.referredToImg}
                      style={{
                        backgroundImage: `url(${getDownloadableLink(
                          lead?.referredToImg
                        )})`,
                      }}
                    />
                  </span>
                )}
                <Typography className={styles.value} variant="body1">
                  {lead?.referredToName}
                </Typography>
              </Box>
              <Box display="flex" alignItems="center" style={{ gap: "10px" }}>
                <Typography className={styles.label} variant="body1">
                  {translate("lead_id")}
                </Typography>
                <CustomCopyToClipboard
                  data={lead?.leadId} // copy
                  childrenData={"#" + lead?.leadId} // render
                  customContainerClasses={styles.f14}
                />
              </Box>
            </Box>
          </Box>
        );
      })}

      {totalCount === 0 && (
        <Typography
          className={styles.emptyState}
          variant="h6"
          align="center"
          color="textSecondary"
        >
          {sharedTranslate("no-data-avlbl")}
        </Typography>
      )}
    </Box>
  );
}

export default ContactOverviewLeadsCard;
