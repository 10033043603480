import React from "react";

function SettingsFour({ fill, ...restProps }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill={fill ?? ""}
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <path
        d="M17.358 4.061c-1.507.235-2.761 1.354-3.215 2.869-.019.066-.323.07-5.635.07-4.642 0-5.647.01-5.798.055a1.02 1.02 0 0 0-.582.461c-.091.156-.108.23-.108.484 0 .256.016.328.111.489.125.213.318.375.539.454.123.044 1.185.054 5.801.055 5.347.002 5.653.006 5.672.072.367 1.222 1.219 2.17 2.369 2.634a3.988 3.988 0 0 0 5.192-2.216 3.963 3.963 0 0 0 .001-2.972 3.978 3.978 0 0 0-3.1-2.458 3.728 3.728 0 0 0-1.247.003m1.165 2.012a2.011 2.011 0 0 1 1.444 1.617c.1.653-.094 1.243-.564 1.714-.401.403-.856.596-1.403.596-.515 0-.962-.178-1.347-.535-1.33-1.236-.464-3.46 1.347-3.46.143 0 .378.031.523.068M5.358 12.061c-1.128.176-2.215.913-2.789 1.892a3.992 3.992 0 0 0 1.943 5.751 3.969 3.969 0 0 0 2.976 0 3.936 3.936 0 0 0 2.223-2.244c.067-.176.132-.351.144-.39.021-.066.328-.07 5.674-.072 4.616-.001 5.678-.011 5.801-.055.221-.079.414-.241.539-.454.095-.161.111-.233.111-.489 0-.254-.017-.328-.108-.484a1.02 1.02 0 0 0-.582-.461c-.151-.045-1.156-.055-5.798-.055-5.312 0-5.616-.004-5.637-.07a14.09 14.09 0 0 0-.144-.39c-.498-1.308-1.703-2.27-3.106-2.482a3.728 3.728 0 0 0-1.247.003m1.165 2.012a2.011 2.011 0 0 1 1.444 1.617c.1.653-.094 1.243-.564 1.714C7.002 17.807 6.547 18 6 18c-.515 0-.962-.178-1.347-.535-1.33-1.236-.464-3.46 1.347-3.46.143 0 .378.031.523.068"
        fill-rule="evenodd"
        fill={fill ?? ""}
      />
    </svg>
  );
}

export default SettingsFour;
