import React, { useEffect, useState, useReducer, useCallback, useRef } from "react";
import { PowerBIEmbed } from "powerbi-client-react";
import { models } from "powerbi-client";
import "../MasterStyleDashborads/MasterStyleDashboards.scss";
import { showError, showSuccess } from "../../../../Helper";
import { MicrosoftToken, lookupItemsGetId } from "../../../../Services";
import { SpinnerDashboards } from "../SpinnerDashboards/SpinnerDashboards";
import { DateRangePickerComponent } from "../../../../Components/Controls/DateRangePickerComponent/DateRangePickerComponent";
import moment from "moment";
import {
  AutocompleteComponent,
  Inputs,
  SelectComponet,
} from "../../../../Components";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { Button, ButtonBase } from "@material-ui/core";
import { CellsSpinner } from "../../../../SharedComponents/CellSpinnerComponent/CellsSpinner";
import { CellSpinnerBi } from "../CellSpinnerBi/CellSpinnerBi";

const parentTranslationPath = "LeadsView";
const translationPath = "";

export const AdminUsersDetails = ({ id, embedUrl }) => {
  const { t } = useTranslation(parentTranslationPath);
  const dispatch = useDispatch();

  const [report, setReport] = useState();
  const [render, setRender] = useState(false);
  const [ShowReport, setShowReport] = useState(false);
  const [DateAppaer, setDateAppaer] = useState(false);
  const [datefilters, setdatefilter] = useState(true);
  const [ShowReport2, setShowReport2] = useState(false);
  const [datepicker, setdatepicker] = useState();
  const [login, selogin] = useState(false);
  const [details, sedetails] = useState(false);
  const [monthsAgo, setMonthsAgo] = useState(1);
  const [MenuButton, setMenuButton] = useState("One");
  const [anchorEl, setAnchorEl] = useState(null);


  const currentDate = new Date();
  const countmonth = new Date(
    currentDate.setMonth(currentDate.getMonth() - monthsAgo)
  );

  const today = new Date();

  const count = new Date(new Date().setDate(today.getDate()));
  const count2 = new Date(new Date().setDate(today.getDate()));
  const datetimes = moment(count)
    .startOf("month")
    .format("YYYY-MM-DDTHH:mm:ss");
  const datetimes2 = moment(count2).format("YYYY-MM-DDTHH:mm:ss");

  const startDate = datetimes ? new Date(datetimes) : null;
  const endDate = datetimes2 ? new Date(datetimes2) : null;

  const dateRangeDefault = {
    startDate: null,
    endDate: null,
    key: "selection",
  };

  const [dateFilter, setDateFilter] = useState(dateRangeDefault);
  const [data, setdata] = useState([]);
  const [isDatePickerChanged, setIsDatePickerChanged] = useState(false);

  const reducer = (state, action) => {
    if (action.id !== "edit") return { ...state, [action.id]: action.value };
    if (action.id === "edit") {
      return {
        ...action.value,
      };
    }
    return undefined;
  };

  const [state, setState] = useReducer(reducer, {
    fromDate: null,
    toDate: null,
  });

  useEffect(() => {
    const currentState = {
      ...state,
      fromDate:
        (dateFilter.startDate &&
          moment(dateFilter.startDate)
            .startOf("day")
            .format("YYYY-MM-DDTHH:mm:ss")) ||
        null,
      toDate:
        (dateFilter.endDate &&
          moment(dateFilter.endDate)
            .endOf("day")
            .format("YYYY-MM-DDTHH:mm:ss")) ||
        null,
    };
    setState({ value: currentState });
    setdata(currentState);
  }, [dateFilter]);

  const advancedFilter = {
    $schema: "http://powerbi.com/product/schema#advanced",
    target: {
      table: "Users",
      column: datefilters ? "CreatedOn" : "UpdateOn",
    },
    filterType: 0,
    logicalOperator: "And",
    conditions: [
      {
        operator: "GreaterThanOrEqual",
        value: data.fromDate,
      },
      {
        operator: "LessThanOrEqual",
        value: data.toDate,
      },
    ],
    filterType: models.FilterType.AdvancedFilter,
  };

  const advancedLogin = {
    $schema: "http://powerbi.com/product/schema#advanced",
    target: {
      table: "UserLogin",
      column: "UserLoginCreatedOn",
    },
    filterType: 0,
    logicalOperator: "And",
    conditions: [
      {
        operator: "GreaterThanOrEqual",
        value: data.fromDate,
      },
      {
        operator: "LessThanOrEqual",
        value: data.toDate,
      },
    ],
    filterType: models.FilterType.AdvancedFilter,
  };

  const Microsoft = async (isExpired) => {
    const res = await MicrosoftToken(isExpired);
    if (!(res && res.status && res.status !== 200)) {
      setReport(res);
      setRender(true);
    } else {
      setReport("");
      showError("Contact Your Bi Admin For Help");
    }
  };

  const [bookmarks, setBookmarks] = useState([]);
  const [error, setError] = useState(null);


  const Pagenavi = async (pagestate) => {
    const report = window.report;
    let pages = await report.getPages();

    if (report) {
      try {
        report.setPage(pagestate);

        console.log("Page Apply!");
      } catch (error) {
        console.error("Error applying:", error);
      }
    }
  };
  const applyBookmark = async (bookmarkName) => {
    const report = window.report;

    if (report) {
      try {
        const bookmarksManager = report.bookmarksManager;
        bookmarksManager.apply(bookmarkName);
        // console.log("Bookmark applied successfully:", bookmarkName);
      } catch (error) {
        console.error("Error applying bookmark:", error);
        setError(error);
      }
    }
  };

  const loadJsonFromFile = async (path) => {
    try {
      const response = await fetch(path);
      const jsonString = await response.text();
      return jsonString;
    } catch (error) {
      console.error("Error loading JSON file:", error);
      throw error;
    }
  };

  const [filterButton, setfilterButton] = useState(false);

  const FilterHide = () => {
    applyBookmark("Bookmark8ffad06a3474d503d990");
    setfilterButton(false);
  };
  const FilterView = () => {
    applyBookmark("Bookmarka9945608e57aca0b0b8d");
    setfilterButton(true);
  };


  const gettheme = async () => {
    const report = window.report;

    if (report) {
      try {
        report
          .getTheme()
          .then((theme) => {
            console.log("theme", theme);
          })
          .catch((error) => {
            console.error(error);
          });
      } catch (error) {
        console.error("Error  theme:", error);
      }
    }
  };

  const onClearedAllFiltersClick = useCallback(() => {
    setDateFilter(dateRangeDefault);
    applyBookmark("Bookmarkfd7a28cf006045a8885a");

    setdatepicker(0);
    setdatefilter(true);
  }, []);

  const scrollToTop = useCallback(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  const downloadCSV = (csvData, fileName) => {
    const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");

    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", fileName);
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  const handleExportData = async () => {
    const report = window.report;

    if (report) {
      try {
        let pages = await report.getPages();

        if (pages.length > 1) {
          let secondPage = pages[3];

          let visuals = await secondPage.getVisuals();
          if (visuals.length > 2) {
            const visual = visuals[0];

            const exportedData = await visual.exportData(
              models.ExportDataType.Summarized,
              30000
            );

            const fileName = "UserDetails.csv";
            downloadCSV(exportedData.data, fileName);

            showSuccess("Data exported successfully!");
          } else {
            console.error("Not enough visuals on the second page.");
            showError("Not enough visuals on the second page.");
          }
        } else {
          console.error("Not enough pages.");
          showError("Not enough pages.");
        }
      } catch (error) {
        console.error("Export Error:", error);
        showError("Error exporting data");
      }
    }
  };

  const loadBookmarks = async () => {
    const report = window.report;
    let pages = await report.getPages();
    if (report) {
      try {
        const bookmarksManager = report.bookmarksManager;
        const fetchedBookmarks = await bookmarksManager.getBookmarks();
        setBookmarks(fetchedBookmarks);
        console.log("Bookmarks:", fetchedBookmarks);
        console.log("pages:", pages);
      } catch (error) {
        console.error("Error loading bookmarks:", error);
        setError(error);
      }
    }
  };

  let selectedFilter;
  switch (true) {
    case details:
      selectedFilter = advancedFilter;
      break;
    case login:
      selectedFilter = advancedLogin;
      break;
   
    default:
      selectedFilter = advancedFilter;
  }
  const [activeIndex, setActiveIndex] = useState(0);
  const horiSelectorRef = useRef(null);
  const navbarRef = useRef(null);



  useEffect(() => {
    const updateSelector = () => {
      if (navbarRef.current && horiSelectorRef.current) {
        const tabs = navbarRef.current.querySelectorAll("li");
        if (tabs.length > 0) {
          const activeItem = tabs[activeIndex];
          const activeWidth = activeItem.offsetWidth;
          const itemPosLeft = activeItem.offsetLeft;
          horiSelectorRef.current.style.left = `${itemPosLeft}px`;
          horiSelectorRef.current.style.width = `${activeWidth}px`;
        }
      }
    };

 
    updateSelector();
  }, [activeIndex]);

  const handleItemClick = (index) => {
    setActiveIndex(index);
  };
  const handleMenuButtonClick = (buttonName) => {
    setMenuButton(buttonName);
  };

  const GeneralPage = () => {
    handleMenuButtonClick("One");
    setAnchorEl(null);
    Pagenavi("ReportSectioncd07b4976a840c88cd42");
  };
  const LoginPage = () => {
    handleMenuButtonClick("Two");
    Pagenavi("c1ad37f9c424e02390ae");
    setAnchorEl(null);
  };

  useEffect(() => {
    Microsoft(false);
  }, []);

  return (
    <div className="dashboardMain-PowerBIEmbed">
      <CellSpinnerBi isActive={!ShowReport} isAbsolute blue />

      <div>
        <div id="navbar-animmenu" ref={navbarRef}>
          <ul className="show-dropdown main-navbar">
            <div className="hori-selector" ref={horiSelectorRef}>
              <div className="left"></div>
              <div className="right"></div>
            </div>
            <li
              className={activeIndex === 0 ? "active" : ""}
              onClick={() => {
                handleItemClick(0);
                GeneralPage();
   
              }}
            >
              <a>
                <i className={`mdi mdi-account-details-outline mdi-24px `}></i>
                {t(`${translationPath}user-detials`)}
              </a>
            </li>
            <li
              className={activeIndex === 1 ? "active" : ""}
              onClick={() => {
                handleItemClick(1);
                LoginPage();
          
              }}
            >
              <a>
                <i className={`mdi mdi-book-account-outline mdi-24px `}></i> 
                {t(`${translationPath}user-login`)}
              </a>
            </li>
        
          </ul>
        </div>
      </div>
      <br />
 
               <div className="mainCardDashboards">
       
        {render && (
          
          <div
            className="dashboardMain cardDashboard"
          >
          
            <div style={{marginTop:'1%'}}>
            {DateAppaer && (
        <div>
          <div className="MainFiltersBi" >
          
            <CellSpinnerBi isActive={!ShowReport2} isAbsolute blue/>

            <div className="selecterbi">
            {details ? (      <div>
                <SelectComponet
                  data={[
                    {
                      id: 1,
                      datepicker: "Update On",
                    },
                  ]}
                  emptyItem={{
                    value: 0,
                    text: `${translationPath}created-date`,
                    isDisabled: false,
                  }}
                  value={datepicker || 0}
                  defaultValue={0}
                  valueInput="id"
                  textInput="datepicker"
                  onSelectChanged={(value) => {
                    switch (value) {
                      case 0:
                        setdatepicker(0);
                        setdatefilter(true);

                        break;
                      default:
                        setdatepicker(1);
                        setdatefilter(false);
                        break;
                    }
                  }}
                  themeClass="theme-transparent"
                  wrapperClasses="w-auto"
                  idRef="Date_Select"
                  parentTranslationPath={parentTranslationPath}
                  translationPath={translationPath}
                  translationPathForData={translationPath}
                />
              </div>):(<div></div>) }
        

              <div>
                <DateRangePickerComponent
                  onClearClicked={() => {
                    setDateFilter(dateRangeDefault);
                  }}
                  ranges={[dateFilter]}
                  onDateChanged={(selectedDate) => {
                    const newStartDate =
                      selectedDate.selection &&
                      selectedDate.selection.startDate;
                    const newEndDate = new Date(
                      moment(
                        selectedDate.selection && selectedDate.selection.endDate
                      ).endOf("day")
                    );

                    setDateFilter({
                      startDate: newStartDate,
                      endDate: newEndDate,
                      key: "selection",
                    });
                  }}
                />
              </div>
            </div>


            {details ? (  
            <div className="DashboardFilter">
            {!filterButton ? (
              <div className="DashboardFilter">
                <ButtonBase
                  variant="contained"
                  className="btns theme-solid bg-primary-dark Dashboardbtn"
                  style={{ color: "white" }}
                  onClick={FilterView}
                >
                  <span className="mdi mdi-filter-menu m-1" />
                </ButtonBase>
              </div>
            ) : (
              <div className="DashboardFilter">
                <ButtonBase
                  variant="contained"
                  className="btns theme-solid bg-danger Dashboardbtn"
                  style={{ color: "white" }}
                  onClick={FilterHide}
                >
                  <span className="mdi mdi-filter-minus-outline m-1" />
                </ButtonBase>
              </div>
            )}
              <div className="DashboardFilter">
                <ButtonBase
                  onClick={onClearedAllFiltersClick}
                  id="onClearedAllFiltersref"
                  className="btns theme-solid bg-danger clear-all-btn Dashboardbtn"
                >
                  <span className="mdi mdi-filter-remove m-1" />
                </ButtonBase>
              </div>
          
              <div className="DivDashboardFilterbtn">
                <div className="DashboardFilterbtn">
                  <Button
                    variant="contained"
                    className="btns theme-solid bg-success"
                    style={{ color: "white" }}
                    onClick={handleExportData}
                  >
                    <span className="mdi mdi-microsoft-excel m-1" />
                  </Button>
                </div>
              </div>
       
            </div>):(<div></div>)}
          </div>
        </div>
      )}
             
           
            </div>
          <PowerBIEmbed
            embedConfig={{
              type: "report",
              id,
              embedUrl,
              accessToken: report,
              filters: [selectedFilter],
              settings: {
                customLayout: {
                  displayOption: models.DisplayOption.FitToWidth,
                },
                filterPaneEnabled: false,
                navContentPaneEnabled: false,
                panes: {
                  filters: {
                    expanded: false,
                    visible: false,
                  },
                },
                visualSettings: {
                  visualHeaders: [
                    {
                      settings: {
                        visible: true,
                      },
                    },
                  ],
                },

                background: models.BackgroundType.Transparent,
              },
            }}
            eventHandlers={
              new Map([
                [
                  "loaded",
                  (event) => {
                    setShowReport(true);
                    setDateAppaer(true);
                  },
                ],
                [
                  "dataSelected",

                  (event) => {
                    setShowReport2(false);
                  },
                ],

                [
                  "rendered",
                  (event) => {
                    setRender(true);
                    setShowReport2(true);
                    loadBookmarks();
                  },
                ],
                ["bookmarkApplied", (event) => {}],

                [
                  "pageChanged",

                  (event) => {
                    const newPageName = event.detail.newPage.displayName;

                    switch (newPageName) {
                      case "Page 3":

                      sedetails(true);
                      selogin(false);
                        setTimeout(() => {
                          scrollToTop();
                        }, 5);
                        break;
                  
                      case "Page 4":
                        sedetails(false);
                        selogin(true);
                        setTimeout(() => {
                          scrollToTop();
                        }, 5);
                        break;
                      default:
                        setTimeout(() => {
                          scrollToTop();
                        }, 5);
                        break;
                    }
                  },
                ],

                [
                  "error",
                  (event) => {
                    const error = event.detail;
                    if (
                      error.detailedMessage === "Get report failed" ||
                      error.detailedMessage ===
                        "Access token has expired, resubmit with a new access token" ||
                      error.detailedMessage ===
                        "Fail to initialize - Could not resolve cluster"
                    ) {
                      Microsoft(true);
                      setRender(false);
                    } else console.log(error.detailedMessage);
                    showError(error.detailedMessage);
                  },
                ],
              ])
            }
            cssClassName={
              ShowReport ? "report-style-class-Show" : "report-style-class-hide"
            }
            getEmbeddedComponent={(embeddedReport) => {
              window.report = embeddedReport;
            }}
          />
        </div>
      )}
    </div>
    </div>
 
  
  );
};
