import React from "react";
import {
  Skeleton,
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
} from "@material-ui/lab";
import { Box, Divider } from "@material-ui/core";
import { CustomCard } from "../../CustomCard";

//styles
import useStyles from "./styles";

const ContactActivityItemTimeLineSkeleton = ({ numberSkeletonCard }) => {
  const styles = useStyles();
  const skeletonCardsAndDate = Array.from(
    { length: numberSkeletonCard },
    (_, index) => (
      <TimelineItem key={index}>
        <TimelineOppositeContent
          className={styles.customTimelineOppositeContent}
        >
          <Skeleton
            animation="wave"
            variant="text"
            className={styles.TimeDateElement}
          />
          <Skeleton
            animation="wave"
            variant="text"
            className={styles.TimeDateElement}
          />
          <Skeleton
            animation="wave"
            variant="text"
            className={styles.TimeDateElement}
          />
        </TimelineOppositeContent>
        <TimelineSeparator className={styles.timelineSeparator}>
          <TimelineDot className={styles.timelineDot} />
          <TimelineConnector className={styles.timelineConnector} />
        </TimelineSeparator>
        <TimelineContent className={styles.customTimelineContent}>
          <CustomCard
            boxShadow="xs"
            borderRadius="xl"
            borderColor="secondary"
            classes={styles.contactActivitesCard}
          >
            <Box className={styles.container_Lead_Score_Info}>
              <Box className={styles.container_LeadInfo}>
                <Skeleton
                  animation="wave"
                  variant="text"
                  className={styles.titleCard}
                />
                <Box className={styles.LeadInfo}>
                  <Skeleton
                    animation="wave"
                    variant="text"
                    className={styles.TitleInfo}
                  />
                  <Skeleton
                    animation="wave"
                    variant="text"
                    className={styles.ValueInfo}
                  />
                </Box>
                <Box className={styles.InfoItem}>
                  <Skeleton
                    animation="wave"
                    width="52px"
                    height="19px"
                    variant="text"
                  />
                  <Box className={styles.container_LeadIdValue}>
                    <Skeleton
                      animation="wave"
                      width="63px"
                      height="19px"
                      variant="text"
                    />
                    <Skeleton
                      animation="wave"
                      width="18px"
                      height="20px"
                      variant="text"
                    />
                  </Box>
                </Box>
                <Box className={styles.container_Status}>
                  <Skeleton
                    animation="wave"
                    width="35px"
                    height="24px"
                    variant="text"
                  />
                  <Skeleton
                    style={{ borderRadius: "20px" }}
                    animation="wave"
                    width="64px"
                    height="24px"
                    variant="text"
                  />
                </Box>
              </Box>
              <Box className={styles.container_ScoreInfo}>
                <Box className={styles.container_Score_CreatedBy_AssignTo}>
                  <Box className={styles.container_Score}>
                    <Skeleton
                      animation="wave"
                      width="35px"
                      height="24px"
                      variant="text"
                    />
                    <Skeleton
                      animation="wave"
                      width="35px"
                      height="24px"
                      variant="text"
                    />
                  </Box>
                  <Box className={styles.container_CreatedBy_AssignTo}>
                    <Box className={styles.container_CreatedBy}>
                      <Skeleton
                      className={styles.TitleInfo}
                        animation="wave"
                        variant="text"
                      />
                      <Skeleton
                       className={styles.ValueInfo}
                        animation="wave"
                        variant="text"
                      />
                    </Box>
                    <Box className={styles.container_AssignTo_ArrowIcon}>
                      <Skeleton
                     
                        animation="wave"
                        width="24px"
                        height="24px"
                        variant="text"
                      />
                      <Box className={styles.container_AssignTo}>
                        <Skeleton
                         className={styles.TitleInfo}
                          animation="wave"
                          variant="text"
                        />
                        <Skeleton
                          className={styles.ValueInfo}
                          animation="wave"
                          variant="text"
                        />
                      </Box>
                    </Box>
                  </Box>
                </Box>
                <Divider className={styles.divider} />
                <Box className={styles.container_Comment}>
                  <Skeleton
                    animation="wave"
                    className={styles.ValueInfo}
                    variant="text"
                  />
                  <Skeleton
                    animation="wave"
                    className={styles.TitleInfo}
                    variant="text"
                  />
                </Box>
              </Box>
            </Box>
          </CustomCard>
          <Box className={styles.container_MoreIcon}>
            <Skeleton
              className={styles.dot}
              variant="circle"
              width={4}
              height={4}
              animation="wave"
            />
            <Skeleton
              className={styles.dot}
              variant="circle"
              width={4}
              height={4}
              animation="wave"
            />
            <Skeleton
              className={styles.dot}
              variant="circle"
              width={4}
              height={4}
              animation="wave"
            />
          </Box>
        </TimelineContent>
      </TimelineItem>
    )
  );
  return (
    <Box className={styles.timelineContainer}>
      <Timeline className={styles.customTimeline}>
        {skeletonCardsAndDate}
      </Timeline>
    </Box>
  );
};
export default ContactActivityItemTimeLineSkeleton;
