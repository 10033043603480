import React, { useEffect, useState, useReducer, useCallback } from "react";
import { PowerBIEmbed } from "powerbi-client-react";
import { models } from "powerbi-client";
import "../MasterStyleDashborads/MasterStyleDashboards.scss";
import { showError, showSuccess } from "../../../../Helper";
import { MicrosoftToken, lookupItemsGetId } from "../../../../Services";
import { SpinnerDashboards } from "../SpinnerDashboards/SpinnerDashboards";
import { DateRangePickerComponent } from "../../../../Components/Controls/DateRangePickerComponent/DateRangePickerComponent";
import moment from "moment";
import {
  AutocompleteComponent,
  Inputs,
  SelectComponet,
} from "../../../../Components";
import { ActionsButtonsEnum, MediaEnum, Status } from "../../../../Enums";
import { useTranslation } from "react-i18next";
import { OrganizationUserSearch } from "../../../../Services/userServices";
import { useSelector, useDispatch } from "react-redux";
import { GlobalOrderFilterActions } from "../../../../store/GlobalOrderFilter/GlobalOrderFilterActions";
import { Button, ButtonBase } from "@material-ui/core";
import { CellsSpinner } from "../../../../SharedComponents/CellSpinnerComponent/CellsSpinner";
import { ReportsBuilderRoutes } from "../../../../routes";
import { CellSpinnerBi } from "../CellSpinnerBi/CellSpinnerBi";

const parentTranslationPath = "LeadsView";
const translationPath = "";

export const UnqualifiedLeadsFilter = ({ dynamicComponentProps }) => {
  const orderFilter = useSelector((state) => state.GlobalOrderFilterReducer);

  const { t } = useTranslation(parentTranslationPath);
  const dispatch = useDispatch();

  const [report, setReport] = useState();
  const [render, setRender] = useState(false);
  const [ShowReport, setShowReport] = useState(false);
  const [DateAppaer, setDateAppaer] = useState(false);
  const [Datetime, setDatetime] = useState(30);
  const [Leadtype, setLeadtype] = useState();
  const [Leadtypestatus, setLeadtypestatus] = useState(true);
  const [OperatorLeadType, setOperatorLeadType] = useState("All");
  const [LeadClass, setLeadClass] = useState();
  const [LeadClassstatus, setLeadClassstatus] = useState(true);
  const [OperatorLeadClass, setOperatorLeadClass] = useState("All");
  const [Leadstatus, setLeadstatus] = useState();
  const [Leadstatusstatus, setLeadstatusstatus] = useState(true);
  const [OperatorLeadstatus, setOperatorLeadstatus] = useState("All");
  const [datefilters, setdatefilter] = useState(true);
  const [vlueapper, setvlueapper] = useState(false);
  const [datefiltersActivity, setdatefilteractivity] = useState(false);
  const [ShowReport2, setShowReport2] = useState(false);
  const [datepicker, setdatepicker] = useState();

  const [salestransactions, setsalestransactions] = useState(false);
  const [Activity, setActivity] = useState(false);
  const [Leads, setLeads] = useState(false);
  const [leasetransaction, setleasetransaction] = useState(false);

  const [monthsAgo, setMonthsAgo] = useState(1);
  const [bookmark, setbookmark] = useState("");

  const currentDate = new Date();
  const countmonth = new Date(
    currentDate.setMonth(currentDate.getMonth() - monthsAgo)
  );

  const today = new Date();

  const count = new Date(new Date().setDate(today.getDate()));
  const count2 = new Date(new Date().setDate(today.getDate()));
  const datetimes = moment(count)
    .startOf("month")
    .format("YYYY-MM-DDTHH:mm:ss");
  const datetimes2 = moment(count2).format("YYYY-MM-DDTHH:mm:ss");

  const startDate = datetimes ? new Date(datetimes) : null;
  const endDate = datetimes2 ? new Date(datetimes2) : null;

  const dateRangeDefault = {
    startDate: startDate || null,
    endDate: endDate || null,
    key: "selection",
  };

  const [dateFilter, setDateFilter] = useState(dateRangeDefault);
  const [data, setdata] = useState([]);
  const [isDatePickerChanged, setIsDatePickerChanged] = useState(false);

  const reducer = (state, action) => {
    if (action.id !== "edit") return { ...state, [action.id]: action.value };
    if (action.id === "edit") {
      return {
        ...action.value,
      };
    }
    return undefined;
  };

  const [state, setState] = useReducer(reducer, {
    fromDate: null,
    toDate: null,
  });

  useEffect(() => {
    const currentState = {
      ...state,
      fromDate:
        (dateFilter.startDate &&
          moment(dateFilter.startDate)
            .startOf("day")
            .format("YYYY-MM-DDTHH:mm:ss")) ||
        datetimes,
      toDate:
        (dateFilter.endDate &&
          moment(dateFilter.endDate)
            .endOf("day")
            .format("YYYY-MM-DDTHH:mm:ss")) ||
        datetimes2,
    };
    setState({ value: currentState });
    setdata(currentState);
  }, [dateFilter]);

  const advancedFilter = {
    $schema: "http://powerbi.com/product/schema#advanced",
    target: {
      table: "UnqualifiedLeadsBi",
      column: datefilters ? "UnqualifiedCreatedOn" : "UnqualifiedUpdateOn",
    },
    filterType: 0,
    logicalOperator: "And",
    conditions: [
      {
        operator: "GreaterThanOrEqual",
        value: data.fromDate,
      },
      {
        operator: "LessThanOrEqual",
        value: data.toDate,
      },
    ],
    filterType: models.FilterType.AdvancedFilter,
  };

  // let selectedFilter;

  // switch (true) {
  //   case Leads:
  //     selectedFilter = advancedFilter;
  //     break;
  //   case Activity:
  //     selectedFilter = advancedFilterActivity;
  //     break;
  //   default:
  //     selectedFilter = advancedFilter;
  // }

  const [activeButton, setActiveButton] = useState("One");

  const handleButtonClick = (buttonName) => {
    setActiveButton(buttonName);
  };

  const handleYTDClick = () => {
    // Implement your YTD logic here
    // Update the date filter accordingly
    handleButtonClick("Two");
    setdatepicker(0);
    setdatefilter(true);
    const currentYear = new Date().getFullYear();
    const ytdStartDate = new Date(currentYear, 0, 1);

    setDateFilter({
      startDate: ytdStartDate,
      endDate: new Date(),
      key: "selection",
    });
  };
  const handleMTDClick = () => {
    handleButtonClick("One");
    const currentDate = new Date();
    const mtdStartDate = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      1
    );

    setDateFilter({
      startDate: mtdStartDate,
      endDate: currentDate,
      key: "selection",
    });
  };

  const FilterLeadClass = {
    $schema: "http://powerbi.com/product/schema#basic",
    target: {
      table: "LeadsView",
      column: "LeadClass",
    },
    operator: OperatorLeadClass,
    values: [LeadClass],
    filterType: models.FilterType.BasicFilter,
  };

  const Microsoft = async (isExpired) => {
    const res = await MicrosoftToken(isExpired);
    if (!(res && res.status && res.status !== 200)) {
      setReport(res);
      setRender(true);
    } else {
      setReport("");
      showError("Contact Your Bi Admin For Help");
    }
  };

  const [bookmarks, setBookmarks] = useState([]);
  const [error, setError] = useState(null);

  const loadBookmarks = async () => {
    const report = window.report;

    if (report) {
      try {
        const bookmarksManager = report.bookmarksManager;
        const fetchedBookmarks = await bookmarksManager.getBookmarks();
        setBookmarks(fetchedBookmarks);
        // console.log("Bookmarks:", fetchedBookmarks);
      } catch (error) {
        console.error("Error loading bookmarks:", error);
        setError(error);
      }
    }
  };
  const applyBookmark = async (bookmarkName) => {
    const report = window.report;
    if (report) {
      try {
        const bookmarksManager = report.bookmarksManager;
        bookmarksManager.apply(bookmarkName);
        // console.log("Bookmark applied successfully:", bookmarkName);
      } catch (error) {
        console.error("Error applying bookmark:", error);
        setError(error);
      }
    }
  };

  const onClearedAllFiltersClick = useCallback(() => {
    applyBookmark("Bookmark52ca87f8816103a900af");
    setDateFilter(dateRangeDefault);
    setLeadClass(0);
    setLeadClassstatus(true);
    setdatepicker(0);
    setdatefilter(true);
    handleMTDClick();
  }, []);

  const scrollToTop = useCallback(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  const handleRefreshClick = useCallback(() => {
    window.report
      .refresh()
      .then(() => {
        setShowReport2(false);
        console.log(true);
      })
      .catch((error) => {
        console.log(false);
      });
  }, []);

  useEffect(() => {
    Microsoft(false);
  }, []);
  useEffect(() => {
    if (LeadClassstatus) setOperatorLeadClass("All");
    else setOperatorLeadClass("In");
  }, [LeadClassstatus, LeadClass]);

  return (
    <div className="dashboardMain-PowerBIEmbed">
      <CellSpinnerBi isActive={!ShowReport} isAbsolute blue />

      {DateAppaer && (
        <div>
          <div className="MainFiltersBi">
          <CellSpinnerBi isActive={!ShowReport2} isAbsolute blue />
            <div className="selecterbi">
              {/* <div>
                <h2>Bookmarks</h2>
                {bookmarks.length > 0 ? (
                  <ul>
                    {bookmarks.map((bookmark) => (
                      <li key={bookmark.name}>
                        {bookmark.displayName}

                        {bookmark.name}

                        <button onClick={() => applyBookmark(bookmark.name)}>
                          Apply Bookmark
                        </button>
                      </li>
                    ))}
                  </ul>
                ) : (
                  <p>No bookmarks available.</p>
                )}

                {error && <p>Error: {error.message}</p>}
              </div>

              <div>
                <button
                  onClick={() => applyBookmark("Bookmark52ca87f8816103a900af")}
                >
                  Clear
                </button>
              </div> */}
              <div>
                <SelectComponet
                  data={[
                    { id: 1, LeadClass: "seller" },
                    { id: 2, LeadClass: "landlord" },
                    { id: 3, LeadClass: "buyer" },
                    { id: 4, LeadClass: "tenant" },
                  ]}
                  emptyItem={{
                    value: 0,
                    text: "select-lead-type",
                    isDisabled: false,
                  }}
                  value={LeadClass || 0}
                  defaultValue={0}
                  valueInput="id"
                  textInput="LeadClass"
                  onSelectChanged={(value) => {
                    switch (value) {
                      case 0:
                        setLeadClassstatus(true);
                        setLeadClass(0);
                        break;
                      default:
                        setLeadClass(value);
                        setLeadClassstatus(false);
                        setShowReport2(false);
                        break;
                    }
                  }}
                  wrapperClasses="w-auto"
                  themeClass="theme-transparent"
                  idRef="Lead_Type"
                  parentTranslationPath={parentTranslationPath}
                  translationPath={translationPath}
                  translationPathForData={translationPath}
                />
              </div>
            </div>

            <div className="selecterbi">
              <div>
                <SelectComponet
                  data={[
                    {
                      id: 1,
                      datepicker: "Update On",
                    },
                  ]}
                  emptyItem={{
                    value: 0,
                    text: `${translationPath}created-date`,
                    isDisabled: false,
                  }}
                  value={datepicker || 0}
                  defaultValue={0}
                  valueInput="id"
                  textInput="datepicker"
                  onSelectChanged={(value) => {
                    switch (value) {
                      case 0:
                        setdatepicker(0);
                        setdatefilter(true);
                        setShowReport2(false);
                        break;
                      default:
                        setdatepicker(1);
                        setdatefilter(false);
                        setShowReport2(false);
                        break;
                    }
                  }}
                  themeClass="theme-transparent"
                  wrapperClasses="w-auto"
                  idRef="Date_Select"
                  parentTranslationPath={parentTranslationPath}
                  translationPath={translationPath}
                  translationPathForData={translationPath}
                />
              </div>

              <div>
                <DateRangePickerComponent
                  onClearClicked={() => {
                    setDateFilter({
                      startDate: startDate,
                      endDate: endDate,
                      key: "selection",
                    });
                  }}
                  ranges={[dateFilter]}
                  onDateChanged={(selectedDate) => {
                    const newStartDate =
                      selectedDate.selection &&
                      selectedDate.selection.startDate;
                    const newEndDate = new Date(
                      moment(
                        selectedDate.selection && selectedDate.selection.endDate
                      ).endOf("day")
                    );

                    if (
                      newStartDate.getTime() !==
                        dateFilter.startDate.getTime() ||
                      newEndDate.getTime() !== dateFilter.endDate.getTime()
                    ) {
                      setShowReport2(false);
                    } else {
                      setShowReport2(true);
                    }

                    setDateFilter({
                      startDate: newStartDate,
                      endDate: newEndDate,
                      key: "selection",
                    });
                  }}
                />
              </div>
            </div>
            <div className="DashboardFilter">
              <div className="DivDashboardFilterbtn">
                <div className="DashboardFilterbtn">
                  <Button
                    variant="contained"
                    className={`btns theme-solid ${
                      activeButton === "Two" ? "bg-primary" : "bg-gray-light"
                    }`}
                    style={{
                      fontWeight: activeButton === "Two" ? "bold" : "normal",
                    }}
                    onClick={handleYTDClick}
                  >
                    <span className="mdi mdi-calendar-range m-1" />
                    Year to Date
                  </Button>
                </div>
                <div className="DashboardFilterbtn">
                  <Button
                    variant="contained"
                    className={`btns theme-solid ${
                      activeButton === "One" ? "bg-primary" : "bg-gray-light"
                    }`}
                    style={{
                      fontWeight: activeButton === "One" ? "bold" : "normal",
                    }}
                    onClick={handleMTDClick}
                  >
                    <span className="mdi mdi-calendar-today m-1" />
                    Month to Date
                  </Button>
                </div>
                <div className="DashboardFilterbtn">
                  <Button
                    variant="contained"
                    className="btns theme-solid bg-primary"
                    style={{ color: "white" }}
                    onClick={() =>
                      applyBookmark("Bookmarkd95e7e975a9b761d6ce0")
                    }
                  >
                    <span className="mdi mdi-filter-menu m-1" />
                    Open Filter
                  </Button>
                </div>
                <div className="DashboardFilterbtn">
                  <Button
                    variant="contained"
                    className="btns theme-solid bg-primary"
                    style={{ color: "white" }}
                    onClick={onClearedAllFiltersClick}
                  >
                    <span className="mdi mdi-filter-remove m-1" />
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {render && (
        <div className="dashboardMain">
          <PowerBIEmbed
            embedConfig={{
              type: "report",
              id:
                dynamicComponentProps &&
                dynamicComponentProps.config &&
                dynamicComponentProps.config.reportId,
              embedUrl:
                dynamicComponentProps &&
                dynamicComponentProps.config &&
                dynamicComponentProps.config.Url,
              accessToken: report,
              filters: [advancedFilter, FilterLeadClass],
              settings: {
                customLayout: {
                  displayOption: models.DisplayOption.FitToWidth,
                },
                filterPaneEnabled: false,
                navContentPaneEnabled: false,
                panes: {
                  filters: {
                    expanded: false,
                    visible: false,
                  },
                },
                background: models.BackgroundType.Transparent,
              },
            }}
            eventHandlers={
              new Map([
                [
                  "loaded",
                  (event) => {
                    setShowReport(true);
                    setDateAppaer(true);
                  },
                ],
                [
                  "dataSelected",

                  (event) => {
                    setShowReport2(false);
                  },
                ],

                [
                  "rendered",
                  (event) => {
                    setShowReport2(true);
                    loadBookmarks();
                    setRender(true);
                  },
                ],
                [
                  "bookmarkApplied",
                  (event) => {
                    // console.log(event.detail, "bookmarks");
              
                  },
                ],

                [
                  "pageChanged",

                  (event) => {
                    const newPageName = event.detail.newPage.displayName;

                    switch (newPageName) {
                      case "Leads Activity":
                        setShowReport2(false);
                        setTimeout(() => {
                          scrollToTop();
                        }, 5);
                        break;
                      default:
                        setShowReport2(false);
                        setTimeout(() => {
                          scrollToTop();
                        }, 5);
                        break;
                    }
                  },
                ],

                [
                  "error",
                  (event) => {
                    const error = event.detail;
                    if (
                      error.detailedMessage === "Get report failed" ||
                      error.detailedMessage ===
                        "Access token has expired, resubmit with a new access token" ||
                      error.detailedMessage ===
                        "Fail to initialize - Could not resolve cluster"
                    ) {
                      Microsoft(true);
                      setRender(false);
                    } else console.log(error.detailedMessage);
                    showError(error.detailedMessage);
                  },
                ],
              ])
            }
            cssClassName={
              ShowReport ? "report-style-class-Show" : "report-style-class-hide"
            }
            getEmbeddedComponent={(embeddedReport) => {
              window.report = embeddedReport;
            }}
          />
        </div>
      )}
    </div>
  );
};
