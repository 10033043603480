
export const ReportBuilderPermissions = 
{
  ViewSystemReports : {
    permissionsId: "86ee2d03-b514-4da2-28a0-08dab0da5710",
    permissionsName: "View System Reports",
    componentsId: null,
    components: null
  },
  EditSystemReports : {
    permissionsId: "1f0b2d3b-5591-4947-28a1-08dab0da5710",
    permissionsName: "Edit System Reports",
    componentsId: null,
    components: null
  },
  ExportSystemReports : {
    permissionsId: "93f96264-d066-41f0-28a2-08dab0da5710",
    permissionsName: "Export System Reports",
    componentsId: null,
    components: null
  },

  CreateSystemReport:  {
    permissionsId: "7fd644fd-80cd-4dfd-28a3-08dab0da5710",
    permissionsName: "Create System Report",
    componentsId: null,
    components: null
  },
  RunSystemReport :  {
    permissionsId: "4360196d-a05f-466b-28a4-08dab0da5710",
    permissionsName : "Run System Report",
    componentsId: null,
    components: null 
  },
  ExportReportAsXML : {
    permissionsId: "7c353b45-77a3-49f3-ecbe-08dad1f1b889",
    permissionsName: "Export Report As XML",
    componentsId: null,
    components: null
  },
  ExportReportAsJSON : {
    permissionsId: "d4e9e8fa-48e4-4160-ecbf-08dad1f1b889",
    permissionsName: "Export Report As JSON",
    componentsId: null,
    components: null
  },
  ExportReportAsPDF : {
    permissionsId: "1311597e-8f96-469e-ecc0-08dad1f1b889",
    permissionsName: "Export Report As PDF",
    componentsId: null,
    components: null
  },
  ExportReportAsCSV : {
    permissionsId: "b9961815-41eb-4caf-ecc1-08dad1f1b889",
    permissionsName: "Export Report As CSV",
    componentsId: null,
    components: null
  },
  ExportReportAsExcel  :{
    permissionsId: "d5b5232a-b7ea-4b40-ecc2-08dad1f1b889",
    permissionsName: "Export Report As Excel",
    componentsId: null,
    components: null
  },

  AddContactsReport : {
    permissionsId: "feda3196-2cdc-4f5b-0775-08dad1f91be5",
    permissionsName: "Add Contacts Report",
    componentsId: null,
    components: null
  },
  AddPropertiesReport :  {
    permissionsId: "05e445cb-21f8-4585-0776-08dad1f91be5",
    permissionsName: "Add Properties Report",
    componentsId: null,
    components: null
  },
  AddUnitsReport :  {
    permissionsId: "3eb6c0e8-b22b-4cbb-0777-08dad1f91be5",
    permissionsName: "Add Units Report",
    componentsId: null,
    components: null
  },
  AddLeadsReport : {
    permissionsId: "f2513182-bdd8-4ddb-0778-08dad1f91be5",
    permissionsName: "Add Leads Report",
    componentsId: null,
    components: null
  },
  AddActivitiesReport : {
    permissionsId: "61fc155c-30cb-43f7-0779-08dad1f91be5",
    permissionsName: "Add Activities Report",
    componentsId: null,
    components: null
  },
  AddTransactioReport :  {
    permissionsId: "3ab1273b-40ea-4775-077a-08dad1f91be5",
    permissionsName: "Add Transactio Report",
    componentsId: null,
    components: null
  }, 
  DateFilter : {
    permissionsId: "d1468c4b-5159-4028-e746-08daecccdaf8",
    permissionsName: "Date Filter",
    componentsId: null,
    components: null
  },
  SendSystemReportByEmail: {
    permissionsId: "bac0ffb4-58eb-48e6-9bbf-08db6d7686ff",
    permissionsName: "Send System Report By Email",
    description: null,
    componentsId: null,
    components: null
  } , 
  AddUserLoginHistoryReport : {
    permissionsId: "585dffe0-f9fe-4129-2b15-08dbca2fdea8",
    permissionsName: "Add User Login History Report",
    description: null,
    componentsId: null,
    components: null
  },
  
  AddRotationSchemesReport : {
    permissionsId: "00efab73-f496-4b2e-2b16-08dbca2fdea8",
    permissionsName: "Add Rotation Schemes Report",
    description: null,
    componentsId: null,
    components: null
  } , 
  BlacklistContact :{
     permissionsId: "4803cb02-7618-4d1b-5a65-08dc32a402e7",
     permissionsName: "Blacklist contact",
     description: null,
     componentsId: null,
     components: null
},
AngryBirdContact :{
    permissionsId: "b7ab1bdb-8261-4530-5a66-08dc32a402e7",
    permissionsName: "Angry Bird Contact",
    description: null,
    componentsId: null,
    components: null
} , 
Inquiries :{
   permissionsId: "c895f3ca-bb0f-499c-7952-08dcaf947ea8",
   permissionsName: "Inquiries",
   description: null,
   componentsId: null,
   components: null
},
CampaignsReport : {
   permissionsId: "598c5b7f-300b-4bc7-7962-08dcaf947ea8",
   permissionsName: "Campaigns Report",
   description: null,
   componentsId: null,
   components: null
 } , 
 CampaignReport :{
   permissionsId: "393e97a5-4287-4ddf-796f-08dcaf947ea8",
   permissionsName: "Campaign Report",
   description: null,
   componentsId: null,
   components: null
} , 

LeadAssignmentHistoryReport :{
   permissionsId : "15a53301-8714-4bc6-5177-08dce7a01c4d",
   permissionsName : "Lead Assignment History Report",
   description : null,
   componentsId: null,
   components: null
}
  
};
