import { config } from '../config';
import { HttpServices } from '../Helper';

const getProperties = async ({ pageSize, pageIndex, search }) => {
  const queryList = [];
  if (pageSize || pageSize === 0) queryList.push(`pageSize=${pageSize}`);
  if (pageIndex || pageIndex === 0)
    queryList.push(`pageIndex=${pageIndex + 1}`);
  if (search) queryList.push(`search=${search}`);
  const result = await HttpServices.get(
    `${config.server_address}/CrmDfm/Properties/ViewProperties?${queryList.join(
      '&'
    )}`
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};
const GetAllUnitModelAndFilesByPropertyId = async ({ propertyId }) => {
  // const queryList = [];
  const result = await HttpServices.get(
    `${config.server_address}/CrmDfm/Properties/GetAllUnitModelAndFilesByPropertyId/${propertyId}`
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};
/// /*****This Api Will Be Remove Soon***\  \\\\\
const GetAdvanceSearchProperties = async ({ pageSize, pageIndex }, body) => {
  /// /*****This Api Will Be Remove Soon***\  \\\\\
  /// /*****This Api Will Be Remove Soon***\  \\\\\
  const queryList = [];
  if (pageSize || pageSize === 0) queryList.push(`pageSize=${pageSize}`);
  /// /*****This Api Will Be Remove Soon***\  \\\\\
  if (pageIndex || pageIndex === 0)
    queryList.push(`pageIndex=${pageIndex + 1}`);
  /// /*****This Api Will Be Remove Soon***\  \\\\\
  const result = await HttpServices.post(
    `${config.server_address}/CrmDfm/Properties/AdvanceSearch?${queryList.join(
      '&'
    )}`,
    body
  )
    /// /*****This Api Will Be Remove Soon***\  \\\\\
    .then((data) => data)
    /// /*****This Api Will Be Remove Soon***\  \\\\\
    .catch((error) => error.response);
  /// /*****This Api Will Be Remove Soon***\  \\\\\
  return result;
  /// /*****This Api Will Be Remove Soon***\  \\\\\
};
/// /*****This Api Will Be Remove Soon***\  \\\\\

const PropertiesAdvanceSearchTest = async (
  { pageSize, pageIndex, isForLog },
  body
) => {
  const queryList = [];
  if (isForLog) queryList.push(`isForLog=${isForLog}`);
  if (pageSize || pageSize === 0) queryList.push(`pageSize=${pageSize}`);
  if (pageIndex || pageIndex === 0)
    queryList.push(`pageIndex=${pageIndex + 1}`);

  const result = await HttpServices.post(
    `${
      config.server_address
    }/CrmDfm/Properties/PropertiesAdvanceSearchTest?${queryList.join('&')}`,
    body
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};
const GetAdvanceSearchPropertyManagement = async (
  { pageSize, pageIndex },
  body
) => {
  const queryList = [];
  if (pageSize || pageSize === 0) queryList.push(`pageSize=${pageSize}`);
  if (pageIndex || pageIndex === 0)
    queryList.push(`pageIndex=${pageIndex + 1}`);
  const result = await HttpServices.post(
    `${
      config.server_address
    }/CrmDfm/Properties/AdvanceSearchPropertyManagement?${queryList.join('&')}`,
    body
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

const propertyDetailsGet = async ({ id }) => {
  const result = await HttpServices.get(
    `${config.server_address}/CrmDfm/Properties/GetPropertyById/${id}`
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};
const propertyDetailsPut = async ({ id, body }) => {
  const result = await HttpServices.put(
    `${config.server_address}/CrmDfm/Properties/UpdateProperty/${id}`,
    body
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};
const archivePropertyPut = async (propertyId) => {
  const result = await HttpServices.put(
    `${config.server_address}/CrmDfm/Properties/ArchiveProperty/${propertyId}`
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};
const propertyPost = async (body) => {
  const result = await HttpServices.post(
    `${config.server_address}/CrmDfm/Properties`,
    body
  )
    .then((response) => response)
    .catch((error) => error.response);
  return result;
};
const propertyFieldsGet = async (payload) =>
  // await base('get', `v1/properties/fields/${searchableValue}`, '', null);
  [];
const mergePropertyPut = async (payload) =>
  // await base('put', `v1/properties/mergeProperties/${id}`, token, body.data);
  ({});
const GetLatitudeAndLongitudeByProperty = async (id) => {
  const result = await HttpServices.get(
    `${config.server_address}/CrmDfm/Properties/GetLatitudeAndLongitudeByPropertyId/${id}`
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

const GetPropertyDocumentByProperty = async (id) => {
  const result = await HttpServices.get(
    `${config.server_address}/CrmDfm/Properties/GetPropertyDocumentByPropertyId/${id}`
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};
const GetPropertyImage = async (id) => {
  const result = await HttpServices.get(
    `${config.server_address}/CrmDfm/Properties/GetPropertyImageByPropertyId/${id}`
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

const PropertySpecification = async (body) => {
  const result = await HttpServices.post(
    `${config.server_address}/CrmDfm/Properties/PropertySpecification`,
    body
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};
const GetPropertySpecificationByPropertyId = async (id) => {
  const result = await HttpServices.get(
    `${config.server_address}/CrmDfm/Properties/GetPropertySpecificationByPropertyId/${id}`
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

const GetAllPropertyImagesCategoryByPropertyId = async (id) => {
  const pageIndex = 1;
  const pageSize = 1000;

  const queryList = [];
  queryList.push(`pageIndex=${pageIndex}`);
  queryList.push(`pageSize=${pageSize}`);

  const result = await HttpServices.get(
    `${
      config.server_address
    }/CrmDfm/Properties/GetAllPropertyImagesCategoryByPropertyId/${id}?${queryList.join(
      '&'
    )}`
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};
const GetAllPropertyVideosLocationCategoryByPropertyId = async (propertyId) => {
  const result = await HttpServices.get(
    `${config.server_address}/CrmDfm/Properties/GetAllPropertyVideosLocationCategoryByPropertyId/${propertyId}`
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};
const GetAllPropertyVideos = async (body) => {
  const result = await HttpServices.post(
    `${config.server_address}/CrmDfm/Properties/GetAllPropertyVideos`,
    body
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

const GetAllPropertyImageLocationCategoryByPropertyId = async (id) => {
  const pageIndex = 1;
  const pageSize = 1000;

  const result = await HttpServices.get(
    `${config.server_address}/CrmDfm/Properties/GetAllPropertyImageLocationCategoryByProperty/${id}/${pageIndex}/${pageSize}`
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};
const GetAllPropertyVideosCategoryByPropertyId = async (propertyId) => {
  const result = await HttpServices.get(
    `${config.server_address}/CrmDfm/Properties/GetAllPropertyVideosCategoryByPropertyId/${propertyId}`
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

const GetAllPropertyImages = async (body) => {
  const result = await HttpServices.post(
    `${config.server_address}/CrmDfm/Properties/GetAllPropertyImages`,
    body
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

const CreatePropertyImage = async (body) => {
  const result = await HttpServices.post(
    `${config.server_address}/CrmDfm/Properties/CreatePropertyImage`,
    body
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};
const UpdatePropertyVideo = async (body) => {
  const result = await HttpServices.post(
    `${config.server_address}/CrmDfm/Properties/UpdatePropertyVideo`,
    body
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

const UpdatePropertyImage = async (body) => {
  const result = await HttpServices.put(
    `${config.server_address}/CrmDfm/Properties/UpdatePropertyImage`,
    body
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};
const DeletePropertyImagesByCategoryId = async (categoryId, propertyId) => {
  const result = await HttpServices.put(
    `${config.server_address}/CrmDfm/Properties/DeletePropertyImagesByCategoryId?categoryId=${categoryId}&propertyId=${propertyId}`
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};
const DeletePropertyImagesCategory = async (categoryId, propertyId) => {
  const result = await HttpServices.put(
    `${config.server_address}/CrmDfm/Properties/DeletePropertyImagesCategory?categoryId=${categoryId}&propertyId=${propertyId}`
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};
const DeletePropertyVideosCategory = async (categoryId, propertyId) => {
  const result = await HttpServices.put(
    `${config.server_address}/CrmDfm/Properties/DeletePropertyVideosCategory?categoryId=${categoryId}&propertyId=${propertyId}`
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

const GetPropertyFixturesAndAmenities = async (propertyId) => {
  const result = await HttpServices.get(
    `${config.server_address}/CrmDfm/Properties/GetPropertyFixturesAndAmenities/${propertyId}`
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

const GetAllPropertyCampaigns = async (propertyId, pageIndex, pageSize) => {
  const queryList = [];
  queryList.push(`pageIndex=${(pageIndex === 0 && 1) || pageIndex + 1}`);
  queryList.push(`pageSize=${pageSize}`);

  const result = await HttpServices.get(
    `${
      config.server_address
    }/CrmDfm/PropertyMarketing/GetAllPropertyCampaigns/${propertyId}?${queryList.join(
      '&'
    )}`
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

const GetAllPaymentPlansByUnitId = async (UnitId) => {
  const result = await HttpServices.get(
    `${config.server_address}/CrmDfm/PropertyPaymentPlan/GetAllPaymentPlansByUnitId/${UnitId}`
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

const UpdateUnitPaymentPlanApi = async (UnitId, body) => {
  const result = await HttpServices.put(
    `${config.server_address}/CrmDfm/PropertyPaymentPlan/UpdateUnitPaymentPlan/${UnitId}`,
    body
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};
const UpdateUnitPaymentPlan = async ({ UnitId, body }) => {
  const result = await HttpServices.put(
    `${config.server_address}/CrmDfm/PropertyPaymentPlan/UpdateUnitPaymentPlan/${UnitId}`,
    body
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

const CreatePropertyCampaign = async (body) => {
  const result = await HttpServices.post(
    `${config.server_address}/CrmDfm/PropertyMarketing/PropertyCampaign`,
    body
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

const UpdatePropertyCampaign = async ({ campaignId, body }) => {
  const result = await HttpServices.put(
    `${config.server_address}/CrmDfm/PropertyMarketing/UpdatePropertyCampaign/${campaignId}`,
    body
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

const DeletePropertyCampaign = async ({ campaignId }) => {
  const result = await HttpServices.delete(
    `${config.server_address}/CrmDfm/PropertyMarketing/PropertyCampaign/${campaignId}`
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

const GetAllPaymentPlanByPropertyId = async ({
  pageSize,
  pageIndex,
  propertyId,
}) => {
  const result = await HttpServices.get(
    `${config.server_address}/CrmDfm/PropertyPaymentPlan/GetAllPaymentPlanByPropertyId/${pageIndex}/${pageSize}/${propertyId}`
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

const CreatePaymentPlan = async (body) => {
  const result = await HttpServices.post(
    `${config.server_address}/CrmDfm/PropertyPaymentPlan/CreatePaymentPlan`,
    body
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

const UpdatePaymentPlan = async ({ body, paymentPlanId }) => {
  const result = await HttpServices.put(
    `${config.server_address}/CrmDfm/PropertyPaymentPlan/UpdatePaymentPlan/${paymentPlanId}`,
    body
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

const DeletePaymentPlan = async ({ paymentPlanId }) => {
  const result = await HttpServices.delete(
    `${config.server_address}/CrmDfm/PropertyPaymentPlan/DeletePaymentPlan /${paymentPlanId}`
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

const GetAllUnitModelByPropertyId = async ({
  pageSize,
  pageIndex,
  propertyId,
}) => {
  const result = await HttpServices.get(
    `${config.server_address}/CrmDfm/Properties/GetAllUnitModelByPropertyId/${propertyId}/${pageIndex}/${pageSize}`
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

const GetAllPropertyUnitTypeAPI = async (propertyId ,operationType) => {
  const queryList = [];
  if(operationType)
  queryList.push(`operationType=${operationType}`);
  const result = await HttpServices.get(
    `${config.server_address}/CrmDfm/Properties/GetAllPropertyUnitType/${propertyId}?${queryList.join("&")}`
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

const CreatePropertyUnitType = async (body) => {
  const result = await HttpServices.post(
    `${config.server_address}/CrmDfm/Properties/CreatePropertyUnitType`,
    body
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

export const BulkUpdateProperties = async (body) => {
  const result = await HttpServices.put(
    `${config.server_address}/CrmDfm/Properties/BulkUpdateProperties`,
    body
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

export const GetPropertiesDuplicationCriteria = async (pageIndex, pageSize) => {
  try {
    const result = await HttpServices.get(
      `${config.server_address}/CrmDfm/Properties/GetPropertiesDuplicationCriteria/`,
      {
        params: pageIndex,
        pageSize,
      }
    );
    return result;
  } catch (error) {
    return error.message;
  }
};

export const GetDuplicateCriteriaProperties = async (
  propertiesDuplicationCriteriaId
) => {
  try {
    const result = await HttpServices.get(
      `${config.server_address}/CrmDfm/Properties/GetDuplicateCriteriaProperties/${propertiesDuplicationCriteriaId}`
    );
    return result;
  } catch (error) {
    return error.message;
  }
};

export const GetAllPropertyPlanInstallment = async (params) => {
  try {
    const queryList = Object.entries(params)
      .map(([key, value]) => `${key}=${value}`)
      .join('&');
    const result = await HttpServices.post(
      `${config.server_address}/CrmDfm/PropertyPlanInstallment/GetAllPropertyPlanInstallment?${queryList}`
    );
    return result;
  } catch (error) {
    return error.response;
  }
};

export const CreatePropertyPlanInstallment = async (data) => {
  try {
    const result = await HttpServices.post(
      `${config.server_address}/CrmDfm/PropertyPlanInstallment/CreatePropertyPlanInstallment`,
      data
    );
    return result;
  } catch (error) {
    return error.response;
  }
};

export const DeletePropertyPlanInstallment = async (id) => {
  try {
    const result = await HttpServices.delete(
      `${config.server_address}/CrmDfm/PropertyPlanInstallment/DeletePropertyPlanInstallment/${id}`
    );
    return result;
  } catch (error) {
    return error.response;
  }
};

export const UpdatePropertyPlanInstallment = async ( data) =>{
  try {
    const result = await HttpServices.put(
      `${config.server_address}/CrmDfm/PropertyPlanInstallment/UpdatePropertyPlanInstallment/${data.id}`,
      data
    );
    return result;
  } catch (error) {
    return error.response;
  } 
}

export const PropertiesAdvanceSearchByUnits = async (
  { pageSize, pageIndex },
  data
) => {
  try {
    const result = await HttpServices.post(
      `${config.server_address}/CrmDfm/Properties/PropertiesAdvanceSearchByUnits?pageIndex=${pageIndex}&pageSize=${pageSize}`,
      data
    );
    return result;
  } catch (error) {
    return error.response;
  }
};

export {
  GetAllUnitModelAndFilesByPropertyId,
  PropertySpecification,
  GetPropertySpecificationByPropertyId,
  getProperties,
  GetAdvanceSearchProperties,
  PropertiesAdvanceSearchTest,
  propertyDetailsGet,
  propertyDetailsPut,
  propertyPost,
  propertyFieldsGet,
  mergePropertyPut,
  archivePropertyPut,
  GetLatitudeAndLongitudeByProperty,
  GetPropertyDocumentByProperty,
  GetPropertyImage,
  GetAdvanceSearchPropertyManagement,
  GetAllPropertyImagesCategoryByPropertyId,
  GetAllPropertyImageLocationCategoryByPropertyId,
  GetAllPropertyImages,
  CreatePropertyImage,
  UpdatePropertyImage,
  GetPropertyFixturesAndAmenities,
  GetAllPropertyCampaigns,
  DeletePropertyCampaign,
  CreatePropertyCampaign,
  UpdatePropertyCampaign,
  GetAllPropertyVideosLocationCategoryByPropertyId,
  GetAllPropertyVideosCategoryByPropertyId,
  UpdatePropertyVideo,
  GetAllPropertyVideos,
  DeletePropertyImagesByCategoryId,
  DeletePropertyImagesCategory,
  DeletePropertyVideosCategory,
  GetAllPaymentPlanByPropertyId,
  CreatePaymentPlan,
  UpdatePaymentPlan,
  GetAllPaymentPlansByUnitId,
  UpdateUnitPaymentPlan,
  UpdateUnitPaymentPlanApi,
  DeletePaymentPlan,
  GetAllUnitModelByPropertyId,
  GetAllPropertyUnitTypeAPI,
  CreatePropertyUnitType,
};
