import { PowerBiEnum } from "../../../../Enums";
import { ExternalDashboardPermissions } from '../../../../Permissions' ; 
import { SegmentationLeadBranch } from "../SegmentationLeadBranch/SegmentationLeadBranch";

export const SegmentationLeadBranchLinks = [
  {
    index: 0,
    label: "Dashboard:General",
    component: SegmentationLeadBranch,
    config: {
      reportId: PowerBiEnum.SegmentationLeadBranch.reportid,
      groupId: PowerBiEnum.SegmentationLeadBranch.groupid,
      Url: PowerBiEnum.SegmentationLeadBranch.url,
    },
    permissionsId : ExternalDashboardPermissions.Tab1.permissionsId , 
  },

  {
    index: 1,
    label: "Dashboard:Demographic-Segmentation",
    component: SegmentationLeadBranch,
    config: {
      reportId: PowerBiEnum.SegmentationContactBranch.reportid,
      groupId: PowerBiEnum.SegmentationContactBranch.groupid,
      Url: PowerBiEnum.SegmentationContactBranch.url,
    },
    permissionsId : ExternalDashboardPermissions.Tab3.permissionsId , 
  },
  {
    index: 2,
    label: "Dashboard:Owners-Segmentation",
    component: SegmentationLeadBranch,
    config: {
      reportId: PowerBiEnum.SegmentationOwnerBranch.reportid,
      groupId: PowerBiEnum.SegmentationOwnerBranch.groupid,
      Url: PowerBiEnum.SegmentationOwnerBranch.url,
    },
    permissionsId : ExternalDashboardPermissions.Tab3.permissionsId , 
  },
 
];
