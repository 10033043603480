import React, { useCallback, useEffect, useReducer, useState } from 'react'
import moment from 'moment';
import Joi from 'joi';
import btnLoader from '../../../../../../assets/images/GIF_images/btn-loader.gif';
import { useTranslation } from "react-i18next";
import { ButtonBase } from '@material-ui/core';
import { AutocompleteComponent, DatePickerComponent, Inputs } from '../../../../../../Components';
import { PaymentMethodsBanner } from '../../../Utilities';
import { CreateInvoice, GetLookupItemsByLookupTypeName, GetUnitLeaseDetails, lookupItemsGetId } from '../../../../../../Services';
import { formatCommasForPriceUnit } from '../../../../../../Helper/formatCommas.Helper';
import { GetParams, showError, showSuccess } from '../../../../../../Helper';
import { StaticLookupsIds } from '../../../../../../assets/json/StaticLookupsIds';
import { UnitsOperationTypeEnum } from '../../../../../../Enums';
import { PaymentMethodsData } from '../../../Utilities/PaymentMethodsBanner/PaymentMethodsData';

export const CreateInvoiceForm = ({
  unitTransactionId,
  numberOfPaymentInvoices,
  reloadInvoices,
  parentTranslationPath,
  translationPath
}) => {
  const unitId = +GetParams('id');
  const { t } = useTranslation(parentTranslationPath);

  const invoiceStatus = {
    Paid: {
      key: 1,
      value: 'Paid',
    },
    Unpaid: {
      key: 2,
      value: 'Unpaid',
    },
    PartiallyPaid: {
      key: 3,
      value: 'PartiallyPaid',
    },
    Canceled: {
      key: 4,
      value: 'Canceled',
    },
  }

  const defaultSelected = {
    paymentType: null,
    paymentAmount: '',
    dateOfPayment: null,
    contactType: null,
    paymentMode: Object.values(PaymentMethodsData)[0],
  }

  const reducer = useCallback((state, action) => {
    if (action.id !== "edit") return { ...state, [action.id]: action.value };
    return {
      ...action.value,
    };
  }, []);

  const [isLoading, setIsLoading] = useState(false);
  const [selected, setSelected] = useReducer(reducer, defaultSelected);
  const [data, setData] = useReducer(reducer, {
    paymentTypes: [],
    contactTypes: [],
    paymentDetails: null,
  });

  const schema = Joi.object({
    paymentType: Joi.object()
      .required(),
    contactType: Joi.object()
      .required(),
    dateOfPayment: Joi.string()
      .required(),
    paymentMode: Joi.object()
      .required(),
  })
    .options({
      abortEarly: false,
      allowUnknown: true,
    })
    .validate(selected);


  const getPaymentTypes = async () => {
    const res = await GetLookupItemsByLookupTypeName({
      lookUpName: "LeasePaymentType",
      pageSize: 100,
      pageIndex: 1,
    });
    if (!(res && res.status && res.status !== 200))
      setData({ id: "paymentTypes", value: res?.result || [] });
    else setData({ id: "paymentTypes", value: [] });
  };

  const getContactTypes = async () => {
    const res = await lookupItemsGetId({
      lookupTypeId: StaticLookupsIds.ContactTypes,
      lookupParentId: UnitsOperationTypeEnum.rent.key,
    });
    if (!(res && res.status && res.status !== 200))
      setData({ id: "contactTypes", value: res || [] });
    else setData({ id: "contactTypes", value: [] });
  };

  const getPaymentDetails = async () => {
    const res = await GetUnitLeaseDetails(unitId);
    if (!(res && res.status && res.status !== 200))
      setData({ id: "paymentDetails", value: res });
    else setData({ id: "paymentDetails", value: null });
  };

  const createInvoice = async () => {
    setIsLoading(true);
    const body = convertSelectedToBody();

    const res = await CreateInvoice(body);
    if (!(res && res.status && res.status !== 200)) {
      showSuccess(t(`${translationPath}payment-invoice-created-successfully`));
      setSelected({ id: 'edit', value: defaultSelected })
      reloadInvoices();
    } else showError(t(`${translationPath}payment-invoice-create-failed`));

    setIsLoading(false);
  };

  const convertSelectedToBody = () => {
    return ({
      unitId,
      unitTransactionId,
      paymentNo:   numberOfPaymentInvoices + 1,
      paymentTypeId: selected.paymentType?.lookupItemId || null,
      paymentModeId: selected.paymentMode?.id || null,
      invoiceContactTypeId: selected.contactType?.lookupItemId || null,
      dueOn: selected.dateOfPayment,
      InvoiceStateId: invoiceStatus.Unpaid.key,
      amountDue: selected.paymentAmount || 0,
      amountDuePercentage: 0,
      tax: 0,
      taxPercentage: 0,
      amountExTax: 0,
    })
  }

  const getPaymentAmount = (paymentType) => {
    let paymentAmount = 0;
    if (paymentType && data.paymentDetails) {
      // paymentAmount = getPaymentAmountFromUnitData(paymentType, data.paymentDetails);
      switch (paymentType.lookupItemName) {
        case "Rent per year":
          paymentAmount = data.paymentDetails.rentPerYear;
          break;
        case "Security deposit":
          paymentAmount = data.paymentDetails.securityDeposit;
          break;
        case "Commission":
          paymentAmount = data.paymentDetails.commission;
          break;
        case "Agency fee (Landlord)":
          paymentAmount = data.paymentDetails.agencyFeeLandlord;
          break;
        case "External agency fee (Landlord)":
          paymentAmount = data.paymentDetails.externalAgencyFeeLandlord;
          break;
        case "Net amount (Landlord)":
          paymentAmount = data.paymentDetails.netAmountLandLord;
          break;
        case "Agency fee (Tenant)":
          paymentAmount = data.paymentDetails.agencyFeeTenant;
          break;
        case "External agency fee (Tenant)":
          paymentAmount = data.paymentDetails.externalAgencyFeeTenant;
          break;
        case "Net amount (Tenant)":
          paymentAmount = data.paymentDetails.netAmountTenant;
          break;
        case "Purchase price":
          paymentAmount = data.paymentDetails.purchasePrice;
          break;
        case "Agency fee VAT 5%":
          paymentAmount = data.paymentDetails.agencyFeeFivePercentValueAddedTax;
          break;
        case "Chiller deposit Lease ":
          paymentAmount = data.paymentDetails.chillerDeposit;
          break;
        case "Management fee Lease":
          paymentAmount = data.managementFee.commission;
          break;
        default:
          break;
      }
      setSelected({ id: 'paymentAmount', value: paymentAmount || 0 });
    } else setSelected({ id: 'paymentAmount', value: '' });
  }

  const getIsCreateBtnDisabled = () => {
    const hasEmptyFields = schema?.error?.message;
    return isLoading || hasEmptyFields;
  }

  const saveHandler = () => {
    createInvoice();
  }

  useEffect(() => {
    getPaymentDetails();
  }, []);

  return (<div>
    <div className="b-bottom relative">
      <div className='d-flex fj-start gap-16 w-75'>
        <div className="w-25 mt-2 pt-1">
          <p className="fw-simi-bold m-0">{t(`${translationPath}Payment-type*`)}</p>
          <p className="light-text">{t(`${translationPath}Payment-type-desc`)}</p>
        </div>
        <div className="w-50 mt-2 mb-3 pt-1">
          <AutocompleteComponent
            idRef='Payment-typeIdRef'
            inputPlaceholder='select'
            selectedValues={selected.paymentType}
            multiple={false}
            data={data.paymentTypes || []}
            displayLabel={(option) => option.lookupItemName || ''}
            withoutSearchButton
            isWithError
            parentTranslationPath={parentTranslationPath}
            translationPath={translationPath}
            onOpen={() => {
              if (data.paymentTypes && data.paymentTypes.length == 0)
                getPaymentTypes();
            }}
            onChange={(_, newValue) => {
              setSelected({ id: 'paymentType', value: newValue });
              getPaymentAmount(newValue);
            }}
          />
        </div>
      </div>
      {selected.paymentType ?
        <div className='payment-type-chip'>
          <span className='fz-12 light-text'>{`${selected.paymentType?.lookupItemName} Amount:`}</span>
          <span className='fz-14 fw-simi-bold'>{`${formatCommasForPriceUnit(selected.paymentAmount)} AED`}</span>
        </div> : null}
    </div>
    <div className="b-bottom">
      <div className='d-flex fj-start gap-16 w-75'>
        <div className="w-25 mt-2 pt-1">
          <p className="fw-simi-bold m-0">{t(`${translationPath}Amount`)}</p>
        </div>
        <div className="w-50 mt-2 mb-3 pt-1">
          <Inputs
            withNumberFormat
            idRef="paymentAmountRef"
            inputPlaceholder={`1,000,00`}
            value={selected.paymentAmount}
            endAdornment={<span className="px-2">AED</span>}
            type="number"
            isDisabled
          />
        </div>
      </div>
    </div>
    <div className="b-bottom">
      <div className='d-flex fj-start gap-16 w-75'>
        <div className="w-25 mt-2 pt-1">
          <p className="fw-simi-bold m-0">{t(`${translationPath}Date-of-payment*`)}</p>
        </div>
        <div className="w-50 mt-2 mb-3 pt-1">
          <DatePickerComponent
            idRef='dateOfPaymentRef'
            labelClasses='Requierd-Color'
            placeholder='DD/MM/YYYY'
            value={selected.dateOfPayment}
            onDateChanged={(newValue) => {
              setSelected({ id: 'dateOfPayment', value: (newValue ? moment(newValue).format() : null), });
            }}
          />
        </div>
      </div>
    </div>
    <div className="b-bottom">
      <div className='d-flex fj-start gap-16 w-75'>
        <div className="w-25 mt-2 pt-1">
          <p className="fw-simi-bold m-0">{t(`${translationPath}Payment-from*`)}</p>
          <p className="light-text">{t(`${translationPath}Payment-from-desc`)}</p>
        </div>
        <div className="w-50 mt-2 mb-3 pt-1">
          <AutocompleteComponent
            idRef='Payment-typeIdRef'
            inputPlaceholder='select'
            selectedValues={selected.contactType}
            multiple={false}
            data={data.contactTypes || []}
            displayLabel={(option) => option.lookupItemName || ''}
            withoutSearchButton
            isWithError
            parentTranslationPath={parentTranslationPath}
            translationPath={translationPath}
            onOpen={() => {
              if (data.contactTypes && data.contactTypes.length == 0)
                getContactTypes();
            }}
            onChange={(_, newValue) => {
              setSelected({ id: 'contactType', value: newValue });
            }}
          />
        </div>
      </div>
    </div>
    <PaymentMethodsBanner
      isDisabled={false}
      activeCard={selected.paymentMode}
      onActiveCardChange={(item) => setSelected({ id: 'paymentMode', value: item })}
      parentTranslationPath={parentTranslationPath}
      translationPath={translationPath}
    />
     <div className="d-flex-v-center-h-between my-3">
     <p className="fw-simi-bold m-0 mb-1 fz-16 mb-1">{t(`${translationPath}Invoices`)}</p>
      <ButtonBase
        className="btns theme-propx solid mr-0"
        onClick={saveHandler}
        disabled={getIsCreateBtnDisabled()}
      >
        {t(`${translationPath}create-payment-invoice`)}
        {isLoading ?
          <img src={btnLoader} className="ml-1" width="12" height="12" />
          : false
        }
      </ButtonBase>
    </div>
  </div>
  )
}