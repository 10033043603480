import React, {
  useCallback,
  useEffect,
  useReducer,
  useRef,
  useState,
} from "react";
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Dialog,
  ButtonBase,
  Tabs,
  Tab,
  makeStyles,
} from "@material-ui/core";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import Joi from "joi";
import moment from "moment";
import {
  AutocompleteComponent,
  DatePickerComponent,
  Inputs,
  Spinner,
  SwitchComponent,
  SelectComponet,
} from "../../Components";
import { ActivityFilesUploader } from "../../Components/UploaderActivitieFileComponent/ActivityFilesUploader";
import { getErrorByName, showError, showSuccess } from "../../Helper";
import {
  OrganizationUserSearch,
  GetActivityTypeBasedOnRelatedType,
  CreateActivity,
  EditActivity,
  GetActivityFilesById,
  GetAllActivityTypes,
  GetAllSystemTemplateByCategoryId,
  leadDetailsGet,
  MyLeadUpdateLead,
} from "../../Services";
import {
  ActivitiesRelatedToActivitiesTypeEnum,
  TemplateCategoryEnum,
} from "../../Enums";
import { GetActivityTypeById } from "../../Services/ActivitiesTypesServices";
import { ActivtityRemindersTab } from "./ActivtityRemindersTab";
import { ActivityRelatedToInput } from "./ActivityRelatedToInput";
import { ActivityNotificationsTab } from "./ActivityNotificationsTab";
import { QualifyLeadDialog } from "../../Views/Home/LeadsView/LeadsUtilities/Dialogs/QualifyLeadDialog/QualifyLeadDialog";
import { GetTeamLeadsByUserId } from "../../Services/userServices";

const LeadType = {
  Seller: 1,
  Landlord: 2,
  Buyer: 3,
  Tenant: 4,
};

const RelateType = {
  UnitType: 1,
  UnitOperation: 2,
  UnitSaleType: 3,
  Contact: 4,
  Lead: 5,
};

const durationTypes = [
  {
    key: 1,
    value: "minutes",
    momentKey: "minutes",
  },
  {
    key: 2,
    value: "hours",
    momentKey: "hours",
  },
  {
    key: 3,
    value: "days",
    momentKey: "days",
  },
];

export const ActivitiesManagementDialog = ({
  activeItem,
  setActiveItem,
  onSave,
  parentTranslationPath,
  translationPath,
  open,
  close,
  isGeneralDialog,
  actionItemId,
  leadClass,
  leadStageId,
  contactId,
  contactClassificationId,
  unitSaleTypeId,
  unitOperationId,
  unitTypeId,
}) => {
  const { pathname } = useLocation();
  const { t } = useTranslation([parentTranslationPath, "Shared"]);
  const reducer = useCallback((state, action) => {
    if (action.id !== "edit") return { ...state, [action.id]: action.value };
    return {
      ...action.value,
    };
  }, []);
  const loginResponse = useSelector((state) => state.login.loginResponse);
  const relatedTypePayload = {
    leadClassId: LeadType[leadClass],
    leadStageId,
    unitOperationId,
    unitSaleTypeId,
    contactClassificationId,
    contactId,
    unitTypeId,
    listActivityTypeRelationTypes: [],
  };

  const isSaleActivitiesView = pathname.includes("activities-sales");
  const isLeaseActivitiesView = pathname.includes("activities-lease");
  const isContactCenterActivitiesView =
    pathname.split("/home/")[1] === "Activities";
  const isLeadActivitiesView =
    pathname.includes("lead-profile-edit") ||
    pathname.includes("lead-sales") ||
    pathname.includes("lead-lease") ||
    pathname.includes("leads") ||
    pathname.includes("mortgage-leads") ||
    pathname.includes("lead-owner-lead");
  const isMortgageActivitiesView = pathname.includes("activities-mortgage");
  const isUnitActivitiesView = pathname.includes("unit");
  const isContactActivitiesView =
    pathname.includes("contact-profile-edit") ||
    pathname.includes("Contacts-CRM/view");
  const isLeadOwnerActivitiesView = pathname.includes("lead-owner-activity");

  const userInfo = JSON.parse(localStorage.getItem("session"));
  const creatorInfo = {
    id: userInfo?.userId,
    value: userInfo?.fullName,
    type: "creator",
  };

  if (
    (leadClass && leadStageId) ||
    isLeadActivitiesView ||
    isContactActivitiesView ||
    isMortgageActivitiesView
  ) {
    relatedTypePayload.listActivityTypeRelationTypes.push(
      ActivitiesRelatedToActivitiesTypeEnum.lead.key
    );
  }
  if ((contactClassificationId && contactId) || isContactActivitiesView) {
    relatedTypePayload.listActivityTypeRelationTypes.push(
      ActivitiesRelatedToActivitiesTypeEnum.contact.key
    );
  }
  if (
    unitOperationId ||
    unitSaleTypeId ||
    isUnitActivitiesView ||
    isSaleActivitiesView ||
    isLeaseActivitiesView ||
    unitTypeId
  ) {
    relatedTypePayload.listActivityTypeRelationTypes.push(
      ActivitiesRelatedToActivitiesTypeEnum.unit.key
    );
  }

  const useStyles = makeStyles((theme) => ({
    root: {
      padding: "0px",
      paddingBottom: "5px",
      textTransform: "initial",
    },
    indicator: {
      backgroundColor: "var(--c-primary)",
    },
    labelIcon: {
      minHeight: "unset",
    },
    wrapper: {
      display: "flex",
      alignItems: "flex-start",
      flexDirection: "row",
    },
    fixed: {
      width: "fit-content",
      margin: "auto",
    },
    scroller: {
      flex: "unset",
    },
  }));

  const classes = useStyles();
  const searchTimer = useRef(null);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isEditDialog, setIsEditDialog] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isWithReminder, setIsWithReminder] = useState();
  const [withDateTime, setWithDateTime] = useState(false);
  const [withDuration, setWithDuration] = useState(false);
  const [activityRelatedToList, setActivityRelatedToList] = useState([]);
  const [isRelatedToNotSelected, setIsRelatedToNotSelected] = useState(true);
  const [defaultReminders, setDefaultReminders] = useState(null);
  const [leadChangeHandler, setLeadChangeHandler] = useState(false);
  const [isDateTimeCustom, setIsDateTimeCustom] = useState(false);
  const [activityAssignToOptions, setActivityAssignToOptions] = useState([]);
  const [remindersWithoutEmptyItems, setRemindersWithoutEmptyItems] = useState(
    []
  );
  const [changeRelatedLead, setChangeRelatedLead] = useState(false);
  const [isEdit, setIsEdit] = useState(null);
  const [filter] = useState({ pageIndex: 0, pageSize: 25 });
  const [tabIndex, setTabIndex] = useState(0);
  const [roles, setRoles] = useState(null);
  const [isOpenQualifyLeadDialog, setIsOpenQualifyLeadDialog] = useState(false);
  const [qualificationData, setQualificationData] = useState(null);
  const [leadId, setLeadId] = useState(null);
  const [idForTeamLeads, setIdForTeamLeads] = useState(null);
  const [loadings, setLoadings] = useReducer(reducer, {
    activityAssignments: false,
    activityTypes: false,
    gettingActivities: false,
    teamLeads: false,
  });
  const [selected, setSelected] = useReducer(reducer, {
    activityAssign: null,
    activityType: null,
    relatedUnit: null,
    relatedLead: null,
    relatedContact: null,
    relatedPortfolio: null,
    relatedWorkOrder: null,
    relatedMaintenanceContract: null,
    reminderForOptions: [],
    reminderTypeOptions: [],
    durationType: 1,
    durationAmount: 0,
  });
  const [classifications, setClassifications] = useReducer(reducer, {
    leadCategories: [],
    operationTypes: [],
    saleTypes: [],
    unitTypes: [],
    contactClass: [],
  });
  const [data, setData] = useReducer(reducer, {
    activityAssignments: [],
    activityTypes: [],
    reminderTemplates: [],
    relatedLead: [],
    relatedContact: [],
    relatedUnit: [],
    relatedPortfolio: [],
    relatedWorkOrder: [],
    relatedMaintenanceContract: [],
  });
  const [state, setState] = useReducer(reducer, {
    assignAgentId: null,
    activityTypeId: null,
    unitId: null,
    relatedUnitNumberId: null,
    relatedLeadNumberId: null,
    relatedContactNumberId: null,
    relatedPortfolioId: null,
    relatedWorkOrderId: null,
    relatedMaintenanceContractId: null,
    activityDate: moment().add(2, "minutes").format("YYYY-MM-DDTHH:mm:ss"),
    activityEndDate: null,
    activityDuration: null,
    subject: null,
    comments: null,
    isOpen: true,
    activityReminders: [],
    createdByName: loginResponse?.fullName || null,
  });

  const getTimeValue = (item) => {
    if (!state.activityDate) return 0;
    const currentTimeAmountType = durationTypes.find(
      (items) => items.key === (item.reminderAmountOfTimeType || 1)
    );

    return moment(state.activityDate).diff(
      item.reminderTime,
      currentTimeAmountType.momentKey
    );
  };

  const handleTabClick = (e, newValue) => {
    setTabIndex(newValue);
  };

  const getAllActivityAssignments = useCallback(
    async (value) => {
      setLoadings({ id: "activityAssignments", value: true });
      const res = await OrganizationUserSearch({
        ...filter,
        rolesIds: roles,
        name: (value && value.value) || "",
        userStatusId: 2,
      });
      if (!(res && res.status && res.status !== 200)) {
        const localValue = (res && res.result) || [];
        if (localValue.length > 0) {
          setData({
            id: "activityAssignments",
            value: localValue,
          });
        } else {
          setData({
            id: "activityAssignments",
            value: [],
          });
        }

        setLoadings({ id: "activityAssignments", value: false });
      }
    },
    [filter, roles]
  );

  async function initialActivityAssigTo() {
    setLoadings({ id: "activityAssignments", value: true });
    const res = await OrganizationUserSearch({
      ...filter,
      rolesIds: roles,
      userName: userInfo?.userName || null,
      name: userInfo?.fullName || null,
      email: userInfo?.email || null,
      userStatusId: 2,
    });
    if (!(res && res.status && res.status !== 200)) {
      const localValue = (res && res.result) || [];
      if (localValue.length > 0) {
        setSelected({
          id: "activityAssign",
          value: localValue[0],
        });
      } else {
        setSelected({
          id: "activityAssign",
          value: null,
        });
      }

      setIdForTeamLeads(localValue[0].id || null);
      setState({
        id: "assignAgentId",
        value: (localValue[0] && localValue[0].id) || null,
      });

      handleReminderForAgentOptions(localValue[0]);

      setLoadings({ id: "activityAssignments", value: false });
    }
  }

  const getAllSystemTemplateByCategoryId = useCallback(async () => {
    setLoadings({ id: "reminderTemplates", value: true });
    const res = await GetAllSystemTemplateByCategoryId(
      TemplateCategoryEnum.Activity.key
    );
    if (!(res && res.status && res.status !== 200)) {
      const mappedTemplatesData =
        res &&
        res.map((item) => ({
          templateName: item.systemTemplateName,
          templateId: item.systemTemplateId,
        }));
      setData({ id: "reminderTemplates", value: mappedTemplatesData || [] });
    } else setData({ id: "reminderTemplates", value: [] });

    setLoadings({ id: "reminderTemplates", value: false });
  }, [filter]);

  const handleActivityRelatedToList = (relatedTo) => {
    if (relatedTo) {
      let filteredRelatedTo = [];

      if (
        isGeneralDialog ||
        isContactCenterActivitiesView ||
        isLeadOwnerActivitiesView ||
        isSaleActivitiesView ||
        isLeaseActivitiesView
      ) {
        filteredRelatedTo = relatedTo;
        setActivityRelatedToList(filteredRelatedTo || []);
        return;
      }

      if (isContactActivitiesView) {
        filteredRelatedTo = relatedTo.filter(
          (item) =>
            item.relatedToName === "Contact" ||
            item.relatedToName === "Lead" ||
            item.relatedToName === "Unit"
        );
        setActivityRelatedToList(filteredRelatedTo || []);
        return;
      }

      if (isLeadActivitiesView) {
        filteredRelatedTo = relatedTo.filter(
          (item) => item.relatedToName !== "Contact"
        );
        setActivityRelatedToList(filteredRelatedTo || []);
        return;
      }

      if (isUnitActivitiesView) {
        filteredRelatedTo = relatedTo.filter(
          (item) =>
            item.relatedToName == "Lead" ||
            item.relatedToName == "Unit" ||
            item.relatedToName == "Contact"
        );
        setActivityRelatedToList(filteredRelatedTo || []);
        return;
      }

      if (isMortgageActivitiesView) {
        filteredRelatedTo = relatedTo.filter(
          (item) =>
            item.relatedToName == "Lead" || item.relatedToName == "Contact"
        );
        setActivityRelatedToList(filteredRelatedTo || []);
        return;
      }
    }

    setActivityRelatedToList([]);
  };

  const getActivityTypeById = useCallback(async () => {
    setLoadings({ id: "activityTypes", value: true });

    setIsEdit(activeItem ? true : false);

    if (state.activityTypeId !== null) {
      const res = await GetActivityTypeById(state.activityTypeId, leadId);
      if (!(res && res.status && res.status !== 200)) {
        const assignToUserRoles = res.assignedToActivityTypeRoles
          ? res.assignedToActivityTypeRoles.map((item) => item.rolesId)
          : [];
        setRoles(assignToUserRoles);
        setIsWithReminder(res.withReminder);
        setWithDateTime(res.withDateTime);
        setWithDuration(res.withDuration);
        setIsDateTimeCustom(res.withDateTimeCustom ?? false);
        setDefaultReminders(
          res?.leadStageActivityTypeReminder
            ? res?.leadStageActivityTypeReminder?.map((reminder) => ({
                reminderTo:
                  reminder?.customActivityTypeReminderRecipient[0]
                    ?.recipientType,
                reminderTime: reminder?.reminderTime,
                reminderType: reminder?.reminderType,
                templateId: reminder?.systemTemplateId,
                templateName: reminder?.templateName,
              }))
            : res.activityTypeReminders
        );
        handleActivityRelatedToList(res.relatedTo);
        if (res.activityTypeRelates?.length > 0) {
          setClassifications({
            id: "edit",
            value: {
              leadCategories:
                res.activityTypeRelates
                  ?.filter((item) => item.relateType === RelateType.Lead)
                  .map((item) => item.enumValue) ?? [],
              operationTypes:
                res.activityTypeRelates
                  ?.filter(
                    (item) => item.relateType === RelateType.UnitOperation
                  )
                  .map((item) => item.lookupsId) ?? [],
              saleTypes:
                res.activityTypeRelates
                  ?.filter(
                    (item) => item.relateType === RelateType.UnitSaleType
                  )
                  .map((item) => item.lookupsId) ?? [],
              unitTypes:
                res.activityTypeRelates
                  ?.filter((item) => item.relateType === RelateType.UnitType)
                  .map((item) => item.lookupsId) ?? [],
              contactClass:
                res.activityTypeRelates
                  ?.filter((item) => item.relateType === RelateType.Contact)
                  .map((item) => item.lookupsId) ?? [],
              contactClassNames:
                res.activityTypeRelates
                  ?.filter((item) => item.relateType === RelateType.Contact)
                  .map((item) => item.lookupsName) ?? [],
            },
          });
        }
        setSelected({ id: "activityType", value: res });
      }
    }

    setLoadings({ id: "activityTypes", value: false });
  }, [state.activityTypeId, leadId]);

  const GetTeamLeadsByUserIdAPICall = async () => {
    setLoadings({ id: "teamLeads", value: true });
    if (idForTeamLeads) {
      const res = await GetTeamLeadsByUserId(idForTeamLeads);
      if (!(res && res.status && res.status !== 200)) {
        const tempReminderForOptions = selected?.reminderForOptions;
        const filteredData = tempReminderForOptions?.filter(
          (obj) => obj?.type !== "teamLead"
        );

        const newTeamLeads =
          res?.map((item) => {
            return {
              id: item.teamLeadId,
              value: item.teamLeadName,
              type: "teamLead",
            };
          }) || [];

        const allItemsTogether = [
          ...filteredData,
          ...newTeamLeads,
          creatorInfo,
        ];

        const hasLead = allItemsTogether.some((item) => item?.type === "lead");

        if (!hasLead) {
          const relatedLead = {
            id: selected?.relatedLead?.leadId,
            value: selected?.relatedLead?.lead?.contact_name?.name,
            type: "lead",
          };

          allItemsTogether.push(relatedLead);
        }

        const hasCreator = allItemsTogether.some(
          (item) => item?.type === "creator"
        );

        if (!hasCreator) {
          allItemsTogether.push(creatorInfo);
        }

        setSelected({
          id: "reminderForOptions",
          value: allItemsTogether,
        });

        if (!activeItem) {
          setState({ id: "activityReminders", value: [] });
        }

        if (!hasLead) {
          setLeadChangeHandler(!leadChangeHandler);
        }
      } else {
      }
    }
    setLoadings({ id: "teamLeads", value: false });
  };

  useEffect(() => {
    if (selected?.relatedLead && leadChangeHandler) {
      const newReminders = selected?.reminderForOptions?.map((reminder) =>
        reminder.type === "lead"
          ? {
              id: selected?.relatedLead?.leadId,
              value: selected?.relatedLead?.lead?.contact_name?.name,
              type: "lead",
            }
          : reminder
      );
      setLeadChangeHandler(!leadChangeHandler);

      setSelected({ id: "reminderForOptions", value: newReminders });
    }
  }, [leadChangeHandler]);

  useEffect(() => {
    setLeadChangeHandler(false);
  }, [close]);

  const getActivityTypes = async () => {
    setLoadings({ id: "gettingActivities", value: true });
    if (isGeneralDialog) {
      const res = await GetAllActivityTypes();
      setLoadings({ id: "gettingActivities", value: false });
      return res;
    }

    if (isContactCenterActivitiesView || isLeadOwnerActivitiesView) {
      const res = await GetAllActivityTypes();
      setLoadings({ id: "gettingActivities", value: false });
      return res;
    }

    const res = await GetActivityTypeBasedOnRelatedType(relatedTypePayload);
    setLoadings({ id: "gettingActivities", value: false });
    return res;
  };

  const qualifyRelatedLead = useCallback(async () => {
    const body = {
      ...(qualificationData || {}),
    };
    const leadId = state.relatedLeadNumberId;
    const res = await MyLeadUpdateLead({ id: leadId, body });

    if (!(res && res.status && res.status !== 200)) {
      const success = true;
      return success;
    } else showError(t(`${translationPath}lead-unqualified`));
  }, [qualificationData, state.relatedLeadNumberId]);

  const getRelatedLeadDetails = useCallback(
    async (leadId) => {
      setLoadings({ id: "relatedLead", value: true });
      const res = await leadDetailsGet({ id: leadId });

      if (!(res && res.status && res.status !== 200)) {
        setSelected({
          id: "relatedLead",
          value: res,
        });
        const reminderForOptions = [
          {
            id: selected.activityAssign?.id,
            value: selected.activityAssign?.fullName,
            type: "agent",
          },
        ];

        reminderForOptions.push({
          id: res.leadId,
          value: res?.lead?.contact_name?.name,
          type: "lead",
        });

        reminderForOptions.push(creatorInfo);

        setSelected({
          id: "reminderForOptions",
          value: reminderForOptions,
        });
      }
      setChangeRelatedLead(true);

      setLoadings({ id: "relatedLead", value: false });
    },
    [selected.activityAssign]
  );
  const getAllActivityTypes = useCallback(async () => {
    setLoadings({ id: "activityTypes", value: true });

    const res = await getActivityTypes();

    if (!(res && res.status && res.status !== 200)) {
      setData({
        id: "activityTypes",
        value: res || [],
      });
    } else {
      setData({
        id: "activityTypes",
        value: [],
      });
    }
    setLoadings({ id: "activityTypes", value: false });
  }, []);

  const getActivityFilesById = useCallback(
    async (activityId) => {
      const res = await GetActivityFilesById(activityId);
      if (!(res && res.status && res.status !== 200)) {
        setState({ id: "files", value: res });
        handleInitialFiles(res || []);
      } else {
        setState({ id: "files", value: [] });
      }
    },
    [activeItem]
  );

  const reminderTimeCalculateHandler = useCallback(
    (item, value, type, activityDate) => {
      const currentTimeAmountType = durationTypes.find(
        (items) => items.key === (type || item.reminderAmountOfTimeType || 1)
      );
      let newTimeValue = moment(activityDate || state.activityDate)
        .add(-value, currentTimeAmountType.momentKey)
        .format("YYYY-MM-DDTHH:mm:ss");

      if (
        moment(newTimeValue).isBefore(moment().format("YYYY-MM-DDTHH:mm:ss"))
      ) {
        newTimeValue = null;
      }
      setState({
        id: "activityReminders",
        value: state.activityReminders,
      });
      return newTimeValue;
    },
    [durationTypes, state.activityDate, state.activityReminders]
  );

  const activityTimeChangeHandler = (activityDate) => {
    if (state.activityReminders) {
      const mappedActiveItemReminders = state.activityReminders.map((item) => {
        return {
          ...item,
          reminderTime: reminderTimeCalculateHandler(
            item,
            getTimeValue(item) || 0,
            item.reminderAmountOfTimeType,
            activityDate
          ),
        };
      });
      setState({
        id: "activityReminders",
        value: mappedActiveItemReminders,
      });
    }
  };

  const handleInitialFiles = (activityFiles) => {
    const initialFiles = activityFiles.map((item) => ({
      uuid: item.fileId,
      fileName: item.fileName,
      isUploaded: false,
    }));
    setState({ id: "files", value: initialFiles });
  };

  const checkIsRelatedLeadClosed = () => {
    const relatedLeadStatus =
      selected?.relatedLead?.lead?.status?.lookupItemName;
    return activeItem?.relatedLeadNumberId && relatedLeadStatus === "Closed";
  };

  const handleReminderForAgentOptions = (newValue) => {
    let itemToPush = {};
    const localReminderForOptions = [...selected.reminderForOptions];
    const assignToReminderIndex = localReminderForOptions.findIndex(
      (item) => item.type === "agent"
    );
    if (newValue) {
      itemToPush = {
        id: newValue.id,
        value: newValue.fullName,
        type: "agent",
      };
    }

    if (!newValue && assignToReminderIndex !== -1) {
      localReminderForOptions.splice(assignToReminderIndex, 1);
    }

    if (newValue && assignToReminderIndex !== -1) {
      localReminderForOptions[assignToReminderIndex] = itemToPush;
    }

    if (newValue && assignToReminderIndex == -1) {
      localReminderForOptions.push(itemToPush);
    }

    setSelected({ id: "reminderForOptions", value: localReminderForOptions });
  };

  const resetRelatedTo = () => {
    setState({ id: "activityReminders", value: [] });
    setState({ id: "relatedUnitNumberId", value: null });
    setState({ id: "relatedLeadNumberId", value: null });
    setState({ id: "relatedContactNumberId", value: null });
    setState({ id: "relatedPortfolioId", value: null });
    setState({ id: "relatedWorkOrderId", value: null });
    setState({ id: "relatedMaintenanceContractId", value: null });
    setSelected({ id: "relatedUnit", value: null });
    setSelected({ id: "relatedLead", value: null });
    setSelected({ id: "relatedContact", value: null });
    setSelected({ id: "relatedPortfolio", value: null });
    setSelected({ id: "relatedWorkOrder", value: null });
    setSelected({ id: "relatedMaintenanceContract", value: null });
    setSelected({ id: "activityAssign", value: null });
  };

  useEffect(() => {
    calculateDurationInMinutes();
    calculateActivityEndDate();
  }, [
    selected.durationAmount,
    selected.durationType,
    state.activityDate,
    state.activityDuration,
  ]);

  useEffect(() => {
    if (leadId) {
      getActivityTypeById();
    }
  }, [leadId]);

  const calculateDurationAmount = (previousType, newType) => {
    const durationTypeKey = durationTypes[previousType - 1].momentKey;
    const momentFormatDuration = moment.duration(
      selected.durationAmount,
      durationTypeKey
    );

    let newDurationAmount = 0;
    if (newType == 1)
      newDurationAmount = Math.floor(momentFormatDuration.asMinutes());
    if (newType == 2)
      newDurationAmount = Math.floor(momentFormatDuration.asHours());
    if (newType == 3)
      newDurationAmount = Math.floor(momentFormatDuration.asDays());

    setSelected({
      id: "durationAmount",
      value: newDurationAmount,
    });
  };

  const calculateActivityEndDate = () => {
    if (state.activityDate && state.activityDuration) {
      const endDate = moment(state.activityDate)
        .add(state.activityDuration, "minutes")
        .format("YYYY-MM-DDTHH:mm:ss");

      setState({
        id: "activityEndDate",
        value: endDate,
      });
    } else {
      setState({
        id: "activityEndDate",
        value: null,
      });
    }
  };

  const calculateDurationInMinutes = () => {
    if (selected.durationAmount) {
      const durationTypeKey =
        durationTypes[selected.durationType - 1].momentKey;
      const momentFormatDuration = moment.duration(
        selected.durationAmount,
        durationTypeKey
      );

      const durationInMinutes = momentFormatDuration.asMinutes();

      setState({
        id: "activityDuration",
        value: durationInMinutes,
      });
    } else {
      setState({
        id: "activityDuration",
        value: null,
      });
    }
  };

  const handleQualifyLeadDialog = () => {
    const isQualifiedLeadRequired =
      selected?.activityType?.leadQualificationName === "Qualified lead";
    const leadClass = selected?.relatedLead?.lead?.leadClass;
    const isSeekerLead = leadClass === "Buyer" || leadClass === "Tenant";

    if (
      !activeItem &&
      isQualifiedLeadRequired &&
      isSeekerLead &&
      selected.relatedLead
    ) {
      setIsOpenQualifyLeadDialog(true);
    } else if (
      !activeItem &&
      isQualifiedLeadRequired &&
      !selected.relatedLead
    ) {
      setQualificationData(null);
    }
  };

  const schema = Joi.object({
    assignAgentId: Joi.string()
      .required()
      .messages({
        "string.base": t(`${translationPath}activity-assign-to-is-required`),
      }),
    activityTypeId: Joi.number()
      .required()
      .messages({
        "number.base": t(`${translationPath}activity-type-is-required`),
      }),
    relatedUnitNumberId: Joi.any()
      .custom((value, helpers) => {
        if (!value && isRelatedToNotSelected)
          return helpers.error("state.required");

        return value;
      })
      .messages({
        "state.required": t(
          `${translationPath}related-to-is-required-one-at-least`
        ),
      }),
    relatedLeadNumberId: Joi.any()
      .custom((value, helpers) => {
        if (!value && isRelatedToNotSelected)
          return helpers.error("state.required");

        return value;
      })
      .messages({
        "state.required": t(
          `${translationPath}related-to-is-required-one-at-least`
        ),
      }),
    relatedContactNumberId: Joi.any()
      .custom((value, helpers) => {
        if (!value && isRelatedToNotSelected)
          return helpers.error("state.required");
        return value;
      })
      .messages({
        "state.required": t(
          `${translationPath}related-to-is-required-one-at-least`
        ),
      }),
    relatedPortfolioId: Joi.any()
      .custom((value, helpers) => {
        if (!value && isRelatedToNotSelected)
          return helpers.error("state.required");

        return value;
      })
      .messages({
        "state.required": t(
          `${translationPath}related-to-is-required-one-at-least`
        ),
      }),
    relatedWorkOrderId: Joi.any()
      .custom((value, helpers) => {
        if (!value && isRelatedToNotSelected)
          return helpers.error("state.required");

        return value;
      })
      .messages({
        "state.required": t(
          `${translationPath}related-to-is-required-one-at-least`
        ),
      }),
    relatedMaintenanceContractId: Joi.any()
      .custom((value, helpers) => {
        if (!value && isRelatedToNotSelected)
          return helpers.error("state.required");

        return value;
      })
      .messages({
        "state.required": t(
          `${translationPath}related-to-is-required-one-at-least`
        ),
      }),
    activityDate:
      (!activeItem &&
        ((withDateTime &&
          Joi.date()
            .required()
            .greater(Date.now())
            .messages({
              "date.base": t(`${translationPath}activity-date-is-required`),
              "date.greater": t(`${translationPath}choose-time-after-now`),
            })) ||
          Joi.any())) ||
      Joi.any(),

    activityReminders:
      (isWithReminder &&
        remindersWithoutEmptyItems.length > 0 &&
        Joi.array().items(
          Joi.object({
            reminderType: Joi.any(),
            leadId: Joi.any(),
            usersId: Joi.any(),
            reminderTime: Joi.any(),
            templateId: Joi.any(),
          })
            .custom((value, helpers) => {
              if (!value.leadId && !value.usersId)
                return helpers.error("state.userNotSet");
              if (((value.reminderTime && getTimeValue(value)) || 0) === 0)
                return helpers.error("state.timeIsZero");
              if (!value.templateId)
                return helpers.error("state.emptyTemplateId");
              if (!value.reminderType)
                return helpers.error("state.reminderType");
              return value;
            })
            .messages({
              "state.userNotSet": t(
                `${translationPath}reminder-for-is-required`
              ),
              "state.timeIsZero": t(
                `${translationPath}time-must-be-greater-than-zero`
              ),
              "state.emptyTemplateId": t(
                `${translationPath}reminder-template-is-required`
              ),
              "state.reminderType": t(
                `${translationPath}reminder-type-is-required`
              ),
            })
        )) ||
      Joi.any(),
  })
    .options({
      abortEarly: false,
      allowUnknown: true,
    })
    .validate(state);

  const createOrUpdateActivity = async () => {
    setIsLoading(true);
    const saveState = { ...state };
    const filteredReminders = saveState?.activityReminders.filter(
      (item) =>
        (item.usersId || item.leadId) &&
        item.reminderType &&
        item.templateId &&
        item.reminderTime
    );

    const mappedReminders = filteredReminders?.map((item) => {
      const contactId = item.leadId
        ? selected.relatedLead?.lead?.contact_name?.id
        : null;
      return {
        activityReminderId: item.activityReminderId,
        reminderType: item.reminderType,
        reminderTime: item.reminderTime,
        templateId: item.templateId,
        usersId: item.usersId,
        contactId,
      };
    });

    saveState.activityReminders = mappedReminders;

    const res = activeItem?.activityId
      ? await EditActivity(activeItem.activityId, saveState)
      : await CreateActivity(saveState);
    if (!(res && res.status && res.status !== 200)) {
      if (activeItem && activeItem.activityId)
        showSuccess(t`${translationPath}activity-updated-successfully`);
      else showSuccess(t`${translationPath}activity-created-successfully`);
      if (onSave) onSave();
    } else if (activeItem && activeItem.activityId)
      showError(t(`${translationPath}activity-update-failed`));
    else showError(t`${translationPath}activity-create-failed`);
    setIsLoading(false);
  };

  const saveHandler = async (event) => {
    setIsSubmitted(true);
    event.preventDefault();

    if (withDateTime === true && !activeItem && !state.activityDate) {
      showError(t(`${translationPath}choose-time-after-now`));
      return;
    } else if (schema?.error?.message) {
      showError(schema.error.message);
      return;
    }

    const isLeadQualifyRequired =
      selected?.activityType?.leadQualificationName === "Qualified lead";
    if (isLeadQualifyRequired && qualificationData && !activeItem) {
      const leadQualifyRes = await qualifyRelatedLead();
      if (leadQualifyRes) {
        createOrUpdateActivity();
      } else return;
    } else createOrUpdateActivity();
  };

  useEffect(() => {
    if (
      activeItem &&
      !selected.relatedLead &&
      !changeRelatedLead &&
      activeItem.relatedLeadNumberId &&
      selected.activityAssign
    ) {
      getRelatedLeadDetails(activeItem.relatedLeadNumberId);
    }
  }, [
    activeItem,
    data.relatedLead,
    state.relatedLeadNumberId,
    selected.activityAssign,
  ]);

  useEffect(() => {
    if (activeItem && !selected.relatedContact) {
      const relatedContactData =
        data.relatedContact &&
        data.relatedContact.find(
          (item) => activeItem.relatedContactNumberId === item.contactsId
        );

      const relatedContact =
        relatedContactData && activeItem.relatedContactNumberId
          ? {
              contactsId: activeItem.relatedContactNumberId,
              contact: {
                company_name: relatedContactData?.contact?.company_name || "",
                first_name: relatedContactData?.contact?.first_name || "",
                last_name: relatedContactData?.contact?.last_name || "",
              },
            }
          : null;

      setSelected({
        id: "relatedContact",
        value: relatedContact,
      });
    }
  }, [activeItem, data.relatedContact, state.relatedContactNumberId]);

  useEffect(() => {
    if (activeItem) {
      setState({
        id: "edit",
        value: {
          assignAgentId: activeItem.agentUserId,
          activityTypeId: activeItem.activityTypeId,
          unitId: activeItem.unitId,
          relatedLeadNumberId: activeItem.relatedLeadNumberId,
          relatedUnitNumberId: activeItem.relatedUnitNumberId,
          unitReferenceNo:
            activeItem.unitReferenceNo || activeItem.relatedUnitNumber,
          relatedContactNumberId: activeItem.relatedContactNumberId,
          relatedPortfolioId: activeItem.relatedPortfolioId,
          relatedWorkOrderId: activeItem.relatedWorkOrderId,
          relatedMaintenanceContractId: activeItem.relatedMaintenanceContractId,
          activityDate: activeItem.activityDate,
          activityEndDate: activeItem.activityEndDate,
          activityDuration: activeItem.activityDuration,
          comments: activeItem.comments,
          isOpen: activeItem.isOpen,
          activityReminders: activeItem.activityReminders || [],
          subject: activeItem.subject,
          createdByName: activeItem.createdByName || activeItem.createdBy,
          files: [],
        },
      });

      if (isLeadActivitiesView) {
        getActivityFilesById(activeItem.activityId);
      } else handleInitialFiles(activeItem.activityFiles || []);

      setIsEditDialog(true);
    }
  }, [activeItem]);

  useEffect(() => {
    if (activeItem) {
      const activityAssign = {
        id: activeItem.agentUserId,
        userName: activeItem.agentUsername,
        fullName: activeItem.agentName,
      };

      const relatedUnit = activeItem.relatedUnitNumberId
        ? {
            id: activeItem.relatedUnitNumberId,
            propertyName: activeItem.relatedUnitPropertyName,
            unitRefNo:
              activeItem.unitReferenceNo || activeItem.relatedUnitNumber,
          }
        : null;

      const relatedMaintenanceContract = activeItem.relatedMaintenanceContractId
        ? {
            maintenanceContractId: activeItem.relatedMaintenanceContractId,
          }
        : null;

      const relatedPortfolio = activeItem.relatedPortfolioId
        ? {
            portfolioId: activeItem.relatedPortfolioId,
            portfolioName: activeItem.relatedPortfolioName,
          }
        : null;

      const reminderForOptions = [
        {
          id: activityAssign.id,
          value: activityAssign.fullName,
          type: "agent",
        },
      ];

      setSelected({
        id: "edit",
        value: {
          durationType: 1,
          durationAmount: activeItem.activityDuration,
          activityAssign,
          relatedUnit,
          relatedPortfolio,
          relatedMaintenanceContract,
          reminderForOptions,
        },
      });
    }
  }, [activeItem]);

  useEffect(() => {
    getAllActivityTypes();
    getAllSystemTemplateByCategoryId();
  }, []);

  useEffect(() => {
    if (roles) getAllActivityAssignments();
    if (roles && !activeItem) initialActivityAssigTo();
  }, [getAllActivityAssignments, activeItem]);

  useEffect(() => {
    getActivityTypeById();
  }, [state.activityTypeId]);

  useEffect(() => {
    handleQualifyLeadDialog();
  }, [activeItem, selected.relatedLead, selected.activityType]);

  useEffect(() => {
    if (selected?.activityAssign) {
      setIdForTeamLeads(selected?.activityAssign?.id);
    }
  }, [selected?.activityAssign]);

  useEffect(() => {
    if (idForTeamLeads) {
      const filteredData = selected?.reminderForOptions?.filter(
        (obj) => obj?.type !== "teamLead"
      );
      setSelected({
        id: "reminderForOptions",
        value: filteredData,
      });

      GetTeamLeadsByUserIdAPICall();
    }
  }, [idForTeamLeads]);

  return (
    <div>
      <Spinner
        isActive={
          loadings.activityTypes ||
          loadings.gettingActivities ||
          loadings.teamLeads
        }
        isAbsolute
      />
      <Dialog
        open={open}
        onClose={() => {
          close();
        }}
        className="activities-management-dialog-wrapper"
      >
        <Spinner isActive={isLoading} isAbsolute />
        <form noValidate onSubmit={saveHandler}>
          <DialogTitle id="alert-dialog-slide-title">
            {t(
              `${translationPath}${
                (activeItem && "edit-activity") || "add-new-activity"
              }`
            )}
          </DialogTitle>
          <DialogContent>
            <Tabs
              value={tabIndex}
              onChange={handleTabClick}
              aria-label="icon label tabs"
              textColor="primary"
              classes={{
                indicator: classes.indicator,
                scroller: classes.scroller,
                fixed: classes.fixed,
              }}
            >
              <Tab
                classes={{
                  root: classes.root,
                  wrapper: classes.wrapper,
                  labelIcon: classes.labelIcon,
                }}
                icon={<span className="mr-2 mdi mdi-format-list-checks " />}
                label={t(`${translationPath}Details`)}
              />
              <Tab
                classes={{
                  root: classes.root,
                  wrapper: classes.wrapper,
                  labelIcon: classes.labelIcon,
                }}
                icon={<span className="mr-2 mdi mdi-file-document" />}
                label={t(`${translationPath}Attachments`)}
              />
              <Tab
                classes={{
                  root: classes.root,
                  wrapper: classes.wrapper,
                  labelIcon: classes.labelIcon,
                }}
                icon={<span className="mr-2 mdi mdi-alarm-check" />}
                label={t(`${translationPath}Reminders`)}
              />
              {activeItem && (
                <Tab
                  classes={{
                    root: classes.root,
                    wrapper: classes.wrapper,
                    labelIcon: classes.labelIcon,
                  }}
                  icon={<span className="mr-2 mdi mdi-bell" />}
                  label={t(`${translationPath}Notifications`)}
                />
              )}
            </Tabs>
            {tabIndex === 0 && (
              <div className="dialog-content-wrapper">
                <div className="dialog-content-item">
                  <AutocompleteComponent
                    idRef="activityTypeIdRef"
                    labelValue="activity-type"
                    labelClasses="Requierd-Color"
                    selectedValues={selected.activityType || null}
                    multiple={false}
                    isDisabled={isEditDialog}
                    data={data.activityTypes}
                    displayLabel={(option) => option.activityTypeName || ""}
                    groupBy={(option) => option.categoryName || ""}
                    getOptionSelected={(option) =>
                      option.activityTypeId === state.activityTypeId
                    }
                    withoutSearchButton
                    helperText={
                      getErrorByName(schema, "activityTypeId").message
                    }
                    error={getErrorByName(schema, "activityTypeId").error}
                    isLoading={loadings.activityTypes}
                    isWithError
                    isSubmitted={isSubmitted}
                    parentTranslationPath={parentTranslationPath}
                    translationPath={translationPath}
                    onChange={(e, newValue) => {
                      setState({
                        id: "edit",
                        value: {
                          ...state,
                          activityTypeId: newValue?.activityTypeId || null,
                          subject: newValue?.activityTypeName || null,
                          assignAgentId: null,
                        },
                      });

                      setSelected({
                        id: "edit",
                        value: {
                          ...selected,
                          reminderForOptions: [],
                          activityAssign: null,
                        },
                      });

                      resetRelatedTo();

                      const assignedToActivityTypeRoles =
                        newValue?.assignedToActivityTypeRoles || [];

                      const rolesIds = assignedToActivityTypeRoles.map(
                        (item) => item.rolesId || []
                      );

                      setRoles(rolesIds);
                    }}
                  />
                </div>
                <div className="dialog-content-item">
                  <AutocompleteComponent
                    idRef="assignAgentIdRef"
                    labelValue="activity-assign-to"
                    labelClasses="Requierd-Color"
                    selectedValues={selected.activityAssign}
                    multiple={false}
                    isDisabled={state.activityTypeId == null}
                    data={data.activityAssignments}
                    displayLabel={(option) => option.fullName || ""}
                    renderOption={(option) =>
                      ((option.userName || option.fullName) &&
                        `${option.fullName} (${option.userName})`) ||
                      ""
                    }
                    getOptionSelected={(option) =>
                      option.id === state.assignAgentId
                    }
                    withoutSearchButton
                    helperText={getErrorByName(schema, "assignAgentId").message}
                    error={getErrorByName(schema, "assignAgentId").error}
                    isLoading={loadings.activityAssignments}
                    onKeyDown={() => {
                      setSelected({ id: "activityAssign", value: null });
                    }}
                    onInputKeyUp={(e) => {
                      const onSearch = e.target;
                      if (searchTimer.current)
                        clearTimeout(searchTimer.current);
                      searchTimer.current = setTimeout(() => {
                        getAllActivityAssignments(onSearch);
                      }, 700);
                    }}
                    isWithError
                    isSubmitted={isSubmitted}
                    parentTranslationPath={parentTranslationPath}
                    translationPath={translationPath}
                    onChange={(e, newValue) => {
                      setSelected({
                        id: "activityAssign",
                        value: newValue || null,
                      });
                      setIdForTeamLeads(newValue?.id || null);
                      setState({
                        id: "assignAgentId",
                        value: (newValue && newValue?.id) || null,
                      });

                      handleReminderForAgentOptions(newValue);
                    }}
                  />
                </div>

                {!loadings.activityTypes && (
                  <ActivityRelatedToInput
                    translationPath={translationPath}
                    parentTranslationPath={parentTranslationPath}
                    activityRelatedToList={activityRelatedToList}
                    setIsRelatedToNotSelected={setIsRelatedToNotSelected}
                    isSubmitted={isSubmitted}
                    isWithReminder={isWithReminder}
                    activeItem={activeItem}
                    schema={schema}
                    state={state}
                    setState={setState}
                    selected={selected}
                    setSelected={setSelected}
                    data={data}
                    actionItemId={actionItemId}
                    setData={setData}
                    isGeneralDialog={isGeneralDialog}
                    checkIsRelatedLeadClosed={checkIsRelatedLeadClosed}
                    classifications={classifications}
                    leadId={leadId}
                    setLeadId={setLeadId}
                  />
                )}
                {(withDateTime === true || isDateTimeCustom) &&
                  state.activityTypeId &&
                  ((
                    <div className="dialog-content-item">
                      <DatePickerComponent
                        idRef="activityDateRef"
                        labelClasses="Requierd-Color"
                        labelValue="activity-date"
                        placeholder="DD/MM/YYYY"
                        value={
                          state.activityDate
                            ? state.activityDate
                            : moment().format("YYYY-MM-DDTHH:mm:ss")
                        }
                        helperText={
                          getErrorByName(schema, "activityDate").message
                        }
                        error={getErrorByName(schema, "activityDate").error}
                        isSubmitted={isSubmitted}
                        minDate={
                          !activeItem
                            ? moment().format("YYYY-MM-DDTHH:mm:ss")
                            : (activeItem &&
                                activeItem.createdOn &&
                                moment(activeItem.createdOn).format(
                                  "YYYY-MM-DDTHH:mm:ss"
                                )) ||
                              ""
                        }
                        parentTranslationPath={parentTranslationPath}
                        translationPath={translationPath}
                        onDateChanged={(newValue) => {
                          const activityDateValue =
                            (newValue &&
                              moment(newValue).format("YYYY-MM-DDTHH:mm:ss")) ||
                            moment()
                              .add(2, "minutes")
                              .format("YYYY-MM-DDTHH:mm:ss");

                          setState({
                            id: "activityDate",
                            value: activityDateValue,
                          });

                          if (activeItem) {
                            activityTimeChangeHandler(activityDateValue);
                          } else if (!activeItem) {
                            setState({ id: "activityReminders", value: [] });
                          }
                        }}
                      />
                    </div>
                  ) ||
                    "")}
                {(withDateTime === true || isDateTimeCustom) &&
                  state.activityTypeId &&
                  ((
                    <div className="dialog-content-item">
                      <DatePickerComponent
                        idRef="activityTimeRef"
                        labelValue="activity-time"
                        labelClasses="Requierd-Color"
                        isTimePicker
                        value={
                          state.activityDate
                            ? state.activityDate
                            : moment()
                                .add(2, "minutes")
                                .format("YYYY-MM-DDTHH:mm:ss")
                        }
                        helperText={
                          getErrorByName(schema, "activityDate").message
                        }
                        error={getErrorByName(schema, "activityDate").error}
                        isSubmitted={isSubmitted}
                        minDate={moment().format("YYYY-MM-DDTHH:mm:ss")}
                        parentTranslationPath={parentTranslationPath}
                        translationPath={translationPath}
                        onDateChanged={(newValue) => {
                          setState({
                            id: "activityDate",
                            value:
                              (newValue &&
                                moment(newValue).format(
                                  "YYYY-MM-DDTHH:mm:ss"
                                )) ||
                              null,
                          });

                          if (activeItem)
                            activityTimeChangeHandler(
                              (newValue &&
                                moment(newValue).format(
                                  "YYYY-MM-DDTHH:mm:ss"
                                )) ||
                                null
                            );

                          if (!activeItem)
                            setState({ id: "activityReminders", value: [] });
                        }}
                      />
                    </div>
                  ) ||
                    "")}

                {withDateTime && withDuration && state.activityTypeId && (
                  <div className="dialog-content-item">
                    <Inputs
                      idRef="activityDurationIdRef"
                      labelValueWithoutTranslation={
                        state.activityEndDate && state.activityDuration
                          ? `Duration (ends by ${moment(
                              state.activityEndDate
                            ).format("L")} ${moment(
                              state.activityEndDate
                            ).format("LT")})`
                          : `Duration `
                      }
                      value={selected.durationAmount || 0}
                      isDisabled={!state.activityDate}
                      wrapperClasses="mb-0"
                      endAdornment={
                        <SelectComponet
                          idRef="durationTypeIdRef"
                          data={durationTypes}
                          value={selected.durationType}
                          valueInput="key"
                          textInput="value"
                          onSelectChanged={(newType) => {
                            const previousType = selected.durationType;
                            setSelected({
                              id: "durationType",
                              value: newType,
                            });
                            calculateDurationAmount(previousType, newType);
                          }}
                          wrapperClasses="over-input-select w-auto"
                          parentTranslationPath={parentTranslationPath}
                          translationPath={translationPath}
                          translationPathForData={translationPath}
                        />
                      }
                      type="number"
                      min={0}
                      isWithError
                      isSubmitted={isSubmitted}
                      parentTranslationPath={parentTranslationPath}
                      translationPath={translationPath}
                      onInputChanged={(event) => {
                        const { value } = event.target;
                        const roundedValue = Math.ceil(+value);

                        setSelected({
                          id: "durationAmount",
                          value: roundedValue,
                        });
                      }}
                    />
                  </div>
                )}

                {!!selected.activityType?.leadScore &&
                  selected.activityType?.leadScoreOptionId &&
                  selected.activityType?.relatedTo?.find(
                    (item) => item.relatedToId === 3
                  ) && (
                    <div className="dialog-content-item">
                      <Inputs
                        idRef="leadScoreRef"
                        labelValue="lead-score"
                        value={`${
                          selected.activityType.leadScoreOptionId === 1
                            ? "+"
                            : "-"
                        }${selected.activityType?.leadScore}`}
                        isDisabled
                        parentTranslationPath={parentTranslationPath}
                        translationPath={translationPath}
                      />
                    </div>
                  )}
                <div className="dialog-content-item">
                  <Inputs
                    idRef="subjectRef"
                    labelValue="subject"
                    value={state.subject || ""}
                    helperText={getErrorByName(schema, "subject").message}
                    error={getErrorByName(schema, "subject").error}
                    isWithError
                    isDisabled
                    isSubmitted={isSubmitted}
                    parentTranslationPath={parentTranslationPath}
                    translationPath={translationPath}
                    onInputChanged={(event) => {
                      setState({ id: "subject", value: event.target.value });
                    }}
                  />
                </div>
                {selected.activityType && (
                  <>
                    <div className="dialog-content-item">
                      <Inputs
                        idRef="leadRatingRef"
                        labelValue="lead-rating"
                        value={selected.activityType.activityRateName}
                        isDisabled
                        parentTranslationPath={parentTranslationPath}
                        translationPath={translationPath}
                      />
                    </div>
                    <div className="dialog-content-item">
                      <Inputs
                        idRef="leadQualificationRef"
                        labelValue="lead-qualification"
                        value={selected.activityType.leadQualificationName}
                        isDisabled
                        parentTranslationPath={parentTranslationPath}
                        translationPath={translationPath}
                      />
                    </div>
                  </>
                )}
                <div className="dialog-content-item">
                  <Inputs
                    idRef="created-byRef"
                    labelValue="created-by"
                    value={state.createdByName || ""}
                    isDisabled
                    parentTranslationPath={parentTranslationPath}
                    translationPath={translationPath}
                  />
                </div>
                <div className="dialog-content-item">
                  <SwitchComponent
                    idRef="isOpenStatusRef"
                    isChecked={state.isOpen}
                    themeClass="theme-line"
                    labelValue={(state.isOpen && "open") || "closed"}
                    parentTranslationPath={parentTranslationPath}
                    translationPath={translationPath}
                    onChangeHandler={(e, isChecked) =>
                      setState({ id: "isOpen", value: isChecked })
                    }
                  />
                </div>
                <div className="dialog-content-item w-100">
                  <Inputs
                    idRef="commentsRef"
                    labelValue="comments"
                    value={state.comments || ""}
                    helperText={getErrorByName(schema, "comments").message}
                    error={getErrorByName(schema, "comments").error}
                    isWithError
                    isSubmitted={isSubmitted}
                    multiline
                    rows={4}
                    parentTranslationPath={parentTranslationPath}
                    translationPath={translationPath}
                    onInputChanged={(event) => {
                      setState({ id: "comments", value: event.target.value });
                    }}
                  />
                </div>
                {isOpenQualifyLeadDialog && (
                  <QualifyLeadDialog
                    isOpen={isOpenQualifyLeadDialog}
                    activityLeadId={state.relatedLeadNumberId}
                    setActivityQualifyData={setQualificationData}
                    clearSelectedLead={() => {
                      setState({ id: "relatedLeadNumberId", value: null });
                      setSelected({ id: "relatedLead", value: null });
                    }}
                    onClose={() => {
                      setIsOpenQualifyLeadDialog(false);
                    }}
                  />
                )}
              </div>
            )}

            {tabIndex === 1 && (
              <div className="dialog-content-item w-100">
                <ActivityFilesUploader
                  state={state}
                  setState={setState}
                  activeItem={activeItem}
                  initFilesChangeAllowed={false}
                />
              </div>
            )}
            {tabIndex === 2 && (
              <div className="pl-4 pr-2 pt-3 w-100">
                <ActivtityRemindersTab
                  activeItem={activeItem}
                  setActiveItem={setActiveItem}
                  schema={schema}
                  state={state}
                  setState={setState}
                  selected={selected}
                  setSelected={setSelected}
                  data={data}
                  loadings={loadings}
                  defaultReminders={defaultReminders}
                  setRemindersWithoutEmptyItems={setRemindersWithoutEmptyItems}
                  isSubmitted={isSubmitted}
                  isWithReminder={isWithReminder}
                  translationPath={translationPath}
                  parentTranslationPath={parentTranslationPath}
                  isEdit={isEdit}
                  isDateTimeCustom={isDateTimeCustom}
                  creatorInfo={creatorInfo}
                  setLeadChangeHandler={setLeadChangeHandler}
                  activityAssignToOptions={activityAssignToOptions}
                  setActivityAssignToOptions={setActivityAssignToOptions}
                />
              </div>
            )}
            {tabIndex === 3 && activeItem && (
              <ActivityNotificationsTab
                filter={filter}
                translationPath={translationPath}
                parentTranslationPath={parentTranslationPath}
                activeItem={activeItem}
              />
            )}
          </DialogContent>
          <DialogActions>
            <ButtonBase
              onClick={() => close()}
              className="btns theme-solid bg-cancel"
            >
              {t(`${translationPath}cancel`)}
            </ButtonBase>
            {!activeItem && (
              <ButtonBase className="btns theme-solid" type="submit">
                {t(`${translationPath}add-activity`)}
              </ButtonBase>
            )}

            {activeItem && (
              <ButtonBase
                className="btns theme-solid"
                type="submit"
                disabled={checkIsRelatedLeadClosed()}
              >
                {t(`${translationPath}edit-activity`)}
              </ButtonBase>
            )}
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
};
