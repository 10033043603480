
export const AmlDashboardPermissions = {
  AmlDashboardPermissions: 
  {
    permissionsId:'385f8857-bae3-46f6-9389-08dc6114e9b2',
    permissionsName:'Aml Dashboard' , 
    componentsId:null,
    components:null
  }
  } ;
  