
import React , { useEffect , useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from "@material-ui/core/Button";
import { PageHeading } from "../../../SharedComponents";
import { TabsComponent } from '../../../Components' ; 
import { useTitle } from '../../../Hooks';
import { PropertyRatingTabsData } from './PropertyRatingTabsData' ; 


 const parentTranslationPath = 'PropertyRating';
 const translationPath = '';
  
  export const PropertyRatingView = () => { 

    const { t } = useTranslation(parentTranslationPath);
    useTitle(t(`${translationPath}property-rating`));

    const [activeTab, setActiveTab] = useState(0);
    const [activeTabObj ,setActiveTabObj] = useState(PropertyRatingTabsData && PropertyRatingTabsData[0]);
    const [ratingPercentage , setRatingPercentage] = useState([]) ;
    const onTabChanged = (event ,index,activeTabItem) => {
      setActiveTab(index);
      setActiveTabObj(PropertyRatingTabsData[index]) ; 
    };


    return (
      <div className="property-rating-view">
        <div className="d-flex-v-center-h-between">
          <PageHeading
            parentTranslationPath={parentTranslationPath}
            translationPath={translationPath}
            headerTitle={"property-rating"}
            subTitle={""}
            wrapperClasses={"px-4 pt-2 w-75"}
            headerTitleClasses={"fz-30"}
            subTitleClasses={"fz-12 m-0"}
          />
        </div>
        <div className="property-rating-container">
        <div className="d-flex">
          <TabsComponent
            data={PropertyRatingTabsData}
            labelInput='label'
            parentTranslationPath={parentTranslationPath}
            translationPath={translationPath}
            wrapperClasses=" w-25 px-4"
            orientation="vertical"
            themeClasses="theme-propx"
            currentTab={activeTab}
            onTabChanged={onTabChanged}
            dynamicComponentProps={{
              parentTranslationPath,
              translationPath,
              activeTab,
              setRatingPercentage , 
              ratingPercentage ,

            }}
          />
           </div>
        </div>
      </div>
    );


  } ; 
  