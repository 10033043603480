import React, { useEffect, useState } from 'react';
import GenricStpeper from '../../../../../Components/OLD/dfmAddEditAndDelete/typePicker/DfmAddEditAndDeleteStepper';
import {  LISTINGSHORTAGE   } from '../../../../../config/pagesName';
import { GetParams } from '../../../../../Helper' ; 

function ListingShortageViewManagementView() {
  const [activeFormType, setActiveFormType] = useState(3);
  const [id, setId] = useState(null);

  useEffect(() => {
    setId(GetParams('id'));
    return () => {};
  }, []);


   return (
    <div className='view-wrapper pt-3'>
      <div className='d-flex-column'>
        {activeFormType && (
          <GenricStpeper
            pageName={LISTINGSHORTAGE}
            type={activeFormType.toString()}
            id={(id && +id) || undefined}
            withTotal
            isDialog={false}
          />
        )}
      </div>
    </div>
  );
}
 
export { ListingShortageViewManagementView };
