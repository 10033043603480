import React, { useCallback, useEffect, useReducer, useState } from 'react';
import { PermissionsComponent, TabsComponent } from '../../../Components';
import { UnitDetailsApprovals } from './UnitDetailsApprovals/UnitDetailsApprovals';
import { PublishUnitApprovals } from './PublishUnitApprovals/PublishUnitApprovals';
import { useTranslation } from 'react-i18next';
import { GetAllBranches, GetAllRoles } from '../../../Services';
import { ApplicationUserSearch } from '../../../Services/userServices';
import { ApprovalsConfigurationPermissions } from '../../../Permissions';

const parentTranslationPath = 'ApprovalsConfiguration';
const translationPath = '';
export const ApprovalsConfigurationView = () => {
  const reducer = useCallback((state, action) => {
    if (action.id !== 'edit') return { ...state, [action.id]: action.value };
    return {
      ...action.value,
    };
  }, []);
  const [activeTab, setActiveTab] = useState(0);
  const [data, setData] = useReducer(reducer, {
    branches: [],
    roles: [],
    users: [],
  });

  const [isLoading, setIsLoading] = useReducer(reducer, {
    branches: false,
    roles: false,
    users: false,
    usersApproval: false,
  });

  const { t } = useTranslation(parentTranslationPath);

  const ApprovalDataList = [
    {
      label: 'publish-unit',
      component: PublishUnitApprovals,
      permissionsList: [...Object.values(ApprovalsConfigurationPermissions)],
      permissionsId:
        ApprovalsConfigurationPermissions.ViewPublishUnitConfiguration
          .permissionsId,
    },
    {
      label: 'unit-details',
      component: UnitDetailsApprovals,
      permissionsList: [...Object.values(ApprovalsConfigurationPermissions)],
      permissionsId:
        ApprovalsConfigurationPermissions.ViewUnitDetailsConfiguration
          .permissionsId,
    },
  ];

  const onTabChanged = (e, newTab) => {
    setActiveTab(newTab);
  };

  const getAllBranches = useCallback(async () => {
    setIsLoading({ id: 'branches', value: true });
    const res = await GetAllBranches({ pageSize: 1000, pageIndex: 0 });
    if (!(res && res.status && res.status !== 200 && res.result)) {
      setData({ id: 'branches', value: res.result });
    }
    setIsLoading({ id: 'branches', value: false });
  }, []);

  const getRoles = useCallback(async () => {
    setIsLoading({ id: 'roles', value: true });
    const pageIndex = 1;
    const pageSize = 1000;
    const res = await GetAllRoles(pageIndex, pageSize);
    if (!(res && res.status && res.status !== 200)) {
      setData({ id: 'roles', value: res.result });
    }
    setIsLoading({ id: 'roles', value: false });
  }, []);

  const getAllUsers = useCallback(async () => {
    setIsLoading({ id: 'users', value: true });

    const res = await ApplicationUserSearch({
      pageIndex: 0,
      pageSize: 1000,
      name: '',
    });
    if (!(res && res.status && res.status !== 200)) {
      setData({ id: 'users', value: res.result });
      setData({ id: 'usersApproval', value: res.result });
    }
    setIsLoading({ id: 'users', value: false });
  }, []);

  useEffect(() => {
    getAllBranches();
    getRoles();
    getAllUsers();
  }, []);

  return (
    <>
      <TabsComponent
        wrapperClasses='w-100'
        data={ApprovalDataList}
        labelInput='label'
        themeClasses='theme-curved'
        currentTab={activeTab}
        onTabChanged={onTabChanged}
        withDynamicComponents
        parentTranslationPath={parentTranslationPath}
        translationPath={translationPath}
        dynamicComponentProps={{
          translationPath,
          parentTranslationPath,
          data,
          setData,
          isLoading,
          reducer,
          setIsLoading,
        }}
      />
    </>
  );
};
