
import React, { useCallback, useState, useEffect , useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import {  SelectComponet  } from '../../../../../Components'; 
import { TableFilterOperatorsEnum  } from  '../../../../../Enums' ; 
import {  getErrorByName  } from '../../../../../Helper' ; 

export const FilterTypes = ({
   parentTranslationPath ,
   translationPath ,
   translationPathForData , 
   state , 
   setState ,
   item ,
   index , 
   schema , 
 }) => {

  const { t } = useTranslation([parentTranslationPath, 'Shared']);
  const [allFilterTypes, setAllFilterTypes] = useState([]);


  useEffect(() => {
    const myData =  Object.values(TableFilterOperatorsEnum);
    let list = [] ;
    if( item.fieldName.field.id ===  'bathrooms' || item.fieldName.field.id === 'bedrooms'  || item.fieldName.field.id === 'budgetFrom' || item.fieldName.field.id === 'budgetTo' || item.fieldName.field.id === 'sizeAreaFrom' || item.fieldName.field.id === 'sizeAreaTo' || item.fieldName.field.id === 'sizeAreaSqmFrom' || item.fieldName.field.id === 'sizeAreaSqmTo' )
    {
      list = myData.filter(x=> (x.key === 1 || x.key === 3  || x.key === 4 ));
    }

  else if(item &&  item.fieldName &&  item.fieldName.field &&
      (item.fieldName.field.FieldType === 'textField'  || item.fieldName.field.FieldType === 'communication' || item.fieldName.field.FieldType === 'textarea' ||  (item.fieldName.field.FieldType === 'address' &&  item.fieldName.data && item.fieldName.data.uiType === 'text' )))
    {
      list = myData.filter(x=> x.key !== 5 && x.key !== 6 && x.key !== 10 && x.key !== 15  )
    }
    else if(item &&  item.fieldName &&  item.fieldName.field &&    (item.fieldName.field.FieldType === 'select' ||  item.fieldName.field.FieldType === 'searchField' || (item.fieldName.data.uiType ==='select')) )
    {
      list = myData.filter(x=> x.key === 1)
    }
     else if(item &&  item.fieldName &&  item.fieldName.field &&    (item.fieldName.field.FieldType === 'alt-date') )
      {
       list = myData.filter(x=> x.key === 5 || x.key === 6 || x.key === 10 || x.key === 15  )
      }
     else {
     list = [...myData]
      }
    setAllFilterTypes(list);
  }, [item]);

  return (
    <div>  
       <div>
     {item && item.fieldName && (
      <SelectComponet
      labelValue={ !index ? t(`${translationPath}filter_types`) : ''}
      idRef={`FilterByRef${index + 1}`}
      data={allFilterTypes || []}
      emptyItem={{ value: null, text:  t(`${translationPath}select_filter`), isHiddenOnOpen: true }}
      value={item.operator}
      valueInput='key'
      textInput='name'
      onSelectChanged={(value ) => {
          let  updatelist =  state.conditions  ;
          if( value === 13 || value === 14 )
          updatelist[index] = { ...updatelist[index] ,
            operator:value , 
            value:''

          }
         else if( value === 1 )
          updatelist[index] = { ...updatelist[index] ,
            operator:value , 
            value:null

          }
          else 
           updatelist[index] = { ...updatelist[index] ,
            operator:value   , 
            value:updatelist[index].value , 
          }   
           setState({ id: 'conditions', value: updatelist });
          }}
      translationPathForData={translationPath}
      parentTranslationPath={parentTranslationPath}
      translationPath={translationPath}
      error={getErrorByName(schema, 'operator').error}
    />
    
  )}       
   </div> 
      
          
   </div>
  );

}


FilterTypes.propTypes = {
  parentTranslationPath: PropTypes.string.isRequired,
  translationPath: PropTypes.string.isRequired,
  translationPathForData :  PropTypes.string.isRequired , 
  item :  PropTypes.instanceOf(Object).isRequired,
  index : PropTypes.number.isRequired ,
  schema : PropTypes.instanceOf(Object).isRequired ,  
  setState :PropTypes.func.isRequired , 
  state : PropTypes.instanceOf(Object).isRequired,
};
