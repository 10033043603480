import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  UploaderComponent,
  Inputs,
  SelectComponet,
} from "../../../../../Components";
import "react-quill/dist/quill.snow.css";
import { showError, showWarn } from "../../../../../Helper";
import {
  getIsValidURL,
  isURL,
  matchYoutubeUrl,
  matchFacebookUrl,
  matchInstagramUrl,
} from "../../../../../Helper/Link.helper";
import { UploaderThemesEnum } from "../../../../../Enums";
import { ImagesGalleryPhotosCardComponent } from "../../LocationSharingUtilities/ImagesGalleryPhotosCardComponent/ImagesGalleryPhotosCardComponent";
import { ButtonBase } from "@material-ui/core";
import { Button } from "react-bootstrap";

export const LandmarkAttachments = ({
  parentTranslationPath,
  translationPath,
  selected,
  setSelected,
}) => {
  const { t } = useTranslation(parentTranslationPath);

  const removeImageHandler = (removedImage, key) => {
    if (key === "files") {
      const removedImageIndex =
        selected.files &&
        selected.files.findIndex((item) => removedImage.uuid == item.uuid);

      if (removedImageIndex !== -1) {
        const localFiles = [...(selected.files || [])];
        localFiles.splice(removedImageIndex, 1);
        setSelected({ id: "files", value: localFiles });
      }
    } else if (key === "logImage") {
      const removedImageIndex =
        selected.logImage &&
        selected.logImage.findIndex((item) => removedImage.uuid == item.uuid);

      if (removedImageIndex !== -1) {
        const localFiles = [...(selected.logImage || [])];
        localFiles.splice(removedImageIndex, 1);
        setSelected({ id: "logImage", value: localFiles });
      }
    }
  };

  return (
    <div className="lamdmarks-form1 lamdmarks-form2">
      <div className="d-flex-column">
        <div className="UploaderComponent-wrapered pt-4">
          <div className="mt-2 mt-2">
          <span className="fw-bold">
            {" "}
            {`${t(`${translationPath}landmark-logo`)}`}{" "}
          </span>
          </div>

          <UploaderComponent
            idRef="profileLogoRef"
            uploaderTheme={UploaderThemesEnum.box.key}
            defaultImage="Uploadmark"
            WithoutDefaultImg
            multiple={false}
            dropHereText="Drag-and-drop-here-or-browse-File"
            uploadedChanged={(newFiles) => {
              if (
                selected?.logImage !== "" &&
                selected.logImage &&
                Array.isArray(selected.logImage) &&
                selected.logImage.length > 0
              ) {
                showWarn(
                  t(
                    `${translationPath}please-remove-logo-image-before-add-new-logo-image`
                  )
                );
                return;
              }
              setSelected({
                id: "logImage",
                value: [...(selected.logImage || []), ...(newFiles || [])],
              });
            }}
          />
          <ImagesGalleryPhotosCardComponent
            data={selected.logImage || []}
            parentTranslationPath={parentTranslationPath}
            translationPath={translationPath}
            WithCheckbox
            Selectedvalue={(items) => console.log(items)}
            deletevalue={(removedImage) =>
              removeImageHandler(removedImage, "logImage")
            }
          />
        </div>
        <div className="UploaderComponent-wrapered pt-3">
        <div className="mt-2 mt-2">
          <span className="fw-bold">
            {" "}
            {`${t(`${translationPath}landmark-images`)}`}{" "}
          </span>
          </div>

          <UploaderComponent
            idRef="profileImgRef"
            uploaderTheme={UploaderThemesEnum.box.key}
            defaultImage="Uploadmark"
            WithoutDefaultImg
            accept="image/*"
            dropHereText="Drag-and-drop-here-or-browse-File"
            multiple={false}
            initUploadedFiles={selected.files || []}
            uploadedChanged={(newFiles) => {
              setSelected({
                id: "files",
                value: [...(selected.files || []), ...(newFiles || [])],
              });
            }}
          />
        </div>
        <div>
          <div className="pt-4">
            <ImagesGalleryPhotosCardComponent
              data={selected.files || []}
              parentTranslationPath={parentTranslationPath}
              translationPath={translationPath}
              imageInput={"uuid"}
              altInput={"fileName"}
              deletevalue={(removedImage) =>
                removeImageHandler(removedImage, "files")
              }
            />
          </div>
          <Inputs
            idRef="VirtualtoursRef"
            labelValue="Virtual-tours"
            translationPath={translationPath}
            parentTranslationPath={parentTranslationPath}
            value={selected.virtualTourLink || ""}
            onInputChanged={(event) =>
              setSelected({ id: "virtualTourLink", value: event.target.value })
            }
          />
          <Inputs
            idRef="usefulLinkRef"
            labelValue="usefulLink"
            translationPath={translationPath}
            parentTranslationPath={parentTranslationPath}
            value={selected.usefulLink || ""}
            onInputChanged={(event) =>
              setSelected({ id: "usefulLink", value: event.target.value })
            }
          />
          <Inputs
            idRef="website-linksRef"
            labelValue="website"
            translationPath={translationPath}
            parentTranslationPath={parentTranslationPath}
            value={selected.website || ""}
            onInputChanged={(event) =>
              setSelected({ id: "website", value: event.target.value })
            }
          />
          <Inputs
            idRef="youtubeLinksRef"
            labelValue="youtubeLink"
            translationPath={translationPath}
            parentTranslationPath={parentTranslationPath}
            value={selected.youtubeLink || ""}
            onInputChanged={(event) =>
              setSelected({ id: "youtubeLink", value: event.target.value })
            }
          />
          <div className="pt-3 w-100">
            <SelectComponet
              labelValue={"isFeatured"}
              data={[
                { key: true, value: `${translationPath}Yes` },
                {
                  key: false,
                  value: `${translationPath}No`,
                },
              ]}
              emptyItem={{
                value: -1,
                text: "",
                isDisabled: false,
              }}
              value={selected.isFeatured || false}
              valueInput="key"
              textInput="value"
              onSelectChanged={(value) => {
                setSelected({ id: "isFeatured", value: value });
              }}
              idRef="isFeatured"
              parentTranslationPath={parentTranslationPath}
              translationPath={translationPath}
              translationPathForData={translationPath}
            />
          </div>
          <div className='pt-3'>
            <Inputs
              idRef='facebook2Ref'
              labelValue='facebook'
              translationPath={translationPath}
              parentTranslationPath={parentTranslationPath}
              value={selected.facebookLink}
              onInputChanged={(event) =>
                setSelected({ id: "facebookLink", value: (event.target.value) })

              }
              endAdornment={(
                <>
                  <Button
                    disabled={!(matchFacebookUrl((selected && selected.facebookLink)|| ''))}
                    onClick={() => {
                      try {
                        window.open(selected.facebookLink || '');
                      } catch (error) { showError(t(`${translationPath}this-link-is-not-valid`)); }
                    }}
                    className='googlemaps-bbt '
                  >
                    <span className='p-1 youtube-wraper'><span className='mdi mdi-web' /></span>
                  </Button>
                </>
              )}
            />
          </div>
          <div className="pt-3">
            <Inputs
              idRef="instagramRef"
              labelValue="instagram"
              translationPath={translationPath}
              parentTranslationPath={parentTranslationPath}
              value={selected.instagramLink}
              onInputChanged={(event) =>
                setSelected({ id: "instagramLink", value: event.target.value })
              }
              endAdornment={
                <>
                  <Button
                    disabled={!matchInstagramUrl(selected.instagramLink || "")}
                    onClick={() => {
                      try {
                        window.open(selected.instagramLink || "");
                      } catch (error) {
                        showError(
                          t(`${translationPath}this-link-is-not-valid`)
                        );
                      }
                    }}
                    className="googlemaps-bbt "
                  >
                    <span className="p-1 youtube-wraper">
                      <span className="mdi mdi-web" />
                    </span>
                  </Button>
                </>
              }
            />
          </div>
          <div className="Active-btt-menu mt-2">
            <ButtonBase
              className={`menu-button ${
                selected.isActive === true ? "is-active" : ""
              }`}
              onClick={() => {
                setSelected({ id: "isActive", value: true });
              }}
            >
              <span className="mdi mdi-check-circle-outline" />
              {t(`${translationPath}Active`)}
            </ButtonBase>
            <ButtonBase
              className={`menu-button ${
                selected.isActive === false ? "is-active" : ""
              }`}
              onClick={() => {
                setSelected({ id: "isActive", value: false });
              }}
            >
              <span className="mdi mdi-close-circle-outline" />
              {t(`${translationPath}inActive`)}
            </ButtonBase>
          </div>
          <div className="Active-btt-menu mt-2" />
        </div>
      </div>
    </div>
  );
};
