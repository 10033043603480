import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => {
  return {
    success: {
      border: `1px solid ${theme.palette.border.success}`,
      backgroundColor: theme.palette.success.light,
      color: theme.palette.text.success_secondary,
    },
    "success-icon": {
      color: theme.palette.success.main,
    },
    warning: {
      border: `1px solid ${theme.palette.border.warning}`,
      backgroundColor: theme.palette.utility.warning_50,
      color: theme.palette.text.warning_secondary,
    },
    "warning-icon": {
      color: theme.palette.warning.main,
    },
    selected: {
      border: "1px solid #7F6944",
      backgroundColor: "transparent",
      color: "#7F6944",
    },
    disabled: {
      border: `1px solid ${theme.palette.border.disabled}`,
      backgroundColor: "#F9FAFB",
      color: "#344054",
    },
    warm: {
      border: "1px solid #F9DBAF",
      backgroundColor: "#FEF6EE",
      color: "#B93815",
    },
    cold: {
      border: "1px solid #B2DDFF",
      backgroundColor: "#EFF8FF",
      color: "#175CD3",
    },
    qualification: {
      border: "1px solid #D5D9EB",
      backgroundColor: "#F8F9FC",
      color: "#363F72",
    },
    lightPrimaryfilled: {
      border: "1px solid #CABFAC",
      backgroundColor: "#F4F1ED",
      color: "#635235",
    },
    lightBluefilled: {
      border: "1px solid #EAECF0",
      backgroundColor: "#F9FAFB",
      color: "#344054",
    },
    default: {
      backgroundColor: "#ffffff",
      color: theme.palette.text.primary,
      border: `1px solid ${theme.palette.divider}`,
    },
  }
});
