import React, {
  useState, useRef, useEffect, useCallback, useReducer
} from 'react';
import { ButtonBase } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import {
  Inputs, SelectComponet, Spinner, AutocompleteComponent, DataFileAutocompleteComponent, ViewTypesActivities
} from '../../../Components';
import { DateRangePickerComponent } from '../../../Components/Controls/DateRangePickerComponent/DateRangePickerComponent';

import { useTitle } from '../../../Hooks';
// import { GetForms } from '../../../Services/formbuilder/getForms';
import { ViewTypes2Enum, ActivitiesRelationTypesEnum } from '../../../Enums';
import { ActivitiesManagementDialog } from '../../../SharedComponents/ActivitiesManagementDialog/ActivitiesManagementDialog';
import {
  GetAllActivities,
  OrganizationUserSearch,
  GetLeads,
  GetAllActivityTypesAPI,

} from '../../../Services';
import { PermissionsComponent } from '../../../Components/PermissionsComponent/PermissionsComponent';
import { GlobalOrderFilterActions } from '../../../store/GlobalOrderFilter/GlobalOrderFilterActions';
import { ActivitiesTableView } from './ActivitiesViewUtilities/ActivitiesTableView';
import { ActivitiesMortgagesPermissions } from '../../../Permissions';

const parentTranslationPath = 'ActivitiesView';
const translationPath = '';

export const MortgageActivitiesView = () => {
  // eslint-disable-next-line prefer-const
  const dateRangeDefault = {
    startDate: null,
    endDate: null,
    key: 'selection',
  };
  const [agents, setAllAgent] = useState({
    result: [],
    totalCount: 0,
  });

  const [sortBy, setSortBy] = useState(null);
  const [activeActionType, setActiveActionType] = useState(ViewTypes2Enum.tableRelatedView.key);
  const [dateFilter, setDateFilter] = useState(dateRangeDefault);

  const { t } = useTranslation(parentTranslationPath);
  const [isLoadingActivities, setIsLoadingActivities] = useState(false);
  const [isLoading, setIsLoading] = useState({
    agents: false,
    units: false,
    leads: false,
    activityTypes: false
  });
  
  const advanceSearch = useSelector((state) => state.GlobalAdvanceSearchReducer);
  const [activitiesTableFilter, setActivitiesTableFilter] = useState(advanceSearch && advanceSearch['mortgageActivities'] || null);
  const [isFirst , setIsFirst] = useState(false); 
  const [searchedItem, setSearchedItem] = useState('');
  const [activities, setActivities] = useState({
    result: [],
    totalCount: 0,
  });
  const [activityTypes, setActivityTypes] = useState({
    result: [],
    totalCount: 0
  });
  const pathName = window.location.pathname.split('/home/')[1].split('/view')[0];
  const [openDialog, setOpenDialog] = useState(false);
  const searchTimer = useRef(null);

  const [editpermissionsList, setEditpermissionsList] = useState([]);

  const reducer = useCallback((state, action) => {
    if (action.id !== 'edit') return { ...state, [action.id]: action.value };
    return {
      ...action.value,
    };
  }, []);
  const [selected, setSelected] = useReducer(reducer, {
    activityAssign: null,
    activityType: null,
    unit: null,
    activeFormType: 2,
    relationType: null,
    relatedUnit: null,
    relatedLead: null,
    relatedWorkOrder: null,
    relatedPortfolio: null,
    relatedMaintenanceContract: null,
    reminderPersons: [],
  });
  const dispatch = useDispatch();
  const orderFilter = useSelector((state) => state.GlobalOrderFilterReducer);
  const [selectedOrderBy, setSelectedOrderBy] = useState(
    {
      filterBy: (orderFilter.activitesMortgageFilter && orderFilter.activitesMortgageFilter.filterBy) || null,
      orderBy: (orderFilter.activitesMortgageFilter && orderFilter.activitesMortgageFilter.orderBy) || null,
    }
);
  const [selectSortBy, setSelectSortBy] = useState(selectedOrderBy.filterBy ? selectedOrderBy : { filterBy: null, orderBy: null });
  const [filter, setFilter] = useState({
    pageSize: parseInt(localStorage.getItem('Pagination')) || 25,
    pageIndex: 0,
    search: '',
    fromDate: null,
    toDate: null,
    filterBy: selectSortBy.filterBy || null,
    orderBy: selectSortBy.orderBy || null,
    guidAgentId: null,
    activityTypeId: null,
    relatedUnitId: null,
    relatedLeadId: null,
    isMortgage: true,

  });
  useTitle(t(`${translationPath}activities`));

  const getActivityType = useCallback(async (activityTypeSearch) => {
    setIsLoading((loading) => ({ ...loading, activityTypes: true }));
    const res = await GetAllActivityTypesAPI({ pageSize: 25, pageIndex: 0, search: activityTypeSearch });
    if (!(res && res.status && res.status !== 200)) {
      setActivityTypes({
        result: res.result || [],
        totalCount: res.totalCount || 0
      });
    } else {
      setActivityTypes({
        result: [],
        totalCount: 0
      });
    }
    setIsLoading((loading) => ({ ...loading, activityTypes: false }));
  });

  const getAllActivities = useCallback(async () => {

    if(!isFirst)
     return ; 

    setIsLoadingActivities(true);

    let activitiesDate = { fromDate: null, toDate: null };
    if (dateFilter && dateFilter.startDate && dateFilter.endDate) {
      activitiesDate = {
        fromDate: moment(dateFilter.startDate).startOf('day').format('YYYY-MM-DDTHH:mm:ss'),
        toDate: moment(dateFilter.endDate).format('YYYY-MM-DDTHH:mm:ss')
      };
    }
    let res = {
      result: [],
      totalCount: 0
    };
    res = activeActionType === ViewTypes2Enum.tableRelatedView.key ? await GetAllActivities({
      ...filter,
     isWithoutRelatedActivities: false,
     pageIndex: filter.pageIndex + 1,
     fromDate: activitiesDate.fromDate,
     toDate: activitiesDate.toDate,
      relationType: selected.relationType,
    }) : await GetAllActivities({
      ...filter,
      isWithoutRelatedActivities: true,
      pageIndex: filter.pageIndex + 1,
      fromDate: activitiesDate.fromDate,
      toDate: activitiesDate.toDate,
      relationType: selected.relationType,
    });

    if (!(res && res.status && res.status !== 200)) {
      setActivities({
        result: (res && res.result) || [],
        totalCount: (res && res.totalCount) || 0,
      });
    } else {
      setActivities({
        result: [],
        totalCount: 0,
      });
    }
    setIsLoadingActivities(false);
  }, [filter, pathName, activeActionType, dateFilter, selected.relationType]);


  useEffect(() => {
    getActivityType();
  }, []);

  const searchHandler = (event) => {
    const { value } = event.target;
    if (searchTimer.current) clearTimeout(searchTimer.current);
    searchTimer.current = setTimeout(() => {
      setFilter((item) => ({ ...item, search: value, pageIndex: 0, }));
    }, 700);
  };

  const onPageIndexChanged = (pageIndex) => {
    setFilter((item) => ({ ...item, pageIndex }));
  };
  const onPageSizeChanged = (pageSize) => {
    setFilter((item) => ({ ...item, pageIndex: 0, pageSize }));
  };

  const addNewHandler = () => {
    setOpenDialog(true);
  };
  const filterByChanged = (value) => {
    setSelectSortBy((item) => ({ ...item, filterBy: value }));
  };
  const orderByChanged = (value) => {
    setSelectSortBy((item) => ({ ...item, orderBy: value }));
  };

  const GetOrdered = () => {
    dispatch(
      GlobalOrderFilterActions.globalOrderFilterRequest({
        ...orderFilter,
        activitesMortgageFilter: {
          filterBy: selectSortBy.filterBy,
          orderBy: selectSortBy.orderBy,
        },
      })
    );
    setSelectedOrderBy({
      filterBy: selectSortBy.filterBy || null,
      orderBy: selectSortBy.orderBy || null,
    });

    setFilter((item) => ({ ...item, orderBy: selectSortBy.orderBy, filterBy: selectSortBy.filterBy }));
  };

  const [data, setData] = useReducer(reducer, {
    activityAssignments: [],
    activityTypes: [],
    units: [],
    relatedLeads: [],
    relatedUnits: [],
    relatedPortfolio: [],
    relatedWorkOrder: [],
    maintenanceContract: [],
    relatedMaintenanceContractId: [],
  });
  const changeActiveFormType = (newValue) => {
    setSelected({
      id: 'edit',
      value: {
        ...selected,
        activeFormType: +newValue,
        relatedUnit: null,
        relatedLead: null,
        relatedPortfolio: null,
        relatedWorkOrder: null,
        relatedMaintenanceContract: null
      },
    });
    if (filter.relatedLeadId)
      setFilter((item) => ({ ...item, relatedLeadId: null }));
  };

  const getAllAgents = useCallback(async () => {
    setIsLoading((loading) => ({ ...loading, agents: true }));

    const res = await OrganizationUserSearch({});
    if (!(res && res.status && res.status !== 200)) {
      setAllAgent({
        result: (res && res.result) || [],
        totalCount: (res && res.totalCount) || 0,
      });
    } else {
      setAllAgent({
        result: [],
        totalCount: 0,
      });
    }

    setIsLoading((loading) => ({ ...loading, agents: false }));
  }, []);

  useEffect(() => {
    getAllAgents();
  }, []);

  const onTypeChanged = useCallback(
    (activeType) => {
      setActiveActionType(activeType);
    },
    [setActiveActionType]
  );

  const getAllRelatedLeads = useCallback(
    async (value) => {
      setIsLoading((loading) => ({ ...loading, leads: true }));

      const response = await GetLeads({ search: value });
      if (!(response && response.status && response.status !== 200))
        setData({ id: 'relatedLeads', value: (response && response.result) || [] });
      else setData({ id: 'relatedLeads', value: [] });

      setIsLoading((loading) => ({ ...loading, leads: false }));
    }, []
  );

  useEffect(() => {
    if (selected.activeFormType === 0) {
      setSelected({
        id: 'relationType',
        value: null
      });
    } else if (selected.activeFormType === 2) {
      getAllRelatedLeads();
      setSelected({
        id: 'relationType',
        value: ActivitiesRelationTypesEnum.lead.key,
      });
    }
  }, [selected.activeFormType]);

  useEffect(() => {
    if (sortBy) {
      setFilter((item) => ({
        ...item, filterBy: sortBy.filterBy, orderBy: sortBy.orderBy, search: '', pageIndex: 0, relatedLeadId: null, relatedUnitId: null, guidAgentId: null
      }));

      dispatch(
        GlobalOrderFilterActions.globalOrderFilterRequest({
          ...orderFilter,
          activitesMortgageFilter: {
            filterBy: sortBy.filterBy,
            orderBy: sortBy.orderBy,
          },
        })
      );
      setSelectSortBy((item) => ({ ...item, filterBy: sortBy.filterBy, orderBy: sortBy.orderBy }));
      setSelectedOrderBy((item) => ({ ...item, filterBy: sortBy.filterBy, orderBy: sortBy.orderBy }));
    }
  }, [sortBy]);

  const onFilterValuesChanged = (newValue) => {

    setIsFirst(true); 
    const localFilterDto = filter || {};
    if(isFirst){
    if (newValue) {
      Object.values(newValue)
        .filter((item) => item.searchableKey || item.displayPath)
        .map((item) => {
          
          if (localFilterDto[item.displayPath])
            item.value ? localFilterDto[item.displayPath] = item.value : localFilterDto[item.displayPath] = null;
           else if (item.value)
            localFilterDto[item.displayPath] = item.value;

          return undefined;
        });
      }
     setFilter(() => ({ ...localFilterDto    }));
    }
    else {
      if (activitiesTableFilter) {
       Object.values(activitiesTableFilter)
        .filter((item) => item.searchableKey || item.displayPath)
        .map((item) => {
          
          if (localFilterDto[item.displayPath])
            item.value ? localFilterDto[item.displayPath] = item.value : localFilterDto[item.displayPath] = null;
           else if (item.value)
            localFilterDto[item.displayPath] = item.value;

          return undefined;
        });
    }
    setFilter(() => ({ ...localFilterDto }));
   }
  };

  useEffect(() => {
    getAllActivities();
  }, [filter]); 

  return (
    <div className='view-wrapper'>
      <Spinner isActive={isLoadingActivities} />
      <div className='d-flex-column'>
        <div className='header-section'>
          <div className='filter-section'>
            <div className='section px-3'>
              <ButtonBase className='btns theme-solid px-3' onClick={addNewHandler}>
                <span className='mdi mdi-plus' />
                {t(`${translationPath}add-new`)}
              </ButtonBase>
            </div>
            <PermissionsComponent
              permissionsList={Object.values(ActivitiesMortgagesPermissions)}
              permissionsId={ActivitiesMortgagesPermissions.ViewMortgageActivities.permissionsId}
            >
              <div className='section autocomplete-section'>
                <div className='d-flex-column px-2 w-100 p-relative'>
                  <div className='d-flex-column w-100'>
                    <Inputs
                      value={searchedItem}
                      onKeyUp={searchHandler}
                      idRef='activitiesSearchRef'
                      label={t(`${translationPath}search-activity`)}
                      onInputChanged={(e) => setSearchedItem(e.target.value)}
                      inputPlaceholder={t(`${translationPath}search-activity`)}
                      beforeIconClasses='mdi mdi-magnify mdi-24px c-gray-primary'
                    />
                  </div>
                  <div className='d-inline-flex pl-4-reversed'>
                    <div className='w-100 mr-1-reversed'>
                      <AutocompleteComponent
                        idRef='referredToRef'
                        isLoading={isLoading.activityTypes}
                        multiple={false}
                        data={activityTypes.result || []}
                        value={filter.activityTypeId}
                        chipsLabel={(option) => option.activityTypeName || ''}
                        displayLabel={(option) => option.activityTypeName || ''}
                        withoutSearchButton
                        inputPlaceholder={t(`${translationPath}activity-type`)}
                        parentTranslationPath={parentTranslationPath}
                        translationPath={translationPath}
                        onInputKeyUp={(event) => {
                          const { value } = event.target;
                          if (searchTimer.current) clearTimeout(searchTimer.current);
                          searchTimer.current = setTimeout(() => {
                            getActivityType(value);
                          }, 700);
                        }}
                        onChange={(event, newValue) => {
                          if (!newValue) getActivityType();
                          setFilter((item) => ({ ...item, activityTypeId: newValue && newValue.activityTypeId || null, pageIndex: 0 }));
                        }}
                      />
                    </div>
                    <div className='w-100'>
                      <AutocompleteComponent
                        idRef='referredToRef'
                        isLoading={isLoading.agents}
                        multiple={false}
                        data={agents.result || []}
                        value={filter.guidAgentId}
                        chipsLabel={(option) => option.fullName || ''}
                        displayLabel={(option) => option.fullName || ''}
                        withoutSearchButton
                        inputPlaceholder={t(`${translationPath}Agent`)}
                        parentTranslationPath={parentTranslationPath}
                        translationPath={translationPath}
                        onChange={(event, newValue) => {
                          setFilter((item) => ({ ...item, guidAgentId: (newValue && newValue.id) || null, pageIndex: 0 }));
                        }}
                      />

                    </div>
                  </div>

                  <div className='d-inline-flex pl-4-reversed mt-2'>
                    <div className='w-100 mr-1-reversed'>
                      <DataFileAutocompleteComponent
                        idRef='RelatedToRef'
                        isDisabled={(selected.activeFormType === 0)}
                        isLoading={(isLoading.leads)}
                        labelClasses='Requierd-Color'
                        multiple={false}
                        selectedValues={
                          (selected.activeFormType === 2 && selected.relatedLead)

                        }
                        data={
                          (selected.activeFormType === 2 && data.relatedLeads) || []
                        }
                        displayLabel={
                          (selected.activeFormType === 0 && ((option) => '')) ||
                          (selected.activeFormType === 2 &&
                            ((option) =>
                              (option.lead && option.lead.company_name) ||
                              (option.lead &&
                                option.lead.contact_name &&
                                option.lead.contact_name.name)) ||
                            '')
                        }
                        renderFor={
                          (selected.activeFormType === 0 && ' ') ||
                          (selected.activeFormType === 2 && 'lead')

                        }
                        getOptionSelected={
                          (selected.activeFormType === 0 && ((option) => '')) ||
                          (selected.activeFormType === 2 &&
                            ((option) => option.leadId === filter.relatedLeadId))

                        }
                        filterOptions={(options) => options}
                        onChange={(event, newValue) => {
                          if (selected.activeFormType === 2) {
                            if (!newValue) getAllRelatedLeads(newValue);
                            setSelected({ id: 'relatedLead', value: newValue });
                            setFilter((item) => ({ ...item, relatedLeadId: (newValue && newValue.leadId) || null, pageIndex: 0 }));
                          }
                        }}
                        onInputKeyUp={(e) => {
                          const { value } = e.target;
                          if (searchTimer.current) clearTimeout(searchTimer.current);
                          searchTimer.current = setTimeout(() => {
                            if (selected.activeFormType === 2) getAllRelatedLeads(value);
                          }, 700);
                        }}
                        withoutSearchButton
                        inputStartAdornment={(
                          <SelectComponet
                            data={[
                              {
                                key: 2,
                                value: 'lead',
                              },
                            ]}
                            value={selected.activeFormType}
                            valueInput='key'
                            textInput='value'
                            onSelectChanged={changeActiveFormType}
                            wrapperClasses='over-input-select w-auto'
                            idRef='relatedToTypeRef'
                            parentTranslationPath={parentTranslationPath}
                            translationPath={translationPath}
                            translationPathForData={translationPath}
                          />
                        )}
                        parentTranslationPath={parentTranslationPath}
                        translationPath={translationPath}
                      />
                    </div>
                    <div className='w-100 filter-section-date-picker'>
                      <DateRangePickerComponent
                        onClearClicked={() => setDateFilter(dateRangeDefault)}
                        ranges={[dateFilter]}
                        emptyLabel={t(`${translationPath}all`)}
                        parentTranslationPath={parentTranslationPath}
                        translationPath={translationPath}
                        onDateChanged={(selectedDate) => {
                          setDateFilter({
                            startDate: selectedDate.selection && selectedDate.selection.startDate,
                            endDate: new Date(moment(selectedDate.selection && selectedDate.selection.endDate).endOf('day')),
                            key: 'selection',
                          });
                          setFilter((item) => ({ ...item, pageIndex: 0 }));
                        }}
                      />
                    </div>
                  </div>
                </div>
                <ViewTypesActivities onTypeChanged={onTypeChanged} className='mb-3' />
              </div>
            </PermissionsComponent>
          </div>
          <PermissionsComponent
            permissionsList={Object.values(ActivitiesMortgagesPermissions)}
            permissionsId={ActivitiesMortgagesPermissions.ViewMortgageActivities.permissionsId}
          >
            <div className='d-flex px-2'>
              <span className='mx-2 mt-1'>{t(`${translationPath}select`)}</span>
              <span className='separator-v s-primary s-reverse s-h-25px mt-1' />
              <span className='px-2 d-flex'>
                <span className='texts-large mt-1'>
                  {t(`${translationPath}order-by`)}
                  :
                </span>
                <div className='px-2'>
                  <SelectComponet
                    idRef='filterByRef'
                    data={[
                      { id: 'CreatedOn', filterBy: 'created-date' },
                      { id: 'UpdateOn', filterBy: 'Updated-date' },
                      { id: 'ActivityDate', filterBy: 'activitie-date' },
                    ]}
                    wrapperClasses='mb-3'
                    isRequired
                    value={selectSortBy.filterBy}
                    emptyItem={{
                      value: null,
                      text: 'select-filter-by',
                      isDisabled: false,
                    }}
                    onSelectChanged={filterByChanged}
                    valueInput='id'
                    textInput='filterBy'
                    parentTranslationPath={parentTranslationPath}
                    translationPath={translationPath}
                    translationPathForData={translationPath}
                  />

                </div>
                <div className='px-2'>
                  <SelectComponet
                    idRef='orderByRef'
                    data={[
                      { id: 1, orderBy: 'ascending' },
                      { id: 2, orderBy: 'descending' },
                    ]}
                    emptyItem={{
                      value: null,
                      text: 'select-sort-by',
                      isDisabled: false,
                    }}
                    value={selectSortBy.orderBy}
                    onSelectChanged={orderByChanged}
                    wrapperClasses='mb-3'
                    isRequired
                    valueInput='id'
                    textInput='orderBy'
                    parentTranslationPath={parentTranslationPath}
                    translationPath={translationPath}
                    translationPathForData={translationPath}
                  />
                </div>
                <div className='mt-1'>

                  <ButtonBase
                    className='btns theme-solid'
                    onClick={GetOrdered}
                    id='action_apply'
                    disabled={selectSortBy.filterBy === null || selectSortBy.orderBy === null}
                  >
                    <span>{t(`${translationPath}apply`)}</span>
                  </ButtonBase>

                </div>
              </span>
            </div>
          </PermissionsComponent>
        </div>

        {activeActionType === ViewTypes2Enum.tableRelatedView.key && (
          <PermissionsComponent
            permissionsList={Object.values(ActivitiesMortgagesPermissions)}
            permissionsId={ActivitiesMortgagesPermissions.ViewMortgageActivities.permissionsId}
          >
            <ActivitiesTableView
              data={activities.result || []}
              activitiesCount={activities.totalCount || 0}
              parentTranslationPath={parentTranslationPath}
              filter={filter}
              translationPath={translationPath}
              onPageIndexChanged={onPageIndexChanged}
              onPageSizeChanged={onPageSizeChanged}
              isLoading={isLoadingActivities}
              setIsLoading={setIsLoadingActivities}
              isTableRelatedView
              getTableActionsByPermissions={editpermissionsList}
              setSortBy={setSortBy}
              pathName={pathName}
              activeActionType={activeActionType}
              onTypeChanged={onTypeChanged}
              onFilterValuesChanged={onFilterValuesChanged}
              localStorageKey={'mortgageActivities'}

            />
          </PermissionsComponent>
        )}
        {activeActionType === ViewTypes2Enum.tableView.key && (
          <PermissionsComponent
            permissionsList={Object.values(ActivitiesMortgagesPermissions)}
            permissionsId={ActivitiesMortgagesPermissions.ViewMortgageActivities.permissionsId}
          >
            <ActivitiesTableView
              data={activities.result || []}
              activitiesCount={activities.totalCount || 0}
              parentTranslationPath={parentTranslationPath}
              filter={filter}
              translationPath={translationPath}
              onPageIndexChanged={onPageIndexChanged}
              onPageSizeChanged={onPageSizeChanged}
              isLoading={isLoadingActivities}
              setIsLoading={setIsLoadingActivities}
              getTableActionsByPermissions={editpermissionsList}
              setSortBy={setSortBy}
              pathName={pathName}
              activeActionType={activeActionType}
              onFilterValuesChanged={onFilterValuesChanged}
              localStorageKey={'mortgageActivities'}


            />
          </PermissionsComponent>
        )}
      </div>
      {
        openDialog && (
          <ActivitiesManagementDialog
            open={openDialog}
            onSave={() => {
              setOpenDialog(false);
              onPageIndexChanged(0);
            }}
            close={() => {
              setOpenDialog(false);
            }}
            translationPath={translationPath}
            parentTranslationPath={parentTranslationPath}
          />

        )

      }

    </div>
  );
};
