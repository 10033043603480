import LearningAndDevelopmentView from '../../Views/Home/LearningAndDevelopmentView';

export const LearningAndDevelopmentRoutes = [
  {
    path: '/courses',
    name: 'LearningAndDevelopmentView:LearningAndDevelopmentView',
    component: LearningAndDevelopmentView,
    layout: '/home/learning-and-development',
    default: true,
    isRoute: true,
    authorize: true,
    roles: [],
    isDisabled: false,
    isExact: true,
    breadcrumbs: [
      {
        name: 'LearningAndDevelopmentView:courses',
        isDisabled: false,
        route: '/home/learning-and-development/courses',
        groupName: 'l-and-d',
      },
    ],
  },
];
