import React from 'react';
import { DialogComponent } from '../../../../../Components';
import { showError, showSuccess } from '../../../../../Helper';
import { DeActiveApplicationUser } from '../../../../../Services';
import { useTranslation } from 'react-i18next';

export const DeActivationConfirmDialog = ({
  onCancelClicked,
  onCloseClicked,
  reloadData,
  isDialogOpen,
  setIsDialogOpen,
  translationPath,
  parentTranslationPath,
  payload
}) => {
  const { t } = useTranslation(parentTranslationPath);

  const deleteHandler = async () => {
    const res = await DeActiveApplicationUser(payload);
    if (!(res && res.status && res.status !== 200)) {
      reloadData();
      showSuccess(t(`${translationPath}user-deactivated-successfully`));
      setIsDialogOpen(false);
    } else  showError(t(`${translationPath}user-deactivate-failed`));
  };

  return (
    <DialogComponent
      titleText='deactivation-confirm-message'
      saveText='confirm'
      saveType='button'
      maxWidth='sm'
      dialogContent={
        <div className='d-flex-column-center'>
          <span className='mdi mdi-close-octagon c-danger mdi-48px' />
          <span>{`${t(`${translationPath}user-deactivation-description`)}`}</span>
        </div>
      }
      saveClasses='btns theme-solid bg-danger w-100 mx-2 mb-2'
      isOpen={isDialogOpen}
      onCancelClicked={onCancelClicked}
      onCloseClicked={onCloseClicked}
      onSaveClicked={deleteHandler}
      parentTranslationPath={parentTranslationPath}
      translationPath={translationPath}
    />
  );
};
