export const LearningHubPermissions = {
    ViewLearningHub :{
        permissionsId: "083d193b-28e7-4ef2-0062-08dd03ecea2b",
        permissionsName: "View Learning Hub",
        description: null,
        componentsId: null,
        components: null
    }
  
  };
  