import React, { useState, useContext } from "react";
import ReactDOM from "react-dom";
import { useHistory } from "react-router-dom";
import clsx from "clsx";
import { useSelector } from "react-redux";
import { Paper, Box, Typography, Avatar } from "@material-ui/core";
import { useSelectedTheme, useTranslate, useIsAr } from "../../../Hooks";
import { CustomBadge, CustomButton, CustomCopyToClipboard, CustomIconButton } from "..";
import { ContactProfileTabs } from "./UI";
import { getDownloadableLink, returnPropsByPermissions } from "../../../Helper";
import { LeadTypeBadges } from "../LeadTypeBadges";
import { ContactsPermissions } from "../../../Permissions";
import { ActivitiesManagementDialog } from "../../../SharedComponents/ActivitiesManagementDialog/ActivitiesManagementDialog";
import { AddTaskDialog } from "../../../Views/Home/TaskConfiguration/Dialogs";
import { ContactLayoutContext } from "../../../Layouts/Home/NewContactsCrmLayout/ContactLayoutContext";

// Icons
import { CloseXIcon, ListIcon, StarOneIcon, ActivityIcon, ArrowUpRight } from "../../../assets/icons";

// Styles
import useStyles from "./styles";

function ContactProfile({ open, onClose, activeItem }) {
  const history = useHistory();
  const styles = useStyles();
  const { translate } = useTranslate("NewContactsView");
  const theme = useSelector((store) => store.theme);
  const { isAr } = useIsAr(theme.themeDirection);
  const { isAddTaskDialogOpen, setIsAddTaskDialogOpen, addActivity, setAddActivity } =
  useContext(ContactLayoutContext);
  const {
    theme: { palette },
  } = useSelectedTheme();

  const [tabValue, setTabValue] = useState(0);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };


  const contactProfileContent = (
    <Paper className={clsx(styles.contactSideMenu, { [styles.hidden]: !open })}>
      <Box className={styles.headingContainer}>
        <Typography className={styles.menuHeading} variant="h2">
          {translate("CONTACT_PROFILE")}
        </Typography>

        <CustomIconButton
          variant="text"
          size="none"
          boxShadow="none"
          color="tertiaryColor"
          hideHoverBg
          onClick={onClose}
        >
          <CloseXIcon
            width="20"
            height="20"
            fill={palette.button.tertiaryColor_fg}
          />
        </CustomIconButton>
      </Box>
      <Box className={styles.detailsContainer}>
        <Box className={styles.detailsBg} />
        <Box className={styles.detailsContent}>
          <Box className={styles.avatarWithIcon}>
            <Box className={styles.avatarWrapper}>
              <Avatar
                className={
                  activeItem?.imagePath ? styles.avatar : styles.defaultAvatar
                }
                src={
                  activeItem?.imagePath
                    ? getDownloadableLink(activeItem?.imagePath)
                    : activeItem?.contact_type_id === 2 // "CORPORATE"
                    ? "/images/contacts/CorporateAvatar.svg"
                    : activeItem?.gender?.lookupItemName === "Male"
                    ? "/images/contacts/MaleAvatar.svg"
                    : "/images/contacts/FemaleAvatar.svg"
                }
              />
            </Box>
            <StarOneIcon
              width="20"
              height="20"
              fill={palette.foreground.white}
              className={styles.starIcon}
            />
          </Box>
          <Box className={styles.contactDetails}>
            <Box className={styles.nameContainer}>
              {activeItem?.title?.lookupItemName && (
                <Typography
                  className={styles.prefix}
                  style={{ order: isAr ? 1 : "unset" }}
                  variant="body1"
                >
                  {activeItem?.title?.lookupItemName}
                </Typography>
              )}
              <Typography className={styles.fullName} variant="h5">
                {activeItem?.company_name
                  ? `${activeItem?.company_name}`
                  : `${activeItem?.first_name || ""} ${
                      activeItem?.last_name || ""
                    }`}
              </Typography>
              <CustomIconButton
                variant="text"
                size="xs"
                boxShadow="none"
                color="secondary"
                onClick={() => {
                  history.push(
                    `/home/Contacts-CRM/contact-profile-overview?formType=${
                      activeItem.contact_type_id
                    }&id=${activeItem?.contactId || activeItem?.id}`
                  );
                }}
              >
                <ArrowUpRight
                  width="20"
                  height="20"
                  fill={palette.button.secondaryGray_fg}
                />
              </CustomIconButton>
            </Box>

            <Box className={styles.contactInfoContainer}>
              <label className={styles.contactTypeLabel}>
                {activeItem?.contact_type_id === 2
                  ? translate("CORPORATE")
                  : translate("INDIVIDUAL")}
              </label>

              {(activeItem?.contact_class?.lookupItemName === "VIP" ||
                activeItem?.company_class?.lookupItemName === "VIP") && (
                <CustomBadge
                  Style={{
                    padding: "0 2px",
                    borderRadius: "2px",
                    lineHeight: "20px",
                  }}
                  label={translate("VIP")}
                  BackgroundColor={palette.utility.brand_300}
                  BorderColor={palette.utility.brand_300}
                  Color={"#FFF"}
                />
              )}

              {(activeItem?.contact_class?.lookupItemName === "VVIP" ||
                activeItem?.company_class?.lookupItemName === "VVIP") && (
                <CustomBadge
                  Style={{
                    padding: "0 2px",
                    borderRadius: "2px",
                    lineHeight: "20px",
                  }}
                  label={translate("VVIP")}
                  BackgroundColor={palette.utility.brand_600}
                  BorderColor={palette.utility.brand_600}
                  Color={"#FFF"}
                />
              )}

              {activeItem?.isBulkUpload && (
                <span style={{ textDecoration: "underline" }}>
                  {translate("BULK")}
                </span>
              )}

              <Box className={styles.idSection}>
                <CustomCopyToClipboard 
                  data={activeItem.contactId || activeItem?.id} // copy
                  childrenData={"#" + (activeItem.contactId || activeItem?.id)} // render
                  copyDoneShowTime={1000}
                />

                <CustomBadge
                  label={activeItem?.data_completed + "%" || "0%"}
                  SizeVariant={"small"}
                  BackgroundColor={
                    palette.utility[
                      activeItem?.data_completed >= 50 ? "brand_50" : "error_50"
                    ]
                  }
                  BorderColor={
                    palette.utility[
                      activeItem?.data_completed >= 50
                        ? "brand_200"
                        : "error_200"
                    ]
                  }
                  Color={
                    palette.utility[
                      activeItem?.data_completed >= 50
                        ? "brand_700"
                        : "error_700"
                    ]
                  }
                />
              </Box>
            </Box>

            {activeItem?.leadTypes && (
              <LeadTypeBadges leadTypesList={activeItem.leadTypes} />
            )}

            <Box className={styles.socialLinksContainer}>
              {/* <SocialLinks
                activeItem={activeItem} 
                gap={8}
              /> */}
            </Box>

            <Box className={styles.actionsContainer}>
              <CustomButton
                boxShadow="xs"
                size="md"
                variant="outlined"
                color="secondaryColor"
                startIcon={
                  <ListIcon
                    width="20"
                    height="20"
                    fill={palette.button.secondaryColor_fg}
                  />
                }
                onClick={() => setIsAddTaskDialogOpen(true)}
              >
                {translate("ADD_TASK")}
              </CustomButton>

              {returnPropsByPermissions(ContactsPermissions.AddNewActivity.permissionsId) &&
                <CustomButton
                  boxShadow="xs"
                  size="md"
                  variant="contained"
                  color="primary"
                  startIcon={
                    <ActivityIcon
                      width="20"
                      height="20"
                      fill={palette.button.primary_fg}
                    />
                  }
                  onClick={() => setAddActivity(true)}
                >
                  {translate("ADD_ACTIVITY")}
                </CustomButton>
              }
            </Box>
          </Box>
          <ContactProfileTabs
            key={activeItem?.contactId || activeItem?.id}
            tabValue={tabValue}
            handleTabChange={handleTabChange}
            activeItem={activeItem}
          />
        </Box>
      </Box>
    </Paper>
  );

  const activityDialog = addActivity && (
    <ActivitiesManagementDialog
      open={addActivity}
      onSave={() => setAddActivity(false)}
      close={() => setAddActivity(false)}
      actionItemId={activeItem?.id}
      translationPath={''}
      parentTranslationPath={'ContactProfileManagementView'}
      contactClassificationId={activeItem?.contact_class?.lookupItemId}
      contactId={activeItem.id}
    />
  );

  const taskDialog = isAddTaskDialogOpen && (
    <AddTaskDialog
      isDialogOpen={isAddTaskDialogOpen}
      onSave={() => setIsAddTaskDialogOpen(false)}
      onClose={() => setIsAddTaskDialogOpen(false)}
      translationPath={''}
      parentTranslationPath={'TaskConfiguration'}
    />
  );

  return ReactDOM.createPortal(
    <>
      {contactProfileContent}
      {activityDialog}
      {taskDialog}
    </>
  ,document.getElementById('portal-root'));
}

export default ContactProfile;
