import { Button, DialogContent } from '@material-ui/core';
import React, { Fragment } from 'react';
import { DialogComponent } from '../../../../../Components';
import { useState } from 'react';

const Recommendation = ({ recommendation, parentTranslationPath }) => {
  const [open, setOpen] = useState(false);
  return (
    <Fragment>
      {!recommendation && '-'}
      {recommendation?.length < 30 && recommendation}
      {recommendation?.length >= 30 && (
        <p>
          {recommendation.slice(0, 15).padEnd(20, '.')}{' '}
          <Button
            onClick={() => setOpen(true)}
            color='primary'
            size='small'
            className='capitalize'
          >
            {' '}
            show more
          </Button>{' '}
        </p>
      )}
      <DialogComponent
        isOpen={open}
        titleText={'recommendation'}
        dialogContent={
          <DialogContent style={{ fontSize: '14px' }}>
            {recommendation}
          </DialogContent>
        }
        onCloseClicked={() => setOpen(false)}
        maxWidth={'sm'}
        parentTranslationPath={parentTranslationPath}
        translationPath={''}
      />
    </Fragment>
  );
};

export default Recommendation;
