import { ButtonBase, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core'
import React, { useCallback, useState } from 'react'
import { Tables } from '../../../../../../../../Components'
import { useTranslation } from 'react-i18next'
export const ProposalHistoryViewDetailsDialog = ({
  activeitem,
  isOpen,
  onClose,
  translationPath,
  parentTranslationPath,
  filter
}) => {
  const { t } = useTranslation(parentTranslationPath);
  const close = () => {
    onClose()
  }

  const tableActionClicked = useCallback((actionEnum, item) => {

  })
  const getTableActions = () => {
    const list = [];
    return list
  }
  const focusedRowChanged = useCallback(() => { }, []);

  return (
    <Dialog
      open={isOpen}
      keepMounted
      onClose={() => {
        close()
      }}
      fullWidth
      maxWidth="lg"
    >
      <DialogTitle>{t(`${translationPath}Proposal-History-Receiver's-Details`)}</DialogTitle>
      <DialogContent>
        <div className='dialog-content-wrapper'>
          <Tables
            data={activeitem.proposalReceivers}
            headerData={
              [
                {
                  id: 1,
                  label: 'name',
                  input: 'name',
                },
                {
                  id: 2,
                  label: 'Type',
                  input: 'proposalReceiverType',
                  component: (item) =>
                  (
                    <span>
                      {item && item?.proposalReceiverType !== 1 ? (
                        <span>
                          User
                        </span>
                      ) : (
                        <span>
                          Lead
                        </span>
                      )}
                    </span>
                  )
                },
                {
                  id: 3,
                  label: 'email',
                  input: 'email',
                },
                {
                  id: 4,
                  label: 'ID',
                  input: 'receiverId',
                },
              ]
            }
            parentTranslationPath={parentTranslationPath}
            translationPath={translationPath}
            defaultActions={getTableActions()}
            actionsOptions={{
              onActionClicked: tableActionClicked,
            }}
            focusedRowChanged={focusedRowChanged}
            totalItems={(activeitem && activeitem.totalCount) || 0}
            itemsPerPage={filter.pageSize}
            activePage={0}
          />
        </div>

      </DialogContent>
      <DialogActions>
        <ButtonBase onClick={() => close()} className='btns theme-solid bg-cancel'>
          {t(`${translationPath}close`)}
        </ButtonBase>
      </DialogActions>
    </Dialog>
  )
}

