export const LeadPoolErrorType = {
  Activity: {
    key: 1,
    value: "Activity",
    message: "LeadPoolCheckActivityError",
  },
  LeadPoolCapacity: {
    key: 2,
    value: "Capacity",
    message: "LeadPoolCheckCapacityError",
  },
};
