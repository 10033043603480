import React  from 'react';
import { AutocompleteComponent } from '../../../../../../../../../Components';

export const SubCommunityComponent = ({
  parentTranslationPath,
  translationPath,
  addressLoadings ,
  onUpdateValue , 
  addressList , 
  propertyValues , 
}) => {

  return (
    <div>
     <AutocompleteComponent
          idRef='subCommunityRef'
          labelValue='sub_community'
          selectedValues={propertyValues && propertyValues.sub_community}
          isLoading={addressLoadings.subCommunities}
          data={addressList && addressList.subCommunities  || []}
          multiple={false}
          displayLabel={(option) => (option && option.lookupItemName) || ''}
          withoutSearchButton
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          onChange={(event, newValue) => {
           onUpdateValue(newValue);
          }}
      />
    </div>
  );
};
