
import React  , {  useState } from 'react';
import { DialogComponent, Spinner } from '../../../../../../Components';
import { DeletePropertyUnitModelAPI   } from '../../../../../../Services' ; 
import { showSuccess  , showError  } from '../../../../../../Helper' ; 
import { useTranslation } from 'react-i18next';

export const DeleteDialog = ({
  parentTranslationPath,
  translationPath,
  onCloseClicked,
  onSave , 
  activeItem, 
  reloadData
}) => {

  const [isLoading , setIsLoading ] = useState(false) ; 
  const { t } = useTranslation(parentTranslationPath);

  const deleteHandler = async () => {
    setIsLoading(true);
    const res = await DeletePropertyUnitModelAPI(activeItem && activeItem.propertyUnitModelId)
    if (!(res && res.status && res.status !== 200)) {
        showSuccess(t(`${translationPath}unit-model-delete-successfully`));
        onCloseClicked() ;
        reloadData()
    } else showError(t(`${translationPath}unit-model-delete-Failed`));
    setIsLoading(false);
};


  return (
    <>
    <Spinner isActive={isLoading} isAbsolute/>
    <DialogComponent
        titleText='confirm-message'
        saveText={t(`${translationPath}confirm`)}
        SmothMove
        saveType='button'
        maxWidth='sm'
        dialogContent={(
          <div className='d-flex-column-center'>
            <Spinner isActive={isLoading} isAbsolute />
            <span className='mdi mdi-close-octagon c-danger mdi-48px' />
            <span className='fz-18px fw-bold'>
              {`${`${t(`${translationPath}are-you-sure-to-delete-this-unit-model`)}` || ''}`}
              <span className='d-flex-center  fz-22px fw-bold mt-2'>
                {' '}
                (
                {' '}
                {' '}
                {activeItem && activeItem.propertyUnitModelName}
                {' '}
                {' '}
                {' '}
                )
                {' '}
              </span>

            </span>
          </div>
        )}
        saveClasses='btns theme-solid bg-danger w-100 mx-2 mb-2'
        isOpen={true}
        onSaveClicked={()=> deleteHandler()}
        onCloseClicked={() =>  onCloseClicked()}
        onCancelClicked={() => onCloseClicked() }
        parentTranslationPath={parentTranslationPath}
        translationPath={translationPath}
      />
      </>

  );
};
