import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import ButtonBase from '@material-ui/core/ButtonBase';
import { getDownloadableLink } from '../../../../../../../Helper';
import {
  ActionsEnum,
  DefaultImagesEnum,
  UnitProfileImagesCardActionEnum,
} from '../../../../../../../Enums';
import { LoadableVideoComponant, PermissionsComponent } from '../../../../../../../Components';
import { useLocalStorage } from '../../../../../../../Hooks';
import { MediaPreviewDialog } from '../../../../../TemplatesView/Dialogs'

export const AlbumsFooterComponent = ({
  hideIcon,
  Display,
  activeCategory,
  onFooterActionsClicked,
  parentTranslationPath,
  activeVideoHandler,
  translationPath,
  allCtegoryVideo
}) => {
  const { t } = useTranslation(parentTranslationPath);
  const [language] = useLocalStorage('localization', {
    currentLanguage: 'en',
    isRtl: false,
  });
  const data = allCtegoryVideo || activeCategory &&
    activeCategory.filteredVideosDto &&
    activeCategory.filteredVideosDto.result &&
    activeCategory.filteredVideosDto.result;
  return (
    <div className='albums-footer-wrapper presentational-wrapper'>
      <div className='images-more-wrapper'>
        {
          (data.length > 0) && (
            <div className='slide-images'>
              {data.filter((item, index) => index < 3)
                .map((item, index) => (
                  <ButtonBase
                    className='slide-image'
                    style={{
                      zIndex: index,
                      transform: `translateX(${(language.isRtl &&
                        -(index * 40)) || (index * 40)
                        }%)`,
                    }}
                    onClick={activeVideoHandler(data, index, data)}
                  >
                    <LoadableVideoComponant
                      key={`albumImageSliderRef${index + 1}`}
                      classes='slide-image'
                      wrapperClasses='slide-image'
                      hideVideoControls
                      // fileId={item.fileId}
                      isLink={item.videoLink && true}
                      src={(item.fileId && getDownloadableLink(item.fileId))}
                      alt={t(`${translationPath}cover-image`)}
                      width={40}
                      height={40}
                    />
                  </ButtonBase>
                ))}
            </div>
          )}
        {(data.length > 3) && (
          <ButtonBase
            className='images-more'
            onClick={activeVideoHandler(data, 0, data)}
            style={{
              transform: `translateX(${(language.isRtl && -120) || 120
                }%)`,
            }}
          >
            {data.length > 0 &&
              (`+ ${(data.length - 3 <= 99 &&
                data.length - 3) ||
                '99'}`) ||
              (`+ ${data.length}`)}
          </ButtonBase>
        )}
      </div>
  
      <div className='footer-actions-wrapper px-1'>
        {Display === UnitProfileImagesCardActionEnum.Show && (
          <ButtonBase
            className='btns-icon theme-transparent mx-1'
            onClick={() => onFooterActionsClicked(ActionsEnum.delete.key, activeCategory)}
          >
            <span className={`${ActionsEnum.delete.icon} c-warning`} />
          </ButtonBase>
        )}
      </div>
    </div>
  );
};
AlbumsFooterComponent.propTypes = {
  hideIcon: PropTypes.bool.isRequired,
  Display: PropTypes.instanceOf(UnitProfileImagesCardActionEnum).isRequired,
  data: PropTypes.instanceOf(Object).isRequired,
  onFooterActionsClicked: PropTypes.func.isRequired,
  activeVideoHandler: PropTypes.func.isRequired,
  parentTranslationPath: PropTypes.string.isRequired,
  translationPath: PropTypes.string.isRequired,
};
