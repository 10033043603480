
import React , {useState} from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import {SwitchComponent } from '../../../../../../../../../../../../Components' ; 
import { PortalComponent , PortalAccountComponent , ListingAgentComponent , BranchComponent } from './Sections' ; 


export const PublishedOnPortalContentDialog  = ({
  parentTranslationPath,
  translationPath,
  state,
  setState , 
  schema , 
  isSubmitted ,
  selected ,
  setSelected , 
  editUnitPublishedPortal , 
  propertyFinderDetails , 
  setPropertyFinderDetails , 
}) => {

  const { t } = useTranslation(parentTranslationPath);
  
  return (
    <div className='dialog-content-wrapper'>
      <ListingAgentComponent 
        parentTranslationPath={parentTranslationPath}
        translationPath={translationPath} 
        state={state}
        setState={setState}
        selected={selected}
        setSelected ={setSelected}
        schema ={schema }
        isSubmitted ={isSubmitted}
        
       />
      <BranchComponent 
        parentTranslationPath={parentTranslationPath}
        translationPath={translationPath} 
        state={state}
        setState={setState}
        selected={selected}
        setSelected ={setSelected}
        schema ={schema }
        isSubmitted ={isSubmitted}

       />
      <PortalAccountComponent 
        parentTranslationPath={parentTranslationPath}
        translationPath={translationPath} 
        state={state}
        setState={setState}
        selected={selected}
        setSelected ={setSelected}
        schema ={schema }
        isSubmitted ={isSubmitted}
        propertyFinderDetails={propertyFinderDetails}
        setPropertyFinderDetails ={setPropertyFinderDetails}

       />
        <PortalComponent 
         parentTranslationPath={parentTranslationPath}
         translationPath={translationPath} 
         state={state}
         setState={setState}
         selected={selected}
         setSelected ={setSelected}
         schema ={schema }
         isSubmitted ={isSubmitted}

       />
      <div className='dialog-content- mt-2 w-100'>
            <SwitchComponent
                  idRef='isActiveRef'
                  isChecked={state.isActive}
                  themeClass='theme-line'
                  labelValue={(state.isActive && 'active') || 'inactive'}
                  parentTranslationPath={parentTranslationPath}
                  translationPath={translationPath}
                  onChangeHandler={(event, isChecked) => {
                     if(!editUnitPublishedPortal)
                     return ; 
                    setState({ id: 'isActive', value: isChecked })}
                  }
                />  
        </div>
    
    </div>
  );
};

PublishedOnPortalContentDialog.propTypes = {
  parentTranslationPath: PropTypes.string.isRequired,
  translationPath: PropTypes.string.isRequired,
  state:PropTypes.instanceOf(Object).isRequired,
  setState : PropTypes.func.isRequired,
  selected:PropTypes.instanceOf(Object).isRequired,
  setSelected : PropTypes.func.isRequired,
  schema :PropTypes.instanceOf(Object).isRequired,
  isSubmitted : PropTypes.bool.isRequired,

};



