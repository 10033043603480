import React, { useCallback, useReducer } from "react";
import { useTranslation } from "react-i18next";
import { AutocompleteComponent, Inputs } from "../../../../../../Components";
import { lookupItemsGetId } from "../../../../../../Services";
import { BankAccount } from "../../../../../../Enums";
import { PaymentMethodsData } from "../../../Utilities/PaymentMethodsBanner/PaymentMethodsData";

export const AdditionalPaymentInfoFields = ({
  selected,
  setSelected,
  parentTranslationPath,
  translationPath,
}) => {
  const { t } = useTranslation(parentTranslationPath);

  const reducer = useCallback((state, action) => {
    if (action.id !== "edit") return { ...state, [action.id]: action.value };
    return {
      ...action.value,
    };
  }, []);

  const [data, setData] = useReducer(reducer, {
    banks: [],
  });

  const getBanks = async () => {
    const res = await lookupItemsGetId({
      lookupTypeId: BankAccount.Babk.value,
    });
    if (!(res && res.status && res.status !== 200))
      setData({ id: "banks", value: res || [] });
    else setData({ id: "banks", value: [] });
  };

  return (
    <>
      <div className="b-bottom">
        <div className="d-flex fj-start gap-16 w-75">
          <div className="w-25 mt-2 pt-1">
            <p className="fw-simi-bold m-0">{t(`${translationPath}bank`)}</p>
          </div>
          <div className="w-50 mt-2 mb-3 pt-1">
            <AutocompleteComponent
              idRef="bankRef"
              inputPlaceholder="select"
              selectedValues={selected.bank}
              multiple={false}
              data={data.banks || []}
              displayLabel={(option) => option.lookupItemName || ""}
              withoutSearchButton
              isWithError
              parentTranslationPath={parentTranslationPath}
              translationPath={translationPath}
              onOpen={() => {
                if (data.banks && data.banks.length == 0) getBanks();
              }}
              onChange={(_, newValue) => {
                setSelected({ id: "bank", value: newValue });
              }}
            />
          </div>
        </div>
      </div>
      <div className="b-bottom">
        <div className="d-flex fj-start gap-16 w-75">
          <div className="w-25 mt-2 pt-1">
            <p className="fw-simi-bold m-0">{t(`${translationPath}branch`)}</p>
          </div>
          <div className="w-50 mt-2 mb-3 pt-1">
            <Inputs
              idRef="branchRef"
              inputPlaceholder={t(`${translationPath}enter`)}
              value={selected.branch || ""}
              parentTranslationPath={parentTranslationPath}
              onInputChanged={(event) =>
                setSelected({ id: "branch", value: event.target.value })
              }
            />
          </div>
        </div>
      </div>
      {selected.paymentMode.key === PaymentMethodsData.BankTransfer.key ? (
        <div className="b-bottom">
          <div className="d-flex fj-start gap-16 w-75">
            <div className="w-25 mt-2 pt-1">
              <p className="fw-simi-bold m-0">
                {t(`${translationPath}bank-transfer-no`)}
              </p>
            </div>
            <div className="w-50 mt-2 mb-3 pt-1">
              <Inputs
                idRef="bankTransferNoRef"
                inputPlaceholder={t(`${translationPath}enter`)}
                value={selected.bankTransferNo || ""}
                parentTranslationPath={parentTranslationPath}
                onInputChanged={(event) =>
                  setSelected({
                    id: "bankTransferNo",
                    value: event.target.value,
                  })
                }
              />
            </div>
          </div>
        </div>
      ) : null}
      {selected.paymentMode.key === PaymentMethodsData.Creditcard.key ? (
        <div className="b-bottom">
          <div className="d-flex fj-start gap-16 w-75">
            <div className="w-25 mt-2 pt-1">
              <p className="fw-simi-bold m-0">{t(`${translationPath}creditCardNo`)}</p>
            </div>
            <div className="w-50 mt-2 mb-3 pt-1">
              <Inputs
                idRef="creditCardNoRef"
                inputPlaceholder={t(`${translationPath}enter`)}
                value={selected.creditCardNo || ""}
                parentTranslationPath={parentTranslationPath}
                onInputChanged={(event) =>
                  setSelected({ id: "creditCardNo", value: event.target.value })
                }
              />
            </div>
          </div>
        </div>
      ) : null}
      {selected.paymentMode.key === PaymentMethodsData.Cheque.key ? (
        <div className="b-bottom">
          <div className="d-flex fj-start gap-16 w-75">
            <div className="w-25 mt-2 pt-1">
              <p className="fw-simi-bold m-0">{t(`${translationPath}chequeNo`)}</p>
            </div>
            <div className="w-50 mt-2 mb-3 pt-1">
              <Inputs
                idRef="chequeNoRef"
                inputPlaceholder={t(`${translationPath}enter`)}
                value={selected.chequeNo || ""}
                parentTranslationPath={parentTranslationPath}
                onInputChanged={(event) =>
                  setSelected({ id: "chequeNo", value: event.target.value })
                }
              />
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};
