import React from "react";

function UploadCloudIcon({ fill, ...restProps }) {
  return (
    <svg
      viewBox="0 0 20 20"
      fill={fill ?? ""}
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <path
        d="M7.383 2.187c-1.039.11-1.816.329-2.65.747C2.678 3.965 1.263 5.927.911 8.233c-.08.523-.08 1.511 0 2.034.225 1.471.9 2.852 1.894 3.87.257.263.405.338.676.34a.776.776 0 0 0 .615-.261.743.743 0 0 0 .208-.55c.002-.261-.08-.431-.342-.716-1.378-1.495-1.816-3.535-1.171-5.45a5.44 5.44 0 0 1 3.376-3.376 5.428 5.428 0 0 1 5.809 1.537c.125.14.339.424.476.63.137.206.313.435.39.51.246.236.591.363.991.365.302.001.798.087 1.098.189 2.128.726 3.161 3.114 2.22 5.131-.208.446-.444.773-.851 1.177-.325.325-.368.381-.418.554a.786.786 0 0 0 .234.845.768.768 0 0 0 .561.205c.273 0 .42-.078.768-.404a5.393 5.393 0 0 0 1.096-6.441 5.337 5.337 0 0 0-2.368-2.336c-.612-.307-1.484-.551-1.973-.553-.24-.001-.273-.023-.467-.31-1.045-1.554-2.863-2.687-4.766-2.972-.346-.052-1.325-.091-1.584-.064m2.384 7.517a1.083 1.083 0 0 0-.267.147c-.274.203-3.517 3.489-3.585 3.632-.083.177-.085.522-.003.689.076.155.261.34.416.416.167.082.513.08.689-.003.083-.04.508-.436 1.141-1.064l1.007-.999.009 2.847c.01 3.182-.007 2.953.248 3.209a.716.716 0 0 0 .578.237.716.716 0 0 0 .578-.237c.255-.256.238-.027.248-3.209l.009-2.847 1.007.999c.633.628 1.058 1.024 1.141 1.064.176.083.522.085.689.003.155-.076.34-.261.416-.416.082-.166.08-.512-.003-.689-.085-.183-3.551-3.65-3.729-3.73a1.008 1.008 0 0 0-.589-.049"
        fill={fill ?? ""}
      />
    </svg>
  );
}

export default UploadCloudIcon;
