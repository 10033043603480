import { GlobalAdvanceSearchStatus } from './GlobalAdvanceSearchStatus';

const initialState = localStorage.getItem('GlobalAdvanceSearch') ?
  JSON.parse(localStorage.getItem('GlobalAdvanceSearch')) :null

export const GlobalAdvanceSearchReducer = (state = initialState, action) => {
  switch (action.type) {
    case GlobalAdvanceSearchStatus.ERROR:
    case GlobalAdvanceSearchStatus.REQUEST:
      return state;

    case GlobalAdvanceSearchStatus.SUCCESS:
      return action.payload;

    case GlobalAdvanceSearchStatus.RESET:
      return initialState;

    default:
      return state;
  }
};
