/* eslint-disable max-len */
import { ButtonBase } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { DateRangePickerComponent } from '../../../../Components/Controls/DateRangePickerComponent/DateRangePickerComponent';
import { SelectComponet } from '../../../../Components';

export const SettingsSectionView = ({
  // eslint-disable-next-line no-unused-vars
  OpenOpationEdit, parentTranslationPath, translationPath, ReastColumus, AcationStateIsEdit, RemoveAllcolumns, onSaveClicked, onCancelClicked
}) => {
  const { t } = useTranslation(parentTranslationPath);
  const ActiveItemReducer = useSelector((state) => state.ActiveItemReducer);
  const dateRangeDefault = {
    startDate: null,
    endDate: null,
    key: 'selection',
  };
  const [dateFilter, setDateFilter] = useState(dateRangeDefault);
  const [selectedDateType, setSelectedDateType] = useState(1);

  useEffect(() => {
    try {
      if (AcationStateIsEdit && ActiveItemReducer && ActiveItemReducer.systemReportFilters) {
        setDateFilter({
          startDate: ((JSON.parse(ActiveItemReducer && ActiveItemReducer.systemReportFilters && ActiveItemReducer.systemReportFilters).fromDate !== null &&
            new Date(moment((JSON.parse(ActiveItemReducer && ActiveItemReducer.systemReportFilters && ActiveItemReducer.systemReportFilters).fromDate)).add(-24, 'hours'))) || null),
          endDate: JSON.parse(ActiveItemReducer && ActiveItemReducer.systemReportFilters && ActiveItemReducer.systemReportFilters).toDate !== null &&
            (new Date(JSON.parse(ActiveItemReducer && ActiveItemReducer.systemReportFilters && ActiveItemReducer.systemReportFilters).toDate) || null),
          key: 'selection',
        });
        setSelectedDateType(JSON.parse(ActiveItemReducer && ActiveItemReducer.systemReportFilters).dateType);
      } else
        setDateFilter(dateRangeDefault);
    } catch (error) {
      setSelectedDateType(1);
      setDateFilter(dateRangeDefault);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className='SettingsSectionView-view-wrapers'>
      <div className='title'>
        {' '}

        {AcationStateIsEdit ? t(`${translationPath}edit-Report`) : t(`${translationPath}add-Report`)}
        {' '}
        <div className='mt-2' style={{ minWidth: '270px', minHeight: '37px' }}>
          {/* <DateRangePickerComponent
            onClearClicked={() => setDateFilter(dateRangeDefault)}
            ranges={[dateFilter]}
            parentTranslationPath={parentTranslationPath}
            translationPath={translationPath}
            onDateChanged={(selectedDate) => {
              setDateFilter({
                startDate: selectedDate.selection && selectedDate.selection.startDate,
                endDate: new Date(moment(selectedDate.selection && selectedDate.selection.endDate).endOf('day')),
                key: 'selection',
              });
            }}
          /> */}

          <div className='datePickerReprt'>
            <div className='date-type-select'>
              <SelectComponet
                data={[
                  { key: 1, value: `${translationPath}CreatedOn` },
                  { key: 2, value: `${translationPath}UpdatedOn` }
                ]}
                defaultValue={{ key: 1, value: `${translationPath}created-date` }}
                value={selectedDateType}
                valueInput='key'
                textInput='value'
                onSelectChanged={(value) => {
                  setDateFilter(dateRangeDefault);
                  setSelectedDateType(value);
                }}
                wrapperClasses='w-auto'
                themeClass='theme-transparent'
                idRef='Date_Select'
                parentTranslationPath={parentTranslationPath}
                translationPath={translationPath}
                translationPathForData={translationPath}
              />
            </div>
            <DateRangePickerComponent
              onClearClicked={() => {
                setDateFilter(dateRangeDefault);
                setSelectedDateType(1);
                onSaveClicked([]);
              }}
              onDialogClose={() => {
              }}
              ranges={[dateFilter]}
              parentTranslationPath={parentTranslationPath}
              translationPath={translationPath}
              onDateChanged={(selectedDate) => {
                setDateFilter({
                  startDate: selectedDate.selection && selectedDate.selection.startDate,
                  endDate: new Date(moment(selectedDate.selection && selectedDate.selection.endDate).endOf('day')),
                  key: 'selection',
                });
                onSaveClicked({
                  startDate: (moment(selectedDate.selection && selectedDate.selection.startDate).add(24, 'hours')),
                  endDate: new Date(moment(selectedDate.selection && selectedDate.selection.endDate).endOf('day')),
                  key: 'selection',
                }, selectedDateType);
              }}

            />
          </div>
        </div>
      </div>
      <div className='Settings-bbt'>
        {/* <ButtonBase className='btns c-black-light history-button'>
          {t('Graph Settings')}
        </ButtonBase>
        | */}
        <ButtonBase className='btns c-black-light history-button' onClick={OpenOpationEdit}>
          {t(`${translationPath}Edit-Filters`)}

        </ButtonBase>
        {/* <ButtonBase className='btns c-black-light history-button' onClick={RemoveAllcolumns}>
          {t(`${translationPath}Remove-all-columns`)}

        </ButtonBase> */}
        {/* |
        <ButtonBase className='btns c-black-light history-button'>
          {t('Report-Settings')}
        </ButtonBase>
        | */}
        <ButtonBase className='btns c-black-light history-button' onClick={ReastColumus}>
          {t(`${translationPath}Reset-Fields`)}
        </ButtonBase>
      </div>
    </div>
  );
};
SettingsSectionView.propTypes = {
  translationPath: PropTypes.string,
  parentTranslationPath: PropTypes.string,
  OpenOpationEdit: PropTypes.func,
  ReastColumus: PropTypes.func,
  AcationStateIsEdit: PropTypes.bool,
  RemoveAllcolumns: PropTypes.func,
  onSaveClicked: PropTypes.func,
  onCancelClicked: PropTypes.func,
};

SettingsSectionView.defaultProps = {
  translationPath: '',
  parentTranslationPath: '',
  AcationStateIsEdit: false,
  OpenOpationEdit: () => { },
  RemoveAllcolumns: () => { },
  onSaveClicked: () => { },
  onCancelClicked: () => { },
  ReastColumus: () => { },
};
