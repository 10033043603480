import React, { useEffect, useState } from 'react';
import { SelectComponet, Spinner, Tables } from '../../../../Components';
import {
  GetConvoloCalls,
  GetConvoloCallStatistics,
} from '../../../../Services/ConvoloReports';
import { showError } from '../../../../Helper';
import { DateRangePickerComponent } from '../../../../Components/Controls/DateRangePickerComponent/DateRangePickerComponent';
import moment from 'moment';
import DetailsCard from './components/DetailsCard';
import DetailLabel from './components/DetailLabel';
import { PlayArrow, Storage, TrendingUp } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import { CopyToClipboardComponents } from '../../../../ReusableComponents/UtilityComponents';
import AudioPlayer from '../../AgentsView/AgentsTabelView/components/AudioPlayer';
import { IconButton } from '@material-ui/core';
const callCategories = [
  'internal',
  'inbound_answered',
  'inbound_unanswered',
  'outbound_answered',
  'outbound_unanswered',
];

const outcomeTypes = ['positive', 'negative', 'neutral', 'absent'];

const parentTranslationPath = 'ConvoloDashboard';
function ConvoloSpeedToLeadCalls() {
  const { t } = useTranslation(parentTranslationPath);
  const [audioLink, setAudioLink] = useState(null);
  const [filter, setFilter] = useState({
    dateInterval: 'day',
    page: 1,
    itemsPerPage: 25,
    dayFrom: moment().subtract(1, 'months'),
    dayTo: moment(),
    categories: [],
    outcomeTypes: [],
  });
  const [isLoading, setIsLoading] = useState(true);
  const [statistics, setStatistics] = useState({});
  const [data, setData] = useState([]);
  const headers = [
    {
      label: 'type',
      input: 'type',
      component: (row) =>
        t(
          `${row.type === 0 ? 'inbound' : 'outbound'}_${
            row.answerTime ? 'answered' : 'unanswered'
          }`
        ),
    },
    {
      label: 'agent',
      input: 'agentShortNumber',
      component: (row) => (
        <CopyToClipboardComponents
          data={row.agentShortNumber}
          childrenData={row.agentShortNumber}
        />
      ),
    },
    {
      label: 'client',
      input: 'fromNumber',
      component: (row) => (row.type === 0 ? row.fromNumber : row.toAnswer),
    },
    {
      label: 'call-info',
      input: 'fromNumber',
      component: (row) => (
        <>
          <p>
            <span>{moment(row.timestamp).format('YYYY-MM-DD hh:mm A')}</span>
            <br />
            <strong>
              {t('id')}: {row.uniqueId}
            </strong>
          </p>
          {row.tempJson?.recordingName && (
            <IconButton
              size='small'
              onClick={() => setAudioLink(row.tempJson?.recordingName)}
            >
              <PlayArrow fontSize='small' />
            </IconButton>
          )}
        </>
      ),
    },
    {
      label: 'call-flow',
      input: 'toNumber',
      component: (row) => (
        <p>
          <span>{row.toNumber}</span>
          <br />
          {row.disconnectedBy && (
            <strong>disconnected By: {row.disconnectedBy}</strong>
          )}
        </p>
      ),
    },
    {
      label: 'call-result',
      input: 'tags',
      component: (row) => row.tags?.join(', '),
    },
    {
      label: 'project',
      input: 'projectName',
      component: (row) => (
        <p>
          {row.projectName && (
            <span>
              {t('project-name')}
              {row.projectName}
            </span>
          )}
          <br />
          {row.projectId && (
            <span>
              {t('project-id')}
              {row.projectId}
            </span>
          )}
          <br />
          {row.projectContactId && (
            <span>
              {t('project-contact-id')}
              {row.projectContactId}
            </span>
          )}
        </p>
      ),
    },
  ];

  const resetDateRange = () =>
    setFilter((prev) => ({
      ...prev,
      dayFrom: moment().subtract(1, 'months'),
      dayTo: moment(),
    }));
  const getConvoloCallStatistics = async () => {
    setIsLoading(true);
    try {
      const response = await GetConvoloCallStatistics(filter);
      setStatistics(response.detailedStat);
    } catch (error) {
      showError('something went wrong');
    } finally {
      setIsLoading(false);
    }
  };

  const getConvoloCalls = async () => {
    try {
      const result = await GetConvoloCalls(filter);
      setData(result.calls);
    } catch (error) {
      showError('something went wrong');
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    getConvoloCallStatistics();
    getConvoloCalls();
  }, [filter]);
  return (
    <div>
      <Spinner isActive={isLoading} isAbsolute />
      <div className='flex gap-1'>
        <DetailsCard
          title={t('call-status')}
          icon={<TrendingUp fontSize='small' />}
          className='w-75'
        >
          <DetailLabel label={t('total')} value={statistics.all?.all} />
          <DetailLabel
            label={t('outbound-unanswered')}
            value={statistics.out?.all}
          />
          <DetailLabel
            label={t('inbound-missed')}
            value={statistics.in?.fail}
          />
          <DetailLabel
            label={t('outbound-answered')}
            value={statistics.out?.success}
          />
          <DetailLabel
            label={t('inbound-answered')}
            value={statistics.in?.success}
          />
        </DetailsCard>
        <DetailsCard
          title={t('call-result')}
          icon={<Storage fontSize='small' />}
        >
          <DetailLabel label={t('positive')} value={statistics.tagsSuccess} />
          <DetailLabel label={t('neutral')} value={statistics.tagsDefault} />
          <DetailLabel label={t('negative')} value={statistics.tagsFail} />
          <DetailLabel
            label={t('no-call-result')}
            value={statistics.tagsNoResult}
          />
        </DetailsCard>
      </div>
      <div className='d-flex fj-end py-2 fa-end'>
        <SelectComponet
          data={outcomeTypes}
          multiple
          value={filter.outcomeTypes}
          onSelectChanged={(e) =>
            setFilter((prev) => ({ ...prev, outcomeTypes: e }))
          }
          labelValue={'select-result'}
          wrapperClasses={'w-25 px-2'}
          parentTranslationPath={parentTranslationPath}
        />
        <SelectComponet
          data={callCategories}
          multiple
          value={filter.categories}
          onSelectChanged={(e) =>
            setFilter((prev) => ({ ...prev, categories: e }))
          }
          labelValue={'select-status'}
          wrapperClasses={'w-25 px-2'}
          parentTranslationPath={parentTranslationPath}
        />
        <DateRangePickerComponent
          ranges={[
            {
              startDate: new Date(filter.dayFrom),
              endDate: new Date(filter.dayTo),
            },
          ]}
          onDateChanged={(e) => {
            setFilter((prev) => ({
              ...prev,
              dayFrom: e.range1.startDate,
              dayTo: e.range1.endDate,
            }));
          }}
          onClearClicked={resetDateRange}
        />
      </div>
      <Tables
        headerData={headers}
        data={data}
        defaultActions={[]}
        itemsPerPage={filter.itemsPerPage}
        parentTranslationPath={parentTranslationPath}
        activePage={filter.page - 1}
        totalItems={statistics.all?.all}
        onPageSizeChanged={(size) =>
          setFilter((prev) => ({ ...prev, itemsPerPage: size }))
        }
        onPageIndexChanged={(index) =>
          setFilter((prev) => ({ ...prev, page: index + 1 }))
        }
      />
      {audioLink && (
        <AudioPlayer audioLink={audioLink} onClose={() => setAudioLink(null)} />
      )}
    </div>
  );
}

export default ConvoloSpeedToLeadCalls;
