import { AgentRotationView } from "../../Views/Home/AgentRotationLog/AgentRotationView";

export const AgentRotationViewRoutes = [
    {
        path: '/view',
        name: 'AgentRotation',
        component: AgentRotationView,
        layout: '/home/agent-rotation',
        default: true,
        isRoute: true,
        authorize: true,
        roles: [],
        isDisabled: false,
        isExact: true,
        breadcrumbs: [
          {
            name: 'agent-rotation-log',
            isDisabled: true,
            route: '/home/agent-rotation',
            groupName: 'agent-management',
          },
        ],
    }];
