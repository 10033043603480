import { ExternalDashboard } from "../../Views/Home/Dashboard/ExternalDashboard";
import { MarketingClientsSegmentation } from "../../Views/Home/Dashboard/MarketingClientsSegmentation/MarketingClientsSegmentation";
import { SegmentationLeadBranchTab } from "../../Views/Home/Dashboard/SegmentationLeadBranchTab/SegmentationLeadBranchTab";
import { MarketingClientsSegmentationView } from "../../Views/Home/MarketingClientsSegmentation";
import { AddMarketingClientsSegmentation , EditMarketingClientsSegmentation } from "../../Views/Home/MarketingClientsSegmentation";
export const ClientsSegmentationRoutes = [
  {
    path: "/view",
    name: "ClientsSegmentation:clients-segmentation",
    component: MarketingClientsSegmentationView,
    layout: "/home/clients-segmentation",
    default: true,
    isRoute: true,
    authorize: true,
    roles: [],
    isDisabled: false,
    isExact: true,
    breadcrumbs: [
      {
        name: "ClientsSegmentation:clients-segmentation",
        isDisabled: true,
        route: "/home/clients-segmentation/view",
        groupName: "marketing",
      },
    ],
  },
  {
    path: "/add",
    name: "ClientsSegmentation:add-clients-segmentation",
    component: AddMarketingClientsSegmentation,
    layout: "/home/clients-segmentation",
    default: false,
    isRoute: true,
    authorize: true,
    roles: [],
    isDisabled: false,
    isExact: true,
    breadcrumbs: [
      {
        name: "ClientsSegmentation:clients-segmentation",
        isDisabled: false,
        route: "/home/clients-segmentation/view",
        groupName: "marketing",
      },
      {
        name: "ClientsSegmentation:add-clients-segmentation",
        isDisabled: true,
        route: "/home/clients-segmentation/add",
      },
    ],
  },
  {
    path: "/quick",
    name: "ClientsSegmentation:quick-client-segmentation",
    component: MarketingClientsSegmentation,
    layout: "/home/clients-segmentation",
    default: false,
    isRoute: true,
    authorize: true,
    roles: [],
    isDisabled: false,
    isExact: true,
    breadcrumbs: [
      {
        name: "ClientsSegmentation:clients-segmentation",
        isDisabled: false,
        route: "/home/clients-segmentation/view",
        groupName: "marketing",
      },
      {
        name: "ClientsSegmentation:quick-client-segmentation",
        isDisabled: true,
        route: "/home/clients-segmentation/quick",
      },
    ],
  },
  {
    path: "/segmentation-branch",
    name: "ClientsSegmentation:quick-client-segmentation-branch",
    component: SegmentationLeadBranchTab,
    layout: "/home/clients-segmentation",
    default: false,
    isRoute: true,
    authorize: true,
    roles: [],
    isDisabled: false,
    isExact: true,
    breadcrumbs: [
      {
        name: "ClientsSegmentation:clients-segmentation",
        isDisabled: false,
        route: "/home/clients-segmentation/view",
        groupName: "marketing",
      },
      {
        name: "ClientsSegmentation:quick-client-segmentation",
        isDisabled: true,
        route: "/home/clients-segmentation/quick",
      },
    ],
  },

  {
    path: "/edit",
    name: "ClientsSegmentation:edit-clients-segmentation",
    component: EditMarketingClientsSegmentation,
    layout: "/home/clients-segmentation",
    default: false,
    isRoute: true,
    authorize: true,
    roles: [],
    isDisabled: false,
    isExact: true,
    breadcrumbs: [
      {
        name: "ClientsSegmentation:clients-segmentation",
        isDisabled: false,
        route: "/home/clients-segmentation/view",
        groupName: "marketing",
      },
      {
        name: "ClientsSegmentation:Edit",
        isDisabled: false,
        route: "/home/clients-segmentation/view",
      },
      {
        name: "ClientsSegmentation:edit-clients-segmentation",
        isDisabled: true,
        route: "/home/clients-segmentation/view/edit",
      },
    ],
  },
];
