
import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ButtonBase from '@material-ui/core/ButtonBase';
import { GetAllPermissionsByModuleId } from '../../../../../../../../../Services';
import {
  CheckboxesComponent,
  CollapseComponent,
  Spinner,
} from '../../../../../../../../../Components';
import { GlobalTranslate } from '../../../../../../../../../Helper';

export const RolesPermissionsComponent = ({
  rolesId,
  state,
  onStateChanged,
  componentItem,
  activePermission,
  onActivePermissionChanged,
  accessTypes,
  index,
  SelectAll,
  setIsSelectedAll,
  setlistOfExtend,
  listOfExtend,
}) => {

  const [isExtended, setIsExtended] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSelectAll, setisSelectAll] = useState([]);
  const [isFirst, setIsFirst] = useState(false);

  const [permissions, setPermissions] = useState({
    result: [],
    totalCount: 0,
  });

  

  const [filter] = useState({
    pageIndex: 1,
    pageSize: 4,
    extendedPageSize: 999999,
  });
  const getAllPermissionsByComponentsId = useCallback(async () => {
    setIsLoading(true);
    const res = await GetAllPermissionsByModuleId(
      componentItem.moduleId,
      filter.pageIndex,
      (!isFirst && filter.extendedPageSize) || (filter.extendedPageSize)
    );
    if (!(res && res.data && res.data.ErrorId) && res && res.result) {

      setPermissions(res);

    }
    else {
      setPermissions({
        result: [],
        totalCount: 0,
      });
    }
    setIsFirst(true);
    setIsLoading(false);
  }, [componentItem, filter]);

  const getIsSelectedPermission = useCallback(
    (permission) =>
      state.permissions &&
      state.permissions.findIndex((item) => item.permissionsId === permission.permissionsId) !== -1,
    [state]
  );
  const getPermissionClassName = useCallback(
    (permission) => {
      const permissionIndex =
        state.permissions &&
        state.permissions.findIndex((item) => item.permissionsId === permission.permissionsId);
      if (permissionIndex >= 0) {
        const accessType = accessTypes.find(
          (item) => item.accessTypesId === state.permissions[permissionIndex].accessTypesId
        );
        return (accessType && accessType.color) || undefined;
      }

      return undefined;
    },
    [accessTypes, state.permissions]
  );
  const onSelectedCheckboxChanged = useCallback(
    (permission) => (event) => {
      let  localPermissions = [...state.permissions];
      if (event.target.checked) {
        localPermissions.push({
          accessTypesId: 3,
          permissionsId: permission.permissionsId,
          rolesId,
        });
        if (onStateChanged) onStateChanged({ id: 'permissions', value: localPermissions });
        if (onActivePermissionChanged) {
          onActivePermissionChanged({
            id: permission.permissionsId,
            name: permission.permissionsName,
          });
        }
      } else {
        const permissionIndex = localPermissions.findIndex(
          (item) => item.permissionsId === permission.permissionsId
        );
        if (permissionIndex !== -1) {
          localPermissions.splice(permissionIndex, 1);
          if (onStateChanged) onStateChanged({ id: 'permissions', value: localPermissions });
          if (
            onActivePermissionChanged &&
            activePermission &&
            activePermission.id === permission.permissionsId
          )
            onActivePermissionChanged(null);
        }
         let list = [...SelectAll];
        list[index] = false;
        setIsSelectedAll(list);
      }
    },
    [activePermission, onActivePermissionChanged, onStateChanged, rolesId, state.permissions]
  );

  useEffect(() => {
    var devPermissions = permissions.result.filter(item1 =>
      state.permissions.some(item2 => (item2.permissionsId === item1.permissionsId)));
    let checkedList = [...SelectAll];
    if (index !== -1 && (devPermissions.length !== 0) && (devPermissions.length === 4 || (devPermissions.length === permissions.totalCount))) {
      checkedList[index] = true;
    }
    else
      checkedList[index] = false;

    setIsSelectedAll(checkedList);

  }, [permissions.result]);


  function checkAvailability(arr, val) {
    return arr.some(function (arrVal) {
        return val.permissionsId === arrVal.permissionsId;
    });
}



  const selectAllChecked = useCallback(
    (selectAllPermissions) => {
      if (index !== -1) {
        let localPermissions = [...state.permissions];
        if(selectAllPermissions)
        { 
          // this case when check select all 
            permissions.result.map((permissionsItem) => {
             if(!checkAvailability(localPermissions,permissionsItem))
             {
              localPermissions.push({
                accessTypesId: 3,
                permissionsId: permissionsItem.permissionsId,
                rolesId,
             });
             }
            
         });
        }
        else  
        {
           // this case when unselect all 
          permissions.result.map((permissionsItem) => 
          {
             const localPermissionsIndex = localPermissions.indexOf(
              localPermissions.find(
                (f) => f.permissionsId === permissionsItem.permissionsId
              )
            );
            if(localPermissionsIndex !== -1)
            localPermissions.splice(localPermissionsIndex , 1 );     
           });
        }

        if (onStateChanged) onStateChanged({ id: 'permissions', value: localPermissions });

      }

    },
    [permissions.result, SelectAll , state.permissions]
  );

  useEffect(() => {
    if (componentItem)
      getAllPermissionsByComponentsId();
    if (isExtended)
      getAllPermissionsByComponentsId();

  }, [componentItem, isExtended, getAllPermissionsByComponentsId]);
  return (
    <div className='roles-permissions-wrapper childs-wrapper'>
      <Spinner isActive={isLoading} isAbsolute isWithoutText isSmall />
      <div className='roles-permission-items-wrapper'>
        <span className='title-text px-2'>
          {(componentItem && componentItem.moduleName) || 'N/A'}

        </span>
        <CheckboxesComponent
          idRef={`Checkboxesntref${index}`}
          label={GlobalTranslate.t('Shared:select-all')}
          singleChecked={(index !== -1 && SelectAll && SelectAll[index]) || false}
          themeClass='theme-secondary'
          onSelectedCheckboxClicked={(event) => {
            event.preventDefault();
            const list = [...SelectAll];
            list[index] = (index !== -1 && !(SelectAll[index])) || false;
            setIsSelectedAll(list);
            selectAllChecked(list[index]);
          }}
        />
        {permissions.result &&
          permissions.result
            .filter((item, index) => index < filter.pageSize)
            .map((item, index) => (
              <div
                className='roles-permission-item'
                key={`permissionCheckboxKey${componentItem.moduleId}${index + 1}`}
              >
                <CheckboxesComponent
                  idRef={`permissionCheckboxRef${index + 1}`}
                  singleChecked={getIsSelectedPermission(item)}
                  label={item.permissionsName}
                  checkboxClasses={getPermissionClassName(item)}
                  onSelectedCheckboxChanged={onSelectedCheckboxChanged(item)}
                  title={ item.description || ''}
                />
              </div>
            ))}
      </div>
      <CollapseComponent
        isOpen={index !== -1 && listOfExtend[index] && permissions.result.length > filter.pageSize}
        classes='roles-permission-extended-items-wrapper w-100'
        component={(
          <>
            {permissions.result &&
              permissions.result
                .filter((item, index) => index >= filter.pageSize)
                .map((item, index) => (
                  <div
                    className='roles-permission-item'
                    key={`permissionCheckboxKey${componentItem.moduleId}${index + 1}`}
                  >
                    <CheckboxesComponent
                      idRef={`permissionCheckboxRef${index + 1}`}
                      singleChecked={getIsSelectedPermission(item)}
                      label={item.permissionsName}
                      checkboxClasses={getPermissionClassName(item)}
                      onSelectedCheckboxChanged={onSelectedCheckboxChanged(item)}
                      title={item.description || ''}
                    />
                  </div>
                ))}
          </>
        )}
      />
      {permissions.totalCount > filter.pageSize && (
        <ButtonBase
          className='btns-icon theme-solid expanding-btn'
          onClick={() => {
           // setIsExtended((item) => !item);
            let list = [...listOfExtend];
            list[index] = !(index !== -1 && (list[index]));
            setlistOfExtend(list);
          }}
        >
          <span
            className={`mdi mdi-chevron-${(index !== -1 && listOfExtend[index] && permissions.result.length > filter.pageSize && 'up') || 'down'
              }`}
          />
        </ButtonBase>
      )}
    </div>
  );
};

RolesPermissionsComponent.propTypes = {
  rolesId: PropTypes.string,
  state: PropTypes.instanceOf(Object).isRequired,
  onStateChanged: PropTypes.func.isRequired,
  componentItem: PropTypes.instanceOf(Object).isRequired,
  index: PropTypes.number.isRequired,
  activePermission: PropTypes.shape({ id: PropTypes.string, name: PropTypes.string }),
  onActivePermissionChanged: PropTypes.func.isRequired,
  accessTypes: PropTypes.arrayOf(
    PropTypes.shape({
      accessTypesId: PropTypes.number,
      accessTypesName: PropTypes.string,
      color: PropTypes.string,
    })
  ).isRequired,
};
RolesPermissionsComponent.defaultProps = {
  activePermission: undefined,
  rolesId: null,
};
