import React, { useState } from "react";
import Joi from "joi";
import { Button } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  showSuccess,
  showError,
  getErrorByName,
  getTranslate,
} from "../../../../Helper";
import { LOGIN } from "../../../../store/login/Actions";
import { Inputs } from "../../../../Components";
import arrowLeft from "../../../../assets/images/icons/arrow-left.png";
import { ApplicationUserResetPassword } from "../../../../Services/LoginService";
import {
  atLeastOneNumber,
  charactersLength,
  containUpper,
  containSpecialCharacter,
} from "../../../../Utils/Expressions";
import { LOGOUt } from "../../../../store/login/Actions";
import propxLogin from "../../../../assets/images/pages/login/propx-login.svg";
import { LoginFormsEnum } from './LoginFormsEnum';

export const ChangePasswordForm = ({
  setActiveLoginForm,
  userVerificationInfo,
  translationPath,
  parentTranslationPath,
}) => {
  const { t } = useTranslation(parentTranslationPath);
  const dispatch = useDispatch(LOGIN);

  const [newPasswords, setNewPasswords] = useState({
    ViewNewassword: "",
    ViewconfirmPassword: "",
  });
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const schema = Joi.object({
    newPassword: Joi.string()
      .required()
      .regex(atLeastOneNumber)
      .regex(containSpecialCharacter)
      .regex(containUpper)
      .regex(charactersLength)
      .messages({
        "string.empty": t(`${translationPath}password-is-required`),
        "string.pattern.base": t(`${translationPath}weak-password`),
      }),
    confirmPassword: Joi.string()
      .valid(Joi.ref("newPassword"))
      .required()
      .messages({
        "any.only": t(`${translationPath}password-not-matched`),
        "string.empty": t(`${translationPath}confirm-password-is-required`),
      }),
  })
    .options({
      abortEarly: false,
    })
    .validate({ newPassword, confirmPassword });
  const handleResetPassword = async (event) => {
    event.preventDefault();
    if (schema.error) {
      showError(getTranslate()("shared.please-fix-all-errors"));
      return;
    }
    const res = await ApplicationUserResetPassword({
      identityVerificationId: +userVerificationInfo.identityVerificationId,
      newPassword,
    });
    if (!(res && res.status && res.status !== 200)) {
      localStorage.removeItem("session");
      dispatch(LOGOUt());
      setActiveLoginForm(LoginFormsEnum.SignIn.key);
      showSuccess(t(`${translationPath}change-password-Succssfuly`));
    } else showError(t(`${translationPath}failed-change-password`));
  };
  return (
    <div className="login-form-container">
      <div className="login-header">
        <img src={propxLogin} />
      </div>
      <h1 className="heading-1">{t(`${translationPath}Change-Password`)}</h1>
      <div className="login-form">
        <form
          noValidate
          className="form-wrapper"
          onSubmit={handleResetPassword}
        >
          <div>
            <Inputs
              idRef="newPassword"
              type={!newPasswords.ViewNewassword ? "password" : ""}
              labelValue={t(`${translationPath}new-password`)}
              inputPlaceholder={t(`${translationPath}new-password`)}
              helperText={getErrorByName(schema, "newPassword").message}
              error={getErrorByName(schema, "newPassword").error}
              isWithError
              value={newPassword}
              endAdornment={
                <Button
                  onClick={() => {
                    setNewPasswords({
                      ...newPasswords,
                      ViewNewassword: !newPasswords.ViewNewassword,
                    });
                  }}
                  className="p-2"
                >
                  {newPasswords.ViewNewassword ? (
                    <span className="mdi mdi-eye-outline" />
                  ) : (
                    <span className="mdi mdi-eye-off-outline" />
                  )}
                </Button>
              }
              onInputChanged={(e) => setNewPassword(e.target.value)}
            />
          </div>
          <div>
            <Inputs
              idRef="confirmPassword"
              type={!newPasswords.ViewconfirmPassword ? "password" : ""}
              labelValue={t(`${translationPath}confirmPassword`)}
              inputPlaceholder={t(`${translationPath}confirmPassword`)}
              helperText={getErrorByName(schema, "confirmPassword").message}
              error={getErrorByName(schema, "confirmPassword").error}
              isWithError
              value={confirmPassword}
              endAdornment={
                <Button
                  onClick={() => {
                    setNewPasswords({
                      ...newPasswords,
                      ViewconfirmPassword: !newPasswords.ViewconfirmPassword,
                    });
                  }}
                  className="p-2"
                >
                  {newPasswords.ViewconfirmPassword ? (
                    <span className="mdi mdi-eye-outline" />
                  ) : (
                    <span className="mdi mdi-eye-off-outline" />
                  )}
                </Button>
              }
              onInputChanged={(e) => {
                setConfirmPassword(e.target.value);
              }}
            />
          </div>
          <div className="password-validation  my-3">
            <span
              className={
                atLeastOneNumber.test(newPassword)
                  ? "mdi mdi-check my-2"
                  : "mdi mdi-information-outline my-2"
              }
            >
              {t(`${translationPath}one-number`)}
            </span>
            <span
              className={
                charactersLength.test(newPassword)
                  ? "mdi mdi-check my-2 "
                  : "mdi mdi-information-outline my-2"
              }
            >
              {" "}
              {t(`${translationPath}8-20-characters`)}
            </span>
            <span
              className={
                containUpper.test(newPassword)
                  ? "mdi mdi-check my-2"
                  : "mdi mdi-information-outline my-2"
              }
            >
              {t(`${translationPath}one-uppercase-letter`)}
            </span>
            <span
              className={
                containSpecialCharacter.test(newPassword)
                  ? "mdi mdi-check my-2"
                  : "mdi mdi-information-outline my-2"
              }
            >
              {t(`${translationPath}special-character(!@#$%^&*)`)}
            </span>
          </div>
          <div className="d-flex-column-center">
            <Button className="login-btn" type="submit">
              {<span>{t(`${translationPath}change-password`)}</span>}
            </Button>
          </div>
          <div
            className="back-btn d-flex-center my-3P5"
            onClick={() => setActiveLoginForm(LoginFormsEnum.SignIn.key)}
          >
            <img src={arrowLeft} className="mr-1" />
            <span>{t(`${translationPath}back-to-login`)}</span>
          </div>
        </form>
      </div>
    </div>
  );
};
