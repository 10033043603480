import React, {  useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  UnitStatusFilter,
  UnitTypeFilter,
  ListingAgentFilter,
  CompletionStatusFilter,
  SortByFilter,
  UnitRefNumberFilter,
  UnitNumberFilter,
  PriceFilter,
  SizeFilter,
  PropertiesFilter,
  BedsAndBathFilter,
} from '../ResaleUnitsFilters';
import '../ResaleUnitsViewStyles.scss';
import { MoreFiltersDialog } from './MoreFiltersDialog';
import { CustomButton } from './utilities';

export const ResaleUnitsFilterComponent = ({
  isClearFiltersClicked,
  setResaleFilters,
  resaleFilters,
  resaleChangeFilters,
  setSortByFilter,
  setOrderBy,
  orderBy,
  setIsClearSortBy,
  isClearSortBy,
  setSelected,
  selected,
  selectedDefault,
  restBedAndBath,
  parentTranslationPath,
  translationPath,
  detailsUnitsList,
  filterBadsAndBaths,
  PriceAndSizeChangeFilters
}) => {
  const { t } = useTranslation(parentTranslationPath);
  const [openFiltersDialog, setOpenFiltersDialog] = useState(false);

  const handleClick = () => {
    setOpenFiltersDialog(true);
  };

  useEffect(() => {
    if (isClearFiltersClicked && isClearFiltersClicked) {
      setSelected({ id: 'edit', value: selectedDefault });
    }
  }, [isClearFiltersClicked]);

  useEffect(() => {
    setSelected({ id: 'sortBy', value: null });
  }, [isClearSortBy]);

  return (
    <>
      <div className='px-4 d-flex-column-center mb-3 resale-filters'>
        <div className='px-4 d-flex-center'>
          <UnitStatusFilter
            setResaleFilters={setResaleFilters}
            resaleFilters={resaleFilters}
            resaleChangeFilters={resaleChangeFilters}
            parentTranslationPath={parentTranslationPath}
            translationPath={translationPath}
            translationPathForData={translationPath}
            selected={selected}
            setSelected={setSelected}
          />
          <UnitTypeFilter
            setResaleFilters={setResaleFilters}
            resaleFilters={resaleFilters}
            resaleChangeFilters={resaleChangeFilters}
            selected={selected}
            setSelected={setSelected}
          />

          <CompletionStatusFilter
            setResaleFilters={setResaleFilters}
            resaleFilters={resaleFilters}
            resaleChangeFilters={resaleChangeFilters}
            selected={selected}
            setSelected={setSelected}
          />
          <UnitRefNumberFilter
            setResaleFilters={setResaleFilters}
            resaleFilters={resaleFilters}
            resaleChangeFilters={resaleChangeFilters}
            parentTranslationPath={parentTranslationPath}
            translationPath={translationPath}
            selected={selected}
            setSelected={setSelected}
          />
        </div>
        <div className='px-5 d-flex-center fas-start gap-1'>
          <BedsAndBathFilter
            parentTranslationPath={parentTranslationPath}
            translationPath={translationPath}
            selected={selected}
            setSelected={setSelected}
            restBedAndBath={restBedAndBath}
            filterBadsAndBaths ={filterBadsAndBaths}
          />

          <CustomButton className='custom-filter-button' onClick={handleClick}>
            <span className='mdi mdi-filter-outline' />
            <span className='more-filters'>
              {t(`${translationPath}more-filters`)}
            </span>
          </CustomButton>
        </div>
      </div>
      {openFiltersDialog && (
        <MoreFiltersDialog
          isOpen={openFiltersDialog}
          SortByFilter={SortByFilter}
          UnitRefNumberFilter={UnitRefNumberFilter}
          ListingAgentFilter={ListingAgentFilter}
          UnitNumberFilter={UnitNumberFilter}
          PriceFilter={PriceFilter}
          SizeFilter={SizeFilter}
          PropertiesFilter={PropertiesFilter}
          setSortByFilter={setSortByFilter}
          setOrderBy={setOrderBy}
          orderBy={orderBy}
          setIsClearSortBy={setIsClearSortBy}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          setResaleFilters={setResaleFilters}
          resaleFilters={resaleFilters}
          resaleChangeFilters={resaleChangeFilters}
          translationPathForData={translationPath}
          selected={selected}
          setSelected={setSelected}
          detailsUnitsList={detailsUnitsList}
          PriceAndSizeChangeFilters={PriceAndSizeChangeFilters}
          isClose={() => setOpenFiltersDialog(false)}
        />
      )}
    </>
  );
};
