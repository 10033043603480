import React from "react";

function MoreVertIcon({ fill, ...restProps }) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill={fill ?? ""}
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <path
        d="M9.717 2.537c-.475.068-.973.445-1.199.906a1.652 1.652 0 0 0 .759 2.206c1.187.581 2.538-.407 2.356-1.724-.096-.695-.691-1.294-1.383-1.391-.262-.037-.258-.037-.533.003m0 5.833c-.475.068-.973.445-1.199.907a1.65 1.65 0 0 0 .759 2.205c1.187.581 2.538-.407 2.356-1.724-.096-.695-.691-1.293-1.383-1.391-.262-.036-.258-.036-.533.003m0 5.834c-.475.068-.973.445-1.199.906a1.652 1.652 0 0 0 .759 2.206c1.187.581 2.538-.408 2.356-1.724-.096-.695-.691-1.294-1.383-1.391-.262-.037-.258-.037-.533.003"
        fill={fill ?? ""}
      />
    </svg>
  );
}

export default MoreVertIcon;
