
import React, { useState, useCallback, useReducer, useEffect } from 'react';
import { DialogActions, DialogContent, DialogTitle, Dialog, ButtonBase } from '@material-ui/core';
import { useTranslation } from 'react-i18next'; 
import { showError, showSuccess, GetParams  , getErrorByName  } from '../../../../../../../Helper';
import { UnitTypes } from '../../../../../../../Enums' ; 
import {
  Spinner , AutocompleteComponent 
} from '../../../../../../../Components';
import { lookupItemsGetId, CreatePropertyUnitType } from '../../../../../../../Services';

export const AddPropertyUnitType = ({
  parentTranslationPath,
  translationPath,
  isDialogOpen,
  onClose,
  onSave,
  propertyUnitTypes ,
}) => {
  const { t } = useTranslation(parentTranslationPath);

  const reducer = useCallback((state, action) => {
    if (action.id !== 'edit') return { ...state, [action.id]: action.value };
    return {
      ...action.value,
    };
  }, []);

  const [isSubmitted, setIsSubmitted] = useState(false);
  const [newPropertyUnitTypes , setNewPropertyUnitTypes] = useState([]); 

  const [isLoading , setIsLoading] = useState({
    viewData :false , 
    saveUnitModel : false , 
  }); 

  const [state, setState] = useReducer(reducer, {
    propertyId: +GetParams('id'),
    unitTypesInProperty : [] , 
  });

  const [unitTypesInPropertyList , setUnitTypesInProperty ] = useState([]) ; 

  const getViewsLookups = useCallback(async () => {
    setIsLoading((item) => ({ ...item, viewData : true   }));
    const res = await lookupItemsGetId({lookupTypeId : (UnitTypes.lookupTypeId)});
    if (!(res && res.status && res.status !== 200)) {
        setUnitTypesInProperty(res);
    } else setUnitTypesInProperty([]);

    setIsLoading((item) => ({ ...item, viewData :false   }));
  }, []);


  const saveHandler = async (event) => {
     event.preventDefault();
     setIsLoading((item) => ({ ...item, saveUnitModel :true }));

    const res = await CreatePropertyUnitType({propertyId : +GetParams('id')  , propertyUnitType : (newPropertyUnitTypes)});
    if (!(res && res.status && res.status !== 200)) {
      showSuccess(t(`${translationPath}${`PropertyUnitTypes-created-successfully`}`));
      onSave();
      onClose(); 
    } else 
    {
      showError(t(`${translationPath}${`PropertyUnitTypes-created-failed`}`) );
    }
            
   setIsLoading((item) => ({ ...item, saveUnitModel :false   }));
  };

  useEffect(() => 
  {
     setNewPropertyUnitTypes(propertyUnitTypes ? [...propertyUnitTypes.result] : [])
  }, [propertyUnitTypes]);

  useEffect(() => 
  {
    getViewsLookups();
  }, [getViewsLookups]);


  return (
    <div>
      <Dialog
        open={isDialogOpen}
        onClose={onClose}
        className='activities-management-dialog-wrapper'>
        <Spinner isActive={(isLoading.saveUnitModel || isLoading.viewData)} isAbsolute />
        <form noValidate onSubmit={saveHandler}>
          <DialogTitle id='alert-dialog-slide-title'>
            {t(
              `${translationPath}${'add-unit-Types-in-property'}`
            )}
          </DialogTitle>

          <DialogContent>
          <div className='dialog-content-item'>
             <AutocompleteComponent
                idRef='PropertyUnitTypesRef'
                labelValue='PropertyUnitTypes'
                multiple
                isLoading={isLoading.viewData}
                value={(newPropertyUnitTypes && newPropertyUnitTypes) ||[] }
                selectedValues={(newPropertyUnitTypes && newPropertyUnitTypes)|| []}
                data={(unitTypesInPropertyList) || []}
                chipsLabel={(option) => (option && option.lookupItemName) || ''}
                displayLabel={(option) => (option && option.lookupItemName) || ''}
                withoutSearchButton
                parentTranslationPath={parentTranslationPath}
                translationPath={translationPath}
                onChange={(event, newValue) => 
                {
                  setNewPropertyUnitTypes(newValue); 
                       
                }}
                
                getOptionSelected={(option) => newPropertyUnitTypes &&  newPropertyUnitTypes.findIndex((item) => item.lookupItemId === option.lookupItemId) !== -1 || ''}
             />
           </div>  
          </DialogContent>
          <DialogActions>
            <ButtonBase onClick={onClose} className='btns theme-solid bg-cancel'>
              {t(`${translationPath}cancel`)}
            </ButtonBase>
            <ButtonBase className='btns theme-solid' onClick={saveHandler} type='submit'>
              {t(`${translationPath}save`)}
            </ButtonBase>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
};
