import React from 'react';
import { Accordion } from './Accordion';

export const AccordionComponent = ({
  data,
  accordionHeaderText
}) => {

  return (
    <div className="accordion-component-wrapper">
      <p className='accordion-header'>{accordionHeaderText}</p>
      <div>
        {data.map(({ title, content, id }) => (
          <Accordion title={title} content={content} key={id} />
        ))}
      </div>
    </div>
  );
};
