


import { HttpServices } from '../../Helper';
import { config } from '../../config';


export const GetAllPropertyRatingTabScores = async () => {
    const result = await HttpServices.get(
        `${config.server_address}/CrmDfm/PropertyRating/GetAllPropertyRatingTabScores`)
        .then((data) => data)
        .catch((error) => error.response);
    return result;
  };
  
  export const UpdatePropertyRatingTabScores = async (body) => {
    const result = await HttpServices.put(
        `${config.server_address}/CrmDfm/PropertyRating/UpdatePropertyRatingTabScores`, body
    )
        .then((data) => data)
        .catch((error) => error.response.data);
    return result;
};








