import React, { useEffect, useCallback, useState, useReducer, useRef } from 'react';
import moment from 'moment';
import { uploadFile, UpdateProductRelease, AddProductRelease } from '../../../../Services';
import { ReleaseNoteForm } from './ReleaseNoteForm';
import { showError, showSuccess } from '../../../../Helper';
import SunEditor from 'suneditor-react';
import {
  align,
  font,
  fontColor,
  fontSize,
  formatBlock,
  hiliteColor,
  horizontalRule,
  lineHeight,
  list,
  paragraphStyle,
  table,
  template,
  textStyle,
  image,
  link,
  blockquote,
  video,
  audio,
  math,
  imageGallery,
} from 'suneditor/src/plugins';
import { SelectComponet, Inputs, DatePickerComponent } from '../../../../Components';
import { ReleaseProductTypes } from '../../../../Enums';
import { GetLookupItemsByLookupTypeName } from '../../../../Services';
import { getPublicDownloadableLink } from '../../../../Helper';

export const ReleaseProductManagementForm = ({ 
   reloadData,
   isEditForm,
   activeItem,
   setActiveItem,
   activeItemReleaseNotes,
   releaseNoteLookups,
   getAllReleaseNotes
   }) => {
  const editor = useRef();
  const [content, setContent] = useState('');
  const [isProductReleaseCreated, setIsProductReleaseAdded] = useState(false);
  const getSunEditorInstance = (sunEditor) => {
    editor.current = sunEditor;
  };
  const reducer = useCallback((state, action) => {
    if (action.id !== 'edit') return { ...state, [action.id]: action.value };
    return {
      ...action.value,
    };
  }, []);

  const initialState = {
    versionName: '',
    versionNumber: '',
    productTypeId: null,
    releaseDate: moment().format('YYYY-MM-DDTHH:mm:ss'),
    summary: '',
    description: '',
  }
  const [state, setState] = useReducer(reducer, initialState);
  const [localReleaseNoteLookups, setLocalReleaseNoteLookups] = useState([]);
  const [localReleaseNotes, setLocalReleaseNotes] = useState([]);
//WHY? making localReleaseNotes? you dont need it right?
  const [focsee, setfocsee] = useState(false);
  const onContentChanged = (newValue) => {
    setState({ id: 'description', value: newValue });
  };

  const cancelClickHandler = () => {
    const isPointOnFirst = true;
    resetProductReleaseForm();
    reloadData(isPointOnFirst);
  };


  const resetProductReleaseForm = () => {
    setState({
      id: 'edit',
      value: initialState
    });
    onContentChanged('');
    setContent('');
  };

  const addProductRelease = useCallback(async () => {
    const body = {
      ...state,
    };
    const res = await AddProductRelease(body);
    if (!(res && res.status && res.status !== 200)) {
      setActiveItem(res);
      reloadData();
      setIsProductReleaseAdded(true);
      showSuccess('Product Release Added Successfully')
    }else {
      showError('Adding Product Release Has Failed')
    }
  }, [state]);


  const handleImageUploadBefore = (files, info, uploadHandler) => {
    Object.values(files).map(async (image) => {
      const response = await uploadFile({
        file: image,
      });
      const responseeeee = {
        result: [
          {
            url: getPublicDownloadableLink(response.uuid),
            name: response.fileName,
            size: files[0].size,
          },
        ],
      };
      uploadHandler(responseeeee);
    });
  };


  const updateProductRelease = useCallback(async () => {
    const productReleaseId  = activeItem?.productReleaseId;

    const body = {
      ...state,
    };
    const res = await UpdateProductRelease(body, productReleaseId );
    if (!(res && res.status && res.status !== 200)) {
        const releaseDescription = state.description || '';
        reloadData();
        setContent(releaseDescription);
        showSuccess('Product Release Updated Successfully')
      }else {
        showError('Updating Product Release Has Failed')
      }
  }, [state, activeItem]);




  useEffect(() => {
    if (isEditForm && activeItem) {
      setContent(activeItem.description);

      setState({
        id: 'edit',
        value: {
          versionName: activeItem.versionName,
          versionNumber: activeItem.versionNumber,
          productTypeId: activeItem.productTypeDto?.productTypeId,
          releaseDate: null,
          releaseDate: activeItem.releaseDate || moment().format('YYYY-MM-DDTHH:mm:ss'),
          summary: activeItem.summary,
          description: activeItem.description,
        }
      });
    }
  }, [isEditForm, activeItem]);

  useEffect(()=>{
    if(releaseNoteLookups &&
     releaseNoteLookups.length > 0) setLocalReleaseNoteLookups(releaseNoteLookups)
  }, [releaseNoteLookups]);

  useEffect(()=>{
    if(activeItemReleaseNotes) setLocalReleaseNotes(activeItemReleaseNotes)
  }, [activeItemReleaseNotes]);

  return (
    <div className='release-note-managament-form'>
      <div className='release-form-wrapper'>
        {isEditForm ? (
          <p className='title'>Edit New Product Release</p>
        ) : (
          <p className='title'>Add New Product Release</p>
        )}
        <div className='product-form-container'>
          <div className='form-three-inputs'>
            <Inputs
              idRef='ProductNameRef'
              labelValue='Product Name'
              value={state.versionName}
              onInputChanged={(event) => {
                setState({ id: 'versionName', value: event.target.value });
              }}
            />

            <SelectComponet
              idRef='ProductTypeIdRef'
              data={ReleaseProductTypes}
              value={state.productTypeId}
              valueInput='key'
              textInput='value'
              labelValue={'Product Type'}
              onSelectChanged={(newValue) => {
                setState({ id: 'productTypeId', value: newValue });
              }}
              wrapperClasses='over-input-select w-auto'
            />
            <DatePickerComponent
              idRef='RealeaseDateRef'
              labelValue='Realease Date'
              value={state.releaseDate || moment().format('YYYY-MM-DDTHH:mm:ss')}
              placeholder='Select Date'
              onDateChanged={(newValue) => {
                setState({
                  id: 'releaseDate',
                  value:
                  (newValue && moment(newValue).format('YYYY-MM-DDTHH:mm:ss')) ||
                  moment().format('YYYY-MM-DDTHH:mm:ss'),
              });
              }}
            />
          </div>

          <div className='form-two-inputs'>
            <Inputs
              idRef='VersionNumberRef'
              labelValue='Version Number'
              value={state.versionNumber}
              onInputChanged={(event) => {
                setState({ id: 'versionNumber', value: event.target.value });
              }}
            />

            <Inputs
              idRef='ReleaseSummeryRef'
              labelValue='Release Summary'
              value={state.summary}
              onInputChanged={(event) => {
                setState({ id: 'summary', value: event.target.value });
              }}
            />
          </div>

          <div className='release-note-sunEditor' onMouseLeave={() => onContentChanged(content)}>
            <SunEditor
              getSunEditorInstance={getSunEditorInstance}
              idRef='refs'
              onImageUploadBefore={handleImageUploadBefore}
              onFocus={() => {
                setfocsee(true);
              }}
              setOptions={{
                showPathLabel: false,
                minHeight: '180px',
                maxHeight: '180px',
                placeholder: 'Enter text',
                plugins: [
                  align,
                  font,
                  fontColor,
                  fontSize,
                  formatBlock,
                  hiliteColor,
                  horizontalRule,
                  lineHeight,
                  list,
                  paragraphStyle,
                  table,
                  template,
                  textStyle,
                  image,
                  link,
                  blockquote,
                  video,
                  audio,
                  math,
                  imageGallery,
                ],
                buttonList: [['bold', 'italic', 'link', 'align', 'list']],
              }}
              setContents={content}
              onChange={(event) => {
                setContent(event);
              }}
            />
          </div>

          <div className='form-btns'>
            <button className='outlined-btn' onClick={cancelClickHandler}>
              Cancel
            </button>
            {isEditForm ? (
              <button className='primary-btn' onClick={updateProductRelease}>
                Edit
              </button>
            ) : (
              <button className='primary-btn' onClick={addProductRelease} disabled={isProductReleaseCreated}>
                Create
              </button>
            )}
          </div>
        </div>

        {localReleaseNoteLookups &&
          localReleaseNoteLookups.map((item) => (
            <ReleaseNoteForm
              localReleaseNotes={localReleaseNotes}
              setLocalReleaseNotes={setLocalReleaseNotes}
              localReleaseNoteLookups={localReleaseNoteLookups}
              setLocalReleaseNoteLookups={setLocalReleaseNoteLookups}
              activeNoteTypeLookup={item}
              isDisabled={(!isProductReleaseCreated && !isEditForm)}
              activeItem={activeItem}
              getAllReleaseNotes={getAllReleaseNotes}
            />
          ))}
      </div>
    </div>
  );
};
