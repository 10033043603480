
import React , {useRef } from 'react';
import { Inputs } from '../../../../Components' ; 
import { useTranslation } from 'react-i18next';


export const LeadsFilter = ({
    translationPath,
    parentTranslationPath,
    filter,
    setFilter , 
    selected , 
    setSelected , 
}) => {
    const { t } = useTranslation(parentTranslationPath);
    const searchTimer = useRef(null);
    
    return (
      <>
        <div className='d-inline-flex'>
         <div className='item-filter mr-2 ml-2'>
             <Inputs
               idRef='leadsNameRef'
               inputPlaceholder={t('leadName')}
               value={(selected && selected.leadName)}
               parentTranslationPath={parentTranslationPath}
               translationPath={translationPath}
               onKeyUp={(e)=> {
                const { value } = e.target;
                if (searchTimer.current) clearTimeout(searchTimer.current);
                  searchTimer.current = setTimeout(() => {
                  setFilter((item) => ({ ...item, leadName: value }));
                 }, 700);
               }}
               onInputChanged={(e)=> 
                {
                  setSelected({ id: 'leadName', value:  e.target.value }); 

               }}
            />  
            </div>
            <div className='item-filter mr-2 ml-2'>
              <Inputs
               idRef='leadIdRef'
               inputPlaceholder={t('leadId')}
               value={(selected && selected.leadId)}
               parentTranslationPath={parentTranslationPath}
               translationPath={translationPath}
               onKeyUp={(e)=> {
                const { value } = e.target;
                if (searchTimer.current) clearTimeout(searchTimer.current);
                  searchTimer.current = setTimeout(() => {
                  setFilter((item) => ({ ...item, leadId: value }));
                 }, 700);
               }}
               onInputChanged={(e)=> 
                {
                  setSelected({ id: 'leadId', value:  e.target.value }); 

               }} 
              />  
              </div>
            </div>
         </>
    );
};
