import React, { useCallback, useEffect, useState, useReducer } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { ButtonBase } from '@material-ui/core';
import { useLocation } from "react-router-dom";
import { GetParams, showError, showSuccess } from '../../../../../../../Helper';
import { DialogComponent, Spinner } from '../../../../../../../Components';
import {
  GetAllPropertyVideosCategoryByPropertyId,
  UpdatePropertyVideo
} from '../../../../../../../Services';
import { VideosGalleryCardComponent } from '../../../../../ImagesGallery/Sections/VideosGalleryComponent/VideosGalleryCardComponent/VideosGalleryCardComponent';
import { UploaderThemesEnum, PropertyImagesType } from '../../../../../../../Enums';

import { UnitProfileImagesCardActionEnum } from '../../../../../../../Enums';
import { VideoDialogsProperties } from './VideoDialogsProperties';
import { PropertyVideoCategory } from '../../../../../../../assets/json/StaticLookupsNames.json';

export const PropertiesVideos = ({
  parentTranslationPath,
  translationPath,
  activeTab
}) => {
  const activeTabName = activeTab === 0 ? 'images' : 'videos';
  const initialStateImage = {
    categoryId: null,
    propertyId: +GetParams('id') || null,
    imageType: PropertyImagesType.Image,
    files: [],
  };
  const initialStateVideo = {
    categoryId: null,
    propertyId: +GetParams('id') || null,
    files: [],
  };
  const reducer = useCallback((state, action) => {
    if (action.id !== 'edit') return { ...state, [action.id]: action.value };
    return {
      ...action.value,
    };
  }, []);
  const [isLoading, setIsLoading] = useState(false);
  const [isCategoryExist, setIsCategoryExist] = useState(false);

  const [allPropertyVideo, setAllPropertyVideo] = useState([]);
  const { t } = useTranslation(parentTranslationPath);
  const [isOpenDialog, setIsOpenDialog] = useState(false);
  const [activeItem, setActiveItem] = useState(null);
  const [hideIcon, setHideIcon] = useState(false);
  const [selected, setSelected] = useState({ lookupItemName: '', lookupItemId: '' });
  const location = useLocation();
  const [state, setState] = useReducer(reducer, activeTabName === 'images' ? initialStateImage : initialStateVideo);

  const getAllPropertyVideosCategoryByPropertyId = useCallback(async (propertyId) => {
    setIsLoading(true);
    const result = await GetAllPropertyVideosCategoryByPropertyId(propertyId);
    if (!(result && result.status && result.status !== 200)) {
      setAllPropertyVideo(result || []);
      setHideIcon(true);

    }
    else setAllPropertyVideo([]);
    setIsLoading(false);
  }, []);

  const updatePropertyVideo = useCallback(async () => {
    setIsLoading(true);
    const res = await UpdatePropertyVideo(state)
    if (!(res && res.status && res.status !== 200)) {
      showSuccess(activeItem || isCategoryExist ? t(`${translationPath}UpdatePropertyVideo-successfully`) : t(`${translationPath}CreatePropertyVideo-successfully`));
      setIsOpenDialog(false);
      getAllPropertyVideosCategoryByPropertyId(+GetParams('id'));
    } else {
      showError(activeItem || isCategoryExist ? t(`${translationPath}Update-PropertyVideo-failed`) : t(`${translationPath}Create-PropertyVideo-failed`));
      setIsOpenDialog(false);
    }
    setIsLoading(false);
  }, [getAllPropertyVideosCategoryByPropertyId, activeItem, state, t, translationPath]);

  useEffect(() => {
    const propertyId = +GetParams('id');
    if (propertyId !== null) getAllPropertyVideosCategoryByPropertyId(propertyId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <div className='property-imgaes-wrapers'>
      <Spinner isActive={isLoading} isAbsolute />
      <div className='property-cards'>
        <div className='childs-wrapper'>
          <div
            className={
              allPropertyVideo.length === 0 ?
                'section px-2 d-flex-center mt-3 mb-3 ' :
                'section px-2  mt-3 mb-3'
            }
          >
            <ButtonBase
              className='btns theme-solid bg-primary'
              onClick={() => {
                setIsOpenDialog(true);
                setActiveItem(null);
              }}
            >
              <span className='mdi mdi-plus' />
              <span className='px-1'>{t(`${translationPath}Add-new`)}</span>
            </ButtonBase>
          </div>
          {allPropertyVideo.length === 0 ? (
            <div className='EmptyPage' />
          ) : (

            <VideosGalleryCardComponent
              hideIcon={hideIcon}
              hidePhotosTooltip
              selected={selected}
              setSelected={setSelected}
              parentTranslationPath={parentTranslationPath}
              translationPath={translationPath}
              data={allPropertyVideo}
              WithUnitDetails
              reloadData={getAllPropertyVideosCategoryByPropertyId}
              Display={UnitProfileImagesCardActionEnum.Show}
              activeTab={activeTab}
              propertyUploader={true}
              setState={setState}
              isCategoryExist={isCategoryExist}
              setIsCategoryExist={setIsCategoryExist}
              isOpenDialog={isOpenDialog}
              setIsOpenDialog={setIsOpenDialog}
              activeItem={activeItem}
              setActiveItem={setActiveItem}
              submitHandler={updatePropertyVideo}
              state={state}
            />
          )}
        </div>
        <VideoDialogsProperties
          state={state}
          setState={setState}
          activeItem={activeItem}
          selected={selected}
          setSelected={setSelected}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          isCategoryExist={isCategoryExist}
          setIsCategoryExist={setIsCategoryExist}
          activeTab={activeTab}
          setActiveItem={setActiveItem}
          isOpenDialog={isOpenDialog}
          setIsOpenDialog={setIsOpenDialog}
          submitHandler={updatePropertyVideo}
          data={allPropertyVideo}
        />
      </div>
    </div>
  );
};
PropertiesVideos.propTypes = {
  HideEdit: PropTypes.bool,
  parentTranslationPath: PropTypes.string.isRequired,
  translationPath: PropTypes.string.isRequired,
  canEditImage: PropTypes.bool,
};
PropertiesVideos.defaultProps = {
  HideEdit: true,
  canEditImage: false,
};
