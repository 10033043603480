export const LearningHubLocale = {
  en: {
    'course-name': 'Course Name',
    category: 'Category',
    'course-link': 'Course Link',
    description: 'Description',
    open_course: 'Open Course',
    course_management: 'Course Management',
    communities: 'Communities',
    course_operation_types: 'Course Operation Types',
    categories: 'Categories',
    projects: 'Projects',
    course_developers: 'Course Developers',
    something_went_wrong: 'Something went wrong',
    updated_successfully: 'Updated successfully',
  },
  ar: {
    'course-name': 'اسم الدورة',
    category: 'الفئة',
    'course-link': 'رابط الدورة',
    description: 'الوصف',
    open_course: 'افتح الدورة',
    course_management: 'إدارة الدورة',
    communities: 'المجتمعات',
    course_operation_types: 'أنواع تشغيل الدورة',
    categories: 'الفئات',
    projects: 'المشاريع',
    course_developers: 'مطوروا الدورات',
    something_went_wrong: 'حدث خطأ ما',
    updated_successfully: 'تم التحديث بنجاح',
  },
  ru: {
    'course-name': 'Название курса',
    category: 'Категория',
    'course-link': 'Ссылка на курс',
    description: 'Описание',
    open_course: 'Открыть курс',
    course_management: 'Управление курсами',
    communities: 'Сообщества',
    course_operation_types: 'Типы операций курса',
    categories: 'Категории',
    projects: 'Проекты',
    course_developers: 'Разработчики курсов',
    something_went_wrong: 'Что-то пошло не так',
    updated_successfully: 'Успешно обновлено',
  },
  cn: {
    'course-name': '课程名称',
    category: '类别',
    'course-link': '课程链接',
    description: '描述',
    open_course: '打开课程',
    course_management: '课程管理',
    communities: '社区',
    course_operation_types: '课程操作类型',
    categories: '类别',
    projects: '项目',
    course_developers: '课程开发者',
    something_went_wrong: '出了些问题',
    updated_successfully: '更新成功',
  },
};

export const LearningHubLocalePath = 'LearningHub';
