export const ProfileAndAchievementstPermissions = {
    ViewProfileAndAchievements :{
        permissionsId: "c91884c0-acdc-4187-0064-08dd03ecea2b",
        permissionsName: "View Profile and Achievements",
        description: null,
        componentsId: null,
        components: null
    }
  
  };
  