
 export const PaymentMethodEnum = {
    Cash: {
      key: 1,
      name : 'Cash' 
    },
    Installments: {
        key: 2,
        name : 'Installments' 
      },
      CashAndInstallments: {
        key: 3,
        name : 'Cash And Installments' 
      },
  };
  