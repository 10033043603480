import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  StaticLookupsIds
} from '../../../../../../assets/json/StaticLookupsIds';
import { AutocompleteComponent } from '../../../../../../Components';
import { lookupItemsGetId  , GetAllPropertyUnitTypeAPI } from '../../../../../../Services';

export const Unittype = ({
  parentTranslationPath,
  translationPath,
  setvalue,
  helperText,
  error,
  isSubmitted,
  labelClasses , 
  state 
}) => {
  const { t } = useTranslation(parentTranslationPath || '');
  const [res, setres] = useState([]);
  const [isLoading, setisLoading] = useState(false);

  const UnittypeAPI = useCallback(async (id) => {
    setisLoading(true);
    const res =   await GetAllPropertyUnitTypeAPI(id) ;
    if ((res && res.data && res.data.ErrorId) || !res) return;
   const list = res && Array.isArray(res)  &&  res[0] && res[0].propertyUnitType && res[0].propertyUnitType.map((ut) => 
      ({
        lookupItemId: ut.lookupItemId,
        lookupItemName: ut.lookupItemName,
      }));     

    setres(list || []);
    setisLoading(false);
  }, []);
  useEffect(() => {
    if(state && state.property_name  && state.property_name.id)
    UnittypeAPI(state.property_name.id);
  else 
    setres([]);


  }, [UnittypeAPI , state.property_name]);

  return (
    <div>
      <AutocompleteComponent
        labelClasses={labelClasses}
        idRef='UnittypeRef'
        labelValue='Unittype'
        selectedValues={state.unit_type}
        multiple={false}
        data={res || []}
        displayLabel={(option) => t(`${option.lookupItemName || ''}`)}
        withoutSearchButton
        inputPlaceholder={t(`${translationPath}selectUnittype`)}
        isSubmitted={isSubmitted}
        helperText={helperText}
        error={error}
        isWithError
        isLoading={isLoading}
        parentTranslationPath={parentTranslationPath}
        translationPath={translationPath}
        onChange={(event, newValue) => {
          setvalue((newValue && newValue) || '');
        }}
      />
    </div>
  );
};

Unittype.propTypes = {
  parentTranslationPath: PropTypes.string.isRequired,
  translationPath: PropTypes.string.isRequired,
  setvalue: PropTypes.func.isRequired,
  helperText: PropTypes.string.isRequired,
  error: PropTypes.string.isRequired,
  isSubmitted: PropTypes.string.isRequired,
};
