

import {  LeadsCreatedBy , LeadsAddress , LeadsMediaDetails , LeadsMethodOfContact , LeadsReferredBy , LeadsReferredTo    } from '../LeadsPolicies' ; 
import { LeadsDataDetails  } from './LeadsDataDetails' ; 



export const LeadsPoliciesTab  = [
    {   
      label: 'createdBy',
      component: LeadsCreatedBy  ,
    } , 
    {   
        label: 'address',
        component: LeadsAddress  ,
    } ,
    {   
        label: 'referredBy',
        component: LeadsReferredBy  ,
    } ,
    {   
        label: 'referredTo',
        component: LeadsReferredTo  ,
    } ,
    {

        label: 'data-details',
        component: LeadsDataDetails  ,
    } , 
    {   
        label: 'mediaDetails',
        component: LeadsMediaDetails  ,
    } ,
    {   
        label: 'methodOfContact',
        component: LeadsMethodOfContact  ,
    } ,
]
  

