/* eslint-disable quotes */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Button } from "@material-ui/core";
import moment from "moment";
import Joi from "joi";
import {
  Inputs,
  SelectComponet,
  Spinner,
  UploaderComponent,
} from "../../../../../../../../Components";
import {
  getDownloadableLink,
  getErrorByName,
  GetParams,
  showSuccess,
} from "../../../../../../../../Helper";
import {
  CreatePropertyOffer,
  UpdatePropertyOffer,
} from "../../../../../../../../Services/OfferServices";
import { DateRangePickerComponent } from "../../../../../../../../Components/Controls/DateRangePickerComponent/DateRangePickerComponent";
import { OldUploaderComponent } from "../../../../../../../../Components/UploaderComponent/OldUploaderComponent";

export const AddEditMarketingOffers = ({
  onCancelClicked,
  DialogState,
  ActiveItem,
  reloadData,
  parentTranslationPath,
  translationPath,
}) => {
  const { t } = useTranslation([parentTranslationPath]);
  const dateRangeDefault = {
    startDate: null,
    endDate: null,
    key: "selection",
  };
  const DefaultState = {
    offerName: null,
    startDate: null,
    endDate: null,
    status: 0,
    arDescription: "",
    enDescription: "",
    propertyId: +GetParams("id"),
    propertyOfferFiles: [],
  };

  const DefaultStateEnum = [{ id: 4, status: t(`${translationPath}Closed`) }];
  const [SelectComponetEnum, setSelectComponetEnum] =
    useState(DefaultStateEnum);

  const [dateFilter, setDateFilter] = useState(dateRangeDefault);
  const [state, setState] = useState(DefaultState);
  const [Empty, setEmpty] = useState({
    value: 0,
    text: t(`${translationPath}select-status`),
    isDisabled: false,
  });
  const [isLoading, setIsLoading] = useState(false);

  const schema = Joi.object({
    offerName: Joi.string()
      .min(3)
      .messages({
        "string.min": t(
          `${translationPath}least-allowed-character-description-3`
        ),
        "string.max": t(
          `${translationPath}max-allowed-character-descriptionmax-allowed-character-description`
        ),
      }),
  })
    .options({
      abortEarly: false,
      allowUnknown: true,
    })
    .validate(state);
  function getFileExtension(filename) {
    // get file extension
    const extension = filename.substring(
      filename.lastIndexOf(".") + 1,
      filename.length
    );
    return extension;
  }
  const SaveHandler = async () => {
    setIsLoading(true);
    const res =
      DialogState === "add"
        ? await CreatePropertyOffer({
            offerName: (state && state.offerName) || null,
            startDate: (state && state.startDate) || null,
            endDate: (state && state.endDate) || null,
            status: (state && state.status) || 0,
            arDescription: (state && state.arDescription) || null,
            enDescription: (state && state.enDescription) || null,
            propertyId: +GetParams("id") || null,
            propertyOfferFiles: (state && state.propertyOfferFiles) || null,
          })
        : UpdatePropertyOffer(
            (ActiveItem && ActiveItem.propertyOfferId) || null,
            {
              offerName: (state && state.offerName) || null,
              startDate: (state && state.startDate) || 0,
              endDate: (state && state.endDate) || 0,
              status: (state && state.status) || 0,
              arDescription: (state && state.arDescription) || null,
              enDescription: (state && state.enDescription) || null,
              propertyId: +GetParams("id") || null,
              propertyOfferFiles: (state && state.propertyOfferFiles) || null,
            }
          );
    // eslint-disable-next-line no-unused-expressions
    DialogState === "add"
      ? showSuccess(t(`${translationPath}added-successfully`))
      : showSuccess(t(`${translationPath}Edit-successfully`));
    onCancelClicked();
    reloadData();
    // else {
    //   // eslint-disable-next-line no-unused-expressions
    //   DialogState === 'add' ? showError(t(`${translationPath}add-failed`)) :
    //     showError(t(`${translationPath}Edit-Failed`));
    // }
    setIsLoading(false);
  };

  useEffect(() => {
    if (DialogState === "edit") {
      setState({
        ...state,
        offerName: (ActiveItem && ActiveItem.offerName) || null,
        startDate: (ActiveItem && ActiveItem.startDate) || null,
        endDate: (ActiveItem && ActiveItem.endDate) || null,
        status: ActiveItem && ActiveItem.status,
        arDescription: (ActiveItem && ActiveItem.arDescription) || null,
        enDescription: (ActiveItem && ActiveItem.enDescription) || null,
        propertyId: +GetParams("id") || null,
        propertyOfferFiles:
          (ActiveItem && ActiveItem.propertyOfferFiles) || null,
      });
      setDateFilter({
        startDate: new Date(ActiveItem && ActiveItem.startDate),
        endDate: new Date(ActiveItem && ActiveItem.endDate),
        key: "selection",
      });

      if (ActiveItem && ActiveItem.status !== 4) {
        setEmpty({
          value: ActiveItem && ActiveItem.status,
          text:
            (ActiveItem && ActiveItem.status === 1 && t("Planned")) ||
            (ActiveItem && ActiveItem.status === 2 && t("Open")) ||
            (ActiveItem && ActiveItem.status === 3 && t("Expired")) ||
            (ActiveItem && ActiveItem.status === 4 && t("Closed")) ||
            "",
          isDisabled: false,
        });
      } else {
        setEmpty({
          value: null,
          text: t(`${translationPath}select-status`),
          isDisabled: false,
        });
      }
    } else setState(DefaultState);
  }, [ActiveItem, DialogState]);

  const Vadation = () => {
    if (
      state.offerName === null ||
      state.offerName === "" ||
      state.startDate === null ||
      state.startDate === null
    )
      return true;
    return false;
  };

  return (
    <div className="property-marketing-remarks-wrapper presentational-wrapper">
      <Spinner isActive={isLoading} isAbsolute />
      <Inputs
        value={state.offerName}
        idRef="activitiesSearchRef"
        helperText={getErrorByName(schema, "offerName").message}
        error={getErrorByName(schema, "offerName").error}
        isWithError
        labelValue={t(`${translationPath}offerName`)}
        labelClasses="Requierd-Color"
        onInputChanged={(e) =>
          setState({ ...state, offerName: e.target.value })
        }
      />
      <div className="d-flex w-100">
        <DateRangePickerComponent
          onClearClicked={() => {
            setDateFilter(dateRangeDefault);
            setState({ ...state, startDate: null, endDate: null });
          }}
          ranges={[dateFilter]}
          labelValue={t(`${translationPath}offerDate`)}
          helperText={getErrorByName(schema, "startDate").message}
          error={getErrorByName(schema, "startDate").error}
          isWithError
          labelClasses="Requierd-Color"
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          onDateChanged={(selectedDate) => {
            setDateFilter({
              startDate:
                selectedDate.selection && selectedDate.selection.startDate,
              endDate: new Date(
                moment(
                  selectedDate.selection && selectedDate.selection.endDate
                ).endOf("day")
              ),
              key: "selection",
            });
            setState({
              ...state,
              startDate: moment(
                selectedDate.selection && selectedDate.selection.startDate
              ).startOf("day").format("YYYY-MM-DD[T00:00:00]"),
              endDate: moment(
                  selectedDate.selection && selectedDate.selection.endDate
                ).endOf("day").format("YYYY-MM-DD[T23:59:59]"),
            });
          }}
        />
        <SelectComponet
          data={SelectComponetEnum}
          value={state.status}
          labelValue={t(`${translationPath}offerState`)}
          // labelClasses='Requierd-Color'
          emptyItem={Empty}
          valueInput="id"
          textInput="status"
          onSelectChanged={(value) => {
            setState({
              ...state,
              status: value,
            });
          }}
        />
      </div>
      <Inputs
        value={state.enDescription}
        rows={3}
        rowsMax={10}
        multiline
        idRef="enDescriptionearchRef"
        labelValue={t(`${translationPath}enDescription`)}
        onInputChanged={(e) =>
          setState({ ...state, enDescription: e.target.value })
        }
      />
      <Inputs
        value={state.arDescription}
        rows={3}
        rowsMax={10}
        multiline
        idRef="arDescriptionhRef"
        labelValue={t(`${translationPath}arDescription`)}
        onInputChanged={(e) =>
          setState({ ...state, arDescription: e.target.value })
        }
      />
      <OldUploaderComponent
        idRef="modelsUnitsImportRef"
        multiple
        accept="*"
        uploadedChanged={(files) =>
          setState({
            ...state,
            propertyOfferFiles:
              (files &&
                files.map((file) => ({
                  fileId: file.uuid || file.fileId || null,
                  fileName: file.fileName,
                  fileType: getFileExtension((file && file.fileName) || "."),
                }))) ||
              [],
          })
        }
        chipHandler={(value) => () => {
          const link = document.createElement("a");
          link.setAttribute("download", value.fileName);
          link.href = getDownloadableLink(value.uuid);
          document.body.appendChild(link);
          link.click();
          link.remove();
        }}
        viewUploadedFilesCount
        initUploadedFiles={
          (state && state.propertyOfferFiles && state.propertyOfferFiles) || []
        }
      />
      <div className="save-cancel-wrapper d-flex-v-center-h-end flex-wrap p-2">
        <div className="cancel-wrapper d-inline-flex-center">
          <Button
            id="cancel-ref-action"
            onClick={onCancelClicked}
            className="MuiButtonBase-root MuiButton-root MuiButton-text cancel-btn-wrapper btns theme-transparent c-primary"
            tabIndex="0"
            type="button"
          >
            <span className="MuiButton-label">
              <span>{t(`${translationPath}cancel`)}</span>
            </span>

            <span className="MuiTouchRipple-root" />
          </Button>
        </div>
        <div className="save-wrapper d-inline-flex-center">
          <Button
            disabled={Vadation()}
            onClick={() => SaveHandler()}
            className="MuiButtonBase-root MuiButton-root MuiButton-text save-btn-wrapper btns theme-solid  w-100 mx-2"
            tabIndex="0"
            type="button"
          >
            <span className="MuiButton-label">
              <span>{t(`${translationPath}confirm`)}</span>
            </span>
            <span className="MuiTouchRipple-root" />
          </Button>
        </div>
      </div>
    </div>
  );
};
