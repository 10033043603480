import React, {
  useState, useCallback, useReducer, useEffect
} from 'react';
import PropTypes from 'prop-types';
import Joi from 'joi';
import { useTranslation } from 'react-i18next';
import { DialogComponent } from '../../../../../../Components';
import { CreateUnitImages, GetAllUnitImages, UpdateUnitImages } from '../../../../../../Services';
import { ImageGalleryLookupsComponent } from '../../../../ImagesGallery/Dialogs/ImagesGalleryManagementDialog/Presentational';
import { DocumentsUploader } from '../../../../../../SharedComponents/DocumentsUploader/DocumentsUploader'
import { GetParams, showError, showSuccess } from '../../../../../../Helper';
import { Spinner } from '../../../../../../Components/SpinnerComponent/Spinner';
import './UnitsSalesProfileManagementDialog.scss'

export const UnitsSalesProfileManagementDialog = ({
  unit,
  activeItem,
  setActiveItem,
  isOpen,
  isOpenChanged,
  parentTranslationPath,
  translationPath,
  updateData,
  data,
  ImageCategoryLookup
}) => {
  const reducer = useCallback((state, action) => {
    if (action.id !== 'edit') return { ...state, [action.id]: action.value };
    return {
      ...action.value,
    };
  }, []);

  const [isSubmitted, setIsSubmitted] = useState(false);
  const { t } = useTranslation([parentTranslationPath, 'Shared']);
  const [isUploading, setIsUploading] = useState(false);
  const [dialogName, setDialogName] = useState();
  const [loading, setLoading] = useState(false);
  const initialState = {
    unitId: +GetParams('id'),
    categoryId: '',
    files: [],
    pageIndex: 1,
    pageSize: 100,
  }
  const [state, setState] = useReducer(reducer, initialState);
  const [body, setBody] = useState({
    categoryId: state.categoryId,
    unitId: state.unitId,
    pageIndex: 1,
    pageSize: 100,
  });

  const onStateChanged = (newValue) => {
    const categoryId = newValue.value && newValue.value && newValue.value.categoryId;
    const isCategoryUsed = data && data.find(el => el.categoryId === categoryId);

    setBody((item) => ({ ...item, categoryId: categoryId }));

    if (isCategoryUsed || activeItem) setDialogName(true);
    else {
      setState({
        id: 'files',
        value: [],
      });
      setDialogName(false);
      setActiveItem(null);
    }
  };

  const getData = useCallback(async () => {
    if (body && body.categoryId) {
      setLoading(true);
      const res = await GetAllUnitImages(body);
      if (!(res && res.data && res.data.ErrorId)) {
        setState({
          id: 'files',
          value: res.result,
        });
        setState({
          id: 'categoryId',
          value: body && body.categoryId,
        });
        if (res.totalCount || activeItem) setDialogName(true);
        // else setDialogName(false)
      } else {
        setDialogName(false);
        setActiveItem(null);
        setState({
          id: 'files',
          value: [],
        });
        setState({
          id: 'categoryId',
          value: '',
        });
      }
      setLoading(false);
    }
  }, [body])

  useEffect(() => {
    if (activeItem && activeItem.categoryId) {
      setBody((item) => ({ ...item, categoryId: activeItem && activeItem.categoryId }));
    }
    setDialogName(false);
  }, [activeItem]);

  useEffect(() => {
    getData();
  }, [getData]);

  const schema = Joi.object({
    category: ((state && state.categoryId === '') && Joi.required()
      .messages({
        'any.required': t(`${translationPath}category-is-required`),
      }) || Joi.any()),
  })
    .options({
      abortEarly: false,
      allowUnknown: true,
    })
    .validate(state);

  const updateHandler = async (e) => {
    e.preventDefault();
    setIsSubmitted(true);
    isOpenChanged(false);
    setLoading(true);
    if (schema.error) {
      showError(t('Shared:please-fix-all-errors'));
      return;
    }
    const res = await UpdateUnitImages(state);
    if (!(res && res.data && res.data.ErrorId)) {
      updateData();
      showSuccess(t(`${translationPath}photo-update-successfully`));
    } else {
      showError(
        t(
          `${translationPath}${(res &&
            res.data &&
            res.data.Message &&
            res.data.Message.substring(
              res.data.Message.lastIndexOf(':') + 1,
              res.data.Message.length
            )) ||
          'photo-update-failed'
          }`
        )
      );
    }
    setState({
      id: 'edit',
      value: initialState,
    });
    setDialogName(false);
    setLoading(false);
  };
  const addHandler = async (e) => {
    e.preventDefault();
    setIsSubmitted(true);
    isOpenChanged(false);
    if (schema.error) {
      showError(t('Shared:please-fix-all-errors'));
      return;
    }
    setLoading(true);
    const res = await CreateUnitImages(state);
    if (!(res && res.data && res.data.ErrorId)) {
      showSuccess(t(`${translationPath}photo-created-successfully`));
      updateData();
    } else {
      showError(
        t(
          `${translationPath}${(res &&
            res.data &&
            res.data.Message &&
            res.data.Message.substring(
              res.data.Message.lastIndexOf(':') + 1,
              res.data.Message.length
            )) ||
          'photo-create-failed'
          }`
        )
      );
    }
    setState({
      id: 'edit',
      value: initialState,
    });
    setDialogName(false);
    setLoading(false);
  };
  return (
    <>
      <Spinner isActive={loading} isAbsolute />
      <DialogComponent
        wrapperClasses='unitpage'
        maxWidth='md'
        disableBackdropClick
        titleText={dialogName ? 'edit-photo' : 'add-new-photo'}
        saveIsDisabled={
          (state && state.categoryId === null || isUploading || (!activeItem && !dialogName && !state.files.length))
        }
        saveText='save'
        dialogContent={(
          <div className='Units-Sales-Profile-management view-wrapper'>
            <div className='dialog-item'>
              <ImageGalleryLookupsComponent
                activeItem={activeItem}
                initialId={activeItem && activeItem.categoryId}
                idRef='categoryIdRef'
                unit={unit}
                state={state}
                schema={schema}
                isSubmitted={isSubmitted}
                isDisabled={activeItem ? true : false}
                onStateChanged={onStateChanged}
                parentTranslationPath={parentTranslationPath}
                translationPath={translationPath}
                ImageCategoryLookup={ImageCategoryLookup}
              />
            </div>
            <div
              className={'Unit-Uploader-wrapper-with-data'}
            >
              <DocumentsUploader
                initUploaded={(state.files &&
                  state.files.length > 0 &&
                  state.files.map((item) => ({
                    uuid: item.uuid || item.fileId,
                    fileName: item.fileName,
                    // documentLink: item.documentLink
                  }))) ||
                  []}
                docTypes={{ images: 0 }}
                isUploading={isUploading}
                setIsUploading={setIsUploading}
                uploadedChanged={(img) =>
                  setState({
                    id: 'files',
                    value:
                      (img &&
                        img.map((file) => ({
                          fileId: (file.uuid || file.fileId || null),
                          fileName: file.fileName,
                          // documentLink: (file.documentLink || null)
                        }))) ||
                      [],
                  })}
                open
                translationPath={translationPath}
                parentTranslationPath={parentTranslationPath}
              />
            </div>
          </div>
        )}
        onSubmit={activeItem || dialogName ? updateHandler : addHandler}
        isOpen={isOpen}
        onCancelClicked={() => {
          setDialogName(false);
          isOpenChanged(false);
          setActiveItem(null);
          setState({
            id: 'edit',
            value: initialState,
          });
        }}

        parentTranslationPath={parentTranslationPath}
        translationPath={translationPath}
      />
    </>
  );
};
UnitsSalesProfileManagementDialog.propTypes = {
  ImageCategoryLookup: PropTypes.instanceOf(Object).isRequired,
  updateData: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  isOpenChanged: PropTypes.func.isRequired,
  unit: PropTypes.bool.isRequired,
  activeItem: PropTypes.instanceOf(Object).isRequired,
  parentTranslationPath: PropTypes.string.isRequired,
  translationPath: PropTypes.string.isRequired,
};
UnitsSalesProfileManagementDialog.defaultProps = {
  setActiveItem: () => { }
};
