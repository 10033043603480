import React from "react";

function CornerDownRight({ fill, ...restProps }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill={fill ?? "none"}
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <path
        d="M3.695 3.057a1.04 1.04 0 0 0-.567.459L3.02 3.7l.003 2.56c.003 2.656.024 3.148.181 4.095.118.714.282 1.233.577 1.825.345.694.699 1.181 1.278 1.761.934.934 1.951 1.505 3.164 1.775 1.056.235 2.09.282 6.216.283l3.14.001-1.705 1.71c-.938.941-1.739 1.773-1.779 1.85-.052.099-.074.228-.074.44-.001.258.015.327.112.492.28.476.9.647 1.359.373.216-.128 5.316-5.24 5.413-5.425.052-.099.074-.228.074-.44.001-.255-.015-.327-.106-.48-.061-.102-1.241-1.31-2.72-2.785-2.37-2.363-2.631-2.611-2.81-2.67a1.003 1.003 0 0 0-1.215.451c-.092.156-.108.229-.107.484 0 .212.022.341.074.44.04.077.841.911 1.779 1.853l1.706 1.713-3.74-.019c-3.73-.018-4.167-.033-4.92-.17-.995-.18-1.667-.529-2.412-1.253-.555-.54-1.016-1.274-1.187-1.891-.235-.844-.284-1.546-.315-4.513L4.98 3.7l-.108-.184a1.005 1.005 0 0 0-1.177-.459"
        fill-rule="evenodd"
        fill={fill ?? "none"}
      />
    </svg>
  );
}

export default CornerDownRight;
