import React, { forwardRef } from "react";
import clsx from "clsx";
import { Icon, Box, InputLabel, Badge } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import { PlusIcon } from "../../../assets/icons";
import { useSelectedTheme } from "../../../Hooks";

// Styles
import useStyles from "./styles";

const MobileNav = forwardRef((props, ref) => {
  const { mobileTabValue, setMobileTabValue } = props;

  const styles = useStyles();
  const { t } = useTranslation("HeaderView");
  const history = useHistory();
  const {
    theme: { palette },
  } = useSelectedTheme();

  const myTasksClicked = () => {
    history.push("/home/my-tasks");
  };

  return (
    <Box className={styles.bottomContainer}>
      <Box className={styles.boxContainer}>
        <Box
          className={clsx(styles.buttonWrapper, {
            active: mobileTabValue === 0,
          })}
          onClick={() => {
            setMobileTabValue(0);
            myTasksClicked();
          }}
        >
          <Icon
            className={clsx(
              "icon-header-menu",
              styles.navigationIcon
              //   {
              //   active: mobileTabValue === 0,
              // }
            )}
          />
          <InputLabel
            className={clsx(
              styles.text
              // { active: mobileTabValue === 0 }
            )}
          >
            {t(`tasks`)}
          </InputLabel>
        </Box>

        <Box
          className={clsx(styles.buttonWrapper, {
            active: mobileTabValue === 1,
          })}
          onClick={() => setMobileTabValue(1)}
        >
          <Icon
            className={clsx("icon-header-calendar", styles.navigationIcon, {
              active: mobileTabValue === 1,
            })}
          />
          <InputLabel
            className={clsx(styles.text, { active: mobileTabValue === 1 })}
          >
            {t(`calendar`)}
          </InputLabel>
        </Box>

        <Box
          className={clsx(styles.buttonWrapper, {
            active: mobileTabValue === 2,
          })}
          onClick={() => {
            setMobileTabValue(2);
            localStorage.setItem("NotificationNumber", "0");
          }}
        >
          <Badge
            badgeContent={
              JSON.parse(
                localStorage.getItem("NotificationNumber")
                  ? localStorage.getItem("NotificationNumber")
                  : 0
              ) > 0
                ? ""
                : undefined
            }
            className={clsx(styles.notificationsBadge)}
            max={99}
          >
            <Icon
              className={clsx(
                "icon-header-notifications",
                styles.navigationIcon,
                { active: mobileTabValue === 2 }
              )}
            />
          </Badge>

          <InputLabel
            className={clsx(styles.text, { active: mobileTabValue === 2 })}
          >
            {t(`notifications`)}
          </InputLabel>
        </Box>
      </Box>

      <Box className={styles.addButton}>
        <PlusIcon width="24" height="24" fill={palette.foreground.primary_fg} />
      </Box>
    </Box>
  );
});

export default MobileNav;
