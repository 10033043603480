export const ExternalLinksPermissions =
{
    ViewExternalLinks: {
        permissionsId: 'cd7f5bda-962d-46cd-cb6d-08db7d42a751',
        permissionsName: 'View External Links',
        description: null,
        componentsId: null,
        components: null
    }
};
