import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => {
  return {
    customMenu: {
      padding: "4px 6px",
      maxWidth: "240px",
      width: "240px",
      border: `1px solid ${theme.palette.border.secondary}`,
      boxShadow:
        "0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03)",
      borderRadius: "10px",
    },
    menuItem: {
      padding: "9px 10px",
      display: "flex",
      gap: "8px",
      alignItems: "center",
      borderRadius: "6px",
      "&:hover": {
        background: theme.palette.background.primary_hover,
      },
      marginTop: "1px",
      marginBottom: "1px",
    },
    menuItemText: {
      fontSize: "14px",
      fontWeight: 500,
      lineHeight: "20px",
      color: theme.palette.text.secondary,
      whiteSpace: "normal",
      wordWrap: "break-word",
      textAlign: "start",
    },
    customMenuList: {
      padding: 0,
    },
  };
});
