import React, { useEffect, useState, useReducer, useCallback, useRef } from "react";
import { PowerBIEmbed } from "powerbi-client-react";
import { models } from "powerbi-client";
import "../MasterStyleDashborads/MasterStyleDashboards.scss";
import { showError } from "../../../../Helper";
import { MicrosoftToken } from "../../../../Services";
import { SpinnerDashboards } from "../SpinnerDashboards/SpinnerDashboards";
import { DateRangePickerComponent } from "../../../../Components/Controls/DateRangePickerComponent/DateRangePickerComponent";
import moment from "moment";
import { SelectComponet } from "../../../../Components";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { ButtonBase, ButtonGroup, CircularProgress } from "@material-ui/core";
import { CellsSpinner } from "../../../../SharedComponents/CellSpinnerComponent/CellsSpinner";
import { PowerBiEnum } from "../../../../Enums";
import { CellSpinnerBi } from "../CellSpinnerBi/CellSpinnerBi";
import "../AdminMarketing/Navbar.scss";
const parentTranslationPath = "LeadsView";
const translationPath = "";

export const AdminListingUnits = ({ id, embedUrl }) => {
  const orderFilter = useSelector((state) => state.GlobalOrderFilterReducer);
  const { t } = useTranslation(parentTranslationPath);
  const dispatch = useDispatch();
  const [ShowReport2, setShowReport2] = useState(false);
  const [report, setReport] = useState();
  const [render, setRender] = useState(false);
  const [ShowReport, setShowReport] = useState(false);
  const [DateAppaer, setDateAppaer] = useState(false);
  const [Datetime, setDatetime] = useState(30);
  const [Leadstatus, setLeadstatus] = useState();
  const [Leadstatusstatus, setLeadstatusstatus] = useState(true);
  const [Leasestatuss, setLeadstatusstatuss] = useState(true);
  const [Leasestatus, setLeasestatus] = useState();
  const [OperatorLeasestatus, setOperatorLeasestatus] = useState("All");
  const [OperatorLeadstatus, setOperatorLeadstatus] = useState("All");
  const [LeadUnitsActivity, setLeadUnitsActivity] = useState(true);
  const [UnitsActivity, setUnitsActivity] = useState(true);
  const [OperatorActivityUnits, setOperatorActivityUnits] = useState("All");
  const [OperatorActivityLead, setOperatorActivityLead] = useState("All");
  const [datefilters, setdatefilter] = useState(true);
  const [salesstatusbtn, setsalesstatusbtn] = useState(false);
  const [Leasestatusbtn, setLeasestatusbtn] = useState(false);
  const [isRentListing, setisRentListing] = useState(false);
  const [datepicker, setdatepicker] = useState();
  const [unitOprationTypesales, setunitOprationTypesales] = useState(false);
  const [unitOprationTypelease, setunitOprationTypelease] = useState(false);

  const [monthsAgo, setMonthsAgo] = useState(1);

  const currentDate = new Date();
  const countmonth = new Date(
    currentDate.setMonth(currentDate.getMonth() - monthsAgo)
  );

  const today = new Date();

  const count = new Date(new Date().setDate(today.getDate() - 7));
  const count2 = new Date(new Date().setDate(today.getDate()));

  const datetimes = moment(count).startOf("day").format("YYYY-MM-DDTHH:mm:ss");
  const datetimes2 = moment(count2).format("YYYY-MM-DDTHH:mm:ss");

  const startDate = datetimes ? new Date(datetimes) : null;
  const endDate = datetimes2 ? new Date(datetimes2) : null;

  const dateRangeDefault = {
    startDate: startDate || null,
    endDate: endDate || null,
    key: "selection",
  };

  const [transactions, settransactions] = useState(false);
  const [Activity, setActivity] = useState(false);
  const [Units, setUnits] = useState(false);
  const [Portaldate, setPortaldate] = useState(false);
  const [Portal, setPortal] = useState(false);
  const [Inquiries, setInquiries] = useState(false);

  const [salesUnits, setsalesUnits] = useState();
  const [salesandrentUnits, setsalesandrentUnits] = useState();

  const [dateFilter, setDateFilter] = useState(dateRangeDefault);
  const [data, setdata] = useState([]);
  const reducer = (state, action) => {
    if (action.id !== "edit") return { ...state, [action.id]: action.value };
    if (action.id === "edit") {
      return {
        ...action.value,
      };
    }
    return undefined;
  };

  const [state, setState] = useReducer(reducer, {
    fromDate: null,
    toDate: null,
  });

  const scrollToTop = useCallback(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  useEffect(() => {
    const currentState = {
      ...state,
      fromDate:
        (dateFilter.startDate &&
          moment(dateFilter.startDate)
            .startOf("day")
            .format("YYYY-MM-DDTHH:mm:ss")) ||
        datetimes,
      toDate:
        (dateFilter.endDate &&
          moment(dateFilter.endDate)
            .endOf("day")
            .format("YYYY-MM-DDTHH:mm:ss")) ||
        datetimes2,
    };
    setState({ value: currentState });
    setdata(currentState);
  }, [dateFilter]);

  const onClearedAllFiltersClick = useCallback(() => {
    setDateFilter(dateRangeDefault);
    setLeadstatus(0);
    setLeasestatusbtn(false);
    setsalesstatusbtn(false);
    setsalesUnits(null);
    setsalesandrentUnits(null);
    setLeasestatus(0);
    setLeadstatusstatus(true);
    setLeadstatusstatuss(true);
    setdatepicker(0);
    setdatefilter(true);
  }, []);

  const columnToFilter = datefilters
    ? "UnitsCreatedOn"
    : isRentListing
    ? "Rent_Listing_Date"
    : "listing_date";

  const advancedFilter = {
    $schema: "http://powerbi.com/product/schema#advanced",
    target: {
      table: "ListingUnitsViews",
      column: columnToFilter,
    },
    filterType: models.FilterType.AdvancedFilter,
    logicalOperator: "And",
    conditions: [
      {
        operator: "GreaterThanOrEqual",
        value: data.fromDate,
      },
      {
        operator: "LessThanOrEqual",
        value: data.toDate,
      },
    ],
  };
  const advancedSalesTransactions = {
    $schema: "http://powerbi.com/product/schema#advanced",
    target: {
      table: "View_SalesTransactionsSellerBuyer",
      column: "TransactionDate",
    },
    filterType: 0,
    logicalOperator: "And",
    conditions: [
      {
        operator: "GreaterThanOrEqual",
        value: data.fromDate,
      },
      {
        operator: "LessThanOrEqual",
        value: data.toDate,
      },
    ],
    filterType: models.FilterType.AdvancedFilter,
  };
  const advancedActivity = {
    $schema: "http://powerbi.com/product/schema#advanced",
    target: {
      table: "ListingActivity",
      column: "ActivityCreatedOn",
    },
    filterType: 0,
    logicalOperator: "And",
    conditions: [
      {
        operator: "GreaterThanOrEqual",
        value: data.fromDate,
      },
      {
        operator: "LessThanOrEqual",
        value: data.toDate,
      },
    ],
    filterType: models.FilterType.AdvancedFilter,
  };
  const advancedInquiries = {
    $schema: "http://powerbi.com/product/schema#advanced",
    target: {
      table: "Inquiries",
      column: "InquiriesCreatedOn",
    },
    filterType: 0,
    logicalOperator: "And",
    conditions: [
      {
        operator: "GreaterThanOrEqual",
        value: data.fromDate,
      },
      {
        operator: "LessThanOrEqual",
        value: data.toDate,
      },
    ],
    filterType: models.FilterType.AdvancedFilter,
  };
  const advancedoprationsSales = {
    $schema: "http://powerbi.com/product/schema#advanced",
    target: {
      table: "ListingUnitsViews",
      column: "operation type",
    },
    filterType: 0,
    logicalOperator: "Or",
    conditions: [
      {
        operator: "Is",
        value: salesUnits || null,
      },
      {
        operator: "Is",
        value: salesandrentUnits || null,
      },
    ],
    filterType: models.FilterType.AdvancedFilter,
  };
  const advancedPublishedPortals = {
    $schema: "http://powerbi.com/product/schema#advanced",
    target: {
      table: "ViewPortalsListing",
      column: "PublishDate",
    },
    filterType: 0,
    logicalOperator: "And",
    conditions: [
      {
        operator: "GreaterThanOrEqual",
        value: data.fromDate,
      },
      {
        operator: "LessThanOrEqual",
        value: data.toDate,
      },
    ],
    filterType: models.FilterType.AdvancedFilter,
  };

  let selectedFilter;

  switch (true) {
    case Units:
      selectedFilter = advancedFilter;
      break;

    case transactions:
      selectedFilter = advancedSalesTransactions;
      break;

    case Activity:
      selectedFilter = advancedActivity;
      break;

    case Portal:
      selectedFilter = advancedPublishedPortals;
      break;
    case Inquiries:
      selectedFilter = advancedInquiries;
      break;

    default:
      selectedFilter = advancedFilter;
  }

  const ActivityLead = {
    $schema: "http://powerbi.com/product/schema#basic",
    target: {
      table: "ListingActivity",
      column: "UnitId",
    },
    operator: OperatorActivityLead,
    values: [null],
    filterType: models.FilterType.BasicFilter,
  };

  const ActivityUnits = {
    $schema: "http://powerbi.com/product/schema#basic",
    target: {
      table: "ListingActivity",
      column: "UnitId",
    },
    operator: OperatorActivityUnits,
    values: [null],
    filterType: models.FilterType.BasicFilter,
  };

  const FilterLeadStatus = {
    $schema: "http://powerbi.com/product/schema#basic",
    target: {
      table: "ListingUnitsViews",
      column: "Status",
    },
    operator: OperatorLeadstatus,
    values: [Leadstatus],
    filterType: models.FilterType.BasicFilter,
  };
  const FilterLeaseStatus = {
    $schema: "http://powerbi.com/product/schema#basic",
    target: {
      table: "ListingUnitsViews",
      column: "LeaseStatus",
    },
    operator: OperatorLeasestatus,
    values: [Leasestatus],
    filterType: models.FilterType.BasicFilter,
  };

  const Microsoft = async (isExpired) => {
    const res = await MicrosoftToken(isExpired);
    if (!(res && res.status && res.status !== 200)) {
      setReport(res);
      setShowReport(true);
      setRender(true);
    } else {
      setReport("");
      showError("Contact Your Bi Admin For Help");
    }
  };

  const [activeButton, setActiveButton] = useState("One");
  const [activeButtonactivity, setactiveButtonactivity] = useState("One");
  const [activeActivityGroupButton, setactiveActivityGroupButton] =
    useState(true);
  const [activeGroupButton, setactiveGroupButton] = useState(true);
  const [bookmarks, setBookmarks] = useState([]);
  const [error, setError] = useState(null);
  const [Page, setpage] = useState();



  const [activeIndex, setActiveIndex] = useState(0);
  const horiSelectorRef = useRef(null);
  const navbarRef = useRef(null);



  useEffect(() => {
    const updateSelector = () => {
      if (navbarRef.current && horiSelectorRef.current) {
        const tabs = navbarRef.current.querySelectorAll("li");
        if (tabs.length > 0) {
          const activeItem = tabs[activeIndex];
          const activeWidth = activeItem.offsetWidth;
          const itemPosLeft = activeItem.offsetLeft;
          horiSelectorRef.current.style.left = `${itemPosLeft}px`;
          horiSelectorRef.current.style.width = `${activeWidth}px`;
        }
      }
    };

 
    updateSelector();
  }, [activeIndex]);

  const handleItemClick = (index) => {
    setActiveIndex(index);
  };


  const loadBookmarks = async () => {
    const report = window.report;
    let pages = await report.getPages();
    if (report) {
      try {
        const bookmarksManager = report.bookmarksManager;
        const fetchedBookmarks = await bookmarksManager.getBookmarks();
        setBookmarks(fetchedBookmarks);
      } catch (error) {
        console.error("Error loading bookmarks:", error);
        setError(error);
      }
    }
  };
  const applyBookmark = async (bookmarkName) => {
    const report = window.report;
    if (report) {
      try {
        const bookmarksManager = report.bookmarksManager;
        bookmarksManager.apply(bookmarkName);
        // console.log("Bookmark applied successfully:", bookmarkName);
      } catch (error) {
        console.error("Error applying bookmark:", error);
        setError(error);
      }
    }
  };

  const handleButtonClick = (buttonNameclick) => {
    setActiveButton(buttonNameclick);
  };
  const handleButtonActivityClick = (buttonName) => {
    setactiveButtonactivity(buttonName);
  };

  const [filterButton, setfilterButton] = useState(false);
  const [clearfilterButton, setclearfilterButton] = useState(false);

  const leadActivity = () => {
    handleButtonActivityClick("Two");
    setLeadUnitsActivity(false);
    setUnitsActivity(true);
  };
  const unitsActivites = () => {
    handleButtonActivityClick("Three");
    setUnitsActivity(false);
    setLeadUnitsActivity(true);
  };
  const allActivity = () => {
    handleButtonActivityClick("One");
    setLeadUnitsActivity(true);
    setUnitsActivity(true);
  };

  const ChartView = () => {
    applyBookmark("Bookmarka9be2daf931bca836ae8");
    handleButtonClick("One");
    setShowReport2(false);
  };
  const TableView = () => {
    applyBookmark("Bookmarkeeaecacfb94c240f6c47");
    handleButtonClick("Two");
    setShowReport2(false);
  };

  const FilterHide = () => {
    applyBookmark("");
    setfilterButton(false);
    setclearfilterButton(false);
  };
  const FilterView = () => {
    applyBookmark("");
    setfilterButton(true);
    setclearfilterButton(true);
  };

  const [MenuButton, setMenuButton] = useState("One");

  const handleMenuButtonClick = (buttonName) => {
    setMenuButton(buttonName);
  };

  const GeneralPage = () => {
    handleMenuButtonClick("One");
    Pagenavi("ReportSection4cce066349040d612c0b");
  };
  const TransactionPage = () => {
    handleMenuButtonClick("Two");
    Pagenavi("ReportSectioncde7f09f0e0558aa480b");
  };

  const InquiriesPage = () => {
    handleMenuButtonClick("Three");
    Pagenavi("ReportSection23a00ca7bfed9a180035");
  };
  const PortalsPage = () => {
    handleMenuButtonClick("Four");
    Pagenavi("ReportSection550923ea39eb579ddb7f");
  };
  const ActivityPage = () => {
    handleMenuButtonClick("Five");
    Pagenavi("ReportSection5cc6b45857bf71ca5b25");
  };

  const Pagenavi = async (pagestate) => {
    const report = window.report;

    if (report) {
      try {
        report.setPage(pagestate);
      } catch (error) {
        console.error("Error applying:", error);
      }
    }
  };

  const handleRefreshClick = useCallback(() => {
    window.report
      .refresh()
      .then(() => {
        setShowReport2(false);
        console.log(true);
      })
      .catch((error) => {
        console.log(false);
      });
  }, []);
  useEffect(() => {
    Microsoft(false);
  }, []);
  useEffect(() => {
    if (Leadstatusstatus) setOperatorLeadstatus("All");
    else setOperatorLeadstatus("In");
    if (Leasestatuss) setOperatorLeasestatus("All");
    else setOperatorLeasestatus("In");
    if (LeadUnitsActivity) setOperatorActivityUnits("All");
    else setOperatorActivityUnits("In");
    if (UnitsActivity) setOperatorActivityLead("All");
    else setOperatorActivityLead("NotIn");
  }, [Leadstatusstatus, Leasestatuss, LeadUnitsActivity, UnitsActivity]);

  return (
    <div className="dashboardMain-PowerBIEmbed">
      <CellSpinnerBi isActive={!ShowReport} isAbsolute blue />
      <div>
        <div id="navbar-animmenu" ref={navbarRef}>
          <ul className="show-dropdown main-navbar">
            <div className="hori-selector" ref={horiSelectorRef}>
              <div className="left"></div>
              <div className="right"></div>
            </div>
            <li
              className={activeIndex === 0 ? "active" : ""}
              onClick={() => {
                handleItemClick(0);
                GeneralPage();
              }}
            >
              <a>
                <i className={`mdi mdi-account-details-outline mdi-24px `}></i>
                {t(`${translationPath}general-menu`)}
              </a>
            </li>
            <li
              className={activeIndex === 1 ? "active" : ""}
              onClick={() => {
                handleItemClick(1);
                TransactionPage();
              }}
            >
              <a>
                <i className={`mdi mdi-book-account-outline mdi-24px `}></i> 
                {t(`${translationPath}transaction-menu`)}
              </a>
            </li>
            <li
              className={activeIndex === 2 ? "active" : ""}
              onClick={() => {
                handleItemClick(2);
                InquiriesPage();
              }}
            >
              <a>
                <i className={`mdi mdi-help-circle mdi-24px `}></i> 
                {t(`${translationPath}inquiries-menu`)}
              </a>
            </li>
            <li
              className={activeIndex === 3 ? "active" : ""}
              onClick={() => {
                handleItemClick(3);
                PortalsPage();
              }}
            >
              <a>
                <i className={`mdi mdi-web-box mdi-24px  `}></i> 
                {t(`${translationPath}portal-menu`)}
              </a>
            </li>
            <li
              className={activeIndex === 4 ? "active" : ""}
              onClick={() => {
                handleItemClick(4);
                ActivityPage();
              }}
            >
              <a>
                <i className={`mdi mdi-alert-rhombus mdi-24px  `}></i> 
                {t(`${translationPath}activity-menu`)}
              </a>
            </li>
          </ul>
        </div>
      </div>
      <br />
      {render && (
        <div className="mainCardDashboards">
        <div className="dashboardMain cardDashboard">
      {DateAppaer && (
        <div>
              <br />
          <div className="MainFiltersBi">
            <CellSpinnerBi isActive={!ShowReport2} isAbsolute blue />
            <div className="selecterbi">
              <div>
                <SelectComponet
                  data={[
                    { id: 1, Leadstatus: "Available" },
                    { id: 10, Leadstatus: "Draft" },
                    { id: 13, Leadstatus: "Pending Reserve Sale" },
                    { id: 14, Leadstatus: "Pending Sale" },
                    { id: 5, Leadstatus: "Reserved Sale" },
                    { id: 6, Leadstatus: "Sale" },
                  ]}
                  emptyItem={{
                    value: 0,
                    text: "Sales Status",
                    isDisabled: false,
                  }}
                  value={Leadstatus || 0}
                  defaultValue={0}
                  valueInput="id"
                  isDisabled={Leasestatusbtn == true}
                  textInput="Leadstatus"
                  onSelectChanged={(value) => {
                    switch (value) {
                      case 0:
                        setLeadstatusstatus(true);
                        setsalesstatusbtn(false);
                        setLeadstatus(0);
                        break;
                      default:
                        setLeadstatus(value);
                        setLeadstatusstatus(false);
                        setsalesstatusbtn(true);
                        setShowReport2(false);
                        break;
                    }
                  }}
                  wrapperClasses="w-auto"
                  themeClass="theme-transparent"
                  idRef="Lead_Status"
                  parentTranslationPath={parentTranslationPath}
                  translationPath={translationPath}
                  translationPathForData={translationPath}
                />
              </div>

              <div>
                <SelectComponet
                  data={[
                    { id: 1, Leasestatus: "Available" },
                    { id: 10, Leasestatus: "Draft" },
                    { id: 3, Leasestatus: "Leased" },
                    { id: 12, Leasestatus: "Pending Lease" },
                    { id: 11, Leasestatus: "Pending Reserve Lease" },
                    { id: 2, Leasestatus: "Reserved Leased" },
                  ]}
                  emptyItem={{
                    value: 0,
                    text: "Lease Status",
                    isDisabled: false,
                  }}
                  value={Leasestatus || 0}
                  defaultValue={0}
                  isDisabled={salesstatusbtn == true}
                  valueInput="id"
                  textInput="Leasestatus"
                  onSelectChanged={(value) => {
                    switch (value) {
                      case 0:
                        setLeadstatusstatuss(true);
                        setLeasestatusbtn(false);
                        setLeasestatus(0);
                        break;
                      default:
                        setLeasestatus(value);
                        setLeadstatusstatuss(false);
                        setLeasestatusbtn(true);
                        setShowReport2(false);
                        break;
                    }
                  }}
                  wrapperClasses="w-auto"
                  themeClass="theme-transparent"
                  idRef="Lead_Status"
                  parentTranslationPath={parentTranslationPath}
                  translationPath={translationPath}
                  translationPathForData={translationPath}
                />
              </div>
            </div>

            <div className="selecterbi">
              {!Units ? (
                <div></div>
              ) : (
                <div>
                  <SelectComponet
                    data={[
                      {
                        id: 1,
                        datepicker: "Sales Listing Date",
                      },
                      {
                        id: 2,
                        datepicker: "Lease Listing Date",
                      },
                    ]}
                    emptyItem={{
                      value: 0,
                      text: `${translationPath}created-date`,
                      isDisabled: false,
                    }}
                    value={datepicker || 0}
                    defaultValue={0}
                    valueInput="id"
                    textInput="datepicker"
                    onSelectChanged={(value) => {
                      switch (value) {
                        case 0:
                          setdatepicker(0);
                          setdatefilter(true);
                          setisRentListing(false);
                          setsalesUnits(null);
                          setsalesandrentUnits(null);
                          setunitOprationTypesales(0);
                          setShowReport2(false);
                          setLeasestatusbtn(false);
                          setsalesstatusbtn(false);
                          break;
                        case 1:
                          setdatepicker(1);
                          setunitOprationTypesales(true);
                          setunitOprationTypelease(false);
                          setisRentListing(false);
                          setsalesUnits(430);
                          setsalesandrentUnits(20984);
                          setdatefilter(false);
                          setShowReport2(false);
                          setLeasestatusbtn(false);
                          setsalesstatusbtn(true);
                          break;
                        case 2:
                          setdatepicker(2);
                          setunitOprationTypelease(true);
                          setunitOprationTypesales(false);
                          setsalesUnits(431);

                          setsalesandrentUnits(20984);
                          setisRentListing(true);
                          setdatefilter(false);
                          setShowReport2(false);
                          setsalesstatusbtn(false);
                          setLeasestatusbtn(true);

                          break;
                      }
                    }}
                    themeClass="theme-transparent"
                    wrapperClasses="w-auto"
                    idRef="Date_Select"
                    parentTranslationPath={parentTranslationPath}
                    translationPath={translationPath}
                    translationPathForData={translationPath}
                  />
                </div>
              )}
              <div>
                <DateRangePickerComponent
                  onClearClicked={() => {
                    setDateFilter({
                      startDate: startDate,
                      endDate: endDate,
                      key: "selection",
                    });
                  }}
                  ranges={[dateFilter]}
                  onDateChanged={(selectedDate) => {
                    const newStartDate =
                      selectedDate.selection &&
                      selectedDate.selection.startDate;
                    const newEndDate = new Date(
                      moment(
                        selectedDate.selection && selectedDate.selection.endDate
                      ).endOf("day")
                    );

                    if (
                      newStartDate.getTime() !==
                        dateFilter.startDate.getTime() ||
                      newEndDate.getTime() !== dateFilter.endDate.getTime()
                    ) {
                      setShowReport2(false);
                    } else {
                      setShowReport2(true);
                    }

                    setDateFilter({
                      startDate: newStartDate,
                      endDate: newEndDate,
                      key: "selection",
                    });
                  }}
                />
              </div>
            </div>
            {/* {!filterButton ? (
              <div className="DashboardFilter">
                <ButtonBase
                  variant="contained"
                  className="btns theme-solid bg-primary-dark Dashboardbtn"
                  style={{ color: "white" }}
                  onClick={FilterView}
                  disabled={activeButton == "Two" || activeGroupButton == false}
                >
                  <span className="mdi mdi-filter-menu m-1" />
                </ButtonBase>
              </div>
            ) : (
              <div className="DashboardFilter">
                <ButtonBase
                  variant="contained"
                  className="btns theme-solid bg-primary-dark Dashboardbtn"
                  style={{ color: "white" }}
                  onClick={FilterHide}
                >
                  <span className="mdi mdi-filter-minus-outline m-1" />
                </ButtonBase>
              </div>
            )} */}

            <div className="DashboardFilter">
              <ButtonBase
                onClick={onClearedAllFiltersClick}
                id="onClearedAllFiltersref"
                className="btns theme-solid bg-primary-dark clear-all-btn Dashboardbtn"
                // disabled={activeGroupButton == false || clearfilterButton == true}
              >
                <span className="mdi mdi-filter-remove m-1" />
              </ButtonBase>
            </div>
            <div className="DashboardFilter">
              <ButtonBase
                onClick={handleRefreshClick}
                id="onClearedAllFiltersref"
                className="btns theme-solid bg-primary-dark clear-all-btn Dashboardbtn"
              >
                <span className="mdi mdi-refresh m-1" />
              </ButtonBase>
            </div>
            {activeGroupButton ? (
              <div className="DashboardFilter">
                <ButtonGroup>
                  <ButtonBase
                    onClick={ChartView}
                    className={`btns theme-solid  clear-all-btn Dashboardbtn ${
                      activeButton === "One" ? "chartActive" : "chartNotActive"
                    }`}
                  >
                    <span className="mdi mdi-chart-pie m-1" />
                  </ButtonBase>

                  <ButtonBase
                    onClick={TableView}
                    className={`btns theme-solid  clear-all-btn Dashboardbtn ${
                      activeButton === "Two" ? "chartActive" : "chartNotActive"
                    }`}
                  >
                    <span className="mdi mdi-format-list-bulleted-square m-1" />
                  </ButtonBase>
                </ButtonGroup>
              </div>
            ) : (
              <div></div>
            )}
            {activeActivityGroupButton ? (
              <div className="DashboardFilter">
                <ButtonGroup>
                  <ButtonBase
                    onClick={allActivity}
                    className={`theme-outline roles-button bg-primary-dark clear-all-btn ${
                      activeButtonactivity === "One"
                        ? "buttonActive"
                        : "buttonNotActive"
                    }`}
                  >
                    All Activity{" "}
                  </ButtonBase>
                  <ButtonBase
                    onClick={leadActivity}
                    className={`theme-outline roles-button bg-primary-dark clear-all-btn ${
                      activeButtonactivity === "Two"
                        ? "buttonActive"
                        : "buttonNotActive"
                    }`}
                  >
                    Leads Activity
                  </ButtonBase>
                  <ButtonBase
                    onClick={unitsActivites}
                    className={`theme-outline roles-button bg-primary-dark clear-all-btn ${
                      activeButtonactivity === "Three"
                        ? "buttonActive"
                        : "buttonNotActive"
                    }`}
                  >
                    Units Activity
                  </ButtonBase>
                </ButtonGroup>
              </div>
            ) : (
              <div></div>
            )}
          </div>
        </div>
      )}
 

      <br />

            <PowerBIEmbed
              embedConfig={{
                type: "report",
                id,
                embedUrl,
                accessToken: report,
                filters: [
                  selectedFilter,
                  FilterLeadStatus,
                  FilterLeaseStatus,
                  advancedoprationsSales,
                  ActivityUnits,
                  ActivityLead,
                ],
                settings: {
                  customLayout: {
                    displayOption: models.DisplayOption.FitToWidth,
                  },
                  filterPaneEnabled: false,
                  navContentPaneEnabled: false,
                  panes: {
                    filters: {
                      expanded: false,
                      visible: false,
                    },
                  },
                  background: models.BackgroundType.Transparent,
                },
              }}
              eventHandlers={
                new Map([
                  [
                    "loaded",
                    (event) => {
                 
                      setDateAppaer(true);
                    },
                  ],
                  [
                    "dataSelected",

                    (event) => {
                      setShowReport2(false);
                    },
                  ],

                  [
                    "rendered",
                    (event) => {
                      setShowReport2(true);
                      loadBookmarks();
                      setRender(true);
                    },
                  ],

                  [
                    "pageChanged",
                    (event) => {
                      const newPageName = event.detail.newPage.displayName;

                      switch (newPageName) {
                        case "Achievment":
                          settransactions(true);
                          setactiveGroupButton(false);
                          setactiveActivityGroupButton(false);
                          setLeadUnitsActivity(true);
                          setUnitsActivity(true);
                          setActivity(false);
                          setUnits(false);
                          setPortaldate(true);
                          setPortal(false);
                          setInquiries(false);
                          setShowReport2(false);
                          setTimeout(() => {
                            scrollToTop();
                          }, 5);

                          break;
                        case "Inquiries":
                          settransactions(false);
                          setactiveGroupButton(false);
                          setactiveActivityGroupButton(false);
                          setLeadUnitsActivity(true);
                          setUnitsActivity(true);
                          setActivity(false);
                          setUnits(false);
                          setPortaldate(true);
                          setPortal(false);
                          setInquiries(true);
                          setShowReport2(false);
                          setTimeout(() => {
                            scrollToTop();
                          }, 5);

                          break;
                        case "Portals":
                          settransactions(false);
                          setactiveGroupButton(false);
                          setactiveActivityGroupButton(false);
                          setLeadUnitsActivity(true);
                          setUnitsActivity(true);
                          setActivity(false);
                          setUnits(false);
                          setPortaldate(false);
                          setPortal(true);
                          setInquiries(false);
                          setShowReport2(false);
                          setTimeout(() => {
                            scrollToTop();
                          }, 5);

                          break;
                        case "Activity Details":
                          settransactions(false);
                          setactiveGroupButton(false);
                          setactiveActivityGroupButton(true);

                          setActivity(true);
                          setUnits(false);
                          setPortaldate(true);
                          setPortal(false);
                          setInquiries(false);
                          setShowReport2(false);
                          setTimeout(() => {
                            scrollToTop();
                          }, 5);

                          break;
                        default:
                          settransactions(false);
                          setactiveGroupButton(true);
                          setactiveActivityGroupButton(false);
                          setLeadUnitsActivity(true);
                          setUnitsActivity(true);
                          setActivity(false);
                          setUnits(true);
                          setPortaldate(true);
                          setPortal(false);
                          setInquiries(false);
                          setdatepicker(0);
                          setShowReport2(false);
                          setdatefilter(true);
                          setTimeout(() => {
                            scrollToTop();
                          }, 5);
                          break;
                      }
                    },
                  ],

                  [
                    "error",
                    (event) => {
                      const error = event.detail;
                      if (
                        error.detailedMessage === "Get report failed" ||
                        error.detailedMessage ===
                          "Access token has expired, resubmit with a new access token" ||
                        error.detailedMessage ===
                          "Fail to initialize - Could not resolve cluster"
                      ) {
                        Microsoft(true);
                        setRender(false);
                      } else console.log(error.detailedMessage);
                      showError(error.detailedMessage);
                    },
                  ],
                ])
              }
              cssClassName={
                ShowReport
                  ? "report-style-class-Show"
                  : "report-style-class-hide"
              }
              getEmbeddedComponent={(embeddedReport) => {
                window.report = embeddedReport;
              }}
            />
              </div>
          </div>
        )}
    
    </div>
  );
};
