import React, { memo } from "react";
import {
  DatePicker,
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import moment from "moment";
import MomentUtils from "@date-io/moment";
import { Badge, ButtonBase, Icon } from "@material-ui/core";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";

import "./Calendar.scss";

const useStyles = makeStyles((theme) => ({
  arrows: {
    fontSize: "20px",
    color: theme.palette.foreground.secondary,
    height: "36px",
    width: "36px",
    padding: "0!important",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

const CalendarV2 = memo((props) => {
  const styles = useStyles();

  return (
    <MuiPickersUtilsProvider
      libInstance={moment}
      utils={MomentUtils}
      locale="en"
    >
      <div
        className={`calendars-new${props.hideHeader ? " hide-header" : ""} ${
          props.wrapperClasses
        }`}
      >
        {props.isInputPicker && (
          <KeyboardDatePicker
            disableToolbar
            variant="inline"
            format={props.format}
            margin="normal"
            id="date-picker-inline"
            label="Date picker inline"
            value={props.selectedDate}
            onChange={props.selectedDateChanged}
            KeyboardButtonProps={{
              "aria-label": "change date",
            }}
          />
        )}
        <DatePicker
          autoOk
          orientation="portrait"
          variant="static"
          openTo="date"
          value={props.selectedDate}
          onChange={props.selectedDateChanged}
          renderDay={(day, selectedDate, isInCurrentMonth, dayComponent) => {
            const currentMonth = moment(day).format();
            props.onMonthChange(currentMonth);
            const isSelected =
              isInCurrentMonth &&
              props.events.selectedDays.includes(dayComponent.props.children);
            if (
              props.withNumber &&
              props.events.selectedDays.findIndex(
                (el) => `${el}` === moment(day).format("D")
              ) !== -1 &&
              props.activities.findIndex(
                (el) => moment(el.date).format("M") === moment(day).format("M")
              ) !== -1
            ) {
              const event = props?.activities?.find(
                (el) => moment(el?.date).format("D") === moment(day).format("D")
              );

              const isExpired =
                event &&
                event?.date &&
                !moment(event.date).isSameOrAfter(moment(), "day");

              return (
                <Badge
                  className={isExpired ? "expiredEvent" : "notExpiredEvent"}
                  color="secondary"
                  overlap="circle"
                  badgeContent={""}
                >
                  {/* date that have a event */}
                  {dayComponent}
                </Badge>
              );
            }

            if (
              props.fullCalendar &&
              props.events.selectedDays.findIndex(
                (el) => `${el}` === moment(day).format("D")
              ) !== -1 &&
              props.activities.findIndex(
                (el) =>
                  el?.date &&
                  moment(el.date).format("M") === moment(day).format("M")
              ) !== -1
            ) {
              return (
                <div className="full-calendar-item">
                  {props.activities.map(
                    (item) =>
                      item?.date &&
                      moment(item.date).format("D") ===
                        moment(day).format("D") && (
                        <ButtonBase
                          onClick={() => props.onActivitySelect(item.activity)}
                          className="activity-item"
                        >
                          <div className="activity-item-subject">
                            <div className="activity-item-badge" />
                            {item.subject}
                          </div>
                          <div className="activity-item-hour">{item.hour}</div>
                        </ButtonBase>
                      )
                  )}
                  <span className="activity-date">{dayComponent}</span>
                </div>
              );
            }
            if (
              props.contactsDetails &&
              props.events.selectedDays.findIndex(
                (el) => `${el}` === moment(day).format("D")
              ) !== -1 &&
              props.activities.findIndex(
                (el) =>
                  el?.date &&
                  moment(el.date).format("M") === moment(day).format("M") &&
                  el?.date &&
                  moment(el.date).format("D") === moment(day).format("D")
              ) !== -1
            ) {
              return (
                isInCurrentMonth && (
                  <Badge
                    color="primary"
                    variant="dot"
                    overlap="circular"
                    className={
                      moment(selectedDate).format("D/M") ===
                      moment(day).format("D/M")
                        ? "selectedDay"
                        : "Badge-dot"
                    }
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "right",
                    }}
                  >
                    {dayComponent}
                  </Badge>
                )
              );
            }
            if (!isSelected) return <span>{dayComponent}</span>;
            if (props.onlyColorChanged)
              return <span className="have-events">{dayComponent}</span>;
            return <span>{dayComponent}</span>;
          }}
          leftArrowIcon={
            <Icon className={clsx("icon-left-arrow", styles.arrows)} />
          }
          rightArrowIcon={
            <Icon className={clsx("icon-right-arrow", styles.arrows)} />
          }
        />
      </div>
    </MuiPickersUtilsProvider>
  );
});

CalendarV2.propTypes = {
  selectedDate: PropTypes.objectOf(PropTypes.any).isRequired,
  selectedDateChanged: PropTypes.func.isRequired,
  hideHeader: PropTypes.bool,
  withNumber: PropTypes.bool,
  fullCalendar: PropTypes.bool,
  onActivitySelect: PropTypes.func,
  onMonthChange: PropTypes.func,
  activities: PropTypes.instanceOf(Array),
  onlyColorChanged: PropTypes.bool,
  events: PropTypes.objectOf(PropTypes.any),
  format: PropTypes.string,
  isInputPicker: PropTypes.bool,
  wrapperClasses: PropTypes.string,
};

CalendarV2.defaultProps = {
  hideHeader: true,
  withNumber: false,
  fullCalendar: false,
  onMonthChange: () => {},
  activities: [],
  onActivitySelect: undefined,
  onlyColorChanged: true,
  events: {
    selectedDays: [],
  },
  format: "",
  isInputPicker: false,
  wrapperClasses: "",
};

export { CalendarV2 };
