
export const CampaignTrackerDashboardPermissions = {
  CampaignTrackerDashboardPermissions: 
  {
    permissionsId:'42d14f26-dbf3-4d73-84c9-08dc38260b6c',
    permissionsName:'Campaign Tracker' , 
    componentsId:null,
    components:null
  }
  } ;
  