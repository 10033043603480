import React, { useCallback, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import { PropertiesAdvanceSearchTest, lookupItemsGetId , GetAllPropertyUnitTypeAPI  } from '../../../../../../../../Services';
import { UnitTypes , UnitsOperationTypeEnum  } from '../../../../../../../../Enums';
import { MyLeadUnitDetailsFields } from './MyLeadUnitDetailsStepFields';
import { PropertiesMapper } from '../../../../../../PropertiesView';

export const MyLeadUnitDetailsStep = ({
  state,
  schema,
  formType,
  selected,
  isSubmitted,
  onStateChangeHandler,
  onSelectedChangeHandler,
}) => {
  const reducer = useCallback((itemsState, action) => {
    if (action.id !== 'edit') return { ...itemsState, [action.id]: action.value };
    return {
      ...action.value,
    };
  }, []);
  const [data, setData] = useReducer(reducer, {
    property: [],
    unitType: [],
  });
  const [loadings, setLoadings] = useReducer(reducer, {
    property: false,
    unitType: false,
  });
  const getAllProperty = useCallback(async (search = '') => {
    setLoadings({ id: 'property', value: true });
    const res = await PropertiesAdvanceSearchTest(
      { pageIndex: 0, pageSize: 25 },
      { criteria: { All: [{ searchType: 2, value: search }] }, filterBy: 'createdOn', orderBy: 2 }
    );
    if (!(res && res.status && res.status !== 200)) setData({ id: 'property', value: ((res && res.result) || []).map((item) => item.propertyJson && PropertiesMapper(item, JSON.parse(item.propertyJson).property)) || [] });
    else setData({ id: 'property', value: [] });
    setLoadings({ id: 'property', value: false });
  }, []);
  const getAllUnitType = useCallback(async () => {
    setLoadings({ id: 'unitType', value: true });
    const res = await lookupItemsGetId({ lookupTypeId: UnitTypes.lookupTypeId });
    if (!(res && res.status && res.status !== 200)) setData({ id: 'unitType', value: res });
    else setData({ id: 'unitType', value: [] });
    setLoadings({ id: 'unitType', value: false });
  }, []);

  const getAllUnitTypeDependedOnUnitType = useCallback(async () => {
    if (state && state.propertyId) {
      setLoadings({ id: "unitType", value: true });

      const operationType =
        state.leadClassId === 1
          ? UnitsOperationTypeEnum.sale.key
          : state.leadClassId === 2
          ? UnitsOperationTypeEnum.rent.key
          : null;
      const res = await GetAllPropertyUnitTypeAPI(
        state.propertyId,
        operationType
      );
      if (!(res && res.status && res.status !== 200)) {
        if (res && res.length && res[0] && res[0].propertyUnitType)
          setData({ id: "unitType", value: res[0].propertyUnitType });
        else if (res && res.length === 0)
          setData({ id: "unitType", value: res });
      } else setData({ id: "unitType", value: [] });
      setLoadings({ id: "unitType", value: false });
    } else setData({ id: "unitType", value: [] });
  }, [state.propertyId]);

  useEffect(() => {
    if (
      state.leadClassId === 1 ||
      (state.leadClassId === 2 && state.propertyId)
    ) {
      getAllUnitTypeDependedOnUnitType();
    } else if (state.leadClassId === 3 || state.leadClassId === 4)
      getAllUnitType();
  }, [getAllUnitType, getAllUnitTypeDependedOnUnitType, state.propertyId]);
  useEffect(() => {
    getAllProperty();
  }, [getAllProperty]);
  return (
    <div className='add-new-lead-dialog-content-wrapper'>
      <MyLeadUnitDetailsFields
        labelClasses='Requierd-Color'
        data={data}
        state={state}
        schema={schema}
        formType={formType}
        selected={selected}
        loadings={loadings}
        isSubmitted={isSubmitted}
        getAllProperty={getAllProperty}
        onStateChangeHandler={onStateChangeHandler}
        onSelectedChangeHandler={onSelectedChangeHandler}
      />
    </div>
  );
};
MyLeadUnitDetailsStep.propTypes = {
  formType: PropTypes.number.isRequired,
  isSubmitted: PropTypes.bool.isRequired,
  state: PropTypes.instanceOf(Object).isRequired,
  schema: PropTypes.instanceOf(Object).isRequired,
  onStateChangeHandler: PropTypes.func.isRequired,
  selected: PropTypes.instanceOf(Object).isRequired,
  onSelectedChangeHandler: PropTypes.func.isRequired,
};
