import React, { useCallback, useEffect, useReducer } from "react";
import { useTranslation } from "react-i18next";
import { GetInvoicesById, GetRemainingBalanceByInvoiceId } from "../../../../../../Services";
import { CopyToClipboardComponents } from "../../../../../../ReusableComponents/UtilityComponents/CopyToClipboardComponents/CopyToClipboardComponents";
import moment from "moment";
import { formatCommasForPriceUnit } from "../../../../../../Helper/formatCommas.Helper";

export const InvoiceDetailsForm = ({
  invoiceId,
  parentTranslationPath,
  translationPath,
}) => {
  const { t } = useTranslation(parentTranslationPath);
  const reducer = useCallback((state, action) => {
    if (action.id !== "edit") return { ...state, [action.id]: action.value };
    return {
      ...action.value,
    };
  }, []);

  const [data, setData] = useReducer(reducer, {
    invoiceDetails: null,
    remainingBalance: null,
  });

  const getRemainingBalance = async () => {
    const res = await GetRemainingBalanceByInvoiceId({
      invoiceId,
    });
    if (!(res && res.status && res.status !== 200))
      if(typeof res === "number") setData({ id: "remainingBalance", value: res });
    else setData({ id: "remainingBalance", value: null });
  };

  const getInvoicesById = async () => {
    const res = await GetInvoicesById(invoiceId);
    if (!(res && res.status && res.status !== 200))
      setData({ id: "invoiceDetails", value: res });
    else setData({ id: "invoiceDetails", value: null });
  };

  useEffect(() => {
    if (invoiceId) {
      getInvoicesById();
      getRemainingBalance();
    }
  }, [invoiceId]);

  return (
    <div>
      <div className="b-bottom">
        <div className="d-flex fj-start gap-16 w-75">
          <div className="w-25 mt-2 pt-1">
            <p className="fw-simi-bold m-0 light-text">
              {t(`${translationPath}invoice-id`)}
            </p>
          </div>
          <div className="w-50 mt-2 mb-3 pt-1">
            <p className="fw-simi-bold fz-14 mb-0 text-primary">
              {invoiceId ? (
                <CopyToClipboardComponents
                  data={invoiceId}
                  childrenData={`# ${invoiceId}`}
                  labelClaass=""
                />
              ) : (
                "-"
              )}
            </p>
          </div>
        </div>
      </div>
      <div className="b-bottom">
        <div className="d-flex fj-start gap-16 w-75">
          <div className="w-25 mt-2 pt-1">
            <p className="fw-simi-bold m-0 light-text">
              {t(`${translationPath}Payment-type`)}
            </p>
          </div>
          <div className="w-50 mt-2 mb-3 pt-1">
            <p className="fw-simi-bold fz-14 mb-0 text-primary">
              {data.invoiceDetails?.paymentTypeName
                ? data.invoiceDetails.paymentTypeName
                : "-"}
            </p>
          </div>
        </div>
      </div>
      <div className="b-bottom">
        <div className="d-flex fj-start gap-16 w-75">
          <div className="w-25 mt-2 pt-1">
            <p className="fw-simi-bold m-0 light-text">
              {t(`${translationPath}Invoice-status`)}
            </p>
          </div>
          <div className="w-50 mt-2 mb-3 pt-1">
            <p className="fw-simi-bold fz-14 mb-0 text-primary">
              {data.invoiceDetails?.invoiceStateName
                ? data.invoiceDetails.invoiceStateName
                : "-"}
            </p>
          </div>
        </div>
      </div>
      <div className="b-bottom">
        <div className="d-flex fj-start gap-16 w-75">
          <div className="w-25 mt-2 pt-1">
            <p className="fw-simi-bold m-0 light-text">
              {t(`${translationPath}payment-mode`)}
            </p>
          </div>
          <div className="w-50 mt-2 mb-3 pt-1">
            <p className="fw-simi-bold fz-14 mb-0 text-primary">
              {data.invoiceDetails?.paymentModeName
                ? data.invoiceDetails.paymentModeName
                : "-"}
            </p>
          </div>
        </div>
      </div>
      <div className="b-bottom">
        <div className="d-flex fj-start gap-16 w-75">
          <div className="w-25 mt-2 pt-1">
            <p className="fw-simi-bold m-0 light-text">
              {t(`${translationPath}net-Amount`)}
            </p>
          </div>
          <div className="w-50 mt-2 mb-3 pt-1">
            <p className="fw-simi-bold fz-14 mb-0 text-primary">
              {data.invoiceDetails?.amountDue
                ? data.invoiceDetails.amountDue
                : "-"}
            </p>
          </div>
        </div>
      </div>
      <div className="b-bottom">
        <div className="d-flex fj-start gap-16 w-75">
          <div className="w-25 mt-2 pt-1">
            <p className="fw-simi-bold m-0 light-text">
              {t(`${translationPath}Date-of-payment`)}
            </p>
          </div>
          <div className="w-50 mt-2 mb-3 pt-1">
            <p className="fw-simi-bold fz-14 mb-0 text-primary">
              {data.invoiceDetails?.dueOn
                ? moment(data.invoiceDetails.dueOn).format("LL")
                : "-"}
            </p>
          </div>
        </div>
      </div>
      <div className="b-bottom">
        <div className="d-flex fj-start gap-16 w-75">
          <div className="w-25 mt-2 pt-1">
            <p className="fw-simi-bold m-0 light-text">
              {t(`${translationPath}Payment-from`)}
            </p>
          </div>
          <div className="w-50 mt-2 mb-3 pt-1">
            <p className="fw-simi-bold fz-14 mb-0 text-primary">
              {data.invoiceDetails?.invoiceContactType
                ? data.invoiceDetails.invoiceContactType
                : "-"}
            </p>
          </div>
        </div>
      </div>
      <div className="b-bottom">
        <div className="d-flex fj-start gap-16 w-75">
          <div className="w-25 mt-2 pt-1">
            <p className="fw-simi-bold m-0 light-text">
              {t(`${translationPath}remaining-balance`)}
            </p>
          </div>
          <div className="w-50 mt-2 mb-3 pt-1">
            {(data.remainingBalance ||
              (data.remainingBalance == 0)) ? (
              <div className="box w-fit">
                <span className="fz-14 fw-simi-bold">{`${formatCommasForPriceUnit(
                  data.remainingBalance
                )} AED`}</span>
              </div>
            ) : "-"}
          </div>
        </div>
      </div>
    </div>
  );
};
