import React, { useEffect, useState } from 'react';
import {
  PaginationComponent,
  Spinner,
  Tables,
  TabsComponent,
} from '../../../../../../Components';
import { PropertiesProfileMPITabsData } from './PropertiesProfileMPITabsData';
import { GetParams, bottomBoxComponentUpdate } from '../../../../../../Helper';
import DialogTitle from '@material-ui/core/DialogTitle';
import {
  GetPropertyMPI,
  GetSummaryPropertyMPIData,
} from '../../../../../../Services/PropertyMPI/PropertMPI';
import { headerData } from './PropertyMPIHeaderData';
import { Tab, Tabs, withStyles } from '@material-ui/core';
import { PropertySummaryMPIComponent } from './PropertySummaryMPIComponent';
import { useTranslation } from 'react-i18next';

const parentTranslationPath = 'PropertiesView';
const translationPath = 'MPI.';

export const PropertyProfileMPIComponent = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [data, setData] = useState({ result: [], totalCount: 0 });
  const [selectedTab, setSelectedTab] = useState('Summary');
  const [summaryData, setSummaryData] = useState({ result: [], totalCount: 0 });
  const initialFilter = {
    propertyId: +GetParams('id'),
    pageIndex: 1,
    pageSize: +localStorage.getItem('Pagination') ?? 25,
    categoryIdentifier: 2,
  };
  const [filter, setFilter] = useState(initialFilter);
  const onTabChanged = (_e, newTap) => {
    setActiveTab(newTap);
    setFilter((filter) => ({
      ...filter,
      pageIndex: 1,
      pageSize: 25,
      categoryIdentifier: newTap === 0 ? 2 : 1,
    }));
    setSelectedTab('Summary');
    setSummaryData({ result: [], totalCount: 0 });
    setData({ result: [], totalCount: 0 });
  };

  const { t } = useTranslation(parentTranslationPath);

  const getPropertyMPI = async () => {
    setIsLoading(true);
    const result = await GetPropertyMPI(filter);
    setData(result);
    setIsLoading(false);
  };

  const handleChangeTabSummary = (_event, newValue) => {
    setSelectedTab(newValue);
    setFilter((filter) => ({
      ...initialFilter,
      categoryIdentifier: filter.categoryIdentifier,
    }));
  };

  const getSummaryPropertyMPI = async () => {
    setIsLoading(true);
    const result = await GetSummaryPropertyMPIData(filter);
    if (!(result.status && result.status !== 200)) {
      setSummaryData(result);
    }
    setIsLoading(false);
  };
  useEffect(() => {
    if (selectedTab === 'Summary') {
      getSummaryPropertyMPI();
    } else {
      getPropertyMPI();
    }
  }, [filter]);

  useEffect(() => {
    if (selectedTab === 'Summary') {
      bottomBoxComponentUpdate(
        <PaginationComponent
          pageIndex={filter.pageIndex - 1}
          totalCount={summaryData.totalCount}
          pageSize={filter.pageSize}
          onPageIndexChanged={(index) =>
            setFilter((filter) => ({ ...filter, pageIndex: index }))
          }
          onPageSizeChanged={(size) =>
            setFilter((filter) => ({ ...filter, pageSize: size }))
          }
          perPageText='row-per-page'
          translationPath=''
          parentTranslationPath='Shared'
        />
      );
    }
  }, [summaryData.totalCount, filter]);

  const CustomTab = withStyles({
    root: {
      textTransform: 'none', // Prevent text transformation
    },
  })(Tab);

  return (
    <div className='properties-information-wrapper childs-wrapper b-0 properties-specification-wrapper'>
      <>
        <TabsComponent
          data={PropertiesProfileMPITabsData}
          labelInput='tab'
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          themeClasses='theme-curved'
          currentTab={activeTab}
          onTabChanged={onTabChanged}
        />
        <div>
          <div className='title-section p-2'>
            <div className='sub-title-properties-MPI'>MPI</div>
            <Tabs
              value={selectedTab}
              indicatorColor='primary'
              textColor='primary'
              onChange={handleChangeTabSummary}
            >
              <CustomTab
                value='Summary'
                label={t(`${translationPath}summary`)}
                sx={{ textTransform: 'none' }}
              />
              <CustomTab
                value='Details'
                label={t(`${translationPath}details`)}
                sx={{ textTransform: 'none' }}
              />
            </Tabs>
          </div>
          {selectedTab === 'Details' ? (
            <Tables
              data={data.result}
              totalItems={data.totalCount}
              headerData={headerData}
              isLoading={isLoading}
              itemsPerPage={filter.pageSize}
              activePage={filter.pageIndex - 1}
              onPageIndexChanged={(index) =>
                setFilter((filter) => ({ ...filter, pageIndex: index + 1 }))
              }
              onPageSizeChanged={(size) =>
                setFilter((filter) => ({ ...filter, pageSize: size }))
              }
              parentTranslationPath={parentTranslationPath}
              translationPath={translationPath}
              defaultActions={[]}
            />
          ) : (
            <PropertySummaryMPIComponent
              isLoading={isLoading}
              summaryData={summaryData.result}
              parentTranslationPath={parentTranslationPath}
              translationPath={translationPath}
            />
          )}
        </div>
      </>
    </div>
  );
};
