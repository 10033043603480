export const ActivityRecipientTypesEnum = {
  Agent: {
    id: 0,
    value: "Agent",
    text: "Agent",
  },
  Client: {
    id: 1,
    value: "Client",
    text: "Client",
  },
  Creator: {
    id: 2,
    value: "Creator",
    text: "Creator",
  },
  TeamLead: {
    id: 3,
    value: "TeamLead",
    text: "TeamLead",
  },
};
