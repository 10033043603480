import React, { useCallback, useEffect, useReducer, useState } from "react";
import {
  AutocompleteComponent,
  Inputs,
} from "../../../../../../../../Components";
import {
  ButtonBase,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { RiskRatingConfigEnum } from "../../../../../../../../Enums";
import { UpdateKycRiskRating } from "../../../../../../../../Services";
import { showSuccess, showWarn } from "../../../../../../../../Helper";

export const RiskRatingDialog = ({
  onSave,
  activeItem,
  open,
  close,
  parentTranslationPath = "",
  translationPath = "",
}) => {
  const { t } = useTranslation([parentTranslationPath, "Shared"]);
  const reducer = useCallback((state, action) => {
    if (action.id !== "edit") return { ...state, [action.id]: action.value };
    return {
      ...action.value,
    };
  }, []);

  const [isLoading, setIsLoading] = useState(false);
  const [selected, setSelected] = useReducer(reducer, {
    prevRiskRatingStatus: null,
    newRiskRatingStatus: null,
  });

  const updateKycRiskRating = async () => {
    setIsLoading(true);
    const body = {
      sharedKycDocumentId: activeItem.sharedKycDocmentHistoryId || null,
      newRiskRatingStatus:
        selected.newRiskRatingStatus?.value ||
        activeItem?.riskRatingDurationTypeInfo?.enumId ||
        null,
      reason: selected.reason,
    };
    const res = await UpdateKycRiskRating(body);
    if (!(res && res.status && res.status !== 200)) {
      showSuccess(`Changes saved successfully!`);
      onSave();
    } else {
      showSuccess(`Changes save failed..`);
    }
    setIsLoading(false);
  };

  const fillInitialData = () => {
    const prevStatusItem =
      Object.values(RiskRatingConfigEnum).find(
        (item) => item.value === activeItem.riskRatingDurationTypeInfo?.enumId
      ) || null;
    setSelected({
      id: "edit",
      value: {
        prevRiskRatingStatus: prevStatusItem,
        newRiskRatingStatus: prevStatusItem,
      },
    });
  };

  const saveHandler = (event) => {
    event.preventDefault();

    if(!selected.reason) {
      showWarn(`Please add the reason of this change . `);
      return;
    }
    updateKycRiskRating();
  };

  useEffect(() => {
    if (activeItem) fillInitialData();
  }, [activeItem]);

  return (
    <Dialog
      open={open}
      onClose={() => {
        close();
      }}
      className="RiskRatingView"
    >
      <form noValidate onSubmit={saveHandler}>
        <DialogTitle id="alert-dialog-slide-title">
          <span>{t(`${translationPath}Risk Rating`)}</span>
        </DialogTitle>
        <DialogContent>
          <div className="d-flex">
            <AutocompleteComponent
              idRef="jurisdictionRiskRef"
              selectedValues={selected.prevRiskRatingStatus}
              labelValue="From*"
              inputPlaceholder={t(`${translationPath}select`)}
              wrapperClasses="w-50 mb-2 mr-2"
              data={Object.values(RiskRatingConfigEnum)}
              displayLabel={(option) => option.name || ""}
              isDisabled
              inputStartAdornment={
                <span
                  className={`RateDot small-dot ml-3 ${
                    selected.prevRiskRatingStatus?.class || ""
                  }`}
                ></span>
              }
              multiple={false}
              withoutSearchButton
            />
            <AutocompleteComponent
              idRef="jurisdictionRiskRef"
              selectedValues={selected.newRiskRatingStatus}
              labelValue="To*"
              inputPlaceholder={t(`${translationPath}select`)}
              wrapperClasses="w-50 mb-2 mx-2"
              data={Object.values(RiskRatingConfigEnum)}
              displayLabel={(option) => option.name || ""}
              inputStartAdornment={
                <span
                  className={`RateDot small-dot ml-3 ${
                    selected.newRiskRatingStatus?.class || ""
                  }`}
                ></span>
              }
              multiple={false}
              withoutSearchButton
              isWithError
              onChange={(_, newValue) =>
                setSelected({ id: "newRiskRatingStatus", value: newValue })
              }
            />
          </div>
          <Inputs
            idRef="reasonRef"
            labelValue="Reason"
            value={selected.reason}
            multiline
            rows={4}
            parentTranslationPath={parentTranslationPath}
            translationPath={translationPath}
            onInputChanged={(event) => {
              const { value } = event.target;
              setSelected({ id: "reason", value: value });
            }}
          />
        </DialogContent>
        <DialogActions>
          <ButtonBase
            onClick={() => close()}
            className={`btns theme-solid bg-cancel`}
          >
            {t(`${translationPath}Cancel`)}
          </ButtonBase>
          <ButtonBase
            className={`btns theme-solid`}
            type="save"
            isDisabled={isLoading}
          >
            {t(`${translationPath}Save`)}
          </ButtonBase>
        </DialogActions>
      </form>
    </Dialog>
  );
};
