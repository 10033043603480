import { makeStyles } from "@material-ui/core/styles";
import { useIsAr } from "../../../Hooks";

const useStyles = makeStyles((theme) => {
  const { isAr } = useIsAr(theme.direction);

  return {
    popoverWrapper: {
      "& .MuiPaper-root": {
        minWidth: "100px",
        borderRadius: "12px",
        display: "flex",
        flexWrap: "wrap",
        border: `1px solid ${theme.palette.border.secondary}`,
      },
    },
    withBackdrop: {
      // backgroundColor: "rgba(0, 0, 0, 0.3) !important", // TODO: use the palette colors and make them dynamic
    },
  };
});

export default useStyles;
