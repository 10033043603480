import { createAction } from '@reduxjs/toolkit';
const UNIT_GET = createAction('UNIT_GET');
const UNIT_GET_SUCCESS = createAction('UNIT_GET_SUCCESS');
const UNIT_GET_FAIL = createAction('UNIT_GET_FAIL');
const UNIT_RESET = createAction('UNIT_RESET');


const UNIT_DETAILS_GET = createAction('UNIT_DETAILS_GET');
const UNIT_DETAILS_GET_SUCCESS = createAction('UNIT_DETAILS_GET_SUCCESS');
const UNIT_DETAILS_GET_FAIL = createAction('UNIT_DETAILS_GET_FAIL');

const UNIT_DETAILS_GET_DIALOG = createAction('UNIT_DETAILS_DIALOG_GET');
const UNIT_DETAILS_GET_DIALOG_SUCCESS = createAction('UNIT_DETAILS_GET_DIALOG_SUCCESS');
const UNIT_DETAILS_GET_DIALOG_FAIL = createAction('UNIT_DETAILS_GET_DIALOG_FAIL');

const UNIT_DETAILS_RESET = createAction('UNIT_DETAILS_RESET');

const UNIT_DETAILS_FIELD_GET = createAction('UNIT_DETAILS_FIELD_GET');
const UNIT_DETAILS_FIELD_GET_SUCCESS = createAction('UNIT_DETAILS_FIELD_GET_SUCCESS');
const UNIT_DETAILS_FIELD_GET_FAIL = createAction('UNIT_DETAILS_FIELD_GET_FAIL');

const UNIT_DETAILS_PUT = createAction('UNIT_DETAILS_PUT');
const UNIT_DETAILS_PUT_SUCCESS = createAction('UNIT_DETAILS_PUT_SUCCESS');
const UNIT_DETAILS_PUT_FAIL = createAction('UNIT_DETAILS_PUT_FAIL');

const UNIT_POST = createAction('UNIT_POST');
const UNIT_POST_SUCCESS = createAction('UNIT_POST_SUCCESS');
const UNIT_POST_FAIL = createAction('UNIT_POST_FAIL');

const UNIT_BY_ID_PUT = createAction('UNIT_PUT');
const UNIT_BY_ID_PUT_SUCCESS = createAction('UNIT_PUT_SUCCESS');
const UNIT_BY_ID_PUT_FAIL = createAction('UNIT_PUT_FAIL');

const UNIT_SEARCH_GET = createAction('UNIT_SEARCH_GET');
const UNIT_SEARCH_GET_SUCCESS = createAction('UNIT_SEARCH_GET_SUCCESS');
const UNIT_SEARCH_FAIL = createAction('UNIT_SEARCH_FAIL');


export {
	UNIT_GET,
	UNIT_GET_SUCCESS,
	UNIT_GET_FAIL,
	UNIT_RESET,
	UNIT_DETAILS_GET,
	UNIT_DETAILS_GET_SUCCESS,
	UNIT_DETAILS_GET_FAIL,
	UNIT_DETAILS_GET_DIALOG,
	UNIT_DETAILS_GET_DIALOG_SUCCESS,
	UNIT_DETAILS_GET_DIALOG_FAIL,
	UNIT_DETAILS_FIELD_GET,
	UNIT_DETAILS_FIELD_GET_SUCCESS,
	UNIT_DETAILS_FIELD_GET_FAIL,
	UNIT_DETAILS_RESET,
	UNIT_DETAILS_PUT,
	UNIT_DETAILS_PUT_SUCCESS,
	UNIT_DETAILS_PUT_FAIL,
	UNIT_POST,
	UNIT_POST_SUCCESS,
	UNIT_POST_FAIL,
	UNIT_BY_ID_PUT,
	UNIT_BY_ID_PUT_SUCCESS,
	UNIT_BY_ID_PUT_FAIL,
	UNIT_SEARCH_GET,
	UNIT_SEARCH_GET_SUCCESS,
	UNIT_SEARCH_FAIL
};