export const RotationSchemaPermissions = {
  ViewAllRotationSchema: {
    permissionsId: 'efa9f7d7-0111-4b57-f9e7-08d9689a2271',
    permissionsName: 'View All Rotation Schema',
    componentsId: null,
    components: null
  },
  ViewRotationSchemaAssignNewAgent: {
    permissionsId: '683e7a61-29a0-4ccd-f9e8-08d9689a2271',
    permissionsName: 'View Rotation Schema Assign New Agent',
    componentsId: null,
    components: null
  },
  SalesLeaseAgents: {
    permissionsId: 'c3197f6b-0d9c-4231-f9e9-08d9689a2271',
    permissionsName: 'Sales/Lease Agents',
    componentsId: null,
    components: null
  },
  ListingAgents: {
    permissionsId: 'd439cee9-ff98-4df9-f9ea-08d9689a2271',
    permissionsName: 'Listing Agents',
    componentsId: null,
    components: null
  },
  EditRotations: {
    permissionsId: '96a8a93d-0899-4b52-f9eb-08d9689a2271',
    permissionsName: 'Edit Rotations',
    componentsId: null,
    components: null
  },
  DeleteRotations: {
    permissionsId: '0f96aa10-01f5-43be-f9ec-08d9689a2271',
    permissionsName: 'Delete Rotations',
    componentsId: null,
    components: null
  },
  CreateNewRotations: {
    permissionsId: '65f2eac5-83d8-44db-f9ed-08d9689a2271',
    permissionsName: 'Create New Rotations',
    componentsId: null,
    components: null
  } , 
  SetARotationAsActiveDeactivated :{
     permissionsId: "38f4a2c6-fd4d-4ac4-2878-08dbaf79a1bb",
     permissionsName: "Set A Rotation As Active , Deactivated",
     description: null,
     componentsId: null,
     components: null
},
ActiveDeactiveAgents : {
    permissionsId: "16988118-33a8-4698-2879-08dbaf79a1bb",
    permissionsName: "Active , Deactive Agents",
    description: null,
    componentsId: null,
    components: null
} , 
RotationSchemaHistory :{
  permissionsId: "0d737296-5066-4404-f947-08dc0f48c1d2",
  permissionsName: "Rotation Schema History",
  description: null,
  componentsId: null,
  components: null
},
ManageAgentHistory :{
   permissionsId: "811ebfd3-de8d-47e0-f948-08dc0f48c1d2",
   permissionsName: "Manage Agent History",
   description: null,
   componentsId: null,
   components: null
}


};
