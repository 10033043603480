import React, { useCallback, useEffect, useState } from "react";
import { PowerBIEmbed } from "powerbi-client-react";
import { models } from "powerbi-client";
import "../MasterStyleDashborads/MasterStyleDashboards.scss";
import {
  GetUserManagedById,
  MicrosoftToken,
} from "../../../../Services/DashboardService";
import { PowerBiEnum } from "../../../../Enums";
import { GetUserTeamsInfo } from "../../../../Services/userServices";
import { GlobalTranslate, showError } from "../../../../Helper";
import { useTitle } from "../../../../Hooks";
import { SpinnerDashboards } from "../SpinnerDashboards/SpinnerDashboards";
import { PermissionDeniedLayout } from "../../../../Layouts/PermissionDeniedLayout/PermissionDeniedLayout";
import { CellSpinnerBi } from "../CellSpinnerBi/CellSpinnerBi";
import { GetUserId } from "../../../../Services";
import { ButtonBase } from "@material-ui/core";

export const ProjectTask = () => {
  useTitle(GlobalTranslate.t("Dashboard:callcenterteamlead-dashboard"));
  const userid = JSON.parse(localStorage.getItem("session")).userId;
  const [reporttoken, setReporttoken] = useState();
  const [teamid, setTeamid] = useState([]);
  const [mangerid, setmangerid] = useState([]);
  const [Appuser, setAppuser] = useState();
  const [render, setRender] = useState(false);
  const [ShowReport, setShowReport] = useState(false);
  const [ShowReport2, setShowReport2] = useState(false);

  const [filterButton, setfilterButton] = useState(false);
  const [clearfilterButton, setclearfilterButton] = useState(false);
  const [activeThemeButton, setActiveThemeButton] = useState("blue");

  const [MangerFilter, setMangerFilter] = useState(false);
  const [TeamsFilter, setTeamsFilter] = useState(false);
  const [UsersFilter, setUsersFilter] = useState(true);


  const loadBookmarks = async () => {
    const report = window.report;

    if (report) {
      try {
        const bookmarksManager = report.bookmarksManager;
        const fetchedBookmarks = await bookmarksManager.getBookmarks();
        console.log("Bookmarks:", fetchedBookmarks);
      } catch (error) {
        console.error("Error loading bookmarks:", error);
      }
    }
  };
  const applyBookmark = async (bookmarkName) => {
    const report = window.report;
    if (report) {
      try {
        const bookmarksManager = report.bookmarksManager;
        bookmarksManager.apply(bookmarkName);
        // console.log("Bookmark applied successfully:", bookmarkName);
      } catch (error) {
        console.error("Error applying bookmark:", error);
      }
    }


  };

  // const handleButtonClick = (buttonName) => {
  //   setActiveButton(buttonName);
  // };

  const FilterHide = () => {
    applyBookmark("Bookmark4c3de8eb5c52742fcc38");
    setfilterButton(false);
    setclearfilterButton(false);
  };
  const FilterView = () => {
    applyBookmark("Bookmarka5745a4a838f60fc75cf");
    setfilterButton(true);
    setclearfilterButton(true);
  };

  const handleRefreshClick = useCallback(() => {
    window.report
      .refresh()
      .then(() => {
        setShowReport2(false);
      })
      .catch((error) => {});
  }, []);


  const onClearedAllFiltersClick = useCallback(() => {
    applyBookmark("Bookmark58dccb4e19e3f49ada74");
 
  }, []);

  const Microsoft = async (isExpired) => {
    const res = await MicrosoftToken(isExpired);
    if (!(res && res.status && res.status !== 200)) {
      setReporttoken(res);
      setAppuser(userid);
      setTimeout(() => {
        setRender(true);
      }, 500);
    } else {
      setReporttoken("");
      showError("Contact Your Bi Admin For Help");
    }
  };
  const FilterManger = {
    $schema: "http://powerbi.com/product/schema#basic",
    target: {
      table: "View_TeamUsersTree",
      column: "TeamsId",
    },
    operator: "In",
    values: [...mangerid, ...teamid],
    filterType: models.FilterType.BasicFilter,
    requireSingleSelection: true,
  };
  const FilterTeam = {
    $schema: "http://powerbi.com/product/schema#basic",
    target: {
      table: "View_TeamUsersTree",
      column: "TeamsId",
    },
    operator: "In",
    values: teamid,
    filterType: models.FilterType.BasicFilter,
    requireSingleSelection: true,
  };
  const FilterUser = {
    $schema: "http://powerbi.com/product/schema#basic",
    target: {
      table: "View_Users",
      column: "UsersIds",
    },
    operator: "In",
    values: [Appuser],
    filterType: models.FilterType.BasicFilter,
    requireSingleSelection: true,
  };

  const getUserManagedById = useCallback(async () => {
    const res = await GetUserManagedById(userid);
    if (!(res && res.status && res.status !== 200)) {
      setmangerid(res && res.map((List) => List.teamsId || null));
      setMangerFilter(true);
      setUsersFilter(false);
    } else {
      setmangerid([]);
    }
  }, [userid]);

  const getUserTemasById = useCallback(async () => {
    const res = await GetUserTeamsInfo(userid, true);
    if (!(res && res.status && res.status !== 200) && res.length > 0) {
      setTeamid(res && res.map((List) => List.teamsId || null));
      setTeamsFilter(true);
      setUsersFilter(false);
    } else {
      setTeamid([]);
    }
  }, [userid]);

  const getAppUserId = useCallback(async () => {
    const res = await GetUserId(userid);
    if (!(res && res.status && res.status !== 200)) {
      setAppuser(res);
    } else {
      setAppuser([]);
    }
  }, [userid]);

  useEffect(() => {
    getUserManagedById(userid);

    if (!MangerFilter) {
      getUserTemasById(userid);
    } else if (TeamsFilter) {
      getUserTemasById(userid);
    } else if (MangerFilter && TeamsFilter) {
      getUserManagedById(userid);
      getUserTemasById(userid);
    } else if (!MangerFilter && !TeamsFilter) {
      setAppuser(userid);
    }
  }, [
    MangerFilter,
    TeamsFilter,
    getUserManagedById,
    getUserTemasById,
    UsersFilter,
    userid,
    Appuser,
  ]);

  let selectedFilter;
  switch (true) {
    case MangerFilter:
      selectedFilter = FilterManger;
      break;

    case TeamsFilter:
      selectedFilter = FilterTeam;
      break;
    case UsersFilter:
      selectedFilter = FilterUser;
      break;
  }

  // useEffect(() => {
  //   getUserManagedById(userid);
  // }, [MangerFilter,getUserManagedById]);

  // useEffect(() => {
  //   if (MangerFilter === false) {
  //     getUserTemasById(userid);
  //   }
  // }, [TeamsFilter, getUserTemasById]);

  // useEffect(() => {
  //   if (MangerFilter === true && TeamsFilter === true) {
  //     getUserManagedById(userid);
  //     getUserTemasById(userid);
  //   }
  // }, [
  //   MangerFilter,
  //   TeamsFilter,
  //   getUserTemasById,
  //   getUserManagedById,
  // ]);

  // useEffect(() => {
  //   if (TeamsFilter === false && MangerFilter === false) {
  //     setAppuser(userid);
  //   }
  // }, [UsersFilter]);

  // useEffect(() => {
  //   getUserTemasById(userid);
  //   getUserManagedById(userid);
  //   getAppUserId(userid);
  // }, []);

  useEffect(() => {
    Microsoft(false);
  }, []);

  return (
    <div className="dashboardMain-PowerBIEmbed">
                <CellSpinnerBi isActive={!ShowReport} isAbsolute blue />

          <div className="MainFiltersBi">
          <CellSpinnerBi
                isActive={!ShowReport2}
                blue={activeThemeButton === "blue"}
              />
            {MangerFilter || TeamsFilter ? (  <div className="DashboardFilter">
           {!filterButton  ? (

                <div className="DashboardFilter">
                  <ButtonBase
                    variant="contained"
                    className="btns theme-solid bg-primary-dark Dashboardbtn"
                    style={{ color: "white" }}
                    onClick={FilterView}
                  
                  >
                    <span className="mdi mdi-filter-menu m-1" />
                  </ButtonBase>
                </div>
              ) : (
                <div className="DashboardFilter">
                  <ButtonBase
                    variant="contained"
                    className="btns theme-solid bg-danger Dashboardbtn"
                    style={{ color: "white" }}
                    onClick={FilterHide}
                  >
                    <span className="mdi mdi-filter-minus-outline m-1" />
                  </ButtonBase>
                </div>
              )}

              <div className="DashboardFilter">
                <ButtonBase
                  onClick={onClearedAllFiltersClick}
                  id="onClearedAllFiltersref"
                  className="btns theme-solid bg-primary-dark clear-all-btn Dashboardbtn"
              
                >
                  <span className="mdi mdi-filter-remove m-1" />
                </ButtonBase>
              </div>
              </div>) : (<div></div>)}
        
              <div className="DashboardFilter">
                <ButtonBase
                  onClick={handleRefreshClick}
                  id="onClearedAllFiltersref"
                  className="btns theme-solid bg-primary-dark clear-all-btn Dashboardbtn"
                >
                  <span className="mdi mdi-refresh m-1" />
                </ButtonBase>
              </div>
           
              </div>
           
         <br />
      {render ? (


<div className="mainCardDashboards">
<div
            className={`dashboardMain  ${
              activeThemeButton === "gold"
                ? "cardDashboardgold"
                : "cardDashboard"
            }`}
          >

          <PowerBIEmbed
            embedConfig={{
              type: "report",
              id: PowerBiEnum.Projecttask.reportid,
              embedUrl: PowerBiEnum.Projecttask.url,
              accessToken: reporttoken,
              filters: [selectedFilter],
              settings: {
                customLayout: {
                  displayOption: models.DisplayOption.FitToWidth,
                },
                filterPaneEnabled: false,
                navContentPaneEnabled: false,
                panes: {
                  filters: {
                    expanded: false,
                    visible: false,
                  },
                },
                background: models.BackgroundType.Transparent,
              },
            }}
            eventHandlers={
              new Map([
                [
                  "loaded",
                  () => {
                    setShowReport(true);
                    setShowReport2(false);
                  },
                ],
                [
                  "dataSelected",

                  (event) => {
                    setShowReport2(false);
                  },
                ],

                ["rendered", () => {
                  setShowReport2(true);
                  loadBookmarks();
                }],
                [
                  "error",
                  (event) => {
                    const error = event.detail;

                    if (
                      error.detailedMessage === "Get report failed" ||
                      error.detailedMessage ===
                        "Access token has expired, resubmit with a new access token" ||
                      error.detailedMessage ===
                        "Fail to initialize - Could not resolve cluster"
                    ) {
                      Microsoft(true);
                      setRender(false);
                    } else console.log(error.detailedMessage);
                    showError(error.detailedMessage);
                  },
                ],
              ])
            }
            cssClassName={
              ShowReport ? "report-style-class-Show" : "report-style-class-hide"
            }
            getEmbeddedComponent={(embeddedReport) => {
              window.report = embeddedReport;
            }}
          />
        </div>
        </div>
      ) : (
        <div>{render && <PermissionDeniedLayout />}</div>
      )}
    </div>
  );
};
