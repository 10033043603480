import { HttpServices } from '../../Helper';
import { config } from '../../config';

export const GetInquiryRotationSchemes = async (body) => {
  try {
    const result = await HttpServices.post(
      `${config.server_address}/CrmDfm/InquiryRotationScheme/GetInquiryRotationSchemes`,
      body
    );
    return result;
  } catch (error) {
    return error.response;
  }
};

export const CreateInquiryRotationScheme = async (body) => {
  try {
    const result = await HttpServices.post(
      `${config.server_address}/CrmDfm/InquiryRotationScheme/CreateInquiryRotationScheme`,
      body
    );
    return result;
  } catch (error) {
    return error.response;
  }
};
export const UpdateInquiryRotationScheme = async (body) => {
  try {
    const id = body.id;
    delete body.id;
    const result = await HttpServices.put(
      `${config.server_address}/CrmDfm/InquiryRotationScheme/UpdateInquiryRotationScheme/${id}`,
      body
    );
    return result;
  } catch (error) {
    return error.response;
  }
};
export const GetInquiryRotationSchemeById = async (id) => {
  try {
    const result = await HttpServices.get(
      `${config.server_address}/CrmDfm/InquiryRotationScheme/GetInquiryRotationSchemeById/${id}`
    );
    return result;
  } catch (error) {
    return error.response;
  }
};
export const DeleteInquiryRotationScheme = async (id) => {
  try {
    const result = await HttpServices.delete(
      `${config.server_address}/CrmDfm/InquiryRotationScheme/DeleteInquiryRotationScheme/${id}`
    );
    return result;
  } catch (error) {
    return error.response;
  }
};
