


import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { bottomBoxComponentUpdate } from '../../../../Helper';
import {
  NoContentComponent,
  NoSearchResultComponent,
  PaginationComponent,
  Spinner,
} from '../../../../Components';
import { GetAllPortalAccountsAPI , GetPortalAccountAPI } from '../../../../Services';
import { PortalAccountsTableView } from './PortalAccountUtilities' ; 


export const PortalAccoutsView = ({
  parentTranslationPath,
  translationPath,
  isReloadPortalAccountData , 
  setIsReloadPortalAccountData
 }) => {
  const { t } = useTranslation(parentTranslationPath);


  const [filter, setFilter] = useState({
    search: "",
    pageIndex: 0,
    pageSize: parseInt(localStorage.getItem('Pagination')) || 25,
  });

  const [portalAccout, setPortalAccout] = useState(null);
  const [activeItem, setActiveItem] = useState(null);

  const [isLoading, setIsLoading] = useState({
    allPortalsAccountsData : false,
    portalAccount : false 
  });

  const [allPortalsAccouts, setAllPortalAccouts] = useState({
    result: [],
    totalCount: 0,
  });


  const onPageIndexChanged = (pageIndex) => {
    setFilter((item) => ({ ...item, pageIndex }));
  };
  const onPageSizeChanged = (pageSize) => {
    setFilter((item) => ({ ...item, pageIndex: 0, pageSize }));
  };
  const reloadData = () => {
    setFilter((item) => ({ ...item, pageIndex: 0 }));
  };



  const GetAllPortalAccounts = useCallback(async () => {

    setIsLoading((item) => ({ ...item, allPortalsAccountsData: true }));
      const res = await GetAllPortalAccountsAPI({ ...filter, pageIndex: (filter.pageIndex + 1) });
      if (!((res && res.data && res.data.ErrorId) || !res)) {
        setAllPortalAccouts({
          result: (res && res.result) || [],
          totalCount: (res && res.totalCount) || 0,
        });
      } else {
        setAllPortalAccouts({
          result: [],
          totalCount: 0,
        });
      }
    setIsLoading((item) => ({ ...item, allPortalsAccountsData: false }));
  }, [filter , isReloadPortalAccountData]);

  const GetPortalAccountById = useCallback(async () => {
    setIsLoading((item) => ({ ...item, portalAccount: true }));
    const res = await GetPortalAccountAPI(activeItem && activeItem.portalAccountId);
    if (res){
      setPortalAccout(res);
    }
    else setPortalAccout(null);
    setIsLoading((item) => ({ ...item, portalAccount: false }));

  }, [activeItem]);


  useEffect(() => {
    GetAllPortalAccounts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter , isReloadPortalAccountData]);

  useEffect(() => {
      bottomBoxComponentUpdate(
        <PaginationComponent
          pageIndex={filter.pageIndex}
          pageSize={filter.pageSize}
          totalCount={allPortalsAccouts.totalCount}
          onPageIndexChanged={onPageIndexChanged}
          onPageSizeChanged={onPageSizeChanged}
          translationPath=''
          parentTranslationPath='Shared'
        />
      );
  }, [filter , allPortalsAccouts]);

  useEffect(
    () => () => {
      bottomBoxComponentUpdate(null);
    },
    []
  );


  useEffect(() => {
    if(activeItem)
     GetPortalAccountById();
  }, [activeItem]);


  return (
    <>
      <>
      <Spinner isActive={(isLoading && isLoading.allPortalsAccountsData)} isAbsolute />
          {((allPortalsAccouts && allPortalsAccouts.result && allPortalsAccouts.result.length === 0) || (allPortalsAccouts && allPortalsAccouts.totalCount === 0)) ? (
            <NoSearchResultComponent />
          ) : (

            (allPortalsAccouts && allPortalsAccouts.totalCount === 0 && (
              <NoContentComponent />
            )) || (
                <>
                  <div className='view-wrapers mt-4 px-2'>
                   <PortalAccountsTableView
                      translationPath={translationPath}
                      data={allPortalsAccouts}
                      filter={filter}
                      parentTranslationPath={parentTranslationPath}
                      setActiveItem={setActiveItem}
                      setFilter={() => setFilter((item) => ({ ...item, pageIndex: 0 }))}
                      activeItem={activeItem}
                      isLoading={isLoading}
                      onPageSizeChanged={onPageSizeChanged}
                      onPageIndexChanged={onPageIndexChanged}
                      isReloadPortalAccountData={isReloadPortalAccountData}
                      setIsReloadPortalAccountData={setIsReloadPortalAccountData}
                      portalAccout={portalAccout}
                      setPortalAccout={setPortalAccout}
                    /> 
                  </div>
                </>
              ))}
        </>
    </>
  );
};
