import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => {
  return {
    socialLinksContainer: (props) => ({
      display: "flex",
      gap: `${props.gap}px`,
    }),
    socialLinkIcon: {
      boxShadow: theme.shadows[1],
      padding: '4px',
      
      borderRadius: theme.borderRadius[9],
      
      background: theme.palette.button?.lightGray_bg,

      border: `1px solid ${theme.palette.button?.lightGray_border}`,

      '&:hover': {
        background: `${theme.palette.button.lightGray_bg_hover}`,
        boxShadow: "none",
        border: `1px solid ${theme.palette.button?.lightGray_border_hover}`,
      },

      '&:focus': {
        boxShadow: theme.shadows[14],
      },

      '&:disabled': {
        '& svg, & svg path': {
          fill: theme.palette.foreground.disabled,
        },
        border: `1px solid ${theme.palette.border.disabled_subtle}`,
      },
    }
  };
});
