export const UnitForEnum = [
  {
    key: 1,
    value: 'invesment',
  },
  {
    key: 2,
    value: 'live-in',
  },
];
