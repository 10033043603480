import { DubaiLeaseAdmin } from "../DubaiLeaseAdmin";
import { DubaiLeaseAgent } from "../DubaiLeaseAgent";
import { DubaiSaleAdmin } from "../DubaiSaleAdmin";
import { DubaiSaleAgent } from "../DubaiSaleAgent";

export const DashboardListingTabs = [
  {
    index: 0,
    label: "Dashboard:lease-listing-admin",
    component: DubaiLeaseAdmin,
 
    permissionsId: "71b14c93-c3de-49b7-ee42-08da65738802",
  },
  {
    index: 1,
    label: "Dashboard:lease-listing-agent",
    component: DubaiLeaseAgent,
 
    permissionsId: "71b14c93-c3de-49b7-ee42-08da65738802",
  },
  {
    index: 2,
    label: "Dashboard:sales-listing-admin",
    component: DubaiSaleAdmin,
    permissionsId: "103383c1-c489-465e-ee41-08da65738802",

  },
  {
   
    index: 3,
    label: "Dashboard:sales-listing-agent",
    component: DubaiSaleAgent,
    permissionsId: "103383c1-c489-465e-ee41-08da65738802",

  }
];
