import React from 'react';
import { UnitsFilterStatusEnum } from '../../../../../Enums';

const getValuecolor = (Value) => {
  let textcolor;
  switch (Value) {
    case 'Available':
      textcolor = 'c-success-light';
      break;
    case 'ReservedSale':
      textcolor = 'globel-bold-style c-primary';
      break;
    case 'Sale':
      textcolor = 'globel-bold-style c-primary';
      break;
    case 'Draft':
      textcolor = 'globel-Gry-style';
      break;
    case 'PendingSale':
      textcolor = 'globel-bold-style c-pending';
      break;
    case 'PendingReserveSale':
      textcolor = 'globel-bold-style c-pending';
      break;
    default:
      textcolor = '';
  }
  return textcolor;
};
export const UnitsSalesTableHeaderData = [
  {
    id: 1,
    label: 'Id',
    fieldKey: 'unitId',
    input: 'unitId',
    isSortable: true,
    isHiddenFilter: true,
    fieldType: 'text',
    isNumber: true,
    component: (item) => (item && item.id) || <span />,
  },
  {
    id: 2,
    label: 'Name',
    input: 'UnitName',
    fieldType: 'text',
    isHiddenFilter: false,
    isDefaultFilterColumn: true,
    isSortable: true,
    component: (item) => (item && item.name) || <span />,
  },
  {
    id: 3,
    label: 'Created By',
    input: 'createdBy',
    fieldKey: 'createdBy',
    fieldType: 'searchField',
    isHiddenFilter: false,
    isDefaultFilterColumn: true,
    isSortable: true,
    data: {
      type: 'string',
      title: 'Created By',
      description: 'General Information',
      searchKey: 'User',
      iconField: '',
      dependOn: '',
      multi: 'false',
      default: '',
      isSearchable: false,
      searchableKey: 'createdBy',
      displayPath: 'createdBy',
    },
  },
  {
    id: 4,
    label: 'Creation On',
    input: 'createdOn',
    fieldKey: 'createdOn',
    fieldType: 'alt-date',
    isHiddenFilter: false,
    isDate: true,
    isSortable: true,
  },
  {
    id: 5,
    label: 'Updated By',
    input: 'updatedBy',
    fieldType: 'searchField',
    isHiddenFilter: false,
    isDefaultFilterColumn: true,
    isSortable: true,
    data: {
      type: 'string',
      title: 'Updated By',
      description: 'General Information',
      searchKey: 'User',
      iconField: '',
      dependOn: '',
      multi: 'false',
      default: '',
      isSearchable: false,
      searchableKey: 'updatedBy',
      displayPath: 'updatedBy',
    },
  },
  {
    id: 6,
    label: 'Progress',
    input: 'data_completed',
    fieldType: 'number',
    textInputType: 'number',
    fieldKey: 'data_completed',
    key: 'data_completed',
    textInputMax: 100,
    textInputMin: 0,
    isHiddenFilter: true,
    isDefaultFilterColumn: true,
    isSortable: true,
    isNumber: true,
    component: (item) => (item && item.progressWithPercentage) || <span />,
  },
  {
    id: 7,
    label: 'Status',
    isHiddenFilter: false,
    isDefaultFilterColumn: true,
    fieldType: 'select',
    input: 'StringStatus',
    data: { enum: Object.values(UnitsFilterStatusEnum.sale) },
    isSortable: true,
    component: (item) => (
      <span>
        {item &&
          item.unitStatus &&
          item.unitStatus.name &&
          item.unitStatus.name && (
            <div className={getValuecolor(item.unitStatus.name)}>
              {item.unitStatus.name}
            </div>
          )}
      </span>
    ),
  },
  {
    id: 8,
    label: 'Status Date',
    isDefaultFilterColumn: true,
    input: 'StatusDate',
    isHiddenFilter: false,
    fieldType: 'alt-date',
    isDate: true,
    isSortable: true,
    component: (item) => (item && item.statusDate) || <span />,
  },
  {
    id: 9,
    label: 'Updated Owner Date',
    isDefaultFilterColumn: true,
    input: 'LastUpdatedOwner',
    fieldType: 'alt-date',
    isHiddenFilter: false,
    isDate: true,
    isSortable: true,
    dateFormat: 'DD/MM/YYYY',
    component: (item) => (item && item.updatedOwnerDate) || <span />,
  },
  {
    id: 10,
    label: 'Sale Price',
    isHiddenFilter: false,
    input: 'selling_price',
    isSortable: true,
    fieldType: 'number',
    isDefaultFilterColumn: true,
    isNumber: true,
    component: (item) => (item && item.selling_price_agency_fee) || <span />,
  },
  {
    id: 11,
    label: 'Listing Agent',
    isSortable: true,
    fieldKey: 'listing_agent',
    input: 'listing_agent',
    fieldType: 'searchField',
    isDefaultFilterColumn: true,
    component: (item) => (
      <span>
        {(item &&
          item.details &&
          item.details.map(
            (el) => el.title === 'listing-agent' && el.value
          )) || <span />}
      </span>
    ),
    data: {
      type: 'string',
      title: 'Listing Agent',
      description: 'General Information',
      searchKey: 'User',
      iconField: '',
      dependOn: '',
      multi: 'false',
      default: '',
      isSearchable: false,
      searchableKey: 'listing_agent',
      displayPath: 'listing_agent',
    },
  },
  {
    id: 12,
    label: 'Sale Publish Date',
    isDefaultFilterColumn: false,
    input: 'lastSalePublishDate',
    isHiddenFilter: false,
    fieldType: 'alt-date',
    isDate: true,
    isSortable: true,
  },
  {
    id: 13,
    label: 'Transacted By',
    input: 'statusCreatedByName',
    isHiddenFilter: false,
    isDefaultFilterColumn: false,
    isSortable: true,
    fieldType: 'searchField',
    data: {
      type: 'string',
      title: 'Status Created By Name',
      description: 'General Information',
      searchKey: 'User',
      iconField: '',
      dependOn: '',
      multi: 'false',
      default: '',
      isSearchable: false,
      searchableKey: 'statusCreatedByName',
      displayPath: 'statusCreatedByName',
    },
  },
  {
    id: 14,
    label: 'isBulkUpload',
    input: 'isBulkUpload',
    fieldType: 'select',
    data: { enum: ['Yes', 'No'] },
    isHiddenFilter: false,
    isDefaultFilterColumn: true,
    isSortable: true,
    component: (item) => <div>{item?.isBulkUpload ? 'Yes' : 'No'}</div>,
  },
  {
    id: 15,
    label: 'Last Activty Type Name',
    input: 'lastActivtyTypeName',
    fieldType: 'searchField',
    data: {
      type: 'string',
      title: 'Last Activty Type Name',
      description: 'General Information',
      searchKey: 'activtyType',
      iconField: '',
      dependOn: '',
      multi: 'false',
      default: '',
      isSearchable: false,
      searchableKey: 'lastActivtyTypeName',
      displayPath: 'lastActivtyTypeName',
    },
    isHiddenFilter: false,
    isDefaultFilterColumn: true,
    isSortable: true,
  },
  {
    id: 16,
    label: 'lastActivityDate',
    input: 'lastActivityDate',
    fieldType: 'alt-date',
    isHiddenFilter: false,
    isDefaultFilterColumn: true,
    isSortable: true,
    isDate: true,
  },
  {
    id: 17,
    label: 'is-Shared-Unit',
    input: 'isSharedUnit',
    fieldType: 'select',
    data: { enum: ['Yes', 'No'] },
    isHiddenFilter: false,
    isDefaultFilterColumn: true,
    isSortable: true,
    component: (item) => <div>{item?.isSharedUnit ? 'Yes' : 'No'}</div>,
  },
  {
    id: 18,
    label: 'deal_expiration_period',
    input: 'dealExpirationPeriod',
    isNumber: true,
    fieldType: 'number',
  },
  {
    id: 19,
    label: 'deal_start_date',
    input: 'dealStartDate',
    isDate: true,
    fieldType: 'alt-date',
  },
  {
    id: 20,
    label: 'is_deal_of_day',
    input: 'isDealOfTheDay',
    fieldType: 'select',
    data: { enum: ['Yes', 'No'] },
    component: (item) => <div>{item?.isDealOfTheDay ? 'Yes' : 'No'}</div>,
  },
];
