import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { PendingDeactivationTabs } from "./PendingDeactivationTabs";
import { TabsComponent } from "../../../../../../Components";
import { PageHeading } from "../../../../../../SharedComponents";
import { PendingDeactivationView } from "./PendingDeactivationView/PendingDeactivationView";

export const PendingDeactivationManagement = (props) => {
  const parentTranslationPath = "UsersView";
  const translationPath = "UsersManagement.";
  const { t } = useTranslation(parentTranslationPath);

  const [activeView, setActiveView] = useState(
    PendingDeactivationTabs.PendingDeactivation.key
  );
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [deActiveUserId, setDeActiveUserId] = useState(null);

  const onTabChanged = (_, tabIndex) => {
    setActiveTabIndex(tabIndex);
  };

  const getAllowedTabs = () => {
    let allowedTabs = [];
    allowedTabs = Object.values(PendingDeactivationTabs).filter(
      (item) => item.key !== PendingDeactivationTabs.PendingDeactivation.key
    );
    return allowedTabs;
  };

  useEffect(() => {
    setActiveTabIndex(0);
  }, [activeView]);

  return (
    <div className="w-80">
      {activeView === PendingDeactivationTabs.PendingDeactivation.key ? (
        <PendingDeactivationView
          {...props}
          setActiveView={setActiveView}
          setDeActiveUserId={setDeActiveUserId}
        />
      ) : (
        <>
          <div className="d-flex-center fj-start px-4">
            <span
              className="fw-simi-bold fz-16 mb-1 opacity-3 pointer"
              onClick={() =>
                setActiveView(PendingDeactivationTabs.PendingDeactivation.key)
              }
            >
              {t(`${translationPath}PendingDeactivation`)}
            </span>
            <span className="mdi mdi-chevron-right opacity-3 mb-1" />
            <span className="fw-simi-bold fz-16 mb-1 ">
              {t(`${translationPath}Reassigning`)}
            </span>
          </div>
          <div className="d-flex-h-between">
            <PageHeading
              parentTranslationPath={parentTranslationPath}
              translationPath={translationPath}
              subTitle={"Reassigning-leads-and-units-title"}
              wrapperClasses={"px-4 m-0 w-50"}
              headerTitleClasses={"fz-20"}
              subTitleClasses={"fz-14 m-0 ml-0"}
            />
          </div>

          <TabsComponent
            wrapperClasses="b-bottom ml-4 "
            data={getAllowedTabs()}
            labelInput="label"
            themeClasses="theme-propx"
            currentTab={activeTabIndex}
            onTabChanged={onTabChanged}
            withDynamicComponents
            dynamicComponentProps={{
              parentTranslationPath,
              translationPath,
              deActiveUserId,
            }}
          />
        </>
      )}
    </div>
  );
};
