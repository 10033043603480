import React from "react";
import moment from "moment";
import { Box, Typography, Divider } from "@material-ui/core";
import { CustomCard } from "../../../../../../../Components";
import { useSelectedTheme, useTranslate } from "../../../../../../../Hooks";

// Styles
import { identityDetailsStyles } from "./styles";

// Icons
import { Attachment } from "../../../../../../../assets/icons";

function IdentityDetails({ details }) {
  const styles = identityDetailsStyles();

  const { translate } = useTranslate("NewContactsView");

  const {
    theme: { palette },
  } = useSelectedTheme();

  return (
    <CustomCard
      borderRadius="xl"
      boxShadow="xs"
      borderColor="secondary"
      classes={styles.container}
    >
      <Box className={styles.mainSideWrapper}>
        <Typography className={styles.heading} variant="h3">
        {translate('id-card-details')}
        </Typography>
        <Divider className={styles.divider} />
        <Box className={styles.infoContainer}>
          <Box className={styles.infoItem}>
            <Typography className={styles.infoLabel} variant="body1">
            {translate('id-Card-no')}:
            </Typography>
            <Typography className={styles.infoValue} variant="body1">
              {details?.id_card_no ?? "N/A"}
            </Typography>
          </Box>
          <Box className={styles.infoItem}>
            <Typography className={styles.infoLabel} variant="body1">
            {translate('id-card-expiry-date')}:
            </Typography>
            <Typography className={styles.infoValue} variant="body1">
              {details?.id_card_expiry_date
                ? moment(details?.id_card_expiry_date).format("DD/MM/YYYY")
                : "N/A"}
            </Typography>
          </Box>
          <Box className={styles.infoItem}>
            <Typography className={styles.infoLabel} variant="body1">
            {translate('attache')}:
            </Typography>
            <Box className={styles.attachmentWrapper}>
              <Typography className={styles.infoValue} variant="body1">
                1
              </Typography>
              <Attachment
                width="20" 
                height="20" 
                fill={palette.foreground.quarterary}
              />
            </Box>
          </Box>
        </Box>
      </Box>
      <Box>
        <Typography className={styles.heading} variant="h3">
        {translate('passport-details')}
        </Typography>
        <Divider className={styles.divider} />
        <Box className={styles.infoContainer}>
          <Box className={styles.infoItem}>
            <Typography className={styles.infoLabel} variant="body1">
            {translate('passport-details')}:
            </Typography>
            <Typography className={styles.infoValue} variant="body1">
              {details?.passport_no ?? "N/A"}
            </Typography>
          </Box>
          <Box className={styles.infoItem}>
            <Typography className={styles.infoLabel} variant="body1">
            {translate('passport-Issue-date')}:
            </Typography>
            <Typography className={styles.infoValue} variant="body1">
              {details?.passport_issue_date
                ? moment(details?.passport_issue_date).format("DD/MM/YYYY")
                : "N/A"}
            </Typography>
          </Box>
          <Box className={styles.infoItem}>
            <Typography className={styles.infoLabel} variant="body1">
            {translate('passport-expiry-date')}:
            </Typography>
            <Typography className={styles.infoValue} variant="body1">
              {details?.passport_expiry_date
                ? moment(details?.passport_expiry_date).format("DD/MM/YYYY")
                : "N/A"}
            </Typography>
          </Box>
          <Box className={styles.infoItem}>
            <Typography className={styles.infoLabel} variant="body1">
            {translate('attache')}:
            </Typography>
            <Box className={styles.attachmentWrapper}>
              <Typography className={styles.infoValue} variant="body1">
                3
              </Typography>
              <Attachment
                width="20" 
                height="20" 
                fill={palette.foreground.quarterary}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </CustomCard>
  );
}

export default IdentityDetails;
