import React, { useState, useReducer, useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { lookupItemsGetId } from "../../../../Services";
import { Spinner } from "../../../../Components";
import { StaticLookupsIds } from "../../../../assets/json/StaticLookupsIds.jsx";

export const LocationFilter = ({
  setResaleFilters,
  resaleFilters,
  resaleChangeFilters,
  parentTranslationPath,
  translationPath,
  isClearFiltersClicked , 
}) => {
  const { t } = useTranslation(parentTranslationPath);

  const reducer = useCallback((state, action) => {
    if (action.id !== "edit") return { ...state, [action.id]: action.value };
    return {
      ...action.value,
    };
  }, []);

  const [selected, setSelected] = useReducer(reducer, {
    City: null,
    District: null,
    Community: null,
    SubCommunity: null,
  });
  const [isSelected, setISelected] = useState({
    Cities: true,
    District: false,
    Community: false,
    SubCommunity: false,
  });
  const [displayAllItem, setDisplayAllItem] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [data, setData] = useState({
    Cities: [],
    District: [],
    Community: [],
    SubCommunity: [],
  });

  const getAddressLookupsById = async (lookupTypeName, lookupParentId) => {
    setIsLoading(true);
    const response = await lookupItemsGetId({
      lookupTypeId: StaticLookupsIds[lookupTypeName],
      lookupParentId,
    });
    if (!(response && response.status && response.status !== 200)) {
      setData((item) => ({ ...item, [lookupTypeName]: response }));
    } else setData((item) => ({ ...item, [lookupTypeName]: [] }));
    setIsLoading(false);
  };

  const clearAllLocationFilters = () => {
    setSelected({
      id: "edit",
      value: {
        City: null,
        District: null,
        Community: null,
        SubCommunity: null,
      },
    });
    setISelected({
      Cities: true,
      District: false,
      Community: false,
      SubCommunity: false,
    });

    const locationTypes = [
      { key: "city" },
      { key: "district" },
      { key: "community" },
      { key: "subCommunity" },
    ];

    resaleChangeFilters(false, false, locationTypes);
  };

  const checkToChangeAreaSize = () => 
    {
      let arr = Array.from(Object.entries(isSelected), ([key, value]) => value === true && key );
      const key = arr.find((x)=> x!== false); 
      return ( key &&   data[key] && data[key].length > 10 ? true : false ) ;
      
    };
    useEffect(() => {
      getAddressLookupsById("Cities", 26642);
    }, []);
    
    useEffect(() => {
      if(isClearFiltersClicked)
        clearAllLocationFilters();
      
    }, [isClearFiltersClicked]);
    
  return (

    <fieldset className="m-1 styled-fieldset d-flex mx-2 no-scrollbar" style={checkToChangeAreaSize() &&  displayAllItem ? {position: 'relative' , 'min-height':'auto'} :{position: 'relative' , 'max-height':'80px' ,  'overflow-y': 'auto'  ,'min-height': '40px'}
    }>
      <Spinner isActive={isLoading} />
      <legend className="styled-legend fw-bold">
        {t(`${translationPath}location`)}
      </legend>
      <div className="px-4 w-100  w-50 m-1 location-filter">

        <div className="w-100 d-flex d-flex-inline d-flex-h-center-h-start">
          <div className="" style={{ width: "20%" }}>
            <div className="d-flex">
              <span className="d-flex-inline">Most searched cities</span>
              {(isSelected.District ||
                isSelected.Community ||
                isSelected.SubCommunity) && (
                <span className="" onClick={() => clearAllLocationFilters()}>
                  <span className="mdi mdi-filter-remove-outline px-2" />
                </span>
              )}
            </div>
            <div className="d-flex-inline">
              {selected && selected.City && (
                <span className="address fw-bold"> {selected.City} </span>
              )}
              {selected && selected.District && (
                <span className="address fw-bold"> , {selected.District} </span>
              )}
              {selected && selected.Community && (
                <span className="address fw-bold">
                  {" "}
                  , {selected.Community}{" "}
                </span>
              )}
              {selected && selected.SubCommunity && (
                <span className="address fw-bold">
                  {" "}
                  , {selected.SubCommunity}{" "}
                </span>
              )}
            </div>
            {
              checkToChangeAreaSize() && 
              (
                <span className=""  style={{position: 'absolute' ,  top: '16px',
                  right: '16px' }}onClick={() => 
                   { 
                     setDisplayAllItem(!displayAllItem);
                   }
                  }>
                     <span className="mdi mdi-filter-outline px-2" />
                   </span>
              )
            }
            
          </div>

          <div
            className={`${
              data.Cities.length > 10 && displayAllItem
                ? "all-items-part"
                : "sub-items-part"
            }`}
            style={{ width: "80%" }}
          >
            {isSelected.Cities &&
              data.Cities.map((item, index) => (
                <span
                  key={`city${item.lookupItemId}`}
                  className="address"
                  onClick={() => {
                    setISelected((s) => ({
                      ...s,
                      Cities: false,
                      District: true,
                    }));
                    getAddressLookupsById("District", item.lookupItemId);
                    setSelected({ id: "City", value: item.lookupItemName });
                    setDisplayAllItem(false);
                    resaleChangeFilters("city", item.lookupItemName);
                  }}
                >
                  {item.lookupItemName}
                </span>
              ))}
            {isSelected.District &&
              data.District.map((item, index) => (
                <span
                  key={`District${item.lookupItemId}`}
                  className="address"
                  onClick={() => {
                    setISelected((s) => ({
                      ...s,
                      District: false,
                      Community: true,
                    }));
                    getAddressLookupsById("Community", item.lookupItemId);
                    setSelected({ id: "District", value: item.lookupItemName });
                    setDisplayAllItem(false);
                    resaleChangeFilters("district", item.lookupItemName);
                  }}
                >
                  {item.lookupItemName}
                </span>
              ))}
            {isSelected.Community &&
              data.Community.map((item, index) => (
                <span
                  key={`Community${item.lookupItemId}`}
                  className="address"
                  onClick={() => {
                    setISelected((s) => ({
                      ...s,
                      Community: false,
                      SubCommunity: true,
                    }));
                    getAddressLookupsById("SubCommunity", item.lookupItemId);
                    setSelected({
                      id: "Community",
                      value: item.lookupItemName,
                    });
                    resaleChangeFilters("community", item.lookupItemName);
                    setDisplayAllItem(false);
                  }}
                >
                  {item.lookupItemName}
                </span>
              ))}
            {isSelected.SubCommunity &&
              data.SubCommunity.map((item, index) => (
                <span
                  key={`SubCommunity${item.lookupItemId}`}
                  onClick={() => {
                    resaleChangeFilters("subCommunity", item.lookupItemName);
                    setSelected({ id: "SubCommunity", value: item.lookupItemName });
                    setDisplayAllItem(false);
                  }}
                  className="address"
                >
                  {item.lookupItemName}
                </span>
              ))}
          </div>
        </div>
      </div>
    </fieldset>
  );
};
