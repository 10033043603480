import React from 'react';
import { CopyToClipboardComponents } from '../../../../ReusableComponents/UtilityComponents';

export const InvoicesHeaderData = [
  {
    id: 1,
    isSortable: true,
    label: 'invoice-no',
    input: 'invoiceId',
    component: (item) => (
      <span>
        <CopyToClipboardComponents
          data={item?.invoiceId}
          childrenData={item?.invoiceId}
        />
      </span>
    ),
  },
  {
    id: 2,
    isSortable: true,
    label: 'invoice',
    input: 'dueOn',
    isDate: true,
  },
  {
    id: 3,
    isSortable: true,
    label: 'payment-type',
    input: 'paymentType',
    component: (item) => <span>{item && item.paymentTypeName}</span> || '',
  },
  {
    id: 4,
    isSortable: true,
    label: 'mode',
    input: 'paymentMode',
    component: (item) => <span>{item && item.paymentModeName}</span> || '',
  },
  {
    id: 5,
    label: 'status',
    input: 'invoiceStatus',
    component: (item) => {
      let statusLabel = '';
      switch (item.invoiceStateId) {
        case 1:
          statusLabel = 'Paid';
          break;
        case 2:
          statusLabel = 'Unpaid';
          break;
        case 3:
          statusLabel = 'Partially paid';
          break;
        case 4:
          statusLabel = 'Canceled';
          break;
        default:
          statusLabel = '';
      }
      return <span>{statusLabel}</span>;
    },
  },
  {
    id: 6,
    isSortable: true,
    label: 'receipts',
    input: 'receiptId',
    isNumber: true,
  },
  {
    id: 7,
    isSortable: true,
    label: 'amount-due',
    input: 'amountDue',
    isNumber: true,
  },
  {
    id: 8,
    isSortable: true,
    label: 'amount-paid',
    input: 'AmountPaid',
    isNumber: true,
    isHiddenFilter: true,
    component: (item) => <span>{(item && item.amountPaid) || 0}</span>,
  },
  // {
  //   id: 9,
  //   isSortable: true,
  //   label: 'paid-on',
  //   input: 'paidOn',
  // },
  // {
  //   id: 10,
  //   isSortable: true,
  //   label: 'balance',
  //   input: 'balance',
  // },
  {
    id: 11,
    isSortable: true,
    label: 'unit-ref-no',
    input: 'unitRefNo',
  },
  {
    id: 11,
    isSortable: false,
    label: 'branch',
    input: 'BranchName',
    component: (item) => <span>{(item && item.branch) || ''}</span>,
  },
];
