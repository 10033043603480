import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { ButtonBase } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { PaginationComponent, Spinner, Tables } from '../../../../../../../../Components';
import { GetAllPublishedPortalsAPI , GetAllPublishedPortalsByIdAPI  } from '../../../../../../../../Services';
import { bottomBoxComponentUpdate , GetParams  , returnPropsByPermissions } from '../../../../../../../../Helper';
import { TableActions , UnitsOperationTypeEnum } from '../../../../../../../../Enums';
import { PublishedUnitsPortalTable } from './UnitPublishedPortal/PublishedUnitsPortalTable' ; 
import { PublishedOnPortalManagementDialog } from './UnitPublishedPortal/PublishedPortalManagement' ; 
import { UnitsSalesPermissions , 
          UnitsLeasePermissions ,
         LeadOwnerUnitLeasePermissions ,
         LeadOwnerUnitSalePermissions
 } from '../../../../../../../../Permissions'; 
import { DeletePublishedPortalDialog } from './UnitPublishedPortal/index' ; 


export const WebPortal = ({
  state , 
  parentTranslationPath,
  translationPath,
  type , 
}) => {

  const { t } = useTranslation([parentTranslationPath, 'Shared']);
  const [isLoading, setIsLoading] = useState({ allPublishedPortalsData : false , publishedUnit : false    });
  const [isOpenPublishUnitDialog, setIsOpenPublishUnitDialog ] = useState(false);
  const [isOpenDeletePublishUnitDialog, setIsOpenDeletePublishUnitDialog ] = useState(false);
  const [activeItem , setActiveItem ] = useState(null);
  const [publishedUnit , setPublishedUnit  ] = useState(null) ; 
  const [unitId , setUnitId] = useState(+GetParams('id')) ; 
  const [unitMarketingId , setUnitMarketingId] = useState(null) ; 
  const pathName = window.location.pathname.split('/home/')[1].split('/view')[0];


  const [publishedUnits, setPublishedUnits] = useState({
    result: [],
    totalCount: 0,
  });
  const [filter, setFilter] = useState({
    pageIndex: 0,
    pageSize:  parseInt(localStorage.getItem('Pagination')) || 25,
    operationType : null ,
  });

  const operationTypeId = () => 
  {
    if(pathName.includes('units-sales/unit-profile-edit') || (pathName.includes('lead-owner-units-sale/unit-profile-edit')))
     return   UnitsOperationTypeEnum.sale.key ; 

     else if(pathName.includes('units-lease/unit-profile-edit') || (pathName.includes('lead-owner-units-lease/unit-profile-edit')))
     return   UnitsOperationTypeEnum.rent.key;
    return null ;  
  };

  const  DisplayViewWithPermission = () => 
  {
   if (type && type === 'sale')
     return  returnPropsByPermissions(UnitsSalesPermissions.ShowWebPortals.permissionsId) ; 

    else if(type && type === 'lease')
    return  returnPropsByPermissions(UnitsLeasePermissions.ShowWebPortals.permissionsId) ; 

    else if (type && type === 'saleLeadOwner')
    return  returnPropsByPermissions(LeadOwnerUnitSalePermissions.ShowWebPortals.permissionsId) ; 

    else if (type && type === 'leaseLeadOwner')
    return  returnPropsByPermissions(LeadOwnerUnitLeasePermissions.ShowWebPortals.permissionsId) ;  
   return true ; 

    
  };


  const GetAllPublishedPortals = useCallback(async () => {
    setIsLoading((item) => ({ ...item, allPublishedPortalsData : true  }));
    if(DisplayViewWithPermission())
    {
      const res = await GetAllPublishedPortalsAPI({ ...filter , pageIndex : (filter.pageIndex +1) , unitId : unitId  , operationType: operationTypeId()});
      if (!(res && res.status && res.status !== 200)) {
        setPublishedUnits({
          result: (res && res.result) || [],
          totalCount: (res && res.totalCount) || 0,
        });
      } else {
        setPublishedUnits({
          result: [],
          totalCount: 0,
        });
      }

    }

   
    setIsLoading((item) => ({ ...item, allPublishedPortalsData : false   }));
  }, [filter]);




   const GetPublishedPortalsById = useCallback(async () => {

    setIsLoading((item) => ({ ...item, publishedUnit : true  }));
    const res = await GetAllPublishedPortalsByIdAPI(activeItem && activeItem.publishedPortalId);
    if (!(res && res.status && res.status !== 200)) 
    {
      setPublishedUnit(res)  
    } else 
    {
      setPublishedUnit(null) ;  
    }
    setIsLoading((item) => ({ ...item, publishedUnit : false  }));

  }, [activeItem]);

  const  DisplayWithPermission = () => 
  {
   if (type && type === 'sale')
     return  returnPropsByPermissions(UnitsSalesPermissions.AddPublishedUnit.permissionsId) ; 

    else if(type && type === 'lease')
    return  returnPropsByPermissions(UnitsLeasePermissions.AddPublishedUnit.permissionsId) ; 

    else if (type && type === 'saleLeadOwner')
    return  returnPropsByPermissions(LeadOwnerUnitSalePermissions.AddPublishedUnit.permissionsId) ; 

    else if (type && type === 'leaseLeadOwner')
    return  returnPropsByPermissions(LeadOwnerUnitLeasePermissions.AddPublishedUnit.permissionsId) ;  
   return true ; 

    
  };

  const onPageIndexChanged = (pageIndex) => {
    setFilter((item) => ({ ...item, pageIndex }));
  };
  const onPageSizeChanged = (pageSize) => {
    setFilter((item) => ({ ...item, pageIndex: 0, pageSize }));
  };

  const reloadData = () => {
    setFilter((item) => ({ ...item, pageIndex: 0 }));
  };

  const tableActionClicked = useCallback(
    (actionEnum, item, focusedRow, event) => {
      setActiveItem(item) ; 
      event.preventDefault();
      if (actionEnum === TableActions.editText.key) setIsOpenPublishUnitDialog(true);
      if (actionEnum === TableActions.deleteText.key) setIsOpenDeletePublishUnitDialog(true);
    },
    [activeItem]
  );

  useEffect(() => {
    GetAllPublishedPortals();
  }, [GetAllPublishedPortals, filter]);

  useEffect(() => {
    if(activeItem)
    GetPublishedPortalsById() ; 
  else setPublishedUnit(null) ; 

  
  }, [activeItem]);
  

 useEffect(() => {
     setUnitMarketingId((state && state.unitMarketingId)|| null ); 
  }, [state]); 



  useEffect(() => {
    bottomBoxComponentUpdate(
      <div className='bottom-box-two-sections'>
        <PaginationComponent
          pageIndex={filter.pageIndex}
          pageSize={filter.pageSize}
          totalCount={publishedUnits.totalCount}
          onPageIndexChanged={onPageIndexChanged}
          onPageSizeChanged={onPageSizeChanged}
        />
      </div>
    );
  });

  return (
    <div className='web-portal-wrapper childs-wrapper mt-2 px-2'>
      <Spinner isActive={(isLoading && isLoading.allPublishedPortalsData)} isAbsolute />
      <div className='d-flex-column mt-2 px-2'>
        <div className='header-section'>
          <div className='filter-section px-2'>
            <div className='section'>
              {
                unitMarketingId &&  DisplayWithPermission() && 
                (
                <ButtonBase className='btns theme-solid px-3' onClick={()=> setIsOpenPublishUnitDialog(true)}>
                <span className='mdi mdi-plus' />
                {t(`${translationPath}add-published-unit`)}
                 </ButtonBase>
               )
              }
                
             </div>
          </div>
        </div>
      </div>
       <div className='px-2'>
         <PublishedUnitsPortalTable
           publishedUnits={publishedUnits}
           parentTranslationPath= {parentTranslationPath} 
           translationPath={translationPath}
           tableActionClicked={tableActionClicked}
           onPageSizeChanged={onPageSizeChanged}
           onPageIndexChanged={onPageIndexChanged}
           filter={filter}
           unitMarketingId={unitMarketingId}
          />
        </div>

      { isOpenPublishUnitDialog && (
        <PublishedOnPortalManagementDialog
          isOpen={isOpenPublishUnitDialog}
          onSave={() => {
            reloadData();
            setIsOpenPublishUnitDialog(false);
            if(activeItem)
            setActiveItem(null);
          }}
          onClose={() => {
            setIsOpenPublishUnitDialog(false);
            if(activeItem)
            setActiveItem(null);
          }}
          translationPath={translationPath}
          parentTranslationPath={parentTranslationPath}
          editUnitPublishedPortal={publishedUnit}
          isLoadingInfo={isLoading.publishedUnit}
          unitMarketingId={unitMarketingId}
          
        />
      )}

      { isOpenDeletePublishUnitDialog && (
        <DeletePublishedPortalDialog
          isOpen={isOpenDeletePublishUnitDialog}
          onClose={() => {
            setIsOpenDeletePublishUnitDialog(false);
            setActiveItem(null);
          }}
          reloadData={reloadData}
          translationPath={translationPath}
          parentTranslationPath={parentTranslationPath}
          editUnitPublishedPortal={publishedUnit}
          
        />
      )}
    </div>
  );
};

WebPortal.propTypes = {
  parentTranslationPath: PropTypes.string.isRequired,
  translationPath: PropTypes.string.isRequired,
};
