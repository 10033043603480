import { ContactsPermissions } from '../../../../../../Permissions';
import {
  AssociatedContactsComponent,
  ContactProfileActivitiesComponent,
  ContactProfileDocumentsComponent,
  ContactProfileLeadsComponent,
  ContactProfileMaintenanceComponent,
  ContactProfileTransactionsComponent,
  ContactProfileUnitsComponent,
  ContactProfileUserAccessComponent,
  ContactsInformationComponent,
  DuplicatesContactsComponent,
  ContactProfileSourceFileComponent,
  KycViewComponent,
  DeveloperDocumentsComponent
} from '../../../../ContactsView';
import DNCRAndConsentManagmentView from '../../../../ContactsView/DNCRAndConsentManagment/DNCRAndConsentManagmentView';
import {ProjectTasksView} from '../../../../TaskConfiguration/ProjectTasks/Utilities';

export const ContactsVerticalTabsData = [
  {
    label: "contact-information",
    component: ContactsInformationComponent,
    permissionsList: Object.values(ContactsPermissions),
    permissionsId: ContactsPermissions.ViewContactInformation.permissionsId,
  },
  {
    label: 'dncr-and-consent-management',
    component: DNCRAndConsentManagmentView,
    permissionsList: Object.values(ContactsPermissions),
    permissionsId: ContactsPermissions.ViewConsentDNCRTab.permissionsId,
  },
  {
    label: "leads",
    component: ContactProfileLeadsComponent,
    permissionsList: Object.values(ContactsPermissions),
    permissionsId: ContactsPermissions.ViewLeads.permissionsId,
  },
  {
    label: "units",
    component: ContactProfileUnitsComponent,
    permissionsList: Object.values(ContactsPermissions),
    permissionsId: ContactsPermissions.ViewUnit.permissionsId,
  },
  {
    label: "activities",
    component: ContactProfileActivitiesComponent,
    permissionsList: Object.values(ContactsPermissions),
    permissionsId: ContactsPermissions.ViewActivity.permissionsId,
  },
  {
    label: "documents",
    component: ContactProfileDocumentsComponent,
    permissionsList: Object.values(ContactsPermissions),
    permissionsId: ContactsPermissions.ViewDocuments.permissionsId,
  },
  {
    label: "developer-documents",
    component: DeveloperDocumentsComponent,
    permissionsList: Object.values(ContactsPermissions),
    permissionsId: ContactsPermissions.DeveloperDocumentsTab.permissionsId,
  },
  {
    label: "transactions-history",
    component: ContactProfileTransactionsComponent,
    permissionsList: Object.values(ContactsPermissions),
    permissionsId: ContactsPermissions.ViewUnitHistoryTransaction.permissionsId,
  },
  {
    label: "assigned-agents",
    component: ContactProfileUserAccessComponent,
    permissionsList: Object.values(ContactsPermissions),
    permissionsId: ContactsPermissions.ViewUsersAccessOnContact.permissionsId,
  },
  {
    label: "duplicates",
    component: DuplicatesContactsComponent,
    permissionsList: Object.values(ContactsPermissions),
    permissionsId:
      ContactsPermissions.ViewDuplicateForThisContact.permissionsId,
  },
  {
    label: "tasks",
    component: ProjectTasksView,
    permissionsList: Object.values(ContactsPermissions),
    permissionsId: ContactsPermissions.ViewTasksForContact.permissionsId,
  },
  {
    label: "KYC",
    component: KycViewComponent,
    permissionsList:Object.values(ContactsPermissions),
    permissionsId: ContactsPermissions.KYCTab.permissionsId,
  },
  {
    label: 'contact-source',
    component: ContactProfileSourceFileComponent,
    permissionsList: Object.values(ContactsPermissions),
    permissionsId: ContactsPermissions.ViewSourceFileTab.permissionsId,
  },
  {
    label: "maintenance-services",
    component: ContactProfileMaintenanceComponent,
  },
  {
    label: 'associated-contacts',
    component: AssociatedContactsComponent,
    permissionsList: Object.values(ContactsPermissions),
    permissionsId: ContactsPermissions.ViewAssociatedContacts.permissionsId,
  },

];
