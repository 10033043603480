import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import {
  GlobalHistory,
} from '../../../../Helper';
import { Spinner, Tables, Inputs } from '../../../../Components';

import { bottomBoxComponentUpdate } from '../../../../Helper/Middleware.Helper';
import { PaginationComponent } from '../../../../Components/PaginationComponent/PaginationComponent';
import { GetMyImportProcess } from '../../../../Services/File';
import { TableActions } from '../../../../Enums';

export const MyImportDetailsActions = () => {
  const { t } = useTranslation('ImportDetailsView');
  const translationPath = '';
  const parentTranslationPath = 'ImportDetailsView';
  const [importDetails, setImportDetails] = useState({
    result: [],
    totalCount: 0,
  });
  const [filter, setFilter] = useState({
    pageIndex: 0,
    pageSize: parseInt(localStorage.getItem('Pagination')) || 25,
    isValid: false,
    search: '',
  });
  const [searchedItem, setSearchedItem] = useState('');
  const [timer, setTimer] = useState(null);

  const [isLoading, setIsLoading] = useState(false);
  const getProcess = useCallback(async () => {
    setIsLoading(true);
    const res = await GetMyImportProcess(filter.pageIndex + 1, filter.pageSize, filter.search);
    if (!(res && res.status && res.status !== 200)) {
      setImportDetails({
        result: (res && res.result) || [],
        totalCount: (res && res.totalCount) || 0,
      });
    }
    setIsLoading(false);
  }, [filter.pageIndex, filter.pageSize]);

  const onPageIndexChanged = (pageIndex) => {
    setFilter((item) => ({ ...item, pageIndex }));
  };
  const onPageSizeChanged = (pageSize) => {
    setFilter((item) => ({ ...item, pageIndex: 0 + 1, pageSize }));
  };
  const tableActionClicked = useCallback((actionEnum, item) => {
    GlobalHistory.push(`/home/import-details?id=${item.importProcceseId}`);
  });

  useEffect(() => {
    bottomBoxComponentUpdate(
      <PaginationComponent
        pageIndex={filter.pageIndex}
        pageSize={filter.pageSize}
        totalCount={importDetails.totalCount}
        onPageIndexChanged={onPageIndexChanged}
        onPageSizeChanged={onPageSizeChanged}
      />
    );
  });
  useEffect(
    () => () => {
      bottomBoxComponentUpdate(null);
    },
    []
  );

  useEffect(() => {
    getProcess();
  }, [filter]);

  return (
    <div className='RotationSchema-wrapper view-wrapper'>
      <Spinner isActive={isLoading} isAbsolute />
      <div className='w-100 px-2'>
        <div className='header-section'>
          <div className='filter-section'>
            <div className='section' />
            <div className='section d-flex-column w-50 px-2 m-auto'>
              <div className='w-100'>
                <Inputs
                  idRef='detailsActionsRef'
                  label={t(`${translationPath}search-actions-details`)}
                  value={searchedItem}
                  onInputChanged={(e) => setSearchedItem(e.target.value)}
                  inputPlaceholder={t(`${translationPath}search-actions-details`)}
                  onKeyUp={() => {
                    setTimer(
                      setTimeout(() => {
                        setFilter((f) => ({ ...f, search: searchedItem }));
                      }, 1000)
                    );
                  }}
                  onKeyDown={() => {
                    if (timer != null) clearTimeout(timer);
                  }}
                  beforeIconClasses='mdi mdi-magnify mdi-24px c-gray-primary'
                />
              </div>
            </div>
          </div>
          <div />
        </div>
        <Tables
          data={importDetails.result}
          headerData={[
            {
              id: 1,
              label: 'fileName',
              input: 'fileName',
            },
            {
              id: 2,
              label: 'importProcceseId',
              input: 'importProcceseId',
            }, {
              id: 3,
              label: 'createdOn',
              isDate: true,
              input: 'createdOn',
            },

            {
              id: 4,

              label: 'updateOn',
              input: 'updateOn',
              isDate: true,
            },
            {
              id: 5,
              label: 'importProcceseStatus',
              component: (item) => (
                <span>
                  {((item && item.importProcceseStatus &&
                    (item.importProcceseStatus === 1 && t(`${translationPath}Reading`)) ||
                    (item.importProcceseStatus === 2 && t(`${translationPath}ReadingFileFailure`)) ||
                    (item.importProcceseStatus === 3 && t(`${translationPath}FinishRead`)) ||
                    (item.importProcceseStatus === 4 && t(`${translationPath}FileImported`))) ||
                    '')}
                </span>
              ),
            },

            {
              id: 6,
              label: 'importProcceseType',
              input: 'importProcceseType',
            }, {
              id: 7,
              label: 'fileId',
              input: 'fileId',
            }
          ]}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          totalItems={(importDetails.totalCount) || 0}
          withfilter
          defaultActions={[
            {
              enum: TableActions.openFile.key,
            },

          ]}
          actionsOptions={{
            onActionClicked: tableActionClicked,
          }}
          itemsPerPage={filter.pageSize}
          activePage={filter.pageIndex}
        />
      </div>
    </div>
  );
};

MyImportDetailsActions.propTypes = {

};
MyImportDetailsActions.defaultProps = {

};
