import React from "react";

function ReassignOwnerIcon({ fill, ...restProps }) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill={fill ?? ""}
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <path
        d="M7.817 1.705a4.725 4.725 0 0 0-1.736.567C4.223 3.335 3.356 5.489 3.953 7.559a4.77 4.77 0 0 0 1.038 1.809 4.57 4.57 0 0 0 6.691-.008 4.57 4.57 0 0 0 .782-5.057 4.561 4.561 0 0 0-4.647-2.598m1.315 1.728a3.07 3.07 0 0 1 1.872 1.62c.319.703.319 1.691 0 2.394-.35.772-1.11 1.416-1.937 1.641-.343.094-1.165.085-1.517-.016a3.051 3.051 0 0 1-1.888-1.627c-.319-.704-.319-1.686 0-2.39a3.014 3.014 0 0 1 2.166-1.69c.278-.051 1.027-.012 1.304.068m-4.67 8.705c-1.466.17-2.769 1.177-3.319 2.564a4.332 4.332 0 0 0-.19.605c-.078.377-.127 1.17-.114 1.83.012.646.033.724.25.941a.716.716 0 0 0 .578.237c.331 0 .581-.156.743-.463.066-.126.073-.204.094-1.085.025-1.043.044-1.165.244-1.59a2.46 2.46 0 0 1 1.793-1.38c.14-.026 1.123-.045 2.976-.059l2.766-.021.136-.089a.81.81 0 0 0 .364-.469c.063-.213.063-.271 0-.485a.813.813 0 0 0-.364-.469l-.136-.088-2.783-.005c-1.531-.002-2.898.009-3.038.026m13.639.399a.934.934 0 0 0-.216.104c-.056.038-.727.692-1.492 1.454l-1.391 1.385-.593-.583c-.334-.33-.65-.611-.726-.648-.175-.084-.52-.086-.688-.004a1.046 1.046 0 0 0-.417.417c-.081.166-.08.512.003.688.086.182 1.887 1.983 2.069 2.069.179.084.52.084.7 0 .189-.089 3.615-3.514 3.721-3.719.097-.191.106-.524.017-.705a1.029 1.029 0 0 0-.41-.412.992.992 0 0 0-.577-.046"
        fill-rule="evenodd"
        fill={fill ?? ""}
      />
    </svg>
  );
}

export default ReassignOwnerIcon;
