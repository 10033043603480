export const PropertyPaymentPlan = {
  PlanType: {
    lookupTypeId: 3199,
    lookupTypeName: "PlanType",
  },
  PaymentType: {
    lookupTypeId: 1209,
    lookupTypeName: "PaymentType",
  },
  Frequency: {
    lookupTypeId: 3196,
    lookupTypeName: "frequency",
  },
  AmountNature: {
    lookupTypeId: 3197,
    lookupTypeName: "amount nature",
  },
};
