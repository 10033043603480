export const TicketsStatusEnum = {
    Open: {
        key: 2,
        label: 'Open',
        iconColor: 'coral'
    },
    Pending: {
        key: 3,
        label: 'Pending',
        iconColor: 'BlueViolet'
    },
    Resolved: {
        key: 4,
        label: 'Resolved',
        iconColor: 'LimeGreen'
    },
    Closed: {
        key: 5,
        label: 'Closed',
        iconColor: 'Gold'
    },
    NeedMoreInformation: {
        key: 6,
        label: 'Need More Information',
        iconColor: 'tan'
    },
    WaitingOnThirdParty: {
        key: 7,
        label: 'Waiting on Third Party',
        iconColor: 'FireBrick'
    },
    WorkInProgress: {
        key: 8,
        label: 'Work In-progress',
        iconColor: 'royalblue'
    },
    AssignToDevelopmentTeam: {
        key: 9,
        label: 'Assign to development Team',
        iconColor: 'mediumspringgreen'
    },
    FixedNotDeployed: {
        key: 10,
        label: 'Fixed Not Deployed',
        iconColor: 'greenyellow'
    },
    AddedToBacklog: {
        key: 11,
        label: 'Added to Backlog',
        iconColor: 'dimgray'
    },
    TestTicketsClose: {
        key: 12,
        label: 'Test Tickets - Close',
        iconColor: 'Gold'
    },
    NeedApproval: {
        key: 13,
        label: 'Need Approval',
        iconColor: 'tan'
    },
    UnderQATest: {
        key: 14,
        label: 'Under QA test',
        iconColor: 'darkslategray'
    },
    ClosedNonCRMTechnicalIssue: {
        key: 15,
        label: 'Closed – Non-CRM Technical issue',
        iconColor: 'Gold'
    },
    WaitingOnRequestor: {
        key: 16,
        label: 'Waiting on Requestor',
        iconColor: 'FireBrick'
    },
    Approved: {
        key: 17,
        label: 'Approved',
        iconColor: 'aqua'
    },
    NotApproved: {
        key: 18,
        label: 'Not Approved',
        iconColor: 'red'
    },
    NeedRevision: {
        key: 19,
        label: 'Need revision',
        iconColor: 'tan'
    },
    UnderReviewCRMTeam: {
        key: 20,
        label: 'Under Review - CRM Team',
        iconColor: 'darkslategray'
    }
}
