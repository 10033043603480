

import {
  LeadsMortgageView , 
  LeadsMortgageManagementView , 
  LeadsMortgageProfileManagementView , 
  LeadsMergeView , 
} from '../../Views/Home/LeadsMortgageView';



export const LeadsMortgageRoutes = [

  {
    path: '/view',
    name: 'LeadsView:leads',
    component: LeadsMortgageView,
    layout: '/home/mortgage-leads',
    default: true,
    isRoute: true,
    authorize: true,
    roles: [],
    isDisabled: false,
    isExact: true,
    breadcrumbs: [
      {
        name: 'LeadsView:leads',
        isDisabled: true,
        route: '/home/mortgage-leads/view',
        groupName: 'mortgage',
      },
    ],
   },
  {
    path: '/add',
    name: 'homeLayout.leadsManagementView.leads-management-view',
    component: LeadsMortgageManagementView,
    layout: '/home/mortgage-leads',
    default: false,
    isRoute: true,
    authorize: true,
    roles: [],
    isDisabled: false,
    isExact: true,
    breadcrumbs: [
      {
        name: 'LeadsView:leads',
        isDisabled: false,
        route: '/home/mortgage-leads/view',
        groupName: 'mortgage',
      },
      {
        name: 'homeLayout.leadsManagementView.leads-management-view',
        isDisabled: true,
        route: '/home/mortgage-leads/add',
      },
    ],
  },
  {
    path: '/edit',
    name: 'homeLayout.leadsManagementView.leads-management-view',
    component: LeadsMortgageManagementView,
    layout: '/home/mortgage-leads',
    default: false,
    isRoute: true,
    authorize: true,
    roles: [],
    isDisabled: false,
    isExact: true,
    breadcrumbs: [
      {
        name: 'LeadsView:leads',
        isDisabled: false,
        route: '/home/mortgage-leads/view',
        groupName: 'mortgage',
      },
      {
        name: 'homeLayout.leadsManagementView.leads-management-view',
        isDisabled: true,
        route: '/home/mortgage-leads/edit',
      },
    ],
  },
  {
    path: '/lead-profile-edit',
    name: 'LeadsProfileManagementView:lead-profile-edit',
    component: LeadsMortgageProfileManagementView,
    layout: '/home/mortgage-leads',
    default: false,
    isRoute: true,
    authorize: true,
    roles: [],
    isDisabled: false,
    isExact: true,
    breadcrumbs: [
      {
        name: 'LeadsView:leads',
        isDisabled: false,
        route: '/home/mortgage-leads/view',
        groupName: 'mortgage',
      },
      {
        name: 'LeadsProfileManagementView:lead-profile-edit',
        isDisabled: true,
        route: '/home/mortgage-leads/lead-profile-edit',
      },
    ],
  },
  {
    path: '/merge',
    name: 'homeLayout.leadsManagementView.leads-management-view',
    component: LeadsMergeView,
    layout: '/home/mortgage-leads',
    default: false,
    isRoute: true,
    authorize: true,
    roles: [],
    isDisabled: false,
    isExact: true,
    breadcrumbs: [
      {
        name: 'LeadsView:leads',
        isDisabled: false,
        route: '/home/mortgage-leads/view',
        groupName: 'mortgage',
      },
      {
        name: 'homeLayout.leadsManagementView.merge-leads',
        isDisabled: true,
        route: '/home/mortgage-leads/merge',
      },
    ],
  },
];

