
import React, {
  useState,
  useEffect,
  useCallback,
  useReducer,
} from "react";
import Joi from 'joi';
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Dialog,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import {
  Inputs,
  Spinner 
} from "../../../../../../Components";
import {
  GetParams,
  showError,
  showSuccess,
  getErrorByName 
} from "../../../../../../Helper";
import { UpdateBranchLocationAPI  } from "../../../../../../Services";
import { FooterWrapperComponent } from "../../../DevelopersUtilities/Components";
import { AddressLocationComponents } from "./AddressLocationComponents";

export const BranchLocationManagementDialog = ({
  parentTranslationPath,
  translationPath,
  isOpen,
  onClose,
  onSave,
  branchesLocationValue,
}) => {
  const { t } = useTranslation(parentTranslationPath, "Shared");

  const [isLoading, setIsLoading] = useState({
    allBranchesLocation: false,
    editBranchesLocation: false,
  });
  const [isSubmitted, setIsSubmitted] = useState(false) ; 
  const [defaultState, setDefaultState] = useState({
    developerContactId: +GetParams("id"),
    branchName: null,
    countryId: null,
    cityId: null , 
    districtId: null,
    communityId: null,
    subCommunityId: null,
    street : null ,
    buildingName: null , 
    buildingNumber: null,
    floorNumber:null,
    officeNumber: null,
    mapCoordinates:null,
    postalCode: null,
    poBox: null , 
  });

  const [defaultSelected, setDefaultSelected] = useState({
    country: null,
    city: null , 
    district: null,
    community: null,
    subCommunity: null,
    latitude : null  ,
    longitude : null 
  });

  const reducer = (select, action) => {
    if (action.id !== "edit") return { ...select, [action.id]: action.value };
    if (action.id === "edit") {
      return {
        ...action.value,
      };
    }
  };

  const reducer2 = (select, action) => {
    if (action.id !== "edit") return { ...select, [action.id]: action.value };
    if (action.id === "edit") {
      return {
        ...action.value,
      };
    }
  };

  const [state, setState] = useReducer(reducer, {
    ...defaultState,
  });

  const [selected, setSelected] = useReducer(reducer2, {
    ...defaultSelected,
  });
  const schema = Joi.object({
    branchName: Joi.string()
      .required()
      .messages({
        'string.base': t(`${translationPath}branch-name-is-required`),
        'string.empty': t(`${translationPath}branch-name-is-required`),
        'string.required': t(`${translationPath}branch-name-is-required`),
      }),
  })
    .options({
      abortEarly: false,
      allowUnknown: true,
    })
    .validate(state);



  const onChangeHandler = (key, values, selectedObj, selectedValues) => {
    if (!selectedObj) 
      setState({ id: [key], value: values });
     else
     {
      
        setState({ id: [key], value: values });
        setSelected({ id: [selectedObj], value: selectedValues });
      }
     
    }
 
  
  const UpdateBranchLocationAPIHandler = useCallback(async () => {
    setIsSubmitted(true);

    setIsLoading((item) => ({ ...item, editBranchesLocation: true }));
    const body = { ...state };
    const res = await UpdateBranchLocationAPI(branchesLocationValue?.branchesLocationId ,
      body
    );
    setIsLoading((item) => ({ ...item, editBranchesLocation: false }));
    if (!(res && res.status && res.status !== 200)) {
      showSuccess(
        t`${translationPath}branch-location-updated-successfully`
      );
      onSave(true);
    } else showError(t`${translationPath}branch-location-update-failed`);
  }, [state]);
 

  
  useEffect(() => {
    if (branchesLocationValue) {
      let location = null ;
      if (branchesLocationValue.mapCoordinates)
      {
        location = branchesLocationValue.mapCoordinates.split(',');
      }
      setIsLoading((item) => ({ ...item, info: true }));
      const updateState = {
        developerContactId: branchesLocationValue?.developerContactId || +GetParams('id'),
        branchesLocationId: branchesLocationValue?.branchesLocationId,
        branchName  : branchesLocationValue?.branchName, 
        countryId:branchesLocationValue?.countryId,  
        cityId:branchesLocationValue?.cityId,  
        districtId: branchesLocationValue?.districtId, 
        communityId: branchesLocationValue?.communityId,
        subCommunityId:  branchesLocationValue?.subCommunityId ,
        street :branchesLocationValue?.street , 
        buildingNumber  : branchesLocationValue.buildingNumber  , 
        buildingName : branchesLocationValue.buildingName , 
        floorNumber: branchesLocationValue.floorNumber , 
        officeNumber: branchesLocationValue.officeNumber , 
        mapCoordinates:branchesLocationValue.mapCoordinates,
        postalCode: branchesLocationValue.postalCode,
        poBox: branchesLocationValue.poBox,
           
      };
      const updateSelected = {
        country:branchesLocationValue?.countryId ? 
        {
          lookupItemId: branchesLocationValue.countryId,
          lookupItemName: branchesLocationValue?.country,
        } : null ,  
        city:branchesLocationValue?.cityId ? 
        {
          lookupItemId: branchesLocationValue.cityId,
          lookupItemName: branchesLocationValue?.city,
        } : null ,   
        district: branchesLocationValue?.districtId ?
        {
          lookupItemId: branchesLocationValue.districtId,
          lookupItemName: branchesLocationValue?.district,
        } : null , 
        community: branchesLocationValue?.communityId ? {
          lookupItemId: branchesLocationValue.communityId,
          lookupItemName: branchesLocationValue?.community,
        } : null , 
        subCommunity:  branchesLocationValue?.subCommunityId ? {
          lookupItemId: branchesLocationValue.subCommunityId,
          lookupItemName: branchesLocationValue?.subCommunity,
        } : null , 
        createdBy: branchesLocationValue?.createdBy,
        createdOn: branchesLocationValue?.createdOn,
        latitude : branchesLocationValue?.mapCoordinates  ? (location && location.length > 0 && location[0]) : null,
        longitude : branchesLocationValue?.mapCoordinates ? (location && location.length > 1 && location[1]) :null
      };
      setState({ id: "edit", value: updateState });
      setSelected({ id: "edit", value: updateSelected });
      setIsLoading((item) => ({ ...item, info: false }));
    }
  }, [branchesLocationValue]);

 
  return (
    <div>
      <Spinner
        isActive={isLoading.editDeveloperEmployee || isLoading.info}
        isAbsolute
      />
      <Dialog
        open={isOpen}
        onClose={() => {
          onClose();
        }}
        className="activities-management-dialog-wrapper"
        maxWidth="sm"
        disableBackdropClick
      >
        <DialogTitle id="alert-dialog-slide-title">
          <span>{t(`${translationPath}edit-branch-location`)}</span>
        </DialogTitle>
        <DialogContent className="dialog-content-wrapper">
          <div className="dialog-content-item w-50">
            <Inputs
              isAttachedInput
              idRef={"branch-NameRef"}
              labelValue={"branch-name"}
              type={"text"}
              value={state.branchName || ""}
              onInputChanged={(e) => {
                const { value } = e.target;
                onChangeHandler("branchName", value);
              }}
              inputPlaceholder={"enter-branch-name"}
              translationPath={translationPath}
              parentTranslationPath={parentTranslationPath}
              helperText={getErrorByName(schema, "branchName").message}
              error={getErrorByName(schema, "branchName").error}
              isWithError
              isSubmitted={isSubmitted}
            />
          </div>
          <AddressLocationComponents
            className={"dialog-content-item w-50"}
            translationPath={translationPath}
            parentTranslationPath={parentTranslationPath}
            state={state}
            setState={setState}
            setSelected={setSelected}
            selected={selected}
            editForm
          />

          <div className="dialog-content-item w-50">
            <Inputs
              isAttachedInput
              idRef={"StreetRef"}
              labelValue={"street"}
              value={state.street || ""}
              type={"text"}
              onInputChanged={(e) => {
                const { value } = e.target;
                onChangeHandler("street", value);
              }}
              translationPath={translationPath}
              parentTranslationPath={parentTranslationPath}
            />
          </div>
          <div className="dialog-content-item">
            <Inputs
              isAttachedInput
              idRef={"BuildingNameRef"}
              labelValue={"building-name"}
              type={"text"}
              value={state.buildingName || ""}
              onInputChanged={(e) => {
                const { value } = e.target;
                onChangeHandler("buildingName", value);
              }}
              translationPath={translationPath}
              parentTranslationPath={parentTranslationPath}
            />
          </div>
          <div className="dialog-content-item w-50">
            <Inputs
              isAttachedInput
              idRef={"BuildingNumberRef"}
              labelValue={"building-number"}
              type={"text"}
              value={state.buildingNumber || ""}
              onInputChanged={(e) => {
                const { value } = e.target;
                onChangeHandler("buildingNumber", value);
              }}
              translationPath={translationPath}
              parentTranslationPath={parentTranslationPath}
            />
          </div>
          <div className="dialog-content-item w-50">
            <Inputs
              isAttachedInput
              idRef={"FloorNumberRef"}
              labelValue={"floor-number"}
              type={"text"}
              value={state.floorNumber || ""}
              onInputChanged={(e) => {
                const { value } = e.target;
                onChangeHandler("floorNumber", value);
              }}
              translationPath={translationPath}
              parentTranslationPath={parentTranslationPath}
            />
          </div>
          <div className="dialog-content-item ">
            <Inputs
              isAttachedInput
              idRef={"OfficeNumberRef"}
              labelValue={"office-number"}
              type={"text"}
              value={state.officeNumber || ""}
              onInputChanged={(e) => {
                const { value } = e.target;
                onChangeHandler("officeNumber", value);
              }}
              translationPath={translationPath}
              parentTranslationPath={parentTranslationPath}
            />
          </div>

          <div className="dialog-content-item">
            <Inputs
              isAttachedInput
              idRef={"Postal-ZIPCodeRef"}
              labelValue={"postal-zip-code"}
              type={"text"}
              value={state.postalCode || ""}
              onInputChanged={(e) => {
                const { value } = e.target;
                onChangeHandler("postalCode", value);
              }}
              translationPath={translationPath}
              parentTranslationPath={parentTranslationPath}
            />
          </div>
          <div className="dialog-content-item">
            <Inputs
              isAttachedInput
              idRef={"poBoxRef"}
              labelValue={"po-box"}
              type={"text"}
              value={state.poBox || ""}
              onInputChanged={(e) => {
                const { value } = e.target;
                onChangeHandler("poBox", value);
              }}
              translationPath={translationPath}
              parentTranslationPath={parentTranslationPath}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <FooterWrapperComponent
            parentTranslationPath={parentTranslationPath}
            translationPath={translationPath}
            onClose={onClose}
            onSave={() => {
              UpdateBranchLocationAPIHandler();
            }}
            disabled={isLoading.editBranchesLocation || schema.error}
          />
        </DialogActions>
      </Dialog>
    </div>
  );
};
