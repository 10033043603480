import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Avatar } from "@material-ui/core";
import "./AboutUnitCard.scss";
import { getDownloadableLink, getFirstLastNameLetters } from "../../Helper";
import { formatCommasForPriceUnit } from "../../Helper/formatCommas.Helper";
import { CopyToClipboardComponents } from "../../ReusableComponents/UtilityComponents/CopyToClipboardComponents/CopyToClipboardComponents";
import { GetUnitImagesForLeaseTransaction } from '../../Services';

export const AboutUnitCard = ({ unitData, orientation }) => {

  const [mappedData, setMappedData] = useState({
    rentListingAgent: null,
    rentPriceFees: null,
  });
  const [unitCoverImageURLs, setUnitCoverImageURLs] = useState([]);
  {/* todo:: fix listing agent doesnt appear when u r in open file --> after updating lead owner */ }
  const mapUnitData = () => {
    const rentListingAgent = unitData?.rent_listing_agent?.name || null;

    const isRentPriceFormat1 = (unitData?.rent_price_fees?.rentPerYear && unitData?.rent_price_fees?.rentPerYear !== "N/A") ? unitData?.rent_price_fees?.rentPerYear : null;
    const isRentPriceFormat2 = (unitData?.rent_price_fees && unitData?.rent_price_fees !== "N/A") ? unitData?.rent_price_fees : null;
    const rentPriceFees = isRentPriceFormat1 || isRentPriceFormat2 || null;

    const localMappedData = {
      ...unitData,
      rentListingAgent,
      rentPriceFees,
    };

    setMappedData(localMappedData);
  };

  const applyThumbnailBg = (bgURL) => {
    let style = {}
    if (bgURL) style.backgroundImage = `url(\"${bgURL}\")`;
    return style;
  }

  const getUnitCoverImages = async () => {
    const response = await GetUnitImagesForLeaseTransaction(unitData?.id);
    if (!(response && response.status && response.status !== 200)) {

      const mappedImageURLs = response ? response.map(item => getDownloadableLink(item.fileId)) : [];
      setUnitCoverImageURLs(mappedImageURLs);
    }
    else setUnitCoverImageURLs([]);
  };


  useEffect(() => {
    if (unitData) {
      mapUnitData();
      getUnitCoverImages();
    }
  }, [unitData]);

  return (
    <>
      {unitData ?
        <div className={`propx-view About-Unit-Card ${orientation}`}>
          <div className="thumbnails">
            <div className="bg-thumbnail" style={applyThumbnailBg(unitCoverImageURLs[0])}></div>
            <div className="col-2">
              <div className="bg-thumbnail" style={applyThumbnailBg(unitCoverImageURLs[1])}></div>
              <div className="bg-thumbnail" style={applyThumbnailBg(unitCoverImageURLs[2])}></div>
            </div>
          </div>
          <div className="unit-inspect">
            {mappedData?.rentPriceFees && (
              <p className="c-propx">
                <span className="fz-18 fw-simi-bold">{`${formatCommasForPriceUnit(
                  mappedData.rentPriceFees
                )}`}</span>
                <span className="fz-12 opacity-8 mx-2">AED</span>
              </p>
            )}
            {unitData.name ? <p className="fz-16 fw-simi-bold">{unitData.name}</p> : null}
            <div className="d-flex-default c-propx fw-simi-bold fz-12">
              {unitData.unit_ref_no ? (
                <div className="d-flex-default mr-2">
                  <CopyToClipboardComponents
                    data={unitData.unit_ref_no}
                    childrenData={`# ${unitData.unit_ref_no}`}
                    labelClaass="mr-1"
                  />
                </div>
              ) : null}
              <span>{`${unitData.unit_usage_type || ""}${(unitData.unit_usage_type && unitData.unitType) ? "/" : ""}${unitData.unitType || ""
                }`}</span>
            </div>
            {unitData.country?.lookupItemName ? (
              <p className="fz-12">{unitData.country.lookupItemName}</p>
            ) : null}
            <p className="my-2 fw-simi-bold mb-2">
              {unitData.data_completed ? (
                <span className="info-capsule gold">{`${unitData.data_completed}%`}</span>
              ) : null}
              {unitData.lease_status ? (
                <span className="info-capsule navy mx-2">
                  {unitData.lease_status}
                </span>
              ) : null}
            </p>
            <div className="d-flex-default flex-wrap my-1">
              {unitData.flatContent ?
                unitData.flatContent
                  .filter(
                    (filterItem) => filterItem.value && filterItem.value !== "N/A"
                  )
                  .map((item, index) => (
                    <p
                      key={`flatContentsItemRef${index + 1}`}
                      className="d-flex-default fj-start  light-text fw-simi-bold ml-0  mx-1"
                    >
                      <span className={`${item.iconClasses} mdi-18px`} />
                      <span className="mx-1">{item.value}</span>
                      <span>{(item.title && <span>{item.title}</span>) || null}</span>
                    </p>
                  )) : null}
            </div>
            {mappedData.rentListingAgent ? (
              <div className="d-flex-default mb-1">
                <div className="campaign-team-f1">
                  <Avatar
                    className="avatars-wrapper team-avatar fz-12 sm-theme"
                    src={''}
                  >
                    {getFirstLastNameLetters(mappedData.rentListingAgent)}
                  </Avatar>
                </div>
                <div className="d-flex-column">
                  <span className="fz-12 fw-simi-bold">{mappedData?.rentListingAgent}</span>
                  <span className="fz-10 text-tertiary">
                    {"Listing agent"}
                  </span>
                </div>
              </div>
            ) : null}
          </div>
        </div> 
      : null}
    </>
  );
};

AboutUnitCard.propTypes = {
  unitId: PropTypes.number,
  orientation: PropTypes.oneOf(["vertical", "horizantal"]),
  unitData: PropTypes.object,
}
AboutUnitCard.defaultProps = {
  orientation: 'vertical',
  unitData: {},
};