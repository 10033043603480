import { HttpServices } from '../../Helper';
import { config } from '../../config';


export const changeStatusOfconfigrationSystemServices = async (body) => {
    const result = await HttpServices.put(`${config.server_address}/CrmDfm/SystemConfiguration/SetSystemConfigurationAsActiveOrInactive` , body)
        .then(data => data)
        .catch(error => error.response);
    return result;
}

export const GetAllSystemConfigurationsStatus = async () => {
    const result = await HttpServices.get(`${config.server_address}/CrmDfm/SystemConfiguration/GetAllSystemConfigurations`)
        .then(data => data)
        .catch(error => error.response);
    return result;
}

export const GetSystemConfigurationByName = async (configName) => {
    const result = await HttpServices.get(`${config.server_address}/CrmDfm/SystemConfiguration/GetSystemConfigurationByName?configName=${configName}`)
        .then(data => data)
        .catch(error => error.response);
    return result;
}
