import React, {
  useEffect, useState, useCallback
} from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { ButtonBase } from '@material-ui/core';
import { DialogComponent, Spinner, Tables } from '../../../Components';
import { TableActions } from '../../../Enums';
import { DeleteTransactionConfig, GetAllUnitTransactionConfig } from '../../../Services/UnitTransactionConfigServices';
import { GlobalHistory, showError, showSuccess } from '../../../Helper';

const parentTranslationPath = 'TransactionConfigurationView';
const translationPath = '';
export const TransactionConfigurationView = () => {
  const { t } = useTranslation(parentTranslationPath);
  const [isLoading, setIsLoading] = useState(false);
  const [APIData, setAPIData] = useState([]);
  const [configId, setconfigId] = useState(null);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [filter, setFilter] = useState({
    search: '',
    pageIndex: 0,
    // eslint-disable-next-line radix
    pageSize: parseInt(localStorage.getItem('Pagination')) || 25,
  });
  const onPageIndexChanged = (pageIndex) => {
    setFilter((item) => ({ ...item, pageIndex }));
  };
  const onPageSizeChanged = (pageSize) => {
    setFilter((item) => ({ ...item, pageIndex: 0, pageSize }));
  };

  const [TeamplateListDataHeader] = useState([
    {
      id: 1,
      label: t(`${translationPath}unitTransactionConfigId`),
      input: 'unitTransactionConfigId',
      isDate: false,
      isHiddenFilter: false
    },
    {
      id: 2,
      label: t(`${translationPath}name`),
      input: 'name',
      isDate: false,
      isHiddenFilter: false
    },
    {
      id: 3,
      label: t(`${translationPath}createdOn`),
      component: (item) => (
        <span>
          {item && item.createdOn &&
            moment(item && item.createdOn).format('DD/MM/YYYY - hh:mm A') || 'N/A'}
        </span>
      ),
      isDate: false,
      isHiddenFilter: false
    },
    {
      id: 4,
      label: t(`${translationPath}complianceNotifications`),
      component: (item) => (
        <div>
          {item && item.complianceNotifications.map((el) => `${el.lookupItemName || ''}, `)}
        </div>
      ),

      isDate: false,
      isHiddenFilter: false
    },
    {
      id: 5,
      label: t(`${translationPath}accountsNotifications`),
      component: (item) => (
        <div>
          {item && item.accountsNotifications.map((el) => `${el.lookupItemName || ''}, `)}
        </div>
      ),
      isDate: false,
      isHiddenFilter: false
    },
    {
      id: 6,
      label: t(`${translationPath}listingAgents`),
      component: (item) => (
        <div>
          {item && item.listingAgents.map((el) => `${el.lookupItemName || ''}, `)}
        </div>
      ),
      isDate: false,
      isHiddenFilter: false
    },
    {
      id: 6,
      label: t(`${translationPath}notificationTemplates`),
      component: (item) => (
        <div>
          {item && item.notificationTemplates.map((el) => `${el.lookupItemName || ''}, `)}
        </div>
      ),
      isDate: false,
      isHiddenFilter: false
    }
  ]);

  const tableActionClicked = useCallback(
    (actionEnum, item) => {
      if (actionEnum === TableActions.view.key) {
        GlobalHistory.push(
          `/home/TransactionConfigurationView/edit?&id=${item.unitTransactionConfigId}`
        );
      } else {
        // setOpenDeleteDialog(true);
        GlobalHistory.push(
          `/home/TransactionConfigurationView/edit?&id=${item.unitTransactionConfigId}`
        );
        setconfigId(item.unitTransactionConfigId);
      }
    },
    []
  );

  const GetAllUnitTransactionConfigAPI = useCallback(async () => {
    setIsLoading(true);
    const result = await GetAllUnitTransactionConfig(filter.pageIndex, filter.pageSize);
    if (!(result && result.status && result.status !== 200))
      setAPIData(result || []);
    else setAPIData([]);
    setIsLoading(false);
  }, []);

  const deleteHandler = async () => {
    setIsLoading(true);
    const res = await DeleteTransactionConfig(configId);
    if (!(res && res.status && res.status !== 200)) {
      showSuccess(t(`${translationPath}delete-Transaction-success`));
      GetAllUnitTransactionConfigAPI(filter);
    } else showError(t(`${translationPath}delete-Transaction-failed`));
    setIsLoading(false);
    setOpenDeleteDialog(false);
  };

  useEffect(() => {
    GetAllUnitTransactionConfigAPI(filter);
  }, [GetAllUnitTransactionConfigAPI, filter]);
  useEffect(() => {
  }, []);

  return (
    <>
      <div />
      <div className='px-1 Transaction-Configuration-view-wrapper'>
        <div className='d-flex m-3'>
          {/* <ButtonBase
            className='btns theme-solid'
            onClick={() => GlobalHistory.push(
              '/home/TransactionConfigurationView/add'
            )}
          >
            <span className='mdi mdi-plus c-white' />
            <span className='add-buttton'>{t(`${translationPath}AddNew`)}</span>
          </ButtonBase> */}
        </div>
        <Spinner isActive={isLoading} />
        <div className='w-100 px-1'>
          <Tables
            headerData={TeamplateListDataHeader}
            data={APIData && APIData.result}
            activePage={filter.pageIndex}
            itemsPerPage={filter.pageSize}
            actionsOptions={{
              onActionClicked: tableActionClicked,
            }}
            onPageIndexChanged={onPageIndexChanged}
            onPageSizeChanged={onPageSizeChanged}
            defaultActions={[
              {
                enum: TableActions.view.key,
              },
              // {
              //   enum: TableActions.delete.key
              // }
            ]}
            totalItems={(APIData && APIData.totalCount) || 0}
          />
        </div>
      </div>
      <DialogComponent
        titleText={t(`${translationPath}delete-rotation-schema`)}
        saveText={t('confirm')}
        saveType='button'
        maxWidth='sm'
        dialogContent={(
          <div className='d-flex-column-center'>
            <Spinner isActive={isLoading} />
            <span className='mdi mdi-close-octagon c-danger mdi-48px' />
            <span>
              {' '}
              <span>{`${t('Are-you-sure-you-want-to-Delete')} ${configId || ''}`}</span>
            </span>
          </div>
        )}
        saveClasses='btns theme-solid bg-danger w-100 mx-2 mb-2'
        isOpen={openDeleteDialog}
        onSaveClicked={deleteHandler}
        onCloseClicked={() => setOpenDeleteDialog(false)}
        onCancelClicked={() => setOpenDeleteDialog(false)}
        translationPath={translationPath}
      />
    </>
  );
};
