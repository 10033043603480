import React, { useState, useEffect, useCallback, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import ButtonBase from '@material-ui/core/ButtonBase';
import { RotationSchemaTabelView } from './RotationSchemaTabelView/RotationSchemaTabelView';
import {
 SelectComponet, Inputs, PermissionsComponent, AutocompleteComponent
} from '../../../../Components';
import { GlobalOrderFilterActions } from '../../../../store/GlobalOrderFilter/GlobalOrderFilterActions';
import { RotationCriteriaDialog } from './Dialogs/RotationCriteriaDialog/RotationCriteriaDialog';
import { GetAdvanceSearchRotationScheme } from '../../../../Services/RotaionSchemaService/RotationSchemaService';
import { useTitle } from '../../../../Hooks';
import { RotationSchemaPermissions } from '../../../../Permissions';
import { OrganizationUserSearch, GetAllBranches, GetTeam } from '../../../../Services';
import { returnPropsByPermissions } from '../../../../Helper';

const parentTranslationPath = 'Agents';
const translationPath = '';

export const AgentsRotationCriteriaView = () => {
  const orderFilter = useSelector((state) => state.GlobalOrderFilterReducer);
  const { t } = useTranslation(parentTranslationPath);
  const dispatch = useDispatch();
  const searchTimer = useRef(null);

  useTitle(t(`${translationPath}rotation-criteria`));
  const reducer = useCallback((itemsState, action) => {
    if (action.id !== 'edit') return { ...itemsState, [action.id]: action.value };
    return {
      ...action.value,
    };
  }, []);
const [selectedOrderBy, setSelectedOrderBy] = useState({
    filterBy: (orderFilter.rotationCriteriaFilter && orderFilter.rotationCriteriaFilter.filterBy) || null,
    orderBy: (orderFilter.rotationCriteriaFilter && orderFilter.rotationCriteriaFilter.orderBy) || null,
  });

  const [sortBy, setSortBy] = useState(null);
  const [orderBy, setOrderBy] = useState(null);
  const [agentName, setAgentName] = useState(null);
  const [filter, setFilter] = useState({
    filterBy: (selectedOrderBy && selectedOrderBy.filterBy) || null,
    orderBy: (selectedOrderBy && selectedOrderBy.orderBy) || null,
    pageSize: parseInt(localStorage.getItem('Pagination')) || 25,
    pageIndex: 0,
    search: '',
    agentId: '',
    teamIds: [],
    branchIds: [],
  });

  const [isLoading, setIsLoading] = useState(false);
  const [timer, setTimer] = useState(null);
  const [agents, setAllAgent] = useState({
    result: [],
    totalCount: 0,
  });
  const [response, setResponse] = useState({
    result: [],
    totalCount: 0,
  });
  const [openDialog, setOpenDialog] = useState(false);
  const [searchedItem, setSearchedItem] = useState('');
  const [searchItem, setSearchItem] = useState(null);
  const [data, setData] = useReducer(reducer, {
    branches: [],
    teams: [],
  });
  const [selected, setSelected] = useState({
    branches: [],
    teams: [],
  });

  const GetOrderedRotationScheme = useCallback(async () => {
    if (returnPropsByPermissions(RotationSchemaPermissions.ViewAllRotationSchema.permissionsId)) {
    setIsLoading(true);
    const res = await GetAdvanceSearchRotationScheme({ ...filter, pageIndex: filter.pageIndex + 1 });
    if (res) {
      setResponse({
        result: (res && res.result) || [],
        totalCount: (res && res.totalCount) || 0,
      });
    } else {
      setResponse({
        result: [],
        totalCount: 0,
      });
    }
    setIsLoading(false);
  }
  }, [filter]);

  const filterByChanged = (value) => {
    setSelectedOrderBy((item) => ({ ...item, filterBy: value }));
  };
  const orderByChanged = (value) => {
    setSelectedOrderBy((item) => ({ ...item, orderBy: value }));
  };

  const orderBySubmitted = (event) => {
    event.preventDefault();
    if (!selectedOrderBy.filterBy || !selectedOrderBy.orderBy)
      return;

    dispatch(
      GlobalOrderFilterActions.globalOrderFilterRequest({
        ...orderFilter,
        rotationCriteriaFilter: {
          filterBy: selectedOrderBy.filterBy,
          orderBy: selectedOrderBy.orderBy,
        },
      })
    );
    setOrderBy({
      filterBy: selectedOrderBy.filterBy,
      orderBy: selectedOrderBy.orderBy,
    });
  };

  const onPageIndexChanged = (pageIndex) => {
    setFilter((item) => ({ ...item, pageIndex }));
  };
  const onPageSizeChanged = (pageSize) => {
    setFilter((item) => ({ ...item, pageIndex: 0, pageSize }));
  };

  const reloadData = (pageSize) => {
    setFilter((item) => ({ ...item, pageIndex: filter.pageIndex, pageSize: filter.pageSize }));
  };

  const getBranches = async (branchName) => {

    const res = await GetAllBranches({ pageSize: 25, pageIndex: 1, branchName: branchName || '', });

    if (!(res && res.status && res.status !== 200)) {
      setData({ id: 'branches', value: (res && res.result) || [] });
    } else {
      setData({ id: 'branches', value: [] });
    }
  };

  const getTeams = async (teamName) => {

    const res = await GetTeam(1, 25, (teamName || ''));

    if (!(res && res.status && res.status !== 200)) {
      setData({ id: 'teams', value: (res && res.result) || [] });
    } else {
      setData({ id: 'teams', value: [] });
    }
  };
  const searchHandler = (event) => {
    const { value } = event.target;
    setSearchItem(value);
    setTimer(
      setTimeout(() => {
        setFilter((filter) => ({ ...filter, search: searchedItem }));
      }, 1000)
    );
  };
  const getAllAgents = useCallback(async () => {
    const res = await OrganizationUserSearch({});
    if (!(res && res.status && res.status !== 200)) {
      setAllAgent({
        result: (res && res.result) || [],
        totalCount: (res && res.totalCount) || 0,
      });
    } else {
      setAllAgent({
        result: [],
        totalCount: 0,
      });
    }
  }, []);

  useEffect(() => {
    GetOrderedRotationScheme();
  }, [GetOrderedRotationScheme]);

  useEffect(() => {
    if (orderBy)
    setFilter((item) => ({ ...item, filterBy: orderBy.filterBy, orderBy: orderBy.orderBy }));
  }, [orderBy]);

  useEffect(() => {
    if (sortBy)
    setFilter((item) => ({ ...item, filterBy: sortBy.filterBy, orderBy: sortBy.orderBy }));
  }, [sortBy]);


  useEffect(() => {
    getAllAgents();
    getBranches();
    getTeams();
  }, []);

  return (
    <div className='RotationSchema-wrapper view-wrapper'>
      <div className='w-100 px-2'>
        <div className='header-section'>
          <div className='filter-section'>
            <div className='section'>
              <PermissionsComponent
                permissionsList={Object.values(RotationSchemaPermissions)}
                permissionsId={RotationSchemaPermissions.CreateNewRotations.permissionsId}
              >
                <ButtonBase
                  className='btns theme-solid'
                  id='new-rotation-criteria-bbt'
                  onClick={() => {
                    setOpenDialog(true);
                  }}
                >
                  <span className='mdi mdi-plus' />
                  <span>{t(`${translationPath}new-rotation-criteria`)}</span>
                </ButtonBase>
              </PermissionsComponent>
            </div>
            <div className='section d-flex-column w-50 px-2 m-auto'>
              <PermissionsComponent
                permissionsList={Object.values(RotationSchemaPermissions)}
                permissionsId={RotationSchemaPermissions.ViewAllRotationSchema.permissionsId}
              >
                <div className='w-100'>
                  <Inputs
                    idRef='RotationCriteriaRef'
                    label={t(`${translationPath}rotation-criteria`)}
                    value={searchedItem}
                    onInputChanged={(e) => setSearchedItem(e.target.value)}
                    inputPlaceholder={t(`${translationPath}search-rotation-criteria`)}
                    onKeyUp={searchHandler}
                    onKeyDown={() => {
                    if (timer != null) clearTimeout(timer);
                  }}
                    beforeIconClasses='mdi mdi-magnify mdi-24px c-gray-primary'
                  />
                </div>
                <div className='d-flex w-100 pl-4-reversed'>
                  <AutocompleteComponent
                    idRef='referredToRef'
                    isLoading={isLoading.agents}
                    multiple={false}
                    data={agents.result || []}
                    value={filter.agentId}
                    chipsLabel={(option) => option.fullName || ''}
                    displayLabel={(option) => option.fullName || ''}
                    withoutSearchButton
                    inputPlaceholder={t(`${translationPath}Agent`)}
                    parentTranslationPath={parentTranslationPath}
                    translationPath={translationPath}
                    onChange={(event, newValue) => {
                        setFilter((item) => ({
                          ...item,
                          agentId: (newValue && newValue.id) || null,
                          pageIndex: 0,
                          pageSize: 25,
                          search: searchItem || '',
                          relatedUnitNumberId: null,
                          relatedLeadNumberId: null,
                        }));
                      }}
                  />
                  <AutocompleteComponent
                    idRef='branch2Ref'
                    wrapperClasses='ml-2'
                    selectedValues={selected.branches || []}
                    inputPlaceholder={t(`${translationPath}branches`)}
                    data={data.branches || []}
                    chipsLabel={(option) => (option.branchName && option.branchName) || ''}
                    displayLabel={(option) => (option.branchName && option.branchName) || ''}
                    getOptionSelected={(option) =>
                      selected.branches?.findIndex((item) => item.branchId === option.branchId) !== -1 || ''}
                    multiple
                    withoutSearchButton
                    onInputKeyUp={(e) => {
                      const { value } = e.target;
                      if (searchTimer.current) clearTimeout(searchTimer.current);
                      searchTimer.current = setTimeout(() => {
                        getBranches(value);
                      }, 700);
                    }}
                    onChange={(event, newValue) => {
                      const branchIds = newValue?.map(item=> item.branchId );
                      setSelected((item) => ({
                        ...item,
                        branches: newValue
                      }));

                      setFilter((item) => ({
                        ...item,
                        branchIds,
                      }));
                    }}
                  />
                  <AutocompleteComponent
                    idRef='teams-ToRef'
                    wrapperClasses='ml-2'
                    inputPlaceholder={t(`${translationPath}teams`)}
                    selectedValues={selected.teams || []}
                    multiple
                    data={data.teams || []}
                    chipsLabel={(option) => option.teamsName || option.teamName || ''}
                    displayLabel={(option) => t(`${(option.teamsName || option.teamName) || ''}`)}
                    getOptionSelected={(option) =>
                      selected.teams?.findIndex((item) => item && ((item.teamsId === option.teamsId)
                        || (item.teamId === option.teamsId))) !== -1 || ''}
                    withoutSearchButton
                    onInputKeyUp={(e) => {
                      const { value } = e.target;
                      if (searchTimer.current) clearTimeout(searchTimer.current);
                      searchTimer.current = setTimeout(() => {
                        getTeams(value);
                      }, 700);
                    }}
                    parentTranslationPath={parentTranslationPath}
                    translationPath={translationPath}
                    onChange={(event, newValue) => {
                      const teamIds = newValue?.map(item=> item.teamsId );
                      setSelected((item) => ({
                        ...item,
                        teams: newValue
                      }));

                      setFilter((item) => ({
                        ...item,
                        teamIds,
                      }));
                    }}
                  />
                </div>
              </PermissionsComponent>
            </div>
          </div>
          <div />
        </div>
        <div className='d-flex px-2'>
          <PermissionsComponent
            permissionsList={Object.values(RotationSchemaPermissions)}
            permissionsId={RotationSchemaPermissions.ViewAllRotationSchema.permissionsId}
          >

            <span className='mx-2 mt-1'>{t(`${translationPath}select`)}</span>
            <span className='separator-v s-primary s-reverse s-h-25px mt-1' />
            <span className='px-2 d-flex'>
              <span className='texts-large mt-1'>
                {t(`${translationPath}order-by`)}
                :
              </span>
              <div className='px-2'>
                <SelectComponet
                  idRef='filterByRef'
                  data={[
                    { id: 'CreatedOn', filterBy: 'created-on' },
                    { id: 'UpdateOn', filterBy: 'last-updated' },
                  ]}
                  wrapperClasses='mb-3'
                  isRequired
                  value={selectedOrderBy.filterBy}
                  onSelectChanged={filterByChanged}
                  valueInput='id'
                  textInput='filterBy'
                  emptyItem={{
                    value: null,
                    text: 'select-filter-by',
                    isDisabled: false,
                  }}
                  parentTranslationPath={parentTranslationPath}
                  translationPath={translationPath}
                  translationPathForData={translationPath}
                />
              </div>
              <div className='px-2'>
                <SelectComponet
                  idRef='orderByRef'
                  data={[
                    { id: 1, orderBy: 'ascending' },
                    { id: 2, orderBy: 'descending' },
                  ]}
                  value={selectedOrderBy.orderBy}
                  onSelectChanged={orderByChanged}
                  emptyItem={{ value: null, text: 'select-sort-by', isDisabled: false }}
                  wrapperClasses='mb-3'
                  isRequired
                  valueInput='id'
                  textInput='orderBy'
                  parentTranslationPath={parentTranslationPath}
                  translationPath={translationPath}
                  translationPathForData={translationPath}
                />
              </div>
              <div className='mt-1'>
                <ButtonBase
                  className='btns theme-solid'
                  id='action_apply'
                  onClick={orderBySubmitted}
                  disabled={!selectedOrderBy.filterBy || !selectedOrderBy.orderBy}
                >
                  <span>{t(`${translationPath}apply`)}</span>
                </ButtonBase>
              </div>
            </span>
          </PermissionsComponent>
        </div>
        <PermissionsComponent
          permissionsList={Object.values(RotationSchemaPermissions)}
          permissionsId={RotationSchemaPermissions.ViewAllRotationSchema.permissionsId}
        >
          <RotationSchemaTabelView
            parentTranslationPath={parentTranslationPath}
            filter={filter}
            translationPath={translationPath}
            data={response || {}}
            isLoading={isLoading}
            reloadData={reloadData}
            onPageIndexChanged={onPageIndexChanged}
            onPageSizeChanged={onPageSizeChanged}
            setSortBy={setSortBy}
            setUpdateIsActive={() => {}}
          />
        </PermissionsComponent>
      </div>
      {openDialog && (
        <RotationCriteriaDialog
          open={openDialog}
          close={() => {
            setOpenDialog(false);
          }}
          onSave={() => {
            // setOpenDialog(false);
            // reload data
            reloadData();
          }}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
        />
      )}
    </div>
  );
};
