import { config } from '../config';
import { HttpServices } from '../Helper';

export const GetAllUnitTransactionConfig = async (pageIndex, pageSize) => {
  const result = await HttpServices.get(
    `${config.server_address}/CrmDfm/UnitTransactionConfig/GetAllUnitTransactionConfig?pageIndex=${pageIndex}&pageSize=${pageSize}`
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

export const GetUnitTransactionConfigById = async (configId) => {
  const result = await HttpServices.get(
    `${config.server_address}/CrmDfm/UnitTransactionConfig/GetUnitTransactionConfigById/${configId}`
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

export const CreateUnitTransactionConfig = async (body) => {
  const result = await HttpServices.post(`${config.server_address}/CrmDfm/UnitTransactionConfig/CreateUnitTransactionConfig`, body)
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

export const UpdateUnitTransactionConfig = async (configId, body) => {
  const result = await HttpServices.put(
    `${config.server_address}/CrmDfm/UnitTransactionConfig/UpdateUnitTransactionConfig/${configId}`,
    body
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

export const DeleteTransactionConfig = async (configId) => {
  const result = await HttpServices.delete(
    `${config.server_address}/CrmDfm/UnitTransactionConfig/DeleteTransactionConfig?configId=${configId}`
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};
