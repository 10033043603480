import React, { useEffect, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonBase, IconButton } from '@material-ui/core';
import moment from 'moment';
import { ContactTypeEnum } from '../../../../../Enums';
import { Spinner, RadiosGroupComponent } from '../../../../../Components';
import {
  contactsDetailsGet,
  GetAllFormFieldsByFormId,
  MergeContact,
} from '../../../../../Services';
import { GetParams, GlobalHistory, showError, showSuccess } from '../../../../../Helper';
import { useSelector, useDispatch } from 'react-redux';
import { ContactsMergeActions } from '../../../../../store/ContactsMerge/Actions';

const parentTranslationPath = 'ContactsView';
const translationPath = 'utilities.contactsMergeView.';

export const ContactsMergeView = () => {
  const { t } = useTranslation(parentTranslationPath);
  const [isLoading, setIsLoading] = useState(false);
  const [isFormsLoading, setIsFormsLoading] = useState(false);
  const [originalContact, setOriginalContact] = useState(null);
  const [mergedRadioValues, setMergedRadioValues] = useState({});
  const [radioValues, setRadioValues] = useState([]);
  const [formsContent, setFormsContent] = useState([]);
  const [userTypeId, setUserTypeId] = useState(null);
  const [contactsDetails, setContactsDetails] = useState([]);
  const [finalContactDetails, setFinalContactDetails] = useState([]);
  const [activeContacts, setActiveContacts] = useState([]);
  const [activeContactsIndexes, setActiveContactsIndexes] = useState([0, 1, 2]);
  const contactsMergeInfo = useSelector((state) => state.ContactsMergeReducer);
  const dispatch = useDispatch();

  const getContact = useCallback(async (id) => {
    const response = await contactsDetailsGet({ id });

    if (!(response && response.status && response.status !== 200)) {
      return response;
    }
  }, []);

  const getAllContactsDetails = useCallback(async () => {
    if (contactsMergeInfo?.ContactsMergeIds) {
      let contactsDetailsList = [];
      for (let index = 0; index < contactsMergeInfo.ContactsMergeIds.length; index++) {
        const contactId = contactsMergeInfo.ContactsMergeIds[index];
        setIsLoading(true);
        const response = await getContact(contactId);
        setIsLoading(false);

        if (response) contactsDetailsList.push(response);
      }

      setContactsDetails(contactsDetailsList);

      if (contactsDetailsList.length > 0) {
        const firstContactIndex = 0;
        const secondContactIndex = 3;
        const activeContactsList = contactsDetailsList.slice(firstContactIndex, secondContactIndex);
        setActiveContacts(activeContactsList);
        setOriginalContact(contactsDetailsList[0]);
      }
    }
  }, [contactsMergeInfo]);

  const mergeHandler = useCallback(async () => {

    const obsoleteContacts = contactsDetails.filter(item=>item.contactsId !== originalContact.contactsId);
    const obsoleteIds = obsoleteContacts&&obsoleteContacts.map(item=> item.contactsId);

    const result = await MergeContact({
      originalId: originalContact.contactsId,
      obsoleteIds,
      mergedJson: {
        contact: finalContactDetails.contact,
      },
    });
    if (!(result && result.status && result.status !== 200)) {
      GlobalHistory.goBack();
      showSuccess(t(`${translationPath}contacts-merge-notification`));
      dispatch(ContactsMergeActions.contactsMergeRequest([]));
    } else if (
      result &&
      result.data &&
      result.data.Message.includes('THERE_IS_TRANSACTION_IN_CONTACT')
    )
      showError(t(`${translationPath}${result.data.Message.split(': ')[1]}`));
    else showError(t(`${translationPath}contacts-merged-failed`));
  }, [finalContactDetails, originalContact, contactsDetails]);

  const cancelHandler = () => {
    GlobalHistory.goBack();
    dispatch(ContactsMergeActions.contactsMergeRequest([]));
  };

  const GetAllFormByFormId = useCallback(async () => {
    setIsFormsLoading(true);
    if (userTypeId) {
      const result = await GetAllFormFieldsByFormId(userTypeId);
      
      const mappedFormFields = result.map((item) =>({
        formFieldTitle: item.formFieldTitle,
        formFieldName: item.formFieldName,
        uiWidgetType: item.uiWidgetType,
        formFieldId: item.formFieldId,
      }));

      setFormsContent(mappedFormFields);
      const arr = result.map((item) => `${item.formFieldName}-0`);
      setRadioValues(JSON.parse(JSON.stringify(arr)));
    }
    setIsFormsLoading(false);
  }, [userTypeId]);

  const getDefaultContactImage = (contactType) => ContactTypeEnum[contactType].defaultImg;

  const checkWidgetType = useCallback(
    (item, contact) => {
      let type;
      switch (item.uiWidgetType) {
        case 'select':
          if (
            item.formFieldName === 'promo_sms_email' ||
            item.formFieldName === 'promo_smsemail' ||
            item.formFieldName === 'multi_property_owner' ||
            item.formFieldName === 'high_net_worth' ||
            item.formFieldName === 'owner_in-multiple_areas' ||
            item.formFieldName === 'owner_for_one_unit' ||
            item.formFieldName === 'owner_in_multiple_areas' ||
            item.formFieldName === 'investor' ||
            item.formFieldName === 'exclusive'
          )
            type = contact;
          else if (item.formFieldName === 'contact_preference')
            type = contact.map((el) => `${el.lookupItemName}  ` || 'N/A');
          else type = contact.lookupItemName;
          break;
        case 'communication':
          if (
            item.formFieldName === 'email_address' ||
            item.formFieldName === 'spouse_email' ||
            item.formFieldName === 'general_email' ||
            item.formFieldName === 'representative_email'
          )
            type = contact.email;
          else if (
            item.formFieldName === 'twitter_link' ||
            item.formFieldName === 'instagram_link' ||
            item.formFieldName === 'linkedin_link' ||
            item.formFieldName === 'facebook_link'
          )
            type = contact.link;
          else type = contact.phone;
          break;
        case 'alt-date':
          type = moment(`${contact}`).format('DD, MMM YYYY');
          break;
        case 'UploadFiles':
          if (userTypeId === 2) type = contact && contact.ID && contact.ID[0].fileName;
          break;
        case 'address':
          if (
            item.formFieldName === 'country' ||
            item.formFieldName === 'city' ||
            item.formFieldName === 'community' ||
            item.formFieldName === 'sub_community' ||
            item.formFieldName === 'workcity' ||
            item.formFieldName === 'workcountry' ||
            item.formFieldName === 'workdistrict' ||
            item.formFieldName === 'worksubcommunity' ||
            item.formFieldName === 'workcommunity' ||
            item.formFieldName === 'work_street' ||
            item.formFieldName === 'district'
          )
            type = contact.lookupItemName;
          else if (item.formFieldName === 'map' || item.formFieldName === 'workmap')
            type = `latitude: ${contact.latitude} , longitude: ${contact.longitude}`;
          else type = contact.value;
          break;
        case 'StepValidation':
          type = contact;
          break;
        case 'textarea':
          type = contact;
          break;
        case 'searchField':
          if (userTypeId === 2) type = contact && contact[0] && contact[0].name;
          break;
        default:
      }
      return type;
    },
    [userTypeId]
  );

  const handleRadioValueChange = useCallback(
    (value, index) => {
      const clickedRadioName = value.split('-')[0];
      const clickedContactIndex = value.split('-')[1];

      if (clickedContactIndex) {
        setRadioValues((oldRadioValues) => {
          const newState = [...oldRadioValues];
          newState[index] = value;
          return newState;
        });

        setMergedRadioValues((oldMergedRadioValues) => {
          const newState = { ...oldMergedRadioValues };

          const clickedContact = contactsDetails[clickedContactIndex];
          const activeContactRadioValue = clickedContact.contact[clickedRadioName];

          newState[clickedRadioName] = activeContactRadioValue;
          return newState;
        });
      }
    },
    [radioValues, activeContacts, mergedRadioValues]
  );

  const handleOriginalContactClick = (newOriginalContact) => {
    if(!contactsMergeInfo.isOriginalWithTransactions) {

      const filterdContactsDetails = contactsDetails.filter(
        (item) => item.contactsId !== newOriginalContact.contactsId
      );
      filterdContactsDetails.unshift(newOriginalContact);
      setContactsDetails(filterdContactsDetails);

      const firstContactIndex = 0;
      const secondContactIndex = 3;
      const activeContactsList = filterdContactsDetails.slice(firstContactIndex, secondContactIndex);
      setActiveContacts(activeContactsList);

      setOriginalContact(newOriginalContact);
      setFinalContactDetails(newOriginalContact);
      setMergedRadioValues([]);
      setActiveContactsIndexes([0, 1, 2]);
      GetAllFormByFormId();
    } else showError(t(`${translationPath}changing-original-contact-failed`));
  };

  const contactsSlidRightHandler = () => {
    const lastActiveIndex = activeContactsIndexes.length - 1;
    const firstContactIndex = activeContactsIndexes[lastActiveIndex] + 1;
    const secondContactIndex = firstContactIndex + 3;

    const activeContactsList = contactsDetails.slice(firstContactIndex, secondContactIndex);

    if (activeContactsList.length > 0) {
      const activeContactsIndexesList = activeContactsList.map(
        (item, index) => firstContactIndex + index
      );

      setActiveContacts(activeContactsList);
      setActiveContactsIndexes(activeContactsIndexesList);
    }
  };

  const contactsSlidLeftHandler = () => {
    const lastActiveIndex = activeContactsIndexes[0];
    const firstContactIndex = lastActiveIndex - 3;
    const lastContactIndex = activeContactsIndexes[0];

    const activeContactsList = contactsDetails.slice(firstContactIndex, lastContactIndex);

    if (activeContactsList.length > 0) {
      const activeContactsIndexesList = activeContactsList.map(
        (item, index) => firstContactIndex + index
      );

      setActiveContacts(activeContactsList);
      setActiveContactsIndexes(activeContactsIndexesList);
    }
  };

  useEffect(() => {
    getAllContactsDetails();
  }, [getAllContactsDetails]);

  useEffect(() => {
    GetAllFormByFormId();
  }, [GetAllFormByFormId]);

  useEffect(() => {
    setUserTypeId(+GetParams('userTypeId'));
  }, []);

  useEffect(() => {
    if (originalContact && mergedRadioValues) {
      const contactWithChangedValues = { ...originalContact.contact, ...mergedRadioValues };

      setFinalContactDetails({
        ...originalContact,
        contact: contactWithChangedValues,
      });
    }
  }, [originalContact, mergedRadioValues]);

  const getFinalContactFields = (radioItem) => {
    const radioFieldName = radioItem?.formFieldName;
    const radioFieldTitle = radioItem?.formFieldTitle;
    const contacFieldValue =
      finalContactDetails &&
      finalContactDetails.contact &&
      finalContactDetails.contact[radioFieldName];

    const radioFieldValue = radioItem?.uiWidgetType
      ? (contacFieldValue && checkWidgetType(radioItem, contacFieldValue)) || 'N/A'
      : contacFieldValue || 'N/A';

    return {
      value: radioFieldName,
      label: `${radioFieldTitle}: ${radioFieldValue}`,
    };
  };

  const getMergeContactsFields = (radioItem) => {
    const radioFieldName = radioItem?.formFieldName;
    const radioFieldTitle = radioItem?.formFieldTitle;

    const contactFields =
      (activeContacts &&
        activeContacts.map((item, index) => ({
          value: `${radioFieldName}-${activeContactsIndexes[index]}`,
          label: `${radioItem?.uiWidgetType
              ? (item?.contact &&
                  item.contact[radioFieldName] &&
                  checkWidgetType(radioItem, item.contact[radioFieldName])) ||
                'N/A'
              : (item?.contact && item.contact[radioFieldName]) || 'N/A'
          }`,
        }))) ||
      [];

    return contactFields;
  };
  return (
    <div className='contact-merge-view-wrapper w-100'>
      <Spinner isActive={isLoading} />
      {contactsDetails && contactsDetails.length > 0 && (
        <>
          <div className='contacts-merge-content'>
            <div>
              <div className='content-header'>
                <div className='header-item-postion'>
                  <div className='header-item' id={`contact#${-1}-${originalContact?.contactsId}`}>
                    <div>
                      <img
                        src={getDefaultContactImage(
                          finalContactDetails?.contact?.contact_type_id &&
                            finalContactDetails.contact.contact_type_id === 2
                            ? ContactTypeEnum.corporate.value
                            : (finalContactDetails?.contact?.gender &&
                                finalContactDetails.contact.gender.lookupItemName === 'Male' &&
                                ContactTypeEnum.man.value) ||
                                ContactTypeEnum.woman.value
                        )}
                        className='details-img'
                        alt={t(`${translationPath}user-image`)}
                      />
                    </div>
                    <div className='item-info'>
                      <div className='item-name'>
                        {t(`${translationPath}contact-id`)}:{` ${finalContactDetails?.contactsId}`}
                      </div>
                      <div className='final-item'>{t(`${translationPath}final`)}</div>
                    </div>
                  </div>
                  {activeContacts &&
                    activeContacts.length > 0 &&
                    activeContacts.map((item, index) => {
                      return (
                        <div className='header-item' id={`contact#${index}-${item?.contactsId}`}>
                          <div>
                            <img
                              src={getDefaultContactImage(
                                item?.contact?.contact_type_id && item.contact.contact_type_id === 2
                                  ? ContactTypeEnum.corporate.value
                                  : (item?.contact?.gender &&
                                      item.contact.gender.lookupItemName === 'Male' &&
                                      ContactTypeEnum.man.value) ||
                                      ContactTypeEnum.woman.value
                              )}
                              className='details-img'
                              alt={t(`${translationPath}user-image`)}
                            />
                          </div>
                          <div className='item-info'>
                            <div className='item-name'>
                              {t(`${translationPath}contact-id`)}:{`  ${item && item.contactsId}`}
                            </div>
                            <div
                              className={
                                activeContactsIndexes[0] == 0 && index == 0
                                  ? 'original-item'
                                  : 'obsolete-item'
                              }>
                              {activeContactsIndexes[0] == 0 && index == 0
                                ? t(`${translationPath}original`)
                                : t(`${translationPath}obsolete`)}
                            </div>
                          </div>
                          <div className='original-contact-btn'>
                            {(activeContactsIndexes[0] == 0 && index == 0 && (
                              <span className='mdi mdi-account-check is-original' />
                            )) || (
                              <span
                                className='mdi mdi-account-outline is-not-original'
                                onClick={() => handleOriginalContactClick(item)}
                              />
                            )}
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>

              <div className='content-body'>
                <>
                  <Spinner isAbsolute isActive={isFormsLoading} />
                  {formsContent &&
                    formsContent.map(
                      (radioItem, radioIndex) =>
                        radioItem?.uiWidgetType !== 'StepValidation' && (
                          <div
                            key={`contact-${radioIndex + 1}`}
                            className={`content-container ${
                              radioIndex % 2 === 0 ? 'is-gray' : ''
                            } `}>
                            <RadiosGroupComponent
                              idRef={`radio-group-${radioItem?.formFieldName}-${radioIndex}`}
                              data={[
                                getFinalContactFields(radioItem),
                                ...getMergeContactsFields(radioItem),
                              ]}
                              onSelectedRadioChanged={(e) => {
                                handleRadioValueChange(e.target.value, radioIndex);
                              }}
                              value={`${radioValues[radioIndex]}`}
                              labelInput='label'
                              valueInput='value'
                            />
                          </div>
                        )
                    )}
                </>
              </div>
            </div>
          </div>
          <div className='header-button-container'>
            <div className='header-button' id='left-btn'>
              <IconButton onClick={contactsSlidLeftHandler}>
                <span className='mdi mdi-arrow-left-bold-circle-outline' />
              </IconButton>
            </div>
            <div className='header-button' id='right-btn'>
              <IconButton onClick={contactsSlidRightHandler}>
                <span className='mdi mdi-arrow-right-bold-circle-outline' />
              </IconButton>
            </div>
          </div>
        </>
      )}
      <div className='bottom-box-merge'>
        <div className='merge-bottom'>
          <div className='contacts-merge-bottom'>
            <ButtonBase className='btns mx-2' onClick={cancelHandler}>
              {t(`${translationPath}cancel`)}
            </ButtonBase>
            <ButtonBase className='btns mx-2 theme-solid' onClick={mergeHandler}>
              {t(`${translationPath}merge`)}
            </ButtonBase>
          </div>
        </div>
      </div>
    </div>
  );
};
