export function ContactPreferenceRule(item, v, setDate, formType) {
    if (item.field.id === 'contact_preference' && Array.isArray(v) && v && v.length && v.length !== 5 && v[v.length - 1] && v[v.length - 1].lookupItemName && v[v.length - 1].lookupItemName === 'Select All') {
        const list = [];

        item.data.enum.map((contactPreferenceItem) => {
            list.push(contactPreferenceItem);
        });
        if (formType === 2)
            setDate([...list], 0, 'contact_preference');

        else if (formType === 1)
            setDate('contact_preference', [...list]);
    } else if (item.field.id === 'contact_preference' && Array.isArray(v) && v && v.length && v.length === 5 && v[v.length - 1] && v[v.length - 1].lookupItemName && v[v.length - 1].lookupItemName === 'Select All') {
        if (formType === 2)
            setDate(null, 0, 'contact_preference');

        else if (formType === 1)
            setDate('contact_preference', null || {});
    } else
        if (formType === 2)
            setDate(v, 0, 'contact_preference');

        else if (formType === 1)
            setDate('contact_preference', v);
}

export function ContactPreferenceDefaultRule(item, setDate, formType)
 {
    if (item.field.id === 'contact_preference' && formType === 1  ) 
    {
        let  list = [];

        item.data.enum.map((contactPreferenceItem) =>
        {
            list.push(contactPreferenceItem);
        });
         setDate('contact_preference', [...list]);

    }
   }