import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { Button } from "@material-ui/core";
import { useSelector } from "react-redux";
import { Tables } from "../../../TablesComponent/Tables";
import { GetParams } from "../../../../Helper";
import { PaginationComponent } from "../../../PaginationComponent/PaginationComponent";
import { Spinner } from "../../../SpinnerComponent/Spinner";
import { GetMergedContactDetails } from "../../../../Services";
import { ContactsMergingHistoryMapper } from "./ContactsMergingHistoryMapper";
import { isDate } from "date-fns";
import { DialogComponent } from "../../../DialogComponent/DialogComponent";

export const MergingHistoryComponent = ({
  parentTranslationPath,
  translationPath,
  typeId,
  formType,
}) => {
  const { t } = useTranslation(parentTranslationPath);
  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [transactions, setTransactions] = useState({
    result: [],
    totalCount: 0,
  });
  const [changeFields, setChangeFields] = useState([]);
  const [activeItem, setActiveItem] = useState(null);
  const [filter, setFilter] = useState({
    pageSize: parseInt(localStorage.getItem("Pagination")) || 25,
    pageIndex: 0,
  });
  const pathName = window.location.pathname
    .split("/home/")[1]
    .split("/view")[0]
    .split("/")[0];

  const getAllDFMTransaction = useCallback(async () => {
    setIsLoading(true);
    let result = await GetMergedContactDetails(filter, +GetParams("id"));
    if (!(result && result.status && result.status !== 200)) {
      setTransactions({
        result: result && result.result,
        totalCount: result && result.totalCount,
      });
    } else setTransactions({ result: [], totalCount: 0 });
    setIsLoading(false);
  }, [filter, pathName]);

  const onPageIndexChanged = (pageIndex) => {
    setFilter((item) => ({ ...item, pageIndex }));
    setChangeFields([]);
  };
  const onPageSizeChanged = (pageSize) => {
    setFilter((item) => ({ ...item, pageIndex: 0, pageSize }));
    setChangeFields([]);
  };
  useEffect(() => {
    getAllDFMTransaction();
  }, [getAllDFMTransaction]);

  const Collapsible = ({ title, children }) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleCollapsible = () => {
      setIsOpen(!isOpen);
    };

    return (
      <div>
        <div
          type="button"
          className={`history_of_Merged-bbt collapsible ${
            isOpen ? "active" : ""
          }`}
          onClick={toggleCollapsible}
        >
          {title}
          {isOpen ? (
            <span class="mdi mdi-chevron-up"></span>
          ) : (
            <span class="mdi mdi-chevron-down"></span>
          )}
        </div>
        <div className="content" style={{ display: isOpen ? "block" : "none" }}>
          {children}
        </div>
      </div>
    );
  };

  return (
    <div className="history-component-content-wrapper w-100 mt-2">
      <div className="history-table-conent-wrapper history-dialog-wrapper d-flex-column-center w-100 transaction-history-wrapper">
        <Spinner isActive={isLoading} />
        <Tables
          data={transactions.result || []}
          headerData={[
            {
              id: 1,
              label: "Merged-by",
              input: "mergedBy",
            },
            {
              id: 2,
              label: "Merged-Date",
              input: "mergedDate",
              isDate: true,
            },
            {
              id: 3,
              label: "Final-Contact-Id",
              input: "finalId",
            },
            {
              id: 4,
              label: "Original-Contact-Id",
              input: "finalId",
            },
            {
              id: 5,
              label: "absoluteContactIds",
              input: "absoluteContactIds",
            },
            {
              id: 5,
              label: "View-history",
              component: (item) => (
                <>
                  <Button
                    onClick={() => {
                      setIsOpen(true);
                      setActiveItem(item);
                    }}
                    id="tabldsfdnClicked-ref"
                    className="MuiButtonBase-root MuiButton-root MuiButton-text table-action-btn  btns-icon theme-solid bg-secondary"
                  >
                    <span className="MuiButton-label">
                      <span className="table-action-icon  mdi mdi-clock-time-four-outline" />
                    </span>
                  </Button>
                </>
              ),
            },
          ]}
          activePage={0}
          defaultActions={[]}
          translationPath={translationPath}
          parentTranslationPath={parentTranslationPath}
          totalItems={(changeFields && changeFields.length) || 200}
          itemsPerPage={(changeFields && changeFields.length) || 10}
          insideForm
        />
      </div>
      <div className="pagination-history-wrapper">
        <PaginationComponent
          pageIndex={filter.pageIndex}
          pageSize={filter.pageSize}
          totalCount={transactions.totalCount || 0}
          onPageIndexChanged={onPageIndexChanged}
          onPageSizeChanged={onPageSizeChanged}
        />
      </div>
      {activeItem && (
        <DialogComponent
          titleText={
            t(`${translationPath}history_of_Merged`) +
              "  " +
              activeItem.mergedBy || ""
          }
          maxWidth="sm"
          dialogContent={
            <div className="history_of_Merged-DialogComponent">
              <div className="w-100 d-flex">
                <div className="w-100 p-text-box-wrpaper">
                  <div
                    type="button"
                    className={`history_of_Merged-bbt collapsible background-original ${
                      isOpen ? "active" : ""
                    }`}
                  >
                    {t(`${translationPath}orginalContactData`)}
                  </div>
                  {activeItem.orginalContactData ? (
                    Object.entries(
                      ContactsMergingHistoryMapper(
                        JSON.parse(activeItem.orginalContactData)
                      )
                    ).map(
                      ([key, value]) =>
                        value != null &&
                        value !== undefined && (
                          <p key={key} className="p-text-box">
                            <strong className="capitalize-text">
                              {key} :{" "}
                            </strong>{" "}
                            {Array.isArray(value)
                              ? value
                                  .map((item) => item.lookupItemName)
                                  .join(", ")
                              : typeof value === "string"
                              ? value
                              : JSON.stringify(value)}
                          </p>
                        )
                    )
                  ) : (
                    <p>N/A</p>
                  )}
                </div>
              </div>
              <div className="w-100 d-flex">
                <div className="w-100 p-text-box-wrpaper">
                  {activeItem ? (
                    activeItem.obsoleteContactData.map((items, index) => (
                      <Collapsible
                        key={index}
                        title={`Obsolete Contact ${
                          items.ObsoleteContactId || ""
                        }`}
                      >
                        {items ? (
                          Object.entries(
                            ContactsMergingHistoryMapper(
                              JSON.parse(items.ObsoleteContact)
                            )
                          ).map(
                            ([key, value]) =>
                              value != null &&
                              value !== undefined && (
                                <p key={key} className="p-text-box">
                                  <strong className="capitalize-text">
                                    {key} :{" "}
                                  </strong>{" "}
                                  {Array.isArray(value)
                                    ? value
                                        .map((item) => item.lookupItemName)
                                        .join(", ")
                                    : typeof value === "string"
                                    ? value
                                    : JSON.stringify(value)}
                                </p>
                              )
                          )
                        ) : (
                          <p>N/A</p>
                        )}
                      </Collapsible>
                    ))
                  ) : (
                    <p>N/A</p>
                  )}
                </div>
              </div>
              <div class="save-cancel-wrapper d-flex-v-center-h-end flex-wrap p-2">
                <div class="cancel-wrapper d-inline-flex-center">
                  <button
                    class="MuiButtonBase-root MuiButton-root MuiButton-text cancel-btn-wrapper btns theme-transparent c-primary"
                    tabindex="0"
                    onClick={() => {
                      setIsOpen(false);
                      setActiveItem(false);
                    }}
                    type="button"
                    id="cancel_nullcancel-btn"
                  >
                    <span class="MuiButton-label">
                      <span>Cancel</span>
                    </span>
                    <span class="MuiTouchRipple-root"></span>
                  </button>
                </div>
              </div>
            </div>
          }
          isOpen={isOpen}
          onCloseClicked={() => {
            setIsOpen(false);
            setActiveItem(false);
          }}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
        />
      )}
    </div>
  );
};

MergingHistoryComponent.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  isOpenChanged: PropTypes.func.isRequired,
  parentTranslationPath: PropTypes.string.isRequired,
  translationPath: PropTypes.string.isRequired,
};
