import { createAction } from '@reduxjs/toolkit';
const LEAD_GET = createAction('LEAD_GET');
const LEAD_GET_SUCCESS = createAction('LEAD_GET_SUCCESS');
const LEAD_GET_FAIL = createAction('LEAD_GET_FAIL');
const LEAD_RESET = createAction('LEAD_RESET');


const LEAD_DETAILS_GET = createAction('LEAD_DETAILS_GET');
const LEAD_DETAILS_GET_SUCCESS = createAction('LEAD_DETAILS_GET_SUCCESS');
const LEAD_DETAILS_GET_FAIL = createAction('LEAD_DETAILS_GET_FAIL');

const LEAD_DETAILS_GET_DIALOG = createAction('LEAD_DETAILS_DIALOG_GET');
const LEAD_DETAILS_GET_DIALOG_SUCCESS = createAction('LEAD_DETAILS_GET_DIALOG_SUCCESS');
const LEAD_DETAILS_GET_DIALOG_FAIL = createAction('LEAD_DETAILS_GET_DIALOG_FAIL');

const LEAD_DETAILS_RESET = createAction('LEAD_DETAILS_RESET');

const LEAD_DETAILS_FIELD_GET = createAction('LEAD_DETAILS_FIELD_GET');
const LEAD_DETAILS_FIELD_GET_SUCCESS = createAction('LEAD_DETAILS_FIELD_GET_SUCCESS');
const LEAD_DETAILS_FIELD_GET_FAIL = createAction('LEAD_DETAILS_FIELD_GET_FAIL');

const LEAD_DETAILS_PUT = createAction('LEAD_DETAILS_PUT');
const LEAD_DETAILS_PUT_SUCCESS = createAction('LEAD_DETAILS_PUT_SUCCESS');
const LEAD_DETAILS_PUT_FAIL = createAction('LEAD_DETAILS_PUT_FAIL');

const LEAD_POST = createAction('LEAD_POST');
const LEAD_POST_SUCCESS = createAction('LEAD_POST_SUCCESS');
const LEAD_POST_FAIL = createAction('LEAD_POST_FAIL');

const LEAD_BY_ID_PUT = createAction('LEAD_PUT');
const LEAD_BY_ID_PUT_SUCCESS = createAction('LEAD_PUT_SUCCESS');
const LEAD_BY_ID_PUT_FAIL = createAction('LEAD_PUT_FAIL');

const LEAD_SEARCH_GET = createAction('LEAD_SEARCH_GET');
const LEAD_SEARCH_GET_SUCCESS = createAction('LEAD_SEARCH_GET_SUCCESS');
const LEAD_SEARCH_FAIL = createAction('LEAD_SEARCH_FAIL');


export {
	LEAD_GET,
	LEAD_GET_SUCCESS,
	LEAD_GET_FAIL,
	LEAD_RESET,
	LEAD_DETAILS_GET,
	LEAD_DETAILS_GET_SUCCESS,
	LEAD_DETAILS_GET_FAIL,
	LEAD_DETAILS_GET_DIALOG,
	LEAD_DETAILS_GET_DIALOG_SUCCESS,
	LEAD_DETAILS_GET_DIALOG_FAIL,
	LEAD_DETAILS_FIELD_GET,
	LEAD_DETAILS_FIELD_GET_SUCCESS,
	LEAD_DETAILS_FIELD_GET_FAIL,
	LEAD_DETAILS_RESET,
	LEAD_DETAILS_PUT,
	LEAD_DETAILS_PUT_SUCCESS,
	LEAD_DETAILS_PUT_FAIL,
	LEAD_POST,
	LEAD_POST_SUCCESS,
	LEAD_POST_FAIL,
	LEAD_BY_ID_PUT,
	LEAD_BY_ID_PUT_SUCCESS,
	LEAD_BY_ID_PUT_FAIL,
	LEAD_SEARCH_GET,
	LEAD_SEARCH_GET_SUCCESS,
	LEAD_SEARCH_FAIL
};