import React, {
  useState, useEffect, useCallback, useRef
} from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonBase } from '@material-ui/core';
import {
  AutocompleteComponent, Inputs, PaginationComponent, SelectComponet, Spinner
} from '../../../Components';
import { OwnerRotationTableView } from './OwnerRotationUtilities/OwnerRotationTableView';
import { GetAdvanceSearchRotationScheme, } from '../../../Services/RotaionSchemaService/RotationSchemaService';
import { bottomBoxComponentUpdate } from '../../../Helper';
import { RotationCriteriaDialog } from './OwnerRotationManagement/RotationCriteriaDialog/RotationCriteriaDialog';
import { useTitle } from '../../../Hooks';
import { OrganizationUserSearch } from '../../../Services';

const parentTranslationPath = 'OwnerRotationView';
const translationPath = '';

export const OwnerRotationView = () => {
  const { t } = useTranslation(parentTranslationPath);
  const searchTimer = useRef(null);
  const [orderBy, setOrderBy] = useState(null);
  const [timer, setTimer] = useState(null);
  const [agents, setAllAgent] = useState({
    result: [],
    totalCount: 0,
  });

  const [sortBy, setSortBy] = useState(null);
  const [OwnerRotationData, setOwnerRotationData] = useState({
    result: [],
    totalCount: 0,
  });
  const [openDialog, setOpenDialog] = useState(false);
  const [isLoading, setisLoading] = useState(null);
  const [activeItem, setActiveItem] = useState(null);
  const [filter, setFilter] = useState({
    branchId: null,
    teamId: null,
    search: null || '',
    isForOwnerLeads: true,
    pageIndex: 0,
    // eslint-disable-next-line radix
    pageSize: parseInt(localStorage.getItem('Pagination')) || 25,
  });

  useEffect(() => {
    if (sortBy)
      setFilter((item) => ({ ...item, filterBy: sortBy.filterBy, orderBy: sortBy.orderBy }));
  }, [sortBy]);

  const onPageIndexChanged = (pageIndex) => {
    setFilter((item) => ({ ...item, pageIndex }));
  };
  const onPageSizeChanged = (pageSize) => {
    setFilter((item) => ({ ...item, pageIndex: 0, pageSize }));
  };

  const GetAllRotationSchemeServicesAPI = useCallback(async () => {
    setisLoading(true);
    const res = await GetAdvanceSearchRotationScheme({ ...filter, pageIndex: filter.pageIndex });
    if (!(res && res.status && res.status !== 200)) {
      setOwnerRotationData({
        result: (res && res.result),
        totalCount: (res && res.totalCount),
      });
    } else {
      setOwnerRotationData({
        result: [],
        totalCount: 0,
      });
    }
    setisLoading(false);
  }, [filter]);

  useEffect(() => {
    GetAllRotationSchemeServicesAPI();
  }, [filter]);

  const reloadData = () => {
    GetAllRotationSchemeServicesAPI();
  };
  useEffect(() => {
    bottomBoxComponentUpdate(
      <PaginationComponent
        pageIndex={filter.pageIndex}
        pageSize={filter.pageSize}
        totalCount={(OwnerRotationData && OwnerRotationData.totalCount)}
        onPageIndexChanged={onPageIndexChanged}
        onPageSizeChanged={onPageSizeChanged}
        translationPath=''
        parentTranslationPath='Shared'
      />
    );
  }, [filter]);

  useTitle(t(`${translationPath}rotation`));

  const [selectedOrderBy, setSelectedOrderBy] = useState({
    filterBy: // (orderFilter.rotationCriteriaFilter && orderFilter.rotationCriteriaFilter.filterBy) ||
      //  const orderFilter = useSelector((state) => state.GlobalOrderFilterReducer);

      null,
    orderBy: // (orderFilter.rotationCriteriaFilter && orderFilter.rotationCriteriaFilter.orderBy) ||
      null,
  });

  const getAllAgents = useCallback(async (name) => {
    // setIsLoading((loading) => ({ ...loading, parmetr: true }));
    const filterDTO = {
      pageSize: 25,
      pageIndex: 1,
      name,
      userName: null,
      phoneNumber: null,
      email: null,
      userStatusId: 2,

    };
    const res = await OrganizationUserSearch(filterDTO);
    if (!(res && res.status && res.status !== 200)) {
      setAllAgent({
        result: (res && res.result) || [],
        totalCount: (res && res.totalCount) || 0,
      });
    } else {
      setAllAgent({
        result: [],
        totalCount: 0,
      });
    }
    // setIsLoading((loading) => ({ ...loading, parmetr: false }));
  }, []);
  useEffect(() => {
    getAllAgents();
  }, []);
  const [searchedItem, setSearchedItem] = useState('');
  const [searchItem, setSearchItem] = useState(null);

  const filterByChanged = (value) => {
    setSelectedOrderBy((item) => ({ ...item, filterBy: value }));
  };
  const orderByChanged = (value) => {
    setSelectedOrderBy((item) => ({ ...item, orderBy: value }));
  };

  const orderBySubmitted = (event) => {
    event.preventDefault();
    if (!selectedOrderBy.filterBy || !selectedOrderBy.orderBy)
      return;

    // dispatch(
    //   GlobalOrderFilterActions.globalOrderFilterRequest({
    //     ...orderFilter,
    //     rotationCriteriaFilter: {
    //       filterBy: selectedOrderBy.filterBy,
    //       orderBy: selectedOrderBy.orderBy,
    //     },
    //   })
    // );
    // const reloadData = (pageSize) => {
    //   setFilter((item) => ({ ...item, pageIndex: filter.pageIndex, pageSize: filter.pageSize }));
    // };
    setOrderBy({
      filterBy: selectedOrderBy.filterBy,
      orderBy: selectedOrderBy.orderBy,
    });
  };

  useEffect(() => {
    if (orderBy)
      setFilter((item) => ({ ...item, filterBy: orderBy.filterBy, orderBy: orderBy.orderBy }));
  }, [orderBy]);

  useEffect(() => {
    if (sortBy)
      setFilter((item) => ({ ...item, filterBy: sortBy.filterBy, orderBy: sortBy.orderBy }));
  }, [sortBy]);
  const searchHandler = (event) => {
    const { value } = event.target;
    setSearchItem(value);
    setTimer(
      setTimeout(() => {
        setFilter((item) => ({ ...item, search: searchedItem }));
      }, 1000)
    );
  };

  return (
    <>
      <div className='OwnerRotationView RotationSchema-wrapper view-wrapper'>
        <div className='w-100 px-2'>
          <div className='header-section'>
            <div className='filter-section'>
              <div className='section'>
                <ButtonBase
                  className='btns theme-solid'
                  id='new-rotation-criteria-bbt'
                  onClick={() => { setOpenDialog(true); }}
                >
                  <span className='mdi mdi-plus' />
                  <span>{t(`${translationPath}new-rotation-criteria`)}</span>
                </ButtonBase>
              </div>
              <div className='section d-flex-column w-50 px-2 m-auto'>
                <div className='w-100'>
                  <Inputs
                    idRef='RotationCriteriaRef'
                    label={t(`${translationPath}rotation-criteria`)}
                    value={searchedItem}
                    onInputChanged={(e) => setSearchedItem(e.target.value)}
                    inputPlaceholder={t(`${translationPath}search-rotation-criteria`)}
                    onKeyUp={searchHandler}
                    onKeyDown={() => {
                      if (timer != null) clearTimeout(timer);
                    }}
                    beforeIconClasses='mdi mdi-magnify mdi-24px c-gray-primary'
                  />
                </div>
                <div className='w-100 pl-4-reversed'>
                  <AutocompleteComponent
                    idRef='referredToRef'
                    // isLoading={isLoading.agents}
                    // onInputKeyUp={(e) => setFilter(e)}
                    // selectedValues={filter.referredTo}
                    multiple={false}
                    data={agents.result || []}
                    value={filter.agentId}
                    chipsLabel={(option) => option.fullName || ''}
                    displayLabel={(option) => option.fullName || ''}
                    withoutSearchButton
                    onInputKeyUp={(e) => {
                      const { value } = e.target;
                      if (searchTimer.current) clearTimeout(searchTimer.current);
                      searchTimer.current = setTimeout(() => {
                        getAllAgents(value);
                      }, 700);
                    }}
                    inputPlaceholder={t(`${translationPath}Agent`)}
                    parentTranslationPath={parentTranslationPath}
                    translationPath={translationPath}
                    onChange={(event, newValue) => {
                      setFilter((item) => ({
                        ...item,
                        agentId: (newValue && newValue.id) || null,
                        pageIndex: 0,
                        pageSize: 25,
                        search: searchItem || '',
                        relatedUnitNumberId: null,
                        relatedLeadNumberId: null,
                      }));
                    }}
                  />
                </div>
              </div>
            </div>
            <div />
          </div>
          <div className='d-flex px-2'>
            <span className='mx-2 mt-1'>{t(`${translationPath}select`)}</span>
            <span className='separator-v s-primary s-reverse s-h-25px mt-1' />
            <span className='px-2 d-flex'>
              <span className='texts-large mt-1'>
                {t(`${translationPath}order-by`)}
                :
              </span>
              <div className='px-2'>
                <SelectComponet
                  idRef='filterByRef'
                  data={[
                    { id: 'CreatedOn', filterBy: 'created-on' },
                    { id: 'UpdateOn', filterBy: 'last-updated' },
                  ]}
                  wrapperClasses='mb-3'
                  isRequired
                  value={selectedOrderBy.filterBy}
                  onSelectChanged={filterByChanged}
                  valueInput='id'
                  textInput='filterBy'
                  emptyItem={{
                    value: null,
                    text: 'select-filter-by',
                    isDisabled: false,
                  }}
                  parentTranslationPath={parentTranslationPath}
                  translationPath={translationPath}
                  translationPathForData={translationPath}
                />
              </div>
              <div className='px-2'>
                <SelectComponet
                  idRef='orderByRef'
                  data={[
                    { id: 1, orderBy: 'ascending' },
                    { id: 2, orderBy: 'descending' },
                  ]}
                  value={selectedOrderBy.orderBy}
                  onSelectChanged={orderByChanged}
                  emptyItem={{ value: null, text: 'select-sort-by', isDisabled: false }}
                  wrapperClasses='mb-3'
                  isRequired
                  valueInput='id'
                  textInput='orderBy'
                  parentTranslationPath={parentTranslationPath}
                  translationPath={translationPath}
                  translationPathForData={translationPath}
                />
              </div>
              <div className='mt-1'>
                <ButtonBase
                  className='btns theme-solid'
                  id='action_apply'
                  onClick={orderBySubmitted}
                  disabled={!selectedOrderBy.filterBy || !selectedOrderBy.orderBy}
                >
                  <span>{t(`${translationPath}apply`)}</span>
                </ButtonBase>
              </div>
            </span>
          </div>
          <div className='view-wrapers'>
            <Spinner isActive={isLoading} isAbsolute />
            <OwnerRotationTableView
              data={OwnerRotationData}
              filter={filter}
              parentTranslationPath={parentTranslationPath}
              translationPath={translationPath}
              setActiveItem={setActiveItem}
              setFilter={() => setFilter((item) => ({ ...item, pageIndex: 0 }))}
              activeItem={activeItem}
              reloadData={reloadData}
              isLoading={isLoading}
              onPageSizeChanged={onPageSizeChanged}
              onPageIndexChanged={onPageIndexChanged}
              setSortBy={setSortBy}
            />
          </div>
        </div>
      </div>
      {openDialog && (
        <RotationCriteriaDialog
          open={openDialog}
          close={() => { setOpenDialog(false); }}
          onSave={() => { reloadData(); }}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
        />
      )}
    </>
  );
};
