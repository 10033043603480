import React from 'react'
import { DialogTopFields } from './DialogTopFields'
import { DialogMiddleFields } from './DialogMiddleFields'
import { DialogBottomFields } from './DialogBottomFields'

export const DialogFields = (props) => {
  return (
    <>
      <DialogTopFields
      {...props}
      />
      <DialogMiddleFields
      {...props}
      />
      <DialogBottomFields
      {...props}
      />
    </>
  )
}
