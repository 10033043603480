export const AdminListingUnitsDashboards = {
  AdminListingUnitsDashboards: 
  {
    permissionsId: "824ae3d9-29b2-450f-5a64-08dc32a402e7",
    permissionsName: "Listing Units",
    description: null,
    componentsId: null,
    components: null
  }
} ;
