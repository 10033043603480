import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Button } from '@material-ui/core';
import { GetAllCampaignTasks } from '../../../../../../../../Services';
import { Tables, Spinner } from '../../../../../../../../Components';
import { TableActions } from '../../../../../../../../Enums';
import { sideMenuComponentUpdate, sideMenuIsOpenUpdate } from '../../../../../../../../Helper';
import { CampaignTasksHeaderData } from './CampaignTasksHeaderData';
import { TaskManagementDialog } from './TaskManagementDialog';
import { DeleteDialog } from './DeleteDialog';
import { TaskDetailsSideMenu } from '../../../../TaskDetailsSideMenu/TaskDetailsSideMenu'

export function CampaignTasksView() {
  const parentTranslationPath = 'Campaign';
  const translationPath = 'campaign-task.';
  const marketingCampaignsInfo = useSelector((state) => state.MarketingCampaignsReducer);

  const { t } = useTranslation(parentTranslationPath);
  const [isLoading, setIsLoading] = useState(false);
  const [filter, setFilter] = useState({
    pageSize: 25,
    pageIndex: 0,
    orderBy: null,
    filterBy: null,
  });
  const [campaignTasks, setCampaignTasks] = useState({
    data: [],
    totalCount: 0,
  });
  const [activeItem, setActiveItem] = useState(null);
  const [isManagementDialogOpen, setIsManagementDialogOpen] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [sortValues, setSortValues] = useState(null);

  const tableActionClicked = useCallback((actionEnum, item) => {
    if (actionEnum === TableActions.editText.key) {
      setActiveItem(item);
      setIsManagementDialogOpen(true);
    } else if (actionEnum === TableActions.deleteText.key) {
      setActiveItem(item);
      setIsDeleteDialogOpen(true);
    }
  }, []);

  const getTableActions = () => {
    const actions = [
      {
        enum: TableActions.editText.key,
        isDisabled: false,
        externalComponent: null,
      },
      {
        enum: TableActions.deleteText.key,
        isDisabled: false,
        externalComponent: null,
      }
    ];
    return actions;
  };

  const addNewHandler = () => {
    setIsManagementDialogOpen(true);
  };
  const getAllCampaignTasks = useCallback(async (reloadRequestId) => {
    setIsLoading(true);
    const body = {
      id: reloadRequestId? reloadRequestId : marketingCampaignsInfo?.campaignRequestId,
      pageSize: filter.pageSize,
      pageIndex: filter.pageIndex || filter.pageIndex + 1,
      filterBy: filter.filterBy || null,
      orderBy: filter.orderBy || null,
    };
    const res = await GetAllCampaignTasks(body);
    if (!(res && res.status && res.status !== 200)) {
      setCampaignTasks({
        data: res.result || [],
        totalCount: res.totalCount || 0,
      });
    } else
      setCampaignTasks({
        data: [],
        totalCount: 0,
      });

    setIsLoading(false);
  }, [filter, marketingCampaignsInfo?.campaignRequestId]);

  const onPageIndexChanged = (pageIndex) => {
    setFilter((item) => ({ ...item, pageIndex }));
  };
  const onPageSizeChanged = (pageSize) => {
    setFilter((item) => ({ ...item, pageIndex: 0, pageSize }));
  };

  const focusedRowChanged = useCallback((rowIndex, item) => {

    sideMenuComponentUpdate(
      <TaskDetailsSideMenu
        taskId={item?.id}
        campaignId={item?.campaignId}
        buttonsGroup={"TasksView"}
        closeSideMenu={() => {
          sideMenuIsOpenUpdate(false);
        }}
        reloadData={() => getAllCampaignTasks()}
      />
    );
    sideMenuIsOpenUpdate(true);
  }, []);

  useEffect(() => {
    sideMenuComponentUpdate(null);
    sideMenuIsOpenUpdate(false);
  }, []);

  useEffect(() => {
    if (sortValues !== null) {
      setFilter((prevState) => ({...prevState,
        filterBy: sortValues?.filterBy || null,
        orderBy: sortValues?.orderBy || null,
      }));
    }
  }, [sortValues]);

  useEffect(() => {
    getAllCampaignTasks();
  }, [getAllCampaignTasks]);

  return (
    <div className='p-2'>
      <Spinner isActive={isLoading} isAbsolute />
      <div className='d-flex-v-center-h-between'>
        <Button
          className='btns mx-3 mb-2 theme-solid'
          onClick={addNewHandler}
          id='action_add-new_plus'>
          <span className='mdi mdi-plus' />
          {t(`${translationPath}add-new`)}
        </Button>
      </div>

      <div className='p-2 w-100'>
        <Tables
          data={campaignTasks.data}
          headerData={CampaignTasksHeaderData}
          actionsOptions={{
            onActionClicked: tableActionClicked,
          }}
          focusedRowChanged={focusedRowChanged}
          itemsPerPage={filter.pageSize}
          activePage={filter.pageIndex}
          defaultActions={getTableActions()}
          setSortBy={setSortValues}
          onPageIndexChanged={onPageIndexChanged}
          onPageSizeChanged={onPageSizeChanged}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          totalItems={campaignTasks.totalCount || 0}
        />
      </div>

      {isManagementDialogOpen && (
        <TaskManagementDialog
          isDialogOpen={isManagementDialogOpen}
          activeItem={activeItem}
          onSave={(reloadRequestId) => {
            getAllCampaignTasks(reloadRequestId);
            setIsManagementDialogOpen(false);
            setActiveItem(null);
          }}
          onClose={() => {
            setActiveItem(null);
            setIsManagementDialogOpen(false);
          }}
          translationPath={translationPath}
          parentTranslationPath={parentTranslationPath}
        />
      )}
      {isDeleteDialogOpen && (
        <DeleteDialog
          activeItem={activeItem}
          setActiveItem={setActiveItem}
          isDeleteDialogOpen={isDeleteDialogOpen}
          setIsDeleteDialogOpen={setIsDeleteDialogOpen}
          reloadData={() => { getAllCampaignTasks(); }}
          onCloseClicked={() => {
            setIsDeleteDialogOpen(false);
            setActiveItem(null);
          }}
          onCancelClicked={() => {
            setIsDeleteDialogOpen(false);
            setActiveItem(null);
          }}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
        />
      )}
    </div>
  );
}
