export const LeadOwnerUnqualifiedLeadsPermissions = {
  ViewUnqualifiedLeads: {
    permissionsId: 'c4b1557f-7dd0-4d4d-f94a-08dc0f48c1d2',
    permissionsName: 'View Unqualified Leads',
    description: null,
    componentsId: null,
    components: null,
  },
  SendToTheLeadPool: {
    permissionsId: '4842d813-40a3-4062-c4c7-08dc683b0c43',
    permissionsName: 'Send To The Leads Pool',
    description: null,
    componentsId: null,
    components: null,
  },
};
