import React, { useEffect, useState, useRef } from 'react';
import { PropTypes } from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  Button, DialogTitle, DialogContent, DialogActions, Dialog
} from '@material-ui/core';
import { Tables, PaginationComponent, Spinner } from '../../../Components';
import { RunLogReport } from '../../../Services';

const parentTranslationPath = 'UnitsProfileManagementView';
const translationPath = '';
const ReportRunDialog = ({ isOpen, close, reportId }) => {
  const [reportSchema, setReportSchema] = useState([]);
  const maxHeight = '170px';
  const mediaprint =
    '@media print {footer {position: fixed !important;bottom: 0 !important;}strong, b {font-weight: bold !important;}@page {size:8.27in 11.69in !important;margin: 1cm 1cm 1cm 1cm !important;}}@media print {header {position: fixed !important;top: 0 !important;left: 0 !important;right: 0 !important;text-align:center !important;width: 100% !important;}strong, b {font-weight: bold !important;}@page {size:8.27in 11.69in !important;margin: .5cm !important;}}';
  const { t } = useTranslation([parentTranslationPath, 'Shared']);
  const [filter, setFilter] = useState({
    pageIndex: 0,
    pageSize: 25,
  });

  const unCamelCaseConvert = (name) =>
    name
      .replace(/([A-Z])/g, ' $1')
      .replace(/^./, (str) => str.toUpperCase())
      .replace('_', ' ')
      .replace('.lookup Item Name', ' ')
      .replace('.value', ' ')
      .replace(/^((?:[^"\n]*"){5})(_*)(\..*)$/gm, ' ')
      .replace('-', ' ')
      .replace('-', ' ')
      .replace('-', ' ');
  const [reportDto, setReportDto] = useState([]);
  const [isLoading, setIsLoading] = useState([]);
  const onPageIndexChanged = (pageIndex) => {
    setFilter((item) => ({ ...item, pageIndex }));
  };
  const onPageSizeChanged = (pageSize) => {
    setFilter((item) => ({ ...item, pageIndex: 0, pageSize }));
  };
  const [sortBy, setSortBy] = useState(null);
  const SystemReportsResultAPI = async () => {
    setIsLoading(true);
    const res = await RunLogReport({
      reportId,
      pageIndex: filter.pageIndex,
      pageSize: filter.pageSize,
      orderBy: ((sortBy && sortBy.filterBy) || null),
      orderType: ((sortBy && sortBy.orderBy) || null)
    });
    const reportRows = JSON.parse(res && res.reportResult);
    const firstRow = reportRows && reportRows.length > 0 && reportRows[0];
    setReportDto({
      data: reportRows || [],
      totalCount: firstRow.TotalCount || 0,
    });
    const headerData = [];

    let i = 0;
    // eslint-disable-next-line no-restricted-syntax
    for (const key in firstRow) {
      // eslint-disable-next-line no-plusplus
      i++;
      if (key !== 'TotalCount') {
        headerData.push({
          id: i,
          label: unCamelCaseConvert(key),
          component: (item) => (
            <div>
              {(item && item[key] !== null && (String(item && item[key]) || 'N/A')) || 'N/A'}
            </div>
          ),
          isDefaultFilterColumn: true,
          isSortable: true,
          input: key,
        });
      }
    }
    setReportSchema(headerData.slice(0, -1));
    setIsLoading(false);
  };

  useEffect(() => {
    if (reportId) SystemReportsResultAPI();
  }, [reportId, filter, sortBy]);
  return (
    <div>
      <Spinner isActive={isLoading} />
      <Dialog
        open={isOpen}
        fullWidth
        maxWidth='lg'
        onClose={() => {
          close();
        }}
        parentTranslationPath={parentTranslationPath}
        translationPath={translationPath}
      >
        <form noValidate>
          <DialogTitle>{t(`${translationPath}Run-Report`)}</DialogTitle>
          <DialogContent>
            <div>
              <PaginationComponent
                pageIndex={filter.pageIndex}
                pageSize={filter.pageSize}
                totalCount={(reportDto && reportDto.totalCount) || 0}
                onPageIndexChanged={onPageIndexChanged}
                onPageSizeChanged={onPageSizeChanged}
              />
              <header className='header'>
                <img
                  alt='HeaderPSITemplet'
                  src={'https://static.psi-crm.com/header.png' || null}
                  style={{ width: '100%', maxHeight }}
                />
              </header>
              <style>{mediaprint}</style>
              <Tables
                data={(reportDto && reportDto.data) || []}
                headerData={reportSchema}
                defaultActions={[]}
                setSortBy={setSortBy}
                activePage={1}
                itemsPerPage={filter.pageSize}
                totalItems={(reportDto && reportDto.totalCount) || 0}
                translationPath={translationPath}
                parentTranslationPath={parentTranslationPath}
              />

              <footer>
                <img
                  alt='FooterPSITemplet'
                  src={'https://static.psi-crm.com/footer.png' || null}
                  style={{ width: '100%', maxHeight }}
                />
              </footer>
            </div>
          </DialogContent>
          <DialogActions>
            <Button className='btns theme-solid bg-cancel' onClick={() => close()}>
              {t(`${translationPath}Cancel`)}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
};

ReportRunDialog.propTypes = {
  isOpen: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
};

export default ReportRunDialog;
