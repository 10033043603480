import React, { useEffect, useState } from 'react';
import { Inputs } from '../../../../../../../Components';
import Button from '@material-ui/core/Button';
import { useTranslation } from 'react-i18next';

export const ConvoloNumber = ({
  parentTranslationPath,
  translationPath,
  state,
  isSubmitted,
  setState,
}) => {
  const [isMainNumberFilled, setIsMainNumberFilled] = useState(false);
  const { t } = useTranslation(parentTranslationPath);

  const handleAddExtensions = () => {
    if (
      state?.branchExtension.length === 0 ||
      state?.branchExtension[
        state?.branchExtension.length - 1
      ].extensionNumber.trim() !== ''
    ) {
      setState({
        id: 'branchExtension',
        value: [...state?.branchExtension, { extensionNumber: '' }],
      });
    }
  };

  useEffect(() => {
    if (state.mainNumber && state.mainNumber.trim() !== '') {
      setIsMainNumberFilled(true);
    } else {
      setIsMainNumberFilled(false);
    }
  }, [state.mainNumber]);

  return (
    <div className='dialog-content-wrapper-convolo'>
      <div className='dialog-content-item main-number w-25'>
        <Inputs
          labelValue='main-number'
          idRef='mainNumberRef'
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          isSubmitted={isSubmitted}
          value={state.mainNumber || null}
          onInputChanged={(event) =>
            setState({
              id: 'mainNumber',
              value: event.target.value,
            })
          }
        />
        <Button
          className='btns-icon theme-solid bg-blue-lighter main-number-icon-wrapper'
          onClick={handleAddExtensions}
          disabled={!isMainNumberFilled}
        >
          <span className='mdi mdi-plus main-number-icon' />
        </Button>
      </div>
      <div className='extension-number-wrapper'>
        {state?.branchExtension?.map((extension, index) => (
          <div key={index} className='dialog-content-item extension-number'>
            <Inputs
              labelValue={t(`${translationPath}extension-number`, {
                index: index + 1,
                ns: parentTranslationPath,
              })}
              idRef={`extensionNumberRef${index + 1}`}
              isSubmitted={isSubmitted}
              value={state?.branchExtension[index]?.extensionNumber || ''}
              onInputChanged={(event) => {
                const updatedBranchExtension = [...state?.branchExtension];
                updatedBranchExtension[index] = {
                  extensionNumber: event.target.value,
                };
                setState({
                  id: 'branchExtension',
                  value: updatedBranchExtension,
                });
              }}
            />
          </div>
        ))}
      </div>
    </div>
  );
};
