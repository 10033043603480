import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  breadcrumbContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  iconLink: {
    display: 'flex',
    alignItems: 'center',
    textDecoration: 'none',
    color: theme.palette.breadcrumbs.iconFG,
    padding: "4px",
    borderRadius: theme.borderRadius[3],

    '&:hover': {
      background: theme.palette.breadcrumbs.bg_hover,
      '& svg, & path': {
        fill: `${theme.palette.breadcrumbs.fg_hover} !important`,
      },
    }
  },
  breadcrumbItem: {
    display: 'flex',
    alignItems: 'center',
    gap: "8px",
    marginInlineStart: "8px",
  },
  breadcrumbLink: {
    marginLeft: "8px",
    textDecoration: 'none',
    color: theme.palette.breadcrumbs.text_fg,
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "20px",
    padding: "4px 8px",
    borderRadius: theme.borderRadius[3],

    '&:hover': {
      textDecoration: 'none',
      color: theme.palette.breadcrumbs.fg_hover,
      background: theme.palette.breadcrumbs.bg_hover,
    },
  },
  activeLink: {
    fontWeight: 'bold',
  },
  nonClickableText: {
    marginLeft: "8px",
    color: theme.palette.breadcrumbs.text_fg,
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "20px",
  },
}));
