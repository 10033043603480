import { HttpServices } from "../../Helper";
import { config } from "../../config";

const GetDeveloperContactsAdvanceSearchAPI = async (
  { pageSize, pageIndex },
  body
) => {
  const result = await HttpServices.post(
    `${
      config.server_address
    }/CrmDfm/DeveloperContact/DeveloperContactsAdvanceSearch/${
      pageIndex + 1
    }/${pageSize}`,
    body
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

const CreateDeveloperContactAPI = async (body) => {
  const result = await HttpServices.post(
    `${config.server_address}/CrmDfm/DeveloperContact/CreateDeveloperContact`,
    body
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

const UpdateDeveloperContactAPI = async (developerContactId, body) => {
  const result = await HttpServices.put(
    `${config.server_address}/CrmDfm/DeveloperContact/UpdateDeveloperContact/${developerContactId}`,
    body
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

const GetDeveloperContactByIdAPI = async (developerContactId) => {
  const result = await HttpServices.get(
    `${config.server_address}/CrmDfm/DeveloperContact/GetDeveloperContactById/${developerContactId}`
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

const CreateAgreementDetailsAPI = async (body) => {
  const result = await HttpServices.post(
    `${config.server_address}/CrmDfm/DeveloperContact/CreateAgreementDetails`,
    body
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

const UpdateAgreementDetailsAPI = async (agreementDetailsId, body) => {
  const result = await HttpServices.put(
    `${config.server_address}/CrmDfm/DeveloperContact/UpdateAgreementDetails/${agreementDetailsId}`,
    body
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

const GetAgreementDetailsByDeveloperContactIdAPI = async (
  developerContactId,
  body
) => {
  const result = await HttpServices.post(
    `${config.server_address}/CrmDfm/DeveloperContact/GetAgreementDetailsByDeveloperContactId/${developerContactId}`,
    body
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

const GetAgreementDetailsByIdAPI = async (agreementDetailsId) => {
  const result = await HttpServices.get(
    `${config.server_address}/CrmDfm/DeveloperContact/GetAgreementDetailsById/${agreementDetailsId}`
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

const DeleteAgreementDetailsAPI = async (agreementDetailsId) => {
  const result = await HttpServices.delete(
    `${config.server_address}/CrmDfm/DeveloperContact/DeleteAgreementDetails/${agreementDetailsId}`
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

const CreateAuditAndComplianceAPI = async (body) => {
  const result = await HttpServices.post(
    `${config.server_address}/CrmDfm/DeveloperContact/CreateAuditAndCompliance`,
    body
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

const UpdateAuditAndComplianceAPI = async (auditAndComplianceId, body) => {
  const result = await HttpServices.put(
    `${config.server_address}/CrmDfm/DeveloperContact/UpdateAuditAndCompliance/${auditAndComplianceId}`,
    body
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

const GetAuditAndComplianceByIdAPI = async (auditAndComplianceId) => {
  const result = await HttpServices.get(
    `${config.server_address}/CrmDfm/DeveloperContact/GetAuditAndComplianceById/${auditAndComplianceId}`
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

const GetAuditAndComplianceByDeveloperContactIdAPI = async (
  developerContactId,
  body
) => {
  const result = await HttpServices.post(
    `${config.server_address}/CrmDfm/DeveloperContact/GetAuditAndComplianceByDeveloperContactId/${developerContactId}`,
    body
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

const DeleteAuditAndComplianceAPI = async (auditAndComplianceId) => {
  const result = await HttpServices.delete(
    `${config.server_address}/CrmDfm/DeveloperContact/DeleteAuditAndCompliance/${auditAndComplianceId}`
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};
const CreateDeveloperContactDocumentsAPI = async (body) => {
  const result = await HttpServices.post(
    `${config.server_address}/CrmDfm/DeveloperContact/AddDeveloperContactDocuments`,
    body
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

const UpdateDeveloperContactDocumentsAPI = async (body) => {
  const result = await HttpServices.put(
    `${config.server_address}/CrmDfm/DeveloperContact/UpdateDeveloperContactDocuments`,
    body
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

const GetDeveloperContactDocumentByIdAPI = async (documentId) => {
  const result = await HttpServices.get(
    `${config.server_address}/CrmDfm/DeveloperContact/GetDeveloperContactDocumentById/${documentId}`
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

const GetDeveloperContactDocumentsAPI = async ({
  developerContactId,
  pageIndex,
  pageSize,
}) => {
  const queryList = [];
  if (pageIndex) queryList.push(`pageIndex=${pageIndex}`);
  if (pageSize) queryList.push(`pageSize=${pageSize}`);

  const result = await HttpServices.get(
    `${
      config.server_address
    }/CrmDfm/DeveloperContact/GetDeveloperContactDocuments/${developerContactId}?${queryList.join(
      "&"
    )}`
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

const DeleteDeveloperContactDocumentAPI = async (documentId) => {
  const result = await HttpServices.delete(
    `${config.server_address}/CrmDfm/DeveloperContact/DeleteDeveloperContactDocument/${documentId}`
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

const CreateDeveloperEmployeeAPI = async (body) => {
  const result = await HttpServices.post(
    `${config.server_address}/CrmDfm/DeveloperContact/AddDeveloperEmployee`,
    body
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

const UpdateDeveloperEmployeeAPI = async (employeeId, body) => {
  const result = await HttpServices.put(
    `${config.server_address}/CrmDfm/DeveloperContact/UpdateDeveloperEmployee/${employeeId}`,
    body
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

const GetDeveloperEmployeeByIdAPI = async (employeeId) => {
  const result = await HttpServices.get(
    `${config.server_address}/CrmDfm/DeveloperContact/GetDeveloperEmployeeById/${employeeId}`
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

const GetDeveloperEmployeessAPI = async (developerId, body) => {
  const result = await HttpServices.post(
    `${config.server_address}/CrmDfm/DeveloperContact/GetDeveloperEmployees?developerId=${developerId}`,
    body
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

const DeleteDeveloperEmployeeAPI = async (employeeId) => {
  const result = await HttpServices.delete(
    `${config.server_address}/CrmDfm/DeveloperContact/DeleteDeveloperEmployee/${employeeId}`
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

const CreateBranchLocationAPI = async (body) => {
  const result = await HttpServices.post(
    `${config.server_address}/CrmDfm/DeveloperContact/AddBranchLocation`,
    body
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

const UpdateBranchLocationAPI = async (branchLocationId, body) => {
  const result = await HttpServices.put(
    `${config.server_address}/CrmDfm/DeveloperContact/UpdateBranchLocation/${branchLocationId}`,
    body
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

const GetBranchLocationByIdAPI = async (branchLocationId) => {
  const result = await HttpServices.get(
    `${config.server_address}/CrmDfm/DeveloperContact/GetBranchLocationById/${branchLocationId}`
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

const GetBranchLocationsByDeveloperIdAPI = async (developerId, body) => {
  const result = await HttpServices.post(
    `${config.server_address}/CrmDfm/DeveloperContact/GetBranchLocationsByDeveloperId/${developerId}`,
    body
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

const DeleteBranchLocationAPI = async (branchLocationId) => {
  const result = await HttpServices.delete(
    `${config.server_address}/CrmDfm/DeveloperContact/DeleteBranchLocation/${branchLocationId}`
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};
const CreateSocialMediaLogoAPI = async (body) => {
  const result = await HttpServices.post(
    `${config.server_address}/CrmDfm/DeveloperContact/AddSocialMediaLogo`,
    body
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

const UpdateSocialMediaLogoAPI = async (socialMediaLogoId, body) => {
  const result = await HttpServices.put(
    `${config.server_address}/CrmDfm/DeveloperContact/UpdateSocialMediaLogo/${socialMediaLogoId}`,
    body
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

const GetSocialMediaLogoByIdAPI = async (socialMediaLogoId) => {
  const result = await HttpServices.get(
    `${config.server_address}/CrmDfm/DeveloperContact/GetSocialMediaLogoById/${socialMediaLogoId}`
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

const GetSocialMediaLogoByDeveloperContactIdAPI = async ({
  developerId,
  pageIndex,
  pageSize,
}) => {
  const queryList = [];
  if (pageIndex) queryList.push(`pageIndex=${pageIndex}`);
  if (pageSize) queryList.push(`pageSize=${pageSize}`);

  const result = await HttpServices.get(
    `${
      config.server_address
    }/CrmDfm/DeveloperContact/GetSocialMediaLogoByDeveloperContactId/${developerId}?${queryList.join(
      "&"
    )}`
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

const DeleteSocialMediaLogoIdAPI = async (socialMediaLogoId) => {
  const result = await HttpServices.delete(
    `${config.server_address}/CrmDfm/DeveloperContact/DeleteSocialMediaLogo/${socialMediaLogoId}`
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};
 
const CreateCommissionDetailsAPI = async (body) => {
  const result = await HttpServices.post(
    `${config.server_address}/CrmDfm/DeveloperContact/AddCommissionDetails`,
    body
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

const UpdateCommissionDetailsAPI = async (commissionDetailsId, body) => {
  const result = await HttpServices.put(
    `${config.server_address}/CrmDfm/DeveloperContact/UpdateCommissionDetails/${commissionDetailsId}`,
    body
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

const GetCommissionDetailsByIdAPI = async (commissionDetailsId) => {
  const result = await HttpServices.get(
    `${config.server_address}/CrmDfm/DeveloperContact/GetCommissionDetailsById/${commissionDetailsId}`
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

const GetCommissionDetailsByAgreementIdAPI = async ({
  developerContactId ,
  pageIndex,
  pageSize,
}) => {
  const queryList = [];
  if (pageIndex) queryList.push(`pageIndex=${pageIndex}`);
  if (pageSize) queryList.push(`pageSize=${pageSize}`);

  const result = await HttpServices.get(
    `${
      config.server_address
    }/CrmDfm/DeveloperContact/GetCommissionDetailsByAgreementId/${developerContactId}?${queryList.join(
      "&"
    )}`
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

const DeleteCommissionDetailsAPI = async (commissionDetailsId) => {
  const result = await HttpServices.delete(
    `${config.server_address}/CrmDfm/DeveloperContact/DeleteCommissionDetails/${commissionDetailsId}`
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};


export {
  GetDeveloperContactsAdvanceSearchAPI,
  GetDeveloperContactByIdAPI,
  CreateDeveloperContactAPI,
  UpdateDeveloperContactAPI,
  CreateAgreementDetailsAPI,
  UpdateAgreementDetailsAPI,
  GetAgreementDetailsByDeveloperContactIdAPI,
  GetAgreementDetailsByIdAPI,
  DeleteAgreementDetailsAPI,
  CreateAuditAndComplianceAPI,
  UpdateAuditAndComplianceAPI,
  GetAuditAndComplianceByIdAPI,
  GetAuditAndComplianceByDeveloperContactIdAPI,
  DeleteAuditAndComplianceAPI,
  CreateDeveloperContactDocumentsAPI,
  UpdateDeveloperContactDocumentsAPI,
  GetDeveloperContactDocumentByIdAPI,
  GetDeveloperContactDocumentsAPI,
  DeleteDeveloperContactDocumentAPI,
  CreateDeveloperEmployeeAPI,
  UpdateDeveloperEmployeeAPI,
  GetDeveloperEmployeeByIdAPI,
  GetDeveloperEmployeessAPI,
  DeleteDeveloperEmployeeAPI,
  CreateBranchLocationAPI,
  UpdateBranchLocationAPI,
  GetBranchLocationByIdAPI,
  GetBranchLocationsByDeveloperIdAPI,
  DeleteBranchLocationAPI,
  CreateSocialMediaLogoAPI,
  UpdateSocialMediaLogoAPI,
  GetSocialMediaLogoByDeveloperContactIdAPI,
  GetSocialMediaLogoByIdAPI,
  DeleteSocialMediaLogoIdAPI,
  CreateCommissionDetailsAPI , 
  UpdateCommissionDetailsAPI , 
  GetCommissionDetailsByAgreementIdAPI , 
  GetCommissionDetailsByIdAPI , 
  DeleteCommissionDetailsAPI 
};
