import React from "react";
import { Box } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { CustomCard } from "../../../../Components";

// Styles
import useStyles from "./styles";

function ContactOverviewLeadsCardSkeleton({ numberOfCards }) {
  const styles = useStyles();

  const skeletonCards = Array.from({ length: numberOfCards }, (_, index) => (
    <Box key={index} className={styles.leadWrapper}>
      <Box className={styles.infoSection}>
        <Box className={styles.headingSection}>
          <Skeleton
            className={styles.rounded}
            animation="wave"
            width="50px"
            variant="circular"
            height="24px"
          />
          <Box display="flex" style={{ gap: "10px" }}>
            <Skeleton variant="text" width={37} height={20} />
            <Skeleton variant="text" width={75} height={20} />
          </Box>
        </Box>
        <Box className={styles.infoItem}>
          <Skeleton variant="text" width={80} height={20} />
          <Skeleton
            variant="circle"
            width={26}
            height={26}
          />
          <Skeleton variant="text" width={60} height={20} />
        </Box>
        <Box className={styles.infoItem}>
          <Skeleton variant="text" width={80} height={20} />
          <Skeleton variant="text" width={60} height={20} />
        </Box>
      </Box>
    </Box>
  ));

  return (
    <CustomCard
      boxShadow="xs"
      borderRadius="xl"
      borderColor="secondary"
      classes={styles.contactOverViewCard}
    >
      <Box className={styles.leadCardTitleWrapper}>
        <div className={styles.titleContainer}>
          <Skeleton
            variant="text"
            width={52.5}
            height={28}
            style={{ borderRadius: "4px" }}
            animation={false}
          />
          <Skeleton
            variant="circular"
            width={32}
            height={22}
            style={{ borderRadius: "0.75rem" }}
          />
        </div>

        <div>
          <Skeleton variant="rectangular" width={64} height={20} />
        </div>
      </Box>

      <Box className={styles.leadContainer}>{skeletonCards}</Box>
    </CustomCard>
  );
}

export default ContactOverviewLeadsCardSkeleton;
