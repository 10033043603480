export const UnitRefNumberLeadOwnerRule = async (item, itemList, value, values, setData, formType) => {
    const saleTypeIndex = itemList.indexOf(itemList.find((f) => (f.field.id === 'sale_type' && f.data.specialKey === 'sale_type_lead_owner')));
    if (value && +formType === 1 && item.field.id === 'unit_ref-number' && item.data.DtoName === 'unit_ref_number_owner_lead') {
        if (saleTypeIndex !== -1) {
            setData('sale_type', (value && value.saleType ? ((value.saleType && +(value.saleType.lookupItemId) === 432) ? 'Sale' : 'Resale') : {}));
            itemList[saleTypeIndex].data.isReadonly = true;
        }
    } else if (+formType === 2 && value) {
        if (saleTypeIndex !== -1 && (values.lead_operation && values.lead_operation === 'Seller')) {
            setData(((value && value.saleType ? ((value.saleType && +(value.saleType.lookupItemId) === 432) ? 'Sale' : 'Resale') : null)), 0, 'sale_type');
            itemList[saleTypeIndex].data.isReadonly = true;
        }
    } else if (!value && formType === 1 && item.field.id === 'unit_ref-number' && item.data.DtoName === 'unit_ref_number_owner_lead') {
        if (saleTypeIndex !== -1) {
            setData('sale_type', {});
            itemList[saleTypeIndex].data.isReadonly = false;
        }
    } else if (!value && formType === 2) {
        if (saleTypeIndex !== -1 && (values.lead_operation && values.lead_operation === 'Seller')) {
            setData(null, 0, 'sale_type');
            itemList[saleTypeIndex].data.isReadonly = false;
        }
    }
};
