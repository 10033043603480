export const LeadsQualificationEnum = {
  'Qualified lead': {
    name: 'qualified',
    classes: 'qualified-lead',
  },
  Opportunity: {
    name: 'opportunity',
    classes: 'opportunity-lead',
  },
  'Unqualified Lead': {
    name: 'un-qualified',
    classes: 'un-qualified-lead',
  },
  'Qualified by QA': {
    name: 'qualified-by-qa',
    classes: 'qualified-by-qa',
  }
};
