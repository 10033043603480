import React, { useContext } from "react";
import { Box } from "@material-ui/core";

import { CustomButton, CustomIconButton } from "../../../Components";
import { ContactLayoutContext } from "../../../Layouts/Home/NewContactsCrmLayout/ContactLayoutContext";
import { useSelectedTheme } from "../../../Hooks";

// Icons
import {
  UnqualifiedIcon,
  ReassignOwnerIcon,
  CloneIcon,
  CloseXIcon,
  SearchIcon,
} from "../../../assets/icons";

// Styles
import useStyles from "./styles";

function LeadActionButtons({ setCriteriaFilter, contactId }) {
  const styles = useStyles();
  const {
    theme: { palette },
  } = useSelectedTheme();

  const { actionableItems, setActionableItems } =
    useContext(ContactLayoutContext);

  const buttons = [
    {
      action: "Unqualified",
      label: "Unqualified",
      icon: UnqualifiedIcon,
    },
    {
      action: "reassign-owner-lead",
      label: "Reassign Owner",
      icon: ReassignOwnerIcon,
    },
    {
      action: "reassign-seeker-lead",
      label: "Reassign Seeker",
      icon: SearchIcon,
    },
    {
      action: "clone",
      label: "Clone",
      icon: CloneIcon,
    },
    {
      action: "close-leads",
      label: "Close Lead",
      icon: CloseXIcon,
    },
  ];

  const handleApplyButton = () => {
    if (
      actionableItems?.action === "Unqualified" &&
      actionableItems?.selectedIds?.length > 0
    ) {
      setActionableItems((prevState) => ({
        ...prevState,
        isUnqualifiedLeadsDialogOpen: true,
      }));
    } else if (
      (actionableItems?.action === "reassign-owner-lead" ||
        actionableItems?.action === "reassign-seeker-lead") &&
      actionableItems?.selectedIds?.length > 0
    ) {
      setActionableItems((prevState) => ({
        ...prevState,
        isOpenReassignDialog: true,
      }));
    } else if (
      actionableItems?.action === "clone" &&
      actionableItems?.selectedIds?.length > 0
    ) {
      setActionableItems((prevState) => ({
        ...prevState,
        isOpenCloneLeads: true,
      }));
    } else if (
      actionableItems?.action === "close-leads" &&
      actionableItems?.selectedIds?.length > 0
    ) {
      setActionableItems((prevState) => ({
        ...prevState,
        isOpenCloseLeadsDialog: true,
      }));
    }
  };

  return (
    <Box className={styles.wrapper}>
      {actionableItems?.selectedIds?.length > 0 && (
        <>
          <CustomIconButton
            variant="text"
            size="none"
            boxShadow="none"
            color="tertiaryColor"
            hideHoverBg
          >
            <CloseXIcon
              onClick={() =>
                setActionableItems({
                  selectedIds: [],
                  action: null,
                })
              }
              width="20"
              height="20"
              fill={palette.button.secondary_fg}
            />
          </CustomIconButton>

          <Box className={styles.selectedCount}>
            {actionableItems?.selectedIds?.length} selected
          </Box>
        </>
      )}

      {buttons.map(({ action, label, icon: Icon }) => {
        const isSelected = actionableItems?.action === action;
        const buttonColor = isSelected ? "secondaryColor" : "secondary";
        const iconFill = isSelected
          ? palette.button.secondaryColor_fg
          : palette.button.secondary_fg;
        const boxShadow = isSelected ? "none" : "xs";

        return (
          <CustomButton
            key={action}
            boxShadow={boxShadow}
            size="lg"
            variant="outlined"
            color={buttonColor}
            onClick={() => {
              if (actionableItems?.action !== action) {
                setActionableItems({
                  selectedIds: [],
                  action,
                });
              }
            }}
            startIcon={<Icon width="20" height="20" fill={iconFill} />}
          >
            {label}
          </CustomButton>
        );
      })}

      {actionableItems?.action && (
        <CustomButton
          size="lg"
          variant="text"
          color="tertiary"
          onClick={handleApplyButton}
        >
          Apply action
        </CustomButton>
      )}

      {actionableItems?.action && (
        <CustomButton
          size="lg"
          variant="text"
          color="tertiary"
          onClick={() => {
            setActionableItems({
              selectedIds: [],
              action: null,
            });

            setCriteriaFilter({
              contactId: [{ searchType: 1, value: contactId }],
            });
          }}
        >
          Cancel
        </CustomButton>
      )}
    </Box>
  );
}

export default LeadActionButtons;
