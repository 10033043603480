const UNITS = 'units';
const LEADS = 'leads';
const AGENTS = 'agents';
const CONTACTS = 'contacts';
const COMPANIES = 'companies';
const PROPERTIES = 'properties';
const ZEROMATCHING = 'zeroMatching';
const LISTINGSHORTAGE = 'listingShortage';
const DEVELOPERS = 'Developers'

export {
      UNITS,
       LEADS,
        AGENTS,
         CONTACTS,
          PROPERTIES,
           COMPANIES,
            ZEROMATCHING,
             LISTINGSHORTAGE , 
             DEVELOPERS
};
