import { UnitsSalesPermissions, UnitsLeasePermissions , LeadOwnerUnitSalePermissions   , LeadOwnerUnitLeasePermissions  } from '../../../../../../Permissions';
import { UnitProfileSaleDetailsComponent } from '../../../../UnitsSalesView';
import {AgentInfoSaleRelatedComponent} from '../../../../UnitsView/UnitsStatusManagementView/Sections';
import { UnitInvoicePaymentDue } from '../../../../UnitsView/UnitsReservationView/Sections/UnitInvoicePaymentDue';
import { UnitRemindersCoponent } from '../../../../UnitsView/UnitsReservationView/Sections/UnitRemindersCoponent';
import { UnitSaleComponent } from '../../../../UnitsView/UnitsReservationView/Sections/UnitSaleComponent';
import { UnitTenantComponent } from '../../../../UnitsView/UnitsReservationView/Sections/UnitTenantComponent';
import {
  AgentInfoRentRelatedComponent,
  DetailsRentRelatedComponent,
} from '../../../../UnitsView/UnitsStatusManagementView/Sections';

export const UnitReservationTabsData = {
  sale: [
    {
      label: 'sale',
      component: UnitSaleComponent,
      permissionsList: Object.values(UnitsSalesPermissions),
      permissionsId:
        UnitsSalesPermissions.ViewSellerBuyerNamesForSoldReservedTransactions.permissionsId,
    },

    {
      label: 'details',
      component: UnitProfileSaleDetailsComponent,
      permissionsList: Object.values(UnitsSalesPermissions),
      permissionsId:
        UnitsSalesPermissions.ViewPricingDetailsForSoldReservedTransactions.permissionsId,
      props: { isReadOnly: true },
    },

    {
      label: 'invoice-payments-due',
      component: UnitInvoicePaymentDue,
      permissionsList: Object.values(UnitsSalesPermissions),
      permissionsId: UnitsSalesPermissions.ViewInvoicesForSoldReservedTransactions.permissionsId,
    },

    {
      label: 'contact-details',
      component: AgentInfoSaleRelatedComponent,
      permissionsList: Object.values(UnitsSalesPermissions),
      permissionsId: UnitsSalesPermissions.ViewUnitDetails.permissionsId,
    },

    {
      label: 'reminders',
      component: UnitRemindersCoponent,
      permissionsList: Object.values(UnitsSalesPermissions),
      permissionsId: UnitsSalesPermissions.ViewRemindersOnSoldReservedTransactions.permissionsId,
    },
  ],
  rent: [
    {
      label: 'tenant',
      component: UnitTenantComponent,
      permissionsList: Object.values(UnitsLeasePermissions),
      permissionsId: UnitsLeasePermissions.ViewTenantLandlordNames.permissionsId,

    },
    {
      label: 'details',
      component: DetailsRentRelatedComponent,
      permissionsList: Object.values(UnitsLeasePermissions),
      permissionsId: UnitsLeasePermissions.ViewPricingDetailsForLease.permissionsId,

    },
    {
      label: 'invoice-payments-due',
      component: UnitInvoicePaymentDue,
      permissionsList: Object.values(UnitsLeasePermissions),
      permissionsId: UnitsLeasePermissions.ViewInvoicesForLeaseReservedTransactions.permissionsId,

    },
    {
      label: 'contact-details',
      component: AgentInfoRentRelatedComponent,
      permissionsList: Object.values(UnitsLeasePermissions),
      permissionsId: UnitsLeasePermissions.ViewTransactionDetailsForUnit.permissionsId,

    },
    {
      label: 'reminders',
      component: UnitRemindersCoponent,
      permissionsList: Object.values(UnitsLeasePermissions),
      permissionsId: UnitsLeasePermissions.ViewReminderForLeasedReservedTransaction.permissionsId,
    },
  ],
  leadOwnerUnitSale : [
    {
      label: 'sale',
      component: UnitSaleComponent,
      permissionsList: Object.values(LeadOwnerUnitSalePermissions),
      permissionsId:
      LeadOwnerUnitSalePermissions.ViewSellerBuyerNamesForSoldReservedTransactions.permissionsId,
    },

    {
      label: 'details',
      component: UnitProfileSaleDetailsComponent,
      permissionsList: Object.values(LeadOwnerUnitSalePermissions),
      permissionsId:
      LeadOwnerUnitSalePermissions.ViewPricingDetailsForSoldReservedTransactions.permissionsId,
      props: { isReadOnly: true },
    },

    {
      label: 'invoice-payments-due',
      component: UnitInvoicePaymentDue,
      permissionsList: Object.values(LeadOwnerUnitSalePermissions),
      permissionsId: LeadOwnerUnitSalePermissions.ViewInvoicesForSoldReservedTransactions.permissionsId,
    },

    {
      label: 'contact-details',
      component: AgentInfoSaleRelatedComponent,
      permissionsList: Object.values(LeadOwnerUnitSalePermissions),
      permissionsId: LeadOwnerUnitSalePermissions.ViewUnitDetails.permissionsId,
    },

    {
      label: 'reminders',
      component: UnitRemindersCoponent,
      permissionsList: Object.values(LeadOwnerUnitSalePermissions),
      permissionsId: LeadOwnerUnitSalePermissions.ViewRemindersOnSoldReservedTransactions.permissionsId,
    },
  ],
  leadOwnerUnitLease :[
    {
      label: 'tenant',
      component: UnitTenantComponent,
      permissionsList: Object.values(LeadOwnerUnitLeasePermissions),
      permissionsId: LeadOwnerUnitLeasePermissions.ViewTenantLandlordNamesForLeaseReservedTransactions.permissionsId,

    },
    {
      label: 'details',
      component: DetailsRentRelatedComponent,
      permissionsList: Object.values(LeadOwnerUnitLeasePermissions),
      permissionsId: LeadOwnerUnitLeasePermissions.ViewPricingDetailsForLeaseReservedTransactions.permissionsId,

    },
    {
      label: 'invoice-payments-due',
      component: UnitInvoicePaymentDue,
      permissionsList: Object.values(LeadOwnerUnitLeasePermissions),
      permissionsId: LeadOwnerUnitLeasePermissions.ViewInvoicesForLeaseReservedTransactions.permissionsId,

    },
    {
      label: 'contact-details',
      component: AgentInfoRentRelatedComponent,
      permissionsList: Object.values(LeadOwnerUnitLeasePermissions),
      permissionsId: LeadOwnerUnitLeasePermissions.ViewTransactionDetailsForUnit.permissionsId,

    },
    {
      label: 'reminders',
      component: UnitRemindersCoponent,
      permissionsList: Object.values(LeadOwnerUnitLeasePermissions),
      permissionsId: LeadOwnerUnitLeasePermissions.ViewReminderForLeasedReservedTransaction.permissionsId,
    }
  ]
};
