import React, { useState, useEffect, useCallback } from 'react';
import { PropTypes } from 'prop-types';
import { UnitProfileImagesCardActionEnum } from '../../../../../../../../Enums';
import { GetAllPropertyVideosCategoryByPropertyId } from '../../../../../../../../Services';
import { VideosGalleryCardComponent } from '../../../../../../ImagesGallery/Sections/VideosGalleryComponent/VideosGalleryCardComponent/VideosGalleryCardComponent';

import { Spinner } from '../../../../../../../../Components';
import { StaticLookupsIds } from '../../../../../../../../assets/json/StaticLookupsIds';

export const PropertyVideoTabComponent = ({
  parentTranslationPath,
  translationPath,
  propertyId,
  canEditImage,
  activeTab
}) => {
  const [result, setResult] = useState();
  const [isLoading, setIsLoading] = useState(true);

  const getAllPropertyVideosCategory = useCallback(async () => {
    const response = await GetAllPropertyVideosCategoryByPropertyId(propertyId);
    setIsLoading(true);
    if (!(response && response.status && response.status !== 200))
      setResult(response || []);
    else setResult([]);
    setIsLoading(false);
  }, [propertyId]);

  useEffect(() => {
    getAllPropertyVideosCategory();
  }, [getAllPropertyVideosCategory]);
  return (
    <div className='PropertyImagesTabComponent'>
      <Spinner isActive={isLoading} />
      {result && result.length > 0 ? (
        <VideosGalleryCardComponent
          ImageCategoryLookup={StaticLookupsIds.UnitImageCategory}
          hidePhotosTooltip
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          data={result}
          WithUnitDetails
          reloadData={getAllPropertyVideosCategory}
          setdata={setResult}
          Display={UnitProfileImagesCardActionEnum.Hide}
          unitsUploader
          activeTab={activeTab}
        />
      ) : (
        <div className='EmptyPage' />
      )}
    </div>
  );
};
PropertyVideoTabComponent.propTypes = {
  parentTranslationPath: PropTypes.string.isRequired,
  translationPath: PropTypes.string.isRequired,
  propertyId: PropTypes.number.isRequired,
  activeTab: PropTypes.number.isRequired,
};
