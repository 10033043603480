export const ActivityTypeReminderToEnum = {
    Agent:{
      value: 0,
      text: 'agent',
      isDisabled: false,
    },
    Lead:{
      value: 1,
      text: 'lead',
      isDisabled: false,
    },
};
