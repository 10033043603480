export const ImportProcceseTypeEnum = [
    {
        key: 1,
        name: 'Contacts',
        value: 'Contacts'
    },
    {
        key: 2,
        name: 'OwnerLeads',
        value: 'Owner Leads'
    },
    {
        key: 3,
        name: 'SeekerLeads',
        value: 'Seeker Leads'
    },
    {
        key: 4,
        name: 'Properties',
        value: 'Properties'
    },
    {
        key: 5,
        name: 'Units',
        value: 'Units'
    },
    {
        key: 6,
        name: 'Companies',
        value: 'Companies'
    },
    {
        key: 7,
        name: 'QuickAddLead',
        value: 'Quick Add Lead'
    },
];
