import { AssignAgentTable } from '../../Views/Home/OwnerRotationView/OwnerRotationUtilities/AssignAgentTable';
import { OwnerRotationView } from '../../Views/Home/OwnerRotationView/OwnerRotationView';

export const OwnerRotationRoutes = [
  {
    path: '/view',
    name: 'OwnerRotationView:OwnerRotation',
    component: OwnerRotationView,
    layout: '/home/OwnerRotation',
    default: true,
    isRoute: true,
    authorize: true,
    roles: [],
    isDisabled: false,
    isExact: true,
    breadcrumbs: [
      {
        name: 'OwnerRotationView:OwnerRotation',
        isDisabled: true,
        route: '/home/OwnerRotation/view',
        groupName: 'agent-management',
      }
    ]
  },
  {
    path: '/manage',
    name: 'OwnerRotationView:rotation',
    component: AssignAgentTable,
    layout: '/home/OwnerRotation',
    default: false,
    isRoute: true,
    authorize: true,
    roles: [],
    isDisabled: false,
    isExact: true,
    breadcrumbs: [
      {
        name: 'OwnerRotationView:OwnerRotation',
        isDisabled: false,
        route: '/home/OwnerRotation/view',
        groupName: 'agent-management',
      },
      {
        name: 'OwnerRotationView:Assign-new-agents',
        isDisabled: true,
        route: '/home/OwnerRotation/manage',
      },
    ],
  }
];
