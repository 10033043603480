export const AMLTransactionStatusEnum = {
  1: {
    key: 1,
    name: "Pass",
    classes: "c-success-light",
  },
  2: {
    key: 2,
    name: "Fail",
    classes: "c-primary",
  },
  3: {
    key: 3,
    name: "Pending",
    classes: "c-primary",
  },
  4: {
    key: 4,
    name: "NeedRevision",
    classes: "c-primary",
  },
  5: {
    key: 5,
    name: "WithdrawTransaction",
    classes: "c-primary",
  },
  6: {
    key: 6,
    name: "AutoWithdraw",
    classes: "c-primary",
  },
  7: {
    key: 7,
    name: "Resubmitted",
    classes: "c-primary",
  },
  8: {
    key: 8,
    name: "Approved",
    classes: "c-primary",
  },
  9: {
    key: 9,
    name: "Rejected",
    classes: "c-primary",
  },
  10: {
    key: 4,
    name: "ReassignedToRequester",
    classes: "c-primary",
  },
};
