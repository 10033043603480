import React from "react";

function ActivityIcon({ fill, ...restProps }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill={fill ?? ""}
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <path
        d="M8.659 2.07c-.223.097-.341.186-.467.354-.076.102-.513 1.36-1.513 4.36L5.273 11h-1.69c-1.836 0-1.922.009-2.201.221-.08.061-.195.194-.254.295-.091.156-.108.23-.108.484 0 .257.016.327.113.492.128.219.369.409.592.469.099.027.938.038 2.364.03L6.3 12.98l.192-.113c.105-.062.243-.184.306-.271.084-.115.402-1.018 1.149-3.258C8.515 7.634 8.989 6.24 9 6.24c.011 0 1.116 3.29 2.456 7.31 1.339 4.02 2.488 7.459 2.554 7.641.09.252.16.373.294.507a.97.97 0 0 0 1.165.176c.371-.192.318-.056 1.85-4.651L18.727 13l1.727-.002c1.857-.002 1.89-.005 2.165-.215.077-.058.189-.191.25-.294.095-.161.111-.233.111-.489 0-.255-.016-.328-.109-.486a1.027 1.027 0 0 0-.647-.475c-.139-.03-.889-.04-2.345-.032l-2.141.013-.199.102c-.375.193-.356.147-1.485 3.536-.568 1.706-1.043 3.102-1.054 3.102-.011 0-1.16-3.421-2.554-7.603-2.08-6.238-2.557-7.632-2.66-7.765-.25-.324-.777-.475-1.127-.322"
        fill-rule="evenodd"
        fill={fill ?? ""}
      />
    </svg>
  );
}

export default ActivityIcon;
