import { AgreementEssentialsTab, ReservationDetailsTab } from "./index";

export const UnitReservationStepsData = [
  { label: "Rental-Reservation-Details", component: ReservationDetailsTab },
  { label: "Rental-Agreement-Essentials", component: AgreementEssentialsTab },
];

export const TransactionExcutionStepsData = [
  { label: "Leased-Details", component: ReservationDetailsTab },
  { label: "Leased-Agreement-Essentials", component: AgreementEssentialsTab },
];
