export const UserTrainingInsightsPermissions = {
    ViewUserTrainingInsights :{
        permissionsId: "3e33eadc-b024-4fcd-0063-08dd03ecea2b",
        permissionsName: "View User Training Insights",
        description: null,
        componentsId: null,
        components: null
    }
  
  };
  