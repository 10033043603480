

export const ExternalDashboardPermissions = {
    Tab1 :{
            permissionsId: "be86ead2-7a4d-46b8-2896-08dab0da5710",
            permissionsName: "Tab1",
            componentsId: "8ac2d5ff-1516-4f6e-60dd-08d8a616b3a7",
            components: null
          } , 
    Tab2 :{
        permissionsId: "b6be1717-54a0-44ff-2897-08dab0da5710",
        permissionsName: "Tab2",
        componentsId: "8ac2d5ff-1516-4f6e-60dd-08d8a616b3a7",
        components: null
         },
    Tab3 :{ 
        permissionsId: "d5cd06ef-d712-469c-2898-08dab0da5710",
        permissionsName: "Tab3",
        componentsId: "8ac2d5ff-1516-4f6e-60dd-08d8a616b3a7",
        components: null
          },
    Tab4 : {
        permissionsId: "4b9cc9ea-250f-498b-2899-08dab0da5710",
        permissionsName: "Tab4",
        componentsId: "8ac2d5ff-1516-4f6e-60dd-08d8a616b3a7",
        components: null
      },
    Tab5 :{
        permissionsId: "a14dec60-6573-465e-289a-08dab0da5710",
        permissionsName: "Tab5",
        componentsId: "8ac2d5ff-1516-4f6e-60dd-08d8a616b3a7",
        components: null
      },
    Tab6 :{
        permissionsId: "7b3713ef-5530-4039-289b-08dab0da5710",
        permissionsName: "Tab6",
        componentsId: "8ac2d5ff-1516-4f6e-60dd-08d8a616b3a7",
        components: null
         },
    Tab7 : {
        permissionsId: "76f0386b-bf70-4dbf-289c-08dab0da5710",
        permissionsName: "Tab7",
        componentsId: "8ac2d5ff-1516-4f6e-60dd-08d8a616b3a7",
        components: null
      },
    Tab8 :{
        permissionsId: "8b1c73e2-d27b-4fdf-289d-08dab0da5710",
        permissionsName: "Tab8",
        componentsId: "8ac2d5ff-1516-4f6e-60dd-08d8a616b3a7",
        components: null
         },
    Tab9 :{
        permissionsId: "1b18e29c-c420-4686-289e-08dab0da5710",
        permissionsName: "Tab9",
        componentsId: "8ac2d5ff-1516-4f6e-60dd-08d8a616b3a7",
        components: null
         },
    Tab10 :{
        permissionsId : "8dea290a-1087-4c6b-289f-08dab0da5710",
        permissionsName: "Tab10",
        componentsId: "8ac2d5ff-1516-4f6e-60dd-08d8a616b3a7",
        components: null
      },
    } ;
    