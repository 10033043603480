import React, { useReducer, useState, useCallback, useEffect } from "react";
import { InputAdornment } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import Button from "@material-ui/core/Button";
import { PageHeading } from "../../../../../SharedComponents";
import { Inputs, Spinner } from "../../../../../Components";
import { showWarn, showError, showSuccess , bottomBoxComponentUpdate } from "../../../../../Helper";
import { ConfirmChangesDialog } from '../../Dialogs' ; 
import {
  GetAllPropertyRatingTabScores,
  UpdatePropertyRatingTabScores,
  
} from "../../../../../Services";

export const RatingPercentageView = ({
  parentTranslationPath,
  translationPath,
  setRatingPercentage,
  ratingPercentage,
}) => {
  const { t } = useTranslation(parentTranslationPath);

  const reducer = (select, action) => {
    if (action.id !== "edit") return { ...select, [action.id]: action.value };
    if (action.id === "edit") {
      return {
        ...action.value,
      };
    }
  };
  const [isLoading, setIsLoading] = useState(false);
  const maxAllowed = 100;
  const [ratingPercentageTotal, setRatingPercentageTotal] = useState(0);
  const [state, setState] = useReducer(reducer, {
    propertyRatingTabScoresDto: [],
  });
  const [helperText, setHelperText] = useState("");
  const [isOpenConfirmChangesDialog, setIsOpenConfirmChangesDialog] = useState(false);

  const calculateRatingPercentage = (newArray) => {
    let sum = 0;
    for (let i in newArray) {
      sum += newArray[i] && newArray[i].score;
    }
    if (sum > 100) {
      showWarn(
        t("total-rating-percentage-more-than-100-please-change-percentage")
      );
      setHelperText(
        t(`${translationPath}total-rating-percentage-more-than-100`)
      );
    } else setHelperText("");
    return sum;
  };

  const UpdateRatingValues = (index, newValue) => {
    let updatelist = state && state.propertyRatingTabScoresDto;
    if (index !== -1) {
      updatelist[index] = {
        ...updatelist[index],
        score: newValue,
      };
    }
    setRatingPercentageTotal(calculateRatingPercentage(updatelist));
    setState({ id: "propertyRatingTabScoresDto", value: updatelist });
  };

  const updateIfMaxValue = (index, score) => {
    let updatelist =state &&  state.propertyRatingTabScoresDto;
    if (score > maxAllowed && index !== -1)
      updatelist[index] = {
        ...updatelist[index],
        score: maxAllowed,
      };
    setState({ id: "propertyRatingTabScoresDto", value: updatelist });
  };

  const saveHandler = async () => {
    setIsLoading(true);
    if (ratingPercentageTotal > 100 || ratingPercentageTotal < 100) {
      showError(
        t(
          "total-rating-percentage-more-than-100-please-change-percentage-or-leass-than-100"
        )
      );
      setIsLoading(false);
      return;
    }
    const res = await UpdatePropertyRatingTabScores(state);
    if (!(res && res.StatusCode && res.StatusCode !== 200)) {
      showSuccess(t`${translationPath}rating-percentage-updated-successfully`);
      GetAllPropertyRatingTabScoresAPI();
    } else {
      showError(t`${translationPath}rating-percentage-update-failed`);
    }
    setIsLoading(false);
  };

  const GetAllPropertyRatingTabScoresAPI = useCallback(async () => {
    setIsLoading(true);
    const res = await GetAllPropertyRatingTabScores();
    if (!((res && res.data && res.data.ErrorId) || !res)) {
      setState({ id: "propertyRatingTabScoresDto", value: res });
      setRatingPercentageTotal(calculateRatingPercentage(res));
      setRatingPercentage(res);
    } else {
      setState({ id: "propertyRatingTabScoresDto", value: [] });
      setRatingPercentage([]);
    }
    setIsLoading(false);
  }, []);

  useEffect(() => {
    GetAllPropertyRatingTabScoresAPI();
  }, [GetAllPropertyRatingTabScoresAPI]);

  useEffect( () => {
    bottomBoxComponentUpdate(null);
  },
  []
);


  return (
    <div className="w-100 mt-4">
      <Spinner isActive={isLoading} isAbsolute />
      <div className="d-flex-v-center-h-between">
        <PageHeading
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          headerTitle={"rating-percentage"}
          subTitle={"enter-how-many-rating-percentage"}
          wrapperClasses={"px-4 pt-2 w-75"}
          headerTitleClasses={"fz-30"}
          subTitleClasses={"fz-12 m-0"}
          titleWithPercentage={''}
        />
        <div className="w-25 px-4 d-flex-center fj-end">
          <Button
            className="btns theme-propx solid"
            id="btn-save"
            onClick={() => setIsOpenConfirmChangesDialog(true)}
          >
            {t(`${translationPath}save-changes`)}
          </Button>
        </div>
      </div>
      <div className="property-rating-container">
        <div className="rating-percentage-view mt-2 mb-2">
          {state && state.propertyRatingTabScoresDto &&
            state.propertyRatingTabScoresDto.map((item, index) => (
              <div
                className="rating-percentage-section b-bottom mb-2 mt-2 w-75"
                key={`Percentage${index + 1}`}
              >
                <div className="pt-1 w-50">
                  <span className="fw-bold">
                    {t(`${translationPath}${item.propertyRatingTabName}`)}
                  </span>
                </div>
                <div className="pt-1 w-25">
                  <Inputs
                    idRef={`PercentageTabRef${index + 1}`}
                    endAdornment={
                      <InputAdornment position="end" className="px-2">
                        %
                      </InputAdornment>
                    }
                    type="number"
                    min={0}
                    max={100}
                    withNumberFormat
                    isAttachedInput
                    value={item.score}
                    onInputChanged={(e) => {
                      const value = isNaN(e.target.value)
                        ? 0
                        : parseInt(e.target.value);
                      UpdateRatingValues(index, value);
                    }}
                    onKeyUp={() => {
                      updateIfMaxValue(index, item.score);
                    }}
                  />
                </div>
              </div>
            ))}
          <div className="rating-percentage-section b-bottom mb-2 mt-2 w-75">
            <div className="pt-1 w-50">
              <span className="fw-bold">{t(`${translationPath}total`)}</span>
            </div>
            <div
              className="pt-1 d-flex d-flex-v-center fj-center w-25 fw-bold"
              style={{ color: "#475467" }}
            >
              {`${ratingPercentageTotal}%`}
            </div>
          </div>
        </div>
        { 
        isOpenConfirmChangesDialog && 
          <ConfirmChangesDialog
           parentTranslationPath={parentTranslationPath}
           translationPath={translationPath}
           isDialogOpen={isOpenConfirmChangesDialog}
           onSave={()=> {
            saveHandler(); 
            setIsOpenConfirmChangesDialog(false);

           }}
           onClose={()=> 
           {
            setIsOpenConfirmChangesDialog(false) ; 
            GetAllPropertyRatingTabScoresAPI();


           }}
          /> 
        }
      </div>
    </div>
  );
};
