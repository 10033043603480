export const TableFilterOperatorsEnum = {
  equal: {
    key: 1,
    value: 'is-equal',
    name: 'Is equal',
    filedType: 'textFiled',
    icon: 'mdi  mdi-equal'
  },
  contains: {
    key: 2,
    value: 'is-contains',
    name: 'Is contains',
    filedType: 'textFiled',
    icon: 'mdi mdi-contain'
  },
  greaterThan: {
    key: 3,
    value: 'is-greater-than-or-equal',
    name: 'Is greater than Or Equal',
    filedType: 'textFiled',
    icon: 'mdi mdi-chevron-double-up'
  },
  lessThan: {
    key: 4,
    value: 'is-less-than-or-equal',
    name: 'Is less than Or Equal',
    filedType: 'textFiled',
    icon: 'mdi mdi-chevron-double-down'
  },
  greaterThanDate: {
    key: 5,
    value: 'is-greater-than-or-equal',
    name: 'Is greater than date',
    filedType: 'date',
    icon: 'mdi mdi-chevron-double-up'
  },
  lessThanDate: {
    key: 6,
    value: 'is-less-than-or-equal',
    name: 'Is less than date',
    filedType: 'date',
    icon: 'mdi mdi-chevron-double-down'
  },
  startWith: {
    key: 7,
    value: 'is-start-with',
    name: 'Is start with',
    filedType: 'textFiled',
    icon: 'mdi mdi-contain-start'
  },
  endWith: {
    key: 8,
    value: 'is-end-with',
    name: 'Is end with',
    filedType: 'textFiled',
    icon: 'mdi mdi-contain-end'
  },
  between: {
    key: 9,
    value: 'is-between',
    name: 'Is between',
    filedType: 'textFiled',
    icon: 'mdi mdi-ray-vertex'
  },
  equalData: {
    key: 10,
    value: 'is-equal-date',
    name: 'Is equal date',
    filedType: 'date',
    icon: 'mdi mdi-equal'
  },
  notEqual: {
    key: 11,
    value: 'not-equal',
    name: 'not equal',
    filedType: 'textFiled',
    icon: 'mdi mdi-not-equal-variant'
  },
  notContains: {
    key: 12,
    value: 'not-contains',
    name: 'not contains',
    filedType: 'date',
    icon: 'mdi mdi-tray-remove'
  },
  isBlank: {
    key: 13,
    value: 'is-blank',
    name: 'Is Blank',
    filedType: 'date',
    icon: 'mdi mdi-checkbox-blank-circle-outline'
  },
  isNotBlank: {
    key: 14,
    value: 'is-not-blank',
    name: 'Is Not Blank',
    filedType: 'date',
    icon: 'mdi mdi-checkbox-blank-circle'
  },
  notEqualDate: {
    key: 15,
    value: 'not-equal-date',
    name: 'not equal date',
    filedType: 'date',
    icon: 'mdi mdi-not-equal-variant'
  },
  // greaterThan: {
  //   key: 16,
  //   value: 'is-greater-than',
  //   name: 'Is greater than',
  //   filedType: 'textFiled',
  //   icon: 'mdi mdi-chevron-double-up'
  // },
  // lessThanLess: {
  //   key: 16,
  //   value: 'is-less-than',
  //   name: 'Is less than',
  //   filedType: 'textFiled',
  //   icon: 'mdi mdi-chevron-double-down'
  // },
};

