import React, { useEffect, useState } from "react";
import "./PaymentDetailsView.scss";
import { PaymentInvoicesView } from "./PaymentInvoicesView/PaymentInvoicesView";
import { InvoiceDetailsView } from "./InvoiceDetailsView/InvoiceDetailsView";
import { InvoiceReceiptView } from "./InvoiceReceiptView/InvoiceReceiptView";
import { PaymentDetailsTabsData } from "./PaymentDetailsTabsData";
import { GetReservationInvoices } from "../../../../../Services";

export const PaymentDetailsView = ({
  backButton,
  onSelectedChanged,
  unitTransactionId,
  parentTranslationPath,
}) => {
  const translationPath = "PaymentDetails.";

  const [activeView, setActiveView] = useState(
    Object.values(PaymentDetailsTabsData)[0]
  );
  const [activeInvoiceId, setActiveInvoiceId] = useState(null);
  const [paymentInvoices, setPaymentInvoices] = useState([]);

  const getAllInvoices = async () => {
    const res = await GetReservationInvoices(unitTransactionId);
    if (!((res && res.data && res.data.ErrorId) || !res)){
      if(res?.length > 0) {
        setPaymentInvoices(res);
        const invoicesIds = res.map(item=> item.invoiceId) || [];
        onSelectedChanged({ id: 'invoicesIds', value: invoicesIds })
      } else {
        setPaymentInvoices([]);
        onSelectedChanged({ id: 'invoicesIds', value: [] })
      }
    } else setPaymentInvoices([]);
  };

  const activeInvoiceHandler = (Id) => {
    setActiveInvoiceId(Id);
  }

  const activeViewHandler = (viewItem) => {
    setActiveView(viewItem);
  };

  const backButtonHandler = () => {
    activeViewHandler(PaymentDetailsTabsData.PaymentInvoices);
    const isClickedResetValue = false;
    backButton.clickHandler(isClickedResetValue);
  };

  const reloadInvoices = () => {
    if (unitTransactionId) getAllInvoices();
  }

  useEffect(() => {
    if (unitTransactionId) getAllInvoices();
  }, [unitTransactionId]);

  useEffect(() => {
    if (backButton.isClicked) backButtonHandler();
  }, [backButton.isClicked]);

  return (
    <div className="Payment-Details-phase">
      {activeView.key == PaymentDetailsTabsData.PaymentInvoices.key ? (
        <PaymentInvoicesView
          activeInvoiceHandler={activeInvoiceHandler}
          unitTransactionId={unitTransactionId}
          activeViewHandler={activeViewHandler}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          paymentInvoices={paymentInvoices}
          reloadInvoices={reloadInvoices}
        />
      ) : null}
      {activeView.key == PaymentDetailsTabsData.InvoiceDetails.key ? (
        <InvoiceDetailsView
          activeInvoiceId={activeInvoiceId}
          activeViewHandler={activeViewHandler}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
        />
      ) : null}

      {activeView.key == PaymentDetailsTabsData.InvoiceReceipt.key ? (
        <InvoiceReceiptView
          activeInvoiceId={activeInvoiceId}
          unitTransactionId={unitTransactionId}
          activeViewHandler={activeViewHandler}
          reloadInvoices={reloadInvoices}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
        />
      ) : null}
    </div>
  );
};
