export const SchoolHeaderData = [
  {
    id: 1,
    label: "School ID",
    input: "id",
    isHiddenFilter: true,
    isSortable: false,
  },
  {
    id: 2,
    label: "School Name / ID",
    input: "schoolName",
    isHiddenFilter: true,
    isSortable: false,
  },
  {
    id: 3,
    label: "Curriculum Type",
    input: "curriculumType",
    isHiddenFilter: true,
    isSortable: false,
  },
  {
    id: 4,
    label: "Gender",
    input: "gender",
    isHiddenFilter: true,
    isSortable: false,
  },
  {
    id: 5,
    label: "School Grade",
    input: "schoolGrade",
    isHiddenFilter: true,
    isSortable: false,
  },
  {
    id: 6,
    label: "Inspection Result",
    input: "inspectionResult",
    isHiddenFilter: true,
    isSortable: false,
  },
  {
    id: 7,
    label: "irtiQaRatingId",
    input: "irtiQaRatingId",
    isHiddenFilter: true,
    isSortable: false,
  },
  {
    id: 8,
    label: "Years of Inspection",
    input: "yearsOfInspection",
    isHiddenFilter: true,
    isSortable: false,
  },
  {
    id: 9,
    label: "Language",
    input: "language",
    isHiddenFilter: true,
    isSortable: false,
  },
  {
    id: 10,
    label: "Max Fees",
    input: "maxFees",
    isHiddenFilter: true,
    isSortable: false,
  },
  {
    id: 11,
    label: "Min Fees",
    input: "minFees",
    isHiddenFilter: true,
    isSortable: false,
  },
  {
    id: 12,
    label: "School Cycle",
    input: "schoolCycle",
    isHiddenFilter: true,
    isSortable: false,
  },
  {
    id: 13,
    label: "School Email",
    input: "schoolEmail",
    isHiddenFilter: true,
    isSortable: false,
  },
  {
    id: 14,
    label: "School Number",
    input: "schoolNumber",
    isHiddenFilter: true,
    isSortable: false,
  },
  {
    id: 15,
    label: "Total Capacity",
    input: "totalCapacity",
    isHiddenFilter: true,
    isSortable: false,
  },
];
