import { config } from '../config';
import { HttpServices } from '../Helper';

export const MarkNotificationAsRead = async (notificationId, isRead) => {
  const result = await HttpServices.put(
    `${config.server_address}/CrmDfm/Activities/MarkNotificationAsRead?notificationId=${notificationId}&isRead=${isRead}`,
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};
