import React, { useCallback, useEffect, useState } from 'react';
import { SelectComponet, Tables } from '../../../../../../../../Components';
import { TableActions } from '../../../../../../../../Enums';
import Chip from '@material-ui/core/Chip';
import { GetUnitMPI, lookupItemsGetId } from '../../../../../../../../Services';
import { GetParams } from '../../../../../../../../Helper';
import headerData from './MPIViewHeaderData';
import './MPIView.scss';
import { useTranslation } from 'react-i18next';
import { StaticLookupsIds } from '../../../../../../../../assets/json/StaticLookupsIds';

const parentTranslationPath = 'UnitsProfileManagementView';

export const MPIView = ({ translationPath, type }) => {
  const { t } = useTranslation(parentTranslationPath);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState({
    unitMPIDataList: null,
    medianPrice: null,
  });
  const [list, setList] = useState([]);
  const [isListLoading, setIsListLoading] = useState(false);
  const getMPIList = async () => {
    setIsListLoading(true);
    const res = await lookupItemsGetId({
      lookupTypeId: StaticLookupsIds.MPI,
    });
    if (!(res && res.status && res.status !== 200)) {
      setList(res);
    }
    setIsListLoading(false);
  };
  const getUnitMPI = async () => {
    setIsLoading(true);
    const res = await GetUnitMPI({
      unitId: +GetParams('id'),
      operationTypeId:
        type === 'lease' ? StaticLookupsIds.Rent : StaticLookupsIds.Sales,
    });
    if (!(res && res.status && res.status !== 200)) {
      setData(res);
    }
    setIsLoading(false);
  };
  useEffect(() => {
    getUnitMPI();
    getMPIList();
  }, []);

  return (
    <div className='mt-5 p-3'>
      {data.medianPrice && (
        <div className='median-container p-3'>
          <Chip
            label={
              <p>
                <strong>{t('median')}: </strong>
                {`${new Intl.NumberFormat('en-US').format(data.medianPrice)} AED`}
              </p>
            }
          />
        </div>
      )}
      {data.unitMPIDataList?.length > 0 ? (
        <Tables
          data={data.unitMPIDataList}
          headerData={headerData}
          defaultActions={[]}
          isLoading={isLoading}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          itemsPerPage={data.unitMPIDataList?.length}
        />
      ) : (
        <>
          <h3>{t('no-match')}</h3>
          <SelectComponet
            data={list}
            parentTranslationPath={parentTranslationPath}
            translationPath={translationPath}
            valueInput={'lookupItemId'}
            textInput={'lookupItemName'}
            wrapperClasses={'w-25'}
            isLoading={isListLoading}
          />
        </>
      )}
    </div>
  );
};
