import React from "react";

function FilterIcon({ fill, ...restProps }) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill={fill ?? ""}
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <path
        d="M2.155 1.361c-.597.054-.954.272-1.178.719-.261.522-.161 1.016.323 1.6.115.139 1.148 1.302 2.295 2.583L5.68 8.594v2.511c0 2.791-.005 2.701.178 2.968a.951.951 0 0 0 .983.398c.218-.046 2.813-1.075 3.022-1.199a.976.976 0 0 0 .406-.527c.048-.154.051-.271.051-2.157V8.594l1.97-2.202c2.699-3.016 2.587-2.885 2.742-3.209a1.188 1.188 0 0 0-.009-1.103c-.131-.263-.296-.434-.525-.547-.405-.199.099-.184-6.338-.189-3.175-.002-5.878.005-6.005.017m11.632 1.332c0 .041-.329.414-2.477 2.814-1.103 1.232-2.041 2.292-2.084 2.357a1.714 1.714 0 0 0-.139.267l-.06.149-.014 1.972L9 12.224l-.986.395a39.79 39.79 0 0 1-1.007.394c-.011 0-.02-1.033-.02-2.296 0-2.471.001-2.452-.139-2.729-.08-.16-.078-.157-2.312-2.654-2.135-2.385-2.323-2.599-2.323-2.642 0-.016 2.158-.025 5.787-.025 3.765 0 5.787.009 5.787.026"
        fill-rule="evenodd"
        fill={fill ?? ""}
      />
    </svg>
  );
}

export default FilterIcon;
