
import { CreateActivity  , GetAllActivityTypesAPI } from '../Services'  ; 


const contactPreferenceType  = (action) => 
{
    if(action ===   'whatsapp'  || action === 'whatsappSolid' )
      return 'WhatsApp Communication' ; 

    if(action ===   'emailSolid'  || action === 'email' )
      return 'Email Communication' ; 

    if(action ===   'smsSolid'  || action === 'sms' )
      return 'SMS Communication' ; 

    if(action ===   'phoneSolid'  || action === 'phone' )
      return  null ; 
 
};

export const GetActivityType = async (activityTypeName) => 
{
    const search = contactPreferenceType(activityTypeName) ; 
    if(search)
    { 
     const res =  await GetAllActivityTypesAPI({ pageSize : 25 , pageIndex : 0 , search :  (search) }); 
      if (!(res && res.status && res.status !== 200)) 
        return (res && res.result && res.result.find(at => (at.activityTypeName === search)))
    }
      
    return  null ; 
};

export const CreateActivitiesWithContactPreference = async (action, leadId , userLogin ) =>  
{

   const user =  !userLogin ?  JSON.parse(localStorage.getItem('session')) :  userLogin  ;
   const activityType  = await GetActivityType(action); 

   if(activityType)
   {
    const  activityBody  = 
    {
         assignAgentId: (user && user.userId)  ,
         createdByName: (user && user.fullName) , 
         activityTypeId:  (activityType && activityType.activityTypeId ),
         subject: (activityType && activityType.activityTypeName),
         relatedLeadNumberId: leadId,
         isOpen: true,
         activityDate:  new Date(),
         activityReminders: [],
         comments: null,
    } 
    await CreateActivity(activityBody) ;
  }  

};

  
