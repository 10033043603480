import React, { useState } from "react";
import { Button } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { Inputs } from "../../../../Components";
import arrowLeft from "../../../../assets/images/icons/arrow-left.png";
import { VerifyApplicationUserCode } from "../../../../Services/LoginService";
import propxLogin from "../../../../assets/images/pages/login/propx-login.svg";
import { LoginFormsEnum } from './LoginFormsEnum';

export const OTPVerificationForm = ({
  setActiveLoginForm,
  userVerificationInfo,
  translationPath,
  parentTranslationPath,
}) => {
  const { t } = useTranslation(parentTranslationPath);
  const [verificationCodeDigits, setVerificationCodeDigits] = useState({
    digit1: null,
    digit2: null,
    digit3: null,
    digit4: null,
  });
  const handleOTPSubmit = async (event) => {
    event.preventDefault();

    const verificationCode = Object.values(verificationCodeDigits).reduce(
      (accumulator, item) => accumulator + item,
      ""
    );
    const response = await VerifyApplicationUserCode({
      identityVerificationId: +userVerificationInfo.identityVerificationId,
      code: verificationCode,
    });
    if (response) {
      setActiveLoginForm(LoginFormsEnum.ChangePassword.key);
    }
  };

  return (
    <div className="login-form-container">
      <div className="login-header">
        <img src={propxLogin} />
      </div>
      <h1 className="heading-1">{t(`${translationPath}otp-heading`)}</h1>
      <h5 className="heading-2 mb-4">
        <span>{t(`${translationPath}otp-description`)}</span>
        <span className="login-text-btn">{userVerificationInfo.email}</span>
      </h5>
      <div className="login-form">
        <form noValidate className="form-wrapper" onSubmit={handleOTPSubmit}>
          <div className="otp-container d-flex-v-center-h-between">
            <Inputs
              idRef="OTP1-Ref"
              wrapperClasses={`OTP-input ${
                verificationCodeDigits.digit1 ? "filled" : ""
              }`}
              inputPlaceholder={t(`${translationPath}zero`)}
              value={verificationCodeDigits.digit1 || ""}
              onInputChanged={(event) => {
                const digitValue = event.target.value;
                if (!isNaN(digitValue) && digitValue?.length <= 1) {
                  setVerificationCodeDigits((prevState) => ({
                    ...prevState,
                    digit1: digitValue,
                  }));
                }
              }}
            />
            <Inputs
              idRef="OTP2-Ref"
              wrapperClasses={`OTP-input ${
                verificationCodeDigits.digit2 ? "filled" : ""
              }`}
              inputPlaceholder={t(`${translationPath}zero`)}
              value={verificationCodeDigits.digit2 || ""}
              onInputChanged={(event) => {
                const digitValue = event.target.value;
                if (!isNaN(digitValue) && digitValue?.length <= 1) {
                  setVerificationCodeDigits((prevState) => ({
                    ...prevState,
                    digit2: digitValue,
                  }));
                }
              }}
              isDisabled={!verificationCodeDigits.digit1}
            />
            <Inputs
              idRef="OTP3-Ref"
              wrapperClasses={`OTP-input ${
                verificationCodeDigits.digit3 ? "filled" : ""
              }`}
              inputPlaceholder={t(`${translationPath}zero`)}
              value={verificationCodeDigits.digit3 || ""}
              onInputChanged={(event) => {
                const digitValue = event.target.value;
                if (!isNaN(digitValue) && digitValue?.length <= 1) {
                  setVerificationCodeDigits((prevState) => ({
                    ...prevState,
                    digit3: digitValue,
                  }));
                }
              }}
              isDisabled={!verificationCodeDigits.digit2}
            />
            <Inputs
              idRef="OTP4-Ref"
              wrapperClasses={`OTP-input ${
                verificationCodeDigits.digit4 ? "filled" : ""
              }`}
              inputPlaceholder={t(`${translationPath}zero`)}
              value={verificationCodeDigits.digit4 || ""}
              onInputChanged={(event) => {
                const digitValue = event.target.value;
                if (!isNaN(digitValue) && digitValue?.length <= 1) {
                  setVerificationCodeDigits((prevState) => ({
                    ...prevState,
                    digit4: digitValue,
                  }));
                }
              }}
              isDisabled={!verificationCodeDigits.digit3}
            />
          </div>
          <div className="d-flex-column-center">
            <Button className="login-btn" type="submit">
              {<span>{t(`${translationPath}Verify-OTP`)}</span>}
            </Button>
          </div>
          <div
            className="back-btn d-flex-center my-3P5"
            onClick={() => setActiveLoginForm(LoginFormsEnum.SignIn.key)}
          >
            <img src={arrowLeft} className="mr-1" />
            <span>{t(`${translationPath}back-to-login`)}</span>
          </div>
        </form>
      </div>
    </div>
  );
};
