import React from "react";
import clsx from "clsx";
import {
  CustomBadge,
  CustomCopyToClipboard,
} from "../../../../../../Components";
import { Box, Typography } from "@material-ui/core";
import {
  useIsArabicLayout,
  useSelectedTheme,
  useTranslate,
} from "../../../../../../Hooks";
import moment from "moment";
import {
  CornerDownLeft,
  CornerDownRight,
} from "../../../../../../assets/icons";

//styles
import useStyles from "./styles";

function TransactionHistoryItem({
  detailsTransaction,
  hideBorder,
  hideUnitType,
}) {
  const styles = useStyles();
  const { translate } = useTranslate("NewContactsView");

  const {
    theme: { palette },
  } = useSelectedTheme();

  const { isArabicLayout } = useIsArabicLayout();
  const CornerIcon = isArabicLayout ? CornerDownLeft : CornerDownRight;

  const formatedDateTime = (dateTime) => {
    const Date = dateTime
      ? moment(dateTime).locale("en").format("D MMM YYYY")
      : null;
    const Time = dateTime
      ? moment(dateTime).locale("en").format("h:mm a")
      : null;
    return { Date, Time };
  };

  return (
    <Box
      className={clsx(styles.containerCardTransactionItem, {
        [styles.cardwithPadding]: hideBorder,
        [styles.cardwithborder]: !hideBorder,
      })}
    >
      <Box className={styles.transactionInfoContainer}>
        <Box className={styles.topTransactionInfoBox}>
          <Box className={styles.transactionTypeBox}>
            <Typography variant="h6" className={styles.transactionTitleType}>
              {translate("transaction_type")}:
            </Typography>
            <strong className={styles.transactionTitleValue}>
              {detailsTransaction?.transactionType ||
                detailsTransaction?.status}
            </strong>
          </Box>
          <Box className={styles.transactionBoxId}>
            <Typography variant="h6" className={styles.transactionTitleId}>
              {translate("transaction_id")}:
            </Typography>
            <strong className={styles.transactionIdValue}>
              #
              {detailsTransaction?.transactionId ||
                detailsTransaction?.unitTransactionId}
            </strong>
          </Box>
        </Box>

        <Box className={styles.bottomTransactionInfoBox}>
          <Box className={styles.leadInfoBox}>
            <CornerIcon
              width="24"
              height="24"
              fill={palette.foreground.quarterary}
            />
            <Box className={styles.leadInfo}>
              <Box className={styles.containerLeadType}>
                <Typography
                  className={styles.leadTypeTitle}
                  variant="body1"
                  component="span"
                >
                  {translate("lead_type")}:
                </Typography>
                <CustomBadge
                  SizeVariant={"medium"}
                  label={detailsTransaction?.leadType || detailsTransaction?.unitType || ""}
                  Color={palette.utility["pink_700"]}
                  BorderColor={palette.utility["pink_50"]}
                  BackgroundColor={palette.utility["pink_50"]}
                  className={styles.leadTypeId}
                />
              </Box>
              <Box className={styles.containerLead}>
                <Typography className={styles.leadId}>
                  {translate("lead_Id")}:
                  <CustomCopyToClipboard
                    data={detailsTransaction?.leadId}
                    childrenData={"#" + detailsTransaction?.leadId}
                    copyDoneShowTime={1000}
                  />
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box className={styles.unitIdBox}>
            <CornerIcon
              width="24"
              height="24"
              fill={palette.foreground.quarterary}
            />
            <Box className={styles.containerUnit}>
              {!hideUnitType && (
                <Typography
                  className={styles.infoItem}
                  variant="body1"
                  component="span"
                >
                  {translate("unit-type-label")}{" "}
                  <strong className={styles.infoValue}>
                    {detailsTransaction.unitType}
                  </strong>
                </Typography>
              )}
              <Typography className={styles.unitId}>
                {translate("unit_id")}:
                <CustomCopyToClipboard
                  data={
                    detailsTransaction?.unitReferenceNo
                      ? detailsTransaction?.unitReferenceNo
                      : String(detailsTransaction?.unitId) || ""
                  } // copy
                  childrenData={
                    detailsTransaction?.unitReferenceNo
                      ? "#" + detailsTransaction?.unitReferenceNo
                      : "#" + String(detailsTransaction?.unitId) || ""
                  } // render
                  copyDoneShowTime={1000}
                />
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>

      <Box className={styles.timeInfo_Container}>
        <Box className={styles.containerTime_Date}>
          <Typography className={styles.DateItem}>
            {formatedDateTime(detailsTransaction?.createdOn).Date}
          </Typography>
          <Typography className={styles.TimeItem}>
            {formatedDateTime(detailsTransaction?.createdOn).Time}
          </Typography>
        </Box>
        <Typography
          className={styles.container_ProprtyName}
          variant="body1"
          component="span"
        >
          {translate("property_name")} :
          <strong className={styles.proprtyNameValue}>
            {detailsTransaction?.propertyName}
          </strong>
        </Typography>
      </Box>
    </Box>
  );
}

export default TransactionHistoryItem;
