import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { AutocompleteComponent } from "../../../../Components";
import { UnitsAdvanceSearchTest } from "../../../../Services";
import { UnitMapper } from "../../UnitsSalesView/UnitMapper/UnitMapper";

export const UnitNumberFilter = ({
  setResaleFilters,
  resaleFilters,
  resaleChangeFilters,
  parentTranslationPath,
  translationPath,
  selected , 
  setSelected , 
}) => {
  const { t } = useTranslation(parentTranslationPath);
  const [allSaleUnits, setAllSaleUnits] = useState({ result: [] });
  const searchTimer = useRef(null);
  const [isLoading, setIsLoading] = useState(false);

  const getAllUnits = async (search) => {
    setIsLoading(true);
    const filter = { pageIndex: 0, pageSize: 50 };
    const body = {
      OperationType :"Sale",
      criteria : search ?  {
        unit_number :  
        [{searchType: 2, value:  search || null }]
      }  : {},
      filterBy :  "createdOn" , 
      orderBy : 2 
      };
    const res = await UnitsAdvanceSearchTest(filter , body);
    if (!(res && res.status && res.status !== 200)) {
      const result = ((res && res.result) || []).map(
        (item) =>
          item.unitJson && UnitMapper(item, JSON.parse(item.unitJson).unit)
      );
      setAllSaleUnits({ result: result.filter((f) => f.unit_number) });
    } else setAllSaleUnits({ result: [] });
    setIsLoading(false);
  };

  return (
    <div className="w-50 m-1">
      <AutocompleteComponent
        idRef="unitsNumberRef"
        multiple={false}
        wrapperClasses="w-min-unset m-2 mr-1"
        inputPlaceholder={t(`${translationPath}select-unit-number`)}
        labelValue={t(`${translationPath}units-number`)}
        data={allSaleUnits?.result || []}
        selectedValues={selected.unitNumber}
        displayLabel={(option) => (option && option.unit_number) || ""}
        chipsLabel={(option) => option.unit_number || ""}
        withoutSearchButton
        onChange={(e, newValue) => {
          resaleChangeFilters(
            "unit_number",
            (newValue && newValue.unit_number) || ""
          );
          setSelected({id :'unitNumber'  , value : newValue })

        }}
        onInputKeyUp={(e) => {
          const { value } = e.target;
          if (searchTimer.current) clearTimeout(searchTimer.current);
          searchTimer.current = setTimeout(() => {
            getAllUnits(value);
          }, 700);
        }}
        onOpen={() => {
          if (
            allSaleUnits &&
            allSaleUnits.result &&
            allSaleUnits.result.length == 0
          )
            getAllUnits();
        }}
        filterOptions={(options) => options}
        parentTranslationPath={parentTranslationPath}
        translationPath={translationPath}
        isLoading={isLoading}
        withLoader
      />
    </div>
  );
};
