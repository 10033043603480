import React from "react";
import { DialogComponent } from "../../../../Components";
import { useTranslation } from "react-i18next";
import { DeleteAMlTransactionDocument } from "../../../../Services";
import { showError, showSuccess } from "../../../../Helper";

function ConfirmAMLDocumentDeleteDialog({
  isOpen,
  activeItem,
  setActiveItem,
  setOpenConfirmDialog,
  translationPath,
  parentTranslationPath,
}) {
  const { t } = useTranslation(parentTranslationPath);
  const onSaveClicked = async() => {
    const res = await DeleteAMlTransactionDocument(activeItem?.id);
    if (!(res && res.status && res.status !== 200)) {
        showSuccess(t("deleted-successfully"));
        setActiveItem({});
        onCloseClicked();
      } else {
        showError(t("error-while-deleting"));
      }
  };
  const onCloseClicked = () => {
    setOpenConfirmDialog(false);
  };

  return (
    <>
      <DialogComponent
        titleText={t("confirm-document-deletion")}
        saveText={t("confirm")}
        saveType="button"
        maxWidth="lg"
        onSaveClicked={() => {
          onSaveClicked();
        }}
        onCloseClicked={() => {
          onCloseClicked();
        }}
        onCancelClicked={() => {
          onCloseClicked();
        }}
        isOpen={isOpen}
        dialogContent={
          <>
            <h3>{t("delete-aml-document-confirm-message")}</h3>
          </>
        }
        translationPath={translationPath}
        parentTranslationPath={parentTranslationPath}
      />
    </>
  );
}

export default ConfirmAMLDocumentDeleteDialog;
