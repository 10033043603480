import React from 'react';
import moment from 'moment';
import Avatar from '@material-ui/core/Avatar';
import { getDownloadableLink, getFirstLastNameLetters } from '../../../../../../../../Helper';


export const CampaignTeamHeaderData = [
  {
    id: 1,
    label: 'name',
    input: 'name',
    isHiddenFilter: true,
    component: (item)=> <div className='info-container'>
        <Avatar className='avatars-wrapper team-avatar sm-theme'
        src={
          item.profileImg
            ? getDownloadableLink(item.profileImg)
            : ''
        }
        >
          {getFirstLastNameLetters(item.name)}
        </Avatar>
    <div className='top-label d-flex-column-center'>
      <p>{item.name || ''}</p>
      <p>{item.email || ''}</p>
    </div>
  </div>
  },
  {
    id: 2,
    label: 'role',
    input: 'campaignTaskUserType',
    isHiddenFilter: true,
  },
  {
    id: 2,
    label: 'date-added',
    input: 'createdOn',
    isHiddenFilter: true,
    component: (item)=> <span>{item.createdOn ? moment(item.createdOn).format('LL'): ''}</span>
  },
  
];
