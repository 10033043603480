
import React, { useState, useEffect, useCallback } from 'react';
import { PropertyPlanEnum } from '../../../../../../../../../Enums';
import { AutocompleteComponent } from '../../../../../../../../../Components';
import { lookupItemsGetId } from '../../../../../../../../../Services/LookupsServices';

export const PropertyPlanComponent = ({
  parentTranslationPath,
  translationPath,
  labelClasses , 
   isSubmitted,
   helperText,
   error,
   onUpdateValue , 
}) => {

  const [allPropertyPlan, setAllPropertyPlan] = useState([]);
  const GetAllPropertyPlan = useCallback(async () => {
    const res = await lookupItemsGetId({
      lookupTypeId: PropertyPlanEnum.lookupTypeId,
    });

    if (!(res && res.status && res.status !== 200)) {
      setAllPropertyPlan(res);
    } else
    setAllPropertyPlan([]);
  }, []);

  useEffect(() => {
    GetAllPropertyPlan();
  }, [GetAllPropertyPlan]);

  return (
    <div>
         <AutocompleteComponent
          idRef='PropertyPlanRef'
          labelValue='Property Plan'
          labelClasses={labelClasses}
          data={allPropertyPlan  || []}
          multiple={false}
          displayLabel={(option) => (option && option.lookupItemName) || ''}
          withoutSearchButton
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          onChange={(event, newValue) => {
           onUpdateValue(newValue);
          }}
          isSubmitted={isSubmitted}
          helperText={helperText}
          error={error}
          isWithError
      />



    
    </div>
  );
};