import React, {
  useCallback, useEffect, useRef, useState
} from 'react';
import PropTypes from 'prop-types';
import { InputAdornment } from '@material-ui/core';
import moment from 'moment';
import {
  AutocompleteComponent,
  DatePickerComponent,
  Inputs,
  RadiosGroupComponent,
  Spinner,
  DataFileAutocompleteComponent,
} from '../../../../../../../Components';
import { StaticLookupsIds } from '../../../../../../../assets/json/StaticLookupsIds';
import { floatHandler, getErrorByName } from '../../../../../../../Helper';
import {
  GetContacts,
  lookupItemsGetId,
  GetUserByApplicationUserId,
  OrganizationUserSearch,
  contactsDetailsGet,
  GetAllContactsByClassificationIds,
  SearchUsersByName

} from '../../../../../../../Services';
import { GetApplicationUserById } from '../../../../../../../Services/userServices';
import { AgentRoleEnum } from '../../../../../../../Enums/AgentRoleEnum';

const parentTranslationPath = 'UnitsStatusManagementView';
export const AgentInfoSaleRelatedComponent = ({
  state,
  selected,
  onSelectedChanged,
  schema,
  unitTransactionId,
  isSubmitted,
  onStateChanged,
  translationPath,
}) => {
  const path =
    window.location.pathname.split('/home/')[1] &&
      window.location.pathname.split('/home/')[1].split('/').length > 1 ?
      window.location.pathname.split('/home/')[1].split('/')[1] :
      window.location.pathname.split('/home/')[1];
    const [isUnitProfileReservation, setIsUnitProfileReservation] = useState(path === 'unit-profile-reservation');

  const [isLoading, setIsLoading] = useState(false);
  const searchTimer = useRef(null);
  const [loadings, setLoadings] = useState({
    agents: false,
    referrals: false,
    reservationTypes: false,
    transactedBy: false,
    externalAgencies: false,
  });
  const [filter] = useState({
    pageIndex: 0,
    pageSize: 10,
  });
  const [agents, setAgents] = useState([]);
  const [referrals, setReferrals] = useState([]);
  const [reservationTypes, setReservationTypes] = useState([]);
  const [transactedBy, setTransactedBy] = useState([]);
  const [externalAgencies, setExternalAgencies] = useState([]);
  const getAllAgents = useCallback(
    async (value, selectedValues) => {
      setLoadings((items) => ({ ...items, agents: true }));
      const res = await OrganizationUserSearch({
        ...filter,
        userTypeId: AgentRoleEnum.SaleAgent.value,
        userStatusId: 2,
        name: value,
      });
      if (!(res && res.status && res.status !== 200)) {
        setAgents(
          (selectedValues &&
            ((res && res.result && [...selectedValues, ...res.result,]) || [...selectedValues])) ||
          (res && res.result) ||
          []
        );
      } else setAgents([]);
      setLoadings((items) => ({ ...items, agents: false }));
    },
    [filter]
  );
  const getAllExternalAgencies = useCallback(
    async (value) => {
      setLoadings((items) => ({ ...items, externalAgencies: true }));
      const res = await GetAllContactsByClassificationIds({
        ...filter,
        userStatusId: 2,
        search: value,
        classificationIds:
          [
            20713,
            20714
          ]
      });
      if (!(res && res.status && res.status !== 200))
        setExternalAgencies((res && res.result) || []);
      else setExternalAgencies([]);
      setLoadings((items) => ({ ...items, externalAgencies: false }));
    },
    [filter]
  );
  const getAllReservationTypes = useCallback(async () => {
    setLoadings((items) => ({ ...items, reservationTypes: true }));
    const res = await lookupItemsGetId({
      lookupTypeId: StaticLookupsIds.ReservationType,
    });
    if (!(res && res.status && res.status !== 200)) setReservationTypes(res || []);
    else setReservationTypes([]);
    setLoadings((items) => ({ ...items, reservationTypes: false }));
  }, []);

  const getUserById = useCallback(async (id) => {
    setIsLoading(true);
    const res = await GetUserByApplicationUserId(id);
    setIsLoading(false);
    if (!(res && res.status && res.status !== 200)) return res;
    return null;
  }, []);

  const getUserByGUID = useCallback(async (id) => {
    setIsLoading(true);
    const res = await GetApplicationUserById(id);
    setIsLoading(false);
    if (!(res && res.status && res.status !== 200)) return res;
    return null;
  }, []);

  const getContactById = useCallback(async (id) => {
    setIsLoading(true);
    const res = await contactsDetailsGet({ id });
    setIsLoading(false);
    if (!(res && res.status && res.status !== 200)) return res;
    return null;
  }, []);

  const handleAgentsEditInit = useCallback(async () => {
    if (selected.agents && !selected.buyerAgent) {
      const buyerAgent = selected.agents.find((item) => item.isOriginalAgent == true) || null;
      const otherBuyerAgents = selected.agents.filter((item) => item.isOriginalAgent == false) || [];

      onSelectedChanged({ id: 'buyerAgent', value: buyerAgent });
      onSelectedChanged({ id: 'otherBuyerAgents', value: (otherBuyerAgents.length > 0) ? otherBuyerAgents : [] });
    }

    if (selected.listingAgents && !selected.sellerAgent) {
      const sellerAgent = selected.listingAgents.find((item) => item.isListingAgent == true) || null;
      const otherSellerAgents = selected.listingAgents.filter((item) => item.isListingAgent == false) || [];

      onSelectedChanged({ id: 'sellerAgent', value: sellerAgent });
      onSelectedChanged({ id: 'otherSellerAgents', value: (otherSellerAgents.length > 0) ? otherSellerAgents : [] });
    }
  }, [
    selected.agents,
    selected.listingAgents,
    selected.sellerAgent,
    selected.buyerAgent
  ]);

  const getBuyerReferralEditInit = useCallback(async () => {
    if (state.referralId && !selected.referral) {
        const res = await getUserById(state.referralId);
        if (res) {
          const selectedReferral = res;
          onSelectedChanged({ id: 'referral', value: selectedReferral });
          setReferrals((items) => {
            items.push(selectedReferral);
            return [...items];
          });
        } else onStateChanged({ id: 'referralId', value: null });
    }
  }, [
    referrals,
    selected.referral,
    state.referralId
  ]);
  const getSellerReferralEditInit = useCallback(async () => {
    if (state.sellerInternalReferralId && !selected.sellerReferral) {
        const res = await getUserById(state.sellerInternalReferralId);
        if (res) {
          const selectedSellerReferral = res;
          onSelectedChanged({ id: 'sellerReferral', value: selectedSellerReferral });

          setReferrals((items) => {
            items.push(selectedSellerReferral);
            return [...items];
          });
        } else onStateChanged({ id: 'sellerInternalReferralId', value: null });
    }
  }, [
    referrals,
    selected.referral,
    state.sellerInternalReferralId
  ]);
  const getTransactedByEditInit = useCallback(async () => {
    if (state.transactedById && !selected.transactedBy) {
        const res = await getUserByGUID(state.transactedById);
        if (res) {
          const selectedTransactedBy = res;
          onSelectedChanged({ id: 'transactedBy', value: selectedTransactedBy });
          setTransactedBy([selectedTransactedBy]);
        } else onStateChanged({ id: 'transactedById', value: null });
    }
  }, [
    state.transactedById,
    selected.transactedBy
  ]);

  const getEditInit = useCallback(async () => {
    if (state.reservationTypeId && !selected.reservationType && reservationTypes.length > 0) {
      const reservationTypeIndex = reservationTypes.findIndex(
        (item) => item.lookupItemId === state.reservationTypeId
      );
      if (reservationTypeIndex !== -1) {
        selected.reservationType = reservationTypes[reservationTypeIndex];
        if (onSelectedChanged) onSelectedChanged({ id: 'edit', value: selected });
      } else onStateChanged({ id: 'reservationTypeId', value: null });
    }
    if (state.externalAgencyId && !selected.externalAgency && externalAgencies.length > 0) {
      const externalAgencyIndex = externalAgencies.findIndex(
        (item) => item.contactsId === state.externalAgencyId
      );
      if (externalAgencyIndex !== -1)
        selected.externalAgency = externalAgencies[externalAgencyIndex];
      else {
        const res = await getContactById(state.externalAgencyId);
        if (res) {
          selected.externalAgency = res;

          setExternalAgencies((items) => {
            items.push(res);
            return [...items];
          });
        } else onStateChanged({ id: 'externalAgencyId', value: null });
      }
      if (onSelectedChanged) onSelectedChanged({ id: 'edit', value: selected });
    }
  }, [
    externalAgencies,
    onSelectedChanged,
    onStateChanged,
    state.externalAgencyId,
    state.reservationTypeId,
  ]);

  useEffect(() => {
    getAllAgents();
    getAllReservationTypes();
    getAllExternalAgencies();
  }, [
    getAllAgents,
    getAllExternalAgencies,
    getAllReservationTypes,
  ]);
  useEffect(() => {
    if (unitTransactionId) getEditInit();
  }, [getEditInit, unitTransactionId]);
  useEffect(() => {
    if (unitTransactionId) handleAgentsEditInit();
  }, [handleAgentsEditInit, unitTransactionId]);
  useEffect(() => {
    if (unitTransactionId) getBuyerReferralEditInit();
  }, [getBuyerReferralEditInit, unitTransactionId]);

  useEffect(() => {
    if (unitTransactionId) getSellerReferralEditInit();
  }, [getSellerReferralEditInit, unitTransactionId]);

  useEffect(() => {
    if (unitTransactionId) getTransactedByEditInit();
  }, [getTransactedByEditInit, unitTransactionId]);

  return (
    <div className='unit-status-agent-info-wapper childs-wrapper p-relative agent-info-sale-related-component-wrapper'>
      <Spinner isActive={isLoading} isAbsolute />
      <div className='form-item'>
        <AutocompleteComponent
          idRef='buyer-agentIdRef'
          labelValue='buyer-agent'
          selectedValues={selected.buyerAgent || {}}
          multiple={false}
          isDisabled
          data={selected.agents || []}
          displayLabel={(option) => option.fullName || ''}
          renderOption={(option) =>
            ((option.userName || option.fullName) && `${option.fullName} (${option.userName})`) ||
            ''}
          withoutSearchButton
          isLoading={loadings.agents}
          isSubmitted={isSubmitted}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
        />
      </div>
      <div className='form-item'>
        <AutocompleteComponent
          idRef='other-buyer-agentsIdRef'
          labelValue='other-buyer-agents'
          selectedValues={selected.otherBuyerAgents || []}
          multiple
          isDisabled
          data={selected.agents || []}
          displayLabel={(option) => option.fullName || ''}
          chipsLabel={(option) => option.fullName || ''}
          renderOption={(option) =>
            ((option.userName || option.fullName) && `${option.fullName} (${option.userName})`) ||
            ''}
          getOptionSelected={(option) => selected.otherBuyerAgents && selected.otherBuyerAgents.findIndex((item) => item.id === option.id) !== -1 || ''}
          withoutSearchButton
          isLoading={loadings.agents}
          isSubmitted={isSubmitted}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
        />
      </div>
      <div className='form-item'>
        <AutocompleteComponent
          idRef='seller-agentIdRef'
          labelValue='seller-agent'
          selectedValues={selected.sellerAgent || {}}
          multiple={false}
          data={selected.listingAgents || []}
          isDisabled
          displayLabel={(option) => option.fullName || ''}
          renderOption={(option) =>
            ((option.userName || option.fullName) && `${option.fullName} (${option.userName})`) ||
            ''}
          withoutSearchButton
          isLoading={loadings.agents}
          isSubmitted={isSubmitted}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
        />
      </div>
      <div className='form-item'>
        <AutocompleteComponent
          idRef='other-seller-agentIdRef'
          labelValue='other-seller-agents'
          selectedValues={selected.otherSellerAgents || []}
          multiple
          isDisabled
          data={selected.listingAgents || []}
          displayLabel={(option) => option.fullName || ''}
          chipsLabel={(option) => option.fullName || ''}
          renderOption={(option) =>
            ((option.userName || option.fullName) && `${option.fullName} (${option.userName})`) ||
            ''}
          getOptionSelected={(option) => selected.otherSellerAgents && selected.otherSellerAgents.findIndex((item) => item.id === option.id) !== -1 || ''}
          withoutSearchButton
          isLoading={loadings.agents}
          isSubmitted={isSubmitted}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
        />
      </div>
      <div className='form-item'>
        <RadiosGroupComponent
          idRef='contractRatifiedRef'
          labelValue='is-contract-ratified'
          data={[
            {
              key: true,
              value: 'yes',
            },
            {
              key: false,
              value: 'no',
            },
          ]}
          value={state.contractRatified}
          parentTranslationPath={parentTranslationPath}
          translationPathForData={translationPath}
          isDisabled={isUnitProfileReservation}
          translationPath={translationPath}
          labelInput='value'
          valueInput='key'
          onSelectedRadioChanged={(e, newValue) =>
            onStateChanged({ id: 'contractRatified', value: newValue === 'true' })}
        />
      </div>
      <div className='form-item'>
        <RadiosGroupComponent
          idRef='titleDeedTransferredRef'
          labelValue='is-transferred-deed'
          data={[
            {
              key: true,
              value: 'yes',
            },
            {
              key: false,
              value: 'no',
            },
          ]}
          value={state.titleDeedTransferred}
          isDisabled={isUnitProfileReservation}
          parentTranslationPath={parentTranslationPath}
          translationPathForData={translationPath}
          translationPath={translationPath}
          labelInput='value'
          valueInput='key'
          onSelectedRadioChanged={(event, newValue) =>
            onStateChanged({ id: 'titleDeedTransferred', value: newValue === 'true' })}
        />
      </div>
      <div className='form-item'>
        <RadiosGroupComponent
          idRef='mortgageRef'
          labelValue='is-mortgage'
          data={[
            {
              key: true,
              value: 'yes',
            },
            {
              key: false,
              value: 'no',
            },
          ]}
          value={state.mortgage}
          isDisabled={isUnitProfileReservation}
          parentTranslationPath={parentTranslationPath}
          translationPathForData={translationPath}
          translationPath={translationPath}
          labelInput='value'
          valueInput='key'
          onSelectedRadioChanged={(e, newValue) =>
            onStateChanged({ id: 'mortgage', value: newValue === 'true' })}
        />
      </div>
      <div className='form-item'>
        <AutocompleteComponent
          idRef='referralIdRef'
          labelValue='trans-description-buyer'
          selectedValues={selected.referral}
          multiple={false}
          data={referrals || []}
          displayLabel={(option) => option.firstName && (`${option.firstName} (${option.lastName})`) || ''}
          isDisabled={isUnitProfileReservation}
          renderOption={(option) =>
            option.firstName && (`${option.firstName} (${option.lastName})`) ||
            ''}
          getOptionSelected={(option) => option.applicationUserId === state.referralId}
          withoutSearchButton
          helperText={getErrorByName(schema, 'referralId').message}
          error={getErrorByName(schema, 'referralId').error}
          isLoading={loadings.referrals}
          isWithError
          isSubmitted={isSubmitted}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          onChange={(event, newValue) => {
            onSelectedChanged({ id: 'referral', value: newValue });
            onStateChanged({
              id: 'referralId',
              value: (newValue && newValue.applicationUserId) || null,
            });
          }}
        />
      </div>
      <div className='form-item'>
        <Inputs
          idRef='referralPercentageRef'
          labelValue='trans-percentage-description-buyer'
          value={state.referralPercentage || 0}
          helperText={getErrorByName(schema, 'referralPercentage').message}
          error={getErrorByName(schema, 'referralPercentage').error}
          isWithError
          isSubmitted={isSubmitted}
          isDisabled={isUnitProfileReservation}
          type='number'
          min={0}
          max={100}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          onInputChanged={(event) => {
            let value = +(floatHandler(event.target.value, 3));
            if (value > 100) value = 100;

            onStateChanged({ id: 'referralPercentage', value: parseInt(value) });
          }}
        />
      </div>
      <div className='form-item'>
        <AutocompleteComponent
          idRef='sellerInternalReferralIdRef'
          labelValue='trans-description-seller'
          selectedValues={selected.sellerReferral}
          multiple={false}
          data={referrals || []}
          displayLabel={(option) => option.firstName && (`${option.firstName} (${option.lastName})`) || ''}
          isDisabled={isUnitProfileReservation}
          renderOption={(option) => option.firstName && (`${option.firstName} (${option.lastName})`) || ''}
          getOptionSelected={(option) => option.applicationUserId === state.sellerInternalReferralId}
          withoutSearchButton
          helperText={getErrorByName(schema, 'sellerInternalReferralId').message}
          error={getErrorByName(schema, 'sellerInternalReferralId').error}
          isLoading={loadings.referrals}
          isWithError
          isSubmitted={isSubmitted}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          onChange={(event, newValue) => {
            onSelectedChanged({ id: 'sellerReferral', value: newValue });
            onStateChanged({
              id: 'sellerInternalReferralId',
              value: (newValue && newValue.applicationUserId) || null,
            });
          }}
        />
      </div>
      <div className='form-item'>
        <Inputs
          idRef='sellerInternalReferralPercentageRef'
          labelValue='trans-percentage-description-seller'
          value={state.sellerInternalReferralPercentage || 0}
          helperText={getErrorByName(schema, 'sellerInternalReferralPercentage').message}
          error={getErrorByName(schema, 'sellerInternalReferralPercentage').error}
          isWithError
          isSubmitted={isSubmitted}
          isDisabled={isUnitProfileReservation}
          type='number'
          min={0}
          max={100}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          onInputChanged={(event) => {
            let value = +(floatHandler(event.target.value, 3));
            if (value > 100) value = 100;
            onStateChanged({ id: 'sellerInternalReferralPercentage', value: parseInt(value) });
          }}
        />
      </div>
      <div className='form-item'>
        <DatePickerComponent
          idRef='contractRatifiedDateRef'
          labelValue='ratified-date'
          placeholder='DD/MM/YYYY'
          value={state.contractRatifiedDate}
          isDisabled={isUnitProfileReservation}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          onDateChanged={(newValue) => {
            onStateChanged({ id: 'contractRatifiedDate', value: newValue });
          }}
        />
      </div>
      <div className='form-item'>
        <Inputs
          idRef='contractRefNoRef'
          labelValue='contract-ref-no'
          value={state.contractRefNo || ''}
          helperText={getErrorByName(schema, 'contractRefNo').message}
          error={getErrorByName(schema, 'contractRefNo').error}
          isWithError
          isDisabled={isUnitProfileReservation}
          isSubmitted={isSubmitted}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          onInputChanged={(event) => {
            onStateChanged({ id: 'contractRefNo', value: event.target.value });
          }}
        />
      </div>
      <div className='form-item'>
        <Inputs
          idRef='financeCompRef'
          labelValue='finance-comp'
          value={state.financeComp || ''}
          helperText={getErrorByName(schema, 'financeComp').message}
          error={getErrorByName(schema, 'financeComp').error}
          isWithError
          isDisabled={isUnitProfileReservation}
          isSubmitted={isSubmitted}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          onInputChanged={(event) => {
            onStateChanged({ id: 'financeComp', value: event.target.value });
          }}
        />
      </div>
      <div className='form-item'>
        <DatePickerComponent
          idRef='transactionEntryDateRef'
          labelValue='transaction-entry-date'
          placeholder='DD/MM/YYYY'
          value={state.transactionEntryDate}
          minDate={moment().format('YYYY-MM-DDTHH:mm:ss')}
          helperText={getErrorByName(schema, 'transactionEntryDate').message}
          error={getErrorByName(schema, 'transactionEntryDate').error}
          isDisabled={isUnitProfileReservation}
          isSubmitted={isSubmitted}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          onDateChanged={(newValue) => {
            onStateChanged({ id: 'transactionEntryDate', value: newValue });
          }}
        />
      </div>
      <div className='form-item'>
        <AutocompleteComponent
          idRef='reservationTypeIdRef'
          labelValue='reservation-type'
          selectedValues={selected.reservationType}
          multiple={false}
          data={reservationTypes || []}
          displayLabel={(option) => option.lookupItemName || ''}
          getOptionSelected={(option) => option.lookupItemId === state.reservationTypeId}
          withoutSearchButton
          helperText={getErrorByName(schema, 'reservationTypeId').message}
          error={getErrorByName(schema, 'reservationTypeId').error}
          isWithError
          isLoading={loadings.reservationTypes}
          isDisabled={isUnitProfileReservation}
          isSubmitted={isSubmitted}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          onChange={(event, newValue) => {
            onSelectedChanged({ id: 'reservationType', value: newValue });
            onStateChanged({
              id: 'reservationTypeId',
              value: (newValue && newValue.lookupItemId) || null,
            });
          }}
        />
      </div>
      <div className='form-item'>
        <RadiosGroupComponent
          idRef='paymentToExternalAgencyRef'
          labelValue='is-payment-to-external-agency'
          data={[
            {
              key: true,
              value: 'yes',
            },
            {
              key: false,
              value: 'no',
            },
          ]}
          value={state.paymentToExternalAgency}
          isDisabled={isUnitProfileReservation}
          parentTranslationPath={parentTranslationPath}
          translationPathForData={translationPath}
          translationPath={translationPath}
          labelInput='value'
          valueInput='key'
          onSelectedRadioChanged={(e, newValue) => {
            if (state.externalAgencyId) {
              onStateChanged({ id: 'externalAgencyId', value: null });
              onSelectedChanged({ id: 'externalAgency', value: null });
            }
            onStateChanged({ id: 'paymentToExternalAgency', value: newValue === 'true' });
          }}
        />
      </div>
      {state.paymentToExternalAgency && (
        <div className='form-item'>
          <DataFileAutocompleteComponent
            idRef='externalAgencyIdRef'
            labelValue='external-agency'
            selectedValues={selected.externalAgency}
            isDisabled={isUnitProfileReservation}
            multiple={false}
            data={externalAgencies || []}
            displayLabel={(option) =>
              (option.contact &&
                (option.contact.first_name || option.contact.last_name) &&
                `${option.contact.first_name} ${option.contact.last_name}`) ||
              option.contact.company_name ||
              ''}
            // renderOption={(option) =>
            //   (option.contact && (
            //     <div className='d-flex-column'>
            //       <div className='d-flex-v-center-h-between w-100 texts-truncate'>
            //         {(option.contact.first_name || option.contact.last_name) && (
            //           <span>{`${option.contact.first_name} ${option.contact.last_name}`}</span>
            //         )}
            //         {option.contact.company_name && <span>{option.contact.company_name}</span>}
            //       </div>
            //       <span className='c-gray-secondary'>
            //         {(option.contact.contact_type_id === 1 &&
            //           (option.contact.mobile.phone || 'N/A')) ||
            //           option.contact.landline_number.phone ||
            //           'N/A'}
            //       </span>
            //     </div>
            //   )) ||
            //   'N/A'}
            getOptionSelected={(option) => option.contactsId === state.externalAgencyId}
            withoutSearchButton
            helperText={getErrorByName(schema, 'externalAgencyId').message}
            error={getErrorByName(schema, 'externalAgencyId').error}
            isWithError
            isSubmitted={isSubmitted}
            isLoading={loadings.externalAgencies}
            onInputKeyUp={(e) => {
              const { value } = e.target;
              if (searchTimer.current) clearTimeout(searchTimer.current);
              searchTimer.current = setTimeout(() => {
                getAllExternalAgencies(value);
              }, 700);
            }}
            parentTranslationPath={parentTranslationPath}
            translationPath={translationPath}
            onChange={(event, newValue) => {
              onSelectedChanged({ id: 'externalAgency', value: newValue });
              onStateChanged({
                id: 'externalAgencyId',
                value: (newValue && newValue.contactsId) || null,
              });
            }}
          />
        </div>

      )}
      {state.paymentToExternalAgency && (
        <div className='form-item'>
          <Inputs
            idRef='externalAgencyCommissionRef'
            labelValue='externalAgencyCommission'
            value={state.externalAgencyCommission}
            endAdornment={(
              <InputAdornment position='end' className='px-2'>
                %
              </InputAdornment>
          )}
            type='number'
            isDisabled={isUnitProfileReservation}
            min={0}
            max={100}
            parentTranslationPath={parentTranslationPath}
            translationPath={translationPath}
            onInputChanged={(event) => {
            let value = +(floatHandler(event.target.value, 3));
            if (value > 100) value = 100;

            onStateChanged({ id: 'externalAgencyCommission', value: parseInt(value) });
          }}
          />
        </div>

      )}

      <div className='form-item'>
        <AutocompleteComponent
          idRef='transactedByIdRef'
          labelValue='transacted-by-sale-description'
          selectedValues={selected.transactedBy}
          multiple={false}
          data={transactedBy || []}
          isDisabled={isUnitProfileReservation}
          displayLabel={(option) => option.fullName || ''}
          renderOption={(option) =>
            ((option.userName || option.fullName) && `${option.fullName} (${option.userName})`) ||
            ''}
          getOptionSelected={(option) => option.id === state.transactedById}
          withoutSearchButton
          helperText={getErrorByName(schema, 'transactedById').message}
          error={getErrorByName(schema, 'transactedById').error}
          isWithError
          isSubmitted={isSubmitted}
          isLoading={loadings.transactedBy}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          onChange={(event, newValue) => {
            onSelectedChanged({ id: 'transactedBy', value: newValue });
            onStateChanged({
              id: 'transactedById',
              value: (newValue && newValue.id) || null,
            });
          }}
        />
      </div>
      <div className='form-item'>
        <Inputs
          idRef='observationsRef'
          labelValue='observations'
          value={state.observations || ''}
          helperText={getErrorByName(schema, 'observations').message}
          error={getErrorByName(schema, 'observations').error}
          isDisabled={isUnitProfileReservation}
          isWithError
          isSubmitted={isSubmitted}
          multiline
          rows={4}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          onInputChanged={(event) => {
            onStateChanged({ id: 'observations', value: event.target.value });
          }}
        />
      </div>
      <div className='form-item'>
        <DatePickerComponent
          idRef='closingDateRef'
          labelValue='closing-date'
          placeholder='DD/MM/YYYY'
          value={state.closingDate}
          helperText={getErrorByName(schema, 'closingDate').message}
          error={getErrorByName(schema, 'closingDate').error}
          isDisabled={isUnitProfileReservation}
          isSubmitted={isSubmitted}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          onDateChanged={(newValue) => {
            onStateChanged({ id: 'closingDate', value: newValue });
          }}
        />
      </div>
    </div>
  );
};

AgentInfoSaleRelatedComponent.propTypes = {
  state: PropTypes.instanceOf(Object).isRequired,
  selected: PropTypes.instanceOf(Object).isRequired,
  schema: PropTypes.instanceOf(Object),
  unitTransactionId: PropTypes.number,
  onStateChanged: PropTypes.func.isRequired,
  onSelectedChanged: PropTypes.func.isRequired,
  isSubmitted: PropTypes.bool,
  parentTranslationPath: PropTypes.string.isRequired,
  translationPath: PropTypes.string.isRequired,
  isReadOnly: PropTypes.bool,
};
AgentInfoSaleRelatedComponent.defaultProps = {
  unitTransactionId: undefined,
  schema: undefined,
  isReadOnly: false,
  isSubmitted: false,
};
