import React, { useCallback, useEffect, useState } from 'react';
import { Tables } from '../../Components';
import { GetActivityNotificationHistory } from '../../Services';
export const ActivityNotificationsTab = ({
  filter,
  translationPath,
  parentTranslationPath,
  activeItem,
}) => {
  const [activityNotifications, setActivityNotifications] = useState([]);
  
  const getActivityNotificationHistory = useCallback(async (activityId) => {
    const res = await GetActivityNotificationHistory(activityId);
    if (!(res && res.status && res.status !== 200)) {
        setActivityNotifications(res);
    } else {
        setActivityNotifications([]);
    }
  }, []);

  useEffect(() => {
    if (activeItem) {
      getActivityNotificationHistory(activeItem.activityId);
    }
  }, [activeItem]);

  return (
    <div className='dialog-content-item w-100 px-4 py-3'>
      <Tables
        data={activityNotifications || []}
        headerData={[
          {
            id: 1,
            label: 'Recipient Name',
            input: 'recipientUserFullName',
          },
          {
            id: 2,
            label: 'Recipient Type',
            input: 'recipientTypeName',
          },
          {
            id: 3,
            label: 'Reminder Type',
            component: (item) => (
              <span>
                {(item.reminderType === 1 && 'Email') ||
                  (item.reminderType === 2 && 'SMS') ||
                  (item.reminderType === 3 && 'Notification')|| ''}
              </span>
            ),
          },
          {
            id: 4,
            label: 'Template Name',
            input: 'templateName',
          },
        ]}
        defaultActions={[]}
        parentTranslationPath={parentTranslationPath}
        translationPath={translationPath}
        totalItems={activityNotifications && activityNotifications.length}
        activePage={filter.pageIndex}
        itemsPerPage={filter.pageSize}
      />
    </div>
  );
};
