import React, {
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from "react";
import { GetParams, getErrorByName, showError } from "../../../../../../Helper";
import { StaticLookupsIds } from "../../../../../../assets/json/StaticLookupsIds";
import {
  GoogleMapsComponent,
  Inputs,
  SelectComponet,
  Spinner,
} from "../../../../../../Components";
import { useTranslation } from "react-i18next";
import {
  CreatePropertyVicinity,
  GetLatitudeAndLongitudeByProperty,
  GetLatitudeAndLongitudeByUnit,
  GetLookupItemsByLookupTypeName,
  UpdatePropertyVicinity,
  lookupItemsGetId,
} from "../../../../../../Services";
import {
  ButtonBase,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@material-ui/core";
import Joi from "joi";
import { accepts } from "react-dropzone-uploader";

export const VicinitiesManagementDialog = ({
  isDialogOpen,
  onClose,
  activeItem,
  parentTranslationPath,
  translationPath,
  reloadData,
}) => {
  const { t } = useTranslation(parentTranslationPath);
  const [location, setLocation] = useState(
    activeItem ? `${activeItem?.vicinityLat}, ${activeItem?.vicinityLong}` : ""
  );
  const [defaultCoordinations, setDefaultCoordinations] = useState();
  const [isLoading, setIsLoading] = useState({
    distance: false,
    categories: false,
    saveVicinity: false,
    map: true,
  });
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [data, setData] = useState({
    distance: [],
    categories: [],
  });
  const reducer = useCallback((state, action) => {
    if (action.id !== "edit") return { ...state, [action.id]: action.value };
    return {
      ...action.value,
    };
  }, []);
  const [state, setState] = useReducer(
    reducer,
    activeItem ?? {
      propertyId: +GetParams("id"),
      vicinityCatId: null,
      vicinityDistanceId: null,
      vicinityName: null,
      vicinityLat: null,
      vicinityLong: null,
      vicinityDesc: null,
    }
  );

  const getDistanceLookups = useCallback(async () => {
    setIsLoading((loadings) => ({ ...loadings, distance: true }));
    const res = await lookupItemsGetId({
      lookupTypeId: StaticLookupsIds.VicinityDistance,
    });
    if (!(res && res.status && res.status !== 200)) {
      setData((currentData) => ({ ...currentData, distance: res ?? [] }));
    }
    setIsLoading((loadings) => ({ ...loadings, distance: false }));
  }, []);
  const getCategoryLookups = useCallback(async () => {
    setIsLoading((loadings) => ({ ...loadings, categories: true }));
    const res = await GetLookupItemsByLookupTypeName({
      lookUpName: 'VicinityCategory',
      pageSize : 100,
      pageIndex: 1
    });
    if (!(res && res.status && res.status !== 200)) {
      setData((currentData) => ({
        ...currentData,
        categories: res.result.length > 0 ? res.result : currentData.categories,
      }));
    }
    setIsLoading((loadings) => ({ ...loadings, categories: false }));
  }, []);
  const submitHandler = async (e) => {
    setIsSubmitted(true);
    setIsLoading((loadings) => ({ ...loadings, saveVicinity: true }));
    e.preventDefault();
    try {
      if (!activeItem) {
        await CreatePropertyVicinity(state);
      } else {
        await UpdatePropertyVicinity(state);
      }
      reloadData();
      onClose();
    } catch {
      showError(t(`${translationPath}please-fill-all-Required-fields`));
    } finally {
      setIsLoading((loadings) => ({ ...loadings, saveVicinity: false }));
    }
  };
  const schema = useMemo(
    () =>
      Joi.object({
        vicinityCatId: Joi.number().required(),
        vicinityName: Joi.string().required(),
      })
        .options({
          abortEarly: false,
          allowUnknown: true,
        })
        .validate(state),
    [state]
  );
  const getPropertyCoordinations = async () => {
    try {
      if (!activeItem || !(state.vicinityLat && state.vicinityLong)) {
        const res = await GetLatitudeAndLongitudeByProperty(state.propertyId);
        if (res.latitude && res.longitude) {
          setDefaultCoordinations({
            lat: +res.latitude,
            lng: +res.longitude,
          });
          setLocation(`${res.latitude}, ${res.longitude}`);
        }
      } else {
        if (activeItem.latitude && activeItem.longitude) {
          setDefaultCoordinations({
            lat: +activeItem.latitude,
            lng: +activeItem.longitude,
          });
          setLocation(`${activeItem.latitude}, ${activeItem.longitude}`);
        }
      }
    } finally {
      setIsLoading((loadings) => ({ ...loadings, map: false }));
    }
  };
  useEffect(() => {
    getPropertyCoordinations();
    getDistanceLookups();
    getCategoryLookups();
  }, [getDistanceLookups, getCategoryLookups]);
  return (
    <div>
      <Dialog
        open={isDialogOpen}
        onClose={onClose}
        className="activities-management-dialog-wrapper unitsModelsFields"
        scroll="paper"
      >
        <Spinner
          isActive={
            isLoading.distance || isLoading.saveVicinity || isLoading.categories
          }
          isAbsolute
        />
        <form noValidate onSubmit={submitHandler}>
          <DialogTitle id="alert-dialog-title">
            {activeItem ? t("edit-vicinity") : t("add-vicinity")}
          </DialogTitle>
          <DialogContent dividers className="p-3">
            <Grid container>
              <Grid item xs={6}>
                <Grid xs={9} item className="dialog-content-item">
                  <Inputs
                    labelValue={"name"}
                    inputPlaceholder={"name"}
                    parentTranslationPath={parentTranslationPath}
                    translationPath={translationPath}
                    idRef="vicinityName"
                    value={state.vicinityName ?? ""}
                    onInputChanged={(e) =>
                      setState({ id: "vicinityName", value: e.target.value })
                    }
                    isSubmitted={isSubmitted}
                    helperText={getErrorByName(schema, "vicinityName").message}
                    error={getErrorByName(schema, "vicinityName").error}
                    isWithError
                  />
                </Grid>
                <Grid xs={9} item className="dialog-content-item">
                  <SelectComponet
                    valueInput="lookupItemId"
                    textInput="lookupItemName"
                    idRef={"vicinityCatId"}
                    data={data.categories}
                    isRequired
                    isLoading={isLoading.categories}
                    labelValue={"vicinityCatName"}
                    parentTranslationPath={parentTranslationPath}
                    translationPath={translationPath}
                    onSelectChanged={(value) =>
                      setState({ id: "vicinityCatId", value: +value })
                    }
                    value={state.vicinityCatId ?? ""}
                    isSubmitted={isSubmitted}
                    helperText={getErrorByName(schema, "vicinityCatId").message}
                    error={getErrorByName(schema, "vicinityCatId").error}
                    isWithError
                  />
                </Grid>
                <Grid item xs={9} className="dialog-content-item">
                  <SelectComponet
                    valueInput="lookupItemId"
                    textInput="lookupItemName"
                    idRef={"vicinityDistanceId"}
                    data={data.distance}
                    isRequired
                    isLoading={isLoading.distance}
                    labelValue={"vicinityDistanceName"}
                    onSelectChanged={(value) =>
                      setState({ id: "vicinityDistanceId", value: +value })
                    }
                    value={state.vicinityDistanceId ?? ""}
                    parentTranslationPath={parentTranslationPath}
                    translationPath={translationPath}
                  />
                </Grid>

                <Grid xs={9} item className="dialog-content-item">
                  <Inputs
                    parentTranslationPath={parentTranslationPath}
                    translationPath={translationPath}
                    labelValue={"vicinityDesc"}
                    inputPlaceholder={"vicinityDesc"}
                    multiline
                    rows={4}
                    idRef="vicinityDesc"
                    value={state.vicinityDesc ?? ""}
                    onInputChanged={(e) =>
                      setState({ id: "vicinityDesc", value: e.target.value })
                    }
                  />
                </Grid>
              </Grid>
              <Grid xs={6} item>
                {isLoading.map ? (
                  <Spinner isActive isSmall />
                ) : (
                  <GoogleMapsComponent
                    defaultCenter={defaultCoordinations}
                    location={location}
                    locations={
                      (state.vicinityLat &&
                        state.vicinityLong && [
                          {
                            latitude: state?.vicinityLat,
                            longitude: state?.vicinityLong,
                          },
                        ]) ||
                      []
                    }
                    onClick={(location) => {
                      setState({ id: "vicinityLat", value: location.lat });
                      setState({ id: "vicinityLong", value: location.lng });
                      setLocation(`${location.lat}, ${location.lng}`);
                    }}
                  />
                )}
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <ButtonBase
              className="btns theme-solid bg-cancel px-3"
              onClick={onClose}
              type="button"
            >
              {t("cancel")}
            </ButtonBase>
            <ButtonBase
              type="submit"
              className="btns theme-solid px-3"
              disabled={!!schema.error}
            >
              {t("save")}
            </ButtonBase>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
};
