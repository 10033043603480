export const TermOfPayment = {
  Annually: {
    name: 'Annually',
    value: 1,
  },
  BiAnnually: {
    name: 'BiAnnually',
    value: 2,
  },
  Monthly: {
    name: 'Monthly',
    value: 3,
  },
  Quarterly: {
    name: 'Quarterly',
    value: 4,
  },
};
