import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { DialogComponent } from '../../Components';
import { ImportDetailsView } from '../../Views/Home';

export const BlukUpLodeDialogComponent = ({
  translationPath,
  parentTranslationPath,
  isOpen, isOpenChanged, IdJop
}) => {
  const { t } = useTranslation([parentTranslationPath, 'Shared']);

  return (
    <div className='BlukUpLodeDialogComponent-container'>
      <DialogComponent
        titleText={t('Shared:ImportDetailsView')}
        saveText='confirm'
        saveType='button'
        maxWidth='lg'
        dialogContent={(
          <div className='d-flex-column-center'>
            <ImportDetailsView IdJop={IdJop} />
          </div>
        )}
        isOpen={isOpen}
        onCloseClicked={isOpenChanged}
        onCancelClicked={isOpenChanged}
        parentTranslationPath={parentTranslationPath}
        translationPath={translationPath}
      />
    </div>
  );
};
BlukUpLodeDialogComponent.propTypes = {
  translationPath: PropTypes.string,
  parentTranslationPath: PropTypes.string,
  isOpen: PropTypes.bool,
  isOpenChanged: PropTypes.func,
  IdJop: PropTypes.string,

};
BlukUpLodeDialogComponent.defaultProps = {
  translationPath: '',
  parentTranslationPath: '',
  isOpen: false,
  isOpenChanged: () => { },
  IdJop: null,

};
