import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { ButtonBase, Tooltip } from '@material-ui/core';
import { Inputs } from '../../../../../../../Components';
import { GetNextAvailableOrderInRotation } from '../../../../../../../Services/RotaionSchemaService/RotationSchemaService';
import { getErrorByName, showError, showSuccess } from '../../../../../../../Helper';

export const OrderComponent = ({
    parentTranslationPath,
    translationPath,
    onStateChanged,
    isSubmitted,
    schema,
    state,
}) => {
    const { t } = useTranslation(parentTranslationPath);
    const [isLoading, setIsLoading] = useState(false);
    const [nextAvailableOrder, setNextAvailableOrder] = useState(null);

    const getNextAvailableOrderInRotation = useCallback(async () => {
        setIsLoading(true);
        const res = await GetNextAvailableOrderInRotation();
        if (!(res && res.status && res.status !== 200)) {
            setNextAvailableOrder(res);
            showSuccess(t(`${translationPath}next-Available-Order`) + res);
        } else {
            showError(t(`${translationPath} something-went-wrong`));
            setNextAvailableOrder(null);
        }
        setIsLoading(false);
    });
    return (
      <>
        <div className='dialog-content-item order-wrapper'>
          <Inputs
            labelClasses='Requierd-Color'
            idRef='orderRef'
            type='number'
            min={nextAvailableOrder || 0}
            labelValue={t(`${translationPath}schemaOrder`)}
            value={state.order || ''}
            isSubmitted={isSubmitted}
            isWithError
            isLoading={isLoading}
            withLoader
            parentTranslationPath={parentTranslationPath}
            translationPath={translationPath}
            helperText={getErrorByName(schema, 'order').message}
            error={getErrorByName(schema, 'order').error}
            onInputChanged={(event) => {
                        onStateChanged({ id: 'order', value: event.target.value });
                    }}
          />
          <Tooltip title={t(`${translationPath}show-next-Available-Order-In-Rotation`)}>
            <ButtonBase id='getNextAvailableOrderInRotation' onClick={getNextAvailableOrderInRotation}>
              <span className='mdi mdi-eye-outline' />
            </ButtonBase>
          </Tooltip>
        </div>
      </>
    );
};

OrderComponent.propTypes = {
    schema: PropTypes.instanceOf(Object).isRequired,
    state: PropTypes.instanceOf(Object).isRequired,
    onStateChanged: PropTypes.func.isRequired,
    parentTranslationPath: PropTypes.string.isRequired,
    translationPath: PropTypes.string.isRequired,
    isSubmitted: PropTypes.bool.isRequired,
};
