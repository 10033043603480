



import React , { useState  , useCallback , useEffect , useRef } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { CheckboxesComponent , AutocompleteComponent  } from '../../../../../../../Components' ; 
import { OrganizationUserSearch } from '../../../../../../../Services' ;  


    
export const LeadsCreatedBy  = ({
  parentTranslationPath,
  translationPath,
  state,
  setState  , 
  checkAtLeastOneSelected , 
  checkAtLeastOneSelected2 , 
  setDefaultSelected, 

}) => {
    const { t } = useTranslation(parentTranslationPath);

    const searchTimer = useRef(null);
    let timer = null; 

    const [allUsers , setAllUsers] =  useState([]) ;
    const [isLoading ,   setIsLoading ] = useState(false) ;  

    const getAllOrganizationUserSearch = useCallback(async (searchValue) => {
      setIsLoading(true);
      const res = await OrganizationUserSearch({ pageIndex: 0, pageSize: 10, name: searchValue  , userStatusId : 2 });
      if (!(res && res.status && res.status !== 200)) 
      setAllUsers(res.result.map((item) => ({
        id :  item.id , 
        fullName : item.fullName  , 
        branch : item.branch  || null , 
      })));
       else 
       setAllUsers([]);  

       setIsLoading(false);

    }, []); 

    useEffect(() => {
      getAllOrganizationUserSearch() ;
    }, []); 
   
  return (
    <div className='content'>
    <div className='w-100 px-2 mt-2 checkBoxsPart'> 
       <div className='checkboxesItem'>
         <CheckboxesComponent
          idRef='LeadsCreatedByViewAllLeadsRef'
          label={t(`${translationPath}view-all-leads`)}
          parentTranslationPath={parentTranslationPath}
          translationPathForData={translationPath}
          singleChecked={state.leads.createdBy.allUsers}
          onSelectedCheckboxClicked={(e)=> {
            const checkIfCanSelected  = checkAtLeastOneSelected({ ...state.leads.createdBy ,  allUsers : e.target.checked } );

            if(checkAtLeastOneSelected2(state.leads.createdBy.specificUsers));
                else if(!checkIfCanSelected)
                  return ;
          
            const leadsCreatedBy =  { ...state.leads.createdBy ,
               allUsers : (e.target.checked)   , 
               myUser  : false  , 
               myTeamUsers : false , 
               myBusinessGroupUsers : false , 
               myBranchUsers :false , 
               myManageUsers : false ,
               specificUsers :[] ,
               myTeamLeadUsers : false
 

             } ;
          setState({id : 'leads' , value : { ...state.leads , createdBy :   leadsCreatedBy  } });
         }}
          />
         </div>
         <div className='checkboxesItem'>
           <CheckboxesComponent
             idRef='leadsCreatedByViewMyLeadsRef'
             label={t(`${translationPath}view-my-leads`)}
             parentTranslationPath={parentTranslationPath}
             translationPathForData={translationPath}
             singleChecked={state.leads.createdBy.myUser}
             onSelectedCheckboxClicked={(e)=> {
              const checkIfCanSelected  = checkAtLeastOneSelected({ ...state.leads.createdBy ,  myUser : e.target.checked });

              if(checkAtLeastOneSelected2(state.leads.createdBy.specificUsers));
              else if(!checkIfCanSelected)
                return ; 

              const leadsCreatedBy =  { ...state.leads.createdBy ,
                 allUsers : false  , 
                 myUser  : (e.target.checked)  , 
                 myTeamUsers : false , 
                 myBusinessGroupUsers : false ,
               } ;
            setState({id : 'leads' , value : { ...state.leads , createdBy :   leadsCreatedBy  } });
           }}
           
         />
         </div>
         <div className='checkboxesItem'>
         <CheckboxesComponent
          idRef='LeadsCreatedByViewMyTeamLeadsRef'
          label={t(`${translationPath}view-my-team-leads`)}
          parentTranslationPath={parentTranslationPath}
          translationPathForData={translationPath}
          singleChecked={state.leads.createdBy.myTeamUsers}
          onSelectedCheckboxClicked={(e)=> {
             const checkIfCanSelected  = checkAtLeastOneSelected({ ...state.leads.createdBy ,  myTeamUsers : e.target.checked } );
             
             if(checkAtLeastOneSelected2(state.leads.createdBy.specificUsers));
             else if(!checkIfCanSelected)
               return ;

            const leadsCreatedBy =  { ...state.leads.createdBy ,
               allUsers : false  , 
               myUser  :false  , 
               myTeamUsers :  (e.target.checked) , 
               myBusinessGroupUsers : false , 

             } ;
          setState({id : 'leads' , value : { ...state.leads , createdBy :   leadsCreatedBy  } });
         }}
         />
         </div>
        <div className='checkboxesItem'>
         <CheckboxesComponent
          idRef='LeadsCreatedByViewMyBusinessGroupRef'
          label={t(`${translationPath}view-my-business-group-leads`)}
          parentTranslationPath={parentTranslationPath}
          translationPathForData={translationPath}
          singleChecked={state.leads.createdBy.myBusinessGroupUsers}
          onSelectedCheckboxClicked={(e)=> {
             const checkIfCanSelected  = checkAtLeastOneSelected({ ...state.leads.createdBy ,  myBusinessGroupUsers : e.target.checked } );
             
             if(checkAtLeastOneSelected2(state.leads.createdBy.specificUsers));
             else if(!checkIfCanSelected)
               return ;

            const leadsCreatedBy =  { ...state.leads.createdBy ,
               allUsers : false  , 
               myUser  :false  , 
               myTeamUsers :  false, 
               myBusinessGroupUsers : (e.target.checked) , 
             } ;
          setState({id : 'leads' , value : { ...state.leads , createdBy :   leadsCreatedBy  } });
         }}
         />
         </div>

         <div className='checkboxesItem'>
          <CheckboxesComponent
            idRef='LeadsCreatedByViewMyBranchLeadsRef'
            label={t(`${translationPath}view-my-branch-leads`)}
            parentTranslationPath={parentTranslationPath}
            translationPathForData={translationPath}
            singleChecked={state.leads.createdBy.myBranchUsers}
            onSelectedCheckboxClicked={(e)=> {
              const checkIfCanSelected  = checkAtLeastOneSelected({ ...state.leads.createdBy ,  myBranchUsers : e.target.checked } );
              
              if(checkAtLeastOneSelected2(state.leads.createdBy.specificUsers));
             else if(!checkIfCanSelected)
               return ;

              const leadsCreatedBy =  { ...state.leads.createdBy ,
                 allUsers : false  , 
                 myBranchUsers : (e.target.checked)
               } ;
            setState({id : 'leads' , value : { ...state.leads , createdBy :   leadsCreatedBy  } });
           }}
          />
         </div>
         <div className='checkboxesItem'>
          <CheckboxesComponent
            idRef='LeadsCreatedByViewMyManageUsersLeadsRef'
            label={t(`${translationPath}view-my-Manage-leads`)}
            parentTranslationPath={parentTranslationPath}
            translationPathForData={translationPath}
            singleChecked={state.leads.createdBy.myManageUsers}
            onSelectedCheckboxClicked={(e)=> {
              const checkIfCanSelected  = checkAtLeastOneSelected({ ...state.leads.createdBy ,  myManageUsers : e.target.checked } );
             
              if(checkAtLeastOneSelected2(state.leads.createdBy.specificUsers));
             else if(!checkIfCanSelected)
                    return ;
                
              const leadsCreatedBy =  { ...state.leads.createdBy ,
                 allUsers : false  , 
                 myManageUsers : (e.target.checked)
               } ;
            setState({id : 'leads' , value : { ...state.leads , createdBy :   leadsCreatedBy  } });
           }}
          />
         </div>
         <div className='checkboxesItem'>
          <CheckboxesComponent
            idRef='LeadsCreatedByViewMyTeamLeadUsersRef'
            label={t(`${translationPath}view-my-team-lead-users`)}
            parentTranslationPath={parentTranslationPath}
            translationPathForData={translationPath}
            singleChecked={state.leads.createdBy.myTeamLeadUsers}
            onSelectedCheckboxClicked={(e)=> {
              const checkIfCanSelected  = checkAtLeastOneSelected({ ...state.leads.createdBy ,  myTeamLeadUsers : e.target.checked } );
             
              if(checkAtLeastOneSelected2(state.leads.createdBy.specificUsers));
             else if(!checkIfCanSelected)
                    return ;
                
              const leadsCreatedBy =  { ...state.leads.createdBy ,
                 allUsers : false  , 
                 myTeamLeadUsers : (e.target.checked)
               } ;
            setState({id : 'leads' , value : { ...state.leads , createdBy :   leadsCreatedBy  } });
           }}
          />
         </div>
         <div className='w-100 px-2 mt-2 mb-2'>
          <AutocompleteComponent
              idRef='leadsCreatedBySpecificsUsersRef'
              labelValue={t(`${translationPath}specific_users`)}
              data={ allUsers || []}
              selectedValues={state.leads.createdBy.specificUsers}
              multiple
              displayLabel={(option) =>
              (option && option.fullName) || ''}
              chipsLabel={(option) => (option && option.fullName) || ''}
              withoutSearchButton
              parentTranslationPath={parentTranslationPath}
              translationPath={translationPath}
              onInputKeyUp={(e) => {
                const { value } = e.target;
                 if (searchTimer.current) clearTimeout(searchTimer.current);
                   searchTimer.current = setTimeout(() => {
                    getAllOrganizationUserSearch(value) ;  
                }, 700); 
                }}
                renderOption={
                  ((option) =>
                  <div className='d-flex-column'>
                   <div className='d-flex-v-center-h-between w-100 texts-truncate'>
                    {option.fullName}
                   </div>
                   <span className='c-gray-secondary'> {option.branch} </span>
                 </div>
               )}
              value={state.leads.createdBy.specificUsers}
              onChange={(e, newValues) => 
              {
                let leadsCreatedBy = { ...state.leads.createdBy  , specificUsers : newValues };
                if( !newValues.length && setDefaultSelected({ ...state.leads.createdBy , specificUsers :false }))
                leadsCreatedBy = { ...state.leads.createdBy  , specificUsers : newValues , allUsers : true }
                  
                setState({ id: 'leads', value: { ...state.leads , createdBy  : leadsCreatedBy } })
              }}
             isLoading={isLoading} 
             withLoader
             filterOptions={(options) => {
              const isFind = (id) => state.leads.createdBy.specificUsers.findIndex((w) => w.id === id) === -1;
             return options.filter((w) => isFind(w.id));
            }}
            isDisabled={state.leads.createdBy.allUsers}
         /> 
        </div>


        
    </div>
  </div>
  );
};

LeadsCreatedBy.propTypes = {
  parentTranslationPath: PropTypes.string.isRequired,
  translationPath: PropTypes.string.isRequired,
  state:PropTypes.instanceOf(Object).isRequired,
  setState : PropTypes.func.isRequired,
  checkAtLeastOneSelected : PropTypes.func.isRequired,
  checkAtLeastOneSelected2 :PropTypes.func.isRequired, 
  setDefaultSelected:PropTypes.func.isRequired, 
};
