import React from "react";

function FilterNotEqualIcon({ fill, ...restProps }) {
  return (
    <svg
      viewBox="0 0 16 16"
      fill={fill ?? ""}
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <path
        d="M12.44 2.711c-.123.037-.232.139-1.428 1.331L9.718 5.333H6.49c-2.658 0-3.25.007-3.35.037a.677.677 0 0 0-.388.308c-.061.103-.072.153-.072.322 0 .171.011.219.074.326.083.142.212.25.359.303.081.028.596.036 2.687.036l2.586.002L7.053 8 5.72 9.333H4.491c-1.335 0-1.382.005-1.57.148a.822.822 0 0 0-.169.197c-.061.103-.072.153-.072.322 0 .171.011.219.074.326a.82.82 0 0 0 .167.196c.169.129.248.142.878.143l.586.002-.803.806c-.441.444-.825.849-.852.9-.035.066-.049.152-.049.294-.001.172.01.218.074.327.187.318.6.432.906.25.062-.037.676-.632 1.366-1.322l1.253-1.255 3.253-.002c2.648 0 3.272-.007 3.354-.036a.693.693 0 0 0 .359-.303c.063-.107.074-.155.074-.326 0-.169-.011-.219-.072-.322a.822.822 0 0 0-.169-.197c-.199-.152-.122-.148-2.903-.148H7.614L8.947 8l1.333-1.333 1.253-.002c.977 0 1.276-.009 1.354-.036a.693.693 0 0 0 .359-.303c.063-.107.074-.155.074-.326 0-.169-.011-.219-.072-.322a.822.822 0 0 0-.169-.197c-.168-.128-.287-.148-.903-.148h-.561l.803-.806c.441-.444.825-.849.852-.9.035-.066.049-.152.049-.294.001-.17-.01-.218-.071-.322a.681.681 0 0 0-.808-.3"
        fill={fill ?? ""}
      />
    </svg>
  );
}

export default FilterNotEqualIcon;
