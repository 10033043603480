
export const ResaleUnitsPermissions =
{
    ViewResaleUnits : {
         permissionsId: "7266c9d6-49df-48e3-7946-08dcaf947ea8",
         permissionsName: "View Resale Units",
         description: null,
         componentsId: null,
         components: null
    },
    AddResaleUnits :{
         permissionsId: "0e02888f-e19f-4562-7947-08dcaf947ea8",
         permissionsName: "Add Primary Units",
         description: null,
         componentsId: null,
         components: null
    },
    SendProposal :{
         permissionsId: "6bb9cbda-f257-4ad3-7948-08dcaf947ea8",
        permissionsName: "Send Proposal",
        description: null,
        componentsId: null,
        components: null
    },
    QuickTransaction : {
        permissionsId: "6a7a692d-b669-4516-7949-08dcaf947ea8",
        permissionsName: "Quick transaction",
        description: null,
        componentsId: null,
        components: null
    }
};