import React, { useCallback, useEffect, useReducer, useState } from "react";
import { DialogComponent, Spinner } from "../../../../Components";
import { useTranslation } from "react-i18next";
import {
  GetScopeCategoryDocuments,
  UploadTransactionDocuments,
} from "../../../../Services";
import { config } from "../../../../config";
import { getDownloadableLink, showSuccess } from "../../../../Helper";
import { MultipleTypesDocumentsUploader } from "../../../../SharedComponents";
import { ScopeDocumentEnum } from "../../../../Enums";

function ContactDocumentsFileUploader({
  contactId,
  isOpen,
  onCloseClicked,
  translationPath,
  parentTranslationPath,
  AMLTransactions,
  IDS,
}) {
  const { t } = useTranslation(parentTranslationPath);
  const reducer = useCallback((state, action) => {
    if (action.id !== "edit") return { ...state, [action.id]: action.value };
    return {
      ...action.value,
    };
  }, []);

  const [isUploading, setIsUploading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selected, setSelected] = useReducer(reducer, {
    IdDocuments: [],
    passportDocuments: [],
    KYCDocuments: [],
    EIDDocuments: [],
  });

  const passportCategoryLookup = {
    id: config.ContactDocsCategoryIds_Passport,

    name: "Passport",
  };
  const IdCategoryLookup = {
    id: config.ContactDocsCategoryIds_IDCard,

    name: "ID",
  };
  const EIDCategoryLookup = {
    id: config.ContactDocsCategoryIds_EID,

    name: "EID",
  };
  const KYCCategoryLookup = {
    id: config.ContactDocsCategoryIds_KYC,

    name: "KYC",
  };

  const onSaveClicked = async () => {
    setIsLoading(true);

    let IdDocuments = [
      ...(selected.IdDocuments.map((item) => ({
        fileId: item.uuid || item.fileId,
        fileName: item.fileName,
        documentLink: item?.url || "",
      })) || []),
    ];

    let passportDocuments = [
      ...(selected.passportDocuments.map((item) => ({
        fileId: item.uuid || item.fileId,
        fileName: item.fileName,
        documentLink: item?.url || "",
      })) || []),
    ];

    let KYCDocuments = [
      ...(selected.KYCDocuments.map((item) => ({
        fileId: item.uuid || item.fileId,
        fileName: item.fileName,
        documentLink: item?.url || "",
      })) || []),
    ];

    let EIDDocuments = [
      ...(selected.KYCDocuments.map((item) => ({
        fileId: item.uuid || item.fileId,
        fileName: item.fileName,
        documentLink: item?.url || "",
      })) || []),
    ];

    const body = {
      unitTransactionId: IDS,
      scopeId: AMLTransactions?.contactId,
      scopeTypeId: ScopeDocumentEnum?.Contact?.scopeTypeId,
      categoryFiles: [
        {
          categoryId: IdCategoryLookup.id,
          files: IdDocuments,
        },
        {
          categoryId: passportCategoryLookup.id,
          files: passportDocuments,
        },
        {
          categoryId: KYCCategoryLookup.id,
          files: KYCDocuments,
        },
        {
          categoryId: EIDCategoryLookup.id,
          files: EIDDocuments,
        },
      ],
    };

    const res = await UploadTransactionDocuments(body);
    if (!(res && res.status && res.status !== 200)) {
      onCloseClicked();
    } else {
    }

    setIsLoading(false);
  };

  return (
    <>
      <Spinner isAbsolute isActive={isLoading} />
      <DialogComponent
        titleText={t("contact-documents-upload")}
        saveText={t("confirm")}
        saveType="button"
        maxWidth="md"
        onSaveClicked={() => {
          onSaveClicked();
        }}
        onCloseClicked={() => {
          onCloseClicked();
        }}
        onCancelClicked={() => {
          onCloseClicked();
        }}
        isOpen={isOpen}
        dialogContent={
          <>
            <div className="mt-2">
              <MultipleTypesDocumentsUploader
                initUploadedFiles={selected?.IdDocuments ?? []}
                labelValue="Id-card-documents"
                uploadedChanged={(files) => {
                  setSelected({
                    id: "IdDocuments",
                    value: files,
                  });
                }}
                setIsUploading={setIsUploading}
                isUploading={isUploading}
                multiple
                isOpenGallery
                idRef="idDocumentsRef"
                viewUploadedFilesCount={false}
                openGallery
                isDocuments
                chipHandler={(value) => () => {
                  const link = document.createElement("a");
                  link.setAttribute("download", value.fileName);
                  link.href = getDownloadableLink(value.uuid);
                  document.body.appendChild(link);
                  link.click();
                  link.remove();
                }}
                parentTranslationPath={parentTranslationPath}
                translationPath={translationPath}
              />
            </div>
            <div className="mt-2">
              <MultipleTypesDocumentsUploader
                initUploadedFiles={selected?.passportDocuments || []}
                labelValue="passport-documents"
                uploadedChanged={(files) => {
                  setSelected({
                    id: "passportDocuments",
                    value: files,
                  });
                }}
                setIsUploading={setIsUploading}
                isUploading={isUploading}
                multiple
                isOpenGallery
                idRef="passportDocumentsRef"
                viewUploadedFilesCount={false}
                openGallery
                isDocuments
                chipHandler={(value) => () => {
                  const link = document.createElement("a");
                  link.setAttribute("download", value.fileName);
                  link.href = getDownloadableLink(value.uuid);
                  document.body.appendChild(link);
                  link.click();
                  link.remove();
                }}
                parentTranslationPath={parentTranslationPath}
                translationPath={translationPath}
              />
            </div>
            <div className="mt-2">
              <MultipleTypesDocumentsUploader
                initUploadedFiles={selected?.EIDDocuments || []}
                labelValue="EID-documents"
                uploadedChanged={(files) => {
                  setSelected({
                    id: "EIDDocuments",
                    value: files,
                  });
                }}
                multiple
                isOpenGallery
                idRef="EIDDocumentsRef"
                viewUploadedFilesCount={false}
                openGallery
                isDocuments
                chipHandler={(value) => () => {
                  const link = document.createElement("a");
                  link.setAttribute("download", value.fileName);
                  link.href = getDownloadableLink(value.uuid);
                  document.body.appendChild(link);
                  link.click();
                  link.remove();
                }}
                parentTranslationPath={parentTranslationPath}
                translationPath={translationPath}
              />
            </div>
            <div className="mt-2">
              <MultipleTypesDocumentsUploader
                initUploadedFiles={selected?.KYCDocuments || []}
                labelValue="KYC-documents"
                uploadedChanged={(files) => {
                  setSelected({
                    id: "KYCDocuments",
                    value: files,
                  });
                }}
                setIsUploading={setIsUploading}
                isUploading={isUploading}
                multiple
                isOpenGallery
                idRef="KYCDocumentsRef"
                viewUploadedFilesCount={false}
                openGallery
                isDocuments
                chipHandler={(value) => () => {
                  const link = document.createElement("a");
                  link.setAttribute("download", value.fileName);
                  link.href = getDownloadableLink(value.uuid);
                  document.body.appendChild(link);
                  link.click();
                  link.remove();
                }}
                parentTranslationPath={parentTranslationPath}
                translationPath={translationPath}
              />
            </div>
          </>
        }
      />
    </>
  );
}
export default ContactDocumentsFileUploader;
