import React, { useEffect } from 'react';
import { DialogComponent } from '../../../../../../../../../../../Components';
import { CreateUnitRequestToPublishOrUnPublish } from '../../../../../../../../../../../Services';
import { useTranslation } from 'react-i18next';
import { showError, showSuccess } from '../../../../../../../../../../../Helper';

export const NeedsApprovalDialog = ({
  isDialogOpen,
  setIsDialogOpen,
  loginResponse,
  state,
  onApprovalResponse,
  parentTranslationPath,
  translationPath,
}) => {
  const { t } = useTranslation(parentTranslationPath);
  const handleCreateUnitRequestToPublish = async () => {
    let typeOfRequest;
    let marketingData;

    if (state) {
      if (state.isForLease) {
        // Lease case
        marketingData = {
          ...state,
          isPublishUnitLease: !state.isPublishUnitLease,
        };
        typeOfRequest = state.isPublishUnitLease ? 2 : 1;
      } else {
        // Sale case
        marketingData = {
          ...state,
          isPublishUnitSale: !state.isPublishUnitSale,
        };
        typeOfRequest = state.isPublishUnitSale ? 2 : 1;
      }
    }
    const res = await CreateUnitRequestToPublishOrUnPublish({
      requesterUserId: loginResponse?.userId,
      userBranchId: loginResponse?.branchId,
      typeOfRequest,
      unitIds: [state?.unitId],
      comments: '',
      marketingData,
    });
    if (!(res && res.status && res.status !== 200)) {
      onApprovalResponse(res);
      showSuccess(t(`${translationPath}create-request-massage`));
      setIsDialogOpen(false);
      return res;
    }else {
      showError(t(`${translationPath}cannot-set-unit-as-published-without-price`))
      setIsDialogOpen(false);
    }
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };

  return (
    <>
      <DialogComponent
        parentTranslationPath={parentTranslationPath}
        translationPath={translationPath}
        isOpen={isDialogOpen}
        titleText={'Approval Required'}
        maxWidth={'sm'}
        saveType='button'
        saveClasses='btns theme-solid w-100 mx-2 mb-2'
        onSaveClicked={handleCreateUnitRequestToPublish}
        onCancelClicked={handleCloseDialog}
        onCloseClicked={handleCloseDialog}
        dialogContent={
          <div>
            <p>{t(`${translationPath}approval-message`)}</p>
          </div>
        }
      />
    </>
  );
};
