import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { DeleteBranchById } from '../../../../../../../Services';
import { DialogComponent, Spinner } from '../../../../../../../Components';
import { showError, showSuccess } from '../../../../../../../Helper';

const parentTranslationPath = 'UnitsView';
const translationPath = '';

export const BranchDeleteDocumentDialog = ({
  open,
  close,
  onSave,
  activeItem,
}) => {
  const { t } = useTranslation([parentTranslationPath, 'Shared']);
  const [isLoading, setIsLoading] = useState(false);

  const deleteHandler = async () => {
    if (!activeItem.branchDocumentId) {
      showSuccess(t(`${translationPath}delete-document-successfully`));
      onSave(activeItem?.fileId);
      setIsLoading(false);
      return;
    }

    setIsLoading(true);
    const res = await DeleteBranchById(activeItem?.branchDocumentId);
    if (!(res && res.status && res.status !== 200)) {
      showSuccess(t(`${translationPath}delete-document-successfully`));
      onSave(activeItem?.branchDocumentId);
    } else showError(t(`${translationPath}delete-document-failed`));
    setIsLoading(false);
  };

  return (
    <DialogComponent
      titleText={t(`${translationPath}delete-document`)}
      saveText={t('confirm')}
      saveType='button'
      maxWidth='sm'
      dialogContent={
        <div className='d-flex-column-center'>
          <Spinner isActive={isLoading} />
          <span className='mdi mdi-close-octagon c-danger mdi-48px' />
          <div className='w-100' style={{ display: 'block' }}>
            {' '}
            <span>{`${t('Are-you-sure-you-want-to-Delete')}`} :</span>{' '}
            <div style={{ fontWeight: 600, width: '130px' }}>
              <p
                style={{
                  fontWeight: 600,
                  width: '30rem',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              >
                {' '}
                {`${activeItem && activeItem.fileName}`}{' '}
              </p>{' '}
            </div>
          </div>
        </div>
      }
      saveClasses='btns theme-solid bg-danger w-100 mx-2 mb-2'
      isOpen={open}
      onSaveClicked={deleteHandler}
      onCloseClicked={close}
      onCancelClicked={close}
      translationPath={translationPath.confirm}
    />
  );
};
BranchDeleteDocumentDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  activeItem: PropTypes.instanceOf(Object).isRequired,
};
