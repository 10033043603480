import React from "react";
import clsx from "clsx";
import { Popover } from "@material-ui/core";
import PropTypes from "prop-types";

// styles
import useStyles from './styles';

function CustomPopover(props) {
  const { component, idRef, attachedWith, handleClose, popoverClasses, anchorOrigin,
    transformOrigin, style, themeClass, withBackdrop, customElevation,
  } = props;

  const styles = useStyles();

  let elevation;
  if (typeof customElevation === "number" ) {
    elevation = {
      elevation: customElevation,
    }
  }

  const open = Boolean(attachedWith);

  return (
    <Popover
      id={idRef || popoverClasses || themeClass || open || ""}
      open={open}
      anchorEl={attachedWith}
      onClose={handleClose}
      anchorOrigin={anchorOrigin}
      style={style}
      transformOrigin={transformOrigin}
      className={clsx(
        styles.popoverWrapper,
        popoverClasses,
        themeClass,
        { [styles.withBackdrop]: withBackdrop } // Apply `withBackdrop` conditionally
      )}

      {...elevation}
    >
      {component}
    </Popover>
  );
}

CustomPopover.propTypes = {
  component: PropTypes.oneOfType([
    PropTypes.elementType,
    PropTypes.func,
    PropTypes.node,
  ]).isRequired,
  idRef: PropTypes.string.isRequired,
  attachedWith: PropTypes.instanceOf(Object),
  handleClose: PropTypes.func,
  popoverClasses: PropTypes.string,
  themeClass: PropTypes.string,
  style: PropTypes.instanceOf(Object),
  anchorOrigin: PropTypes.shape({
    vertical: PropTypes.oneOf(["top", "bottom", "left", "right", "center"]),
    horizontal: PropTypes.oneOf(["top", "bottom", "left", "right", "center"]),
  }),
  transformOrigin: PropTypes.shape({
    vertical: PropTypes.oneOf(["top", "bottom", "left", "right", "center"]),
    horizontal: PropTypes.oneOf(["top", "bottom", "left", "right", "center"]),
  }),
  withBackdrop: PropTypes.bool,
  customElevation: PropTypes.number,
};

CustomPopover.defaultProps = {
  attachedWith: undefined,
  handleClose: undefined,
  popoverClasses: "",
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "center",
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "center",
  },
  style: undefined,
  themeClass: undefined,
  withBackdrop: false,
  customElevation: undefined,
};

export default CustomPopover;
