import React, { useEffect, useReducer, useState, useCallback } from "react";
import moment from "moment";
import "../MasterStyleDashborads/MasterStyleDashboards.scss";
import { useTranslation } from "react-i18next";
import { AdminLeadPool } from "../AdminLeadPool/AdminLeadPool";
import { AdminLeadFromPool } from "../AdminLeadFromPool";
import { DateRangePickerComponent } from "../../../../Components/Controls/DateRangePickerComponent/DateRangePickerComponent";
import { ButtonBase } from "@material-ui/core";

const parentTranslationPath = "LeadsView";

export const AdminLeadPoolCmp = () => {
  const { t } = useTranslation(parentTranslationPath);

  const dateRangeDefault = {
    startDate: null,
    endDate: null,
    key: "selection",
  };

  const [dateFilter, setDateFilter] = useState(dateRangeDefault);

  const reducer = (state, action) => {
    if (action.id !== "edit") return { ...state, [action.id]: action.value };
    if (action.id === "edit") {
      return {
        ...action.value,
      };
    }
    return undefined;
  };

  const [state, setState] = useReducer(reducer, {
    fromDate: null,
    toDate: null,
  });

  useEffect(() => {
    const currentState = {
      fromDate:
        (dateFilter.startDate &&
          moment(dateFilter.startDate)
            .startOf("day")
            .format("YYYY-MM-DDTHH:mm:ss")) ||
        null,
      toDate:
        (dateFilter.endDate &&
          moment(dateFilter.endDate)
            .endOf("day")
            .format("YYYY-MM-DDTHH:mm:ss")) ||
        null,
    };
    setState({ id: "edit", value: currentState });
  }, [dateFilter]);

  return (
    <div>
      <br/>
      <div style={{ display: "flex", justifyContent: "center" }}>

          <DateRangePickerComponent
            onClearClicked={() => {
              setDateFilter({
                startDate: null,
                endDate: null,
                key: "selection",
              });
            }}
            ranges={[dateFilter]}
            onDateChanged={(selectedDate) => {
              const newStartDate =
                selectedDate.selection && selectedDate.selection.startDate;
              const newEndDate = new Date(
                moment(
                  selectedDate.selection && selectedDate.selection.endDate
                ).endOf("day")
              );

              setDateFilter({
                startDate: newStartDate,
                endDate: newEndDate,
                key: "selection",
              });
              console.log("Date filter updated:", { newStartDate, newEndDate });
            }}
          />
      </div>

      <div className="MultiDashboards">
        <div className="MultiDashboardsCards">
          <AdminLeadPool
            startDatecmp2={state.fromDate}
            endDatecmp2={state.toDate}
          />
        </div>

        <div className="MultiDashboardsCards">
          <AdminLeadFromPool
            startDatecmp={state.fromDate}
            endDatecmp={state.toDate}
          />
        </div>
      </div>
    </div>
  );
};
