import React from "react";
import { TableFilterTypesEnum } from "../../../../../Enums";
import { checkIsColumnHiddenInConfig } from "../../../../../Helper";

export const LeadsSalesTableHeaderData = [
  {
    id: 1,
    label: "Lead Name",
    input: "contact_name",
    isHiddenFilter: false,
    isDefaultFilterColumn: true,
    isSortable: true,
    fieldType: "text",
    component: (item) => <span>{item && item.name}</span> || <span />,
  },
  {
    id: 2,
    label: "Lead Type",
    input: "leadClass",
    fieldType: "select",
    data: {
      enum: [
        { id: "Seller", name: "seller" },
        { id: "Buyer", name: "buyer" },
        { id: "Landlord", name: "landlord" },
        { id: "Tenant", name: "tenant" },
      ],
    },
    isHiddenFilter: false,
    isDefaultFilterColumn: true,
    isSortable: true,
  },
  {
    id: 3,
    label: "Created By",
    input: "createdBy",
    fieldKey: "createdBy",
    isHiddenFilter: false,
    isDefaultFilterColumn: true,
    isSortable: true,
    fieldType: "searchField",
    isHiddenFilter: false,
    isDefaultFilterColumn: true,
    isSortable: true,
    data: {
      type: "string",
      title: "Created By",
      description: "General Information",
      searchKey: "User",
      iconField: "",
      dependOn: "",
      multi: "false",
      default: "",
      isSearchable: false,
      searchableKey: "createdBy",
      displayPath: "createdBy",
    },
  },
  {
    id: 4,
    label: "Creation Date",
    input: "createdOn",
    fieldKey: "createdOn",
    fieldType: "alt-date",
    isDefaultFilterColumn: true,
    isHiddenFilter: false,
    isDate: true,
    isSortable: true,
    dateFormat: "DD/MM/YYYY - hh:mm A",
  },
  {
    id: 5,
    label: "Updated By",
    input: "updatedBy",
    fieldKey: "updatedBy",
    isHiddenFilter: false,
    isDefaultFilterColumn: true,
    isSortable: true,
    fieldType: "searchField",
    data: {
      type: "string",
      title: "Updated By",
      description: "General Information",
      searchKey: "User",
      iconField: "",
      dependOn: "",
      multi: "false",
      default: "",
      isSearchable: false,
      searchableKey: "updatedBy",
      displayPath: "updatedBy",
    },
  },

  {
    id: 6,
    label: "Creation Time",
    input: "createdOn",
    fieldKey: "creationTime",
    isDefaultFilterColumn: true,
    isHiddenFilter: true,
    isDate: true,
    fieldType: "alt-date",
    isSortable: true,
    dateFormat: "hh:mm A",
  },
  {
    id: 7,
    label: "Progress",
    input: "data_completed",
    textInputType: "number",
    fieldKey: "data_completed",
    textInputMax: 100,
    textInputMin: 0,
    fieldType: "number",
    isHiddenFilter: true,
    isDefaultFilterColumn: true,
    isNumber: true,
    isSortable: true,
    component: (item) =>
      <span>{item && item.progressWithPercentage}</span> || <span />,
  },
  {
    id: 8,
    label: "Lead Assigned Date",
    input: "assignDate",
    fieldKey: "leadAssignedDate",
    fieldType: "alt-date",
    isDefaultFilterColumn: true,
    isHiddenFilter: false,
    isDate: true,
    isSortable: true,
    dateFormat: "DD/MM/YYYY - hh:mm A",
  },
  {
    id: 9,
    label: "Last Activity Date",
    input: "lastActivityDate",
    fieldKey: "lastActivityDate",
    isDefaultFilterColumn: true,
    fieldType: "alt-date",
    isHiddenFilter: false,
    isDate: true,
    isSortable: true,
    dateFormat: "DD/MM/YYYY - hh:mm A",
  },

  {
    id: 10,
    label: "Activity Created By",
    input: "lastActivityCreatedByName",
    fieldKey: "activityCreatedBy",
    isHiddenFilter: false,
    isDefaultFilterColumn: true,
    isSortable: true,
    fieldType: "searchField",
    data: {
      type: "string",
      title: "Created By",
      description: "General Information",
      searchKey: "User",
      iconField: "",
      dependOn: "",
      multi: "false",
      default: "",
      isSearchable: false,
      searchableKey: "activityCreatedBy",
      displayPath: "activityCreatedBy",
    },
  },

  {
    id: 11,
    label: "Activity Remarks",
    input: "lastActivityComment",
    isHiddenFilter: true,
    isDefaultFilterColumn: true,
    isSortable: true,
    fieldType: "text",
  },
  {
    id: 12,
    label: "Last Activity Type Name",
    input: "lastActivtyTypeName",
    fieldKey: "lastActivityTypeName",
    isHiddenFilter: false,
    isDefaultFilterColumn: true,
    isSortable: true,
    fieldType: "searchField",
    data: {
      type: "string",
      title: "Last Activty Type Name",
      description: "General Information",
      searchKey: "activtyType",
      iconField: "",
      dependOn: "",
      multi: "false",
      default: "",
      isSearchable: false,
      searchableKey: "lastActivtyTypeName",
      displayPath: "lastActivtyTypeName",
    },
  },
  {
    id: 13,
    label: "Size Area From",
    input: "sizeAreaFrom",
    fieldKey: "sizeAreaFrom",
    isDefaultFilterColumn: true,
    isHiddenFilter: false,
    isSortable: true,
    isNumber: true,
    fieldType: "number",
  },
  {
    id: 14,
    label: "Size Area To",
    input: "sizeAreaTo",
    fieldKey: "sizeAreaTo",
    isDefaultFilterColumn: true,
    isHiddenFilter: false,
    isSortable: true,
    isNumber: true,
    fieldType: "number",
  },
  {
    id: 15,
    label: "Budget From",
    input: "budgetFrom",
    fieldKey: "budgetFrom",
    isDefaultFilterColumn: true,
    isHiddenFilter: false,
    isSortable: true,
    isNumber: true,
    fieldType: "number",
  },
  {
    id: 16,
    label: "Budget To",
    input: "budgetTo",
    fieldKey: "budgetTo",
    isDefaultFilterColumn: true,
    isHiddenFilter: false,
    isSortable: true,
    isNumber: true,
    fieldType: "number",
  },
  {
    id: 17,
    label: "isBulk",
    input: "bulkUpload",
    isDefaultFilterColumn: true,
    isHiddenFilter: false,
    isSortable: false,
    fieldType: "select",
    data: { enum: ["Yes", "No"] },
    component: (item) =>
      <span>{item && item.bulkUpload ? "Yes" : "No"}</span> || "",
  },
  {
    id: 18,
    label: "CampaignID",
    input: "PropertyCampaignId",
    isDefaultFilterColumn: true,
    isHiddenFilter: false,
    isSortable: false,
    fieldType: "text",
    component: (item) =>
      (
        <span>
          {item && item.campaign_name && item.campaign_name.propertyCampaignId}
        </span>
      ) || "",
  },
  {
    id: 19,
    label: "PSI Opportunity ",
    input: "psi_opportunity",
    component: (item) => <span>{item?.psi_opportunity}</span> || "",
    isHiddenFilter: false,
    isDefaultFilterColumn: true,
    isSortable: false,
    fieldType: "select",
    data: { enum: ["Yes", "No"] },
  },
  {
    id: 20,
    label: "Leads Pool Availability",
    input: "leadsPoolAvailability",
    component: (item) =>
      <span>{item?.leadsPoolAvailability === "yes" ? "Yes" : "No"}</span> || "",
    isHiddenFilter: false,
    isDefaultFilterColumn: true,
    isSortable: false,
    fieldType: "select",
    data: { enum: ["yes", "no"] },
  },
  {
    id: 21,
    label: "Leads Pool Source",
    input: "leadsPoolSource",
    component: (item) =>
      <span>{item?.leadsPoolSource === "yes" ? "Yes" : "No"}</span> || "",
    isHiddenFilter: false,
    isDefaultFilterColumn: true,
    isSortable: false,
    fieldType: "select",
    data: { enum: ["yes", "no"] },
  },
  {
    id: 22,
    label: "Lead Score",
    input: "score",
    component: (item) =>
      <span>{item?.averageLeadScore ? item?.averageLeadScore : "N/A"}</span> ||
      "",
    isHiddenFilter: false,
    isDefaultFilterColumn: true,
    isSortable: false,
    isNumber: true,
    fieldType: "number",
  },
  {
    id: 23,
    label: "Main Consent",
    input: "mainConsent",
    component: (item) =>
      <span>{item?.mainConsent ? item.mainConsent : "-"}</span> || "",
    isHiddenFilter: false,
    isDefaultFilterColumn: false,
    isSortable: true,
    fieldType: "select",
    data: {
      lookupName: "ContactConsent",
    },
  },
  {
    id: 24,
    label: "Assets Consent",
    input: "assetsConsent",
    component: (item) =>
      <span>{item?.assetsConsent ? item.assetsConsent : "-"}</span> || "",
    isHiddenFilter: false,
    isHidden: checkIsColumnHiddenInConfig({ columnName: "assetsConsent" }),
    isDefaultFilterColumn: false,
    isSortable: true,
    fieldType: "select",
    data: {
      lookupName: "ContactConsent",
    },
  },
  {
    id: 25,
    label: "DNCR",
    input: "dncr",
    component: (item) => <span>{item?.dncr ? item.dncr : "-"}</span> || "",
    isHiddenFilter: false,
    isDefaultFilterColumn: false,
    isSortable: true,
    fieldType: "select",
    data: {
      lookupName: "ContactDNCR",
    },
  },
  {
    id: 26,
    label: "angry Bird",
    input: "angryBird",
    isDefaultFilterColumn: true,
    isHiddenFilter: false,
    isSortable: false,
    fieldType: "select",
    data: { enum: ["Yes", "No"] },
    component: (item) =>
      <span>{item && item.angryBird ? "Yes" : "No"}</span> || "",
  },
  {
    id: 27,
    label: "critical contact class",
    input: "highProfile",
    isDefaultFilterColumn: true,
    isHiddenFilter: false,
    isSortable: false,
    fieldType: "select",
    data: { enum: ["Yes", "No"] },
    component: (item) =>
      <span>{item && item.highProfile ? "Yes" : "No"}</span> || "",
  },
];
