import React from "react";

function GridView({ fill, ...restProps }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill={fill ?? ""}
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <path
        d="M3.686 2.04a1.943 1.943 0 0 0-1.625 1.541c-.088.412-.088 5.426 0 5.838.086.408.237.685.536.984.299.299.576.45.984.536.412.088 5.426.088 5.838.001.399-.085.683-.241.982-.539.298-.299.454-.583.539-.982.087-.412.087-5.426 0-5.838-.148-.696-.612-1.218-1.305-1.469L9.38 2.02l-2.74-.006c-1.507-.003-2.836.008-2.954.026m11 0a1.943 1.943 0 0 0-1.625 1.541c-.088.412-.088 5.426 0 5.838.086.408.237.685.536.984.299.299.576.45.984.536.412.088 5.426.088 5.838.001.399-.085.683-.241.982-.539.298-.299.454-.583.539-.982.087-.412.087-5.426-.001-5.838-.148-.7-.607-1.217-1.304-1.469l-.255-.092-2.74-.006c-1.507-.003-2.836.008-2.954.026M9 6.5V9H4V4h5v2.5m11 0V9h-5V4h5v2.5M3.686 13.04a1.943 1.943 0 0 0-1.625 1.541c-.088.412-.088 5.426 0 5.838.086.408.237.685.536.984.299.299.576.45.984.536.412.088 5.426.088 5.838.001.398-.085.682-.24.984-.539.299-.297.45-.572.536-.982.088-.412.088-5.426.001-5.838-.148-.696-.612-1.218-1.305-1.469l-.255-.092-2.74-.006c-1.507-.003-2.836.008-2.954.026m11 0a1.943 1.943 0 0 0-1.625 1.541c-.088.412-.088 5.426 0 5.838.086.408.237.685.536.984.299.299.576.45.984.536.412.088 5.426.088 5.838.001.399-.085.683-.241.982-.539.298-.299.454-.583.539-.982.087-.412.087-5.426 0-5.838-.148-.696-.612-1.218-1.305-1.469l-.255-.092-2.74-.006c-1.507-.003-2.836.008-2.954.026M9 17.5V20H4v-5h5v2.5m11 0V20h-5v-5h5v2.5"
        fill-rule="evenodd"
        fill={fill ?? ""}
      />
    </svg>
  );
}

export default GridView;
