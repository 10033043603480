export const LeadsPriorityEnum = {
  Cold: {
    key: 'Cold',
    value: 'low',
    icon: 'mdi mdi-arrow-down',
  },
  Warm: {
    key: 'Warm',
    value: 'medium',
    icon: 'mdi mdi-arrow-right',
  },
  Hot: {
    key: 'Hot',
    value: 'high',
    icon: 'mdi mdi-arrow-up',
  },
};
