import React, {
    useState, useCallback, useReducer, useEffect
} from 'react';
import PropTypes from 'prop-types';
import Joi from 'joi';
import { useTranslation } from 'react-i18next';
import { DialogComponent, AutocompleteComponent } from '../../../../../../Components';
import { GetAllUnitVideos, UpdateUnitVideo, GetLookupItemsByLookupTypeName } from '../../../../../../Services';
import { GetParams, showError, showSuccess, getErrorByName } from '../../../../../../Helper';
import { Spinner } from '../../../../../../Components/SpinnerComponent/Spinner';
import './UnitsUploaderDialog.scss'
import { DocumentsUploader } from '../../../../../../SharedComponents/DocumentsUploader/DocumentsUploader'
import { UnitVideoCategory } from '../../../../../../assets/json/StaticLookupsNames.json';

export const UnitsUploaderDialog = ({
    onSave,
    activeItem,
    isOpen,
    isOpenChanged,
    parentTranslationPath,
    translationPath,
    reloadData,
    setActiveItem,
    selected,
    setSelected,
    data,
}) => {
    const reducer = useCallback((state, action) => {
        if (action.id !== 'edit') return { ...state, [action.id]: action.value };
        return {
            ...action.value,
        };
    }, []);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [lookuosData, setLookuosData] = useState([]);

    const { t } = useTranslation([parentTranslationPath, 'Shared']);
    const [dialogName, setDialogName] = useState(false);
    const [loading, setLoading] = useState(false);

    const initialState = {
        unitId: +GetParams('id'),
        categoryId: null,
        files: [],
        unitVideoTypeId: 1
    }
    const [state, setState] = useReducer(reducer, initialState);
    // const isCategoryUsed = (categoryId) => data && data.find(el => el.categoryId === categoryId);
    const [isUploading, setIsUploading] = useState(false);

    const getAllTitle = useCallback(async () => {
        setLoading(true);
        const res = await GetLookupItemsByLookupTypeName({
            lookUpName: UnitVideoCategory,
            pageSize: 100,
            pageIndex: 1
        });
        if (!(res && res.status && res.status !== 200)) setLookuosData(res.result || []);
        else setLookuosData([]);
        setLoading(false);
    }, []);


    const getAllUnitVideos = useCallback(async (newValue) => {
        setLoading(true);
        const isCategoryUsed = data && data.find(el => el.categoryId === newValue);
        const body = {
            categoryId: newValue,
            unitId: state.unitId,
            unitVideoTypeId: 1,
            pageIndex: 1,
            pageSize: 100,
        }
        const res = await GetAllUnitVideos(body);
        if (!(res && res.data && res.data.ErrorId)) {
            if (isCategoryUsed) {
                setSelected({
                    lookupItemName: isCategoryUsed && isCategoryUsed.categoryName,
                    lookupItemId: isCategoryUsed && isCategoryUsed.categoryId,
                })
                setState({
                    id: 'files',
                    value: res.result,
                    documentLink: res.result.videoLink || ''
                });
                setState({
                    id: 'categoryId',
                    value: newValue,
                });
                setDialogName(true);
            } else {
                setState({
                    id: 'files',
                    value: [],
                });
                setDialogName(false);
                setActiveItem(null);
            }

        } else {
            setDialogName(false);
            setActiveItem(null);
            setState({
                id: 'files',
                value: [],
            });
        }
        setLoading(false);
    })

    useEffect(() => {
        if (activeItem && activeItem.categoryId) getAllUnitVideos(activeItem && activeItem.categoryId)
    }, [activeItem]);

    const schema = Joi.object({
        categoryId: Joi.number()
            .required()
            .messages({
                'number.base': t(`${translationPath}category-is-required`),
                'number.empty': t(`${translationPath}category-is-required`),
            }),
    })
        .options({
            abortEarly: false,
            allowUnknown: true,
        })
        .validate(state);

    const submitHandler = async (e) => {
        e.preventDefault();
        setIsSubmitted(true);
        isOpenChanged(false);
        if (schema.error) {
            showError(t('Shared:please-fix-all-errors'));
            return;
        }
        setLoading(true);
        const res = await UpdateUnitVideo(state);
        if (!(res && res.data && res.data.ErrorId)) {
            activeItem || dialogName ? showSuccess(t(`${translationPath}video-update-successfully`)) : showSuccess(t(`${translationPath}video-created-successfully`));
            reloadData();
        } else {
            showError(
                t(
                    `${translationPath}${(res &&
                        res.data &&
                        res.data.Message &&
                        res.data.Message.substring(
                            res.data.Message.lastIndexOf(':') + 1,
                            res.data.Message.length
                        )) ||
                    (activeItem || dialogName ? showSuccess(t(`${translationPath}video-update-failed`)) : showSuccess(t(`${translationPath}video-created-failed`)))
                    }`
                )
            );
        }
        setState({
            id: 'edit',
            value: initialState,
        });
        setLoading(false);
        setSelected({
            lookupItemName: '',
            lookupItemId: '',
        });
    };

    useEffect(() => {
        getAllTitle();
    }, []);

    return (
        <>
            <Spinner isActive={loading} isAbsolute />
            <DialogComponent
                wrapperClasses='unitpage'
                maxWidth='md'
                disableBackdropClick
                titleText={(dialogName || activeItem) ? 'edit-video' : 'add-new-video'}
                saveText='save'
                saveIsDisabled={
                    (state && state.categoryId === null || isUploading || (!activeItem && !dialogName && !state.files.length ))
                }
                dialogContent={(
                    <div className='Units-Sales-Profile-management view-wrapper'>
                        <div className='dialog-item'>
                            <AutocompleteComponent
                                idRef='TitleRef'
                                labelValue='Title'
                                isLoading={loading}
                                selectedValues={selected || []}
                                getOptionSelected={(option) => option.lookupItemName === selected.lookupItemName}
                                displayLabel={(option) => t(`${option.lookupItemName || ''}`)}
                                isDisabled={activeItem ? true : false}
                                helperText={
                                    getErrorByName(schema, 'categoryId').message
                                }
                                error={getErrorByName(schema, 'categoryId').error}
                                multiple={false}
                                data={lookuosData || []}
                                withoutSearchButton
                                isWithError
                                isSubmitted={isSubmitted}
                                parentTranslationPath={parentTranslationPath}
                                translationPath={translationPath}
                                onChange={(event, newValue) => {
                                    getAllUnitVideos(newValue && +newValue.lookupItemId);
                                    setSelected({
                                        lookupItemName: newValue && newValue.lookupItemName,
                                        lookupItemId: newValue && +newValue.lookupItemId,
                                    });
                                    setState({
                                        id: 'categoryId',
                                        value: newValue && +newValue.lookupItemId
                                    });
                                    setDialogName(false);
                                }}
                            />
                        </div>
                        <div className='dialog-wrapper'>
                            <div
                                className='Unit-Uploader-wrapper'
                            >
                                <DocumentsUploader
                                    initUploaded={(state.files &&
                                        state.files.length > 0 &&
                                        state.files.map((item) => ({
                                            uuid: item.uuid || item.fileId,
                                            fileName: item.fileName,
                                            documentLink: item.documentLink || item.videoLink || null
                                        }))) ||
                                        []}
                                    docTypes={{ videos: 0, links: 1 }}
                                    setIsUploading={setIsUploading}
                                    isUploading={isUploading}
                                    uploadedChanged={(videos) => 
                                        setState({
                                            id: 'files',
                                            value:
                                                (videos &&
                                                    videos.map((file) => ({
                                                        fileId: (file.uuid || file.fileId || null),
                                                        fileName: file.fileName,
                                                        videoLink: (file.documentLink || file.videoLink || null)
                                                    }))) ||
                                                [],
                                        })
                                    }
                                    open
                                    translationPath={translationPath}
                                    parentTranslationPath={parentTranslationPath}
                                />
                            </div>
                        </div>
                    </div>
                )}
                onSubmit={submitHandler}
                isOpen={isOpen}
                // onSaveClicked={onSave}
                onCancelClicked={() => {
                    isOpenChanged(false);
                    setState({
                        id: 'edit',
                        value: initialState,
                    });
                    setActiveItem(null);
                    setSelected({
                        lookupItemName: '',
                        lookupItemId: '',
                    });
                }}

                parentTranslationPath={parentTranslationPath}
                translationPath={translationPath}
            />
        </>
    );
};
UnitsUploaderDialog.propTypes = {
    ImageCategoryLookup: PropTypes.instanceOf(Object).isRequired,
    reloadData: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
    isOpenChanged: PropTypes.func.isRequired,
    unit: PropTypes.bool.isRequired,
    activeItem: PropTypes.instanceOf(Object).isRequired,
    parentTranslationPath: PropTypes.string.isRequired,
    translationPath: PropTypes.string.isRequired,
};
