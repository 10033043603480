import React, { useCallback, useEffect, useReducer, useState } from "react";
import { PaginationComponent, Spinner, Tables } from "../../../../Components";
import { useTranslation } from "react-i18next";
import { OpportunitiesLogTableHeaderData } from "./OpportunitiesLogTableHeaderData";
import { GetAllFilteredLeadStageLogsAPI } from "../../../../Services";
import OpportunitiesSearchFilters from "./OpportunitiesSearchFilters";
import { ContactsMapper } from "../../ContactsView";
import { bottomBoxComponentUpdate } from "../../../../Helper";
import { TableActions } from "../../../../Enums";
import LeadDocumentsPreviewDialog from "./LeadDocumentsPreviewDialog";

function OpporunitiesLogsView({ }) {
  const reducer = useCallback((state, action) => {
    if (action.id !== "edit") return { ...state, [action.id]: action.value };
    return {
      ...action.value,
    };
  }, []);

  const parentTranslationPath = "OpportunitiesLog";
  const translationPath = "";
  const { t } = useTranslation(parentTranslationPath);

  const [activeItem, setActiveItem] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [oldClientData, setOldClientData] = useReducer(reducer, {});
  const [newClientData, setNewClientData] = useReducer(reducer, {});
  const [data, setData] = useState({
    result: [],
    totalCount: 0,
  });

  const initialState = {
    leadId: null,
    newStageId: null,
    oldStageId: null,
    newLeadName: null,
    oldLeadName: null,
    createdOn: null,
    createdBy: null,
    filterBy: null,
    orderBy: null,
    fromDate: null,
    toDate: null
  };

  const initialFilter = {
    pageSize: parseInt(localStorage.getItem("Pagination")) || 25,
    pageIndex: 0,
  };

  const [openPreviewDialog, setOpenPreviewDialog] = useState(false);
  const [state, setState] = useReducer(reducer, initialState);
  const [fetchData, setFetchData] = useState(null);
  const [filter, setFilter] = useReducer(reducer, initialFilter);
  const GetAllLeadOpportunitiesLogs = async () => {
    setIsLoading(true);
    const body = state;
    try {
      const results = await GetAllFilteredLeadStageLogsAPI(
        {
          pageIndex: filter.pageIndex + 1,
          pageSize: filter.pageSize,
        },
        body
      );
      if (!(results && results.status && results.status !== 200)) {
        setData(() => {
          const formatedArray = results.result.map((item) => ({
            ...item,
            newClientData: JSON.parse(item.newClientData),
            oldClientData: JSON.parse(item.oldClientData),
          }));
          return { result: formatedArray, totalCount: results.totalCount };
        });
      } else {
        setData({ result: [], totalCount: 0 });
      }
    } catch (error) {
      setData({ result: [], totalCount: 0 });
    }
    setIsLoading(false);
  };

  const onPageIndexChanged = (pageIndex) => {
    setFilter({ id: "pageIndex", value: pageIndex });
  };
  const onPageSizeChanged = (pageSize) => {
    setFilter({ id: "pageSize", value: pageSize });
    localStorage.setItem("Pagination", pageSize);
  };

  const onFilterReset = () => {
    const defaultPageSize = 25;
    setFilter({
      id: "edit",
      value: {
        pageIndex: 0,
        pageSize: defaultPageSize,
      },
    });
    localStorage.setItem("Pagination", defaultPageSize);
  };

  const tableActionClicked = (actionType, row) => {
    setActiveItem(row);
    setOpenPreviewDialog(true);
  };

  const reloadData = () => {
    setFetchData(Math.random());
  };

  useEffect(() => {
    GetAllLeadOpportunitiesLogs();
  }, [fetchData, filter , state]);

  useEffect(() => {
    bottomBoxComponentUpdate(
      <PaginationComponent
        pageIndex={filter.pageIndex}
        pageSize={filter.pageSize}
        totalCount={data?.totalCount || 0}
        onPageIndexChanged={onPageIndexChanged}
        onPageSizeChanged={onPageSizeChanged}
      />
    );
  }, [filter]);

  return (
    <div className="p-3">
      <Spinner isActive={isLoading} />
      <OpportunitiesSearchFilters
        state={state}
        setState={setState}
        filter={filter}
        initialState={initialState}
        onFilterReset={onFilterReset}
        reloadData={reloadData}
        translationPath={translationPath}
        parentTranslationPath={parentTranslationPath}
      />

      <Tables
        data={data?.result || []}
        headerData={OpportunitiesLogTableHeaderData || []}
        isLoading={isLoading}
        defaultActions={[
          {
            enum: TableActions.preview.key,
          },
        ]}
        actionsOptions={{
          onActionClicked: tableActionClicked,
        }}
        FilterDisabledButton
        onPageIndexChanged={onPageIndexChanged}
        onPageSizeChanged={onPageSizeChanged}
        activePage={filter.pageIndex}
        itemsPerPage={filter.pageSize}
        translationPath={translationPath}
        parentTranslationPath={parentTranslationPath}
      />
      {openPreviewDialog && (
        <div>
          <LeadDocumentsPreviewDialog
            openPreviewDialog={openPreviewDialog}
            setOpenPreviewDialog={setOpenPreviewDialog}
            activeItem={activeItem}
            filter={filter}
            translationPath={translationPath}
            parentTranslationPath={parentTranslationPath}
          />
        </div>
      )}
    </div>
  );
}

export default OpporunitiesLogsView;
