import React, { useCallback, useEffect, useState } from 'react';
import {
  Button, DialogTitle, DialogContent, DialogActions, Dialog, Grid
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import DateFnsUtils from '@date-io/date-fns';
import moment from 'moment';
import Joi from 'joi';

import {
  MuiPickersUtilsProvider,
  KeyboardDateTimePicker
} from '@material-ui/pickers';
import { Inputs, Spinner, UploaderComponent } from '../../../../../../../Components';
import {
  GetParams, showError, showSuccess, getErrorByName
} from '../../../../../../../Helper';
import { AddOpenHouse, UpdateUnitOpenHouse } from '../../../../../../../Services';
import { UploaderThemesEnum } from '../../../../../../../Enums';

export const AddOpenhouseDialog = ({
  open,
  close,
  translationPath,
  parentTranslationPath,
  reloadData,
  UnitId,
  IsEdit,
  OpenHouses,
}) => {
  const defaultState = {
    unitId: UnitId,
    unitOpenHouseLocation: '',
    unitOpenHouseStartTime: new Date(),
    unitOpenHouseEndTime: new Date(),
    registrationLink: '',
    imageId: '',
    imageName: '',
  };
  useEffect(() => {
    const UnitId = GetParams('id');
    if (UnitId !== null) setState({ ...state, unitId: UnitId });
  }, []);

  const { t } = useTranslation(parentTranslationPath);
  const [state, setState] = useState(defaultState);
  const schema = Joi.object({
    unitOpenHouseLocation: Joi.string()
      .required()
      .messages({
        'string.base': t`${translationPath}imageName-is-required`,
        'string.empty': t`${translationPath}imageName-is-required`,
      }),

    imageId: Joi.string()
      .required()
      .messages({
        'string.base': t`${translationPath}imageId-is-required`,
        'string.empty': t`${translationPath}imageId-is-required`,
      }),
      unitOpenHouseStartTime: Joi.date()
      .required()
      .messages({
        'date.base': t(`${translationPath}unitOpenHouseStartTime-is-required`),
        'date.empty': t(`${translationPath}unitOpenHouseStartTime-is-required`),
        }),
        
     unitOpenHouseEndTime: Joi.date()
     .greater(Joi.ref('unitOpenHouseStartTime'))
     .required()
      .messages({
       'date.base': t(`${translationPath}unitOpenHouseEndTime-is-required`),
       'date.empty': t(`${translationPath}unitOpenHouseEndTime-is-required`),
       'date.greater': t(`${translationPath}select-unitOpenHouseEndTime-after-unitOpenHouseStartTime`),
  }),
  })
    .options({
      abortEarly: false,
      allowUnknown: true,
    })
    .validate(state);
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const saveHandler = useCallback(async () => {
    setIsSubmitted(true);
    setIsLoading(true);
    if (getErrorByName(schema, 'imageId').error) {
      setIsLoading(false);
      showError(t(`${translationPath}imageId-is-required`));
      return;
    }
    if (schema.error) {
      setIsLoading(false);
      showError(t(`${translationPath}please-fix-all-errors`));
      return;
    }

    if( state.unitOpenHouseStartTime && state.unitOpenHouseEndTime &&  moment(state.unitOpenHouseStartTime).diff(moment(state.unitOpenHouseEndTime)) > 0)
    {
      showError(t(`${translationPath}please-select-start-time-less-than-end-time`));
      setIsLoading(false);
      return;
    }

   else if(!state.unitOpenHouseStartTime && state.unitOpenHouseEndTime)
    {
      showError(t(`${translationPath}please-select-start-time`));
      setIsLoading(false);
      return;
    }

    else if(!state.unitOpenHouseStartTime && !state.unitOpenHouseEndTime)
    {
      showError(t('please-fix-all-errors'));
      setIsLoading(false);
      return; 
    }

    if (IsEdit === true) {
      setIsLoading(true);
     const result = await UpdateUnitOpenHouse(OpenHouses.unitOpenHouseId, { ...state, unitOpenHouseEndTime: moment(state.unitOpenHouseEndTime).add(3, 'hours'), unitOpenHouseStartTime: moment(state.unitOpenHouseStartTime).add(3, 'hours') });
     if (!(result && result.status && result.status !== 200)) {
      reloadData();
      close(false);
      setState(defaultState);
      showSuccess(t(`${translationPath}edit-successfully`));
    } else showError(t(`${translationPath}company-finance-updated-failed`));
    } else {
      setIsLoading(true);
      const result = await AddOpenHouse({ ...state, unitOpenHouseEndTime: moment(state.unitOpenHouseEndTime).add(3, 'hours'), unitOpenHouseStartTime: moment(state.unitOpenHouseStartTime).add(3, 'hours') });
      setIsLoading(false);
      if (!(result && result.status && result.status !== 200)) {
        reloadData();
        close(false);
        setState(defaultState);
        showSuccess(t(`${translationPath}add-successfully`));
      } else showError(t(`${translationPath}company-finance-updated-failed`));
    }
  }, [close, defaultState, reloadData, state, t, translationPath]);




  useEffect(() => {
    if (IsEdit === true) {
      setState({
        ...state,
        unitOpenHouseLocation: OpenHouses.unitOpenHouseLocation,
        unitId: OpenHouses.unitId,
        unitOpenHouseLocation: OpenHouses.unitOpenHouseLocation,
        unitOpenHouseStartTime: OpenHouses.unitOpenHouseStartTime,
        unitOpenHouseEndTime: OpenHouses.unitOpenHouseEndTime,
        imageId: OpenHouses.imageId,
        imageName: OpenHouses.imageName,
        registrationLink: OpenHouses.registrationLink,
      });
    } else {
    }
  }, []);

  return (
    <Dialog
      open={open}
      keepMounted
      onClose={() => {
        close(false);
        setIsSubmitted(true);
        setState(defaultState);
      }}
      className='add-new-company'
    >
      <Spinner isActive={isLoading} isAbsolute />
      <form
        noValidate
        onSubmit={(event) => {
          event.preventDefault();
          close(false);
        }}
      >
        <DialogTitle>
          {IsEdit === true ?
            t(`${translationPath}EDITOpenhouse`) :
            t(`${translationPath}AddnewOpenhouse`)}
        </DialogTitle>
        <DialogContent>
          <div className='UploaderComponent-wrapered'>
            <UploaderComponent
              idRef='profileImgRef'
              labelClasses='Requierd-Color'
              uploaderTheme={UploaderThemesEnum.box.key}
              initUploadedFiles={
                (state && state.imageId && [{ uuid: state.profileImg, fileName: 'user-image' }]) ||
                []
              }
              uploadedChanged={(files) =>
                setState({
                  ...state,
                  imageId: (files.length > 0 && files[0].uuid) || null,
                  imageName: 'profileImg',
                })}
            />
          </div>
          <Inputs
            isRequired
            isSubmitted={isSubmitted}
            value={state.unitOpenHouseLocation}
            idRef='activitiesSearchRef'
            labelClasses='Requierd-Color'
            labelValue={t(`${translationPath}Name`)}
            helperText={
              getErrorByName(schema, 'unitOpenHouseLocation').message
            }
            isWithError
            error={getErrorByName(schema, 'unitOpenHouseLocation').error}
            onInputChanged={(e) => setState({ ...state, unitOpenHouseLocation: e.target.value })}
          />
          <Inputs
            isSubmitted={isSubmitted}
            value={state.registrationLink}
            idRef='activitiesSearchRef'
            labelValue={t(`${translationPath}Registration link`)}
            onInputChanged={(e) => setState({ ...state, registrationLink: e.target.value })}
          />
          <div className='DatePicker'>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Grid item xs={24} sm={12}>
                <KeyboardDateTimePicker
                  margin='normal'
                  id='time-picker'
                  label={t(`${translationPath}Start`)}
                  value={state.unitOpenHouseStartTime}
                  onChange={(date) => setState({ ...state, unitOpenHouseStartTime: date })}
                  KeyboardButtonProps={{
                    'aria-label': 'change time',
                  }}
                  isWithError
                  helperText={getErrorByName(schema, 'unitOpenHouseStartTime').message}
                  error={getErrorByName(schema, 'unitOpenHouseStartTime').error}
                  isSubmitted={isSubmitted}
                />
                {' '}
              </Grid>
              <Grid item xs={24} sm={12}>
                <KeyboardDateTimePicker
                  margin='normal'
                  id='time-picker'
                  label={t(`${translationPath}Close`)}
                  value={state.unitOpenHouseEndTime}
                  onChange={(date) => setState({ ...state, unitOpenHouseEndTime: date })}
                  KeyboardButtonProps={{
                    'aria-label': 'change time',
                  }}
                  minDate={state.unitOpenHouseStartTime} 
                  isWithError
                  helperText={getErrorByName(schema, 'unitOpenHouseEndTime').message}
                  error={getErrorByName(schema, 'unitOpenHouseEndTime').error}
                  isSubmitted={isSubmitted}
                />
              </Grid>
            </MuiPickersUtilsProvider>
          </div>
        </DialogContent>
        <DialogActions>
          <Button className='btns theme-solid bg-cancel' onClick={() => close(false)}>
            {t(`${translationPath}Cancel`)}
          </Button>
          <Button 
           disabled={isLoading}
           onClick={saveHandler} className='btns theme-solid' variant='contained'>
            {IsEdit === true ? t(`${translationPath}edit`) : t(`${translationPath}Add`)}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
