import { lookupItemsGetId, lookupItemsGet  } from '../Services';
import { getLook as look } from '../Helper';

export async function LookupRule(item, allItems ) {
  
  if (
    !(
      item.field.FieldType === 'select' ||
      (item.field.FieldType === 'address' && item.data.uiType === 'select') ||
      item.field.FieldType === 'UploadFiles'
    )
  )
    return;


    if(item.data.isFillData)
     return ; 


    if (item  &&  item.data && item.data.enum && item.data.enum.length)
    return;

    if(item.data.isFillData ===  true )
    return ; 

   

  if (!item.data.enum) item.data.enum = [];
  if (!item.data.lookup  || (item.data.api && item.data.api ===  'get_values_depends_on_another_api')) return;

   // i will fix bugs in this rule after release  24/9/2023 ...AYAT

  // if (+formType === 2 && item.data.addressType)
  // return;

  const effectedItemIndex = allItems.findIndex(
    (f) =>
      item.data.hasEffectedFrom &&
      f.field.id &&
      f.field.id.toLowerCase() === item.data.hasEffectedFrom.toLowerCase()
  );
  if ((look() && !look().find((f) => f === item.field.id)) || item.data.lookup) {
    if (
      (effectedItemIndex !== -1 &&
        allItems[effectedItemIndex].data &&
        allItems[effectedItemIndex].data.valueToEdit &&
        allItems[effectedItemIndex].data.valueToEdit.lookupItemId) ||
      !item.data.specialKey || item.data.lookup
    ) {
      const res = await lookupItemsGet({
        pageIndex: 1,
        pageSize: 25,
        lookupTypeId: item.data.lookup,
        lookupParentId:
          (effectedItemIndex !== -1 &&
            allItems[effectedItemIndex].data &&
            allItems[effectedItemIndex].data.valueToEdit &&
            allItems[effectedItemIndex].data.valueToEdit.lookupItemId) ||
          null,
      });
      if ((res && res.data && res.data.ErrorId) || !res) return;
      if (item.data.enum.length === 0){
        if(res && res.result)
        res && res.result &&  res.result.map((items) => item.data.enum.push(items));
      }


      // if (item.data.enum.length === 0) result.map((items) => item.data.enum.push(items));
    } else if (item.data.specialKey) {
      const res = await lookupItemsGet({
        lookupTypeName: (item.data.specialKey),
        pageIndex: 1,
        pageSize: 25,
      });
      if ((res && res.data && res.data.ErrorId) || !res || !res.result) return;
      if (item.data.enum.length === 0) {
      res.result.map((items) =>{ 
        item.data.isFillData = true ; 
        item.data.enum.push(items);
      });
      }

    }
    look().push(item.field.id);
    // await fillAllEffectedByDataOnEdit(item, allItems);
  }
}
