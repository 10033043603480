import { config } from "../../config/config";
import { HttpServices } from "../../Helper";

const MainEndpoint     =   "WorkflowBuilder";
const ActionEndpoint   = `${config.server_address}/${MainEndpoint}/Action`;
const TriggerEndpoint  = `${config.server_address}/${MainEndpoint}/Triger`;
const WorkflowEndpoint = `${config.server_address}/${MainEndpoint}/Workflow`;

export const GetActions = async (body) => {
  const result = await HttpServices.post(
    `${ActionEndpoint}/GetActions`, body).then((data) => data)
    .catch((error) => error.response);
  return result;
};


export const GetSubActions = async (body) => {
  const result = await HttpServices.post(
    `${ActionEndpoint}/GetSubActions`, body).then((data) => data)
    .catch((error) => error.response);
  return result;
};



export const GetTrigerSubActions = async (triger) => {
  const result = await HttpServices.get(`${ActionEndpoint}/GetTrigerSubActions?triger=${triger}`
  ).then((data) => data)
    .catch((error) => error.response);
  return result;
};

export const GetActionCategories = async () => {
  const result = await HttpServices.get(
    `${ActionEndpoint}/GetActionCategories`
  ).then((data) => data)
    .catch((error) => error.response);
  return result;
};

export const GetTriggers = async (body) => {
  const result = await HttpServices.post(
    `${TriggerEndpoint}/GetTrigers`,
    body
  ).then((data) => data)
    .catch((error) => error.response);
  return result;
};


export const UpdateTrigger = async (body) => {
  const result = await HttpServices.put(
    `${TriggerEndpoint}/UpdateTriger`,
    body
  ).then((data) => data)
    .catch((error) => error.response);
  return result;
};


export const GetTriggerDetails = async (trigerId) => {
  const result = await HttpServices.get(
    `${TriggerEndpoint}/GetTrigerDetails/${trigerId}`
  ).then((data) => data)
    .catch((error) => error.response);
  return result;
};

export const GetTriggerCategories = async () => {
  const result = await HttpServices.get(
    `${TriggerEndpoint}/GetTrigerCategories`
  ).then((data) => data)
    .catch((error) => error.response);
  return result;
};

export const GetWorkflowLogs = async (body) => {
  const result = await HttpServices.post(
    `${WorkflowEndpoint}/GetWorkflowLogs`, body).then((data) => data)
    .catch((error) => error.response);
  return result;
};


export const StartNewWorkflow = async (TrigerName, TrigerParamId) => {
  const result = await HttpServices.post(
    `${WorkflowEndpoint}/StartNewWorkflow?TrigerName=${TrigerName}&TrigerParamId=${TrigerParamId}`
  ).then((data) => data)
    .catch((error) => error.response);
  return result;
};

export const ExecuteWorkflow = async (WorkflowId, CurrentUser) => {
  const result = await HttpServices.post(
    `${WorkflowEndpoint}/ExecuteWorkflow?WorkflowId=${WorkflowId}&CurrentUser=${CurrentUser}&OrganizationId=${config.organizationsId}&SourceId=${config.source}&ApplicationsId=${config.applicationId}`
  ).then((data) => data)
    .catch((error) => error.response);
  return result;
}; 
