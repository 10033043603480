import { ContactsMergeStatus } from './Status';

const contactsMergeRequest = (payload) => {
  return ({ type: ContactsMergeStatus.REQUEST, payload })
  
};
const contactsMergeSuccess = (payload) => ({ type: ContactsMergeStatus.SUCCESS, payload });
const contactsMergeError = (payload) => ({ type: ContactsMergeStatus.ERROR, payload });
const contactsMergeReset = (payload) => ({ type: ContactsMergeStatus.RESET, payload });

export const ContactsMergeActions = {
  contactsMergeRequest,
  contactsMergeSuccess,
  contactsMergeError,
  contactsMergeReset,
};
