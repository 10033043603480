export function BuiltupAreaSqmRule(
  id,
  value,
  setDate,
  formType,
  itemList,
  sellingPrice
) {
  if (id === "builtup_area_sqm" || id === "builtup_area-sqm") {

    const fixedNumber =  (value  && value !== '' )  ? ( value.replace(/,/g, "") || value ) : 0 ;
    const builtupAreaSqmValue = fixedNumber  ? parseFloat(fixedNumber) : 0;
    const builtupAreaSqft = builtupAreaSqmValue ? (builtupAreaSqmValue / 0.09290303997 ) : 0;
    const priceSqm =
      sellingPrice && sellingPrice.salePrice && builtupAreaSqft !== 0
        ? Number(sellingPrice.salePrice) / Number(builtupAreaSqft)
        : 0;

    const builtupAreaSqftIndex = itemList.indexOf(
      itemList.find((f) => f.field.id.toLowerCase() === "builtup_area_sqft" ||  f.field.id.toLowerCase() === "builtup_area-sqft"  )
    );

    if (formType === 2 && builtupAreaSqftIndex !== -1) {
      setDate(builtupAreaSqft, 0, "builtup_area_sqft");
      setDate(priceSqm, 0, "pricesqm");
    } else if (formType === 1 && builtupAreaSqftIndex !== -1) {
      setDate("builtup_area-sqft", parseInt(builtupAreaSqft) || 0 );
      setDate("builtup_area_sqft", parseInt(builtupAreaSqft) || ' ');
      setDate("pricesqm", priceSqm );
    }
  }
}

export function BuiltupAreaSqmDefaultRule(
  value,
  setDate,
  formType,
  itemList,
  values 
) {

  const builtupAreaSqmIndex = itemList.indexOf(
    itemList.find((f) => f.field.id.toLowerCase() === "builtup_area_sqm")
  );

  if(builtupAreaSqmIndex !== -1 && values && !(values.builtup_area_sqm))
    {
      const builtupAreaSqFtValue =  (value !== '' && value !== null  && value !== undefined   ) ? Number(value): 0 ; 
      const builtupAreaSqm =  builtupAreaSqFtValue ? parseFloat((0.09290303997 * parseInt(builtupAreaSqFtValue)).toFixed(2))  : 0   ; 
     
  
      if (formType === 2 && builtupAreaSqmIndex !== -1) 
        setDate(builtupAreaSqm, 0, 'builtup_area_sqm');


    }
}
