import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Typography } from '@material-ui/core';
import { CustomBadge } from '../../../../../../../Components';
import { useSelectedTheme, useTranslate } from '../../../../../../../Hooks';
import unitTest from '../../../../../../../assets/images/icons/unitTest.png';

// Icons
import { BathIcon, BedIcon, UnitSizeIcon, MaidIcon } from '../../../../../../../assets/icons';

// Styles
import { unitCardStyles } from './styles';


function ContactOverviewUnitCard({ contactUnitsData }) {
  const styles = unitCardStyles();

  const { translate: sharedTranslate } = useTranslate("SharedV2");

  const {
    theme: { palette },
  } = useSelectedTheme();

  return (
    <Box className={styles.unitCardDetailsContainer}>
      {contactUnitsData?.result?.map((unit) => (
        <Box key={unit.id} className={styles.unitCardDetails}>
          <Box>
            <img
              src={unitTest}
              height='40px'
              width='50px'
              style={{ borderRadius: '6px' }}
            />
          </Box>
          <Box className={styles.unitNameContainer}>
            <div className={styles.nameAndPriceWrapper}>
              <Typography className={styles.UnitName}>
                {unit.unitName}
              </Typography>
              <span className={styles.unitePrice}>
                {unit.operationType === 'Sale'
                  ? `AED ${unit.sellingPrice?.toLocaleString() || '-'}`
                  : `AED ${unit.rentPerYearPrice?.toLocaleString() || '-'}`}
              </span>
            </div>
            <Typography className={styles.uniteLocation}>
              {unit.city}
            </Typography>
            <div className={styles.badgesContainer}>
              <CustomBadge
                label={unit?.operationType !== "Rent" ? unit?.status : unit?.leaseStatus}
                // PS: rent === lease
                // PS: unit?.operationType !== "Rent" => sale
                SizeVariant={'medium'}
                BackgroundColor={palette.utility.gray_blue_50}
                BorderColor={palette.utility.gray_blue_200}
                Color={palette.utility.gray_blue_700}
              />
              <span className={styles.unitStatus}>{unit.operationType}</span>
            </div>
            <div className={styles.subDetails}>
              <Box className={styles.iconDetailsWrapper}>
                <UnitSizeIcon
                  width='20'
                  height='20'
                  fill={palette.foreground.quinary}
                />
                <span className={styles.textDetails}>{unit.sizeArea || 0}</span>
                <span className={styles.textDetails}>sq.ft</span>
              </Box>
              <Box className={styles.iconDetailsWrapper}>
                <BedIcon
                  width='20'
                  height='20'
                  fill={palette.foreground.quinary}
                />
                <span className={styles.textDetails}>{unit.bedrooms || 0}</span>
              </Box>
              <Box className={styles.iconDetailsWrapper}>
                <BathIcon
                  width='20'
                  height='20'
                  fill={palette.foreground.quinary}
                />
                <span className={styles.textDetails}>
                  {unit.bathrooms || 0}
                </span>
              </Box>
              <Box className={styles.iconDetailsWrapper}>
                <MaidIcon
                  width='20'
                  height='20'
                  fill={palette.foreground.quinary}
                />
                <span className={styles.textDetails}>{unit.maid || 0}</span>
              </Box>
            </div>
          </Box>
        </Box>
      ))}
      {contactUnitsData?.totalCount === 0 && (
        <Typography className={styles.emptyState} variant="h6" align="center" color="textSecondary">
          {sharedTranslate("no-data-avlbl")}
        </Typography>
      )}
    </Box>
  );
}

export default ContactOverviewUnitCard;
