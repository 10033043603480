import React, {
 useCallback, useEffect, useState, useRef
} from 'react';
import { useTranslation } from 'react-i18next';
import { getProperties } from '../../../../../../../Services';
import { AutocompleteComponent } from '../../../../../../../Components';

export const PropertyComponent = ({
                                       parentTranslationPath,
                                       translationPath,
                                       onStateChanged,
                                       state,
                                   }) => {
    const { t } = useTranslation([parentTranslationPath, 'Shared']);
    const searchTimer = useRef(null);
    const [properties, setProperties] = useState([]);
    const [propertySearch, setPropertySearch] = useState(null);

    const GetAllProperties = useCallback(async () => {
        const res = await getProperties({ pageSize: 50, pageIndex: 0, search: propertySearch });
        if (!(res && res.status && res.status !== 200)) {
            const mapped = [];
            res.result.map((item) => {
                mapped.push({
                    propertyId: item.propertyId,
                    propertyName: item.property && item.property.property_name,
                    property_plan: item.property && item.property.property_plan && item.property.property_plan.lookupItemName
                });
            });
            setProperties(mapped || []);
} else setProperties([]);
        // else setLookups([]);
    }, [propertySearch]);
    useEffect(() => {
        GetAllProperties();
    }, [propertySearch]);

    return (
      <>
        <div className='dialog-content-item'>
          <AutocompleteComponent
            idRef='PropertiesIdRef'
            labelValue={t(`${translationPath}Properties`)}
            selectedValues={state.rotationSchemeProperties}
            data={properties || []}
            multiple
            displayLabel={(option) => `${option && option.propertyName}${`      (      ${(option && option.property_plan)}      ) `}` || ''}
            chipsLabel={(option) => (option && option.propertyName) || ''}
            withoutSearchButton
            parentTranslationPath={parentTranslationPath}
            translationPath={translationPath}
            onChange={(event, newValue) => {
                        const localNewValue = {
                            id: 'rotationSchemeProperties',
                            value: [
                                ...newValue,
                            ],
                        };
                        onStateChanged(localNewValue);
                    }}
            onInputKeyUp={(event) => {
                        const { value } = event.target;
                        if (searchTimer.current) clearTimeout(searchTimer.current);
                        searchTimer.current = setTimeout(() => {
                            setPropertySearch(value);
                        }, 700);
                    }}
            filterOptions={(options) => {
                        const isFound = (id) => state.rotationSchemeProperties.findIndex((item) => item.propertyId === id) === -1;
                        return options.filter((option) => isFound(option.propertyId));
                    }}
          />
        </div>
      </>
    );
};
