import React from "react";

function MergeContactsIcon({ fill, ...restProps }) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill={fill ?? ""}
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <path
        d="M4.553 1.701c-1.376.217-2.422 1.157-2.778 2.495-.072.274-.085.391-.085.804 0 .413.013.53.085.804.266 1 .922 1.791 1.835 2.213.136.063.317.133.402.155l.154.042.009 4.751c.008 4.515.011 4.758.069 4.873.078.157.263.341.418.417.163.08.513.08.676 0 .155-.076.34-.26.418-.417.057-.114.061-.287.07-2.731l.009-2.609.207.259c.273.341.87.936 1.225 1.221a8.464 8.464 0 0 0 4.122 1.774c.198.027.369.061.38.074a.86.86 0 0 1 .062.174c.023.082.093.26.154.394.418.907 1.212 1.565 2.211 1.831.274.072.391.085.804.085.413 0 .53-.013.804-.085a3.296 3.296 0 0 0 1.541-.88c.436-.436.716-.927.88-1.541.072-.274.085-.391.085-.804 0-.413-.013-.53-.085-.804a3.334 3.334 0 0 0-2.421-2.421c-.274-.072-.391-.085-.804-.085-.413 0-.53.013-.804.085-.999.266-1.793.924-2.211 1.831a3.812 3.812 0 0 0-.154.387l-.042.143-.153-.021a6.75 6.75 0 0 1-4.479-2.624 6.902 6.902 0 0 1-1.272-3.127l-.021-.153.143-.042c.456-.135.959-.445 1.338-.824.436-.436.716-.927.88-1.541.072-.274.085-.391.085-.804 0-.413-.013-.53-.085-.804-.324-1.217-1.219-2.111-2.421-2.417-.362-.092-.934-.128-1.251-.078m.862 1.683c.296.077.56.234.779.462a1.634 1.634 0 0 1 0 2.308 1.639 1.639 0 0 1-2.348.04 1.643 1.643 0 0 1 0-2.388 1.648 1.648 0 0 1 1.569-.422m10 10c.296.077.56.234.779.462a1.634 1.634 0 0 1 0 2.308 1.639 1.639 0 0 1-2.348.04 1.643 1.643 0 0 1 0-2.388 1.648 1.648 0 0 1 1.569-.422"
        fill={fill ?? ""}
      />
    </svg>
  );
}

export default MergeContactsIcon;
