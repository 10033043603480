import React, {
  useState,
  useEffect,
  useReducer,
  useRef,
  useCallback,
} from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import PropTypes from "prop-types";
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Dialog,
} from "@material-ui/core";
import {
  Spinner,
  AutocompleteComponent,
  DatePickerComponent,
  Inputs,
} from "../../../../../../Components";
import { showSuccess, showError, GetParams, showWarn } from "../../../../../../Helper";
import { UpdateAuditAndComplianceAPI } from "../../../../../../Services";

import { FooterWrapperComponent } from "../../../DevelopersUtilities/Components";
import { ActivityFilesUploader } from "../../../../../../Components/UploaderActivitieFileComponent/ActivityFilesUploader";

export const AuditAndComplianceManagementDialog = ({
  parentTranslationPath,
  translationPath,
  isOpen,
  onClose,
  onSave,
  auditAndComplianceValues,
}) => {
  const { t } = useTranslation(parentTranslationPath, "Shared");
  const [isLoading, setIsLoading] = useState({
    editAuditAndCompliance: false,
    info: false,
  });
  const searchTimer = useRef(null);

  const [items, setItems] = useState({
    allAuditAndCompliance: {
      result: [],
      totalCount: 0,
    },
  });
  const [defaultState, setDefaultState] = useState({
    developerContactId: +GetParams("id"),
    isAuditProcessComplete: false,
    auditRemarks: null,
    auditDate: null,
    auditAndComplianceDocuments: [],
    files: null,
  });

  const [defaultSelected, setDefaultSelected] = useState({
    auditProcessComplete: null,
    auditAndComplianceDocuments: [],
  });

  const reducer = (select, action) => {
    if (action.id !== "edit") return { ...select, [action.id]: action.value };
    if (action.id === "edit") {
      return {
        ...action.value,
      };
    }
  };

  const reducer2 = (select, action) => {
    if (action.id !== "edit") return { ...select, [action.id]: action.value };
    if (action.id === "edit") {
      return {
        ...action.value,
      };
    }
  };

  const [state, setState] = useReducer(reducer, {
    ...defaultState,
  });

  const [selected, setSelected] = useReducer(reducer2, {
    ...defaultSelected,
  });

  const [isSubmitted, setIsSubmitted] = useState(false);

  const onChangeHandler = (key, values, selectedObj, selectedValues) => {
    if (!selectedObj) {
      setState({ id: [key], value: values });
    } else {
      setState({ id: [key], value: values });
      setSelected({ id: [selectedObj], value: selectedValues });
    }
  };

  const SaveUpdateAgreementDetailsHandler = useCallback(async () => {
    setIsSubmitted(true);

    setIsLoading((item) => ({ ...item, editAuditAndCompliance: true }));
    const body = { ...state };
    if (state.files) delete body.files;
    if(state && state.auditDate &&  !(moment(state.auditDate).isValid())){
      showWarn(
        t`${translationPath}audit-date-is-not-valid`
      ); 
      setIsLoading((item) => ({ ...item, editAuditAndCompliance: false }));
      return ; 
    }
    const res = await UpdateAuditAndComplianceAPI(
      state.auditAndComplianceId,
      body
    );
    setIsLoading((item) => ({ ...item, editAuditAndCompliance: false }));
    if (!(res && res.status && res.status !== 200)) {
      showSuccess(
        t`${translationPath}audit-and-compliance-update-successfully`
      );
      onSave(true);
    } else showError(t`${translationPath}audit-and-compliance-update-failed`);
  }, [state]);

  useEffect(() => {
    if (auditAndComplianceValues) {
      setIsLoading((item) => ({ ...item, info: true }));
      const updateState = {
        auditAndComplianceId: auditAndComplianceValues?.auditAndComplianceId,
        developerContactId: auditAndComplianceValues?.developerContactId,
        isAuditProcessComplete:
          auditAndComplianceValues?.isAuditProcessComplete,
        auditRemarks: auditAndComplianceValues?.auditRemarks,
        auditDate: auditAndComplianceValues?.auditDate,
        auditAndComplianceDocuments:
          auditAndComplianceValues?.auditAndComplianceDocuments,
        files:
          auditAndComplianceValues.auditAndComplianceDocuments &&
          auditAndComplianceValues.auditAndComplianceDocuments &&
          auditAndComplianceValues.auditAndComplianceDocuments.length > 0
            ? auditAndComplianceValues.auditAndComplianceDocuments.map(
                (file) => ({
                  ...file,
                  uuid: file.fileId,
                  isUploaded: true,
                })
              )
            : null,
      };
      const updateSelected = {
        auditProcessComplete: auditAndComplianceValues?.isAuditProcessComplete
          ? { key: true, name: "yes" }
          : { key: false, name: "no" },
        auditAndComplianceDocuments:
          auditAndComplianceValues?.auditAndComplianceDocuments,
        files: auditAndComplianceValues?.auditAndComplianceDocuments,
        createdBy: auditAndComplianceValues?.createdBy,
        createdOn: auditAndComplianceValues?.createdOn,
      };
      setState({ id: "edit", value: updateState });
      setSelected({ id: "edit", value: updateSelected });
      setIsLoading((item) => ({ ...item, info: false }));
    }
  }, [auditAndComplianceValues]);

  useEffect(() => {
    if (state && state.files)
      onChangeHandler(
        "auditAndComplianceDocuments",
        state.files,
        "auditAndComplianceDocuments",
        state.files
      );
  }, [state.files]);


  return (
    <div>
      <Spinner
        isActive={isLoading.editAuditAndCompliance || isLoading.info}
        isAbsolute
      />
      <Dialog
        open={isOpen}
        onClose={() => {
          onClose();
        }}
        className="activities-management-dialog-wrapper"
        maxWidth="sm"
        disableBackdropClick
      >
        <DialogTitle id="alert-dialog-slide-title">
          <span>{t(`${translationPath}edit-adit-and-compliance`)}</span>
        </DialogTitle>
        <DialogContent>
          <div className="px-2 item mt-1">
            <AutocompleteComponent
              key={`auditAndCompliance`}
              idRef={`auditAndComplianceRef`}
              multiple={false}
              selectedValues={selected.auditProcessComplete}
              inputPlaceholder={t("select-audit-process-complete")}
              data={[
                { key: true, name: "yes" },
                { key: false, name: "no" },
              ]}
              inputClasses="inputs theme-form-builder"
              displayLabel={(option) =>
                (option && t(`${translationPath}${option.name}`)) || ""
              }
              withoutSearchButton
              labelValue={"audit-process-complete"}
              translationPath={translationPath}
              parentTranslationPath={parentTranslationPath}
              onChange={(e, newValue) => {
                onChangeHandler(
                  "isAuditProcessComplete",
                  newValue === null ? false : newValue.key,
                  "auditProcessComplete",
                  newValue
                );
              }}
            />
          </div>
          <div className="px-2 item mt-1">
            <DatePickerComponent
              idRef={"auditDateRef"}
              labelValue={"audit-date"}
              value={
                state &&
                state.auditDate &&
                moment(state.auditDate).isValid() &&
                moment(state.auditDate).format("YYYY-MM-DDTHH:mm:ss")
              }
              onDateChanged={(newValue) => { 
                onChangeHandler(
                  "auditDate", (newValue  && moment(newValue).format("YYYY-MM-DDTHH:mm:ss") ) || newValue
                
                );
              }}
              placeholder={"select-audit-date"}
              translationPath={translationPath}
              parentTranslationPath={parentTranslationPath}
            />
          </div>
          <div className="px-2 item mt-1">
            <Inputs
              isAttachedInput
              idRef={`AuditRemarks${1}`}
              labelValue={"audit-remarks"}
              multiline
              rows={3}
              value={state.auditRemarks || ""}
              onInputChanged={(e) => {
                const { value } = e.target;
                onChangeHandler("auditRemarks", value);
              }}
              inputPlaceholder={"enter-audit-remarks"}
              translationPath={translationPath}
              parentTranslationPath={parentTranslationPath}
            />
          </div>
          <div className="w-100 mt-1">
            <ActivityFilesUploader
              state={state}
              setState={setState}
              translationPath={translationPath}
              parentTranslationPath={parentTranslationPath}
              multiple={true}
              accept={"/*"}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <FooterWrapperComponent
            parentTranslationPath={parentTranslationPath}
            translationPath={translationPath}
            onClose={onClose}
            onSave={() => {
              SaveUpdateAgreementDetailsHandler();
            }}
            disabled={isLoading.editAuditAndCompliance || !selected.auditProcessComplete}
          />
        </DialogActions>
      </Dialog>
    </div>
  );
};

AuditAndComplianceManagementDialog.propTypes = {
  parentTranslationPath: PropTypes.string.isRequired,
  translationPath: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  editPortalConfiguration: PropTypes.instanceOf(Object),
  isLoading: PropTypes.instanceOf(Object),
};
AuditAndComplianceManagementDialog.defaultProps = {
  editPortalConfiguration: undefined,
  isLoading: undefined,
};
