import React, { useEffect } from 'react';
import { SwitchRoute } from '../../../Components/Route/SwitchRoute';
import { LeadsRoutes } from '../../../routes';

export const LeadsLayout = () => {
    useEffect(() => {
        return () => {
            localStorage.removeItem("contactLeadsCurrentPageIndex");
        }
    }, []);
    return (
        <SwitchRoute routes={LeadsRoutes} />
    );
};
