/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, {
  useCallback,
} from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import i18next from 'i18next';
import { ButtonBase, Tooltip } from '@material-ui/core';
import {
  CheckboxesComponent,
  LoadableImageComponant,

} from '../../../../../../../../Components';
import {
  LoadableImageEnum, DefaultImagesEnum, UnitsOperationTypeEnum
} from '../../../../../../../../Enums';
import { getDownloadableLink, GlobalHistory } from '../../../../../../../../Helper';
import { formatCommasForPriceUnit } from '../../../../../../../../Helper/formatCommas.Helper';
import { CopyToClipboardComponents } from '../../../../../../../../ReusableComponents/UtilityComponents/CopyToClipboardComponents/CopyToClipboardComponents';

export const LeadProfileUnitsCardComponent = ({
  data,
  activeCard,
  isExpanded,
  notExpandedMax,
  selectedDetailsUnitItem,
  selectedMatchesIds,
  parentTranslationPath,
  translationPath,
  onViewMoreClicked,
  onCardCheckboxClick,

}) => {
  const { t } = useTranslation([parentTranslationPath]);

  // const [buttonWidth, setButtonWidth] = useState('w-30');
  const pathName = window.location.pathname.split('/home/')[1].split('/')[0];

  const getDefaultUnitImage = useCallback(
    () =>
      DefaultImagesEnum.corporate.defaultImg
  );

  const getIsSelectedCard = useCallback(
    (itemId) => selectedMatchesIds.findIndex((item) => item === itemId) !== -1,
    [selectedMatchesIds]
  );

  const onViewMoreHandler = useCallback(
    (item, index) => () => {
      //  if (onViewMoreClicked) onViewMoreClicked(item, index);

      if ((item.operationType === UnitsOperationTypeEnum.rentAndSale.key || item.operationType === UnitsOperationTypeEnum.rent.key) && pathName === 'lead-lease') {
        GlobalHistory.push(
          `/home/units-lease/unit-profile-edit?formType=${item.unit_type_id}&id=${item.unitId}`
        );
      } else if ((item.operationType === UnitsOperationTypeEnum.rentAndSale.key || item.operationType === UnitsOperationTypeEnum.sale.key) && pathName === 'lead-sales') {
        GlobalHistory.push(
          `/home/units-sales/unit-profile-edit?formType=${item.unit_type_id}&id=${item.unitId}`
        );
      } else if ((item.operationType === UnitsOperationTypeEnum.rent.key) && (pathName === 'leads' || pathName === 'mortgage-leads')) {
        GlobalHistory.push(
          `/home/units-lease/unit-profile-edit?formType=${item.unit_type_id}&id=${item.unitId}`
        );
      } else if ((item.operationType === UnitsOperationTypeEnum.sale.key || item.operationType === UnitsOperationTypeEnum.rentAndSale.key) && (pathName === 'leads' || pathName === 'mortgage-leads')) {
        GlobalHistory.push(
          `/home/units-sales/unit-profile-edit?formType=${item.unit_type_id}&id=${item.unitId}`
        );
      } else {
        GlobalHistory.push(
          `/home/units-sales/unit-profile-edit?formType=${item.unit_type_id}&id=${item.unitId}`
        );
      }
    },
    [onViewMoreClicked, pathName]
  );

  return (
    <div className='units-cards-wrapper childs-wrapper'>
      {data.result &&
        data.result.map((item, index) => (
          <div
            className={`units-card-wrapper${isExpanded ? ' is-expanded' : ''}${(((activeCard && activeCard.id === item.id) ||
              (selectedDetailsUnitItem && selectedDetailsUnitItem.id === item.id)) &&
              ' is-open') ||
              ''
              }`}
            key={`unitsCardItemRef${index + 1}`}
          >
            {item && item.isPublishUnitSale && (
              <Tooltip title={(item.lastSalePublishDate && moment(item.lastSalePublishDate).format('DD/MM/YYYY HH:mm A')) || `${t('Shared:not-available')}`}>
                <div className='ribbon green'>Published</div>
              </Tooltip>
            )}
            <div className='card-checkbox-wrapper'>
              <CheckboxesComponent
                idRef={`unitsCheckboxItemRef${index + 1}`}
                singleChecked={getIsSelectedCard(item.unitId)}
                onSelectedCheckboxClicked={(event) => {
                  event.preventDefault();
                  onCardCheckboxClick(item, index);
                }}
              />
            </div>
            {item && (
              <div className='container-wrapper'>
                <a className='cards-wrapper'>
                  <div className='cards-body-wrapper'>
                    <div className='card-body-section'>
                      <div className='body-item-wrapper'>
                        <div className={`flex-wrapper ${!isExpanded ? 'w-100' : ''}`}>
                          <div className='body-images-wrapper'>
                            <div className='body-image-item-wrapper'>
                              <div className='body-image-item'>
                                <LoadableImageComponant
                                  classes='cover-image'
                                  type={LoadableImageEnum.div.key}
                                  alt={t(`${translationPath}unit-image`)}
                                  src={
                                    (item.unitImage && item.unitImage.fileId && getDownloadableLink(item.unitImage.fileId)) ||

                                    (getDefaultUnitImage())
                                  }
                                />
                              </div>
                            </div>
                            <div className='body-image-item-wrapper'>
                              <div className='body-title-wrapper'>
                                <span className='body-title'>{item.unitName}</span>
                                <div
                                  className={`body-status ${(item.leadUnitStatus && item.leadUnitStatus.classes) || 'bg-warning'
                                    }`}
                                >
                                  <div className='body-status-type'>
                                    {t(`${translationPath}status`)}
                                  </div>
                                  {' : '}
                                  {(item.leadUnitStatus &&
                                    item.leadUnitStatus.value &&
                                    t(
                                      `${translationPath}${item.leadUnitStatus.value === 'sale' ?
                                        'sold' :
                                        item.leadUnitStatus.value
                                      }`
                                    )) ||
                                    'N/A'}
                                </div>
                              </div>
                            </div>
                          </div>
                          {isExpanded && item && item.details && (
                            <div className='card-body-section'>
                              {item && item.details.map((subItem) =>
                              (
                                <div
                                  key={`unitsCardItemRef${+1}`}
                                  className='details-item-wrapper'
                                >
                                  {/* Add your icon rendering here if needed */}
                                  <div className='details-item'>
                                    <div className='wr-fl d-flex'>
                                      <div className='details-text '>
                                        {t(`${translationPath}${subItem && subItem.title || ''}`)}
                                        :
                                        {'  '}
                                      </div>
                                      <div className='details-text '>
                                        {t(`${translationPath}${subItem && subItem.value || ''}`)}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </div>
                          )}
                        </div>
                        <div className='price-wrapper'>
                          <div className={`for-lable ${item.isForSale ? 'Sale' : 'Rent'}`}>
                            {t(`${translationPath}for`)}
                          </div>
                          <div className={item.isForSale ? 'Sale' : 'Rent'}>
                            {item.isForSale ? 'Sale' : 'Rent'}
                          </div>
                          <div className='unit-price'>
                            {
                              (item.price && item.price !== 'N/A' ? `${formatCommasForPriceUnit(item.price)} AED` : 'N/A')

                            }
                          </div>
                        </div>
                        <div className='flat-contents-wrapper'>
                          {item.flatContent
                            .filter(
                              (filterItem, filterIndex) =>
                                (!isExpanded && filterIndex < notExpandedMax) || isExpanded
                            )
                            .map((subItem, subIndex) => (
                              <div
                                key={`flatContentsItemRef${subIndex + 1}`}
                                className='flat-content-item'
                              >
                                <span className={`flat-content-icon ${subItem.iconClasses} mdi-18px`} />
                                <span className='px-1'>{subItem.value}</span>
                                <span>
                                  {subItem.title && (
                                    <span className='flat-content-text'>
                                      {t(`${translationPath}${subItem.title}`)}
                                    </span>
                                  )}
                                </span>
                              </div>
                            ))}
                        </div>
                        <div className='id-date-wrapper pl-2 pr-2'>
                          <div className='created-on'>
                            <span className='details-icon mdi mdi-calendar mdi-16px' />
                            <span>
                              <span className='details-text'>
                                {t(`${translationPath}created`)}
                                :
                              </span>
                              <span className='px-1'>
                                {(item.createdOn &&
                                  moment(item.createdOn)
                                    .locale(i18next.language)
                                    .format('DD/MM/YYYY')) ||
                                  'N/A'}
                              </span>
                            </span>
                          </div>
                          <div className='contact-id-wrapper'>
                            {t(`${translationPath}ref-#`)}
                            :
                            <div className='contact-id' />

                            <Tooltip title={t(`${translationPath}copy`)}>
                              <CopyToClipboardComponents
                                data={item.refNumber}
                                childrenData={item.refNumber}
                              />
                            </Tooltip>

                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='flex-section'>
                      <div className={`card-body-section${isExpanded ? ' is-expanded' : ''}`}>
                        {item.details
                          .filter(
                            (filterItem, filterIndex) =>
                              (!isExpanded && filterIndex < notExpandedMax) || isExpanded
                          )
                          .map(
                            (subItem, subIndex) =>
                              subIndex < notExpandedMax && (
                                <div
                                  key={`unitsCardItemRef${subIndex + 1}`}
                                  className='details-item-wrapper'
                                >
                                  <span className='details-item'>
                                    <span className='details-text'>
                                      {t(`${translationPath}${subItem.title}`)}
                                      :
                                    </span>
                                    <span className='px-1' title={subItem.value}>
                                      {subItem.value}
                                    </span>
                                  </span>
                                </div>
                              )
                          )}

                        <div
                          key='unitsCardItemRef'
                          className='details-item-wrapper'
                        >
                          <span className='details-item'>
                            <span className='details-text'>
                              {t(`${translationPath}${'owner-name'}`)}
                              {' '}
                              :
                            </span>
                            <span className='px-1' title='owner-name'>
                              {(item && item.leadOwner) || 'N/A'}
                            </span>
                          </span>
                        </div>

                      </div>
                    </div>
                  </div>

                  <div className='cards-footer-wrapper'>
                    <div className='d-flex-center  w-100'>
                      <ButtonBase
                        className='btns theme-transparent mx-0'
                        onClick={onViewMoreHandler(item, index)}
                      >
                        <span className='mdi mdi-folder-open-outline' />
                        <span className='px-1'>{t(`${translationPath}open-file`)}</span>
                      </ButtonBase>
                    </div>
                  </div>

                </a>
              </div>
            )}
          </div>
        ))}
    </div>
  );
};

LeadProfileUnitsCardComponent.propTypes = {
  data: PropTypes.arrayOf({
    result: PropTypes.shape({
      flatContent: PropTypes.instanceOf(Array),
    }),
  }).isRequired,
  selectedMatchesIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  parentTranslationPath: PropTypes.string.isRequired,
  translationPath: PropTypes.string.isRequired,
  onViewMoreClicked: PropTypes.func.isRequired,
  notExpandedMax: PropTypes.number,
  selectedDetailsUnitItem: PropTypes.object,
  activeCard: PropTypes.object,
  isExpanded: PropTypes.bool,
};
LeadProfileUnitsCardComponent.defaultProps = {
  notExpandedMax: 4,
  selectedDetailsUnitItem: undefined,
  activeCard: undefined,
  isExpanded: false,

};
