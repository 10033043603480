import { config } from '../../config';
import { HttpServices } from '../../Helper';

export const CreateSettingsForUnitApproval = async (body) => {
  try {
    const result = await HttpServices.post(
      `${config.server_address}/CrmDfm/UnitApproval/CreateSettingsForUnitApproval`,
      body
    );
    return result;
  } catch (error) {
    return error.response;
  }
};

export const GetAllUnitApprovelSettings = async ({
  pageSize,
  pageIndex,
  approvalSettingType,
}) => {
  try {
    const result = await HttpServices.get(
      `${
        config.server_address
      }/CrmDfm/UnitApproval/GetAllUnitApprovelSettings/${pageIndex}/${pageSize}?approvalSettingType=${
        approvalSettingType || ''
      }`
    );
    return result;
  } catch (error) {
    return error.response;
  }
};

export const UpdateSettingsForUnitApproval = async (id, body) => {
  try {
    const result = await HttpServices.put(
      `${config.server_address}/CrmDfm/UnitApproval/UpdateSettingsForUnitApproval/${id}`,
      body
    );
    return result;
  } catch (error) {
    return error.response;
  }
};

export const DeleteUnitApprovelSettings = async (id) => {
  try {
    const result = await HttpServices.delete(
      `${config.server_address}/CrmDfm/UnitApproval/DeleteUnitApprovelSettings/${id}`
    );
    return result;
  } catch (error) {
    return error.response;
  }
};
