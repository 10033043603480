import React, { useCallback, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import {
  Spinner, Tables, DialogComponent, SwitchComponent
} from '../../../../Components';
import { TableActions } from '../../../../Enums';
import { GlobalHistory, showError, showSuccess } from '../../../../Helper';
import { OwnerRotationSchemaViewDetails } from '../OwnerRotationManagement/OwnerRotationSchemaViewDetails';
import { DeleteOwnerRotationCriteriaDialog } from '../OwnerRotationManagement';
import { RotationCriteriaDialog } from '../OwnerRotationManagement/RotationCriteriaDialog/RotationCriteriaDialog';
import { GetRotationSchemeByIdServices, UpdateRotationScheme } from '../../../../Services/RotaionSchemaService/RotationSchemaService';
import { ActiveItemActions } from '../../../../store/ActiveItem/ActiveItemActions';

export const OwnerRotationTableView = ({
  parentTranslationPath,
  translationPath,
  filter,
  data,
  isLoading,
  reloadData,
  activeItem,
  setActiveItem,
  onPageIndexChanged,
  onPageSizeChanged,
  setSortBy
}) => {
  const [isOpenEditDialog, setIsOpenEditDialog] = useState(false);
  const [openDeleteDialog, setopenDeleteDialog] = useState(false);
  const [isOpenDialog, setisOpenDialog] = useState(false);
  const [IsEditLoading, setIsEditLoading] = useState(false);
  const [rotationEdit, setRotationEdit] = useState(null);
  const { t } = useTranslation(parentTranslationPath);
  const dispatch = useDispatch();
  const [isDeactive, setIsDeactive] = useState(null);
  const tableActionClicked = useCallback((actionEnum, item) => {
    dispatch(ActiveItemActions.activeItemRequest(item));
    setActiveItem(item);
    if (actionEnum === TableActions.view.key)
      setisOpenDialog(true);
    else if (actionEnum === TableActions.edit.key)
      setIsOpenEditDialog(true);
    else if (actionEnum === TableActions.manageAgents.key) {
      GlobalHistory.push(`/home/OwnerRotation/manage?id=${item.rotationSchemeId}`);
      dispatch(ActiveItemActions.activeItemRequest(item));
} else if (actionEnum === TableActions.delete.key)
      setopenDeleteDialog(true);
    else if (actionEnum === TableActions.preview.key)
      setisOpenDialog(true);
  }, []);

  const getActionTableWithPermissions = () => {
    const list = [];
    list.push({ enum: TableActions.manageAgents.key, title: t(`${translationPath}Shared:manageAgents`) });
    list.push({ enum: TableActions.preview.key, title: t(`${translationPath}Shared:preview`) });
    list.push({ enum: TableActions.edit.key, title: t(`${translationPath}Shared:edit`) });
    list.push({ enum: TableActions.delete.key, title: t(`${translationPath}Shared:delete`) });
    return list;
  };

  const GetRotationCriteria = useCallback(async () => {
    const res = await GetRotationSchemeByIdServices(activeItem && activeItem.rotationSchemeId);
    if (!(res && res.status && res.status !== 200))
      setRotationEdit(res);
  }, [activeItem]);
  useEffect(() => {
    if (activeItem && activeItem.rotationSchemeId)
      GetRotationCriteria();

    setRotationEdit(null);
  }, [activeItem]);

  const saveHandler = useCallback(async () => {
    setIsEditLoading(true);
    const { rotationSchemeId } = rotationEdit;
    const currntState = {
      rotationSchemeId: null,
      label: '',
      isForOwnerLeads: true,
      order: 0,
      rotationPreferredLanguages: [],
      rotationSchemeCountries: [],
      rotationSchemeCities: [],
      rotationSchemeCommunities: [],
      rotationSchemeDistricts: [],
      rotationSchemeSubCommunities: [],
      rotationSchemeUnitTypes: [],
      rotationSchemeRanges: [],
      rotationSchemaContactCLasses: [],
      rotationSchemeMedias: [],
      rotationSchemaDeveloperIds: [],
      rotationSchemaReferredBys: [],
      rotationSchemeProperties: [],
      rotationSchemaLeadsType: [],
      rotationSchemaMethodOfContacts: [],
      propertyCampaignIds: [],
      isActive: false,
      isBulk: 2,
      rotationSchemeLeadDistributionType: 3,
      isOverrideLeadCapacitySettingEnabled: false,
    };
    currntState.rotationSchemeId = rotationEdit.rotationSchemeId;
    rotationEdit.languages.map((item) => {
      currntState.rotationPreferredLanguages.push({
        languageName: item.lookupItemName,
        languageId: item.lookupsId,
      });
    });
    rotationEdit.countries.map((item) => {
      currntState.rotationSchemeCountries.push({
        countryName: item.lookupItemName,
        countryId: item.lookupsId,
      });
    });
    rotationEdit.cities.map((item) => {
      currntState.rotationSchemeCities.push({
        cityName: item.lookupItemName,
        cityId: item.lookupsId,
      });
    });
    rotationEdit.districts.map((item) => {
      currntState.rotationSchemeDistricts.push({
        districtName: item.lookupItemName,
        districtId: item.lookupsId,
      });
    });
    rotationEdit.communities.map((item) => {
      currntState.rotationSchemeCommunities.push({
        communityName: item.lookupItemName,
        communityId: item.lookupsId,
      });
    });
    rotationEdit.subCommunities.map((item) => {
      currntState.rotationSchemeSubCommunities.push({
        subCommunityName: item.lookupItemName,
        subCommunityId: item.lookupsId,
      });
    });
    rotationEdit.leadClasses.map((item) => {
      currntState.rotationSchemaContactCLasses.push({
        contactClassId: item.lookupsId,
        contactClassName: item.lookupItemName
      });
    });
    rotationEdit.unitTypes.map((item) => {
      currntState.rotationSchemeUnitTypes.push({
        unitTypeName: item.lookupItemName,
        unitTypeId: item.lookupsId,
      });
    });
    rotationEdit.developers.map((item) => {
      currntState.rotationSchemaDeveloperIds.push({
        developerName: item.developerName,
        developerId: item.developerId,
      });
    });

    rotationEdit.properties.map((item) => {
      currntState.rotationSchemeProperties.push({
        propertyName: item.propertyName,
        propertyId: item.propertyId,
      });
    });

    rotationEdit.referredBys.map((item) => {
      currntState.rotationSchemaReferredBys.push({
        userId: item.userId,
        fullName: item.fullName
      });
    });
    rotationEdit.medias.map((item) => {
      currntState.rotationSchemeMedias.push({
        mediaName: item.mediaName,
        mediaNameId: item.mediaId === 0 ? null : item.mediaId,
        mediaDetailsName: item.mediaDetails,
        mediaDetailsId: item.mediaDetailsId === 0 ? null : item.mediaDetailsId,
      });
    });
    rotationEdit.rotationSchemeRanges.map((item) => {
      currntState.rotationSchemeRanges.push({
        ...item,
      });
    });
    rotationEdit.methodOfContact.map((item) => {
      currntState.rotationSchemaMethodOfContacts.push({
        methodOfContactName: item.methodOfContactName,
        methodOfContactId: item.methodOfContactId
      });
    });
    rotationEdit.leadsType.map((item) => {
      currntState.rotationSchemaLeadsType.push({
        leadClass: item.leadClass.toLowerCase()
      });
    });
    rotationEdit.rotationSchemeCampaigns.map((item) => {
      currntState.propertyCampaignIds.push(item.propertyCampaignId);
    });
    currntState.label = rotationEdit.label;
    currntState.isActive = !rotationEdit.isActive;
    currntState.order = rotationEdit.order;

    currntState.isBulk = rotationEdit.isBulk;
    currntState.isOverrideLeadCapacitySettingEnabled = rotationEdit.isOverrideLeadCapacitySettingEnabled;
    currntState.rotationSchemeLeadDistributionType = rotationEdit.rotationSchemeLeadDistributionType;

    const res = await UpdateRotationScheme(rotationSchemeId, currntState);

    if (!(res && res.status && res.status !== 200)) {
      showSuccess(t`${translationPath}Edit-Rotation-Scheme-status-successfully`);
      setIsDeactive(null);
      setActiveItem(null);
      reloadData();
    } else
      showError(t('Shared:Server-Error'));

    setIsEditLoading(false);
  }, [isDeactive, rotationEdit]);

  useEffect(() => {
    if (rotationEdit && isDeactive)
      saveHandler();
  }, [isDeactive, rotationEdit]);

  return (
    <div className='policiesTabelView'>
      <Spinner isActive={IsEditLoading} isAbsolute />
      <div className='filter-section-item' />
      <div className='w-100 px-2'>
        <Tables
          data={(data && data.result) || []}
          translationPath={translationPath}
          parentTranslationPath={parentTranslationPath}
          headerData={[
            {
              id: 1,
              isSortable: true,
              input: 'label',
              label: t(`${translationPath}label`),
              component: (item) => (
                <div>
                  {item && item.label}
                </div>
              ),
            },
            {
              id: 2,
              isSortable: true,
              input: 'order',
              label: 'order',
            },
            {
              id: 3,
              label: 'isBulk',
              component: (item) => <span>{((item && item.isBulk === true) && t('bulk')) || ((item && item.isBulk === false && t('fresh-lead'))) || t('bulk-and-fresh-leads') }</span> || ''
            },
            {
              id: 4,
              label: t(`${translationPath}agents`),
              component: (item) => (
                <span
                  className='c-primary'
                >
                  {(item && item.numberOfAgents) || 0}
                </span>
              ),
            },
            {
              id: 5,
              label: `${t('status')}`,
              input: 'isActive',
              component: (item, index) => (
                <SwitchComponent
                  idRef={`isOpenRef${index + 1}`}
                  isChecked={item.isActive || false}
                  // isDisabled={!(returnPropsByPermissions(RotationSchemaPermissions.SetARotationAsActiveDeactivated.permissionsId))}
                  labelClasses='px-0'
                  onChangeHandler={() => {
                    setActiveItem(item);
                    setIsDeactive(item);
                  }}
                  themeClass='theme-line'
                  labelValue={(item.isActive && `${t('Active')}`) || `${t('Deactive')}`}
                  parentTranslationPath={parentTranslationPath}
                  translationPath={translationPath}
                />
              )
            },
            {
              id: 5,
              label: t(`${translationPath}createdOn`),
              component: (item) => (
                <div>
                  {moment(item.createdOn).format('DD/MM/YYYY')}
                </div>
              )
            },
            {
              id: 6,
              label: t(`${translationPath}created-by`),
              input: 'createdBy',
            },
            {
              id: 7,
              label: t(`${translationPath}updateOn`),
              component: (item) => (
                <div>
                  {moment(item.updatedOn).format('DD/MM/YYYY')}
                </div>
              )
            },
          ]}
          defaultActions={getActionTableWithPermissions()}
          actionsOptions={{
            onActionClicked: tableActionClicked,
          }}
          setSortBy={setSortBy}
          itemsPerPage={filter.pageSize}
          activePage={filter.pageIndex}
          totalItems={(data && data.totalCount) || 0}
          onPageIndexChanged={onPageIndexChanged}
          onPageSizeChanged={onPageSizeChanged}
        />
      </div>
      {isOpenEditDialog && (
        <RotationCriteriaDialog
          open={isOpenEditDialog}
          close={() => {
            setIsOpenEditDialog(false);
          }}
          rotationEdit={rotationEdit}
          onSave={() => {
            // setOpenDialog(false);
            // reload data
            reloadData();
          }}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
        />
      )}
      {isOpenDialog && (
        <DialogComponent
          titleText='rotation-schema-details'
          SmothMove
          saveType='button'
          maxWidth='lg'
          dialogContent={(
            <div className='d-flex-column-center'>
              <OwnerRotationSchemaViewDetails activeItem={activeItem} />
            </div>
          )}
          saveClasses='btns theme-solid bg-danger w-100 mx-2 mb-2'
          isOpen={isOpenDialog}
          onCloseClicked={() => { setisOpenDialog(false); }}
          onCancelClicked={() => { setisOpenDialog(false); }}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
        />
      )}
      {openDeleteDialog && (
        <DeleteOwnerRotationCriteriaDialog
          rotationCriteria={activeItem}
          open={openDeleteDialog}
          close={() => {
            setopenDeleteDialog(false);
            const currentActive = {};
            setActiveItem(currentActive);
          }}
          onSave={() => {
            setopenDeleteDialog(false);
            reloadData();
            const currentActive = {};
            setActiveItem(currentActive);
          }}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
        />
      )}
    </div>
  );
};

OwnerRotationTableView.propTypes = {
  parentTranslationPath: PropTypes.string.isRequired,
  translationPath: PropTypes.string.isRequired,
  filter: PropTypes.number.isRequired,
  data: PropTypes.instanceOf(Object).isRequired,
  isLoading: PropTypes.bool.isRequired,
  onPageIndexChanged: PropTypes.func.isRequired,
  onPageSizeChanged: PropTypes.func.isRequired,

};
