



import React , { useState , useCallback , useEffect , useRef }  from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { CheckboxesComponent , AutocompleteComponent  } from '../../../../../../../Components' ; 
import { OrganizationUserSearch } from '../../../../../../../Services' ;  

    
export const LeadsReferredBy  = ({
  parentTranslationPath,
  translationPath,
  state,
  setState , 
  checkAtLeastOneSelected , 
  checkAtLeastOneSelected2 , 
  setDefaultSelected, 

}) => {
    const { t } = useTranslation(parentTranslationPath);

    const [allUsers , setAllUsers] =  useState([]) ;
    const [isLoading ,   setIsLoading ] = useState(false) ;  

    const searchTimer = useRef(null);
    let timer = null; 

    const getAllOrganizationUserSearch = useCallback(async (searchValue) => {
      setIsLoading(true);
      const res = await OrganizationUserSearch({ pageIndex: 0, pageSize: 10, name: searchValue  , userStatusId : 2 });
      if (!(res && res.status && res.status !== 200)) 
      setAllUsers(res.result.map((item) => ({
        id :  item.id , 
        fullName : item.fullName  , 
        branch : item.branch  || null  , 
      })));
       else 
       setAllUsers([]);  

       setIsLoading(false);

    }, []); 

    useEffect(() => {
      getAllOrganizationUserSearch() ;
    }, []); 

  return (
    <div className='content'>
    <div className='w-100 px-2 mt-2 checkBoxsPart'>
      <div className='checkboxesItem'>
         <CheckboxesComponent
          idRef='LeadsReferredByViewAllLeadsRef'
          label={t(`${translationPath}view-all-leads`)}
          parentTranslationPath={parentTranslationPath}
          translationPathForData={translationPath}
          singleChecked={state.leads.referredBy.allUsers}
          onSelectedCheckboxClicked={(e)=> {
            const checkIfCanSelected  = checkAtLeastOneSelected({ ...state.leads.referredBy ,  allUsers : e.target.checked } );
             
            if(checkAtLeastOneSelected2(state.leads.referredBy.specificUsers));
            else if(!checkIfCanSelected)
                 return ; 

            const leadsReferredBy =  { ...state.leads.referredBy ,
               allUsers : (e.target.checked)   , 
               myUser  : false  , 
               myTeamUsers : false , 
               myBusinessGroupUsers : false , 
               myBranchUsers :false , 
               myManageUsers : false ,
               specificUsers :[] , 
               myTeamLeadUsers : false ,  
             } ;
          setState({id : 'leads' , value : { ...state.leads , referredBy  :   leadsReferredBy  } });
         }}
         />
         </div>
         <div className='checkboxesItem'>
         <CheckboxesComponent
          idRef='leadsReferredByViewMyLeadsRef'
          label={t(`${translationPath}view-my-leads`)}
          parentTranslationPath={parentTranslationPath}
          translationPathForData={translationPath}
          singleChecked={state.leads.referredBy.myUser}
          onSelectedCheckboxClicked={(e)=> {
            const checkIfCanSelected  = checkAtLeastOneSelected({ ...state.leads.referredBy ,  myUser : e.target.checked } );

            if(checkAtLeastOneSelected2(state.leads.referredBy.specificUsers));
            else if(!checkIfCanSelected)
                 return ; 

            const leadsReferredBy =  { ...state.leads.referredBy ,
               allUsers : false   , 
               myUser  : (e.target.checked)   , 
               myTeamUsers : false , 
               myBusinessGroupUsers : false , 
             } ;
          setState({id : 'leads' , value : { ...state.leads , referredBy  :   leadsReferredBy  } });
         }}
         />
         </div>

        <div className='checkboxesItem'>
         <CheckboxesComponent
          idRef='LeadsReferredByViewMyTeamLeadsRef'
          label={t(`${translationPath}view-my-team-leads`)}
          parentTranslationPath={parentTranslationPath}
          translationPathForData={translationPath}
          singleChecked={state.leads.referredBy.myTeamUsers}
          onSelectedCheckboxClicked={(e)=> {
            const checkIfCanSelected  = checkAtLeastOneSelected({ ...state.leads.referredBy ,  myTeamUsers : e.target.checked } );
            
            if(checkAtLeastOneSelected2(state.leads.referredBy.specificUsers));
            else if(!checkIfCanSelected)
                 return ; 

            const leadsReferredBy =  { ...state.leads.referredBy ,
               allUsers : false   , 
               myUser  :  false    , 
               myTeamUsers : (e.target.checked) , 
               myBusinessGroupUsers : false , 
             } ;
          setState({id : 'leads' , value : { ...state.leads , referredBy  :   leadsReferredBy  } });
         }}
         />
         </div>
         <div className='checkboxesItem'>
         <CheckboxesComponent
          idRef='LeadsReferredByViewMyBusinessGroupRef'
          label={t(`${translationPath}view-my-business-group-leads`)}
          parentTranslationPath={parentTranslationPath}
          translationPathForData={translationPath}
          singleChecked={state.leads.referredBy.myBusinessGroupUsers}
          onSelectedCheckboxClicked={(e)=> {
            const checkIfCanSelected  = checkAtLeastOneSelected({ ...state.leads.referredBy ,  myBusinessGroupUsers : e.target.checked } );

            if(checkAtLeastOneSelected2(state.leads.referredBy.specificUsers));
            else if(!checkIfCanSelected)
                 return ; 

            const leadsReferredBy =  { ...state.leads.referredBy ,
               allUsers : false   , 
               myUser  :  false    , 
               myTeamUsers : false  , 
               myBusinessGroupUsers : (e.target.checked) , 
             } ;
          setState({id : 'leads' , value : { ...state.leads , referredBy  :   leadsReferredBy  } });
         }}
         />
         </div>
        <div className='checkboxesItem'>
          <CheckboxesComponent
          idRef='LeadsReferredByViewMyBranchLeadsRef'
          label={t(`${translationPath}view-my-branch-leads`)}
          parentTranslationPath={parentTranslationPath}
          translationPathForData={translationPath}
          singleChecked={state.leads.referredBy.myBranchUsers}
          onSelectedCheckboxClicked={(e)=> {
            const checkIfCanSelected  = checkAtLeastOneSelected({ ...state.leads.referredBy ,  myBranchUsers : e.target.checked });

            if(checkAtLeastOneSelected2(state.leads.referredBy.specificUsers));
            else if(!checkIfCanSelected)
                 return ;  
      
            const leadsReferredBy =  { ...state.leads.referredBy ,
               allUsers : false   , 
               myBranchUsers:  (e.target.checked) 
             } ;
          setState({id : 'leads' , value : { ...state.leads , referredBy  :   leadsReferredBy  } });
         }}
         />
         </div>
        <div className='checkboxesItem'>
         <CheckboxesComponent
          idRef='LeadsReferredByViewMyManagedTeamLeadsRef'
          label={t(`${translationPath}view-my-managed-team-leads`)}
          parentTranslationPath={parentTranslationPath}
          translationPathForData={translationPath}
          singleChecked={state.leads.referredBy.myManageUsers}
          onSelectedCheckboxClicked={(e)=> {
            const checkIfCanSelected  = checkAtLeastOneSelected({ ...state.leads.referredBy ,  myManageUsers : e.target.checked });

            if(checkAtLeastOneSelected2(state.leads.referredBy.specificUsers));
             else if(!checkIfCanSelected)
              return ;  
               
            const leadsReferredBy =  { ...state.leads.referredBy ,
               allUsers : false   , 
               myManageUsers:  (e.target.checked) 
             } ;
          setState({id : 'leads' , value : { ...state.leads , referredBy  :   leadsReferredBy  } });
         }}
         />
       </div>
       <div className='checkboxesItem'>
         <CheckboxesComponent
          idRef='LeadsReferredByMyTeamLeadUsersRef'
          label={t(`${translationPath}view-my-team-lead-users`)}
          parentTranslationPath={parentTranslationPath}
          translationPathForData={translationPath}
          singleChecked={state.leads.referredBy.myTeamLeadUsers}
          onSelectedCheckboxClicked={(e)=> {
            const checkIfCanSelected  = checkAtLeastOneSelected({ ...state.leads.referredBy ,  myTeamLeadUsers : e.target.checked });

            if(checkAtLeastOneSelected2(state.leads.referredBy.specificUsers));
             else if(!checkIfCanSelected)
              return ;  
               
            const leadsReferredBy =  { ...state.leads.referredBy ,
               allUsers : false   , 
               myTeamLeadUsers:  (e.target.checked) 
             } ;
          setState({id : 'leads' , value : { ...state.leads , referredBy  :   leadsReferredBy  } });
         }}
         />
       </div>
       <div className='w-100 px-2 mt-2 mb-2'>
          <AutocompleteComponent
              idRef='leadsReferredBySpecificsUsersRef'
              labelValue={t(`${translationPath}specific_users`)}
              data={ allUsers || []}
              selectedValues={state.leads.referredBy.specificUsers}
              multiple
              displayLabel={(option) =>
              (option && option.fullName) || ''}
              chipsLabel={(option) => (option && option.fullName) || ''}
              withoutSearchButton
              parentTranslationPath={parentTranslationPath}
              translationPath={translationPath}
              onInputKeyUp={(e) => {
                const { value } = e.target;
                 if (searchTimer.current) clearTimeout(searchTimer.current);
                   searchTimer.current = setTimeout(() => {
                    getAllOrganizationUserSearch(value) ;  
                }, 700); 
                }}
                renderOption={
                  ((option) =>
                  <div className='d-flex-column'>
                   <div className='d-flex-v-center-h-between w-100 texts-truncate'>
                    {option.fullName}
                   </div>
                   <span className='c-gray-secondary'> {option.branch} </span>
                 </div>
               )}
              value={state.leads.referredBy.specificUsers}
              onChange={(e, newValues) => 
              {
                let LeadsReferredBy = { ...state.leads.referredBy  , specificUsers : newValues };
                if( newValues && !(newValues.length) && setDefaultSelected({ ...state.leads.referredBy , specificUsers :false }))
                   LeadsReferredBy = { ...state.leads.referredBy  , specificUsers : newValues , allUsers : true }
                setState({ id: 'leads', value: { ...state.leads , referredBy  : LeadsReferredBy } })
              }}
             isLoading={isLoading} 
             withLoader
             filterOptions={(options) => {
              const isFind = (id) => state.leads.referredBy.specificUsers.findIndex((w) => w.id === id) === -1;
             return options.filter((w) => isFind(w.id));
            }}
            isDisabled={state.leads.referredBy.allUsers}
         /> 
        </div>
    </div>
  </div>
  );
};

LeadsReferredBy.propTypes = {
  parentTranslationPath: PropTypes.string.isRequired,
  translationPath: PropTypes.string.isRequired,
  state:PropTypes.instanceOf(Object).isRequired,
  setState : PropTypes.func.isRequired,
  checkAtLeastOneSelected : PropTypes.func.isRequired, 
  checkAtLeastOneSelected2 :PropTypes.func.isRequired, 
  setDefaultSelected:PropTypes.func.isRequired, 
};
