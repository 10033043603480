import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { PropTypes } from 'prop-types';
import { PhonesComponent } from '../../../../../../../../Components/Controls/PhonesComponent/PhonesComponent';
import { RepeatedItemDialog } from '../../../../../../FormBuilder/Dialogs/RepeatedItemDialog' ; 

export const MobileNumberComponent = ({
  parentTranslationPath,
  translationPath,
  mobileNumbers,
  setNumber,
  isSubmitted,
  helperText,
  error,
  isReloadData ,
  communicationId 
}) => {
  const { t } = useTranslation([parentTranslationPath, 'Shared']);
  const [repeatedItemDialog, setRepeatedItemDialog] = useState(false);
    const [timer  , setTimer] = useState(null)
    const [HelperText ,  setHelperText ] =useState("")
    const [Error ,  setError ] =useState("")
  const mobileItem = {
    data: {
      type: 'string',
      title: 'Mobile *',
      description: 'Main Information',
      lookupItem: 18027,
      defaultCountryCode: '+971',
      CommunicationType: 'Phone',
    },
    field: { id: 'mobile', FieldType: 'communication', Required: 'true' },
  };

  const [mobileNumbersState, setMobileNumbersStates] = useState({
    phone: '971',
    typeId: communicationId
  });


  useEffect(() => {


    if (mobileNumbers && mobileNumbers.length === 1)
      setMobileNumbersStates({   typeId: communicationId , phone: (mobileNumbers[0] &&  mobileNumbers[0]?.communicationValue) || '' });
     else  if (mobileNumbers && mobileNumbers.length > 1) {
        let mobileNumbersList = mobileNumbers.map((m)=> m.communicationValue);
         mobileNumbersList.splice(0, 1);
        setMobileNumbersStates({typeId: communicationId ,phone:  mobileNumbers[0]?.communicationValue , others: [...mobileNumbersList] });
    }
   }, []);


   useEffect(() => {
    let list2 = [];
    if (mobileNumbersState && mobileNumbersState.phone !== '971' && mobileNumbersState.phone !== '' )
    list2.push({communicationTypeId : communicationId  , communicationValue :  mobileNumbersState?.phone});
    if (mobileNumbersState && mobileNumbersState.others && mobileNumbersState.others.length > 0 )
      mobileNumbersState.others.map((item) => {
       if(item !== '')
        list2.push({communicationTypeId : communicationId  , communicationValue : (item )})
      })
    setNumber([...list2]);
   }, [mobileNumbersState]);

   useEffect(() => 
    {
      if(isReloadData !== null && isReloadData !== undefined )
        setMobileNumbersStates({
          phone: '',
          typeId: 18027 
        });
   }, [isReloadData]);

   

  return (
    <div className='w-100'>
      <PhonesComponent
        value={mobileNumbersState.phone}
        item={mobileItem}
        idRef='phoneNumberRef'
        labelValue={t(`${translationPath}phoneNumber`)}
        inputPlaceholder={t(`${translationPath}phoneNumber`)}
        buttonOptions={{
          className: 'btns-icon theme-solid bg-blue-lighter',
          iconClasses: 'mdi mdi-plus',
          isDisabled: !(mobileNumbersState && mobileNumbersState.phone && mobileNumbersState.phone.length >= 9),
          isRequired: false,
          onActionClicked: () => {
            if (mobileNumbersState && mobileNumbersState.phone.length >= 9)
              setRepeatedItemDialog(true);
          },
        }}
        onInputChanged={(newValue) => {
          if (newValue.length > 14) 
            return ; 
            if(newValue === '')
              setMobileNumbersStates({phone : '' , typeId: communicationId}) ; 

        
         else   setMobileNumbersStates({ ...mobileNumbersState, phone: newValue })
        }}
        isSubmitted={isSubmitted}
        helperText={helperText || HelperText}
        error={error || Error}
      />

      {repeatedItemDialog && (
        <RepeatedItemDialog
          open={repeatedItemDialog}
          item={mobileItem}
          type='phone'
          initialState={mobileNumbersState}
          label={mobileItem.data.title}
          onChange={(data) => {
             setMobileNumbersStates({ ...data });
          }}
          closeDialog={() => {
            setRepeatedItemDialog(false);
          }}
        />
      )}
    </div>
  );
};
MobileNumberComponent.propTypes = {
  mobileNumbers: PropTypes.string.isRequired,
  setNumber: PropTypes.func.isRequired,
  isSubmitted: PropTypes.bool.isRequired,
  helperText: PropTypes.string.isRequired,
  error: PropTypes.string.isRequired,
  translationPath: PropTypes.string.isRequired,
  parentTranslationPath: PropTypes.string.isRequired,

};
