import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Button } from '@material-ui/core';
import {
  DialogComponent, PaginationComponent, Spinner, SwitchComponent, Tables
} from '../../../../../../../../Components';
import {
  bottomBoxComponentUpdate, getDownloadableLink, GetParams, showError, showSuccess
} from '../../../../../../../../Helper';
import { TableActions } from '../../../../../../../../Enums';
import { DeletePropertyCampaignAPI, GetAllPropertyOffers } from '../../../../../../../../Services/OfferServices';
import { AddEditMarketingOffers } from './AddEditMarketingOffers';

export const PropertyMarketingOffers = ({
  onStateChanged,
  parentTranslationPath,
  translationPath,
}) => {
  const { t } = useTranslation([parentTranslationPath]);
  const [DialogState, setDialogState] = useState('Add');
  const [ActiveItem, setActiveItem] = useState(null);
  const [isOpen, setisOpen] = useState(false);
  const [isLoadingMain, setisLoadingMain] = useState(false);
  const [isOpenDelete, setisOpenDeleten] = useState(false);
  const [isOpenFiles, setisOpenFiles] = useState(false);
  const [ActiveFiles, setActiveFiles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [result, setresult] = useState([]);
  const MarketingCampaignsHeaderData = [
    {
      id: 1,
      label: 'offerName',
      input: 'offerName',
      isHiddenFilter: true,
      isSortable: false,
    }, {
      id: 2,
      label: 'propertyId',
      input: 'propertyId',
      isHiddenFilter: true,
      isSortable: false,
    }, {
      id: 3,
      label: 'enDescription',
      input: 'enDescription',
      isHiddenFilter: true,
      isSortable: false,
    }, {
      id: 4,
      label: 'arDescription',
      input: 'arDescription',
      isHiddenFilter: true,
      isSortable: false,
    },
    {
      id: 5,
      label: 'StartDate',
      input: 'startDate',
      isHiddenFilter: true,
      isSortable: false,
      isDate: true,
      dateFormat: 'DD/MM/YYYY',
    },
    {
      id: 6,
      label: 'EndDate',
      input: 'endDate',
      isHiddenFilter: true,
      isSortable: false,
      isDate: true,
      dateFormat: 'DD/MM/YYYY',
    },
    {
      id: 7,
      label: `${t('status')}`,
      input: 'isActive',
      component: (item, index) => (

        (item && item.status === 1) && (
          <div className='globel-blue-style'>
            {' '}
            (
            {t('Planned')}
            )
          </div>
        ) ||
        (item && item.status === 2) && (
          <div className='globel-open-style'>
            {' '}
            (
            {t('Open')}
            )
            {' '}
          </div>
        ) ||
        (item && item.status === 3) && (
          <div>
            {' '}
            (
            {t('Expired')}
            )
          </div>
        ) ||
        (item && item.status === 4) && (
          <div className='globel-closed-style'>
            {' '}
            (
            {t('Closed')}
            )
            {' '}
          </div>
        ) || ''

      )
    }, {
      id: 8,
      label: `${t('Files')}`,
      input: 'isActive',
      component: (item) => (

        (item && item.propertyOfferFiles && item.propertyOfferFiles.length > 0) && (
          <Button
            onClick={() => {
              setisOpenFiles(true);
              setActiveFiles(item && item.propertyOfferFiles || []);
            }}
            className='MuiButtonBase-root MuiButton-root MuiButton-text table-action-btn  btns-icon theme-solid bg-secondary'
          >
            <span className='MuiButton-label'>
              <span className='table-action-icon  mdi mdi-file' />
            </span>
          </Button>
        ) || ''
      )
    }
  ];
  const [filter, setFilter] = useState({
    // eslint-disable-next-line radix
    pageSize: parseInt(localStorage.getItem('Pagination')) || 25,
    pageIndex: 1,
  });

  const defaultTableActions = [
    {
      enum: TableActions.editText.key,
      isDisabled: false,
      externalComponent: null,
    },
    {
      enum: TableActions.deleteText.key,
      isDisabled: false,
      externalComponent: null,
    },
  ];

  const tableActionClicked = useCallback((actionEnum, item) => {
    if (actionEnum === TableActions.editText.key) {
      setActiveItem(item);
      setisOpen(true);
      setDialogState('edit');
    } else if (actionEnum === TableActions.deleteText.key) {
      setActiveItem(item);
      setisOpenDeleten(true);
    }
  }, []);
  const GetAllPropertyOffersAPI = useCallback(async (propertyId, pageIndex, pageSize) => {
    setisLoadingMain(true);
    const res = await GetAllPropertyOffers(

      propertyId,
      pageIndex,
      pageSize,
    );

    if (!(res && res.status && res.status !== 200)) setresult(res);

    else setresult([]);

    setisLoadingMain(false);
  }, []);

  const onPageIndexChanged = (pageIndex) => {
    setFilter((item) => ({ ...item, pageIndex }));
  };
  const onPageSizeChanged = (pageSize) => {
    setFilter((item) => ({ ...item, pageIndex: 0, pageSize }));
  };

  useEffect(() => {
    bottomBoxComponentUpdate(
      <PaginationComponent
        pageIndex={filter.pageIndex - 1}
        pageSize={filter.pageSize}
        totalCount={result.totalCount}
        onPageIndexChanged={onPageIndexChanged}
        onPageSizeChanged={onPageSizeChanged}
      />
    );
  });

  const deleteHandler = async (propertyCampaignId) => {
    setIsLoading(true);
    const res = await DeletePropertyCampaignAPI(propertyCampaignId);
    if (!(res && res.status && res.status !== 1)) {
      GetAllPropertyOffersAPI(+GetParams('id'), filter.pageIndex, filter.pageSize);
      setIsLoading(false);
      setisOpenDeleten(false);
      showSuccess(t(`${translationPath}delete-successfully`));
    } else {
      showError(t(`${translationPath}delete-failed`));
      setisOpenDeleten(false);
    }
    setIsLoading(false);
  };
  useEffect(() => {
    GetAllPropertyOffersAPI(+GetParams('id'), filter.pageIndex, filter.pageSize);
  }, [filter.pageIndex, filter.pageSize]);
  return (
    <>
      <Spinner isActive={isLoadingMain} isAbsolute />
      <div className='property-marketing-remarks-wrapper presentational-wrapper'>

        <div className='title-wrapper'>
          <span className='title-section'>{t(`${translationPath}offers`)}</span>
        </div>
        <Button className='btns mx-3 mb-2 theme-solid' onClick={() => { setisOpen(true); setDialogState('add'); }}>
          <span className='mdi mdi-plus' />
          {t(`${translationPath}add-new`)}
        </Button>
        <div className='w-100'>
          <Tables
            data={(result && result.result) || []}
            headerData={MarketingCampaignsHeaderData}
            actionsOptions={{
              onActionClicked: tableActionClicked,
            }}
            itemsPerPage={filter.pageSize}
            activePage={filter.pageIndex}
            defaultActions={defaultTableActions}
            parentTranslationPath={parentTranslationPath}
            translationPath={translationPath}
            isLoading={isLoading}
          />
        </div>
        <DialogComponent
          titleText='confirm-message'
          saveText='confirm'
          saveType='button'
          maxWidth='sm'
          dialogContent={(
            <AddEditMarketingOffers
              reloadData={() => {
                setisLoadingMain(true);
                setTimeout(() => {
                  GetAllPropertyOffersAPI(+GetParams('id'), filter.pageIndex, filter.pageSize);
                }, 1500);
              }}
              ActiveItem={ActiveItem}
              DialogState={DialogState}
              onCancelClicked={() => setisOpen(false)}
              parentTranslationPath={parentTranslationPath}
              translationPath={translationPath}
            />
          )}
          saveClasses='btns theme-solid bg-danger w-100 mx-2 mb-2'
          isOpen={isOpen}
          onCloseClicked={() => setisOpen(false)}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
        />
        <DialogComponent
          titleText={t(`${translationPath}confirm-message`)}
          saveText='confirm'
          saveType='button'
          maxWidth='sm'
          dialogContent={(
            <div className='d-flex-column-center'>
              <Spinner isActive={isLoading} isAbsolute />
              <span className='mdi mdi-close-octagon c-danger mdi-48px' />
              <span>{`${t(`${translationPath}delete-offers`)}`}</span>
              <span>{(ActiveItem && ActiveItem.offerName) || ''}</span>
            </div>
          )}
          saveClasses='btns theme-solid bg-danger w-100 mx-2 mb-2'
          isOpen={isOpenDelete}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          onSaveClicked={() => deleteHandler(ActiveItem && ActiveItem.propertyOfferId)}
          onCloseClicked={() => setisOpenDeleten(false)}
          onCancelClicked={() => setisOpenDeleten(false)}
        />
        <DialogComponent
          titleText={t(`${translationPath}files-view`)}
          maxWidth='sm'
          dialogContent={(
            <div className='d-flex-column-center'>

              {ActiveFiles &&
                ActiveFiles.map((item) => (
                  <div className='files-properties-profile-marketing-privte'>
                    <div>
                      {item && item.fileName || ''}
                    </div>
                    <div>
                      <Button
                        onClick={() => {
                          const link = document.createElement('a');
                          link.setAttribute('download', item.fileName);
                          link.href = getDownloadableLink(item.fileId);
                          document.body.appendChild(link);
                          link.click();
                          link.remove();
                        }}
                        className='MuiButtonBase-root MuiButton-root MuiButton-text table-action-btn  btns-icon theme-solid bg-secondary'
                      >
                        <span className='MuiButton-label'>
                          <span className='table-action-icon  mdi mdi-download-outline' />
                        </span>
                      </Button>
                    </div>
                  </div>

                ))}
            </div>
          )}
          isOpen={isOpenFiles}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          onCloseClicked={() => setisOpenFiles(false)}
        />
      </div>
    </>
  );
};
