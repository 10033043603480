
import React from 'react';
import { DialogComponent} from '../DialogComponent/DialogComponent'  ; 
import { useTranslation } from 'react-i18next'; 


export const HighPofileManagementDialog = ({
  translationPath,
  isOpenDialog,
  onClose,
  onSave , 
  withCreateLeadForm , 
}) => {

  const { t } = useTranslation('Shared');

  return (
    <div>
     <DialogComponent
        titleText='confirm-message'
        saveText='confirm'
        saveType='button'
        maxWidth='sm'
        dialogContent={(
          <div className='d-flex-column-center'>
            <span className='mdi mdi-close-octagon c-danger mdi-48px' />

            <span> 
           {
             !withCreateLeadForm ? 
             `${t(`Angry-Bird-atomatically-close-all-leads`)}` 
             :`${t(`${translationPath}Shared:Whoa-hold-on-This-lead-seems-A-bit`)}`
             }
            </span>
          </div>
        )}
        saveClasses='btns theme-solid bg-danger w-100 mx-2 mb-2'
        isOpen={isOpenDialog}
        onSaveClicked={() => {
          if(onSave)
          onSave(); 

         onClose();
        }}
        onCloseClicked={() => {
            onClose(false);
        }}
        onCancelClicked={() => {
            onClose(false);
        }}
        parentTranslationPath={'Shared'}
        translationPath={translationPath}
      />
    </div>
  );
};
