import React from "react";
import { Box } from "@material-ui/core";
import { CustomCard } from "../../CustomCard";
import { Skeleton } from "@material-ui/lab";

//styles
import useStyles from "./styles";

function ContactTaskCardSkeleton({ NumberSkeletonCard }) {
  const styles = useStyles();

  const skeletonCards = Array.from({ length: NumberSkeletonCard }).map(
    (_, index) => (
      <CustomCard
        key={index}
        boxShadow="xs"
        borderRadius="sm"
        classes={styles.container_Task_Card_Skeleton}
      >
        <Box className={styles.header_card_Container}>
          <Box className={styles.container_Date}>
            <Skeleton
              animation="wave"
              width="95px"
              height="24px"
              variant="text"
            />
            <Skeleton
              animation="wave"
              width="95px"
              height="24px"
              variant="text"
            />
          </Box>
          <Skeleton
            animation="wave"
            width="184px"
            height="28px"
            variant="text"
          />
        </Box>
        <Box className={styles.Status_Task_Container}>
          <Skeleton
            style={{ borderRadius: "100px" }}
            animation="wave"
            width="57.5px"
            height="24px"
            variant="circle"
          />
          <Skeleton
            animation="wave"
            width="100px"
            height="28px"
            variant="text"
          />
        </Box>
        <Box className={styles.Details_Task_Container}>
          <Box className={styles.Deatails_Row}>
            <Skeleton
              animation="wave"
              width="82px"
              height="24px"
              variant="text"
            />
            <Box className={styles.container_Avatar_Value_Asignto}>
              <Skeleton
                animation="wave"
                width="24px"
                height="24px"
                variant="circle"
              />
              <Skeleton
                animation="wave"
                width="122px"
                height="24px"
                variant="text"
              />
            </Box>
          </Box>
          <Box className={styles.Deatails_Row}>
            <Skeleton
              animation="wave"
              width="106px"
              height="24px"
              variant="text"
            />
            <Skeleton
              animation="wave"
              width="122px"
              height="24px"
              variant="text"
            />
          </Box>
        </Box>
        <Box className={styles.Progress_Task_Container}>
          <Skeleton animation="wave" width="100%" height="1px" variant="text" />
        </Box>
        <Box className={styles.Creator_Task_Container}>
          <Skeleton
            animation="wave"
            width="87px"
            height="24px"
            variant="text"
          />
          <Skeleton
            animation="wave"
            width="87px"
            height="24px"
            variant="text"
          />
        </Box>
      </CustomCard>
    )
  );
  return <>{skeletonCards}</>;
}
export default ContactTaskCardSkeleton;
