import { ApprovalsConfigurationView } from "../../Views/Home";

export const ApprovalsConfigurationRoutes = [
  {
    path: "/view",
    name: 'ApprovalsConfiguration:approvals-configuration',
    component: ApprovalsConfigurationView,
    layout: "/home/approvals-configuration",
    default: true,
    isRoute: true,
    authorize: true,
    roles: [],
    isDisabled: false,
    isExact: true,
    breadcrumbs: [
      {
        name: 'ApprovalsConfiguration:approvals-configuration',
        isDisabled: true,
        route: "/home/approvals-configuration/view",
        groupName: "system-configuration",
      },
    ],
  },
];