import React, { useEffect, useState } from 'react';
import { SelectComponet, Tables } from '../../../Components';
import { TableActions } from '../../../Enums';
import { useTranslation } from 'react-i18next';
import headerData from './UsersManagementHeaderData';
import { GetAllUsersForBillingByBranchId } from '../../../Services';
import { GetParams, GlobalHistory } from '../../../Helper';

const parentTranslationPath = 'UsersManagement';

export const UsersManagement = () => {
  const isCRM = GetParams('isCRM');

  const { t } = useTranslation(parentTranslationPath);
  const [isLoading, setIsLoading] = useState(false);

  const [filter, setFilter] = useState({
    name: '',
    pageIndex: 0,
    pageSize: 25,
    branchId: GetParams('branchId'),
    isCRM,
  });
  const [data, setData] = useState({ result: [], totalCount: 0 });
  const onActionClicked = (actionType, row) => {
    if (actionType === TableActions.view.key) {
      GlobalHistory.push(`/home/Users/edit?id=${row.userId}`);
    }
  };

  const getAllUsersForBillingByBranchId = async () => {
    setIsLoading(true);
    const result = await GetAllUsersForBillingByBranchId({
      ...filter,
    });
    setData(result);
    setIsLoading(false);
  };
  useEffect(() => {
    getAllUsersForBillingByBranchId();
  }, [filter, isCRM]);

  const onFilterChange = (field, value) => {
    setFilter((filter) => ({ ...filter, [field]: value }));
  };

  return (
    <div>
      <div className='p-3'>
        <SelectComponet
          data={['CRM', 'MyLead']}
          labelValue={'Application'}
          wrapperClasses={'w-25'}
          value={
            (isCRM === 'true' && 'CRM') || (isCRM === 'false' && 'MyLead') || ''
          }
          // onSelectChanged={(value) => setIsCRM(value === 'CRM' ? true : false)}
          isDisabled
        />
      </div>
      <Tables
        isLoading={isLoading}
        headerData={headerData}
        data={data.result}
        defaultActions={[{ enum: TableActions.view.key }]}
        actionsOptions={{ onActionClicked }}
        parentTranslationPath={parentTranslationPath}
        itemsPerPage={filter.pageSize}
        activePage={filter.pageIndex}
        totalItems={data.totalCount}
        onPageIndexChanged={(index) => onFilterChange('pageIndex', index)}
        onPageSizeChanged={(index) => onFilterChange('pageSize', index)}
      />
    </div>
  );
};
