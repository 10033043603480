const ActiveUserLogHistoryHeader = [
  {
    id: 1,
    label: 'line-manager-action-status',
    input: 'lineManagerActionStatus',
    isSortable: true,
    isHiddenFilter: true,
    isDate: false,
  },
  {
    id: 2,
    label: 'line-manager-action-date',
    input: 'lineManagerActionDate',
    isSortable: true,
    isHiddenFilter: true,
    isDate: true,
  },
  {
    id: 3,
    label: 'line-manager-action-by',
    input: 'lineManagerActionBy',
    isSortable: true,
    isHiddenFilter: true,
    isDate: false,
  },
  {
    id: 4,
    label: 'training-center-action-status',
    input: 'trainingCenterActionStatus',
    isSortable: true,
    isHiddenFilter: true,
    isDate: false,
  },
  {
    id: 5,
    label: 'training-center-action-date',
    input: 'trainingCenterActionDate',
    isSortable: true,
    isHiddenFilter: true,
    isDate: true,
  },
  {
    id: 6,
    label: 'training-center-action-by',
    input: 'trainingCenterActionBy',
    isSortable: true,
    isHiddenFilter: true,
    isDate: false,
  },
  {
    id: 7,
    label: 'CRM-admin-action-status',
    input: 'crmAdminActionStatus',
    isSortable: true,
    isHiddenFilter: true,
    isDate: false,
  },
  {
    id: 8,
    label: 'CRM-admin-action-date',
    input: 'crmAdminActionDate',
    isSortable: true,
    isHiddenFilter: true,
    isDate: true,
  },
  {
    id: 9,
    label: 'CRM-admin-action-by',
    input: 'crmAdminActionBy',
    isSortable: true,
    isHiddenFilter: true,
    isDate: false,
  },
];

export default ActiveUserLogHistoryHeader;
