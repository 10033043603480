import React, { useEffect, useState } from "react";
import {
  AutocompleteComponent,
  DialogComponent,
  Inputs,
  Spinner,
} from "../../../../Components";
import { useTranslation } from "react-i18next";
import { showError, showSuccess } from "../../../../Helper";
import {
  GetLookupItemsByLookupTypeName,
  TransactionManualApprove,
  TransactionManualCancel,
  TransactionReassignedToRequester,
  TransactionWithdraw,
} from "../../../../Services";
import Joi from "joi";

function AMLActionsDialog({
  reason,
  isOpen,
  setIsOpenDialog,
  translationPath,
  parentTranslationPath,
  AMLTransactions,
  IDS,
  setIDS,
  APIGetAllAMLTransactions,
}) {
  const { t } = useTranslation(parentTranslationPath);
  const [selectedReason, setSelectedReason] = useState(null);
  const [description, setDescription] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [reasonsList, setReasonsList] = useState([]);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const schema = Joi.object()
    .min(1)
    .messages({
      "object.base": t(`${translationPath}select-reason-before-submitting`),
      "object.min": t(`${translationPath}select-reason-before-submitting`),
    });

  const getAllApproveReasonsAPICall = async () => {
    const res = await GetLookupItemsByLookupTypeName({
      lookUpName: "AMLApprovedReason",
      pageSize: 100,
      pageIndex: 1,
    });

    if (!(res && res.status && res.status !== 200)) {
      setReasonsList(res?.result || []);
    } else {
      setReasonsList([]);
    }
  };

  const getAllRejectReasonsAPICall = async () => {
    const res = await GetLookupItemsByLookupTypeName({
      lookUpName: "AMLRejectionReason",
      pageSize: 100,
      pageIndex: 1,
    });

    if (!(res && res.status && res.status !== 200)) {
      setReasonsList(res?.result || []);
    } else setReasonsList([]);
  };

  const getAllReassignReasonsAPICall = async () => {
    const res = await GetLookupItemsByLookupTypeName({
      lookUpName: "AMLReassignedReason",
      pageSize: 100,
      pageIndex: 1,
    });

    if (!(res && res.status && res.status !== 200)) {
      setReasonsList(res?.result || []);
    } else setReasonsList([]);
  };

  const getAllWithdrawReasons = async () => {
    const res = await GetLookupItemsByLookupTypeName({
      lookUpName: "AMLWithdrawReason",
      pageSize: 100,
      pageIndex: 1,
    });

    if (!(res && res.status && res.status !== 200)) {
      setReasonsList(res?.result || []);
    } else setReasonsList([]);
  };

  const onCloseClicked = () => {
    setIsOpenDialog(false);
  };

  const TransactionManualApproveAPICall = async (body) => {
    setIsLoading(true);
    const res = await TransactionManualApprove(body);
    if (!(res && res.status && res.status !== 200)) {
      showSuccess(t("updated-sucessfully"));
      APIGetAllAMLTransactions(IDS);
      setIsOpenDialog(false);
    } else showError(t("couldnt-perform-action"));
    setIsLoading(false);
  };

  const TransactionManualCancelAPICall = async (body) => {
    setIsLoading(true);
    const res = await TransactionManualCancel(body);
    if (!(res && res.status && res.status !== 200)) {
      showSuccess(t("updated-sucessfully"));
      APIGetAllAMLTransactions(IDS);
      setIsOpenDialog(false);
    } else showError(t("couldnt-perform-action"));
    setIsLoading(false);
  };

  const TransactionReassignedToRequesterAPICall = async (body) => {
    setIsLoading(true);
    const res = await TransactionReassignedToRequester(body);
    if (!(res && res.status && res.status !== 200)) {
      showSuccess(t("updated-sucessfully"));
      APIGetAllAMLTransactions(IDS);
      setIsOpenDialog(false);
    } else showError(t("couldnt-perform-action"));
    setIsLoading(false);
  };

  const TransactionWithdrawAPICall = async (body) => {
    setIsLoading(true);
    const res = await TransactionWithdraw(body);
    if (!(res && res.status && res.status !== 200)) {
      showSuccess(t("updated-sucessfully"));
      APIGetAllAMLTransactions(IDS);
      setIsOpenDialog(false);
    } else showError(t("couldnt-perform-action"));
    setIsLoading(false);
  };

  const onSaveClicked = () => {
    setIsSubmitted(true);
    const { error } = schema.validate(selectedReason);
    if (error) {
      showError(t(`${translationPath}select-reason-before-submitting`));
    } else {
      const body = {
        amlTransactionId: AMLTransactions?.amlTransactionId,
        reasonId: selectedReason?.lookupItemId,
        remarks: description,
      };

      if (reason === "approve-reason") {
        TransactionManualApproveAPICall(body);
      } else if (reason === "reject-reason") {
        TransactionManualCancelAPICall(body);
      } else if (reason === "reassign-to-requester-reason") {
        TransactionReassignedToRequesterAPICall(body);
      } else if (reason === "withdraw-transaction-reason") {
        TransactionWithdrawAPICall(body);
      } else {
        showError(t("couldnt-perform-action"));
      }
    }
  };

  useEffect(() => {
    if (reason === "approve-reason") {
      getAllApproveReasonsAPICall();
    } else if (reason === "reject-reason") {
      getAllRejectReasonsAPICall();
    } else if (reason === "reassign-to-requester-reason") {
      getAllReassignReasonsAPICall();
    } else if (reason === "withdraw-transaction-reason") {
      getAllWithdrawReasons();
    } else {
      showError(t("couldnt-perform-action"));
    }
  }, []);

  return (
    <>
      <Spinner isAbsolute isActive={isLoading} />
      <DialogComponent
        titleText={t("reason")}
        saveText={t("confirm")}
        saveType="button"
        dialogContent={
          <>
            <div className="mt-2">
              <AutocompleteComponent
                idRef="rejectionReasonRef"
                data={reasonsList || []}
                labelValue={t(reason)}
                inputPlaceholder={t("select-reason")}
                selectedValues={selectedReason || []}
                withoutSearchButton
                isWithError
                multiple={false}
                displayLabel={(option) => option?.lookupItemName || ""}
                onChange={(e, newValue) => {
                  setSelectedReason(newValue);
                }}
                isSubmitted={isSubmitted}
                translationPath={translationPath}
                parentTranslationPath={parentTranslationPath}
              />
            </div>
            <div className="mt-4">
              <Inputs
                idRef="reasonDescriptionRef"
                labelValue={t("type-your-reason")}
                value={description || null}
                rows={6}
                multiline={true}
                onInputChanged={(e) => {
                  setDescription(e?.target?.value || "");
                }}
                translationPath={translationPath}
                parentTranslationPath={parentTranslationPath}
              />
              <span>{t("add-at-least-one-reason")}</span>
            </div>
          </>
        }
        maxWidth="sm"
        onSaveClicked={() => {
          onSaveClicked();
        }}
        onCloseClicked={() => {
          onCloseClicked();
        }}
        onCancelClicked={() => {
          onCloseClicked();
        }}
        isOpen={isOpen}
        setIsOpenDialog={setIsOpenDialog}
        translationPath={translationPath}
        parentTranslationPath={parentTranslationPath}
      />
    </>
  );
}

export default AMLActionsDialog;
