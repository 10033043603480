export const CampaignsPermissions = {
  ViewAllCampaigns :
   {
    permissionsId: "93d3aa9a-a6c9-46fb-a0c5-08db9980f187",
    permissionsName: "View All Campaigns",
    description: null,
    componentsId: null,
    components: null
   },
   AddCampaign :
   {
    permissionsId: "7249031a-054b-4409-a0c6-08db9980f187",
    permissionsName: "Add Campaign",
    description: null,
    componentsId: null,
    components: null
   },
  EditCampaign : 
   {
    permissionsId: "e049e639-ee54-417f-a0c7-08db9980f187",
    permissionsName: "Edit Campaign",
    description: null,
    componentsId: null,
    components: null
  },
  DeleteCampaign :
  {
    permissionsId: "6272e0e8-2f33-4637-a0c8-08db9980f187",
    permissionsName: "Delete Campaign",
    description: null,
    componentsId: null,
    components: null
  },

  ViewCampaignDashboard :
  {
    permissionsId: "006cbd05-9ae2-4269-84ca-08dc38260b6c",
    permissionsName: "View Campaign Dashboard",
    description: null,
    componentsId: null,
    components: null
  }
};
  