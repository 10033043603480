import React, {
  useCallback,
  useEffect,
  useReducer,
  useRef,
  useState,
} from "react";
import {
  AutocompleteComponent,
  DatePickerComponent,
  Inputs,
  SelectComponet,
} from "../../../../Components";
import { useTranslation } from "react-i18next";
import { GetLookupItemsByLookupTypeName } from "../../../../Services";
import { ButtonBase } from "@material-ui/core";
import moment from "moment";
import { DateRangePickerComponent } from "../../../../Components/Controls/DateRangePickerComponent/DateRangePickerComponent";
import { useSelector } from "react-redux";
import { TableFilterTypesEnum } from "../../../../Enums";

function OpportunitiesSearchFilters({
  state,
  setState,
  filter,
  initialState,
  onFilterReset,
  reloadData,
  translationPath,
  parentTranslationPath
}) {
  const searchTimer = useRef(null);
  const { t } = useTranslation(parentTranslationPath);


  const reducer = useCallback((state, action) => {
    if (action.id !== "edit") return { ...state, [action.id]: action.value };
    return {
      ...action.value,
    };
  }, []);

  const initialSelected = {
    newStage: null,
    oldStage: null,
    fromDate: null,
    toDate: null,
  };
  const [selected, setSelected] = useReducer(reducer, initialSelected);
  const [data, setData] = useReducer(reducer, {
    leadStages: null,
  });
  const dateRangeDefault = {
    startDate: null,
    endDate: null,
    key: 'selection',
    selectedDateType: 1,
  };
  const [isDatePickerChanged, setIsDatePickerChanged] = useState(false);
  const [searchData, setSearchData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);


  const [dateFilter, setDateFilter] = useState(dateRangeDefault);


  const getLeadStages = async () => {
    const res = await GetLookupItemsByLookupTypeName({
      lookUpName: "LeadStage",
      pageSize: filter.pageSize,
      pageIndex: filter.pageIndex,
    });
    if (!(res && res.status && res.status !== 200)) {
      setData({ id: "leadStages", value: res?.result || [] });
    } else setData({ id: "leadStages", value: [] });
  };

  const filterByChanged = (value) => {

    setState({ id: "filterBy", value });
  };

  const filterDateChanged = (selectedDate) => {
    setDateFilter((item) => ({
      ...item,
      startDate: selectedDate?.selection?.startDate,
      endDate: selectedDate?.selection?.endDate,
      key: 'selection',
    }));
    setIsDatePickerChanged(false)
  };

  useEffect(() => {
    if (dateFilter && dateFilter.startDate && dateFilter.endDate) {
      const fromDate = moment(dateFilter.startDate)
        .startOf("day")
        .format("YYYY-MM-DDTHH:mm:ss");
      const toDate = moment(dateFilter.endDate)
        .endOf("day")
        .format("YYYY-MM-DDTHH:mm:ss");
      setState({ id: "fromDate", value: fromDate });
      setState({ id: "toDate", value: toDate });
    }
  }, [isDatePickerChanged])

  const orderByChanged = (value) => {
    setState({ id: "orderBy", value });
  };

  const clearFilterHandler = () => {
    setState({ id: "edit", value: initialState });
    setSelected({ id: "edit", value: initialSelected });
    setDateFilter(dateRangeDefault)
    onFilterReset();
    reloadData();
  };

  useEffect(() => {
    getLeadStages();
  }, []);

  return (
    <div>
      <div className="d-flex my-2 w-100 ">
        <div className="w-33 mx-1">
          <Inputs
            idRef="leadIdRef"
            inputPlaceholder={t("lead-id")}
            value={state?.leadId || ""}
            onInputChanged={(event) => {
              setState({ id: "leadId", value: event.target.value });
            }}
            onKeyUp={(e, newValue) => {
              if (searchTimer.current) clearTimeout(searchTimer.current);
              searchTimer.current = setTimeout(() => {
                reloadData();
              }, 700);
            }}
            translationPath={translationPath}
            parentTranslationPath={parentTranslationPath}
          />
        </div>
        <div className="w-33 mx-1">
          <AutocompleteComponent
            idRef="oldStageRef"
            data={data?.leadStages || []}
            inputPlaceholder={t(`${translationPath}old-stage`)}
            selectedValues={selected?.oldStage || ""}
            displayLabel={(option) => option?.lookupItemName || ""}
            multiple={false}
            withoutSearchButton
            onChange={(e, newValue) => {
              setSelected({
                id: "oldStage",
                value: newValue,
              });
              setState({
                id: "oldStageId",
                value: newValue?.lookupItemId,
              });
              if (searchTimer.current) clearTimeout(searchTimer.current);
              searchTimer.current = setTimeout(() => {
                reloadData();
              }, 700);
            }}
          />
        </div>
        <div className="w-33 mx-1">
          <AutocompleteComponent
            idRef="newStageRef"
            data={data?.leadStages || []}
            inputPlaceholder={t(`${translationPath}new-stage`)}
            selectedValues={selected?.newStage || ""}
            displayLabel={(option) => option?.lookupItemName || ""}
            multiple={false}
            withoutSearchButton
            onChange={(e, newValue) => {
              setSelected({
                id: "newStage",
                value: newValue,
              });
              setState({
                id: "newStageId",
                value: newValue?.lookupItemId,
              });
              if (searchTimer.current) clearTimeout(searchTimer.current);
              searchTimer.current = setTimeout(() => {
                reloadData();
              }, 700);
            }}
          />
        </div>
      </div>

      <div className="d-flex my-2 w-100">
        <div className="w-25 mx-1">
          <Inputs
            idRef="oldLeadNameRef"
            inputPlaceholder={t("old-lead-name")}
            value={state?.oldLeadName || ""}
            onInputChanged={(event) => {
              setState({ id: "oldLeadName", value: event.target.value });
            }}
            onKeyUp={(e, newValue) => {
              if (searchTimer.current) clearTimeout(searchTimer.current);
              searchTimer.current = setTimeout(() => {
                reloadData();
              }, 700);
            }}
            translationPath={translationPath}
            parentTranslationPath={parentTranslationPath}
          />
        </div>
        <div className="w-25 mx-1">
          <Inputs
            idRef="newLeadNameRef"
            inputPlaceholder={t("new-lead-name")}
            value={state?.newLeadName || ""}
            onInputChanged={(event) => {
              setState({ id: "newLeadName", value: event.target.value });
            }}
            onKeyUp={(e, newValue) => {
              if (searchTimer.current) clearTimeout(searchTimer.current);
              searchTimer.current = setTimeout(() => {
                reloadData();
              }, 700);
            }}
            translationPath={translationPath}
            parentTranslationPath={parentTranslationPath}
          />
        </div>

        {/* //datepicker */}
        <div className="w-25 mx-1">
          <Inputs
            idRef="createdByRef"
            inputPlaceholder={t("changed-by")}
            value={state?.createdBy || ""}
            onInputChanged={(event) => {
              setState({ id: "createdBy", value: event.target.value });
            }}
            onKeyUp={(e, newValue) => {
              if (searchTimer.current) clearTimeout(searchTimer.current);
              searchTimer.current = setTimeout(() => {
                reloadData();
              }, 700);
            }}
            translationPath={translationPath}
            parentTranslationPath={parentTranslationPath}
          />
        </div>
        <div className="lead-opportunities-logs-date-paker">
          <div className="w-25 mx-1 customDatePicker d-flex">
            <div className='date-type-select'>
              <SelectComponet
                data={[
                  { key: 1, value: `${translationPath}created-date` }
                ]}
                defaultValue={{
                  key: 1,
                  value: `${translationPath}created-date`,
                }}
                value={dateFilter.selectedDateType || 1}
                valueInput='key'
                textInput='value'
                isDisabled={isLoading}
                onSelectChanged={(value) => {
                  setIsDatePickerChanged(true);
                  setDateFilter((f) => ({
                    ...f,
                    selectedDateType: value,
                  }));
                }}
                wrapperClasses='w-auto'
                themeClass='theme-transparent'
                idRef='Date_Select'
                parentTranslationPath={parentTranslationPath}
                translationPath={translationPath}
                translationPathForData={translationPath}
              />
            </div>
            <DateRangePickerComponent
              onClearClicked={() => {
                setDateFilter(dateRangeDefault);
                filterDateChanged(dateRangeDefault);
                setState({ id: "fromDate", value: null });
                setState({ id: "toDate", value: null });
              }}
              onDialogClose={() => setIsDatePickerChanged(true)}
              ranges={[dateFilter]}
              parentTranslationPath={parentTranslationPath}
              translationPath={translationPath}
              onDateChanged={(selectedDate) => {
                filterDateChanged(selectedDate)
              }}
            />
          </div>
        </div>
      </div>

      <div className="d-flex px-2">
        <span className="mx-2 mt-1">{t(`${translationPath}leads`)}</span>
        <span className="separator-v s-primary s-reverse s-h-25px mt-1" />
        <span className="px-2 d-flex">
          <span className="texts-large mt-1">
            {t(`${translationPath}order-by`)}:
          </span>
          <div className="px-2">
            <SelectComponet
              idRef="filterByRef"
              data={[
                { id: "createdOn", filterBy: "created-on" },
                { id: "updateOn", filterBy: "last-updated" },
              ]}
              value={state.filterBy}
              onSelectChanged={filterByChanged}
              wrapperClasses="mb-3"
              isRequired
              valueInput="id"
              textInput="filterBy"
              emptyItem={{
                value: null,
                text: "select-filter-by",
                isDisabled: false,
              }}
              parentTranslationPath={parentTranslationPath}
              translationPath={translationPath}
              translationPathForData={translationPath}
            />
          </div>
          <div className="px-2">
            <SelectComponet
              idRef="orderByRef"
              data={[
                { id: 1, orderBy: "ascending" },
                { id: 2, orderBy: "descending" },
              ]}
              value={state.orderBy}
              onSelectChanged={orderByChanged}
              wrapperClasses="mb-3"
              isRequired
              valueInput="id"
              textInput="orderBy"
              emptyItem={{
                value: null,
                text: "select-sort-by",
                isDisabled: false,
              }}
              parentTranslationPath={parentTranslationPath}
              translationPath={translationPath}
              translationPathForData={translationPath}
            />
          </div>
          <div className="mt-1">
            <ButtonBase
              className="btns theme-solid"
              onClick={reloadData}
              id="action_apply"
            >
              <span>{t(`${translationPath}apply`)}</span>
            </ButtonBase>
          </div>
          <div className="mt-1">
            <ButtonBase
              className="btns theme-solid bg-danger clear-all-btn"
              onClick={clearFilterHandler}
              id="clearFiltersRef"
            >
              {t(`${translationPath}clear-filters`)}
            </ButtonBase>
          </div>
        </span>
      </div>
    </div>
  );
}

export default OpportunitiesSearchFilters;
