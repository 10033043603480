import React, { useCallback, useEffect, useReducer, useState } from 'react';
import { useLocalStorage } from '../../../../Hooks';
import ContactCardViewer from './components/ContactCardViewer';
import {
  BulkUpdateContacts,
  GetAllFormFieldsByFormId,
} from '../../../../Services';
import { Spinner } from '../../../../Components';
import { FormsIdsEnum } from '../../../../Enums';
import ContactsFieldsViewer from './components/ContactsFieldsViewer';
import ContactValuesViewer from './components/ContactValuesViewer';
import {
  GetParams,
  GlobalHistory,
  bottomBoxComponentUpdate,
  formItemsBuilderV3,
  showError,
  showSuccess,
  showWarn,
  sideMenuComponentUpdate,
  sideMenuIsOpenUpdate,
} from '../../../../Helper';
import { useTranslation } from 'react-i18next';
import { ButtonBase } from '@material-ui/core';

const requiredFields = [
  // 'company_name',
  // 'first_name',
  // 'last_name',
  'gender',
  'language',
  'contact_class',
  'multi_property-owner',
  'contact_preference',
  'contact_classifications',
  'flagged_contact',
  'psi_opportunity',
  'country',
  'city',
  'district',
  'community',
  'sub_community',
  'job_title',
  'owner_in-multiple-areas',
  'owner_for-one-unit',
  'high_net-worth',
  'nationality_category',
  'investor',
  'contact_profession',
  'angry_bird',
  'industry',
  'retail_category',
  'company_type',
  'media_name',
  'company_class',
  'promo_by-email',
  'promo_by-sms',
  'promo_by-whatsapp',
  'exclusive',
  'contact_rate',
  "title",
  "high_profile-management",
];

const parentTranslationPath = 'ContactsView';
const translationPath = 'contactBulkUpdate.';

export const ContactBulkUpdate = () => {
  const { t } = useTranslation(parentTranslationPath);
  const [items] = useLocalStorage('bulk-assign-contacts-items');
  const [selectedItems, setSelectedItems] = useState(items);
  const [formFields, setFormFields] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [formFieldIds, setFormFieldIds] = useState(formFields);
  const [formFieldsData, setFormFieldsData] = useState([]);
  const [contactDetails, setContactDetails] = useState({});
  const [contactInitDetails, setContactInitDetails] = useState([]);
  const [formData, setFormData] = useState([]);
  const [errors, setErrors] = useState([]);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [contactStatus, setContactStatus] = useState({
    failure: [],
    success: [],
  });

  const type = +GetParams('type');
  const reducer = useCallback((state, action) => {
    if (action.id !== 'edit') return { ...state, [action.id]: action.value };
    return {
      ...action.value,
    };
  }, []);

  const defaultState = {
    bulkUpdateContactId: [],
    fieldsName: [],
    updatedContactJson: {},
  };

  const [state, setState] = useReducer(reducer, defaultState);
  useEffect(() => {
    const contacts = [];
    const fieldsNames = [];
    contactInitDetails.map((item) => {
      if (!contactDetails[item]) contactDetails[item] = null;
    });

    contacts.map((item, index) => {
      if (contactStatus?.success.length > 0) contacts.splice(index, 1);
    });
    formFieldIds.map((item) => fieldsNames.push(item.formFieldName));
    setState({ id: 'updatedContactJson', value: contactDetails });
    setState({ id: 'fieldsName', value: fieldsNames });
    setState({
      id: 'bulkUpdateContactId',
      value: selectedItems.map((contact) => contact.id),
    });
  }, [
    formFieldIds,
    selectedItems,
    contactDetails,
    contactInitDetails,
    contactStatus,
  ]);

  const selectHandler = (id) => {
    const item = items.find((i) => i.id === id);
    setSelectedItems((items) => items.concat(item));
  };
  const removeHandler = (id) => {
    setSelectedItems((items) => items.filter((i) => i.id !== id));
  };
  const onChangeHandler = (id) => {
    const item = selectedItems.find((i) => i.id === id);
    if (item) {
      removeHandler(id);
    } else {
      selectHandler(id);
    }
  };

  const getAllFormFieldsByFormId = useCallback(async () => {
    setIsLoading(true);
    const result = await GetAllFormFieldsByFormId(type);
    if (!(result?.status && result?.status !== 200)) {
      const results = [...result].filter((item) =>
        requiredFields.includes(item.formFieldKey)
      );
      setFormFields(results);
    }
    setIsLoading(false);
  }, []);

  useEffect(() => {
    getAllFormFieldsByFormId();
  }, []);

  useEffect(() => {
    setFormData(formItemsBuilderV3([], formFieldsData));
  }, [formFieldsData]);

  const cancelHandler = () => {
    GlobalHistory.goBack();
  };
  const saveHandler = async () => {
    setIsLoading(true);
    setIsSubmitted(true);
    if (errors?.length > 0) {
      showError(errors[0].message);
      setIsLoading(false);
      return;
    }
    try {
      delete state.updatedContactJson.undefined;
      const result = await BulkUpdateContacts(state);
      if (!(result && result.status && result.status !== 200)) {
        setContactStatus(result);
        if (result.success.length === 0 && result.failure.length > 0)
          showError(t(`${translationPath}contacts-update-failed`));
        if (result.failure.length === 0 && result.success.length > 0) {
          showSuccess(t(`${translationPath}contacts-updated-successfully`));
        }
        if (result.failure.length > 0 && result.success.length > 0) {
          showWarn(
            `${t(`${translationPath}number-of-contacts-failed`)} : ${
              result.failure.length
            } / ${t(`${translationPath}number-of-contacts-succeeded`)} : ${
              result.success.length
            }`
          );
        }
      }
    } catch (error) {
      showError(t(`${translationPath}contacts-update-failed`));
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    bottomBoxComponentUpdate(
      <div className='bulk-actions-wrapper w-100'>
        <ButtonBase className='btns c-danger' onClick={cancelHandler}>
          <span>{t(`${translationPath}cancel`)}</span>
        </ButtonBase>
        <ButtonBase className='btns theme-solid' onClick={saveHandler}>
          <span className='px-3'>
            {contactStatus.failure.length > 0
              ? t(`${translationPath}update-failed-properties`)
              : t(`${translationPath}save-changes`)}
          </span>
        </ButtonBase>
      </div>
    );
  });
  useEffect(
    () => () => {
      bottomBoxComponentUpdate(null);
      sideMenuComponentUpdate(null);
      sideMenuIsOpenUpdate(false);
    },
    []
  );
  return (
    <div className='contacts-bulk-container units-bulk-assign-view-wrapper d-flex w-100 p-3 fj-between'>
      <Spinner isActive={isLoading} isAbsolute />
      <div>
        {/* <h2 className=' px-2'>{t(`${translationPath}contacts-bulk-update`)}</h2> */}
        <ContactCardViewer
          cards={items}
          selectedItems={selectedItems}
          onChangeHandler={onChangeHandler}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
        />
      </div>
      <span className='section-seperator mdi mdi-arrow-collapse-right c-gray' />

      <div>
        <ContactsFieldsViewer
          formFields={formFields}
          setFormFieldsData={setFormFieldsData}
          contactDetails={contactDetails}
          setContactInitDetails={setContactInitDetails}
          formFieldIds={formFieldIds}
          setFormFieldIds={setFormFieldIds}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
        />
      </div>
      <span className='section-seperator mdi mdi-arrow-collapse-right c-gray' />

      <div>
        <ContactValuesViewer
          errors={errors}
          formData={formData}
          setErrors={setErrors}
          contactDetails={contactDetails}
          setContactInitDetails={setContactInitDetails}
          formFieldIds={formFieldIds}
          isSubmitted={isSubmitted}
          setFormData={setFormData}
          setContactDetails={setContactDetails}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
        />
      </div>
    </div>
  );
};

export default ContactBulkUpdate;
