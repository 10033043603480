import React, { useCallback, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Spinner, Tables } from '../../../Components';
import { TableActions } from '../../../Enums';
import { ActiveItemActions } from '../../../store/ActiveItem/ActiveItemActions';

export const MergeLogsTable = ({
  parentTranslationPath,
  translationPath,
  filter,
  data,
  isLoading,
  setFilter,
  TableFilterData,
  onPageIndexChanged,
  onPageSizeChanged,
  setSortBy,
  MergeLogsViewheaderData,
  onFilterValuesChanged
}) => {
  const { t } = useTranslation([parentTranslationPath, 'Shared']);

  return (
    <div className='MergeLogsTableView'>
      <div className='filter-section-item' />
      <div className='w-100 px-2'>
        <Tables
          data={(data && data.result) || []}
          headerData={MergeLogsViewheaderData}
          defaultActions={[]}
          //   actionsOptions={{
          //     onActionClicked: tableActionClicked,
          //   }}
          itemsPerPage={filter.pageSize}
          activePage={filter.pageIndex}
          setSortBy={setSortBy}
          isWithFilter
          onFilterValuesChanged={onFilterValuesChanged}
          filterData={TableFilterData}
          FilterDisabledButton
          translationPath={translationPath}
          parentTranslationPath={parentTranslationPath}
          totalItems={(data && data.totalCount) || 0}
          onPageIndexChanged={onPageIndexChanged}
          onPageSizeChanged={onPageSizeChanged}
        />
      </div>
    </div>
  );
};

MergeLogsTable.propTypes = {
  parentTranslationPath: PropTypes.string.isRequired,
  translationPath: PropTypes.string.isRequired,
  filter: PropTypes.number.isRequired,
  data: PropTypes.instanceOf(Object).isRequired,
  isLoading: PropTypes.bool.isRequired,
  onPageIndexChanged: PropTypes.func.isRequired,
  onPageSizeChanged: PropTypes.func.isRequired,

};
