import React from "react";
import clsx from "clsx";
import moment from "moment";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Typography } from "@material-ui/core";
import { CustomCopyToClipboard } from "../../../../../../../Components";
import { useIsArabicLayout, useSelectedTheme, useTranslate } from "../../../../../../../Hooks";

// Icons
import { CornerDownRight, CornerDownLeft } from "../../../../../../../assets/icons";

// Styles
import { transactionCardStyles } from "./styles";


function ContactOverviewTransactionCard({ transactionItems, totalCount }) {
  const styles = transactionCardStyles();

  const { translate } = useTranslate("NewContactsView");
  const { translate: sharedTranslate } = useTranslate("SharedV2");

  const {
    theme: { palette },
  } = useSelectedTheme();

  const { isArabicLayout } = useIsArabicLayout();
  const CornerIcon = isArabicLayout ? CornerDownLeft : CornerDownRight;

  return (
    <Box className={styles.transactionContainer}>
      {transactionItems.map((transaction) => {
        const formattedDate = transaction?.createdOn
          ? moment(transaction?.createdOn).locale("en").format("D MMM YYYY")
          : null;

        const formattedTime = transaction?.createdOn
          ? moment(transaction?.createdOn).locale("en").format("h:mm a")
          : null;

        return (
          <Box className={styles.transactionWrapper}>
            <Box className={styles.infoSection}>
              <Typography
                className={clsx(styles.infoItem, styles.f16)}
                variant="body1"
                component="span"
              >
                {translate("transaction-type-label")} <strong>{transaction.status}</strong>
              </Typography>
              <Typography
                className={styles.infoItem}
                variant="body1"
                component="span"
              >
                {translate("transaction-id-label")}{" "}
                <strong className={styles.infoValue}>
                  #{transaction.unitTransactionId}
                </strong>
              </Typography>
              <Box className={styles.propertyDetailsContainer}>
                <Box className={styles.propertyItemWrapper}>
                  <CornerIcon
                    width="24"
                    height="24"
                    fill={palette.foreground.quarterary}
                  />
                  <Box className={styles.propertyInfoWrapper}>
                    <Typography
                      className={styles.infoItem}
                      variant="body1"
                      component="span"
                    >
                      {translate("lead-type-label")}{" "}
                      <strong className={styles.infoValue}>#12354</strong>
                    </Typography>
                    <Typography
                      className={styles.infoItem}
                      variant="body1"
                      component="span"
                    >
                      {translate("lead_id")}
                      <CustomCopyToClipboard
                        data={transaction.leadId} // copy
                        childrenData={"#" + transaction.leadId} // render
                        copyDoneShowTime={1000}
                      />
                    </Typography>
                  </Box>
                </Box>
                <Box className={styles.propertyItemWrapper}>
                  <CornerIcon
                    width="24"
                    height="24"
                    fill={palette.foreground.quarterary}
                  />
                  <Box className={styles.propertyInfoWrapper}>
                    <Typography
                      className={styles.infoItem}
                      variant="body1"
                      component="span"
                    >
                      {translate("unit-type-label")}{" "}
                      <strong className={styles.infoValue}>
                        {transaction.unitType}
                      </strong>
                    </Typography>
                    <Typography
                      className={styles.infoItem}
                      variant="body1"
                      component="span"
                    >
                      {translate("unit-id-label")}
                      <CustomCopyToClipboard
                        data={transaction.unitId} // copy
                        childrenData={"#" + transaction.unitId} // render
                        copyDoneShowTime={1000}
                      />
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box className={styles.dateSection}>
              <Box className={styles.dateAndTime}>
                <Typography className={styles.date}>{formattedDate}</Typography>
                <Typography className={styles.date}>{formattedTime}</Typography>
              </Box>
              <Typography
                className={styles.infoItem}
                variant="body1"
                component="span"
              >
                {translate("property-name-label")}{" "}
                <strong className={styles.infoValue}>
                  {transaction.propertyName}
                </strong>
              </Typography>
            </Box>
          </Box>
        );
      })}

      {totalCount === 0 && (
        <Typography className={styles.emptyState} variant="h6" align="center" color="textSecondary">
          {sharedTranslate("no-data-avlbl")}
        </Typography>
      )}
    </Box>
  );
}

export default ContactOverviewTransactionCard;
