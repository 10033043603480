import React from "react";
import { Box } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";

// styles
import useStyles from "./styles";
import clsx from "clsx";

function TransactionHistoryItemSkeleton({
  numberOfCards,
  hideUnitType,
  hideBorder,
}) {
  const styles = useStyles();

  const SkeletonCard = Array.from({ length: numberOfCards || 1 }).map(
    (_, index) => (
      <Box
        key={index}
        className={clsx(styles.containerCardTransactionItemSkeleton, {
          [styles.cardwithPadding]: hideBorder,
          [styles.cardwithborder]: !hideBorder,
        })}
      >
        <Box className={styles.transactionInfoContainerSkeleton}>
          <Box className={styles.topTransactionInfoBoxSkeleton}>
            <Box className={styles.transactionTypeBoxSkeleton}>
              <Skeleton
                className={styles.transactionTitleTypeSkeleton}
                variant="text"
                width={95}
                height={25}
                animation={"wave"}
              ></Skeleton>
              <Skeleton
                className={styles.transactionTitleValueSkeleton}
                variant="text"
                width={70}
                height={25}
                animation={"wave"}
              ></Skeleton>
            </Box>
            <Box className={styles.transactionBoxIdSkeleton}>
              <Skeleton
                className={styles.transactionTitleIdSkeleton}
                variant="text"
                width={95}
                height={25}
                animation={"wave"}
              ></Skeleton>
              <Skeleton
                className={styles.transactionIdValueSkeleton}
                variant="text"
                width={70}
                height={25}
                animation={"wave"}
              ></Skeleton>
            </Box>
          </Box>
          <Box className={styles.bottomTransactionInfoBoxSkeleton}>
            <Box className={styles.leadInfoBoxSkeleton}>
              {/* corner*/}
              <Skeleton
                className={styles.transactionIdValueSkeleton}
                variant="text"
                width={20}
                height={30}
                animation={"wave"}
                style={{ borderRadius: "0.60rem" }}
              ></Skeleton>
              <Box className={styles.leadInfoSkeleton}>
                <Box className={styles.containerLeadTypeSkeleton}>
                  <Skeleton
                    variant="text"
                    width={70}
                    height={20}
                    animation={"wave"}
                    className={styles.leadTypeTitle}
                  ></Skeleton>
                  <Skeleton
                    className={styles.leadTypeId}
                    variant="text"
                    width={70}
                    height={20}
                    animation={"wave"}
                  ></Skeleton>
                </Box>
                <Box className={styles.containerLeadSketlon}>
                  <Skeleton
                    className={styles.transactionLeadId}
                    variant="text"
                    width={70}
                    height={20}
                    animation={"wave"}
                  ></Skeleton>
                  <Skeleton
                    className={styles.transactionIdValueSkeleton}
                    variant="text"
                    width={70}
                    height={20}
                    animation={"wave"}
                  ></Skeleton>
                </Box>
              </Box>
            </Box>
            <Box className={styles.unitIdBoxSkeleton}>
              {/* corner*/}
              <Skeleton
                className={styles.transactionIdValueSkeleton}
                variant="text"
                width={20}
                height={30}
                animation={"wave"}
                style={{ borderRadius: "0.60rem" }}
              ></Skeleton>
              <Box className={styles.containerUnitSkeleton}>
                {!hideUnitType && (
                  <Box className={styles.containerUnitTypeTypeSkeleton}>
                    <Skeleton
                      variant="text"
                      width={70}
                      height={20}
                      animation={"wave"}
                      className={styles.transactionUnitTypeTitle}
                    ></Skeleton>
                    <Skeleton
                      className={styles.transactionUnitIdValue}
                      variant="text"
                      width={70}
                      height={20}
                      animation={"wave"}
                    ></Skeleton>
                  </Box>
                )}
                <Box className={styles.containerUnitIdSketlon}>
                  <Skeleton
                    className={styles.transactionUnitIdTitleSkeleton}
                    variant="text"
                    width={70}
                    height={20}
                    animation={"wave"}
                  ></Skeleton>
                  <Skeleton
                    className={styles.transactionUnitIdValueSkeleton}
                    variant="text"
                    width={70}
                    height={20}
                    animation={"wave"}
                  ></Skeleton>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box className={styles.timeInfo_ContainerSkeleton}>
          <Box className={styles.containerTime_Dateskeleton}>
            <Skeleton
              variant="text"
              width={95}
              height={25}
              animation={"wave"}
              className={styles.DateItemSkeleton}
            ></Skeleton>
            <Skeleton
              variant="text"
              width={70}
              height={25}
              animation={"wave"}
              className={styles.TimeItemSkeleton}
            ></Skeleton>
          </Box>
          <Box className={styles.container_ProprtyNameSkeleton}>
            <Skeleton
              variant="text"
              width={95}
              height={25}
              animation={"wave"}
              className={styles.proprtyNameTitleSleleton}
            ></Skeleton>
            <Skeleton
              variant="text"
              width={70}
              height={25}
              animation={"wave"}
              className={styles.proprtyNameValueSkeleton}
            ></Skeleton>
          </Box>
        </Box>
      </Box>
    )
  );
  return <>{SkeletonCard}</>;
}

TransactionHistoryItemSkeleton.defaultProps = {
  numberOfCards: 1,
};

export default TransactionHistoryItemSkeleton;
