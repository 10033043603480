
import React, { useState , useCallback  , useEffect}  from 'react';
import { useTranslation } from 'react-i18next';
import Rating from '@material-ui/lab/Rating';

  export const VisitParkingAvailability = ({
    parentTranslationPath ,
    translationPath ,
    onChangeSelectedItem , 
    selectedValues ,
    setSelectValues ,
   } ) => { 

    const { t } = useTranslation(parentTranslationPath);

    return (
      <>
        <div className="">
        <span className="fw-bold"> {t(`${translationPath}visit-parking-availability`)} </span>
        </div> 
        <div className='w-75 parking-distribution'>
          {
            selectedValues && selectedValues.visitorsParkingAvailability && 
            selectedValues.visitorsParkingAvailability.map((item , index)=> 
            (
              <div className="d-flex d-flex-v-center parking-distribution-card mt-2 mb-2 b-bottom" key={`visit-parking-availability${index}`}>
               <div className='d-flex d-flex-v-center'>
                <span className='d-inline-flex fw-bold'>{item.lookupItemName}</span>
               </div>
              <div>
              <Rating  
                  size="large"
                  name={`RatingVisitParkingAvailabilityRe${item.lookupItemName}`}
                  max={10}
                  value={item.score}
                  precision={1}
                  onChange={(event, newValue) => 
                  {
                      onChangeSelectedItem(item.lookupItemName , newValue || 0);
                      let newUpdatedList =  selectedValues.visitorsParkingAvailability ? [...selectedValues.visitorsParkingAvailability] : [];
                      const fieldIndex = newUpdatedList.findIndex((f) => f.lookupItemName?.toLowerCase()?.includes(item.lookupItemName?.toLowerCase()));
                      if(fieldIndex !== -1)
                        newUpdatedList[fieldIndex].score = newValue ||0  ;  
                      setSelectValues((item)=> ({...item , visitorsParkingAvailability : newUpdatedList }))
                   
                  }}
                  />     
              </div>
             </div>  
            ))
          } 
        </div>
      </>
    );
  } ; 
  
