export const RotationSchemesBranchDashboardPermissions = 
{

  RotationSchemesBranchDashboard : 
  {
     permissionsId: "8b2e232c-1dd7-4488-7048-08dbf65badbd",
     permissionsName: "Rotation Schemes Branch Dashboard",
     description: null,
     componentsId: null,
     components: null
 },
   
  
} ;
