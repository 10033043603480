import React, { useState, useEffect } from 'react';
import { TabsComponent } from '../../../../Components';
import { GlobalTranslate } from '../../../../Helper';
import { tabsPermissionsHelper } from '../../../../Helper/ExceptionPermissions.Helper';
import { useTitle } from '../../../../Hooks';
import { LeaseAgentDashboardTabs } from '../LeaseAgentDashboardTabs/LeaseAgentDashboardTabs';

export const LeaseAgentDashboardTabCmp = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [dashboardTabsList, setDashboardTabsList] = useState([]);
  useTitle(GlobalTranslate.t('Dashboard:lease-agent-dashboard'));
  const onTabChanged = (e, newTap) => {
    setActiveTab(newTap);
  };

  useEffect(() => {
    const list = tabsPermissionsHelper(LeaseAgentDashboardTabs).map((field, fieldIndex) => ({
      ...field,
      index: fieldIndex,
    }));
    setDashboardTabsList(list);
  }, [LeaseAgentDashboardTabs]);

  return (
    <div>
      <TabsComponent
        data={dashboardTabsList}
        labelInput='label'
        themeClasses='theme-curvedpowerbi'
        currentTab={activeTab}
        onTabChanged={onTabChanged}
        dynamicComponentProps={{
          setActiveTab,
          dynamicComponentLeaseAgentProps: Object.values(dashboardTabsList).find(
            (element) => element.index === activeTab
          ),
        }}
      />
    </div>
  );
};
