
export const ActivatedLeadsAgentDashboardPermissions = 
{

  ActivatedLeadsAgentDashboard : 
   {
    permissionsId: "fe4df429-3886-4d55-286a-08dbaf79a1bb",
    permissionsName: "Activated Leads Agent Dashboard",
    description: null,
    componentsId: null,
    components: null
   } 
    
} ;
  