import React from "react";

function DotIcon({ fill, ...restProps }) {
  return (
    <svg
      viewBox="0 0 10 10"
      fill={fill ?? ""}
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <g id="Size=md, Outline=False">
        <circle id="Dot" cx="5" cy="5" r="4" fill={fill ?? ""} />
      </g>
    </svg>
  );
}

export default DotIcon;
