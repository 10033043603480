import { OrganizationUserSearch } from '../Services';
import { AgentRoleEnum } from '../Enums/AgentRoleEnum';
import { GetParams } from '../Helper';
import { LeadTypeIdEnum } from '../Enums';

let timer = null;

export async function ReferredToByLeadOperationRule(item, value, setRerender, itemList, values, setData) {
  if (+GetParams('formType') === LeadTypeIdEnum.Owner.leadTypeId) {
    const referredToIndex = itemList.indexOf(itemList.find((f) => f.field.id === 'referredto'));
    if (referredToIndex !== -1) {
      itemList[referredToIndex].data.enum = [];
      setData(itemList[referredToIndex].field.id, {});

      let userTypeId = null;
      if (value)
        userTypeId = (value === 'Seller' ? AgentRoleEnum.SaleListingAgent.value : AgentRoleEnum.LeaseListingAgent.value);
      const filter = {
        pageSize: 25,
        pageIndex: 1,
        name: null,
        userName: null,
        phoneNumber: null,
        email: null,
        userStatusId: 2,
        userTypeId

      };

      timer = setTimeout(async () => {
        const rs = await OrganizationUserSearch({ ...filter });
        if (!rs || !rs.result) return;
        rs.result.map((element) => {
          itemList[referredToIndex].data.enum.push({
            id: element.id,
            name: element.fullName,
            phone: element.phoneNumber,
            email: element.email,
            userName: element.userName,
            branch: element.branch,
          });
        });
      }, 300);
    }
  }
}
