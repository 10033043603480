import React from 'react';
import { ButtonBase } from '@material-ui/core';

const PushTOlink = (item) => {
  window.open(`/home/Properties-CRM/property-profile-edit?formType=1&id=${item.propertyId}`)
};

export const DuplicatedPropertirsTableHeaderData = [
  {
    id: 1,
    label: 'Property Owner',
    input: 'propertyOwner',
  },
  {
    id: 2,
    label: 'Property Type',
    input: 'propertyType',
  },
  {
    id: 3,
    label: 'Property ID',
    component: (item) => (
      <>
        <ButtonBase
          onClick={() => {
            PushTOlink(item);
          }}
        >
          <div class='icon-container mx-1'>
            <span class='mdi mdi-open-in-new c-primary'></span>
          </div>
          {item.propertyId}
        </ButtonBase>
      </>
    ),
  },
  {
    id: 4,
    label: 'Community',
    input: 'community',
  },
  {
    id: 5,
    label: 'City',
    input: 'city',
  },
  {
    id: 6,
    label: 'District',
    input: 'district',
  },
  {
    id: 7,
    label: 'Master Developer',
    input: 'masterDeveloper',
  },
  {
    id: 8,
    label: 'Created On',
    input: 'createdOn',
    isDate: true
  },
  {
    id: 9,
    label: 'Created By',
    input: 'createdByName',
  }
];
