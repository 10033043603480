
import React, { useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useLocation } from "react-router-dom";
import ButtonBase from '@material-ui/core/ButtonBase';
import { PaginationComponent, Spinner, Tables, ViewTypes , SelectComponet  } from '../../../../../../Components';
import { ActionsEnum, TableActions, ViewTypesEnum } from '../../../../../../Enums';
import { UnitsCardsComponent } from '../../../../UnitsView';
import { AssignUnitCards  } from '../../../AssignUnitCards/AssignUnitCards';
import {  UnitMapper as leaseUnitMapper  } from '../../../../UnitsLeaseView/UnitLeaseMapper/UnitMapper';
import { UnitMapper  }  from '../../../../UnitsSalesView/UnitMapper';
import {
  AssignInquiryToUnitServices ,
  GetAllUnitsByInquiryIdService,
  SetInquiryStatusAsCompleteServices ,
  UnAssignInquiryFromUnitServices , 
} from '../../../../../../Services';
import { bottomBoxComponentUpdate, GetParams, GlobalHistory  , showError, showSuccess } from '../../../../../../Helper';
import { ActiveItemActions } from '../../../../../../store/ActiveItem/ActiveItemActions';
import { AssignToUnitDialog } from '../../ListingShortageUtilities'
import { CompleteInquiryDialog } from '../../../CompleteInquires/CompleteInquiryDialog' ; 

export const AssignInquiryToUnitComponent = ({ 
  parentTranslationPath, 
  translationPath ,
  activeInquiryData  , 
  setActiveTab  , 
  setCompletInquiry 
 }) => {

  const { t } = useTranslation(parentTranslationPath);
  const location = useLocation();

  const [activeActionType, setActiveActionType] = useState(ViewTypesEnum.cards.key);
  const [unitsDetailes, setUnitsDetailes] = useState({});
  const [inquiryId , setInquiryId] = useState(+GetParams('id')) ;
  const [isOpenCompleteDialog , setIsOpenCompleteDialog ] = useState(false) ; 

  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [filter, setFilter] = useState({
    pageSize: parseInt(localStorage.getItem('Pagination')) || 25,
    pageIndex: 0,
  });
  const  [isOpenAssignToUnitDialog  , setIsOpenAssignToUnitDialog]  = useState(false) ; 
  const [ allUnitsByInquiryId ,  setAllUnitsByInquiryId ] = useState({
    result : [] ,
    totalCount : 0 
  }) ; 


  
  const onTypeChanged = useCallback((activeType) => {
    setActiveActionType(activeType);
  }, []);

  const saveHandler = async (unit) => 
  { 
    if(!unit)
    {
      showError(t(`${translationPath}emptyUnitToAssignToInquiry`)); 
      return ; 
    }
      setIsLoading(true) ; 
      const res = await AssignInquiryToUnitServices( unit.id  , activeInquiryData && activeInquiryData.inquiryId );
      if (!(res && res.status && res.status !== 200))
      {
        showSuccess(t(`${translationPath}assign-inquiry-to-unit-successfully`));
      }
      else
        showError(t(`${translationPath}assign-inquiry-to-unit-failure`));


      setIsLoading(false) ; 
    setIsOpenAssignToUnitDialog(false);
    setFilter((item) => ({ ...item, pageIndex : 0 }));

  };

  const completedHandler = async () => 
  { 
      setIsLoading(true) ; 
      const res = await SetInquiryStatusAsCompleteServices(activeInquiryData && activeInquiryData.inquiryId);
      if (!(res && res.status && res.status !== 200)){
        setCompletInquiry(true);
        showSuccess(t(`${translationPath}SetInquiryStatusAsComplete-successfully`));
      } 
      else 
        showError(t(`${translationPath}THERE_IS_NO_UNITS_ASSIGNED_TO_THIS_INQUIRY_Please-Assign_units`));  

     setIsLoading(false) ; 
  };

  const GetAllUnitsByInquiryId = useCallback(async () => 
  {
    setIsLoading(true) ; 
    const res = await GetAllUnitsByInquiryIdService(activeInquiryData && activeInquiryData.inquiryId , filter.pageIndex + 1 , filter.pageSize)  ; 
    if (!(res && res.status && res.status !== 200))
    { 
      if((activeInquiryData && activeInquiryData.inquiryTypeId === 3) || (activeInquiryData && activeInquiryData.inquiryTypeId === 1))
      {
        setAllUnitsByInquiryId({
          result: ((res && res.result) || []).map((item) => item.unit && UnitMapper(item, item.unit.unit)),
          totalCount :  res.totalCount 
        });

      }
      else 
      {
        setAllUnitsByInquiryId({
          result: ((res && res.result) || []).map((item) => item.unit && leaseUnitMapper(item, item.unit.unit)),
          totalCount :  res.totalCount 
        });

      }
    } else {
       setAllUnitsByInquiryId({
        result :  [] , 
        totalCount :  0 
      });
    }
    setIsLoading(false) ; 

  }, [filter]);



  const unAssignUnitHandler =  useCallback(async (unit) => 
  { 
    if(!unit)
    {
      showError(t(`${translationPath}emptyUnitToAssignToInquiry`)); 
      return ; 
    }
      setIsLoading(true) ; 
      const res = await UnAssignInquiryFromUnitServices( unit.id  , activeInquiryData && activeInquiryData.inquiryId );
      if (!(res && res.status && res.status !== 200))
        showSuccess(t(`${translationPath}Unassign-inquiry-to-unit-successfully`));
      else
        showError(t(`${translationPath}unassign-inquiry-to-unit-failure`)); 
      setIsLoading(false) ; 
      setFilter((item) => ({ ...item, pageIndex : 0 }));
  } ,[activeInquiryData]);

  

  const onPageIndexChanged = (pageIndex) => {
    setFilter((item) => ({ ...item, pageIndex }));
  };
  const onPageSizeChanged = (pageSize) => {
    setFilter((item) => ({ ...item, pageIndex: 0, pageSize }));

  };
  
  const tableActionClicked = useCallback(
    (actionEnum, item) => {
      dispatch(ActiveItemActions.activeItemRequest(item));
      if (actionEnum === TableActions.delete.key) 
      {
        unAssignUnitHandler(item);
        setFilter((item) => ({ ...item, pageIndex: 0 }));

      }
    },
    [dispatch]
  );


  useEffect(() => {
    bottomBoxComponentUpdate(
      <PaginationComponent
        pageIndex={filter.pageIndex}
        pageSize={filter.pageSize}
        totalCount={allUnitsByInquiryId && allUnitsByInquiryId.totalCount}
        onPageIndexChanged={onPageIndexChanged}
        onPageSizeChanged={onPageSizeChanged}
      />
    );
  });
  useEffect(() => {
    GetAllUnitsByInquiryId();
 
  },[GetAllUnitsByInquiryId]);
  
  const focusedRowChanged = useCallback(() => { }, []);
  return (
    <div className='associated-contacts-wrapper childs-wrapper'>
      <Spinner isActive={isLoading} isAbsolute />
      <div className='title-section d-flex-v-center-h-between flex-wrap'>
        <span>{t(`${translationPath}units`)}</span>
        <ViewTypes
          onTypeChanged={onTypeChanged}
          activeTypes={[ViewTypesEnum.tableView.key, ViewTypesEnum.cards.key]}
        />
      </div>
      <div className='filter-sections-wrapper mb-2'>
      <div className='filter-section-item'>
        {
           !(activeInquiryData &&  activeInquiryData.inquiryStatus === 'Canceled')  && (
            <ButtonBase
            onClick={() => setIsOpenAssignToUnitDialog(true)}
            className='btns theme-transparent c-gray-primary'
          >
            <span className='mdi mdi-send-outline mdi-rotate-315' />
            <span className='px-2'>{t(`${translationPath}assigntoUnit`)}</span>
         </ButtonBase>
         )}   
      </div>
      <div className='filter-section-item'>
     { 
       !((activeInquiryData &&  activeInquiryData.inquiryStatus === 'Canceled' ) ||  (activeInquiryData && activeInquiryData.inquiryStatus === 'Completed')) && 
       (
        <ButtonBase
              onClick={() => {
                setIsOpenCompleteDialog(true);
              }}
              className='btns theme-solid mb-2 mx-2'
            >
            <span className='px-2'>{t(`${translationPath}completed`)}</span>
         </ButtonBase>

       )
     }  
      </div>
      </div>


      {activeActionType === ViewTypesEnum.tableView.key && (
        <div className='w-100 px-2'>
          <Tables
            data={ allUnitsByInquiryId && allUnitsByInquiryId.result}
            headerData={[
              { id: 1, label: 'ref-no', input: 'unit_ref_no' },
              { id: 2, label: 'property', input: 'propertyName' },
              {
                id: 3,
                label: 'unit-no',
                input: 'id',
              },
              {
                id: 5,
                label: 'unit-type',
                input: 'unitType',
              },
              {
                id: 6,
                label: 'bedrooms',
                input: 'bedrooms',
              },
              {
                id: 7,
                label: 'bathrooms',
                input: 'bathrooms',
              },
              {
                id: 9 ,
                label: 'createdOn',
                isDate : true , 
                input: 'createdOn',  
              } ,
              {
                id: 8 ,
                label: 'assignedBy',
                input: 'assignedBy',  
              } , 
            ]}
            onPageIndexChanged={onPageIndexChanged}
            onPageSizeChanged={onPageSizeChanged}
            actionsOptions={{
              onActionClicked: tableActionClicked,
            }}
            defaultActions={[
               {
              enum: TableActions.delete.key,
              isDisabled: (activeInquiryData && activeInquiryData.inquiryStatus === 'Completed') || ( activeInquiryData &&  activeInquiryData.inquiryStatus === 'Canceled')
               }
            ]}
            itemsPerPage={filter.pageSize}
            activePage={filter.pageIndex}
            parentTranslationPath={parentTranslationPath}
            translationPath={translationPath}                            
            focusedRowChanged={focusedRowChanged}
            totalItems={allUnitsByInquiryId && allUnitsByInquiryId.totalCount ? allUnitsByInquiryId.totalCount : 0}
          />
        </div>
      )}
      {activeActionType === ViewTypesEnum.cards.key && allUnitsByInquiryId && allUnitsByInquiryId.result && (
        <AssignUnitCards
          data={allUnitsByInquiryId}
          onFooterActionsClicked={()=>{}} 
          onCardClicked={()=>{}}
          onCardCheckboxClick={()=>{}}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          isExpanded={false}
          unAssignUnitHandler={(unit)=> {
            unAssignUnitHandler(unit) ;
          }}
          inquiryStatus={(activeInquiryData && activeInquiryData.inquiryStatus ==='Completed') || (activeInquiryData &&  activeInquiryData.inquiryStatus === "Canceled") }
          isSale={(activeInquiryData && activeInquiryData.inquiryTypeId === 3 ) ||(activeInquiryData && activeInquiryData.inquiryTypeId === 1 )  }
        />
      )}
      {
        isOpenAssignToUnitDialog && (
       <AssignToUnitDialog
          isOpen={isOpenAssignToUnitDialog}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          onSave={(unit)=>{
            saveHandler(unit);
           }}
          onClose={()=> setIsOpenAssignToUnitDialog(false)}
          inquiryTypeId= {activeInquiryData && activeInquiryData.inquiryTypeId }
         />
       )
      }

    {
        isOpenAssignToUnitDialog && (
       <AssignToUnitDialog
          isOpen={isOpenAssignToUnitDialog}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          onSave={(unit)=>{
            saveHandler(unit);
           }}
          onClose={()=> setIsOpenAssignToUnitDialog(false)}
          inquiryTypeId= {activeInquiryData && activeInquiryData.inquiryTypeId }
          allUnitsRelatedInquiry = {(allUnitsByInquiryId && allUnitsByInquiryId.result) || []} 
         />
       )
      }

  {
      isOpenCompleteDialog && (
       <CompleteInquiryDialog
          isOpen={isOpenCompleteDialog}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          onSave={()=>{
           completedHandler() ; 
           setIsOpenCompleteDialog(false) ; 
           }}
           isLoading={isLoading}
          close={()=> setIsOpenCompleteDialog(false)}
          inquiry= {activeInquiryData }
         />
       )
      }

    </div>
  );
};

AssignInquiryToUnitComponent.propTypes = {
  parentTranslationPath: PropTypes.string.isRequired,
  translationPath: PropTypes.string.isRequired,
};
