export const AddressEnum =
{
    country: {
        lookupTypeId: 16,
        lookupTypeName: 'country'
    },
    city: {
        lookupTypeId: 17,
        lookupTypeName: 'city'
    },
    district: {
        lookupTypeId: 18,
        lookupTypeName: 'district'
    },
    community: {
        lookupTypeId: 19,
        lookupTypeName: 'community'
    },
    subCommunity: {
        lookupTypeId: 20,
        lookupTypeName: 'subCommunity'
    }
};
