import React, { useEffect, useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { LeadsAdvanceSearchTest } from "../../../../../../../Services";
import {
  TableFilterOperatorsEnum,
  TableActions,
  LeadsClassTypesEnum,
} from "../../../../../../../Enums";
import Button from "@material-ui/core/Button";
import { LeadMapper } from "./LeadMapper";
import { LeadsSalesTableComponent } from "../../../../../../../Views/Home/LeadsSalesView/LeadsSalesUtilities/LeadsSalesTableComponent/LeadsSalesTableComponent";
import { ReassignListingAgentDialog } from "../../../Dialogs";
import { RadiosGroupComponent, SelectComponet, Spinner } from "../../../../../../../Components";
import { ButtonBase } from "@material-ui/core";

export const LeadsReassignView = ({
  parentTranslationPath,
  translationPath,
  deActiveUserId,
}) => {
  const { t } = useTranslation(parentTranslationPath);

  const initiaFilterlDto = {
    "status.lookupItemName": [
      {
        searchType: 1,
        value: "open",
      },
    ],
    leadClass: [
      {
        searchType: 1,
        value: LeadsClassTypesEnum.seller.name,
      },
    ],
  };

  const [data, setData] = useState({
    result: [],
    totalCount: 0,
  });
  const [filter, setFilter] = useState({
    pageSize: parseInt(localStorage.getItem("Pagination")) || 25,
    pageIndex:
      JSON.parse(localStorage.getItem("leadSalesCurrentPageIndex")) || 0,
  });
  const [filterSearchDto, setFilterSearchDto] = useState(initiaFilterlDto);
  const [selectedOrderBy, setSelectedOrderBy] = useState({ filterBy: 'createdOn', orderBy: 2 });
  const [orderBy, setOrderBy] = useState({ filterBy: 'createdOn', orderBy: 2 });
  const [leadsTableFilter, setLeadTableFilter] = useState(null);
  const [checkedRows, setCheckedRows] = useState([]);
  const [leadType, setLeadType] = useState("Seller");
  const [isReassignDialogOpen, setIsReassignDialogOpen] = useState(false);
  const [isClearFiltersClicked, setIsClearFiltersClicked] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const getLeadsData = async () => {
    setIsLoading(true);

    const localFilterDto = filterSearchDto || {};

    if (leadsTableFilter) {
      Object.values(leadsTableFilter)
        .filter((item) => item && item.searchableKey || item.displayPath)
        .map((item) => {
          if (localFilterDto[item.searchableKey || item.displayPath]) {
            localFilterDto[item.searchableKey || item.displayPath] = [
              {
                searchType: item.operator,
                value: item.value,
              },
            ];
          } else if (item.value) {
            localFilterDto[item.searchableKey || item.displayPath] = [
              {
                searchType: item.operator,
                value: item.value,
              },
            ];
          } else if (
            !item.value &&
            (item.operator === TableFilterOperatorsEnum.isNotBlank.key ||
              item.operator === TableFilterOperatorsEnum.isBlank.key)
          ) {
            localFilterDto[item.searchableKey || item.displayPath] = [
              {
                searchType: item.operator,
                value: null,
              },
            ];
          }
          return undefined;
        });
    }

    const body = {
      criteria: {
        ...localFilterDto,
        referredtoid: [
          {
            searchType: 1,
            value: deActiveUserId,
          },
        ],
      },
      ...orderBy,
      ...filter,
      pageIndex: filter.pageIndex + 1,
    };

    const res = await LeadsAdvanceSearchTest(filter, body);
    if (!(res && res.status && res.status !== 200) && res && res.result) {
      const result = ((res && res.result) || []).map(
        (item) =>
          item.leadJson && LeadMapper(item, JSON.parse(item.leadJson).lead)
      );
      setData({
        result: result,
        totalCount: (res && res.totalCount) || 0,
      });
    } else {
      setData({
        result: [],
        totalCount: 0,
      });
    }
    setIsLoading(false);

  };

  const tableActionClicked = useCallback((actionEnum, item) => {
    if (actionEnum === TableActions.openFile.key) {
      window.open(
        `/home/lead-sales/lead-profile-edit?formType=${item.leadTypeId}&id=${item.id}&leadClass=${item.leadClass}`,
        "_blank"
      );
    }
  }, []);

  const onPageIndexChanged = (pageIndex) => {
    setFilter((item) => ({ ...item, pageIndex }));
  };
  const onPageSizeChanged = (pageSize) => {
    setFilter((item) => ({ ...item, pageIndex: 0, pageSize }));
  };

  const onFilterValuesChanged = (newValue) => {
    setLeadTableFilter(newValue);
  };

  const orderBySubmitted = (event) => {
    event.preventDefault();
    if (!selectedOrderBy.filterBy || !selectedOrderBy.orderBy) {
      if (orderBy.filterBy || orderBy.orderBy) setOrderBy({});
      return;
    }
    setOrderBy({
      filterBy: selectedOrderBy.filterBy,
      orderBy: selectedOrderBy.orderBy,
    });
  };

  const filterByChanged = (value) => {
    setSelectedOrderBy((item) => ({ ...item, filterBy: value }));
  };

  const orderByChanged = (value) => {
    setSelectedOrderBy((item) => ({ ...item, orderBy: value }));
  };

  const onSelectClicked = useCallback(
    (row, rowIndex) => {
      setCheckedRows((items) => {
        const index = items.findIndex((item) => item.id === row.id);
        if (index !== -1) items.splice(index, 1);
        else items.push(row);
        return [...items];
      });
    },
    [checkedRows]
  );
  const getIsSelected = useCallback(
    (row) =>
      checkedRows && checkedRows.findIndex((item) => item.id === row.id) !== -1,
    [checkedRows]
  );

  const onClearedAllFiltersClick = useCallback(() => {
    setIsClearFiltersClicked(true);
    setSelectedOrderBy((item) => ({ ...item, filterBy: null, orderBy: null }));
    setOrderBy({ filterBy: 'createdOn', orderBy: 2 });
    setLeadTableFilter(null);
    leadClassChangeHandler(leadType, initiaFilterlDto);
  });

  const leadClassChangeHandler = (leadType, filterParam) => {
    setFilterSearchDto({
      ...(filterParam || filterSearchDto || {}),
      ["leadClass"]: [
        {
          value: leadType,
          searchType: TableFilterOperatorsEnum.equal.key,
        },
      ],
    });
  };

  useEffect(() => {
    if (deActiveUserId) getLeadsData();
  }, [deActiveUserId, filterSearchDto, filter, orderBy, leadsTableFilter]);

  return (
    <div className="w-100">
      <Spinner isActive={isLoading} isAbsolute />
      <div className="d-flex-h-between pt-3 users-manage-view">
        <div className="d-flex-default">
          <Button
            className="btns theme-propx outlined px-3 ml-4"
            id="btn-save"
            onClick={() => {
              if (checkedRows && checkedRows.length > 0)
                setIsReassignDialogOpen(true);
            }}
          >
            <span className="icons b-icon i-reverse-right mr-1"></span>
            <span>{t(`${translationPath}Reassign-leads`)}</span>
          </Button>
          <Button
            className="btns theme-propx solid px-3 mx-4"
            id="btn-save"
            onClick={() => {
              if (checkedRows && checkedRows.length == 0)
              setIsReassignDialogOpen(true);
            }}
          >
            <span>{t(`${translationPath}Complete-Reassign`)}</span>
          </Button>
        </div>
        <RadiosGroupComponent
          data={Object.values(LeadsClassTypesEnum)}
          labelClasses="Requierd-Color"
          valueInput="name"
          labelInput="name"
          wrapperClasses="pr-4"
          value={leadType}
          onSelectedRadioChanged={(e, newValue) => {
            setLeadType(newValue);
            setCheckedRows([]);
            setData({
              result: [],
              totalCount: 0,
            });
            leadClassChangeHandler(newValue);
          }}
          name="radioGroups"
          titleClasses="texts gray-primary-bold"
        />
      </div>
      <div className="propx-view-container sm m-3">
      <div className="d-flex px-2">
        <div className="d-flex px-2">
          <span className="px-2 d-flex">
            <span className="texts-large mt-1">
              {t(`${translationPath}order-by`)}:
            </span>
            <div className="px-2">
              <SelectComponet
                idRef="filterByRef"
                data={[
                  { id: "createdOn", filterBy: "created-on" },
                  { id: "updateOn", filterBy: "last-updated" },
                  {
                    id: "LastUpdatedOwner",
                    filterBy: "last-updated-owner",
                  },
                ]}
                value={selectedOrderBy.filterBy}
                onSelectChanged={filterByChanged}
                wrapperClasses="mb-3"
                isRequired
                valueInput="id"
                textInput="filterBy"
                emptyItem={{
                  value: null,
                  text: "select-filter-by",
                  isDisabled: false,
                }}
                parentTranslationPath={parentTranslationPath}
                translationPath={translationPath}
                translationPathForData={translationPath}
              />
            </div>
            <div className="px-2">
              <SelectComponet
                idRef="orderByRef"
                data={[
                  { id: 1, orderBy: "ascending" },
                  { id: 2, orderBy: "descending" },
                ]}
                value={selectedOrderBy.orderBy}
                onSelectChanged={orderByChanged}
                wrapperClasses="mb-3"
                isRequired
                valueInput="id"
                textInput="orderBy"
                emptyItem={{
                  value: null,
                  text: "select-sort-by",
                  isDisabled: false,
                }}
                parentTranslationPath={parentTranslationPath}
                translationPath={translationPath}
                translationPathForData={translationPath}
              />
            </div>
            <div className="mb-2">
              <ButtonBase
                className="btns theme-propx outlined-colored"
                onClick={orderBySubmitted}
                id="action_apply"
                disabled={!selectedOrderBy.filterBy || !selectedOrderBy.orderBy}
              >
                <span>{t(`${translationPath}apply`)}</span>
              </ButtonBase>
            </div>
          </span>
        </div>
        <ButtonBase
            onClick={onClearedAllFiltersClick}
            id="onClearedAllFiltersref"
            className="btns theme-solid bg-danger clear-all-btn max-height"
          >
            <span className="mdi mdi-filter-remove m-1" />
            {t(`${translationPath}clear-filters`)}
        </ButtonBase>
      </div>
        <LeadsSalesTableComponent
          detailsLeadsList={data}
          tableActionClicked={tableActionClicked}
          onPageIndexChanged={onPageIndexChanged}
          onPageSizeChanged={onPageSizeChanged}
          filter={filter}
          parentTranslationPath={parentTranslationPath}
          leadsTableFilter={leadsTableFilter}
          setIsClearFiltersClicked={setIsClearFiltersClicked}
          isClearFiltersClicked={isClearFiltersClicked}
          onFilterValuesChanged={onFilterValuesChanged}
          checkedCardsIds={checkedRows}
          getIsSelected={getIsSelected}
          onSelectClicked={onSelectClicked}
          isDeactivationView
          defaultActions={[
            {
              enum: TableActions.openFile.key,
            },
          ]}
          setCheckedCards={setCheckedRows}
          setOrderBy={setOrderBy}
        />
      </div>
      {isReassignDialogOpen && (
        <ReassignListingAgentDialog
          isDialogOpen={isReassignDialogOpen}
          onSave={() => {
            setIsReassignDialogOpen(false);
            getLeadsData();
            setCheckedRows([]);
          }}
          onClose={() => {
            setIsReassignDialogOpen(false);
          }}
          leadReassignProps={{
            leadIds: checkedRows && checkedRows.map((item) => item.id),
            leadType,
            deActiveUserId,
            isCompleteAction: checkedRows && checkedRows.length == 0,
          }}
          translationPath={translationPath}
          parentTranslationPath={parentTranslationPath}
        />
      )}
    </div>
  );
};
