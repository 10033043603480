
import React from 'react';
import { useTranslation } from 'react-i18next';
import Rating from '@material-ui/lab/Rating';
import { GeneralPropertyCriteriaTypesEnum } from '../../../../../../Enums' ; 


  export const TwentyFourReception = ({
    parentTranslationPath ,
    translationPath ,
    state ,
    setState ,
   } ) => { 

    const { t } = useTranslation(parentTranslationPath);

    
    const onChangeSelectedItem = (key , value ) => 
      {
        let  updateObject =  state.twentyFourHourReceptionDto ?  { ...state.twentyFourHourReceptionDto} : {
          generalPropertyCriteriaType : GeneralPropertyCriteriaTypesEnum.TwentyFourHourReception.key  , 
          isTwentyFourHourSecurity : false , 
          propertyRatingGeneralPropertyCriteriaScoreId : null , 
          score : value || 0  
         } ;
  
         setState({id:'twentyFourHourReceptionDto' , value : { ...updateObject ,  score : value || 0  } })
      };

    return (
      <div className="w-75">
        <div className='general-property-section'>
          <div>
            <span className='fw-bold'> {t(`${translationPath}twentyFourReception`)} </span>
          </div>
          <div className='w-100  mt-2 mb-2'>
          <div className='d-flex d-flex-v-center general-property-card mt-2 mb-2'>
          <div className=''>

                </div>
                <div className=''>
                 <Rating  
                    name={`twentyFourHourReception`}
                    max={10}
                    value={(state && state.twentyFourHourReceptionDto && state.twentyFourHourReceptionDto.score)}
                    precision={1}
                    onChange={(event, newValue) => 
                    {
                      onChangeSelectedItem('score' ,newValue || 0 ); 
                   
                    }}
                   />
                   </div>
               </div>
            </div>  
          </div>
        </div>
       );
  } ;   
  