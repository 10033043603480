import moment from 'moment';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { PopoverComponent, Tables } from '../../../Components';
import { GlobalHistory } from '../../../Helper';
import { ButtonBase, Tooltip } from '@material-ui/core';
import folderICON from '../../../assets/images/icons/folder.svg';
import dots from '../../../assets/images/icons/dots-vertical.svg';

export const TeamplateFolderView = ({
  data,
  translationPath,
  parentTranslationPath,
  onActionsClickedFolder
}) => {
  const { t } = useTranslation(parentTranslationPath);
  const [activeItem, setActiveItem] = useState(null);

  const TableListOpationActions = [
    {
      key: 1,
      value: t(`${translationPath}Edit-Name`),
      keyName: 'edit',
      icon: 'mdi mdi-pencil',
    },
    {
      key: 2,
      keyName: 'openFile',
      value: t(`${translationPath}open-Folder`),
      icon: 'mdi mdi-file',
    },
    {
      key: 4,
      keyName: 'delete',
      value: t(`${translationPath}delete`),
      icon: 'mdi mdi-delete c-warning ',
    },
  ];


  const PushHandler = (item) => {
    GlobalHistory.push(`/home/templates/OpenFile?id=${item && item.systemTemplateFolderId}&systemTemplateFolderName=${item && item.systemTemplateFolderName}`);
  };
  const [ActionsPopover, setActionsPopover] = useState(null);
  const actionsPopoverClickedHandler = (event) => {
    setActionsPopover(event.currentTarget);
  };
  const actionsPopoverCloseHandler = () => {
    setActionsPopover(null);
  };
  return (
    <>
      <div className='main-folder-title'>
        <div className='folder-title'>{t(`${translationPath}Folders`)}</div>
        <div className='folder-sub-title'>
          {data?.length || 0}
          {' '}
          {' '}
          {t(`${translationPath}Folders`)}
        </div>
      </div>
      <div className='TeamplateFolder-wraper w-100 px-3'>
        <div className='folder-container'>
          {data.map((folder, index) => (
            <Tooltip
              size='small'
              placement='top'
              title={t(`${translationPath}DoubleClickToOpen`)}
            >
              <div index={index} className='file-item' onDoubleClick={() => { PushHandler(folder); }}>
                <div className='heading-and-icon'>
                  <img alt='folder' className='folder' src={folderICON} />
                  <div className='heading'>
                    {folder.systemTemplateFolderName}
                    {' '}
                  </div>
                </div>
                <div className='frame'>
                  <div className='heading-and-number'>
                    <div className='text-wrapper'>{t(`${translationPath}Total-File`)} </div>
                    <div className='number-and-badge'><div className='number'>{folder.systemTemplateDtos.length || 0}</div></div>
                  </div>
                  <div className='heading-and-number'>
                    <div className='text-wrapper'>{t(`${translationPath}Created`)} </div>
                    <div className='number-and-badge'>
                      <div className='number'>
                        {(folder.createdOn &&
                          moment(folder.createdOn)
                            .locale(i18next.language)
                            .format('MMM D, YYYY')) ||
                          'N/A'}
                      </div>

                    </div>
                  </div>
                </div>
                <div className='dropdown-inquiries'
                  onClick={(e) => {
                    setActiveItem(folder);
                    actionsPopoverClickedHandler(e);
                  }}

                >
                  <img alt='folder' className='dots-vertical' src={dots} />
                </div>
              </div>
            </Tooltip>
          ))}
        </div>
        <PopoverComponent
          idRef='headfdsopovercogRef'
          attachedWith={ActionsPopover}
          popoverClasses='top-ppp'
          header-actions-popover-wrapper
          handleClose={actionsPopoverCloseHandler}
          component={(
            <div className='Popap-Option'>
              {TableListOpationActions.map((item, index) => (
                <ButtonBase
                  className='Option'
                  key={`OptionKey${index + 1}`}
                  onClick={() => {
                    onActionsClickedFolder(item.keyName, activeItem)
                    setActionsPopover(null)
                  }}
                >
                  <div>{item.value}</div>
                  <div className={item.icon} />
                </ButtonBase>
              ))}
            </div>
          )}
        />
      </div>
      {' '}
    </>
  );
};
