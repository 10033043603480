import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => {
  return {
    loadingSpinnerWrapper: {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      backgroundColor: "rgba(255, 255, 255, 0.8)",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      zIndex: 9999,
    },
    loadingSpinnerContainer: {
      top: "auto",
      bottom: "5%",
    }
  }
});
