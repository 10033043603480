import React, {
  useCallback,
  useEffect, useState
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  DialogComponent, Spinner, UploaderComponent
} from '../../../../../Components';
import 'react-quill/dist/quill.snow.css';
import {
  GetParams, showError, showSuccess,
  showWarn
} from '../../../../../Helper';
import { ImagesGalleryFilterEnum, UploaderThemesEnum } from '../../../../../Enums';
import { ImagesGalleryPhotosCardComponent } from '../../LocationSharingUtilities/ImagesGalleryPhotosCardComponent/ImagesGalleryPhotosCardComponent';
import { CreateOrUpdateLocationLookupAlbum, DeleteAlbumImage, GetAllImagesByTypeId } from '../../../../../Services';

export const EditFormSubCommunity = ({
  parentTranslationPath,
  translationPath,
  // validatestate,
  // obejectDTO,
}) => {
  const { t } = useTranslation(parentTranslationPath);
  const [filter] = useState({
    pageSize: 1000,
    pageIndex: 0,
    search: '',
  });
  const [state, setState] = useState({
    lookupItemId: 0,
    arabicLocationName: '',
    LocationName: '',
    files: '',
    isActive: true ,
    logImage : '' , 

  });
  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setisOpen] = useState(false);
  const [item, setitem] = useState(null);
  const [cityPhotos, setCityPhotos] = useState({
    result: [],
    totalCount: 0,
  });
  // const onTabChanged = (e, newTap) => {
  //   setActiveTab(newTap);
  // };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getAllCityPhotos = useCallback(async () => {
    const res = await GetAllImagesByTypeId({
      ...filter,
      lookupItemId: +GetParams('id'),
      typeId: ImagesGalleryFilterEnum.Subcommunity.key
    });
    if (!(res && res.status && res.status !== 200)) {
      setCityPhotos({
        result: res.result,
        totalCount: res.totalCount,
      });
    } else
      showError(t(`${translationPath}permission`));
  });

  const deleteHandler = async (ImageId) => {
    setIsLoading(true);
    const res = ImageId && ImageId.albumImagesId ? await DeleteAlbumImage(ImageId?.albumImagesId) : null;
    if (!(res && res.status && res.status !== 200)) {
      getAllCityPhotos();
      const newList = state.logImage && state.logImage !=='' && Array.isArray(state.logImage) ? state.logImage.filter((f)=> f.fileId !== (res && res.fileId)) : [];
      setState((items) => ({
        ...items , 
        logImage: ([...newList]) || []
      }));
      setIsLoading(false);
      showSuccess(t(`${translationPath}image-delete-successfully`));
    } else showError(t(`${translationPath}image-delete-failed`));
    setisOpen(false);
    setIsLoading(false);
  };

  const saveHandler = async () => {
    setIsLoading(true);
    const statelogImage = state.logImage.length > 0  ? state.logImage[state.logImage.length - 1] : [] ; 
    const list =  (statelogImage && [{...statelogImage}]) || [] ;
    const toSaveState = {
      imageGalleryType: ImagesGalleryFilterEnum.Subcommunity.lookupType,
      lookupItemId: +GetParams('id'),
      albumImages: [...state.files , ...list],
    };
    const res =
      await CreateOrUpdateLocationLookupAlbum(toSaveState);
    setIsLoading(false);
    if (!(res && res.data && res.data.ErrorId)) {
      if (res) {
        showSuccess(t(`${translationPath}image-uplode-successfully`));
        getAllCityPhotos();
      } else showError(t(`${translationPath}image-uplode-failed`));
    }
  };
  useEffect(() => {
    getAllCityPhotos();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (state.files !== '')
      saveHandler();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.files]);

  useEffect(() => {
    if (state.logImage !== '')
      saveHandler();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.logImage]);


  return (
    <div className='view-wrapper-editFormCountry'>
      <div className='d-flex-column'>
      <div className='UploaderComponent-wrapered pt-4'>
         <div className='mt-2 mb-2'>
          <span className="fw-bold"> {`${t(`${translationPath}sub-community-logo`)}`} </span>
          </div>
          <UploaderComponent
            idRef='profileLogoRef'
            uploaderTheme={UploaderThemesEnum.box.key}
            defaultImage='Uploadmark'
            WithoutDefaultImg
            multiple={false}
            dropHereText='Drag-and-drop-here-or-browse-File'
            uploadedChanged={(files) => {
              if(state.logImage !=='' && state.logImage && Array.isArray(state.logImage) && (state.logImage.length > 0 ))
                {
                  showWarn(t(`${translationPath}please-remove-logo-image-before-add-new-logo-image`));
                  return ; 
  
                }
                else if(cityPhotos?.result && cityPhotos.result.some((s)=> s.isLogo))
                  { 
                    showWarn(t(`${translationPath}please-remove-logo-image-before-add-new-logo-image`));
                    return ; 
                  }
              setState((items) => ({
                ...items , 
                logImage: [{
                  isLogo : true , 
                  fileId: (files.length > 0 && files[0].uuid) || null,
                  fileName: (files.length > 0 && files[0].fileName) || null,
                }]
              }));
              
            }}
          />
            <ImagesGalleryPhotosCardComponent
                data={(cityPhotos && cityPhotos.result && cityPhotos.result.filter((f)=> f.isLogo)) || []}
                parentTranslationPath={parentTranslationPath}
                translationPath={translationPath}
                WithCheckbox
                Selectedvalue={(items) => console.log(items)}
                deletevalue={(items) => { setitem(items); setisOpen(true); }}
              />
        </div>
        <div className='UploaderComponent-wrapered pt-4'>
          <div className='mt-2 mb-2'>
            <span className="fw-bold"> {`${t(`${translationPath}sub-community-images`)}`} </span>
            </div>
          <UploaderComponent
            idRef='profileImgRef'
            uploaderTheme={UploaderThemesEnum.box.key}
            defaultImage='Uploadmark'
            WithoutDefaultImg
            dropHereText='Drag-and-drop-here-or-browse-File'
            multiple={false}
            initUploadedFiles={
              (state.templateFileId && [
                {
                  uuid: state.templateFileId,
                  fileName: state.templateFileName,
                },
              ]) ||
              []
            }
            uploadedChanged={(files) => {
              setState((items) => ({
                ...items,
                files: [{
                  fileId: (files.length > 0 && files[0].uuid) || null,
                  fileName: (files.length > 0 && files[0].fileName) || null,
                }]
              }));
            }}
          />
        </div>
            <div className='pt-4'>
              <ImagesGalleryPhotosCardComponent
                data={cityPhotos.result || []}
                parentTranslationPath={parentTranslationPath}
                translationPath={translationPath}
                WithCheckbox
                Selectedvalue={(items) => console.log(items)}
                deletevalue={(items) => { setitem(items); setisOpen(true); }}
              />
              <DialogComponent
                titleText='confirm-message'
                saveText='confirm'
                saveType='button'
                maxWidth='sm'
                dialogContent={(
                  <div className='d-flex-column-center'>
                    <Spinner isActive={isLoading} isAbsolute />
                    <span className='mdi mdi-close-octagon c-danger mdi-48px' />
                    <span>{`${t(`${translationPath}ConfirmText`)}`}</span>
                  </div>
                )}
                saveClasses='btns theme-solid bg-danger w-100 mx-2 mb-2'
                isOpen={isOpen}
                onSaveClicked={() => deleteHandler(item)}
                onCloseClicked={() => { setisOpen(false); }}
                onCancelClicked={() => { setisOpen(false); }}
                parentTranslationPath={parentTranslationPath}
                translationPath={translationPath}
              />
            </div>
      </div>
    </div>

  );
};
// AddFormCountry.propTypes = {
//   parentTranslationPath: PropTypes.string.isRequired,
//   translationPath: PropTypes.string.isRequired,
//   activeItem: PropTypes.instanceOf(Object).isRequired,
//   Data: PropTypes.instanceOf(Object).isRequired,
//   edit: PropTypes.bool.isRequired,
//   onCancelClicked: PropTypes.func.isRequired,
//   GetAllActivityTypesAPI: PropTypes.func.isRequired,
//   setReloading: PropTypes.func,
// };
