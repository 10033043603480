import { ButtonBase } from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { DialogComponent, PermissionsComponent  , CheckboxesComponent } from '../../../Components';
import {
  GetParams, GlobalHistory, showError, showSuccess
} from '../../../Helper';
import { useTitle } from '../../../Hooks';
import {
  CreateSystemReport, GetSystemReportCategoryTypeById, GetSystemReportsResultReportBulider, UpdateSystemReport
} from '../../../Services';
import { FiltersDialogView } from './ReportsBuilderDialogs';
import {
  ReportSavingView, SettingsSectionView, TableReportsView, TreeFieldsView,
} from './ReportsManagementViewComponents';
import { ReportBuilderPermissions } from '../../../Permissions';

const parentTranslationPath = 'ReportsBuilderg';
const translationPath = '';

export const AddReportsBuilderView = () => {
  const { t } = useTranslation(parentTranslationPath);
  const path = window.location.pathname.split('/home/')[1].split('/')[1];
  const AcationStateIsEdit = path === 'edit';
  useTitle(t(`${translationPath}ReportsBuilder`));
  const ActiveItemReducer = useSelector((state) => state.ActiveItemReducer);
  const [isOpenOpation, setisisOpenOpation] = useState(false);
  const [angryBird , setAngryBird] = useState({isAngryBird : false , isHighProfileManagement : false  }) ; 

  const [isOpenCancel, setisOpenCancel] = useState(false);
  const [isOpenRest, setisOpenRest] = useState(false);
  const [dragItem, setDragItem] = useState('');
  const [HaveChange, setHaveChange] = useState(false);
  const [SelectDataTemp, setSelectDataTemp] = useState([]);
  const [TableReportsCloums, SetTableReportsCloums] = useState([]);
  const [drageisnide, setdrageisnide] = useState(false);
  const [Data, SetData] = useState(null);
  const [Order, SetOrder] = useState({});
  const defaultValues = {
    systemReportName: [],
    withLogo: false,
    isEditableByOthers: false,
    isUsersPermission: false,
    isRolesPermission: false,
    description: '',
    ownerId: null,
    privacy: 0,
    order: 0,
    systemReportRoles: [],
    systemReportUsers: [],
    systemReportTypeId: +GetParams('id'),
  };
  const [isLoading, setIsLoading] = useState(false);
  const [selectedData, setSelectedData] = useState({});
  const [conditions, setconditions] = useState([]);
  const [isOpeneportSettings, setisOpeneportSettings] = useState(false);
  const [ReastColumus, setReastColumus] = useState(false);
  const [FromTodate, setFromTodate] = useState(null);
  const [Settingbody, setSettingbody] = useState(defaultValues);
  const [ColumusResultTemp, setColumusResultTemp] = useState([]);
  const [BodyFilter, setBodyFilter] = useState([]);
  const [DATAGetSystemReportsResult, setDATAGetSystemReportsResult] = useState([]);
  const GetSystemReportCategoryTypeByIdAPI = useCallback(async (id) => {
    const res = await GetSystemReportCategoryTypeById(id);
    if (!(res && res.status && res.status !== 200))
      SetData(res);
    else {
      SetData(
        []
      );
    }
  }, []);
  useEffect(() => {
    if (AcationStateIsEdit) {
      try {
        GetSystemReportCategoryTypeByIdAPI(ActiveItemReducer.systemReportCategoryTypeId);
        const conditionState = ActiveItemReducer && ActiveItemReducer.systemReportConditions.map((item) => (
          {
            category: item.fieldDisplayPath,
            operand: item.logicalOperator === 1 && 'AND' || item.logicalOperator === 2 && 'OR' || null,
            operator: item.operatorId,
            searchKey: item.conditionField,
            value: item.filterValue,
            formId: item.formId,
          }
        ));
        const filterList =    conditionState.filter((f)=> f.searchKey !== 'angry_bird'  && f.searchKey !== 'high_profile_management')
        const updateAngryBird = (conditionState.find((f)=> f.searchKey === 'angry_bird')  && conditionState.find((f)=> f.searchKey === 'angry_bird').value === 'Yes' ) || false ; 
        const updatehighProfileManagement = (conditionState.find((f)=> f.searchKey === 'high_profile_management')  && conditionState.find((f)=> f.searchKey === 'high_profile_management').value === 'Yes' ) || false ; 
        setAngryBird((item)=>({ ...item , isAngryBird : updateAngryBird , isHighProfileManagement : updatehighProfileManagement })); 

        setconditions(filterList);
        setBodyFilter(filterList);
       // setconditions(conditionState); 
      // setBodyFilter(conditionState);


        setFromTodate({
          startDate: JSON.parse(ActiveItemReducer && ActiveItemReducer.systemReportFilters).fromDate || null,
          endDate: JSON.parse(ActiveItemReducer && ActiveItemReducer.systemReportFilters).toDate || null,
          key: 'selection',
        });
      } catch (error) {
        GlobalHistory.push('/home/Reports-Builder/view');
      }
    } else
      GetSystemReportCategoryTypeByIdAPI(+GetParams('id'));
  }, []);

  // event.preventDefault();
  // setIsSubmitted(true);
  // if (schema.error) {
  //   showError(t('Shared:please-fix-all-errors'));
  //   return;
  // }
  // setIsLoading(true);
  const SaveReport = async (Response) => {
    let body = {...Response} ; 
    const withAngryBridFilterList = [
      {
          category: "contacts",
          operand: "AND",
          operator: 1,
          searchKey: "angry_bird",
          value:  (angryBird.isAngryBird ? 'Yes' :  'No') , 
      },
      {
          category: "contacts",
          operand: "AND",
          operator: 1,
          searchKey: "high_profile_management",
          value:  (angryBird.isHighProfileManagement ? 'Yes' :  'No') , 
      }
    ] 
    if(GetParams('Report') === 'Contacts')
    {
      const systemReportConditions = Response && Response.systemReportConditions.filter((x)=> x.searchKey !== 'angry_bird' &&  x.searchKey !== 'high_profile_management' );
      body = { ...Response , systemReportConditions :[...withAngryBridFilterList , ...systemReportConditions]} ; 

    }
    const res =
      (AcationStateIsEdit &&
        (await UpdateSystemReport(ActiveItemReducer.systemReportId, { ...body }))) ||
      (await CreateSystemReport({ ...body }));
    // setIsLoading(false);
    if (!(res && res.status && res.status !== 200)) {
      if (AcationStateIsEdit) {
        showSuccess(t`${translationPath}Report-updated-successfully`);
        setisOpeneportSettings(false);
        GlobalHistory.push('/home/Reports-Builder/view');
      } else {
        showSuccess(t(`${translationPath}Report-created-successfully`));
        setisOpeneportSettings(false);
        GlobalHistory.push('/home/Reports-Builder/view');
      }
    } else if (AcationStateIsEdit) showError(t(`${translationPath}update-Report-failed`));
    else showError(t(`${translationPath}Report-create-failed`));
  };

  // eslint-disable-next-line no-shadow, max-len
  const SystemReportsResultAPI = useCallback(async (Order, TableReports, conditionstemp, fromDate, toDate, orderByCategory, dateType) => {
    setIsLoading(true);

    const conditionsWithAngryBirdList = [
      {category: "contacts",operand: "AND",operator: 1,searchKey: "angry_bird",value: (angryBird.isAngryBird ? 'Yes' :  'No')},
      {category: "contacts", operand: "AND", operator: 1,searchKey: "high_profile_management",value: (angryBird.isHighProfileManagement ? 'Yes' :  'No')}];
      let  systemReportConditionsNew = [...conditionstemp]

      if(GetParams('Report') === 'Contacts')
      systemReportConditionsNew = systemReportConditionsNew && systemReportConditionsNew.filter((x)=> x.searchKey !== 'angry_bird' &&  x.searchKey !== 'high_profile_management' );

    

    const res = await GetSystemReportsResultReportBulider(1, 10,
      {
        spName: GetParams('spName'),
        reportJson: {
          report: {
            fields: TableReports,
            conditions:  (GetParams('Report') === 'Contacts' &&  [...conditionsWithAngryBirdList , ...systemReportConditionsNew] ) || systemReportConditionsNew || [],
            systemReportFilters: {
              orderBy: Order.searchKey,
              orderType: Order.order,
              orderByCategory: Order.orderByCategory,
              dateType: dateType || 1,
              fromDate: fromDate || null,
              toDate: toDate || null,
            },
          }
        }
      });
    if (!(res && res.status && res.status !== 200)) {
      setDATAGetSystemReportsResult(res);
      setIsLoading(false);
    } else
      setDATAGetSystemReportsResult([]);
  }, [angryBird]);

  const ConditionsMaper = (value) => {
    const res = value && value.map((item) => (
      {
        category: item.category,
        operand: item.operand,
        operator: item.operator,
        searchKey: item.searchKey,
        value: item.value,
      }
    ));
    return res;
  };

  useEffect(() => {
    if (ColumusResultTemp && ColumusResultTemp && ColumusResultTemp.length > 0) {
      // eslint-disable-next-line max-len
      SystemReportsResultAPI(Order, ColumusResultTemp, ConditionsMaper(conditions), ((FromTodate && FromTodate.startDate) || null),
        ((FromTodate && FromTodate.endDate) || null), (null), ((FromTodate && FromTodate.orderBy) || null));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ColumusResultTemp, Order , angryBird]);

  useEffect(() => {
    localStorage.setItem('ColumusResultTemp', JSON.stringify(ColumusResultTemp));
    localStorage.setItem('conditions', JSON.stringify(conditions));
    localStorage.setItem('systemReportFilters', JSON.stringify({
      orderBy: Order.searchKey,
      orderType: Order.order,
      orderByCategory: Order.orderByCategory,
      dateType: FromTodate && FromTodate.orderBy || 1,
      fromDate: FromTodate && FromTodate.startDate || null,
      toDate: FromTodate && FromTodate.endDate || null,
    }));
  }, [ColumusResultTemp, conditions, FromTodate, Order]);

  useEffect(() => {
    if (FromTodate !== null && ColumusResultTemp && ColumusResultTemp && ColumusResultTemp.length > 0)
      // eslint-disable-next-line max-len
      SystemReportsResultAPI(Order, ColumusResultTemp, ConditionsMaper(conditions), FromTodate.startDate, FromTodate.endDate, (null), FromTodate.orderBy);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [FromTodate]);
  return (
    <div className='ReportsBuilder-view-wrapers'>
      <TreeFieldsView
        parentTranslationPath={parentTranslationPath}
        translationPath={translationPath}
        drageisnide={drageisnide}
        Data={Data}
        setdrageisnide={setdrageisnide}
        setDragItem={setDragItem}
      />
        

      <div className='Form-view'>
        <div className='d-flex-v-center-h-end flex-wrap'>
          {
            GetParams('Report') ===  'Contacts' && (
              <div className='mr-2 ml-2'>
               <PermissionsComponent
                permissionsList={Object.values(ReportBuilderPermissions)}
                permissionsId={ReportBuilderPermissions.AngryBirdContact.permissionsId}>
                <CheckboxesComponent
                  idRef='isAngryBirdRef'
                  label='is-angry-bird'
                  singleChecked={angryBird.isAngryBird}
                  onSelectedCheckboxChanged={(event) => setAngryBird((item)=>({...item , isAngryBird : event.target.checked }))}
                  parentTranslationPath={parentTranslationPath}
                  translationPathForData={translationPath}
               /> 
             </PermissionsComponent>

             <PermissionsComponent
                permissionsList={Object.values(ReportBuilderPermissions)}
                permissionsId={ReportBuilderPermissions.BlacklistContact.permissionsId}>
    
             <CheckboxesComponent
              idRef='CriticalContactClassRef'
              label='critical-contact-class'
              singleChecked={angryBird.isHighProfileManagement}
              onSelectedCheckboxChanged={(event) => setAngryBird((item)=>({...item , isHighProfileManagement : event.target.checked }))}
              parentTranslationPath={parentTranslationPath}
              translationPathForData={translationPath}
             />   
             </PermissionsComponent>
            </div>
            )
          }
          <ButtonBase className='btns theme-transparent  cancel-btns mb-2' onClick={() => { setisOpenCancel(true); }}>
            <span className='mdi mdi-close ' />
            <span>{t('Shared:cancel')}</span>
          </ButtonBase>

          {AcationStateIsEdit && (
            <PermissionsComponent
              permissionsList={Object.values(ReportBuilderPermissions)}
              permissionsId={ReportBuilderPermissions.EditSystemReports.permissionsId}
            >

              <ButtonBase
                disabled={ColumusResultTemp && ColumusResultTemp.length === 0}
                className='btns theme-solid mb-2'
                onClick={() => setisOpeneportSettings(true)}
              >
                <span className='mdi mdi-content-save-all-outline px-2 ' />
                <span>{t('Shared:save')}</span>
              </ButtonBase>

            </PermissionsComponent>
          )}
          {
            !AcationStateIsEdit && (
              <PermissionsComponent
                permissionsList={Object.values(ReportBuilderPermissions)}
                permissionsId={ReportBuilderPermissions.CreateSystemReport.permissionsId}
              >
                <ButtonBase
                  disabled={ColumusResultTemp && ColumusResultTemp.length === 0}
                  className='btns theme-solid mb-2'
                  onClick={() => setisOpeneportSettings(true)}
                >
                  <span className='mdi mdi-content-save-all-outline px-2 ' />
                  <span>{t('Shared:save')}</span>
                </ButtonBase>
              </PermissionsComponent>
            )
          }
          <PermissionsComponent
            permissionsList={Object.values(ReportBuilderPermissions)}
            permissionsId={ReportBuilderPermissions.RunSystemReport.permissionsId}
          >
            <ButtonBase
              disabled={ColumusResultTemp && ColumusResultTemp.length === 0}
              className='btns theme-solid mb-2 run-btns'
              onClick={() => { 
                if(GetParams('Report') === 'Contacts')
                window.open(`/share/ReportRunTempView?spName=${GetParams('spName')}&ReportId=${((AcationStateIsEdit && ActiveItemReducer.systemReportId) || undefined)}&systemReportName=${((AcationStateIsEdit && ActiveItemReducer.systemReportName) || undefined)}&Report=${GetParams('Report')}&systemReportCategoryTypeId=${GetParams('systemReportCategoryTypeId')}&isAngryBird=${angryBird.isAngryBird}&isHighProfileManagement=${angryBird.isHighProfileManagement}`, '', 'width=1000,height=1000');

                else window.open(`/share/ReportRunTempView?spName=${GetParams('spName')}&ReportId=${((AcationStateIsEdit && ActiveItemReducer.systemReportId) || undefined)}&systemReportName=${((AcationStateIsEdit && ActiveItemReducer.systemReportName) || undefined)}&Report=${GetParams('Report')}&systemReportCategoryTypeId=${GetParams('systemReportCategoryTypeId')}`, '', 'width=1000,height=1000');
              
              }}
            >
              <span className='mdi mdi-newspaper-variant-multiple-outline  px-2 ' />
              <span>
                {' '}
                {t(`${translationPath}Run-Report`)}
              </span>
            </ButtonBase>
          </PermissionsComponent>
        </div>
        <div className='internal-Form-view'>
          <SettingsSectionView
            parentTranslationPath={parentTranslationPath}
            translationPath={translationPath}
            AcationStateIsEdit={AcationStateIsEdit}
            OpenOpationEdit={() => setisisOpenOpation(true)}
            ReastColumus={() => setisOpenRest(true)}
            onSaveClicked={(items, items2) => {
              // eslint-disable-next-line max-len
              setFromTodate({ startDate: items.startDate, endDate: items.endDate, orderBy: items2 });
              setisisOpenOpation(false);
            }}
            onCancelClicked={() => {
              setFromTodate(null);
              SystemReportsResultAPI(Order, ColumusResultTemp, ConditionsMaper(conditions),
                // eslint-disable-next-line max-len
                ((FromTodate && FromTodate.startDate) || null), ((FromTodate && FromTodate.endDate) || null), ((FromTodate && FromTodate.orderBy) || null));
            }}
          // RemoveAllcolumns={() => setReastColumus(true)}
          />
          <div className='isLoading-Reports'>
            {isLoading && (
              <>
                <div>
                  {' '}
                  <p className='loading fw-bold'>
                    <span className='m-1'>{t(`${translationPath}loading-Data`)}</span>
                    <span className='m-1 mdi mdi-relation-one-or-many-to-zero-or-one' />
                    <span className='m-1 mdi mdi-relation-one-or-many-to-zero-or-one' />
                    {' '}
                    <span className='m-1'>
                      {' '}
                      <span className='mdi mdi-relation-one-or-many-to-zero-or-one' />
                    </span>
                  </p>
                  <div className='loader-line' />
                </div>
                <div className='mt-1'>
                  {' '}
                </div>
                {' '}
              </>
            )}
          </div>
          <TableReportsView
            parentTranslationPath={parentTranslationPath}
            translationPath={translationPath}
            dragItem={dragItem}
            spName={GetParams('spName')}
            AcationStateIsEdit={AcationStateIsEdit}
            whenDROPandUpadte={() => { }}
            DATAGetSystemReportsResult={DATAGetSystemReportsResult}
            Data={Data}
            setTableReportsCloums={(item) => {
              setColumusResultTemp(
                item.map((itemData) => ({
                  name: itemData.name,
                  displayPath: itemData.searchKey,
                  category: itemData.category,
                  formId: itemData.formId,
                  categoryType: itemData.categoryType,
                  searchKey: itemData.searchKey,
                }))
              );
              SetTableReportsCloums(
                item.map((data, index) => ({
                  systemReportFieldJson: JSON.stringify(data),
                  order: index
                }))
              );
            }}
            ReastColumus={ReastColumus}
            setReastColumus={() => setReastColumus(false)}
            SetOrder={(item) => SetOrder(item)}
            drageisnide={drageisnide}
            setdrageisnide={setdrageisnide}
          />
        </div>
      </div>
      <DialogComponent
        titleText={t(`${translationPath}Edit-Filters`)}
        saveText='confirm'
        saveType='button'
        SmothMove
        maxWidth='lg'
        dialogContent={(
          <div className='d-flex-column-center'>
            <FiltersDialogView
              Data={(Data && Data) || []}
              BodyFilter={BodyFilter}
              HaveChange={HaveChange}
              onSaveClicked={(inputList) => {
                SystemReportsResultAPI(Order, ColumusResultTemp, ConditionsMaper(inputList),
                  ((FromTodate && FromTodate.startDate) || null),
                  // eslint-disable-next-line max-len
                  ((FromTodate && FromTodate.endDate) || null), (null), ((FromTodate && FromTodate.orderBy) || null));
                setisisOpenOpation(false);
                setHaveChange(true);
                setBodyFilter(inputList);
              }}
              RunClearFilter={() => {
                SystemReportsResultAPI(Order, ColumusResultTemp, [],
                  ((FromTodate && FromTodate.startDate) || null),
                  // eslint-disable-next-line max-len
                  ((FromTodate && FromTodate.endDate) || null), (null), ((FromTodate && FromTodate.orderBy) || null));
                setisisOpenOpation(false);
              }}
              onCancelClicked={() => setisisOpenOpation(false)}
              AcationStateIsEdit={AcationStateIsEdit}
              setBodyFilter={(item) => {
                setBodyFilter(item);
                setconditions(
                  item.map((itemData) => ({
                    operator: itemData.operator,
                    searchKey: itemData.searchKey,
                    value: itemData.value,
                    category: itemData.category,
                    operand: itemData.operand
                  }))
                );
              }}
              setSelectDataTemp={(item) => setSelectDataTemp(item)}
              SelectDataTemp={SelectDataTemp}
              parentTranslationPath={parentTranslationPath}
              translationPath={translationPath}
            />
          </div>
        )}
        isOpen={isOpenOpation}
        onCloseClicked={() => setisisOpenOpation(false)}
        parentTranslationPath={parentTranslationPath}
        translationPath={translationPath}
      />
      <DialogComponent
        titleText={t(`${translationPath}Report-Save`)}
        saveText='confirm'
        saveType='button'
        SmothMove
        maxWidth='md'
        dialogContent={(
          <div className='d-flex-column-center'>
            <ReportSavingView
              Data={Data}
              systemReportTypeId={+GetParams('id') || null}
              ReportCategory={GetParams('Report') || null}
              Settingbody={Settingbody}
              AcationStateIsEdit={AcationStateIsEdit}
              setSelectedData={(item) => setSelectedData(item)}
              selectedData={selectedData}
              setBody={(item) => setSettingbody(item)}
              parentTranslationPath={parentTranslationPath}
              translationPath={translationPath}
            />
          </div>
        )}
        isOpen={isOpeneportSettings}
        onSaveClicked={() => {
        const angryBirdList  = [{category: "contacts",operand: "AND",operator: 1,searchKey: "angry_bird",value: (angryBird.isAngryBird ? 'Yes' :  'No')},
          {category: "contacts", operand: null, operator: 1,searchKey: "high_profile_management",value: (angryBird.isHighProfileManagement ? 'Yes' :  'No')}];
          SaveReport({
            ...Settingbody,
            systemReportConditions: BodyFilter , 
            systemReportFields: TableReportsCloums,
            systemReportFilters: JSON.stringify({
              orderBy: Order.searchKey,
              orderType: Order.order,
              orderByCategory: Order.orderByCategory,
              dateType: ((FromTodate && FromTodate.orderBy) || 1),
              fromDate: ((FromTodate && FromTodate.startDate) || null),
              toDate: ((FromTodate && FromTodate.endDate) || null),
            })
          });
        }}
        onCloseClicked={() => setisOpeneportSettings(false)}
        onCancelClicked={() => setisOpeneportSettings(false)}
        parentTranslationPath={parentTranslationPath}
        translationPath={translationPath}
      />
      <DialogComponent
        titleText='confirm-message'
        saveText='confirm'
        SmothMove
        saveType='button'
        maxWidth='sm'
        dialogContent={(
          <div className='d-flex-column-center'>
            <span className='mdi mdi-arrow-left-circle c-primary-dark mdi-48px' />
            <span className='fz-18px fw-bold mt-3'>
              {`${`${t(`${translationPath}Reports-back`)}` || ''}`}
            </span>
          </div>
        )}
        saveClasses='btns theme-solid  bg-danger w-100 mx-2 mb-2'
        isOpen={isOpenCancel}
        onSaveClicked={() => { GlobalHistory.push('/home/Reports-Builder/view'); }}
        onCloseClicked={() => setisOpenCancel(false)}
        onCancelClicked={() => setisOpenCancel(false)}
        parentTranslationPath={parentTranslationPath}
        translationPath={translationPath}
      />
      <DialogComponent
        titleText='confirm-message'
        saveText='confirm'
        SmothMove
        saveType='button'
        maxWidth='sm'
        dialogContent={(
          <div className='d-flex-column-center'>
            <span className='mdi mdi-file-restore c-primary-dark mdi-48px' />
            <span className='fz-18px fw-bold mt-3'>
              {`${`${t(`${translationPath}Reports-Rest`)}` || ''}`}
            </span>
          </div>
        )}
        saveClasses='btns theme-solid w-100 mx-2 mb-2'
        isOpen={isOpenRest}
        onSaveClicked={() => { setReastColumus(true); setisOpenRest(false); }}
        onCloseClicked={() => setisOpenRest(false)}
        onCancelClicked={() => setisOpenRest(false)}
        parentTranslationPath={parentTranslationPath}
        translationPath={translationPath}
      />
    </div>
  );
};
