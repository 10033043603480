import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => {
  return {
    containerTransactionHistory:{
      display: 'flex',
      flexDirection: 'column'
    },
    containerCardsTransaction:{
      display: 'flex',
      flexDirection: 'column',
      gap:"16px",
      margin:'0px 0px'
    },
    emptyData:{
      color: theme.palette.text.primary,
      fontSize: '16px',
      lineHeight: '20px'
    }
  };
});
