import React from "react";

function Attachment({ fill, ...restProps }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill={fill ?? ""}
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <path
        d="M9.476 1.058c-1.757.253-3.287 1.582-3.775 3.279-.207.72-.201.528-.201 6.803 0 6.224-.003 6.099.186 6.88a6.492 6.492 0 0 0 5.486 4.919 6.444 6.444 0 0 0 5.406-1.841c.997-.996 1.571-2.117 1.853-3.617.056-.301.066-1.037.079-6.241.011-4.314.003-5.963-.031-6.136a.975.975 0 0 0-.428-.671c-.182-.127-.208-.133-.551-.133-.343 0-.369.006-.551.133a.976.976 0 0 0-.424.64c-.032.152-.045 1.853-.045 6.044 0 5.071-.008 5.879-.062 6.186-.323 1.856-1.83 3.332-3.713 3.637-2.394.389-4.708-1.254-5.123-3.637-.054-.307-.062-1.115-.062-6.186 0-4.034.013-5.898.043-6.045.024-.117.125-.381.224-.588a1.99 1.99 0 0 1 .488-.68c.169-.167.407-.363.529-.434a2.702 2.702 0 0 1 2.556-.084c.455.224.951.712 1.172 1.154.308.614.284.093.299 6.36.01 3.968.001 5.679-.03 5.822-.171.791-1.239.886-1.575.14-.034-.074-.051-1.493-.066-5.262-.022-5.728.002-5.252-.283-5.537a.926.926 0 0 0-.695-.293.993.993 0 0 0-.916.55l-.086.16v10.6l.109.32c.155.454.365.79.713 1.138.349.35.687.56 1.138.711.283.094.382.106.86.106s.577-.012.86-.106c.453-.151.79-.362 1.138-.713.35-.352.56-.686.713-1.136l.109-.32v-5.92c0-6.409.009-6.139-.221-6.829a4.503 4.503 0 0 0-1.138-1.851 4.647 4.647 0 0 0-2.351-1.277 5.687 5.687 0 0 0-1.634-.045"
        fill-rule="evenodd"
        fill={fill ?? ""}
      />
    </svg>
  );
}

export default Attachment;
