import { InquiryAssignmentTrack } from '../../../../../../Layouts';
import {
  AssignInquiryToUnitComponent,
  ListingShortageInformationComponent,
} from '../Sections';
import { ListingShortagePermissions } from '../../../../../../Permissions' ; 



export const ListingShortageVerticalTabsData = [
  {
    label: 'Inquiries-information',
    component: ListingShortageInformationComponent,
    permissionsList: Object.values(ListingShortagePermissions),
    permissionsId: ListingShortagePermissions.ViewInquiry.permissionsId,
  },
  {
    label: 'Assigned-Units',
    component: AssignInquiryToUnitComponent,
    permissionsList: Object.values(ListingShortagePermissions),
    permissionsId: ListingShortagePermissions.ViewInquiry.permissionsId,
  },
  {
    label: 'inquiry_assignment',
    component: InquiryAssignmentTrack,
    permissionsList: Object.values(ListingShortagePermissions),
    permissionsId: ListingShortagePermissions.ViewInquiryAssignmentTracker.permissionsId,
  },
];
