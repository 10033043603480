
import { HttpServices } from '../../Helper';
import { config } from '../../config';

export const GetGeographicCriteriaWithScores = async ({parentLookupItemId}) => {
    const queryList = [];
    queryList.push(`parentLookupItemId=${parentLookupItemId}`);

    const result = await HttpServices.get(
        `${config.server_address}/CrmDfm/PropertyRating/GetGeographicCriteriaWithScores?${queryList.join('&')}`)
        .then((data) => data)
        .catch((error) => error.response);
    return result;
  };
  
  export const UpdateGeographicCriteriaWithScore = async (body) => {
    const result = await HttpServices.post(
        `${config.server_address}/CrmDfm/PropertyRating/UpdateGeographicCriteriaWithScore`, body
    )
        .then((data) => data)
        .catch((error) => error.response.data);
    return result;
};

export const GetAllLocationTypeWithScores = async () => {
    const result = await HttpServices.get(
        `${config.server_address}/CrmDfm/PropertyRating/GetAllLocationTypeWithScores`)
        .then((data) => data)
        .catch((error) => error.response);
    return result;
  };
