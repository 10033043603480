import React, {
  useCallback, useEffect, useRef, useState
} from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  AutocompleteComponent,
  DatePickerComponent,
  Inputs,
  RadiosGroupComponent,
  Spinner,
} from '../../../../../../../Components';
import {AgentTypesEnum} from '../../../../../../../Enums';
import {
  StaticLookupsIds
} from '../../../../../../../assets/json/StaticLookupsIds';
import { floatHandler, getErrorByName } from '../../../../../../../Helper';
import {
  GetUserByApplicationUserId,
  lookupItemsGetId,
  OrganizationUserSearch,
  SearchUsersByName
} from '../../../../../../../Services';
import { GetApplicationUserById } from '../../../../../../../Services/userServices';
import { AgentRoleEnum } from '../../../../../../../Enums/AgentRoleEnum';

export const AgentInfoRentRelatedComponent = ({
  state,
  selected,
  onSelectedChanged,
  schema,
  unitTransactionId,
  isSubmitted,
  onStateChanged,
  parentTranslationPath,
  translationPath,
  unitData , 
  cantEditReservedLeaseTransaction  , 

}) => {

  const searchTimer = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [loadings, setLoadings] = useState({
    agents: false,
    referrals: false,
    reservationTypes: false,
    transactedBy: false,
    externalAgencies: false,
    rentFreePeriods: false,
  });
  const [filter] = useState({
    pageIndex: 0,
    pageSize: 25,
  });
  const [agents, setAgents] = useState([]);
  const [referrals, setReferrals] = useState([]);
  const [reservationTypes, setReservationTypes] = useState([]);
  const [transactedBy, setTransactedBy] = useState([]);
  const [rentFreePeriods, setRentFreePeriods] = useState([]);
  const getAllAgents = useCallback(
    async (value, selectedValue) => {
      setLoadings((items) => ({ ...items, agents: true }));
      const res = await OrganizationUserSearch({
        ...filter,
        name: value,
        userTypeId: AgentRoleEnum.LeaseAgent.value,
        userStatusId: 2,
      });
      if (!(res && res.status && res.status !== 200)) {
        setAgents(
          (selectedValue &&
            ((res && res.result && [...res.result, selectedValue]) || [selectedValue])) ||
          (res && res.result) ||
          []
        );
      } else setAgents([]);
      setLoadings((items) => ({ ...items, agents: false }));
    },
    [filter]
  );
  const getAllTransactedBy = useCallback(
    async (value, selectedValue) => {
      setLoadings((items) => ({ ...items, transactedBy: true }));
      const res = await OrganizationUserSearch({
        ...filter,
        name: value,
        userStatusId: 2,
      });
      if (!(res && res.status && res.status !== 200)) {
        setTransactedBy(
          (selectedValue &&
            ((res && res.result && [...res.result, selectedValue]) || [selectedValue])) ||
          (res && res.result) ||
          []
        );
      } else setTransactedBy([]);
      setLoadings((items) => ({ ...items, transactedBy: false }));
    },
    [filter]
  );
  const getAllReferrals = useCallback(
    async (value, selectedValue) => {
      setLoadings((items) => ({ ...items, referrals: true }));
      const res = await SearchUsersByName(value , 1 , 50);
      if (!(res && res.status && res.status !== 200)) {
        setReferrals(res && res.result);
      } else setReferrals([]);
      setLoadings((items) => ({ ...items, referrals: false }));
    },
    [filter]
  );
  const getAllReservationTypes = useCallback(async () => {
    setLoadings((items) => ({ ...items, reservationTypes: true }));
    const res = await lookupItemsGetId({
      lookupTypeId: StaticLookupsIds.ReservationType,
    });
    if (!(res && res.status && res.status !== 200)) setReservationTypes(res || []);
    else setReservationTypes([]);
    setLoadings((items) => ({ ...items, reservationTypes: false }));
  }, []);
  const getAllRentFreePeriods = useCallback(async () => {
    setLoadings((items) => ({ ...items, rentFreePeriods: true }));
    const res = await lookupItemsGetId({
      lookupTypeId: StaticLookupsIds.RentFreePeriod,
    });
    if (!(res && res.status && res.status !== 200)) setRentFreePeriods(res || []);
    else setRentFreePeriods([]);
    setLoadings((items) => ({ ...items, rentFreePeriods: false }));
  }, []);
  const getUserById = useCallback(async (id) => {
    setIsLoading(true);
    const res = await GetUserByApplicationUserId(id);
    setIsLoading(false);
    if (!(res && res.status && res.status !== 200)) return res;
    return null;
  }, []);
  
  const getUserByGUID = useCallback(async (id) => {
    setIsLoading(true);
    const res = await GetApplicationUserById(id);
    setIsLoading(false);
    if (!(res && res.status && res.status !== 200)) return res;
    return null;
  }, []);

  const handleAgentsEditInit = useCallback(async () => {
    if (state.agents && !selected.agents) {

      const selectedAgents = state.agents.map(item => ({
        id: item.userId,
        fullName: item.agentName,
        isOriginalAgent: item.isOriginalAgent,
        agentType: item.agentType,
      }))

      const tenantAgent = selectedAgents.find(item=>item.isOriginalAgent == true) || null;
      const otherTenantAgents = selectedAgents.filter(item=>item.isOriginalAgent == false) || [];

      onSelectedChanged({ id: 'agents', value: selectedAgents })
      onSelectedChanged({ id: 'tenantAgent', value: tenantAgent })
      onSelectedChanged({ id: 'otherTenantAgents', value: (otherTenantAgents.length > 0) && otherTenantAgents })
    }


    if (state.listingAgents && !selected.listingAgents  && unitData?.rent_listing_agent) {

      const selectedListingAgents = state.listingAgents.map(item => ({
        id: item.userId,
        fullName: item.listingAgentName,
        isListingAgent: item.isListingAgent,
        listingAgentType: item.listingAgentType,
        listingAgentCommission: item.listingAgentCommission,
      }))
      const landlordAgentCommission = (selectedListingAgents.length>0) ? selectedListingAgents[0].listingAgentCommission : 0;
      const unitLandlordAgent = unitData?.rent_listing_agent && ({
        id: unitData.rent_listing_agent.id,
        fullName: unitData.rent_listing_agent.name,
        isListingAgent: true,
        listingAgentType: AgentTypesEnum.Landlord.value,
        listingAgentCommission: landlordAgentCommission,
      });
      selectedListingAgents.shift();
      selectedListingAgents.unshift(unitLandlordAgent);

      const stateListingAgents = selectedListingAgents.map(item=>({
        userId: item.id,
        listingAgentName: item.fullName,
        isListingAgent: item.isListingAgent,
        listingAgentType: item.listingAgentType,
        listingAgentCommission: item.listingAgentCommission,
      }))
      const otherLandlordAgents = selectedListingAgents.filter(item=>item.isListingAgent == false) || [];;

      onStateChanged({ id: 'listingAgents', value: stateListingAgents })
      onSelectedChanged({ id:'withListingAgents', value: true })
      onSelectedChanged({ id: 'listingAgents', value: selectedListingAgents })
      onSelectedChanged({ id: 'landlordAgent', value: unitLandlordAgent })
      onSelectedChanged({ id: 'otherLandlordAgents', value: (otherLandlordAgents.length > 0) && otherLandlordAgents })
    }
  }, [
    state.agents,
    selected.agents,
    selected.listingAgents,
    state.listingAgents,
    unitData,
  ])

  const getLandlordReferralEditInit = useCallback(async () => {
    if (state.landlordReferralId && !selected.landlordReferral && referrals.length > 0) {
      const referralIndex = referrals.findIndex((item) => item.id === state.landlordReferralId);
      if (referralIndex !== -1) selected.landlordReferral = referrals[referralIndex];
      else {
        const res = await getUserById(state.landlordReferralId);
        if (res) {
          selected.landlordReferral = res;

          setReferrals((items) => {
            items.push(res);
            return [...items];
          });
        } else onStateChanged({ id: 'landlordReferralId', value: null });
      }
      if (onSelectedChanged) onSelectedChanged({ id: 'edit', value: selected });
    }
  },[referrals])

    const getTenantReferralEditInit = useCallback(async () => {
      if (state.referralId && !selected.referral && referrals.length > 0) {
        const referralIndex = referrals.findIndex((item) => item.id === state.referralId);
        if (referralIndex !== -1) selected.referral = referrals[referralIndex];
        else {
          const res = await getUserById(state.referralId);
          if (res) {
            selected.referral = res;
  
            setReferrals((items) => {
              items.push(res);
              return [...items];
            });
          } else onStateChanged({ id: 'referralId', value: null });
        }
        if (onSelectedChanged) onSelectedChanged({ id: 'edit', value: selected });
      }
    },[referrals,
      state.referralId,
    ])

    const getTransactedByEditInit = useCallback(async () => {
      if (state.transactedById && !selected.transactedBy && transactedBy.length > 0) {
        const transactedByIndex = transactedBy.findIndex((item) => item.id === state.transactedById);
        if (transactedByIndex !== -1) selected.transactedBy = transactedBy[transactedByIndex];
        else {
          const res = await getUserByGUID(state.transactedById);
          if (res) {
            selected.transactedBy = res;
  
            setTransactedBy((items) => {
              items.push(res);
              return [...items];
            });
          } else onStateChanged({ id: 'transactedById', value: null });
        }
        if (onSelectedChanged) onSelectedChanged({ id: 'edit', value: selected });
      }
    },[
      state.transactedById,
      transactedBy,
    ])
    

    const getEditInit = useCallback(async () => {
    if (state.reservationTypeId && !selected.reservationType && reservationTypes.length > 0) {
      const reservationTypeIndex = reservationTypes.findIndex(
        (item) => item.lookupItemId === state.reservationTypeId
      );
      if (reservationTypeIndex !== -1) {
        selected.reservationType = reservationTypes[reservationTypeIndex];
        if (onSelectedChanged) onSelectedChanged({ id: 'edit', value: selected });
      } else onStateChanged({ id: 'reservationTypeId', value: null });
    }
    if (state.rentFreePeriodId && !selected.rentFreePeriod && rentFreePeriods.length > 0) {
      const rentFreePeriodIndex = rentFreePeriods.findIndex(
        (item) => item.lookupItemId === state.rentFreePeriodId
      );
      if (rentFreePeriodIndex !== -1) {
        selected.rentFreePeriod = rentFreePeriods[rentFreePeriodIndex];
        if (onSelectedChanged) onSelectedChanged({ id: 'edit', value: selected });
      } else onStateChanged({ id: 'rentFreePeriodId', value: null });
    }
  }, [
    rentFreePeriods,
    reservationTypes,
    state.rentFreePeriodId,
    state.reservationTypeId,
  ]);
  useEffect(() => {
    getAllAgents();
    getAllReferrals();
    getAllReservationTypes();
    getAllTransactedBy();
    getAllRentFreePeriods();
  }, [
    getAllAgents,
    getAllReferrals,
    getAllRentFreePeriods,
    getAllReservationTypes,
    getAllTransactedBy,
  ]);
  useEffect(() => {
    if (unitTransactionId)
      getEditInit();
  }, [getEditInit, unitTransactionId]);

  useEffect(() => {
    if (state.startDate > state.endDate)
      onStateChanged({ id: 'endDate', value: null });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.endDate, state.startDate]);
  useEffect(() => {
    if (!state.transactionEntryDate)
      onStateChanged({ id: 'transactionEntryDate', value: (Date.now() && moment(Date.now()).format('YYYY-MM-DDTHH:mm:ss')) || null });
  }, [state]);
  useEffect(() => {
    if (unitTransactionId) handleAgentsEditInit();
  }, [handleAgentsEditInit, unitTransactionId]);

  useEffect(() => {
    if (unitTransactionId) getTenantReferralEditInit();
  }, [getTenantReferralEditInit, unitTransactionId]);
  
  useEffect(() => {
    if (unitTransactionId) getLandlordReferralEditInit();
  }, [getLandlordReferralEditInit, unitTransactionId]);
  
  useEffect(() => {
    if (unitTransactionId) getTransactedByEditInit();
  }, [getTransactedByEditInit, unitTransactionId]);
  
  return (
    <div className='unit-status-agent-info-wapper childs-wrapper p-relative'>
      <Spinner isActive={isLoading} isAbsolute />
      <div className='form-item'>
        <AutocompleteComponent
          idRef='tenant-agentIdRef'
          labelValue='tenant-agent'
          labelClasses='Requierd-Color'
          selectedValues={selected.tenantAgent}
          multiple={false}
          data={agents||[]}
          displayLabel={(option) => option.fullName || ''}
          renderOption={(option) =>
            ((option.userName || option.fullName) && `${option.fullName} (${option.userName})`) ||
            ''}
          withoutSearchButton
          helperText={getErrorByName(schema, 'tenantAgent').message}
          error={getErrorByName(schema, 'tenantAgent').error}
          isLoading={loadings.agents}
          onInputKeyUp={(e) => {
            const { value } = e.target;
            if (searchTimer.current) clearTimeout(searchTimer.current);
            searchTimer.current = setTimeout(() => {
              getAllAgents(value, selected.agents);
            }, 1200);
          }}
          isWithError
          isSubmitted={isSubmitted}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          onChange={(event, newValue) => {

            let newSelected = [];
            let newTenantAgent = newValue&& {
              id: newValue.id,
              fullName: newValue.fullName,
              isOriginalAgent: true,
              agentType: AgentTypesEnum.Tenant.value,
            } || null;
            if(newTenantAgent && !selected.otherTenantAgents){
              newSelected.push(newTenantAgent);
            }
            
            if(newTenantAgent && !selected.tenantAgent && selected.otherTenantAgents){
              newSelected = [newTenantAgent, ...selected.agents];
            }
            
            if(newTenantAgent && selected.tenantAgent && selected.otherTenantAgents){
              const agentsList = [...selected.agents];
              agentsList.shift();
              newSelected = [newTenantAgent, ...agentsList];
            }
            
            if(!newTenantAgent && selected.tenantAgent && selected.otherTenantAgents){
              const agentsList = [...selected.agents];
              agentsList.shift();
              newSelected = agentsList;
            }
            
            const mappedStateAgents = newSelected.length > 0 && (newSelected.map(item=>({
              userId: item.id,
              agentCommission: 0,
              isOriginalAgent: item.id == newTenantAgent?.id,
              agentType: AgentTypesEnum.Tenant.value,
            }))) || null;

            const mappedTenantAgent = newTenantAgent && {
              userId: newTenantAgent.id,
              agentCommission: 0,
              isOriginalAgent: true,
              agentType: AgentTypesEnum.Tenant.value,
              agentName: newTenantAgent.fullName,
            };
            if(newSelected.length > 0) {
              onSelectedChanged({id: 'agents', value: newSelected});
              onStateChanged({ id: 'agents', value: mappedStateAgents });
            } else {
              onSelectedChanged({id: 'agents', value: [] });
              onStateChanged({ id: 'agents', value: null });
            }
    
            onSelectedChanged({ id: 'tenantAgent', value: newTenantAgent });
            onStateChanged({ id: 'tenantAgent', value: mappedTenantAgent });
          }}
          isDisabled={cantEditReservedLeaseTransaction}
        />
      </div>
      <div className='form-item'>
        <AutocompleteComponent
          idRef='other-tenant-agentsIdRef'
          labelValue='other-tenant-agents'
          selectedValues={selected.otherTenantAgents||[]}
          multiple
          data={agents||[]}
          displayLabel={(option) => option.fullName || ''}
          chipsLabel={(option) => option.fullName || ''}
          renderOption={(option) =>
            ((option.userName || option.fullName) && `${option.fullName} (${option.userName})`) ||
            ''}
          getOptionSelected={(option) => selected.otherTenantAgents&&selected.otherTenantAgents.findIndex((item) => item.id === option.id)!== -1 || ''}
          withoutSearchButton
          isLoading={loadings.agents}
          onInputKeyUp={(e) => {
            const { value } = e.target;
            if (searchTimer.current) clearTimeout(searchTimer.current);
            searchTimer.current = setTimeout(() => {
              getAllAgents(value, selected.agents);
            }, 1200);
          }}
          isSubmitted={isSubmitted}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          onChange={(event, newValue) => {
            if(cantEditReservedLeaseTransaction)
              return ; 

            let newSelected = [];
            let newOtherTenantAgents = newValue.map(item => ({
              id: item.id,
              fullName: item.fullName,
              isOriginalAgent: false,
              agentType: AgentTypesEnum.Tenant.value,
          }))
            if(!selected.tenantAgent){
              newSelected = newOtherTenantAgents;
            } else {
              newSelected= [selected.tenantAgent, ...newOtherTenantAgents];
            }

            const mappedStateAgents = newSelected&&newSelected.length > 0 && (newSelected.map(item=>({
              userId: item.id,
              agentCommission: 0,
              isOriginalAgent: item.isOriginalAgent,
              agentType: AgentTypesEnum.Tenant.value,
            }))) || null;

            if(newSelected.length > 0){
              onStateChanged({ id: 'agents', value: mappedStateAgents });
              onSelectedChanged({id: 'agents',  value: newSelected});
              onSelectedChanged({ id: 'otherTenantAgents', value: newOtherTenantAgents });
            } else {
              onSelectedChanged({id: 'agents', value: [] });
              onStateChanged({ id: 'agents', value: null });
              onSelectedChanged({ id: 'otherTenantAgents', value: null });
            }
        }}
         isDisabled={cantEditReservedLeaseTransaction}
        />
      </div>
      <div className='form-item'>
        <AutocompleteComponent
          idRef='landlord-agentIdRef'
          labelValue='landlord-agent'
          selectedValues={selected.landlordAgent}
          multiple={false}
          data={agents||[]}
          isDisabled={selected.withListingAgents || cantEditReservedLeaseTransaction}
          displayLabel={(option) => option.fullName || ''}
          renderOption={(option) =>
            ((option.userName || option.fullName) && `${option.fullName} (${option.userName})`) ||
            ''}
          withoutSearchButton
          helperText={getErrorByName(schema, 'landlordAgent').message}
          error={getErrorByName(schema, 'landlordAgent').error}
          isLoading={loadings.agents}
          onInputKeyUp={(e) => {
            const { value } = e.target;
            if (searchTimer.current) clearTimeout(searchTimer.current);
            searchTimer.current = setTimeout(() => {
              getAllAgents(value, selected.listingAgents)
            }, 1200);
          }}
          isWithError
          isSubmitted={isSubmitted}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          onChange={(event, newValue) => {

            let newSelected = [];
            let newLandlordAgent = newValue&& {
              id: newValue.id,
              fullName: newValue.fullName,
              isListingAgent: true,
              listingAgentType: AgentTypesEnum.Landlord.value
            } || null;
            if(newLandlordAgent && !selected.otherLandlordAgents){
              newSelected.push(newLandlordAgent);
            }
            
            if(newLandlordAgent && !selected.landlordAgent && selected.otherLandlordAgents){
              newSelected = [newLandlordAgent, ...selected.listingAgents];
            }
            
            if(newLandlordAgent && selected.landlordAgent && selected.otherLandlordAgents){
              const agentsList = [...selected.listingAgents];
              agentsList.shift();
              newSelected = [newLandlordAgent, ...agentsList];
            }
            
            if(!newLandlordAgent && selected.landlordAgent && selected.otherLandlordAgents){
              const agentsList = [...selected.listingAgents];
              agentsList.shift();
              newSelected = agentsList;
            }
            
            const mappedStateAgents = newSelected.length > 0 && (newSelected.map(item=>({
              userId: item.id,
              listingAgentCommission: 0,
              isListingAgent: item.id == newLandlordAgent?.id,
              listingAgentType: AgentTypesEnum.Landlord.value
            }))) || null;

            if(newSelected.length > 0) {
              onSelectedChanged({id: 'listingAgents', value: newSelected});
              onStateChanged({ id: 'listingAgents', value: mappedStateAgents });
            } else {
              onSelectedChanged({id: 'listingAgents', value: [] });
              onStateChanged({ id: 'listingAgents', value: null });
            }
    
            onSelectedChanged({ id: 'landlordAgent', value: newLandlordAgent });
            onStateChanged({ id: 'landlordAgent', value: newLandlordAgent });

          }}
        />
      </div>
      <div className='form-item'>
        <AutocompleteComponent
          idRef='other-landlord-agentsIdRef'
          labelValue='other-landlord-agents'
          selectedValues={selected.otherLandlordAgents||[]}
          multiple
          data={agents||[]}
          displayLabel={(option) => option.fullName || ''}
          chipsLabel={(option) => option.fullName || ''}
          renderOption={(option) =>
            ((option.userName || option.fullName) && `${option.fullName} (${option.userName})`) ||
            ''}
          getOptionSelected={(option) => selected.otherLandlordAgents&&selected.otherLandlordAgents.findIndex((item) => item.id === option.id)!== -1 || ''}
          withoutSearchButton
          isLoading={loadings.agents}
          onInputKeyUp={(e) => {
            const { value } = e.target;
            if (searchTimer.current) clearTimeout(searchTimer.current);
            searchTimer.current = setTimeout(() => {
              getAllAgents(value, selected.listingAgents);
            }, 1200);
          }}
          isSubmitted={isSubmitted}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          onChange={(event, newValue) => {
            
            if(cantEditReservedLeaseTransaction)
              return ; 
            let newSelected = [];
            let newOtherLandlordAgents = newValue.map(item => ({
              id: item.id,
              fullName: item.fullName,
              isListingAgent: false,
              listingAgentType: AgentTypesEnum.Landlord.value
          }))
            if(!selected.landlordAgent){
              newSelected = newOtherLandlordAgents;
            } else {
              newSelected= [selected.landlordAgent, ...newOtherLandlordAgents];
            }

            const mappedStateAgents = newSelected&&newSelected.length > 0 && (newSelected.map(item=>({
              userId: item.id,
              listingAgentCommission: 0,
              isListingAgent: item.isListingAgent,
              listingAgentType: AgentTypesEnum.Landlord.value,
            }))) || null;

            if(newSelected.length > 0){
              onStateChanged({ id: 'listingAgents', value: mappedStateAgents });
              onSelectedChanged({id: 'listingAgents',  value: newSelected});
              onSelectedChanged({ id: 'otherLandlordAgents', value: newOtherLandlordAgents });
            } else {
              onSelectedChanged({id: 'listingAgents', value: [] });
              onStateChanged({ id: 'listingAgents', value: null });
              onSelectedChanged({ id: 'otherLandlordAgents', value: null });
            }
          }}
          isDisabled={cantEditReservedLeaseTransaction}
        />
      </div>
      <div className='form-item'>
        <RadiosGroupComponent
          idRef='contractSignedRef'
          labelValue='is-contract-ratified'
          data={[
            {
              key: true,
              value: 'yes',
            },
            {
              key: false,
              value: 'no',
            },
          ]}
          value={state.contractSigned}
          parentTranslationPath={parentTranslationPath}
          translationPathForData={translationPath}
          translationPath={translationPath}
          labelInput='value'
          valueInput='key'
          onSelectedRadioChanged={(e, newValue) =>
            onStateChanged({ id: 'contractSigned', value: newValue === 'true' })}
          isDisabled={cantEditReservedLeaseTransaction}
        />
      </div>
      <div className='form-item'>
        <Inputs
          idRef='premisesRef'
          labelValue='premises'
          value={state.premises || ''}
          helperText={getErrorByName(schema, 'premises').message}
          error={getErrorByName(schema, 'premises').error}
          isWithError
          isSubmitted={isSubmitted}
          multiline
          rows={4}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          onInputChanged={(event) => {
            onStateChanged({ id: 'premises', value: event.target.value });
          }}
          isDisabled={cantEditReservedLeaseTransaction}
        />
      </div>

      <div className='form-item'>
        <DatePickerComponent
          idRef='contractDateRef'
          labelValue='contract-date'
          placeholder='DD/MM/YYYY'
          value={state.contractDate}
          helperText={getErrorByName(schema, 'contractDate').message}
          error={getErrorByName(schema, 'contractDate').error}
          isSubmitted={isSubmitted}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          onDateChanged={(newValue) => {
            onStateChanged({ id: 'contractDate', value: newValue });
          }}
          isDisabled={cantEditReservedLeaseTransaction}
        />
      </div>
      <div className='form-item'>
        <AutocompleteComponent
          idRef='referralIdRef'
          labelValue='trans-description-tenant'
          selectedValues={selected.referral}
          multiple={false}
          data={referrals||[]}
          displayLabel={(option) => option.firstName && (`${option.firstName} (${option.lastName})`) || ''}
          renderOption={(option) =>
            option.firstName && (`${option.firstName} (${option.lastName})`) ||
            ''}
          getOptionSelected={(option) => option.id === state.referralId}
          withoutSearchButton
          helperText={getErrorByName(schema, 'referralId').message}
          error={getErrorByName(schema, 'referralId').error}
          isLoading={loadings.referrals}
          onInputKeyUp={(e) => {
            const { value } = e.target;
            if (searchTimer.current) clearTimeout(searchTimer.current);
            searchTimer.current = setTimeout(() => {
              getAllReferrals(value, selected.referral);
            }, 700);
          }}
          isWithError
          isSubmitted={isSubmitted}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          onChange={(event, newValue) => {
            onSelectedChanged({ id: 'referral', value: newValue });
            onStateChanged({
              id: 'referralId',
              value: (newValue && newValue.applicationUserId) || null,
            });
          }}
          isDisabled={cantEditReservedLeaseTransaction}
        />
      </div>
      <div className='form-item'>
        <Inputs
          idRef='referralPercentageRef'
          labelValue='trans-percentage-description-tenant'
          value={state.referralPercentage || 0}
          helperText={getErrorByName(schema, 'referralPercentage').message}
          error={getErrorByName(schema, 'referralPercentage').error}
          isWithError
          isSubmitted={isSubmitted}
          type='number'
          min={0}
          max={100}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          onInputChanged={(event) => {
            let value = +(floatHandler(event.target.value, 3));
            if (value > 100) value = 100;
            onStateChanged({ id: 'referralPercentage', value: parseInt(value) });
          }}
          isDisabled={cantEditReservedLeaseTransaction}
        />
      </div>
      <div className='form-item'>
        <AutocompleteComponent
          idRef='landlordReferralIdRef'
          labelValue='trans-description-landlord'
          selectedValues={selected.landlordReferral}
          multiple={false}
          data={referrals||[]}
          displayLabel={(option) => option.firstName && (`${option.firstName} (${option.lastName})`) || ''}
          renderOption={(option) =>
            option.firstName && (`${option.firstName} (${option.lastName})`) ||
            ''}
          getOptionSelected={(option) => option.id === state.landlordReferralId}
          withoutSearchButton
          helperText={getErrorByName(schema, 'landlordReferralId').message}
          error={getErrorByName(schema, 'landlordReferralId').error}
          isLoading={loadings.referrals}
          onInputKeyUp={(e) => {
            const { value } = e.target;
            if (searchTimer.current) clearTimeout(searchTimer.current);
            searchTimer.current = setTimeout(() => {
              getAllReferrals(value, selected.landlordReferral);
            }, 700);
          }}
          isWithError
          isSubmitted={isSubmitted}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          onChange={(event, newValue) => {
            onSelectedChanged({ id: 'landlordReferral', value: newValue });
            onStateChanged({
              id: 'landlordReferralId',
              value: (newValue && newValue.applicationUserId) || null,
            });
          }}
          isDisabled={cantEditReservedLeaseTransaction}
        />
      </div>
      <div className='form-item'>
        <Inputs
          idRef='landlordReferralPercentageRef'
          labelValue='trans-percentage-description-landlord'
          value={state.landlordReferralPercentage || 0}
          helperText={getErrorByName(schema, 'landlordReferralPercentage').message}
          error={getErrorByName(schema, 'landlordReferralPercentage').error}
          isWithError
          isSubmitted={isSubmitted}
          type='number'
          min={0}
          max={100}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          onInputChanged={(event) => {
            let value = +(floatHandler(event.target.value, 3));
            if (value > 100) value = 100;
            onStateChanged({ id: 'landlordReferralPercentage', value: parseInt(value) });
          }}
          isDisabled={cantEditReservedLeaseTransaction}
        />
      </div>
      <div className='form-item px-0'>
        <div className='form-item w-50'>
          <DatePickerComponent
            idRef='startDateRef'
            labelValue='start-date'
            labelClasses='Requierd-Color'
            placeholder='DD/MM/YYYY'
            value={state.startDate}
            helperText={getErrorByName(schema, 'startDate').message}
            error={getErrorByName(schema, 'startDate').error}
            isSubmitted={isSubmitted}
            parentTranslationPath={parentTranslationPath}
            translationPath={translationPath}
            onDateChanged={(newValue) => {
              onStateChanged({ id: 'startDate', value: newValue });
            }}
            isDisabled={cantEditReservedLeaseTransaction}
          />
        </div>
        <div className='form-item w-50'>
          <DatePickerComponent
            idRef='endDateRef'
            labelValue='end-date'
            labelClasses='Requierd-Color'
            placeholder='DD/MM/YYYY'
            isDisabled={state.startDate === null ||cantEditReservedLeaseTransaction }
            minDate={(state && state.startDate) || ''}
            value={state.endDate}
            helperText={getErrorByName(schema, 'endDate').message}
            error={getErrorByName(schema, 'endDate').error}
            isSubmitted={isSubmitted}
            parentTranslationPath={parentTranslationPath}
            translationPath={translationPath}
            onDateChanged={(newValue) => {
              onStateChanged({ id: 'endDate', value: newValue });
            }}
          />
        </div>
      </div>
      <div className='form-item'>
        <Inputs
          idRef='contactRefNoRef'
          labelValue='contract-ref-no'
          value={state.contractRefNo || ''}
          helperText={getErrorByName(schema, 'contractRefNo').message}
          error={getErrorByName(schema, 'contractRefNo').error}
          isWithError
          isSubmitted={isSubmitted}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          onInputChanged={(event) => {
            onStateChanged({ id: 'contractRefNo', value: event.target.value });
          }}
          isDisabled={cantEditReservedLeaseTransaction}

        />
      </div>
      <div className='form-item'>
        <AutocompleteComponent
          idRef='reservationTypeIdRef'
          labelValue='reservation-type'
          labelClasses='Requierd-Color'
          selectedValues={selected.reservationType}
          multiple={false}
          data={reservationTypes||[]}
          displayLabel={(option) => option.lookupItemName || ''}
          getOptionSelected={(option) => option.lookupItemId === state.reservationTypeId}
          withoutSearchButton
          helperText={getErrorByName(schema, 'reservationTypeId').message}
          error={getErrorByName(schema, 'reservationTypeId').error}
          isWithError
          isLoading={loadings.reservationTypes}
          isSubmitted={isSubmitted}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          onChange={(event, newValue) => {
            onSelectedChanged({ id: 'reservationType', value: newValue });
            onStateChanged({
              id: 'reservationTypeId',
              value: (newValue && newValue.lookupItemId) || null,
            });
          }}
          isDisabled={cantEditReservedLeaseTransaction}
        />
      </div>
      <div className='form-item'>
        <AutocompleteComponent
          idRef='rentFreePeriodIdRef'
          labelValue='rent-free-period-description'
          selectedValues={selected.rentFreePeriod}
          multiple={false}
          data={rentFreePeriods||[]}
          displayLabel={(option) => option.lookupItemName || ''}
          getOptionSelected={(option) => option.lookupItemId === state.rentFreePeriod}
          withoutSearchButton
          helperText={getErrorByName(schema, 'rentFreePeriodId').message}
          error={getErrorByName(schema, 'rentFreePeriodId').error}
          isWithError
          isLoading={loadings.rentFreePeriods}
          isSubmitted={isSubmitted}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          onChange={(event, newValue) => {
            onSelectedChanged({ id: 'rentFreePeriod', value: newValue });
            onStateChanged({
              id: 'rentFreePeriodId',
              value: (newValue && newValue.lookupItemId) || null,
            });
          }}
          isDisabled={cantEditReservedLeaseTransaction}
        />
      </div>
      <div className='form-item'>
        <DatePickerComponent
          idRef='transactionEntryDateRef'
          labelValue='transaction-entry-date'
          placeholder='DD/MM/YYYY'
          value={(state && state.transactionEntryDate)}
          helperText={getErrorByName(schema, 'transactionEntryDate').message}
          error={getErrorByName(schema, 'transactionEntryDate').error}
          isSubmitted={isSubmitted}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          onDateChanged={(newValue) => {
            onStateChanged({ id: 'transactionEntryDate', value: newValue });
          }}
          isDisabled={cantEditReservedLeaseTransaction}
        />
      </div>
      <div className='form-item'>
        <Inputs
          idRef='observationsRef'
          labelValue='observations'
          value={state.observations || ''}
          helperText={getErrorByName(schema, 'observations').message}
          error={getErrorByName(schema, 'observations').error}
          isWithError
          isSubmitted={isSubmitted}
          multiline
          rows={4}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          onInputChanged={(event) => {
            onStateChanged({ id: 'observations', value: event.target.value });
          }}
          isDisabled={cantEditReservedLeaseTransaction}
        />
      </div>
      <div className='form-item px-0'>
        <div className='px-2 w-50'>
          <Inputs
            idRef='occupantsAdultsRef'
            labelValue='number-of-adults-description'
            value={state.occupantsAdults || 0}
            helperText={getErrorByName(schema, 'occupantsAdults').message}
            error={getErrorByName(schema, 'occupantsAdults').error}
            isWithError
            isSubmitted={isSubmitted}
            type='number'
            min={0}
            parentTranslationPath={parentTranslationPath}
            translationPath={translationPath}
            onInputChanged={(event) => {
              const value = floatHandler(event.target.value, 3);
              onStateChanged({ id: 'occupantsAdults', value });
            }}
            isDisabled={cantEditReservedLeaseTransaction}
          />
        </div>
        <div className='px-2 w-50'>
          <Inputs
            idRef='occupantsChildrenRef'
            labelValue='number-of-children-description'
            value={state.occupantsChildren || 0}
            helperText={getErrorByName(schema, 'occupantsChildren').message}
            error={getErrorByName(schema, 'occupantsChildren').error}
            isWithError
            isSubmitted={isSubmitted}
            type='number'
            min={0}
            parentTranslationPath={parentTranslationPath}
            translationPath={translationPath}
            onInputChanged={(event) => {
              const value = floatHandler(event.target.value, 3);
              onStateChanged({ id: 'occupantsChildren', value });
            }}
            isDisabled={cantEditReservedLeaseTransaction}
          />
        </div>
      </div>
      <div className='form-item'>
        <AutocompleteComponent
          idRef='transactedByIdRef'
          labelValue='transacted-by-leasing-description'
          labelClasses='Requierd-Color'
          selectedValues={selected.transactedBy}
          multiple={false}
          data={transactedBy||[]}
          displayLabel={(option) => option.fullName || ''}
          renderOption={(option) =>
            ((option.userName || option.fullName) && `${option.fullName} (${option.userName})`) ||
            ''}
          getOptionSelected={(option) => option.id === state.transactedById}
          withoutSearchButton
          helperText={getErrorByName(schema, 'transactedById').message}
          error={getErrorByName(schema, 'transactedById').error}
          isWithError
          isSubmitted={isSubmitted}
          isLoading={loadings.transactedBy}
          onInputKeyUp={(e) => {
            const { value } = e.target;
            if (searchTimer.current) clearTimeout(searchTimer.current);
            searchTimer.current = setTimeout(() => {
              getAllTransactedBy(value, selected.transactedBy);
            }, 700);
          }}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          onChange={(event, newValue) => {
            onSelectedChanged({ id: 'transactedBy', value: newValue });
            onStateChanged({
              id: 'transactedById',
              value: (newValue && newValue.id) || null,
            });
          }}
          isDisabled={cantEditReservedLeaseTransaction}
        />
      </div>
    </div>
  );
};

AgentInfoRentRelatedComponent.propTypes = {
  state: PropTypes.instanceOf(Object).isRequired,
  selected: PropTypes.instanceOf(Object).isRequired,
  schema: PropTypes.instanceOf(Object).isRequired,
  unitTransactionId: PropTypes.number,
  onStateChanged: PropTypes.func.isRequired,
  onSelectedChanged: PropTypes.func.isRequired,
  isSubmitted: PropTypes.bool.isRequired,
  parentTranslationPath: PropTypes.string.isRequired,
  translationPath: PropTypes.string.isRequired,
};
AgentInfoRentRelatedComponent.defaultProps = {
  unitTransactionId: undefined,
};
