import React, { useState } from 'react';
import {
  DialogComponent,
  SelectComponet as SelectComponent,
} from '../../../../Components';

export const AssignToMeLeadTypeDialog = ({
  isOpen,
  onClose,
  assignData,
  onSave,
  translationPath,
  parentTranslationPath,
}) => {
  const [selectedType, setSelectedType] = useState(null);
  return (
    <DialogComponent
      isOpen={isOpen}
      onCancelClicked={onClose}
      onCloseClicked={onClose}
      titleText={'select-lead-type-to-assign'}
      dialogContent={
        <div className='py-3'>
          <SelectComponent
            data={assignData}
            textInput={'name'}
            valueInput={'id'}
            labelValue={'select-lead-type'}
            parentTranslationPath={parentTranslationPath}
            translationPath={translationPath}
            emptyItem={{
              value: null,
              text: 'select-lead-type',
              isHiddenOnOpen: true,
            }}
            value={selectedType}
            onSelectChanged={(e) => setSelectedType(e)}
          />
        </div>
      }
      maxWidth={'sm'}
      onSaveClicked={() => onSave(selectedType)}
      saveText={'select'}
      saveType={'button'}
      parentTranslationPath={parentTranslationPath}
      translationPath={translationPath}
    />
  );
};
