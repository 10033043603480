import { ConfigurationFormsView } from "../../Views/Home/RiskRatingConfiguration/ConfigurationForms/ConfigurationFormsView";
import { RiskRatingConfigurationView } from "../../Views/Home/RiskRatingConfiguration/RiskRatingConfigurationView";

export const RiskRatingRoutes = [
  {
    path: "/view",
    name: "RiskRating:risk-rating",
    component: RiskRatingConfigurationView,
    layout: "/home/risk-rating-configuration",
    default: true,
    isRoute: true,
    authorize: true,
    roles: [],
    isDisabled: false,
    isExact: true,
    breadcrumbs: [
      {
        name: "RiskRating:risk-rating",
        isDisabled: true,
        route: "/home/risk-rating-configuration/view",
        groupName: "system-configuration",
      },
    ],
  },
  {
    path: "/configuration-form",
    name: "RiskRating:risk-rating",
    component: ConfigurationFormsView,
    layout: "/home/risk-rating-configuration",
    default: true,
    isRoute: true,
    authorize: true,
    roles: [],
    isDisabled: false,
    isExact: true,
    breadcrumbs: [
      {
        name: "RiskRating:risk-rating",
        isDisabled: true,
        route: "/home/risk-rating-configuration/view",
        groupName: "system-configuration",
      },
      {
        name: "RiskRating:/configuration-form",
        isDisabled: true,
        route: "/home/risk-rating-configuration/configuration-form",
        groupName: "system-configuration",
      },
    ],
  },
];
