/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { ButtonBase } from '@material-ui/core';
import moment from 'moment';
import {
  Tables, SwitchComponent, Spinner, TableColumnsFilterComponent
} from '../../../../Components';
import {
  TableActions, ColumnsFilterPagesEnum, TableFilterTypesEnum, ActionsEnum, LeadsClassTypesEnum,
  LeadTypeIdEnum
} from '../../../../Enums';
import { TableColumnsFilterActions } from '../../../../store/TableColumnsFilter/TableColumnsFilterActions';
import {
  GetActivityById, GetAllRelatedActivitiesByActivityId
} from '../../../../Services';
import { ActivityDeleteDialog } from './Dialogs/ActivityDeleteDialog/ActivityDeleteDialog';
import { returnPropsByPermissions } from '../../../../Helper/ReturnPropsByPermissions.Helper';
import { ActivityHistory } from '../../ActivitiesView/ActivityHistory/ActivityHistory';
import { isEmptyObject } from '../../../../Helper';
import { ReplyActivityDialog } from '../../ActivitiesView/ReplyActivitesView/ReplyActivityDialog';
import { ActivitiesManagementDialog } from '../../../../SharedComponents/ActivitiesManagementDialog/ActivitiesManagementDialog';
import { ActivitiesLeasePermissions } from '../../../../Permissions';
import { ActiveItemActions } from '../../../../store/ActiveItem/ActiveItemActions';
import { CopyToClipboardComponents } from '../../../../ReusableComponents/UtilityComponents/CopyToClipboardComponents/CopyToClipboardComponents';


const parentTranslationPath = 'ActivitiesView';
const translationPath = '';

export const ActivitiesLeaseTableView = ({
  data,
  onPageIndexChanged,
  onPageSizeChanged,
  filter,
  activitiesCount,
  isTableRelatedView,
  isLoading,
  setIsLoading,
  setSortBy,
  activeActionType,
  onFilterValuesChanged , 
  localStorageKey

}) => {
  const { t } = useTranslation(parentTranslationPath);
  let listflattenObject = [];

  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [openReplyActivityDialog, setOpenReplyActivityDialog] = useState(false);
  const [activitesRelatedChildren, setActivitesRelatedChildren] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [openActivityHistoryDialog, setOpenActivityHistoryDialog] = useState(false);
  const [activeItem, setActiveItem] = useState(null);
  const tableColumnsFilterResponse = useSelector((state) => state.TableColumnsFilterReducer);
  const [tableColumns, setTableColumns] = useState([]);
  const [tableFilterData, setTableFilterData] = useState([]);
  const [isReplay, setIsReplay] = useState(false);

  const dispatch = useDispatch();

  const GetActivityAPI = useCallback(async (activityId) => {
    const res = await GetActivityById(activityId);
    if (!(res && res.status && res.status !== 200)) setActiveItem(res);
  }, []);
  const flattenObject = (obj) => {
    // eslint-disable-next-line prefer-const
    const flattened = {};
    Object.keys(obj).forEach((key) => {
      if (typeof obj[key] === 'object' && obj[key] !== null && key === 'relatedActivityTo') {
        if (obj[key]) {
          if (!isEmptyObject(obj[key]))
            listflattenObject.push({ ...obj[key] });
          Object.assign(flattened, flattenObject(obj[key]));
        }
      } else
        flattened[key] = obj[key];
    });
  };

  const GetRelatedActivitiesByActivityId = useCallback(async (activityId) => {
    setIsLoading(true);
    const res = await GetAllRelatedActivitiesByActivityId(activityId);
    if (!(res && res.status && res.status !== 200)) {
      listflattenObject = [];
      flattenObject(res);
      setActivitesRelatedChildren(listflattenObject.filter((item) => item.activityId));
    } else setActivitesRelatedChildren([]);
    setIsLoading(false);
  }, [activeItem]);
  const tableActionClicked = useCallback((actionEnum, item, focusedRow, event) => {
    event.stopPropagation();
    event.preventDefault();
    if (actionEnum === TableActions.deleteText.key) {
      setOpenConfirmDialog(true);
      setActiveItem(item);
    } else if (actionEnum === TableActions.editText.key) {
      GetActivityAPI(item.activityId);
      setOpenDialog(true);
    } else if (actionEnum === TableActions.replyText.key) {
      setActiveItem(item);
      setOpenReplyActivityDialog(true);
    }
  }, [GetActivityAPI]);

  const focusedRowChanged = (activeRow) => {
    const item = data[activeRow];
    if (!item) return;
    setIsReplay(item.isReplyAble);
  };
  const getTableActionsWithPermissions = () => {
    if (isReplay) {
      return (returnPropsByPermissions(ActivitiesLeasePermissions.ViewActivityDetails.permissionsId) ?
        [{ enum: TableActions.editText.key }, { enum: TableActions.replyText.key, isDisabled: false }] :
        [{ enum: TableActions.replyText.key, isDisabled: false }]);
    }
    return (returnPropsByPermissions(ActivitiesLeasePermissions.ViewActivityDetails.permissionsId) ?
      [{ enum: TableActions.editText.key }] : []);
  };

  const detailedCardSideActionClicked = useCallback(
    (actionEnum, activeData, type) => async (event) => {
      dispatch(ActiveItemActions.activeItemRequest(
        {
          id: activeData.relatedLeadNumberId || '',
          ...activeData
        }
      ));
      event.stopPropagation();
      if (actionEnum === ActionsEnum.folder.key) {
        if (activeData.relatedLeadNumberId && type == 'lead') {
          const leadForm = activeData && (activeData.leadClass === LeadsClassTypesEnum.buyer.key || activeData.leadClass === LeadsClassTypesEnum.tenant.key) ? LeadTypeIdEnum.Seeker.leadTypeId : LeadTypeIdEnum.Owner.leadTypeId;
          const leadOprationType = activeData && (activeData.leadClass === LeadsClassTypesEnum.buyer.key || activeData.leadClass === LeadsClassTypesEnum.seller.key) ? 'Sale' : 'Lease';
          if (leadOprationType === 'Sale')
            window.open(`/home/lead-sales/lead-profile-edit?formType=${leadForm}&id=${activeData.relatedLeadNumberId}`, '_blank').focus();
          else
            window.open(`/home/lead-lease/lead-profile-edit?formType=${leadForm}&id=${activeData.relatedLeadNumberId}`, '_blank').focus();
        }else if (activeData.relatedContactNumberId && type == 'contact'){
          if (activeData?.contactTypeId === 1){
            window.open(`/home/Contacts-CRM/contact-profile-edit?formType=${1}&id=${activeData.relatedContactNumberId}`, '_blank').focus();
          }else if (activeData?.contactTypeId === 2){
            window.open(`/home/Contacts-CRM/contact-profile-edit?formType=${2}&id=${activeData.relatedContactNumberId}`, '_blank').focus();
          }
        }
         else if (activeData.relatedUnitNumberId && type == 'unit')
          window.open(`units-sales/unit-profile-edit?formType=1&id=${activeData.relatedUnitNumberId}&operationType=${activeData.operationType}`, '_blank').focus();
      }
    },
    [dispatch]
  );

  const allActivitiesTableHeaderData = [
    {
      id: 1,
      label: 'activity-Date',
      input: 'activityDate',
      isDate: true,
      isSortable: true,
      dateFormat: 'DD/MM/YYYY',
      isDefaultFilterColumn: true,
    },

    {
      id: 2,
      label: 'created-Date',
      isDate: true,
      input: 'createdOn',
      isSortable: true,
      dateFormat: 'DD/MM/YYYY',
      isDefaultFilterColumn: true,
    },
    {
      id: 3,
      label: 'created-time',
      input: 'createdOn',
      isDate: true,
      dateFormat: ' hh:mm A',
      isDefaultFilterColumn: true,
      isHiddenFilter: true
    },
    {
      id: 4,
      label: 'created-By',
      input: 'createdBy',
      component: (item) => (
        <span>{item.createdByName}</span>
      ),
      isDefaultFilterColumn: true,
    },
    {
      id: 5,
      label: 'related-to',
      input: 'relatedTo',
      isHiddenFilter: true,
      component: (item) => (
        <span className='c-primary'>
          {(item.relatedLeadNumberId && item.relatedUnitNumberId && t(`${translationPath}lead/unit`)) ||
            (item.relatedLeadNumberId && t(`${translationPath}lead`)) ||
            (item.relatedUnitNumberId && t(`${translationPath}unit`)) ||
            'N/A'}
        </span>
      ),
      isDefaultFilterColumn: true,
    },
    {
      id: 7,
      label: 'related-lead-id',
      input: 'relatedLeadId',
      component: (item) => (
        <span className='c-primary'>
          {(
            item && item.relatedLeadNumberId && (
              <CopyToClipboardComponents
                data={item.relatedLeadNumberId}
                childrenData={item.relatedLeadNumberId}
                CustomizationClassName='block-container'
              />
            )) ||
            'N/A'}
        </span>
      ),
      isDefaultFilterColumn: true,
    },
    {
      id: 8,
      label: 'related-lead-name',
      input: 'relatedToName',
      isHiddenFilter: true,
      component: (item) => (
        <span className='c-primary'>
          {(item.relatedLeadNumberId && item.relatedLeadFullName) || 'N/A'}
        </span>
      ),
      isDefaultFilterColumn: true,
    },
    {
      id: 16,
      label: 'related-unit-ref-no',
      input: 'relatedUnitref',
      isHiddenFilter: true,
      component: (item) => (
        <span className='c-primary'>
          {item.unitReferenceNo || 'N/A'}
        </span>
      ),
      isDefaultFilterColumn: true,
    },
    {
      id: 17,
      label: 'related-unit-name',
      input: 'relatedToName',
      isHiddenFilter: true,
      component: (item) => (
        <span className='c-primary'>
          {item.relatedUnitName || 'N/A'}
        </span>
      ),
      isDefaultFilterColumn: true,
    },
    {
      id: 18,
      label: 'contact-id',
      input: 'contactId',
      component: (item) =>
        item?.relatedContactNumberId ? (
          <ButtonBase
            onClick={detailedCardSideActionClicked(
              ActionsEnum.folder.key,
              item,
              'contact'
            )}
          >
            <div class='icon-container mx-1'>
              <span class='mdi mdi-open-in-new c-primary'></span>
            </div>
            <CopyToClipboardComponents
              data={item?.relatedContactNumberId}
              childrenData={item?.relatedContactNumberId}
            />
          </ButtonBase>
        ) : (
          ''
        ),
      isDefaultFilterColumn: true,
    },
    {
      id: 8,
      label: 'assign-to',
      input: 'assignedTo',
      isDefaultFilterColumn: true,
    },
    {
      id: 9,
      label: 'stage',
      input: 'stage',
      isDefaultFilterColumn: true,
      isHiddenFilter: false,
      component: (item) => (<span>{item && item.leadStage || ''}</span> || '')
    },
    {
      id: 10,
      label: 'category',
      input: 'category',
      isDefaultFilterColumn: true,
      component: (item) => (<span>{item && item.categoryName || ''}</span> || '')
    },

    {
      id: 11,
      label: 'activity-type',
      input: 'activityType',
      isDefaultFilterColumn: true,
      component: (item) => <span>{item && item.activityTypeName || ''}</span>

    },
    {
      id: 12,
      label: 'status',
      input: 'status',
      withSelectFilter: true,
      cellClasses: 'py-0',
      component: (item, index) => (
        (item.isOpen && <div className='globel-open-style' index={index}>{t(`${translationPath}open`)}</div>) || <div className='globel-closed-style' index={index}>{t(`${translationPath}closed`)}</div>
      ),
      isDefaultFilterColumn: true,
    },
    {
      id: 13,
      label: 'copyTo',
      input: 'copyTo',
      isDefaultFilterColumn: true,
      component: (item) => (<span>{item && item.copyToFullName || ''}</span> || '')
    }, {
      id: 17,
      label: 'comments',
      input: 'comments',
      isDefaultFilterColumn: true,
      isHiddenFilter: true,
    },

  ];

  const relatedActivitiesTableHeaderData = [
    {
      id: 1,
      label: 'history',
      input: 'relatedActivityToId',
      cellClasses: 'py-0',
      component: (item) => (
        item.relatedActivityToId && (
          <ButtonBase
            className='MuiButtonBase-root btns-icon  mt-1 mx-2'
            onClick={() => {
              GetRelatedActivitiesByActivityId(item.activityId);
              setActiveItem(item);
              setOpenActivityHistoryDialog(true);
            }}
          >
            <span className='table-action-icon mdi mdi-undo-variant' />
            <span className='MuiTouchRipple-root' />

          </ButtonBase>
        )
      ),
      isHiddenFilter: true,
      isDefaultFilterColumn: true,
    },
    {
      id: 2,
      label: 'activity-Date',
      input: 'activityDate',
      isDate: true,
      isSortable: true,
      dateFormat: 'DD/MM/YYYY',
      isDefaultFilterColumn: true,
    },
    {
      id: 3,
      label: 'created-Date',
      isDate: true,
      isSortable: false,
      input: 'createdDate',
      dateFormat: 'DD/MM/YYYY',
      isDefaultFilterColumn: true,
      component: (item) => (<span>{item && moment(item.createdOn).format('YYYY-MM-DD') || ''}</span> || '')
    },
    {
      id: 4,
      label: 'created-time',
      input: 'createdOn',
      isDate: true,
      dateFormat: ' hh:mm A',
      isDefaultFilterColumn: true,
      isHiddenFilter: true
    },
    {
      id: 5,
      label: 'created-By',
      input: 'createdBy',
      component: (item) => (
        <span>{item.createdByName}</span>
      ),
      isDefaultFilterColumn: true,
    },

    {
      id: 6,
      label: 'related-to',
      input: 'relatedTo',
      isHiddenFilter: true,
      component: (item) => (
        <span className='c-primary'>
          {(item.relatedLeadNumberId && item.relatedUnitNumberId && t(`${translationPath}lead/unit`)) ||
            (item.relatedLeadNumberId && t(`${translationPath}lead`)) ||
            (item.relatedUnitNumberId && t(`${translationPath}unit`)) ||
            'N/A'}
        </span>

      ),
      isDefaultFilterColumn: true,
    },
    {
      id: 7,
      label: 'related-lead-id',
      input: 'relatedLeadId',
      component: (item) => (item.relatedLeadNumberId ? (
        <a className='c-primary newTabActivites' onClick={detailedCardSideActionClicked(ActionsEnum.folder.key, item, 'lead')}>

          {(
            item && item.relatedLeadNumberId && (
              <CopyToClipboardComponents
                data={item.relatedLeadNumberId}
                childrenData={item.relatedLeadNumberId}
                CustomizationClassName='block-container'
              />
            )) ||
            'N/A'}

        </a>
      ) : <span className='c-primary'>N/A</span>),
      isDefaultFilterColumn: true,
    },
    {
      id: 8,
      label: 'related-lead-name',
      input: 'relatedToName',
      isHiddenFilter: true,
      component: (item) => (
        <span className='c-primary'>
          {(item.relatedLeadNumberId && item.relatedLeadFullName) || 'N/A'}
        </span>
      ),
      isDefaultFilterColumn: true,
    },
    {
      id: 18,
      label: 'contact-id',
      input: 'contactId',
      component: (item) =>
        item?.relatedContactNumberId ? (
          <ButtonBase
            onClick={detailedCardSideActionClicked(
              ActionsEnum.folder.key,
              item,
              'contact'
            )}
          >
            <div class='icon-container mx-1'>
              <span class='mdi mdi-open-in-new c-primary'></span>
            </div>
            <CopyToClipboardComponents
              data={item?.relatedContactNumberId}
              childrenData={item?.relatedContactNumberId}
            />
          </ButtonBase>
        ) : (
          ''
        ),
      isDefaultFilterColumn: true,
    },
    {
      id: 16,
      label: 'related-unit-ref-no',
      input: 'relatedUnitref',
      isHiddenFilter: true,
      component: (item) => (
        <a className='c-primary newTabActivites' onClick={detailedCardSideActionClicked(ActionsEnum.folder.key, item, 'unit')}>
          {(item.unitReferenceNo) || 'N/A'}
        </a>
      ),
      isDefaultFilterColumn: true,
      isNumber: true
    },
    {
      id: 15,
      label: 'related-unit-name',
      input: 'relatedToName',
      isHiddenFilter: true,
      component: (item) => (
        <span className='c-primary'>
          {item.relatedUnitName || 'N/A'}
        </span>
      ),
      isDefaultFilterColumn: true,
    },
    {
      id: 9,
      label: 'assign-to',
      input: 'assignedTo',
      isDefaultFilterColumn: true,
    },
    {
      id: 10,
      label: 'stage',
      input: 'stage',
      isDefaultFilterColumn: true,
      isHiddenFilter: false,
      component: (item) => (<span>{item && item.leadStage || ''}</span> || '')
    },
    {
      id: 11,
      label: 'category',
      input: 'category',
      isDefaultFilterColumn: true,
      component: (item) => (<span>{item && item.categoryName || ''}</span> || '')
    },

    {
      id: 12,
      label: 'activity-type',
      input: 'activityType',
      isDefaultFilterColumn: true,
      component: (item) => <span>{item && item.activityTypeName || ''}</span>
    },
    {
      id: 13,
      label: 'status',
      input: 'status',
      withSelectFilter: true,
      cellClasses: 'py-0',
      component: (item, index) => (
        (item.isOpen && <div className='globel-open-style' index={index}>{t(`${translationPath}open`)}</div>) || <div className='globel-closed-style' index={index}>{t(`${translationPath}closed`)}</div>
      ),
      isDefaultFilterColumn: true,
      isHiddenFilter: false
    },
    {
      id: 14,
      label: 'comments',
      input: 'comments',
      isDefaultFilterColumn: true,
      isHiddenFilter: true,
    },
    {
      id: 17,
      label: 'Copy-To',
      input: 'copyTo',
      isDefaultFilterColumn: true,
      component: (item) => (<span>{item && item.copyToFullName || ''}</span> || '')
    }

  ];

  const [activitiesTableHeaderData, setActivitiesTableHeaderData] = useState(relatedActivitiesTableHeaderData);

  const [selectedTableFilterColumns, setSelectedTableFilterColumns] = useState({
    tableRelatedView: relatedActivitiesTableHeaderData.filter((item) => item.isDefaultFilterColumn).map((column) => column.id),
    tableView: allActivitiesTableHeaderData.filter((item) => item.isDefaultFilterColumn).map((column) => column.id),
  });

  useEffect(() => {
    if (activeActionType && activeActionType === 'tableRelatedView')
      setActivitiesTableHeaderData(relatedActivitiesTableHeaderData);
    else if (activeActionType && activeActionType === 'tableView')
      setActivitiesTableHeaderData(allActivitiesTableHeaderData);
  }, [activeActionType]);

  useEffect(() => {
    setTableColumns([
      ...activitiesTableHeaderData.filter(
        (item) => selectedTableFilterColumns[activeActionType].findIndex((element) => element === item.id) !== -1
      )
    ]);
  }, [selectedTableFilterColumns, activeActionType, activitiesTableHeaderData]);

  useEffect(() => {
    if (activeActionType === 'tableRelatedView' &&
      tableColumnsFilterResponse &&
      tableColumnsFilterResponse[ColumnsFilterPagesEnum.activityLeaseRelated.key]
    )
      setSelectedTableFilterColumns({ ...selectedTableFilterColumns, tableRelatedView: tableColumnsFilterResponse[ColumnsFilterPagesEnum.activityLeaseRelated.key] });

    else if (activeActionType === 'tableView' && tableColumnsFilterResponse &&
      tableColumnsFilterResponse[ColumnsFilterPagesEnum.activityLeaseAll.key])
      setSelectedTableFilterColumns({ ...selectedTableFilterColumns, tableView: tableColumnsFilterResponse[ColumnsFilterPagesEnum.activityLeaseAll.key] });
  }, [tableColumnsFilterResponse, activeActionType]);

  useEffect(() => {
    setTableFilterData(
      tableColumns.map((column) => ({
        key: column.key || column.fieldKey || column.id,
        filterType: (column.isDate && TableFilterTypesEnum.datePicker.key) || (column.withSelectFilter && TableFilterTypesEnum.selectOption.key) || TableFilterTypesEnum.textInput.key,
        isHiddenFilter: column.isHiddenFilter,
        isHidden: column.isHidden,
        textInputType: column.textInputType,
        textInputMax: column.textInputMax,
        textInputMin: column.textInputMin,
        displayPath: column.input
      }))
    );
  }, [tableColumns]);

  return (
    <div className='w-100 px-2'>

      <div className='w-100 px-3'>
        <TableColumnsFilterComponent
          columns={activitiesTableHeaderData.filter(item => !item.isHidden ).map((item) => ({
            key: item.formFieldId || item.id,
            value: (item.formFieldTitle && item.formFieldTitle.replace('*', '')) || item.label,
          }))}

          isLoading={isLoading}
          selectedColumns={selectedTableFilterColumns[activeActionType]}
          onSelectedColumnsChanged={(newValue) => {
            let localTableColumnsFilterResponse = tableColumnsFilterResponse;
            setSelectedTableFilterColumns({ ...selectedTableFilterColumns, [activeActionType]: newValue });
            if (localTableColumnsFilterResponse) {
              if (activeActionType && activeActionType === 'tableRelatedView') localTableColumnsFilterResponse[ColumnsFilterPagesEnum.activityLeaseRelated.key] = newValue;
              else if (activeActionType && activeActionType === 'tableView') localTableColumnsFilterResponse[ColumnsFilterPagesEnum.activityLeaseAll.key] = newValue;
            } else if (activeActionType && activeActionType === 'tableRelatedView') {
              localTableColumnsFilterResponse = {
                [ColumnsFilterPagesEnum.activityLeaseRelated.key]: newValue,
              };
            } else if (activeActionType && activeActionType === 'tableView') {
              localTableColumnsFilterResponse = {
                [ColumnsFilterPagesEnum.activityLeaseAll.key]: newValue,
              };
            }
            dispatch(
              TableColumnsFilterActions.TableColumnsFilterRequest(localTableColumnsFilterResponse)
            );
          }}
          parentTranslationPath={parentTranslationPath}
          translationPath=''
        />

        {!isTableRelatedView && (
          <Tables
            data={data}
            headerData={tableColumns}
            actionsOptions={{
              onActionClicked: tableActionClicked,
            }}
            defaultActions={getTableActionsWithPermissions()}
            focusedRowChanged={focusedRowChanged}
            onPageIndexChanged={onPageIndexChanged}
            onPageSizeChanged={onPageSizeChanged}
            itemsPerPage={filter.pageSize}
            activePage={filter.pageIndex}
            translationPath={translationPath}
            parentTranslationPath={parentTranslationPath}
            totalItems={activitiesCount}
            setSortBy={setSortBy}
            isWithFilter
            FilterDisabledButton
            onFilterValuesChanged={onFilterValuesChanged}
            filterData={tableFilterData}
            optionFilterName={[
              {
                key: '1',
                value: 'open',
              },
              {
                key: '0',
                value: 'close',
              }
            ]}
            localStorageKey={localStorageKey}
          />

        )}
        {isTableRelatedView && (
          <Tables
            data={data}
            headerData={tableColumns}
            actionsOptions={{
              onActionClicked: tableActionClicked,
            }}
            defaultActions={getTableActionsWithPermissions()}
            focusedRowChanged={focusedRowChanged}
            onPageIndexChanged={onPageIndexChanged}
            onPageSizeChanged={onPageSizeChanged}
            itemsPerPage={filter.pageSize}
            activePage={filter.pageIndex}
            translationPath={translationPath}
            parentTranslationPath={parentTranslationPath}
            totalItems={activitiesCount}
            setSortBy={setSortBy}
            isWithFilter
            FilterDisabledButton
            onFilterValuesChanged={onFilterValuesChanged}
            filterData={tableFilterData}
            optionFilterName={[
              {
                key: '1',
                value: 'open',
              },
              {
                key: '0',
                value: 'close',
              }
            ]}
            localStorageKey={localStorageKey}

          />

        )}

      </div>
      {openDialog && (
        <ActivitiesManagementDialog
          open={openDialog}
          activeItem={activeItem}
          onSave={() => {
            setOpenDialog(false);
            setActiveItem(null);
            onPageIndexChanged(0);
          }}
          close={() => {
            setActiveItem(null);
            setOpenDialog(false);
          }}
          translationPath={translationPath}
          parentTranslationPath={parentTranslationPath}
        />
      )}
      {openConfirmDialog && (
        <ActivityDeleteDialog
          isOpen={openConfirmDialog}
          activeItem={activeItem}
          reloadData={() => {
            setOpenDialog(false);
            setActiveItem(null);
            onPageIndexChanged(0);
          }}
          isOpenChanged={() => {
            setActiveItem(null);
            setOpenDialog(false);
          }}
          translationPath={translationPath}
          parentTranslationPath={parentTranslationPath}
        />
      )}
      {openActivityHistoryDialog && (
        <ActivityHistory
          isLoading={isLoading}
          open={openActivityHistoryDialog}
          close={() => {
            listflattenObject = [];
            setActivitesRelatedChildren([]);
            setOpenActivityHistoryDialog(false);
          }}
          data={activitesRelatedChildren.reverse()}
          translationPath={translationPath}
          parentTranslationPath={parentTranslationPath}
        />
      )}
      {openReplyActivityDialog && (
        <ReplyActivityDialog
          open={openReplyActivityDialog}
          close={() => {
            setActiveItem(null);
            setOpenReplyActivityDialog(false);
          }}
          activeItem={activeItem}
          onSave={() => {
            setOpenReplyActivityDialog(false);
            setActiveItem(null);
            onPageIndexChanged(0);
          }}
          translationPath={translationPath}
          parentTranslationPath={parentTranslationPath}
        />
      )}

    </div>
  );
};

ActivitiesLeaseTableView.propTypes = {
  data: PropTypes.arrayOf(PropTypes.instanceOf(Object)).isRequired,
  onPageIndexChanged: PropTypes.string.isRequired,
  onPageSizeChanged: PropTypes.string.isRequired,
  filter: PropTypes.instanceOf(Object).isRequired,
  activitiesCount: PropTypes.number.isRequired,
  isTableRelatedView: PropTypes.bool,
  isLoading: PropTypes.bool.isRequired,
  setIsLoading: PropTypes.func.isRequired,
  setSortBy: PropTypes.func.isRequired,

};

ActivitiesLeaseTableView.defaultProps = {
  isTableRelatedView: false,
};
