import React, { useCallback, useEffect, useReducer, useState } from "react";
import {
  AutocompleteComponent,
  DialogComponent,
  Spinner,
} from "../../../../Components";
import { useTranslation } from "react-i18next";
import {
  getDownloadableLink,
  getErrorByName,
  showError,
  showSuccess,
} from "../../../../Helper";
import Joi from "joi";
import {
  GetLookupItemsByLookupTypeName,
  UploadAMLTransactionDocuments,
} from "../../../../Services";
import { MultipleTypesDocumentsUploader } from "../../../../SharedComponents";

function AMLDocumentsUploader({
  IDS,
  isOpen,
  onCloseClicked,
  unitTransactionId,
  translationPath,
  parentTranslationPath,
}) {
  const { t } = useTranslation(parentTranslationPath);
  const reducer = useCallback((state, action) => {
    if (action.id !== "edit") return { ...state, [action.id]: action.value };
    return {
      ...action.value,
    };
  }, []);

  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [amlCategories, setAmlCategories] = useState([]);
  const [state, setState] = useReducer(reducer, {
    amlCategoryId: null,
  });
  const [selected, setSelected] = useReducer(reducer, {
    amlCategory: {},
    amlDocuments: [],
  });

  const schema = Joi.object({
    amlCategoryId: Joi.number()
      .required()
      .messages({
        "number.base": t(`${translationPath}category-is-required`),
      }),
  })
    .options({
      abortEarly: false,
      allowUnknown: true,
    })
    .validate(state);

  const GetLookupItemsByLookupTypeNameAPI = useCallback(async () => {
    setIsLoading(true);

    const res = await GetLookupItemsByLookupTypeName({
      lookUpName: "AMLDocumentCategories",
      pageSize: 150,
      pageIndex: 1,
    });
    if (!(res && res.status && res.status !== 200)) {
      setAmlCategories(res);
    } else {
      setAmlCategories([]);
    }

    setIsLoading(false);
  }, []);

  const onSaveClicked = async () => {
    setIsLoading(true);
    setIsSubmitted(true);

    if (schema?.error) {
      showError(t("Shared:please-fix-all-errors"));
      setIsLoading(false);
      return;
    } else {
      let categoryFiles = [
        {
          categoryId: selected?.amlCategory?.lookupItemId,
          files: selected.amlDocuments.map((el) => ({
            fileId: el?.uuid,
            fileName: el?.fileName,
            documentLink: el?.url,
          })),
        },
      ];

      const body = {
        unitTransactionId: IDS,
        categoryFiles,
      };

      const res = await UploadAMLTransactionDocuments(body);
      if (!(res && res.status && res.status !== 200)) {
        showSuccess(t("uploaded-successfully"));
        onCloseClicked();
      } else {
        showError(t("error-while-uploading"));
      }
    }

    setIsLoading(false);
  };

  useEffect(() => {
    GetLookupItemsByLookupTypeNameAPI();
  }, []);

  return (
    <>
      <DialogComponent
        titleText={t("aml-documents-upload")}
        saveText={t("confirm")}
        saveType="button"
        maxWidth="lg"
        onSaveClicked={() => {
          onSaveClicked();
        }}
        onCloseClicked={() => {
          onCloseClicked();
        }}
        onCancelClicked={() => {
          onCloseClicked();
        }}
        isOpen={isOpen}
        dialogContent={
          <>
            <Spinner isActive={isLoading} isAbsolute />
            <div className="">
              <div className="w-100 my-2">
                <AutocompleteComponent
                  idRef="amlDocumentCategoryRef"
                  labelValue="aml-category"
                  labelClasses="Requierd-Color"
                  data={amlCategories?.result || []}
                  selectedValues={selected?.amlCategory}
                  multiple={false}
                  isLoading={isLoading}
                  isSubmitted={isSubmitted}
                  isWithError
                  withoutSearchButton
                  displayLabel={(option) => option?.lookupItemName || ""}
                  getOptionSelected={(option) =>
                    option?.lookupItemId === selected?.amlCategory?.lookupItemId
                  }
                  onChange={(e, newValue) => {
                    setSelected({ id: "amlCategory", value: newValue });
                    setState({
                      id: "amlCategoryId",
                      value: newValue?.lookupItemId || null,
                    });
                  }}
                  helperText={getErrorByName(schema, "amlCategoryId").message}
                  error={getErrorByName(schema, "amlCategoryId").error}
                  translationPath={translationPath}
                  parentTranslationPath={parentTranslationPath}
                />
              </div>
              <div className="w-100 my-2">
                <div className="">
                  <MultipleTypesDocumentsUploader
                    initUploadedFiles={[]}
                    labelValue="aml-documents"
                    uploadedChanged={(files) => {
                      setSelected({
                        id: "amlDocuments",
                        value: files,
                      });
                    }}
                    multiple
                    isOpenGallery
                    idRef="amlDocumentsMultiUploaderRef"
                    viewUploadedFilesCount={false}
                    openGallery
                    isDocuments
                    chipHandler={(value) => () => {
                      const link = document.createElement("a");
                      link.setAttribute("download", value.fileName);
                      link.href = getDownloadableLink(value.uuid);
                      document.body.appendChild(link);
                      link.click();
                      link.remove();
                    }}
                    parentTranslationPath={parentTranslationPath}
                    translationPath={translationPath}
                  />
                </div>
              </div>
            </div>
          </>
        }
        translationPath={translationPath}
        parentTranslationPath={parentTranslationPath}
      />
    </>
  );
}

export default AMLDocumentsUploader;
