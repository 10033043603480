import React, { useCallback, useEffect, useRef, useState } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { Spinner, Tables } from '../../../Components';
import { TableActions, TableFilterTypesEnum } from '../../../Enums';
import { GetLeadsRotationLog } from '../../../Services/RotaionSchemaService/RotationSchemaService';

import { GlobalOrderFilterActions } from '../../../store/GlobalOrderFilter/GlobalOrderFilterActions';
import { useTitle } from '../../../Hooks';
import { Link } from 'react-router-dom';

export const RotationLogView = () => {
  const parentTranslationPath = 'RotationLogView';
  const translationPath = '';
  const { t } = useTranslation(parentTranslationPath);
  useTitle(t(`${translationPath}rotaion-log`));
  const [isLoading, setIsLoading] = useState(false);
  const [sortBy, setSortBy] = useState(null);
  const [tableFilterData, setTableFilterData] = useState([]);
  const [rotationLogData, setRotationLogData] = useState({
    result: [],
    totalCount: 0,
  });
  // const orderFilter = useSelector((state) => state.GlobalOrderFilterReducer);
  // const dispatch = useDispatch();
  // const [selectedOrderBy, setSelectedOrderBy] = useState({
  //     filterBy: (orderFilter.rotationLogDataFilter && orderFilter.rotationLogDataFilter.filterBy) || null,
  //     orderBy: (orderFilter.rotationLogDataFilter && orderFilter.rotationLogDataFilter.orderBy) || null,
  // });
  const [orderBy, setOrderBy] = useState({ filterBy: null, orderBy: null });
  const [filter, setFilter] = useState({
    pageIndex: 0,
    pageSize: parseInt(localStorage.getItem('Pagination')) || 25,
    orderBy: (orderBy && orderBy.orderBy) || null,
    filterBy: (orderBy && orderBy.filterBy) || null,
    leadName: null,
    leadId: null,
    leadTypeId: null,
    agentName: null,
    agentExpiryPeriod: null,
    agentBranch: null,
    rotationName: null,
    isLose: null,
    creationFrom: null,
    creationTo: null,
  });
  const headerData = [
    {
      id: 1,
      isSortable: true,
      label: 'leadId',
      input: 'leadId',
      component: (item) => (
        <Link
          to={`/home/leads/lead-profile-edit?formType=${item.leadTypeId}&id=${item.leadId}`}
          target="_blank"
        >
          {item.leadId}
        </Link>
      ),
    },
    {
      id: 2,
      isSortable: true,
      label: 'leadName',
      input: 'leadName',
    },
    {
      id: 3,
      isSortable: true,
      label: 'leadTypeDto',
      input: 'leadTypeId',
      component: (item) => <span>{item && item.leadTypeName}</span> || '',
    },
    {
      id: 4,
      isSortable: true,
      label: 'agentName',
      input: 'agentName',
    },
    {
      id: 5,
      isSortable: true,
      label: 'agentExpiryPeriod',
      input: 'agentExpiryPeriod',
    },
    {
      id: 6,
      isSortable: true,
      input: 'agentBranch',
      label: 'agentBranch',
    },
    {
      id: 7,
      isSortable: true,
      label: 'rotationName',
      input: 'rotationName',
    },
    {
      id: 8,
      isSortable: true,
      label: 'isLose',
      input: 'isLose',
      component: (item) =>
        <span>{item && item.isLose ? 'Yes' : 'No'}</span> || '',
    },
    {
      id: 9,
      isSortable: true,
      label: 'creationDate',
      input: 'creationDate',
      isDate: true,
      dateFormat: 'YYYY-MM-DD / HH:mm:ss',
      component: (item) =>
        (
          <span>
            {item &&
              item.creationDate &&
              moment(item.creationDate).format('DD/MM/YYYY - hh:mm A')}
          </span>
        ) || '',
    },
  ];

  const onFilterValuesChanged = (newValue) => {
    let localFilterDto = filter || {};

    // if (localFilterDto && localFilterDto.creationDate) {
    //     localFilterDto.creationTo = moment(localFilterDto.creationTo).format('YYYY-MM-DDTHH:mm:ss');
    //     localFilterDto.creationFrom = moment(localFilterDto.creationFrom).format('YYYY-MM-DDTHH:mm:ss');
    // }
    if (newValue) {
      Object.values(newValue)
        .filter((item) => item.searchableKey || item.displayPath)
        .map((item) => {
          if (localFilterDto[item.displayPath]) {
            localFilterDto[item.displayPath] = item.value;
          } else if (item.value) {
            localFilterDto[item.displayPath] = item.value;
          }
          if (item.displayPath === 'leadTypeId' && item.value)
            localFilterDto[item.displayPath] = 'seeker'.includes(
              item.value.toLowerCase()
            )
              ? 2
              : 'owner'.includes(item.value.toLowerCase())
              ? 1
              : item.value;
          if (item.displayPath === 'isLose' && item.value)
            localFilterDto[item.displayPath] = 'yes'.includes(
              item.value.toLowerCase()
            )
              ? true
              : 'no'.includes(item.value.toLowerCase())
              ? false
              : item.value;
          if (item.displayPath === 'creationDate' && item.value) {
            localFilterDto.creationTo = moment(item.value).format('YYYY-MM-DD');
            localFilterDto.creationFrom = moment(item.value).format(
              'YYYY-MM-DD'
            );
          } else {
            localFilterDto.creationTo = null;
            localFilterDto.creationFrom = null;
          }
          return undefined;
        });
    }
    setFilter(() => ({ ...localFilterDto }));
  };
  const tableActionClicked = useCallback(
    (actionEnum, item, focusedRow, event) => {
      event.stopPropagation();
      event.preventDefault();
    },
    []
  );

  const getLeadsRotationLog = useCallback(async () => {
    setIsLoading(true);
    const res = await GetLeadsRotationLog({
      ...filter,
      pageIndex: filter.pageIndex + 1,
    });
    if (!(res && res.status && res.status !== 200)) {
      setRotationLogData({
        result: res && res.result,
        totalCount: res && res.totalCount,
      });
    } else {
      setRotationLogData({
        result: [],
        totalCount: 0,
      });
    }
    setIsLoading(false);
  }, [filter, orderBy]);

  const onPageIndexChanged = (pageIndex) => {
    setFilter((item) => ({ ...item, pageIndex }));
  };
  const onPageSizeChanged = (pageSize) => {
    setFilter((item) => ({
      ...item,
      pageIndex: 0,
      pageSize,
    }));
  };
  useEffect(() => {
    getLeadsRotationLog();
  }, [filter, getLeadsRotationLog]);

  // const filterByChanged = (value) => {
  //     setSelectedOrderBy((item) => ({ ...item, filterBy: value }));
  // };
  // const orderByChanged = (value) => {
  //     setSelectedOrderBy((item) => ({ ...item, orderBy: value }));
  // };
  // const orderBySubmitted = (event) => {
  //     event.preventDefault();
  //     if (!selectedOrderBy.filterBy || !selectedOrderBy.orderBy) {
  //         if (orderBy.filterBy || orderBy.orderBy) setOrderBy({});
  //         return;
  //     }
  //     dispatch(
  //         GlobalOrderFilterActions.globalOrderFilterRequest({
  //             ...orderFilter,
  //             rotationLogDataFilter: {
  //                 filterBy: selectedOrderBy.filterBy,
  //                 orderBy: selectedOrderBy.orderBy,
  //             },
  //         })
  //     );
  //     setOrderBy({
  //         filterBy: selectedOrderBy.filterBy,
  //         orderBy: selectedOrderBy.orderBy,
  //     });
  // };

  useEffect(() => {
    if (sortBy)
      setFilter((item) => ({
        ...item,
        filterBy: sortBy.filterBy,
        orderBy: sortBy.orderBy,
      }));
  }, [sortBy]);

  useEffect(() => {
    setTableFilterData(
      headerData.map((column) => ({
        key: column.key || column.fieldKey || column.id,
        filterType:
          (column.isDate && TableFilterTypesEnum.datePicker.key) ||
          (column.withSelectFilter && TableFilterTypesEnum.selectOption.key) ||
          TableFilterTypesEnum.textInput.key,
        isHiddenFilter: column.isHiddenFilter,
        textInputType: column.textInputType,
        textInputMax: column.textInputMax,
        textInputMin: column.textInputMin,
        displayPath: column.input,
      }))
    );
  }, []);

  return (
    <div className="view-wrapper">
      <Spinner isActive={isLoading} />
      <div className="d-flex-column">
        {/* <div className='header-section'>
            <div className='filter-section'>
           
          </div> */}

        <div className="w-100 px-3">
          <Tables
            data={rotationLogData.result || []}
            headerData={headerData}
            defaultActions={[]}
            onPageIndexChanged={onPageIndexChanged}
            onPageSizeChanged={onPageSizeChanged}
            actionsOptions={{
              onActionClicked: tableActionClicked,
            }}
            onFilterValuesChanged={onFilterValuesChanged}
            filterData={tableFilterData}
            isWithFilter
            FilterDisabledButton
            itemsPerPage={filter.pageSize}
            activePage={filter.pageIndex}
            translationPath={translationPath}
            parentTranslationPath={parentTranslationPath}
            totalItems={rotationLogData.totalCount}
            setSortBy={setSortBy}
          />
        </div>
      </div>
    </div>
  );
};
