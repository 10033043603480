import React, { useState  } from "react";
import { useTranslation } from "react-i18next";
import { AutocompleteComponent } from "../../../../Components";
import { GetLookupItemsByLookupTypeName } from "../../../../Services";

const parentTranslationPath = "UnitsView";
const translationPath = "";

export const UnitTypeFilter = ({
  setResaleFilters,
  resaleFilters,
  resaleChangeFilters,
  selected , 
  setSelected ,
}) => {
  const { t } = useTranslation(parentTranslationPath);
  const [allUnitTypes, setAllUnitTypes] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const getUnitTypeLookups = async () => {
    setIsLoading(true);
    const res = await GetLookupItemsByLookupTypeName({
      lookUpName: "UnitType",
      pageSize: 100,
      pageIndex: 1,
    });
    if (!(res && res.status && res.status !== 200)) {
      setAllUnitTypes(res.result || []);
    } else setAllUnitTypes([]);
    setIsLoading(false);
  };

  return (
    <div className="w-50 m-1">
      <AutocompleteComponent
        idRef="unitStatusRef"
        multiple={false}
        wrapperClasses="w-min-unset m-2 mr-1"
        inputPlaceholder={t(`${translationPath}select-unit-type`)}
        labelValue={t(`${translationPath}unit-types`)}
        selectedValues={selected.unitType}
        data={allUnitTypes || []}
        displayLabel={(option) => (option && option.lookupItemName) || ""}
        chipsLabel={(option) => option.lookupItemName || ""}
        withoutSearchButton
        onChange={(e, newValue) => {
          resaleChangeFilters("unit_type", newValue?.lookupItemName);
          setSelected({id :'unitType'  , value : newValue })
        }}
        onOpen={() => {
          if (allUnitTypes &&  allUnitTypes.length == 0)
            getUnitTypeLookups();
        }}
        isLoading={isLoading}
        withLoader
      />
    </div>
  );
};
