


import React, { useState ,  useEffect  , useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import {
  DialogActions, DialogContent, DialogTitle, Dialog
} from '@material-ui/core';
import { ButtonBase } from '@material-ui/core';
import {
  Spinner, Inputs
} from '../../../../../Components';
import { GetPortalAccountDetailsAPI   } from '../../../../../Services' ; 
import { CopyToClipboardComponents } from '../../../../../ReusableComponents/UtilityComponents/CopyToClipboardComponents/CopyToClipboardComponents';



export const PortalAccountDetailsDialog = ({
   parentTranslationPath ,
   translationPath  ,
   portalAccountId ,
   isOpen ,  
   onClose , 
  }) => {

  const { t } = useTranslation(parentTranslationPath);
  const [isLoading, setIsLoading] = useState(false) ; 

  const [apiDetails, setAPIDetails] = useState({
    apiUrl : null , 
    apiKey : null  , 
  });

  const GetPortalAccountDetails = useCallback(async () => {
    setIsLoading(true);
    const res = await GetPortalAccountDetailsAPI(portalAccountId);
    if (!(res && res.status && res.status !== 200))
    setAPIDetails(res);
    else setAPIDetails(null);
    setIsLoading(false);

  }, []); 

  useEffect(() => 
  {
    GetPortalAccountDetails() ; 
  
  }, [GetPortalAccountDetails]);



  return (
    <div>
      <Dialog
        open={isOpen}
        onClose={() => {
          onClose();
        }}
        maxWidth='sm'
        disableBackdropClick
      >
      <Spinner isActive={isLoading} isAbsolute />
          <DialogTitle id='alert-dialog-slide-title'>
            <span>
                {t(`${translationPath}API-details`)} 
            </span>
          </DialogTitle>
          <DialogContent>
     <div className='dialog-content-wrapper'>
        <div className='dialog-content-item w-100 px-2 mb-2 mt-1'>
         <div className=''> 
            <span className=''>
                {t(`${translationPath}apiUrl`)} 
            </span>
         </div>
        <CopyToClipboardComponents
            data={apiDetails && apiDetails.apiUrl}
            childrenData={apiDetails && apiDetails.apiUrl}

         />
         </div>
         <div className='dialog-content-item w-100 px-2 mb-2 mt-1'>
          <div className=''> 
            <span>
                {t(`${translationPath}apiKey`)} 
            </span>
          </div>
          <CopyToClipboardComponents
             data={apiDetails && apiDetails.apiKey}
             childrenData={apiDetails && apiDetails.apiKey}

         />
         </div>
        
          </div>
          </DialogContent>
          <DialogActions>
          <div className='form-builder-wrapper'>
          <div className='form-builder-footer-wrapper is-dialog w-100 MuiGrid-align-items-xs-center MuiGrid-justify-xs-space-between'>
           <div className='MuiDialogActions-root dialog-footer-wrapper  MuiDialogActions-spacing'>
            <div className='save-cancel-wrapper d-flex-v-center-h-end flex-wrap p-2'>
              <div className='cancel-wrapper d-inline-flex-center'>
              <ButtonBase
                className='MuiButtonBase-root MuiButton-root MuiButton-text cancel-btn-wrapper btns theme-transparent c-primary'
                tabIndex='0'
                id='cancel-ref-action'
                onClick={() => onClose()}
              >
                <span className='MuiButton-label'>
                  <span>{t(`${translationPath}cancel`)}</span>
                </span>
                <span className='MuiTouchRipple-root' />
              </ButtonBase>
            </div>
          </div>
        </div>
      </div>
    </div>
            
          </DialogActions>
      </Dialog>
    </div>
  );

};

PortalAccountDetailsDialog.propTypes = {
  parentTranslationPath: PropTypes.string.isRequired,
  translationPath: PropTypes.string.isRequired,
  portalAccountId  : PropTypes.number.isRequired,
  isOpen : PropTypes.bool.isRequired, 
  onClose :  PropTypes.func.isRequired, 
};


