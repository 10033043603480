import React, {
  useCallback,
  useEffect,
  useState
} from 'react';
import { ButtonBase, } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { AutocompleteComponent, Inputs } from '../../../../../../../Components';
import { ModuelPopOverComponent } from './ModuelPopOverComponent';
import Tooltip from '@material-ui/core/Tooltip';

export const FinancialDataComponent = ({
  parentTranslationPath,
  translationPath,
  state,
  isSubmitted,
  isLoading,
  currency,
  currencyValue,
  nameBank,
  bankNameValue,
  setCurrencyValue,
  setBankNameValue,
  financialDetailState,
  setFinancialDetailState,
  initialModuleState,
  isChecked,
  setIsChecked,
  selectedModuleName,
  setSelectedModuleName,
  isOpenAddModule,
  setIsOpenAddModule,
  isDisabled,
  setIsDisabled,
  dublicatModule,
  setDublicatModule,
}) => {
  const { t } = useTranslation(parentTranslationPath);
  const [selectedModule, setSelectedModule] = useState(null);
  const [isItemDeleted, setIsItemDeleted] = useState(false);

  const modulesEnum = [
    { key: 1, value: 'Leasing' },
    { key: 2, value: 'Sales' },
    { key: 3, value: 'PM' },
    { key: 4, value: 'Mortgage' },
  ];


  const onItemCardClick = useCallback((item, el) => {
    if (!isItemDeleted && el && item) {
      setSelectedModule(el);
      setIsDisabled(false);
      setFinancialDetailState({ id: 'accountName', value: el.accountName });
      setFinancialDetailState({ id: 'accountNumber', value: el.accountNumber });
      setFinancialDetailState({ id: 'ibanCode', value: el.ibanCode });
      setFinancialDetailState({ id: 'swiftCode', value: el.swiftCode });
      setFinancialDetailState({ id: 'businessUnit', value: item.businessUnit || 0 });
      setFinancialDetailState({ id: 'branchFinancialId', value: el.branchFinancialId });
      setFinancialDetailState({ id: 'bankId', value: el.bankId });
      setFinancialDetailState({ id: 'currencyId', value: el.currencyId });
      setBankNameValue({ lookupItemId: el.bankId, lookupItemName: el.bankName });
      setCurrencyValue({ lookupItemId: el.currencyId, lookupItemName: el.currencyName });
    } else {
      setSelectedModule(null);
      setFinancialDetailState({ id: 'edit', value: initialModuleState });
      setFinancialDetailState({ id: 'businessUnit', value: 0 });
      setFinancialDetailState({ id: 'edit', value: initialModuleState });
      setCurrencyValue(null);
      setBankNameValue(null);
      setSelectedModuleName(0);
      setIsItemDeleted(false);
    }

  }, [isItemDeleted])


  useEffect(() => {
    onItemCardClick();
  }, [onItemCardClick])
  return (
    <>
      <div className='Financial-dialog-content-wrapper'>
        <div className='modules-wrapper'>
          {modulesEnum.map((module) => (
            <div className='module-wrapper'>
              <span className='module-title'>
                {t(`${translationPath}${module.value}`)}
              </span>
              {state.branchFinancialBusinessUnits && state.branchFinancialBusinessUnits.map((item) =>
                item.businessUnit === module.key && item.financialDetails.map((el) => (
                  <div
                    onClick={() => {
                      setSelectedModuleName(module);
                      onItemCardClick(item, el)
                    }}
                    className={`module-details ${selectedModule === el ? 'selectedModule' : null}`}
                  >
                    <div className='col1'>
                      <div>
                        <span>{t(`${translationPath}Account-Name`)}</span>
                        <span>{el.accountName || 'N/A'}</span>
                      </div>
                      <div>
                        <span> {t(`${translationPath}Account-Number`)}</span>

                        <span>{el.accountNumber || 'N/A'}</span>
                      </div>
                    </div>
                    <div className='col2'>
                      <Tooltip
                        title={t(`${translationPath}delete`)}
                      >
                        <ButtonBase
                          className='btns theme-outline actions delete-btn'
                          onClick={() => {
                            setIsItemDeleted(true)
                            item.financialDetails = item.financialDetails.filter((data) => data.branchFinancialId !== el.branchFinancialId);
                          }}

                        ><span className={'mdi mdi-close c-warning'} />

                        </ButtonBase>
                      </Tooltip>
                      <ModuelPopOverComponent
                        t={t}
                        setDublicatModule={setDublicatModule}
                        translationPath={translationPath}
                      />

                    </div>

                  </div>
                )))}
              <ButtonBase
                className='btns outline mx-2 mb-2'
                onClick={() => {
                  setIsOpenAddModule(true);
                  setIsDisabled(false);
                  setSelectedModuleName(module);
                  setSelectedModule(null);
                  setFinancialDetailState({ id: 'edit', value: initialModuleState });
                  setFinancialDetailState({ id: 'businessUnit', value: module.key || 0 });
                  setCurrencyValue(null);
                  setBankNameValue(null);
                }}
              >
                <span className='mdi mdi-plus' />
                {t(`${translationPath}add-new-bank-account`)}
              </ButtonBase>
            </div>
          ))}

        </div>

        <div className='virtical-line' />

        <div className='detail-wrapper-dialog'>
          <div className='detail-header-wrapper'>
            <div className='detail-header-title'>
              <span>{isOpenAddModule ? t(`${translationPath}add-new-bank-account`) : t(`${translationPath}bank-account-details`)}</span>
            </div>
            <span className='selected-module-title'>{selectedModuleName && t(`${translationPath}${selectedModuleName.value}`) || 'N/A'}</span>
          </div>
          <div className='dialog-content-item'>
            <Inputs
              labelValue='account-name'
              idRef='accountNameRef'
              isSubmitted={isSubmitted}
              parentTranslationPath={parentTranslationPath}
              translationPath={translationPath}
              value={financialDetailState.accountName || ''}
              isDisabled={isDisabled}
              onInputChanged={(event) => {
                setFinancialDetailState({ id: 'accountName', value: event.target.value });
              }}
            />
          </div>
          <div className='dialog-content-item'>
            <Inputs
              labelValue='account-number'
              isSubmitted={isSubmitted}
              idRef='accountNoRef'
              parentTranslationPath={parentTranslationPath}
              translationPath={translationPath}
              value={financialDetailState.accountNumber || ''}
              isDisabled={isDisabled}
              max={500000000}
              type='number'
              onInputChanged={(event) => {
                let value = event.target.value;
                if (value > 500000000) value = 500000000;
                setFinancialDetailState({ id: 'accountNumber', value: parseInt(value) });
              }}
            />
          </div>
          <div className='dialog-content-item'>
            <Inputs
              labelValue='iban'
              isSubmitted={isSubmitted}
              idRef='ibanNoRef'
              parentTranslationPath={parentTranslationPath}
              translationPath={translationPath}
              value={financialDetailState.ibanCode || ''}
              isDisabled={isDisabled}
              onInputChanged={(event) => {
                setFinancialDetailState({ id: 'ibanCode', value: event.target.value });
              }}
            />
          </div>
          <div className='dialog-content-item'>
            <Inputs
              labelValue='swift-code'
              isSubmitted={isSubmitted}
              idRef='swift-codeRef'
              parentTranslationPath={parentTranslationPath}
              translationPath={translationPath}
              value={financialDetailState.swiftCode || ''}
              isDisabled={isDisabled}
              onInputChanged={(event) => {
                setFinancialDetailState({ id: 'swiftCode', value: event.target.value });
              }}

            />
          </div>
          <div className='dialog-content-item'>
            <AutocompleteComponent
              labelValue={t(`${translationPath}bank-name`)}
              data={nameBank || []}
              multiple={false}
              idRef='bank-nameRef'
              withoutSearchButton
              selectedValues={bankNameValue || null}
              value={bankNameValue || null}
              isDisabled={isDisabled}
              displayLabel={(option) => (option && option.lookupItemName) || ''}
              onChange={(event, value) => {
                setFinancialDetailState({ id: 'bankId', value: value && value.lookupItemId });
                setBankNameValue(value);
              }}
              isLoading={isLoading}
            />
          </div>
          <div className='dialog-content-item'>
            <AutocompleteComponent
              idRef='currencyRef'
              data={currency || []}
              labelValue={t(`${translationPath}currency`)}
              multiple={false}
              withoutSearchButton
              value={financialDetailState.currency || null}
              isDisabled={isDisabled}
              selectedValues={currencyValue || null}
              displayLabel={(option) => (option && option.lookupItemName) || ''}
              onChange={(event, value) => {
                setFinancialDetailState({ id: 'currencyId', value: value && value.lookupItemId });
                setCurrencyValue(value);
              }}

            />
          </div>
        </div>
      </div>
    </>
  );
};
