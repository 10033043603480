/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { PropTypes } from 'prop-types';
import moment from 'moment';
import Avatar from '@material-ui/core/Avatar';
import './RequestSideDetails.scss';
import { useTranslation } from 'react-i18next';
import { getDownloadableLink } from '../../../../../Helper';
import { Spinner } from '../../../../../Components';
import { GetCampaignRequestHistory } from '../../../../../Services';

function RequestSideDetails({ activeData }) {
  const parentTranslationPath = 'Campaign';
  const translationPath = '';
  const FirstLettersExp = /\b(\w)/gm;
  const { t } = useTranslation(parentTranslationPath);
  const [campaignHistory, setCampaignHistory] = useState([]);
  const [isLoading, setIsLoading] = useState({
    history: false,
  });

  const getCampaignRequestHistory = async () => {
    setIsLoading((prevState) => ({ ...prevState, history: true }));

    const res = await GetCampaignRequestHistory({
      id: activeData.id,
    });
    if (!(res && res.status && res.status !== 200)) {
      const mappedHistoryData = res?.history? await historyDataMapper(res.history) : [];

      setCampaignHistory(mappedHistoryData || []);
    } else {
      setCampaignHistory([]);
    }
    setIsLoading((prevState) => ({ ...prevState, history: false }));
  };


  const historyDataMapper = async (historyData) => {
    const mappedHistoryData = Promise.all(historyData.map( async (item) => ({
      ...item,
      historyDate: item.createdOn ? `${moment(item.createdOn).fromNow(true)} ago` : '',
      profileImgLink: item.profileImg ? getDownloadableLink(item.profileImg) : null,
    }))).then(result=> result);

    return mappedHistoryData;
  };

  useEffect(() => {
    getCampaignRequestHistory();
  }, [activeData.id]);

  return (
    <div className='req-history-details'>
      <h5 className='p-3'>{t(`${translationPath}Campaign-Request-Details`)}</h5>
      <div className='req-history-card-container'>
        <Spinner isActive={isLoading.history} isAbsolute />
        {campaignHistory &&
          campaignHistory.map((item) => (
            <div className='req-history-card'>
              {!item.profileImgLink && item.creator ? (
                <Avatar className='avatars-wrapper theme-small'>
                  {item.creator.match(FirstLettersExp).join('')}
                </Avatar>
              ) : (
                <img className='req-avatar' src={item.profileImgLink} />
              )}
              <div className='req-history-content'>
                <div>
                  <p className='top-label'>
                    <span>{item.creator}</span>
                    <span>{item.historyDate}</span>
                  </p>
                  <p className='bottom-label'>{item.messageText}</p>
                </div>
                {item.statusNote && <div className='status-note p-2 mb-3'>
                  <p className='m-0' dangerouslySetInnerHTML={{ __html: item.statusNote }}></p>
                </div>}
                <div className='card-capsule'>{item.status}</div>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
}

RequestSideDetails.propTypes = {
  activeData: PropTypes.instanceOf(Object),
};
RequestSideDetails.defaultProps = {
  activeData: null,
};

export { RequestSideDetails };
