import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => {
  return {
    header: {
      top: 0,
      width: '100%',
      zIndex: 1000,
      backgroundColor: theme.palette.background.primary,
      borderBottom: `1px solid ${theme.palette.border.secondary}`,
      padding: '16px 32px',
      boxShadow: 'none',
      boxSizing: 'border-box',
      height: '72px',
      [theme.breakpoints.down('md')]: {
        padding: '12px 16px',
        height: '64px',
      },
    },
    toolbar: {
      padding: '0px',
      minHeight: '0px',
    },
    menuIcon: {
      color: theme.palette.text.tertiary,
      fontSize: '20px',
    },
    menuButton: {
      padding: "8px 10px",
      borderRadius: "6px",
      '&:hover': {
        background: theme.palette.background.primary_hover,
      },
      marginRight: "4px",
      marginLeft: "4px",
    }
  };
});
