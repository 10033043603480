import React, { useState, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Tables, Spinner } from "../../../../../../Components";
import { showError, showSuccess , returnPropsByPermissions } from "../../../../../../Helper";
import { 
  GetAuditAndComplianceByIdAPI,
  DeleteAuditAndComplianceAPI,
} from "../../../../../../Services";
import { TableActions } from "../../../../../../Enums";
import { MessageConfirmDialog } from "../../../../../../SharedComponents";
import { AuditAndComplianceManagementDialog } from "./AuditAndComplianceManagementDialog";
import { DevelopersPermissions } from '../../../../../../Permissions' ; 

export const AuditAndComplianceTable = ({
  translationPath,
  parentTranslationPath,
  data,
  filter,
  setFilter,
  reloadData,
  setActiveTab 
}) => {
  const { t } = useTranslation(parentTranslationPath, "Shared");
  const [isLoading, setIsLoading] = useState({ info: false });
  const [activeItem, setActiveItem] = useState(null);
  const [isOpenEditDialog, setIsOpenEditDialog] = useState(false);
  const [isMissingInfoMSGOpen, setIsMissingInfoMSGOpen] = useState(false);

  const GetAuditAndComplianceHandler = useCallback(async () => {
    setIsLoading((item) => ({ ...item, info: true }));
    const res = await GetAuditAndComplianceByIdAPI(
      activeItem && activeItem.auditAndComplianceId
    );
    if (res && res.ErrorId) {
      showError(t(`${translationPath}failed-to-get-audit-and-compliance`));
    } else if (!(res && res.status && res.status !== 200)) {
    }
    setIsLoading((item) => ({ ...item, info: false }));
  }, [activeItem]);

  const DeleteHandler = useCallback(async () => {
    if (activeItem && activeItem.auditAndComplianceId) {
      setIsLoading((item) => ({ ...item, delete: true }));

      const res = await DeleteAuditAndComplianceAPI(
        activeItem && activeItem.auditAndComplianceId
      );
      if (res && res.ErrorId) {
        showError(t(`${translationPath}failed-to-delete-audit-and-compliance`));
      } else if (!(res && res.status && res.status !== 200)) {
        showSuccess(
          t(`${translationPath}delete-audit-and-compliance-successfully`)
        );
        setIsMissingInfoMSGOpen(false);
        setActiveItem(null);
        reloadData();
      }
      setIsLoading((item) => ({ ...item, delete: false }));
    }
  }, [activeItem]);

  const tableActionClicked = useCallback(
    (actionEnum, item, focusedRow, event) => {
      event.stopPropagation();
      event.preventDefault();
      setActiveItem(item);

      if (actionEnum === TableActions.delete.key) {
        setIsMissingInfoMSGOpen(true);
      } else if (actionEnum === TableActions.editText.key) {
        setIsOpenEditDialog(true);
      }
    },
    []
  );

  const onPageIndexChanged = (pageIndex) => {
    setFilter((item) => ({ ...item, pageIndex }));
  };
  const onPageSizeChanged = (pageSize) => {
    setFilter((item) => ({ ...item, pageIndex: 0, pageSize }));
  };

  const getActionTableWithPermissions = () => {
    const list = [];
    if(returnPropsByPermissions(DevelopersPermissions.EditAuditComplianceDetails.permissionsId))
    list.push({
      enum: TableActions.editText.key,
      title: t(`${translationPath}Shared:edit`),
    });
    list.push({
      enum: TableActions.delete.key,
      title: t(`${translationPath}Shared:delete`),
    });
    return list;
  };

  useEffect(() => {
    if (activeItem) {
      GetAuditAndComplianceHandler(activeItem.auditAndComplianceId);
    }
  }, [activeItem]);

  return (
    <div className="d-flex-column mt-2">
      <Spinner isActive={isLoading.info || isLoading.delete} isAbsolute />
      <Tables
        data={data?.result || []}
        headerData={[
          {
            id: 1,
            label: t(`${translationPath}audit-process-complete`),
            input: "audit-process-complete",
            component: (item, index) =>
              (item.isAuditProcessComplete && (
                <div className="globel-open-style" index={index}>
                  {t(`${translationPath}yes`)}
                </div>
              )) || (
                <div className="globel-closed-style" index={index}>
                  {t(`${translationPath}no`)}
                </div>
              ),
          },
          {
            id: 3,
            label: t(`${translationPath}audit-date`),
            input: "auditDate",
            isDate: true,
          },
          {
            id: 10,
            label: t(`${translationPath}audit-remarks`),
            input: "auditRemarks",
          },
        ]}
        onPageIndexChanged={onPageIndexChanged}
        onPageSizeChanged={onPageSizeChanged}
        defaultActions={getActionTableWithPermissions()}
        actionsOptions={{
          onActionClicked: tableActionClicked,
        }}
        activePage={filter.pageIndex}
        parentTranslationPath={parentTranslationPath}
        translationPath={translationPath}
        itemsPerPage={filter.pageSize}
        totalItems={(data && data?.totalCount) || 0}
      />
      {isMissingInfoMSGOpen && activeItem && (
        <MessageConfirmDialog
          isDialogOpen={isMissingInfoMSGOpen}
          theme="warning"
          content={{
            heading: "delete-audit-and-compliance",
            body:
              t(
                `${translationPath}are-you-sure-to-delete-audit-and-compliance-id`
              ) +
              " " +
              ((activeItem && activeItem.auditAndComplianceId) || ""),
          }}
          confirmButton={{
            label: t(`${translationPath}confirm`),
            handler: () => DeleteHandler(),
          }}
          cancelButton={{
            label: t(`${translationPath}discard`),
            handler: () => {
              setIsMissingInfoMSGOpen(false);
            },
          }}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
        />
      )}
      {isOpenEditDialog && activeItem && (
        <AuditAndComplianceManagementDialog
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          isOpen={isOpenEditDialog}
          onClose={() => {
            setIsOpenEditDialog(false);
            setActiveItem(null);
          }}
          onSave={(editItem) => {
            if (editItem) {
              setIsOpenEditDialog(false);
              setActiveItem(null);
              reloadData();
            }
          }}
          auditAndComplianceValues={activeItem}
        />
      )}
    </div>
  );
};
