export const LeadActionsButtonsEnum = {
  add: {
    id: 'add',
    classes: 'bg-secondary',
    label: 'actions',
    buttonLabel: 'add',
  },
  'close-leads': {
    id: 'close-leads',
    classes: 'bg-secondary',
    label: 'close-leads',
    buttonLabel: 'close-leads',
  },
  'unqualified-leads': {
    id: 'unqualified-leads',
    classes: 'bg-secondary',
    label: 'unqualified-leads',
    buttonLabel: 'unqualified-leads',
  },
  'reassign-seeker-leads': {
    id: 'reassign-seeker-leads',
    classes: 'bg-secondary',
    label: 'reassign-seeker-leads',
    buttonLabel: 'reassign-seeker-leads',
  },
  'clone-leads': {
    id: 'clone-leads',
    classes: 'bg-secondary',
    label: 'clone-leads',
    buttonLabel: 'clone-leads',
  },
  merge: {
    id: 'merge',
    classes: 'bg-secondary',
    label: 'merge',
    buttonLabel: 'merge',
  },
  import: {
    id: 'import',
    classes: 'bg-secondary',
    label: 'import',
    buttonLabel: 'import',
  },
  'bulk-update': {
    id: 'bulk-update',
    classes: 'bg-secondary',
    label: 'bulk-update',
    buttonLabel: 'bulk-update',
  },
  'send-to-rotation': {
    id: 'send-to-rotation',
    classes: 'bg-secondary',
    label: 'send-to-seeker-rotation',
    buttonLabel: 'send-to-seeker-rotation',
  },
  'qualify-lead': {
    id: 'qualify-lead',
    classes: 'bg-secondary',
    label: 'qualify-lead',
    buttonLabel: 'qualify-lead',
  },
  'unqualified-lead': {
    id: 'unqualified-lead',
    classes: 'bg-secondary',
    label: 'unqualified-lead',
    buttonLabel: 'unqualified-lead',
  },
  'reassign-owner-leads': {
    id: 'reassign-owner-leads',
    classes: 'bg-secondary',
    label: 'reassign-owner-leads',
    buttonLabel: 'reassign-owner-leads',
  },
  'make-transaction': {
    id: 'make-transaction',
    classes: 'bg-secondary',
    label: 'make-transaction',
    buttonLabel: 'make-transaction',
  },
  'send-Proposal': {
    id: 'send-Proposal',
    classes: 'bg-secondary',
    label: 'send-Proposal',
    buttonLabel: 'send-Proposal',
  },
  'mark-as-available': {
    id: 'mark-as-available',
    classes: 'bg-secondary',
    label: 'mark-as-available',
    buttonLabel: 'mark-as-available',
  },
  'move-to-draft': {
    id: 'move-to-draft',
    classes: 'bg-secondary',
    label: 'move-to-draft',
    buttonLabel: 'move-to-draft',
  },
  'publish-to-website': {
    id: 'publish-to-website',
    classes: 'bg-secondary',
    label: 'publish-to-website',
    buttonLabel: 'publish-to-website',
  },
  unpublish: {
    id: 'unpublish',
    classes: 'bg-secondary',
    label: 'unpublish',
    buttonLabel: 'unpublish',
  },
  'send-to-leads-pool': {
    id: 'send-to-leads-pool',
    classes: 'bg-secondary',
    label: 'send-to-leads-pool',
    buttonLabel: 'send-to-leads-pool',
  },
  delete: {
    id: 'delete',
    classes: 'bg-secondary',
    label: 'delete',
    buttonLabel: 'delete',
  },
  'send-to-owner-rotation': {
    id: 'send-to-owner-rotation',
    classes: 'bg-secondary',
    label: 'send-to-owner-rotation',
    buttonLabel: 'send-to-owner-rotation',
  },
  assign: {
    id: 'assign',
    classes: 'bg-secondary',
    label: 'assign',
    buttonLabel: 'assign',
  },
  'assign-to-me': {
    id: 'assign-to-me',
    classes: 'bg-secondary',
    label: 'assign-to-me',
    buttonLabel: 'assign-to-me',
  },
  'share-unit': {
    id: 'share-unit',
    classes: 'bg-secondary',
    label: 'share-unit',
    buttonLabel: 'share-unit',
  },
  'add-to-favourite': {
    id: 'add-to-favourite',
    classes: 'bg-secondary',
    label: 'add-to-favourite',
    buttonLabel: 'add-to-favourite',
  },
  'archived': {
    id: 'archived',
    classes: 'bg-secondary',
    label: 'archived',
    buttonLabel: 'archived',
  },
  'un-archived': {
    id: 'un-archived',
    classes: 'bg-secondary',
    label: 'un-archived',
    buttonLabel: 'un-archived',
  },
  'pull-to-contact-opportunity':{
    id: 'pull-to-contact-opportunity',
    classes: 'bg-secondary',
    label: 'pull-to-contact-opportunity',
    buttonLabel: 'pull-to-contact-opportunity',
  },
  'dncr': {
    id: 'dncr',
    classes: 'bg-secondary',
    label: 'dncr',
    buttonLabel: 'dncr',
  },
  'consent-management': {
    id: 'consent-management',
    classes: 'bg-secondary',
    label: 'consent-management',
    buttonLabel: 'consent-management',
  },
  'send-to-leads-pool-assets': {
    id: 'send-to-leads-pool-assets',
    classes: 'bg-secondary',
    label: 'send-to-leads-pool-assets',
    buttonLabel: 'send-to-leads-pool-assets',
  },
};
