export const TemplateCategoryEnum = {
    Unit: {
      key: 1,
      value: 'Unit',
    },
    Activity: {
      key: 2,
      value: 'Activity',
    }
  };
