import {
  AgreementDetails,
  AuditAndCompliance,
  DeveloperContactDocumentsComponent,
  CommissionDetailsByAgreementView,
} from "../AgreementsAndCompliance";
import { DevelopersPermissions } from '../../../../../Permissions'
export const AgreementsAndComplianceVerticalTabsData = [
  {
    label: "agreement-details",
    component: AgreementDetails,
    permissionsList: Object.values(DevelopersPermissions),
    permissionsId: DevelopersPermissions.ViewAgreementDetails.permissionsId,
  },
  {
    label: "audit-and-compliance",
    component: AuditAndCompliance,
    permissionsList: Object.values(DevelopersPermissions),
    permissionsId:
      DevelopersPermissions.ViewAuditAndComplianceDetails.permissionsId,
  },
  // {
  //   label: "commission-details",
  //   component: CommissionDetailsByAgreementView,
  // },

  {
    label: "documents",
    component: DeveloperContactDocumentsComponent,
    permissionsList: Object.values(DevelopersPermissions),
    permissionsId: DevelopersPermissions.Documents.permissionsId,
  },
];
 