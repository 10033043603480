
import React, { useState , useCallback  , useEffect  , useReducer }  from 'react';
import { useTranslation } from 'react-i18next';
import Button from "@material-ui/core/Button";
import { PageHeading } from "../../../../../SharedComponents";
import { Spinner } from '../../../../../Components' ; 
import { PaginationComponent } from '../../../../../Components/PaginationComponent/PaginationComponent';
import { showError  , showSuccess  , bottomBoxComponentUpdate  } from '../../../../../Helper' ; 
import { GetAllPropertyRatingDeveloperScoresAPI  , UpdateDeveloperScoreAPI  } from '../../../../../Services' ; 
import { DeveloperContent } from './DeveloperContent' ; 
import { ClassificationsContactEnum } from '../../../../../Enums' ; 

  export const DeveloperView = ({parentTranslationPath , translationPath  , ratingPercentage} ) => { 
    const { t } = useTranslation(parentTranslationPath);
    const [contactClassificationFilter,setContactClassificationFilter]  =  useState(null);
    const [search , setSearch] = useState('') ;
 
    const[isLoading ,setIsLoading] = useState(false);
    const [filter , setFilter] = useState({
        pageIndex: 0,
        pageSize: 25,
        search :'' , 
        contactClassificationId :(ClassificationsContactEnum.find((f)=> f.name === 'Real Estate - Developer').Id) ,
        isSecondaryDeveloper : false 
      }); 

    const[developers,setDevelopers] = useState({
      allDevelopers : [] , 
      realEstateDevelopers : [] , 
      secondaryDevelopers : [] , 
      allDevelopers : [] , 
    });

    const [developerRatingPercentage ,setDeveloperRatingPercentage]  = useState(0) ;
    const reducer = (state, action) => {
        if (action.id !== 'edit') return { ...state, [action.id]: action.value };
        if (action.id === 'edit') {
          return {
            ...action.value,
          };
        }
        return undefined;
      };

      const [state, setState] = useReducer(reducer, {
        listOfDeveloperScore :[] , 
        totalCount : 0  ,
        secondaryDeveloperList : [] ,
        realEstateDevelopers : [] ,  
      });

    const GetAllPropertyRatingDeveloperScores = useCallback(async (search) => 
    {
        setIsLoading(true);
        const res = await GetAllPropertyRatingDeveloperScoresAPI({...filter , pageIndex : filter.pageIndex + 1 ,  
          contactClassificationId :(ClassificationsContactEnum.find((f)=> f.name === 'Real Estate - Developer').Id) , });
        if (!((res && res.data && res.data.ErrorId) || !res)) 
          {
            const allDevelopers = res &&  res.result ? [...(res.result).filter((s)=> !s.isSecondaryDeveloper).map((i)=> ({
                      ...i , 
                      propertyRatingDeveloperScoreId : null , 
                      isSecondaryDeveloper : true ,
                      score : 0 
                    }))] : [];

                    const allDevelopers2 = res &&  res.result ? [...(res.result).filter((s)=> s.isSecondaryDeveloper).map((i)=> ({
                      ...i , 
                      propertyRatingDeveloperScoreId : null , 
                      isSecondaryDeveloper : false ,
                      score : 0 
                    }))] : [];

            const   secondaryDeveloperList = res &&  res.result ? [...res.result.filter((s)=> s.isSecondaryDeveloper) ,...allDevelopers ] : [] ;
            const   realEstateDevelopers =  res &&  res.result ? [...res.result.filter((s)=> !s.isSecondaryDeveloper) , ...allDevelopers2] : [];
 

            let filterArray = secondaryDeveloperList.filter(
              (value, index, self) =>
                index === self.findIndex((t) => t.contactId === value.contactId)
            );
            let filterArray2 = realEstateDevelopers.filter(
              (value, index, self) =>
                index === self.findIndex((t) => t.contactId === value.contactId)
            );

            setState({ id: 'edit', value:({ 
                 listOfDeveloperScore : (res?.result)  || [] , 
                 secondaryDeveloperList :[...filterArray]  , 
                 realEstateDevelopers : [...filterArray2] ,
                 totalCount : (res?.totalCount || 0)
               }
               ) });
          }
         else setState({ id: 'edit', value: { listOfDeveloperScore :[] , secondaryDeveloperList : []  , realEstateDevelopers : []  ,  totalCount : 0 }});
       
         setIsLoading(false);
    },[filter]);

    const onPageIndexChanged = (pageIndex) => {
      setFilter((item) => ({ ...item, pageIndex }));
    };

    const onPageSizeChanged = (pageSize) => {
      setFilter((item) => ({ ...item, pageIndex: 0, pageSize }));
    };



    const reloadData = () => 
    {
        GetAllPropertyRatingDeveloperScores(); 
    };

    const saveHandler = async () => {
      setIsLoading(true);

      const res = await UpdateDeveloperScoreAPI({listOfDeveloperScore : [...state.realEstateDevelopers , ...state.secondaryDeveloperList]}); 
      if (!((res && res.status && res.status !== 200) || !res)) {
        showSuccess(t(`${translationPath}developer-scores-updated-successfully`));
          reloadData();
      }
      else  showError(t(`${translationPath}developer-scores-updated-failed`));
       
      setIsLoading(false) ;
  };

    useEffect(() => {
      reloadData();
      if(ratingPercentage)
        {
          const rating = ratingPercentage.find((f) => f.propertyRatingTabName === "Developer");
          setDeveloperRatingPercentage((rating && rating.score) || 0 ) ; 
        }
    }, [filter.pageIndex , filter.pageIndex , filter.search , ratingPercentage ]);



    useEffect(() => {
      bottomBoxComponentUpdate(
        <PaginationComponent
          pageIndex={filter.pageIndex}
          pageSize={filter.pageSize}
          totalCount={(state && state.totalCount) || 0 }
          onPageIndexChanged={onPageIndexChanged}
          onPageSizeChanged={onPageSizeChanged}
        />
      );
    });

    return (
      <div className="w-100 mt-4">
        <Spinner isActive={isLoading} isAbsolute />
        <div className="d-flex-v-center-h-between">
          <PageHeading
            parentTranslationPath={parentTranslationPath}
            translationPath={translationPath}
            headerTitle={"developer"}
            subTitle={"manage-your-team-members-and-their-account-permissions-here"}
            wrapperClasses={"px-4 pt-2 w-75"}
            headerTitleClasses={"fz-30"}
            subTitleClasses={"fz-12 m-0"}
            titleWithPercentage={`${developerRatingPercentage}%`}
          />
          
          <div className="w-25 px-4 d-flex-center fj-end">
          <Button
              className="btns theme-propx outlined"
              id="btn-cancel"
              onClick={() => {
                reloadData()
              }}
            >
              {t(`${translationPath}cancel`)}
            </Button>
            <Button
              className="btns theme-propx solid"
              id="btn-save"
              onClick={() => saveHandler()}
            >
              {t(`${translationPath}save-changes`)}
            </Button>
           
          </div>
         </div>
        <div className="property-rating-container">
          <div className='developer-view'>
            <DeveloperContent
              parentTranslationPath={parentTranslationPath}
              translationPath={translationPath}
              state={state}  
              setState={setState}
              setContactClassificationFilter={setContactClassificationFilter}
              contactClassificationFilter={contactClassificationFilter}
              setFilter={setFilter}
              setSearch={setSearch}
              search={search}
              filter={filter}
              developers={developers}
              setDevelopers={setDevelopers}
             />
          </div>   
        </div>
      </div>
    );
  } ; 
  