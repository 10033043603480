import React from 'react';
import { SelectComponet } from '../../../../Components' ; 
import { useTranslation } from 'react-i18next';


export const ActivityTypeFilter = ({
    translationPath,
    parentTranslationPath,
    filter,
    setFilter , 
    selected , 
    setSelected , 
    
}) => {

    const { t } = useTranslation(parentTranslationPath);
    return (
      <>
      
        <div className='d-inline-flex'>
          <div className='item-filter' >
             <SelectComponet 
               idRef='ActivityTypeRef'
               data={[
                   {key : 1666  , value :  'WhatsApp Communication' } ,
                   {key : 1667 ,  value : 'Email Communication' } ,
                   {key : 1668 , value : 'SMS Communication'}
                 ]}
                valueInput='key'
                keyLoopBy='key'
                translationPathForData={translationPath}
                textInput='value'
                inputPlaceholder={t('ActivityType')}
                value={(filter && filter.activityTypeId)}
                parentTranslationPath={parentTranslationPath}
                translationPath={translationPath}
                onSelectChanged={(newValue) => 
                {
                  setFilter((item) => ({ ...item, activityTypeId  : newValue || null }));
                 
                }}
                emptyItem={{
                  value: null,
                  text: 'select-activity-Type',
                  isDisabled: false,
                }}
             
              />   
            </div>
            </div>
         </>
    );
};
