import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => {
  return {
    container_Contact_Activity:{
      display:'flex',
      flexDirection:'column',
      gap:'12px'
    },
    container_timeLine_Crad:{
      width:'100%'
    },container_time:{
      display:'flex',
      flexDirection:'column',
      width:'5%'
    }
  };
});
