import React from "react";
import { NavLink } from "react-router-dom";
import { ListItemText, Box, Collapse, List, ListItem, ListItemIcon, Icon } from "@material-ui/core";
import clsx from "clsx";
import { PermissionsComponent } from "../../..";
import { useIsActiveRoute, useIsAr, useIsDesktop, useTranslate } from "../../../../Hooks";
import FormattedMessage from "../../../../SharedComponents/FormattedComponent/FormattedMessage";
import { makeStyles } from "@material-ui/core/styles";
import { useVerticalNav } from "../../../../Contexts/VerticalNavContext";

const PARENT_TRANSLATION_PATH = 'Shared';

const useStyles = makeStyles((theme) => {
  const { isAr } = useIsAr(theme.direction);

  return {
    navIcon: {
      fontSize: '24px',
      color: '#94969C',
    },
    activeNavIcon: {
      color: '#FFF !important',
    },
    colNavIconWrapper: {
      padding: '12px',
      borderRadius: '6px',
      marginBottom: '8px',
      maxHeight: '48px',
    },
    ActiveListItemWrapper: {
      backgroundColor: '#7F6944 !important',
      '& $dropdownIcon': { // Using the $ syntax to reference another class name
        color: '#FFF !important',
      },
      color: '#F5F5F6 !important',
      '&:hover': {
        backgroundColor: '#7F6944 !important',
      }
    },
    nestedListItem: {
      padding: "0px",
      width: "100%",
      marginBottom: "8px",
      borderRadius: "6px",
    },
    navLink: {
      width: "100%",
      padding: "8px 12px",
      paddingInlineStart: "48px",
      ...(isAr ? { textAlign: "start" } : {}),
      borderRadius: "6px",
      fontSize: "16px",
      color: "#CECFD2 !important",
      '&:focus': {
        color: "#ECECED !important",
      },
      '&:hover': {
        color: "#ECECED !important",
        background: "#1F242F !important",
      }
    },
    activeNavLink: {
      color: "#ECECED !important",
      background: "#1F242F !important",
      '&:hover': {
        color: "#ECECED !important",
        background: "#1F242F !important",
      }
    },
    expandedListItemParent: {
      padding: 0,
      display: "flex",
      alignItems: "center",
      gap: "12px",
      width: "100%",
      padding: "12px",
      justifyContent: "space-between",
      color: "#CECFD2",
      '&:hover': {
        backgroundColor: '#7F6944 !important',
        color: '#F5F5F6',
        '& $navIcon': { // Using the $ syntax to reference another class name
          color: '#FFF !important',
        },
        '& $dropdownIcon': { // Using the $ syntax to reference another class name
          color: '#FFF',
        },
      }
    },
    listItemIcon: {
      minWidth: "fit-content"
    },
    dropdownIcon: {
      color: theme.palette.foreground.quarterary,
      fontSize: '20px',
    },
    customCollapse: {
      minHeight: "auto !important",
    }
  };
});

function ExpandedSideNav({ item, index, handleMenuToggle, open, defaultStyles }) {

  const { translate } = useTranslate(PARENT_TRANSLATION_PATH);

  const { onToggleMobileSideNav } = useVerticalNav();

  // the updated styles in case of mobile side nav
  let updatedStyles = useStyles();
  
  let styles = defaultStyles ?? updatedStyles;

  const { isActiveRoute } = useIsActiveRoute();

  const { isDesktop } = useIsDesktop();

  const CollapseIcon = open === item.groupId ? "icon-up-arrow" : "icon-down-arrow";

  return (
    <React.Fragment key={index}>
      <PermissionsComponent permissionsList={item.roles} allowEmptyRoles>
        <ListItem
          button
          onClick={() => handleMenuToggle(item.groupId)}
          className={clsx(
            styles.colNavIconWrapper,
            styles.expandedListItemParent,
            isActiveRoute(item) && styles.ActiveListItemWrapper
          )}
        >
          <Box
            component="div"
            display="flex"
            alignItems="center"
            style={{ gap: "12px" }}
          >
            <ListItemIcon className={styles.listItemIcon}>
              <span
                className={clsx(
                  item.newIcon,
                  styles.navIcon,
                  isActiveRoute(item) && styles.activeNavIcon
                )}
              ></span>
            </ListItemIcon>
            <ListItemText primary={translate(item.name)} />
          </Box>
          <Icon className={clsx(CollapseIcon, styles.dropdownIcon)} />
        </ListItem>
      </PermissionsComponent>
      <Collapse className={styles.customCollapse} in={open === item.groupId} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {item.children
            .filter((nestedRoute) => !nestedRoute?.hasNewLayout)
            .sort((a, b) => a.order - b.order)
            .map((child, index) => {
              return (
                <PermissionsComponent
                  permissionsList={child.roles}
                  allowEmptyRoles
                >
                  <ListItem
                    button
                    key={`submenu${index + 1}`}
                    className={styles.nestedListItem}
                  >
                    <NavLink
                      to={child.layout ? child.layout + child.path : child.path}
                      exact={child.isExact}
                      activeClassName={styles.activeNavLink}
                      className={styles.navLink}
                      disabled={child.isDisabled}
                      onClick={() => {
                        if(!isDesktop) {
                          onToggleMobileSideNav();
                        }
                      }}
                    >
                      <FormattedMessage id={child.name} path={PARENT_TRANSLATION_PATH} />
                    </NavLink>
                  </ListItem>
                </PermissionsComponent>
              );
            })}
        </List>
      </Collapse>
    </React.Fragment>
  );
}

export default ExpandedSideNav;
