import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { ButtonBase } from '@material-ui/core';
import { useLocation } from "react-router-dom";
import {
  ConvertJsonV2Component,
  PermissionsComponent,
  Spinner,
  TabsComponent,
} from '../../../../../../Components';
import {
  contactsDetailsPut,
  contactsDetailsGet,
  GetAllFormFieldTabsByFormId,
  GetRatingDeveloperScoreAPI 
} from '../../../../../../Services';
import {
  bottomBoxComponentUpdate,
  FormErrorsHandler,
  formItemsBuilder,
  GetParams,
  GlobalHistory,
  showError,
  showSuccess,
  returnPropsByPermissions,
} from '../../../../../../Helper';

import { LookupsRules } from '../../../../../../Rule';
import { ContactsPermissions } from '../../../../../../Permissions';

export const ContactsInformationComponent = ({
  viewType,
  parentTranslationPath,
  translationPath,
  isActiveToSave,
  onSave,
  save,
  isDetailsDialog,
  isOpen,
  isOpenChanged,
  isOpenInsideForm,
  contactWithPolicy,
  sensitiveFieldsForUser,
  isSensitiveLoading,
  isSensitiveLoading2,
  isLoading,
  reloadData,
  contactEmptyFields,

}) => {



  const { t } = useTranslation(parentTranslationPath, 'Shared');
  const location = useLocation();
  const [activeItem, setActiveItem] = useState({
    id: null,
    userTypeId: null,
  });

  const [isLoadingForm, setIsLoadingForm] = useState(false);
  const [contactScore, setContactScore] = useState(0);
  const [contactFieldsGetDataFromAnother ,setContactFieldsGetDataFromAnother] = useState([
    {key : 'contact_rate'}
   ]);
   const [isSubmitted, setIsSubmitted] = useState(false);
  // const [flag, setFlag] = useState(true);
  // const [parentContactId, setParentContactId] = useState(null);
  const [loadings, setLoadings] = useState([]);
  const [formData, setFormData] = useState([]);
  const [activeTab, setActiveTab] = useState(0);

  const pathName = window.location.pathname
    .split('/home/')[1]
    .split('/view')[0]
    .split('/')[0];

  const [isRoutFromUnit, setIsRoutFromUnit] = useState(pathName.includes('units'));

  const [enumsInitFinished, setEnumsInitFinished] = useState(false);
  const [errors, setErrors] = useState([]);
  const [contactInitDetails, setContactInitDetails] = useState({});
  const [contactDetails, setContactDetails] = useState({});
  const [formAndTabs, setFormAndTabs] = useState([]);



  const onTabChanged = (e, newTap) => {
    setEnumsInitFinished(false);
    setActiveTab(newTap);
  };

  
  const GetRatingDeveloperScore = useCallback(async (contactId) => {
    setIsLoadingForm(true);
   
      const result = await GetRatingDeveloperScoreAPI(contactId);
      if (!(result && result.status && result.status !== 200)) 
         setContactScore(result || 0) ; 
        else 
        setContactScore(0) ; 
     
    setIsLoadingForm(false);
  }, []);


  const getAllFormFieldTabsByFormId = useCallback(async () => {
    setIsLoadingForm(true);

    const result = await GetAllFormFieldTabsByFormId({ formId: activeItem.userTypeId });
    if (!(result && result.status && result.status !== 200)) {
      setFormAndTabs((result[0].filter(x => x.tab !== 'Source File')) || []);

    } else setFormAndTabs([]);
    setIsLoadingForm(false);
  }, [activeItem]);

  const selectValueFromAnotherAPI = (key) => 
  {
    switch (key) {
      case 'contact_rate':
        return (contactScore.toString());
  
      default:
       return null ;
    }
  
  };

  const getContactDetails = useCallback(async () => {
    setIsLoadingForm(true);
    // const contactDetailsRes = await contactsDetailsGet({ id: activeItem.id });
    // if (!(contactDetailsRes && contactDetailsRes.status && contactDetailsRes.status !== 200)) {
    //   setContactInitDetails(JSON.parse(JSON.stringify(contactDetailsRes)));
    //   setContactDetails(contactDetailsRes);
    // } else {
    //   setContactInitDetails({});
    //   setContactDetails({});
    // }
    setIsLoadingForm(false);
  }, [activeItem]);

  const dataHandler = useCallback(() => {
    if (formData.length === 0 && isSensitiveLoading)
      setFormData(formItemsBuilder(contactDetails.contact, formAndTabs, sensitiveFieldsForUser));
  }, [contactDetails.contact, formAndTabs, formData.length, sensitiveFieldsForUser, isSensitiveLoading]);
  const cancelHandler = () => {
    const parentContact = localStorage.getItem('parentContact')
    if (GetParams('isAssociated') && parentContact) {
      const parsParentContact = JSON.parse(parentContact);
      parsParentContact.id && parsParentContact.userTypeId && setActiveItem({
        id: JSON.stringify(parsParentContact.id),
        userTypeId: JSON.stringify(parsParentContact.userTypeId),
      });
    }
    GlobalHistory.goBack();
  };

  const saveHandler = async () => {
    setIsSubmitted(true);
    if (onSave)
      onSave(false);
    if (errors && errors.length > 0) {
      setEnumsInitFinished(false) ; 
      const firstErrorTapIndex = formData.findIndex(
        (item) => item.findIndex((element) => element.field.id === errors[0].key) !== -1
      );
      if (firstErrorTapIndex !== -1) setActiveTab(firstErrorTapIndex);
      showError(errors[0].message);
      return;
    }

    let totalItems = formData.reduce((total, items) => total + items.length, 0);
    let totalValues = contactDetails && contactDetails.contact && Object.values(contactDetails.contact).filter(
      (item) => (item !== null && !(Object.keys(item).length === 0 && item.constructor === Object) && item !== '' && item !== ' ' && item !== undefined)
    ).length;

    const totalValues2 = totalValues - 2;

    const total = (totalValues2 / totalItems) * 100;
    const dataCompleted = Math.round(total);
    contactDetails.contact.data_completed = dataCompleted > 100 ? 100 : dataCompleted;

    setIsLoadingForm(true);
    const putResponse = await contactsDetailsPut({
      id: activeItem.id,
      body: { contactJson: contactDetails },
    });
    if (!(putResponse && putResponse.status && putResponse.status !== 200)) {
      showSuccess(t(`${translationPath}contact-updated-successfully`));
      setIsLoadingForm(false);
      if (reloadData)
        reloadData();
      if (isOpenChanged) {
        isOpenChanged();
      }
      // cancelHandler();
    } else {
      setIsLoadingForm(false);
      showError(
        putResponse?.data?.Message?.split(':')[1] ??
        t(`${translationPath}contact-update-failed`)
      );
    }
  };
  const onLoadingsChanged = (value, key) => {
    setLoadings((items) => {
      const itemIndex = items.findIndex((item) => item.key === key);
      if (value) {
        const addItem = {
          key,
          value,
        };
        if (itemIndex !== -1) items[itemIndex] = addItem;
        else items.push(addItem);
      } else if (itemIndex !== -1) items.splice(itemIndex, 1);
      return [...items];
    });
  };
  const onItemChanged = (item, index) => (newValue, itemIndex, itemKey, parentItemKey) => {
    setFormData((elements) => {
      if (parentItemKey) {
        if (itemIndex !== undefined)
          elements[activeTab][itemIndex][parentItemKey][itemKey] = newValue;
        else elements[activeTab][index][parentItemKey][itemKey] = newValue;
      } else if (itemIndex) elements[activeTab][itemIndex][itemKey] = newValue;
      else elements[activeTab][index][itemKey] = newValue;
      return [...elements];
    });
  };
  const onValueChanged = (item) => (newValue, itemIndex, id) => {
    setContactDetails((items) => {
      if ((itemIndex || itemIndex === 0) && itemIndex !== -1) {
        if (id || (formData[activeTab][itemIndex] && formData[activeTab][itemIndex].field && formData[activeTab][itemIndex].field.id))
          items.contact[id || formData[activeTab][itemIndex].field.id] = newValue;


      }

      else if (item && item.field && item.field.id) items.contact[item.field.id] = newValue;
      return { ...items };
    });
  };
  const lookupInit = useCallback(() => {
    setIsLoadingForm(true);
    const result = LookupsRules(formData[activeTab], contactDetails.contact, onLoadingsChanged, sensitiveFieldsForUser);
    setFormData((items) => {
      items.splice(activeTab, 1, result);
      return [...items];
    });
    setIsLoadingForm(false);
  }, [activeTab, contactDetails.contact, formData, sensitiveFieldsForUser]);
  useEffect(() => {
    if (formData.length > 0 && contactDetails.contact)
      setErrors(FormErrorsHandler(formData, contactDetails.contact));
  }, [contactDetails, formData]);
  useEffect(() => {
    if (!enumsInitFinished && formData.length > 0) {
      setEnumsInitFinished(true);
      lookupInit();
    }
  }, [activeTab, enumsInitFinished, formData.length, lookupInit, isSensitiveLoading]);
  useEffect(() => {
    if (contactDetails.contact) dataHandler();
  }, [contactDetails.contact, dataHandler, sensitiveFieldsForUser, isSensitiveLoading]);
  useEffect(() => {
    if (formAndTabs.length > 0) getContactDetails();
  }, [formAndTabs, getContactDetails]);
  useEffect(() => {
    if (activeItem && activeItem.userTypeId) {
      getAllFormFieldTabsByFormId();
      if(activeItem.userTypeId === '2' || activeItem.userTypeId === 2)
        GetRatingDeveloperScore(activeItem.id);
    }
  }, [activeItem, getAllFormFieldTabsByFormId ,GetRatingDeveloperScore]);
  useEffect(() => {
    if (isDetailsDialog) {
      const obj = JSON.parse(localStorage.getItem('current'));
      setActiveItem({
        id: obj.id,
        userTypeId: obj.type
      });
    }
    else {
      setActiveItem({
        id: GetParams('id'),
        userTypeId: GetParams('formType'),
      });
    }
  }, [location]);

  useEffect(() => {
    localStorage.removeItem('leadStatus');
    // GlobalHistory.listen(
    //   () =>
    //     GlobalHistory.action === 'POP' &&
    //     setActiveItem({
    //       id: GetParams('id'),
    //       userTypeId: GetParams('formType'),
    //     })
    // );
  }, []);

  useEffect(() => {
    if (!isOpen) {
      bottomBoxComponentUpdate(
        <div className='d-flex-v-center-h-end flex-wrap'>
          <ButtonBase className='btns theme-transparent mb-2' onClick={cancelHandler}>
            <span>{t('Shared:cancel')}</span>
          </ButtonBase>

          <PermissionsComponent
            permissionsList={Object.values(ContactsPermissions)}
            permissionsId={ContactsPermissions.EditContactInformation.permissionsId}
          >
            <ButtonBase className='btns theme-solid mb-2' onClick={saveHandler}>
              <span>{t('Shared:save')}</span>
            </ButtonBase>
          </PermissionsComponent>

        </div>
      );

    }
  });
  
  useEffect(() => {
    if (save && isActiveToSave) {
      saveHandler();
    }
  }, [save]);

  useEffect(() => {

    if (contactWithPolicy) {
      setContactInitDetails(JSON.parse(JSON.stringify(contactWithPolicy)));
      setContactDetails(contactWithPolicy);
    }
    else {
      setContactInitDetails({});
      setContactDetails({});
    }


  }, [contactWithPolicy]);
  return (
    <div className='contacts-information-wrapper childs-wrapper b-0'>
      <Spinner isActive={(isLoading || isLoadingForm)} isAbsolute />
      <TabsComponent
        data={formAndTabs}
        labelInput='tab'
        parentTranslationPath={parentTranslationPath}
        translationPath={translationPath}
        themeClasses='theme-curved'
        currentTab={activeTab}
        onTabChanged={onTabChanged}
      />
      <div className='tabs-content-wrapper'>
        {formData &&
          // enumsInitFinished &&
          formData[activeTab] &&
          formData[activeTab]
            .filter(
              (item) =>
                viewType === 1 ||
                (viewType === 2 &&
                  ((contactEmptyFields && contactEmptyFields.contact && contactEmptyFields.contact[item.field.id] === null) ||
                    (contactEmptyFields && contactEmptyFields.contact && contactEmptyFields.contact[item.field.id] === undefined) ||
                    (contactEmptyFields && contactEmptyFields.contact && contactEmptyFields.contact[item.field.id] === '')))
            )
            .map((item, index) => (
              <ConvertJsonV2Component
                key={`form${index + 1}-${activeTab}`}
                item={item}
                allItems={formData[activeTab]}
                allItemsValues={contactDetails.contact}
                itemValue={(item.field.id && contactFieldsGetDataFromAnother.some((x)=> x.key === item.field.id ) && selectValueFromAnotherAPI(item.field.id)) || ((contactDetails.contact[item.field.id]))}
                isSubmitted={isSubmitted}
                onItemChanged={onItemChanged(item, index)}
                onValueChanged={onValueChanged(item, index)}
                helperText={
                  (errors.find((element) => element.key === item.field.id) &&
                    errors.find((element) => element.key === item.field.id).message) ||
                  ''
                }
                error={errors.findIndex((element) => element.key === item.field.id) !== -1}
                isLoading={
                  loadings.findIndex(
                    (element) => element.key === item.field.id && element.value
                  ) !== -1
                }
                onLoadingsChanged={onLoadingsChanged}
                isOpenInsideForm={isOpenInsideForm}
                sensitiveFieldsForUser={sensitiveFieldsForUser}
                parentTranslationPath={parentTranslationPath}
                translationPath={translationPath}
              />
            ))}
      </div>
    </div>
  );
};

ContactsInformationComponent.propTypes = {
  viewType: PropTypes.oneOf([1, 2]).isRequired,
  parentTranslationPath: PropTypes.string.isRequired,
  translationPath: PropTypes.string.isRequired,
  save: PropTypes.bool,
  isDetailsDialog: PropTypes.bool,
  isActiveToSave: PropTypes.bool,
  isOpen: PropTypes.bool,
  isOpenChanged: PropTypes.func,
  onSave: PropTypes.func,
  isOpenInsideForm: PropTypes.bool,

};

ContactsInformationComponent.defaultProps = {
  save: false,
  isDetailsDialog: false,
  isActiveToSave: false,
  isOpen: false,
  isOpenChanged: undefined,
  onSave: undefined,
  isOpenInsideForm: false,

};

