import React, { useState, useEffect } from 'react';
import { NotFoundLayout } from '../../../Layouts/NotFoundLayout/NotFoundLayout';

export const ExternalLinkDisplayComponent = ({ URL }) => {
  const expression =
    /\bhttps?:\/\/(?:(?:[-;:&=+$,\w]+@)?[A-Za-z0-9.-]+|(?:www\.|[-;:&=+$,\w]+@)[A-Za-z0-9.-]+)(?:\/[+~%\/.\w_-]*\??(?:[-+=&;%@.\w_]*)#?[.!\/\\\w]*)?/g;
  const urlRegex = new RegExp(expression);

  const [isValidURL, setIsValidURL] = useState(false);


  useEffect(() => {
    if (URL) setIsValidURL(URL.match(urlRegex));
  }, [URL]);

  return (
    <div className='external-link-display mt-2 px-2'>
      {(isValidURL && (
        <>
          <iframe
             allowFullScreen="true"
             src={URL}
             sandbox='allow-scripts allow-same-origin allow-presentation allow-popups allow-forms'
             loading='lazy'
             className='iframe-style'
          />
        </>
      )) || <NotFoundLayout />}
    </div>
  );
};
