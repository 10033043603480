import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button } from '@material-ui/core';
import { GetAllPropertyCampaigns } from '../../../../../../../../Services';
import { GetParams , returnPropsByPermissions  } from '../../../../../../../../Helper';
import { Tables, Spinner , PermissionsComponent  } from '../../../../../../../../Components';
import { TableActions } from '../../../../../../../../Enums';
import { MarketingCampaignsHeaderData } from './MarketingCampaignsHeaderData';
import { DeleteDialog } from './DeleteDialog';
import { CampaignManangementDialog } from './CampaignManangementDialog';
import { PropertiesPermissionsCRM  } from '../../../../../../../../Permissions' ; 

export const PropertyMarketingCampaigns = ({
  state,
  onStateChanged,
  parentTranslationPath,
  translationPath,
}) => {
  const { t } = useTranslation(parentTranslationPath);
  const defaultTableActions = [
    {
      enum: TableActions.editText.key,
      isDisabled: false,
      externalComponent: null,
    },
    {
      enum: TableActions.deleteText.key,
      isDisabled: false,
      externalComponent: null,
    },
  ];


  const getActionTableWithPermissions = () => {
    const list = [];
    if (returnPropsByPermissions(PropertiesPermissionsCRM.EditPropertyCampaign.permissionsId)) 
      list.push({
        enum: TableActions.editText.key,
        isDisabled: false,
        externalComponent: null,
      });
    if (returnPropsByPermissions(PropertiesPermissionsCRM.DeletePropertyCampaign.permissionsId)) 
       list.push({
        enum: TableActions.deleteText.key,
        isDisabled: false,
        externalComponent: null,
      });
    return list;
  };

  const [isLoading, setIsLoading] = useState(false);
  const [filter, setFilter] = useState({
    pageSize: 25,
    pageIndex: 0,
  });
  const [marketingCampaigns, setMarketingCampaigns] = useState([]);
  const [marketingCampaignsTotal, setMarketingCampaignsTotal] = useState([]);
  const [activeItem, setActiveItem] = useState(null);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const tableActionClicked = useCallback((actionEnum, item) => {
    if (actionEnum === TableActions.editText.key) {
      setActiveItem(item);
      setIsDialogOpen(true);
    } else if (actionEnum === TableActions.deleteText.key) {
      setActiveItem(item);
      setIsDeleteDialogOpen(true);
    }
  }, []);
  const addNewHandler = () => {
    setIsDialogOpen(true);
  };
  const getAllPropertyCampaigns = useCallback(async () => {
    setIsLoading(true);
    const propertyId = +GetParams('id');
    const res = await GetAllPropertyCampaigns(propertyId, filter.pageIndex, filter.pageSize);
    if (!(res && res.status && res.status !== 200)) {
      setMarketingCampaigns(res.result || []);
      setMarketingCampaignsTotal(res.totalCount || 0);
    } else
      setMarketingCampaigns([]);

    setIsLoading(false);
  }, [filter]);

  const reloadData = () => {
    getAllPropertyCampaigns();
  };

  const onPageIndexChanged = (pageIndex) => {
    setFilter((item) => ({ ...item, pageIndex }));
  };
  const onPageSizeChanged = (pageSize) => {
    setFilter((item) => ({ ...item, pageIndex: 0, pageSize }));
  };

  useEffect(() => {
    getAllPropertyCampaigns();
  }, [filter]);

  return (
    <div className='property-marketing-views-wrapper presentational-wrapper'>
      <Spinner isActive={isLoading} isAbsolute />
      <div className='title-wrapper'>
        <span className='title-section'>{t(`${translationPath}campaigns`)}</span>
      </div>
        <PermissionsComponent
            permissionsList={Object.values(PropertiesPermissionsCRM)}
            permissionsId={PropertiesPermissionsCRM.AddPropertyCampaign.permissionsId}
        >
          <Button className='btns mx-3 mb-2 theme-solid' onClick={addNewHandler}>
            <span className='mdi mdi-plus' />
             {t(`${translationPath}add-new`)}
           </Button>
       </PermissionsComponent>
      
      <div className='w-100'>
        <Tables
          data={marketingCampaigns}
          headerData={MarketingCampaignsHeaderData}
          actionsOptions={{
            onActionClicked: tableActionClicked,
          }}
          itemsPerPage={filter.pageSize}
          activePage={filter.pageIndex}
          defaultActions={getActionTableWithPermissions()}
          onPageIndexChanged={onPageIndexChanged}
          onPageSizeChanged={onPageSizeChanged}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          isLoading={isLoading}
          totalItems={marketingCampaignsTotal}
        />
      </div>
      {isDeleteDialogOpen && (
        <DeleteDialog
          activeItem={activeItem}
          setActiveItem={setActiveItem}
          isDeleteDialogOpen={isDeleteDialogOpen}
          setIsDeleteDialogOpen={setIsDeleteDialogOpen}
          reloadData={reloadData}
          onCloseClicked={() => {
            setIsDeleteDialogOpen(false);
            setActiveItem(null);
          }}
          onCancelClicked={() => {
            setIsDeleteDialogOpen(false);
            setActiveItem(null);
          }}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
        />
      )}
      {isDialogOpen && (
        <CampaignManangementDialog
          isDialogOpen={isDialogOpen}
          activeItem={activeItem}
          onSave={() => {
            reloadData();
            setIsDialogOpen(false);
            setActiveItem(null);
          }}
          onClose={() => {
            setActiveItem(null);
            setIsDialogOpen(false);
          }}
          translationPath={translationPath}
          parentTranslationPath={parentTranslationPath}
        />
      )}
    </div>
  );
};
