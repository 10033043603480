export const TaskNotificationReceiverType = {
    Creator: {
        key: 'Creator',
        value: 1,
    },
    AssignedTo: {
        key: 'Assigned To',
        value: 2,
    },
    LineManager: {
        key: 'Line Manager',
        value: 3,
    },
    TeamManager: {
        key: 'Team Manager',
        value: 4,
    },
    TeamLead: {
        key: 'Team Lead',
        value: 5,
    },
    LeadAuditor: {
        key: 'Lead Auditor',
        value: 6,
    },
}