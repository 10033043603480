import React, { useState, useEffect, useCallback } from 'react';
import { Tooltip } from '@material-ui/core';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useTitle } from '../../../Hooks';
import { bottomBoxComponentUpdate } from '../../../Helper';
import { GetAllSendTemplateEmailLogs } from '../../../Services';
import { PaginationComponent, DialogComponent, Spinner, Tables } from '../../../Components';
import { TableActions } from '../../../Enums';

const parentTranslationPath = 'TemplateShareLog';
const translationPath = '';

export const TemplateShareLogView = () => {
  const { t } = useTranslation(parentTranslationPath);

  useTitle(t(`${translationPath}template-share-log`));

  const [isOpenViewTempleteDialog, setIsOpenViewTempleteDialog] = useState();
  const [activeItem, setActiveItem] = useState(null);
  const [templateViewDialogSize, setTemplateViewDialogSize] = useState('lg');
  const [filter, setFilter] = useState({
    pageIndex: 0,
    pageSize: parseInt(localStorage.getItem('Pagination')) || 25,
  });
  const [isLoading, setIsLoading] = useState({
    templateShareLog: false,
  });
  const [logData, setLogData] = useState({
    result: [],
    totalCount: 0,
  });

  const onPageIndexChanged = (pageIndex) => {
    setFilter((item) => ({ ...item, pageIndex }));
  };
  const onPageSizeChanged = (pageSize) => {
    setFilter((item) => ({ ...item, pageIndex: 0, pageSize }));
  };

  const tableActionClicked = useCallback((actionKey, item) => {
    if (actionKey === TableActions.preview.key) {
      setActiveItem(item);

      setIsOpenViewTempleteDialog(true);
    }
  }, []);

  const resizeClickHandler = useCallback(async () => {
    if (templateViewDialogSize === 'lg')
      setTemplateViewDialogSize('xl');
    else setTemplateViewDialogSize('lg');
  }, [templateViewDialogSize]);

  const getAllSendTemplateEmailLogs = useCallback(async () => {
    setIsLoading((item) => ({ ...item, templateShareLog: true }));

    const res = await GetAllSendTemplateEmailLogs({
      pageSize: filter.pageSize,
      pageIndex: filter.pageIndex + 1,
    }
    );
    if (!(res && res.status && res.status !== 200)) {
      setLogData({
        result: res?.result || [],
        totalCount: res?.totalCount || 0,
      });
    } else {
      setLogData({
        result: [],
        totalCount: 0,
      });
    }
    setIsLoading((item) => ({ ...item, templateShareLog: false }));
  }, [filter]);



  useEffect(() => {
    bottomBoxComponentUpdate(
      <PaginationComponent
        pageIndex={filter.pageIndex}
        pageSize={filter.pageSize}
        totalCount={(logData && logData.totalCount)}
        onPageIndexChanged={onPageIndexChanged}
        onPageSizeChanged={onPageSizeChanged}
        translationPath=''
        parentTranslationPath='Shared'
      />
    );
  }, [logData, filter.pageIndex, filter.pageSize]);

  useEffect(
    () => () => {
      bottomBoxComponentUpdate(null);
    },
    []
  );

  useEffect(() => {
    getAllSendTemplateEmailLogs();
  }, [filter]);


  return (
    <>
      <Spinner isActive={isLoading?.templateShareLog} />
      <div className='w-100 p-3 mt-5'>
        <Tables
          data={logData?.result || []}
          headerData={[
            {
              id: 1,
              label: 'id',
              component: (item) => <span>{item?.templateInfoDto?.templateId}</span>,
            },
            {
              id: 1,
              label: 'name',
              component: (item) => <span>{item?.templateInfoDto?.templateName}</span>,
            },
            {
              id: 3,
              label: 'sent-via',
              component: () => <span>Email</span>,
            },
            {
              id: 3,
              label: 'sender-name',
              component: (item) => <span>{item?.senderInfoDto?.senderName}</span>,
            },
            {
              id: 3,
              label: 'reciver-name',
              component: (item) => (
                <div className='table-list-wrapper'>
                  {item?.reciverInfoDto &&
                    item?.reciverInfoDto.map((element, index) => (
                      <p className='d-inline-flex mr-1'>
                        <span className='circle-dot-element bg-primary'/>
                        <span title={element.reciverEmail}>{element.reciverName}</span>
                      </p>
                    ))}
                </div>
              ),
            },
            {
              id: 3,
              label: 'reciver-Email',
              component: (item) => (
                <div className='table-list-wrapper'>
                  {item?.reciverInfoDto &&
                    item.reciverInfoDto.map(element => (
                      <p className='d-inline-flex mr-1'>
                        <span className='circle-dot-element'/>
                        <span className='c-primary'>{element.reciverEmail}</span>
                      </p>
                    ))}
                </div>
              ),
            },
            {
              id: 2,
              label: 'Sending-date',
              component: (item) => (
                <span>{moment(item?.templateInfoDto?.sentTemplateDate).format('DD/MM/YYYY')}</span>
              ),
            },
            {
              id: 2,
              label: 'Sending-time',
              component: (item) => (
                <span>{moment(item?.templateInfoDto?.sentTemplateDate).format('h:mm A')}</span>
              ),
            },
          ]}
          defaultActions={[
            {
              enum: TableActions.preview.key,
            },
          ]}
          actionsOptions={{
            onActionClicked: tableActionClicked,
          }}
          itemsPerPage={filter.pageSize}
          activePage={filter.pageIndex}
          totalItems={logData?.totalCount || 0}
          translationPath={translationPath}
          parentTranslationPath={parentTranslationPath}
          onPageIndexChanged={onPageIndexChanged}
          onPageSizeChanged={onPageSizeChanged}
        />
      </div>
      {isOpenViewTempleteDialog && (
        <DialogComponent
          titleText='View-templete'
          isOpen={isOpenViewTempleteDialog}
          reSize
          maxWidth={templateViewDialogSize}
          dialogContent={
            <>
              <div
                className='d-flex-center'
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{ __html: activeItem?.templateInfoDto?.templateContent }}
              />
            </>
          }
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          onCloseClicked={() => {
            setIsOpenViewTempleteDialog(false);
          }}
          onSizeClicked={() => resizeClickHandler()}
          />
      )}
    </>
  );
};
