export const AgentsPermissions = {
    ViewAllAgents: {
        permissionsId: '3e55049e-ca35-4ac9-f9e5-08d9689a2271',
        permissionsName: 'View All Agents',
        componentsId: null,
        components: null
    },
    EditAgentFile: {
        permissionsId: 'e379eae3-7711-490a-f9e6-08d9689a2271',
        permissionsName: 'Edit Agent File',
        componentsId: null,
        components: null
    }

};
