
import React from 'react';
import { 
    UnitsModelsFieldsFirstSectionComponent , 
    UnitsModelsFieldsSecondSectionComponent , 
    UnitsModelsFieldsThirdSectionComponent , 
    UnitsModelsFieldsFourthSectionComponent ,
    UnitsModelsImageSectionComponent ,  

} from '../UnitsModelsFieldsComponent' ; 

export const UnitsModelsFieldsComponent = ({
  state ,
   setState ,
   parentTranslationPath,
   translationPath ,
   propertyUnitTypes ,
   activeItem ,
   schema , 
   isSubmitted  , 
   error , 
   setError , 
   isLoadingModelsImageUploading,
   setIsLoadingModelsImageUploading
}) => 
{
  return (
    <div className='dialog-content-wrapper unitsModelsFields'>
          <UnitsModelsFieldsFirstSectionComponent 
            state ={state}
            setState={setState}
            parentTranslationPath={parentTranslationPath}
            translationPath={translationPath}
            schema={schema}
            isSubmitted={isSubmitted}
            error={error}
            setError={setError}
          />
           <UnitsModelsFieldsSecondSectionComponent 
            state ={state}
            setState={setState}
            parentTranslationPath={parentTranslationPath}
            translationPath={translationPath}
            error={error}
            setError={setError}
           />
           <UnitsModelsFieldsThirdSectionComponent 
            state ={state}
            setState={setState}
            parentTranslationPath={parentTranslationPath}
            translationPath={translationPath}
            error={error}
            setError={setError}
          />
           <UnitsModelsFieldsFourthSectionComponent 
            state ={state}
            setState={setState}
            parentTranslationPath={parentTranslationPath}
            translationPath={translationPath}
            propertyUnitTypes={propertyUnitTypes}
          />
          <UnitsModelsImageSectionComponent
            state ={state}
            setState={setState}
            isLoadingModelsImageUploading={isLoadingModelsImageUploading}
            setIsLoadingModelsImageUploading = {setIsLoadingModelsImageUploading}
            parentTranslationPath={parentTranslationPath}
            translationPath={translationPath}
          />
     </div>
  );
};
