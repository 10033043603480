export const OwnerRotationPermissions = {
  
    ViewOwnerLeadsRotationSchemes : {
         permissionsId: "0956ccde-b9f8-476a-5dc2-08dc7403ebc2",
         permissionsName: "View Owner Leads Rotation Schemes",
         description: null,
         componentsId: null,
         components: null
    }
};
