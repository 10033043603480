import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { Tables } from "../../../../../../Components";
import { TableActions } from "../../../../../../Enums";
import { PaymentDetailsTabsData } from "../PaymentDetailsTabsData";
import { formatCommasForPriceUnit } from "../../../../../../Helper/formatCommas.Helper";

export const PaymentInvoicesTable = ({
  paymentInvoices,
  activeInvoiceHandler,
  activeViewHandler,
  parentTranslationPath,
  translationPath,
}) => {
  const { t } = useTranslation(parentTranslationPath);
  const [filter, setFilter] = useState({
    pageIndex: 1,
    pageSize: 1000,
  });
  const tableActionClicked = useCallback(
    (actionEnum, item, focusedRow, event) => {
      event.stopPropagation();
      event.preventDefault();
      if (actionEnum === TableActions.preview.key) {
        activeViewHandler(PaymentDetailsTabsData.InvoiceDetails);
      } else if (actionEnum === TableActions.addPrimaryText.key) {
        activeViewHandler(PaymentDetailsTabsData.InvoiceReceipt);
      }
    },
    []
  );

  const focusedRowChanged = (_, activeRow) => {
    activeInvoiceHandler(activeRow?.invoiceId);
  };

  return (
    <>
      <div className="w-100 mb-2">
        <Tables
          data={paymentInvoices || []}
          headerData={[
            {
              id: 1,
              label: "payment-no",
              input: "paymentNo",
            },
            {
              id: 2,
              label: "invoice-id",
              component: (item) => <span className="fw-simi-bold">{item.invoiceId}</span>
            },
            {
              id: 3,
              label: "status",
              input: "invoiceStateName",
            },
            {
              id: 4,
              label: "amount-due",
              input: "amountDue",
              component: (item) => (item?.amountDue
                ? (<span className="fw-simi-bold fz-14 c-propx">
                  {`${formatCommasForPriceUnit(item.amountDue)}`}
                  <span className="px-1 fz-10 light-text">AED</span>
                </span>)
                : '-')
            },
            {
              id: 5,
              label: "balance",
              input: "balance",
              component: (item) => (item?.balance
                ? (<span className="fw-simi-bold fz-14 c-propx">
                  {`${formatCommasForPriceUnit(item.balance)}`}
                  <span className="px-1 fz-10 light-text">AED</span>
                </span>)
                : '-')
            },
            {
              id: 6,
              label: "type",
              input: "paymentTypeName",
            },
            {
              id: 7,
              label: "mode",
              input: "paymentModeName",
            },
            {
              id: 8,
              label: "ref-no",
              input: "unitReferenceNo",
            },
          ]}
          defaultActions={[
            {
              enum: TableActions.preview.key,
              title: t(`${translationPath}preview-invoice`),
            },
            {
              enum: TableActions.addPrimaryText.key,
              title: t(`${translationPath}add-receipt`),
            },
          ]}
          actionsOptions={{
            onActionClicked: tableActionClicked,
          }}
          focusedRowChanged={focusedRowChanged}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          activePage={filter.pageIndex}
          itemsPerPage={paymentInvoices?.length || 0}
          totalItems={paymentInvoices?.length || 0}
        />
      </div>
    </>
  );
};
