import { config } from '../config';
import { HttpServices } from '../Helper';


export const GetAllTicketListFromFreshDisk = async ({ pageIndex, pageSize }) => {

    const result = await HttpServices.get(
        `${config.server_address}/CrmDfm/FreshDisk/GetAllTicketListFromFreshDisk/${pageIndex}/${pageSize}`
    ).then((data) => data)
        .catch((error) => error.response);
    return result;
};

