import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => {
  return {
    badgeSection: {
      display: "flex",
      gap: "6px",
    },
  };
});
