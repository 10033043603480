import React, { useCallback, useEffect, useReducer, useRef } from "react";
import {
  AutocompleteComponent,
  Inputs,
  SelectComponet,
} from "../../../../../Components";
import { useTranslation } from "react-i18next";
import { DateRangePickerComponent } from "../../../../../Components/Controls/DateRangePickerComponent/DateRangePickerComponent";
import moment from "moment";
import { GetLookupItemsByLookupTypeName } from "../../../../../Services";

function DNCRAndConsentTableFilters({
  filter,
  setFilter,
  consentTypes,
  translationPath,
  parentTranslationPath,
}) {
  const searchTimer = useRef(null);
  const { t } = useTranslation(parentTranslationPath);
  const reducer = useCallback((state, action) => {
    if (action.id !== "edit") return { ...state, [action.id]: action.value };
    return {
      ...action.value,
    };
  }, []);
  const [selected, setSelected] = useReducer(reducer, {
    startDate: null,
    endDate: null,
    key: "selection",
    user: null,
    remarks: null,
    dncrId: null,
    changeReasonId: null,
    mainConsentId: null,
    assetsConsentId: null,
    contactConsentEvidenceType: null,
    changeType: null,
  });

  const [data, setData] = useReducer(reducer, {
    reasons: [],
    consentTypes: consentTypes,
    dncrTypes: [],
  });

  const getReasons = useCallback(async () => {
    const res = await GetLookupItemsByLookupTypeName({
      lookUpName: "DNCRReasons",
      pageIndex: 0,
      pageSize: 99999,
    });

    const response = await GetLookupItemsByLookupTypeName({
      lookUpName: "ConsentReasons",
      pageIndex: 0,
      pageSize: 99999,
    });

    if (
      !(res && res.status && res.status !== 200) &&
      !(response && response.status && response.status !== 200)
    ) {
      setData({ id: "reasons", value: [...res.result, ...response.result] });
    } else setData([]);
  }, []);

  const getConsentTypes = useCallback(async () => {
    const res = await GetLookupItemsByLookupTypeName({
      lookUpName: "ContactDNCR",
      pageIndex: 0,
      pageSize: 99999,
    });

    if (!(res && res.status && res.status !== 200)) {
      setData({ id: "dncrTypes", value: res });
    } else setData([]);
  }, []);

  useEffect(() => {
    getConsentTypes();
    getReasons();
  }, []);

  useEffect(() => {
    setData({
      id: "consentTypes",
      value: consentTypes,
    });
  }, [consentTypes]);

  return (
    <React.Fragment>
      <div className="d-flex w-100 mx-2">
        <div className="w-33 mx-2">
          <Inputs
            idRef="userNameInputRef"
            labelValue="user-name"
            value={selected?.user || ""}
            onInputChanged={(e) => {
              const { value } = e.target;
              setSelected({ id: "user", value: value });
            }}
            onKeyUp={(e) => {
              const value = e.target.value;
              if (searchTimer.current) clearTimeout(searchTimer.current);
              searchTimer.current = setTimeout(() => {
                setFilter({ id: "user", value: value || "" });
              }, 700);
            }}
            parentTranslationPath={parentTranslationPath}
            translationPath={translationPath}
          />
        </div>
        <div className="w-33 mx-2">
          <AutocompleteComponent
            idRef="reasonsOfChangeRef"
            labelValue="reason-of-change"
            multiple={false}
            data={data.reasons || []}
            displayLabel={(option) => option.lookupItemName || ""}
            renderOption={(option) => option.lookupItemName || ""}
            withoutSearchButton
            selectedValues={selected.changeReasonId || null}
            isWithError
            onChange={(event, newValue) => {
              setFilter({
                id: "changeReasonId",
                value: newValue?.lookupItemId || null,
              });
              setSelected({
                id: "changeReasonId",
                value: newValue || null,
              });
            }}
            translationPath={translationPath}
            parentTranslationPath={parentTranslationPath}
          />
        </div>
        <div className="w-33 mx-2">
          <AutocompleteComponent
            idRef="dncrTypeRef"
            labelValue="dncr-type"
            multiple={false}
            data={data?.dncrTypes?.result || []}
            displayLabel={(option) => option.lookupItemName || ""}
            renderOption={(option) => option.lookupItemName || ""}
            withoutSearchButton
            selectedValues={selected.dncrId || null}
            isWithError
            onChange={(event, newValue) => {
              setFilter({
                id: "dncrId",
                value: newValue?.lookupItemId || null,
              });
              setSelected({
                id: "dncrId",
                value: newValue || null,
              });
            }}
            translationPath={translationPath}
            parentTranslationPath={parentTranslationPath}
          />
        </div>
      </div>
      <div className="d-flex w-100">
        <div className="w-25 mx-2 mt-3">
          <AutocompleteComponent
            idRef="mainConsentTypeRef"
            labelValue="main-consent-type"
            multiple={false}
            data={data?.consentTypes?.result || []}
            displayLabel={(option) => option?.lookupItemName || ""}
            renderOption={(option) => option?.lookupItemName || ""}
            withoutSearchButton
            selectedValues={selected?.mainConsentId || null}
            isWithError
            onChange={(event, newValue) => {
              setFilter({
                id: "mainConsentId",
                value: newValue?.lookupItemId || null,
              });
              setSelected({
                id: "mainConsentId",
                value: newValue || null,
              });
            }}
            translationPath={translationPath}
            parentTranslationPath={parentTranslationPath}
          />
        </div>
        <div className="w-25 mx-2 my-3">
          <AutocompleteComponent
            idRef="assetConsentTypeRef"
            labelValue="asset-consent-type"
            multiple={false}
            data={data?.consentTypes?.result || []}
            displayLabel={(option) => option?.lookupItemName || ""}
            renderOption={(option) => option?.lookupItemName || ""}
            withoutSearchButton
            selectedValues={selected.assetsConsentId || null}
            isWithError
            onChange={(event, newValue) => {
              setFilter({
                id: "assetsConsentId",
                value: newValue?.lookupItemId || null,
              });
              setSelected({
                id: "assetsConsentId",
                value: newValue || null,
              });
            }}
            translationPath={translationPath}
            parentTranslationPath={parentTranslationPath}
          />
        </div>
        <div className="w-50 mx-2 my-3">
          <div className="d-flex my-3">
            <div className="w-50 mr-2">
              <SelectComponet
                idRef="contactConsentEvidenceTypeRef"
                data={[
                  { id: 1, text: "link" },
                  { id: 2, text: "document" },
                  { id: 3, text: "text" },
                ]}
                emptyItem={{
                  value: null,
                  text: "evidence",
                  isDisabled: false,
                }}
                value={selected?.contactConsentEvidenceType}
                onSelectChanged={(value) => {
                  setFilter({ id: "contactConsentEvidenceType", value: value });
                  setSelected({
                    id: "contactConsentEvidenceType",
                    value: value,
                  });
                }}
                wrapperClasses="mb-3"
                valueInput="id"
                textInput="text"
                parentTranslationPath={parentTranslationPath}
                translationPath={translationPath}
                translationPathForData={translationPath}
              />
            </div>
            <div className="w-50">
              <DateRangePickerComponent
                ranges={[filter]}
                onClearClicked={() => {
                  setFilter({
                    id: "edit",
                    value: {
                      ...filter,
                      startDate: null,
                      endDate: null,
                    },
                  });
                }}
                onDateChanged={(selectedDate) => {
                  setFilter({
                    id: "edit",
                    value: {
                      ...filter,
                      startDate:
                        (selectedDate.selection &&
                          selectedDate.selection.startDate) ||
                        null,
                      endDate:
                        new Date(
                          moment(
                            selectedDate.selection &&
                              selectedDate.selection.endDate
                          ).endOf("day")
                        ) || null,
                      key: "selection",
                    },
                  });
                }}
                translationPath={translationPath}
                parentTranslationPath={parentTranslationPath}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="d-flex w-100 mx-2">
        <div className="w-50 mx-2">
          <SelectComponet
            idRef="changeTypeRef"
            labelValue="change-type"
            data={[
              { id: 1, text: "auto" },
              { id: 2, text: "manual" },
            ]}
            emptyItem={{
              value: null,
              text: "change-type",
              isDisabled: false,
            }}
            value={selected?.changeType}
            onSelectChanged={(value) => {
              setFilter({ id: "changeType", value: value });
              setSelected({
                id: "changeType",
                value: value,
              });
            }}
            wrapperClasses="mb-3"
            valueInput="id"
            textInput="text"
            parentTranslationPath={parentTranslationPath}
            translationPath={translationPath}
            translationPathForData={translationPath}
          />
        </div>
        <div className="w-50 mx-2">
          <Inputs
            idRef="remarksRef"
            labelValue="remarks"
            value={selected?.remarks || ""}
            onInputChanged={(e) => {
              const { value } = e.target;
              setSelected({ id: "remarks", value: value });
            }}
            onKeyUp={(e) => {
              const value = e.target.value;
              if (searchTimer.current) clearTimeout(searchTimer.current);
              searchTimer.current = setTimeout(() => {
                setFilter({ id: "remarks", value: value || "" });
              }, 700);
            }}
            parentTranslationPath={parentTranslationPath}
            translationPath={translationPath}
          />
        </div>
      </div>
    </React.Fragment>
  );
}

export default DNCRAndConsentTableFilters;
