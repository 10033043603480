import React, {
  useState,
  useCallback,
  useReducer,
  useRef,
} from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { OrganizationUserSearch, SearchUsersByName } from "../../../../../../../Services";
import {
  SwitchComponent,
  AutocompleteComponent,
  Inputs,
} from "../../../../../../../Components";
import { floatHandler } from "../../../../../../../Helper";

export const ReferralSection = ({
  isReadOnly,
  selected,
  onSelectedChanged,
  parentTranslationPath,
  translationPath
}) => {
  const { t } = useTranslation(parentTranslationPath);
  const searchTimer = useRef(null);

  const reducer = useCallback((state, action) => {
    if (action.id !== "edit") return { ...state, [action.id]: action.value };
    return {
      ...action.value,
    };
  }, []);

  const [isFieldShown, setIsFieldShown] = useState({
    landlordReferral: false,
    tenantReferral: false,
  });
  const [data, setData] = useReducer(reducer, {
    systemUsers: {
      tenantReferrals: [],
      landlordReferrals: [],
    },
  });

  const getSystemUsers = async (userType, searchedItem) => {
    const pageIndex = 0;
    const pageSize = 10;

    const res = await SearchUsersByName(searchedItem, pageIndex, pageSize);
    if (!(res && res.status && res.status !== 200))
      setData({
        id: "systemUsers",
        value: {
          ...(data.systemUsers || []),
          [userType]: res?.result,
        },
      });
    else setData({
      id: "systemUsers", value: {
        ...(data.systemUsers || []),
        [userType]: [],
      },
    });
  };
  // const getAllReferrals = useCallback(
  //   async (value, selectedValue) => {
  //     setLoadings((items) => ({ ...items, referrals: true }));
  //     const res = await SearchUsersByName(value , 1 , 50);
  //     if (!(res && res.status && res.status !== 200)) {
  //       setReferrals(res && res.result);
  //     } else setReferrals([]);
  //     setLoadings((items) => ({ ...items, referrals: false }));
  //   },
  //   [filter]
  // );
  return (
    <>
      <div className="d-flex fj-start b-bottom">
        <div className="w-33 mt-2 pt-1">
          <span className="fw-simi-bold">
            {t(`${translationPath}Internal-referral-landlord`)}
          </span>
        </div>
        <div className="w-50 mt-0 mb-2">
          <SwitchComponent
            idRef="Internal-referral-landlordRef"
            themeClass="thick-theme"
            wrapperClasses="mb-3"
            isChecked={isFieldShown.landlordReferral || false}
            onChangeHandler={(_, isChecked) => {
              setIsFieldShown(prevState => ({
                ...prevState,
                landlordReferral: isChecked,
              }))
            }}
          />
          {isFieldShown.landlordReferral &&
            <div className="d-flex-column gap-12">
              <AutocompleteComponent
                idRef="landlordReferralIdRef"
                inputPlaceholder="select"
                labelClasses="px-0"
                labelValue="transactional-internal-landlord"
                selectedValues={selected.landlordReferral}
                multiple={false}
                data={data.systemUsers?.landlordReferrals || []}
                displayLabel={(option) => `${option.firstName || ''} ${option.lastName || ''}`}
                renderOption={(option) =>
                  (`${option.firstName || ''} ${option.lastName || ''} ${option.userName ? `(${option.userName})` : ''}`) ||
                  ""
                }
                // getOptionSelected={(option) => option.id === state.referralId}
                withoutSearchButton
                // isLoading={loadings.referrals}
                onOpen={() => {
                  const userType = "landlordReferrals";
                  if (
                    data.systemUsers?.landlordReferrals &&
                    data.systemUsers.landlordReferrals.length == 0
                  )
                    getSystemUsers(userType);
                }}
                onInputKeyUp={(e) => {
                  const { value } = e.target;
                  const userType = "landlordReferrals";

                  if (searchTimer) clearTimeout(searchTimer.current);

                  searchTimer.current = setTimeout(() => {
                    getSystemUsers(userType, value);
                  }, 1200);
                }}
                isWithError
                // isSubmitted={isSubmitted}
                parentTranslationPath={parentTranslationPath}
                translationPath={translationPath}
                onChange={(_, newValue) => {
                  onSelectedChanged({ id: 'landlordReferral', value: newValue });
                }}
                isDisabled={isReadOnly}
              />

              <Inputs
                idRef="landlordReferralPercentageRef"
                labelValue="Transactional-internal-referral-landlord"
                labelClasses="px-0"
                inputPlaceholder="%100"
                value={selected.landlordReferralPercentage || ''}
                isWithError
                // isSubmitted={isSubmitted}
                type="number"
                min={0}
                max={100}
                parentTranslationPath={parentTranslationPath}
                translationPath={translationPath}
                onInputChanged={(event) => {
                  let value = +(floatHandler(event.target.value, 3));
                  if (value > 100) value = 100;
                  onSelectedChanged({ id: 'landlordReferralPercentage', value: parseInt(value) });
                }}
                isDisabled={isReadOnly}
              />
            </div>}
        </div>
      </div>
      <div className="d-flex fj-start b-bottom">
        <div className="w-33 mt-2 pt-1">
          <span className="fw-simi-bold">
            {t(`${translationPath}Internal-referral-tenant`)}
          </span>
        </div>
        <div className="w-50 mt-0 mb-2">
          <SwitchComponent
            idRef="Internal-referral-tenantRef"
            themeClass="thick-theme"
            wrapperClasses="mb-3"
            isChecked={isFieldShown.tenantReferral || false}
            onChangeHandler={(_, isChecked) => {
              setIsFieldShown(prevState => ({
                ...prevState,
                tenantReferral: isChecked,
              }))
            }}
          />
          {isFieldShown.tenantReferral &&
            <div className="d-flex-column gap-12">
              <AutocompleteComponent
                idRef="tenantReferralIdRef"
                inputPlaceholder="select"
                labelClasses="px-0"
                labelValue="transactional-internal-tenant"
                selectedValues={selected.tenantReferral}
                multiple={false}
                data={data.systemUsers?.tenantReferrals || []}
                displayLabel={(option) => `${option.firstName || ''} ${option.lastName || ''}`}
                renderOption={(option) =>
                  (`${option.firstName || ''} ${option.lastName || ''} ${option.userName ? `(${option.userName})` : ''}`) ||
                  ""
                }
                // getOptionSelected={(option) => option.id === state.referralId}
                withoutSearchButton
                onOpen={() => {
                  const userType = "tenantReferrals";
                  if (
                    data.systemUsers?.tenantReferrals &&
                    data.systemUsers.tenantReferrals.length == 0
                  )
                    getSystemUsers(userType);
                }}
                onInputKeyUp={(e) => {
                  const { value } = e.target;
                  const userType = "tenantReferrals";

                  if (searchTimer) clearTimeout(searchTimer.current);

                  searchTimer.current = setTimeout(() => {
                    getSystemUsers(userType, value);
                  }, 1200);
                }}
                isWithError
                // isSubmitted={isSubmitted}
                parentTranslationPath={parentTranslationPath}
                translationPath={translationPath}
                onChange={(_, newValue) => {
                  onSelectedChanged({ id: 'tenantReferral', value: newValue });
                  // onStateChanged({
                  //   id: 'referralId',
                  //   value: (newValue && newValue.applicationUserId) || null,
                  // });
                }}
                isDisabled={isReadOnly}
              />
              <Inputs
                idRef="tenantReferralPercentageRef"
                labelValue="Transactional-internal-referral-tenant"
                labelClasses="px-0"
                inputPlaceholder="%100"
                value={selected.tenantReferralPercentage || ''}
                isWithError
                // isSubmitted={isSubmitted}
                type="number"
                min={0}
                max={100}
                parentTranslationPath={parentTranslationPath}
                translationPath={translationPath}
                onInputChanged={(event) => {
                  let value = +(floatHandler(event.target.value, 3));
                  if (value > 100) value = 100;
                  onSelectedChanged({ id: 'tenantReferralPercentage', value: parseInt(value) });
                }}
                isDisabled={isReadOnly}
              />
            </div>}
        </div>
      </div>
    </>
  );
};

ReferralSection.propTypes = {};
