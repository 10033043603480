import React from 'react';
import { Menu, MenuItem, withStyles } from '@material-ui/core';
import { GlobalHistory } from '../../../../Helper';
import { useTranslation } from 'react-i18next';
import Bill from '../../../../assets/images/Propxicon/ticket-02.svg';
import cryptocurrency from '../../../../assets/images/Propxicon/cryptocurrency-01.svg';
import crm from '../../../../assets/images/Propxicon/crm.svg';
import list from '../../../../assets/images/Propxicon/list.svg';
import { PermissionsComponent } from '../../../../Components';
import { havePermissionToViewDetails } from '../../../../Helper';
import {
  BillingPermissions,
  GroupsPermissions,
  UsersManagementPermissions,
} from '../../../../Permissions';

const StyledMenu = withStyles({
  // paper: {
  //   borderRadius: "8px",
  //   border: "1px solid #EAECF0",
  //   background: "#FFF",
  //   top: "60px !important",
  //   boxShadow:
  //     "0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03)",
  //   "& .MuiList-padding": {
  //     paddingTop: "6px",
  //     paddingBottom: "6px",
  //     "& .MuiMenuItem-root": {
  //       fontSize: "14px",
  //     },
  //   },
  // },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    keepMounted
    anchorOrigin={{
      vertical: '',
      horizontal: '',
    }}
    transformOrigin={{
      vertical: '',
      horizontal: '',
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    paddingBlock: '8px',
    '&:focus': {
      backgroundColor: theme.palette.primary.secondary,
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);

export const SettingsMenu = ({ anchorEl, onClose, parentTranslationPath }) => {
  const { t } = useTranslation(parentTranslationPath);
  return (
    <StyledMenu
      id='customized-menu'
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={onClose}
    >
      <PermissionsComponent
        permissionsList={Object.values(BillingPermissions)}
        permissionsId={[
          BillingPermissions.ViewPreview.permissionsId,
          BillingPermissions.ViewHistory.permissionsId,
        ]}
      >
        <StyledMenuItem onClick={() => GlobalHistory.push('/home/billing')}>
          <img src={Bill} className='mr-2' alt='billing' />
          {t('billing')}
        </StyledMenuItem>
      </PermissionsComponent>

      <PermissionsComponent
        permissionsList={Object.values(GroupsPermissions)}
        permissionsId={[GroupsPermissions.ViewGroupsPage.permissionsId]}
      >
        <StyledMenuItem onClick={() => GlobalHistory.push('/home/user-groups')}>
          <img src={cryptocurrency} className='mr-2' alt='group' />
          {t('groups')}
        </StyledMenuItem>
      </PermissionsComponent>

      {havePermissionToViewDetails(
        Object.values(UsersManagementPermissions)
      ) ? (
        <StyledMenuItem
          onClick={() => GlobalHistory.push('/home/users-management')}
        >
          <img src={crm} className='mr-2' alt='users' />
          {t('users-management')}
        </StyledMenuItem>
      ) : null}
      <StyledMenuItem
        onClick={() => GlobalHistory.push('/home/user-approvals')}
      >
        <img src={list} className='mr-2' alt='Approvals' />
        {t('Approvals')}
      </StyledMenuItem>
    </StyledMenu>
  );
};
