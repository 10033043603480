import React, { useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CheckboxesComponent, Inputs } from '../../../../../../../Components';
import { Children } from 'react';

function FieldsViewer({
  setFormFieldIds,
  formFieldIds,
  userDetails,
  formFields,
  parentTranslationPath,
  setUserDetails,
}) {
  const { t } = useTranslation(parentTranslationPath);
  const [searchedItemId, setSearchedItemId] = useState('');

  const searchTimer = useRef(null);
  const searchedItemRef = useRef(null);
  const cardCheckboxClicked = useCallback(
    (element) => {
      setFormFieldIds((items) => {
        const index = items.findIndex(
          (item) => item.fieldKey === element.fieldKey
        );
        if (index === -1) items.push(element);
        else {
          items.splice(index, 1);
          setSearchedItemId('');
          setUserDetails((details) => {
            const updateDetails = { ...details };
            delete updateDetails[element.fieldKey];
            return updateDetails;
          });
        }
        return [...items];
      });
    },
    [setFormFieldIds, userDetails]
  );
  const getIsChecked = useCallback(
    (item) =>
      formFieldIds.findIndex((el) => el.fieldKey === item.fieldKey) !== -1,
    [formFieldIds]
  );
  const executeScroll = () =>
    searchedItemRef?.current?.scrollIntoView({
      block: 'end',
      behavior: 'smooth',
    });
  const searchHandler = (value) => {
    if (searchTimer.current) clearTimeout(searchTimer.current);
    searchTimer.current = setTimeout(() => {
      setSearchedItemId(value);
      executeScroll();
    }, 500);
  };
  return (
    <div>
      <div className='bulk-header-section'>{t(`user-fields`)}</div>
      <div className='bulk-sub-header-section'>
        {t(`choose-one-of-the-following-fields`)}
      </div>
      <div className='mt-2'>
        <Inputs
          translationPath={''}
          parentTranslationPath={parentTranslationPath}
          idRef='unitsSearchRef'
          inputPlaceholder='search-fields'
          onKeyUp={(e) => searchHandler(e.target.value)}
          startAdornment={<span className='mdi mdi-magnify mdi-24px c-gray' />}
        />
      </div>
      <div className='bulked-units-section mt-3'>
        {Children.toArray(
          formFields?.map((item, index) => (
            <div
              ref={
                searchedItemId.length > 0 &&
                item.fieldTitle
                  .toLowerCase()
                  .includes(searchedItemId.toLowerCase())
                  ? searchedItemRef
                  : null
              }
              className={`unit-fields-item mb-3 ${
                getIsChecked(item) ? 'is-cheked' : ''
              } ${
                searchedItemId.length > 0 &&
                item.fieldTitle
                  .toLowerCase()
                  .includes(searchedItemId.toLowerCase())
                  ? 'is-search'
                  : ''
              } `}
            >
              <div className='unit-fields-card-checkbox-wrapper'>
                <CheckboxesComponent
                  singleChecked={getIsChecked(item)}
                  idRef={`fieldsCheckboxItemRef${index + 3}`}
                  onSelectedCheckboxClicked={(e) => {
                    cardCheckboxClicked(item);
                  }}
                />
              </div>
              <div className='item-ref-no pl-2 pr-2 mt-1 capitalize'>
                {t(item.fieldTitle)}
                {item.isRequired && (
                  <span style={{ color: 'red', margin: 2 }}> * </span>
                )}
              </div>
            </div>
          ))
        )}
      </div>
    </div>
  );
}

export default FieldsViewer;
