import React, { useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Tables } from '../../../../Components';
import {
  TableActions,
  UnitsOperationTypeEnum,
  TableFilterTypesEnum,
} from '../../../../Enums';
import { InvoicesHeaderData } from './InvoicesHeaderData';
import { CancelInvoice, GetInvoicesById } from '../../../../Services';
import { InvoicesTransaction } from '../InvoicesTransaction';
import { GlobalHistory, showSuccess } from '../../../../Helper';
import { InvoiceEditDialog } from '../InvoiceEditDialog';
import { ActiveItemActions } from '../../../../store/ActiveItem/ActiveItemActions';
import { TemplateViewDialog } from '../../../Share/TemplateViewDialog/TemplateViewDialog';
import { StaticLookupsIds } from '../../../../assets/json/StaticLookupsIds';

export const InvoiceViewTable = ({
  state,
  filter,
  invoices,
  selected,
  setOpenDialog,
  onStateChanged,
  onFilterChange,
  totalAmountPaid,
  translationPath,
  onSelectedChanged,
  setTotalAmountPaid,
  parentTranslationPath,
  setSortBy,
  isWithCheckboxColumn,
  onFilterValuesChanged,
  handleChangeView,
  setActiveItem,
  activeItem,
  reload,
  isFlagTrue,
  setIsLoading,
  isLoading,
}) => {
  const onPageSizeChanged = (pageSize) => {
    onFilterChange((item) => ({ ...item, pageIndex: 0, pageSize }));
  };
  const dispatch = useDispatch();
  const [unitId, setunitId] = useState(null);
  const [templateId, settemplateId] = useState(null);
  const [activeItemid, setactiveItemid] = useState(null);
  const [isOpenTemplateDialog, setIsOpenTemplateDialog] = useState(false);
  const { t } = useTranslation(parentTranslationPath);
  const [isSale, setIsSale] = useState(false);
  const [list, setList] = useState([
    {
      enum: TableActions.openFile.key,
    },
    {
      enum: TableActions.edit.key,
    },
    {
      enum: TableActions.transactionText.key,
    },
    {
      enum: TableActions.delete.key,
    },
  ]);
  const [isSaleAndRent, setIsSaleAndRent] = useState(false);
  const [oprationTypeValue, setOprationTypeValue] = useState(0);

  const [openInvoicesTransactionDialog, setOpenInvoicesTransactionDialog] =
    useState(false);
  const [openInvoiceEditDialog, setOpenInvoiceEditDialog] = useState(false);
  const [tableFilterData, setTableFilterData] = useState([]);

  const onPageIndexChanged = (pageIndex) => {
    onFilterChange((item) => ({ ...item, pageIndex }));
  };
  const getIsSelectedAll = useCallback(
    () =>
      (state.invoicesIds &&
        invoices.result.findIndex(
          (item) => !state.invoicesIds.includes(item.invoiceId)
        ) === -1) ||
      false,
    [invoices.result, state.invoicesIds]
  );
  const getIsSelected = useCallback(
    (row) =>
      state.invoicesIds &&
      state.invoicesIds.findIndex((item) => item === row.invoiceId) !== -1,
    [state.invoicesIds]
  );

  const GetInvoicesIdAPI = useCallback(async (InvoicesId) => {
    const res = await GetInvoicesById(InvoicesId);
    if (!(res && res.status && res.status !== 200)) setActiveItem(res);
    else setActiveItem(null);
  }, []);

  const cancelInvoice = async (invoiceId) => {
    const res = await CancelInvoice(invoiceId);
    if (!(res && res.status && res.status !== 200)) {
      showSuccess('cancel-invoice-successfully');
      reload();
    }
  };
  const handleOpenFile = useCallback(
    (item) => {
      handleChangeView(item);
      onStateChanged('invoicesIds', [item?.invoiceId]);
      onSelectedChanged('invoicesIds', [item]);
      setTotalAmountPaid([item?.amountPaid]);
    },
    [handleChangeView, onStateChanged, onSelectedChanged]
  );

  const tableActionClicked = useCallback(
    (actionEnum, item, focusedRow, event) => {
      event.stopPropagation();
      event.preventDefault();
      setActiveItem(item);
      setactiveItemid(item.invoiceId);
      GetInvoicesIdAPI(item.invoiceId);
      if (actionEnum === TableActions.openFile.key) {
        
        handleOpenFile(activeItem);
       
        // if (!item.invoiceStatus) setOpenDialog(true);
        // else setOpenInvoicesTransactionDialog(true);
      } else if (actionEnum === TableActions.delete.key) {
        cancelInvoice(item.invoiceId);
      } else if (actionEnum === TableActions.edit.key) {
        if (!item.invoiceStatus) {
          if (item.operationType === UnitsOperationTypeEnum.sale.key) {
            setIsSale(true);
            setOpenInvoiceEditDialog(true);
          } else if (item.operationType === UnitsOperationTypeEnum.rent.key) {
            setIsSale(false);
            setOpenInvoiceEditDialog(true);
          } else if (
            item.operationType === UnitsOperationTypeEnum.rentAndSale.key
          ) {
            setIsSale(false);
            setIsSaleAndRent(true);
            setOprationTypeValue(
              item.invoiceContactType === 'Tenant' ||
                item.invoiceContactType === 'Landlord'
                ? 431
                : 430
            );
          }
        } else setOpenInvoicesTransactionDialog(true);
      } else if (actionEnum === TableActions.transactionText.key) {
        if (item.operationType === UnitsOperationTypeEnum.sale.key) {
          dispatch(ActiveItemActions.activeItemRequest(item));
          setIsSale(true);
          GlobalHistory.push(
            `/home/units-sales/unit-profile-reservation?id=${item.unitId}`
          );
        } else if (item.operationType === UnitsOperationTypeEnum.rent.key)
          GlobalHistory.push(
            `/home/units-lease/unit-profile-reservation?id=${item.unitId}`
          );
        else if (item.operationType === UnitsOperationTypeEnum.rentAndSale.key)
          GlobalHistory.push(
            `/home/units-lease/unit-profile-reservation?id=${item.unitId}`
          );
      } else if (actionEnum === TableActions.PrintInvoice.key) {
        setIsOpenTemplateDialog(true);
        setunitId(item.invoiceId);
        settemplateId(StaticLookupsIds.invoiceId);
      } else if (actionEnum === TableActions.PrintReceipt.key) {
        setIsOpenTemplateDialog(true);
        setunitId(item.receiptId);
        settemplateId(StaticLookupsIds.receiptId);
      }
    },
    [
      GetInvoicesIdAPI,
      onSelectedChanged,
      onStateChanged,
      setOpenDialog,
      setTotalAmountPaid,
    ]
  );

  const onSelectClicked = useCallback(
    (row) => {
      const itemIndex = state.invoicesIds
        ? state.invoicesIds.findIndex((item) => item === row.invoiceId)
        : -1;
      if (itemIndex !== -1) {
        state.invoicesIds.splice(itemIndex, 1);
        selected.invoicesIds.splice(itemIndex, 1);
        totalAmountPaid.splice(itemIndex, 1);
      } else {
        state.invoicesIds.push(row.invoiceId);
        selected.invoicesIds.push(row);
        totalAmountPaid.push(row.amountPaid);
      }
      onStateChanged('invoicesIds', state.invoicesIds);
      onSelectedChanged('invoicesIds', selected.invoicesIds);
      setTotalAmountPaid(totalAmountPaid);
    },
    [
      onSelectedChanged,
      onStateChanged,
      selected.invoicesIds,
      totalAmountPaid,
      setTotalAmountPaid,
      state.invoicesIds,
    ]
  );
  const onSelectAllClicked = () => {
    if (!getIsSelectedAll()) {
      invoices.result.map((item) => {
        if (!getIsSelected(item)) {
          state.invoicesIds.push(item.invoiceId);
          selected.invoicesIds.push(item);
          totalAmountPaid.push(item.amountPaid);
        }
      });
    } else {
      invoices.result.map((item) => {
        if (getIsSelected(item)) {
          const isSelectedIndex = state.invoicesIds.findIndex(
            (element) => element === item.invoiceId
          );
          if (isSelectedIndex !== -1) {
            state.invoicesIds.splice(isSelectedIndex, 1);
            selected.invoicesIds.splice(isSelectedIndex, 1);
            totalAmountPaid.splice(isSelectedIndex, 1);
          }
        }
      });
    }
    onStateChanged('invoicesIds', state.invoicesIds);
    onSelectedChanged('invoicesIds', selected.invoicesIds);
    setTotalAmountPaid(totalAmountPaid);
  };

  const focusedRowChanged = (rowIndex, item) => {
    if (rowIndex !== -1) {
      list.splice(0, list.length);

      if (item && item.invoiceStateName === 'Paid') {
        list.push(
          {
            enum: TableActions.openFile.key,
          },
          {
            enum: TableActions.edit.key,
          },
          {
            enum: TableActions.transactionText.key,
          },
          {
            enum: TableActions.PrintInvoice.key,
            title: t(`${translationPath}Shared:PrintInvoice`),
          }
        );
      } else if (item && item.invoiceStateName === 'Partially paid') {
        list.push(
          {
            enum: TableActions.openFile.key,
          },
          {
            enum: TableActions.edit.key,
          },
          {
            enum: TableActions.transactionText.key,
          },
          {
            enum: TableActions.PrintReceipt.key,
            title: t(`${translationPath}Shared:PrintReceipt`),
          },
          {
            enum: TableActions.PrintInvoice.key,
            title: t(`${translationPath}Shared:PrintInvoice`),
          }
        );
      } else if (item && item.invoiceStateName === 'Canceled') {
        list.push(
          {
            enum: TableActions.PrintReceipt.key,
            title: t(`${translationPath}Shared:PrintReceipt`),
          },
          {
            enum: TableActions.PrintInvoice.key,
            title: t(`${translationPath}Shared:PrintInvoice`),
          },
          {
            enum: TableActions.transactionText.key,
          }
        );
      } else if (item && item.invoiceStateName === 'Unpaid') {
        list.push(
          {
            enum: TableActions.openFile.key,
          },
          {
            enum: TableActions.edit.key,
          },
          {
            enum: TableActions.transactionText.key,
          },
          {
            enum: TableActions.delete.key,
          },
          {
            enum: TableActions.PrintReceipt.key,
            title: t(`${translationPath}Shared:PrintReceipt`),
          },
          {
            enum: TableActions.PrintInvoice.key,
            title: t(`${translationPath}Shared:PrintInvoice`),
          }
        );
      }
    }
  };

  useEffect(() => {
    setTableFilterData(
      InvoicesHeaderData.map((column) => ({
        key: column.key || column.fieldKey || column.id,
        filterType:
          (column.isDate && TableFilterTypesEnum.datePicker.key) ||
          (column.withSelectFilter && TableFilterTypesEnum.selectOption.key) ||
          (column.isNumber && TableFilterTypesEnum.numberInput.key) ||
          TableFilterTypesEnum.textInput.key,
        isHiddenFilter: column.isHiddenFilter,
        textInputType: column.textInputType,
        textInputMax: column.textInputMax,
        textInputMin: column.textInputMin,
        displayPath: column.input,
      }))
    );
  }, []);

  useEffect(() => {
    if (isFlagTrue && activeItem) {
      handleOpenFile(activeItem);
    }
  }, [isFlagTrue, activeItem, handleOpenFile]);
  return (
    <div>
      <Tables
        data={invoices.result}
        headerData={InvoicesHeaderData}
        TotalSelect={state?.invoicesIds?.length || 0}
        selectAllOptions={{
          getIsSelected,
          onSelectClicked,
          disabledRows: [],
          onSelectAllClicked,
          withCheckAll: true,
          totalAmountPaid: state.invoicesIds,
          isSelectAll: getIsSelectedAll(),
        }}
        onPageIndexChanged={onPageIndexChanged}
        onPageSizeChanged={onPageSizeChanged}
        actionsOptions={{
          onActionClicked: tableActionClicked,
        }}
        setSortBy={setSortBy}
        defaultActions={
          (list && list) || [
            {
              enum: TableActions.openFile.key,
            },
            {
              enum: TableActions.edit.key,
            },
            {
              enum: TableActions.transactionText.key,
            },
          ]
        }
        activePage={filter.pageIndex}
        itemsPerPage={filter.pageSize}
        totalItems={invoices.totalCount}
        focusedRowChanged={focusedRowChanged}
        translationPath={translationPath}
        parentTranslationPath={'InvoicesView'}
        // isWithCheckboxColumn
        onFilterValuesChanged={onFilterValuesChanged}
        filterData={tableFilterData}
        isWithFilter
        FilterDisabledButton
      />
      <InvoicesTransaction
        open={openInvoicesTransactionDialog}
        close={() => {
          setOpenInvoicesTransactionDialog(false);
        }}
        activeItem={activeItemid}
        translationPath={translationPath}
        parentTranslationPath={parentTranslationPath}
      />
      {openInvoiceEditDialog && isSale && (
        <InvoiceEditDialog
          currentOperationType={UnitsOperationTypeEnum.sale.key}
          effectedByNumber={activeItem && activeItem.sellingPrice}
          effectedByName='selling-price'
          totalInvoicesLength={
            (state.invoicesIds && state.invoicesIds.length) || 0
          }
          activeItem={activeItem}
          isOpen={openInvoiceEditDialog}
          reloadData={() => {
            setOpenInvoiceEditDialog(false);
            setActiveItem(null);
            onFilterChange((item) => ({ ...item, pageIndex: 0 }));
          }}
          isOpenChanged={() => {
            setOpenInvoiceEditDialog(false);
            setActiveItem(null);
          }}
        />
      )}

      {openInvoiceEditDialog && !isSale && (
        <InvoiceEditDialog
          currentOperationType={UnitsOperationTypeEnum.rent.key}
          effectedByNumber={activeItem && activeItem.rentPerYear}
          effectedByName='rent-per-year'
          totalInvoicesLength={
            (state.invoicesIds && state.invoicesIds.length) || 0
          }
          activeItem={activeItem}
          isOpen={openInvoiceEditDialog}
          reloadData={() => {
            setOpenInvoiceEditDialog(false);
            setActiveItem(null);
            onFilterChange((item) => ({ ...item, pageIndex: 0 }));
          }}
          isOpenChanged={() => {
            setOpenInvoiceEditDialog(false);
            setActiveItem(null);
          }}
        />
      )}
      {!isSale && isSaleAndRent && (
        <InvoiceEditDialog
          currentOperationType={oprationTypeValue}
          effectedByNumber={
            oprationTypeValue === 430
              ? activeItem && activeItem.sellingPrice
              : activeItem && activeItem.rentPerYear
          }
          effectedByName={
            oprationTypeValue === 430 ? 'selling-price' : 'rent-per-year'
          }
          totalInvoicesLength={
            (state.invoicesIds && state.invoicesIds.length) || 0
          }
          activeItem={activeItem}
          isOpen={isSaleAndRent}
          reloadData={() => {
            setIsSaleAndRent(false);
            setActiveItem(null);
            onFilterChange((item) => ({ ...item, pageIndex: 0 }));
          }}
          isOpenChanged={() => {
            setIsSaleAndRent(false);
            setActiveItem(null);
          }}
        />
      )}
      {isOpenTemplateDialog && (
        <TemplateViewDialog
          templateName='activeTemplateName'
          isOpen={isOpenTemplateDialog}
          unitId={unitId}
          templateId={templateId}
          isOpenChanged={() => {
            setIsOpenTemplateDialog(false);
          }}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
        />
      )}
    </div>
  );
};

InvoiceViewTable.propTypes = {
  setOpenDialog: PropTypes.func.isRequired,
  onFilterChange: PropTypes.func.isRequired,
  onStateChanged: PropTypes.func.isRequired,
  totalAmountPaid: PropTypes.number.isRequired,
  onSelectedChanged: PropTypes.func.isRequired,
  translationPath: PropTypes.string.isRequired,
  setTotalAmountPaid: PropTypes.func.isRequired,
  state: PropTypes.instanceOf(Object).isRequired,
  filter: PropTypes.instanceOf(Object).isRequired,
  invoices: PropTypes.instanceOf(Object).isRequired,
  selected: PropTypes.instanceOf(Object).isRequired,
  parentTranslationPath: PropTypes.string.isRequired,
  setSortBy: PropTypes.func.isRequired,
  isWithCheckboxColumn: PropTypes.bool.isRequired,
};
