import React, { useEffect, useState, useRef} from 'react';
import { PropTypes } from 'prop-types';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { Button, DialogTitle, DialogContent, DialogActions, Dialog } from '@material-ui/core';
import { Spinner, Tables } from '../../../Components';
import { GetCriteriaByReportBuilderLogId } from '../../../Services';
import { TableFilterOperatorsNumericEnum } from '../../../Enums';

const parentTranslationPath = 'UnitsProfileManagementView';
const translationPath = '';
export const ReportProcessDetailsDialog = ({
    open,
    close,
    logId
}) => {
    const { t } = useTranslation([parentTranslationPath, 'Shared']);
    const [data, setData] = useState(null);


  const getReportCriteria = async () => {
    const res = await GetCriteriaByReportBuilderLogId({ logId });
    if (!(res && res.status && res.status !== 200)) {
      setData(res?.dynamicReportFilterDto?.reportJson?.report);
    } 
  }



    useEffect(() => {
        if (logId) getReportCriteria(logId)        
    }, [logId])


    return (
      <div>
        <Dialog
          open={open}
          fullWidth
          maxWidth='md'
          className='Documents-dialog-wrapper'
          onClose={() => {
            close();
          }}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}>
          <form noValidate>
            <DialogTitle>{t(`${translationPath}Report Process Details`)}</DialogTitle>
            <DialogContent>
              <div className='report-builder-log-dialog d-flex-column'>
                <div className='process-filters'>
                  {data?.reportFilters?.dateType && (
                    <div className='mx-2'>
                      <div className='filter-capsule'>
                        <span className='mdi mdi-calendar-question' />
                        <span>Date Type :</span>
                      </div>
                      <span>{data?.reportFilters?.dateType === 1 && 'Creation Date'}</span>
                      <span>{data?.reportFilters?.dateType === 2 && 'Update Date'}</span>
                    </div>
                  )}
                  {(data?.reportFilters?.fromDate || data?.reportFilters?.toDate) && (
                    <div className='mx-2'>
                      <div className='filter-capsule'>
                        <span className='mdi mdi-calendar' />
                        <span>From-To Date :</span>
                      </div>
                      <span>
                        {(data?.reportFilters?.fromDate &&
                          moment(data?.reportFilters?.fromDate).format('DD/MM/YYYY')) ||
                          'N/A'}
                      </span>
                      <span> - </span>
                      <span>
                        {(data?.reportFilters?.toDate &&
                          moment(data?.reportFilters?.toDate).format('DD/MM/YYYY')) ||
                          'N/A'}
                      </span>
                    </div>
                  )}
                </div>
                <div className='m-2'>
                  <Tables
                    data={data?.reportConditionsDto || []}
                    headerData={[
                      {
                        id: 1,
                        label: 'Category',
                        input: 'category',
                      },
                      {
                        id: 2,
                        label: 'Form Field Title',
                        input: 'searchKey',
                      },
                      {
                        id: 3,
                        label: 'Value',
                        input: 'value',
                      },
                      {
                        id: 4,
                        label: 'Operator',
                        component: (item) => (
                          <span>
                            {item.operator
                              ? TableFilterOperatorsNumericEnum[item.operator] &&
                                TableFilterOperatorsNumericEnum[item.operator].name
                              : ''}
                          </span>
                        ),
                      },
                      {
                        id: 5,
                        label: 'Operand',
                        input: 'operand',
                      },
                    ]}
                    defaultActions={[]}
                    totalItems={data?.reportConditionsDto?.length}
                  />
                </div>
              </div>
            </DialogContent>
            <DialogActions>
              <Button className='btns theme-solid bg-cancel' onClick={() => close(false)}>
                {t(`${translationPath}Cancel`)}
              </Button>
            </DialogActions>
          </form>
        </Dialog>
      </div>
    );
};

ReportProcessDetailsDialog.propTypes = {
    open: PropTypes.func.isRequired,
    close: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    activeItem: PropTypes.instanceOf(Object),

};
ReportProcessDetailsDialog.defaultProps = {
    activeItem: null,

};
