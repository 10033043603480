import React, { useState, useEffect, useCallback } from 'react';
import { ButtonBase } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import {
  Spinner,
  Tables,
  PermissionsComponent,
} from '../../../../../../Components';
import { useTitle } from '../../../../../../Hooks';
import { TableActions } from '../../../../../../Enums';
import { GetParams, returnPropsByPermissions, showError, showSuccess } from '../../../../../../Helper';
import {
  DeletePaymentPlan,
  GetAllPaymentPlanByPropertyId,
} from '../../../../../../Services';
import { DeleteDialog } from './DeleteDialog';
import { PaymentPlanManangementDialog } from './PaymentPlanManangementDialog';
import { PropertiesPermissionsCRM } from '../../../../../../Permissions';

const parentTranslationPath = 'PropertiesProfileManagementView';
const translationPath = '';

export const PlansView = ({ handleChangeView }) => {
  const { t } = useTranslation(parentTranslationPath);
  const [isLoading, setIsLoading] = useState(false);
  const [activeItem, setActiveItem] = useState(null);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [paymentPlans, setPaymentPlans] = useState({
    result: [],
    totalCount: 0,
  });
  const [filter, setFilter] = useState({
    pageSize: 25,
    pageIndex: 0,
  });

  useTitle(t(`${translationPath}payment-plan`));

  const getAllPaymentPlans = useCallback(async () => {
    setIsLoading(true);
    const res = await GetAllPaymentPlanByPropertyId({
      propertyId: +GetParams('id'),
      pageIndex: filter.pageIndex + 1,
      pageSize: filter.pageSize,
    });
    if (!(res && res.status && res.status !== 200)) {
      setPaymentPlans({
        result: (res && res.result) || [],
        totalCount: (res && res.totalCount) || 0,
      });
    } else {
      setPaymentPlans({
        result: [],
        totalCount: 0,
      });
    }
    setIsLoading(false);
  }, [filter]);

  const onPageIndexChanged = (pageIndex) => {
    setFilter((item) => ({ ...item, pageIndex }));
  };
  const onPageSizeChanged = (pageSize) => {
    setFilter((item) => ({ ...item, pageSize }));
  };
  const addNewHandler = () => {
    setIsDialogOpen(true);
  };
  const reloadData = () => {
    getAllPaymentPlans();
  };

  const getActionTableWithPermissions = () => {
    const list = [{ enum: TableActions.viewInstallments.key }];
    if (
      returnPropsByPermissions(
        PropertiesPermissionsCRM.EditPropertyPaymentPlan.permissionsId
      )
    )
      list.push({
        enum: TableActions.editText.key,
        isDisabled: false,
        externalComponent: null,
      });
    if (
      returnPropsByPermissions(
        PropertiesPermissionsCRM.DeletePropertyPaymentPlan.permissionsId
      )
    )
      list.push({
        enum: TableActions.deleteText.key,
        isDisabled: false,
        externalComponent: null,
      });
    return list;
  };

  const tableActionClicked = useCallback(
    (actionEnum, item, focusedRow, event) => {
      event.stopPropagation();
      event.preventDefault();
      if (actionEnum === TableActions.deleteText.key) {
        setIsDeleteDialogOpen(true);
        setActiveItem(item);
      } else if (actionEnum === TableActions.editText.key) {
        setIsDialogOpen(true);
        setActiveItem(item);
      } else if (actionEnum === TableActions.viewInstallments.key) {
        handleChangeView(item.propertyPaymentPlanId);
      }
    },
    []
  );
  const deleteHandler = useCallback(async () => {
    const paymentPlanId = activeItem.propertyPaymentPlanId;
    const res = await DeletePaymentPlan({ paymentPlanId });
    if (!(res && res.status && res.status !== 200)) {
      reloadData();
      showSuccess(t(`${translationPath}payment-plan-deleted-successfully`));
    } else showError(t(`${translationPath}payment-plan-delete-failed`));
    setIsDeleteDialogOpen(false);
  }, [activeItem]);

  useEffect(() => {
    getAllPaymentPlans();
  }, [getAllPaymentPlans]);

  return (
    <div className='associated-Properties-wrapper childs-wrapper'>
      <Spinner isActive={isLoading} />
      <div className='title-section'>
        <span>{t(`${translationPath}payment-plan`)}</span>
      </div>
      <div className='d-flex-column'>
        <div className='header-section'>
          <div className='filter-section px-2'>
            <div className='section'>
              <PermissionsComponent
                permissionsList={Object.values(PropertiesPermissionsCRM)}
                permissionsId={
                  PropertiesPermissionsCRM.AddPropertyPaymentPlan.permissionsId
                }
              >
                <ButtonBase
                  className='btns theme-solid px-3'
                  onClick={addNewHandler}
                >
                  <span className='mdi mdi-plus' />
                  {t(`${translationPath}add-new`)}
                </ButtonBase>
              </PermissionsComponent>
            </div>

            <div className='section px-2' />
          </div>
        </div>
      </div>
      <div className='w-100 px-3'>
        <Tables
          data={paymentPlans.result}
          headerData={[
            {
              id: 3,
              label: 'plan-name',
              component: (item) => (
                <span>{item?.paymentPlanName ?? 'N/A'}</span>
              ),
            },
            {
              id: 1,
              label: 'Plan Type',
              component: (item) => (
                <span>
                  {item?.planTypeLookupDto?.planTypeLookupName || 'N/A'}
                </span>
              ),
            },
            {
              id: 2,
              label: 'Plan Status',
              component: (item) => <span>{item.statusName || 'N/A'}</span>,
            },
            {
              id: 9,
              label: 'Description',
              input: 'description',
            },
          ]}
          defaultActions={getActionTableWithPermissions()}
          onPageIndexChanged={onPageIndexChanged}
          onPageSizeChanged={onPageSizeChanged}
          actionsOptions={{
            onActionClicked: tableActionClicked,
          }}
          itemsPerPage={filter.pageSize}
          activePage={filter.pageIndex}
          translationPath={translationPath}
          parentTranslationPath={parentTranslationPath}
          totalItems={paymentPlans.totalCount}
        />
      </div>
      {isDeleteDialogOpen && (
        <DeleteDialog
          activeItem={activeItem}
          isDeleteDialogOpen={isDeleteDialogOpen}
          setIsDeleteDialogOpen={setIsDeleteDialogOpen}
          reloadData={reloadData}
          onCloseClicked={() => {
            setIsDeleteDialogOpen(false);
            setActiveItem(null);
          }}
          onCancelClicked={() => {
            setIsDeleteDialogOpen(false);
            setActiveItem(null);
          }}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          onConfirmDelete={deleteHandler}
        />
      )}

      {isDialogOpen && (
        
        <PaymentPlanManangementDialog
          isDialogOpen={isDialogOpen}
          activeItem={activeItem}
          onSave={() => {
            reloadData();
            setIsDialogOpen(false);
            setActiveItem(null);
          }}
          onClose={() => {
            setActiveItem(null);
            setIsDialogOpen(false);
          }}
          translationPath={translationPath}
          parentTranslationPath={parentTranslationPath}
        />
      )}
    </div>
  );
};
