export const PowerBiEnumproduction = {
  salesToken: {
    groupid: 'fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2',
    reportid: '8c81de09-4fc8-4d21-bb77-4728702afb93',
    url: 'https://app.powerbi.com/reportEmbed?reportId=8c81de09-4fc8-4d21-bb77-4728702afb93&groupId=fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsibW9kZXJuRW1iZWQiOnRydWUsImFuZ3VsYXJPbmx5UmVwb3J0RW1iZWQiOnRydWUsImNlcnRpZmllZFRlbGVtZXRyeUVtYmVkIjp0cnVlLCJ1c2FnZU1ldHJpY3NWTmV4dCI6dHJ1ZSwic2tpcFpvbmVQYXRjaCI6dHJ1ZX19',
  },
  newsalesToken: {
    groupid: 'aa196a41-662c-47d5-a998-e40c7dad64c3',
    reportid: 'a3927eb9-2b70-47dd-b4ab-2ac42ed5b8d6',
    url: 'https://app.powerbi.com/reportEmbed?reportId=a3927eb9-2b70-47dd-b4ab-2ac42ed5b8d6&groupId=aa196a41-662c-47d5-a998-e40c7dad64c3&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsibW9kZXJuRW1iZWQiOnRydWUsInVzYWdlTWV0cmljc1ZOZXh0Ijp0cnVlfX0%3d',
  },
  teamleadToken: {
    groupid: 'fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2',
    reportid: 'f5b41fe0-31bb-4731-bd6d-88f7e149f46e',
    url: 'https://app.powerbi.com/reportEmbed?reportId=f5b41fe0-31bb-4731-bd6d-88f7e149f46e&groupId=fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsibW9kZXJuRW1iZWQiOnRydWUsInVzYWdlTWV0cmljc1ZOZXh0Ijp0cnVlfX0%3d',
  },
  agentToken: {
    groupid: 'aa196a41-662c-47d5-a998-e40c7dad64c3',
    reportid: 'cf35e43e-b6aa-4999-8c4b-fb4673a1e1a4',
    url: 'https://app.powerbi.com/reportEmbed?reportId=cf35e43e-b6aa-4999-8c4b-fb4673a1e1a4&groupId=aa196a41-662c-47d5-a998-e40c7dad64c3&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsibW9kZXJuRW1iZWQiOnRydWUsInVzYWdlTWV0cmljc1ZOZXh0Ijp0cnVlfX0%3d',
  },
  leaseAgentToken: {
    groupid: 'aa196a41-662c-47d5-a998-e40c7dad64c3',
    reportid: '78d08e9b-7db0-4760-8f6c-0e0d87347a8a',
    url: 'https://app.powerbi.com/reportEmbed?reportId=78d08e9b-7db0-4760-8f6c-0e0d87347a8a&groupId=aa196a41-662c-47d5-a998-e40c7dad64c3&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsibW9kZXJuRW1iZWQiOnRydWUsInVzYWdlTWV0cmljc1ZOZXh0Ijp0cnVlfX0%3d',
  },
  leasingToken: {
    groupid: 'fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2',
    reportid: '2c42857e-2ed6-4e51-8bbd-e5670ec2df7d',
    url: 'https://app.powerbi.com/reportEmbed?reportId=2c42857e-2ed6-4e51-8bbd-e5670ec2df7d&groupId=fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsibW9kZXJuRW1iZWQiOnRydWUsImFuZ3VsYXJPbmx5UmVwb3J0RW1iZWQiOnRydWUsImNlcnRpZmllZFRlbGVtZXRyeUVtYmVkIjp0cnVlLCJ1c2FnZU1ldHJpY3NWTmV4dCI6dHJ1ZSwic2tpcFpvbmVQYXRjaCI6dHJ1ZX19',
  },
  NewleasingToken: {
    groupid: 'aa196a41-662c-47d5-a998-e40c7dad64c3',
    reportid: 'e13e1e98-144d-4802-ae34-6e300feb3e98',
    url: 'https://app.powerbi.com/reportEmbed?reportId=e13e1e98-144d-4802-ae34-6e300feb3e98&groupId=aa196a41-662c-47d5-a998-e40c7dad64c3&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsibW9kZXJuRW1iZWQiOnRydWUsInVzYWdlTWV0cmljc1ZOZXh0Ijp0cnVlfX0%3d',
  },
  callCenter: {
    groupid: 'fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2',
    reportid: '0667ec04-e216-4397-a91a-7e910126f0c2',
    url: 'https://app.powerbi.com/reportEmbed?reportId=0667ec04-e216-4397-a91a-7e910126f0c2&groupId=fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsibW9kZXJuRW1iZWQiOnRydWUsImFuZ3VsYXJPbmx5UmVwb3J0RW1iZWQiOnRydWUsImNlcnRpZmllZFRlbGVtZXRyeUVtYmVkIjp0cnVlLCJ1c2FnZU1ldHJpY3NWTmV4dCI6dHJ1ZSwic2tpcFpvbmVQYXRjaCI6dHJ1ZX19',
  },
  newcallCenter: {
    groupid: 'aa196a41-662c-47d5-a998-e40c7dad64c3',
    reportid: 'a42217aa-5949-47de-bef9-0982f51e1774',
    url: 'https://app.powerbi.com/reportEmbed?reportId=a42217aa-5949-47de-bef9-0982f51e1774&groupId=aa196a41-662c-47d5-a998-e40c7dad64c3&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsidXNhZ2VNZXRyaWNzVk5leHQiOnRydWUsImRpc2FibGVBbmd1bGFySlNCb290c3RyYXBSZXBvcnRFbWJlZCI6dHJ1ZX19',
  },
  marketing: {
    groupid: 'fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2',
    reportid: '8bfa5689-bc70-4027-a57d-98100119ab29',
    url: 'https://app.powerbi.com/reportEmbed?reportId=8bfa5689-bc70-4027-a57d-98100119ab29&groupId=fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsibW9kZXJuRW1iZWQiOnRydWUsImFuZ3VsYXJPbmx5UmVwb3J0RW1iZWQiOnRydWUsImNlcnRpZmllZFRlbGVtZXRyeUVtYmVkIjp0cnVlLCJ1c2FnZU1ldHJpY3NWTmV4dCI6dHJ1ZSwic2tpcFpvbmVQYXRjaCI6dHJ1ZX19',
  },
  newmarketing: {
    groupid: "aa196a41-662c-47d5-a998-e40c7dad64c3",
    reportid: "c6c4c436-c5de-445b-b23e-1c8fbbc24457",
    url: "https://app.powerbi.com/reportEmbed?reportId=c6c4c436-c5de-445b-b23e-1c8fbbc24457&groupId=aa196a41-662c-47d5-a998-e40c7dad64c3&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsidXNhZ2VNZXRyaWNzVk5leHQiOnRydWUsImRpc2FibGVBbmd1bGFySlNCb290c3RyYXBSZXBvcnRFbWJlZCI6dHJ1ZX19",
  },
  psidaily: {
    groupid: 'fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2',
    reportid: '9fc2cabd-c713-4ff5-a26f-e114d7b0e915',
    url: 'https://app.powerbi.com/reportEmbed?reportId=9fc2cabd-c713-4ff5-a26f-e114d7b0e915&groupId=fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsibW9kZXJuRW1iZWQiOnRydWUsImFuZ3VsYXJPbmx5UmVwb3J0RW1iZWQiOnRydWUsImNlcnRpZmllZFRlbGVtZXRyeUVtYmVkIjp0cnVlLCJ1c2FnZU1ldHJpY3NWTmV4dCI6dHJ1ZSwic2tpcFpvbmVQYXRjaCI6dHJ1ZX19',
  },
  Newpsidaily: {
    groupid: 'aa196a41-662c-47d5-a998-e40c7dad64c3',
    reportid: '2f898643-8fb2-4d78-af65-3364c66e1489',
    url: 'https://app.powerbi.com/reportEmbed?reportId=2f898643-8fb2-4d78-af65-3364c66e1489&groupId=aa196a41-662c-47d5-a998-e40c7dad64c3&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsibW9kZXJuRW1iZWQiOnRydWUsInVzYWdlTWV0cmljc1ZOZXh0Ijp0cnVlfX0%3d',
  },
  saleslistingmanager: {
    groupid: 'fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2',
    reportid: '34e7829b-ed37-4501-b6c1-4bccdcb812c6',
    url: 'https://app.powerbi.com/reportEmbed?reportId=34e7829b-ed37-4501-b6c1-4bccdcb812c6&groupId=fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsibW9kZXJuRW1iZWQiOnRydWUsImFuZ3VsYXJPbmx5UmVwb3J0RW1iZWQiOnRydWUsImNlcnRpZmllZFRlbGVtZXRyeUVtYmVkIjp0cnVlLCJ1c2FnZU1ldHJpY3NWTmV4dCI6dHJ1ZSwic2tpcFpvbmVQYXRjaCI6dHJ1ZX19',
  },
  newsaleslistingmanager: {
    groupid: 'fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2',
    reportid: '62302fde-3c11-450e-b25d-308f7e1838c8',
    url: 'https://app.powerbi.com/reportEmbed?reportId=62302fde-3c11-450e-b25d-308f7e1838c8&groupId=fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsibW9kZXJuRW1iZWQiOnRydWUsInVzYWdlTWV0cmljc1ZOZXh0Ijp0cnVlfX0%3d',
  },
  SalesListingUnitsDate: {
    groupid: 'aa196a41-662c-47d5-a998-e40c7dad64c3',
    reportid: '8981b2c6-fab0-48b2-9952-ae62cca006ad',
    url: 'https://app.powerbi.com/reportEmbed?reportId=8981b2c6-fab0-48b2-9952-ae62cca006ad&groupId=aa196a41-662c-47d5-a998-e40c7dad64c3&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsidXNhZ2VNZXRyaWNzVk5leHQiOnRydWUsImRpc2FibGVBbmd1bGFySlNCb290c3RyYXBSZXBvcnRFbWJlZCI6dHJ1ZX19',
  },
  LeaseListingUnitsDate: {
    groupid: 'aa196a41-662c-47d5-a998-e40c7dad64c3',
    reportid: 'd1b40c5d-eb86-4bc9-b35e-2aa003786708',
    url: 'https://app.powerbi.com/reportEmbed?reportId=d1b40c5d-eb86-4bc9-b35e-2aa003786708&groupId=aa196a41-662c-47d5-a998-e40c7dad64c3&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsidXNhZ2VNZXRyaWNzVk5leHQiOnRydWUsImRpc2FibGVBbmd1bGFySlNCb290c3RyYXBSZXBvcnRFbWJlZCI6dHJ1ZX19',
  },
  leaselistingmanager: {
    groupid: 'fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2',
    reportid: 'f923e598-53d8-4195-ac75-25e6c90ce3ed',
    url: 'https://app.powerbi.com/reportEmbed?reportId=ff378a3e-c53d-4211-b77a-9d3630ddb4d7&groupId=fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsibW9kZXJuRW1iZWQiOnRydWUsImFuZ3VsYXJPbmx5UmVwb3J0RW1iZWQiOnRydWUsImNlcnRpZmllZFRlbGVtZXRyeUVtYmVkIjp0cnVlLCJ1c2FnZU1ldHJpY3NWTmV4dCI6dHJ1ZSwic2tpcFpvbmVQYXRjaCI6dHJ1ZX19',
  },
  Newleaselistingmanager: {
    groupid: 'fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2',
    reportid: 'd310adc4-99e0-4d28-af32-d3d854624658',
    url: 'https://app.powerbi.com/reportEmbed?reportId=d310adc4-99e0-4d28-af32-d3d854624658&groupId=fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsibW9kZXJuRW1iZWQiOnRydWUsImFuZ3VsYXJPbmx5UmVwb3J0RW1iZWQiOnRydWUsImNlcnRpZmllZFRlbGVtZXRyeUVtYmVkIjp0cnVlLCJ1c2FnZU1ldHJpY3NWTmV4dCI6dHJ1ZSwic2tpcFpvbmVQYXRjaCI6dHJ1ZX19',
  },
  accounts: {
    groupid: 'fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2',
    reportid: '74f57fe0-0b1c-4774-9707-2c91187c0efb',
    url: 'https://app.powerbi.com/reportEmbed?reportId=74f57fe0-0b1c-4774-9707-2c91187c0efb&groupId=fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsibW9kZXJuRW1iZWQiOnRydWUsImFuZ3VsYXJPbmx5UmVwb3J0RW1iZWQiOnRydWUsImNlcnRpZmllZFRlbGVtZXRyeUVtYmVkIjp0cnVlLCJ1c2FnZU1ldHJpY3NWTmV4dCI6dHJ1ZSwic2tpcFpvbmVQYXRjaCI6dHJ1ZX19',
  },
  intentionalsales: {
    groupid: 'aa196a41-662c-47d5-a998-e40c7dad64c3',
    reportid: 'e78e52e5-10df-4250-851e-d2d98c6c1049',
    url: 'https://app.powerbi.com/reportEmbed?reportId=e78e52e5-10df-4250-851e-d2d98c6c1049&groupId=aa196a41-662c-47d5-a998-e40c7dad64c3&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsibW9kZXJuRW1iZWQiOnRydWUsInVzYWdlTWV0cmljc1ZOZXh0Ijp0cnVlfX0%3d',
  },
  dubai_lease_listing_admin: {
    groupid: 'fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2',
    reportid: '6f4bc7fa-d9c7-42dd-b3b3-afeb0021ce9d',
    url: 'https://app.powerbi.com/reportEmbed?reportId=6f4bc7fa-d9c7-42dd-b3b3-afeb0021ce9d&groupId=fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsibW9kZXJuRW1iZWQiOnRydWUsInVzYWdlTWV0cmljc1ZOZXh0Ijp0cnVlLCJza2lwUXVlcnlEYXRhU2FhU0VtYmVkIjp0cnVlLCJza2lwUXVlcnlEYXRhUGFhU0VtYmVkIjp0cnVlLCJza2lwUXVlcnlEYXRhRXhwb3J0VG8iOnRydWV9fQ%3d%3d',
  },
  dubai_lease_listing_agent: {
    groupid: 'fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2',
    reportid: 'c6dfbed6-2122-4590-ae8a-a272fd4b42e5',
    url: 'https://app.powerbi.com/reportEmbed?reportId=c6dfbed6-2122-4590-ae8a-a272fd4b42e5&groupId=fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsibW9kZXJuRW1iZWQiOnRydWUsInVzYWdlTWV0cmljc1ZOZXh0Ijp0cnVlLCJza2lwUXVlcnlEYXRhU2FhU0VtYmVkIjp0cnVlLCJza2lwUXVlcnlEYXRhUGFhU0VtYmVkIjp0cnVlLCJza2lwUXVlcnlEYXRhRXhwb3J0VG8iOnRydWV9fQ%3d%3d',
  },
  dubai_sales_listing_admin: {
    groupid: 'fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2',
    reportid: '98da1945-916c-495e-9f4a-93b751c67788',
    url: 'https://app.powerbi.com/reportEmbed?reportId=98da1945-916c-495e-9f4a-93b751c67788&groupId=fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsibW9kZXJuRW1iZWQiOnRydWUsInVzYWdlTWV0cmljc1ZOZXh0Ijp0cnVlLCJza2lwUXVlcnlEYXRhU2FhU0VtYmVkIjp0cnVlLCJza2lwUXVlcnlEYXRhUGFhU0VtYmVkIjp0cnVlLCJza2lwUXVlcnlEYXRhRXhwb3J0VG8iOnRydWV9fQ%3d%3d',
  },
  dubai_sales_listing_agent: {
    groupid: 'fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2',
    reportid: 'a4ef57e8-568f-4eea-ad5e-97261b8a05b5',
    url: 'https://app.powerbi.com/reportEmbed?reportId=a4ef57e8-568f-4eea-ad5e-97261b8a05b5&groupId=fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsibW9kZXJuRW1iZWQiOnRydWUsInVzYWdlTWV0cmljc1ZOZXh0Ijp0cnVlLCJza2lwUXVlcnlEYXRhU2FhU0VtYmVkIjp0cnVlLCJza2lwUXVlcnlEYXRhUGFhU0VtYmVkIjp0cnVlLCJza2lwUXVlcnlEYXRhRXhwb3J0VG8iOnRydWV9fQ%3d%3d',
  },
  tab_1: {
    groupid: 'fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2',
    reportid: 'fd1f176f-0d01-4a24-91da-7076cb0d5846',
    url: 'https://app.powerbi.com/reportEmbed?reportId=fd1f176f-0d01-4a24-91da-7076cb0d5846&groupId=fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsibW9kZXJuRW1iZWQiOnRydWUsInVzYWdlTWV0cmljc1ZOZXh0Ijp0cnVlLCJza2lwUXVlcnlEYXRhU2FhU0VtYmVkIjp0cnVlLCJza2lwUXVlcnlEYXRhUGFhU0VtYmVkIjp0cnVlLCJza2lwUXVlcnlEYXRhRXhwb3J0VG8iOnRydWV9fQ%3d%3d',
  },
  tab_2: {
    groupid: 'fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2',
    reportid: 'b89a7df7-2029-4757-b8d1-3244fb75ab8c',
    url: 'https://app.powerbi.com/reportEmbed?reportId=b89a7df7-2029-4757-b8d1-3244fb75ab8c&groupId=fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsibW9kZXJuRW1iZWQiOnRydWUsInVzYWdlTWV0cmljc1ZOZXh0Ijp0cnVlLCJza2lwUXVlcnlEYXRhU2FhU0VtYmVkIjp0cnVlLCJza2lwUXVlcnlEYXRhUGFhU0VtYmVkIjp0cnVlLCJza2lwUXVlcnlEYXRhRXhwb3J0VG8iOnRydWV9fQ%3d%3d',
  },
  tab_3: {
    groupid: 'fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2',
    reportid: '8d99ee48-042c-4853-977b-bbc587770ac2',
    url: 'https://app.powerbi.com/reportEmbed?reportId=8d99ee48-042c-4853-977b-bbc587770ac2&groupId=fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsibW9kZXJuRW1iZWQiOnRydWUsInVzYWdlTWV0cmljc1ZOZXh0Ijp0cnVlLCJza2lwUXVlcnlEYXRhU2FhU0VtYmVkIjp0cnVlLCJza2lwUXVlcnlEYXRhUGFhU0VtYmVkIjp0cnVlLCJza2lwUXVlcnlEYXRhRXhwb3J0VG8iOnRydWV9fQ%3d%3d',
  },
  tab_4: {
    groupid: 'fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2',
    reportid: 'f00d74ab-163f-450c-99df-46eb77473bd8',
    url: 'https://app.powerbi.com/reportEmbed?reportId=f00d74ab-163f-450c-99df-46eb77473bd8&groupId=fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsibW9kZXJuRW1iZWQiOnRydWUsInVzYWdlTWV0cmljc1ZOZXh0Ijp0cnVlLCJza2lwUXVlcnlEYXRhU2FhU0VtYmVkIjp0cnVlLCJza2lwUXVlcnlEYXRhUGFhU0VtYmVkIjp0cnVlLCJza2lwUXVlcnlEYXRhRXhwb3J0VG8iOnRydWV9fQ%3d%3d',
  },
  tab_5: {
    groupid: 'fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2',
    reportid: 'f00618e5-7112-4a41-a2d4-709a7c1a3ed3',
    url: 'https://app.powerbi.com/reportEmbed?reportId=f00618e5-7112-4a41-a2d4-709a7c1a3ed3&groupId=fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsibW9kZXJuRW1iZWQiOnRydWUsInVzYWdlTWV0cmljc1ZOZXh0Ijp0cnVlLCJza2lwUXVlcnlEYXRhU2FhU0VtYmVkIjp0cnVlLCJza2lwUXVlcnlEYXRhUGFhU0VtYmVkIjp0cnVlLCJza2lwUXVlcnlEYXRhRXhwb3J0VG8iOnRydWV9fQ%3d%3d',
  },
  tab_6: {
    groupid: 'fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2',
    reportid: '4d2414a3-456e-4a2a-b86d-798225f5eaa5',
    url: 'https://app.powerbi.com/reportEmbed?reportId=4d2414a3-456e-4a2a-b86d-798225f5eaa5&groupId=fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsibW9kZXJuRW1iZWQiOnRydWUsInVzYWdlTWV0cmljc1ZOZXh0Ijp0cnVlLCJza2lwUXVlcnlEYXRhU2FhU0VtYmVkIjp0cnVlLCJza2lwUXVlcnlEYXRhUGFhU0VtYmVkIjp0cnVlLCJza2lwUXVlcnlEYXRhRXhwb3J0VG8iOnRydWV9fQ%3d%3d',
  },
  tab_7: {
    groupid: 'fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2',
    reportid: 'fdfc7e38-bc6d-437e-83d0-c777e0192e98',
    url: 'https://app.powerbi.com/reportEmbed?reportId=fdfc7e38-bc6d-437e-83d0-c777e0192e98&groupId=fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsibW9kZXJuRW1iZWQiOnRydWUsInVzYWdlTWV0cmljc1ZOZXh0Ijp0cnVlLCJza2lwUXVlcnlEYXRhU2FhU0VtYmVkIjp0cnVlLCJza2lwUXVlcnlEYXRhUGFhU0VtYmVkIjp0cnVlLCJza2lwUXVlcnlEYXRhRXhwb3J0VG8iOnRydWV9fQ%3d%3d',
  },
  tab_8: {
    groupid: 'fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2',
    reportid: '3cb4ef98-043e-49bb-8c7f-9dfde1d493af',
    url: 'https://app.powerbi.com/reportEmbed?reportId=3cb4ef98-043e-49bb-8c7f-9dfde1d493af&groupId=fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsibW9kZXJuRW1iZWQiOnRydWUsInVzYWdlTWV0cmljc1ZOZXh0Ijp0cnVlLCJza2lwUXVlcnlEYXRhU2FhU0VtYmVkIjp0cnVlLCJza2lwUXVlcnlEYXRhUGFhU0VtYmVkIjp0cnVlLCJza2lwUXVlcnlEYXRhRXhwb3J0VG8iOnRydWV9fQ%3d%3d',
  },
  tab_9: {
    groupid: 'fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2',
    reportid: '85ad52b7-bd56-4f81-9d8b-5e35d2af7fa7',
    url: 'https://app.powerbi.com/reportEmbed?reportId=85ad52b7-bd56-4f81-9d8b-5e35d2af7fa7&groupId=fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsibW9kZXJuRW1iZWQiOnRydWUsInVzYWdlTWV0cmljc1ZOZXh0Ijp0cnVlLCJza2lwUXVlcnlEYXRhU2FhU0VtYmVkIjp0cnVlLCJza2lwUXVlcnlEYXRhUGFhU0VtYmVkIjp0cnVlLCJza2lwUXVlcnlEYXRhRXhwb3J0VG8iOnRydWV9fQ%3d%3d',
  },
  tab_10: {
    groupid: 'fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2',
    reportid: '3277b670-1f6e-4d21-b60d-5beb2fabb925',
    url: 'https://app.powerbi.com/reportEmbed?reportId=3277b670-1f6e-4d21-b60d-5beb2fabb925&groupId=fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsibW9kZXJuRW1iZWQiOnRydWUsInVzYWdlTWV0cmljc1ZOZXh0Ijp0cnVlLCJza2lwUXVlcnlEYXRhU2FhU0VtYmVkIjp0cnVlLCJza2lwUXVlcnlEYXRhUGFhU0VtYmVkIjp0cnVlLCJza2lwUXVlcnlEYXRhRXhwb3J0VG8iOnRydWV9fQ%3d%3d',
  },
  MarketingClientsSegmentation: {
    groupid: 'aa196a41-662c-47d5-a998-e40c7dad64c3',
    reportid: '0a7e1274-7eb3-41e4-a9d9-032c555ecb3c',
    url: 'https://app.powerbi.com/reportEmbed?reportId=0a7e1274-7eb3-41e4-a9d9-032c555ecb3c&groupId=aa196a41-662c-47d5-a998-e40c7dad64c3&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsidXNhZ2VNZXRyaWNzVk5leHQiOnRydWUsImRpc2FibGVBbmd1bGFySlNCb290c3RyYXBSZXBvcnRFbWJlZCI6dHJ1ZX19',
  },
  Branches: {
    groupid: 'aa196a41-662c-47d5-a998-e40c7dad64c3',
    reportid: 'eb28fd29-1be4-4e30-bdbf-7c79fb91d7d5',
    url: 'https://app.powerbi.com/reportEmbed?reportId=eb28fd29-1be4-4e30-bdbf-7c79fb91d7d5&groupId=aa196a41-662c-47d5-a998-e40c7dad64c3&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsibW9kZXJuRW1iZWQiOnRydWUsInVzYWdlTWV0cmljc1ZOZXh0Ijp0cnVlfX0%3d',
  },
  Salesdirector: {
    groupid: 'fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2',
    reportid: '6ca9e9db-9a0b-40f1-9262-82380d5a387b',
    url: 'https://app.powerbi.com/reportEmbed?reportId=6ca9e9db-9a0b-40f1-9262-82380d5a387b&groupId=fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsibW9kZXJuRW1iZWQiOnRydWUsInVzYWdlTWV0cmljc1ZOZXh0Ijp0cnVlLCJza2lwUXVlcnlEYXRhU2FhU0VtYmVkIjp0cnVlLCJza2lwUXVlcnlEYXRhUGFhU0VtYmVkIjp0cnVlLCJza2lwUXVlcnlEYXRhRXhwb3J0VG8iOnRydWV9fQ%3d%3d',
  },
  Leasedirector: {
    groupid: 'fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2',
    reportid: 'bb5e7954-b3cc-4a5f-aeb7-1d4930a2dc73',
    url: 'https://app.powerbi.com/reportEmbed?reportId=bb5e7954-b3cc-4a5f-aeb7-1d4930a2dc73&groupId=fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsibW9kZXJuRW1iZWQiOnRydWUsInVzYWdlTWV0cmljc1ZOZXh0Ijp0cnVlLCJza2lwUXVlcnlEYXRhU2FhU0VtYmVkIjp0cnVlLCJza2lwUXVlcnlEYXRhUGFhU0VtYmVkIjp0cnVlLCJza2lwUXVlcnlEYXRhRXhwb3J0VG8iOnRydWV9fQ%3d%3d',
  },
  Leasedetails: {
    groupid: 'fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2',
    reportid: '8cbbf2b5-086d-4ea7-b17b-e355f4e48b2d',
    url: 'https://app.powerbi.com/reportEmbed?reportId=8cbbf2b5-086d-4ea7-b17b-e355f4e48b2d&groupId=fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsibW9kZXJuRW1iZWQiOnRydWUsInVzYWdlTWV0cmljc1ZOZXh0Ijp0cnVlLCJza2lwUXVlcnlEYXRhU2FhU0VtYmVkIjp0cnVlLCJza2lwUXVlcnlEYXRhUGFhU0VtYmVkIjp0cnVlLCJza2lwUXVlcnlEYXRhRXhwb3J0VG8iOnRydWV9fQ%3d%3d',
  },
  LeadOwner: {
    groupid: 'aa196a41-662c-47d5-a998-e40c7dad64c3',
    reportid: 'c286d39b-459b-4f3c-a204-7851e72056ee',
    url: 'https://app.powerbi.com/reportEmbed?reportId=c286d39b-459b-4f3c-a204-7851e72056ee&groupId=aa196a41-662c-47d5-a998-e40c7dad64c3&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsidXNhZ2VNZXRyaWNzVk5leHQiOnRydWV9fQ%3d%3d',
  },
  UserLogin: {
    groupid: 'fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2',
    reportid: '38951a74-53ae-4742-8707-478880e41cde',
    url: 'https://app.powerbi.com/reportEmbed?reportId=38951a74-53ae-4742-8707-478880e41cde&groupId=fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsibW9kZXJuRW1iZWQiOnRydWUsInVzYWdlTWV0cmljc1ZOZXh0Ijp0cnVlfX0%3d',
  },
  ActivatedLeadid: {
    groupid: 'aa196a41-662c-47d5-a998-e40c7dad64c3',
    reportid: 'b8124d93-ba1d-4eab-b40f-492dae25570d',
    url: 'https://app.powerbi.com/reportEmbed?reportId=b8124d93-ba1d-4eab-b40f-492dae25570d&groupId=aa196a41-662c-47d5-a998-e40c7dad64c3&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsibW9kZXJuRW1iZWQiOnRydWUsInVzYWdlTWV0cmljc1ZOZXh0Ijp0cnVlfX0%3d',
  },
  CustomersRisk: {
    groupid: 'aa196a41-662c-47d5-a998-e40c7dad64c3',
    reportid: '61e7190f-53a0-4af1-a315-a79ecf011a6b',
    url: 'https://app.powerbi.com/reportEmbed?reportId=61e7190f-53a0-4af1-a315-a79ecf011a6b&groupId=aa196a41-662c-47d5-a998-e40c7dad64c3&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsibW9kZXJuRW1iZWQiOnRydWUsInVzYWdlTWV0cmljc1ZOZXh0Ijp0cnVlfX0%3d',
  },
  PropertyFinder: {
    groupid: 'aa196a41-662c-47d5-a998-e40c7dad64c3',
    reportid: 'd3ccbcbb-39de-4ede-91bb-b18ad6993561',
    url: 'https://app.powerbi.com/reportEmbed?reportId=d3ccbcbb-39de-4ede-91bb-b18ad6993561&groupId=aa196a41-662c-47d5-a998-e40c7dad64c3&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsibW9kZXJuRW1iZWQiOnRydWUsInVzYWdlTWV0cmljc1ZOZXh0Ijp0cnVlfX0%3d',
  },
  Bayut: {
    groupid: 'aa196a41-662c-47d5-a998-e40c7dad64c3',
    reportid: '637bb277-6f53-4b70-b7b4-20791a8bc9e2',
    url: 'https://app.powerbi.com/reportEmbed?reportId=637bb277-6f53-4b70-b7b4-20791a8bc9e2&groupId=aa196a41-662c-47d5-a998-e40c7dad64c3&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsibW9kZXJuRW1iZWQiOnRydWUsInVzYWdlTWV0cmljc1ZOZXh0Ijp0cnVlfX0%3d',
  },
  SalesAgentplus: {
    groupid: 'aa196a41-662c-47d5-a998-e40c7dad64c3',
    reportid: 'd90ad0bc-acc3-4849-bed6-e2385e231adb',
    url: 'https://app.powerbi.com/reportEmbed?reportId=d90ad0bc-acc3-4849-bed6-e2385e231adb&groupId=aa196a41-662c-47d5-a998-e40c7dad64c3&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsibW9kZXJuRW1iZWQiOnRydWUsInVzYWdlTWV0cmljc1ZOZXh0Ijp0cnVlfX0%3d',
  },
  SalesTeamLeadplus: {
    groupid: 'aa196a41-662c-47d5-a998-e40c7dad64c3',
    reportid: '027fe68c-035e-4687-a0f2-aef06384cd71',
    url: 'https://app.powerbi.com/reportEmbed?reportId=027fe68c-035e-4687-a0f2-aef06384cd71&groupId=aa196a41-662c-47d5-a998-e40c7dad64c3&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsibW9kZXJuRW1iZWQiOnRydWUsInVzYWdlTWV0cmljc1ZOZXh0Ijp0cnVlLCJkaXNhYmxlQW5ndWxhckpTQm9vdHN0cmFwUmRsRW1iZWQiOnRydWV9fQ%3d%3d',
  },
  SalesDirectorplus: {
    groupid: 'aa196a41-662c-47d5-a998-e40c7dad64c3',
    reportid: '4bf6eb0a-7782-4765-9817-64692c74ad68',
    url: 'https://app.powerbi.com/reportEmbed?reportId=4bf6eb0a-7782-4765-9817-64692c74ad68&groupId=aa196a41-662c-47d5-a998-e40c7dad64c3&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsibW9kZXJuRW1iZWQiOnRydWUsInVzYWdlTWV0cmljc1ZOZXh0Ijp0cnVlLCJkaXNhYmxlQW5ndWxhckpTQm9vdHN0cmFwUmRsRW1iZWQiOnRydWV9fQ%3d%3d',
  },
  LeaseDirectorplus: {
    groupid: 'aa196a41-662c-47d5-a998-e40c7dad64c3',
    reportid: 'a80195b4-3bc6-47a3-a4fb-01ab7504728f',
    url: 'https://app.powerbi.com/reportEmbed?reportId=a80195b4-3bc6-47a3-a4fb-01ab7504728f&groupId=aa196a41-662c-47d5-a998-e40c7dad64c3&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsibW9kZXJuRW1iZWQiOnRydWUsInVzYWdlTWV0cmljc1ZOZXh0Ijp0cnVlLCJkaXNhYmxlQW5ndWxhckpTQm9vdHN0cmFwUmRsRW1iZWQiOnRydWV9fQ%3d%3d',
  },
  SalesDirectorplus_Date: {
    groupid: 'aa196a41-662c-47d5-a998-e40c7dad64c3',
    reportid: '666a1548-0abe-4fdc-b5d2-0b55d1713a4f',
    url: 'https://app.powerbi.com/reportEmbed?reportId=666a1548-0abe-4fdc-b5d2-0b55d1713a4f&groupId=aa196a41-662c-47d5-a998-e40c7dad64c3&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsidXNhZ2VNZXRyaWNzVk5leHQiOnRydWV9fQ%3d%3d',
  },
  
  LeaseDirectorplus_Date: {
    groupid: 'aa196a41-662c-47d5-a998-e40c7dad64c3',
    reportid: '57eae417-ddd1-40e5-896e-78de815b101b',
    url: 'https://app.powerbi.com/reportEmbed?reportId=57eae417-ddd1-40e5-896e-78de815b101b&groupId=aa196a41-662c-47d5-a998-e40c7dad64c3&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsidXNhZ2VNZXRyaWNzVk5leHQiOnRydWV9fQ%3d%3d',
  },
  LeaseAgentplus: {
    groupid: 'aa196a41-662c-47d5-a998-e40c7dad64c3',
    reportid: '6fa91352-8814-468e-8fe5-a86c521490ab',
    url: 'https://app.powerbi.com/reportEmbed?reportId=6fa91352-8814-468e-8fe5-a86c521490ab&groupId=aa196a41-662c-47d5-a998-e40c7dad64c3&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsibW9kZXJuRW1iZWQiOnRydWUsInVzYWdlTWV0cmljc1ZOZXh0Ijp0cnVlLCJkaXNhYmxlQW5ndWxhckpTQm9vdHN0cmFwUmRsRW1iZWQiOnRydWV9fQ%3d%3d',
  },
  LeaseTeamLeadplus: {
    groupid: 'aa196a41-662c-47d5-a998-e40c7dad64c3',
    reportid: '9f997d93-92fa-453f-8ac0-bfae0e03df92',
    url: 'https://app.powerbi.com/reportEmbed?reportId=9f997d93-92fa-453f-8ac0-bfae0e03df92&groupId=aa196a41-662c-47d5-a998-e40c7dad64c3&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsibW9kZXJuRW1iZWQiOnRydWUsInVzYWdlTWV0cmljc1ZOZXh0Ijp0cnVlLCJkaXNhYmxlQW5ndWxhckpTQm9vdHN0cmFwUmRsRW1iZWQiOnRydWV9fQ%3d%3d',
  },
  SalesTeamLeadDate: {
    groupid: 'aa196a41-662c-47d5-a998-e40c7dad64c3',
    reportid: 'a3d455de-d171-4a1a-86b1-e82df59a3d1d',
    url: 'https://app.powerbi.com/reportEmbed?reportId=a3d455de-d171-4a1a-86b1-e82df59a3d1d&groupId=aa196a41-662c-47d5-a998-e40c7dad64c3&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsidXNhZ2VNZXRyaWNzVk5leHQiOnRydWV9fQ%3d%3d',
  },
  LeaseTeamLeadDate: {
    groupid: 'aa196a41-662c-47d5-a998-e40c7dad64c3',
    reportid: 'e7b91b06-cffe-4ed6-8669-eb88c31b2772',
    url: 'https://app.powerbi.com/reportEmbed?reportId=e7b91b06-cffe-4ed6-8669-eb88c31b2772&groupId=aa196a41-662c-47d5-a998-e40c7dad64c3&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsidXNhZ2VNZXRyaWNzVk5leHQiOnRydWV9fQ%3d%3d',
  },
  RotationScheme: {
    groupid: 'aa196a41-662c-47d5-a998-e40c7dad64c3',
    reportid: 'f644dd0c-a4e3-44b5-983f-45661d1851fb',
    url: 'https://app.powerbi.com/reportEmbed?reportId=f644dd0c-a4e3-44b5-983f-45661d1851fb&groupId=aa196a41-662c-47d5-a998-e40c7dad64c3&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsidXNhZ2VNZXRyaWNzVk5leHQiOnRydWV9fQ%3d%3d',
  },
  CampaignCost: {
    groupid: 'aa196a41-662c-47d5-a998-e40c7dad64c3',
    reportid: '08cab506-333d-442d-ac1f-da05d9b66392',
    url: 'https://app.powerbi.com/reportEmbed?reportId=08cab506-333d-442d-ac1f-da05d9b66392&groupId=aa196a41-662c-47d5-a998-e40c7dad64c3&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsidXNhZ2VNZXRyaWNzVk5leHQiOnRydWV9fQ%3d%3d',
  },
  SegmentationLeadBranch: {
    groupid: 'aa196a41-662c-47d5-a998-e40c7dad64c3',
    reportid: '4076ea50-943f-4ebc-a2e5-969636c267ad',
    url: 'https://app.powerbi.com/reportEmbed?reportId=4076ea50-943f-4ebc-a2e5-969636c267ad&groupId=aa196a41-662c-47d5-a998-e40c7dad64c3&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsidXNhZ2VNZXRyaWNzVk5leHQiOnRydWUsImRpc2FibGVBbmd1bGFySlNCb290c3RyYXBSZXBvcnRFbWJlZCI6dHJ1ZX19',
  },
  SegmentationContactBranch: {
    groupid: 'aa196a41-662c-47d5-a998-e40c7dad64c3',
    reportid: '142360bb-5681-4724-b6d5-a9d41a66af94',
    url: 'https://app.powerbi.com/reportEmbed?reportId=142360bb-5681-4724-b6d5-a9d41a66af94&groupId=aa196a41-662c-47d5-a998-e40c7dad64c3&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsidXNhZ2VNZXRyaWNzVk5leHQiOnRydWUsImRpc2FibGVBbmd1bGFySlNCb290c3RyYXBSZXBvcnRFbWJlZCI6dHJ1ZX19',
  },
  SegmentationOwnerBranch: {
    groupid: 'aa196a41-662c-47d5-a998-e40c7dad64c3',
    reportid: '76b6f561-e683-4fd5-ae4d-3a27c5a5a98a',
    url: 'https://app.powerbi.com/reportEmbed?reportId=76b6f561-e683-4fd5-ae4d-3a27c5a5a98a&groupId=aa196a41-662c-47d5-a998-e40c7dad64c3&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsidXNhZ2VNZXRyaWNzVk5leHQiOnRydWUsImRpc2FibGVBbmd1bGFySlNCb290c3RyYXBSZXBvcnRFbWJlZCI6dHJ1ZX19',
  },
  callCenterDate: {
    groupid: 'aa196a41-662c-47d5-a998-e40c7dad64c3',
    reportid: 'a42217aa-5949-47de-bef9-0982f51e1774',
    url: 'https://app.powerbi.com/reportEmbed?reportId=a42217aa-5949-47de-bef9-0982f51e1774&groupId=aa196a41-662c-47d5-a998-e40c7dad64c3&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsidXNhZ2VNZXRyaWNzVk5leHQiOnRydWUsImRpc2FibGVBbmd1bGFySlNCb290c3RyYXBSZXBvcnRFbWJlZCI6dHJ1ZX19',
  },
  LeadAutomation: {
    groupid: 'aa196a41-662c-47d5-a998-e40c7dad64c3',
    reportid: 'cb02ac48-0c73-4da2-9a7c-ba87e6a8b267',
    url: 'https://app.powerbi.com/reportEmbed?reportId=cb02ac48-0c73-4da2-9a7c-ba87e6a8b267&groupId=aa196a41-662c-47d5-a998-e40c7dad64c3&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsidXNhZ2VNZXRyaWNzVk5leHQiOnRydWUsImRpc2FibGVBbmd1bGFySlNCb290c3RyYXBSZXBvcnRFbWJlZCI6dHJ1ZX19',
  },
  UnqualifiedLeads: {
    groupid: 'aa196a41-662c-47d5-a998-e40c7dad64c3',
    reportid: '30e9dce1-751a-4e5a-a79f-7089de75290f',
    url: 'https://app.powerbi.com/reportEmbed?reportId=30e9dce1-751a-4e5a-a79f-7089de75290f&groupId=aa196a41-662c-47d5-a998-e40c7dad64c3&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsidXNhZ2VNZXRyaWNzVk5leHQiOnRydWUsImRpc2FibGVBbmd1bGFySlNCb290c3RyYXBSZXBvcnRFbWJlZCI6dHJ1ZX19',
  },
  InternationalSalesYTDMTD: {
    groupid: 'aa196a41-662c-47d5-a998-e40c7dad64c3',
    reportid: '697e1b63-db2f-4127-b620-bc3f7b3a7906',
    url: 'https://app.powerbi.com/reportEmbed?reportId=697e1b63-db2f-4127-b620-bc3f7b3a7906&groupId=aa196a41-662c-47d5-a998-e40c7dad64c3&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsidXNhZ2VNZXRyaWNzVk5leHQiOnRydWUsImRpc2FibGVBbmd1bGFySlNCb290c3RyYXBSZXBvcnRFbWJlZCI6dHJ1ZX19',
  },
  UsersDetails: {
    groupid: 'aa196a41-662c-47d5-a998-e40c7dad64c3',
    reportid: '7c780501-1f32-40c9-9026-7412102585c7',
    url: 'https://app.powerbi.com/reportEmbed?reportId=7c780501-1f32-40c9-9026-7412102585c7&groupId=aa196a41-662c-47d5-a998-e40c7dad64c3&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsidXNhZ2VNZXRyaWNzVk5leHQiOnRydWUsImRpc2FibGVBbmd1bGFySlNCb290c3RyYXBSZXBvcnRFbWJlZCI6dHJ1ZX19',
  },
  PortalsCampaignChart: {
    groupid: 'aa196a41-662c-47d5-a998-e40c7dad64c3',
    reportid: '99ae0358-11e3-4a1c-a55f-37818bc82203',
    url: 'https://app.powerbi.com/reportEmbed?reportId=99ae0358-11e3-4a1c-a55f-37818bc82203&groupId=aa196a41-662c-47d5-a998-e40c7dad64c3&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsidXNhZ2VNZXRyaWNzVk5leHQiOnRydWUsImRpc2FibGVBbmd1bGFySlNCb290c3RyYXBSZXBvcnRFbWJlZCI6dHJ1ZX19',
  },
  ListingAllUnits: {
    groupid: 'aa196a41-662c-47d5-a998-e40c7dad64c3',
    reportid: '155aae7e-ecd3-4cf4-bd08-9bcbe52d9f00',
    url: 'https://app.powerbi.com/reportEmbed?reportId=155aae7e-ecd3-4cf4-bd08-9bcbe52d9f00&groupId=aa196a41-662c-47d5-a998-e40c7dad64c3&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsidXNhZ2VNZXRyaWNzVk5leHQiOnRydWUsImRpc2FibGVBbmd1bGFySlNCb290c3RyYXBSZXBvcnRFbWJlZCI6dHJ1ZX19',
  },
  AML: {
    groupid: 'aa196a41-662c-47d5-a998-e40c7dad64c3',
    reportid: 'd9176f7d-a2f7-413a-8e4e-871186e5d2d8',
    url: 'https://app.powerbi.com/reportEmbed?reportId=d9176f7d-a2f7-413a-8e4e-871186e5d2d8&groupId=aa196a41-662c-47d5-a998-e40c7dad64c3&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsidXNhZ2VNZXRyaWNzVk5leHQiOnRydWV9fQ%3d%3d',
  },
  Projecttask: {
    groupid: 'aa196a41-662c-47d5-a998-e40c7dad64c3',
    reportid: '5dfc13e6-db85-4bc2-b22e-f5dac1ba7964',
    url: 'https://app.powerbi.com/reportEmbed?reportId=5dfc13e6-db85-4bc2-b22e-f5dac1ba7964&groupId=aa196a41-662c-47d5-a998-e40c7dad64c3&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsidXNhZ2VNZXRyaWNzVk5leHQiOnRydWV9fQ%3d%3d',
  },
  CampaignRequest: {
    groupid: 'aa196a41-662c-47d5-a998-e40c7dad64c3',
    reportid: 'd154cd86-f3c8-439a-a3e9-919c7fc87da7',
    url: 'https://app.powerbi.com/reportEmbed?reportId=d154cd86-f3c8-439a-a3e9-919c7fc87da7&groupId=aa196a41-662c-47d5-a998-e40c7dad64c3&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsidXNhZ2VNZXRyaWNzVk5leHQiOnRydWV9fQ%3d%3d',
  },
  LeadPool: {
    groupid: 'aa196a41-662c-47d5-a998-e40c7dad64c3',
    reportid: '091c01ad-956d-4a8a-aa40-1f8b6f89eaa8',
    url: 'https://app.powerbi.com/reportEmbed?reportId=091c01ad-956d-4a8a-aa40-1f8b6f89eaa8&groupId=aa196a41-662c-47d5-a998-e40c7dad64c3&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsidXNhZ2VNZXRyaWNzVk5leHQiOnRydWV9fQ%3d%3d',
  },
  LeadFromPool: {
    groupid: 'aa196a41-662c-47d5-a998-e40c7dad64c3',
    reportid: '257883c9-7aff-4081-a817-3d817d166eff',
    url: 'https://app.powerbi.com/reportEmbed?reportId=257883c9-7aff-4081-a817-3d817d166eff&groupId=aa196a41-662c-47d5-a998-e40c7dad64c3&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsidXNhZ2VNZXRyaWNzVk5leHQiOnRydWV9fQ%3d%3d',
  },
  AdminLeadFromPool: {
    groupid: 'aa196a41-662c-47d5-a998-e40c7dad64c3',
    reportid: 'fb27c7cc-5d9e-4d60-9f19-b5675a8a1f67',
    url: 'https://app.powerbi.com/reportEmbed?reportId=fb27c7cc-5d9e-4d60-9f19-b5675a8a1f67&groupId=aa196a41-662c-47d5-a998-e40c7dad64c3&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsidXNhZ2VNZXRyaWNzVk5leHQiOnRydWV9fQ%3d%3d',
  },
  zeromatching: {
    groupid: 'aa196a41-662c-47d5-a998-e40c7dad64c3',
    reportid: '2f1c76c6-a9c0-490c-a145-699d4a9a6db1',
    url: 'https://app.powerbi.com/reportEmbed?reportId=2f1c76c6-a9c0-490c-a145-699d4a9a6db1&groupId=aa196a41-662c-47d5-a998-e40c7dad64c3&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsidXNhZ2VNZXRyaWNzVk5leHQiOnRydWV9fQ%3d%3d',
  },
  developerProperty: {
    groupid: 'aa196a41-662c-47d5-a998-e40c7dad64c3',
    reportid: 'fde198fb-eabb-422c-867c-e43c8d355686',
    url: 'https://app.powerbi.com/reportEmbed?reportId=fde198fb-eabb-422c-867c-e43c8d355686&groupId=aa196a41-662c-47d5-a998-e40c7dad64c3&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsidXNhZ2VNZXRyaWNzVk5leHQiOnRydWV9fQ%3d%3d',
  },
  activity: {
    groupid: 'aa196a41-662c-47d5-a998-e40c7dad64c3',
    reportid: '9ae9e9ff-fba2-409e-bd35-c05755de6499',
    url: 'https://app.powerbi.com/reportEmbed?reportId=9ae9e9ff-fba2-409e-bd35-c05755de6499&groupId=aa196a41-662c-47d5-a998-e40c7dad64c3&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsidXNhZ2VNZXRyaWNzVk5leHQiOnRydWV9fQ%3d%3d',
  },
  Ai_Call: {
    groupid: 'aa196a41-662c-47d5-a998-e40c7dad64c3',
    reportid: 'd988920b-1a36-4cf7-874a-789535d37115',
    url: 'https://app.powerbi.com/reportEmbed?reportId=d988920b-1a36-4cf7-874a-789535d37115&groupId=aa196a41-662c-47d5-a998-e40c7dad64c3&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsidXNhZ2VNZXRyaWNzVk5leHQiOnRydWV9fQ%3d%3d',
  },
};