export const PortfolioVerticalTabsData = [
  { label: 'portfolio-details' },
  { label: 'properties' },
  { label: 'landlords' },
  { label: 'maintenance-contract' },
  { label: 'bank-accounts' },
  { label: 'documents' },
  { label: 'activities' },
  { label: 'operating-costs' },
];
