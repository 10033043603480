import React, { useEffect, useRef, useState } from 'react';
import { DialogComponent, Inputs, Tables } from '../../../../Components';
import { TableActions } from '../../../../Enums';
import headerData from './BillingHistoryHeaderData';
import {
  GetBranchBillingUsersDetails,
  GetBranchesBillingHistoryInfo,
} from '../../../../Services';
import { BranchUserDetails } from './BranchUserDetails';
import { useTranslation } from 'react-i18next';
import { GlobalHistory, returnPropsByPermissions } from '../../../../Helper';
import { InvoicePreviewDialog } from '../Sections/InvoicePreviewDialog';
import { BillingPermissions } from '../../../../Permissions';

const dialogStateInitialState = {
  isLoading: false,
  isOpen: false,
  row: {},
};

export const BillingHistory = ({ parentTranslationPath, translationPath }) => {
  const defaultActions = [
    { enum: TableActions.view.key },
    {
      enum: TableActions.users.key,
    },
  ];
  const { t } = useTranslation(parentTranslationPath);
  const [filter, setFilter] = useState({
    branchName: '',
    pageIndex: 1,
    pageSize: 25,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [dialogState, setDialogState] = useState(dialogStateInitialState);
  const [data, setData] = useState({ result: [], totalCount: 0 });
  const [branchUserDetails, setBranchUserDetails] = useState(null);
  const [previewDialogState, setPreviewDialogState] = useState({
    isOpen: false,
    branchId: null,
  });
  const timerRef = useRef();

  const actionsOptions = async (actionType, row) => {
    if (actionType === TableActions.users.key) {
      setDialogState({ isLoading: true, isOpen: true, row });
      const userDetails = await GetBranchBillingUsersDetails(row.branchId);
      setBranchUserDetails(userDetails);
      setDialogState((state) => ({ ...state, isLoading: false }));
    } else if (actionType === TableActions.view.key) {
      GlobalHistory.push(`/home/billing-users?branchId=${row.branchId}`);
    } else if (actionType === TableActions.PrintInvoice.key) {
      setPreviewDialogState({ isOpen: true, branchId: row.branchId });
    }
  };
  const onUserTypeClick = (type) => {
    GlobalHistory.push(
      `/home/billing-users?branchId=${dialogState.row.branchId}&isCRM=${!!type}`
    );
    setDialogState(dialogStateInitialState);
  };
  const onChange = (field, value) => {
    setFilter((filter) => ({ ...filter, [field]: value }));
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
    timerRef.current = setTimeout(() => {
      getBranchesBillingHistoryInfo(value);
    }, 700);
  };
  const getBranchesBillingHistoryInfo = async (branchName) => {
    setIsLoading(true);
    const result = await GetBranchesBillingHistoryInfo({
      ...filter,
      branchName,
    });
    if (!result.err) {
      setData(result);
    }
    setIsLoading(false);
  };

  const getActionTableWithPermissions = () => {
    if (
      returnPropsByPermissions(BillingPermissions.ShareInvoice.permissionsId) ||
      returnPropsByPermissions(BillingPermissions.PrintInvoice.permissionsId)
    ) {
      defaultActions.push({ enum: TableActions.PrintInvoice.key });
    }
    return defaultActions;
  };
  useEffect(() => {
    getBranchesBillingHistoryInfo();
  }, []);
  return (
    <div className='pt-3'>
      <div className='w-50 pl-3'>
        <Inputs
          inputPlaceholder={'Search by branch name'}
          onInputChanged={(e) => onChange('branchName', e.target.value)}
          value={filter.branchName}
          idRef='search_input'
        />
      </div>
      <Tables
        isLoading={isLoading}
        headerData={headerData}
        data={data.result}
        defaultActions={getActionTableWithPermissions()}
        isWithFilter
        actionsOptions={{ onActionClicked: actionsOptions }}
        parentTranslationPath={parentTranslationPath}
        translationPath={translationPath}
        totalItems={data.totalCount}
        itemsPerPage={filter.pageSize}
        activePage={filter.pageIndex - 1}
        onPageIndexChanged={(index) => onChange('pageIndex', index + 1)}
        onPageSizeChanged={(index) => onChange('pageSize', index)}
      />
      <DialogComponent
        isOpen={dialogState.isOpen}
        dialogTitle={t('user-details')}
        dialogContent={
          <BranchUserDetails
            userDetails={branchUserDetails}
            isLoading={dialogState.isLoading}
            onUserTypeClick={onUserTypeClick}
            parentTranslationPath={parentTranslationPath}
          />
        }
        saveType=''
        onCancelClicked={() =>
          setDialogState((state) => ({ ...state, isOpen: false }))
        }
        maxWidth='sm'
      />
      {previewDialogState.isOpen && (
        <InvoicePreviewDialog
          isOpen={previewDialogState.isOpen}
          branchId={previewDialogState.branchId}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          isForHistory
          onClose={() =>
            setPreviewDialogState({ isOpen: false, branchId: null })
          }
          mainTitleText={'preview-invoice'}
        />
      )}
    </div>
  );
};
