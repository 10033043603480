import React, {
  useEffect, useRef, useState, useCallback, useReducer
} from 'react';
import { Button } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { orderBy } from 'lodash';
import {
  Spinner,
  Tables,
  NoDataFoundComponent,
  NoSearchResultComponent,
  Inputs,
  PaginationComponent,
  PermissionsComponent
} from '../../../../../../Components';
import { bottomBoxComponentUpdate, GetParams, GlobalHistory, returnPropsByPermissions, showError, showSuccess } from '../../../../../../Helper';
import { useTitle } from '../../../../../../Hooks';
import { TableActions } from '../../../../../../Enums';
import { DeleteRoleDialog } from '../RolesDialogs/DeleteRoleDialog';
import { GetAllRoles, GetAllPermissionsByRoleId, AssignPermissionsToRole, PostRole } from '../../../../../../Services/roleServices';
import { RolesPermissions } from '../../../../../../Permissions';
import { getIsAllowedPermission } from '../../../../../../Helper/Permissions.Helper';

export const RolesListView = () => {
  const loginResponse = useSelector((state) => state.login.loginResponse);
  const { t } = useTranslation(['RolesView', 'Shared']);
  const searchTimer = useRef(null);
  const [rolesResponse, setRolesResponse] = React.useState({
    result: [],
    totalCount: 0
  });
  const [loading, setLoading] = React.useState(false);
  const [deletedName, setDeletedName] = React.useState('');
  const [deletedId, setDeletedId] = React.useState(0);
  const [deleteDialog, setDeleteDialog] = React.useState(false);
  const [isFirstLoad, setisFirstLoad] = React.useState(true);
  const [isCoreRole, setIsCoreRole] = React.useState(false);
  const [sortBy, setSortBy] = React.useState(null);
  const [filter, setFilter] = useState({
    pageSize: parseInt(localStorage.getItem('Pagination')) || 25,
    pageIndex: 0,
    search: '',
    filterBy: null,
    orderBy: null
  });
  const reducer = useCallback((state, action) => {
    if (action.id !== 'edit') return { ...state, [action.id]: action.value };
    return {
      ...action.value,
    };
  }, []);
  const [state, setState] = useState({
    roleName: '',
    permissions: [],
  })
  // useReducer(reducer, {
  //   roleName: '',
  //   permissions: [],
  // });
  useTitle(t('Shared:SideMenuView.Administration.Roles'));

  const getMyRoles = useCallback(async () => {
    setLoading(true);
    if (getIsAllowedPermission(
      Object.values(RolesPermissions),
      loginResponse,
      RolesPermissions.ViewRoles.permissionsId
    )) {
      const res = await GetAllRoles(filter.pageIndex + 1, filter.pageSize, filter.search, filter.filterBy, filter.orderBy);
      if (!(res && res.data && res.data.ErrorId) && res) setRolesResponse({
        result: res.result,
        totalCount: res.totalCount
      });
      else setRolesResponse({ result: [], totalCount: 0 });
      if (res && res.totalCount === 0) setisFirstLoad(false);
    }
    setLoading(false);
  }, [filter, loginResponse]);
  useEffect(() => {
    getMyRoles();
  }, [getMyRoles, filter]);

  const searchHandler = (e) => {
    const { value } = e.target;
    if (searchTimer.current) clearTimeout(searchTimer.current);
    searchTimer.current = setTimeout(() => {
      setFilter((items) => ({ ...items, pageIndex: 0, search: value }));
    }, 700);
  };

  const onPageIndexChanged = (pageIndex) => {
    setFilter((item) => ({ ...item, pageIndex }));
  };
  const onPageSizeChanged = (pageSize) => {
    setFilter((item) => ({ ...item, pageIndex: 0, pageSize }));
  };

  useEffect(() => {
    if (returnPropsByPermissions(RolesPermissions.ViewRoles.permissionsId)) {
      bottomBoxComponentUpdate(
        <PaginationComponent
          pageIndex={filter.pageIndex}
          pageSize={filter.pageSize}
          totalCount={(rolesResponse && rolesResponse.totalCount) || 0}
          onPageIndexChanged={onPageIndexChanged}
          onPageSizeChanged={onPageSizeChanged}
        />
      );
    }

  });

  useEffect(() => {
    if (sortBy)
      setFilter((item) => ({ ...item, filterBy: sortBy.filterBy, orderBy: sortBy.orderBy }));
  }, [sortBy]);

  useEffect(
    () => () => {
      bottomBoxComponentUpdate(null);
    },
    []
  );

  const tableActionClicked = useCallback((actionEnum, item, focusedRow, event) => {
    event.stopPropagation();
    event.preventDefault();
    if (actionEnum === TableActions.view.key) GlobalHistory.push(`./Details?id=${item.rolesId}`);
    else if (actionEnum === TableActions.edit.key)
      GlobalHistory.push(`./role-management?id=${item.rolesId}&roleName=${item.rolesName}&isCore=${item.isCore}`);
    else if (actionEnum === TableActions.delete.key) {
      setDeletedId(item.rolesId);
      setDeletedName(item.rolesName);
      setDeleteDialog(true);
      setIsCoreRole(item.isCore)
    } else if (actionEnum === TableActions.copyItem.key) getAllPermissionsByRoleId(item)

  }, []);

  const focusedRowChanged = (activeRow) => {
      const item = rolesResponse.result[activeRow];
      if (!item) return;
      setIsCoreRole(item.isCore);
  };

  const getAllPermissionsByRoleId = useCallback(async (item) => {
    setLoading(true);

    const res = await GetAllPermissionsByRoleId(item.rolesId, filter.pageIndex, filter.pageSize);
    if (!(res && res.data && res.data.ErrorId) && res && res.result) {
      setState({
        permissions: res.result,
        roleName: item.rolesName && (item.rolesName + '_COPY') || ''
      })
    } else {
      setLoading(false);
      showError(t`role-copy-failed`);
    }
  }, []);
  const saveRoleHandler = useCallback(async () => {
    if (state.roleName) {
      const roleRes = await PostRole({
        rolesName: state.roleName,
      });
      if(!(roleRes && roleRes.data && roleRes.data.ErrorId)) {
        // setLoading(true);
        const res = await AssignPermissionsToRole(
          state.permissions.map((item) => ({
            ...item,
            rolesId: roleRes.rolesId,
          }))
        );
        if (!(res && res.data && res.data.ErrorId)) {
          showSuccess(t`role-copy-successfully`);
          getMyRoles();
          setLoading(false);
        } else {
          showError(t`role-copy-failed`);
          setLoading(false);
        }
      } else {
        setLoading(false);
        showError(
          t(
            `${(roleRes &&
              roleRes.data &&
              roleRes.data.Message &&
              roleRes.data.Message.substring(
                roleRes.data.Message.lastIndexOf(':') + 1,
                roleRes.data.Message.length
              )) ||
            'role-copy-failed'
            }`
          )
        );
      }
    }
  }, [state]);

  useEffect(() => {
    saveRoleHandler();
  }, [saveRoleHandler, state])
  return (
    <div className='view-wrapper'>
      <Spinner isActive={loading} />
      <div className='header-section'>
        <div className='filter-section px-2'>
          <div className='section'>
            <PermissionsComponent
              permissionsList={Object.values(RolesPermissions)}
              permissionsId={RolesPermissions.AddRoles.permissionsId}
            >
              <Button
                onClick={() => GlobalHistory.push('/home/Roles/role-management')}
                className='btns theme-solid bg-primary'
              >
                {t('RolesView:RolesListView.AddNewRole')}
              </Button>
            </PermissionsComponent>
          </div>
          <PermissionsComponent
            permissionsList={Object.values(RolesPermissions)}
            permissionsId={RolesPermissions.ViewRoles.permissionsId}
          >
            <div className='section px-2'>
              <div className='d-flex-column p-relative'>
                <Inputs
                  idRef='usersSearchRef'
                  variant='outlined'
                  fieldClasses='inputs theme-solid'
                  label={t('RolesView:RolesListView.SearchRoles')}
                  beforeIconClasses='mdi mdi-magnify mdi-24px c-gray-primary'
                  onKeyUp={searchHandler}
                />
              </div>
            </div>
          </PermissionsComponent>
        </div>
      </div>
      <PermissionsComponent
        permissionsList={Object.values(RolesPermissions)}
        permissionsId={RolesPermissions.ViewRoles.permissionsId}
      >
        {' '}
        <NoDataFoundComponent />

      </PermissionsComponent>
      <PermissionsComponent
        permissionsList={Object.values(RolesPermissions)}
        permissionsId={RolesPermissions.ViewRoles.permissionsId}
      >
        {rolesResponse && rolesResponse.totalCount === 0 && !isFirstLoad ? (
          <NoSearchResultComponent />
        ) : (
          rolesResponse && (
            <div className='w-100 px-3'>
              <Tables
                data={rolesResponse.result || []}
                headerData={[
                  {
                    id: 1,
                    label: 'RolesView:RolesListView.RolesId2',
                    component: (item, index) => <span>{index + 1}</span>,
                  },
                  {
                    id: 2,
                    isSortable: true,
                    label: 'RolesView:RolesListView.RolesName',
                    input: 'rolesName',
                    isDate: false,
                  },
                ]}
                focusedRowChanged={focusedRowChanged}
                defaultActions={
                  // getTableActions()
                  [{
                    enum: TableActions.edit.key,
                    title: t(`Shared:edit`),
                    isDisabled: !getIsAllowedPermission(
                      Object.values(RolesPermissions),
                      loginResponse,
                      RolesPermissions.EditViewRolesRecord.permissionsId
                    ),
                    externalComponent: null,
                  },
                  {
                    enum: TableActions.delete.key,
                    title: t(`Shared:delete`),
                    isDisabled: !getIsAllowedPermission(
                      Object.values(RolesPermissions),
                      loginResponse,
                      RolesPermissions.DeleteRoles.permissionsId
                    ),
                    externalComponent: null,
                  },
                  {
                    enum: TableActions.copyItem.key,
                    title: t(`Shared:copy`),
                  }]
                }
                onPageIndexChanged={onPageIndexChanged}
                onPageSizeChanged={onPageSizeChanged}
                actionsOptions={{
                  onActionClicked: tableActionClicked,
                }}
                itemsPerPage={filter.pageSize}
                activePage={filter.pageIndex}
                totalItems={rolesResponse && rolesResponse.totalCount ? rolesResponse.totalCount : 0}
                setSortBy={setSortBy}
              />
            </div>
          )
        )}
      </PermissionsComponent>
      <DeleteRoleDialog
        open={deleteDialog}
        close={() => setDeleteDialog(false)}
        deletedId={deletedId}
        name={deletedName}
        isCoreRole={isCoreRole}
        reloadData={() => setFilter((items) => ({ ...items, pageIndex: 0 }))}
      />
    </div>
  );
};
