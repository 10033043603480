import React from 'react';
import PropTypes from 'prop-types';
import { Tables } from '../../../../Components';
import moment from 'moment';
import { LearningUserProfileLocalePath } from '../i18n/LearningUserProfileLocale';
import { urlExpression } from '../../../../Utils';
import { Link } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

function CoursesTable({ courses }) {
  const { t } = useTranslation(LearningUserProfileLocalePath);
  return (
    <div className='pt-3'>
      <Tables
        headerData={[
          { label: 'course-name', input: 'courseName' },
          {
            label: 'complete-date',
            input: 'completionDate',
            component: (row) =>
              !!row.completionDate
                ? moment(row.completionDate, 'DD/MM/YYYY, HH:mm:ss').format(
                    'YYYY-MM-DD'
                  )
                : '-',
          },
          {
            label: 'certificate-link',
            input: 'certificateLink',
            component: (row) =>
              urlExpression.test(row.certificateLink) ? (
                <Link href={row.certificateLink} target='_blank'>
                  {t('certificate')}
                </Link>
              ) : (
                row.certificateLink
              ),
          },
          { label: 'progress', input: 'progress' },
          {
            label: 'enrolled-on',
            input: 'enrolledOn',
            component: (row) =>
              moment(row.enrolledOn, 'DD/MM/YYYY, HH:mm:ss').format(
                'YYYY-MM-DD'
              ),
          },
          { label: 'time-spent', input: 'timeSpent' },
          { label: 'score', input: 'score' },
        ]}
        defaultActions={[]}
        data={courses}
        activePage={0}
        parentTranslationPath={LearningUserProfileLocalePath}
        itemsPerPage={courses?.length}
      />
    </div>
  );
}

CoursesTable.propTypes = {
  courses: PropTypes.array.isRequired,
};

export default CoursesTable;
