import React, {
  useReducer, useEffect, useState, useCallback
} from 'react';
import Joi from 'joi';

import {
  ButtonBase,
  DialogActions, DialogContent, DialogTitle, Dialog
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { getDownloadableLink, showSuccess, showError , getErrorByName  } from '../../../../Helper';
import {  ActivityFilesUploader } from '../../../../Components/UploaderActivitieFileComponent/ActivityFilesUploader' ;

// import { config } from '../../../../config/config';
import {
Inputs,
  UploaderComponent,
  AutocompleteComponent,
  Spinner,
} from '../../../../Components';
import {
  lookupItemsGetId , CreatePropertyUnitModel 
} from '../../../../Services';

// import { DefaultImagesEnum, UploaderThemesEnum } from '../../../../Enums';

export const AddModelsUnitsDialog = ({
  open,
  close,
  propertyName,
  parentTranslationPath , 
  translationPath , 
}) => {

  const { t } = useTranslation(parentTranslationPath);
  const [views, setViews] = useState([]);

  // eslint-disable-next-line prefer-const

  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const reducer = useCallback((state, action) => {
    if (action.id !== 'edit') return { ...state, [action.id]: action.value };
    return {
      ...action.value,
    };
  }, []);

  const [error , setError ] = useState({
    bedroomNo : false , bathroomsNo : false  , 
    maidsRoomNo : false  ,
    storeRoomNo :false , 
    laundryRoomNo : false ,
    utilityRoomNo : false ,
    studyRoomNo : false   ,
   }) ; 

  const [state, setState] = useReducer(reducer, {
    propertyUnitModelName : null , 
    propertyId: (propertyName && propertyName.id ),
    unitsNo : null , 
    area: null,
    bathroomNo : null , 
    bedroomNo : null , 
    sellingPriceFrom : null , 
    sellingPriceTo : null , 
    rentPerYearFrom: null,
    primaryView:null , 
    secondaryView : null , 
    unitTypeId : null , 
    parkingNo: null,
    maidsRoomNo: null,
    storeRoomNo: null,
    backyardNo: null,
    laundryRoomNo: null,
    utilityRoomNo: null,
    studyRoomNo: null,
    unitModelImage: null,
    unitModelFileId: null,
    unitModelDocuments: null,
    propertyUnitType: null ,
    files: [] , 
    serviceCharge: null , 
  });

  const schema = Joi.object({
    propertyUnitModelName: Joi.string()
      .required()
      .messages({
        'string.base': t`${translationPath}propertyUnitModelName-is-required`,
        'string.empty': t`${translationPath}propertyUnitModelName-is-required`,
      }),
      area: Joi.number()
      .required()
      .messages({
        'number.base': t(`${translationPath}area-is-required`),
        'number.empty': t`${translationPath}area-is-required`,
      }), 
  })
    .options({
      abortEarly: false,
      allowUnknown: true,
    })
    .validate(state);



  const saveHandler = useCallback(async () => {
    setIsLoading(true);
    setIsSubmitted(true); 

    const errorList =Object.values(error);
   
    if (schema.error || errorList.some(x => x)) 
    {
      setIsLoading(false);
      showError(t(`${translationPath}please-fill-all-Required-fields`));
      return;
    }

    const res = await CreatePropertyUnitModel({...state ,    
      unitModelFileId : (state && state.files && state.files.length &&  state.files[0] && state.files[0].uuid) || null ,
    }) ; 

    if (!(res && res.status && res.status !== 200)) {
      showSuccess(t(`${translationPath}${`unitModel-created-successfully`}`));
     }
      else 
      showError(t(`${translationPath}${`unitModel-created-failed`}`));

    setIsLoading(false);
    close();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);


  const getAllViewsAPI = useCallback(async () => {
    setIsLoading(true);
    const result = await lookupItemsGetId({ lookupTypeId: 142 });
    if (!(result && result.status && result.status !== 200))
      setViews(result);
    else setViews([]);
    setIsLoading(false);
  }, []);


  useEffect(() => {

    getAllViewsAPI();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAllViewsAPI]);

  return (
    <div>
      <Dialog
        open={open}
        onClose={() => {
          close();
        }}
        disableBackdropClick
        className='rotationCriteriaDialog-dialog-wrapper'
      >
        <Spinner isActive={isLoading} />

        <form noValidate>
          <DialogTitle id='alert-dialog-slide-title'>
            <span>
              {t(`${translationPath}AddNewUnitModel`)}
            </span>
          </DialogTitle>
          <DialogContent>
            <div className='dialog-content-wrapper'>
              <div className='dialog-content-item'>
                <AutocompleteComponent
                  idRef='proprtyNameRef'
                  className='px-2'
                  labelValue={t(`${translationPath}ProprtyName`)}
                  selectedValues={propertyName}
                  value={propertyName}
                  isDisabled
                  multiple={false}
                  data={[]}
                  displayLabel={(option) =>
                    (option && option.name) || ''}
                  chipsLabel={(option) => (option && option.name) || ''}
                  withoutSearchButton
                  onChange={(event, newValue) => {
                  }}
                />
              </div>
              <div className='dialog-content-item'>
                <Inputs
                  idRef='modelUnitNameRef'
                  labelValue={t(`${translationPath}ModelUnitName`)}
                  labelClasses='Requierd-Color'
                  type='text'
                  onInputChanged={(event) => {
                    setState({ id: 'propertyUnitModelName', value: event.target.value });
                  }}
                  helperText={
                    getErrorByName(schema, 'propertyUnitModelName').message
                  }
                  error={getErrorByName(schema, 'propertyUnitModelName').error}
                  isWithError
                  translationPath={translationPath} 
                  parentTranslationPath={parentTranslationPath}
                />
              </div>
              <div className='dialog-content-item'>
                <Inputs
                  idRef='areaRef'
                  labelValue={t(`${translationPath}Area`)}
                  labelClasses='Requierd-Color'
                  type='number'
                  min={0}
                  onInputChanged={(event) => {
                    const { value }= event.target ; 
                    if(value < 0 )
                    return ;
                    setState({ id: 'area', value: +value });
                  }}
                  isSubmitted={isSubmitted}
                  helperText={
                    getErrorByName(schema, 'area').message
                  }
                  error={getErrorByName(schema, 'area').error}
                  isWithError
                  translationPath={translationPath} 
                  parentTranslationPath={parentTranslationPath}
                />
              </div>
              <div className='dialog-content-item'>
                <Inputs
                  idRef='bedroomsRef'
                  labelValue={t(`${translationPath}Bedrooms`)}
                  labelClasses='Requierd-Color'
                  min={0}
                  max={100}
                  type='number'
                  onInputChanged={(event) => {
                    const { value }= event.target ; 
                    if(value < 0  ||  value > 99)
                    return ;

                    const  numberRegex = /^\d+$/;
                    const bedroomNoError =  !(numberRegex.test(value)) ;
                     setError((item) => ({ ...item, bathroomsNo  : bedroomNoError})); 
                    setState({ id: 'bedroomNo', value: event.target.value });
                  }}

                  isSubmitted={isSubmitted}
                  error={error && error.bathroomsNo}
                  helperText={t(`${translationPath}Choose-correct-number`)}
                  isWithError
                  translationPath={translationPath} 
                  parentTranslationPath={parentTranslationPath}
                />
              </div>
              <div className='dialog-content-item'>
                <Inputs
                  idRef='bathroomsRef'
                  labelValue={t(`${translationPath}Bathrooms`)}
                  labelClasses='Requierd-Color'
                  type='number'
                  min={0}
                  max={100}
                  onInputChanged={(event) => {

                    const { value }= event.target ; 
                    if(value < 0 || value > 99  )
                    return ;

                    const  numberRegex = /^\d+$/;
                    const bathroomNoError =  !(numberRegex.test(value)) ;
                     setError((item) => ({ ...item, bedroomNo  : bathroomNoError})); 
                     
                    setState({ id: 'bathroomNo', value:(value) });
                  }}
                  error={error && error.bedroomNo}
                  helperText={t(`${translationPath}Choose-correct-number`)}
                  isSubmitted={isSubmitted}
                  isWithError
                  translationPath={translationPath} 
                  parentTranslationPath={parentTranslationPath}
                />
              </div>
              <div className='dialog-content-item'>
                <Inputs
                  idRef='labelRef'
                  labelValue={t(`${translationPath}UnitNumber`)}
                  onInputChanged={(event) => {
                    setState({ id: 'unitsNo', value: event.target.value });
                  }}
                  translationPath={translationPath} 
                  parentTranslationPath={parentTranslationPath}
                />
              </div>
              <div className='dialog-content-item'>
                <Inputs
                  idRef='sellingPriceFromRef'
                  labelValue={t(`${translationPath}SellingPriceFrom`)}
                  type='number'
                  min={0}
                  onInputChanged={(event) => {
                    const { value }= event.target ; 
                    if(value < 0 )
                    return ;
                    setState({ id: 'sellingPriceFrom', value: value});
                  }}
                  withNumberFormat
                  translationPath={translationPath} 
                  parentTranslationPath={parentTranslationPath}
                />
              </div>
              <div className='dialog-content-item'>
                <Inputs
                  idRef='sellingPriceToRef'
                  labelValue={t(`${translationPath}SellingPriceTo`)}
                  type='number'
                  min={0}
                  onInputChanged={(event) => {

                   const { value }= event.target ; 
                    if(value < 0 )
                    return ;
                    setState({ id: 'sellingPriceTo', value: value });
                  }}
                  withNumberFormat
                  translationPath={translationPath} 
                  parentTranslationPath={parentTranslationPath}
                />
              </div>

              <div className='dialog-content-item'>
                <Inputs
                  idRef='RentPerYearFromRef'
                  labelValue={t(`${translationPath}RentPerYearFrom`)}
                  type='number'
                   min={0}
                  value={state.rentPerYearFrom}
                  onInputChanged={(event) => {
                    const { value }= event.target ; 
                    if(value < 0 )
                    return ;

                    setState({ id: 'rentPerYearFrom', value: (value) });
                  }}
                  translationPath={translationPath} 
                  parentTranslationPath={parentTranslationPath}
                  withNumberFormat
                 
                />
              </div>
              <div className='dialog-content-item'>
                <Inputs
                  idRef='rentPerYearToRef'
                  labelValue={t(`${translationPath}RentPerYearTo`)}
                  type='number'
                  min={0}
                  withNumberFormat
                  onInputChanged={(event) => {
                    const { value }= event.target ; 
                    if(value < 0 )
                    return ;
                  
                    setState({ id: 'rentPerYearTo', value:  (value)  });
                  }}
                  translationPath={translationPath} 
                  parentTranslationPath={parentTranslationPath}
                />
              </div>
              <div className='dialog-content-item'>
                <AutocompleteComponent
                  idRef='primaryViewRef'
                  className='px-2'
                  labelValue={t(`${translationPath}PrimaryView`)}
                  multiple
                  data={views || []}
                  displayLabel={(option) =>
                    (option && option.lookupItemName) || ''}
                  chipsLabel={(option) => (option && option.lookupItemName) || ''}
                  withoutSearchButton
                  onChange={(event, newValue) => {
                    setState({ id: 'primaryView', value: newValue });
                  }}
                  translationPath={translationPath} 
                  parentTranslationPath={translationPath} 
                />
              </div>
              <div className='dialog-content-item'>
                <AutocompleteComponent
                  idRef='secondaryViewRef'
                  className='px-2'
                  labelValue={t(`${translationPath}SecondaryView`)}
                  multiple={true}
                  data={views || []}
                  displayLabel={(option) =>
                    (option && option.lookupItemName) || ''}
                  chipsLabel={(option) => (option && option.lookupItemName) || ''}
                  withoutSearchButton
                  onChange={(event, newValue) => {
                    setState({ id: 'secondaryView', value: newValue });
                  }}
                  translationPath={translationPath} 
                  parentTranslationPath={parentTranslationPath}
                />
              </div>
               <div className='dialog-content-item w-100 mt-2'>
                  <ActivityFilesUploader
                     state={state}
                     setState={setState}
                     translationPath={translationPath}
                     canSelectOneDocument={true}
                       accept={'image/*'}
                   />
    
               </div>

            </div>
          </DialogContent>
          <DialogActions>
            <div className='form-builder-wrapper'>
              <div className='form-builder-footer-wrapper is-dialog w-100 MuiGrid-align-items-xs-center MuiGrid-justify-xs-space-between'>
                <div className='MuiDialogActions-root dialog-footer-wrapper  MuiDialogActions-spacing'>
                  <div className='save-cancel-wrapper d-flex-v-center-h-end flex-wrap p-2'>
                    <div className='cancel-wrapper d-inline-flex-center'>
                      <ButtonBase
                        className='MuiButtonBase-root MuiButton-root MuiButton-text cancel-btn-wrapper btns theme-transparent c-primary'
                        tabIndex='0'
                        id='cancel-ref-action'
                        onClick={() => close()}
                      >
                        <span className='MuiButton-label'>
                          <span>
                            {t(`${translationPath}Cancel`)}

                          </span>
                        </span>
                        <span className='MuiTouchRipple-root' />
                      </ButtonBase>
                    </div>
                    <div className='save-wrapper d-inline-flex-center'>
                      <ButtonBase
                        className='MuiButtonBase-root MuiButton-root MuiButton-text save-btn-wrapper btns theme-solid bg-primary w-100 mx-2 mb-2'
                        tabIndex='0'
                        onClick={() => {
                          saveHandler();
                        }}
                      >
                        <span className='MuiButton-label'>
                          <span>
                            {t(`${translationPath}Save`)}

                          </span>
                        </span>
                        <span className='MuiTouchRipple-root' />
                      </ButtonBase>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
};
