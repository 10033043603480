export const BedroomEnum = [
  {
    key: 1,
    value: 'studio',
  },
  {
    key: 2,
    value: 1,
  },
  {
    key: 3,
    value: 2,
  },
  {
    key: 4,
    value: 3,
  },
  {
    key: 5,
    value: 4,
  },
  {
    key: 6,
    value: '5+',
  },
];
