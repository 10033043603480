import React from "react";

function TableView({ fill, ...restProps }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill={fill ?? ""}
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <path
        d="M5.92 2.041c-.769.051-1.248.164-1.74.41A3.776 3.776 0 0 0 2.124 5.16C2.013 5.729 2 6.455 2 12c0 6.358.006 6.526.245 7.32.332 1.102 1.333 2.103 2.435 2.435.794.239.962.245 7.32.245 6.358 0 6.526-.006 7.32-.245 1.102-.332 2.103-1.333 2.435-2.435.239-.794.245-.962.245-7.32 0-6.358-.006-6.526-.245-7.32-.331-1.099-1.29-2.058-2.435-2.434-.676-.222-.52-.217-6.98-.227-3.278-.005-6.167.005-6.42.022m12.572 2.05c.402.097.617.218.908.509.487.486.552.74.587 2.29L20.012 8H3.988l.025-1.11c.034-1.481.099-1.77.507-2.223.363-.405.762-.576 1.46-.628.22-.016 3.064-.026 6.32-.021 5.343.006 5.947.014 6.192.073M20 12v2H4v-4h16v2m-.013 5.11c-.035 1.55-.1 1.804-.587 2.291-.299.3-.58.45-.982.527-.408.077-12.428.077-12.836-.001a1.69 1.69 0 0 1-.982-.524c-.487-.492-.552-.744-.587-2.293L3.988 16h16.024l-.025 1.11"
        fill-rule="evenodd"
        fill={fill ?? ""}
      />
    </svg>
  );
}

export default TableView;
