import React, { useState, useCallback, useEffect, useReducer } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { GetAgentsForContactLeads } from "../../../../../../Services";
import {
  LoadableImageComponant,
  PaginationComponent,
  Spinner,
} from "../../../../../../Components";
import {
  bottomBoxComponentUpdate,
  getDownloadableLink,
  GetParams,
} from "../../../../../../Helper";
import { ContactTypeEnum } from "../../../../../../Enums";
import { useLocation } from "react-router-dom";

export const ContactProfileUserAccessComponent = ({
  parentTranslationPath,
  translationPath,
}) => {
  const { t } = useTranslation(parentTranslationPath);
  const reducer = useCallback((state, action) => {
    if (action.id !== "edit") return { ...state, [action.id]: action.value };
    return {
      ...action.value,
    };
  }, []);
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState({});
  const [contactId, setContactId] = useState(null);
  const [filter, setFilter] = useReducer(reducer, {
    pageIndex: 1,
    pageSize: parseInt(localStorage.getItem("Pagination")) || 25,
  });

  useEffect(() => {
    setContactId(+GetParams("id"));
  }, [location]);

  const getAllAccessUser = useCallback(async () => {
    setLoading(true);
    if (contactId) {
      const result = await GetAgentsForContactLeads(
        contactId,
        filter.pageIndex,
        filter.pageSize
      );
      if (!(result && result.status && result.status !== 200))
        setResponse(result);
      else setResponse({});
    }
    setLoading(false);
  }, [contactId, filter]);

  const onPageIndexChanged = (pageIndex) => {
    setFilter({ id: "pageIndex", value: pageIndex });
  };
  const onPageSizeChanged = (pageSize) => {
    setFilter({ id: "pageSize", value: pageSize });
  };

  useEffect(() => {
    getAllAccessUser();
  }, [getAllAccessUser, filter]);

  useEffect(() => {
    bottomBoxComponentUpdate(
      <PaginationComponent
        pageIndex={filter?.pageIndex - 1 || 0}
        pageSize={filter?.pageSize || 25}
        totalCount={response?.totalCount || 0}
        parentTranslationPath={parentTranslationPath}
        onPageIndexChanged={onPageIndexChanged}
        onPageSizeChanged={onPageSizeChanged}
      />
    );
  });
  useEffect(
    () => () => {
      bottomBoxComponentUpdate(null);
    },
    []
  );

  return (
    <div className="associated-contacts-wrapper childs-wrapper">
      <Spinner isActive={loading} />
      <div className="title-section">
        <span>{t(`${translationPath}who-can-access-this-file`)}</span>
      </div>
      <div className="user-access-wrapper w-100 px-2">
        {response &&
          response.result &&
          response.result.map((item) => (
            <div className="user-access-item">
              <div className="user-access-image-wrapper">
                <LoadableImageComponant
                  classes="user-access-image"
                  alt={`${(item && item.agentUsername) || ""}`}
                  src={
                    (item.profileImg && getDownloadableLink(item.profileImg)) ||
                    ContactTypeEnum.man.defaultImg
                  }
                />
              </div>
              <div className="user-access-info">
                <div className="user-access-name">
                  {`${(item && item.agentName) || ""}`}
                </div>
                <div className="user-access-type">
                  {`${(item && item.agentUsername) || ""}`}
                </div>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

ContactProfileUserAccessComponent.propTypes = {
  parentTranslationPath: PropTypes.string.isRequired,
  translationPath: PropTypes.string.isRequired,
};
