import React, { useState, useCallback, useEffect } from 'react';
import { PropTypes } from 'prop-types';
import { GetAllPropertyVideosLocationCategoryByPropertyId } from '../../../../../../../../Services';
import { UnitProfileImagesCardActionEnum } from '../../../../../../../../Enums';
import { VideosGalleryCardComponent } from '../../../../../../ImagesGallery/Sections/VideosGalleryComponent/VideosGalleryCardComponent/VideosGalleryCardComponent';
import { Spinner } from '../../../../../../../../Components';

export const LocationVideosTabComponent = ({
  parentTranslationPath,
  translationPath,
  propertyId,
  canEditImage,
  activeTab
}) => {
  const [result, setResult] = useState();
  const [isLoading, setIsLoading] = useState(true);
  
  const gtAllPropertyVideosOrVirtualToursLocationCategoryByPropertyId = useCallback(async () => {
    const response = await GetAllPropertyVideosLocationCategoryByPropertyId(propertyId);
    setIsLoading(true);
    if (!(response && response.status && response.status !== 200))
      setResult(response || []);
    else setResult([]);
    setIsLoading(false);
  }, [propertyId]);
  useEffect(() => {
    gtAllPropertyVideosOrVirtualToursLocationCategoryByPropertyId();
  }, [gtAllPropertyVideosOrVirtualToursLocationCategoryByPropertyId]);

  return (
    <div className='LocationImagesTabComponent'>
      <Spinner isActive={isLoading} />
      {result && result.length > 0 ? (
        <VideosGalleryCardComponent
          hidePhotosTooltip
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          data={result}
          WithUnitDetails
          reloadData={gtAllPropertyVideosOrVirtualToursLocationCategoryByPropertyId}
          setdata={setResult}
          Display={UnitProfileImagesCardActionEnum.Hide}
          unitsUploader={true}
          activeTab={activeTab}
        />
      ) : (
        <div className='EmptyPage' />
      )}
    </div>
  );
};
LocationVideosTabComponent.propTypes = {
  parentTranslationPath: PropTypes.string.isRequired,
  translationPath: PropTypes.string.isRequired,
  propertyId: PropTypes.number.isRequired,
};
