import React, { useState, useEffect, } from 'react';
import moment from 'moment';
import './../TaskDetailsView.scss';
import { useTranslation } from 'react-i18next';
import { Avatar } from '@material-ui/core';
import { PageHeading } from '../../../../../SharedComponents';
import { LoadableDocumentComponent } from '../../../../../Components';
import { MediaPreviewDialog } from '../../../../../Views/Home/TemplatesView/Dialogs';
import {
  getIsValidURL,
  getIsValidDataURL,
  getDownloadableLink,
  GetParams,
  showError,
  getFirstLastNameLetters
} from '../../../../../Helper';
import { GetTaskById } from '../../../../../Services';

export const TaskDetailsTab = ({ parentTranslationPath, translationPath }) => {
  const { t } = useTranslation(parentTranslationPath);
  const [viewedFile, setViewedFile] = useState(null);
  const [isOpenPreviewDialog, setIsOpenPreviewDialog] = useState(false);
  const [taskData, setTaskData] = useState(null);

  const handleDownloadClick = (value) => () => {
    const link = document.createElement('a');
    link.setAttribute('download', value.urlDescription);
    link.href = getDownloadableLink(value.fileId);
    document.body.appendChild(link);
    link.click();
    link.remove();
  };

  const openLink = (url) => {
    if (!url.match(/^https?:\/\//i)) url = 'http://' + url;
    if (getIsValidURL(url)) {
      window.open(url || '');
    } else if (getIsValidDataURL(url)) {
      let windowObj = window.open();
      const iframeElement = `<iframe src="${url}" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>`;
      windowObj.document.write(iframeElement);
    }
  };

  const getTaskById = async () => {
    const res = await GetTaskById({
      taskId: +GetParams('id') || null,
    });
    if (!(res && res.status && res.status !== 200)) {
      setTaskData(res);
    }
  };

  useEffect(() => {
    getTaskById();
  }, []);

  return (
    <>
      <PageHeading
        parentTranslationPath={parentTranslationPath}
        translationPath={translationPath}
        headerTitle={'task-details'}
        subTitle={'task-details-heading'}
        headerTitleClasses={"fz-20 mb-3"}
      />
      <div>
        <div className='d-flex-default py-3 b-top'>
          <div className='w-25'>
            <span className='fw-simi-bold text-primary'>Task title</span>
          </div>
          <div className='w-50'>
            <p className='text-tertiary'>{taskData?.title || '-'}</p>
          </div>
        </div>
        <div className='d-flex-default py-3 b-top'>
          <div className='w-25'>
            <span className='fw-simi-bold text-primary'>Description</span>
          </div>
          <div className='w-50'>
            <p className='text-tertiary'>{taskData?.description || '-'}</p>
          </div>
        </div>
        <div className='d-flex-default py-3 b-top'>
          <div className='w-25'>
            <span className='fw-simi-bold text-primary'>Deadline</span>
          </div>
          <div className='w-50'>
            <p className='text-tertiary'>
              {taskData?.deadline ? moment(taskData?.deadline).format('LL') : '-'}
            </p>
          </div>
        </div>
        <div className='d-flex-default py-3 b-top'>
          <div className='w-25'>
            <span className='fw-simi-bold text-primary'>Requested by</span>
          </div>
          <div className='w-50'>
            {taskData?.reporter ? (
              <div className='d-flex-v-center'>
                <div className='campaign-team-f1'>
                  <Avatar
                    className='avatars-wrapper team-avatar fz-12 sm-theme'
                    src={
                      taskData?.reporterProfileImg
                        ? getDownloadableLink(taskData?.reporterProfileImg)
                        : ''
                    }>
                    {getFirstLastNameLetters(taskData?.reporter || '')}
                  </Avatar>
                </div>
                <div className='d-flex-column'>
                  <span className='fz-12 fw-simi-bold'>{taskData?.reporter || '-'}</span>
                  <span className='fz-10 text-tertiary'>{taskData?.reporterEmail || '-'}</span>
                </div>
              </div>
            ) : (
              '-'
            )}
          </div>
        </div>
        <div className='d-flex-default py-3 b-top'>
          <div className='w-25'>
            <span className='fw-simi-bold text-primary'>Attached files</span>
          </div>
          <div className='w-50'>
            {taskData?.taskAttachmentsDto?.length
              ? taskData?.taskAttachmentsDto.map((item) =>
                  item.url ? (
                    <div className='link-item-container py-3'>
                      <p className='text-tertiary ecilipse-text'>{item.url}</p>
                      <div className='d-flex-center'>
                        <span
                          className='fw-simi-bold text-primary pointer sec-color'
                          onClick={() => {
                            try {
                              openLink(item.url);
                            } catch (error) {
                              showError(t(`${translationPath}this-link-is-not-valid`));
                            }
                          }}>
                          Visit Link
                        </span>
                      </div>
                    </div>
                  ) : (
                    <div className='file-item-container  py-3'>
                      <div className='file-icon-wrapper d-flex-center'>
                        <span className='mr-1'>
                          <LoadableDocumentComponent
                            classes='file-icon'
                            fileName={item.urlDescription || ''}
                            alt={`cover-image`}
                          />
                        </span>
                        <span className='d-flex-column'>
                          <span className='file-title'>{item.urlDescription}</span>
                        </span>
                      </div>
                      <div className='d-flex-center'>
                        <span
                          className='fw-simi-bold text-primary pointer'
                          onClick={handleDownloadClick(item)}>
                          Download
                        </span>
                      </div>
                      <div className='d-flex-center'>
                        <span
                          className='fw-simi-bold text-primary pointer sec-color'
                          onClick={() => {
                            setIsOpenPreviewDialog(true);
                            setViewedFile({
                              templateFileId: item.fileId,
                              templateFileName: item.urlDescription,
                              templateText: '',
                            });
                          }}>
                          View
                        </span>
                      </div>
                    </div>
                  )
                )
              : '-'}
          </div>
        </div>
        {isOpenPreviewDialog && (
          <MediaPreviewDialog
            activeItem={viewedFile}
            maintitleText='FILE-VIEW'
            isOpen={isOpenPreviewDialog}
            isOpenChanged={() => {
              setIsOpenPreviewDialog(false);
              setViewedFile(null);
            }}
            parentTranslationPath='Shared'
            translationPath={translationPath}
          />
        )}
      </div>
    </>
  );
};
