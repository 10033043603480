export const CampaignRequestHeaderData = [
    {
      id: 1,
      label: 'Campaign-name',
      input: 'campaignName',
      isDefaultFilterColumn: true,
    },
    {
      id: 2,
      label: 'department',
      input: 'department',
      isDefaultFilterColumn: true,
    },
    {
      id: 3,
      label: 'status',
      input: 'campaignStatus',
      isDefaultFilterColumn: true,
    },
    {
      id: 4,
      label: 'Date',
      input: 'createdOn',
      isDefaultFilterColumn: true,
      isDate: true,
      dateFormat: 'DD/MM/YYYY - hh:mm A',
    },
    {
      id: 5,
      label: 'Requestor',
      input: 'requester',
      isDefaultFilterColumn: true,
    },

    {
      id: 6,
      label: 'Next-action-by',
      input: 'nextActionBy',
      isDefaultFilterColumn: true,
    },
  ]
