import React from "react"
import { KycFormsView } from "./KycForms/KycFormsView"
import { DocumentsHistoryView } from "./DocumentsHistory/DocumentsHistoryView";

export const KYCTabsData = [
  {
    label: "KYC form",
    component: KycFormsView,
    permissionsList: [],
    permissionsId: null,
  },
  {
    label: "Document history",
    component: DocumentsHistoryView,
    permissionsList: [],
    permissionsId: null,
  },
];