import React, { useState } from "react";
import clsx from "clsx";
import { Icon } from "@material-ui/core";
import { useVerticalNav } from "../../../Contexts/VerticalNavContext";
import { ExpandedSideNav } from "../SideNav/UI";
import { MainMenu, NewHomeRoutes } from "../../../routes";

// styles
import useStyles from './styles';

function MobileSideNav() {
  const [open, setOpen] = useState(null);

  const { onToggleMobileSideNav } = useVerticalNav();
  
  const styles = useStyles();

  const getFullMenu = () => {
    return MainMenu.map((menuItem) => ({
      ...menuItem,
      children: NewHomeRoutes.filter(
        (homeRoute) => homeRoute.groupId === menuItem.groupId
      ),
    }));
  };
  
  const handleMenuToggle = (id) => {
    setOpen((prevOpen) => (prevOpen === id ? null : id));
  };

  return (
    <>
      <div className={styles.mobileSideNav}>
        {getFullMenu()
          .sort((a, b) => a.order - b.order)
          .map((item, index) => {
            return (
              <ExpandedSideNav 
                item={item} 
                index={index} 
                handleMenuToggle={handleMenuToggle} 
                open={open} 
              />
            );
        })}
      </div>
      <div
        onClick={() => onToggleMobileSideNav()}
        className={styles.overlay}
      >
        <Icon 
          onClick={() => onToggleMobileSideNav()}
          className={clsx("icon-x-close", styles.closeIcon)}
        />
      </div>
    </>
  );
}

export default MobileSideNav;
