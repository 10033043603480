import React from "react";

const EmailIcon = ({ fill, ...restProps }) => {
  return (
    <svg
      viewBox="0 0 21 20"
      fill={fill ?? ""}
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <path
        d="M4.253 2.56c-.759.096-1.367.376-1.885.868a3.06 3.06 0 0 0-.93 1.754 23.41 23.41 0 0 1-.074.436c-.044.243-.045 7.726 0 8.457.06.992.224 1.523.641 2.078.563.748 1.296 1.15 2.342 1.284.527.067 11.779.067 12.306 0 1.046-.134 1.779-.536 2.342-1.284.42-.559.583-1.092.641-2.098.044-.75.044-8.197 0-8.437a23.41 23.41 0 0 1-.074-.436 3.06 3.06 0 0 0-.93-1.754c-.532-.506-1.126-.773-1.936-.872-.37-.045-12.086-.041-12.443.004m12.552 1.68a1.6 1.6 0 0 1 1.115 1.136c0 .068-.537.457-3.312 2.397a363.65 363.65 0 0 1-3.594 2.495c-.266.17-.294.18-.514.18-.22 0-.248-.01-.514-.18A354.853 354.853 0 0 1 6.388 7.77L3.072 5.451l.02-.119c.049-.302.325-.672.667-.893.234-.151.385-.2.739-.239.134-.015 2.914-.024 6.177-.021 5.245.006 5.955.013 6.13.061M5.888 9.443a909.87 909.87 0 0 0 3.061 2.135c1.052.712 2.05.712 3.102 0a909.87 909.87 0 0 0 3.061-2.135 328.42 328.42 0 0 1 2.861-1.988c.035 0 .017 6.681-.019 6.954-.09.675-.579 1.217-1.215 1.346-.35.07-12.128.07-12.478 0a1.596 1.596 0 0 1-1.171-1.08c-.055-.185-.062-.511-.072-3.711-.006-1.93-.002-3.509.008-3.509s1.298.895 2.862 1.988"
        fill-rule="evenodd"
        fill={fill ?? ""}
      />
    </svg>
  );
};

export default EmailIcon;
