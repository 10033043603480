
import React  from 'react';
import { 
    ParkingDistributionPerUnit , 
    BasementParking , 
    CoveredParking , 
    PodiumParking , 
    ValetParking , 
    VisitParkingAvailability , 
} from '../ParkingDistributionContent' ; 
   
  export const ParkingDistributionContent = ({
    parentTranslationPath ,
    translationPath ,
    onChangeSelectedItem , 
    selectedValues ,
    setSelectValues , 
   } ) => { 

    return (
      <>
        <div className="parking-distribution-section b-bottom  mb-2">
          <ParkingDistributionPerUnit
           parentTranslationPath={parentTranslationPath}
           translationPath={translationPath}
           onChangeSelectedItem={onChangeSelectedItem}
           selectedValues={selectedValues}
           setSelectValues={setSelectValues} 
          /> 
        </div> 
        <div className="parking-distribution-section b-bottom mb-2">
          <BasementParking
           parentTranslationPath={parentTranslationPath}
           translationPath={translationPath}
           onChangeSelectedItem={onChangeSelectedItem}
           selectedValues={selectedValues}
           setSelectValues={setSelectValues}
          /> 
        </div> 
        <div className="parking-distribution-section b-bottom mb-2">
          <CoveredParking
           parentTranslationPath={parentTranslationPath}
           translationPath={translationPath}
           onChangeSelectedItem={onChangeSelectedItem}
           selectedValues={selectedValues}
           setSelectValues={setSelectValues}
          /> 
        </div> 
        <div className="parking-distribution-section b-bottom mb-2">
          <PodiumParking
           parentTranslationPath={parentTranslationPath}
           translationPath={translationPath}
           onChangeSelectedItem={onChangeSelectedItem}
           selectedValues={selectedValues}
           setSelectValues={setSelectValues}
          /> 
        </div> 
        <div className="parking-distribution-section b-bottom mb-2">
          <ValetParking
           parentTranslationPath={parentTranslationPath}
           translationPath={translationPath}
           onChangeSelectedItem={onChangeSelectedItem}
           selectedValues={selectedValues}
           setSelectValues={setSelectValues}
          /> 
        </div> 
        <div className="parking-distribution-section b-bottom mb-2">
          <VisitParkingAvailability
           parentTranslationPath={parentTranslationPath}
           translationPath={translationPath}
           onChangeSelectedItem={onChangeSelectedItem}
           selectedValues={selectedValues}
           setSelectValues={setSelectValues}
          /> 
        </div> 
      </>
    );
  } ; 
  
