import { config } from "../config";

const EnvironmentsHiddenColumns = {
  production: [],
  development: [],
  dubaiCRMConfig: [ "assetsConsent" ],
  dubai: [],
  staging: [],
  demo: [],
  assets: [],
};

export const checkIsColumnHiddenInConfig = ({ columnName }) => {
  const environmentHiddenColumns = EnvironmentsHiddenColumns[config.name] || [];
  const isColumnHidden = environmentHiddenColumns.findIndex((item) => item === columnName) !== -1;

  return isColumnHidden;
};
