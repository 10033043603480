import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Inputs } from '../../../../../../../../Components';
import { RepeatedItemDialog } from '../../../../../../FormBuilder/Dialogs/RepeatedItemDialog' ; 
import { isRef } from 'joi';

export const EmailComponent = ({
  parentTranslationPath,
  translationPath,
  emailAddresses,
  setEmail,
  isSubmitted,
  helperText,
  error,
  isReloadData  ,
  communicationId 
}) => {


  const { t } = useTranslation([parentTranslationPath, 'Shared']);
  const [repeatedItemDialogEmail, setRepeatedItemDialogEmail] = useState(false);
    const [timer, setTimer] = useState(null);
    const [HelperText, setHelperText] = useState('');
    const [Error, setError] = useState('');

  const emailItem = {
    data: {
      type: 'string',
      title: 'Email Address',
      description: 'Main Information',
      lookupItem: 18037,
      CommunicationType: 'Email',
    },
    field: {
      id: 'email_address',
      FieldType: 'communication',
      Required: 'true',
    },
  };

  const [emailState, setEmailState] = useState({
    email: '',
    typeId: communicationId,
  });

  useEffect(() => {
    if (emailAddresses && emailAddresses.length === 1)
      setEmailState({ email: emailAddresses[0] &&  emailAddresses[0].communicationValue });
     else  if (emailAddresses && emailAddresses.length > 1) {
        let emails = emailAddresses  && emailAddresses.map((item) => {
          return item.communicationValue
        });
        emails.splice(0, 1);
        setEmailState({ email: emailAddresses[0]?.communicationValue, others: emails });
    }
   }, []);



   useEffect(() => {
    let list = [];
    if (emailState.email !== '')
    list.push({communicationTypeId : communicationId  , communicationValue :  emailState.email});
    if (emailState?.others && emailState.others.length > 0 )
    emailState.others.map((item) => list.push({communicationTypeId : communicationId  , communicationValue : item}));
    setEmail([...list]);
   }, [emailState]);

   
   useEffect(() => 
    {
      if(isReloadData !==  null && isReloadData !== undefined)
       setEmailState({ email:'' , typeId: communicationId });
   }, [isReloadData]);


  return (
    <div className='w-100'>
      <Inputs
        idRef='emailAddressRef'
        labelValue='emailAddress'
        isWithError
        value={emailState.email}
        buttonOptions={{
          className: 'btns-icon theme-solid bg-blue-lighter',
          iconClasses: 'mdi mdi-plus',
          isRequired: false,
          isDisabled: !(
            emailState.email &&
            new RegExp(emailState.email.regExp).test(emailState.email) &&
            emailState.email &&
            emailState.email.length > 10
          ),
          onActionClicked: () => {
            if (
              emailState.email &&
              new RegExp(emailState.email.regExp).test(emailState.email)
            )
              setRepeatedItemDialogEmail(true);
          },
        }}
        inputPlaceholder={t(`${translationPath}enter-email-address`)}
        onInputChanged={(e) => {
          let { value } = e.target  ; 
          if(value === '')
            setEmailState({email : '' , typeId: communicationId}) ; 
          else 
          {
            setEmailState({
              ...emailState,
              email: value,
            });
          }
       
        }}
        parentTranslationPath={parentTranslationPath}
        translationPath={translationPath}
        isSubmitted={isSubmitted}
        helperText={helperText || HelperText}
        error={error || Error}
      />
      {repeatedItemDialogEmail && (
        <RepeatedItemDialog
          open={repeatedItemDialogEmail}
          item={emailItem}
          type='email'
          initialState={emailState}
          label={emailItem.data.title}
          onChange={(data) => {
            setEmailState({ ...data });
          }}
          closeDialog={() => {
            setRepeatedItemDialogEmail(false);
          }}
        />
      )}
    </div>
  );
};