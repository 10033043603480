

export const  ReportBuilderLogPermissions  = {
  GetAllReportBuilderLogs: {
      permissionsId:'984fe5b0-709b-4016-2e4c-08db604be055',
      permissionsName:'Get All Agents In Rotation Log',
      componentsId:null,
      components:null , 
    }
  };
  