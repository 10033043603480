import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TabsComponent, NoContentComponent } from '../../../Components';
import { GetAllExternalLinks } from '../../../Services';
import { useTitle } from '../../../Hooks';
import { ExternalLinkDisplayComponent } from './ExternalLinkDisplayComponent';

export const ExternalLinksView = ({ }) => {
    const parentTranslationPath = 'ExternalLinksView';
    const translationPath = '';
    const { t } = useTranslation(parentTranslationPath);
    useTitle(t(`${translationPath}external-links`));

    const [allExternalLinks, setAllExternalLinks] = useState([]);
    const [externalLinksTabs, setExternalLinksTabs] = useState([]);
    const [activeTabIndex, setActiveTabIndex] = useState(0);
    const [activeTabURL, setActiveTabURL] = useState(null);

    const getAllExternalLinks = async () => {
        const isAdmin = false;
        const body = {
            pageIndex: 1,
            pageSize: 25,
            orderBy: 1
        };
        const response = await GetAllExternalLinks(body, isAdmin);
        if (!(response && response.status && response.status !== 200))
            setAllExternalLinks(response && response.result || []);
        else
            setAllExternalLinks([]);
    };

    const getExternalLinksTabs = () => {
        const linkTabsList = allExternalLinks.map((item) => ({
            label: item.externalLinkName,
            component: ExternalLinkDisplayComponent,
        }));

        setExternalLinksTabs(linkTabsList || []);
    };

    const onTabChanged = (e, newTabIndex) => {
        setActiveTabIndex(newTabIndex);
        const clickedTabURL = allExternalLinks[newTabIndex].url;
        setActiveTabURL(clickedTabURL);
    };

    useEffect(() => {
        getAllExternalLinks();
    }, []);

    useEffect(() => {
        if (allExternalLinks && allExternalLinks.length > 0) {
            getExternalLinksTabs();

            const firstTabURL = allExternalLinks[0].url;
            setActiveTabURL(firstTabURL);
        }
    }, [allExternalLinks]);

    return (
      <div className='external-link'>
        {(externalLinksTabs && externalLinksTabs.length > 0) && (
        <div className='mt-2 px-2'>
          <TabsComponent
            data={externalLinksTabs}
            labelInput='label'
            themeClasses='theme-solid'
            dynamicComponentProps={{
                            URL: activeTabURL,
                        }}
            currentTab={activeTabIndex}
            onTabChanged={onTabChanged}
          />
        </div>
            ) || <NoContentComponent />}
      </div>
    );
};
