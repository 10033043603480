import { HttpServices } from "../../Helper";
import { config } from "../../config";

export const CreatePropertyVicinity = async (body) => {
  try {
    const result = await HttpServices.post(
      `${config.server_address}/CrmDfm/Properties/CreatePropertyVicinity`,
      body
    );
    return result;
  } catch (error) {
    return error.response;
  }
};

export const GetAllPropertyVicinitiesAPI = async ({
  propertyId,
  pageIndex,
  pageSize,
}) => {
  try {
    const result = await HttpServices.get(
      `${config.server_address}/CrmDfm/Properties/GetAllPropertyVicinityByPropertyId/${propertyId}/${pageIndex}/${pageSize}`
    );
    return result;
  } catch (error) {
    return error.response;
  }
};

export const UpdatePropertyVicinity = async (vicinity) => {
  try {
    const result = await HttpServices.put(
      `${config.server_address}/CrmDfm/Properties/UpdatePropertyVicinity/${vicinity.propertyVicinityId}`,
      vicinity
    );
    return result;
  } catch (error) {
    return error.response;
  }
};
export const DeletePropertyVicinity = async (id) => {
  try {
    const result = await HttpServices.delete(
      `${config.server_address}/CrmDfm/Properties/DeletePropertyVicinity/${id}`
    );
    return result;
  } catch (error) {
    return error.response;
  }
};
