import React, { useEffect, useState } from "react";
import {
  AutocompleteComponent,
  Inputs,
  SelectComponet,
} from "../../../../../../Components";
import {
  ReminderAmountOfTimeEnum,
  ReminderForEnum,
  ReminderToEnum,
  ReminderTypesEnum,
  TemplateCategoryEnum,
} from "../../../../../../Enums";
import { GetAllSystemTemplateByCategoryId } from "../../../../../../Services";
import { useTranslation } from "react-i18next";

const roles = [
  { value: 1, text: "Client" },
  { value: 2, text: "Agent" },
  { value: 3, text: "Creator" },
  { value: 4, text: "TeamLead" },
];

export const ActivityReminders = ({
  parentTranslationPath,
  translationPath,
  setState,
  state,
}) => {
  const isActivityBuilder =
    window.location.pathname === "/home/activity-builder";
  const initialReminder = state?.activityTypeReminders?.[0];
  const [reminderTemplates, setReminderTemplates] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState(
    {
      templateId: initialReminder?.templateId,
      templateName: initialReminder?.templateName,
    } ?? null
  );

  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation(parentTranslationPath);
  const [reminder, setReminder] = useState(
    initialReminder ?? {
      reminderTo: null,
      reminderType: null,
      templateId: null,
      reminderTime: 0,
      reminderFrequency: null,
    }
  );

  const getAllSystemTemplateByCategoryId = async () => {
    setIsLoading(true);
    const res = await GetAllSystemTemplateByCategoryId(
      TemplateCategoryEnum.Activity.key
    );
    if (!(res?.status && res.status !== 200)) {
      const mappedTemplatesData =
        res &&
        res.map((item) => ({
          templateName: item.systemTemplateName,
          templateId: item.systemTemplateId,
        }));
      setReminderTemplates(mappedTemplatesData || []);
    }

    setIsLoading(false);
  };
  useEffect(() => {
    getAllSystemTemplateByCategoryId();
  }, []);

  const getReminderTypeOptions = (reminderTo) => {
    let reminderTypeOptions = [];

    if (reminderTo !== ReminderForEnum.Lead.key)
      reminderTypeOptions = Object.values(ReminderTypesEnum);
    if (reminderTo === ReminderForEnum.Lead.key) {
      reminderTypeOptions.push(ReminderTypesEnum.Email);
      reminderTypeOptions.push(ReminderTypesEnum.Sms);
    }
    return reminderTypeOptions;
  };

  useEffect(() => {
    setState(reminder);
  }, [reminder]);

  return (
    <div>
      <SelectComponet
        data={ReminderToEnum}
        textInput={"text"}
        valueInput={"value"}
        labelValue={"reminder-to"}
        emptyItem={{
          value: null,
          text: "reminder-to",
          isDisabled: false,
        }}
        value={reminder?.reminderTo}
        onSelectChanged={(value) =>
          setReminder((current) => ({ ...current, reminderTo: value }))
        }
        translationPathForData={translationPath}
        parentTranslationPath={parentTranslationPath}
        translationPath={translationPath}
      />

      <SelectComponet
        idRef={`reminderWayRef`}
        data={getReminderTypeOptions(reminder.reminderTo)}
        value={reminder.reminderType}
        valueInput="key"
        textInput="value"
        onSelectChanged={(value) =>
          setReminder((current) => ({ ...current, reminderType: +value }))
        }
        isDisabled={reminder.reminderTo === null}
        translationPathForData={translationPath}
        parentTranslationPath={parentTranslationPath}
        translationPath={translationPath}
        placeholder="reminder-type"
        emptyItem={{
          value: null,
          text: "reminder-type",
          isDisabled: false,
        }}
        wrapperClasses="mt-3"
      />

      <AutocompleteComponent
        idRef="reminderTemplateRef"
        selectedValues={selectedTemplate}
        multiple={false}
        data={reminderTemplates || []}
        displayLabel={(option) => t(`${option.templateName || ""}`)}
        getOptionSelected={(option) =>
          option.templateId === selectedTemplate?.templateId
        }
        wrapperClasses="mt-3"
        withoutSearchButton
        inputPlaceholder={t(`${translationPath}Template`)}
        isLoading={isLoading}
        isWithError
        parentTranslationPath={parentTranslationPath}
        translationPath={translationPath}
        onChange={(event, newValue) => {
          setSelectedTemplate(newValue);
          setReminder((current) => ({
            ...current,
            templateId: newValue?.templateId,
          }));
        }}
      />
      <Inputs
        idRef="reminderTimeRef"
        value={reminder.reminderTime || 0}
        wrapperClasses="mt-3"
        endAdornment={
          <SelectComponet
            data={ReminderAmountOfTimeEnum}
            value={reminder?.reminderFrequency ?? 1}
            valueInput="key"
            textInput="value"
            onSelectChanged={(value) => {
              setReminder((current) => ({
                ...current,
                reminderFrequency: +value,
              }));
            }}
            wrapperClasses="over-input-select w-auto"
            idRef="timeAmountTypeRef"
            parentTranslationPath={parentTranslationPath}
            translationPath={translationPath}
            translationPathForData={translationPath}
          />
        }
        type="number"
        min={0}
        isWithError
        parentTranslationPath={parentTranslationPath}
        translationPath={translationPath}
        onInputChanged={(event) => {
          const value = +event.target.value ?? 1;
          setReminder((current) => ({
            ...current,
            reminderTime: value,
          }));
        }}
      />
    </div>
  );
};
