export const ExpectedRoiEnum = [
  {
    key: 1,
    value: '5-6',
  },
  {
    key: 2,
    value: '6-7',
  },
  {
    key: 3,
    value: '7-8',
  },
  {
    key: 4,
    value: '8-9',
  },
  {
    key: 5,
    value: '9-10',
  },
  {
    key: 6,
    value: '10+',
  },
];
