export const BranchesPermissions = {
   GetAllBranches: 
   {
     permissionsId:'dec7d181-4398-4e90-9619-08da36560604',
     permissionsName :'Get All Branches',
     componentsId:null,
     components:null
    },
   AddNewBranch: {
      permissionsId :'bb8ccc59-daf5-4897-961a-08da36560604',
      permissionsName:'Add New Branch',
      componentsId:null,
      components:null
    },
   UpdateBranch : {
     permissionsId:'fce1f80e-9f33-4142-961b-08da36560604',
     permissionsName:'Update Branch',
     componentsId:null,
     components:null
    },
    SetBranchAsActiveOrInactive : {
       permissionsId:'a5ed0ca4-6816-4472-961c-08da36560604',
       permissionsName:'Set Branch As Active Or Inactive',
       componentsId:null,
       components:null
    },
   GetBranchById:
     {
       permissionsId:'b7f29469-6af9-419e-961d-08da36560604',
       permissionsName:'Get Branch By Id',
       componentsId:null,
       components:null
     }  , 
  ViewDocuments : {
    permissionsId: "aee2253b-e4db-449d-c4c8-08dc683b0c43",
    permissionsName: "View Documents",
    description: null,
    componentsId: null,
    components: null
   },
   AddDocuments : {
     permissionsId: "a4735708-958d-48b2-c4c9-08dc683b0c43",
      permissionsName: "Add Documents",
      description: null,
      componentsId: null,
      components: null
  },
  EditDocuments : {
      permissionsId: "1b862324-1a25-4c64-c4ca-08dc683b0c43",
      permissionsName: "Edit Documents",
      description: null,
      componentsId: null,
      components: null
  },
  DeleteDocuments :{
      permissionsId: "446e029c-8bc6-434d-c4cb-08dc683b0c43",
      permissionsName: "Delete Documents",
      description: null,
      componentsId: null ,
      components: null ,
  } 
};
