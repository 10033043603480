import React, { useCallback, useEffect, useReducer, useState } from "react";
import { GetConsentMonitoring } from "../../../../Services";
import { useTranslation } from "react-i18next";
import { PaginationComponent, Tables } from "../../../../Components";
import { ConsentLogHeadData } from "./ConsentLogHeadData";
import { bottomBoxComponentUpdate } from "../../../../Helper";
import ConsentLogFilters from "./ConsentLogFilters";

const parentTranslationPath = "ConsentLog";
const translationPath = "";
function ConsentLogView() {
  const { t } = useTranslation(parentTranslationPath);
  const reducer = useCallback((state, action) => {
    if (action.id !== "edit") return { ...state, [action.id]: action.value };
    return {
      ...action.value,
    };
  }, []);
  const [data, setData] = useState({});
  const [filter, setFilter] = useReducer(reducer, {
    pageIndex: 0,
    pageSize: 25,
    filterBy: "createdOn",
    orderBy: 1,
    fromDate: null,
    toDate: null,
    consentChannel: null,
    senderName: null,
    responseReceived: null,
  });
  const [isLoading, setIsLoading] = useState(false);

  const getLogs = async () => {
    setIsLoading(true);
    const res = await GetConsentMonitoring({
      ...filter,
      fromDate: filter.fromDate || filter.startDate,
      toDate: filter.toDate || filter.endDate,
      pageIndex: filter.pageIndex + 1,
    });

    if (!(res && res.status && res.status !== 200)) {
      setData(res);
    } else {
      setData({});
    }
    setIsLoading(false);
  };

  const onPageIndexChanged = (pageIndex) => {
    setFilter({ id: "pageIndex", value: pageIndex });
  };
  const onPageSizeChanged = (pageSize) => {
    setFilter({ id: "pageSize", value: pageSize });
    localStorage.setItem("Pagination", pageSize);
  };

  useEffect(() => {
    getLogs();
  }, [filter]);

  useEffect(() => {
    bottomBoxComponentUpdate(
      <PaginationComponent
        pageIndex={filter?.pageIndex}
        pageSize={filter?.pageSize}
        totalCount={data?.totalCount}
        onPageIndexChanged={onPageIndexChanged}
        onPageSizeChanged={onPageSizeChanged}
      />
    );
  });

  return (
    <React.Fragment>
      <div className="mx-2 my-2">
        <ConsentLogFilters
          filter={filter}
          setFilter={setFilter}
          translationPath={translationPath}
          parentTranslationPath={parentTranslationPath}
        />
        <Tables
          data={data.result || []}
          headerData={ConsentLogHeadData || []}
          isLoading={isLoading}
          FilterDisabledButton
          onPageIndexChanged={onPageIndexChanged}
          onPageSizeChanged={onPageSizeChanged}
          actionsOptions={[]}
          defaultActions={[]}
          activePage={filter?.pageIndex}
          itemsPerPage={filter?.pageSize}
          translationPath={translationPath}
          parentTranslationPath={parentTranslationPath}
        />
      </div>
    </React.Fragment>
  );
}

export default ConsentLogView;
