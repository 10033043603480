import { makeStyles } from "@material-ui/core";
export default makeStyles((theme) => {
  return {
    containerCard_Assign_Agent: {
      width: "314px",
      display: "flex",
      flexDirection: "row",
      gap: "16px",
      padding: "20px 16px",
      alignItems: "center",
    },
    container_personalInfo: {
      display: "flex",
      flexDirection: "column",
      width: "180px",
      justifyContent: "center",
      [theme.breakpoints.down(321)]: {
        width: "150px",
      },
    },
    name: {
      lineHeight: "28px",
      fontSize: "18px",
      fontWeight: "600",
      color: theme.palette.text.secondary,
    },
    job_Title: {
      lineHeight: "24px",
      fontSize: "16px",
      fontWeight: "400",
      color: theme.palette.text.secondary,
    },
    containerAvatar: {
      alignItems: "center",
      backgroundColor: "#98A2B324",
      borderRadius: "50%",
      boxShadow:
        "0px 4px 8px -2px rgba(16, 24, 40, 0.10), 0px 2px 4px -2px rgba(16, 24, 40, 0.06)",
      display: "flex",
      height: "56px",
      justifyContent: "center",
      width: "56px",
    },
    defultAvatarStyle: {
      backgroundColor: "#F2F4F7", 
      border: `4px solid ${theme.palette.border.secondary}`,
      height: "72px",
      width: "72px",
      "& img": {
        height: "36px",
        width: "36px",
      },
    },
    AvatarStyle: {
      height: "72px",
      width: "72px",
      border: `4px solid ${theme.palette.border.secondary}`,
    },
  };
});
