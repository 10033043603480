
import React, { useState , useCallback  , useEffect}  from 'react';
import { useTranslation } from 'react-i18next';
import Rating from '@material-ui/lab/Rating';
import { SwitchComponent } from '../../../../../../Components' ; 

  export const ValetParking = ({
    parentTranslationPath ,
    translationPath ,
    onChangeSelectedItem , 
    selectedValues ,
    setSelectValues ,
   } ) => { 

    const { t } = useTranslation(parentTranslationPath);

    return (
      <>
        <div className="">
        <span className="fw-bold"> {t(`${translationPath}valet-parking`)}</span>
        </div> 
        <div className='w-75 parking-distribution'>
        <div className="d-flex d-flex-v-center parking-distribution-card mt-2 mb-2">
            <div>
            </div>
            <div>
            <Rating  
                size="large"
                name={`RatingValetParking`}
                value={(selectedValues &&  selectedValues.valetParking && selectedValues.valetParking.score) || 0}
                max={10}
                precision={1}
                onChange={(event, newValue) => 
                 {
                  onChangeSelectedItem((selectedValues.valetParking?.lookupItemName) || 'valet Parking' , newValue || 0 );
                  const newUpdateObj = selectedValues.valetParking ? {...selectedValues.valetParking , score : (newValue || 0 ) } : null
                  setSelectValues((item)=> ({...item , valetParking : newUpdateObj }))
                 }}
                />     
            </div>
         </div>  
        </div>
      </>
    );
  } ; 
  
