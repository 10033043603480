import React, { useEffect, useRef } from 'react';
import { GoogleMapsComponent } from '../../Components';
import { bottomBoxComponentUpdate } from '../../Helper';

const UnitsMapView = ({
  locations,
  setMap,
  location,
  onPinClick,
  isLoading,
  totalCount,
  totalUnitsCount,
  stopChangeCapturing,
}) => {
  const scaleChangeTimer = useRef();

  useEffect(() => {
    bottomBoxComponentUpdate();
  }, []);
  const scaleChangeHandler = (newMap) => {
    if (scaleChangeTimer.current) {
      clearTimeout(scaleChangeTimer.current);
      scaleChangeTimer.current = undefined;
    }
    scaleChangeTimer.current = setTimeout(() => setMap(newMap), 1500);
  };
  useEffect(() => {
    setMap({
      Lat: '24.414833592365972',
      Long: '54.59777364239554',
      ZoomScale: '2',
    });
  }, []);

  const Content = ({ children }) => {
    return (
      <div className='d-flex d-flex-v-center fj-end px-3 w-50'>{children}</div>
    );
  };
  return (
    <div>
      <GoogleMapsComponent
        propertyView
        forUnits
        hideLatitudeLongitude
        onScaleChange={scaleChangeHandler}
        locations={locations ?? []}
        onPinClick={onPinClick}
        reCenterOption
        searchPlace={location}
        isLoading={isLoading}
        loadingIndicator
        loadingOnContent={
          <Content>
            <strong>loading....</strong>
          </Content>
        }
        loadingOffContent={
          <Content>
            <strong>{`${totalCount} properties ${totalUnitsCount ? `and ${totalUnitsCount} units` : ''}`}</strong>
          </Content>
        }
        stopChangeCapturing={stopChangeCapturing}
      />
    </div>
  );
};

export default UnitsMapView;
