/* eslint-disable react/prop-types */
import React, { useCallback, useEffect, useState } from 'react';
import { ButtonBase, Button, Tooltip } from '@material-ui/core';
import { PropTypes } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { getDownloadableLink, showinfo } from '../../../../Helper';
import { LoadableImageComponant, Spinner } from '../../../../Components';
import { ActionsEnum } from '../../../../Enums';
import { DefaultImagesEnum } from '../../../../Enums/DefaultImages.Enum';
import { GetActivityById, GetAllActivitiesByLeadId, GetAllActivities } from '../../../../Services';
import { TableActions } from '../../../../Enums/TableActions.Enum';
import { ReplyActivityDialog } from '../../ActivitiesView/ReplyActivitesView/ReplyActivityDialog';
import '../style/CardDetailsComponent.scss'
import moment from 'moment';
import { ActivitiesManagementDialog } from '../../ActivitiesSalesView/ActivitiesSalesViewUtilities/Dialogs/ActivitiesManagementDialog';
import {CopyToClipboardComponents} from '../../../../ReusableComponents/UtilityComponents/CopyToClipboardComponents/CopyToClipboardComponents';

function CardDetailsComponent({
  activeData,
  cardDetailsActionClicked,
  translationPath,
  parentTranslationPath,
}) {

  const { t } = useTranslation(parentTranslationPath);
  const [isLoading, setIsLoading] = useState(false);
  const [activeItem, setActiveItem] = useState(null);
  const [hide, sethide] = useState(false);
  const [edit, setIsEdit] = useState(false);
  const [open, setOpen] = useState(false);
  const [openReply, setOpenReply] = useState(false);
  const [qaInfo, setQaInfo] = useState(true);
  const [activityBtn, setActivityBtn] = useState(false);
  const [activities, setActivities] = useState([]);

  const handleActiveItem = useCallback(async (activityId, key) => {
    if (key === TableActions.replyText.key) {
      setOpenReply(true);
      setIsLoading(true);
      const res = await GetActivityById(activityId);
      if (!(res && res.status && res.status !== 200)) {
        setActiveItem(res);
        setIsLoading(false);
      }
    } else {
      setOpen(true);
      setIsEdit(true);
      setIsLoading(true);
      const res = await GetActivityById(activityId);
      if (!(res && res.status && res.status !== 200)) setActiveItem(res);
      setIsLoading(false);
    }
  }, []);

  const getActivities = useCallback(async () => {
    setIsLoading(true);
    const body = {
      pageIndex: 1,
      pageSize: 25,
      relatedLeadId: activeData?.leadId
    }
    const result = await GetAllActivities(body);
    if (!(result && result.status && result.status !== 200)) setActivities(result.result);
    else setActivities([]);
    setIsLoading(false);
  }, [activeData && activeData.leadId]);

  useEffect(() => {
    getActivities();
  }, [getActivities]);

  useEffect(() => {
    sethide(false);
  }, [activeData && activeData.leadId]);


  return (
    <div className="qa-details-wrapper">
      <div className='leads-card-detaild-wrapper'>
        <Spinner isActive={isLoading} isAbsolute={true} />
        <div className='side-menu-wrapper mb-3'>
          <div className='items-title mb-3 d-flex-center'>
            {t(`${translationPath}QA-detalis`)}
          </div>
          <div className='d-flex-center mb-3'>
            <LoadableImageComponant
              classes='details-img'
              alt={t(`${translationPath}contact-image`)}
              src={
                (activeData && activeData.contactImage && activeData.contactImage.fileId) ?
                getDownloadableLink(activeData && activeData.contactImage.fileId ,200 ,20) :
                  DefaultImagesEnum.man.defaultImg
              }
            />
          </div>
          <div className='properety-plan d-flex-center mb-3'>
            {activeData && activeData.contactName}
          </div>
          <div className='copy-id'>
            <div>
              <span className='texts gray-primary-bold'>
                {t(`${translationPath}leadId`)}
                :
              </span>


            </div>



            <Tooltip title={t(`${translationPath}copy`)}>
              <CopyToClipboardComponents
                data={activeData && activeData.leadId}
                childrenData={activeData && activeData.leadId}
              />
            </Tooltip>



          </div>

          <div className='mb-3 '>
            <div className='data-schedule-button'>
              <div className={`data-file-button ${qaInfo ? 'selected' : ''}`}>
                <Button
                  id='qa-Info-ref'
                  onClick={() => {
                    setActivityBtn(false);
                    setQaInfo(true);
                  }}
                  className={`btns ${qaInfo ? 'theme-outline' : ''}`}
                >
                  {t(`${translationPath}qa-Info`)}
                </Button>
              </div>
              <div className={`schedule-button ${activityBtn ? 'selected' : ''}`}>
                <Button
                  id='activity-details-ref'
                  onClick={() => {
                    setActivityBtn(true);
                    setQaInfo(false);
                  }}
                  className={`btns ${activityBtn ? 'theme-outline' : ''}`}
                >
                  {t(`${translationPath}activity-details`)}
                </Button>
              </div>
            </div>
          </div>

          {qaInfo && (
            <div className='px-3 mb-3 slider-data'>
              <div className='qaInfo-wrapper'>
                <div className='d-flex mb-3'>
                  <span className='date mr-1 texts gray-primary-bold'>
                    <span className='mdi mdi-email-outline mr-2 ' />
                  </span>
                  <span className='date texts s-gray-primary'>{activeData && activeData.leadEmail || 'N/A'}</span>
                </div>
                <div style={{ display: "flex", justifyContent: "space-around" }}>
                  <div>
                    <div className='mb-3'>
                      <span className='texts gray-primary-bold'>
                        {t(`${translationPath}buyers`)}
                        :
                      </span>
                      <span className='texts s-gray-primary'>{`  ${activeData && activeData.buyerCount}`}</span>
                    </div>
                    <div className='mb-3'>
                      <span className='texts gray-primary-bold'>
                        {t(`${translationPath}sellers`)}
                        :
                      </span>
                      <span className='texts s-gray-primary'>{`  ${activeData && activeData.sellerCount}`}</span>
                    </div>
                  </div>
                  <div>
                    <div className='mb-3'>
                      <span className='texts gray-primary-bold'>
                        {t(`${translationPath}landlords`)}
                        :
                      </span>
                      <span className='texts s-gray-primary'>{`  ${activeData && activeData.landlordCount}`}</span>
                    </div>
                    <div className='mb-3'>
                      <span className='texts gray-primary-bold'>
                        {t(`${translationPath}tenants`)}
                        :
                      </span>
                      <span className='texts s-gray-primary'>{`  ${activeData && activeData.tenantCount}`}</span>
                    </div>
                  </div>
                </div>
                <div className='allDetails'>
                  <div className='totalTransactionContainer'>
                    <div className='totalTransaction'>{t('total-transaction')}</div>
                    <div className='leaseAndSale'>
                      <span className='mdi mdi-circle-medium '>
                        {`${activeData && (activeData.landlordCount + activeData.tenantCount)} ${t('lease')}`}
                      </span>
                      <span className='mdi mdi-circle-medium '>
                        {`${activeData && (activeData.sellerCount + activeData.buyerCount)} ${t('sale')}`}
                      </span>
                    </div>
                  </div>


                  <div className='view-all-data'>
                    <div>
                      <div className='title'>{t('last-activity')}</div>
                      <div className='detailsLastActivity' onClick={() => {
                        handleActiveItem(activeData && activeData.activityId)
                      }}>
                        <div className='last-activity-div'>
                          <div className='first-call-1'>
                            <span>
                              {' '}
                              {activeData && activeData.activityTypeName}
                            </span>
                          </div>
                          <div className='first-call-2'>
                            <span>
                              {' '}
                              {`${t('Id')}: ${activeData && activeData.activityId}`}
                            </span>
                          </div>
                        </div>
                        <div className='last-activity-description'>
                          <div className='last-activity-name'>
                            <span className='agent-name'>{t('agent-name')}</span>
                            <span className='agent-name-value'>{activeData && activeData.assignTo}</span>
                          </div>
                          <div className='last-activity-type'>
                            <span className='date'>{t('date')}</span>
                            <span className='date-value'>
                              {moment(activeData && activeData.activityDate).format('DD, MMM YYYY')}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          {activityBtn && (
            <div className="px-3 mb-3 slider-data">
              <div className='mb-3'>
                {activities && activities.map((element) =>
                  <div className='cards-wrapper'>
                    <div>{element.isOpen ? <span className='open-status'>{t(`${translationPath}open`)}</span> : <span className='close-status'>{t(`${translationPath}close`)}</span>}</div>
                    <div className='mb-3'>
                      <span className='mr-1 texts gray-primary-bold'>
                        {t(`${translationPath}assigned-to `)}
                        :
                      </span>
                      <span className='texts s-gray-primary'>{`${element.agentName || ''}`}</span>
                    </div>

                    <div className='date mb-3'>
                      <span className='date mr-1 texts gray-primary-bold'>
                        <span className='details-icon mdi mdi-calendar mdi-18px mr-1 ' />
                      </span>
                      <span className='date texts s-gray-primary'>{`${moment(element.activityDate).format("DD/MM/YYYY")}`}</span>
                      <span className='date mr-1 texts gray-primary-bold'>
                        <span className='date mdi mdi-clock-time-four-outline ml-4 mr-1' />
                      </span>
                      <span className='date texts s-gray-primary'>{`${moment(element.activityDate).format('LT')}`}</span>
                    </div>

                    <div className='mb-3'>
                      <span className='mr-1 texts gray-primary-bold'>
                        {t(`${translationPath}created-by`)}
                        :
                      </span>
                      <span className='texts s-gray-primary'>{`${element.createdByName || ''}`}</span>
                    </div>
                    <div className='mb-3'>
                      <span className='mr-1 texts gray-primary-bold'>
                        {t(`${translationPath}category`)}
                        :
                      </span>
                      <span className='texts s-gray-primary'>{`${element.categoryName || ''}`}</span>
                    </div>
                    <div className='mb-3'>
                      <span className='mr-1 texts gray-primary-bold'>
                        {t(`${translationPath}activityName`)}
                        :
                      </span>
                      <span className='texts s-gray-primary'>{`${element.activityTypeName || ''}`}</span>
                    </div>
                    <div className='mb-3'>
                      <span className='mr-1 texts gray-primary-bold'>
                        {t(`${translationPath}comments`)}
                        :
                      </span>
                      <span className='texts s-gray-primary'>{`${element.comments || ''}`}</span>
                    </div>

                  </div>
                )}
              </div>
            </div>
          )}
        </div>
        <div className='side-menu-actions'>
          <div className='d-flex-center'>
            <ButtonBase
              id='open-file-ref'
              className='btns theme-solid mx-2 mb-0 w-50'
              onClick={cardDetailsActionClicked(ActionsEnum.reportEdit.key, activeData)}
            >
              <span className='icons i-folder-white' />
              <span className='mx-2'>{t(`${translationPath}open-file`)}</span>
            </ButtonBase>
            <div className='cards-footer-wrapper'>
              <ButtonBase
                id='reply-ref'
                className='btns theme-transparent w-50 mx-2'
                disabled={!activeData.isReplyAble}
                onClick={() => {
                  handleActiveItem(activeData && activeData.activityId, TableActions.replyText.key);
                }}
              >
                <span className='mdi mdi-reply-outline' />
                <span className='px-1'>{t('reply')}</span>
              </ButtonBase>
            </div>
          </div>
        </div>
      </div>

      {openReply && (
        <ReplyActivityDialog
          isLoading={isLoading}
          open={openReply}
          close={() => {
            setActiveItem(null);
            setOpenReply(false);
          }}
          activeItem={activeItem}
          onSave={() => {
            setOpenReply(false);
            setActiveItem(null);
          }}
          translationPath={translationPath}
          parentTranslationPath='ActivitiesView'
        />
      )}
      {open && (
        <ActivitiesManagementDialog
          open={open}
          activeItem={activeItem}
          isLoading={isLoading}
          isEdit={edit}
          onSave={() => {
            setOpen(false);
            setActiveItem(null);
            setIsEdit(false);
          }}
          close={() => {
            setActiveItem(null);
            setOpen(false);
            setIsEdit(false);
          }}
          translationPath={translationPath}
          parentTranslationPath='ActivitiesView'
        />
      )}
    </div>

  );
}

CardDetailsComponent.propTypes = {

  activeData: PropTypes.instanceOf(Object),
  relodedata: PropTypes.func,
};
CardDetailsComponent.defaultProps = {
  activeData: null,
  cardDetailsActionClicked: () => { },
};

export { CardDetailsComponent };
