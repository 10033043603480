import React from "react";
import PropTypes from "prop-types";

import "./CustomBadge.scss";

const CustomBadge = ({
  Avatar,
  BackgroundColor,
  BorderColor,
  Color,
  DeleteIcon,
  RightIcon,
  LeftIcon,
  Icon,
  label,
  SizeVariant,
  Style,
  isFilterBadge,
  handleOnDelete,
}) => {
  const badgeStyles = {
    ...Style,
    backgroundColor: BackgroundColor,
    borderColor: BorderColor,
    color: Color,
  };

  return isFilterBadge ? (
    <div
      className={`badge medium ${DeleteIcon ? "deleteIcon" : ""} filter
      `}
      style={{ ...badgeStyles, backgroundColor: "transparent" }}
    >
      <div className="filterBadgeText">
        {label[0] && <span style={{ fontWeight: 700 }}>{label[0]}</span>}

        <div
          className="badge medium"
          style={{ ...badgeStyles, padding: "4px 12px", BackgroundColor }}
        >
          {label[1] && <span style={{ fontWeight: 500 }}>{label[1]}</span>}
        </div>

        {label[2] && (
          <span style={{ fontSize: "16px", fontWeight: 500 }}>{label[2]}</span>
        )}
      </div>

      {DeleteIcon && (
        <div
          className="badge-delete-icon"
          onClick={handleOnDelete || (() => {})}
        >
          {DeleteIcon}
        </div>
      )}
    </div>
  ) : (
    <div
      className={`badge ${SizeVariant} ${LeftIcon ? "leftIcon" : ""}
    ${Icon ? "icon" : ""}
      ${RightIcon ? "rightIcon" : ""}${DeleteIcon ? "deleteIcon" : ""}
      ${Avatar ? "avatar" : ""}
    `}
      style={badgeStyles}
    >
      {Avatar && <div className="badge-avatar">{Avatar}</div>}
      {Icon && <div className="badge-icon">{Icon}</div>}
      {LeftIcon && <div className="badge-icon">{LeftIcon}</div>}
      {label && <span>{label}</span>}
      {RightIcon && <div className="badge-icon">{RightIcon}</div>}
      {DeleteIcon && <div className="badge-delete-icon">{DeleteIcon}</div>}
    </div>
  );
};

export default CustomBadge;

CustomBadge.propTypes = {
  Color: PropTypes.string.isRequired,
  BorderColor: PropTypes.string.isRequired,
  BackgroundColor: PropTypes.string,
  Avatar: PropTypes.node,
  Icon: PropTypes.node,
  DeleteIcon: PropTypes.node,
  RightIcon: PropTypes.node,
  LeftIcon: PropTypes.node,
  label: PropTypes.string,
  SizeVariant: PropTypes.oneOf(["small", "medium", "large"]),
  Style: PropTypes.instanceOf(Object),
  isFilterBadge: PropTypes.bool,
  handleOnDelete: PropTypes.func,
};

CustomBadge.defaultProps = {
  SizeVariant: "medium",
  BackgroundColor: "transparent",
  Avatar: null,
  Icon: null,
  DeleteIcon: null,
  RightIcon: null,
  LeftIcon: null,
  label: "",
  Style: undefined,
  isFilterBadge: false,
};
