import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => {
  return {
    gridContainer: {
      display: "flex",
      flex: "1 1 100%",
      flexWrap: "wrap",
      gap: "18px",
      justifyContent: "flex-start",
      [theme.breakpoints.down("sm")]: {
        justifyContent: "center",
      },
    },
    flex: {
      display: "flex",
      gap: "12px",
      alignItems: "center",
      justifyContent: "flex-end",
      marginBottom: "24px",
    },
    customSelect: {
      width: "101px",
    },
    container: {
      display: "flex",
      gap: "12px",
      alignItems: "center",
    },
    galleryImg: {
      border: `1px solid ${theme.palette.border.secondary}`,
      width: "63px",
      height: "40px",
      borderRadius: "6px",

      "& img": {
        objectFit: "contain",
      },
    },
    detailsColumn: {
      display: "flex",
      flexDirection: "column",
    },
    nameRow: {
      display: "flex",
      alignItems: "center",
      gap: "6px",
      cursor: "pointer",
    },
    nameText: {
      lineHeight: "24px",
      fontSize: "16px",
      fontWeight: 600,
      color: theme.palette.text.primary,
    },
    communityText: {
      lineHeight: "20px",
      fontSize: "14px",
      fontWeight: 500,
      margin: 0,
    },
    unitDetails: {
      display: "flex",
      gap: "8px",
      flexWrap: "wrap",
    },
    detailItem: {
      display: "flex",
      alignItems: "center",
      gap: "4px",
      fontSize: "14px",
      fontWeight: 500,
      color: theme.palette.text.tertiary,
    },
  };
});
