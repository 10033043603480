import React from "react";

function CornerUpLeft({ fill, ...restProps }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill={fill ?? "none"}
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <path
        d="M8.64 3.068c-.155.053-.547.427-2.793 2.667-1.479 1.474-2.659 2.683-2.72 2.785-.091.153-.107.225-.106.48 0 .212.022.341.074.44.097.185 5.197 5.297 5.413 5.425.459.274 1.079.103 1.359-.373.097-.165.113-.234.112-.492 0-.212-.022-.341-.074-.44-.04-.077-.841-.911-1.779-1.854L6.42 9.993l3.68.021c4.138.023 4.553.045 5.488.288 1.401.363 2.777 1.755 3.125 3.158.209.843.25 1.481.281 4.403l.026 2.443.121.197a.998.998 0 0 0 1.718 0l.121-.197.012-1.883c.027-4.09-.137-5.423-.824-6.715a6.964 6.964 0 0 0-1.921-2.269c-1.137-.854-2.151-1.203-3.947-1.361-.266-.024-2.125-.053-4.174-.065L6.432 7.99l1.7-1.705c.935-.938 1.733-1.768 1.773-1.845.052-.099.074-.228.074-.44.001-.255-.015-.328-.107-.484a1.016 1.016 0 0 0-1.232-.448"
        fill-rule="evenodd"
        fill={fill ?? "none"}
      />
    </svg>
  );
}

export default CornerUpLeft;
