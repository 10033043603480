export const ProjectStatusEnum = [
  {
    key: 1,
    value: 'off-plan',
  },
  {
    key: 2,
    value: 'completed',
  },
  {
    key: 3,
    value: 'Pre-Launch',
  }
];
