import React from 'react';
import { DialogComponent } from '../../../../../Components';
import './AudioPlayer.scss';

function AudioPlayer({ audioLink, onClose }) {
  return (
    <DialogComponent
      maxWidth='sm'
      isOpen
      titleText='listen-to-call'
      onCloseClicked={onClose}
      parentTranslationPath={'Agents'}
      dialogContent={
        <div className='d-flex-column-center audio-player-container mb-3'>
          <audio controls>
            <source src={audioLink} type='audio/mpeg' />
            <track kind='captions' src='captions.vtt' />
            Your browser does not support the audio element.
          </audio>
        </div>
      }
    />
  );
}

export default AudioPlayer;
