import { HttpServices } from '../../Helper';
import { config } from '../../config';

export const GetAllKeysNearby = async () => {

    const result = await HttpServices.get(
        `${config.server_address}/CrmDfm/PropertyRating/GetAllKeysNearby`)
        .then((data) => data)
        .catch((error) => error.response);
    return result;
  };
  
  export const UpdateKeysNearby = async (body) => {
    const result = await HttpServices.put(
        `${config.server_address}/CrmDfm/PropertyRating/UpdateKeysNearby`, body
    )
        .then((data) => data)
        .catch((error) => error.response.data);
    return result;
};