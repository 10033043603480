import React, {
  useState,
  useEffect,
  useReducer,
  useRef,
  useCallback,
} from "react";
import { useTranslation } from "react-i18next";
import Joi from 'joi';
import PropTypes from "prop-types";
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Dialog,
} from "@material-ui/core";
import {
  Spinner,
  Inputs,
} from "../../../../../../Components";
import { showSuccess, showError, GetParams  , matchLinkedinUrl ,getErrorByName } from "../../../../../../Helper";
import { UpdateDeveloperEmployeeAPI  } from "../../../../../../Services";
import { FooterWrapperComponent } from "../../../DevelopersUtilities/Components";
import { ActivityFilesUploader } from "../../../../../../Components/UploaderActivitieFileComponent/ActivityFilesUploader";
import { Button } from 'react-bootstrap';
import { EmailComponent , MobileNumberComponent } from './Component/FieldsComponent' ; 

export const DeveloperEmployeesManagementDialog = ({
  parentTranslationPath,
  translationPath,
  isOpen,
  onClose,
  onSave,
  developerEmployeesValues,
  isReloadData ,
  communicationsIds 

}) => {
  const { t } = useTranslation(parentTranslationPath, "Shared");
  const [filter, setFilter] = useState({
  pageIndex: 0,
  pageSize: 25,
  search: "",
});
const [isSubmitted, setIsSubmitted] = useState(false) ; 
const [isLoading, setIsLoading] = useState({
  editDeveloperEmployee: false,
  infoDeveloperEmployee:false ,
});

const [defaultState, setDefaultState] = useState({
  developerContactId: +GetParams("id"),
  employeeName: null,
  jobTitle: null,
  linkedInProfile: null,
  employeePhoto: null, 
  files: null,
});

const [defaultSelected, setDefaultSelected] = useState({
  employeePhoto: null ,
  emailAddresses : [] ,
  mobileNumbers :  [] ,
});

const reducer = (select, action) => {
  if (action.id !== "edit") return { ...select, [action.id]: action.value };
  if (action.id === "edit") {
    return {
      ...action.value,
    };
  }
};

const reducer2 = (select, action) => {
  if (action.id !== "edit") return { ...select, [action.id]: action.value };
  if (action.id === "edit") {
    return {
      ...action.value,
    };
  }
};

const [state, setState] = useReducer(reducer, {
  ...defaultState,
});

const [selected, setSelected] = useReducer(reducer2, {
  ...defaultSelected,
});

const schema = Joi.object({
  employeeName: Joi.string()
    .required()
    .messages({
      'string.base': t(`${translationPath}employee-name-is-required`),
      'string.empty': t(`${translationPath}employee-name-is-required`),
      'string.required': t(`${translationPath}employee-name-is-required`),
    }),
})
  .options({
    abortEarly: false,
    allowUnknown: true,
  })
  .validate(state);


const checkIsValidValues = (key) => 
{
    if((key === 'linkedInProfile' || key === 'save' ) && state.linkedInProfile )
    return  !(matchLinkedinUrl(state.linkedInProfile || '')) ; 
  return  false  

};

const onChangeHandler = (key, values, selectedObj, selectedValues) => {
  if (!selectedObj) {
    setState({ id: [key], value: values });
  } else {
    setState({ id: [key], value: values });
    setSelected({ id: [selectedObj], value: selectedValues });
  }
};

const SaveUpdateDeveloperEmployeeHandler = async () => {
  setIsLoading((item) => ({ ...item, editDeveloperEmployee: true }));
  const body = { ...state , developerEmployeeCommunications : [...selected.emailAddresses , ...selected.mobileNumbers ]   , employeePhoto: ((state?.files && state.files.length > 0 && state.files[0].fileId) || null)};
  if (state.files) delete body.files;
  const res = await UpdateDeveloperEmployeeAPI(developerEmployeesValues?.employeeId,body);
  if (!(res && res.status && res.status !== 200)) {
    showSuccess(
      t`${translationPath}developer-employee-updated-successfully`
    );
    setIsLoading((item) => ({ ...item, editDeveloperEmployee: false }));
    onSave(res);
  } else {
    showError(t`${translationPath}developer-employee-update-failed`);
    setIsLoading((item) => ({ ...item, editDeveloperEmployee: false }));
  }
};
  
  useEffect(() => {
    if (developerEmployeesValues) {
      setIsLoading((item) => ({ ...item, info: true }));
      const updateState = {
        employeeId: developerEmployeesValues?.employeeId,
        employeeName  : developerEmployeesValues?.employeeName, 
        jobTitle :developerEmployeesValues?.jobTitle , 
        developerContactId: developerEmployeesValues?.developerContactId,
        linkedInProfile  : developerEmployeesValues.linkedInProfile  , 
        employeePhoto : developerEmployeesValues.employeePhoto  , 
        files:
          developerEmployeesValues.employeePhoto ?  
        [{ uuid: developerEmployeesValues.employeePhoto, isUploaded: true , fileName : `Employee Photo ${developerEmployeesValues.employeeId}.png` ,fileId : developerEmployeesValues.employeePhoto  }] : []       
           
      };
      const updateSelected = {
        emailAddresses : developerEmployeesValues.developerEmployeeCommunications && developerEmployeesValues.developerEmployeeCommunications.filter((e)=> e.communicationTypeId ===  communicationsIds.email).map((item) => ({
          communicationTypeId: item.communicationTypeId,
          communicationValue: item.communicationValue,
        })),
        mobileNumbers : developerEmployeesValues.developerEmployeeCommunications && developerEmployeesValues.developerEmployeeCommunications.filter((e)=> e.communicationTypeId ===  communicationsIds.mobile).map((item) => ({
          communicationTypeId: item.communicationTypeId,
          communicationValue: item.communicationValue,
        })),
        createdBy: developerEmployeesValues?.createdBy,
        createdOn: developerEmployeesValues?.createdOn,
      };
      setState({ id: "edit", value: updateState });
      setSelected({ id: "edit", value: updateSelected });
      setIsLoading((item) => ({ ...item, info: false }));
    }
  }, [developerEmployeesValues]);


  return (
    <div>
      <Spinner
        isActive={isLoading.editDeveloperEmployee || isLoading.info}
        isAbsolute
      />
      <Dialog
        open={isOpen}
        onClose={() => {
          onClose();
        }}
        className="activities-management-dialog-wrapper"
        maxWidth="sm"
        disableBackdropClick
      >
        <DialogTitle id="alert-dialog-slide-title">
          <span>{t(`${translationPath}edit-developer-employee`)}</span>
        </DialogTitle>
        <DialogContent>
        <div className="dialog-content-item">
            <Inputs
                isAttachedInput
                idRef={`employeeName${1}`}
                labelValue={"employee-name"}
                value={state.employeeName || ""}
                onInputChanged={(e) => {
                  const { value } = e.target;
                  onChangeHandler("employeeName", value);
                }}
                inputPlaceholder={"enter-employee-name"}
                translationPath={translationPath}
                parentTranslationPath={parentTranslationPath}
                helperText={getErrorByName(schema, 'employeeName').message}
                error={getErrorByName(schema, 'employeeName').error}
                isWithError
                isSubmitted={isSubmitted}
              />
              
            </div>
            <div className="dialog-content-item">
            <MobileNumberComponent
                translationPath={translationPath}
                parentTranslationPath={parentTranslationPath}
                setNumber={(data)=> 
                  setSelected(({id : 'mobileNumbers', value : data }))
                }
                mobileNumbers={selected.mobileNumbers}
                isReloadData={isReloadData}
                communicationId={communicationsIds.mobile}
              />
            </div>

            <div className="dialog-content-item">
            <EmailComponent
                translationPath={translationPath}
                parentTranslationPath={parentTranslationPath}
                setEmail={(data)=> 
                  setSelected(({id : 'emailAddresses', value : data }))
                }
                emailAddresses={selected.emailAddresses}
                isReloadData={isReloadData}
                communicationId={communicationsIds.email}

              />
            </div>
            
            <div className="dialog-content-item">
            <Inputs
                isAttachedInput
                idRef={`jobTitle${1}`}
                labelValue={"job-title"}
                value={state.jobTitle || ""}
                onInputChanged={(e) => {
                  onChangeHandler("jobTitle", e.target.value );
                }}
                inputPlaceholder={"enter-job-title"}
                translationPath={translationPath}
                parentTranslationPath={parentTranslationPath}
              />
            </div>

            <div className="dialog-content-item">
              <Inputs
                  isAttachedInput
                  idRef={"LinkedInLinkRef"}
                  labelValue={"linkedIn-link"}
                  type={"text"}
                  value= {state.linkedInProfile || ''} 
                  onInputChanged={(e) => 
                  {
                    onChangeHandler('linkedInProfile' ,(e?.target?.value  !== '' ?  e.target.value : null));

                  }}
                  translationPath={translationPath}
                  parentTranslationPath={parentTranslationPath}
                  endAdornment={(
                    <>
                      <Button
                        disabled={state.linkedInProfile ? (state.linkedInProfile !== "" ? checkIsValidValues('linkedInProfile') :  true ):  true }
                        onClick={() => {
                          try {
                            window.open(state.linkedInProfile || '');
                          } catch (error) { showError(t(`${translationPath}this-link-is-not-valid`)); }
                        }}
                        className='googlemaps-bbt '
                      >
                        <span className='p-1 youtube-wraper'><span className='mdi mdi-web' /></span>
                      </Button>
                    </>
                  )}
                  helperText={t`${translationPath}please-enter-valid-linkedIn-url`}
                  error={(state?.linkedInProfile !== "" ?  checkIsValidValues('linkedInProfile')  : false)} 
                  isWithError
                  isSubmitted={isSubmitted}
                />
            </div>
          <div className="w-100">
            <ActivityFilesUploader
              state={state}
              setState={setState}
              translationPath={translationPath}
              parentTranslationPath={parentTranslationPath}
              multiple={false}
              accept={"/*"}
              canSelectOneDocument={true}

            />
          </div>
        </DialogContent>
        <DialogActions>
          <FooterWrapperComponent
            parentTranslationPath={parentTranslationPath}
            translationPath={translationPath}
            onClose={onClose}
            onSave={() => {
              SaveUpdateDeveloperEmployeeHandler();
            }}
            disabled={isLoading.editDeveloperEmployee || checkIsValidValues('save') ||  state.employeeName === '' || !state.employeeName}
          />
        </DialogActions>
      </Dialog>
    </div>
  );
};

DeveloperEmployeesManagementDialog.propTypes = {
  parentTranslationPath: PropTypes.string.isRequired,
  translationPath: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  isLoading: PropTypes.instanceOf(Object),
};
DeveloperEmployeesManagementDialog.defaultProps = {
  isLoading: undefined,
};
