import React, { useCallback, useEffect, useState } from 'react'
import { AddAMLRevision } from '../../../Services'
import { DialogComponent } from '../../../Components'
import { Inputs } from '../../../Components';
import { useTranslation } from 'react-i18next';
import { Spinner } from '../../../Components';
import { showError, showSuccess } from '../../../Helper';

const AMLRemarksDialog = (
  {
    openDialog,
    parentTranslationPath,
    translationPath,
    onClose,
    UnitTransactionId,
  }
) => {

  const { t } = useTranslation(parentTranslationPath);

  const [isLoading, setIsLoading] = useState(false);
  const [inputComment, setInputComment] = useState("");

  const APIOnSubmitRevession = useCallback(async (amlTransactionId, comment) => {
    if (amlTransactionId !== null) {
      setIsLoading(true);
      const result = await AddAMLRevision(amlTransactionId, comment);
      if (!(result && result.status && result.status !== 200)) {
        onClose(result)
        showSuccess(t('Send-successfully'));
        setIsLoading(false)
      }
      else {
        showError(t('Faild-successfully'));
        setIsLoading(false)
      }
    }
  }, [])

  return (
    <>
      <Spinner isActive={isLoading} isAbsolute />
      <DialogComponent
        isOpen={openDialog}
        titleText={t(`${translationPath}Add-Comment`)}
        saveText={t(`${translationPath}Send`)}
        cancelText={t(`${translationPath}Cancel`)}
        dialogContent={(
          <Inputs
            inputPlaceholder={t(`${translationPath}Write-here`)}
            idRef='CommentRef'
            labelClasses='Requierd-Color'
            value={inputComment}
            onInputChanged={(event) => {
              const { value } = event.target;
              setInputComment(value);
            }}
            parentTranslationPath={parentTranslationPath}
            translationPath={translationPath}
            multiline
            rows={4}
          />
        )}
        saveType='button'
        onSaveClicked={() => {
          if (inputComment.trim() !== '') {
            APIOnSubmitRevession(UnitTransactionId,inputComment );
          }
        }}
        onCloseClicked={() => {
          onClose();
        }}
        onCancelClicked={() => {
          onClose();
        }}
      />
    </>
  )
}

export default AMLRemarksDialog