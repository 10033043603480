import { GetLeadsReferredByData } from "../Helper";
import { LeadsStatusEnum } from "../Enums";
import { GetLookupItemsByLookupTypeName } from '../Services' ; 

export const AutoFillLeadReferredByRule = async (
  setRerender,
  setData,
  setNewValue,
  setLoading
) => {
  setTimeout(async () => {
    setLoading(true);
    const data = await GetLeadsReferredByData();
    setLoading(false);
    setNewValue(data);
    setData("referredby", data);
  }, 20);
  setRerender(Math.random());
};

export const AutoFillLeadStatusRule = async (
  setRerender,
  setData,
  setNewValue , 
  setLoading 
) => {
  setTimeout(async () => {
    setLoading(true);
    let openLeadStatus = null;
    const res = await GetLookupItemsByLookupTypeName({
      lookUpName: "LeadStatus",
      pageSize: 100,
      pageIndex: 1,
    });
    if (!(res && res.status && res.status !== 200)) {
      openLeadStatus =
        res.result &&
        res.result.find((s) => s.lookupItemName === LeadsStatusEnum.Open.key);
      if (openLeadStatus) setNewValue(openLeadStatus);
      setData("status", openLeadStatus);
    } else {
      setNewValue(null);
      setData("status", null);
    }
    setLoading(false);
  }, 20);
  setRerender(Math.random());
};
