export const MonthsEnum = [
  {
    key: 1,
    value: 'january',
  },
  {
    key: 2,
    value: 'febraury',
  },
  {
    key: 3,
    value: 'march',
  },
  {
    key: 4,
    value: 'april',
  },
  {
    key: 5,
    value: 'may',
  },
  {
    key: 6,
    value: 'june',
  },
  {
    key: 7,
    value: 'july',
  },
  {
    key: 8,
    value: 'august',
  },
  {
    key: 9,
    value: 'september',
  },
  {
    key: 10,
    value: 'october',
  },
  {
    key: 11,
    value: 'november',
  },
  {
    key: 12,
    value: 'december',
  },
];
