import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { ButtonBase, Tooltip } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { PopoverComponent } from '../../../../../Components';
import text_tag from '../../../../../assets/images/icons/text_tag.svg';
import code_tag from '../../../../../assets/images/icons/code-tag.svg';
import vertical from '../../../../../assets/images/icons/dots-vertical.svg';
export const TemplatesCardsComponent = ({
  data,
  onFooterActionsClicked,
  parentTranslationPath,
  translationPath,
}) => {
  const [activeItem, setActiveItem] = useState(null);
  const { t } = useTranslation(parentTranslationPath);
  const TableListOpationActions = [
    {
      key: 1,
      value: t(`${translationPath}Edit`),
      keyName: 'edit',
      icon: 'mdi mdi-pencil',
    },
    {
      key: 2,
      keyName: 'view',
      value: t(`${translationPath}View-Details`),
      icon: 'mdi mdi-eye-outline',
    },
    {
      key: 3,
      keyName: 'check',
      value: t(`${translationPath}Move-to-Folder`),
      icon: 'mdi mdi-briefcase-plus',
    },
    {
      key: 4,
      keyName: 'delete',
      value: t(`${translationPath}delete`),
      icon: 'mdi mdi-delete c-warning ',
    },
  ];
  const [ActionsPopover, setActionsPopover] = useState(null);


  const actionsPopoverClickedHandler = (event) => {
    setActionsPopover(event.currentTarget);
  };
  const actionsPopoverCloseHandler = () => {
    setActionsPopover(null);
  };



  return (
    <> <div className='main-folder-title mt-4'>
      <div className='folder-title'>  {t(`${translationPath}File`)} </div>
      <div className='folder-sub-title'>
        {data?.length || 0}
        {' '}
        {' '}
        {t(`${translationPath}templates`)}
      </div>
    </div>
      <div className='templates-cards-wrapper childs-wrapper'>
        <div className='container'>
          {data &&
            data.map((item, index) => (
              <div className='metric-group' index={index}>
                <div className='metric-item'>
                  <div className='templates-cards-overlap-group'>
                    <div className='heading-and-icon'>
                      <div className={item.templateTypeLookupDto.templateTypeName !== 'HTML' && 'featured-icon' || 'featured-icon html'}>
                        <img
                          className='type'
                          alt='d'
                          src={
                            item.templateTypeLookupDto.templateTypeName !== 'HTML' &&  text_tag ||
                            code_tag
                          }
                        />
                      </div>
                      <div className='frame'>
                          <Tooltip
                            size='small'
                            placement='top'
                            title={item &&  item && item.templateName || ''}
                          >
                        <div className='heading'>
                            {item.templateName}
                        </div>
                        </Tooltip>
                        <div className='table-cell'>
                          <div className='badge'>
                            <div className='text'>
                              {item.systemTemplateCategoryDto.templateCategoryName.replace('Templates', '')}
                              {' '}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='dropdown'>
                      <div
                        className='dropdown-inquiries'
                        onClick={(e) => {
                          setActiveItem(item);
                          actionsPopoverClickedHandler(e);
                        }}
                      >
                        <img className='dots-vertical' alt='dots' src={vertical} />
                      </div>
                      <div
                        className='dropdown-inquiries'
                        onClick={(e) => { onFooterActionsClicked('view', item) }}
                      >
                        <div className='mt-3 mdi mdi-eye-outline' />
                      </div>
                    </div>
                  </div>
                  <div className='actions'>
                    <div className='location-wrap'>
                      <div className='calendar' />
                      <div className='location'>
                        {' '}
                        {item && item.createdOn &&
                          moment(item && item.createdOn).format('DD/MM/YYYY - hh:mm A') ||
                          'N/A'}
                      </div>
                    </div>
                    <div className='location-wrap'>
                      <img className='calendar-check' alt='Calendar' src='https://c.animaapp.com/xfXGoreo/img/calendar-check-01.svg' />
                      <div className='location'>
                        {' '}
                        {item && item.updateOn &&
                          moment(item && item.updateOn).format('DD/MM/YYYY - hh:mm A') ||
                          'N/A'}

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </div>
        <PopoverComponent
          idRef='headerActionsPopovercogRef'
          attachedWith={ActionsPopover}
          popoverClasses=''
          header-actions-popover-wrapper
          handleClose={actionsPopoverCloseHandler}
          component={(
            <div className='Popap-Option'>
              {TableListOpationActions.map((item, index) => (
                <ButtonBase
                  className='Option'
                  key={`OptionKey${index + 1}`}
                  onClick={() => {
                    onFooterActionsClicked(item.keyName, activeItem)
                    setActionsPopover(null)
                  }
                  }>
                  <div>{item.value}</div>
                  <div className={item.icon} />
                </ButtonBase>
              ))}
            </div>
          )}
        />
      </div>
    </>
  );
};
TemplatesCardsComponent.propTypes = {
  data: PropTypes.shape({ result: PropTypes.instanceOf(Array), totalCount: PropTypes.number })
    .isRequired,
  onFooterActionsClicked: PropTypes.func.isRequired,
  parentTranslationPath: PropTypes.string.isRequired,
  translationPath: PropTypes.string.isRequired,
};
