import { useEffect } from "react";
import { useVerticalNav } from "../Contexts/VerticalNavContext";

export const useMainContainerPadding = (initialPadding, cleanupPadding) => {
  const { setMainContainerPadding } = useVerticalNav();

  useEffect(() => {
    if (initialPadding) {
      setMainContainerPadding(initialPadding);
    }

    return () => {
      if (cleanupPadding) {
        setMainContainerPadding(cleanupPadding);
      }
    };
  }, [initialPadding, cleanupPadding, setMainContainerPadding]);
};
