
/* eslint-disable react/prop-types */
import React, { useCallback, useEffect, useState } from 'react';
import { Tooltip } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Spinner } from '../../../../Components';
import { GetAngryBirdLeadsByLeadId  } from '../../../../Services';
import { CopyToClipboardComponents } from '../../../../ReusableComponents/UtilityComponents/CopyToClipboardComponents/CopyToClipboardComponents';
import '../style/CardDetailsComponent.scss' ; 


export function LeadsOfAngryBirds({
  activeData,
  translationPath,
  parentTranslationPath,
}) {
  const { t } = useTranslation(parentTranslationPath);
  const [isLoading, setIsLoading] = useState(false);
  const [allLeadAngryBirds , setAllLeadsOfAngryBirds ] = useState({
    result :[] , 
    totalCount : 0 
  }) ; 

  const GetAllLeadAngryBirdsAPI  = useCallback(async () => {

    setIsLoading(true) ;
      const res = await GetAngryBirdLeadsByLeadId(activeData && activeData.leadId);
      if (!((res && res.data && res.data.ErrorId) || !res)) {
        setAllLeadsOfAngryBirds({
          result: (res) || [],
          totalCount: (res && res.length) || 0,
        });
      } else {
        setAllLeadsOfAngryBirds({
          result: [],
          totalCount: 0,
        });
      }
    setIsLoading(false) ;
}, [activeData]);


useEffect(() => {
    GetAllLeadAngryBirdsAPI();
  }, [GetAllLeadAngryBirdsAPI , activeData]);


  return (
    <div className="Unqualified-details-wrapper">
      <div className='leads-card-detaild-wrapper'>
      <Spinner isActive={isLoading} isAbsolute />
        <div className='side-menu-wrapper mb-3'>
            <div className="px-3 mb-3 slider-data">
              <div className='mt-3'>
                {allLeadAngryBirds  && allLeadAngryBirds.result && allLeadAngryBirds.result.map((element) =>
                  <div className='cards-wrapper'>
                    <div className='mb-3 mt-3'>
                      <span className='mr-1  texts gray-primary-bold'>
                        {t(`${translationPath}leadId`)}
                        :
                      </span>
                      <Tooltip title={t(`${translationPath}copy`)}>
                        <CopyToClipboardComponents
                          data={element && element.leadId}
                          childrenData={element && element.leadId}
                        />
                      </Tooltip>
                    </div>
                    <div className='mb-3'>
                      <span className='mr-1 texts gray-primary-bold'>
                        {t(`${translationPath}leadName`)}
                        :
                      </span>
                      <span className='texts s-gray-primary'>{`${element.leadName || ''}`}</span>
                    </div>
                    <div className='mb-3'>
                      <span className='mr-1 texts gray-primary-bold'>
                        {t(`${translationPath}createdByActivityFullName`)}
                        :
                      </span>
                      <span className='texts s-gray-primary'>{`${element.createdByActivityFullName || ''}`}</span>
                    </div>
                  
                  </div>
                )}
                {allLeadAngryBirds  && allLeadAngryBirds.result  && allLeadAngryBirds.result.length === 0 &&
                  <div class="MuiGrid-root no-content-text MuiGrid-item flex-d-inline-flex-center"><h1 class="no-content-title">
                    {t(`${translationPath}NoContent`)}
                  </h1> </div>}
              </div>
            </div>
        </div>
        </div>

      </div>
  )
}
