export const RotationSchemesTeamLeadDashboardPermissions = 
{

  RotationSchemesTeamLeadDashboard : 
  {
    permissionsId: "8d6e59ef-29dc-4f0a-7049-08dbf65badbd",
    permissionsName: "Rotation Schemes Team Lead Dashboard",
    description: null,
    componentsId: null,
    components: null
 },
  
} ;
