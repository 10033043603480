export const ModeOfPaymentRule = (item, value, setData, formType) => {
    if (item && item.field && item.field.id === 'mode_of-payment' && +formType === 1) {
         if (value && value.lookupItemName && value.lookupItemName === 'Mortgage')
         setData('is_mortgage', 'Yes');

         else
         setData('is_mortgage', 'No');
    } else if (+formType === 2 && item && item.field && item.field.id === 'mode_of_payment') {
        if (value && value.lookupItemName && value.lookupItemName === 'Mortgage')
         setData('Yes', 0, 'is_mortgage');

       else
        setData('No', 0, 'is_mortgage');
    }
};

export const IsMortgageReletedModeOfPaymentRule = async (item, value, setData, formType, itemList) => {
     if (item && item.field && item.field.id !== 'is_mortgage')
       return;

    if (item && item.field && item.field.id === 'is_mortgage' && (item.data.specialKey === 'is_mortgage_seeker_lead')) {
        const modeOfPaymentIndex = itemList.findIndex((f) => f.data.DtoName && f.data.DtoName === 'mode_of_payment_seeker_lead');

         if (value && value === 'Yes' && modeOfPaymentIndex !== -1) {
          const modeOfPayment = itemList[modeOfPaymentIndex].data && itemList[modeOfPaymentIndex].data.enum && itemList[modeOfPaymentIndex].data.enum.find((lo) => lo.lookupItemName === 'Mortgage');
          if (+formType === 1 && modeOfPayment) {
               setData('mode_of-payment', modeOfPayment);
               setData('mode_of_payment', modeOfPayment);
          } else if (+formType === 2 && modeOfPayment)
               setData(modeOfPayment, 0, 'mode_of_payment');
         } else if (!value || value === 'No') {
           if (+formType === 1) {
             setData('mode_of-payment', null);
             setData('mode_of_payment', {});
          } else if (+formType === 2)
            setData(null, 0, 'mode_of_payment');
       }
    }
};
