
import React, { useCallback, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Spinner, Tables , SwitchComponent  } from '../../../../Components';
import { TableActions } from '../../../../Enums';
import { returnPropsByPermissions    } from '../../../../Helper' ; 
import { PolicyPermissions   } from '../../../../Permissions' ; 
import { ActiveItemActions } from '../../../../store/ActiveItem/ActiveItemActions';
import moment from 'moment';
import {  PoliciesManagementDialog  } from '../PoliciesManagement' ; 
import { DeletePolicyDialog } from '../DeletePolicy' ; 
import PolicyHistoryDialog from '../PolicyHistoryDialog/PolicyHistoryDialog'

export const PolicyTableView = ({
   parentTranslationPath,
   translationPath,
   filter,
   data,
   isLoading,
   reloadData,
   activeItem ,
   setActiveItem ,
   editPolicy , 
   onSave , 
   setFilter , 
   onPageIndexChanged,
   onPageSizeChanged,
   setSortBy

}) => {

  const [isOpenEditDialog, setIsOpenEditDialog] = useState(false);
  const [isOpenDeleteDialog, setIsOpenDeleteDialog] = useState(false);
  const [isOpenHistoryDialog, setIsOpenHistoryDialog] = useState(false);


  const { t } = useTranslation([parentTranslationPath, 'Shared']);

  const tableActionClicked = useCallback((actionEnum, item) => {
    setActiveItem(item);
      if (actionEnum === TableActions.view.key)
        setIsOpenEditDialog(true) ; 
       else if (actionEnum === TableActions.edit.key)
         setIsOpenEditDialog(true) ; 
         else if (actionEnum === TableActions.delete.key)
          setIsOpenDeleteDialog(true) ; 
         else if (actionEnum === TableActions.viewHistory.key)
          setIsOpenHistoryDialog(true) ; 
  }, []);

  const getActionTableWithPermissions = () => {
    const list = [];
    list.push({ enum: TableActions.viewHistory.key, title: t(`${translationPath}Shared:view-history`)});

    if (returnPropsByPermissions(PolicyPermissions.EditPolicy.permissionsId)) 
      list.push({ enum: TableActions.edit.key , title: t(`${translationPath}Shared:edit`)});
    if (returnPropsByPermissions(PolicyPermissions.DeletePolicy.permissionsId)) 
       list.push({ enum: TableActions.delete.key , title: t(`${translationPath}Shared:delete`)});

    return list;
  };


  return (
<div className='policiesTabelView'>
      <Spinner isActive={isLoading.policies} isAbsolute />
      <div className='filter-section-item' />
      <div className='w-100 px-2'>
        <Tables
          data={(data && data.result) || []}
          headerData={[
            {
              id: 1,
              isSortable: true,
              input: 'policyDataName',
              label: t(`${translationPath}policyName`),
              component: (item) => (
                <div>
                  {item && item.policyDataName}
                </div>
              ),
            },
            {
              id: 2,
              isSortable: true,
              input: 'createdOn',
              isDate : true , 
              label: t(`${translationPath}createdOn`),
              component: (item) => (
                <div>
                  {item && item.createdOn}
                </div>
              ),
            },
            {
              id: 3,
              isSortable: true,
              input: 'updateOn',
              label: t(`${translationPath}updateOn`),
              isDate : true , 
              component: (item) => (
                <div>
                  {item && item.updateOn}
                </div>
              ),
            },
            
          ]}
          defaultActions={getActionTableWithPermissions()}
          actionsOptions={{
            onActionClicked: tableActionClicked,
          }}
          setSortBy={setSortBy}
          itemsPerPage={filter.pageSize}
          activePage={filter.pageIndex}
          translationPath={translationPath}
          parentTranslationPath={parentTranslationPath}
          totalItems={(data && data.totalCount) || 0}
          onPageIndexChanged ={onPageIndexChanged}
          onPageSizeChanged={onPageSizeChanged}
        />
      </div>
      {isOpenEditDialog && (
        <PoliciesManagementDialog
          isOpen={isOpenEditDialog}
          onClose={() => {
            setIsOpenEditDialog(false);
            setActiveItem(null) ; 
          }}
          onSave={() => {
            setIsOpenEditDialog(false);
            setActiveItem(null) ; 
            setFilter() ;
          }}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          editPolicy ={editPolicy}
          isLoadingPolicy={isLoading.policyItemLoading}
        />
      )}
      {isOpenDeleteDialog && (
        <DeletePolicyDialog
          open={isOpenDeleteDialog}
          close={() => {
            setIsOpenDeleteDialog(false);
          }}
          onSave={() => {
            setIsOpenDeleteDialog(false);
            setFilter();
          }}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          activeItem={activeItem}
        />
      )} 
      {isOpenHistoryDialog && (
        <PolicyHistoryDialog
          open={isOpenHistoryDialog}
          close={() => {
            setIsOpenHistoryDialog(false);
          }}
          onSave={() => {
            setIsOpenHistoryDialog(false);
            setFilter();
          }}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          activeItem={activeItem}
        />
      )} 
    </div>
  );
};

PolicyTableView.propTypes = {
  parentTranslationPath: PropTypes.string.isRequired,
  translationPath: PropTypes.string.isRequired,
  filter: PropTypes.number.isRequired,
  data:PropTypes.instanceOf(Object).isRequired,
  isLoading: PropTypes.bool.isRequired,
  onPageIndexChanged :  PropTypes.func.isRequired, 
  onPageSizeChanged: PropTypes.func.isRequired,  

};
