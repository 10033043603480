export const CoursesPermissions = {
    ViewAllCourses :{
        permissionsId: "b1955867-956f-4014-c4ad-08dc683b0c43",
        permissionsName: "View All Courses",
        description: null,
        componentsId: null,
        components: null
    }
  
  };
  