import React from "react";

function FilterBlankIcon({ fill, ...restProps }) {
  return (
    <svg
      viewBox="0 0 16 16"
      fill={fill ?? ""}
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <path
        d="m7.52.682-.373.04c-1.259.134-2.487.626-3.574 1.435-.406.302-1.117 1.013-1.422 1.421C.486 5.81.201 8.687 1.398 11.187c.36.752.79 1.351 1.427 1.988s1.236 1.067 1.988 1.427a7.31 7.31 0 0 0 6.374 0c.752-.36 1.351-.79 1.988-1.427s1.067-1.236 1.427-1.988c1.197-2.5.912-5.377-.753-7.609-.305-.408-1.016-1.119-1.422-1.421A7.296 7.296 0 0 0 8.949.731C8.666.692 7.716.66 7.52.682m1.36 1.385a6.025 6.025 0 0 1 4.16 2.68c.202.308.495.906.621 1.266.231.662.323 1.231.323 1.987 0 .592-.038.931-.162 1.44-.349 1.435-1.26 2.726-2.515 3.567a6.496 6.496 0 0 1-2.035.857 6.033 6.033 0 0 1-5.026-1.185A6.087 6.087 0 0 1 2.178 9.44c-.124-.509-.162-.848-.162-1.44 0-.996.171-1.747.597-2.627a5.992 5.992 0 0 1 3.494-3.062c.47-.156.848-.232 1.453-.295.215-.022 1.048.01 1.32.051"
        fill={fill ?? ""}
      />
    </svg>
  );
}

export default FilterBlankIcon;
