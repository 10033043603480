import React from 'react';
import PropTypes from 'prop-types';
import MUIChip from '@material-ui/core/Chip';

// styles
import useStyles from './styles';

function Chip({ label, variant, Icon, ...restProps }) {
  const styles = useStyles();

  // Initialize chipStyle.
  let chipStyle;

  // Determine the style based on the variant prop using a switch statement
  switch (variant) {
    case 'success':
      chipStyle = styles.success;
      break;
    case 'warning':
    case 'hot':
      chipStyle = styles.warning;
      break;
    case 'selected':
      chipStyle = styles.selected;
      break;
    case 'disabled':
      chipStyle = styles.disabled;
      break;
    case 'warm':
      chipStyle = styles.warm;
      break;
    case 'cold':
      chipStyle = styles.cold;
      break;
    case 'qualification':
      chipStyle = styles.qualification;
      break;
    case 'lightPrimaryfilled':
      chipStyle = styles.lightPrimaryfilled;
      break;
    case 'lightBluefilled':
      chipStyle = styles.lightBluefilled;
      break;
    default:
      chipStyle = styles.default;
      break;
  }

  return (
    <MUIChip
      label={label}
      className={chipStyle}
      icon={Icon && <Icon className={styles[`${variant}-icon`]} />}
      {...restProps}
    />
  );
}

Chip.propTypes = {
  label: PropTypes.string.isRequired,
  variant: PropTypes.oneOf([
    'success',
    'warning',
    'hot',
    'selected',
    'disabled',
    'warm',
    'cold',
    'qualification',
    'lightPrimaryfilled',
    'lightBluefilled',
  ]),
  ...MUIChip.propTypes,
};

Chip.defaultProps = {
  variant: 'default',
};

export default Chip;
