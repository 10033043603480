import { makeStyles } from "@material-ui/core/styles";
import { useIsAr } from "../../../../../Hooks";

export default makeStyles((theme) => {
  const { isAr } = useIsAr(theme.direction);

  return {
    infoSectionWrapper: {
      marginBottom: "24px",
    },
    title: {
      color: theme.palette.text.primary,
      fontSize: "18px",
      lineHeight: "28px",
      fontWeight: 600,
    },
    subTitle: {
      marginTop: "4px",
      color: theme.palette.text.tertiary,
      fontWeight: 400,
      fontSize: "16px",
      lineHeight: "24px",
    },
    divider: (props) => ({
      marginTop: "20px",
      marginBottom: props.fullWidthDivider ? "20px" : "12px",
      maxWidth: props.fullWidthDivider ? "100%" : "332px",
      backgroundColor: theme.palette.border.secondary,
    }),
    infoItemsWrapper: {
      display: "flex",
      flexDirection: "column",
      gap: "12px",
    },
  }
});
