import React, { useCallback, useEffect, useReducer, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DialogComponent, SelectComponet } from '../../../Components';
import { showError, showSuccess } from '../../../Helper';
import { DeletePropertyCampaign } from '../../../Services';
import { PowerBIEmbed } from 'powerbi-client-react';
import { models } from 'powerbi-client';
import "../../Home/Dashboard/MasterStyleDashborads/MasterStyleDashboards.scss";
import { MicrosoftToken } from '../../../Services/DashboardService';
import { PowerBiEnum } from '../../../Enums';
import { CellSpinnerBi } from '../Dashboard/CellSpinnerBi/CellSpinnerBi';
import { DateRangePickerComponent } from '../../../Components/Controls/DateRangePickerComponent/DateRangePickerComponent';
import moment from 'moment';
import { ButtonBase, ButtonGroup } from '@material-ui/core';
export const PortalsCampaignChart = ({
  onCancelClicked,
  onCloseClicked,
  reloadData,
  isDialogChartOpen,
  translationPath,
  parentTranslationPath,
  activeItem,
  setActiveItem,
}) => {
  const { t } = useTranslation(parentTranslationPath);
  const campaignId = activeItem.propertyCampaignId;
  const [report, setReport] = useState();
  const [render, setRender] = useState(false);
  const [ShowReport, setShowReport] = useState(false);
  const [datefilters, setdatefilter] = useState(true);
  const [datepicker, setdatepicker] = useState();
  const [activeButton, setActiveButton] = useState("One");
  const [filterButton, setfilterButton] = useState(false);

 

  const today = new Date();

  const count = new Date(new Date().setDate(today.getDate()));
  const count2 = new Date(new Date().setDate(today.getDate()));
  const datetimes = moment(count)
    .startOf("month")
    .format("YYYY-MM-DDTHH:mm:ss");
  const datetimes2 = moment(count2).format("YYYY-MM-DDTHH:mm:ss");



  const dateRangeDefault = {
    startDate: null,
    endDate: null,
    key: "selection",
  };


  const [dateFilter, setDateFilter] = useState(dateRangeDefault);
  const [data, setdata] = useState([]);

  const reducer = (state, action) => {
    if (action.id !== "edit") return { ...state, [action.id]: action.value };
    if (action.id === "edit") {
      return {
        ...action.value,
      };
    }
    return undefined;
  };

  const [state, setState] = useReducer(reducer, {
    fromDate: null,
    toDate: null,
  });


  useEffect(() => {
    const currentState = {
      ...state,
      fromDate:
        (dateFilter.startDate &&
          moment(dateFilter.startDate)
            .startOf("day")
            .format("YYYY-MM-DDTHH:mm:ss")) ||
        null,
      toDate:
        (dateFilter.endDate &&
          moment(dateFilter.endDate)
            .endOf("day")
            .format("YYYY-MM-DDTHH:mm:ss")) ||
        null,
    };
    setState({ value: currentState });
    setdata(currentState);
  }, [dateFilter]);

  const advancedFilter = {
    $schema: "http://powerbi.com/product/schema#advanced",
    target: {
      table: "leads",
      column: "created_at",
    },
    filterType: 0,
    logicalOperator: "And",
    conditions: [
      {
        operator: "GreaterThanOrEqual",
        value: data.fromDate,
      },
      {
        operator: "LessThanOrEqual",
        value: data.toDate,
      },
    ],
    filterType: models.FilterType.AdvancedFilter,
  };


  const Filter = {
    $schema: 'http://powerbi.com/product/schema#basic',
    target: {
      table: 'campaigns',
      column: 'propertyCampaignId',
    },
    operator: 'In',
    values: [campaignId],
    filterType: models.FilterType.BasicFilter,
    requireSingleSelection: true,
  };

  const Microsoft = async (isExpired) => {
    const res = await MicrosoftToken(isExpired);
    if (!(res && res.status && res.status !== 200)) {
      setReport(res);
      setRender(true);
    } else {
      setReport('');
      showError('Contact Your Bi Admin For Help');
    }
  };

  const onClearedAllFiltersClick = useCallback(() => {
    setDateFilter(dateRangeDefault);
    applyBookmark("Bookmarke19e7b061aac5c7573b4");
    setdatepicker(0);
    setdatefilter(true);
  }, []);

  const [bookmarks, setBookmarks] = useState([]);
  const [error, setError] = useState(null);

  const loadBookmarks = async () => {
    const report = window.report;

    if (report) {
      try {
        const bookmarksManager = report.bookmarksManager;
        const fetchedBookmarks = await bookmarksManager.getBookmarks();
        setBookmarks(fetchedBookmarks);
      } catch (error) {
        console.error("Error loading bookmarks:", error);
        setError(error);
      }
    }
  };
  const applyBookmark = async (bookmarkName) => {
    const report = window.report;
    if (report) {
      try {
        const bookmarksManager = report.bookmarksManager;
        bookmarksManager.apply(bookmarkName);
        // console.log("Bookmark applied successfully:", bookmarkName);
      } catch (error) {
        console.error("Error applying bookmark:", error);
        setError(error);
      }
    }
  };

  const handleButtonClick = (buttonName) => {
    setActiveButton(buttonName);
  };

  const ChartView = () => {
    applyBookmark("Bookmarkc96906704d3b70863060");
    handleButtonClick("One");

  };
  const TableView = () => {
    applyBookmark("Bookmark244dd3833253c18eead9");
    handleButtonClick("Two");
   
  };
  const FilterHide = () => {
    applyBookmark("Bookmark5bb828776de9a05ae30c");
    setfilterButton(false);
  };
  const FilterView = () => {
    applyBookmark("Bookmark656d1e699d6684cc97d9");
    setfilterButton(true);
  };
  const handleRefreshClick = useCallback(() => {
    window.report
      .refresh()
      .then(() => {
   
        console.log(true);
      })
      .catch((error) => {
        console.log(false);
      });
  }, []);

  
  useEffect(() => {
    Microsoft(false);
  }, []);



  return (
    <DialogComponent
      titleText='portaldashboard'
      maxWidth='lg'
      minheight='lg'
      dialogContent={(
        <div className='d-flex-column-center' style={{minHeight: '740px'}}>
 
      <CellSpinnerBi isActive={!ShowReport} isAbsolute blue/>
      {ShowReport ? (
    <div className="dashboardMain-PowerBIEmbed">
                <div className="MainFiltersBi">
        <div className="selecterbi">
          <div>
            <DateRangePickerComponent
              onClearClicked={() => {
                setDateFilter(dateRangeDefault);
              }}
              ranges={[dateFilter]}
              onDateChanged={(selectedDate) => {
                const newStartDate =
                  selectedDate.selection && selectedDate.selection.startDate;
                const newEndDate = new Date(
                  moment(
                    selectedDate.selection && selectedDate.selection.endDate
                  ).endOf("day")
                );

                setDateFilter({
                  startDate: newStartDate,
                  endDate: newEndDate,
                  key: "selection",
                });
              }}
            />
          </div>
          {!filterButton ? (
     <div className="DashboardFilter">
              <ButtonBase
                variant="contained"
                className="btns theme-solid bg-primary-dark Dashboardbtn"
                style={{ color: "white" }}
                onClick={FilterView}
                disabled={activeButton == "Two"}
              >
                <span className="mdi mdi-filter-menu m-1" />
              </ButtonBase>
            </div>):(
         <div className="DashboardFilter">
              <ButtonBase
                variant="contained"
                className="btns theme-solid bg-primary-dark Dashboardbtn"
                style={{ color: "white" }}
                onClick={FilterHide}
           
              >
                <span className="mdi mdi-filter-minus-outline m-1" />
              </ButtonBase>
            </div>
            )}
          <div className="DashboardFilter">
              <ButtonBase
                onClick={onClearedAllFiltersClick}
                id="onClearedAllFiltersref"
                className="btns theme-solid bg-primary-dark clear-all-btn Dashboardbtn"
        
              >
                <span className="mdi mdi-filter-remove m-1" />
              </ButtonBase>
            </div>
            <div className="DashboardFilter">
              <ButtonBase
                onClick={handleRefreshClick}
                id="onClearedAllFiltersref"
                className="btns theme-solid bg-primary-dark clear-all-btn Dashboardbtn"
              >
                <span className="mdi mdi-refresh m-1" />
              </ButtonBase>
            </div>
            <div className="DashboardFilter">
                  <ButtonGroup>
                    <ButtonBase
                      onClick={ChartView}
                      className={`btns theme-solid  clear-all-btn Dashboardbtn ${
                        activeButton === "One"
                          ? "chartActive"
                          : "chartNotActive"
                      }`}
                    >
                      <span className="mdi mdi-chart-pie m-1" />
                    </ButtonBase>

                    <ButtonBase
                      onClick={TableView}
                      className={`btns theme-solid  clear-all-btn Dashboardbtn ${
                        activeButton === "Two"
                          ? "chartActive"
                          : "chartNotActive"
                      }`}
                    >
                      <span className="mdi mdi-format-list-bulleted-square m-1" />
                    </ButtonBase>
                  </ButtonGroup>
                </div>
        </div>

</div>
</div>
      ) : (
        <div></div>
      
      )}

      <div className="dashboardMain">
        <PowerBIEmbed
          embedConfig={{
            type: "report",
            id: PowerBiEnum.PortalsCampaignChart.reportid,
            embedUrl: PowerBiEnum.PortalsCampaignChart.url,
            accessToken: report,
            pageView: "fitToWidth",
            filters: [Filter,advancedFilter],
            settings: {
              customLayout: {
                displayOption: models.DisplayOption.FitToWidth,
              },
              filterPaneEnabled: false,
              navContentPaneEnabled: false,
              panes: {
                filters: {
                  expanded: false,
                  visible: false,
                },
              },
              background: models.BackgroundType.Transparent,
            },
          }}
          eventHandlers={
            new Map([
              [
                "loaded",
                () => {
                  console.log("Report loaded");
                  setShowReport(true);
                },
              ],
              [
                "rendered",
                (event) => {
                  setRender(true);
                  loadBookmarks();
                  console.log("Report rendered ");
                },
              ],
              [
                "error",
                (event, embed) => {
                  const error = event.detail;

                  if (
                    error.detailedMessage === "Get report failed" ||
                    error.detailedMessage ===
                      "Access token has expired, resubmit with a new access token" ||
                    error.detailedMessage ===
                      "Fail to initialize - Could not resolve cluster"
                  ) {
                    Microsoft(true);
                    setRender(false);
                  } else console.log(error.detailedMessage);
                  showError(error.detailedMessage);
                },
              ],
            ])
          }
          cssClassName={
            ShowReport ? "report-style-class-Show-Dialog" : "report-style-class-hide"
          }
          getEmbeddedComponent={(embeddedReport) => {
            window.report = embeddedReport;
          }}
        />
      </div>
    </div> 

      )}
      saveClasses='btns theme-solid bg-danger w-100 mx-2 mb-2'
      isOpen={isDialogChartOpen}
      onCloseClicked={onCloseClicked}
      parentTranslationPath={parentTranslationPath}
      translationPath={translationPath}
    />
  );
};
