import { config } from '../../config';
import { HttpServices } from '../../Helper';

export const GetUnitImagesForLeaseTransaction = async (unitId) => {
    const result = await HttpServices.get(
      `${config.server_address}/CrmDfm/OfferLetter/GetUnitImagesForLeaseTransaction/${unitId}`
    )
      .then((data) => data)
      .catch((error) => error.response);
    return result;
  };
  
export const SetUnitAsUnderOfferOrOfferSigned = async (body) => {
    const result = await HttpServices.post(
      `${config.server_address}/CrmDfm/OfferLetter/SetUnitAsUnderOfferOrOfferSigned`
    , body)
      .then((data) => data)
      .catch((error) => error.response);
    return result;
  };
  
export const SetUnitAsUnderContractOrContractSigned = async (body) => {
    const result = await HttpServices.post(
      `${config.server_address}/CrmDfm/TenancyContract/SetUnitAsUnderContractOrContractSigned`
    , body)
      .then((data) => data)
      .catch((error) => error.response);
    return result;
  };
  
  export const UploadSignedOfferLetter = async (body) => {
    const result = await HttpServices.post(
      `${config.server_address}/CrmDfm/OfferLetter/UploadSignedOfferLetter`
    , body)
      .then((data) => data)
      .catch((error) => error.response);
    return result;
  };

  export const UploadSignedTenancyContract = async (body) => {
    const result = await HttpServices.post(
      `${config.server_address}/CrmDfm/TenancyContract/UploadSignedTenancyContract`
    , body)
      .then((data) => data)
      .catch((error) => error.response);
    return result;
  };
  
  export const AddOfferLetterTenantDetails = async (body) => {
    const result = await HttpServices.post(
      `${config.server_address}/CrmDfm/OfferLetter/AddOfferLetterTenantDetails`
    , body)
      .then((data) => data)
      .catch((error) => error.response);
    return result;
  };
  

export const GetUnitTransactionOfferLetters = async (unitId) => {
    const result = await HttpServices.get(
      `${config.server_address}/CrmDfm/OfferLetter/GetUnitTransactionOfferLetters/${unitId}`)
      .then((data) => data)
      .catch((error) => error.response);
    return result;
  };

export const GetUnitTransactionTenancyContracts = async (unitId) => {
    const result = await HttpServices.get(
      `${config.server_address}/CrmDfm/TenancyContract/GetUnitTransactionTenancyContracts/${unitId}`)
      .then((data) => data)
      .catch((error) => error.response);
    return result;
  };

export const GetTransactionSummary = async (unitTransactionId) => {
    const result = await HttpServices.get(
      `${config.server_address}/CrmDfm/TenancyContract/GetTransactionSummary/${unitTransactionId}`)
      .then((data) => data)
      .catch((error) => error.response);
    return result;
  };

export const ExportTransactionSummary = async (unitTransactionId) => {
    const result = await HttpServices.post(
      `${config.server_address}/CrmDfm/TenancyContract/ExportTransactionSummary?unitTransactionId=${unitTransactionId}`)
      .then((data) => data)
      .catch((error) => error.response);
    return result;
  };

export const ShareTransactionSummary = async (unitTransactionId, body) => {
    const result = await HttpServices.post(
      `${config.server_address}/CrmDfm/TenancyContract/ShareTransactionSummary?unitTransactionId=${unitTransactionId}`,
       body)
      .then((data) => data)
      .catch((error) => error.response);
    return result;
  };

export const GetTransactionDocumentsCheckList = async (unitId) => {
    const result = await HttpServices.get(
      `${config.server_address}/CrmDfm/TenancyContract/GetTransactionDocumentsCheckList/${unitId}`)
      .then((data) => data)
      .catch((error) => error.response);
    return result;
  };

export const AddTransactionMissingDocuments = async (body) => {
  const result = await HttpServices.post(
    `${config.server_address}/CrmDfm/TenancyContract/AddTransactionMissingDocuments`,
    body)
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

export const GetTransactionSummaryFile = async (unitTransactionId) => {
  const result = await HttpServices.get(
    `${config.server_address}/CrmDfm/TenancyContract/GetTransactionSummaryFile?unitTransactionId=${unitTransactionId}`)
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

export const GetUnitTransactionTenant = async (unitId) => {
    const result = await HttpServices.get(
      `${config.server_address}/CrmDfm/OfferLetter/GetUnitTransactionTenant/${unitId}`)
      .then((data) => data)
      .catch((error) => error.response);
    return result;
  };
  