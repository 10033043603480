export const TaskConfigurationPriorityEnum = {
    Lowest: {
        key: 'Lowest',
        value: 1,
    },
    Low: {
        key: 'Low',
        value: 2,
    },
    Medium: {
        key: 'Medium',
        value: 3,
    },
    High: {
        key: 'High',
        value: 4,
    },
    Highest: {
        key: 'Highest',
        value: 5,
    },
}