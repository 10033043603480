import React, { useState, useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import ReactFlow, {
  ReactFlowProvider,
  MiniMap,
  Controls,
  Background,
  addEdge,
  Handle,
} from "react-flow-renderer";
import "react-flow-renderer/dist/style.css";
import { GetSubActions, GetTrigerSubActions, GetTriggers } from "../../../Services";
import CustomNode from "./WorkFlowUtilities/CustomNode";
import Sidebar from "./WorkFlowUtilities/Sidebar";

export const WorkFlowView = () => {
  const parentTranslationPath = 'WorkFlow';
  const translationPath = '';

  const { t } = useTranslation(parentTranslationPath);
  const [TrigerSubActions, SetTrigerSubActions] = useState([]);
  const [Triggers, SetTriggers] = useState([]);
  const [nodes, setNodes] = useState([]);
  const [edges, setEdges] = useState([]);
  const [subActions, setSubActions] = useState([]);


  const [idCount, setIdCount] = useState(2);
  const onConnect = (params) => setEdges((eds) => addEdge(params, eds));

  const handleDeleteNode = (nodeId) => {
    const findAllChildren = (nodeId, edges) => {
      let children = edges
        .filter((edge) => edge.source === nodeId)
        .map((edge) => edge.target);

      children.forEach((childId) => {
        children = [...children, ...findAllChildren(childId, edges)];
      });

      return children;
    };

    const childNodeIds = findAllChildren(nodeId, edges);

    setNodes((nds) =>
      nds.filter((node) => ![nodeId, ...childNodeIds].includes(node.id))
    );
    setEdges((eds) =>
      eds.filter(
        (edge) =>
          ![nodeId, ...childNodeIds].includes(edge.source) &&
          ![nodeId, ...childNodeIds].includes(edge.target)
      )
    );
  };

  const onDrop = useCallback(
    (event) => {
      event.preventDefault();
      const reactFlowBounds = event.target.getBoundingClientRect();
      const position = {
        x: event.clientX - reactFlowBounds.left,
        y: event.clientY - reactFlowBounds.top,
      };

      const triggerData = JSON.parse(event.dataTransfer.getData("trigger")); // Retrieve the trigger data

      const newNode = {
        id: `${idCount}`,
        type: "Action",
        position,
        data: {
          triggerData: triggerData,
          trigerId: triggerData.trigerId,
          label: triggerData.trigerName || "New Lead", // Use trigger data
          description:
            triggerData.description || "Triggers when Lead is created", // Use trigger description
        },
      };

      setNodes((nds) => nds.concat(newNode));
      setIdCount(idCount + 1); // Increase the count for new nodes
      GetTrigerSubActionsAPI(triggerData.trigerId);
    },
    [idCount]
  );

  const onDragOver = (event) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = "move";
  };

  const onDragStart = (event, trigger) => {
    event.dataTransfer.setData("trigger", JSON.stringify(trigger)); // Store trigger data
  };

  const handleAddNewNode = (parentId, direction = "down", actionName = "Default Action", FullData) => {
    addNode(parentId, direction, "Action", actionName, FullData); // Pass actionName to addNode
  };

  const handleAddConditionNode = (parentId, direction = "down", data, FullData) => {
    const actionName = data.actionName || data.conditionAction || "Default Condition"; // Use actionName from data, or fall back

    // Add a Condition node with the determined actionName
    addNode(parentId, direction, "Condition", actionName, FullData);
  };



  const addNode = (parentId, direction, nodeType, actionName, FullData) => {
    const parentNode = nodes.find((node) => node.id === parentId);

    let newPosition;
    if (direction === "down-left") {
      newPosition = {
        x: parentNode.position.x - 120,
        y: parentNode.position.y + 150,
      };
    } else if (direction === "down-right") {
      newPosition = {
        x: parentNode.position.x + 120,
        y: parentNode.position.y + 150,
      };
    } else {
      newPosition = {
        x: parentNode.position.x,
        y: parentNode.position.y + 150,
      };
    }

    const newNodeId = `${idCount}`;

    const newNode = {
      id: newNodeId,
      actionId: FullData?.actionId || null,
      type: nodeType,
      data: {
        label: actionName,
        description: nodeType === "Condition" ? "Condition Node" : "Action Node",
        parentData: parentNode?.data,
        actionId: FullData?.actionId || null,
      },
      position: newPosition,
    };

    setNodes((nds) => [...nds, newNode]);

    const newEdge = {
      id: `e${parentId}-${newNodeId}`,
      source: parentId,
      target: newNodeId,
      type: "smoothstep",
      data: { conditionType: direction === "down-right" ? "no" : "yes" }  // Use direction for condition type
    };

    setEdges((eds) => [...eds, newEdge]);
    setIdCount(idCount + 1);
  };





  const GetTrigerSubActionsAPI = async (triger) => {
    const response = await GetTrigerSubActions(triger);
    if (!(response && response.status && response.status !== 200)) {

      SetTrigerSubActions(response); return response;
    } else {
      SetTrigerSubActions([]);
    }

  };

  const GetTriggersAPI = async () => {
    const response = await GetTriggers({
      pageIndex: 1,
      pageSize: 1000,
    });
    if (!(response && response.status && response.status !== 200)) {
      SetTriggers(response);
    } else {
      SetTriggers([]);
    }
  };

  const GetSubActionsAPI = async (actionId, actionType, actionValue, anchor) => {
    const response = await GetSubActions({
      actionId: actionId ? +actionId : null,
      actionType,
      actionValue,
    });

    if (response) {
      setSubActions(response);
    } else {
      console.warn('Failed to fetch sub-actions');
      setSubActions([]);
    }
    return response;
  };

  useEffect(() => {
    GetTriggersAPI();
  }, []);

  const nodeTypes = useMemo(
    () => ({
      Action: (props) => (
        <CustomNode
          {...props}
          handleAddNewNode={handleAddNewNode}
          Trigers={TrigerSubActions}
          GetTrigerSubActionsAPI={(item) => GetTrigerSubActionsAPI(item)}
          handleAddConditionNode={handleAddConditionNode}
          handleDeleteNode={handleDeleteNode}
          nodeType="Action"
          edges={edges}
        />
      ),
      Condition: (props) => (
        <CustomNode
          {...props}
          handleAddNewNode={handleAddNewNode}
          Trigers={TrigerSubActions}
          GetTrigerSubActionsAPI={(item) => GetTrigerSubActionsAPI(item)}
          handleAddConditionNode={handleAddConditionNode}
          handleDeleteNode={handleDeleteNode}
          nodeType="Condition"
          edges={edges}
        />
      ),
    }),
    [
      TrigerSubActions,
      edges,
      handleAddNewNode,
      handleAddConditionNode,
      handleDeleteNode,
    ]
  );

  function generateWorkflowJson(nodes, edges) {
    if (!nodes || nodes.length === 0) {
      console.error("Nodes array is empty or undefined.");
      return null;
    }
  
    // Get TrigerId and TrigerName from the first node
    const firstNode = nodes[0];
    const triggerId = firstNode?.data?.triggerData?.trigerId || null;
    const triggerName = firstNode?.data?.triggerData?.trigerName || "Unnamed Trigger";
  
    // Create a mapping of node IDs to their index-based sequence number, starting at 0
    const nodeSequence = {};
    nodes.forEach((node, index) => {
      nodeSequence[node.id] = index; // Sequence starts at 0
    });
  
    // Map nodes to the desired Workflow format
    const workflow = nodes.slice(1).map((node, index) => {
      const nodeId = node.id;
      const sequenceId = nodeSequence[nodeId];
  
      // Find the edge where this node is the target (i.e., it has a parent)
      const incomingEdge = edges.find(edge => edge.target === nodeId);
      const previousActionId = incomingEdge ? nodeSequence[incomingEdge.source] : null;
      const parentNode = incomingEdge ? nodes.find(n => n.id === incomingEdge.source) : null;
  
      // Determine PreviousActionStatusRequired based on the parent node type and edge direction
      let previousActionStatusRequired = null;
      if (index === 0) {
        previousActionStatusRequired = null;
      } else if (parentNode && parentNode.type === "Action") {
        previousActionStatusRequired = "Done";
      } else if (incomingEdge && parentNode && parentNode.type === "Condition") {
        previousActionStatusRequired = incomingEdge.data?.conditionType === "yes" ? "True" : "False";
      }
  
      // Safely access label, type, and other properties
      const label = node?.data?.label || "Unnamed Node";
      const type = node?.type || "Unknown Type";
  
      // Create the Workflow entry
      const workflowItem = {
        id: sequenceId,
        Name: label,
        Type: type,
        PreviousActionId: index === 0 ? null : previousActionId,
        PreviousActionStatusRequired: index === 0 ? null : previousActionStatusRequired,
        FormValues: {},
        Params: {}
      };
  
      // If the node is a Condition type, use actionId as ConditionId if available
      if (type === "Condition") {
        const conditionId = node.actionId || sequenceId; // Use actionId if available, otherwise fallback to sequenceId
        workflowItem.FormValues = {
          ConditionId: conditionId,
          Conditions: [
            {
              FormId: 127,
              SearchKey: "ExampleKey",
              Value: "ExampleValue",
              Operator: 1,
              Operand: "AND"
            }
          ]
        };
      }
  
      return workflowItem;
    });
  
    // Construct the final JSON structure
    const finalJson = {
      TrigerId: triggerId,
      TrigerName: triggerName,
      Workflow: workflow
    };
  
    return finalJson;
  }
  
  // Example usage:
  console.log(JSON.stringify(generateWorkflowJson(nodes, edges), null, 2));
  







  return (
    <div className="WorkFlowView-MAIN view-wrapper QA-view-wrapper">
      <div style={{ height: "600px", width: "100%" }}>
        <ReactFlowProvider>
          <div className="dndflow">
            <div
              className={
                (nodes && nodes.length > 0 && "Item-Drag-Work DISBELDp ") ||
                "Item-Drag-Work"
              }
            >
              <Sidebar
                onDragStart={onDragStart}
                initialNodes={nodes}
                Triggers={Triggers}
              />
            </div>
            <div
              style={{ height: "600px", width: "100%" }}
              onDrop={onDrop}
              onDragOver={onDragOver}
            >
              <ReactFlow
                nodes={nodes}
                edges={edges}
                isConnectable={false}
                onConnect={onConnect}
                nodeTypes={nodeTypes}
                fitView
                fitViewOptions={{ padding: 0.2 }}
                attributionPosition="top-right"
              >
                <MiniMap />
                <Controls />
                {/* <Background variant="solid" color="#aaa" gap={16} /> */}
              </ReactFlow>
            </div>
          </div>
        </ReactFlowProvider>
      </div>
    </div>
  );
};
