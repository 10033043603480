export const SaleAgentDashboardPermissions = {
  SaleAgentDashboard: 
    {
      permissionsId:'dcc65699-5427-46ab-682f-08da421d6e99',
      permissionsName :'Sale Agent Dashboard',
      componentsId:null,
      components:null , 
    } ,
    SalesAgentPlusDashboard : {
      permissionsId: "fc300be2-6343-4eb5-7f30-08dbbb5bf6df",
      permissionsName: "Sales Agent + Dashboard",
      description: null,
      componentsId: null,
      components: null
  }
} ;
