import React, { Fragment, memo, useEffect } from 'react';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import {
  Button,
  Step,
  StepLabel,
  Stepper,
  Tooltip,
  IconButton,
  TableCell,
  TableRow,
  ButtonBase,
} from '@material-ui/core';
import moment from 'moment';
import { AMLReleaseType } from '../../../../Enums';
import './AMLView.scss';
import { PermissionsComponent } from '../../../../Components';
import { AMLViewPermissions } from '../../../../Permissions';
import HistoryIcon from '@material-ui/icons/History';
import GetAppIcon from '@material-ui/icons/GetApp';
const parentTranslationPath = 'AML';
const translationPath = '';

const Row = (props) => {
  const {
    row,
    setOpen,
    setIDS,
    setData,
    setAmlTransactionId,
    AMLTransactions,
    data,
    PushTOlink,
    t,
    steps,
    setOpenDialog,
    AllAMLTransactions,
    APITransactionManualCancel,
    filter,
    APITransactionManualApprove,
    isLoading,
    APITransactionComplete,
    setReason,
    openDialogHandler,
    comment,
    open,
    openDocumentsDialogHandler,
    activeItem,
    setActiveItem,
    reload,
    reason,
    remarks,
    activeTab,
    setIsLoading,
    setIsOpenDialog,
    APIGetAllAMLTransactions,
    IDS,
    AMLReason,
    setAMLReason,
    setFilter,
    initialFilter,
    openAMLDocumentsDialogHandler,
    openAMLHistoryDialog,
    onExportClicked,
  } = props;
  const [activeStep, setActiveStep] = React.useState(2);

  useEffect(() => {
    if (!AMLTransactions.responseReceiveDate) setActiveStep(2);
    else if (!AMLTransactions.completeActionDate) setActiveStep(3);
    else setActiveStep(1);
  }, [AMLTransactions]);

  const openTransactionArrowHandler = () => {
    setActiveItem(row);
    setOpen({ index: props.index, open: true });
    setIDS(props.row.unitTransactionId);
    setData(props);
  };

  const closeTransactionArrowHandler = () => {
    setActiveItem(null);
    setOpen({ index: null, open: false });
    setIDS(null);
    setData(false);
  };

  return (
    <Fragment key={row.name}>
      <TableRow
        className={`TableRow-TableBody-aml   ${''}   ${
          data.index === props.index ? 'isselect' : ''
        }`}
        index={props.index}
      >
        <TableCell component='th' scope='row'>
          {row.unitType}
        </TableCell>
        {activeTab === 0 && (
          <TableCell align='right'>{row.unitSaleType}</TableCell>
        )}
        <TableCell align='right'>
          <ButtonBase
            onClick={() => {
              if (row.status === 'Pass') {
                PushTOlink(row, 'unitTransactionId');
              }
            }}
          >
            <div class='icon-container mx-1'>
              <span class='mdi mdi-open-in-new c-primary'></span>
            </div>
            {row.unitTransactionId}
          </ButtonBase>
        </TableCell>

        <TableCell align='right'>
          <ButtonBase
            onClick={() => {
              PushTOlink(row, 'unit');
            }}
          >
            <div class='icon-container mx-1'>
              <span class='mdi mdi-open-in-new c-primary'></span>
            </div>
            {row.unitRefNumber}
          </ButtonBase>
        </TableCell>
        <TableCell align='right'>{row.location}</TableCell>
        <TableCell align='right'>
          {moment(row.transactionDate).format('YYYY-M-DD - HH:mm:ss')}
        </TableCell>
        <TableCell align='right'>
          {t(`${translationPath}${row.transactionType}`)}
        </TableCell>
        <TableCell align='right'>
          <div className='d-flex-center flex-h-center'>
            {row.status === 'Pass' || row.status === 'Approved' ? (
              <div className='pass-lapel'>
                {t(`${translationPath}${row.status}`)}
              </div>
            ) : row.status === 'Fail' || row.status === 'Rejected' ? (
              <div className='Fail-lapel'>
                {t(`${translationPath}${row.status}`)}
              </div>
            ) : row.status === 'Pending' ||
              row.status === 'WithdrawTransaction' ||
              row.status === 'ReassignedToRequester' ||
              row.status === 'NeedRevision' ||
              row.status === 'Resubmitted' ||
              row.status === 'AutoWithdraw' ? (
              <div className='Pending-lapel'>
                {t(`${translationPath}${row.status}`)}
              </div>
            ) : (
              'N/A'
            )}
          </div>
        </TableCell>
        <TableCell align='right'>{row.amount}</TableCell>
        <TableCell align='right'>
          {row.completeDate !== null &&
            moment(row.completeDate).format('YYYY-M-DD - HH:mm:ss')}
        </TableCell>
        <TableCell align='right'>
          <IconButton aria-label='expand row' size='small'>
            {open.index === props.index && open.open ? (
              <KeyboardArrowUpIcon
                id='KeyboardArrowUpIcon-ref'
                onClick={() => {
                  closeTransactionArrowHandler(row);
                }}
              />
            ) : (
              <KeyboardArrowDownIcon
                id='KeyboardArrowDownIcon-ref'
                onClick={() => {
                  openTransactionArrowHandler(row);
                }}
              />
            )}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell
          className='TableCell-BLOCK'
          style={{ paddingBottom: 0, paddingTop: 0 }}
          size='small'
          colSpan={24}
        >
          {/* <div />/in= timeout='auto' unmountOnExit */}
          <div
            className='claapse_wraper'
            style={
              open.index === props.index && open.open === true
                ? { display: 'block' }
                : { display: 'none' }
            }
          >
            <div className='d-flex mr-4 '>
              <div className='Contanier_StepperAndRevession'>
                <div className='stepaer-mian-wr'>
                  {!!Object.values(AMLTransactions).length && (
                    <div className='stepaer-inner-container'>
                      <Stepper activeStep={activeStep} alternativeLabel>
                        {steps.map((label, index) => (
                          <Step key={index} index={index}>
                            <StepLabel>{label.maintaitle}</StepLabel>
                            <div className='d-flex-column-center'>
                              {label.value}
                            </div>
                          </Step>
                        ))}
                      </Stepper>
                    </div>
                  )}
                </div>
              </div>
              <PermissionsComponent
                permissionsList={Object.values(AMLViewPermissions)}
                permissionsId={AMLViewPermissions?.ExportAML?.permissionsId}
              >
                <div className='history-button-container mt-2 mx-2'>
                  <ButtonBase
                    className='history-cont'
                    onClick={() => onExportClicked(row.unitTransactionId)}
                  >
                    <GetAppIcon color='primary' />
                    <span className='history-text'>{t('export')}</span>
                  </ButtonBase>
                </div>
              </PermissionsComponent>

              <PermissionsComponent
                permissionsList={Object.values(AMLViewPermissions)}
                permissionsId={AMLViewPermissions?.ViewHistory?.permissionsId}
              >
                <div className='history-button-container mt-2'>
                  <ButtonBase
                    className='history-cont'
                    onClick={openAMLHistoryDialog}
                  >
                    <HistoryIcon color='primary' />
                    <span className='history-text'>{t('history')}</span>
                  </ButtonBase>
                </div>
              </PermissionsComponent>
            </div>

            <div className='darkline' />
            <div>
              <div className='margin-titel'>
                {' '}
                {t(`${translationPath}ContactsInfo`)}{' '}
              </div>
              <div className='row-item-wrap'>
                {open.index === props.index &&
                  // eslint-disable-next-line no-return-assign
                  AllAMLTransactions.map((item, index) => (
                    <div key={index}>
                      <div className='row-wrper-line'>
                        <div className='row-item'>
                          <div className='row-item-sub-title'>
                            {t(`${translationPath}Name`)}{' '}
                          </div>

                          <div className='row-item-sub-value d-flex'>
                            <ButtonBase
                              onClick={() => {
                                PushTOlink(item, 'Contacts');
                              }}
                            >
                              <div class='icon-container mx-1'>
                                <span class='mdi mdi-open-in-new c-primary'></span>
                              </div>
                            </ButtonBase>
                            {item.contactName || 'N/A'}
                          </div>
                        </div>
                        <div className='row-item'>
                          <div className='row-item  EMAIL-S'>
                            <div className='row-item-sub-title'>
                              {' '}
                              {t(`${translationPath}Email`)}{' '}
                            </div>
                            <Tooltip title={item.email}>
                              <div className='row-item-sub-value nowrap'>
                                {' '}
                                {item.email || 'N/A'}
                              </div>
                            </Tooltip>
                          </div>
                        </div>
                        <div className='row-item'>
                          <div className='row-item-sub-title'>
                            {' '}
                            {t(`${translationPath}nationality`)}{' '}
                          </div>
                          <div className='row-item-sub-value'>
                            {item.nationality || 'N/A'}
                          </div>
                        </div>

                        <div className='row-item'>
                          <div className='row-item-sub-title'>
                            {' '}
                            {t(`${translationPath}Location`)}{' '}
                          </div>
                          <div className='row-item-sub-value'>
                            {item.location || 'N/A'}
                          </div>
                        </div>
                      </div>

                      <div className='row-wrper-line'>
                        <div className='row-item'>
                          <div className='row-item-sub-title'>
                            {' '}
                            {t(`${translationPath}Language`)}{' '}
                          </div>
                          <div className='row-item-sub-value'>
                            {item.language || 'N/A'}
                          </div>
                        </div>

                        {(item && item.traxContactId && (
                          <div className='row-item'>
                            <div className='row-item-sub-title'>
                              {' '}
                              {t(`${translationPath}screening-id`)}{' '}
                            </div>
                            <Tooltip title={item && item.traxContactId}>
                              <div className='row-item-sub-value nowrap'>
                                {' '}
                                {(item && item.traxContactId) || 'N/A'}
                              </div>
                            </Tooltip>
                          </div>
                        )) ||
                          ''}

                        <div className='row-item'>
                          <div className='row-item-sub-title'>
                            {' '}
                            {t(`${translationPath}screening-status`)}{' '}
                          </div>
                          <div className='row-item-sub-value'>
                            {item.traxStatus === 'Pass' ? (
                              <div className='pass-lapel'>
                                {t(`${translationPath}${item.traxStatus}`)}
                              </div>
                            ) : item.traxStatus === 'Fail' ? (
                              <div className='Fail-lapel'>
                                {t(`${translationPath}${item.traxStatus}`)}
                              </div>
                            ) : item.traxStatus === 'Pending' ? (
                              <div className='Pending-lapel'>
                                {t(`${translationPath}${item.traxStatus}`)}
                              </div>
                            ) : (
                              'N/A'
                            )}
                          </div>
                        </div>

                        <div className='row-item'>
                          <div className='row-item-sub-title'>
                            {' '}
                            {t(`${translationPath}status`)}{' '}
                          </div>
                          <div className='row-item-sub-value'>
                            {item.status === 'Pass' ||
                            item.status === 'Approved' ? (
                              <div className='pass-lapel'>
                                {t(`${translationPath}${item.status}`)}
                              </div>
                            ) : item.status === 'Fail' ||
                              item.status === 'Rejected' ? (
                              <div className='Fail-lapel'>
                                {t(`${translationPath}${item.status}`)}
                              </div>
                            ) : item.status === 'Pending' ||
                              item.status === 'WithdrawTransaction' ||
                              item.status === 'ReassignedToRequester' ||
                              item.status === 'NeedRevision' ||
                              item.status === 'Resubmitted' ||
                              item.status === 'AutoWithdraw' ? (
                              <div className='Pending-lapel'>
                                {t(`${translationPath}${item.status}`)}
                              </div>
                            ) : (
                              'N/A'
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>

              <div className='part-tow-secation'>
                <div className='margin-titel'>
                  {t(`${translationPath}RequestDetails`)}
                </div>
                <div className='d-flex flx-v-been w-100'>
                  <div className='Request-Details'>
                    <div className='row-item'>
                      <div className='row-item-sub-title'>
                        {t(`${translationPath}RequestCreated`)}
                      </div>
                      <div className='row-item-sub-value mt-3'>
                        {AMLTransactions?.requestCreated
                          ? moment(AMLTransactions?.requestCreated).format(
                              'YYYY-M-DD - HH:mm:ss'
                            )
                          : 'N/A'}
                      </div>
                    </div>
                    <div>
                      <div className='row-item-sub-title'>
                        {(AMLTransactions &&
                          AMLTransactions.completeActionDate !== null &&
                          AMLTransactions &&
                          AMLTransactions.completeActionDate &&
                          t(`${translationPath}TransactionComplete`)) ||
                          (AMLTransactions &&
                            AMLTransactions.responseReceiveDate !== null &&
                            t(`${translationPath}Resultreceived`)) ||
                          (AMLTransactions?.requestSent &&
                            t(`${translationPath}RequestSent`)) ||
                          'N/A'}
                      </div>
                      <div className='row-item-sub-value mt-3'>
                        {(AMLTransactions.completeActionDate &&
                          moment(
                            AMLTransactions &&
                              AMLTransactions.completeActionDate
                          ).format('YYYY-M-DD - HH:mm:ss')) ||
                          (AMLTransactions.responseReceiveDate &&
                            moment(
                              AMLTransactions &&
                                AMLTransactions.responseReceiveDate
                            ).format('YYYY-M-DD - HH:mm:ss')) ||
                          (AMLTransactions.requestSent &&
                            moment(AMLTransactions.requestSent).format(
                              'YYYY-M-DD - HH:mm:ss'
                            )) ||
                          'N/A'}
                      </div>
                    </div>
                    <div>
                      <div className='row-item-sub-title'>
                        {t(`${translationPath}Createdby`)}
                      </div>
                      <div className='row-item-sub-value mt-3'>
                        {(AMLTransactions && AMLTransactions.createdByName) ||
                          'N/A'}
                      </div>
                    </div>
                    <div>
                      <div className='row-item-sub-title'>
                        {t(`${translationPath}Release-Type`)}
                      </div>
                      <div className='row-item-sub-value mt-3'>
                        {(AMLTransactions &&
                          AMLTransactions.amlReleaseTypeDto &&
                          AMLReleaseType[
                            AMLTransactions &&
                              AMLTransactions.amlReleaseTypeDto.releaseType
                          ] &&
                          t(
                            `Shared:${
                              AMLReleaseType[
                                AMLTransactions &&
                                  AMLTransactions.amlReleaseTypeDto.releaseType
                              ].name
                            }`
                          )) ||
                          'N/A'}
                        {(AMLTransactions &&
                          AMLTransactions.amlReleaseTypeDto &&
                          AMLTransactions.amlReleaseTypeDto.releaseType ===
                            AMLReleaseType[3].value &&
                          `:  ${
                            AMLTransactions &&
                            AMLTransactions.amlReleaseTypeDto &&
                            AMLTransactions.amlReleaseTypeDto
                              .manualApprovedByName
                          }`) ||
                          ' '}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='part-tow-secation'>
                <div className='margin-titel'>
                  {t(`${translationPath}documents`)}
                </div>
                <div className='d-flex flx-v-been w-100'>
                  <div className='Request-Details'>
                    <div className='row-item'>
                      <div className='row-item-sub-title'>
                        {t(`${translationPath}contact-document`)}
                      </div>
                      <div className='row-item-sub-value mt-3'>
                        <span>
                          {AMLTransactions?.contactDocuments || 'N/A'}
                        </span>
                      </div>
                    </div>
                    <div>
                      <div className='row-item-sub-title'>
                        {t(`${translationPath}unit-documents`)}
                      </div>
                      <div className='row-item-sub-value mt-3'>
                        <span>{AMLTransactions?.unitDocuments || 'N/A'}</span>
                      </div>
                    </div>
                    <PermissionsComponent
                      permissionsList={Object.values(AMLViewPermissions)}
                      permissionsId={
                        AMLViewPermissions?.ViewDocument?.permissionsId
                      }
                    >
                      <div>
                        <div className='row-item-sub-title'>
                          <span>{t(`${translationPath}view-documents`)}</span>
                        </div>
                        <div className='row-item-sub-value mt-3'>
                          <span
                            className='text-primary bg-transparent'
                            role='button'
                            onClick={() => {
                              openDocumentsDialogHandler();
                            }}
                          >
                            {t('show')}
                          </span>
                        </div>
                      </div>
                    </PermissionsComponent>
                  </div>
                </div>

                <div className='part-tow-secation'>
                  <div className='margin-titel'>
                    {t(`${translationPath}aml-documents`)}
                  </div>
                  <div className='d-flex flx-v-been w-100'>
                    <div className='Request-Details'>
                      <div className='row-item'>
                        <div className='row-item-sub-title'>
                          {t(`${translationPath}aml-documents`)}
                        </div>
                        <div className='row-item-sub-value mt-3'>
                          <span>
                            {AMLTransactions?.amlTransactionDocuments || 'N/A'}
                          </span>
                        </div>
                      </div>
                      <div>
                        <div className='row-item-sub-title'>
                          <span>
                            {t(`${translationPath}is-opportunity-contact`)}
                          </span>
                        </div>
                        <div className='row-item-sub-value mt-3'>
                          <span>
                            {AllAMLTransactions[0]?.isOpportunityContact
                              ? t('yes')
                              : t('no')}
                          </span>
                        </div>
                      </div>
                      <div>
                        <div className='row-item-sub-title'>
                          <span>{t(`${translationPath}view-documents`)}</span>
                        </div>
                        <div className='row-item-sub-value mt-3'>
                          <span
                            className='text-primary bg-transparent'
                            role='button'
                            onClick={() => {
                              openAMLDocumentsDialogHandler();
                            }}
                          >
                            {t('show')}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {(AMLTransactions?.isComplete === false && (
                  <>
                    <div className='d-flex mt-4'>
                      <PermissionsComponent
                        permissionsList={Object.values(AMLViewPermissions)}
                        permissionsId={
                          AMLViewPermissions?.ApproveAction?.permissionsId
                        }
                      >
                        <div className='ml-2'>
                          <Button
                            className='Complete-bbt MuiButtonBase-root btns theme-solid mb-2'
                            onClick={() => {
                              setReason('approve-reason');
                              openDialogHandler();
                            }}
                            id='approveButtonRef'
                          >
                            <span>{t(`${translationPath}approve`)}</span>
                          </Button>
                        </div>
                      </PermissionsComponent>
                      <PermissionsComponent
                        permissionsList={Object.values(AMLViewPermissions)}
                        permissionsId={
                          AMLViewPermissions?.RejectAction?.permissionsId
                        }
                      >
                        <div className='ml-2 '>
                          <Button
                            className='Complete-bbt MuiButtonBase-root btns theme-solid mb-2'
                            onClick={() => {
                              setReason('reject-reason');
                              openDialogHandler();
                            }}
                            id='rejectButtonRef'
                          >
                            <span>{t(`${translationPath}reject`)}</span>
                          </Button>
                        </div>
                      </PermissionsComponent>
                      <PermissionsComponent
                        permissionsList={Object.values(AMLViewPermissions)}
                        permissionsId={
                          AMLViewPermissions?.ReassignToRequester?.permissionsId
                        }
                      >
                        <div className='ml-2'>
                          <Button
                            className='Complete-bbt MuiButtonBase-root btns theme-solid mb-2'
                            onClick={() => {
                              setReason('reassign-to-requester-reason');
                              openDialogHandler();
                            }}
                            id='reassignButtonRef'
                          >
                            <span>
                              {t(`${translationPath}reassign-to-requester`)}
                            </span>
                          </Button>
                        </div>
                      </PermissionsComponent>
                      <PermissionsComponent
                        permissionsList={Object.values(AMLViewPermissions)}
                        permissionsId={
                          AMLViewPermissions?.WithdrawTransaction?.permissionsId
                        }
                      >
                        <div className='ml-2'>
                          <Button
                            className='Complete-bbt MuiButtonBase-root btns theme-solid mb-2'
                            onClick={() => {
                              setReason('withdraw-transaction-reason');
                              openDialogHandler();
                            }}
                            id='withdrawButtonRef'
                          >
                            <span>
                              {t(`${translationPath}withdraw-transaction`)}
                            </span>
                          </Button>
                        </div>
                      </PermissionsComponent>
                    </div>
                  </>
                )) ||
                  ''}
              </div>
              {AMLReason !== null && (
                <>
                  <div className='part-Three-secation'>
                    <div className='margin-titel-comment'>
                      {t(`${translationPath}reason`)}
                    </div>
                    <div className='continer_comments'>
                      <b>{t(`${translationPath}${AMLReason}`)}</b>
                    </div>
                  </div>

                  <div className='part-Three-secation'>
                    <div className='margin-titel-comment'>
                      {t(`${translationPath}remarks`)}
                    </div>
                    <div className='continer_comments'>
                      <b>{remarks}</b>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </TableCell>
      </TableRow>
    </Fragment>
  );
};

export default memo(Row);
