import { config } from '../config';
import { HttpServices } from '../Helper';


export const GetAllActivityTypesAPI = async ({ pageSize, pageIndex, search }) => {
  const queryList = [];
  if (pageSize || pageSize === 0) queryList.push(`pageSize=${pageSize}`);
  if (pageIndex || pageIndex === 0) queryList.push(`pageIndex=${pageIndex + 1}`);
  const result = await HttpServices.get(
    `${config.server_address}/CrmDfm/ActivityTypes/${pageIndex + 1}/${pageSize}?search=${search || ''}`
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

export const GetAllRelatedActivities = async (body) => {
  const result = await HttpServices.post(`${config.server_address}/CrmDfm/Activities/GetAllNotRelatedActivities`, body)
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

export const GetAllRelatedActivitiesByActivityId = async (activityId) => {
  const result = await HttpServices.get(
    `${config.server_address}/CrmDfm/Activities/GetAllRelatedActivitiesByActivityId?activityId=${activityId}`
  )
    .then((data) => data)
    .catch(() => undefined);
  return result;
};

export const GetAllActivitiesByLeadId = async (leadId, { pageSize, pageIndex }) => {
  const queryList = [];
  if (leadId) queryList.push(`leadId=${leadId}`);
  const result = await HttpServices.get(
    `${config.server_address}/CrmDfm/Activities/GetAllActivitiesByLeadId/${pageIndex + 1
    }/${pageSize}?${queryList.join('&')}`
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

export const GetAllActivityTypes = async () => {
  const result = await HttpServices.get(
    `${config.server_address}/CrmDfm/Activities/GetAllActivityTypes`
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};
export const GetAllActivityTypesSearch = async (search) => {
  const result = await HttpServices.get(
    `${config.server_address}/CrmDfm/Activities/GetAllActivityTypes?search=${search}`
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

export const GetAllActivityTypesWithPrerequisites = async (search) => {
  const result = await HttpServices.get(
    `${config.server_address}/CrmDfm/Activities/GetAllActivityTypesWithPrerequisites?search=${search}`
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

export const GetAllReplyActivityType = async (activityTypeId, search) => {
  const result = await HttpServices.get(
    `${config.server_address}/CrmDfm/Activities/GetAllReplyActivityType?activityTypeId=${activityTypeId}`
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

export const GetMyActivities = async (startDate, endDate) => {
  const result = await HttpServices.get(
    `${config.server_address}/CrmDfm/Activities/GetMyActivities/${startDate}/${endDate}`
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};
export const EditActivity = async (activityId, body) => {
  const result = await HttpServices.put(
    `${config.server_address}/CrmDfm/Activities/${activityId}`,
    body
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};
export const CreateActivity = async (body) => {
  const result = await HttpServices.post(`${config.server_address}/CrmDfm/Activities`, body)
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

export const DeleteActivity = async (id) => {
  const result = await HttpServices.delete(`${config.server_address}/CrmDfm/Activities/${id}`)
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};
export const GetAllActivitiesByContactId = async (body) => {
  const result = await HttpServices.post(
    `${config.server_address}/CrmDfm/Activities/GetAllActivitiesByContactId`, body)
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};
export const GetAllActivitiesByUnitId = async (body) => {

  const result = await HttpServices.post(
    `${config.server_address}/CrmDfm/Activities/GetAllActivitiesByUnitId`, body
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};
export const GetActivityById = async (activityId) => {
  const result = await HttpServices.get(
    `${config.server_address}/CrmDfm/Activities/GetActivityById/${activityId}`
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};
export const GetContactActivitiesByPhoneNumber = async (id) => {
  const headers = {
    ApiKey: config.ApiKey,
  };
  const result = await HttpServices.get(
    `${config.server_address}/CrmDfm/Activities/GetContactActivitiesByPhoneNumber/?phoneNumber=${id}`,
    { headers }
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

export const GetMyNotifications = async (body) => {
  const result = await HttpServices.post(
    `${config.server_address}/CrmDfm/Activities/GetMyNotifications`, body
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};


export const GetActivityTypeBasedOnRelatedType = async ({
  leadStageId,
  leadClassId,
  unitOperationId = 0,
  unitSaleTypeId = null,
  unitTypeId = null,
  contactClassificationId,
  contactId,
  listActivityTypeRelationTypes
}) => {
  const result = await HttpServices.post(
    `${config.server_address}/CrmDfm/ActivityTypes/GetActivityTypeBasedOnRelatedType`,
    {
      leadStageId,
      leadClassId,
      unitOperationId,
      unitSaleTypeId,
      contactClassificationId,
      contactId,
      listActivityTypeRelationTypes,
      unitTypeId,
    }
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

export const GetQaActivities = async (body) => {
  const result = await HttpServices.post(
    `${config.server_address}/CrmDfm/Activities/GetQaActivities`
    , body)
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};
export const GetAllQaActivities = async (body) => {
  const result = await HttpServices.post(
    `${config.server_address}/CrmDfm/Activities/GetAllQaActivities`
    , body)
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

export const GetActivitiesByPropertyManagementEntityId = async (body) => {
  const result = await HttpServices.post(
    `${config.server_address}/CrmDfm/Activities/GetActivitiesByPropertyManagementEntityId`
    , body)
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

export const GetActivities = async (body) => {
  const result = await HttpServices.post(
    `${config.server_address}/CrmDfm/Activities/GetActivities`
    , body)
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

export const GetAllActivities = async (body) => {
  const result = await HttpServices.post(
    `${config.server_address}/CrmDfm/Activities/GetAllActivities`
    , body)
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

export const GetAllLeadAuditorAgentsActivities = async (body) => {
  const result = await HttpServices.post(
    `${config.server_address}/CrmDfm/LeadAuditor/GetAllLeadAuditorAgentsActivities`
    , body)
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

export const GetActivityNotification = async (activityId) => {
  const result = await HttpServices.get(
    `${config.server_address}/CrmDfm/Activities/GetActivityNotification/${activityId}`
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

export const GetActivityFilesById = async (activityId) => {
  const result = await HttpServices.get(
    `${config.server_address}/CrmDfm/Activities/GetActivityFilesById?id=${activityId}`
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

export const GetActivityRemindersById = async (activityId) => {
  const result = await HttpServices.get(
    `${config.server_address}/CrmDfm/Activities/GetActivityRemindersById/${activityId}`
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

export const GetActivityNotificationHistory = async (activityId) => {
  const result = await HttpServices.get(
    `${config.server_address}/CrmDfm/Activities/GetActivityNotificationHistory/${activityId}`
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

export const GetAllCommunicationActivities = async (pageSize , pageIndex  ,body) => {

  const queryList = [];
  if (pageSize || pageSize === 0) queryList.push(`pageSize=${pageSize}`);
  if (pageIndex || pageIndex === 0) queryList.push(`pageIndex=${pageIndex + 1}`);

  const result = await HttpServices.post(
    `${config.server_address}/CrmDfm/Activities/GetAllCommunicationActivities?${queryList.join('&')}`, body)
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};
  
 export const MarkAllUserNotificationsAsReadAPI = async () => {
  const result = await HttpServices.put(
    `${config.server_address}/CrmDfm/Activities/MarkAllUserNotificationsAsRead`
    )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};