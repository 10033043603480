import React, { useEffect, useState } from "react";
import { PowerBIEmbed } from "powerbi-client-react";
import { models } from "powerbi-client";
import "../MasterStyleDashborads/MasterStyleDashboards.scss";
import { MicrosoftToken } from "../../../../Services/DashboardService";
import { PowerBiEnum } from "../../../../Enums";
import { GlobalTranslate, showError } from "../../../../Helper";
import { useTitle } from "../../../../Hooks";
import { SpinnerDashboards } from "../SpinnerDashboards/SpinnerDashboards";
import { CellSpinnerBi } from "../CellSpinnerBi/CellSpinnerBi";
import { ButtonBase, ButtonGroup, Switch } from "@material-ui/core";
import Brightness4Icon from "@material-ui/icons/Brightness4";
import WbSunnyIcon from "@material-ui/icons/WbSunny";
export const UserDashboard = () => {
  const [report, setReport] = useState();
  const [userid, setUserid] = useState(null);
  const [render, setRender] = useState(false);
  const [ShowReport, setShowReport] = useState(false);

  useTitle(GlobalTranslate.t("Dashboard:userlogin-dashboard"));
  const Filter = {
    $schema: "http://powerbi.com/product/schema#basic",
    target: {
      table: "UserLogin",
      column: "UserId",
    },
    operator: "In",
    values: [userid],
    filterType: models.FilterType.BasicFilter,
    requireSingleSelection: true,
  };

  const Microsoft = async (isExpired) => {
    const res = await MicrosoftToken(isExpired);
    if (!(res && res.status && res.status !== 200)) {
      setReport(res);
      setRender(true);
    } else {
      setReport("");
      showError("Contact Your Bi Admin For Help");
    }
  };
  const [activeThemeButton, setActiveThemeButton] = useState("blue");
  const handleButtonClick2 = (buttonName) => {
    setActiveThemeButton(buttonName);
  };

  const [state, setState] = useState({
    checkedA: false,
  });

  const [Themeicon, setThemeicon] = useState(false);

  const applytheme = async () => {
    const report = window.report;
    handleButtonClick2("gold");
    setThemeicon(true);
    if (report) {
      const themeJsonObject = require("../CRMFilterDashboards/Theme_1.json");


      try {
        // Apply the theme
        report.applyTheme({ themeJson: themeJsonObject });

        console.log("Theme applied successfully!");
      } catch (error) {
        console.error("Error applying theme:", error);
      }
    }
  };
  const applytheme2 = async () => {
    const report = window.report;
    handleButtonClick2("blue");
    setThemeicon(false);
    if (report) {
      const themeJsonObject = require("../CRMFilterDashboards/Theme_2.json");

      try {
        // Apply the theme
        report.applyTheme({ themeJson: themeJsonObject });

        console.log("Theme applied successfully!");
      } catch (error) {
        console.error("Error applying theme:", error);
      }
    }
  };
  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
    if (event.target.checked) {
      applytheme();
    } else {
      applytheme2();
    }
  };
  useEffect(() => {
    setUserid(JSON.parse(localStorage.getItem("session")).userId || null);
  }, [userid]);

  useEffect(() => {
    Microsoft(false);
  }, []);

  return (
    <div className="dashboardMain-PowerBIEmbed">
      <div>
        {ShowReport && (
          <div className="SwitchTheme">
            <div className="ThemeIcon">
              {Themeicon ? <Brightness4Icon /> : <WbSunnyIcon />}
            </div>
            <div>
              <Switch
                onChange={handleChange}
                color="#7f6944"
                name="theme"
                inputProps={{ "aria-label": "primary checkbox" }}
              />
            </div>
          </div>
        )}
        <div className="mainCardDashboards">
          {render && (
            <div
              className={`dashboardMain  ${
                activeThemeButton === "gold"
                  ? "cardDashboardgold"
                  : "cardDashboard"
              }`}
            >
              <CellSpinnerBi
                isActive={!ShowReport}
                blue={activeThemeButton === "blue"}
              />

              <PowerBIEmbed
                embedConfig={{
                  type: "report",
                  id: PowerBiEnum.UserLogin.reportid,
                  embedUrl: PowerBiEnum.UserLogin.url,
                  accessToken: report,
                  pageView: "fitToWidth",
                  filters: [Filter],
                  settings: {
                    customLayout: {
                      displayOption: models.DisplayOption.FitToWidth,
                    },
                    filterPaneEnabled: false,
                    navContentPaneEnabled: false,
                    panes: {
                      filters: {
                        expanded: false,
                        visible: false,
                      },
                    },
                    background: models.BackgroundType.Transparent,
                  },
                }}
                eventHandlers={
                  new Map([
                    [
                      "loaded",
                      () => {
                        setShowReport(true);
                      },
                    ],
                    ["rendered", (event) => {}],
                    [
                      "error",
                      (event, embed) => {
                        const error = event.detail;

                        if (
                          error.detailedMessage === "Get report failed" ||
                          error.detailedMessage ===
                            "Access token has expired, resubmit with a new access token" ||
                          error.detailedMessage ===
                            "Fail to initialize - Could not resolve cluster"
                        ) {
                          Microsoft(true);
                          setRender(false);
                        } else console.log(error.detailedMessage);
                        showError(error.detailedMessage);
                      },
                    ],
                  ])
                }
                cssClassName={
                  ShowReport
                    ? "report-style-class-Show"
                    : "report-style-class-hide"
                }
                getEmbeddedComponent={(embeddedReport) => {
                  window.report = embeddedReport;
                }}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
