import React, {
  useCallback, useEffect, useState, useRef
} from 'react';
import moment from 'moment';

import { useTranslation } from 'react-i18next';
import { AutocompleteComponent} from '../../../../Components';
import { DateRangePickerComponent } from '../../../../Components/Controls/DateRangePickerComponent/DateRangePickerComponent';
import { ContactsFilter ,LeadsFilter ,  AgentsFilter , ActivityTypeFilter  , CommunicationFilter  } from '../ActivitiesCommunicationLogsFilter' ; 


export const ActivitiesCommunicationLogsFilter = ({
    translationPath,
    parentTranslationPath,
    filter, 
    setFilter , 
    selected , 
    setSelected ,
    setDateFilter , 
    dateFilter , 
    dateRangeDefault , 
}) => {
    const { t } = useTranslation(parentTranslationPath);

    return (

        <div className='filter-section-activities-communication-Logs'>
             <div className='flex-v-end mx-5'>
              <div className='section-filters'>
                 <ActivityTypeFilter
                  translationPath={translationPath}
                  parentTranslationPath={parentTranslationPath}
                  filter={filter}
                  setFilter={setFilter}
                  selected= {selected}
                  setSelected={setSelected}
                />
                 <AgentsFilter
                  translationPath={translationPath}
                  parentTranslationPath={parentTranslationPath}
                  filter={filter}
                  setFilter={setFilter}
                  selected= {selected}
                  setSelected={setSelected}
                />
                <ContactsFilter
                  translationPath={translationPath}
                  parentTranslationPath={parentTranslationPath}
                  filter={filter}
                  setFilter={setFilter}
                  selected= {selected}
                  setSelected={setSelected}
                />
              </div>
                
              <div className='section-filters'>
                 <LeadsFilter
                  translationPath={translationPath}
                  parentTranslationPath={parentTranslationPath}
                  filter={filter}
                  setFilter={setFilter}
                  selected= {selected}
                  setSelected={setSelected}
                />
                 <CommunicationFilter
                  translationPath={translationPath}
                  parentTranslationPath={parentTranslationPath}
                  filter={filter}
                  setFilter={setFilter}
                  selected= {selected}
                  setSelected={setSelected}
                />
              </div>
              <div className='section-filters'>
              <div className='datePickerComponentAgentRotation'>
              <DateRangePickerComponent
                  onClearClicked={() => setDateFilter(dateRangeDefault)}
                  ranges={[dateFilter]}
                  parentTranslationPath={parentTranslationPath}
                  translationPath={translationPath}
                  onDateChanged={(selectedDate) => {
                   setDateFilter({
                    startDate: (selectedDate.selection && selectedDate.selection.startDate),
                    endDate: new Date(moment(selectedDate.selection && selectedDate.selection.endDate).endOf('day')),
                    key: 'selection',
                  });
                }}
              />
            </div>
              </div>
            </div>
        </div>
    );
};
