import React, { useState } from 'react';
import { Inputs } from '../../../../../../../Components';
import LookupAutocomplete from '../../../../../../../Components/Controls/LookupAutocomplete/LookupAutocomplete';
import { StaticLookupsIds } from '../../../../../../../assets/json/StaticLookupsIds';
import { useTranslation } from 'react-i18next';

const parentTranslationPath = 'LeadsView';
const translationPath = 'utilities.cardDetailsComponent.';
function SearchView({
  location,
  setCountry,
  setCity,
  setDistrict,
  setCommunity,
  setBedroomsNumber,
  setBathroomsNumber,
  setPriceFrom,
  setPriceTo,
  setSizeFrom,
  setSizeTo,
  setSubCommunity,
  setUnitTypeId,
  params,
}) {
  const { t } = useTranslation(parentTranslationPath);
  const [selectedUnitType, setSelectedUnitType] = useState(null);
  return (
    <div className=' d-flex gap-1 flex-wrap'>
      <LookupAutocomplete
        lookupTypeId={StaticLookupsIds.Country}
        selectedValues={location.country}
        parentTranslationPath={parentTranslationPath}
        translationPath={translationPath}
        onChange={(_e, newValue) => setCountry(newValue)}
        label={'country'}
        wrapperClasses={'w-50'}
      />
      <LookupAutocomplete
        lookupTypeId={StaticLookupsIds.Cities}
        selectedValues={location.city}
        lookupParentId={location.country?.lookupItemId}
        parentTranslationPath={parentTranslationPath}
        translationPath={translationPath}
        onChange={(_e, newValue) => setCity(newValue)}
        label={'city'}
        wrapperClasses={'w-50'}
      />
      <LookupAutocomplete
        lookupTypeId={StaticLookupsIds.District}
        selectedValues={location.district}
        lookupParentId={location.city?.lookupItemId}
        parentTranslationPath={parentTranslationPath}
        translationPath={translationPath}
        onChange={(_e, newValue) => setDistrict(newValue)}
        label={'district'}
        wrapperClasses={'w-50'}
        disabled={!location.city?.lookupItemId}
      />
      <LookupAutocomplete
        lookupTypeId={StaticLookupsIds.Community}
        selectedValues={location.community}
        lookupParentId={location.district?.lookupItemId}
        parentTranslationPath={parentTranslationPath}
        translationPath={translationPath}
        onChange={(_e, newValue) => setCommunity(newValue)}
        label={'community'}
        wrapperClasses={'w-50'}
        disabled={!location.district?.lookupItemId}
      />
      <LookupAutocomplete
        lookupTypeId={StaticLookupsIds.SubCommunity}
        selectedValues={location.subCommunity}
        lookupParentId={location.community?.lookupItemId}
        parentTranslationPath={parentTranslationPath}
        translationPath={translationPath}
        onChange={(_e, newValue) => setSubCommunity(newValue)}
        label={'subCommunity'}
        wrapperClasses={'w-50'}
        disabled={!location.community?.lookupItemId}
      />
      <LookupAutocomplete
        lookupTypeId={StaticLookupsIds.UintType}
        selectedValues={selectedUnitType}
        parentTranslationPath={parentTranslationPath}
        translationPath={translationPath}
        onChange={(_e, newValue) => {
          setUnitTypeId(newValue?.lookupItemId);
          setSelectedUnitType(newValue);
        }}
        label={'unit-type'}
        wrapperClasses={'w-50'}
      />
      <div className='w-100 d-flex-column gap-1'>
        <div className='w-100 d-flex gap-1'>
          <Inputs
            labelValue={'number-of-bedrooms'}
            type={'number'}
            wrapperClasses={'w-50'}
            parentTranslationPath={parentTranslationPath}
            translationPath={translationPath}
            value={params.bedroomsNumber}
            onInputChanged={(e) => setBedroomsNumber(e.target.value)}
          />
          <Inputs
            labelValue={'number-of-bathrooms'}
            type={'number'}
            wrapperClasses={'w-50'}
            parentTranslationPath={parentTranslationPath}
            translationPath={translationPath}
            value={params.bathroomsNumber}
            onInputChanged={(e) => setBathroomsNumber(e.target.value)}
          />
        </div>
        <div className='w-100'>
          <h6>{t(`${translationPath}price`)}</h6>
          <div className='d-flex gap-1'>
            <Inputs
              labelValue={'from'}
              type={'number'}
              wrapperClasses={'w-50'}
              parentTranslationPath={parentTranslationPath}
              translationPath={translationPath}
              value={params.priceFrom}
              onInputChanged={(e) => setPriceFrom(e.target.value)}
            />
            <Inputs
              labelValue={'to'}
              type={'number'}
              wrapperClasses={'w-50'}
              parentTranslationPath={parentTranslationPath}
              translationPath={translationPath}
              value={params.priceTo}
              onInputChanged={(e) => {
                if (+e.target.value > 60000000) {
                  setPriceTo(60000000);
                } else {
                  setPriceTo(e.target.value);
                }
              }}
            />
          </div>
        </div>
        <div className='w-100'>
          <h6>{t(`${translationPath}size`)}</h6>
          <div className='d-flex gap-1'>
            <Inputs
              labelValue={'from'}
              type={'number'}
              wrapperClasses={'w-50'}
              parentTranslationPath={parentTranslationPath}
              translationPath={translationPath}
              value={params.sizeFrom}
              onInputChanged={(e) => setSizeFrom(e.target.value)}
            />
            <Inputs
              labelValue={'to'}
              type={'number'}
              wrapperClasses={'w-50'}
              parentTranslationPath={parentTranslationPath}
              translationPath={translationPath}
              value={params.sizeTo}
              onInputChanged={(e) => {
                if (+e.target.value > 10000) {
                  setSizeTo(10000);
                } else {
                  setSizeTo(e.target.value);
                }
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default SearchView;
