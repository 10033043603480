import React from "react";
import { Box } from "@material-ui/core";
import moment from "moment";

import {
  CustomBadge,
  CustomCopyToClipboard,
  LeadTypeBadges,
  LeadsPriorityBadges,
} from "../../../../../../Components";
import { AgentTypesEnum } from "../../../../../../Enums";

// Icons
import { CloseXIcon, CheckIcon } from "../../../../../../assets/icons";

export const dncrTableHeaderData = (isDarkMode = false) => [
  {
    id: 1,
    formFieldId: 1,
    minWidth: 250,
    headerName: "Updated by",
    field: "user",
    sortable: true,
    filterable: true,
    fieldType: "text",
    displayPath: "user",
    isDefaultFilterColumn: true,
    fieldKey: "user",
    formFieldTitle: "user",
    disableColumnMenu: true,
    searchKey: "user",

    renderCell: (item) => {
      return (
        <span
          style={{
            alignContent: "center",
          }}
        >
          {item?.row?.user}
        </span>
      );
    },
  },
  {
    id: 2,
    formFieldId: 2,
    minWidth: 150,
    headerName: "Date",
    field: "createdOn",
    displayPath: "createdOn",
    isHiddenFilter: false,
    filterable: true,
    fieldType: "datePicker",
    disableColumnMenu: true,
    fieldKey: "createdOn",
    isDate: true,
    sortable: true,
    isDefaultFilterColumn: true,
    formFieldTitle: "Date",
    renderCell: (item) => {
      return (
        <Box style={{ display: "flex", flexDirection: "column" }}>
          {item?.row?.createdOn && (
            <>
              <label style={{ lineHeight: "20px", fontWeight: 500 }}>
                {moment(item.row.createdOn).format("DD MMM YYYY")}
              </label>

              <span
                style={{
                  lineHeight: "20px",
                  fontWeight: 400,
                  color: !isDarkMode ? "#475467" : "#94969C",
                }}
              >
                {moment(item.row.createdOn).format("hh:mm A")}
              </span>
            </>
          )}
        </Box>
      );
    },
  },
  {
    id: 3,
    formFieldId: 3,
    minWidth: 150,
    headerName: "Change type",
    field: "changeType",
    sortable: true,
    filterable: true,
    fieldType: "text",
    displayPath: "changeType",
    isDefaultFilterColumn: true,
    fieldKey: "changeType",
    formFieldTitle: "changeType",
    disableColumnMenu: true,
    searchKey: "changeType",

    renderCell: (item) => {
      return (
        <span
          style={{
            alignContent: "center",
          }}
        >
          {item?.row?.changeType}
        </span>
      );
    },
  },
  {
    id: 4,
    formFieldId: 4,
    minWidth: 175,
    headerName: "DNCR status",
    field: "dncrName",
    fieldType: "text",
    fieldKey: "dncrName",
    sortable: true,
    displayPath: "dncrName",
    filterable: true,
    disableColumnMenu: true,
    isDefaultFilterColumn: true,
    formFieldTitle: "DNCR status",
    renderCell: (item) => {
      const isOpen = item?.row?.dncrName?.includes("Unblock");
      const color = isOpen
        ? !isDarkMode
          ? "#079455"
          : "#47CD89"
        : !isDarkMode
        ? "#D92D20"
        : "#F97066";

      const fill = color;

      return (
        <CustomBadge
          label={item?.row?.dncrName}
          BackgroundColor={"transparent"}
          BorderColor={"transparent"}
          Color={color}
          LeftIcon={
            isOpen ? (
              <CheckIcon width="20" height="20" fill={fill} />
            ) : (
              <CloseXIcon width="20" height="20" fill={fill} />
            )
          }
        />
      );
    },
  },
  {
    id: 5,
    formFieldId: 5,
    minWidth: 175,
    headerName: "Consent",
    field: "mainConsentName",
    fieldType: "text",
    fieldKey: "mainConsentName",
    sortable: true,
    displayPath: "mainConsentName",
    filterable: true,
    disableColumnMenu: true,
    isDefaultFilterColumn: true,
    formFieldTitle: "Consent",
    renderCell: (item) => {
      return (
        <span
          style={{
            alignContent: "center",
          }}
        >
          {item?.row?.mainConsentName}
        </span>
      );
    },
  },
  {
    id: 6,
    formFieldId: 6,
    minWidth: 200,
    headerName: "Reason for change",
    field: "changeReasonName",
    sortable: true,
    filterable: true,
    fieldType: "text",
    displayPath: "changeReasonName",
    isDefaultFilterColumn: true,
    fieldKey: "changeReasonName",
    formFieldTitle: "changeReasonName",
    disableColumnMenu: true,
    searchKey: "changeReasonName",

    renderCell: (item) => {
      return (
        <span
          style={{
            alignContent: "center",
          }}
        >
          {item?.row?.changeReasonName}
        </span>
      );
    },
  },
  {
    id: 7,
    formFieldId: 7,
    minWidth: 200,
    headerName: "Remarks",
    field: "remarks",
    sortable: true,
    filterable: true,
    fieldType: "text",
    displayPath: "remarks",
    isDefaultFilterColumn: true,
    fieldKey: "remarks",
    formFieldTitle: "remarks",
    disableColumnMenu: true,
    searchKey: "remarks",

    renderCell: (item) => {
      return (
        <span
          style={{
            alignContent: "center",
          }}
        >
          {item?.row?.remarks}
        </span>
      );
    },
  },
];
