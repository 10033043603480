
import {   ActivitiesCreatedBy , ActivitiesAssignTo, MyActivities  } from '../ActivitiesPolicies' ; 

export const ActivitiesPoliciesTabs = [
    {   
      label: 'createdBy',
      component:  ActivitiesCreatedBy ,
    } , 
    {   
        label: 'AssignTo',
        component: ActivitiesAssignTo  ,
    } ,
    {   
        label: 'MyActivities',
        component: MyActivities  ,
    } ,
]
  

