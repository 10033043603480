import React from 'react';
import { useSelector } from "react-redux";
import { Typography, Box } from '@material-ui/core';
import { CustomCard, CustomButton } from '..';
import { useIsAr, useSelectedTheme, useTranslate } from '../../../Hooks';

// Icons
import { ArrowNarrowLeft, ArrowNarrowRight } from '../../../assets/icons';

// styles
import useStyles from "./styles";

function SummaryCard({ title, subTitle, isSelected }) {
  const { translate } = useTranslate("Shared");

  const theme = useSelector((store) => store.theme);
  const { isAr } = useIsAr(theme.themeDirection);

  const {
    theme: { palette },
  } = useSelectedTheme();

  const styles = useStyles();

  return (
    <CustomCard
      classes={styles.cardStyle}
      boxShadow="xs"
      borderRadius="xl"
      borderColor={isSelected ? "brand" : "secondary"}
    >
      <Typography
        component='p'
        variant='body1'
        className={styles.title}
      >
        {title}
      </Typography>
      <Box 
        display='flex'
        flexDirection='row'
        justifyContent='space-between'
        alignItems='center'
        bgcolor='background.paper'
      >
        <Typography variant='h2' component='h2' className={styles.subTitle}>
          {subTitle}
        </Typography>
        <CustomButton
          boxShadow="none"
          size="sm"
          variant="text"
          color="tertiary"
          endIcon={
            isAr ?
              <ArrowNarrowLeft
                width="20"
                height="20"
                fill={palette.button.tertiary_fg}
              />
            :
            <ArrowNarrowRight
              width="20"
              height="20"
              fill={palette.button.tertiary_fg}
            />
          }
        >
          {translate("common-show")}
        </CustomButton>
      </Box>
    </CustomCard>
  );
}

export default SummaryCard;
