export const ReminderTimeOptions = {
  Minutes: {
    id: 1,
    value: "Minutes",
    text: "Minutes",
  },
  Hours: {
    id: 2,
    value: "Hours",
    text: "Hours",
  },
  Days: {
    id: 3,
    value: "Days",
    text: "Days",
  },
};
