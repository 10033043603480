export const PortalsDashboardPermissions = 
{

  PropertyFinderDashboard : 
  {
    permissionsId: "f76c09a9-e76c-4c3b-286c-08dbaf79a1bb",
    permissionsName: "Property Finder Dashboard",
    description: null,
    componentsId: null,
    components: null
 },
   BayutDashboard : {
     permissionsId: "5327a4f9-19f7-4868-286d-08dbaf79a1bb",
     permissionsName: "Bayut Dashboard",
     description: null,
     componentsId: null,
     components: null
  }
  
} ;
