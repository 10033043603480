
import React  from 'react';
import { useTranslation } from 'react-i18next';
import Rating from '@material-ui/lab/Rating';

  export const PropertyViewsContent = ({
    parentTranslationPath ,
    translationPath ,
    state , 
    setState ,
  
   } ) => { 

    const { t } = useTranslation(parentTranslationPath);

    return (
      <div className='property-views-view-section w-75'>
        <div className="fw-bold">
        <span> {t(`${translationPath}property-views`)} </span>
        </div> 
        <div className='w-100 property-views'>
         {    state && state.updatePropertyRatingViewsDtos &&  state.updatePropertyRatingViewsDtos.map((item, index) => 
            (
            <div className="d-flex d-flex-v-center property-views-item mt-2 mb-2 b-bottom" key={`PropertyRatingView${index}`}>
            <div className='property-views-name'>
            <span className='fw-bold'> {item.lookupItemName} </span> 
            </div>
            <div className=''>
            <Rating  
                className=''
                size="large"
                name={`propertyRatingViews${index}`}
                max={10}
                value={item.score}
                precision={1}
                onChange={(event, newValue) => 
                {
                  const updateList = [...state.updatePropertyRatingViewsDtos] ; 
                  updateList[index].score = newValue || 0 ; 
                  setState({id : 'updatePropertyRatingViewsDtos' , value :updateList }) ; 
                }}
                />     
              </div>
           </div>  
          )) } 
        </div>
      </div>
    );
  } ; 