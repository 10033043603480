import React from 'react';
import { PropTypes } from 'prop-types';
import './CellSpinnerBi.scss';

export const CellSpinnerBi = ({
 isActive, isAbsolute, isSmall, isWithoutText, blue ,wi
}) => (
  <>
    {isActive && (

<div  className={wi ? 'loading-wave2':  'loading-wave'  }>
  <div class={blue ? 'loading-barBlue' : 'loading-bar'}></div>
  <div class={blue ? 'loading-barBlue' : 'loading-bar'}></div>
  <div class={blue ? 'loading-barBlue' : 'loading-bar'}></div>
  <div class={blue ? 'loading-barBlue' : 'loading-bar'}></div>
</div>
   )}

      {/* <div
        className={`CellsSpinnerbi-wrapper${isAbsolute ? '  is-absolute' : ''}${
          (isSmall && ' is-small') || ''
        }`}
      >
        <div className='app-spinner'>
          <div className='hex-border'>
            <div className='hexagons'>
              <div className='hexagon' />
              <div className='hexagon' />
              <div className='hexagon' />
              <div className='hexagon' />
              <div className='hexagon' />
              <div className='hexagon' />
              <div className='hexagon' />
            </div>
          </div>
        </div>
      </div>
    )} */}
  </>
);
CellSpinnerBi.propTypes = {
  isActive: PropTypes.bool.isRequired,
  isAbsolute: PropTypes.bool,
  isSmall: PropTypes.bool,
  isWithoutText: PropTypes.bool,
};
CellSpinnerBi.defaultProps = {
  isAbsolute: false,
  isSmall: false,
  isWithoutText: false,
};
