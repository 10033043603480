import React, { useEffect, useState } from "react";
import { CreateReceiptForm } from "./CreateReceiptForm";
import { PaymentDetailsTabsData } from "../PaymentDetailsTabsData";

export const InvoiceReceiptView = ({
  activeInvoiceId,
  activeViewHandler,
  reloadInvoices,
  unitTransactionId,
  parentTranslationPath,
  translationPath,
}) => {
  const [isReceiptSubmitted, setIsReceiptSubmitted] = useState(false);

  useEffect(() => {
    if(isReceiptSubmitted) {
      activeViewHandler(PaymentDetailsTabsData.InvoiceDetails);
    }
  }, [isReceiptSubmitted]);

  return (
    <div>
        <CreateReceiptForm
          invoiceId={activeInvoiceId}
          unitTransactionId={unitTransactionId}
          translationPath={translationPath}
          reloadInvoices={reloadInvoices}
          setIsSubmitted={setIsReceiptSubmitted}
          parentTranslationPath={parentTranslationPath}
        />
    </div>
  );
};
