import React, { useCallback } from 'react';
import {
  Card,
  CardContent,
  CardMedia,
  Chip,
  Grid,
  Typography,
} from '@material-ui/core';
import { Bathtub, DirectionsCar, Fullscreen, Room } from '@material-ui/icons';
import moment from 'moment';
import { getDownloadableLink } from '../../../../../../../Helper';
import { ContactTypeEnum } from '../../../../../../../Enums';

export const UnitDetailsCard = ({
  classes,
  size,
  unitType,
  numberOfBathrooms,
  numberOfBedrooms,
  location,
  completionDate,
  unitCoverImage,
  unitName,
  servicesCharge,
}) => {
  return (
    <Card className={classes.root}>
      <Grid container justifyContent='center' alignItems='stretch'>
        <Grid item xs={3}>
          <CardMedia
            title='Live from space album cover'
            className={classes.cover}
            image={
              unitCoverImage?.fileId
                ? getDownloadableLink(unitCoverImage?.fileId)
                : ContactTypeEnum.corporate.defaultImg
            }
          />
        </Grid>
        <Grid
          item
          xs={9}
          container
          justifyContent='between'
          alignItems='stretch'
        >
          <div className={classes.details}>
            <CardContent className={classes.content}>
              <Typography variant='subtitle1' className='d-flex'>
                {unitName}
              </Typography>
              <Typography
                variant='subtitle2'
                color='textSecondary'
                className='d-flex'
              >
                <Room fontSize='small' />
                {location}
              </Typography>
              <Typography
                variant='subtitle2'
                color='textSecondary'
                className='d-flex'
              >
                {size && (
                  <>
                    <Fullscreen fontSize='small' />
                    {size}
                  </>
                )}
                {numberOfBedrooms && (
                  <>
                    <DirectionsCar fontSize='small' className='mx-2' />
                    {numberOfBedrooms}
                  </>
                )}
                {numberOfBathrooms && (
                  <>
                    <Bathtub fontSize='small' className='mx-2' />
                    {numberOfBathrooms}
                  </>
                )}
              </Typography>
              {completionDate && (
                <Typography
                  variant='subtitle2'
                  color='textSecondary'
                  className='d-flex'
                >
                  Completion Date {moment(completionDate).format('MMM D, YYYY')}
                </Typography>
              )}
            </CardContent>
            <CardContent className={classes.content2}>
              <div className='d-flex '>
                <Typography
                  variant='subtitle2'
                  color='primary'
                  className='mx-2'
                >
                  {unitType}
                </Typography>
                {completionDate && (
                  <Chip color='default' size='small' label='Completed' />
                )}
              </div>
              {servicesCharge && (
                <Typography variant='subtitle2' color='textSecondary'>
                  Service Charge ${servicesCharge}
                </Typography>
              )}
            </CardContent>
          </div>
        </Grid>
      </Grid>
    </Card>
  );
};
