export const AdminExternalLinksPermissions = {
    ViewExternalLinks: {
        permissionsId: 'a2d41942-0b5b-415e-cb69-08db7d42a751',
        permissionsName: 'View External Links',
        description: null,
        componentsId: null,
        components: null
    },
    AddNewExternalLink: {
        permissionsId: '7d576263-528e-4b7c-cb6a-08db7d42a751',
        permissionsName: 'Add New External Link',
        description: null,
        componentsId: null,
        components: null
    },
    EditExternalLink: {
        permissionsId: '7817dc03-073f-4697-cb6b-08db7d42a751',
        permissionsName: 'Edit External Link',
        description: null,
        componentsId: null,
        components: null
    },
    DeleteExternalLink: {
        permissionsId: 'a2610789-5224-4553-cb6c-08db7d42a751',
        permissionsName: 'Delete External Link',
        description: null,
        componentsId: null,
        components: null
    }
};
