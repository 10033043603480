import React from "react";

function UploadIcon({ fill, ...restProps }) {
  return (
    <svg 
      fill={fill ?? ""}
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <path
        d="M9.4.853c-1.963.17-3.549.78-5.067 1.949-.407.313-1.218 1.124-1.531 1.531-.747.97-1.264 1.969-1.583 3.059-.836 2.856-.278 5.835 1.538 8.217.676.886 1.868 1.918 2.7 2.337a.905.905 0 0 0 .685.039.823.823 0 0 0 .48-.988c-.071-.242-.202-.391-.513-.582a7.432 7.432 0 0 1-2.828-3.081c-.83-1.65-1.007-3.61-.491-5.417A7.591 7.591 0 0 1 6.036 3.63a7.508 7.508 0 0 1 7.928 0 7.735 7.735 0 0 1 2.402 2.399c1.46 2.355 1.515 5.355.139 7.704-.493.842-1.181 1.641-1.872 2.173-.602.465-1.488.96-1.845 1.032-.388.078-.837-.061-1.337-.415-.357-.253-.528-.566-.586-1.072-.017-.147-.031-1.729-.031-3.516l-.001-3.248 1.009 1c.633.629 1.058 1.024 1.141 1.064.176.084.522.086.687.005a.993.993 0 0 0 .406-.404c.094-.178.098-.513.009-.702-.086-.185-3.55-3.649-3.735-3.735a.993.993 0 0 0-.7 0c-.185.086-3.649 3.55-3.735 3.735-.083.177-.085.522-.003.688.076.156.261.341.416.417.167.082.513.08.689-.004.083-.04.507-.435 1.139-1.061l1.006-.999.014 3.463c.012 2.968.022 3.5.066 3.721.213 1.055.697 1.71 1.675 2.268.58.332 1.038.457 1.661.456.617-.001 1.023-.126 1.805-.557a8.536 8.536 0 0 0 2.085-1.573 9.096 9.096 0 0 0 2.237-3.602c.409-1.193.554-2.777.375-4.084-.227-1.647-.84-3.097-1.882-4.45-.313-.407-1.124-1.218-1.531-1.531C14.019 1.533 12.222.892 10.2.853a14.22 14.22 0 0 0-.8 0"
        fill-rule="evenodd"
        fill={fill ?? ""}
      />
    </svg>
  );
}

export default UploadIcon;
