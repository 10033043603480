

import { HttpServices } from '../../Helper';
import { config } from '../../config';


export const GetAllPropertyRatingDeveloperScoresAPI = async ({pageIndex ,pageSize ,contactClassificationId  ,search  , isSecondaryDeveloper }) => {
    const queryList = [];
    queryList.push(`contactClassificationId=${contactClassificationId}`);
    queryList.push(`search=${search}`);
    queryList.push(`isSecondaryDeveloper=${isSecondaryDeveloper}`);

    const result = await HttpServices.get(
        `${config.server_address}/CrmDfm/PropertyRating/GetAllPropertyRatingDeveloperScores/${pageIndex}/${pageSize}?${queryList.join('&')}`)
        .then((data) => data)
        .catch((error) => error.response);
    return result;
  };
  
  export const UpdateDeveloperScoreAPI = async (body) => {
    const result = await HttpServices.put(
        `${config.server_address}/CrmDfm/PropertyRating/UpdateDeveloperScore`, body
    )
        .then((data) => data)
        .catch((error) => error.response.data);
    return result;
};


export const GetRatingDeveloperScoreAPI = async (contactId) => {

    const result = await HttpServices.get(
        `${config.server_address}/CrmDfm/PropertyRating/GetRatingDeveloperScore?contactId=${contactId}`)
        .then((data) => data)
        .catch((error) => error.response);
    return result;
  };


