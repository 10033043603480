
import React, { useState, useEffect  , useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import {  Tables  , TableColumnsFilterComponent  } from '../../../../../Components';
import { GetAllFormFieldsByFormId  } from '../../../../../Services'   ; 
import {  FormsIdsEnum  , ClientsSegmentationFilterFields  , CustomFiledsClientsSegmentation  } from '../../../../../Enums' ; 

 
export const SearchClientsSegmentationTable = ({
   parentTranslationPath ,
   translationPath , 
   tableColumns ,
   setTableColumns ,
   setSortBy , 
   tableFilterData , 
   filter , 
   onPageIndexChanged , 
   onPageSizeChanged , 
   searchResponse  , 
   
  }) => {



  const { t } = useTranslation([parentTranslationPath, 'Shared']);
  const [allFormFields , setAllFormFields ] = useState([]);
  const [isLoading , setIsLoading ] = useState(false);
  const [selectedTableFilterColumns, setSelectedTableFilterColumns] = useState([1,2,3,4,5]);
  const [allFieldSelected , setAllFieldSelected] = useState([]) ; 
  
const getAllFormFieldsByFormId = useCallback(async () => {
    setIsLoading(true);
    Promise.all([
      await GetAllFormFieldsByFormId(FormsIdsEnum.contactsIndividual.id),
      await GetAllFormFieldsByFormId(FormsIdsEnum.contactsCorporate.id),
      // await GetAllFormFieldsByFormId(FormsIdsEnum.leadsOwner.id),
      // await GetAllFormFieldsByFormId(FormsIdsEnum.leadsSeeker.id),
    ])
      .then((result) => {
        if (Array.isArray(result[0]) && Array.isArray(result[1]) 
        // && Array.isArray(result[2]) && Array.isArray(result[3])
        ) {
          const customFileds =  CustomFiledsClientsSegmentation  ; 
          const contactFields =  customFileds
           .concat(result[0])
            .concat(result[1])
            .concat(ClientsSegmentationFilterFields.Contact)
            .filter(
              (field, index, array) =>
                array.findIndex((element) => element.formFieldKey === field.formFieldKey) === index  && field.uiWidgetType !=='UploadFiles' &&   field.uiWidgetType !==  'MapField'   &&  field.formFieldKey  !==  'mobile' 
            );

            // const leadFields = result[2]
            // .concat(result[3])
            // .concat(ClientsSegmentationFilterFields.Lead)
            // .filter(
            //   (field, index, array) =>
            //     array.findIndex((element) => element.formFieldKey === field.formFieldKey) === index  && field.uiWidgetType !=='UploadFiles' &&   field.uiWidgetType !==  'MapField' 
            // );

          setAllFormFields(contactFields);
        } else setAllFormFields([]);
        setIsLoading(false);
      })
      .catch(() => {
        setAllFormFields([]);
        setIsLoading(false);
      });
  }, []);


  useEffect(() => {
    getAllFormFieldsByFormId();
  }, [getAllFormFieldsByFormId]);
  
  useEffect(() => {
    setTableColumns([
      ...allFormFields
        .filter(
          (item) =>
            selectedTableFilterColumns.findIndex((element) => element === item.formFieldId) !== -1
        )
        .map((field) => ({
          id: field.formFieldId,
          category: ((field.formsId === 6 || field.formsId === 7 ) ? 'Leads'  : (( field.formsId === 1 || field.formsId === 2 )? 'Contacts' : 'Activites' )), 
          key: field.formFieldKey,
          displayPath : field.displayPath ,  
          isDate: field.uiWidgetType === 'alt-date',
          label: (field.formFieldTitle && field.formFieldTitle.replace('*', '')),
          input :  (field.formFieldTitle && field.formFieldTitle.replace('*', '')),
          // label: (field.formFieldTitle && field.formFieldTitle.replace('*', '') +  ' ' + ((field.formsId === 6 || field.formsId === 7 ) ? '(Lead)'  : (( field.formsId === 1 || field.formsId === 2 )? '(Contact)' : '(Activity)' ))),
          // input:  field.displayPath.includes(".") ? field.formFieldKey.replace("-", "_") : field.displayPath.replace("-", "_") ,
          // input:field.formFieldKey ,
          // input :  (field.formFieldTitle && field.formFieldTitle.replace('*', '') +  ' ' + ((field.formsId === 6 || field.formsId === 7 ) ? '(Lead)'  : (( field.formsId === 1 || field.formsId === 2 )? '(Contact)' : '(Activity)' ))), 
          searchableKey: field.searchableKey|| undefined,
          isSortable: true,
          categoryType : field.formsId , 
          isNumber:
            field.propertyJson && JSON.parse(field.propertyJson).schema &&
            field.propertyJson && JSON.parse(field.propertyJson).schema.specialKey === 'currency' ||
            field.propertyJson && JSON.parse(field.propertyJson).schema &&
            field.propertyJson && JSON.parse(field.propertyJson).schema.specialKey === 'decimal'
        })),
      
    ]);

    
  }, [allFormFields, selectedTableFilterColumns]);

  


  useEffect(() => {
    let filtetColumns =  [] ; 
    allFormFields.map((item) => {
      filtetColumns.push
      ({
        key: item.formFieldId || item.id,
      //   value: ((item.formFieldTitle && item.formFieldTitle.replace('*', '') + 
      //   `${(item.formsId === 6 || item.formsId === 7 )?' (Lead)' :  (item.formsId === 1 || item.formsId === 2 ) ? ' (Contact)'  : ' (Activites)'  }`)
      // || item.label),
      value: ((item.formFieldTitle && item.formFieldTitle.replace('*', ''))|| item.label),
      
      })
    });
    setAllFieldSelected(filtetColumns) ; 
  }, [allFormFields]);


  return (
    <div>
     <TableColumnsFilterComponent
           columns={allFieldSelected}
           selectedColumns={selectedTableFilterColumns}
           onSelectedColumnsChanged={(newValue) => {
             setSelectedTableFilterColumns(newValue);
           }}
            parentTranslationPath={parentTranslationPath}
            translationPath={translationPath}
            isLoading={isLoading}
            withoutSelectAll
      /> 
    </div>
  );

}

SearchClientsSegmentationTable.propTypes = {
  parentTranslationPath: PropTypes.string.isRequired,
  translationPath: PropTypes.string.isRequired,
  onPageIndexChanged :  PropTypes.func.isRequired, 
  onPageSizeChanged : PropTypes.func.isRequired,
  filter : PropTypes.instanceOf(Object).isRequired,  
  searchResponse : PropTypes.instanceOf(Object).isRequired, 
  tableColumns: PropTypes.arrayOf(PropTypes.instanceOf(Object)).isRequired,
  setSortBy : PropTypes.func.isRequired, 
  setTableColumns : PropTypes.func.isRequired, 
};



