import React, { useEffect, useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import Rating from "@material-ui/lab/Rating";
import { AutocompleteComponent, Inputs } from "../../../../../Components";
import { showError } from "../../../../../Helper";
import { ReactComponent as ArrowNarrowRightIcon } from "../../../../../assets/images/defaults/arrow-narrow-right.svg";
import PlusIcon from "../../../../../assets/images/defaults/plus.svg";

export const KeyNearbyNumber = ({
  parentTranslationPath,
  translationPath,
  state,
  setState,
}) => {
  const { t } = useTranslation(parentTranslationPath);
  
  const onChangeValues = (key, value, index, rangeIndex, item) => {
    let updateRangelist =
      (index !== undefined &&
        state.listOfPropertyRatingKeysNearbiesDto &&
        state.listOfPropertyRatingKeysNearbiesDto[index]
          ?.propertyRatingKeyNearbyRangesDto && [
          ...state.listOfPropertyRatingKeysNearbiesDto[index]
            .propertyRatingKeyNearbyRangesDto,
        ]) ||
      [];

    if (key === "form" && rangeIndex !== undefined)
      updateRangelist[rangeIndex] = {
        ...updateRangelist[rangeIndex],
        from: value === "" ? 0 : value,
      };
    else if (key === "to" && rangeIndex !== undefined)
      updateRangelist[rangeIndex] = {
        ...updateRangelist[rangeIndex],
        to: value === "" ? 0 : value,
      };
    else if (key === "score" && rangeIndex !== undefined)
      updateRangelist[rangeIndex] = {
        ...updateRangelist[rangeIndex],
        score: value === null ? 0 : value,
      };
    else if (key === "delete" && rangeIndex !== undefined)
      updateRangelist.splice(rangeIndex, 1);
    else if (key === "add" && item)
      updateRangelist.push({
        propertyRatingKeyNearbyRangeId: null,
        propertyRatingKeysNearbyId: item?.propertyRatingKeysNearbyId || null,
        landmarkCategoryId: item?.lookupsId,
        lookupItemName: item?.lookupItemName,
        score: 0,
        from: 0,
        to: 0,
      });

    let updateKeysNearbieslist = [...state.listOfPropertyRatingKeysNearbiesDto];
    updateKeysNearbieslist[index] = {
      ...updateKeysNearbieslist[index],
      propertyRatingKeyNearbyRangesDto: [...updateRangelist],
    };
    setState({
      id: "listOfPropertyRatingKeysNearbiesDto",
      value: [...updateKeysNearbieslist],
    });
  };

  return (
    <div className="px-4 mt-2 w-75">
      <div className="key-nearby-section">
        <div>
          <span className="fw-bold">
            {t(`${translationPath}key-nearby-number`)}
          </span>
        </div>
        <div className="key-nearby-landmarks">
          {state.listOfPropertyRatingKeysNearbiesDto &&
            state.listOfPropertyRatingKeysNearbiesDto.map((item, index) => (
              <div
                className="b-bottom  mt-4 mb-4"
                key={`propertyRatingKeysNearbies${index + 1 + item.lookupsId}`}
              >
                <div
                  className="fw-bold"
                  key={`propertyRatingKeysNearbiesDtoRef${index}`}
                >
                  <div className="d-flex d-inline mt-2 mb-4 w-100 first-section  fw-bold">
                    <AutocompleteComponent
                      idRef={`VicinityCategory-${index}`}
                      labelValue={"landmark"}
                      multiple={false}
                      selectedValues={item}
                      value={item}
                      isDisabled={true}
                      data={[]}
                      displayLabel={(option) => option.lookupItemName || ""}
                      renderOption={(option) => option.lookupItemName || ""}
                      withoutSearchButton
                      getOptionSelected={(option) =>
                        (option && option.lookupsId) === item?.lookupsId
                      }
                      parentTranslationPath={parentTranslationPath}
                      translationPath={translationPath}
                      onChange={(event, newValue) => {
                        let updatelist = [
                          ...state.listOfPropertyRatingKeysNearbiesDto,
                        ];
                        updatelist[index] = newValue
                          ? {
                              ...updatelist[index],
                              newValue,
                            }
                          : {
                              ...updatelist[index],
                              lookupsId: null,
                              lookupItemName: null,
                            };
                        setState({
                          id: "listOfPropertyRatingKeysNearbiesDto",
                          value: [...updatelist],
                        });
                      }}
                    />
                  </div>
                  {state &&
                    state.listOfPropertyRatingKeysNearbiesDto[index] &&
                    state.listOfPropertyRatingKeysNearbiesDto[index]
                      .propertyRatingKeyNearbyRangesDto &&
                    state.listOfPropertyRatingKeysNearbiesDto[
                      index
                    ].propertyRatingKeyNearbyRangesDto.map(
                      (range, rangeIndex) => (
                        <div
                          className="d-flex-column w-100 mb-2"
                          key={`propertyRatingKeyNearbyRanges${
                            rangeIndex + "1993" + index
                          }`}
                        >
                          <div className="key-nearby-number w-100 ">
                            <div className="mb-2">
                              <Inputs
                                idRef={`From-${index}-${rangeIndex}`}
                                labelValue={t("From")}
                                value={range && range.from}
                                type={"number"}
                                min={1}
                                inputPlaceholde={t("Shared:From")}
                                parentTranslationPath={parentTranslationPath}
                                translationPath={translationPath}
                                onInputChanged={(event) => {
                                  const value = event.target.value;
                                  if (value <= 0) {
                                    showError(
                                      t(
                                        `${translationPath}please-enter-number-greater-than-zero`
                                      )
                                    );
                                    return;
                                  }
                                  onChangeValues(
                                    "form",
                                    value,
                                    index,
                                    rangeIndex,
                                    item
                                  );
                                }}
                              />
                            </div>
                            <div className="arrow-part">
                              <ArrowNarrowRightIcon />
                            </div>
                            <div className="">
                              <Inputs
                                idRef={`To-${index}-${rangeIndex}`}
                                labelValue={t("To")}
                                inputPlaceholde={t("To")}
                                type={"number"}
                                min={1}
                                value={range && range.to}
                                parentTranslationPath={parentTranslationPath}
                                translationPath={translationPath}
                                onInputChanged={(event) => {
                                  const value = event.target.value;
                                  if (value <= 0) {
                                    showError(
                                      t(
                                        `${translationPath}please-enter-number-greater-than-zero`
                                      )
                                    );
                                    return;
                                  }
                                  onChangeValues(
                                    "to",
                                    value,
                                    index,
                                    rangeIndex,
                                    item
                                  );
                                }}
                              />
                            </div>
                          </div>
                          <div className="d-flex d-flex-v-center b-bottom">
                            <div className="px-2">
                              {" "}
                              <span className="">
                                {" "}
                                {t(`${translationPath}rating`)}{" "}
                              </span>{" "}
                            </div>
                            <div className="d-flex d-flex-v-center d-flex-h-between mb-2">
                              <Rating
                                size="large"
                                name={`Rating-${index}-${rangeIndex}`}
                                max={10}
                                value={range && range.score}
                                precision={1}
                                onChange={(event, newValue) => {
                                  onChangeValues(
                                    "score",
                                    newValue,
                                    index,
                                    rangeIndex,
                                    item
                                  );
                                }}
                              />
                              <span
                                className="mdi mdi-delete-outline c-warning delete-button"
                                onClick={() => {
                                  onChangeValues(
                                    "delete",
                                    undefined,
                                    index,
                                    rangeIndex,
                                    item
                                  );
                                }}
                              ></span>
                            </div>
                          </div>
                        </div>
                      )
                    )}
                  <div
                    className="d-flex d-flex-v-center  mt-2 mb-2"
                    onClick={() => {
                      onChangeValues("add", undefined, index, undefined, item);
                    }}
                  >
                    <img
                      className="mr-2 ml-2"
                      src={PlusIcon}
                      alt={t(`${translationPath}permission-denied`)}
                    />
                    <span>{t(`${translationPath}add-another`)}</span>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};
