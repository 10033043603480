export const LeaseTeamLeadDashboardPermissions = {
  LeaseTeamLeadDashboard: 
    {
      permissionsId:'929398bd-e64d-43f5-6830-08da421d6e99',
      permissionsName :'Lease Team Lead Dashboard',
      componentsId:null,
      components:null , 
    } , 
    LeaseTeamLeadPlusDashboard : 
     {
      permissionsId: "c0f557dd-ffbc-43f8-7f31-08dbbb5bf6df",
      permissionsName: "Lease Team Lead + Dashboard",
      description: null,
      componentsId: null,
      components: null
    }
} ;
