export const UserAMLViewPermissions = {
  ViewAMLCheckerModule: {
    permissionsId: "e630a178-53c2-4efa-44f6-08db5f99228b",
    permissionsName: "View AML Checker Module",
    description: null,
    componentsId: null,
    components: null,
  },

  CancelACheckRequest: {
    permissionsId: "2a986dc9-3035-4451-287a-08dbaf79a1bb",
    permissionsName: "Cancel A Check Request",
    description: null,
    componentsId: null,
    components: null,
  },

  WithdrawTransaction: {
    permissionsId: "2a986dc9-3035-4451-287a-08dbaf79a1bb",
    permissionsName: "Withdraw Transaction",
    description: null,
    componentsId: null,
    components: null,
  },

  ResubmitTransaction: {
    permissionsId: "7b0ec5b4-95e9-4352-5ddf-08dc7403ebc2",
    permissionsName: "Resubmit Transaction",
    description: null,
    componentsId: null,
    components: null,
  },

  ViewHistory: {
    permissionsId: "16495b27-db9a-45e2-7945-08dcaf947ea8",
    permissionsName: "View History",
    description: null,
    componentsId: null,
    components: null,
  },
  ExportAML : {
     permissionsId: "d0a68db7-ff12-40dc-5173-08dce7a01c4d",
     permissionsName: "Export AML",
     description: null,
     componentsId: null,
     components: null
}
};
