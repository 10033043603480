import { Fab } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Spinner } from '../../../Components';
import { config } from '../../../config';
import {
  GetParams, GlobalHistory, HttpServices, showError
} from '../../../Helper';

export const ReportPdfView = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [reportDto, setReportDto] = useState({});
  const [filter, setFilter] = useState({ pageIndex: 0, pageSize: 25 });
  const [reportSchema, setReportSchema] = useState([]);
  const [activeReport, setactiveReport] = useState([]);
  const [exportData, setexportData] = useState([]);
  const convertFormDtoToDto = (obj) => {
    const dto = {};
    // eslint-disable-next-line no-restricted-syntax
    for (const key in obj) {
      if (typeof (obj[key]) === 'object') {
        if (obj[key] && obj[key].id) dto[key] = obj[key].id;
        else if (obj[key] && obj[key].lookupItemId) dto[key] = obj[key].lookupItemId;
        else dto[key] = obj[key];
      } else
        dto[key] = obj[key];
    }
    return dto;
  };

  const onExportClickedPDF = async () => {
    if (activeReport === null) return;
    setIsLoading(true);
    reportDto.pageSize = 3000;
    reportDto.pageIndex = filter.pageIndex + 1;
    let Dto = '';
    if (GetParams('ReportType') === 'alldata')
      Dto = convertFormDtoToDto(reportDto);
    else {
      const filterStatus = localStorage.getItem('PrintRepertLoaclFilter');
      if (filterStatus) {
        const filterStatusJson = JSON.parse(filterStatus);

        if (filterStatusJson && filterStatusJson.length === 0)
          Dto = convertFormDtoToDto(reportDto);

        else Dto = filterStatusJson;
      }
    }
    const res = await HttpServices.post(`${config.server_address}${GetParams('activeReport')}`, Dto)
      .then((data) => data)
      .catch(() => undefined);
    if (!res) { showError('server Error'); setIsLoading(false); return; }
    if (res.result && res.result.length === 0) { setReportSchema([]); setIsLoading(false); return; }
    const reData = (res.result);
    setIsLoading(false);
    setexportData(reData);
  };
  const printPartOfPage = async () => {
    window.print();
  };
  useEffect(() => {
    onExportClickedPDF();
  }, []);

  return (
    <div className='ReportPdfView-PAGE'>
      <div className='Report-Fab'>
        <Fab
          onClick={() => {
                printPartOfPage();
              }}
          color='primary'
          aria-label='edit'
        >
          <span className='mdi mdi-file-pdf-box' />
          <span className='mdi mdi-printer-settings' />
        </Fab>
        <div className='mt-2'>
          {' '}
          <Fab color='inherit' aria-label='edit' className='mt-1' onClick={() => GlobalHistory.push('/home/Reports-CRM/view')}>
            <span className='mdi mdi-arrow-left' />
          </Fab>
        </div>
        <div className='mt-2'>
          {' '}
          <Fab color='secondary' aria-label='edit' className='mt-1' onClick={() => window.close()}>
            <span className='mdi mdi-close' />
          </Fab>
        </div>
      </div>
      <div className='unit-card-view'>
        <Spinner isActive={isLoading} isAbsolute />
        <div>
          <div className='users-card-component-wrapper' id='divcontents'>
            {exportData && exportData.map((item, key) => (
              <div index={key} className='users-card-wrapper'>
                <div className='cards-wrapper'>
                  <div>
                    {Object.keys(item).map((k,) => (
                      <div>
                        <span>{k}</span>
                        {' '}
                        :
                        {' '}
                        <span>
                          {' '}
                          {((item[k] === null || item[k] === '') ? 'N/A' : item[k])}
                          {' '}
                        </span>
                        {' '}
                        {' '}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
