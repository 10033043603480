import React, { useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Inputs, RadiosGroupComponent, TabsComponent } from '../../../../../Components';
import { getErrorByName } from '../../../../../Helper';
// import { UnitProfileSaleDetailsComponentTabsData } from '../../../../UnitsSalesProfileManagement/Sections/UnitProfileSaleDetailsComponent/PropertiesProfileSpecificationTabsData';
export const UnitProfileSaleDetailsComponentTabsData = [
  { tab: 'sales-details' },
  { tab: 'for-financial-team-use-only' },
  { tab: 'payment-plan-1' },
  { tab: 'payment-plan-2' },
];
export const DetailsSaleRelatedComponent = ({
  state,
  selected,
  unitSaleDetails,
  schema,
  isSubmitted,
  onStateChanged,
  parentTranslationPath,
  translationPath,
  cantEditReservedSaleTransaction
}) => {
  useEffect(() => {
    if (state.basePrice === 0) {
      const getSaleTransactionDetails = localStorage.getItem('saleTransactionDetails');

      if (getSaleTransactionDetails) {
        const saleTransactionDetails = JSON.parse(getSaleTransactionDetails);
        onStateChanged({
          id: 'edit',
          value: {
            ...state,
            basePrice: (saleTransactionDetails && saleTransactionDetails.basePrice ? saleTransactionDetails.basePrice : 0),
            premium: ((saleTransactionDetails && saleTransactionDetails.premium) || 0),
            sellingPrice: ((saleTransactionDetails && saleTransactionDetails.sellingPrice) || 0),
            premiumPercentageOfBasePrice: ((saleTransactionDetails && saleTransactionDetails.premiumPercentageOfBasePrice) || 0),
            agencyFeeSeller: ((saleTransactionDetails && saleTransactionDetails.premiumPercentageOfBasePrice) || 0),
            transferFeeBuyer: ((saleTransactionDetails && saleTransactionDetails.transferFeeBuyer) || 0),
            transferFeeBuyerPercentageOfBasePrice: ((saleTransactionDetails && saleTransactionDetails.transferFeeBuyerPercentageOfBasePrice) || 0),
            sellerHasPaidPercentageOfBasePrice: ((saleTransactionDetails && saleTransactionDetails.sellerHasPaidPercentageOfBasePrice) || 0),
            sellerHasPaid: ((saleTransactionDetails && saleTransactionDetails.sellerHasPaid) || 0),
            admRegistrationFees: ((saleTransactionDetails && saleTransactionDetails.admRegistrationFees) || 0),
            admRegistrationFeesPercentageOfSellingPrice: ((saleTransactionDetails && saleTransactionDetails.admRegistrationFeesPercentageOfSellingPrice) || 0),
            transferFeeSeller: ((saleTransactionDetails && saleTransactionDetails.transferFeeSeller) || 0),
            transferFeeSellerPercentageOfSellingPrice: ((saleTransactionDetails && saleTransactionDetails.transferFeeSellerPercentageOfSellingPrice) || 0),
            amountDueToDeveloper: ((saleTransactionDetails && saleTransactionDetails.amountDueToDeveloper) || 0),
            agencyFeeBuyer: ((saleTransactionDetails && saleTransactionDetails.agencyFeeBuyer) || 0),
            agencyFeeBuyerPercentageOfSellingPrice: ((saleTransactionDetails && saleTransactionDetails.agencyFeeBuyerPercentageOfSellingPrice) || 0),
            discount: ((saleTransactionDetails && saleTransactionDetails.discount) || 0),
            transferFeeCompletedAndPaidProp: ((saleTransactionDetails && saleTransactionDetails.transferFeeCompletedAndPaidProp) || 0),
            transferFeeCompletedAndPaidPropOfSellingPrice: ((saleTransactionDetails && saleTransactionDetails.transferFeeCompletedAndPaidPropOfSellingPrice) || 0),
            agencyFeeSellerPercentageOfBasePriceAndPremium: ((saleTransactionDetails && saleTransactionDetails.agencyFeeSellerPercentageOfBasePriceAndPremium) || 0),
            discountPercentageOfBasePrice: ((saleTransactionDetails && saleTransactionDetails.discountPercentageOfBasePrice) || 0),
            //
            externalAgencyFeeSeller:((saleTransactionDetails && saleTransactionDetails.externalAgencyFeeSeller) || 0),
            externalAgencyFeeSellerPercentage:((saleTransactionDetails && saleTransactionDetails.externalAgencyFeeSellerPercentage) || 0),
            agencyFeeSeller :((saleTransactionDetails && saleTransactionDetails.agencyFeeSeller) || 0),
            agencyFeeSellerPercentageOfBasePriceAndPremium:((saleTransactionDetails && saleTransactionDetails.agencyFeeSellerPercentageOfBasePriceAndPremium) || 0),
            netAmountSeller: ((saleTransactionDetails && saleTransactionDetails.netAmountSeller) || 0),
            agencyFeeBuyer : ((saleTransactionDetails && saleTransactionDetails.AgencyFeeBuyer) || 0),
            agencyFeeBuyerPercentageOfSellingPrice :  ((saleTransactionDetails && saleTransactionDetails.AgencyFeeBuyerPercentageOfSellingPrice) || 0),
            externalAgencyFeeBuyer:((saleTransactionDetails && saleTransactionDetails.ExternalAgencyFeeBuyer) || 0),
            externalAgencyFeeBuyerPercentage :((saleTransactionDetails && saleTransactionDetails.externalAgencyFeeBuyerPercentage) || 0),
            netAmountBuyer:((saleTransactionDetails && saleTransactionDetails.netAmountBuyer) || 0),
          }
        });
      }
    }
  }, []);

  const [activeTab, setActiveTab] = useState(0);
  //SellingPriceRe
  const effectedFieldsBySellingPriceRecalculate = useCallback(
    (effectorNewValue = 0) => {
      const admRegistrationFees =
        (+effectorNewValue / 100) * state.admRegistrationFeesPercentageOfSellingPrice;

      const agencyFeeBuyer =
        (+effectorNewValue / 100) * state.agencyFeeBuyerPercentageOfSellingPrice;

      const transferFeeCompletedAndPaidProp =
        (+effectorNewValue / 100) * state.transferFeeCompletedAndPaidPropOfSellingPrice;

      const downPayment =
        (+effectorNewValue / 100) * state.downPaymentPercentageOfSellingPrice;

      const downPaymentForPlanOne =
        (+effectorNewValue / 100) * state.downPaymentForPlanOnePercentageOfSellingPrice;

      const monthlyInstallment =
        (+effectorNewValue / 100) * state.monthlyInstallmentPercentageOfSellingPrice;

      const handoverPaymentForPlanOne =
        (+effectorNewValue / 100) * state.handoverPaymentForPlanOnePercentageOfSellingPrice;

      const downPaymentForPlanTwo =
        (+effectorNewValue / 100) * state.downPaymentForPlanTwoPercentageOfSellingPrice;

      const handoverPaymentForPlanTwo =
        (+effectorNewValue / 100) * state.handoverPaymentForPlanTwoPercentageOfSellingPrice;

      const transferFeeSeller =
        (+effectorNewValue / 100) * state.transferFeeSellerPercentageOfSellingPrice;

      const sellerHasPaid =
        (+effectorNewValue / 100) * state.sellerHasPaidPercentageOfBasePrice;

      const amountDueToDeveloper = effectorNewValue - sellerHasPaid;
      

      return {
        admRegistrationFees,
        agencyFeeBuyer,
        transferFeeCompletedAndPaidProp,
        downPayment,
        downPaymentForPlanOne,
        monthlyInstallment,
        handoverPaymentForPlanOne,
        downPaymentForPlanTwo,
        handoverPaymentForPlanTwo,
        transferFeeSeller,
        sellerHasPaid,
        amountDueToDeveloper,

      };
    },
    [state]
  );

  // ByBasePrice
  const effectedFieldsByBasePriceRecalculate = (effectorNewValue = 0) => {
    // if (effectorName === 'basePrice') {
    const premium = (effectorNewValue / 100) * state.premiumPercentageOfBasePrice;
    const agencyFees =
      ((+premium + +effectorNewValue) / 100) * state.agencyFeeSellerPercentageOfBasePriceAndPremium;

    const discount =
      (effectorNewValue / 100) * state.discountPercentageOfBasePrice;

    const sellingPrice = +effectorNewValue + +premium - +discount;
    const transferFeeBuyer =
      (effectorNewValue / 100) * state.transferFeeBuyerPercentageOfBasePrice;
  
    onStateChanged({
      id: 'edit',
      value: {
        ...state,
        basePrice: effectorNewValue,
        premium,
        agencyFees,
        discount,
        sellingPrice,
        ...effectedFieldsBySellingPriceRecalculate(sellingPrice),
        transferFeeBuyer,
      },
    });
  };
  const onTabChanged = (e, newTap) => {
    setActiveTab(newTap);
  };

  // ByPremiumOrPer
  const effectedFieldsByPremiumOrPerRecalculate = (effectorName, effectorNewValue = 0) => {
    let { premium } = state;
    let { premiumPercentageOfBasePrice } = state;
    if (effectorName === 'premiumPercentageOfBasePrice') {
      premiumPercentageOfBasePrice = +effectorNewValue;
      premium = (+(state.basePrice || 0) / 100) * +premiumPercentageOfBasePrice;
    } else {
      premium = effectorNewValue;
      premiumPercentageOfBasePrice = (+premium / +(state.basePrice || 0)) * 100;
    }

    const agencyFees =
      ((+premium + +state.basePrice) / 100) * state.agencyFeeSellerPercentageOfBasePriceAndPremium;

    const sellingPrice = +state.basePrice + +premium - +state.discount;
    onStateChanged({
      id: 'edit',
      value: {
        ...state,
        premium,
        premiumPercentageOfBasePrice,
        agencyFees,
        sellingPrice,
        ...effectedFieldsBySellingPriceRecalculate(sellingPrice),
        
      },
    });
  };

  // DiscountOrPer
  const effectedFieldsByDiscountOrPerRecalculate = (effectorName, effectorNewValue = 0) => {
    let { discount } = state;
    let { discountPercentageOfBasePrice } = state;
    if (effectorName === 'discountPercentageOfBasePrice') {
      discountPercentageOfBasePrice = effectorNewValue;
      discount = (+state.basePrice / 100) * discountPercentageOfBasePrice;
    } else {
      discount = effectorNewValue;
      discountPercentageOfBasePrice = (discount / +state.basePrice) * 100;
    }
    const sellingPrice = +state.basePrice + +state.premium - +discount;
    onStateChanged({
      id: 'edit',
      value: {
        ...state,
        discount,
        discountPercentageOfBasePrice,
        sellingPrice,
        ...effectedFieldsBySellingPriceRecalculate(sellingPrice),
      },
    });
  };

  
  useEffect(()=>{
    onStateChanged({id: 'edit', value: {
      ...state,
      ...(unitSaleDetails || {}),
    }})
  }, [unitSaleDetails])

  return (
    <div className='details-sale-related-wrapper childs-wrapper'>
      <TabsComponent
        data={UnitProfileSaleDetailsComponentTabsData}
        labelInput='tab'
        parentTranslationPath={parentTranslationPath}
        translationPath={translationPath}
        themeClasses='theme-curved'
        currentTab={activeTab}
        onTabChanged={onTabChanged}
      />
      <div className='tabs-content-wrapper w-100 px-2 pt-3'>
        {activeTab === 0 && (
          <>
            <div className='form-item'>
              <Inputs
                withNumberFormat
                idRef='basePriceRef'
                labelValue='base-price'
                labelClasses='Requierd-Color'
                value={state.basePrice}
                helperText={getErrorByName(schema, 'basePrice').message}
                error={getErrorByName(schema, 'basePrice').error}
                endAdornment={<span className='px-2'>AED</span>}
                type='number'
                min={0}
                isWithError
                isSubmitted={isSubmitted}
                parentTranslationPath={parentTranslationPath}
                translationPath={translationPath}
                // onInputChanged={(event) => {
                //   const { value } = event.target;
                //   effectedFieldsByBasePriceRecalculate(value);
                // }}
                onKeyUp={(e) => {
                  const value = e && e.target && e.target.value ? (e.target.value) : 0;
                  const fixed = (value && value.replace(/,/g, ''));
                  const editValue = fixed ? parseFloat(fixed) : 0;
                  effectedFieldsByBasePriceRecalculate(editValue);

                }
                }
                isDisabled={cantEditReservedSaleTransaction}
              />
            </div>
            <div className='form-item form-item-wrapper'>
              <Inputs
                withNumberFormat
                isAttachedInput
                idRef='premiumRef'
                labelValue='premium'
                labelClasses='has-inside-label'
                value={state.premium || 0}
                helperText={getErrorByName(schema, 'premium').message}
                error={getErrorByName(schema, 'premium').error}
                endAdornment={(
                  <span className='px-2 inner-span'>AED</span>
                )}
                type='number'
                min={0}
                max={+(state.basePrice || 0)}
                isWithError
                isSubmitted={isSubmitted}
                parentTranslationPath={parentTranslationPath}
                translationPath={translationPath}
                onKeyUp={(e) => {
                  const value = e && e.target && e.target.value ? (e.target.value) : 0;
                  if (Number.isNaN(value))
                    value = 0;
                  const fixed = (value && value.replace(/,/g, ''));
                  let parsedValue = fixed ? parseFloat(fixed) : 0;

                  if (parsedValue > +(state.basePrice || 0)) parsedValue = +(state.basePrice || 0);
                  effectedFieldsByPremiumOrPerRecalculate('premium', parsedValue);
                }}
                isDisabled={cantEditReservedSaleTransaction}
              />

              <div className='input-container'>
                <Inputs
                  withNumberFormat
                  isAttachedInput
                  idRef='premiumPercentageOfBasePriceRef'
                  labelValue='of-base-price'
                  value={state.premiumPercentageOfBasePrice || 0}
                  helperText={getErrorByName(schema, 'premiumPercentageOfBasePrice').message}
                  error={getErrorByName(schema, 'premiumPercentageOfBasePrice').error}
                  endAdornment={<span className='px-2'>%</span>}
                  type='number'
                  labelClasses='inside-input-label'
                  wrapperClasses='mb-0'
                  min={0}
                  max={100}
                  isWithError
                  isSubmitted={isSubmitted}
                  parentTranslationPath={parentTranslationPath}
                  translationPath={translationPath}
                  onKeyUp={(e) => {
                    const value = e && e.target && e.target.value ? (e.target.value) : 0;
                    if (Number.isNaN(value))
                      value = 0;
                    const fixed = (value && value.replace(/,/g, ''));
                    let parsedValue = fixed ? parseFloat(fixed) : 0;
                    if (parsedValue > 100) parsedValue = 100;
                    effectedFieldsByPremiumOrPerRecalculate(
                      'premiumPercentageOfBasePrice',
                      parsedValue
                    );
                  }}
                  isDisabled={cantEditReservedSaleTransaction}
                />
              </div>
            </div>
            <div className='form-item form-item-wrapper'>
              <Inputs
                withNumberFormat
                isAttachedInput
                idRef='discountRef'
                labelValue='discount'
                labelClasses='has-inside-label'
                value={state.discount || 0}
                helperText={getErrorByName(schema, 'discount').message}
                error={getErrorByName(schema, 'discount').error}
                endAdornment={(
                  <span className='px-2 inner-span'>AED</span>
                )}
                type='number'
                min={0}
                max={+(state.basePrice || 0)}
                isWithError
                isSubmitted={isSubmitted}
                parentTranslationPath={parentTranslationPath}
                translationPath={translationPath}
                onKeyUp={(e) => {
                  const value = e && e.target && e.target.value ? (e.target.value) : 0;
                  if (Number.isNaN(value))
                    value = 0;
                  const fixed = (value && value.replace(/,/g, ''));
                  let parsedValue = fixed ? parseFloat(fixed) : 0;
                  const total = +(state.basePrice || 0);
                  if (parsedValue > total) parsedValue = total;
                  effectedFieldsByDiscountOrPerRecalculate('discount', parsedValue);
                }}
                isDisabled={cantEditReservedSaleTransaction}
              />
              <div className='input-container'>
                <Inputs
                  withNumberFormat
                  isAttachedInput
                  idRef='discountPercentageOfBasePriceRef'
                  labelValue='of-base-price'
                  value={state.discountPercentageOfBasePrice || 0}
                  helperText={getErrorByName(schema, 'discountPercentageOfBasePrice').message}
                  error={getErrorByName(schema, 'discountPercentageOfBasePrice').error}
                  endAdornment={<span className='px-2'>%</span>}
                  type='number'
                  labelClasses='inside-input-label'
                  wrapperClasses='mb-0'
                  min={0}
                  max={100}
                  isWithError
                  isSubmitted={isSubmitted}
                  parentTranslationPath={parentTranslationPath}
                  translationPath={translationPath}
                  onKeyUp={(e) => {
                    const value = e && e.target && e.target.value ? (e.target.value) : 0;
                    if (Number.isNaN(value))
                      value = 0;
                    const fixed = (value && value.replace(/,/g, ''));
                    let parsedValue = fixed ? parseFloat(fixed) : 0;
                    if (parsedValue > 100) parsedValue = 100;
                    effectedFieldsByDiscountOrPerRecalculate(
                      'discountPercentageOfBasePrice',
                      parsedValue
                    );
                  }}
                  isDisabled={cantEditReservedSaleTransaction}
                />
              </div>
            </div>
            <div className='form-item'>
              <Inputs
                withNumberFormat
                idRef='sellingPriceRef'
                labelValue='selling-price'
                value={state.sellingPrice || 0}
                helperText={getErrorByName(schema, 'sellingPrice').message}
                error={getErrorByName(schema, 'sellingPrice').error}
                endAdornment={<span className='px-2'>AED</span>}
                type='number'
                min={0}
                isDisabled
                isWithError
                isSubmitted={isSubmitted}
                parentTranslationPath={parentTranslationPath}
                translationPath={translationPath}
              />
            </div>
            <div className='form-item form-item-wrapper'>
              <Inputs
                withNumberFormat
                isAttachedInput
                idRef='admRegistrationFeesRef'
                labelValue='municipal-registration-fees'
                labelClasses='has-inside-label'
                value={state.admRegistrationFees || 0}
                helperText={getErrorByName(schema, 'admRegistrationFees').message}
                error={getErrorByName(schema, 'admRegistrationFees').error}
                endAdornment={(
                  <span className='px-2 inner-span'>AED</span>
                )}
                type='number'
                min={0}
                max={+(state.sellingPrice || 0)}
                isWithError
                isSubmitted={isSubmitted}
                parentTranslationPath={parentTranslationPath}
                translationPath={translationPath}
                onKeyUp={(e) => {
                  const value = e && e.target && e.target.value ? (e.target.value) : 0;
                  if (Number.isNaN(value))
                    value = 0;
                  const fixed = (value && value.replace(/,/g, ''));
                  let parsedValue = fixed ? parseFloat(fixed) : 0;
                  const effectedByValue = +(state.sellingPrice || 0);
                  if (parsedValue > effectedByValue) parsedValue = effectedByValue;
                  const effectedValue = (parsedValue / effectedByValue) * 100;
                  onStateChanged({
                    id: 'edit',
                    value: {
                      ...state,
                      admRegistrationFees: parsedValue,
                      admRegistrationFeesPercentageOfSellingPrice: effectedValue,
                    },
                  });
                }}
                isDisabled={cantEditReservedSaleTransaction}
              />
              <div className='input-container'>
                <Inputs
                  withNumberFormat
                  isAttachedInput
                  idRef='admRegistrationFeesPercentageOfSellingPriceRef'
                  labelValue='of-selling-price'
                  value={state.admRegistrationFeesPercentageOfSellingPrice || 0}
                  helperText={
                    getErrorByName(schema, 'admRegistrationFeesPercentageOfSellingPrice')
                      .message
                  }
                  error={
                    getErrorByName(schema, 'admRegistrationFeesPercentageOfSellingPrice').error
                  }
                  endAdornment={<span className='px-2'>%</span>}
                  type='number'
                  labelClasses='inside-input-label'
                  wrapperClasses='mb-0'
                  min={0}
                  max={100}
                  isWithError
                  isSubmitted={isSubmitted}
                  parentTranslationPath={parentTranslationPath}
                  translationPath={translationPath}
                  onKeyUp={(e) => {
                    const value = e && e.target && e.target.value ? (e.target.value) : 0;
                    if (Number.isNaN(value))
                      value = 0;
                    const fixed = (value && value.replace(/,/g, ''));
                    let parsedValue = fixed ? parseFloat(fixed) : 0;
                    if (parsedValue > 100) parsedValue = 100;
                    const effectedValue =
                      (+(state.sellingPrice || 0) / 100) * parsedValue;

                    onStateChanged({
                      id: 'edit',
                      value: {
                        ...state,
                        admRegistrationFeesPercentageOfSellingPrice: parsedValue,
                        admRegistrationFees: effectedValue,
                      },
                    });
                  }}
                  isDisabled={cantEditReservedSaleTransaction}
                />
              </div>
            </div>
            <div className='form-item form-item-wrapper'>
              <Inputs
                withNumberFormat
                isAttachedInput
                idRef='transferFeeSellerRef'
                labelValue='transfer-fees-seller-description'
                labelClasses='has-inside-label'
                value={state.transferFeeSeller || 0}
                helperText={getErrorByName(schema, 'transferFeeSeller').message}
                error={getErrorByName(schema, 'transferFeeSeller').error}
                endAdornment={(
                  <span className='px-2 inner-span'>AED</span>
                )}
                type='number'
                min={0}
                max={+(state.sellingPrice || 0)}
                isWithError
                isSubmitted={isSubmitted}
                parentTranslationPath={parentTranslationPath}
                translationPath={translationPath}
                onKeyUp={(e) => {
                  const value = e && e.target && e.target.value ? (e.target.value) : 0;
                  if (Number.isNaN(value))
                    value = 0;
                  const fixed = (value && value.replace(/,/g, ''));
                  let parsedValue = fixed ? parseFloat(fixed) : 0;
                  const effectedByValue = +(state.sellingPrice || 0);
                  if (parsedValue > effectedByValue) parsedValue = effectedByValue;
                  const effectedValue = (parsedValue / effectedByValue) * 100;
                  onStateChanged({
                    id: 'edit',
                    value: {
                      ...state,
                      transferFeeSeller: parsedValue,
                      transferFeeSellerPercentageOfSellingPrice: effectedValue,
                    },
                  });
                }}
                isDisabled={cantEditReservedSaleTransaction}
              />
              <div className='input-container'>
                <Inputs
                  withNumberFormat
                  isAttachedInput
                  idRef='transferFeeSellerPercentageOfSellingPriceRef'
                  labelValue='of-selling-price'
                  value={state.transferFeeSellerPercentageOfSellingPrice || 0}
                  helperText={
                    getErrorByName(schema, 'transferFeeSellerPercentageOfSellingPrice').message
                  }
                  error={
                    getErrorByName(schema, 'transferFeeSellerPercentageOfSellingPrice').error
                  }
                  endAdornment={<span className='px-2'>%</span>}
                  type='number'
                  labelClasses='inside-input-label'
                  wrapperClasses='mb-0'
                  min={0}
                  max={100}
                  isWithError
                  isSubmitted={isSubmitted}
                  parentTranslationPath={parentTranslationPath}
                  translationPath={translationPath}
                  onKeyUp={(e) => {
                    const value = e && e.target && e.target.value ? (e.target.value) : 0;
                    if (Number.isNaN(value))
                      value = 0;
                    const fixed = (value && value.replace(/,/g, ''));
                    let parsedValue = fixed ? parseFloat(fixed) : 0;
                    if (parsedValue > 100) parsedValue = 100;
                    const effectedValue =
                      (+(state.sellingPrice || 0) / 100) * parsedValue;

                    onStateChanged({
                      id: 'edit',
                      value: {
                        ...state,
                        transferFeeSellerPercentageOfSellingPrice: parsedValue,
                        transferFeeSeller: effectedValue,
                      },
                    });
                  }}
                  isDisabled={cantEditReservedSaleTransaction}
                />
              </div>
            </div>
            <div className='form-item form-item-wrapper'>
              <Inputs
                withNumberFormat
                isAttachedInput
                idRef='transferFeeBuyerRef'
                labelValue='transfer-fees-buyer-description'
                labelClasses='has-inside-label'
                value={state.transferFeeBuyer || 0}
                helperText={getErrorByName(schema, 'transferFeeBuyer').message}
                error={getErrorByName(schema, 'transferFeeBuyer').error}
                endAdornment={(
                  <span className='px-2 inner-span'>AED</span>
                )}
                type='number'
                min={0}
                max={+(state.basePrice || 0)}
                isWithError
                isSubmitted={isSubmitted}
                parentTranslationPath={parentTranslationPath}
                translationPath={translationPath}
                onKeyUp={(e) => {
                  const value = e && e.target && e.target.value ? (e.target.value) : 0;
                  if (Number.isNaN(value))
                    value = 0;
                  const fixed = (value && value.replace(/,/g, ''));
                  let parsedValue = fixed ? parseFloat(fixed) : 0;
                  const effectedByValue = +(state.basePrice || 0);
                  if (parsedValue > effectedByValue) parsedValue = effectedByValue;
                  const effectedValue = (parsedValue / effectedByValue) * 100;
                  onStateChanged({
                    id: 'edit',
                    value: {
                      ...state,
                      transferFeeBuyer: parsedValue,
                      transferFeeBuyerPercentageOfBasePrice: effectedValue,
                    },
                  });
                }}
                isDisabled={cantEditReservedSaleTransaction}
              />
              <div className='input-container'>
                <Inputs
                  withNumberFormat
                  isAttachedInput
                  idRef='transferFeeBuyerPercentageOfBasePriceRef'
                  labelValue='of-base-price'
                  value={state.transferFeeBuyerPercentageOfBasePrice || 0}
                  helperText={
                    getErrorByName(schema, 'transferFeeBuyerPercentageOfBasePrice').message
                  }
                  error={getErrorByName(schema, 'transferFeeBuyerPercentageOfBasePrice').error}
                  endAdornment={<span className='px-2'>%</span>}
                  type='number'
                  labelClasses='inside-input-label'
                  wrapperClasses='mb-0'
                  min={0}
                  max={100}
                  isWithError
                  isSubmitted={isSubmitted}
                  parentTranslationPath={parentTranslationPath}
                  translationPath={translationPath}
                  onKeyUp={(e) => {
                    const value = e && e.target && e.target.value ? (e.target.value) : 0;
                    if (Number.isNaN(value))
                      value = 0;
                    const fixed = (value && value.replace(/,/g, ''));
                    let parsedValue = fixed ? parseFloat(fixed) : 0;
                    if (parsedValue > 100) parsedValue = 100;
                    const effectedValue =
                      (+(state.basePrice || 0) / 100) * parsedValue;
                    onStateChanged({
                      id: 'edit',
                      value: {
                        ...state,
                        transferFeeBuyerPercentageOfBasePrice: parsedValue,
                        transferFeeBuyer: effectedValue,
                      },
                    });
                  }}
                  isDisabled={cantEditReservedSaleTransaction}
                />
              </div>
            </div>
            <div className='form-item form-item-wrapper'>
              <Inputs
                withNumberFormat
                isAttachedInput
                idRef='transferFeesForCompletedPaidPropRef'
                labelValue='transfer-fees-completed-properties-description'
                labelClasses='has-inside-label'
                value={state.transferFeeCompletedAndPaidProp || 0}
                helperText={getErrorByName(schema, 'transferFeeCompletedAndPaidProp').message}
                error={getErrorByName(schema, 'transferFeeCompletedAndPaidProp').error}
                endAdornment={(
                  <span className='px-2 inner-span'>AED</span>
                )}
                type='number'
                min={0}
                max={+(state.sellingPrice || 0)}
                isWithError
                isSubmitted={isSubmitted}
                parentTranslationPath={parentTranslationPath}
                translationPath={translationPath}
                onKeyUp={(e) => {
                  const value = e && e.target && e.target.value ? (e.target.value) : 0;
                  if (Number.isNaN(value))
                    value = 0;
                  const fixed = (value && value.replace(/,/g, ''));
                  let parsedValue = fixed ? parseFloat(fixed) : 0;
                  const effectedByValue = +(state.sellingPrice || 0);
                  if (parsedValue > effectedByValue) parsedValue = effectedByValue;
                  const effectedValue = (parsedValue / effectedByValue) * 100;
                  onStateChanged({
                    id: 'edit',
                    value: {
                      ...state,
                      transferFeeCompletedAndPaidProp: parsedValue,
                      transferFeeCompletedAndPaidPropOfSellingPrice: effectedValue,
                    },
                  });
                }}
                isDisabled={cantEditReservedSaleTransaction}
              />
              <div className='input-container'>
                <Inputs
                  withNumberFormat
                  isAttachedInput
                  idRef='transferFeeCompletedAndPaidPropOfSellingPriceRef'
                  labelValue='of-selling-price'
                  value={state.transferFeeCompletedAndPaidPropOfSellingPrice || 0}
                  helperText={
                    getErrorByName(schema, 'transferFeeCompletedAndPaidPropOfSellingPrice')
                      .message
                  }
                  error={
                    getErrorByName(schema, 'transferFeeCompletedAndPaidPropOfSellingPrice')
                      .error
                  }
                  endAdornment={<span className='px-2'>%</span>}
                  type='number'
                  labelClasses='inside-input-label'
                  wrapperClasses='mb-0'
                  min={0}
                  max={100}
                  isWithError
                  isSubmitted={isSubmitted}
                  parentTranslationPath={parentTranslationPath}
                  translationPath={translationPath}
                  onKeyUp={(e) => {
                    const value = e && e.target && e.target.value ? (e.target.value) : 0;
                    if (Number.isNaN(value))
                      value = 0;
                    const fixed = (value && value.replace(/,/g, ''));
                    let parsedValue = fixed ? parseFloat(fixed) : 0;
                    if (parsedValue > 100) parsedValue = 100;
                    const effectedValue =
                      (+(state.sellingPrice || 0) / 100) * parsedValue;
                    onStateChanged({
                      id: 'edit',
                      value: {
                        ...state,
                        transferFeeCompletedAndPaidPropOfSellingPrice: parsedValue,
                        transferFeeCompletedAndPaidProp: effectedValue,
                      },
                    });
                  }}
                  isDisabled={cantEditReservedSaleTransaction}
                />
              </div>
            </div>
            <div className='form-item form-item-wrapper'>
              <Inputs
                withNumberFormat
                isAttachedInput
                idRef='sellerHasPaidRef'
                labelValue='seller-has-paid'
                labelClasses='has-inside-label'
                value={state.sellerHasPaid || 0}
                helperText={getErrorByName(schema, 'sellerHasPaid').message}
                error={getErrorByName(schema, 'sellerHasPaid').error}
                endAdornment={(
                  <span className='px-2 inner-span'>AED</span>
                )}
                type='number'
                min={0}
                max={+(state.sellingPrice || 0)}
                isWithError
                isSubmitted={isSubmitted}
                parentTranslationPath={parentTranslationPath}
                translationPath={translationPath}
                isDisabled={cantEditReservedSaleTransaction}
                onKeyUp={(e) => {
                  const value = e && e.target && e.target.value ? (e.target.value) : 0;
                  if (Number.isNaN(value))
                    value = 0;
                  const fixed = (value && value.replace(/,/g, ''));
                  let parsedValue = fixed ? parseFloat(fixed) : 0;
                  const effectedByValue = +(state.sellingPrice || 0);
                  if (parsedValue > effectedByValue) parsedValue = effectedByValue;
                  const effectedValue = (parsedValue / effectedByValue) * 100;
                  const amountDueToDeveloper = state.sellingPrice - parsedValue;
                  onStateChanged({
                    id: 'edit',
                    value: {
                      ...state,
                      sellerHasPaid: parsedValue,
                      sellerHasPaidPercentageOfBasePrice: effectedValue,
                      amountDueToDeveloper,

                    },
                  });
                }}
              />
              <div className='input-container'>
                <Inputs
                  withNumberFormat
                  isAttachedInput
                  idRef='sellerHasPaidPercentageOfBasePriceRef'
                  labelValue='of-selling-price'
                  value={state.sellerHasPaidPercentageOfBasePrice || 0}
                  helperText={
                    getErrorByName(schema, 'sellerHasPaidPercentageOfBasePrice').message
                  }
                  error={getErrorByName(schema, 'sellerHasPaidPercentageOfBasePrice').error}
                  endAdornment={<span className='px-2'>%</span>}
                  type='number'
                  labelClasses='inside-input-label'
                  wrapperClasses='mb-0'
                  min={0}
                  max={100}
                  isDisabled={cantEditReservedSaleTransaction}
                  isWithError
                  isSubmitted={isSubmitted}
                  parentTranslationPath={parentTranslationPath}
                  translationPath={translationPath}
                  onKeyUp={(e) => {
                    const value = e && e.target && e.target.value ? (e.target.value) : 0;
                    if (Number.isNaN(value))
                      value = 0;
                    const fixed = (value && value.replace(/,/g, ''));
                    let parsedValue = fixed ? parseFloat(fixed) : 0;
                    if (parsedValue > 100) parsedValue = 100;
                    const effectedValue =
                      (+(state.sellingPrice || 0) / 100) * parsedValue;
                    const amountDueToDeveloper = state.sellingPrice - effectedValue;
                    onStateChanged({
                      id: 'edit',
                      value: {
                        ...state,
                        sellerHasPaidPercentageOfBasePrice: parsedValue,
                        sellerHasPaid: effectedValue,
                        amountDueToDeveloper,
                      },
                    });
                  }}
                />
              </div>
            </div>
            <div className='form-item'>
              <Inputs
                withNumberFormat
                idRef='amountDueToDeveloperRef'
                labelValue='amount-due-to-developer'
                value={state.amountDueToDeveloper || 0}
                helperText={getErrorByName(schema, 'amountDueToDeveloper').message}
                error={getErrorByName(schema, 'amountDueToDeveloper').error}
                endAdornment={<span className='px-2'>AED</span>}
                type='number'
                isDisabled
                min={0}
                isWithError
                isSubmitted={isSubmitted}
                parentTranslationPath={parentTranslationPath}
                translationPath={translationPath}
                onInputChanged={(event) => {
                  const { value } = event.target;
                  onStateChanged({ id: 'amountDueToDeveloper', value });
                }}
              />
            </div>
            {selected.agents && selected.agents.map((item, index) => (
              <div className='form-item'>
                <Inputs
                  withNumberFormat
                  idRef={`agent-commission-${index})`}
                  labelValue={`${item.agentType} Agent Commission (${item.fullName})`}
                  value={state.agents && state.agents.length > 0 && state.agents[index].agentCommission || 0}
                  endAdornment={<span className='px-2'>%</span>}
                  type='number'
                  min={0}
                  max={100}
                  isWithError
                  isDisabled={cantEditReservedSaleTransaction}
                  isSubmitted={isSubmitted}
                  parentTranslationPath={parentTranslationPath}
                  translationPath={translationPath}
                  onInputChanged={(event) => {
                    const { value } = event.target;
                    const stateAgents = [...state.agents]
                    const changedAgentIndex = stateAgents.findIndex((agent) => agent.userId === item.id);
                    const changedAgent = stateAgents[changedAgentIndex]
                    stateAgents[changedAgentIndex] = {
                      ...changedAgent,
                      agentCommission: value,
                    }
                    onStateChanged({ id: 'agents', value: stateAgents });
                  }}
                />
              </div>))
            }
            {selected.listingAgents && selected.listingAgents.map((item, index) => (
              <div className='form-item'>
                <Inputs
                  withNumberFormat
                  idRef={`listing-agent-commission-${index})`}
                  labelValue={`${item.listingAgentType} Agent Commission (${item.fullName})`}
                  value={state.listingAgents && state.listingAgents.length > 0 && state.listingAgents[index] && state.listingAgents[index].listingAgentCommission || 0}
                  endAdornment={<span className='px-2'>%</span>}
                  type='number'
                  min={0}
                  max={100}
                  isWithError
                  isSubmitted={isSubmitted}
                  isDisabled={cantEditReservedSaleTransaction}
                  parentTranslationPath={parentTranslationPath}
                  translationPath={translationPath}
                  onInputChanged={(event) => {
                    const { value } = event.target;
                    const stateAgents = [...state.listingAgents]
                    const changedAgentIndex = stateAgents.findIndex((agent) => agent.userId === item.id);
                    const changedAgent = stateAgents[changedAgentIndex]
                    stateAgents[changedAgentIndex] = {
                      ...changedAgent,
                      listingAgentCommission: value,
                    }
                    onStateChanged({ id: 'listingAgents', value: stateAgents });
                  }}
                />
              </div>))
            }
          </>
        )}
        {activeTab === 1 && (
          <>
            <div className='form-item form-item-wrapper'>
              <Inputs
                withNumberFormat
                isAttachedInput
                idRef='downPaymentRef'
                labelValue='down-payment'
                labelClasses='has-inside-label'
                value={state.downPayment || 0}
                helperText={getErrorByName(schema, 'downPayment').message}
                error={getErrorByName(schema, 'downPayment').error}
                endAdornment={(
                  <span className='px-2 inner-span'>AED</span>
                )}
                type='number'
                min={0}
                max={+(state.sellingPrice || 0)}
                isWithError
                isSubmitted={isSubmitted}
                parentTranslationPath={parentTranslationPath}
                translationPath={translationPath}
                onKeyUp={(e) => {
                  const value = e && e.target && e.target.value ? (e.target.value) : 0;
                  if (Number.isNaN(value))
                    value = 0;
                  const fixed = (value && value.replace(/,/g, ''));
                  let parsedValue = fixed ? parseFloat(fixed) : 0;
                  const effectedByValue = +(state.sellingPrice || 0);
                  if (parsedValue > effectedByValue) parsedValue = effectedByValue;
                  const effectedValue = (parsedValue / effectedByValue) * 100;
                  onStateChanged({
                    id: 'edit',
                    value: {
                      ...state,
                      downPayment: parsedValue,
                      downPaymentPercentageOfSellingPrice: effectedValue,
                    },
                  });
                }}
                isDisabled={cantEditReservedSaleTransaction}
              />
              <div className='input-container'>
                <Inputs
                  withNumberFormat
                  isAttachedInput
                  idRef='downPaymentPercentageOfSellingPriceRef'
                  labelValue='of-selling-price'
                  value={state.downPaymentPercentageOfSellingPrice || 0}
                  helperText={
                    getErrorByName(schema, 'downPaymentPercentageOfSellingPrice').message
                  }
                  error={getErrorByName(schema, 'downPaymentPercentageOfSellingPrice').error}
                  endAdornment={<span className='px-2'>%</span>}
                  type='number'
                  labelClasses='inside-input-label'
                  wrapperClasses='mb-0'
                  min={0}
                  max={100}
                  isWithError
                  isSubmitted={isSubmitted}
                  parentTranslationPath={parentTranslationPath}
                  translationPath={translationPath}
                  onKeyUp={(e) => {
                    const value = e && e.target && e.target.value ? (e.target.value) : 0;
                    if (Number.isNaN(value))
                      value = 0;
                    const fixed = (value && value.replace(/,/g, ''));
                    let parsedValue = fixed ? parseFloat(fixed) : 0;
                    if (parsedValue > 100) parsedValue = 100;
                    const effectedValue =
                      (+(state.sellingPrice || 0) / 100) * parsedValue;
                    onStateChanged({
                      id: 'edit',
                      value: {
                        ...state,
                        downPaymentPercentageOfSellingPrice: parsedValue,
                        downPayment: effectedValue,
                      },
                    });
                  }}
                  isDisabled={cantEditReservedSaleTransaction}
                />
              </div>
            </div>
            <div className='form-item'>
              <Inputs
                withNumberFormat
                idRef='loanTermByYearsRef'
                labelValue='loan-term-years'
                value={state.loanTermByYears || 0}
                helperText={getErrorByName(schema, 'loanTermByYears').message}
                error={getErrorByName(schema, 'loanTermByYears').error}
                type='number'
                min={0}
                isWithError
                isSubmitted={isSubmitted}
                parentTranslationPath={parentTranslationPath}
                translationPath={translationPath}
                onInputChanged={(event) => {
                  const { value } = event.target;
                  onStateChanged({ id: 'loanTermByYears', value });
                }}
                isDisabled={cantEditReservedSaleTransaction}
              />
            </div>
            <div className='form-item'>
              <Inputs
                withNumberFormat
                idRef='interestRateRef'
                labelValue='interest-rate-%'
                value={state.interestRate || 0}
                helperText={getErrorByName(schema, 'interestRate').message}
                error={getErrorByName(schema, 'interestRate').error}
                endAdornment={<span className='px-2'>%</span>}
                type='number'
                min={0}
                max={100}
                isWithError
                isSubmitted={isSubmitted}
                parentTranslationPath={parentTranslationPath}
                translationPath={translationPath}
                onInputChanged={(event) => {
                  let { value } = event.target;
                  if (value > 100) value = 100;
                  onStateChanged({ id: 'interestRate', value });
                }}
                isDisabled={cantEditReservedSaleTransaction}

              />
            </div>
            <div className='form-item'>
              <Inputs
                withNumberFormat
                idRef='mortgagePerMonthRef'
                labelValue='mortgage-per-month'
                value={state.mortgagePerMonth || 0}
                helperText={getErrorByName(schema, 'mortgagePerMonth').message}
                error={getErrorByName(schema, 'mortgagePerMonth').error}
                endAdornment={<span className='px-2'>AED</span>}
                type='number'
                min={0}
                isWithError
                isSubmitted={isSubmitted}
                parentTranslationPath={parentTranslationPath}
                translationPath={translationPath}
                onInputChanged={(event) => {
                  const { value } = event.target;
                  onStateChanged({ id: 'mortgagePerMonth', value });
                }}
                isDisabled={cantEditReservedSaleTransaction}
              />
            </div>
          </>
        )}
        {/* <div className="form-title-wrapper">
        <span className="form-title">{t(`${translationPath}for-finance-team-use-only`)}</span>
      </div> */}

        {/* <div className="form-title-wrapper">
        <span className="form-title">{t(`${translationPath}payment-plan-1`)}</span>
      </div> */}
        {activeTab === 2 && (
          <>
            <div className='form-item form-item-wrapper'>
              <Inputs
                withNumberFormat
                isAttachedInput
                idRef='downPaymentForPlanOneRef'
                labelValue='down-payment-plan-1'
                labelClasses='has-inside-label'
                value={state.downPaymentForPlanOne || 0}
                helperText={getErrorByName(schema, 'downPaymentForPlanOne').message}
                error={getErrorByName(schema, 'downPaymentForPlanOne').error}
                endAdornment={(
                  <span className='px-2 inner-span'>AED</span>
                )}
                type='number'
                min={0}
                max={+(state.sellingPrice || 0)}
                isWithError
                isSubmitted={isSubmitted}
                parentTranslationPath={parentTranslationPath}
                translationPath={translationPath}
                onKeyUp={(e) => {
                  const value = e && e.target && e.target.value ? (e.target.value) : 0;
                  if (Number.isNaN(value))
                    value = 0;
                  const fixed = (value && value.replace(/,/g, ''));
                  let parsedValue = fixed ? parseFloat(fixed) : 0;
                  const effectedByValue = +(state.sellingPrice || 0);
                  if (parsedValue > effectedByValue) parsedValue = effectedByValue;
                  const effectedValue = (parsedValue / effectedByValue) * 100;
                  onStateChanged({
                    id: 'edit',
                    value: {
                      ...state,
                      downPaymentForPlanOne: parsedValue,
                      downPaymentForPlanOnePercentageOfSellingPrice: effectedValue,
                    },
                  });
                }}
                isDisabled={cantEditReservedSaleTransaction}
              />
              <div className='input-container'>

                <Inputs
                  withNumberFormat
                  isAttachedInput
                  idRef='downPaymentForPlanOnePercentageOfSellingPriceRef'
                  labelValue='of-selling-price'
                  value={state.downPaymentForPlanOnePercentageOfSellingPrice || 0}
                  helperText={
                    getErrorByName(schema, 'downPaymentForPlanOnePercentageOfSellingPrice')
                      .message
                  }
                  error={
                    getErrorByName(schema, 'downPaymentForPlanOnePercentageOfSellingPrice')
                      .error
                  }
                  endAdornment={<span className='px-2'>%</span>}
                  type='number'
                  labelClasses='inside-input-label'
                  wrapperClasses='mb-0'
                  min={0}
                  max={100}
                  isWithError
                  isSubmitted={isSubmitted}
                  parentTranslationPath={parentTranslationPath}
                  translationPath={translationPath}
                  onKeyUp={(e) => {
                    const value = e && e.target && e.target.value ? (e.target.value) : 0;
                    if (Number.isNaN(value))
                      value = 0;
                    const fixed = (value && value.replace(/,/g, ''));
                    let parsedValue = fixed ? parseFloat(fixed) : 0;
                    if (parsedValue > 100) parsedValue = 100;
                    const effectedValue =
                      (+(state.sellingPrice || 0) / 100) * parsedValue;

                    onStateChanged({
                      id: 'edit',
                      value: {
                        ...state,
                        downPaymentForPlanOnePercentageOfSellingPrice: parsedValue,
                        downPaymentForPlanOne: effectedValue,
                      },
                    });
                  }}
                  isDisabled={cantEditReservedSaleTransaction}
                />
              </div>
            </div>
            <div className='form-item form-item-wrapper'>
              <Inputs
                withNumberFormat
                isAttachedInput
                idRef='monthlyInstallmentRef'
                labelValue='monthly-installment'
                labelClasses='has-inside-label'
                value={state.monthlyInstallment || 0}
                helperText={getErrorByName(schema, 'monthlyInstallment').message}
                error={getErrorByName(schema, 'monthlyInstallment').error}
                endAdornment={(
                  <span className='px-2 inner-span'>AED</span>
                )}
                type='number'
                min={0}
                max={+(state.sellingPrice || 0)}
                isWithError
                isSubmitted={isSubmitted}
                parentTranslationPath={parentTranslationPath}
                translationPath={translationPath}
                isDisabled={cantEditReservedSaleTransaction}
                onKeyUp={(e) => {
                  const value = e && e.target && e.target.value ? (e.target.value) : 0;
                  if (Number.isNaN(value))
                    value = 0;
                  const fixed = (value && value.replace(/,/g, ''));
                  let parsedValue = fixed ? parseFloat(fixed) : 0;
                  const effectedByValue = +(state.sellingPrice || 0);
                  if (parsedValue > effectedByValue) parsedValue = effectedByValue;
                  const effectedValue = (parsedValue / effectedByValue) * 100;
                  onStateChanged({
                    id: 'edit',
                    value: {
                      ...state,
                      monthlyInstallment: parsedValue,
                      monthlyInstallmentPercentageOfSellingPrice: effectedValue,
                    },
                  });
                }}
              />
              <div className='input-container'>
                <Inputs
                  withNumberFormat
                  isAttachedInput
                  idRef='monthlyInstallmentPercentageOfSellingPriceRef'
                  labelValue='of-selling-price'
                  value={state.monthlyInstallmentPercentageOfSellingPrice || 0}
                  helperText={
                    getErrorByName(schema, 'monthlyInstallmentPercentageOfSellingPrice').message
                  }
                  error={
                    getErrorByName(schema, 'monthlyInstallmentPercentageOfSellingPrice').error
                  }
                  endAdornment={<span className='px-2'>%</span>}
                  type='number'
                  labelClasses='inside-input-label'
                  wrapperClasses='mb-0'
                  min={0}
                  max={100}
                  isWithError
                  isSubmitted={isSubmitted}
                  parentTranslationPath={parentTranslationPath}
                  translationPath={translationPath}
                  onKeyUp={(e) => {
                    const value = e && e.target && e.target.value ? (e.target.value) : 0;
                    if (Number.isNaN(value))
                      value = 0;
                    const fixed = (value && value.replace(/,/g, ''));
                    let parsedValue = fixed ? parseFloat(fixed) : 0;
                    if (parsedValue > 100) parsedValue = 100;
                    const effectedValue =
                      (+(state.sellingPrice || 0) / 100) * parsedValue;
                    onStateChanged({
                      id: 'edit',
                      value: {
                        ...state,
                        monthlyInstallmentPercentageOfSellingPrice: parsedValue,
                        monthlyInstallment: effectedValue,
                      },
                    });
                  }}
                  isDisabled={cantEditReservedSaleTransaction}
                />
              </div>
            </div>
            <div className='form-item form-item-wrapper'>
              <Inputs
                withNumberFormat
                isAttachedInput
                idRef='handoverPaymentForPlanOneRef'
                labelValue='handover-payment-plan-1'
                labelClasses='has-inside-label'
                value={state.handoverPaymentForPlanOne || 0}
                helperText={getErrorByName(schema, 'handoverPaymentForPlanOne').message}
                error={getErrorByName(schema, 'handoverPaymentForPlanOne').error}
                endAdornment={(
                  <span className='px-2 inner-span'>AED</span>
                )}
                type='number'
                min={0}
                max={+(state.sellingPrice || 0)}
                isWithError
                isSubmitted={isSubmitted}
                parentTranslationPath={parentTranslationPath}
                translationPath={translationPath}
                onKeyUp={(e) => {
                  const value = e && e.target && e.target.value ? (e.target.value) : 0;
                  if (Number.isNaN(value))
                    value = 0;
                  const fixed = (value && value.replace(/,/g, ''));
                  let parsedValue = fixed ? parseFloat(fixed) : 0;
                  const effectedByValue = +(state.sellingPrice || 0);
                  if (parsedValue > effectedByValue) parsedValue = effectedByValue;
                  const effectedValue = (parsedValue / effectedByValue) * 100;
                  onStateChanged({
                    id: 'edit',
                    value: {
                      ...state,
                      handoverPaymentForPlanOne: parsedValue,
                      handoverPaymentForPlanOnePercentageOfSellingPrice: effectedValue,
                    },
                  });
                }}
                isDisabled={cantEditReservedSaleTransaction}
              />
              <div className='input-container'>
                <Inputs
                  withNumberFormat
                  isAttachedInput
                  idRef='handoverPaymentForPlanOnePercentageOfSellingPriceRef'
                  labelValue='of-selling-price'
                  value={state.handoverPaymentForPlanOnePercentageOfSellingPrice || 0}
                  helperText={
                    getErrorByName(schema, 'handoverPaymentForPlanOnePercentageOfSellingPrice')
                      .message
                  }
                  error={
                    getErrorByName(schema, 'handoverPaymentForPlanOnePercentageOfSellingPrice')
                      .error
                  }
                  endAdornment={<span className='px-2'>%</span>}
                  type='number'
                  labelClasses='inside-input-label'
                  wrapperClasses='mb-0'
                  min={0}
                  max={100}
                  isWithError
                  isSubmitted={isSubmitted}
                  parentTranslationPath={parentTranslationPath}
                  translationPath={translationPath}
                  onKeyUp={(e) => {
                    const value = e && e.target && e.target.value ? (e.target.value) : 0;
                    if (Number.isNaN(value))
                      value = 0;
                    const fixed = (value && value.replace(/,/g, ''));
                    let parsedValue = fixed ? parseFloat(fixed) : 0;
                    if (parsedValue > 100) parsedValue = 100;
                    const effectedValue =
                      (+(state.sellingPrice || 0) / 100) * parsedValue;
                    onStateChanged({
                      id: 'edit',
                      value: {
                        ...state,
                        handoverPaymentForPlanOnePercentageOfSellingPrice: parsedValue,
                        handoverPaymentForPlanOne: effectedValue,
                      },
                    });
                  }}
                  isDisabled={cantEditReservedSaleTransaction}
                />
              </div>
            </div>
          </>
        )}

        {/* <div className="form-title-wrapper">
        <span className="form-title">{t(`${translationPath}payment-plan-2`)}</span>
      </div> */}
        {activeTab === 3 && (
          <>
            <div className='form-item form-item-wrapper'>
              <Inputs
                withNumberFormat
                isAttachedInput
                idRef='downPaymentForPlanTwoRef'
                labelValue='down-payment-plan-2'
                labelClasses='has-inside-label'
                value={state.downPaymentForPlanTwo || 0}
                helperText={getErrorByName(schema, 'downPaymentForPlanTwo').message}
                error={getErrorByName(schema, 'downPaymentForPlanTwo').error}
                endAdornment={(
                  <span className='px-2 inner-span'>AED</span>
                )}
                type='number'
                min={0}
                max={+(state.sellingPrice || 0)}
                isWithError
                isSubmitted={isSubmitted}
                parentTranslationPath={parentTranslationPath}
                translationPath={translationPath}
                onKeyUp={(e) => {
                  const value = e && e.target && e.target.value ? (e.target.value) : 0;
                  if (Number.isNaN(value))
                    value = 0;
                  const fixed = (value && value.replace(/,/g, ''));
                  let parsedValue = fixed ? parseFloat(fixed) : 0;
                  const effectedByValue = +(state.sellingPrice || 0);
                  if (parsedValue > effectedByValue) parsedValue = effectedByValue;
                  const effectedValue = (parsedValue / effectedByValue) * 100;
                  onStateChanged({
                    id: 'edit',
                    value: {
                      ...state,
                      downPaymentForPlanTwo: parsedValue,
                      downPaymentForPlanTwoPercentageOfSellingPrice: effectedValue,
                    },
                  });
                }}
                isDisabled={cantEditReservedSaleTransaction}
              />
              <div className='input-container'>
                <Inputs
                  withNumberFormat
                  isAttachedInput
                  idRef='downPaymentForPlanTwoPercentageOfSellingPriceRef'
                  labelValue='of-selling-price'
                  value={state.downPaymentForPlanTwoPercentageOfSellingPrice || 0}
                  helperText={
                    getErrorByName(schema, 'downPaymentForPlanTwoPercentageOfSellingPrice')
                      .message
                  }
                  error={
                    getErrorByName(schema, 'downPaymentForPlanTwoPercentageOfSellingPrice')
                      .error
                  }
                  endAdornment={<span className='px-2'>%</span>}
                  type='number'
                  labelClasses='inside-input-label'
                  wrapperClasses='mb-0'
                  min={0}
                  max={100}
                  isWithError
                  isSubmitted={isSubmitted}
                  parentTranslationPath={parentTranslationPath}
                  translationPath={translationPath}
                  onKeyUp={(e) => {
                    const value = e && e.target && e.target.value ? (e.target.value) : 0;
                    if (Number.isNaN(value))
                      value = 0;
                    const fixed = (value && value.replace(/,/g, ''));
                    let parsedValue = fixed ? parseFloat(fixed) : 0;
                    if (parsedValue > 100) parsedValue = 100;
                    const effectedValue =
                      (+(state.sellingPrice || 0) / 100) * parsedValue;
                    onStateChanged({
                      id: 'edit',
                      value: {
                        ...state,
                        downPaymentForPlanTwoPercentageOfSellingPrice: parsedValue,
                        downPaymentForPlanTwo: effectedValue,
                      },
                    });
                  }}
                  isDisabled={cantEditReservedSaleTransaction}
                />
              </div>
            </div>
            <div className='form-item form-item-wrapper'>
              <Inputs
                withNumberFormat
                isAttachedInput
                idRef='handoverPaymentForPlanTwoRef'
                labelValue='handover-payment-plan-2'
                labelClasses='has-inside-label'
                value={state.handoverPaymentForPlanTwo || 0}
                helperText={getErrorByName(schema, 'handoverPaymentForPlanTwo').message}
                error={getErrorByName(schema, 'handoverPaymentForPlanTwo').error}
                endAdornment={(
                  <span className='px-2 inner-span'>AED</span>
                )}
                type='number'
                min={0}
                max={+(state.sellingPrice || 0)}
                isWithError
                isSubmitted={isSubmitted}
                parentTranslationPath={parentTranslationPath}
                translationPath={translationPath}
                onKeyUp={(e) => {
                  const value = e && e.target && e.target.value ? (e.target.value) : 0;
                  if (Number.isNaN(value))
                    value = 0;
                  const fixed = (value && value.replace(/,/g, ''));
                  let parsedValue = fixed ? parseFloat(fixed) : 0;
                  const effectedByValue = +(state.sellingPrice || 0);
                  if (parsedValue > effectedByValue) parsedValue = effectedByValue;
                  const effectedValue = (parsedValue / effectedByValue) * 100;
                  onStateChanged({
                    id: 'edit',
                    value: {
                      ...state,
                      handoverPaymentForPlanTwo: parsedValue,
                      handoverPaymentForPlanTwoPercentageOfSellingPrice: effectedValue,
                    },
                  });
                }}
                isDisabled={cantEditReservedSaleTransaction}
              />
              <div className='input-container'>
                <Inputs
                  withNumberFormat
                  isAttachedInput
                  idRef='handoverPaymentForPlanTwoPercentageOfSellingPriceRef'
                  labelValue='of-selling-price'
                  value={state.handoverPaymentForPlanTwoPercentageOfSellingPrice || 0}
                  helperText={
                    getErrorByName(schema, 'handoverPaymentForPlanTwoPercentageOfSellingPrice')
                      .message
                  }
                  error={
                    getErrorByName(schema, 'handoverPaymentForPlanTwoPercentageOfSellingPrice')
                      .error
                  }
                  endAdornment={<span className='px-2'>%</span>}
                  type='number'
                  labelClasses='inside-input-label'
                  wrapperClasses='mb-0'
                  min={0}
                  max={100}
                  isWithError
                  isSubmitted={isSubmitted}
                  parentTranslationPath={parentTranslationPath}
                  translationPath={translationPath}
                  onKeyUp={(e) => {
                    const value = e && e.target && e.target.value ? (e.target.value) : 0;
                    if (Number.isNaN(value))
                      value = 0;
                    const fixed = (value && value.replace(/,/g, ''));
                    let parsedValue = fixed ? parseFloat(fixed) : 0;
                    if (parsedValue > 100) parsedValue = 100;
                    const effectedValue =
                      (+(state.sellingPrice || 0) / 100) * parsedValue;
                    onStateChanged({
                      id: 'edit',
                      value: {
                        ...state,
                        handoverPaymentForPlanTwoPercentageOfSellingPrice: parsedValue,
                        handoverPaymentForPlanTwo: effectedValue,
                      },
                    });
                  }}
                  isDisabled={cantEditReservedSaleTransaction}
                />
              </div>
            </div>
            <div className='form-item'>
              <RadiosGroupComponent
                idRef='isPriceOnApplicationRef'
                labelValue='is-price-on-application'
                data={[
                  {
                    key: true,
                    value: 'yes',
                  },
                  {
                    key: false,
                    value: 'no',
                  },
                ]}
                value={state.isPriceOnApplication}
                parentTranslationPath={parentTranslationPath}
                translationPathForData={translationPath}
                translationPath={translationPath}
                labelInput='value'
                valueInput='key'
                onSelectedRadioChanged={(e, newValue) =>
                  onStateChanged({ id: 'isPriceOnApplication', value: newValue === 'true' })}
                isDisabled={cantEditReservedSaleTransaction}
              />
            </div>
            <div className='form-item'>
              <RadiosGroupComponent
                idRef='isNegotiableRef'
                labelValue='is-negotiable'
                data={[
                  {
                    key: true,
                    value: 'yes',
                  },
                  {
                    key: false,
                    value: 'no',
                  },
                ]}
                value={state.isNegotiable}
                parentTranslationPath={parentTranslationPath}
                translationPathForData={translationPath}
                translationPath={translationPath}
                labelInput='value'
                valueInput='key'
                onSelectedRadioChanged={(e, newValue) =>
                  onStateChanged({ id: 'isNegotiable', value: newValue === 'true' })}
                isDisabled={cantEditReservedSaleTransaction}
              />
            </div>
            <div className='form-item'>
              <Inputs
                withNumberFormat
                idRef='sellingPricePerSQFTRef'
                labelValue='selling-price-per-sq-ft'
                value={state.sellingPricePerSQFT || 0}
                helperText={getErrorByName(schema, 'sellingPricePerSQFT').message}
                error={getErrorByName(schema, 'sellingPricePerSQFT').error}
                endAdornment={<span className='px-2'>AED</span>}
                type='number'
                min={0}
                isWithError
                isSubmitted={isSubmitted}
                parentTranslationPath={parentTranslationPath}
                translationPath={translationPath}
                onInputChanged={(event) => {
                  const { value } = event.target;
                  onStateChanged({ id: 'sellingPricePerSQFT', value });
                }}
                isDisabled={cantEditReservedSaleTransaction}
              />
            </div>
            <div className='form-item'>
              <Inputs
                withNumberFormat
                idRef='originalPricePerSQFTRef'
                labelValue='original-price-per-sq-ft'
                value={state.originalPricePerSQFT || 0}
                helperText={getErrorByName(schema, 'originalPricePerSQFT').message}
                error={getErrorByName(schema, 'originalPricePerSQFT').error}
                endAdornment={<span className='px-2'>AED</span>}
                type='number'
                min={0}
                isWithError
                isSubmitted={isSubmitted}
                parentTranslationPath={parentTranslationPath}
                translationPath={translationPath}
                onInputChanged={(event) => {
                  const { value } = event.target;
                  onStateChanged({ id: 'originalPricePerSQFT', value });
                }}
                isDisabled={cantEditReservedSaleTransaction}
              />
            </div>
          </>
        )}
                    <div className='form-item form-item-wrapper'>
              <Inputs
                withNumberFormat
                isAttachedInput
                idRef='agencyFeesRef'
                labelValue='agency-fees-sale-description'
                labelClasses='has-inside-label'
                value={state.agencyFeeSeller || 0}
                helperText={getErrorByName(schema, 'agencyFees').message}
                error={getErrorByName(schema, 'agencyFees').error}
                endAdornment={(
                  <span className='px-2 inner-span'>AED</span>
                )}
                type='number'
                min={0}
                max={+(state.premium || 0) + +(state.basePrice || 0)}
                isWithError
                isSubmitted={isSubmitted}
                parentTranslationPath={parentTranslationPath}
                translationPath={translationPath}
                onKeyUp={(e) => {
                  const value = e && e.target && e.target.value ? (e.target.value) : 0;
                  if (Number.isNaN(value))
                    value = 0;
                  const fixed = (value && value.replace(/,/g, ''));
                  let parsedValue = fixed ? parseFloat(fixed) : 0;
                  const total = +(state.premium || 0) + +(state.basePrice || 0);
                  if (parsedValue > total) parsedValue = total;

                  let effectedValue = (parsedValue / total) * 100;

                  if (parsedValue < state.externalAgencyFeeSeller) effectedValue = 0
                  
                  const netAmountSeller = parsedValue - state.externalAgencyFeeSeller;

                  onStateChanged({
                    id: 'edit',
                    value: {
                      ...state,
                      agencyFeeSeller: parsedValue,
                      agencyFeeSellerPercentageOfBasePriceAndPremium: +(effectedValue || 0),
                      netAmountSeller
                    },
                  });
                }}
                isDisabled={cantEditReservedSaleTransaction}
              />
              <div className='input-container'>
                <Inputs
                  withNumberFormat
                  isAttachedInput
                  idRef='agencyFeeSellerPercentageOfBasePriceAndPremiumRef'
                  labelValue='of-base-price-premium'
                  value={state.agencyFeeSellerPercentageOfBasePriceAndPremium || 0}
                  helperText={
                    getErrorByName(schema, 'agencyFeeSellerPercentageOfBasePriceAndPremium')
                      .message
                  }
                  error={
                    getErrorByName(schema, 'agencyFeeSellerPercentageOfBasePriceAndPremium')
                      .error
                  }
                  endAdornment={<span className='px-2'>%</span>}
                  type='number'
                  labelClasses='inside-input-label'
                  wrapperClasses='mb-0'
                  min={0}
                  max={100}
                  isWithError
                  isSubmitted={isSubmitted}
                  parentTranslationPath={parentTranslationPath}
                  translationPath={translationPath}
                  onKeyUp={(e) => {
                    const value = e && e.target && e.target.value ? (e.target.value) : 0;
                    if (Number.isNaN(value))
                      value = 0;
                    const fixed = (value && value.replace(/,/g, ''));
                    let parsedValue = fixed ? parseFloat(fixed) : 0;

                    if (parsedValue > 100) parsedValue = 100;

                    let effectedValue =
                      ((+(state.premium || 0) + +(state.basePrice || 0)) / 100) * parsedValue;


                    const netAmountSeller = effectedValue - state.externalAgencyFeeSeller;

                    onStateChanged({
                      id: 'edit',
                      value: {
                        ...state,
                        agencyFeeSellerPercentageOfBasePriceAndPremium: +(parsedValue || 0),
                        agencyFeeSeller: effectedValue,
                        netAmountSeller
                      },
                    });
                  }}
                  isDisabled={cantEditReservedSaleTransaction}
                />
              </div>
            </div>
        <div className='form-item form-item-wrapper'>
        <Inputs
          withNumberFormat
          isAttachedInput
          idRef='agencyFeesRef'
          labelValue='External-Agency-Fee-(Seller)'
          labelClasses='has-inside-label'
          value={state.externalAgencyFeeSeller || 0}
          helperText={getErrorByName(schema, 'externalAgencyFeeSeller').message}
          error={getErrorByName(schema, 'externalAgencyFeeSeller').error}
          endAdornment={(
            <span className='px-2 inner-span'>AED</span>
          )}
          type='number'
          min={0}
          max={+(state.premium || 0) + +(state.basePrice || 0)}
          isWithError
          isSubmitted={isSubmitted}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          onKeyUp={(e) => {
            const value = e && e.target && e.target.value ? (e.target.value) : 0;
            if (Number.isNaN(value))
              value = 0;
            const fixed = (value && value.replace(/,/g, ''));
            let parsedValue = fixed ? parseFloat(fixed) : 0;

            const total = +(state.premium || 0) + +(state.basePrice || 0);

            if (parsedValue > total) parsedValue = total;

            const effectedValue = (parsedValue / total) * 100;

            const netAmountSeller = state.agencyFeeSeller - parsedValue;

            onStateChanged({
              id: 'edit',
              value: {
                ...state,
                externalAgencyFeeSeller: parsedValue,
                externalAgencyFeeSellerPercentage: +(effectedValue || 0),
                netAmountSeller
              },
            });
          }}
          isDisabled={cantEditReservedSaleTransaction}
        />
        <div className='input-container'>
          <Inputs
            withNumberFormat
            isAttachedInput
            idRef='externalAgencyFeeSellerPercentageRef'
            labelValue='of-base-price-premium'
            value={state.externalAgencyFeeSellerPercentage || 0}
            helperText={
              getErrorByName(schema, 'externalAgencyFeeSellerPercentage')
                .message
            }
            error={
              getErrorByName(schema, 'externalAgencyFeeSellerPercentage')
                .error
            }
            endAdornment={<span className='px-2'>%</span>}
            type='number'
            labelClasses='inside-input-label'
            wrapperClasses='mb-0'
            min={0}
            max={100}
            isWithError
            isSubmitted={isSubmitted}
            parentTranslationPath={parentTranslationPath}
            translationPath={translationPath}
            onKeyUp={(e) => {
              const value = e && e.target && e.target.value ? (e.target.value) : 0;
              if (Number.isNaN(value))
                value = 0;
              const fixed = (value && value.replace(/,/g, ''));
              let parsedValue = fixed ? parseFloat(fixed) : 0;
              if (parsedValue > 100) parsedValue = 100;
              const effectedValue =
                ((+(state.premium || 0) + +(state.basePrice || 0)) / 100) * parsedValue;
                const netAmountSeller = state.agencyFeeSeller - effectedValue;
                onStateChanged({
                id: 'edit',
                value: {
                  ...state,
                  externalAgencyFeeSellerPercentage: +(parsedValue || 0),
                  externalAgencyFeeSeller: effectedValue,
                  netAmountSeller
                },
              });
            }}
            isDisabled={cantEditReservedSaleTransaction}
          />
        </div>
      </div>

        
        <div className='form-item'>
          <Inputs
            withNumberFormat
            idRef='sellingPriceRef'
            labelValue='Net-Amount-(Seller)'
            value={state.netAmountSeller || 0}
            helperText={getErrorByName(schema, 'sellingPrice').message}
            error={getErrorByName(schema, 'sellingPrice').error}
            endAdornment={<span className='px-2'>AED</span>}
            type='number'
            min={0}
            isDisabled
            isWithError
            isSubmitted={isSubmitted}
            parentTranslationPath={parentTranslationPath}
            translationPath={translationPath}
          />
        </div>

            <div className='form-item form-item-wrapper'>
              <Inputs
                withNumberFormat
                isAttachedInput
                idRef='agencyFeeBuyerRef'
                labelValue='agency-fees-buyer-description'
                labelClasses='has-inside-label'
                value={state.agencyFeeBuyer || 0}
                helperText={getErrorByName(schema, 'agencyFeeBuyer').message}
                error={getErrorByName(schema, 'agencyFeeBuyer').error}
                endAdornment={(
                  <span className='px-2 inner-span'>AED</span>
                )}
                type='number'
                min={0}
                max={+(state.sellingPrice || 0)}
                isWithError
                isSubmitted={isSubmitted}
                parentTranslationPath={parentTranslationPath}
                translationPath={translationPath}
                onKeyUp={(e) => {
                  const value = e && e.target && e.target.value ? (e.target.value) : 0;
                  if (Number.isNaN(value))
                    value = 0;
                  const fixed = (value && value.replace(/,/g, ''));
                  let parsedValue = fixed ? parseFloat(fixed) : 0;

                  const effectedByValue = +(state.sellingPrice || 0);
                  if (parsedValue > effectedByValue) parsedValue = effectedByValue;

                  let effectedValue = (parsedValue / effectedByValue) * 100;

                  const netAmountBuyer = parsedValue - state.externalAgencyFeeBuyer;

                  onStateChanged({
                    id: 'edit',
                    value: {
                      ...state,
                      agencyFeeBuyer: parsedValue,
                      agencyFeeBuyerPercentageOfSellingPrice: effectedValue,
                      netAmountBuyer
                    },
                  });
                }}
                isDisabled={cantEditReservedSaleTransaction}
              />
              <div className='input-container'>
                <Inputs
                  withNumberFormat
                  isAttachedInput
                  idRef='agencyFeeBuyerPercentageOfSellingPriceRef'
                  labelValue='of-selling-price'
                  value={state.agencyFeeBuyerPercentageOfSellingPrice || 0}
                  helperText={
                    getErrorByName(schema, 'agencyFeeBuyerPercentageOfSellingPrice').message
                  }
                  error={getErrorByName(schema, 'agencyFeeBuyerPercentageOfSellingPrice').error}
                  endAdornment={<span className='px-2'>%</span>}
                  type='number'
                  labelClasses='inside-input-label'
                  wrapperClasses='mb-0'
                  min={0}
                  max={100}
                  isWithError
                  isSubmitted={isSubmitted}
                  parentTranslationPath={parentTranslationPath}
                  translationPath={translationPath}
                  onKeyUp={(e) => {
                    const value = e && e.target && e.target.value ? (e.target.value) : 0;
                    if (Number.isNaN(value))
                      value = 0;
                    const fixed = (value && value.replace(/,/g, ''));
                    let parsedValue = fixed ? parseFloat(fixed) : 0;

                    if (parsedValue > 100) parsedValue = 100;

                    let effectedValue =
                      (+(state.sellingPrice || 0) / 100) * parsedValue;

                    if (effectedValue < state.externalAgencyFeeBuyer) effectedValue = 0;

                    const netAmountBuyer = effectedValue - state.externalAgencyFeeBuyer;

                    onStateChanged({
                      id: 'edit',
                      value: {
                        ...state,
                        agencyFeeBuyerPercentageOfSellingPrice: parsedValue,
                        agencyFeeBuyer: effectedValue,
                        netAmountBuyer
                      },
                    });
                  }}
                  isDisabled={cantEditReservedSaleTransaction}
                />
              </div>
            </div>
      <div className='form-item form-item-wrapper'>
      <Inputs
        withNumberFormat
        isAttachedInput
        idRef='agencyFeeBuyerRef'
        labelValue='External-Agency-Fee-(Buyer)'
        labelClasses='has-inside-label'
        value={state.externalAgencyFeeBuyer || 0}
        helperText={getErrorByName(schema, 'externalAgencyFeeBuyer').message}
        error={getErrorByName(schema, 'externalAgencyFeeBuyer').error}
        endAdornment={(
          <span className='px-2 inner-span'>AED</span>
        )}
        type='number'
        min={0}
        max={+(state.sellingPrice || 0)}
        isWithError
        isSubmitted={isSubmitted}
        parentTranslationPath={parentTranslationPath}
        translationPath={translationPath}
        onKeyUp={(e) => {
          const value = e && e.target && e.target.value ? (e.target.value) : 0;
          if (Number.isNaN(value))
            value = 0;
          const fixed = (value && value.replace(/,/g, ''));
          let parsedValue = fixed ? parseFloat(fixed) : 0;

          const effectedByValue = +(state.sellingPrice || 0);

          if (parsedValue > effectedByValue) parsedValue = effectedByValue;

          const effectedValue = (parsedValue / effectedByValue) * 100;

          const netAmountBuyer = state.agencyFeeBuyer - parsedValue;

          onStateChanged({
            id: 'edit',
            value: {
              ...state,
              externalAgencyFeeBuyer: parsedValue,
              externalAgencyFeeBuyerPercentage: effectedValue,
              netAmountBuyer
            },
          });
        }}
        isDisabled={cantEditReservedSaleTransaction}
      />
      <div className='input-container'>
        <Inputs
          withNumberFormat
          isAttachedInput
          idRef='externalAgencyFeeBuyerPercentageRef'
          labelValue='of-selling-price'
          value={state.externalAgencyFeeBuyerPercentage || 0}
          helperText={
            getErrorByName(schema, 'externalAgencyFeeBuyerPercentage').message
          }
          error={getErrorByName(schema, 'externalAgencyFeeBuyerPercentage').error}
          endAdornment={<span className='px-2'>%</span>}
          type='number'
          labelClasses='inside-input-label'
          wrapperClasses='mb-0'
          min={0}
          max={100}
          isWithError
          isSubmitted={isSubmitted}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          onKeyUp={(e) => {
            const value = e && e.target && e.target.value ? (e.target.value) : 0;
            if (Number.isNaN(value))
              value = 0;
            const fixed = (value && value.replace(/,/g, ''));
            let parsedValue = fixed ? parseFloat(fixed) : 0;

            if (parsedValue > 100) parsedValue = 100;

            const effectedValue =
              (+(state.sellingPrice || 0) / 100) * parsedValue;

            const netAmountBuyer = state.agencyFeeBuyer - effectedValue;
            
            onStateChanged({
              id: 'edit',
              value: {
                ...state,
                externalAgencyFeeBuyerPercentage: parsedValue,
                externalAgencyFeeBuyer: effectedValue,
                netAmountBuyer,
              },
            });
          }}
          isDisabled={cantEditReservedSaleTransaction}
        />
      </div>
    </div>

        <div className='form-item'>
          <Inputs
            withNumberFormat
            idRef='netAmount(Buyer)'
            labelValue='Net-Amount-(Buyer)'
            value={state.netAmountBuyer || 0}
            helperText={getErrorByName(schema, 'sellingPrice').message}
            error={getErrorByName(schema, 'sellingPrice').error}
            endAdornment={<span className='px-2'>AED</span>}
            type='number'
            min={0}
            isDisabled
            isWithError
            isSubmitted={isSubmitted}
            parentTranslationPath={parentTranslationPath}
            translationPath={translationPath}
          />
        </div>
      </div>
    </div>
  );
};
