
import React   from 'react';
import { useTranslation } from 'react-i18next';
import Rating from '@material-ui/lab/Rating';

  export const CoveredParking = ({
    parentTranslationPath ,
    translationPath ,
    onChangeSelectedItem , 
    selectedValues ,
    setSelectValues , 
    
   } ) => { 

    const { t } = useTranslation(parentTranslationPath);

    return (
      <>
        <div className="">
        <span className="fw-bold"> {t(`${translationPath}covered-parking`)}</span>
        </div> 
        <div className='w-75 parking-distribution'>
        <div className="d-flex d-flex-v-center parking-distribution-card mt-2 mb-2">
            <div>
            </div>
            <div>
            <Rating  
                size="large"
                name={`RatingCoveredParking`}
                max={10}
                precision={1}
                value={(selectedValues &&  selectedValues.coveredParking && selectedValues.coveredParking.score) || 0}
                onChange={(event, newValue) => 
                {
                  onChangeSelectedItem((selectedValues.coveredParking?.lookupItemName) || 'Covered Parking' , newValue || 0);
                  const newUpdateObj = selectedValues.coveredParking ? {...selectedValues.coveredParking , score : (newValue || 0 ) } : null
                  setSelectValues((item)=> ({...item , coveredParking : newUpdateObj }))
                  
                }}
                />     
            </div>
         </div>  
        </div>
      </>
    );
  } ; 
  
