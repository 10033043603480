

import React , { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import { Inputs  , TabsComponent  } from '../../../../../../Components';
import { getErrorByName } from '../../../../../../Helper' ; 
import { PoliciesTabs  } from './PoliciesTabs' ;



    
export const PoliciesContentDialog  = ({
  parentTranslationPath,
  translationPath,
   state,
   setState , 
   schema , 
   isSubmitted , 
}) => {
    const { t } = useTranslation(parentTranslationPath);
    const [activeOriginalTab, setActiveOriginalTab] = useState(0);

  const onOriginalTabChanged = (e, newTap) => {
    setActiveOriginalTab(newTap);

  };

  const checkAtLeastOneSelected = (objItem ) =>
  { 
      let oneAtLeast = false ;
      if(objItem)
      {
        Object.entries(objItem).forEach(([key, value]) => 
      {
            if(value === true &&  !Array.isArray(value)) 
               oneAtLeast  =  true ; 
              
      });

      }  
     return oneAtLeast  ;
  };

  const checkAtLeastOneSelected2 = (array) =>
  { 
     if(Array.isArray(array) &&  array.length > 0 )
     return true ; 
     
     else return false ; 
  };

  const setDefaultSelected = (objItem) =>
  { 
     if(objItem)
       return (Object.values(objItem).every(value => value === false ));
     else
     {
      return false ;
     }
  };

  

  return (
    <div className='Policies-dialog-wrapper'>
      <div className='titlePart1 mb-2'>
        <div className='titlePart2 w-50'>
         <Inputs
          labelClasses='Requierd-Color'
          idRef='policy-name'
          labelValue={t(`${translationPath}policy-name`)}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          value={state && state.policyDataName}
          onInputChanged={(e) => { 
            setState({ id: 'policyDataName', value:e.target.value  })
          }}
          helperText={getErrorByName(schema, 'policyDataName').message}
           error={getErrorByName(schema, 'policyDataName').error}
          isWithError
          isSubmitted={isSubmitted}
        />
        </div>
        </div>
        <div className='mt-2 px-2'> 
         <TabsComponent
            data={ PoliciesTabs  || []}
            labelInput='label'
            key={'PoliciesTabs'+ activeOriginalTab}
            parentTranslationPath={parentTranslationPath}
            translationPath={translationPath}
            themeClasses='theme-solid'
            dynamicComponentProps={{
            parentTranslationPath,
            translationPath,
            state , 
            setState ,
            checkAtLeastOneSelected ,
            checkAtLeastOneSelected2 , 
            setDefaultSelected,
           }}
           currentTab={activeOriginalTab}
           onTabChanged={onOriginalTabChanged}
      />
        </div>  
      
    </div>
  );
};

PoliciesContentDialog.propTypes = {
  parentTranslationPath: PropTypes.string.isRequired,
  translationPath: PropTypes.string.isRequired,
 state:PropTypes.instanceOf(Object).isRequired,
 setState : PropTypes.func.isRequired,
 schema :PropTypes.instanceOf(Object).isRequired,
 isSubmitted : PropTypes.bool.isRequired,

};

