import React from "react";

function EditOne({ fill, ...restProps }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill={fill ?? ""}
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <path
        d="M18.611 1.204a3.808 3.808 0 0 0-1.991.812c-.176.142-3.41 3.351-7.186 7.131-5.038 5.042-6.91 6.941-7.029 7.127-.23.36-.387.798-.462 1.286-.146.954-.459 3.981-.426 4.12.059.249.117.354.283.52.344.344.39.346 2.458.117.969-.108 1.951-.225 2.182-.26a3.332 3.332 0 0 0 1.286-.462c.186-.119 2.089-1.996 7.127-7.029 3.78-3.776 6.988-7.009 7.129-7.183.326-.404.592-.933.725-1.443.087-.336.104-.487.104-.94 0-.453-.017-.604-.104-.94-.482-1.843-2.189-3.033-4.096-2.856m1.013 2.082c.996.366 1.462 1.495 1.029 2.491-.099.227-.555.692-7.048 7.192-5.628 5.634-6.976 6.963-7.123 7.026-.132.057-.543.119-1.494.226-.722.081-1.321.14-1.331.13-.018-.019.244-2.443.288-2.651.013-.066.057-.183.097-.26.04-.077 3.192-3.256 7.005-7.064 6.642-6.632 6.944-6.928 7.192-7.036.43-.187.966-.208 1.385-.054"
        fill-rule="evenodd"
        fill={fill ?? ""}
      />
    </svg>
  );
}

export default EditOne;
