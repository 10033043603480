
import React, { useEffect}  from 'react';
import { useTranslation } from 'react-i18next';
import Rating from '@material-ui/lab/Rating';

  export const UnitsAmenities = ({
    parentTranslationPath ,
    translationPath ,
    state ,
    setSelectedList  , 
    selectedList , 
    onChangeSelectedItem ,

   } ) => { 

    const { t } = useTranslation(parentTranslationPath);

  
    useEffect(() => {
      setSelectedList((item)=> ({...item , unitsAmenities : state.updatePropertyRatingLookupInfoDtos.filter((x)=> x.type === 'AmenitiesCategory')}));
   }, [state]);



   return (
    <>
      <div className="">
      <span>  </span>
      </div> 
      <div className='w-100 units-finishing'>
       {    selectedList && selectedList.unitsAmenities &&  selectedList.unitsAmenities.map((item, index) => 
          (
          <div className="d-flex d-flex-v-center tower-units-finishing-card mt-2 mb-2" key={`unitsAmenities${index}`}>
          <div>
          <span className='fw-bold'> {item.lookupItemName} </span> 
          </div>
          <div>
          <Rating  
              size="large"
              name={`unitsAmenities${index}`}
              max={10}
              value={item.score}
              precision={1}
              onChange={(event, newValue) => 
              {
                onChangeSelectedItem(newValue || 0  ,index , selectedList.unitsAmenities , 'unitsAmenities');
              }}
              />     
            </div>
         </div>  
        )) } 
      </div>
    </>
  );
  } ; 
  
