import {  ZeroMatchingView } from '../../Views/Home/Inquires' ;
import  { ZeroMatchingManagementView , ZeroMatchingProfileManagementView   } from '../../Views/Home/Inquires/ZeroMatching/ZeroMatchingUtilities' ; 


export const ZeroMatchingInquiresRoutes = [
  {
    path: '/view',
    name: 'Inquires:zero-matching-sale',
    component: ZeroMatchingView,
    layout: '/home/zero-matching-sale',
    default: true,
    isRoute: true,
    authorize: true,
    roles: [],
    isDisabled: false,
    isExact: true,
    breadcrumbs: [
      {
        name: 'Inquires:zero-matching-sale',
        isDisabled: false,
        route: '/home/zero-matching-sale/view',
        groupName: 'inquiries',
      },
    ],
  },
  
  {
    path: '/add',
    name : 'Inquires:zero-matching-sale' , 
    component: ZeroMatchingManagementView,
    layout: '/home/zero-matching-sale',
    default: false,
    isRoute: true,
    authorize: true,
    roles: [],
    isDisabled: false,
    isExact: true,
    breadcrumbs: [
      {
        name : 'Inquires:zero-matching-sale' , 
        isDisabled: false,
        route: '/home/zero-matching-sale/view',
        groupName: 'inquiries',
      },
      {
        name: 'Inquires:zero-matching-sale-management-View',
        isDisabled: true,
        route: '/home/zero-matching-sale/add',
      },
    ],
  },
  {
    path: '/zero-matching-sale-profile-edit',
    name : 'Inquires:zero-matching-sale' , 
    component: ZeroMatchingProfileManagementView,
    layout: '/home/zero-matching-sale',
    default: false,
    isRoute: true,
    authorize: true,
    roles: [],
    isDisabled: false,
    isExact: true,
    breadcrumbs: [
      {
        name : 'Inquires:zero-matching-sale' , 
        isDisabled: false,
        route: '/home/zero-matching-sale/view',
        groupName: 'inquiries',
      },
      {
        name: 'Inquires:zero-matching-sale-profile-edit',
        isDisabled: true,
        route: '/home/zero-matching-sale/zero-matching-sale-profile-edit',
      },
    ],
  },
];
