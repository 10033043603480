import React from 'react'
import { useTranslation } from "react-i18next";

const MissingInformationErrorMSG = (
) => {
    const parentTranslationPath = "LeaseTransactionJourney";
    const translationPath = "UnitSelection.";
    const { t } = useTranslation(parentTranslationPath);

    return (
        <div>
            <p>{t(`${translationPath}msg-p1`)}</p>
            <ul>
                <li>{t(`${translationPath}msg-li1`)}</li>
                <li>{t(`${translationPath}msg-li2`)}</li>
            </ul>
            <p className='fw-simi-bold m-0'>{t(`${translationPath}msg-p2`)}</p>
        </div>
    )
}

export default MissingInformationErrorMSG
