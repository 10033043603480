export const LeadOwnerAssignAgentPermissions = 
{
  ViewAssignAgent :{
    permissionsId: "c22e074f-4794-406b-44f9-08db5f99228b",
    permissionsName: "View Assign Agent",
    description: null,
    componentsId: null,
    components: null
  }
    
};