import React, { useCallback, useEffect, useReducer, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useTitle } from "../../../Hooks";
import { AutocompleteComponent, Inputs, PaginationComponent, SelectComponet, Spinner, Tables } from "../../../Components";
import { HubSpotIntegration } from "../../../Services/Marketing/HubSpotIntegration";
import { bottomBoxComponentUpdate } from "../../../Helper";
import { GetContacts } from "../../../Services";
import { ButtonBase } from "@material-ui/core";

const parentTranslationPath = "HubspotContacts";
const translationPath = "";

export const HubSpotContactsView = () => {
    const { t } = useTranslation(parentTranslationPath);
    const searchTimer = useRef(null);
    useTitle(t(`${translationPath}hubspot-contacts`));
    const reducer = useCallback((state, action) => {
        if (action.id !== "edit") return { ...state, [action.id]: action.value };
        return {
            ...action.value,
        };
    }, []);
    const defaultSelected = {
        contact: null,
        isMarketingContact: null,
        contactSelected: null
    }
    const defaultData = {
        hubSpotContactData: [],
        ContactData: []
    }
    const DefultLoading = {
        isloading: false,
        getContacts: false
    }

    const [selected, setSelected] = useReducer(reducer, defaultSelected);
    const [data, setData] = useReducer(reducer, defaultData);
    const [isLoading, setIsLoading] = useReducer(reducer, DefultLoading);

    const defaultFilter = {
        "pageIndex": 0,
        "pageSize": 25,
        "search": null,
        "contactId": null,
        "isMarketingContact": null
    }
    const [filter, setFilter] = useState(defaultFilter);
    const onPageIndexChanged = (pageIndex) => {
        setFilter((item) => ({ ...item, pageIndex }));
    };
    const onPageSizeChanged = (pageSize) => {
        setFilter((item) => ({ ...item, pageIndex: 0, pageSize }));
    };

    const GetHubSpotContacts = useCallback(async () => {
        setIsLoading({ id: "isloading", value: true })
        const res = await HubSpotIntegration(filter);
        if (!(res && res.status && res.status !== 200)) {
            setData({ "id": "hubSpotContactData", "value": res.result || [] })
        }
        else {
            setData({ "id": "hubSpotContactData", "value": [] })
        }
        setIsLoading({ id: "isloading", value: false })
    })
    const getContactDisplayLabel = (option) => {
        let displayLabel = ''

        if (option?.contact?.company_name) {
            displayLabel = option.contact.company_name || '';
            return displayLabel;
        }

        if (option?.contact?.first_name) {
            displayLabel = `${option.contact.first_name || ''} ${option.contact.last_name || ''}`;
            return displayLabel;
        }

        if (option?.company_name) {
            displayLabel = option.company_name || '';
            return displayLabel;
        }

        if (option?.first_name) {
            displayLabel = `${option.first_name || ''} ${option.last_name || ''}`;
            return displayLabel;
        }

        return displayLabel;
    }
    const getAllContacts = useCallback(async (value) => {
        const res = await GetContacts({ pageIndex: 0, pageSize: 25, search: value || '', isAdvance: false });
        if (!(res && res.status && res.status !== 200)) setData({ "id": "getContacts", "value": res.result || [] });
        else setData({ "id": "getContacts", "value": [] });
    }, []);

    const onClearedAllFiltersClick = useCallback(() => {
        setSelected({
            id: "edit",
            value: {
                defaultSelected
            },
        });
        setFilter(defaultFilter);
    }, []);


    useEffect(() => {
        bottomBoxComponentUpdate(
            <PaginationComponent
                pageIndex={filter.pageIndex}
                pageSize={filter.pageSize}
                totalCount={data && data.hubSpotContactData && data.hubSpotContactData.length || 0}
                onPageIndexChanged={onPageIndexChanged}
                onPageSizeChanged={onPageSizeChanged}
            />
        );
    });

    useEffect(() => {
        GetHubSpotContacts()
    }, [filter,])

    return (
        <>
            <Spinner isActive={isLoading.isloading} isAbsolute />
            <div className="d-flex mr-5">
                <span className="fw-simi-bold fz-22px mb-4 ml-5 text-primary d-flex-column px-2 w-100 p-relative">{(t(`${translationPath}hubspot-contacts`))}</span>
                <div className='d-flex-column px-2 w-100 p-relative'>
                    <div className='d-inline-flex pl-5-reversed'>
                        <div className='d-flex w-100 mr-1'>
                            <Inputs
                                wrapperClasses={'w-100'}
                                value={selected.contact || ""}
                                inputPlaceholder={t('filter-contact-name')}
                                onInputChanged={(e) => {
                                    let { value } = e.target
                                    setSelected({ id: "contact", value: value })
                                }}
                                onKeyUp={(e) => {
                                    const { value } = e.target;
                                    if (searchTimer.current) clearTimeout(searchTimer.current);
                                    searchTimer.current = setTimeout(() => {
                                        setFilter((item) => ({ ...item, search: value || null }));
                                    }, 700);
                                }}
                                parentTranslationPath={parentTranslationPath}
                                translationPath={translationPath}
                            />
                        </div>
                    </div>
                    <div className='d-inline-flex pl-5-reversed'>
                        <div className="w-100 mt-2 mr-1-reversed">
                            <AutocompleteComponent
                                idRef='ContactSearchRef'
                                isLoading={isLoading.getContacts}
                                multiple={false}
                                data={data.getContacts || []}
                                selectedValues={selected.contactSelected}
                                chipsLabel={(option) => `${option?.contact?.first_name} ${option?.contact?.first_name}`}
                                displayLabel={(option) =>
                                    getContactDisplayLabel(option)
                                }
                                withoutSearchButton={true}
                                inputPlaceholder={t(`Search-Contacts`)}
                                parentTranslationPath={parentTranslationPath}
                                translationPath={translationPath}
                                onInputKeyUp={(event) => {
                                    const { value } = event.target;
                                    if (searchTimer.current) clearTimeout(searchTimer.current);
                                    searchTimer.current = setTimeout(() => {
                                        getAllContacts(value);
                                    }, 700);
                                }}
                                onChange={(event, newValue) => {
                                    setFilter((item) => ({ ...item, contactId: newValue && newValue.contactsId || null }));
                                    setSelected({ id: "contactSelected", value: newValue && newValue || null })
                                }}
                                onOpen={() => {
                                    if (data.ContactData && data.ContactData.length == 0) getAllContacts();
                                }}
                                renderOption={(option) =>
                                    getContactDisplayLabel(option)
                                }
                            />
                        </div>

                        <div className="w-100 mt-2 mr-1-reversed">
                            <SelectComponet
                                idRef="consentChannelRef"
                                data={[
                                    { id: 1, text: "no" },
                                    { id: 2, text: "yes" }
                                ]}
                                value={selected.isMarketingContact || 0}
                                onSelectChanged={(value) => {
                                    if (value === 1) {
                                        setSelected({ id: "isMarketingContact", value: 1 });
                                        setFilter((item) => ({ ...item, isMarketingContact: false }));
                                    } else if (value === 2) {
                                        setSelected({ id: "isMarketingContact", value: 2 });
                                        setFilter((item) => ({ ...item, isMarketingContact: true }));
                                    } else {
                                        setSelected({ id: "isMarketingContact", value: 0 });
                                        setFilter((item) => ({ ...item, isMarketingContact: false }));
                                    }
                                }}
                                wrapperClasses="mb-3"
                                isRequired
                                valueInput="id"
                                textInput="text"
                                emptyItem={{
                                    value: 0,
                                    text: "filter-by-isMarkiting",
                                    isDisabled: false,
                                }}
                                parentTranslationPath={parentTranslationPath}
                                translationPath={translationPath}
                                translationPathForData={translationPath}
                            />
                        </div>

                    </div>

                </div>
                <div className="fa-center">
                    <ButtonBase
                        onClick={onClearedAllFiltersClick}
                        id='onClearedAllFiltersref'
                        className='btns theme-solid bg-danger clear-all-btn'
                    >
                        <span className='mdi mdi-filter-remove m-1' />
                        {t(`${translationPath}clear-filters`)}
                    </ButtonBase>
                </div>


            </div>
            <div className='dialog-content-item w-100 px-4 py-3'>
                <Tables
                    data={data.hubSpotContactData || []}
                    headerData={[
                        {
                            id: 1,
                            label: 'contact-Id',
                            input: 'contactId',
                            component: (item) => (
                                <span>
                                    {(item.contactId ? item.contactId : 'N/A')}
                                </span>
                            ),
                        },
                        {
                            id: 2,
                            label: 'contact-Name',
                            input: 'contactName',
                            component: (item) => (
                                <span>
                                    {(item.contactName ? item.contactName : 'N/A')}
                                </span>
                            ),
                        },
                        {
                            id: 3,
                            label: 'contact-Class',
                            input: 'contactClass',
                            component: (item) => (
                                <span>
                                    {(item.contactClass ? item.contactClass : 'N/A')}
                                </span>
                            ),
                        },
                        {
                            id: 4,
                            label: 'Template-Name',
                            input: 'templateName',
                            component: (item) => (
                                <span>
                                    {(item.templateName ? item.templateName : 'N/A')}
                                </span>
                            ),
                        },
                        {
                            id: 4,
                            label: 'email',
                            input: 'email',
                            component: (item) => (
                                <span>
                                    {(item.email ? item.email : 'N/A')}
                                </span>
                            ),
                        },
                        {
                            id: 5,
                            label: 'phone',
                            input: 'phone',
                            component: (item) => (
                                <span>
                                    {(item.phone ? item.phone : 'N/A')}
                                </span>
                            ),
                        },
                        {
                            id: 6,
                            label: 'is-Marketing-Contact',
                            component: (item) => (
                                <span>
                                    {item.isMarketingContact === true
                                        ? 'Yes'
                                        : item.isMarketingContact === false
                                            ? 'No'
                                            : 'N/A'}
                                </span>
                            ),
                        },
                    ]}
                    defaultActions={[]}
                    parentTranslationPath={parentTranslationPath}
                    translationPath={translationPath}
                    totalItems={data.hubSpotContactData && data.hubSpotContactData.length}
                    activePage={filter.pageIndex}
                    itemsPerPage={filter.pageSize}
                />
            </div>
        </>
    );
}; 