import React from "react";
import { Box } from "@material-ui/core";
import { Timeline, TimelineItem, TimelineSeparator, TimelineConnector, TimelineContent, TimelineDot } from "@material-ui/lab";
import { Skeleton } from "@material-ui/lab";
import { CustomCard } from "../../../../Components";

// Styles
import useStyles from "./styles";

function UserActivityTimelineCardSkeleton({ numberOfCards }) {
  const styles = useStyles();

  const skeletonCards = Array.from({ length: numberOfCards }, (_, index) => (
    <TimelineItem key={index} className={styles.missingOppositeContent}>
      <TimelineSeparator className={styles.timelineSeparator}>
        <TimelineDot className={styles.timelineDot} />
        <TimelineConnector className={styles.timelineConnector} />
      </TimelineSeparator>
      <TimelineContent className={styles.timelineContent}>
        {/* Render Dynamic Component */}
        <Box className={styles.timelineContentWrapper}>
          <Box className={styles.infoSection}>
            <Box>
              <Skeleton variant="text" width="60%" height={24} />
            </Box>

            <Box className={styles.infoItem}>
              <Skeleton variant="text" width="50%" height={24} />

              <Skeleton variant="text" width="50%" height={24} />
            </Box>

            <Box className={styles.infoItem}>
              <Skeleton variant="text" width="50%" height={24} />

              <Skeleton variant="text" width="50%" height={24} />
            </Box>

            <Box>
              <Skeleton variant="text" width="30%" height={24} />
            </Box>
          </Box>
          <Box className={styles.dateSection}>
            <Box className={styles.dateDetails}>
              <Box className={styles.infoItem}>
                <Skeleton variant="text" width="60%" height={24} />
                <Skeleton variant="text" width="60%" height={24} />
              </Box>
              <Box className={styles.infoItem}>
                <Skeleton variant="text" width="60%" height={24} />
                <Skeleton variant="text" width="60%" height={24} />
              </Box>
              <Box className={styles.infoItem}>
                <Skeleton variant="text" width="60%" height={24} />
                <Skeleton variant="text" width="60%" height={24} />
              </Box>
            </Box>
            <Box className={styles.cornerUpLeftIcon}>
              <Skeleton
                className={styles.icon}
                variant="rectangular"
                width={20}
                height={20}
              />
            </Box>
          </Box>
        </Box>
      </TimelineContent>
    </TimelineItem>
  ));

  return (
    <CustomCard
      boxShadow="xs"
      borderRadius="xl"
      borderColor="secondary"
      classes={styles.contactOverViewCard}
    >
      <Box className={styles.activityCardTitleWrapper}>
        <div className={styles.titleContainer}>
          <Skeleton
            variant="text"
            width={180}
            height={28}
            style={{ borderRadius: "4px" }}
            animation={false}
          />
          <Skeleton
            variant="circular"
            width={32}
            height={22}
            style={{ borderRadius: "0.75rem" }}
          />
        </div>

        <div>
          <Skeleton variant="rectangular" width={64} height={20} />
        </div>
      </Box>

      <Box className={styles.timelineContainer}>
        <Timeline align="left" className={styles.timeLine}>
          {skeletonCards}
        </Timeline>
      </Box>
    </CustomCard>
  );
}

export default UserActivityTimelineCardSkeleton;
