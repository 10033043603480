import React, {
  useEffect,
  useState,
  useReducer,
  useCallback,
  useRef,
} from "react";
import { PowerBIEmbed } from "powerbi-client-react";
import { models } from "powerbi-client";
import "../MasterStyleDashborads/MasterStyleDashboards.scss";
import { showError } from "../../../../Helper";
import { MicrosoftToken } from "../../../../Services";
import { SpinnerDashboards } from "../SpinnerDashboards/SpinnerDashboards";
import { DateRangePickerComponent } from "../../../../Components/Controls/DateRangePickerComponent/DateRangePickerComponent";
import moment from "moment";
import { SelectComponet } from "../../../../Components";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { ButtonBase, ButtonGroup, CircularProgress } from "@material-ui/core";
import { CellsSpinner } from "../../../../SharedComponents/CellSpinnerComponent/CellsSpinner";
import { CellSpinnerBi } from "../CellSpinnerBi/CellSpinnerBi";

const parentTranslationPath = "LeadsView";
const translationPath = "";

export const AdminDeveloper = ({ id, embedUrl }) => {
  const orderFilter = useSelector((state) => state.GlobalOrderFilterReducer);
  const { t } = useTranslation(parentTranslationPath);
  const dispatch = useDispatch();
  const [ShowReport2, setShowReport2] = useState(false);
  const [report, setReport] = useState();
  const [render, setRender] = useState(false);
  const [ShowReport, setShowReport] = useState(false);
  const [StatusFilter, setStatusFilter] = useState(false);
  const [DateAppaer, setDateAppaer] = useState(false);
  const [Datetime, setDatetime] = useState(30);
  const [Leadstatus, setLeadstatus] = useState();
  const [Leadstatusstatus, setLeadstatusstatus] = useState(true);
  const [Leasestatuss, setLeadstatusstatuss] = useState(true);
  const [Leasestatus, setLeasestatus] = useState();
  const [OperatorLeasestatus, setOperatorLeasestatus] = useState("All");
  const [OperatorLeadstatus, setOperatorLeadstatus] = useState("All");
  const [datefilters, setdatefilter] = useState(true);
  const [datefilterhandover, setdatefilterhandover] = useState(true);
  const [salesstatusbtn, setsalesstatusbtn] = useState(false);
  const [Leasestatusbtn, setLeasestatusbtn] = useState(false);
  const [isRentListing, setisRentListing] = useState(false);
  const [datepicker, setdatepicker] = useState();
  const [datepicker2, setdatepicker2] = useState();
  const [monthsAgo, setMonthsAgo] = useState(1);
  const currentDate = new Date();
  const countmonth = new Date(
    currentDate.setMonth(currentDate.getMonth() - monthsAgo)
  );

  const today = new Date();
  const count = new Date(new Date().setDate(today.getDate() - 7));
  const count2 = new Date(new Date().setDate(today.getDate()));
  const datetimes = moment(count).startOf("day").format("YYYY-MM-DDTHH:mm:ss");
  const datetimes2 = moment(count2).format("YYYY-MM-DDTHH:mm:ss");
  const startDate = datetimes ? new Date(datetimes) : null;
  const endDate = datetimes2 ? new Date(datetimes2) : null;

  const dateRangeDefault = {
    startDate: startDate || null,
    endDate: endDate || null,
    key: "selection",
  };

  const [transactions, settransactions] = useState(false);
  const [ContactsDeveloper, setContactsDeveloper] = useState(false);
  const [Developertransactions, setDevelopertransactions] = useState(false);

  const [Units, setUnits] = useState(false);
  const [salesUnits, setsalesUnits] = useState();
  const [salesandrentUnits, setsalesandrentUnits] = useState();
  const [dateFilter, setDateFilter] = useState(dateRangeDefault);
  const [data, setdata] = useState([]);
  const reducer = (state, action) => {
    if (action.id !== "edit") return { ...state, [action.id]: action.value };
    if (action.id === "edit") {
      return {
        ...action.value,
      };
    }
    return undefined;
  };

  const [state, setState] = useReducer(reducer, {
    fromDate: null,
    toDate: null,
  });

  const scrollToTop = useCallback(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  useEffect(() => {
    const currentState = {
      ...state,
      fromDate:
        (dateFilter.startDate &&
          moment(dateFilter.startDate)
            .startOf("day")
            .format("YYYY-MM-DDTHH:mm:ss")) ||
        datetimes,
      toDate:
        (dateFilter.endDate &&
          moment(dateFilter.endDate)
            .endOf("day")
            .format("YYYY-MM-DDTHH:mm:ss")) ||
        datetimes2,
    };
    setState({ value: currentState });
    setdata(currentState);
  }, [dateFilter]);


  const ClearAllFilters= useCallback(()=>{
 
    setsalesUnits(null);
    setsalesandrentUnits(null);
    setdatepicker(0);
    setdatepicker2(0);
    setdatefilter(true);
    setdatefilterhandover(true);
  },[])

  const onClearedAllFiltersClick = useCallback(() => {
    setDateFilter(dateRangeDefault);
    applyBookmark("6dfc0c7b55b90026ba39");
    setLeadstatus(0);
    setLeasestatusbtn(false);
    setsalesstatusbtn(false);
    setsalesUnits(null);
    setsalesandrentUnits(null);
    setLeasestatus(0);
    setLeadstatusstatus(true);
    setLeadstatusstatuss(true);
    setdatepicker(0);
    setdatepicker2(0);
    setdatefilter(true);
  }, []);
  const onClearedAllFiltersClick2 = useCallback(() => {
    setDateFilter(dateRangeDefault);
    setLeadstatus(0);
    applyBookmark("Bookmark5b52d9aa7c75d4e88fa1");
    setLeasestatusbtn(false);
    setsalesstatusbtn(false);
    setsalesUnits(null);
    setsalesandrentUnits(null);
    setLeasestatus(0);
    setLeadstatusstatus(true);
    setLeadstatusstatuss(true);
    setdatepicker(0);
    setdatepicker2(0);
    setdatefilterhandover(true);
  }, []);
  const onClearedContactsDeveloper = useCallback(() => {
    setDateFilter(dateRangeDefault);
    setLeadstatus(0);
    applyBookmark("90f4ff3c36cc86470e4c");
    setLeasestatusbtn(false);
    setsalesstatusbtn(false);
    setsalesUnits(null);
    setsalesandrentUnits(null);
    setLeasestatus(0);
    setLeadstatusstatus(true);
    setLeadstatusstatuss(true);
    setdatepicker(0);
    setdatepicker2(0);
    setdatefilterhandover(true);
  }, []);
  const onClearedDevelopertransactions = useCallback(() => {
    setDateFilter(dateRangeDefault);
    setLeadstatus(0);
    applyBookmark("16f70b509390b384008d");
    setLeasestatusbtn(false);
    setsalesstatusbtn(false);
    setsalesUnits(null);
    setsalesandrentUnits(null);
    setLeasestatus(0);
    setLeadstatusstatus(true);
    setLeadstatusstatuss(true);
    setdatepicker(0);
    setdatepicker2(0);
    setdatefilterhandover(true);
  }, []);

  const columnToFilter = datefilters
    ? "UnitsCreatedOn"
    : isRentListing
    ? "Rent_Listing_Date"
    : "listing_date";
  const columnToFilterDeveloper = datefilterhandover
    ? "CreatedOn"
    : "handover_date";

  const advancedFilter = {
    $schema: "http://powerbi.com/product/schema#advanced",
    target: {
      table: "ListingUnitsViews",
      column: columnToFilter,
    },
    filterType: models.FilterType.AdvancedFilter,
    logicalOperator: "And",
    conditions: [
      {
        operator: "GreaterThanOrEqual",
        value: data.fromDate,
      },
      {
        operator: "LessThanOrEqual",
        value: data.toDate,
      },
    ],
  };
  const advancedPropertyDeveloperBi = {
    $schema: "http://powerbi.com/product/schema#advanced",
    target: {
      table: "PropertyDeveloperBi",
      column: columnToFilterDeveloper,
    },
    filterType: 0,
    logicalOperator: "And",
    conditions: [
      {
        operator: "GreaterThanOrEqual",
        value: data.fromDate,
      },
      {
        operator: "LessThanOrEqual",
        value: data.toDate,
      },
    ],
    filterType: models.FilterType.AdvancedFilter,
  };


  const advancedFilterTransaction = {
    $schema: "http://powerbi.com/product/schema#advanced",
    target: {
      table: "View_SalesTransactionsSellerBuyer",
      column: "TransactionDate",
    },
    filterType: 0,
    logicalOperator: "And",
    conditions: [
      {
        operator: "GreaterThanOrEqual",
        value: data.fromDate,
      },
      {
        operator: "LessThanOrEqual",
        value: data.toDate,
      },
    ],
    filterType: models.FilterType.AdvancedFilter,
  };
  const advancedContactsDeveloper = {
    $schema: "http://powerbi.com/product/schema#advanced",
    target: {
      table: "ContactsDeveloperBi",
      column: "ContactCreatedOn",
    },
    filterType: 0,
    logicalOperator: "And",
    conditions: [
      {
        operator: "GreaterThanOrEqual",
        value: data.fromDate,
      },
      {
        operator: "LessThanOrEqual",
        value: data.toDate,
      },
    ],
    filterType: models.FilterType.AdvancedFilter,
  };

  let selectedFilter;

  switch (true) {
    case Units:
      selectedFilter = advancedFilter;
      break;

    case transactions:
      selectedFilter = advancedPropertyDeveloperBi;
      break;

    case ContactsDeveloper:
      selectedFilter = advancedContactsDeveloper;
      break;
    case Developertransactions:
      selectedFilter = advancedFilterTransaction;
      break;

    default:
      selectedFilter = advancedPropertyDeveloperBi;
  }

  const FilterLeadStatus = {
    $schema: "http://powerbi.com/product/schema#basic",
    target: {
      table: "ListingUnitsViews",
      column: "Status",
    },
    operator: OperatorLeadstatus,
    values: [Leadstatus],
    filterType: models.FilterType.BasicFilter,
  };
  const FilterLeaseStatus = {
    $schema: "http://powerbi.com/product/schema#basic",
    target: {
      table: "ListingUnitsViews",
      column: "LeaseStatus",
    },
    operator: OperatorLeasestatus,
    values: [Leasestatus],
    filterType: models.FilterType.BasicFilter,
  };

  const advancedoprationsSales = {
    $schema: "http://powerbi.com/product/schema#advanced",
    target: {
      table: "ListingUnitsViews",
      column: "operation type",
    },
    filterType: 0,
    logicalOperator: "Or",
    conditions: [
      {
        operator: "Is",
        value: salesUnits || null,
      },
      {
        operator: "Is",
        value: salesandrentUnits || null,
      },
    ],
    filterType: models.FilterType.AdvancedFilter,
  };

  const Microsoft = async (isExpired) => {
    const res = await MicrosoftToken(isExpired);
    if (!(res && res.status && res.status !== 200)) {
      setReport(res);
      setRender(true);
    } else {
      setReport("");
      showError("Contact Your Bi Admin For Help");
    }
  };


  const [activeButton, setActiveButton] = useState("One");
  const [filterButton, setfilterButton] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const horiSelectorRef = useRef(null);
  const navbarRef = useRef(null);
  const [activeGroupButton, setactiveGroupButton] = useState(true);
  const [bookmarks, setBookmarks] = useState([]);
  const [error, setError] = useState(null);
  const [Page, setpage] = useState();

  
  const loadBookmarks = async () => {
    const report = window.report;
    let pages = await report.getPages();
    if (report) {
      try {
        const bookmarksManager = report.bookmarksManager;
        const fetchedBookmarks = await bookmarksManager.getBookmarks();
        setBookmarks(fetchedBookmarks);
        console.log(fetchedBookmarks);
        console.log(pages);
      } catch (error) {
  
        setError(error);
      }
    }
  };
  const applyBookmark = async (bookmarkName) => {
    const report = window.report;
    if (report) {
      try {
        const bookmarksManager = report.bookmarksManager;
        bookmarksManager.apply(bookmarkName);
        // console.log("Bookmark applied successfully:", bookmarkName);
      } catch (error) {
 
        setError(error);
      }
    }
  };

  const handleButtonClick = (buttonNameclick) => {
    setActiveButton(buttonNameclick);
  };


  const ChartView = () => {
    applyBookmark("Bookmarka9be2daf931bca836ae8");
    handleButtonClick("One");
    setShowReport2(false);
  };
  const TableView = () => {
    applyBookmark("Bookmarkeeaecacfb94c240f6c47");
    handleButtonClick("Two");
    setShowReport2(false);
  };

  const FilterHide = () => {
    applyBookmark("Bookmarke8bdfc92671bb77c202b");
    setfilterButton(false);
  };
  const FilterView = () => {
    applyBookmark("Bookmark4a47b9d51da010b8d401");
    setfilterButton(true);
  };

  const [MenuButton, setMenuButton] = useState("One");

  const handleMenuButtonClick = (buttonName) => {
    setMenuButton(buttonName);
  };

  const GeneralPage = () => {
    handleMenuButtonClick("One");

    Pagenavi("ReportSection6eaf615e56ee0fe885da");
  };
  const TransactionPage = () => {
    handleMenuButtonClick("Two");
    Pagenavi("ReportSectionfabb05623e665c4320c0");
  };
  const DeveloperInfo = () => {
    handleMenuButtonClick("Three");
    Pagenavi("717fb00f799d00a15356");
  };
  const Transactions = () => {
    handleMenuButtonClick("Four");
    Pagenavi("d7abdb3416d8383ce787");
  };

  const Pagenavi = async (pagestate) => {
    const report = window.report;

    if (report) {
      try {
        report.setPage(pagestate);
      } catch (error) {
        // console.error("Error applying:", error);
      }
    }
  };

  const handleRefreshClick = useCallback(() => {
    window.report
      .refresh()
      .then(() => {
        setShowReport2(false);

      })
      .catch((error) => {
  
      });
  }, []);


  useEffect(() => {
    const updateSelector = () => {
      if (navbarRef.current && horiSelectorRef.current) {
        const tabs = navbarRef.current.querySelectorAll("li");
        if (tabs.length > 0) {
          const activeItem = tabs[activeIndex];
          const activeWidth = activeItem.offsetWidth;
          const itemPosLeft = activeItem.offsetLeft;
          horiSelectorRef.current.style.left = `${itemPosLeft}px`;
          horiSelectorRef.current.style.width = `${activeWidth}px`;
        }
      }
    };

    updateSelector();
  }, [activeIndex]);

  const handleItemClick = (index) => {
    setActiveIndex(index);
  };

  useEffect(() => {
    Microsoft(false);
  }, []);
  useEffect(() => {
    if (Leadstatusstatus) setOperatorLeadstatus("All");
    else setOperatorLeadstatus("In");
    if (Leasestatuss) setOperatorLeasestatus("All");
    else setOperatorLeasestatus("In");

  }, [Leadstatusstatus, Leasestatuss]);

  return (
    <div className="dashboardMain-PowerBIEmbed">
      <CellSpinnerBi isActive={!ShowReport} isAbsolute blue />
      <div>
        <div id="navbar-animmenu" ref={navbarRef}>
          <ul className="show-dropdown main-navbar">
            <div className="hori-selector" ref={horiSelectorRef}>
              <div className="left"></div>
              <div className="right"></div>
            </div>
            <li
              className={activeIndex === 0 ? "active" : ""}
              onClick={() => {
                handleItemClick(0);
                GeneralPage();
              }}
            >
              <a>
                <i className={`mdi mdi-account-details-outline mdi-24px `}></i>
                {t(`${translationPath}developer-menu`)}
              </a>
            </li>
            <li
              className={activeIndex === 1 ? "active" : ""}
              onClick={() => {
                handleItemClick(1);
                TransactionPage();
              }}
            >
              <a>
                <i className={`mdi mdi-book-account-outline mdi-24px `}></i>
                {t(`${translationPath}listingUnits-menu`)}
              </a>
            </li>
            <li
              className={activeIndex === 2 ? "active" : ""}
              onClick={() => {
                handleItemClick(2);
                DeveloperInfo()
              }}
            >
              <a>
                <i className={`mdi mdi-help-circle mdi-24px `}></i>
                {t(`${translationPath}DeveloperInfo-menu`)}
         
              </a>
            </li>
            <li
              className={activeIndex === 3 ? "active" : ""}
              onClick={() => {
                handleItemClick(3);
                Transactions()
              }}
            >
              <a>
                <i className={`mdi mdi-book-account-outline mdi-24px `}></i>
                {t(`${translationPath}Developer-Transactions-menu`)}
         
              </a>
            </li>
          </ul>
        </div>
      </div>
      <br />
      {DateAppaer && (
        <div>
          <div className="MainFiltersBi">
            <CellSpinnerBi isActive={!ShowReport2} isAbsolute blue />
            {/* {Units && (
              <div className="selecterbi">
                <div>
                  <SelectComponet
                    data={[
                      { id: 1, Leadstatus: "Available" },
                      { id: 10, Leadstatus: "Draft" },
                      { id: 13, Leadstatus: "Pending Reserve Sale" },
                      { id: 14, Leadstatus: "Pending Sale" },
                      { id: 5, Leadstatus: "Reserved Sale" },
                      { id: 6, Leadstatus: "Sale" },
                    ]}
                    emptyItem={{
                      value: 0,
                      text: "Sales Status",
                      isDisabled: false,
                    }}
                    value={Leadstatus || 0}
                    defaultValue={0}
                    valueInput="id"
                    isDisabled={Leasestatusbtn == true}
                    textInput="Leadstatus"
                    onSelectChanged={(value) => {
                      switch (value) {
                        case 0:
                          setLeadstatusstatus(true);
                          setsalesstatusbtn(false);
                          setLeadstatus(0);
                          break;
                        default:
                          setLeadstatus(value);
                          setLeadstatusstatus(false);
                          setsalesstatusbtn(true);
                          setShowReport2(false);
                          break;
                      }
                    }}
                    wrapperClasses="w-auto"
                    themeClass="theme-transparent"
                    idRef="Lead_Status"
                    parentTranslationPath={parentTranslationPath}
                    translationPath={translationPath}
                    translationPathForData={translationPath}
                  />
                </div>

                <div>
                  <SelectComponet
                    data={[
                      { id: 1, Leasestatus: "Available" },
                      { id: 10, Leasestatus: "Draft" },
                      { id: 3, Leasestatus: "Leased" },
                      { id: 12, Leasestatus: "Pending Lease" },
                      { id: 11, Leasestatus: "Pending Reserve Lease" },
                      { id: 2, Leasestatus: "Reserved Leased" },
                    ]}
                    emptyItem={{
                      value: 0,
                      text: "Lease Status",
                      isDisabled: false,
                    }}
                    value={Leasestatus || 0}
                    defaultValue={0}
                    isDisabled={salesstatusbtn == true}
                    valueInput="id"
                    textInput="Leasestatus"
                    onSelectChanged={(value) => {
                      switch (value) {
                        case 0:
                          setLeadstatusstatuss(true);
                          setLeasestatusbtn(false);
                          setLeasestatus(0);
                          break;
                        default:
                          setLeasestatus(value);
                          setLeadstatusstatuss(false);
                          setLeasestatusbtn(true);
                          setShowReport2(false);
                          break;
                      }
                    }}
                    wrapperClasses="w-auto"
                    themeClass="theme-transparent"
                    idRef="Lead_Status"
                    parentTranslationPath={parentTranslationPath}
                    translationPath={translationPath}
                    translationPathForData={translationPath}
                  />
                </div>
              </div>
      
                  )} */}
            <div className="selecterbi">
              {Units && (
                <div className="selecterbi">
                  <div>
                    <SelectComponet
                      data={[
                        {
                          id: 1,
                          datepicker: "Sales Listing Date",
                        },
                        {
                          id: 2,
                          datepicker: "Lease Listing Date",
                        },
                      ]}
                      emptyItem={{
                        value: 0,
                        text: `${translationPath}created-date`,
                        isDisabled: false,
                      }}
                      value={datepicker || 0}
                      defaultValue={0}
                      valueInput="id"
                      textInput="datepicker"
                      onSelectChanged={(value) => {
                        switch (value) {
                          case 0:
                            setdatepicker(0);
                            setdatefilter(true);
                            setisRentListing(false);
                            setsalesUnits(null);
                            setsalesandrentUnits(null);                        
                            setShowReport2(false);
                            setLeasestatusbtn(false);
                            setsalesstatusbtn(false);
                            break;
                          case 1:
                            setdatepicker(1);                          
                            setisRentListing(false);
                            setsalesUnits(430);
                            setsalesandrentUnits(20984);
                            setdatefilter(false);
                            setShowReport2(false);
                            setLeasestatusbtn(false);
                            setsalesstatusbtn(true);
                            break;
                          case 2:
                            setdatepicker(2);
                            setsalesUnits(431);
                            setsalesandrentUnits(20984);
                            setisRentListing(true);
                            setdatefilter(false);
                            setShowReport2(false);
                            setsalesstatusbtn(false);
                            setLeasestatusbtn(true);

                            break;
                        }
                      }}
                      themeClass="theme-transparent"
                      wrapperClasses="w-auto"
                      idRef="Date_Select"
                      parentTranslationPath={parentTranslationPath}
                      translationPath={translationPath}
                      translationPathForData={translationPath}
                    />
                  </div>

          
                </div>
             )}

             {transactions && (
              <div className="selecterbi">
              <div>
                <SelectComponet
                  data={[
                    {
                      id: 1,
                      datepicker2: "Hand Over Date",
                    },
                  ]}
                  emptyItem={{
                    value: 0,
                    text: `${translationPath}created-date`,
                    isDisabled: false,
                  }}
                  value={datepicker2 || 0}
                  defaultValue={0}
                  valueInput="id"
                  textInput="datepicker2"
                  onSelectChanged={(value) => {
                    switch (value) {
                      case 0:
                        setdatepicker2(0);
                        setdatefilterhandover(true);                                      
                        setShowReport2(false);
                        break;
                      case 1:
                        setdatepicker2(1);                          
                        setdatefilterhandover(false);      
                        setShowReport2(false);
                 
                        break;
                    }
                  }}
                  themeClass="theme-transparent"
                  wrapperClasses="w-auto"
                  idRef="Date_Select"
                  parentTranslationPath={parentTranslationPath}
                  translationPath={translationPath}
                  translationPathForData={translationPath}
                />
              </div>
     
              </div>
             )}

              <div className="selecterbi">
                  <div>
                <DateRangePickerComponent
                  onClearClicked={() => {
                    setDateFilter({
                      startDate: startDate,
                      endDate: endDate,
                      key: "selection",
                    });
                  }}
                  ranges={[dateFilter]}
                  onDateChanged={(selectedDate) => {
                    const newStartDate =
                      selectedDate.selection &&
                      selectedDate.selection.startDate;
                    const newEndDate = new Date(
                      moment(
                        selectedDate.selection &&
                          selectedDate.selection.endDate
                      ).endOf("day")
                    );

                    if (
                      newStartDate.getTime() !==
                        dateFilter.startDate.getTime() ||
                      newEndDate.getTime() !== dateFilter.endDate.getTime()
                    ) {
                      setShowReport2(false);
                    } else {
                      setShowReport2(true);
                    }

                    setDateFilter({
                      startDate: newStartDate,
                      endDate: newEndDate,
                      key: "selection",
                    });
                  }}
                />
              </div>
                </div>


           
            </div>

            <div className="selecterbi">
            {/* {!Units ? (
            <div>
              {!filterButton ? (
                <div className="DashboardFilter">
                  <ButtonBase
                    variant="contained"
                    className="btns theme-solid bg-primary-dark Dashboardbtn"
                    style={{ color: "white" }}
                    onClick={FilterView}
                                 >
                    <span className="mdi mdi-filter-menu m-1" />
                  </ButtonBase>
                </div>
              ) : (
                <div className="DashboardFilter">
                  <ButtonBase
                    variant="contained"
                    className="btns theme-solid bg-danger Dashboardbtn"
                    style={{ color: "white" }}
                    onClick={FilterHide}
                  >
                    <span className="mdi mdi-filter-minus-outline m-1" />
                  </ButtonBase>
                </div>
              )}
              </div>
            ):(<div></div>)} */}
         
              {Units && (
                
                <div className="DashboardFilter">
                  <ButtonBase
                    onClick={onClearedAllFiltersClick}
                    id="onClearedAllFiltersref"
                    className="btns theme-solid bg-primary-dark clear-all-btn Dashboardbtn"
                  >
                    <span className="mdi mdi-filter-remove m-1" />
                  </ButtonBase>
                </div>
              )} 

              {transactions && (
                <div className="DashboardFilter">
                  <ButtonBase
                    onClick={onClearedAllFiltersClick2}
                    id="onClearedAllFiltersref"
                    className="btns theme-solid bg-primary-dark clear-all-btn Dashboardbtn"
                  >
                    <span className="mdi mdi-filter-remove m-1" />
                  </ButtonBase>
                </div>
              )}

              {ContactsDeveloper && (
                <div className="DashboardFilter">
                  <ButtonBase
                    onClick={onClearedContactsDeveloper}
                    id="onClearedAllFiltersref"
                    className="btns theme-solid bg-primary-dark clear-all-btn Dashboardbtn"
                  >
                    <span className="mdi mdi-filter-remove m-1" />
                  </ButtonBase>
                </div>
              )}

              {Developertransactions && (
                <div className="DashboardFilter">
                  <ButtonBase
                    onClick={onClearedDevelopertransactions}
                    id="onClearedAllFiltersref"
                    className="btns theme-solid bg-primary-dark clear-all-btn Dashboardbtn"
                  >
                    <span className="mdi mdi-filter-remove m-1" />
                  </ButtonBase>
                </div>
              )}

              <div className="DashboardFilter">
                <ButtonBase
                  onClick={handleRefreshClick}
                  id="onClearedAllFiltersref"
                  className="btns theme-solid bg-primary-dark clear-all-btn Dashboardbtn"
                >
                  <span className="mdi mdi-refresh m-1" />
                </ButtonBase>
              </div>
            </div>
          </div>
        </div>
      )}

      <br />
      <br />

      <div className="mainCardDashboards">
        {render && (
          <div className="dashboardMain cardDashboard">
            <PowerBIEmbed
              embedConfig={{
                type: "report",
                id,
                embedUrl,
                accessToken: report,
                filters: [selectedFilter, FilterLeadStatus, FilterLeaseStatus, advancedoprationsSales ],
                settings: {
                  customLayout: {
                    displayOption: models.DisplayOption.FitToWidth,
                  },
                  filterPaneEnabled: false,
                  navContentPaneEnabled: false,
                  panes: {
                    filters: {
                      expanded: false,
                      visible: false,
                    },
                  },
                  background: models.BackgroundType.Transparent,
                },
              }}
              eventHandlers={
                new Map([
                  [
                    "loaded",
                    (event) => {
                      setShowReport(true);
                      setDateAppaer(true);
                    },
                  ],
                  [
                    "dataSelected",

                    (event) => {
                      setShowReport2(false);
                    },
                  ],

                  [
                    "rendered",
                    (event) => {
                      setShowReport2(true);
                      loadBookmarks();
                      setRender(true);
                    },
                  ],

                  [
                    "pageChanged",
                    (event) => {
                      const newPageName = event.detail.newPage.displayName;

                      switch (newPageName) {
                        case "Listing Units":
                          ClearAllFilters();
                  
                          settransactions(false);
                          setDevelopertransactions(false)
                          setContactsDeveloper(false)
                          setactiveGroupButton(false);
                          setStatusFilter(true);
                          setUnits(true);                        
                          setShowReport2(false);
                          setTimeout(() => {
                            scrollToTop();
                          }, 5);

                          break;
                        case "Developer Info":
                          ClearAllFilters();
                          settransactions(false);
                          setDevelopertransactions(false)
                          setContactsDeveloper(true)
                          setactiveGroupButton(true);
                          setStatusFilter(false);
                          setUnits(false);
                
                          setShowReport2(false);                   
                          setTimeout(() => {
                            scrollToTop();
                          }, 5);

                          break;
                        case "Transactions":
                          ClearAllFilters();
                          settransactions(false);
                          setDevelopertransactions(true)
                          setContactsDeveloper(false)
                          setactiveGroupButton(true);
                          setStatusFilter(false);
                          setUnits(false);
                   
                          setShowReport2(false);                   
                          setTimeout(() => {
                            scrollToTop();
                          }, 5);

                          break;

                        default:             
                        ClearAllFilters();
                          settransactions(true);
                          setDevelopertransactions(false)
                          setContactsDeveloper(false)
                          setactiveGroupButton(true);
                          setStatusFilter(false);
                          setUnits(false);
                     
                          setShowReport2(false);                   
                          setTimeout(() => {
                            scrollToTop();
                          }, 5);
                          break;
                      }
                    },
                  ],

                  [
                    "error",
                    (event) => {
                      const error = event.detail;
                      if (
                        error.detailedMessage === "Get report failed" ||
                        error.detailedMessage ===
                          "Access token has expired, resubmit with a new access token" ||
                        error.detailedMessage ===
                          "Fail to initialize - Could not resolve cluster"
                      ) {
                        Microsoft(true);
                        setRender(false);
                      }
                    },
                  ],
                ])
              }
              cssClassName={
                ShowReport
                  ? "report-style-class-Show"
                  : "report-style-class-hide"
              }
              getEmbeddedComponent={(embeddedReport) => {
                window.report = embeddedReport;
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
};
