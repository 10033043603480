import React from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as XCloseIcon } from "../../../../assets/images/icons/x-close-dialog.svg";
import { DialogActions, DialogContent, ButtonBase } from "@material-ui/core";
import { DialogComponent } from "../../../../Components";

export const ConfirmChangesDialog = ({
  parentTranslationPath,
  translationPath,
  isDialogOpen,
  onSave,
  onClose,
}) => {
  const { t } = useTranslation(parentTranslationPath);

  return (
    <div className="">
      <DialogComponent
        isOpen={isDialogOpen}
        maxWidth="xs"
        dialogTitle={
            <div className="d-flex-cloumn w-100">
            <div className="">
              <div className="d-flex-v-center-h-between">
                <div className="fw-simi-bold">
                    <span className="mdi mdi-content-save-settings"/>
                </div>
                <div className="xCloseIcon pointer" onClick={onClose}>
                  <XCloseIcon />
                </div>
              </div>
            </div>
        </div>
          }
        dialogContent={
          <div className="">
            <DialogContent style={{'padding' : '0rem' }}>
              <>
              <div className="px-2 d-flex-v-center-h-between">
                <div className="fw-bold">
                    <h2 className="fw-bold">
                    {t(`${translationPath}confirm-submit-change`)}
                    </h2>
                </div>
              </div>
                <div className="px-2 d-flex-column-center">
                  <div className="w-100  pt-1 px-4">
                  {t(`${translationPath}you-have-made-changes-that-will-update-for-all-properties-and-units-submitting-this-changes-can-take-up-(24hours)-to-fully-take-effect`)}  
                  </div>
                </div>
              </>
            </DialogContent>
            <DialogActions>
              <div className="d-flex-center fj-center py-0 pt-3">
                <ButtonBase
                  onClick={() => onClose()}
                  className="w-50 btns theme-propx outlined"
                  id="btn-cancel"
                  fullWidth={true}
                >
                  {t(`${translationPath}discard`)}
                </ButtonBase>
                <ButtonBase
                  className="w-50 btns theme-propx solid mr-0"
                  onClick={() => onSave()}
                  fullWidth={true}
                >
                  {t(`${translationPath}save-changes`)}
                </ButtonBase>
              </div>
            </DialogActions>
          </div>
        }
      />
    </div>
  );
};
