import { PowerBiEnum } from "../../../../Enums";
import { MainDashboardExternal } from "../MainDashboardExternal/MainDashboardExternal";
import { ExternalDashboardPermissions } from '../../../../Permissions' ; 
import { DashboardListingDubai } from "../DashboardListingDubai/DashboardListingDubai";

export const ExternalDashboardTabs = [
  {
    index: 0,
    label: "Dashboard:tab-1",
    component: MainDashboardExternal,
    config: {
      reportId: PowerBiEnum.tab_1.reportid,
      groupId: PowerBiEnum.tab_1.groupid,
      Url: PowerBiEnum.tab_1.url,
    },
    permissionsId : ExternalDashboardPermissions.Tab1.permissionsId , 
  },
  {
    index: 1,
    label: "Dashboard:tab-2",
    component: DashboardListingDubai,
  
    permissionsId : ExternalDashboardPermissions.Tab2.permissionsId , 
  },
  {
    index: 2,
    label: "Dashboard:tab-3",
    component: MainDashboardExternal,
    config: {
      reportId: PowerBiEnum.tab_3.reportid,
      groupId: PowerBiEnum.tab_3.groupid,
      Url: PowerBiEnum.tab_3.url,
    },
    permissionsId : ExternalDashboardPermissions.Tab3.permissionsId , 
  },
  {
    index: 3,
    label: "Dashboard:tab-4",
      component: MainDashboardExternal,
    config: {
      reportId: PowerBiEnum.tab_4.reportid,
      groupId: PowerBiEnum.tab_4.groupid,
      Url: PowerBiEnum.tab_4.url,
    },
    permissionsId : ExternalDashboardPermissions.Tab4.permissionsId , 
  },
  {
    index: 4,
    label: "Dashboard:tab-5",
      component: MainDashboardExternal,
    config: {
      reportId: PowerBiEnum.tab_5.reportid,
      groupId: PowerBiEnum.tab_5.groupid,
      Url: PowerBiEnum.tab_5.url,
    },
    permissionsId : ExternalDashboardPermissions.Tab5.permissionsId , 
  },
  {
    index: 5,
    label: "Dashboard:tab-6",
      component: MainDashboardExternal,
    config: {
      reportId: PowerBiEnum.tab_6.reportid,
      groupId: PowerBiEnum.tab_6.groupid,
      Url: PowerBiEnum.tab_6.url,
    },
    permissionsId : ExternalDashboardPermissions.Tab6.permissionsId , 
  },
  {
    index: 6,
    label: "Dashboard:tab-7",
      component: MainDashboardExternal,
    config: {
      reportId: PowerBiEnum.tab_7.reportid,
      groupId: PowerBiEnum.tab_7.groupid,
      Url: PowerBiEnum.tab_7.url,
    },
    permissionsId : ExternalDashboardPermissions.Tab7.permissionsId, 
  },
  {
    index: 7,
    label: "Dashboard:tab-8",
      component: MainDashboardExternal,
    config: {
      reportId: PowerBiEnum.tab_8.reportid,
      groupId: PowerBiEnum.tab_8.groupid,
      Url: PowerBiEnum.tab_8.url,
    },
    permissionsId : ExternalDashboardPermissions.Tab8.permissionsId , 
  },
  {
    index: 8,
    label: "Dashboard:tab-9",
      component: MainDashboardExternal,
    config: {
      reportId: PowerBiEnum.tab_9.reportid,
      groupId: PowerBiEnum.tab_9.groupid,
      Url: PowerBiEnum.tab_9.url,
    },
    permissionsId : ExternalDashboardPermissions.Tab9.permissionsId , 
  },
  {
    index: 9,
    label: "Dashboard:tab-10",
      component: MainDashboardExternal,
    config: {
      reportId: PowerBiEnum.tab_10.reportid,
      groupId: PowerBiEnum.tab_10.groupid,
      Url: PowerBiEnum.tab_10.url,
    },
    permissionsId : ExternalDashboardPermissions.Tab10.permissionsId , 
  },
];
