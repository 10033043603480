import React from 'react'
import { LoadableImageEnum } from '../../../Enums';
import { LoadableImageComponant } from '../../../Components';
import { DefaultImagesEnum } from '../../../Enums';
import { useTranslation } from 'react-i18next';
import { useDispatch , useSelector  } from 'react-redux';
import { GlobalOrderFilterActions  } from '../../../store/GlobalOrderFilter/GlobalOrderFilterActions';

import PropTypes from 'prop-types';
import { getDownloadableLink } from '../../../Helper'

export const LeadAuditorComponent = ({
    userLeadAuditor,
    parentTranslationPath,
    translationPath,
}) => {
    const { t } = useTranslation(parentTranslationPath);
    
    const dispatch = useDispatch();
    const orderFilter = useSelector((state) => state.GlobalOrderFilterReducer);

    return (
        <div className='Lead-auditor-scroll-wrapper'>
            <div className='Lead-auditor-wrapper'>
                <div className='Lead-auditor-title'>
                    <span>{t(`${translationPath}lead-owners`)} </span>
                    <span>{userLeadAuditor && '1' || '0'}</span>
                </div>

                {userLeadAuditor && (
                    <div className={`lead-auditor-cards-wrapper`}>
                        <div className='w-100 d-flex'>
                            <LoadableImageComponant
                                type={LoadableImageEnum.image.key}
                                classes='lead-auditor-image'
                                alt={'user-image'}
                                src={userLeadAuditor.profileImg && getDownloadableLink(userLeadAuditor.profileImg) || DefaultImagesEnum.man.defaultImg}
                            />
                            <div className='w-100'>
                                <div className='title-wrapper'>
                                    <span>{userLeadAuditor.leadAuditorName || 'N/A'}</span>
                                    {/* <span>{t(`${translationPath}supervisor`)}</span> */}
                                </div>
                            </div>

                        </div>
                        <div className='leads-row'>
                            <div className='leads-numbers'>
                                {<span className='hot lead-number pointer'  
                                  onClick={()=> 
                                     {
                                        if(userLeadAuditor && userLeadAuditor.numberHotLead)
                                        {
                                            const searchFilter =  [{key:"rating",title:"Rating",value:"hot"}] ; 
                                            localStorage.setItem('leadsLeadOwnerFilter', JSON.stringify(searchFilter));
                                            dispatch(
                                              GlobalOrderFilterActions.globalOrderFilterRequest({
                                                ...orderFilter,
                                                StatusTypeLeadTypeLeadOwnerFilter: null , 
                                              })
                                            );
                                            window.open(`/home/lead-owner-lead/view`, '_blank'); 
                                        }
                                     }}
                                   >
                                 {(userLeadAuditor && userLeadAuditor.numberHotLead) || '0'}</span>}
                                {
                                 <span className='warm lead-number pointer'
                                     onClick={()=> 
                                      {
                                        if(userLeadAuditor && userLeadAuditor.numberWarmLead)
                                        {
                                        const searchFilter =  [{key:"rating",title:"Rating",value:"warm"}] ; 
                                        localStorage.setItem('leadsLeadOwnerFilter', JSON.stringify(searchFilter));
                                        dispatch(
                                          GlobalOrderFilterActions.globalOrderFilterRequest({
                                            ...orderFilter,
                                            StatusTypeLeadTypeLeadOwnerFilter: null , 
                                          })
                                        );
                                        window.open(`/home/lead-owner-lead/view`, '_blank'); 
                                        }
                                      }}
                                   > {(userLeadAuditor && userLeadAuditor.numberWarmLead) || '0'}
                                </span>}
                                {<span className='cold lead-number pointer'
                                   onClick={()=> 
                                    {
                                        if(userLeadAuditor && userLeadAuditor.numberColdLead)
                                        {
                                          const searchFilter =  [{key:"rating",title:"Rating",value:"cold"}] ; 
                                          localStorage.setItem('leadsLeadOwnerFilter', JSON.stringify(searchFilter));
                                          dispatch(
                                            GlobalOrderFilterActions.globalOrderFilterRequest({
                                              ...orderFilter,
                                              StatusTypeLeadTypeLeadOwnerFilter: null , 
                                            })
                                          );
                                          window.open(`/home/lead-owner-lead/view`, '_blank'); 
                                        }
                                    }}
                                  >
                                { (userLeadAuditor && userLeadAuditor.numberColdLead) || '0'}</span>}

                                {<span className='hot lead-number pointer'
                                   onClick={()=> 
                                      {
                                        if(userLeadAuditor && userLeadAuditor.percentageOfHotLead)
                                        {
                                           const searchFilter =  [{key:"rating",title:"Rating",value:"hot"}] ; 
                                           localStorage.setItem('leadsLeadOwnerFilter', JSON.stringify(searchFilter));
                                           dispatch(
                                            GlobalOrderFilterActions.globalOrderFilterRequest({
                                              ...orderFilter,
                                              StatusTypeLeadTypeLeadOwnerFilter: null , 
                                            })
                                          );
                                           window.open(`/home/lead-owner-lead/view`, '_blank');
                                        }
                                     }}

                                >{userLeadAuditor.percentageOfHotLead && `${userLeadAuditor.percentageOfHotLead}%` || '0'}</span>}
                                {<span className='warm lead-number pointer'
                                   onClick={()=> 
                                     {
                                        if(userLeadAuditor && userLeadAuditor.percentageOfWarmLead)
                                        {
                                          const searchFilter =  [{key:"rating",title:"Rating",value:"warm"}] ; 
                                          localStorage.setItem('leadsLeadOwnerFilter', JSON.stringify(searchFilter));
                                          dispatch(
                                            GlobalOrderFilterActions.globalOrderFilterRequest({
                                              ...orderFilter,
                                              StatusTypeLeadTypeLeadOwnerFilter: null , 
                                            })
                                          );
                                          window.open(`/home/lead-owner-lead/view`, '_blank');  
                                        }
                                     }}

                                >{userLeadAuditor.percentageOfWarmLead && `${userLeadAuditor.percentageOfWarmLead}%` || '0'}</span>}
                                {<span className='cold lead-number pointer'
                                  onClick={()=> 
                                    {
                                        if(userLeadAuditor && userLeadAuditor.percentageOfColdLead)
                                        {
                                          const searchFilter =  [{key:"rating",title:"Rating",value:"cold"}] ; 
                                          localStorage.setItem('leadsLeadOwnerFilter', JSON.stringify(searchFilter));
                                          dispatch(
                                            GlobalOrderFilterActions.globalOrderFilterRequest({
                                              ...orderFilter,
                                              StatusTypeLeadTypeLeadOwnerFilter: null , 
                                            })
                                          );
                                          window.open(`/home/lead-owner-lead/view`, '_blank');
                                        }                                  
                                    }}
                                 >{userLeadAuditor.percentageOfColdLead && `${userLeadAuditor.percentageOfColdLead}%` || '0'}</span>
                                }
                            </div>
                        </div>
                        <div className='agent-row d-flex'>
                            <div>
                                <span>{t(`${translationPath}agent`)}</span>
                                <span>{userLeadAuditor.numberOfAgentAssigned || '0'}</span></div>
                            <div> 
                                <span>{t(`${translationPath}cap`)}</span>
                                <span className={`${userLeadAuditor.leadAuditorCapacity < userLeadAuditor.numberOfAgentAssigned ? 'red-lead-cap' : 'green-lead-cap'}`} >{userLeadAuditor.leadAuditorCapacity || '0'}</span></div>
                            <div className='pointer' onClick={()=> 
                                {
                                    if(userLeadAuditor  && userLeadAuditor.numberOfOpenLead)
                                    {
                                      dispatch(
                                        GlobalOrderFilterActions.globalOrderFilterRequest({
                                          ...orderFilter,
                                          StatusTypeLeadTypeLeadOwnerFilter: 'Open'
                                        })
                                       );
                                      localStorage.removeItem('leadsLeadOwnerFilter'); 
                                      window.open(`/home/lead-owner-lead/view`, '_blank');  
                                    } 
                                }}
                             >
                                <span>{t(`${translationPath}active`)}</span>
                                <span>{userLeadAuditor.numberOfOpenLead || '0'}</span>
                            </div>
                            <div className='pointer' onClick={()=> 
                                {
                                    if(userLeadAuditor  && userLeadAuditor.numberOfClosedLead)
                                    {
                                      dispatch(
                                        GlobalOrderFilterActions.globalOrderFilterRequest({
                                          ...orderFilter,
                                          StatusTypeLeadTypeLeadOwnerFilter: 'Closed'
                                        })
                                      );
                                      localStorage.removeItem('leadsLeadOwnerFilter');
                                      window.open(`/home/lead-owner-lead/view`, '_blank');
                                    } 
                                }}
                             >
                                <span>{t(`${translationPath}inactive`)}</span>
                                <span>{userLeadAuditor.numberOfClosedLead || '0'}</span>
                            </div>

                        </div>
                    </div>
                ) || null}
            </div>
        </div>
    )
}
LeadAuditorComponent.propTypes = {
    userLeadAuditor: PropTypes.instanceOf(Object).isRequired,
    parentTranslationPath: PropTypes.string.isRequired,
    translationPath: PropTypes.string.isRequired,
};
