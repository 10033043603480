import React from 'react';
import './PropertySummaryMPIComponent.scss';
import {
  SingleBed,
  BathtubOutlined,
  PowerInputOutlined,
} from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import { Spinner } from '../../../../../../Components';

export const PropertySummaryMPIComponent = ({
  summaryData,
  translationPath,
  parentTranslationPath,
  isLoading,
}) => {
  const { t } = useTranslation(parentTranslationPath);
  const formatNumber = new Intl.NumberFormat('eu-US', {
    maximumFractionDigits: 0,
  });
  return (
    <div className='custom-table'>
      <Spinner isActive={isLoading} isAbsolute />
      {summaryData?.map((item) => (
        <div className='table-item'>
          <div className='left-side-table'>
            {' '}
            <div className='unit-type'>
              <span>{t(`${translationPath}unit-type`)}</span>
              <h5>{item?.unitType || ''} </h5>
            </div>
            <div className='num-ads'>
              <span>{t(`${translationPath}number-of-ads`)}</span>
              <h5>{item?.numberOfAds || ''}</h5>
            </div>
            <div className='details-container'>
              <div className='sub-details-container'>
                <SingleBed fontSize='small' />
                <h5>{item?.numberOfBedrooms || ''}</h5>
                <h5>{t(`${translationPath}bedrooms`)}</h5>
              </div>
              <div className='sub-details-container'>
                <BathtubOutlined fontSize='small' />
                <h5>{item?.numberOfBathrooms || ''}</h5>
                <h5>{t(`${translationPath}bathrooms`)}</h5>
              </div>
              <div className='sub-details-container'>
                <PowerInputOutlined fontSize='small' />
                <h5>{+item?.avgSize.toFixed(0) || ''}</h5>
                <h5>{t(`${translationPath}average-size`)}</h5>
              </div>
            </div>
          </div>
          <div className='right-side-table'>
            <div className='price-info'>
              <span>{t(`${translationPath}average-market-price`)}</span>
              <h5>{formatNumber.format(item?.avgMarketPrice) || ''}</h5>
              <p>AED</p>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};
