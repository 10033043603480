import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import genric from './genric/Reducer';
import login from './login/Reducer';
import forms from './forms/Reducer';
import formbuilder from './formbuilder';
import contacts from './contacts/Reducer';
import leads from './lead/Reducer';
import properties from './property/Reducer';
import units from './unit/Reducer';
import files from './file/Reducer';
import lookups from './lookups/Reducer';
import layout from './layout/Reducer';
import theme from './theme/Reducer';

import { LoggerReducer } from './Logger/LoggerReducer';
import { ActiveItemReducer } from './ActiveItem/ActiveItemReducer';
import { ContactsMergeReducer } from './ContactsMerge/Reducer';
import { MarketingCampaignsReducer } from './MarketingCampaigns/Reducer';
import { TableColumnsFilterReducer } from './TableColumnsFilter/TableColumnsFilterReducer';
import { GlobalOrderFilterReducer } from './GlobalOrderFilter/GlobalOrderFilterReducer';
import { GlobalAdvanceSearchReducer } from './GlobalAdvanceSearch/GlobalAdvanceSearchReducer';

const themePersistConfig = {
  key: 'root',
  storage: storage,
  whitelist: ['theme', 'layout'],
};


const rootReducer = combineReducers({
  genric,
  login,
  forms,
  formbuilder,
  contacts,
  leads,
  properties,
  units,
  files,
  lookups,
  LoggerReducer,
  ActiveItemReducer,
  TableColumnsFilterReducer,
  GlobalOrderFilterReducer,
  GlobalAdvanceSearchReducer,
  ContactsMergeReducer,
  MarketingCampaignsReducer,
  layout,
  theme,
});

const persistedReducer = persistReducer(themePersistConfig, rootReducer);

export default persistedReducer;
