import React from 'react'

export const TransactionDataMap = {
    TransactionId: {
      label: "TransactionId",
      type: "basic-text",
      key: "transactionId",
    },
    TransactionDate: {
      label: "TransactionDate",
      type: "date",
      key: "transactionDate",
    },
    TransactingAgent: {
      label: "TransactingAgent",
      type: "basic-text",
      key: "transactingAgent",
    },
    IssuerName: {
      label: "IssuerName",
      type: "basic-text",
      key: "issuerName",
    },
    Agent: {
      label: "Agent",
      type: "sub-content",
      subContent: [
        {
          label: "Tenant",
          type: "basic-text",
          key: "tenantAgent",
        },
        {
          label: "Landlord",
          type: "basic-text",
          key: "landlordAgent",
        },
      ],
    },
    Name: {
      label: "Name",
      type: "sub-content",
      subContent: [
        {
          label: "Tenant",
          type: "basic-text",
          key: "tenantName",
        },
        {
          label: "Landlord",
          type: "basic-text",
          key: "landlordName",
        },
      ],
    },
    Nationality: {
      label: "Nationality",
      type: "sub-content",
      subContent: [
        {
          label: "Tenant",
          type: "basic-text",
          key: "tenantNationality",
        },
        {
          label: "Landlord",
          type: "basic-text",
          key: "landlordNationality",
        },
      ],
    },
    PassportNumber: {
      label: "PassportNumber",
      type: "sub-content",
      subContent: [
        {
          label: "Tenant",
          type: "basic-text",
          key: "tenantPassportNumber",
        },
        {
          label: "Landlord",
          type: "basic-text",
          key: "landlordPassportNumber",
        },
      ],
    },
    ContactReferenceNumber: {
      label: "ContactReferenceNumber",
      type: "sub-content",
      subContent: [
        {
          label: "Tenant",
          type: "basic-text",
          key: "tenantContactReferenceNumber",
        },
        {
          label: "Landlord",
          type: "basic-text",
          key: "landlordContactReferenceNumber",
        },
      ],
    },
    MobileNumber: {
      label: "MobileNumber",
      type: "sub-content",
      subContent: [
        {
          label: "Tenant",
          type: "basic-text",
          key: "tenantMobileNumber",
        },
        {
          label: "Landlord",
          type: "basic-text",
          key: "landlordMobileNumber",
        },
      ],
    },
    Community: {
      label: "Community",
      type: "basic-text",
      key: "community",
    },
    Developer: {
      label: "Developer",
      type: "basic-text",
      key: "developer",
    },
    Property: {
      label: "Property",
      type: "basic-text",
      key: "property",
    },
    UnitType: {
      label: "UnitType",
      type: "basic-text",
      key: "unitType",
    },
    UnitReferenceNumber: {
      label: "UnitReferenceNumber",
      type: "basic-text",
      key: "unitNumber",
    },
    UnitNumber: {
      label: "UnitNumber",
      type: "basic-text",
      key: "unitNumber",
    },
    NumberOfBeds: {
      label: "NumberOfBeds",
      type: "number",
      key: "numberOfBeds",
      startAddition:  <span className='icons b-icon i-20 i-bed'></span>,
    },
    numberOfParking: {
      label: "numberOfParking",
      type: "number",
      key: "numberOfParking",
      startAddition:  <span className='icons b-icon i-16 i-parking'></span>,
    },
    BuiltUpArea: {
      label: "BuiltUpArea",
      type: "number",
      key: "builtUpArea",
      endAddition: "(Sq.m)",
    },
    PlotArea: {
      label: "PlotArea",
      type: "number",
      key: "plotArea",
      endAddition: "(Sq.ft)",
    },
    MoveInDate: {
      label: "MoveInDate",
      type: "date",
      key: "moveInDate",
    },
    LeadSourceFor: {
      label: "LeadSourceFor",
      type: "basic-text",
      key: "tenantSource",
    },
    EmailAccount: {
      label: "EmailAccount",
      type: "sub-content",
      subContent: [
        {
          label: "Tenant",
          type: "basic-text",
          key: "tenantEmail",
        },
        {
          label: "Landlord",
          type: "basic-text",
          key: "landlordEmail",
        },
      ],
    },
    WorkNumber: {
      label: "WorkNumber",
      type: "sub-content",
      subContent: [
        {
          label: "Tenant",
          type: "basic-text",
          key: "tenantWorkNumber",
        },
        {
          label: "Landlord",
          type: "basic-text",
          key: "landlordWorkNumber",
        },
      ],
    },
    FaxNumber: {
      label: "FaxNumber",
      type: "sub-content",
      subContent: [
        {
          label: "Tenant",
          type: "basic-text",
          key: "tenantFaxNumber",
        },
        {
          label: "Landlord",
          type: "basic-text",
          key: "landlordFaxNumber",
        },
      ],
    },
    PoBox: {
      label: "PoBox",
      type: "sub-content",
      subContent: [
        {
          label: "Tenant",
          type: "basic-text",
          key: "tenantPoBox",
        },
        {
          label: "Landlord",
          type: "basic-text",
          key: "landlordPoBox",
        },
      ],
    },
  };