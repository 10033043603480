import {
  DeveloperProfile,
  AgreementsAndComplianceView,
  PeopleAndLocationsView,
  SocialMediaLink,
  DeveloperProfileProjectOverviewComponent,
  DeveloperProfileAgentsOverviewComponent,
  DeveloperProfileLeadsOverviewComponent,
} from "../../DeveloperTabsSections";
import {  DevelopersPermissions } from '../../../../../Permissions' ; 

export const DeveloperVerticalTabsData = [
  {
    label: "developer-profile",
    component: DeveloperProfile,
    permissionsList: Object.values(DevelopersPermissions),
    permissionsId: DevelopersPermissions.ViewDeveloperProfile.permissionsId,
  },
  {
    label: "agreements-and-compliance",
    component: AgreementsAndComplianceView,
    permissionsList: Object.values(DevelopersPermissions),
    permissionsId: [
      DevelopersPermissions.ViewAgreementDetails.permissionsId,
      DevelopersPermissions.ViewAuditAndComplianceDetails.permissionsId,
      DevelopersPermissions.Documents.permissionsId,
    ],
  },

  {
    label: "people-and-locations",
    component: PeopleAndLocationsView,
    permissionsList: Object.values(DevelopersPermissions),
    permissionsId: [
      DevelopersPermissions.DevelopersEmployees.permissionsId,
      DevelopersPermissions.NewBranchesLocation.permissionsId,
    ],
  },
  {
    label: "social-media-link",
    component: SocialMediaLink,
    permissionsList: Object.values(DevelopersPermissions),
    permissionsId: DevelopersPermissions.ViewSocialMediaLinks.permissionsId,
  },
  // {
  //   label: "project-overview",
  //  component: DeveloperProfileProjectOverviewComponent,
  //  permissionsList: Object.values(DevelopersPermissions),
  //  permissionsId: DevelopersPermissions.ViewProjectOverview.permissionsId,
  // },
  // {
  //   label: "agents-overview",
  //   component: DeveloperProfileAgentsOverviewComponent,
  //   permissionsList: Object.values(DevelopersPermissions),
  //   permissionsId: DevelopersPermissions.AgentOverview.permissionsId,
  // },
  // },
  // {
  //   label: "leads-overview",
  //   component: DeveloperProfileLeadsOverviewComponent,
  // },
];
