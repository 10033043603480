export const UnitsSalesPermissions = {
  ViewAndSearchInSaleUnits: {
    permissionsId: "282186a1-0e06-4bc2-32ca-08d91a092746",
    permissionsName: "View and search in sale units",
    componentsId: null,
    components: null,
  },
  ImportUnits: {
    permissionsId: "bf33b224-fc87-4172-32cb-08d91a092746",
    permissionsName: "Import units",
    componentsId: null,
    components: null,
  },
  UnitBulkUpdate: {
    permissionsId: "401281e7-c3b2-4842-32cc-08d91a092746",
    permissionsName: "Unit bulk update",
    componentsId: null,
    components: null,
  },
  AddNewUnit: {
    permissionsId: "b55060aa-f60d-4997-32cd-08d91a092746",
    permissionsName: "Add new unit",
    componentsId: null,
    components: null,
  },
  ViewUnitDetails: {
    permissionsId: "97434242-6f6b-4504-32ce-08d91a092746",
    permissionsName: "View unit details",
    componentsId: null,
    components: null,
  },
  EditUnitDetails: {
    permissionsId: "038f44f9-0ce8-4892-32cf-08d91a092746",
    permissionsName: "Edit unit details",
    componentsId: null,
    components: null,
  },
  ViewUnitImages: {
    permissionsId: "745509ba-2577-4552-32d0-08d91a092746",
    permissionsName: "View unit images",
    componentsId: null,
    components: null,
  },
  ViewUnitLocationOnMap: {
    permissionsId: "c61e1994-0668-42bc-32d1-08d91a092746",
    permissionsName: "View unit location on map",
    componentsId: null,
    components: null,
  },
  ViewUnitMarketingInfo: {
    permissionsId: "ee3b411b-abb2-4c05-32d2-08d91a092746",
    permissionsName: "View unit marketing info",
    componentsId: null,
    components: null,
  },
  EditUnitMarketingInfo: {
    permissionsId: "e922af5b-b968-4eef-32d3-08d91a092746",
    permissionsName: "Edit unit marketing info",
    componentsId: null,
    components: null,
  },
  ViewDocumentsForUnit: {
    permissionsId: "cc70fce0-120e-4898-32d4-08d91a092746",
    permissionsName: "View documents for unit",
    componentsId: null,
    components: null,
  },
  ViewRemarksForUnit: {
    permissionsId: "47aa9fc0-7992-436b-32d5-08d91a092746",
    permissionsName: "View remarks for unit",
    componentsId: null,
    components: null,
  },
  AddNewRemarksForUnit: {
    permissionsId: "4ae17b92-cf37-457a-32d6-08d91a092746",
    permissionsName: "Add new remarks for unit",
    componentsId: null,
    components: null,
  },
  UeditRemarksInUnit: {
    permissionsId: "4708f29e-6ba8-4447-32d7-08d91a092746",
    permissionsName: "Uedit remarks in unit",
    componentsId: null,
    components: null,
  },
  DeleteRemarksInUnit: {
    permissionsId: "e3657c20-51a4-4d1a-32d8-08d91a092746",
    permissionsName: "Delete remarks in unit",
    componentsId: null,
    components: null,
  },
  ViewOpenHouseForUnit: {
    permissionsId: "4e60df47-e151-4a83-32d9-08d91a092746",
    permissionsName: "View open house for unit",
    componentsId: null,
    components: null,
  },
  AddNewOpenHouseForUnit: {
    permissionsId: "719d4188-a704-4396-32da-08d91a092746",
    permissionsName: "Add new open house for unit",
    componentsId: null,
    components: null,
  },
  UpdateOpenHouseForUnit: {
    permissionsId: "29bd8c22-f5b7-4103-32db-08d91a092746",
    permissionsName: "Update open house for unit",
    componentsId: null,
    components: null,
  },
  DeleteOpenHouseForUnit: {
    permissionsId: "36efcad2-622a-4d10-32dc-08d91a092746",
    permissionsName: "Delete open house for unit",
    componentsId: null,
    components: null,
  },
  ViewTheActivitiesForUnit: {
    permissionsId: "a92d91c3-3b9f-4e11-32dd-08d91a092746",
    permissionsName: "View the activities for unit",
    componentsId: null,
    components: null,
  },
  ViewSaleDetailsForUnit: {
    permissionsId: "8c97bcbc-4082-4f8e-32de-08d91a092746",
    permissionsName: "View sale details for unit",
    componentsId: null,
    components: null,
  },
  EditSalesDetailsForUnit: {
    permissionsId: "fe51e8db-eb9d-4252-32df-08d91a092746",
    permissionsName: "Edit sales details for unit",
    componentsId: null,
    components: null,
  },
  ViewBuyerSummaryForUnit: {
    permissionsId: "8c149fbf-226b-4e11-32e0-08d91a092746",
    permissionsName: "View buyer summary for unit",
    componentsId: null,
    components: null,
  },
  ViewReferenceDetailsForUnit: {
    permissionsId: "3bcd4445-9fff-477e-32e1-08d91a092746",
    permissionsName: "View Reference details for unit",
    componentsId: null,
    components: null,
  },
  EditReferenceDetailsForUnit: {
    permissionsId: "4c7991c0-9d97-43d4-32e2-08d91a092746",
    permissionsName: "Edit reference details for unit",
    componentsId: null,
    components: null,
  },
  ViewPaymentPlansForUnit: {
    permissionsId: "f5b3b421-b68f-4361-32e3-08d91a092746",
    permissionsName: "View payment plans for unit",
    componentsId: null,
    components: null,
  },
  AddPaymentPlanOnUnit: {
    permissionsId: "7e9a8da9-00b9-4976-32e4-08d91a092746",
    permissionsName: "Add payment plan on unit",
    componentsId: null,
    components: null,
  },
  EditPaymentPlanForUnit: {
    permissionsId: "aa34dc96-2acd-483e-32e5-08d91a092746",
    permissionsName: "Edit payment plan for unit",
    componentsId: null,
    components: null,
  },
  DeletePaymentPlanForUnit: {
    permissionsId: "720842bc-669c-47dd-32e6-08d91a092746",
    permissionsName: "Delete payment plan for unit",
    componentsId: null,
    components: null,
  },
  ViewParkingInfoForUnit: {
    permissionsId: "10ae34ee-b0a5-41e0-32e7-08d91a092746",
    permissionsName: "View parking info for unit",
    componentsId: null,
    components: null,
  },
  AddParkingForUnit: {
    permissionsId: "dda430c7-74d5-4cf6-32e8-08d91a092746",
    permissionsName: "Add parking for unit",
    componentsId: null,
    components: null,
  },
  EditParkingForUnit: {
    permissionsId: "e9cde7e4-85ef-43bd-32e9-08d91a092746",
    permissionsName: "Edit parking for unit",
    componentsId: null,
    components: null,
  },
  DeleteParkingForUnit: {
    permissionsId: "171f3230-4bac-41a0-32ea-08d91a092746",
    permissionsName: "Delete parking for unit",
    componentsId: null,
    components: null,
  },
  ViewUnitHistory: {
    permissionsId: "c2dae773-23df-44e8-32eb-08d91a092746",
    permissionsName: "View unit history",
    componentsId: null,
    components: null,
  },
  ViewUnitTemplates: {
    permissionsId: "5264cb4b-5ee6-4118-32ec-08d91a092746",
    permissionsName: "View unit templates",
    componentsId: null,
    components: null,
  },
  OpenUnitTemplates: {
    permissionsId: "63e2a6e3-cb06-4696-32ed-08d91a092746",
    permissionsName: "Open unit templates",
    componentsId: null,
    components: null,
  },
  ViewTransactionDetailsForUnit: {
    permissionsId: "4da1ec55-fd39-4dc3-32ee-08d91a092746",
    permissionsName: "View transaction details for unit",
    componentsId: null,
    components: null,
  },
  ViewSellerBuyerNamesForSoldReservedTransactions: {
    permissionsId: "b2f888e1-cc5f-4cfa-32ef-08d91a092746",
    permissionsName: "View Seller/Buyer names for sold/reserved transactions",
    componentsId: null,
    components: null,
  },
  ViewPricingDetailsForSoldReservedTransactions: {
    permissionsId: "b69c326b-3e7e-460b-32f0-08d91a092746",
    permissionsName: "View pricing details for sold/reserved transactions",
    componentsId: null,
    components: null,
  },
  ViewInvoicesForSoldReservedTransactions: {
    permissionsId: "356daf6b-f883-4d6f-32f1-08d91a092746",
    permissionsName: "View invoices for sold/reserved transactions",
    componentsId: null,
    components: null,
  },
  ViewRemindersOnSoldReservedTransactions: {
    permissionsId: "7147ecee-3888-4ae4-32f2-08d91a092746",
    permissionsName: "View reminders on sold/reserved transactions",
    componentsId: null,
    components: null,
  },
  AddRemindersForSoldReservedTransactions: {
    permissionsId: "8c51cc41-d6c2-497a-32f3-08d91a092746",
    permissionsName: "Add reminders for sold/reserved transactions",
    componentsId: null,
    components: null,
  },
  MakeTransactionOnUnitToBeAvailable: {
    permissionsId: "e382e897-8c71-478f-32f4-08d91a092746",
    permissionsName: "Make transaction on unit to be available",
    componentsId: null,
    components: null,
  },
  MakeTransactionOnUnitToBeDraft: {
    permissionsId: "243db2ff-7e76-4018-32f5-08d91a092746",
    permissionsName: "Make transaction on unit to be draft",
    componentsId: null,
    components: null,
  },

  GetAllOwnersByUnitId: {
    permissionsId: "a962aa83-fbe9-48d8-e91e-08da38d6f417",
    permissionsName: "Get All Owners By Unit-Id",
    componentsId: null,
    components: null,
  },
  DeleteUnitOwner: {
    permissionsId: "d22403c1-0655-42be-e91f-08da38d6f417",
    permissionsName: "Delete Unit Owner",
    componentsId: null,
    components: null,
  },
  UpdateUnitOwner: {
    permissionsId: "f61e4ac1-53ff-404c-e920-08da38d6f417",
    permissionsName: "Update Unit Owner",
    componentsId: null,
    components: null,
  },
  CreateUnitOwner: {
    permissionsId: "d4bb5ded-03c4-4676-e921-08da38d6f417",
    permissionsName: "create Unit Owner",
    componentsId: null,
    components: null,
  },
  EditUspMarketing: {
    permissionsId: "2ba6e45d-552f-409a-85bc-08da4220e9f0",
    permissionsName: "Edit Usp Marketing",
    componentsId: null,
    components: null,
  },
  EditMarketingTitle: {
    permissionsId: "a377a23b-3aff-4d08-85bd-08da4220e9f0",
    permissionsName: "Edit Marketing Title",
    componentsId: null,
    components: null,
  },
  EditMarketingDescription: {
    permissionsId: "47f3ed33-6dc1-4ab9-85be-08da4220e9f0",
    permissionsName: "Edit Marketing Description",
    componentsId: null,
    components: null,
  },
  EditFeaturedUnitMarketing: {
    permissionsId: "e5d4a0ae-d400-47c7-85bf-08da4220e9f0",
    permissionsName: "Edit Featured Unit Marketing",
    componentsId: null,
    components: null,
  },
  EditHotDealMarketing: {
    permissionsId: "bf54f260-84c4-4189-85c0-08da4220e9f0",
    permissionsName: "Edit Hot Deal Marketing",
    componentsId: null,
    components: null,
  },
  EditPublishedToWebsiteMarketing: {
    permissionsId: "270ccfa4-0fb3-439b-85c1-08da4220e9f0",
    permissionsName: "Edit Published To Website Marketing",
    componentsId: null,
    components: null,
  },

  MakeTransactionOnUnitSaleToBeAvailable: {
    permissionsId: "1c50c5b5-3d85-431b-0fc7-08da550cab60",
    permissionsName: "Make Transaction On Unit Sale To Be Available",
    componentsId: null,
    components: null,
  },
  MakeTransactionOnUnitSaleToBeDraft: {
    permissionsId: "7b23e901-3de8-4c70-0fc8-08da550cab60",
    permissionsName: "Make Transaction On Unit Sale To Be Draft",
    componentsId: null,
    components: null,
  },
  SetUnitAsSale: {
    permissionsId: "b6fefd50-bb82-428a-0fc9-08da550cab60",
    permissionsName: "Set Unit As Sale",
    componentsId: null,
    components: null,
  },
  SetUnitAsReserveForSale: {
    permissionsId: "a570a0f0-1efd-45d6-0fca-08da550cab60",
    permissionsName: "Set Unit As Reserve For Sale",
    componentsId: null,
    components: null,
  },

  ShareUnit: {
    permissionsId: "2904809a-bf75-4a51-2890-08dab0da5710",
    permissionsName: "Share Unit",
    componentsId: null,
    components: null,
  },
  ViewUnitMatching: {
    permissionsId: "6da921ef-3281-458b-288d-08dab0da5710",
    permissionsName: "View Unit Matching",
    componentsId: null,
    components: null,
  },
  EditUnitImageCategory: {
    permissionsId: "e866933e-b0a7-4581-1ccb-08dabfc53c80",
    permissionsName: "Edit Unit Image Category",
    componentsId: null,
    components: null,
  },
  OpenFileForUnitOwner: {
    permissionsId: "9a0e0861-4d4c-46ca-1ccc-08dabfc53c80",
    permissionsName: "Open File For Unit Owner",
    componentsId: null,
    components: null,
  },
  AddDocumentsForUnits: {
    permissionsId: "82b11f0a-33a2-47a8-2e54-08db604be055",
    permissionsName: "Add Documents For Units",
    description: null,
    componentsId: null,
    components: null,
  },
  EditDocumentsForUnits: {
    permissionsId: "4b5f53ba-bea7-48d9-2e55-08db604be055",
    permissionsName: "Edit Documents For Units",
    description: null,
    componentsId: null,
    components: null,
  },
  DeleteDocumentsFromUnits: {
    permissionsId: "3aafb974-9204-4708-2e56-08db604be055",
    permissionsName: "Delete Documents From Units",
    description: null,
    componentsId: null,
    components: null,
  },
  SaleListingUnitDubizzle: {
    permissionsId: "51fac24d-951b-4aaa-a0bd-08db9980f187",
    permissionsName: "Sale Listing Unit / Dubizzle",
    description: null,
    componentsId: null,
    components: null,
  },
  SaleListingUnitBayut: {
    permissionsId: "d101c962-daf4-4946-a0be-08db9980f187",
    permissionsName: "Sale Listing Unit / Bayut",
    description: null,
    componentsId: null,
    components: null,
  },
  SaleListingUnitPropertyFinder: {
    permissionsId: "c738359e-43cf-4129-a0bf-08db9980f187",
    permissionsName: "Sale Listing Unit / Property Finder",
    description: null,
    componentsId: null,
    components: null,
  },
  SaleListingUnitHouza: {
    permissionsId: "e9dc5589-f5a5-4de6-a0c0-08db9980f187",
    permissionsName: "Sale Listing Unit / Houza",
    description: null,
    componentsId: null,
    components: null,
  },
  AddNewActivity: {
    permissionsId: "39e2f986-5c10-4604-286f-08dbaf79a1bb",
    permissionsName: "Add New Activity",
    description: null,
    componentsId: null,
    components: null,
  },

  EditReservedSaleTransaction: {
    permissionsId: "d78bf7e2-c6d6-4e46-2b18-08dbca2fdea8",
    permissionsName: "Edit Reserved Sale Transaction",
    description: null,
    componentsId: null,
    components: null,
  },
  AddPublishedUnit: {
    permissionsId: "1821a5f9-f402-455a-703e-08dbf65badbd",
    permissionsName: "Add Published Unit",
    description: null,
    componentsId: null,
    components: null,
  },
  ShowWebPortals: {
    permissionsId: "bba9a763-a776-4595-703f-08dbf65badbd",
    permissionsName: "Show Web Portals",
    description: null,
    componentsId: null,
    components: null,
  },
  AgentTransactionButton: {
    permissionsId: "0f1c8d46-c8b5-4f58-8876-08dc0129f7d6",
    permissionsName: "Agent Transaction Button",
    description: null,
    componentsId: null,
    components: null,
  },
  EditListingAgent: {
    permissionsId: "7922234b-8f05-481a-42ee-08dc2f124e17",
    permissionsName: "Edit Listing Agent",
    description: null,
    componentsId: null,
    components: null,
  },
  EditLeaseListingAgent: {
    permissionsId: "73e75c56-235c-43cc-42ef-08dc2f124e17",
    permissionsName: "Edit Lease Listing Agent",
    description: null,
    componentsId: null,
    components: null,
  },
  ViewTasksForUnit: {
    permissionsId: "7a4ae03c-72b8-4f08-19b4-08dc313da57c",
    permissionsName: "View Tasks For Unit",
    description: null,
    componentsId: null,
    components: null,
  },
  AddNewTask: {
    permissionsId: "26bd6c24-d4a3-40b2-19b5-08dc313da57c",
    permissionsName: "Add New Task",
    description: null,
    componentsId: null,
    components: null,
  },
  ViewUnitMPI: {
    permissionsId: "935622be-00f6-4c7d-f46a-08dc3da1d8ed",
    permissionsName: "View Unit MPI",
    description: null,
    componentsId: null,
    components: null,
  },
  ShareUnitDocuments: {
    permissionsId: "07b40f33-f655-4802-c4b1-08dc683b0c43",
    permissionsName: "Share Unit Documents",
    description: null,
    componentsId: null,
    components: null,
  },
  UnitDuplications: {
    permissionsId: "53c867d8-1391-47ff-f475-08dc3da1d8ed",
    permissionsName: "Unit Duplications",
    description: null,
    componentsId: null,
    components: null,
  },
  ShareUnits: {
    permissionsId: "9257eb90-33a6-4ee5-5dca-08dc7403ebc2",
    permissionsName: "Share Unit",
    description: null,
    componentsId: null,
    components: null,
  },
  ProposalHistory: {
    permissionsId: "460327f2-c91b-47f1-9f7f-08dc85eb760a",
    permissionsName: "Proposal History",
    description: null,
    componentsId: null,
    components: null,
  },
  ViewTenantTab: {
    permissionsId: "46692cb0-c86a-479a-7956-08dcaf947ea8",
    permissionsName: "View Tenant Tab",
    description: null,
    componentsId: null,
    components: null,
  },
  DeveloperTrasnaction: {
    permissionsId: "d9874a84-878a-41bb-7960-08dcaf947ea8",
    permissionsName: "Developer Trasnaction",
    description: null,
    componentsId: null,
    components: null,
  },
  EditDealOfTheDay: {
    permissionsId: "f1d4c195-6ac5-4f04-0065-08dd03ecea2b",
    permissionsName: "Edit Deal Of The Day",
    description: null,
    componentsId: null,
    components: null,
  },
};
