import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  fieldContainer: {
    display: "flex",
    gap: "32px",
    padding: "16px 0",
    "&:not(:last-child)": {
      borderBottom: `1px solid ${theme.palette.border.secondary}`,
    },
    "& label": {
      width: "160px",
      fontSize: "14px",
      fontWeight: 600,
      [theme.breakpoints.down("426")]: {
        width: "100px",
      },
    },
    "& p": {
      fontSize: "14px",
      fontWeight: 400,
      lineHeight: "20px",
      margin: 0,
      maxWidth: "448px",
      textWrap: "pretty",
    },
    [theme.breakpoints.down("426")]: {
      flexWrap: "wrap",
    },
  },
  fullWidth: {
    width: "100%",
  },
}));
