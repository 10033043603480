


import React , { useState  } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { TabsComponent , CheckboxesComponent   } from '../../../../../../../Components' ;
import { ContactPoliciesTabs } from './ContactPoliciesTabs' ; 

    
export const ContactPolicies  = ({
  parentTranslationPath,
  translationPath,
  state , 
  setState ,
  checkAtLeastOneSelected, 
  checkAtLeastOneSelected2 ,
  setDefaultSelected , 
}) => {
    const { t } = useTranslation(parentTranslationPath);
    const [activeTab, setActiveTab] = useState(0);

    const onTabChanged = (e, newTap) => {
      setActiveTab(newTap);
    };

 
  return (
    <div className='childs-wrapper b-0 part'>
       <TabsComponent
          data={ContactPoliciesTabs}
          labelInput='label'
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          themeClasses='theme-curved'
          dynamicComponentProps={{
          parentTranslationPath,
          translationPath,
            state,
            setState , 
            checkAtLeastOneSelected ,
            checkAtLeastOneSelected2 ,
            setDefaultSelected ,   
        }} 
         currentTab={activeTab}
         onTabChanged={onTabChanged}
        />
        <div className='w-100 px-2 mt-2'>
           <CheckboxesComponent
            idRef='contact_view-sensitive-dataRef'
            label={t(`${translationPath}view-sensitive-data`)}
            singleChecked={state.contacts.viewSensitive}
            parentTranslationPath={parentTranslationPath}
            translationPathForData={translationPath}
            onSelectedCheckboxClicked={()=> {
            setState({id : 'contacts' , value : { ...state.contacts , viewSensitive : !(state.contacts.viewSensitive)} })
          }}
         />
      </div> 
    </div>
  );
};

ContactPolicies.propTypes = {
  parentTranslationPath: PropTypes.string.isRequired,
  translationPath: PropTypes.string.isRequired,
  state:PropTypes.instanceOf(Object).isRequired,
  setState : PropTypes.func.isRequired,
  checkAtLeastOneSelected :  PropTypes.func.isRequired, 
};

