
import React, { useEffect}  from 'react';
import { useTranslation } from 'react-i18next';
import Rating from '@material-ui/lab/Rating';

  export const UnitMix = ({
    parentTranslationPath ,
    translationPath ,
    state ,
    setSelectedList  , 
    selectedList , 
    onChangeSelectedItem ,
   } ) => { 

    const { t } = useTranslation(parentTranslationPath);
    
    useEffect(() => {
      setSelectedList((item)=> ({...item , unitTypes : state.updatePropertyRatingLookupInfoDtos.filter((x)=> x.type === 'UnitType')}));
   }, [state]);

    return (
      <>
        <div className="">
        <span className="fw-bold"> {t(`${translationPath}unit-mix`)} </span>
        </div> 
        <div className='w-100 units-finishing'>
         {    selectedList && selectedList.unitTypes &&  selectedList.unitTypes.map((item, index) => 
            (
            <div className="d-flex d-flex-v-center tower-units-finishing-card mt-2 mb-2 b-bottom" key={`unitTypes${index}`}>
            <div>
            <span className='fw-bold'> {item.lookupItemName} </span> 
            </div>
            <div>
            <Rating  
                size="large"
                name={`unit-mix${index}`}
                max={10}
                value={item.score}
                precision={1}
                onChange={(event, newValue) => 
                {
                  onChangeSelectedItem(newValue || 0 ,index , selectedList.unitTypes , 'unitTypes');
                }}
                />     
              </div>
           </div>  
          )) } 
        </div>
      </>
    );
  } ; 
  
