import React, { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { ButtonBase } from '@material-ui/core';
import { DialogComponent, Spinner } from '../../../../../../../Components';
import { DeleteUnitOwner } from '../../../../../../../Services'
import { showError, showSuccess, GetParams } from '../../../../../../../Helper';


export const OwnerDeleteDialog = ({
    parentTranslationPath,
    translationPath,
    activeItem,
    setIsOpenDeleteDialog,
    isOpenDeleteDialog,
    setActiveItem,
    reloadData
}) => {
    const { t } = useTranslation(parentTranslationPath);

    const [isLoading, setIsLoading] = useState(false);

    const deleteHandler = useCallback(async () => {
        setIsLoading(true);
        const unitId = GetParams('id');
        const ownerId = activeItem?.ownerId;

        const res = await DeleteUnitOwner({ unitId, ownerId });

        if (!(res && res.status && res.status !== 200)) {
            showSuccess(`Unit Owner Delete Successfully`);
            setIsOpenDeleteDialog(false);
            reloadData();
        } else showError(`Unit Owner Delete Failed`);

        setActiveItem(null);
        setIsLoading(false);
    }, []);

    return (
        <>
            <Spinner isActive={isLoading} />
            <DialogComponent
                isOpen={isOpenDeleteDialog}
                maxWidth='sm'
                titleText='Delete Unit Owner'
                disableBackdropClick
                dialogContent={(
                    <>
                        <div className='d-flex-column-center'>
                            <Spinner isActive={isLoading} />
                            <span className='mdi mdi-close-octagon c-danger mdi-48px' />
                            <span>
                                {`Are You Sure You Want to Delete This Unit Owner : "${activeItem?.name}"`}
                            </span>
                        </div>
                        <div className='actions-btns'>
                            <ButtonBase
                             id='delete-ref-action'
                                onClick={deleteHandler}
                                className='btns theme-solid'>
                                {t(`${translationPath}delete`)}
                            </ButtonBase>
                            <ButtonBase 
                                id='cancel-ref-action'
                            onClick={() => {
                                setIsOpenDeleteDialog(false);
                            }} className='btns cancel-btn-wrapper'>
                                {t(`${translationPath}cancel`)}
                            </ButtonBase>
                        </div>

                    </>
                )}
                parentTranslationPath={parentTranslationPath}
                translationPath={translationPath}
            />
        </>
    )
}
OwnerDeleteDialog.propTypes = {
    parentTranslationPath: PropTypes.string.isRequired,
    translationPath: PropTypes.string.isRequired,
}
