import React, { useEffect, useState, useCallback, useReducer } from "react";
import { useTranslation } from "react-i18next";
import {
  Spinner,
  SwitchComponent,
  Tables,
  PermissionsComponent,
  Inputs,
} from "../../../../Components";
import { bottomBoxComponentUpdate } from "../../../../Helper";
import { useTitle } from "../../../../Hooks";
import { RotationConfigurationPermissions } from "../../../../Permissions";
import {
  changeStatusOfconfigrationSystemServices,
  GetAllSystemConfigurationsStatus,
} from "../../../../Services/SystemConfigrationServices/SystemConfigrationServices";
import { Save } from "@material-ui/icons";
import { Button } from "@material-ui/core";

export const SystemConfigrationView = () => {
  const parentTranslationPath = "SystemConfigration";
  const translationPath = "";
  const { t } = useTranslation(parentTranslationPath);
  useTitle(t("Shared:system-configration"));

  const reducer = (state, action) => {
    if (action.id !== "edit") return { ...state, [action.id]: action.value };
    return {
      ...action.value,
    };
  };
  const [selected, setSelected] = useReducer(reducer, {});
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [filter, setFilter] = useState({
    pageIndex: 1,
    pageSize: 50,
  });
  const getAllSystemConfigurations = async () => {
    setIsLoading(true);

    const result = await GetAllSystemConfigurationsStatus();
    if (!(result && result.status && result.status !== 200)) {
      setData(result);
    }
    setIsLoading(false);
  };

  const changeStatusOfconfigrationSystem = useCallback(async (body) => {
    setIsLoading(true);
    await changeStatusOfconfigrationSystemServices(body);
    await getAllSystemConfigurations();
    setIsLoading(false);
  }, []);

  const parseBoolValue = (value) => {
    let parsedValue = false;
    if (value === "true") parsedValue = JSON.parse(value);
    return parsedValue;
  }

  const fillInitialConfigData = () => {
    const initialData = {};
    if (data)
      data.forEach((item) => {
        if (item.systemConfigurationValueTypeName === "Toggle")
          initialData[item.systemConfigurationName] = parseBoolValue(item.systemConfigurationValue);
        else if (item.systemConfigurationValueTypeName === "InputField")
          initialData[item.systemConfigurationName] =
            item.systemConfigurationValue;
      });

    setSelected({ id: "edit", value: initialData });
  };

  useEffect(() => {
    getAllSystemConfigurations();
  }, []);

  useEffect(() => {
    bottomBoxComponentUpdate(null);
  }, []);

  useEffect(() => {
    if (data) fillInitialConfigData();
  }, [data]);
  return (
    <>
      <Spinner isActive={isLoading} />
      <div className="headOfTabel" />
      <PermissionsComponent
        permissionsList={Object.values(RotationConfigurationPermissions)}
        permissionsId={
          RotationConfigurationPermissions.ViewRotationConfiguration
            .permissionsId
        }
      >
        <div className="px-3">
          <Tables
            data={data}
            headerData={[
              {
                id: 1,
                label: "configration-name",
                input: 'systemConfigurationName',
                component: (item) => {
                  if(item.systemConfigurationName === 'MPIUnitSize'){
                    return <div>{t(item.systemConfigurationName)} <span className="text-primary">(sqf)</span></div>;
                  }
                  else if(
                    item.systemConfigurationName === 'CommercialOfferLetter'
                  ){
                    return  <div>{t(item.systemConfigurationName)} <span className="text-primary"> (Days) </span></div>;
                  }
                  else if (
                    item.systemConfigurationName === "ResidentialOfferLetter"
                  ){
                    return  <div>{t(item.systemConfigurationName)} <span className="text-primary">(Days)</span></div>;
                  } else if(
                    item.systemConfigurationName === 'AutoWithdrawPeriod(Horus)'
                  ) {
                    return  <div>{t(item.systemConfigurationName)} <span className="text-primary">Hours</span></div>;
                  }else{
                    t(item.systemConfigurationName)
                  }
                
                },
              },
              {
                id: 2,
                label: "status",
                component: item =>
                  (selected[item.systemConfigurationName] !== undefined) ?
                    ((item.systemConfigurationValueTypeName === "InputField" && (
                      <div className="d-flex w-100">
                        <div className="d-felx w-25">
                          <Inputs
                            idRef={`${item.systemConfigurationName}Ref`}
                            value={selected[item.systemConfigurationName] || null}
                            endAdornment={
                              <Button
                                color="primary"
                                onClick={() =>
                                  changeStatusOfconfigrationSystem({
                                    key: item.systemConfigurationName,
                                    value: selected[item.systemConfigurationName],
                                  })
                                }
                              >
                                <Save fontSize="small" />
                              </Button>
                            }
                            onInputChanged={(e) => {
                              setSelected({
                                id: item.systemConfigurationName,
                                value: e.target.value,
                              });
                            }}
                          />

                        </div>
                          {
                            item.systemConfigurationName === 'AutoWithdrawPeriod(Horus)' && (
                              <div className="text-muted m-2">
                                <small>if the input is empty will deactivate the auto withdraw feature</small>
                              </div>
                            )
                          } 
                      </div>
                    )) || (item.systemConfigurationValueTypeName === "Toggle" && (
                      <SwitchComponent
                        idRef={`${item.systemConfigurationName}`}
                        isChecked={selected[item.systemConfigurationName]}
                        labelClasses="px-0"
                        onChangeHandler={(_, isChecked) => {
                          setSelected({
                            id: item.systemConfigurationName,
                            value: isChecked,
                          });
                          changeStatusOfconfigrationSystem({
                            key: item.systemConfigurationName,
                            value: isChecked,
                          });
                        }}
                        themeClass="theme-line"
                        labelValue={
                          selected[item.systemConfigurationName]
                            ? `${t("open")}`
                            : `${t("close")}`
                        }
                        parentTranslationPath={parentTranslationPath}
                        translationPath={translationPath}
                      />
                    ))) : null,
              },
            ]
            }
            defaultActions={[]}
            parentTranslationPath={parentTranslationPath}
            translationPath={translationPath}
            activePage={filter.pageIndex}
            itemsPerPage={filter.pageSize}
          />
        </div>
      </PermissionsComponent>
    </>
  );
};
