import React, { useState  , useEffect} from "react";
import { useTranslation } from "react-i18next";
import { AutocompleteComponent } from "../../../../Components";

const parentTranslationPath = "UnitsView";
const translationPath = "";

export const SortByFilter = ({
  parentTranslationPath,
  translationPath,
  selected , 
  setSelected ,
  setOrderBy 
}) => {
  const { t } = useTranslation(parentTranslationPath);
  const [sortByList, setSortByList] = useState([
    { name : "Newest", value: "Newest" ,key : 'createdOn' ,orderBy : 2  ,fieldType : "date" },
    { name: "Oldest", value: "Oldest" , key : 'createdOn' ,orderBy : 1 , fieldType : "date" },
    { name: "Price (high)", value: "Oldest"  ,key : 'selling_price' ,orderBy : 2  ,fieldType :"number" },
    { name: "Price (low)", value: "price-low"  ,key : 'selling_price' ,orderBy : 1  ,fieldType :"number" },
    { name: "Beds (Most)", value: "beds-most" ,  key : 'bedrooms' ,orderBy : 2  , fieldType : 'string' },
    { name: "Beds (least)", value: "beds-least" , key : 'bedrooms' ,orderBy : 1  , fieldType : 'string'   },

  ]);

  return (
    <div className="m-1 w-25">
      <AutocompleteComponent
        idRef="sortByRef"
        wrapperClasses="w-min-unset m-2 mr-1"
        inputPlaceholder={t(`${translationPath}select-sort-by`)}
        labelValue={t(`${translationPath}sort-by`)}
        selectedValues={selected.sortBy}
        data={sortByList}
        displayLabel={(option) => (option && option.name) || ""}
        multiple={false}
        withoutSearchButton
        onChange={(e, newValue) => {
          setOrderBy({filterBy : (newValue && newValue.key) || 'createdOn'  , orderBy :  (newValue && newValue.orderBy) || 2 , fieldType  : (newValue && newValue.fieldType) || null });
          setSelected({id :'sortBy'  , value : newValue })

        }}
        parentTranslationPath={parentTranslationPath}
        translationPath={translationPath}
      />
    </div>
  );
};
