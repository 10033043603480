import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Inputs, SwitchComponent } from "../../../../../../../Components";
import { Chip } from "@material-ui/core";
import { getErrorByName } from "../../../../../../../Helper";
export const AutoCorrespondenceComponent = ({
  parentTranslationPath,
  translationPath,
  setState,
  state,
  isSubmitted,
  addressList,
  allUsers,
  isLoading,
  schema,
  addressLoadings,
  searchHandler,
  selected,
  setSelected,
  address,
  setAddress,
  getSubCommunityLookUps,
  getCommunityLookUps,
  getDistrictLookUps,
  getCitiesLookups,
  getLookupsOfParent,
}) => {
  const { t } = useTranslation(parentTranslationPath);

  const [emails, setEmails] = useState(
    (state && state.outgoingBcc && state.outgoingBcc.split(",")) || []
  );

  const [inputValue, setInputValue] = useState("");
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [isEmailSubmitted, setIsEmailSubmitted] = useState(false);
  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleInputKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      addEmail(inputValue.trim());
    }
  };

  const handleChipDelete = (emailToDelete) => () => {
    const updatedEmails = emails.filter((email) => email !== emailToDelete);
    setState({
      id: "outgoingBcc",
      value: updatedEmails.join(","),
    });
    setEmails(updatedEmails);
  };
  const addEmail = (email) => {
    if (email && isValidEmail(email) && !emails.includes(email)) {
      setEmails((prevEmails) => [...prevEmails, email]);
      setState({
        id: "outgoingBcc",
        value: [...emails, email].join(","),
      });
      setInputValue("");
      setIsEmailSubmitted(true);
      setIsEmailValid(true);
    } else {
      setIsEmailSubmitted(true);
      setIsEmailValid(false);
    }
  };

  const isValidEmail = (email) => {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  };

  return (
    <>
      <div className="enableAutoCorrespondence-wrapper">
        <div>
          <div className="Seaction-dFlex v1">
            <div>
              <div className="action-box">
                <SwitchComponent
                  idRef="isMarketingREF"
                  themeClass="theme-line"
                  isChecked={state.enableAutoCorrespondence}
                  labelValue={"enableAutoCorrespondence"}
                  parentTranslationPath={parentTranslationPath}
                  translationPath={translationPath}
                  onChangeHandler={(event) =>
                    setState({
                      id: "enableAutoCorrespondence",
                      value: !state.enableAutoCorrespondence,
                    })
                  }
                />
                <SwitchComponent
                  idRef="isMarketingREF"
                  themeClass="theme-line"
                  isChecked={state.isDefaultEmailSettings}
                  labelValue={"isDefaultEmailSettings"}
                  parentTranslationPath={parentTranslationPath}
                  translationPath={translationPath}
                  isDisabled={!(state.emailFrom || state.emailPassword)}
                  onChangeHandler={(event) =>
                    setState({
                      id: "isDefaultEmailSettings",
                      value: !state.isDefaultEmailSettings,
                    })
                  }
                />
              </div>
            </div>

            <div>
              <div className="action-box">
                <Inputs
                  idRef="fromNameRef"
                  translationPath={translationPath}
                  labelValue={"SenderName"}
                  inputPlaceholder="SenderNamePlaceholde"
                  parentTranslationPath={parentTranslationPath}
                  value={state.fromName}
                  onInputChanged={(event) =>
                    setState({
                      id: "fromName",
                      value: event.target.value,
                    })
                  }
                />
              </div>
              <div className="action-box">
                <Inputs
                  idRef="fromEmailRef"
                  translationPath={translationPath}
                  labelValue={"EmailAddress"}
                  labelClasses="Requierd-Color"
                  helperText={getErrorByName(schema, "fromEmail").message}
                  error={getErrorByName(schema, "fromEmail").error}
                  isWithError
                  isSubmitted={isSubmitted}
                  inputPlaceholder="fromEmailEmailAddress"
                  parentTranslationPath={parentTranslationPath}
                  value={state.fromEmail}
                  onInputChanged={(event) =>
                    setState({
                      id: "fromEmail",
                      value: event.target.value,
                    })
                  }
                />
              </div>
              <div className="action-box nulte-email">
                <div>
                  {emails.map((email, index) => (
                    <span className="item-space">
                      <Chip
                        key={index}
                        label={email}
                        onDelete={handleChipDelete(email)}
                        color="primary"
                      />
                    </span>
                  ))}
                  <Inputs
                    idRef="outgoingBccRef"
                    translationPath={translationPath}
                    inputPlaceholder="outgoingBccPlaceholder"
                    labelValue={"outgoingBccPlaceholde"}
                    parentTranslationPath={parentTranslationPath}
                    value={inputValue}
                    onKeyDown={handleInputKeyDown}
                    onInputChanged={handleInputChange}
                    isSubmitted={isEmailSubmitted}
                    isWithError
                    error={!isEmailValid}
                    helperText={t("invalid-email")}
                  />
                  {inputValue !== "" && (
                    <span className="m-2 units-cards-wrapper price-wrapper Sale globel-Yellow-style">
                      {t(`${translationPath}press-enter-to-add-email-address`)}
                    </span>
                  )}
                </div>
              </div>
              <div className="action-box">
                <Inputs
                  idRef="emailPasswordRef"
                  translationPath={translationPath}
                  labelValue={"emailPassword"}
                  inputPlaceholder="emailPasswordPlaceholde"
                  DisabledPaste
                  helperText={getErrorByName(schema, "emailPassword").message}
                  error={getErrorByName(schema, "emailPassword").error}
                  isWithError
                  isSubmitted={isSubmitted}
                  type="password"
                  DisabledCopy
                  labelClasses="Requierd-Color"
                  parentTranslationPath={parentTranslationPath}
                  value={state.emailPassword}
                  onInputChanged={(event) =>
                    setState({
                      id: "emailPassword",
                      value: event.target.value,
                    })
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
