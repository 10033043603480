import React, { useState } from "react";
import clsx from "clsx";
import { Box, Popover } from "@material-ui/core";
import { CustomSelect, CustomButton } from "../../../Components";
import { useSelectedTheme } from "../../../Hooks";
import { NewTableFilterOperatorsEnum } from "../../../Enums";

// Styles
import useStyles from "./styles";

// Icons
import { FilterIcon, FilterEqualIcon, CloseXIcon } from "../../../assets/icons";

const CustomTableHeader = ({
  params,
  title,
  isHiddenFilter = false,
  criteriaFilter = {},
  handleFilterConfirm = () => {},
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [inputValue, setInputValue] = useState("");
  const [selectedValue, setSelectedValue] = useState(1);
  const [inputNameValue, setInputNameValue] = useState("");
  const [selectedNameValue, setSelectedNameValue] = useState(1);

  const key = params?.field || params?.colDef?.field;
  const styles = useStyles();
  const {
    theme: { palette },
  } = useSelectedTheme();

  const handleClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const handleClose = () => {
    setAnchorEl(null);
    setInputValue("");
    setSelectedValue(1);
    setInputNameValue("");
    setSelectedNameValue(1);
  };

  const handleConfirmClick = () => {
    if (handleFilterConfirm) {
      handleFilterConfirm(
        params,
        title,
        inputValue,
        selectedValue,
        inputNameValue,
        selectedNameValue
      );
    }
    handleClose();
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const isSpecialDateField = (field) =>
    field === "Creation Date" || field === "Updated Date";

  return (
    <>
      <Box className={styles.headerCell}>
        {title}
        {!isHiddenFilter && (
          <FilterIcon
            onClick={handleClick}
            width="16"
            height="16"
            fill={
              Object.keys(criteriaFilter || [])
                ?.map((str) => str?.toLowerCase())
                ?.some(
                  (element) =>
                    key?.toLowerCase() === element ||
                    key?.toLowerCase()?.includes(element)
                )
                ? palette.border.brandSolid
                : palette.text.tertiary
            }
          />
        )}
      </Box>

      <Popover
        elevation={0}
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        onClick={handlePopoverClick}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Box className={styles.contactPopupContainerStyle}>
          <Box className={styles.popupHeader}>
            <h4 className={styles.h4}>{title}</h4>
            <CloseXIcon
              width="20"
              height="20"
              fill={palette.foreground.disabled}
              style={{ cursor: "pointer" }}
              onClick={handleClose}
            />
          </Box>

          <Box
            className={clsx({
              [styles.test]: isSpecialDateField(title),
            })}
          >
            <Box
              className={clsx({
                [styles.creationDateMargin]: isSpecialDateField(title),
                [styles.defaultMargin]: !isSpecialDateField(title),
              })}
            >
              <Box className={clsx(styles.customInput)}>
                <CustomSelect
                  variant="custom"
                  onValueChange={setSelectedValue}
                  emptyItem={{ id: 0, name: "Select", isDisabled: true }}
                  defaultValue={1}
                  options={Object.values(
                    NewTableFilterOperatorsEnum(palette.text)
                  )}
                  IconComponent={<FilterEqualIcon />}
                  value={selectedValue}
                  getOptionLabel={(option) => option.name}
                  getOptionValue={(option) => option.id}
                />

                <input
                  className={clsx(styles.BasicInput, {
                    [styles.placeholder]: !inputValue,
                  })}
                  type="text"
                  placeholder={
                    title === "Creation Date" || title === "Updated Date"
                      ? "yyyy/mm/dd"
                      : `Enter ${title}`
                  }
                  value={inputValue}
                  onChange={(e) => setInputValue(e.target.value)}
                />
              </Box>

              {isSpecialDateField(title) && (
                <Box className={styles.marginTop}>
                  <h4 className={styles.h4}>
                    {title === "Creation Date" ? "CreatedBy" : "UpdatedBy"}
                  </h4>
                  <Box className={styles.customInput}>
                    <CustomSelect
                      variant="custom"
                      onValueChange={setSelectedNameValue}
                      emptyItem={{ id: 0, name: "Select", isDisabled: true }}
                      defaultValue={1}
                      options={Object.values(
                        NewTableFilterOperatorsEnum(palette.text)
                      )}
                      IconComponent={<FilterEqualIcon />}
                      value={selectedNameValue}
                      getOptionLabel={(option) => option.name}
                      getOptionValue={(option) => option.id}
                    />

                    <input
                      className={clsx(styles.BasicInput, {
                        [styles.placeholder]: !inputNameValue,
                      })}
                      type="text"
                      placeholder={`Enter ${
                        title === "Creation Date" ? "CreatedBy" : "UpdatedBy"
                      } Name`}
                      value={inputNameValue}
                      onChange={(e) => setInputNameValue(e.target.value)}
                    />
                  </Box>
                </Box>
              )}
            </Box>
          </Box>

          <Box className={styles.actions}>
            <CustomButton
              boxShadow="xs"
              size="popupAction"
              variant="outlined"
              color="secondary"
              onClick={handleClose}
            >
              Cancel
            </CustomButton>
            <CustomButton
              disabled={
                !(
                  selectedValue &&
                  inputValue.trim().length > 0 &&
                  (!isSpecialDateField(title) ||
                    (selectedNameValue && inputNameValue.trim().length > 0))
                )
              }
              boxShadow="xs"
              size="popupAction"
              variant="contained"
              color="primary"
              onClick={handleConfirmClick}
            >
              Confirm
            </CustomButton>
          </Box>
        </Box>
      </Popover>
    </>
  );
};

export default CustomTableHeader;
