
export const CallCenterAgentDashboardPermissions = {
  CallCenterAgentDashboard: 
  {
    permissionsId:'83f42f40-d628-44eb-a89b-08da6e2b177d',
    permissionsName:'Call CentermAgent' , 
    componentsId:null,
    components:null
  }
  } ;
  