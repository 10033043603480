import React, { useState, useEffect, useReducer , useCallback  } from "react";
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { ButtonBase } from '@material-ui/core';
import { Spinner, Tables } from '../../../../../../Components';
import { TableActions , ScopeDocumentEnum } from '../../../../../../Enums';
import { getDownloadableLink, GetParams, getIsValidURL, getIsValidDataURL, showError , showSuccess  , returnPropsByPermissions  } from '../../../../../../Helper';
import {   CreateDeveloperContactDocumentsAPI  , GetDeveloperContactDocumentsAPI  , GetDeveloperContactDocumentByIdAPI  , UpdateDeveloperContactDocumentsAPI} from '../../../../../../Services';
import { DeveloperProfileDocumentManagementDialog } from './Dialogs';
import { MediaPreviewDialog } from '../../../../TemplatesView/Dialogs';
import { DeleteDocumentDialog } from '../../../../UnitsView/UnitsProfileManagementView/Sections/UnitProfileDocumentsComponent/DeleteDocumentDialog';
import { useLocation } from "react-router-dom";
import { ShareDocumentsDialog } from '../../../../../../SharedComponents/ShareDocumentsDialog/ShareDocumentsDialog';
import { DevelopersPermissions  } from '../../../../../../Permissions'


export const DeveloperContactDocumentsComponent = ({ parentTranslationPath, translationPath }) => {
  const { t } = useTranslation(parentTranslationPath);
  const location = useLocation();
  const [developerFiles , setDeveloperFile] = useState([]) ;
  const [isCanUploadFiles , setIsCanUploadFiles] = useState(false) ; 
  const [ isSubmitted , setIsSubmitted] = useState(false) ;
  const [filter, setFilter] = useState({
    pageSize: 25,
    pageIndex: 0,
    scopeTypeId : ScopeDocumentEnum.DeveloperDocument.scopeTypeId

  });
  const [isLoading, setIsLoading] = useState({
    allDeveloperDocuments: false,
    developerDocumentInfo : false ,
    addDeveloperDocuments: false,
    info : false 

  });

  const [items, setItems] = useState({
    allAuditAndCompliance: {
      result: [],
      totalCount: 0,
    },
  });
  
  const [defaultState, setDefaultState] = useState({
    developerContactId: +GetParams("id"),
    documents : [] ,
    files: null,
    documentTitle : null 
  });

  const [defaultSelected, setDefaultSelected] = useState({
    documents: [],
  });

  const reducer = (select, action) => {
    if (action.id !== "edit") return { ...select, [action.id]: action.value };
    if (action.id === "edit") {
      return {
        ...action.value,
      };
    }
  };

  const reducer2 = (select, action) => {
    if (action.id !== "edit") return { ...select, [action.id]: action.value };
    if (action.id === "edit") {
      return {
        ...action.value,
      };
    }
  };

  const [state, setState] = useReducer(reducer, {
    ...defaultState,
  });

  const [selected, setSelected] = useReducer(reducer2, {
    ...defaultSelected,
  });

  const [isOpenPreviewDialog, setIsOpenPreviewDialog] = useState(false);
  const [activeItem, setActiveItem] = useState(null);
  const [tableActions, setTableActions] = useState([])
  const [isOpenDeleteDialog, setIsOpenDeleteDialog] = useState(false);
  const [response, setResponse] = useState({ result: [], totalCount: 0 });
  const [developerId, setDeveloperId] = useState(+GetParams('id') || null);
  const [isOpenManagementDialog, setIsOpenManagementDialog] = useState(false);
  const [isOpenShareDialog, setIsOpenShareDialog] = useState(false);

  const onPageIndexChanged = (pageIndex) => {
    setFilter((item) => ({ ...item, pageIndex }));
  };
  const onPageSizeChanged = (pageSize) => {
    setFilter((item) => ({ ...item, pageIndex: 0, pageSize }));
  };


  const GetDeveloperContactDocuments = useCallback(async () => {
    setIsLoading((item) => ({ ...item, allDeveloperDocuments: true }));
    const result = await GetDeveloperContactDocumentsAPI({ ...filter , pageIndex : filter.pageIndex + 1 , developerContactId : developerId });
    if (!(result && result.status && result.status !== 200)) {
      setResponse({
        result: (result && result.result) || [],
        totalCount: (result && result.totalCount) || 0,
      });
    } else {
      setResponse({
        result: [],
        totalCount: 0,
      });
    }
    setIsLoading((item) => ({ ...item, allDeveloperDocuments: false }));
  }, [filter]);

  const onChangeHandler = (documentsActiveItem) => {
    let body = null ; 
    if (documentsActiveItem) {
       body = {
           developerContactId  : developerId , 
           documents : documentsActiveItem?.files && documentsActiveItem.files?.length > 0 ? 
           documentsActiveItem.files.map((file) => ({ 
          documentCategoryId :  documentsActiveItem.categoryId ,
          documentName : documentsActiveItem.documentTitle , 
          documentRemarks : documentsActiveItem.documentRemarks  ,
          fileId :  file.fileId || file.uuid  , 
          documentLink : file.documentLink , 
          fileName : file.fileName  
         })) : [] , 
      }
    }
    if (activeItem)
     {
      let  allDeveloperDocuments = response && response.result && response.result.filter((f)=> f.documentName  !==  activeItem?.documentTitle) ;
       let list  = allDeveloperDocuments && allDeveloperDocuments.length > 0 ? 
       allDeveloperDocuments.map((document) => ({ 
       documentCategoryId :  document.documentCategoryId ,
       documentName : document.documentName , 
       documentRemarks : document.documentRemarks  ,
       fileId :  document.fileId || document.uuid  , 
       documentLink : document.documentLink , 
       fileName : document.fileName  
      })) : [] ;

    body.documents = [...allDeveloperDocuments , ...body.documents   ];
    }
    return body ; 
    
  }; 

  const openLink = (url)=>{
    if (!url.match(/^https?:\/\//i)) url = 'http://' + url;
    if(getIsValidURL(url)){
        window.open(url || '');
    } else if(getIsValidDataURL(url)){
        let windowObj = window.open();
        const iframeElement = `<iframe src="${url}" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>`
        windowObj.document.write(iframeElement);
    }
}

  const focusedRowChanged = (rowIndex, item) => {
    if (rowIndex !== -1) {
      let newItems = [] ;
      const defaultTableActions = getDefaultActionsWithPermissions();

      if(item && item.documentLink){
        setTableActions([...defaultTableActions, {
          enum: TableActions.openLinkText.key,
          isDisabled: false,
          externalComponent: null,
        }]);
      }else {
        newItems.push({
          enum: TableActions.openFile.key,
          isDisabled: false,
          externalComponent: null,
        });
        if(returnPropsByPermissions(DevelopersPermissions.DownloadDocuments.permissionsId))
            newItems.push({
             enum: TableActions.downloadText.key,
             isDisabled: false,
             externalComponent: null,
         });
        setTableActions([...defaultTableActions , ...newItems ]) ; 
        
  
      }
    }
  };

  const getDefaultActionsWithPermissions = () => {
    const list = [];
    if(returnPropsByPermissions(DevelopersPermissions.EditDocuments.permissionsId))

      list.push({
        enum: TableActions.editText.key,
        isDisabled: false,
        externalComponent: null,
      })

      if(returnPropsByPermissions(DevelopersPermissions.DeletDocuments.permissionsId))
       list.push({
        enum: TableActions.deleteText.key,
        isDisabled: false,
        externalComponent: null,
      });

      if(returnPropsByPermissions(DevelopersPermissions.ShareDocuments.permissionsId))
      list.push({
       enum: TableActions.share.key,
       isDisabled: false,
       externalComponent: null,
     });
    return list;
  };
  const tableActionClicked = useCallback((actionEnum, item) => {
    let developerDocuments = [] ;
    setActiveItem({
      templateFileId: item.documentId || item.fileId|| item.uuid || null,
      templateFileName: item.fileName || item.fullfileName , 
      templateText: '',
      scopeDocumentId: item.scopeDocumentId,
      documentName: item.fileName,
      documentTitle : item.documentName , 
      documentRemarks : item.documentRemarks , 
      documentLink: item.documentLink,
      categoryId: item.documentCategoryId,
      categoryName : item.documentCategory  , 
      documentId: item.documentId || item.uuid || item.fileId || null,
      developerContactDocumentId  : item.developerContactDocumentId 
    });
    
    if(item.documentName)
    { 
      developerDocuments = (response.result  && response.result && response.result.filter((f)=> f.documentName === item.documentName)) || [] ; 

    }
    setDeveloperFile([...developerDocuments]);




    if (actionEnum === TableActions.openFile.key)
    setIsOpenPreviewDialog(true);

    if (actionEnum === TableActions.openLinkText.key){
      try {
          openLink(item.documentLink)
      } catch (error) {
          showError(t(`${translationPath}this-link-is-not-valid`));
      }
      }

    if (actionEnum === TableActions.editText.key)
    setIsOpenManagementDialog(true);
    
    if (actionEnum === TableActions.deleteText.key)
    setIsOpenDeleteDialog(true);

    if (actionEnum === TableActions.share.key)
    setIsOpenShareDialog(true);
    
    if (actionEnum === TableActions.downloadText.key) {
      try {
        const link = document.createElement('a');
        // If you don't know the name or want to use
        // the webserver default set name = ''
        link.setAttribute('download', item.fileName ||  item.documentName);
        link.href = getDownloadableLink(item.documentId || item.fileId);
        document.body.appendChild(link);
        link.click();
        link.remove();
        setActiveItem(null);
      } catch (error) { }
    }
  }, [response]);

  const reloadData = () => {
    setFilter((item)=> ({ ...item , pageIndex : 0})) ; 
  }

  const onSaveDeveloper = async (developerDocuments) => {

    setIsSubmitted(true);
    if(developerDocuments && (developerDocuments.documentTitle === ''  || !developerDocuments.documentTitle )){
      showError(t(`${translationPath}please-select-document-title`));
      return ; 
    }
     
    if(developerDocuments && developerDocuments.files && developerDocuments.files.length === 0 ){
      showError(t(`${translationPath}please-select-files`));
      return ; 
    }
    setIsOpenManagementDialog(false);
    const  documentsBody   = onChangeHandler(developerDocuments);
    setIsLoading((item) => ({ ...item, addDeveloperDocuments:  true   , editDeveloperDocuments :  true  }));
    const res=  !activeItem ?  await CreateDeveloperContactDocumentsAPI(documentsBody) : await UpdateDeveloperContactDocumentsAPI(documentsBody);
    if (!(res && res.status && res.status !== 200)) {
      showSuccess(!activeItem ? (t`${translationPath}developer-documents-created-successfully`) : (t`${translationPath}developer-documents-update-successfully`)) ;
      setIsLoading((item) => ({ ...item, addDeveloperDocuments:  false    , editDeveloperDocuments : false  }));
      reloadData();
    } else {
      showError(!activeItem ?(t`${translationPath}developer-documents-create-failed`) : (t`${translationPath}developer-documents-update-failed`) );
      setIsLoading((item) => ({ ...item, addDeveloperDocuments:  false   , editDeveloperDocuments : false   }));
    }
    if(activeItem)
      setActiveItem(null) ; 
  };

  const GetDeveloperContactDocumentById = useCallback(async (documentId) => {
    if(activeItem && activeItem){
      setIsLoading((item) => ({ ...item, info: true }));


      const result = await GetDeveloperContactDocumentByIdAPI(documentId);
      if (!(result && result.status && result.status !== 200)) {
       
      }
      setIsLoading((item) => ({ ...item, info: false }));

    }

  }, [activeItem]);

  useEffect(() => {
    if (activeItem) {
      GetDeveloperContactDocumentById(activeItem.developerContactDocumentId);
    }
  }, [activeItem]);



  useEffect(() => {
    if (developerId) GetDeveloperContactDocuments();
  }, [GetDeveloperContactDocuments , filter]);

  useEffect(() => {
    setDeveloperId(+GetParams('id'));
  }, [location]);

  useEffect(()=>{
    setTableActions(getDefaultActionsWithPermissions());
    setIsCanUploadFiles(returnPropsByPermissions(DevelopersPermissions.UploadDocuments.permissionsId)) ; 
  }, [])

  return (
    <div className='associated-contacts-wrapper childs-wrapper'>
      <Spinner isActive={isLoading.addDeveloperDocuments || isLoading.allDeveloperDocuments ||  isLoading.editDeveloperDocuments} isAbsolute />
      <div className='title-section'>
        <span>{t(`${translationPath}developer-documents`)}</span>
      </div>
      <div className='filter-section px-2 mb-3'>
        <div className='section'>
          <ButtonBase
            className='btns theme-solid px-3'
            onClick={() => {
              setIsOpenManagementDialog(true);
            }}
          >
            <span className='mdi mdi-plus' />
            {t(`${translationPath}add-developer-document`)}
          </ButtonBase>
        </div>
      </div>
      <div className='w-100 px-2'>
        <Tables
          data={(response && response.result) || []}
          headerData={[
            {
              id: 1,
              label: t(`${translationPath}category`),
              input: 'documentCategory'
            },
            {
              id: 2,
              label: t(`${translationPath}documentName`),
              component: (item) => (
                <span>
                  {item.documentName||item.documentLink}
                </span>
              ),
            },
            {
              id: 3,
              label: t(`${translationPath}file-name`),
              component: (item) => (
                <span>
                  {item.fileName||item.documentLink}
                </span>
              ),
            },
            {
              id: 4,
              label: t(`${translationPath}remarks`),
              component: (item) => (
                <span>
                  {item.remarks||item.documentRemarks || ''}
                </span>
              ),
            },

            {
              id: 5,
              label: t(`${translationPath}created-date`),
              isDate: true,
              input: 'createdOn',
              dateFormat: 'DD/MM/YYYY',

            },
            {
              id: 6,
              label: t(`${translationPath}created-by`),
              input: 'createdBy',
            }]}
          onPageIndexChanged={onPageIndexChanged}
          onPageSizeChanged={onPageSizeChanged}
          defaultActions={tableActions}
          focusedRowChanged={focusedRowChanged}
          actionsOptions={{
            onActionClicked: tableActionClicked,
          }}
          itemsPerPage={filter.pageSize}
          activePage={filter.pageIndex}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          totalItems={response && response.totalCount}
        />
      </div>
      {isOpenManagementDialog && (
        <DeveloperProfileDocumentManagementDialog
        developerId={developerId}
          activeItem={activeItem}
          developerFiles={developerFiles}
          isOpen={isOpenManagementDialog}
          isOpenChanged={() => {
            setIsOpenManagementDialog(false);
            if(activeItem)
            setActiveItem(null);
          }}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          isDeveloperDocuments
          onSaveDeveloper={(developerDocuments)=> onSaveDeveloper(developerDocuments)}
          isDeveloperContact
          otherSubmitted={isSubmitted}
          isCanUploadFiles={isCanUploadFiles}
        />
      )}
      {isOpenPreviewDialog && (
        <MediaPreviewDialog
          activeItem={activeItem}
          maintitleText='FILE-VIEW'
          isOpen={isOpenPreviewDialog}
          isOpenChanged={() => {
            setIsOpenPreviewDialog(false);
            setActiveItem(null);
          }}
          parentTranslationPath='Shared'
          translationPath={translationPath}
        />
      )}
      {isOpenDeleteDialog && (
        <DeleteDocumentDialog
          activeItem={activeItem}
          open={isOpenDeleteDialog}
          close={() => {
            setIsOpenDeleteDialog(false);
            setActiveItem(null);
          }}
          onSave={() => {
            setIsOpenDeleteDialog(false);
            setActiveItem(null);
            setFilter((item) => ({ ...item, pageIndex: 0 }));
          }}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          isDeveloperContact
        />
      )}
      {
        isOpenShareDialog &&
        <ShareDocumentsDialog
        open={isOpenShareDialog}
        activeItem={activeItem}
        onSave={() => {
          setIsOpenShareDialog(false);
        }}
        close={() => setIsOpenShareDialog(false)}
        parentTranslationPath={parentTranslationPath}
        translationPath={translationPath}
        developerId={developerId}
        isFromDeveloper={true}
        />
      }
    </div>
  );
};
DeveloperContactDocumentsComponent.propTypes = {
  parentTranslationPath: PropTypes.string.isRequired,
  translationPath: PropTypes.string.isRequired,
};
