import React, { useState, useEffect, useRef } from "react";
import clsx from "clsx";
import { useDebounce, useSelectedTheme } from "../../../Hooks";

// Icons
import { SearchIcon } from "../../../assets/icons";

// Styles
import useStyles from "./styles";

function CustomInput(props) {
  const { debounceTime, hasSearchIcon, onChange, handleClick, disabled, style,
    CustomClass, hasNoBorder, handleOnBlur, inputContainerOverrideStyles, ...restProps } = props;
  
  const styles = useStyles();
  
  const {
    theme: { palette },
  } = useSelectedTheme();

  const [inputValue, setInputValue] = useState(restProps.value || "");
  const debouncedValue = useDebounce(inputValue, debounceTime);
  const [isFocused, setIsFocused] = useState(false);
  const inputRef = useRef(null);

  useEffect(() => {
    setInputValue(restProps.value || "");

    if (!restProps.value) {
      setIsFocused(false);
    }
  }, [restProps.value]);

  const isInitialRender = useRef(true); // Track if it's the initial render

  useEffect(() => {
    if (isInitialRender.current) {
      isInitialRender.current = false;
      return; // Skip the effect on the initial render
    }

    if (debounceTime && onChange) {
      onChange({ target: { value: debouncedValue } });
    }
  }, [debouncedValue, debounceTime]);

  useEffect(() => {
    if (hasNoBorder && inputRef.current) {
      inputRef.current.focus();
    }
  }, [hasNoBorder]);

  const handleChange = (event) => {
    setInputValue(event.target.value);
    if (!debounceTime && onChange) {
      onChange(event);
    }
  };

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = (event) => {
    setIsFocused(false);

    if (handleOnBlur) {
      handleOnBlur(event);
    }
  };

  return (
    <div
      style={style}
      className={clsx(
        styles.inputContainer,
        {
          [styles.inputContainerFocused]: isFocused,
          [styles.inputContainerNoBorder]: hasNoBorder,
          [inputContainerOverrideStyles]: !!inputContainerOverrideStyles,
        },
        CustomClass
      )}
    >
      {hasSearchIcon && (
        <SearchIcon
          width="20"
          height="20"
          fill={palette.foreground.quarterary}
        />
      )}

      <input
        type={restProps.type}
        placeholder={restProps.placeholder}
        value={inputValue}
        onChange={handleChange}
        onClick={handleClick}
        name={restProps.name}
        disabled={disabled}
        onFocus={handleFocus}
        onBlur={handleBlur}
        ref={inputRef}
      />
    </div>
  );
}

CustomInput.defaultProps = {
  disabled: false,
  debounceTime: 0,
  hasSearchIcon: true,
  onChange: undefined,
  handleClick: undefined,
  style: {},
  CustomClass: "",
  hasNoBorder: false,
  handleOnBlur: undefined,
  inputContainerOverrideStyles: undefined,
};

export default CustomInput;
