import React, {
  useEffect, useCallback, useState, useRef
} from 'react';
import { Button } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useTitle } from '../../../../Hooks';
import { Inputs } from '../../../../Components/Controls';
import {
  Spinner,
  ViewTypes,
  PermissionsComponent
} from '../../../../Components';
import {
  BranchCardsComponent, BranchListComponent, BranchDeactiveDialog, BranchManagmentDialog
} from './BranchUtilities';
import {
  ViewTypesEnum, ActionsEnum, TableActions, CityTypeIdEnum
} from '../../../../Enums';
import {
  GetAllBranches,
  lookupItemsGetId,
  SetBranchAsActiveOrInactive,
} from '../../../../Services';
import { returnPropsByPermissions, showError, showSuccess } from '../../../../Helper';

import { BranchesPermissions } from '../../../../Permissions';

const BranchView = () => {
  const [viewTypes, setViewTypes] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [isOpenDeactive, setIsOpenDeactive] = useState(false);
  const [branches, setBranches] = useState({
    result: [],
    totalCount: 0
  });
  const [activeItem, setActiveItem] = useState(null);
  const [countriesData, setCountriesData] = useState([]);
  const [citiesData, setCitiesData] = useState([]);
  const [countries, setCountries] = useState([]);
  const [cities, setCities] = useState([]);
  const [staticSearch, setStaticSearch] = useState(null);
  const [sortBy, setSortBy] = useState(null);
  const [filter, setFilter] = useState({
    pageSize: parseInt(localStorage.getItem('Pagination')) || 25,
    pageIndex: 0,
    branchName: '',
    filterBy: null,
    orderBy: null,

  });
  const translationPath = 'Branches.';
  const parentTranslationPath = 'BranchView';
  const { t } = useTranslation(parentTranslationPath);

  useTitle(t(`${translationPath}branches`));

  const activateBranch = useCallback(async (branchId) => {
    setIsLoading(true);
    const res = await SetBranchAsActiveOrInactive({ branchId });
    if (!(res && res.status && res.status !== 200)) {
      showSuccess(t(`${translationPath}branch-activated-successfully`));
      setActiveItem(null);
    } else showError(t(`${translationPath}branch-activation-failed`));
    setIsLoading(false);
    reloadData();
  }, []);

  const actionClicked = useCallback(
    (actionEnum, item) => async (event) => {
      event.stopPropagation();
      setActiveItem(item);

      if (actionEnum === ActionsEnum.edit.key || actionEnum === TableActions.edit.key)
        setOpenDialog(true);
      else if (actionEnum === ActionsEnum.activate.key || actionEnum === TableActions.check.key)
        activateBranch(item.branchId);
      else if (actionEnum === ActionsEnum.deactivate.key || actionEnum === TableActions.close.key)
        setIsOpenDeactive(true);
    }, [activateBranch]
  );

  const onTypeChanged = (activeType) => {
    setViewTypes(activeType);
  };

  const addNewBranch = () => {
    setOpenDialog(true);
  };

  const getAllBranches = useCallback(async () => {
    if (returnPropsByPermissions(BranchesPermissions.GetAllBranches.permissionsId)) {
      setIsLoading(true);
      const res = await GetAllBranches({...filter, pageSize: 25, pageIndex: 1, branchName: staticSearch });
      if (!(res && res.status && res.status !== 200)) setBranches({
          result: res.result,
          totalCount: res.totalCount
        });
      else setBranches({
          result: [],
          totalCount: 0
        });

      setIsLoading(false);
    }
  }, [staticSearch, filter]);

  const reloadData = useCallback(() => {
    setIsOpenDeactive(false);
    setActiveItem(null);
    getAllBranches();
  });

  const getCountriesLookups = async () => {
    const res = await lookupItemsGetId({ lookupTypeId: 16 });
    if (!(res && res.status && res.status !== 200)) setCountriesData(res || []);
    else setCountriesData([]);
  };

  const getCitiesLookups = async () => {
    const res = await lookupItemsGetId({ lookupTypeId: CityTypeIdEnum.lookupTypeId });
    if (!(res && res.status && res.status !== 200)) setCitiesData(res || []);
    else setCitiesData([]);
  };


  const getCountriesCitiesIdName = () => {
    const countriesObj = {};
    countriesData &&
      countriesData.length &&
      countriesData.forEach((item) => {
        countriesObj[`${item.lookupItemId}`] = item.lookupItemName;
      });
    const citiesObj = {};
    citiesData &&
      citiesData.length &&
      citiesData.forEach((item) => {
        citiesObj[`${item.lookupItemId}`] = item.lookupItemName;
      });

    setCountries(countriesObj);
    setCities(citiesObj);
  };

  useEffect(() => {
    if (sortBy) {
      setFilter((item) => ({
        ...item, filterBy: sortBy.filterBy, orderBy: sortBy.orderBy, search: '', pageIndex: 0
      }));
    }
  }, [sortBy]);

  useEffect(() => {
    getCountriesLookups();
    getCitiesLookups();
  }, []);

  useEffect(() => {
    getCountriesCitiesIdName();
  }, [countriesData, citiesData]);

  useEffect(() => {
    getAllBranches()
  }, [getAllBranches]);
  
  return (
    <>
      <div className='view-wrapper'>
        <Spinner isActive={isLoading} />

        <div className='header-section'>
          <div className='filter-section px-2'>

            <div className='section'>
              <PermissionsComponent
                permissionsList={Object.values(BranchesPermissions)}
                permissionsId={BranchesPermissions.AddNewBranch.permissionsId}
              >
                <Button className='btns theme-solid' onClick={addNewBranch}>
                  <span className='mdi mdi-plus' />
                  {t(`${translationPath}add-new-branch`)}
                </Button>
              </PermissionsComponent>
            </div>

            <PermissionsComponent
              permissionsList={Object.values(BranchesPermissions)}
              permissionsId={BranchesPermissions.GetAllBranches.permissionsId}
            >
              <div className='section px-2'>
                <div className='d-flex-column p-relative'>
                  <Inputs
                    idRef='usersSearchRef'
                    variant='outlined'
                    fieldClasses='inputs theme-solid'
                    beforeIconClasses='mdi mdi-magnify mdi-24px c-gray-primary'
                    inputPlaceholder={t(`${translationPath}quick-search-branches`)}
                    onInputChanged={(e) => setStaticSearch(e.target.value)}
                  />
                </div>
                <div>
                  <ViewTypes
                    onTypeChanged={onTypeChanged}
                    initialActiveType={viewTypes}
                    activeTypes={[ViewTypesEnum.cards.key, ViewTypesEnum.tableView.key]}
                    className='mb-3'
                  />
                </div>
              </div>
            </PermissionsComponent>
          </div>
        </div>
        <PermissionsComponent
          permissionsList={Object.values(BranchesPermissions)}
          permissionsId={BranchesPermissions.GetAllBranches.permissionsId}
        >
          {viewTypes === ViewTypesEnum.tableView.key && (
            <BranchListComponent
              activeItem={activeItem}
              data={branches.result || []}
              countries={countries}
              cities={cities}
              setSortBy={setSortBy}
              actionClicked={(actionEnum, item, focusedRow, event) =>
                actionClicked(actionEnum, item)(event)}
              translationPath={translationPath}
              parentTranslationPath={parentTranslationPath}
              staticSearch={staticSearch}
            />
          )}
        </PermissionsComponent>
        <PermissionsComponent
          permissionsList={Object.values(BranchesPermissions)}
          permissionsId={BranchesPermissions.GetAllBranches.permissionsId}
        >
          {viewTypes !== ViewTypesEnum.tableView.key && (
            <BranchCardsComponent
              data={branches.result}
              activeItem={activeItem}
              countries={countries}
              cities={cities}
              actionClicked={actionClicked}
              translationPath={translationPath}
              parentTranslationPath={parentTranslationPath}
              staticSearch={staticSearch}
            />
          )}
        </PermissionsComponent>

        {openDialog && (
          <BranchManagmentDialog
            activeItem={activeItem}
            setActiveItem={setActiveItem}
            open={openDialog}
            onSave={() => {
              setOpenDialog(false);
              setActiveItem(null);
              reloadData();
            }}
            close={() => {
              setOpenDialog(false);
              setActiveItem(null);
            }}
            reloadData={reloadData}
            translationPath={translationPath}
            parentTranslationPath={parentTranslationPath}
          />
        )}

        {activeItem && (
          <BranchDeactiveDialog
            activeItem={activeItem}
            isOpen={isOpenDeactive}
            isOpenChanged={() => {
              setIsOpenDeactive(false);
              setActiveItem(null);
            }}
            reloadData={reloadData}
            translationPath={translationPath}
            parentTranslationPath={parentTranslationPath}
          />
        )}
      </div>
    </>
  );
};

export { BranchView };
