import React from "react";
import "../MasterStyleDashborads/MasterStyleDashboards.scss";
import { PowerBiEnum } from "../../../../Enums";
import { AgentsDashboardPolicy } from "../AgentsDashboardPolicy";
import { MainDashboardFilterDate } from "../MainDashboardFilterDate/MainDashboardFilterDate";

export const CallCenterAgent = () => {
 return (
  <div>
   
        <MainDashboardFilterDate
          id={PowerBiEnum.callCenterDate.reportid}
          embedUrl={PowerBiEnum.callCenterDate.url}
        />
    
    </div>
  );
};
