import React from 'react';
import { Inputs, SelectComponet } from '../../../../../../Components';

export const SLALApplyForComponent = ({
    parentTranslationPath,
    translationPath,
    state,
    setState,
}) => {
    const slaAppliedFor = [
        {
            key: 1,
            value: 'OnlyForBulkLeads',
        },
        {
            key: 2,
            value: 'OnlyForFreshLeads',
        },
        {
            key: 3,
            value: 'ForBothBulkAndFreshLeads',
        },
    ];

    return (
        <SelectComponet
            idRef='slaAppliedForRef'
            labelValue="SLAAppliedFor"
            labelClasses='Requierd-Color'
            data={slaAppliedFor}
            value={state.slaAppliedFor}
            valueInput='key'
            textInput='value'
            onSelectChanged={(value) => {
                setState({
                    slaAppliedFor: +value,
                });
            }}
            wrapperClasses='my-2'
            parentTranslationPath={parentTranslationPath}
            translationPath={translationPath}
            translationPathForData={translationPath}
        />

    );
};
