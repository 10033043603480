export const TableFilterOptionsSelectUserStatusEnum =
    [
        {
            key: 1,
            value: 'Pending',
        },
        {
            key: 2,
            value: 'Active',
        },
        {
            key: 3,
            value: 'Deactivated',
        },
    ];
