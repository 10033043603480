import React, { useEffect, useState } from "react";
import {
  DialogComponent,
  Inputs,
  LoadableImageComponant,
  Spinner,
} from "../../../../../Components";
import { GetEvidenceByLogId } from "../../../../../Services";
import { Button, DialogActions, withStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { getDownloadableLink } from "../../../../../Helper";
import LinkIcon from "@material-ui/icons/Link";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";

const CancelButton = withStyles(() => ({
  root: {
    color: "#000000",
    backgroundColor: "#",
    border: "1px solid #e0e0e0",
    borderRadius: "8px",
    "&:hover": {
      backgroundColor: "#f5f5f5",
    },
  },
}))(Button);

function EvidenceDialog({
  isOpen,
  onClose,
  activeItem,
  translationPath,
  parentTranslationPath,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [copied, setCopied] = useState(false);
  const { t } = useTranslation(parentTranslationPath);

  const GetEvidence = async () => {
    setIsLoading(true);
    const res = await GetEvidenceByLogId(activeItem?.consentAndDNCRLogId);

    if (!(res && res.status && res.status !== 200)) {
      setData(res);
    } else setData([]);
    setIsLoading(false);
  };

  function onCopyLinkClicked(item) {
    navigator.clipboard
      .writeText(item.evidenceLink || "")
      .then(() => {
        setCopied(true);
        setTimeout(() => setCopied(false), 2000);
      })
      .catch((err) => {
        console.error("Failed to copy text: ", err);
      });
  }

  useEffect(() => {
    GetEvidence();
  }, []);

  return (
    <React.Fragment>
      <Spinner isActive={isLoading} isAbsolute />
      <div>
        <DialogComponent
          isOpen={isOpen}
          dialogTitle={t("evidence")}
          maxWidth={"lg"}
          dialogContent={
            <React.Fragment>
              {data.map((item) => (
                <React.Fragment key={item.fileId}>
                  <div>
                    {item.fileName && item.fileId && (
                      <div className="d-flex w-100 my-4 mx-3">
                        <div className="w-25 mx-4">{t("image-evidence")}</div>
                        <div className="w-50 mx-4">
                          <LoadableImageComponant
                            classes="cover-image"
                            src={getDownloadableLink(item?.fileId)}
                            alt={t(`${translationPath}image`)}
                            width={170}
                            height={170}
                          />
                        </div>
                      </div>
                    )}
                    {item.evidenceLink && (
                      <div>
                        <div className="d-flex w-100 my-4 mx-3">
                          <div className="w-25 mx-4">{t("link-evidence")}</div>
                          <div className="w-50 mx-4">
                            <Inputs
                              idRef="linkRef"
                              value={item?.evidenceLink || ""}
                              isDisabled={true}
                              endAdornment={
                                <div className="mr-2 mt-1">
                                  {copied ? (
                                    <CheckCircleIcon
                                      className="pointer"
                                      color="primary"
                                    />
                                  ) : (
                                    <LinkIcon
                                      className="pointer"
                                      color="primary"
                                      onClick={() => onCopyLinkClicked(item)}
                                    />
                                  )}
                                </div>
                              }
                              translationPath={translationPath}
                              parentTranslationPath={parentTranslationPath}
                            />
                          </div>
                        </div>
                      </div>
                    )}
                    {item.writtenEvidence && (
                      <div>
                        <div className="d-flex w-100 my-4 mx-3">
                          <div className="w-25 mx-4">
                            {t("written-evidence")}
                          </div>
                          <div className="w-50 mx-4">
                            <Inputs
                              idRef="evidenceRef"
                              labelValue={t(`written-evidence`)}
                              value={item?.writtenEvidence || ""}
                              multiline
                              rows={6}
                              isDisabled={true}
                              translationPath={translationPath}
                              parentTranslationPath={parentTranslationPath}
                            />
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </React.Fragment>
              ))}
              <DialogActions>
                <CancelButton onClick={(e) => onClose()}>
                  {t("cancel")}
                </CancelButton>
              </DialogActions>
            </React.Fragment>
          }
        />
      </div>
    </React.Fragment>
  );
}

export default EvidenceDialog;
