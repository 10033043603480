import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { Box, Typography, Divider } from "@material-ui/core";

// styles
import useStyles from "./styles";

function InfoSection({ title, subTitle, hideDevider, children, fullWidthDivider }) {
  const styles = useStyles({ fullWidthDivider });

  return (
    <Box className={styles.infoSectionWrapper}>
      <Typography
        className={styles.title}
        variant="h6"
        component="h3"
      >{title}</Typography>

      {subTitle && <Typography
        className={styles.subTitle}
        variant="h6"
        component="h3"
      >{subTitle}</Typography>}

      {!hideDevider && <Divider className={clsx(styles.divider)} light />}

      <Box className={styles.infoItemsWrapper}>
        {children}
      </Box>
    </Box>
  );
}

InfoSection.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string,
  hideDevider: PropTypes.bool,
  fullWidthDivider: PropTypes.bool,
};

InfoSection.defaultProps = {
  title: "",
  hideDevider: false,
  fullWidthDivider: false,
};

export default InfoSection;
