

import React from 'react';
import { useTranslation } from 'react-i18next'; 
import moment from 'moment';
import { DatePickerComponent } from '../../Components'

export const DateItemFilter = ({
    translationPath,
    parentTranslationPath,
    item , 
    index,
    filtersWithValues , 
    setFiltersWithValues , 
    changeOperator , 
}) => {
    const { t } = useTranslation('Shared');

    return (
        <> 
          <DatePickerComponent
                idRef={item.input}
                format='YYYY/MM/DD'
                labelValue={item.label}
                value={(item.displayObj && moment(item.displayObj).isValid() &&  moment(item.displayObj).format('YYYY/MM/DD'))}
                onDateChanged={(e) => 
                {

                 const updatelist = [...filtersWithValues];
                   updatelist[index] = {
                    ...updatelist[index],
                    displayValue: (e && moment(e).format('YYYY/MM/DD')) || null,
                    displayObj  : (e && moment(e).format('YYYY/MM/DD')) || null, 

                   }
                   setFiltersWithValues(updatelist) ; 
                   changeOperator((e && moment(e).format('YYYY/MM/DD')) || null); 
                }}
                translationPath={translationPath}
                parentTranslationPath={parentTranslationPath}
              />
        </>
    );
};
