import React, {
  Fragment,
  useCallback,
  useEffect,
  useReducer,
  useState,
} from "react";
import {
  GetAllSystemTemplateByCategoryId,
  GetLeadStageActivityTypeForCustomDetails,
} from "../../../../../../../Services";
import {
  ReminderToEnum,
  TemplateCategoryEnum,
} from "../../../../../../../Enums";
import {
  DialogComponent,
  Inputs,
  SelectComponet,
  Spinner,
  SwitchComponent,
} from "../../../../../../../Components";
import {
  Button,
  Checkbox,
  DialogActions,
  FormControlLabel,
  RadioGroup,
  withStyles,
} from "@material-ui/core";
import { LeadScoreRadioButtons } from "../../../../../ActivitiesTypeView/ActivitiesTypeManagementView/DialogManagementViewComponent/DialogManagementConrtolComponent/LeadScoreRadioButtons";
import { useTranslation } from "react-i18next";
import "./CustomActivityDialog.scss";
import ReminderComponent from "./ReminderComponent";
import { showError } from "../../../../../../../Helper";
import { ReminderTimeOptions } from "../../../../../../../Enums/ReminderTimeOptions.enum";
import { ActivityRecipientTypesEnum } from "../../../../../../../Enums/ActivityRecipientTypes.Enum";
import { LeadScoreOptions } from "../../../../../../../Enums/LeadScoreOptions.Enum";
import Joi from "joi";

const LabelWithElement = ({ label, element }) => {
  return (
    <Fragment>
      <div className="activity-type-card__label-with-element py-3">
        <div className="activity-type-card__label">
          <span className="bolder lighter-font-color">{label}</span>
        </div>
        <div className="element-container">{element}</div>
      </div>
      <hr className="hr" />
    </Fragment>
  );
};

const ActivityDialogSection = ({ t, label, element, message }) => {
  return (
    <React.Fragment>
      <div className="activity-type-card__label-with-element py-3">
        <div className="activity-type-card__label">
          <span className="bolder lighter-font-color">
            <b>{label}</b>
          </span>
        </div>
        <div className="element-container">
          <React.Fragment>{element}</React.Fragment>
          <React.Fragment>
            <p className="mx-1">{t(message)}</p>
          </React.Fragment>
        </div>
      </div>
    </React.Fragment>
  );
};

const SubmitButton = withStyles(() => ({
  root: {
    color: "white",
    backgroundColor: "#84693f",
    border: "2px solid #84693f",
    borderRadius: "8px",
    "&:hover": {
      backgroundColor: "#6d5734",
    },
  },
}))(Button);

const ResetButton = withStyles(() => ({
  root: {
    color: "#84693f",
    backgroundColor: "#",
    border: "1px solid #84693f",
    borderRadius: "8px",
    "&:hover": {
      backgroundColor: "#f5f5f5",
    },
  },
}))(Button);

const CancelButton = withStyles(() => ({
  root: {
    color: "#000000",
    backgroundColor: "#",
    border: "1px solid #e0e0e0",
    borderRadius: "8px",
    "&:hover": {
      backgroundColor: "#f5f5f5",
    },
  },
}))(Button);

const GoldenCheckBox = withStyles({
  root: {
    color: "#84693f",
    fontSize: "10px",
    "&$checked": {
      color: "#84693f",
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

function CustomActivityDialog({
  isOpen,
  translationPath,
  parentTranslationPath,
  isCustomActivityOpen,
  setIsCustomActivityOpen,
  leadStageActivityTypeId,
  setLeadStageActivityTypeId,
  setIsOpenConfirmDialog,
  setBody,
  setActionName,
  reload,
  setReload,
  resetTrigger,
  resetData,
  setFetchedTemplates,
}) {
  const { t } = useTranslation(parentTranslationPath);
  const reducer = useCallback((state, action) => {
    if (action.id !== "edit") return { ...state, [action.id]: action.value };
    return {
      ...action.value,
    };
  }, []);

  const timeOptions = Object.values(ReminderTimeOptions);
  const roles = Object.values(ActivityRecipientTypesEnum);
  const scoreOperations = Object.values(LeadScoreOptions);

  const [schemaErrors, setSchemaErrors] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [reminders, setReminders] = useReducer(reducer, {
    withReminder: false,
    remindersData: [],
  });
  const [state, setState] = useReducer(reducer, {
    slaExpirationPeriod: 0,
    slaAppliedFor: "",
    slaAppliedForId: 0,
  });

  const [leadScoreData, setLeadScoreData] = useReducer(reducer, {
    leadScore: null,
    leadScoreOption: null,
    leadScoreOptionId: null,
  });

  const [slaTimerData, setSlaTimerData] = useReducer(reducer, {
    slaExpirationPeriod: 0,
    slaExpirationPeriodFreqency: timeOptions[0]?.value,
    slaExpirationPeriodFreqencyId: timeOptions[0]?.id,
  });

  const [selected, setSelected] = useReducer(reducer, {
    slaExpirationPeriodFreqencyId: timeOptions[0],
    template: null,
    reminderTime: 0,
    isWithReminder: false,
    selectedFrom: null,
    leadScoreOptionId: null,
    customActivityTypeReminderRecipient: [],
    slaExpirationPeriod: 0,
    slaExpirationPeriodComparisonDate: null,
    slaExpirationPeriodComparisonDateId: null,
  });

  const [data, setData] = useReducer(reducer, {
    templates: [],
  });
  const [recipientData, setRecipientData] = useReducer(reducer, []);
  const [selectedApplyOn, setSelectedApplyOn] = useState(0);

  const schema = Joi.object({
    reminderType: Joi.number()
      .required()
      .messages({
        "number.base": t(`${translationPath}please-select-reminder-type`),
        "number.min": t(`${translationPath}please-select-reminder-type`),
      }),
    reminderTime: Joi.number()
      .required()
      .messages({
        "number.base": t(`${translationPath}please-add-reminder-time`),
        "number.min": t(`${translationPath}please-add-reminder-time`),
      }),
    systemTemplateId: Joi.object({
      systemTemplateId: Joi.number()
        .required()
        .messages({
          "number.base": t(`${translationPath}please-select-template`),
          "number.min": t(`${translationPath}please-select-template`),
        }),
    }).options({ allowUnknown: true }),
  }).options({
    abortEarly: false,
    allowUnknown: true,
  });

  const Checkbox = ({ label, onClick, checked }) => (
    <FormControlLabel
      control={<GoldenCheckBox onClick={onClick} checked={checked} />}
      label={<span className="input-label">{label}</span>}
    />
  );

  const getAllSystemTemplateByCategoryId = useCallback(async () => {
    setIsLoading(true);
    const res = await GetAllSystemTemplateByCategoryId(
      TemplateCategoryEnum?.Activity?.key
    );
    if (!(res && res.status && res.status !== 200)) {
      setData({ id: "templates", value: res });
      setFetchedTemplates(res);
    } else {
      setData({ id: "templates", value: [] });
      setFetchedTemplates([]);
    }
    setIsLoading(false);
  }, []);

  const handleRadioChange = useCallback(
    (value, actionName) => {
      if (actionName === "leadScoreOptionId") {
        setLeadScoreData({
          id: actionName,
          value: leadScoreData.leadScoreOptionId === value ? null : value,
        });
        setLeadScoreData({
          id: "leadScoreOption",
          value: value === 1 ? "Add" : "Subtract",
        });
      } else if (actionName === "slaExpirationPeriodComparisonDateId") {
        const newSlaExpirationPeriodComparisonDateId = value;

        const newSlaExpirationPeriodComparisonDate =
          newSlaExpirationPeriodComparisonDateId === 1
            ? "ActivityDate"
            : newSlaExpirationPeriodComparisonDateId === 2
            ? "CreationDate"
            : null;

        setSelected({
          id: "edit",
          value: {
            ...selected,
            slaExpirationPeriodComparisonDate:
              newSlaExpirationPeriodComparisonDate,
            slaExpirationPeriodComparisonDateId:
              newSlaExpirationPeriodComparisonDateId,
          },
        });
        setSelected({
          id: "slaExpirationPeriodComparisonDate",
          value: value === 1 ? "ActivityDate" : "CreationDate",
        });
      }
    },
    [selected]
  );

  const handleCheckBoxChange = (value) => {
    let newSlaAppliedFor = state?.slaAppliedFor;
    let newSlaAppliedForId = state?.slaAppliedForId;

    if (state?.slaAppliedForId + value === 3) {
      newSlaAppliedFor = "ForBothBulkAndFreshLeads";
      newSlaAppliedForId = 3;
    } else if (state?.slaAppliedForId === 3) {
      newSlaAppliedForId = state?.slaAppliedForId - value;
      newSlaAppliedFor =
        newSlaAppliedForId === 1
          ? "OnlyForBulkLeads"
          : newSlaAppliedForId === 2
          ? "OnlyForFreshLeads"
          : "ForBothBulkAndFreshLeads";
    } else {
      newSlaAppliedForId = value;
      newSlaAppliedFor =
        newSlaAppliedForId === 1
          ? "OnlyForBulkLeads"
          : newSlaAppliedForId === 2
          ? "OnlyForFreshLeads"
          : "ForBothBulkAndFreshLeads";
    }

    setState({ id: "slaAppliedFor", value: newSlaAppliedFor });
    setState({ id: "slaAppliedForId", value: newSlaAppliedForId });
  };

  function submitHandler() {
    setActionName("submit");
    setIsLoading(true);
    setIsSubmitted(true);

    const validationErrors = reminders?.remindersData.reduce(
      (errors, data, index) => {
        const { error } = schema.validate(data);
        if (error) {
          errors.push({ index, error: error.details[0].message });
        }
        return errors;
      },
      []
    );

    setSchemaErrors(validationErrors);

    if (
      (!leadScoreData.leadScore && leadScoreData.leadScoreOptionId) ||
      (leadScoreData.leadScore && !leadScoreData.leadScoreOptionId)
    ) {
      showError(t("please-fill-lead-score"));
      setIsLoading(false);
      return;
    } else if (reminders.withReminder && validationErrors.length > 0) {
      showError(t("please-fill-all-reminders"));
      setIsLoading(false);
      return;
    } else {
      let reminderDataForBody = reminders?.remindersData.map((obj) => ({
        ...obj,
        leadStageActivityTypeId: leadStageActivityTypeId,
        systemTemplateId: obj?.systemTemplateId?.systemTemplateId,
        reminderTime:
          obj.slaExpirationPeriodFreqencyId === 2
            ? obj.reminderTime * 60
            : obj.slaExpirationPeriodFreqencyId === 3
            ? obj.reminderTime * 1440
            : obj.reminderTime,
      }));

      const newSlaAppliedFor =
        state.slaAppliedForId === 1
          ? "OnlyForBulkLeads"
          : state.slaAppliedForId === 2
          ? "OnlyForFreshLeads"
          : "ForBothBulkAndFreshLeads";

      setBody({
        slaAppliedFor: newSlaAppliedFor,
        slaAppliedForId: state.slaAppliedForId,
        slaExpirationPeriod: slaTimerData.slaExpirationPeriod || null,
        slaExpirationPeriodFreqency: slaTimerData.slaExpirationPeriodFreqency,
        slaExpirationPeriodComparisonDate:
          selected.slaExpirationPeriodComparisonDate || null,
        leadScoreOption: leadScoreData.leadScoreOption || null,
        leadScoreOptionId: leadScoreData.leadScoreOptionId || null,
        leadScore: leadScoreData.leadScore || null,
        withReminder: reminders.withReminder,
        slaExpirationPeriodComparisonDate:
          selected.slaExpirationPeriodComparisonDate,
        slaExpirationPeriodComparisonDateId:
          selected.slaExpirationPeriodComparisonDateId,
        slaExpirationPeriodFreqencyId:
          slaTimerData?.slaExpirationPeriodFreqencyId || 1,
        leadStageActivityTypeReminderDto: reminders.withReminder
          ? reminderDataForBody
          : [],
      });
      setIsOpenConfirmDialog(true);
    }

    setIsLoading(false);
  }

  function resetHandler() {
    setActionName("reset");
    setIsOpenConfirmDialog(true);
  }

  function cancelHandler() {
    setIsCustomActivityOpen(false);
  }

  const GetLeadStageActivityTypeForCustomDetailsAPICall = async () => {
    setIsLoading(true);

    try {
      const res = await GetLeadStageActivityTypeForCustomDetails(
        leadStageActivityTypeId
      );
      if (!(res && res.status && res.status !== 200)) {
        const data = res[0];

        const newReminderData = data?.leadStageActivityTypeReminders.map(
          (reminder) => ({
            leadStageActivityTypeId: leadStageActivityTypeId,
            reminderTime: reminder?.reminderTime,
            systemTemplateId: reminder?.systemTemplateId,
            templateName: reminder?.templateName,
            reminderType: reminder?.reminderType,
            reminderTo: reminder?.reminderTo,
            customActivityTypeReminderRecipient: reminder
              ?.customActivityTypeReminderRecipient?.length
              ? reminder?.customActivityTypeReminderRecipient
              : [{ recipientType: reminder?.reminderTo }],
          })
        );

        setState({
          id: "edit",
          value: {
            slaExpirationPeriod: data?.slaExpirationPeriod,
            activityTypeName: data.activityTypeName,
            slaExpirationPeriodFreqency: data.slaExpirationPeriodFreqency,
            slaExpirationPeriodComparisonDate:
              data.slaExpirationPeriodComparisonDate,
            slaExpirationPeriodComparisonDateId:
              data.slaExpirationPeriodComparisonDateId,
            slaAppliedForId: data.slaAppliedForId || 3,
            slaAppliedFor:
              data.slaAppliedFor ||
              (!data?.slaAppliedForId ?? "ForBothBulkAndFreshLeads"),
            slaExpirationPeriodComparisonDateId:
              data.slaExpirationPeriodComparisonDateId,
            leadScore: data.leadScore || 0,
            leadScoreOption: data.leadScoreOption,
            leadScoreOptionId: data.leadScoreOptionId,
            isCustom: data.isCustom,
          },
        });
        setLeadScoreData({
          id: "edit",
          value: {
            leadScore: data.leadScore || 0,
            leadScoreOption: data.leadScoreOption,
            leadScoreOptionId: data.leadScoreOptionId,
          },
        });
        setSlaTimerData({
          id: "edit",
          value: {
            slaExpirationPeriod: data.slaExpirationPeriod || 0,
            slaExpirationPeriodFreqency: data.slaExpirationPeriodFreqency,
            slaExpirationPeriodFreqencyId: data.slaExpirationPeriodFreqencyId,
          },
        });
        setSelected({
          id: "edit",
          value: {
            slaExpirationPeriodComparisonDate:
              data?.slaExpirationPeriodComparisonDate || "CreationDate",
            slaExpirationPeriodComparisonDateId:
              data?.slaExpirationPeriodComparisonDateId || 2,
          },
        });
        setReminders({
          id: "edit",
          value: {
            withReminder: data.withReminder,
            remindersData: newReminderData,
          },
        });
      } else {
        showError("error-occurred-while-fetching-data");
      }
    } catch (error) {}

    setIsLoading(false);
  };

  useEffect(() => {
    if (isCustomActivityOpen) {
      GetLeadStageActivityTypeForCustomDetailsAPICall();
    }
  }, [fetch, reload, isCustomActivityOpen, setIsCustomActivityOpen]);

  useEffect(() => {
    getAllSystemTemplateByCategoryId();
  }, []);

  useEffect(() => {
    if (leadScoreData.leadScoreOptionId === 0) {
      setLeadScoreData({
        id: "edit",
        value: {
          leadScoreOption: null,
          leadScoreOptionId: null,
          leadScore: null,
        },
      });
    }
  }, [leadScoreData]);

  useEffect(() => {
    if (resetData) {
      setIsLoading(true);
      setState({
        id: "edit",
        value: {
          slaExpirationPeriod: resetData?.stateSlaExpirationPeriod,
          activityTypeName: resetData?.stateActivityTypeName,
          slaExpirationPeriodFreqency:
            resetData?.stateSlaExpirationPeriodFreqency,

          slaExpirationPeriodComparisonDate:
            resetData?.stateSlaExpirationPeriodComparisonDate,

          slaExpirationPeriodComparisonDateId:
            resetData?.stateSlaExpirationPeriodComparisonDateId,

          slaAppliedForId: resetData?.stateSlaAppliedForId || 3,
          slaAppliedFor:
            resetData?.stateSlaAppliedFor || "ForBothBulkAndFreshLeads",
          stateSlaExpirationPeriodComparisonDateId:
            resetData?.stateSlaExpirationPeriodComparisonDateId,
          leadScore: resetData?.stateLeadScore || 0,
          leadScoreOption: resetData?.stateLeadScoreOption,
          leadScoreOptionId: resetData?.StateLeadScoreOptionId,
          isCustom: resetData?.isCustom,
        },
      });
      setLeadScoreData({
        id: "edit",
        value: {
          leadScore: resetData?.leadScore || 0,
          leadScoreOption: resetData?.leadScoreOption,
          leadScoreOptionId: resetData?.leadScoreOptionId,
        },
      });
      setSlaTimerData({
        id: "edit",
        value: {
          slaExpirationPeriod: resetData?.slaExpirationPeriod || 0,
          slaExpirationPeriodFreqency: resetData?.slaExpirationPeriodFreqency,
          slaExpirationPeriodFreqencyId:
            resetData?.slaExpirationPeriodFreqencyId,
        },
      });
      setSelected({
        id: "edit",
        value: {
          slaExpirationPeriodComparisonDate:
            resetData?.slaExpirationPeriodComparisonDate || "CreationDate",
          slaExpirationPeriodComparisonDateId:
            resetData?.slaExpirationPeriodComparisonDateId || 2,
        },
      });
      setReminders({
        id: "edit",
        value: {
          withReminder: resetData?.withReminder || false,
          remindersData: resetData?.remindersData || [],
        },
      });
    }
    setIsLoading(false);
  }, [resetTrigger]);

  return (
    <React.Fragment>
      <DialogComponent
        isOpen={isOpen}
        maxWidth={"sm"}
        dialogTitle={t("custom-activity")}
        dialogContent={
          <div className="custom-dialog-content">
            <Spinner isActive={isLoading} isAbsolute />
            <div className="activity-details d-flex-v-center-h-between mt-2">
              <div className="d-flex-column w-auto">
                <span className="bolder lighter-font-color">
                  {t("activity-name")}
                </span>
                <h6 className="no-break bolder">
                  {state?.activityTypeName || "N/A"}
                </h6>
              </div>
              <div className="d-flex-column w-auto gap-c-1">
                <span className="bolder lighter-font-color">
                  {t("sla-timer")}
                </span>
                <h6 className="no-break bolder">
                  {`${state?.slaExpirationPeriod || 0} ${t(
                    state?.slaExpirationPeriodFreqency
                  )} ${t("from")} ${t(
                    state?.slaExpirationPeriodComparisonDate
                  )} `}
                </h6>
              </div>
              <div className="d-flex-column w-20 d-flex-v-center">
                <span className="bolder lighter-font-color">{t("score")}</span>
                <h6 className="bolder">
                  {state.leadScoreOptionId === 1
                    ? "+"
                    : state.leadScoreOptionId === 2
                    ? "-"
                    : ""}
                  {state?.leadScore || "N/A"}
                </h6>
              </div>
            </div>
            <p className="is-custom-text bolder">
              {state?.isCustom ? t("custom-reminder") : t("main-reminder")}
            </p>

            <LabelWithElement
              label={t("apply-on")}
              element={
                <div>
                  <Checkbox
                    id="fresh-leads"
                    value={2}
                    checked={
                      state.slaAppliedForId === 2 || state.slaAppliedForId === 3
                    }
                    onClick={() => handleCheckBoxChange(2)}
                    label={t("fresh-leads")}
                  />
                  <Checkbox
                    id="bulk-leads"
                    value={1}
                    checked={
                      state.slaAppliedForId === 1 || state.slaAppliedForId === 3
                    }
                    onClick={() => handleCheckBoxChange(1)}
                    label={t("bulk-leads")}
                  />
                </div>
              }
            />
            <LabelWithElement
              label={t("sla-timer")}
              element={
                <div>
                  <Inputs
                    idRef="slaTimerRef"
                    value={slaTimerData?.slaExpirationPeriod}
                    wrapperClasses={"mt-3"}
                    type="number"
                    min={0}
                    max={900000000}
                    parentTranslationPath={parentTranslationPath}
                    translationPath={translationPath}
                    onInputChanged={(e) => {
                      const value = +e.target.value;
                      setSlaTimerData({ id: "slaExpirationPeriod", value });
                    }}
                    startAdornment={
                      <SelectComponet
                        wrapperClasses={"w-25"}
                        data={timeOptions || []}
                        valueInput="id"
                        textInput="value"
                        value={slaTimerData?.slaExpirationPeriodFreqencyId || 1}
                        translationPath={translationPath}
                        parentTranslationPath={parentTranslationPath}
                        onSelectChanged={(e) => {
                          let selectedOption = timeOptions.find(
                            (option) => option.id === e
                          );

                          if (selectedOption) {
                            setSlaTimerData({
                              id: "edit",
                              value: {
                                ...slaTimerData,
                                slaExpirationPeriodFreqencyId:
                                  selectedOption?.id,
                                slaExpirationPeriodFreqency:
                                  selectedOption?.value,
                              },
                            });
                          }
                        }}
                      />
                    }
                  />
                </div>
              }
            />

            <LabelWithElement
              label={t("from")}
              className={"inputs-container"}
              element={
                <RadioGroup row>
                  <FormControlLabel
                    value={1}
                    className={"inputs-container"}
                    control={
                      <input
                        type="radio"
                        name="date"
                        id="creation-date"
                        checked={
                          selected?.slaExpirationPeriodComparisonDateId === 2
                        }
                        onClick={() =>
                          handleRadioChange(
                            2,
                            "slaExpirationPeriodComparisonDateId"
                          )
                        }
                      />
                    }
                    label={
                      <span className="input-label">{t("creation-date")}</span>
                    }
                  />
                  <FormControlLabel
                    value={2}
                    className={"inputs-container"}
                    control={
                      <input
                        type="radio"
                        name="date"
                        id="activity-date"
                        checked={
                          selected?.slaExpirationPeriodComparisonDateId === 1
                        }
                        onClick={() =>
                          handleRadioChange(
                            1,
                            "slaExpirationPeriodComparisonDateId"
                          )
                        }
                      />
                    }
                    label={
                      <span className="input-label">{t("activity-date")}</span>
                    }
                  />
                </RadioGroup>
              }
            />

            <LabelWithElement
              label={t("score")}
              element={
                <div className="w-100 d-flex-column h-around align-center">
                  <SelectComponet
                    data={[
                      { title: "Add", value: 1 },
                      { title: "Subtract", value: 2 },
                    ]}
                    parentTranslationPath={parentTranslationPath}
                    translationPath={translationPath}
                    textInput={"title"}
                    valueInput={"value"}
                    value={leadScoreData?.leadScoreOptionId || 0}
                    onSelectChanged={(value) => {
                      setLeadScoreData({
                        id: "leadScoreOptionId",
                        value: value,
                      });
                      setLeadScoreData({
                        id: "leadScoreOption",
                        value:
                          value === 1 ? "Add" : value === 2 ? "Subtract" : null,
                      });
                    }}
                    emptyItem={{
                      value: 0,
                      text: "select-lead-score-option",
                    }}
                  />

                  <div className="d-flex mt-2">
                    {(leadScoreData.leadScoreOptionId === 1 ||
                      leadScoreData.leadScoreOptionId === 2) && (
                      <LeadScoreRadioButtons
                        selected={leadScoreData?.leadScore}
                        onClick={(i) =>
                          setLeadScoreData({ id: "leadScore", value: i })
                        }
                      />
                    )}
                  </div>
                </div>
              }
            />

            <ActivityDialogSection
              message={"with-reminder-message"}
              t={t}
              element={
                <div className="w-100 d-flex h-around align-center ">
                  <SwitchComponent
                    idRef="isWithReminderRef"
                    themeClass="theme-line"
                    isChecked={reminders.withReminder}
                    labelValue={t("with-reminder")}
                    translationPath={translationPath}
                    parentTranslationPath={parentTranslationPath}
                    onChangeHandler={(event, isChecked) => {
                      setReminders({ id: "withReminder", value: isChecked });
                    }}
                  />
                </div>
              }
            />

            {reminders.withReminder && (
              <ReminderComponent
                t={t}
                data={reminders}
                setData={setReminders}
                legend={"reminder"}
                roles={roles}
                timeOptions={timeOptions}
                templates={data.templates}
                isSubmitted={isSubmitted}
                translationPath={translationPath}
                parentTranslationPath={parentTranslationPath}
              />
            )}

            <hr className="hr"></hr>

            <DialogActions>
              <CancelButton
                className="dialog-action-btn"
                onClick={cancelHandler}
              >
                {t("cancel")}
              </CancelButton>
              <ResetButton className="dialog-action-btn" onClick={resetHandler}>
                {t("reset-to-default")}
              </ResetButton>
              <SubmitButton
                className="dialog-action-btn"
                onClick={() => submitHandler()}
              >
                {t("submit")}
              </SubmitButton>
            </DialogActions>
          </div>
        }
      />
    </React.Fragment>
  );
}

export default CustomActivityDialog;
