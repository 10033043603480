import React, { Children, useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CheckboxesComponent, Inputs } from '../../../../../Components';

export const ContactsFieldsViewer = ({
  parentTranslationPath,
  translationPath,
  formFields,
  formFieldIds,
  setFormFieldsData,
  contactDetails,
  setContactInitDetails,
  setFormFieldIds
}) => {
  const { t } = useTranslation(parentTranslationPath);
  const [searchedItemId, setSearchedItemId] = useState('');
  const searchedItemRef = useRef(null);
  const searchTimer = useRef(null);

  const cardCheckboxClicked = useCallback(
    (element) => {
      setFormFieldsData((items) => {
        if (
          items.filter((f) => f.formFieldId === element.formFieldId).length ===
          0
        )
          return [...items, element];
        else {
          const index = items.findIndex(
            (f) => f.formFieldId === element.formFieldId
          );
          items.splice(index, 1);
          return [...items];
        }
      });
      setFormFieldIds((items) => {
        const index = items.findIndex(
          (item) => item.formFieldId === element.formFieldId
        );
        if (index === -1) items.push(element);
        else {
          items.splice(index, 1);
          setSearchedItemId('');
        }
        return [...items];
      });
      setContactInitDetails((items) => {
        const index = items.findIndex((item) => item === element.formFieldName);
        if (index === -1) items.push(element.formFieldName);
        else {
          items.splice(index, 1);
          delete contactDetails[element.formFieldName];
        }
        return [...items];
      });
    },
    [
      setFormFieldIds,
      setFormFieldsData,
      setContactInitDetails,
      contactDetails,
    ]
  );
  const executeScroll = () =>
    searchedItemRef?.current?.scrollIntoView({
      block: 'end',
      behavior: 'smooth',
    });
  const searchHandler = (value) => {
    if (searchTimer.current) clearTimeout(searchTimer.current);
    searchTimer.current = setTimeout(() => {
      setSearchedItemId(value);
      executeScroll();
    }, 500);
  };
  const getIsChecked = useCallback(
    (item) =>
      formFieldIds.findIndex((el) => el.formFieldId === item.formFieldId) !==
      -1,
    [formFieldIds]
  );
  return (
    <div>
      <div className='bulk-header-section'>fields</div>
      <div className='bulk-sub-header-section'>
        {t(`choose-one-of-the-following-fields`, {
          ns: 'BulkUpdateProperties',
        })}
      </div>
      <div className='mt-2'>
        <Inputs
          translationPath={translationPath}
          parentTranslationPath={parentTranslationPath}
          idRef='unitsSearchRef'
          inputPlaceholder='search-fields'
          onKeyUp={(e) => searchHandler(e.target.value)}
          startAdornment={<span className='mdi mdi-magnify mdi-24px c-gray' />}
        />
      </div>
      <div className='bulked-units-section mt-3'>
        {Children.toArray(
          formFields?.map((item) => (
            <div
              onClick={(event) => {
                event.preventDefault();
                cardCheckboxClicked(item);
              }}
              ref={
                searchedItemId.length > 0 &&
                item.formFieldTitle
                  .toLowerCase()
                  .includes(searchedItemId.toLowerCase())
                  ? searchedItemRef
                  : null
              }
              className={`unit-fields-item mb-3 ${
                getIsChecked(item) ? 'is-cheked' : ''
              } ${
                searchedItemId.length > 0 &&
                item.formFieldTitle
                  .toLowerCase()
                  .includes(searchedItemId.toLowerCase())
                  ? 'is-search'
                  : ''
              } `}
            >
              <div className='unit-fields-card-checkbox-wrapper'>
                <CheckboxesComponent singleChecked={getIsChecked(item)} />
              </div>
              <div className='item-ref-no pl-2 pr-2 mt-1'>
                {item.formFieldTitle}
              </div>
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default ContactsFieldsViewer;
