export const GroupsPermissions = {
    ViewGroupsPage: {
      permissionsId: '221ead9e-a4b3-4aff-f46c-08dc3da1d8ed',
      permissionsName: 'View Groups Page',
      description: null,
      componentsId: null,
      components: null,
    },
    AddGroup: {
      permissionsId: '295d2f6f-7b07-4380-f46d-08dc3da1d8ed',
      permissionsName: 'Add Group',
      description: null,
      componentsId: null,
      components: null,
    },
    EditGroup: {
      permissionsId: 'f47d493d-73a8-42e3-f46e-08dc3da1d8ed',
      permissionsName: 'Edit Group',
      description: null,
      componentsId: null,
      components: null,
    },
    DeleteGroup: {
      permissionsId: '7cd22054-b7a6-48a0-f46f-08dc3da1d8ed',
      permissionsName: 'Delete Group',
      description: null,
      componentsId: null,
      components: null,
    },
  };
  