
export const PropertyRatingLocationTypeScoreEnum = {
    City: {
      lookupTypeId: 17,
      lookupTypeName: "City",
      propertyRatingLocationTypeScoreId :1 
    },
    
    District: {
      lookupTypeId: 18,
      lookupTypeName: "District",
      propertyRatingLocationTypeScoreId :2 

    },

    Community: {
        lookupTypeId: 19,
        lookupTypeName: "Community",
        propertyRatingLocationTypeScoreId :3 
  
      },

      SubCommunity: {
        lookupTypeId: 20,
        lookupTypeName: "SubCommunity",
        propertyRatingLocationTypeScoreId :4 
  
      },
  };
  