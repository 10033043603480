import React from 'react';
import { Menu, MenuItem, withStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import Bill from '../../../../../assets/images/Propxicon/ticket-02.svg';
import PropertyManagementImage from '../../../../../assets/images/icons/PropertyManagement.svg' ;
import Building from '../../../../../assets/images/icons/Building.svg' ; 
import { ClassificationsContactEnum } from  '../../../../../Enums' ; 
import cryptocurrency from '../../../../../assets/images/Propxicon/cryptocurrency-01.svg'

const StyledMenu = withStyles({
  paper: {
    borderRadius: "8px",
    border: "1px solid #EAECF0",
    background: "#FFF",
    boxShadow:
      "0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03)",
    "& .MuiList-padding": {
      paddingTop: "6px",
      paddingBottom: "6px",
      "& .MuiMenuItem-root": {
        fontSize: "14px",
      },
    },
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    keepMounted
    anchorOrigin={{
      vertical: "",
      horizontal: "",
    }}
    transformOrigin={{
      vertical: "",
      horizontal: "",
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    paddingBlock: "8px",
    '&:focus': {
      backgroundColor: theme.palette.primary.secondary,
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);

export const ContactClassificationMenu = ({ anchorEl, onClose, parentTranslationPath , setContactClassificationFilter , setFilter , setAnchorEl }) => {
  const { t } = useTranslation(parentTranslationPath);
  return (
    <StyledMenu
      id="customized-menu"
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={onClose}
    >
        <StyledMenuItem onClick={() =>{
          setFilter((item)=> ({...item , pageIndex : 0 ,  contactClassificationId:(ClassificationsContactEnum.find((f)=> f.name === 'Real Estate - Developer').Id) ,isSecondaryDeveloper : false }));
          setAnchorEl() ; 
          }}>
          <img src={Building} className="mr-2" alt="Developer"/>
          {t("developer")}
        </StyledMenuItem>

    
        <StyledMenuItem onClick={() => {
          setFilter((item)=> ({...item , pageIndex : 0 ,  contactClassificationId:(ClassificationsContactEnum.find((f)=> f.name === 'Real Estate - Developer').Id)  , isSecondaryDeveloper : true }));
          setAnchorEl() ; 

          }}>

          <img src={PropertyManagementImage} className="mr-2" alt="secondary-developer" />
          {t("secondary-developer")}
        </StyledMenuItem>
    </StyledMenu>
  );
};
