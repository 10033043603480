import i18next from 'i18next';
import React from 'react';
import { useTranslation } from 'react-i18next';
const parentTranslationPath = 'ApprovalsConfiguration';
const translationPath = '';

export const unitDetailsApprovalHeaderData = [
  {
    id: 1,
    label: 'branch-name',
    component: (item) => <span>{item?.branchName ?? 'N/A'}</span>,
  },
  {
    id: 2,
    label: 'expiration-details',
    component: (item) => (
      <span>{`${item?.expirationPeriod} ${item?.durationTypeName} `}</span>
    ),
  },
  {
    id: 7,
    label: 'approver-names',
    component: (item) => (
      <span>
        {item?.approverNames.map((element) => (
          <span className='table-capsule mr-1 fw-simi-bold'>
            {element.approverName || ''}
          </span>
        ))}
      </span>
    ),
  },
  {
    id: 8,
    label: 'excluded-roleNames',
    component: (item) => (
      <span>
        {item?.excludedRoleNames.map((element) => (
          <span className='table-capsule mr-1 fw-simi-bold'>
            {element.roleName || ''}
          </span>
        ))}
      </span>
    ),
  },
  {
    id: 9,
    label: 'excluded-userNames',
    component: (item) => (
      <span>
        {item.excludedUserNames.map((element) => (
          <span className='table-capsule mr-1 fw-simi-bold'>
            {element.excludedUserName || ''}
          </span>
        ))}
      </span>
    ),
  },
  {
    id: 5,
    label: 'send-notification',
    component: (item) => <span>{item?.isSendNotification ? 'Yes' : 'No'}</span>,
  },
  {
    id: 6,
    label: 'send-email',
    component: (item) => <span>{item?.isSendEmail ? 'Yes' : 'No'}</span>,
  },
  {
    id: 10,
    label: 'unit-approval-fields',
    component: (item) => (
      <span className='unit-approval-fields'>
        {item?.unitApprovalFields?.map((element) => {
          let fieldsName = '';
          if (element.toLowerCase() === 'status') {
            fieldsName = i18next.t('change-to-available', {
              ns: parentTranslationPath,
            });
          } else fieldsName = element;
          return (
            <span className='table-capsule mr-1 fw-simi-bold unit-approval-fields'>
              {i18next.t(fieldsName, { ns: parentTranslationPath }) || ''}
            </span>
          );
        })}
      </span>
    ),
  },
];
