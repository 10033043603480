import React, { useEffect, useState } from "react";
import { Spinner, Tables } from "../../../../Components";
import { GetInquiryAgentDetailByInquiryId } from "../../../../Services";
import { Button, DialogActions, withStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";

const ConfirmButton = withStyles(() => ({
  root: {
    color: "white",
    backgroundColor: "#84693f",
    border: "2px solid #84693f",
    borderRadius: "8px",
    "&:hover": {
      backgroundColor: "#6d5734",
    },
  },
}))(Button);

const CancelButton = withStyles(() => ({
  root: {
    color: "#000000",
    backgroundColor: "#",
    border: "1px solid #e0e0e0",
    borderRadius: "8px",
    "&:hover": {
      backgroundColor: "#f5f5f5",
    },
  },
}))(Button);

function InquiryAgentDialog({
  activeItem,
  isDialogOpen,
  setIsDialogOpen,
  translationPath,
  parentTranslationPath,
}) {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation(parentTranslationPath);

  async function GetInquiryAgentDetailByInquiryIdAPICall() {
    setIsLoading(true);
    const res = await GetInquiryAgentDetailByInquiryId(
        activeItem?.inquiryId,
        activeItem?.inquiryStatusId
    );
    if (!(res && res.status && res.status !== 200)) {
      setData(res);
    } else {
      setData([]);
    }
    setIsLoading(false);
  }

  function onCloseClickedHandler() {
    setIsDialogOpen(false);
  }
  function onSubmitClickedHandler() {
    setIsDialogOpen(false);
  }

  useEffect(() => {
    GetInquiryAgentDetailByInquiryIdAPICall();
  }, []);

  return (
    <React.Fragment>
      <Spinner isActive={isLoading} isAbsolute />
      <Tables
        data={data || []}
        headerData={[
          {
            id: 1,
            label: "assign-to",
            isHiddenFilter: true,
            isSortable: false,
            component: (item) => <p>{item.assignTo || "N/A"}</p>,
        },
        {
            id: 2,
            label: "assign-type",
            isHiddenFilter: true,
            isSortable: false,
            component: (item) => <p>{item.assignedType || "N/A"}</p>,
        },
        {
            id: 3,
            label: "assign-by",
            isHiddenFilter: true,
            isSortable: false,
            component: (item) => <p>{item.assignedBy || "N/A"}</p>,
          },
        ]}
        defaultActions={[]}
        translationPath={translationPath}
        parentTranslationPath={parentTranslationPath}
      />

      <DialogActions>
        <CancelButton onClick={onCloseClickedHandler}>
          {t("cancel")}
        </CancelButton>
        <ConfirmButton onClick={onSubmitClickedHandler}>
          {t("confirm")}
        </ConfirmButton>
      </DialogActions>
    </React.Fragment>
  );
}

export default InquiryAgentDialog;
