
import { HttpServices } from '../../Helper';
import { config } from '../../config';


export const CreatePolicyServices = async (body) => {
    const result = await HttpServices.post(
        `${config.server_address}/CrmDfm/Policies/CreatePolicy`, body
    )
        .then((data) => data)
        .catch((error) => error.response);
    return result;
};

export const getAllPoliciesServices = async (body) => {
    const result = await HttpServices.post(
        `${config.server_address}/CrmDfm/Policies/GetAllPolicies`, body
    )
        .then((data) => data)
        .catch((error) => error.response);
    return result;
};

export const UpdatePolicyServices = async ( policyId , body) => {
    const result = await HttpServices.put(
        `${config.server_address}/CrmDfm/Policies/UpdatePolicy?policyId=${policyId}`, body
    )
        .then((data) => data)
        .catch((error) => error.response);
    return result;
};

export const GetAllUsersByPolicyIdServices = async ({policyId  ,  pageIndex , pageSize  }) => {

    const queryList = [];
    if (pageIndex) queryList.push(`pageIndex=${pageIndex}`);
    if (pageSize) queryList.push(`pageSize=${pageSize}`);
    if (policyId) queryList.push(`policyId=${policyId}`);
    
    const result = await HttpServices.post(
        `${config.server_address}/CrmDfm/Policies/GetAllUsersByPolicyId?${queryList.join('&')}`
    )
        .then((data) => data)
        .catch((error) => error.response);
    return result;
};


export const AssignPolicyToUserService = async ({ userId , policyId }) => {
    const queryList = [];
    if (userId) queryList.push(`userId=${userId}`);
      queryList.push(`policyId=${policyId}`);

    const result = await HttpServices.post(
        `${config.server_address}/CrmDfm/Policies/AssignPolicyToUser?${queryList.join('&')}`)
        
        .then((data) => data)
        .catch((error) => error.response);
    return result;
}; 


export const RemovePolicyFromUserService = async (policyUserId) => {
    const result = await HttpServices.put(
        `${config.server_address}/CrmDfm/Policies/RemovePolicyFromUser?policyUserId=${policyUserId}`)
        .then((data) => data)
        .catch((error) => error.response);
    return result;
};

export const DeletePolicyService = async (policyId) => {
    const result = await HttpServices.put(
        `${config.server_address}/CrmDfm/Policies/DeletePolicy?policyId=${policyId}`)
        .then((data) => data)
        .catch((error) => error.response);
    return result;
};



export const GetPolicyByIdService = async (policyId) => {
    const result = await HttpServices.get(
        `${config.server_address}/CrmDfm/Policies/GetPolicyById?policyId=${policyId}`)
        .then((data) => data)
        .catch((error) => error.response);
    return result;
}; 

export const GetUserPolicyService = async (userId) => {
    const result = await HttpServices.get(
        `${config.server_address}/CrmDfm/Policies/GetPolicyByUserId?userId=${userId}`)
        .then((data) => data)
        .catch((error) => error.response);
    return result;
};

export const GetAllDFMTransactionForPolicy = async ({policyId, pageIndex, pageSize}) => {
    const result = await HttpServices.get(
        `${config.server_address}/CrmDfm/AuditTrail/GetAllDFMTransactionForPolicy/${policyId }?pageIndex=${pageIndex}&pageSize=${pageSize}`)
        .then((data) => data)
        .catch((error) => error.response);
    return result;
};





