import { FormControl, RadioGroup } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { LeadScoreRadioButtons } from './LeadScoreRadioButtons';
import React from 'react';

export const LeadScore = ({
  parentTranslationPath,
  translationPath,
  setState,
  state,
}) => {
  const { t } = useTranslation(parentTranslationPath);

  return (
    <FormControl className='my-2'>
      <label className='label-wrapper'>
        {t(`${translationPath}lead-score`)}
      </label>
      <RadioGroup
        name='leadScore'
        row
        className='d-flex h-around align-center  my-2'
      >
        <LeadScoreRadioButtons
          onClick={(i) => setState({ id: 'leadScore', value: i })}
          selected={state.leadScore}
        />
      </RadioGroup>
    </FormControl>
  );
};
