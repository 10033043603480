import ico from '../assets/images/upload-icons/ico.svg';
import png from '../assets/images/upload-icons/png.svg';
import jpg from '../assets/images/upload-icons/jpg.svg';
import gif from '../assets/images/upload-icons/gif.svg';
import svg from '../assets/images/upload-icons/svg.svg';
import tif from '../assets/images/upload-icons/tif.svg';
import bmp from '../assets/images/upload-icons/bmp.svg';
import xls from '../assets/images/upload-icons/xls.svg';
import mp4 from '../assets/images/upload-icons/mp4.svg';
import pdf from '../assets/images/upload-icons/pdf.svg';
import xlsx from '../assets/images/upload-icons/xlsx.svg';
import xlsm from '../assets/images/upload-icons/xlsm.svg';
import webm from '../assets/images/upload-icons/webm.svg';
import word from '../assets/images/upload-icons/word.svg';
import jfif from '../assets/images/upload-icons/jfif.svg';
import csv from '../assets/images/upload-icons/csvicon.svg';
import unknown from '../assets/images/upload-icons/unknown.svg';

const fileTypes = {
  ico: {
    image: ico,
    isFile: false,
    isImage: true,
    isVideo: false,
  },
  csv: {
    image: csv,
    isFile: true,
    isImage: false,
    isVideo: false,
  },
  gif: {
    image: gif,
    isFile: false,
    isImage: true,
    isVideo: false,
  },
  png: {
    image: png,
    isFile: false,
    isImage: true,
    isVideo: false,
  },
  webp: {
    image: png,
    isFile: false,
    isImage: true,
    isVideo: false,
  },
  jpg: {
    image: jpg,
    isFile: false,
    isImage: true,
    isVideo: false,
  },
  jpeg: {
    image: jpg,
    isFile: false,
    isImage: true,
    isVideo: false,
  },
  jfif: {
    image: jfif,
    isFile: false,
    isImage: true,
    isVideo: false,
  },
  svg: {
    image: svg,
    isFile: false,
    isImage: true,
    isVideo: false,
  },
  tif: {
    image: tif,
    isFile: false,
    isImage: true,
    isVideo: false,
  },
  bmp: {
    image: bmp,
    isFile: false,
    isImage: true,
    isVideo: false,
  },
  xlsx: {
    image: xlsx,
    isFile: true,
    isImage: false,
    isVideo: false,
  },
  xls: {
    image: xls,
    isFile: true,
    isImage: false,
    isVideo: false,
  },
  xlsm: {
    image: xlsm,
    isFile: true,
    isImage: false,
    isVideo: false,
  },
  mp4: {
    image: mp4,
    isFile: false,
    isImage: false,
    isVideo: true,
  },
  webm: {
    image: webm,
    isFile: false,
    isImage: false,
    isVideo: true,
  },
  pdf: {
    image: pdf,
    isFile: true,
    isPdf: true,
    isImage: false,
    isVideo: false,
  },
  doc: {
    image: word,
    isFile: true,
    isImage: false,
    isVideo: false,
  },
  docx: {
    image: word,
    isFile: true,
    isImage: false,
    isVideo: false,
  },
  unknown: {
    image: unknown,
    isFile: true,
    isImage: false,
    isVideo: false,
  }
};

export const getMimeTypeHandler = (fileName) => {
  const fileType = fileName.substring(fileName.lastIndexOf('.') + 1, fileName.length).toLowerCase();
  return fileTypes[fileType] || fileTypes.unknown;
};
// Refactoring Done 2023
