import React, { useState, useCallback, useEffect, useReducer } from 'react';
import Joi from 'joi';
import { useTranslation } from "react-i18next";
import { StepperComponent } from '../../../../../Components';
 import { GetUnitTransactionTenant, GetReservedUnitTransactionDataForLeaseByUnitId, GetUserIdByApplicationUserId,
   GetScopeCategoryDocuments, lookupItemsGetId, GetUnitLeaseDetails } from '../../../../../Services';
import { GetParams, showSuccess } from '../../../../../Helper';
import { AgentTypesEnum, ScopeDocumentEnum, UnitsStatusEnum } from '../../../../../Enums';
import { config } from '../../../../../config';
import { StaticLookupsIds } from '../../../../../assets/json/StaticLookupsIds';
import { UnitReservationStepsData } from '../../Utilities';

export const UnitReservationView = ({
  schema,
  isSubmitted,
  activeSchemaHandler,
  unitId,
  unitData,
  selected,
  onSelectedChanged,
  changeActiveSubmitButton,
  parentTranslationPath,
}) => {

  const translationPath = "UnitReservation.";

  const { t } = useTranslation(parentTranslationPath);
  const URLPathStep = GetParams('step');

  const [activeStep, setActiveStep] = useState(0);
  const reducer = (state, action) => {
    if (action.id !== "edit") return { ...state, [action.id]: action.value };
    return {
      ...action.value,
    };
  };

  const [localSelected, setLocalSelected] = useReducer(reducer, {
    tenantAgent: null,//
    otherTenantAgents: [],
    landlordAgent: null,
    otherLandlordAgents: [],
    agents: [],
    listingAgents: [],
    contractDate: null,
    transactionEntryDate: null,
    startDate: null,
    endDate: null,
    landlordReferral: null,
    landlordReferralPercentage: 0,
    tenantReferral: null,
    tenantReferralPercentage: 0,
    reservationType: null,
    rentFreePeriod: null,
    transactedBy: null,
    rentPerYear: 0,
    contractRent: 0,
    securityDeposit: 0,
    renewalFee: 0,
    agencyFeeLandlord: 0,
    agencyFeeTenant: 0,
    externalAgencyFeeLandlord: 0,
    externalAgencyFeeTenant: 0,
    netAmountLandLord: 0,
    netAmountTenant: 0,
    paymentToExternalAgency: false,
    tenancyContractIssued: false,
    contractRenewable: false,
    printContractOn: false,
    contractIssuedBy: null,
    contractIssuedDate: null,
    leasingType: null,
    periodOfStay: null,

  })
  const stepChangeHandler = (step) => () => {
    setActiveStep(step);
  };


  const mapTenantsData = (response) => {
    const mappedContact = {
      contactId: response.contactId,
      contactName: response.contactName,
      leadId: response.tenantId,
      email: response.email,
      phone: response.phone,
      ownershipPercentage: response.ownershipPercentage,
    }

    onSelectedChanged({
      id: "contacts",
      value: [mappedContact]
    });
  }

  const getUnitTransactionTenant = async () => {
    const res = await GetUnitTransactionTenant(unitId);
    if (!(res && res.status && res.status !== 200)) {
      if (res) mapTenantsData(res);
    }
  }
  const passportCategoryLookup = {
    id: config.ContactDocsCategoryIds_Passport,

    name: 'Passport',
  };
  const IdCategoryLookup = {
    id: config.ContactDocsCategoryIds_IDCard,

    name: 'ID',
  };
  const EIDCategoryLookup = {
    id: config.ContactDocsCategoryIds_EID,

    name: 'EID',
  };
  const KYCCategoryLookup = {
    id: config.ContactDocsCategoryIds_KYC,

    name: 'KYC',
  };

  const getTenantDocuments = async () => {
    const contactId = selected.contacts[0].contactId;
    const body = {
      scopeId: contactId,
      pageSize: 25,
      pageIndex: 0,
    };

    //todo:: make code less dummy and repeattive (refactor)
    try {
      const [
        IdDocsResponse,
        passportDocsResponse,
        KYCDocsResponse,
        EIDDocsResponse,
      ] = await Promise.all([
        GetScopeCategoryDocuments({
          ...body,
          categoryId: IdCategoryLookup.id,
        }),
        GetScopeCategoryDocuments({
          ...body,
          categoryId: passportCategoryLookup.id,
        }),
        GetScopeCategoryDocuments({
          ...body,
          categoryId: KYCCategoryLookup.id,
        }),
        GetScopeCategoryDocuments({
          ...body,
          categoryId: EIDCategoryLookup.id,
        }),
      ]);

      if (
        !(
          IdDocsResponse &&
          IdDocsResponse.status &&
          IdDocsResponse.status !== 200
        ) &&
        !(
          passportDocsResponse &&
          passportDocsResponse.status &&
          passportDocsResponse.status !== 200
        ) &&
        !(
          KYCDocsResponse &&
          KYCDocsResponse.status &&
          KYCDocsResponse.status !== 200
        ) &&
        !(
          EIDDocsResponse &&
          EIDDocsResponse.status &&
          EIDDocsResponse.status !== 200
        )
      ) {
        const mappedIdDocuments = IdDocsResponse?.result?.map((item) => ({
          scopeId: contactId,
          fileId: item.documentId,
          fileName: item.documentName,
          categoryId: item.categoryId,
          scopeTypeId: ScopeDocumentEnum.Contact.scopeTypeId,
          isLandlordOfferLatter: false,
          isTenantOfferLatter: false,
          isLandlordTenancyContract: false,
          isTenantTenancyContract: false,
        }));

        const mappedPassportDocuments = passportDocsResponse?.result?.map(
          (item) => ({
            scopeId: contactId,
            fileId: item.documentId,
            fileName: item.documentName,
            categoryId: item.categoryId,
            scopeTypeId: ScopeDocumentEnum.Contact.scopeTypeId,
            isLandlordOfferLatter: false,
            isTenantOfferLatter: false,
            isLandlordTenancyContract: false,
            isTenantTenancyContract: false,
          })
        );
        const mappedKYCDocuments = KYCDocsResponse?.result?.map((item) => ({
          scopeId: contactId,
          fileId: item.documentId,
          fileName: item.documentName,
          categoryId: item.categoryId,
          scopeTypeId: ScopeDocumentEnum.Contact.scopeTypeId,
          isLandlordOfferLatter: false,
          isTenantOfferLatter: false,
          isLandlordTenancyContract: false,
          isTenantTenancyContract: false,
        }));
        const mappedEIDDocuments = EIDDocsResponse?.result?.map((item) => ({
          scopeId: contactId,
          fileId: item.documentId,
          fileName: item.documentName,
          categoryId: item.categoryId,
          scopeTypeId: ScopeDocumentEnum.Contact.scopeTypeId,
          isLandlordOfferLatter: false,
          isTenantOfferLatter: false,
          isLandlordTenancyContract: false,
          isTenantTenancyContract: false,
        }));

        onSelectedChanged({
          id: 'contactDocuments',
          value: [
            ...(mappedIdDocuments || []),
            ...(mappedPassportDocuments || []),
            ...(mappedKYCDocuments || []),
            ...(mappedEIDDocuments || []),
          ],
        });
      } else {
        onSelectedChanged({
          id: 'contactDocuments',
          value: [],
        });
      }
    } catch (error) {
      console.error('Error fetching documents:', error);
    } finally {
    }
  };
  const getReservedTransactionData = async () => {

    const res = await GetReservedUnitTransactionDataForLeaseByUnitId(unitId);
    //make a ecternal helper  func (mapper)
    if (res?.leaseTransactionDetails) {
      fillSelectedFromReservationData(res.leaseTransactionDetails);

    }
  }

  
  const getUnitLeaseDetails = async () => {
      const res = await GetUnitLeaseDetails(unitId);
      if (!(res && res.status && res.status !== 200)) 
       return res;
  };

  //todo:: make this function smaller and externam (like UnitMapper)
  const fillSelectedFromReservationData = (leaseTransactionDetails) => {
    const landlordReferral = {
      applicationUserId: leaseTransactionDetails.landlordReferralId,
      firstName: leaseTransactionDetails.landlordReferralName,
    }
    const tenantReferral = {
      applicationUserId: leaseTransactionDetails.referralId,
      firstName: leaseTransactionDetails.referralName,
    }
    const reservationType = {
      lookupItemId: leaseTransactionDetails.reservationTypeId,
      lookupItemName: leaseTransactionDetails.reservationTypeName,
    }
    const rentFreePeriod = {
      lookupItemId: leaseTransactionDetails.rentFreePeriodId,
      lookupItemName: leaseTransactionDetails.rentFreePeriodName,
    }
    const transactedBy = {
      id: leaseTransactionDetails.transactedById,
      fullName: leaseTransactionDetails.transactedByName,
    }
    const contractIssuedBy = {
      id: leaseTransactionDetails.contractIssuedById,
      fullName: leaseTransactionDetails.contractIssuedByName,
    }
    const leasingType = {
      lookupItemId: leaseTransactionDetails.leasingTypeId,
      lookupItemName: leaseTransactionDetails.leasingTypeName,
    }
    const periodOfStay = {
      lookupItemId: leaseTransactionDetails.periodOfStayId,
      lookupItemName: leaseTransactionDetails.periodOfStayName,
    }
    const mappedSelected = {
      ...localSelected,
      contractDate: leaseTransactionDetails.contractDate,
      transactionEntryDate: leaseTransactionDetails.transactionEntryDate,
      startDate: leaseTransactionDetails.startDate,
      endDate: leaseTransactionDetails.endDate,
      landlordReferralPercentage: leaseTransactionDetails.landlordReferralPercentage,
      tenantReferralPercentage: leaseTransactionDetails.referralPercentage,
      rentPerYear: leaseTransactionDetails.rentPerYear,
      contractRent: leaseTransactionDetails.contractRent,
      securityDeposit: leaseTransactionDetails.securityDeposit,
      renewalFee: leaseTransactionDetails.renewalFee,
      agencyFeeLandlord: leaseTransactionDetails.agencyFee,
      agencyFeeTenant: leaseTransactionDetails.agencyFeeTenant,
      externalAgencyFeeLandlord: leaseTransactionDetails.externalAgencyFeeLandLord,
      externalAgencyFeeTenant: leaseTransactionDetails.externalAgencyFeeTenant,
      netAmountLandLord: leaseTransactionDetails.netAmountLandLord,
      netAmountTenant: leaseTransactionDetails.netAmountTenant,
      paymentToExternalAgency: leaseTransactionDetails.paymentToExternalAgency,
      tenancyContractIssued: leaseTransactionDetails.tenancyContractIssued,
      contractRenewable: leaseTransactionDetails.contractRenewable,
      printContractOn: leaseTransactionDetails.printContractOn,
      contractIssuedDate: leaseTransactionDetails.contractIssuedDate,
      tenantReferral,
      landlordReferral,
      reservationType,
      rentFreePeriod,
      transactedBy,
      contractIssuedBy,
      leasingType,
      periodOfStay,
    }

    if (
      leaseTransactionDetails?.agents &&
      (!selected.agents || selected.agents?.length == 0)
    ) {
      const selectedAgents = leaseTransactionDetails?.agents.map((item) => ({
        id: item.userId,
        fullName: item.agentName,
        isOriginalAgent: item.isOriginalAgent,
        agentType: item.agentType,
        agentCommission: item.agentCommission,
      }));

      const tenantAgent =
        selectedAgents.find((item) => item.isOriginalAgent == true) || null;
      const otherTenantAgents =
        selectedAgents.filter((item) => item.isOriginalAgent == false) || [];

      mappedSelected.agents = selectedAgents;
      mappedSelected.tenantAgent = tenantAgent;
      mappedSelected.otherTenantAgents = otherTenantAgents.length > 0 && otherTenantAgents;
    }


    let selectedListingAgents = [];

    if (
      leaseTransactionDetails?.listingAgents &&
      (!selected.listingAgents || selected.listingAgents?.length == 0)
    ) {
      selectedListingAgents = leaseTransactionDetails?.listingAgents.map((item) => ({
        id: item.userId,
        fullName: item.listingAgentName,
        isListingAgent: item.isListingAgent,
        listingAgentType: item.listingAgentType,
        listingAgentCommission: item.listingAgentCommission,
      }));

      const otherLandlordAgents =
        selectedListingAgents.filter((item) => item.isListingAgent == false) ||
        [];

      mappedSelected.listingAgents = selectedListingAgents;
      mappedSelected.otherLandlordAgents = otherLandlordAgents.length > 0 && otherLandlordAgents;
    }
    if (unitData?.rent_listing_agent) {
      const landlordAgentCommission =
        selectedListingAgents.length > 0
          ? selectedListingAgents[0].listingAgentCommission
          : 0;
      const unitLandlordAgent = {
        id: unitData.rent_listing_agent.id,
        fullName: unitData.rent_listing_agent.name,
        isListingAgent: true,
        listingAgentType: AgentTypesEnum.Landlord.value,
        listingAgentCommission: landlordAgentCommission,
      };
      if (selectedListingAgents?.length > 0) {
        selectedListingAgents.shift();
        selectedListingAgents.unshift(unitLandlordAgent);
      } else selectedListingAgents.push(unitLandlordAgent);

      mappedSelected.landlordAgent = unitLandlordAgent;
      mappedSelected.unitHasListingAgents = true;
    }



    //////////////////////////////////////////////////


    setLocalSelected({ id: 'edit', value: mappedSelected })
    // setReservationData(leaseTransactionDetails);

  }
  const getSelectedFromUnitData = (leaseDetails) => {

    const mappedSelected = {
      landlordReferralPercentage: leaseDetails.landlordReferralPercentage,
      tenantReferralPercentage: leaseDetails.referralPercentage,
      rentPerYear: leaseDetails.rentPerYear,
      securityDeposit: leaseDetails.securityDeposit,
      agencyFeeLandlord: leaseDetails.agencyFeeLandlord,
      agencyFeeTenant: leaseDetails.agencyFeeTenant,
      externalAgencyFeeLandlord: leaseDetails.externalAgencyFeeLandlord,
      externalAgencyFeeTenant: leaseDetails.externalAgencyFeeTenant,
      netAmountLandLord: leaseDetails.netAmountLandLord,
      netAmountTenant: leaseDetails.netAmountTenant,
    }

    return mappedSelected;
  }


  const getSelectedFromOfferData = () => {
    
    const selectedTenantAgent = {
      id: selected.autoFilled?.tenantAgent?.id,
      fullName: selected.autoFilled?.tenantAgent?.fullName,
      userName: '',
      isOriginalAgent: true,
      agentType: AgentTypesEnum.Tenant.value,
      agentCommission: 0,
    };
    const selectedLandLordAgent = unitData?.rent_listing_agent && {
      id: unitData.rent_listing_agent.id,
      fullName: unitData.rent_listing_agent.name,
      userName: unitData.rent_listing_agent.userName,
      isListingAgent: true,
      listingAgentType: AgentTypesEnum.Landlord.value,
      listingAgentCommission: 0,
    };
    
       const mappedSelected = {
        listingAgents: selectedLandLordAgent? [selectedLandLordAgent] : [],
        landlordAgent: selectedLandLordAgent,
        agents: selectedTenantAgent? [selectedTenantAgent] : [],
        tenantAgent: selectedTenantAgent,
        unitHasListingAgents: true,
        transactedBy: selected.autoFilled?.transactedBy,
        startDate: selected.autoFilled?.startDate,
        endDate: selected.autoFilled?.endDate,
      };

      return mappedSelected;

  }
  


  const [data, setData] = useReducer(reducer, {
    documentCategories: [],
  });

  const getDocumentCategories = async () => {
    const result = await lookupItemsGetId({
      lookupTypeId: StaticLookupsIds.UnitsDocuments,
    });
    if (!(result && result.status && result.status !== 200)) {
      setData({ id: 'documentCategories', value: result })
    }
  };

  //todo:: refactor this repeatitve code 
  const mapSingleUploadedDoc = ({document,
    isTenant = false,
    isLandlord = false,
  }) => {
    const scopeId = unitId;
    const scopeTypeId = ScopeDocumentEnum.Unit.scopeTypeId;
    const categoryId = data.documentCategories.find(item => item.lookupItemName === "Offer Letter")?.lookupItemId;

    return ({
      fileName: document.fileName,
      fileId: document.uuid,
      uuid: document.uuid,
      scopeId,
      categoryId,
      scopeTypeId,
      isTenantOfferLatter: isTenant,
      isLandlordOfferLatter: isLandlord,
    })
  }


  const DTOChangeHandler = useCallback(() => {
    const agents = localSelected.agents ? localSelected.agents.map(item => ({
      userId: item.id,
      agentCommission: item.agentCommission || 0,
      isOriginalAgent: item.id == localSelected?.tenantAgent?.id,
      agentType: AgentTypesEnum.Tenant.value,
    })) : [];

    const listingAgents = localSelected.listingAgents ? localSelected.listingAgents.map(item => ({
      userId: item.id,
      listingAgentName: item.fullName,
      isListingAgent: item.isListingAgent,
      listingAgentType: item.listingAgentType,
      listingAgentCommission: item.listingAgentCommission || 0,
    })) : [];

    const mappedTenantDocuments = (selected.offerLetterDocs?.tenantDocuments || [])
      .map(item => mapSingleUploadedDoc({
        document: item,
        isTenant : true,
      }))
    const mappedLandlordDocuments = (selected.offerLetterDocs?.landlordDocuments || [])
      .map(item => mapSingleUploadedDoc({
        document: item,
        isLandlord : true,
      }))
    const transactionDocuments = [
      ...(selected.contactDocuments || []),
      ...mappedTenantDocuments,
      ...mappedLandlordDocuments,
    ];
    const leaseTransactionDetails = {
      referralId: localSelected.tenantReferral?.applicationUserId,
      landlordReferralId: localSelected.landlordReferral?.applicationUserId,
      referralPercentage: localSelected.tenantReferralPercentage,
      landlordReferralPercentage: localSelected.landlordReferralPercentage,
      listingAgents,
      agents,
      reservationTypeId: localSelected.reservationType?.lookupItemId,
      contractDate: localSelected.contractDate,
      startDate: localSelected.startDate,
      endDate: localSelected.endDate,
      rentFreePeriodId: localSelected.rentFreePeriod?.lookupItemId,
      transactionEntryDate: localSelected.transactionEntryDate,
      transactedById: selected.autoFilled?.transactedBy?.id,
      contractSigned: true,
      rentPerYear: localSelected.rentPerYear,
      contractRent: localSelected.contractRent || 0,
      securityDeposit: localSelected.securityDeposit || 0,
      renewalFee: localSelected.renewalFee || 0,
      agencyFee: localSelected.agencyFeeLandlord || 0,
      agencyFeePercentage: localSelected.agencyFeeLandlordPercentage || 0,
      agencyFeeTenant: localSelected.agencyFeeTenant || 0,
      agencyFeeTenantPercentage: localSelected.agencyFeeTenantPercentage || 0,
      externalAgencyFeeTenant: localSelected.externalAgencyFeeTenant || 0,
      externalAgencyFeeTenantPercentage: localSelected.externalAgencyFeeTenantPercentage || 0,
      externalAgencyFeeLandlord: localSelected.externalAgencyFeeLandlord || 0,
      externalAgencyFeeLandlordPercentage: localSelected.externalAgencyFeeLandlordPercentage || 0,
      netAmountTenant: localSelected.netAmountTenant || 0,
      netAmountLandLord: localSelected.netAmountLandLord || 0,
      tenancyContractIssued: localSelected.tenancyContractIssued,
      contractIssuedDate: localSelected.contractIssuedDate,
      contractIssuedById: localSelected.contractIssuedBy?.id,
      paymentToExternalAgency: localSelected.paymentToExternalAgency,
      leasingTypeId: localSelected.leasingType?.lookupItemId,
      periodOfStayId: localSelected.periodOfStay?.lookupItemId,
      contractRenewable: localSelected.contractRenewable,
      printContractOn: localSelected.printContractOn,
    };

    const unitReservationDTO = {
      unitId,
      status: UnitsStatusEnum.ReservedLeased.key,
      leaseTransactionDetails,
      unitTransactionContacts: selected.contacts,
      transactionDocuments,
    }

    const transactionDTOs = {
      ...(selected.transactionDTOs || {}),
      UnitReservation: unitReservationDTO,
    }
    onSelectedChanged({ id: 'transactionDTOs', value: transactionDTOs });

  }, [localSelected, data.documentCategories, selected.autoFilled])


  const unitReservationSchema = ({
      contractIssuedById: Joi.string()
        .required()
        .messages({
          'any.required': t(`${translationPath}contract-issued-by-is-required`),
          'string.base': t(`${translationPath}contract-issued-by-is-required`),
          'string.empty': t(`${translationPath}contract-issued-by-is-required`),
        }),
      reservationTypeId: Joi.number()
        .required()
        .messages({
          'number.required': t(`${translationPath}reservation-type-is-required`),
          'number.base': t(`${translationPath}reservation-type-is-required`),
          'number.empty': t(`${translationPath}reservation-type-is-required`),
  
        }),
      rentPerYear: Joi.number()
        .greater(0)
        .required()
        .messages({
          'number.empty': t(`${translationPath}rent-per-year-is-required`),
          'number.required': t(`${translationPath}rent-per-year-is-required`),
          'number.base': t(`${translationPath}rent-per-year-is-required`),
        }),
      leasingTypeId: Joi.number()
        .required()
        .messages({
          'any.required': t(`${translationPath}leasing-type-is-required`),
          'number.base': t(`${translationPath}leasing-type-is-required`),
        }),
  })

  useEffect(() => {
    if (data.documentCategories?.length > 0) DTOChangeHandler();
  }, [DTOChangeHandler])

  useEffect(() => {
    activeSchemaHandler(unitReservationSchema)
    getDocumentCategories();
  }, []);
  const onLocalSelectedChange = (newValue) => {
    setLocalSelected(newValue);
  };


  const checkIsOfferSigned = () => {
    const isOfferSigned = unitData?.lease_status === UnitsStatusEnum.OfferSigned.name;
    return isOfferSigned;
  }

  const submitButtonChangeHandler = () => {
    if (checkIsOfferSigned()) changeActiveSubmitButton("Submit");
    else changeActiveSubmitButton("Next");
  }

  useEffect(() => {
    if (unitData) {
      const isContactsRetrieved = selected.contacts?.length > 0;
      if (checkIsOfferSigned() && !isContactsRetrieved) getUnitTransactionTenant();

      if (!checkIsOfferSigned()) getReservedTransactionData();
      else handleAutoFilledData();
    }
  }, [unitData, selected.contacts, selected.autoFilled])


  useEffect(() => {
    const isContactsRetrieved = selected.contacts?.length > 0;

    if (unitData && isContactsRetrieved) getTenantDocuments();

  }, [unitData, selected.contacts]);

  useEffect(() => {

    if (unitData) submitButtonChangeHandler();

  }, [unitData, URLPathStep, localSelected]);

  const handleAutoFilledData = async () => {
 
    const unitLeaseData = await getUnitLeaseDetails()

    const selectedFromUnitLeaseData = unitLeaseData? getSelectedFromUnitData(unitLeaseData) : [];
    const selectedFromOfferData = (selected.autoFilled?.tenantAgent &&
    selected.autoFilled?.transactedBy) ? getSelectedFromOfferData() : [];

    onLocalSelectedChange({ id: "edit", value: {
      ...localSelected,
      ...(selectedFromOfferData || []),
      ...(selectedFromUnitLeaseData || []),
    }})
  };


  return (
    <div className=''>
      <StepperComponent
        steps={UnitReservationStepsData}
        labelInput='label'
        wrapperClasses='propx-stepper-wrapper'
        onStepperClick={stepChangeHandler}
        activeStep={activeStep}
        parentTranslationPath={parentTranslationPath}
        translationPath={translationPath}
        dynamicComponentProps={{
          schema,
          isSubmitted,
          unitData,
          isReadOnly: !checkIsOfferSigned(),
          selected: localSelected,
          onSelectedChanged: onLocalSelectedChange,
          parentTranslationPath,
          translationPath
        }}
      />
    </div>
  )
}