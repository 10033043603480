import React, {
  Fragment,
  useCallback,
  useEffect,
  useLayoutEffect,
  useReducer,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { SwitchComponent } from "../../../../../../Components";
import Tooltip from "@material-ui/core/Tooltip";
import { IconButton } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { UpdateLeadStageActivityType } from "../../../../../../Services";
import { showError } from "../../../../../../Helper";
import CustomActivityDialog from "./CustomActivityDialog/CustomActivityDialog";
import ConfirmActionsDialog from "./CustomActivityDialog/ConfirmActionsDialog";

export const ActivityTypeCard = ({
  isActiveCard,
  selected,
  onSelect,
  onRemove,
  item,
  setActiveCard,
  activeCard,
}) => {
  const parentTranslationPath = "ActivityBuilder";
  const translationPath = "";
  const { t } = useTranslation(parentTranslationPath);
  const reducer = useCallback((state, action) => {
    if (action.id !== "edit") return { ...state, [action.id]: action.value };
    return {
      ...action.value,
    };
  }, []);
  const [isMandatory, setIsMandatory] = useState(item.isRequired);
  const [isLoading, setIsLoading] = useState(false);
  const [reload, setReload] = useState(false);
  const [body, setBody] = useState({});
  const [actionName, setActionName] = useState("");
  const [isOpenConfirmDialog, setIsOpenConfirmDialog] = useState(false);
  const [leadStageActivityTypeId, setLeadStageActivityTypeId] = useState(null);
  const [isCustomActivityOpen, setIsCustomActivityOpen] = useState(false);
  const [resetTrigger, setResetTrigger] = useState(false);
  const [fetchedTemplates, setFetchedTemplates] = useState([]);
  const [resetData, setResetData] = useReducer(reducer, {})

  const [data, setData] = useReducer(reducer, {
    roles: [],
    template: [],
    selectedScore: null,
  });

  const ActivityCardButtons = {
    Discard: {
      iconClass: "i-x-close",
    },
    Plus: {
      iconClass: "i-plus",
    },
    Check: {
      iconClass: "i-check",
    },
  };

  const updateLeadStageActivityType = async () => {
    try {
      const res = await UpdateLeadStageActivityType(
        item.leadStageActivityTypeId
      );
      if (res) {
        setIsMandatory((current) => !current);
      }
    } catch (error) {
      showError("something went wrong, please try again later");
    }
  };

  const [activeCardButton, setActiveCardButton] = useState(
    ActivityCardButtons.Plus
  );

  useLayoutEffect(() => {
    setIsMandatory(!!item.isRequired);
  }, [item.isRequired]);

  return (
    <div
      className={`ActivityTypeCard mb-3 ${isActiveCard ? "active-card" : ""}`}
      onClick={() => setActiveCard(item.activityTypeId)}
    >
      <div className="d-flex w-100">
        <div className="mr-2">
          {!selected ? (
            <span
              className={`pointer icons b-icon i-20 ${activeCardButton.iconClass}`}
              onClick={() => onSelect(item)}
            ></span>
          ) : (
            <IconButton size="small" onClick={() => onRemove(item)}>
              <Close />
            </IconButton>
          )}
        </div>
        <div className="w-100 px-1">
          <div className="d-flex-h-between w-100 mb-2 flex-wrap">
            <span className="fw-simi-bold fz-16">{item.activityTypeName}</span>
          </div>
          <div className="d-flex-h-between w-100 mb-2 flex-wrap">
            <p>{item.categoryName}</p>
          </div>
          {item.slaExpirationPeriod > 0 && (
            <div className="d-flex-h-between w-100 flex-wrap">
              <span className="fz-14">{`${item.slaExpirationPeriod} ${t(
                item.slaExpirationPeriodFreqencyName
              )} from ${t(item.slaExpirationPeriodComparisonDateName)}`}</span>
              <br />
              <span className="fz-14">{t(item.slaAppliedForName)}</span>
            </div>
          )}
          {selected && (
            <div className="d-flex-h-between w-100 mb-2  flex-wrap">
              <SwitchComponent
                idRef="reHigherRef"
                themeClass="thick-theme"
                isChecked={isMandatory}
                labelValue={t(`${translationPath}Mandatory`)}
                onChangeHandler={(e, isChecked) => {
                  updateLeadStageActivityType();
                }}
              />
              <div className="d-flex-default fj-end mt-3 pointer">
                <span
                  className={`pointer icons b-icon i-20 i-settings-04 px-2`}
                ></span>
                <span
                  className="px-2 fw-simi-bold c-propx"
                  onClick={() => {
                    setLeadStageActivityTypeId(item?.leadStageActivityTypeId);
                    setIsCustomActivityOpen(true);
                  }}
                >
                  {t(`${translationPath}Custom-activity`)}
                </span>
                <Tooltip
                  size="large"
                  title={t(`${translationPath}Custom-activity-tooltip`)}
                >
                  <span
                    className={`pointer icons b-icon i-help-circle px-2`}
                  ></span>
                </Tooltip>
              </div>
            </div>
          )}
        </div>
      </div>

      {isCustomActivityOpen && (
        <CustomActivityDialog
          isOpen={isCustomActivityOpen}
          translationPath={translationPath}
          parentTranslationPath={parentTranslationPath}
          isCustomActivityOpen={isCustomActivityOpen}
          setIsCustomActivityOpen={setIsCustomActivityOpen}
          leadStageActivityTypeId={leadStageActivityTypeId}
          setLeadStageActivityTypeId={setLeadStageActivityTypeId}
          setIsOpenConfirmDialog={setIsOpenConfirmDialog}
          setBody={setBody}
          setActionName={setActionName}
          reload={reload}
          setReload={setReload}
          resetTrigger={resetTrigger}
          resetData={resetData}
          setFetchedTemplates={setFetchedTemplates}
        />
      )}

      {isOpenConfirmDialog && (
        <ConfirmActionsDialog
          isOpen={isOpenConfirmDialog}
          setIsOpenConfirmDialog={setIsOpenConfirmDialog}
          body={body}
          setBody={setBody}
          reload={reload}
          setReload={setReload}
          actionName={actionName}
          translationPath={translationPath}
          parentTranslationPath={parentTranslationPath}
          leadStageActivityTypeId={leadStageActivityTypeId}
          setIsCustomActivityOpen={setIsCustomActivityOpen}
          activityTypeId={item?.activityTypeId}
          resetTrigger={resetTrigger}
          setResetTrigger={setResetTrigger}
          resetData={resetData}
          setResetData={setResetData}
          fetchedTemplates={fetchedTemplates}
          setFetchedTemplates={setFetchedTemplates}
        />
      )}
    </div>
  );
};
