export const AdminDashboardPermissions = {
AdminSalesTeam:
{
  permissionsId: '2927e262-852d-434e-ee3c-08da65738802',
  permissionsName: 'Admin Sales Team',
  componentsId: null,
  components: null
},
AdminLeaseTeam: {
  permissionsId: 'fda7d11b-92e6-420b-ee3d-08da65738802',
  permissionsName: 'Admin Lease Team',
  componentsId: null,
  components: null
},
AdminCallCenterTeam: {
  permissionsId: '612750e1-f9ac-4055-ee3e-08da65738802',
  permissionsName: 'Admin Call Center Team',
  componentsId: null,
  components: null
},
AdminMarketingTeam: {
  permissionsId: '87d3e2d8-95c3-48e4-ee3f-08da65738802',
  permissionsName: 'Admin Marketing Team',
  componentsId: null,
  components: null
},
AdminPSIDaily: {
  permissionsId: '1218d591-95c3-4874-ee40-08da65738802',
  permissionsName: 'Admin PSI Daily',
  componentsId: null,
  components: null
},

AdminSalesListingManager: {
  permissionsId: '103383c1-c489-465e-ee41-08da65738802',
  permissionsName: 'Admin Sales Listing Manager',
  componentsId: null,
  components: null
},
AdminLeaseListingManager: {
  permissionsId: '71b14c93-c3de-49b7-ee42-08da65738802',
  permissionsName: 'Admin Lease Listing Manager',
  componentsId: null,
  components: null
},
AdminAccounts:
{
  permissionsId: '9746b2f2-79b3-4119-ee43-08da65738802',
  permissionsName: 'Admin Accounts',
  componentsId: null,
  components: null
},
AdminLeadActivities:
{
  permissionsId: 'ff9163cb-d061-4230-ee44-08da65738802',
  permissionsName: 'Admin Lead Activities',
  componentsId: null,
  components: null
},
Branches: {
  permissionsId: 'b80495c5-45f2-4b4d-2456-08dac1673f1e',
  permissionsName: 'Branches',
  componentsId: null,
  components: null
},

LeaseContract: {
  components: null,
  componentsId: null,
  permissionsId: '2bf1fdae-08ac-4dc1-0773-08dad1f91be5',
  permissionsName: 'Lease Contract'

}
 , 
 CustomerRisk :
 {
    permissionsId: "1f2b1fe6-a4ab-4ac2-286b-08dbaf79a1bb",
    permissionsName: "Customer Risk",
    description: null,
    componentsId: null,
    components: null
 }  , 
 CampaignCost :
 {
    permissionsId: "cc899969-ca78-44e5-7047-08dbf65badbd",
    permissionsName: "Campaign Cost",
    description: null,
    componentsId: null,
    components: null
 }  , 
 LeadOwner :
 {
    permissionsId: "d78ea1b1-559e-492d-8870-08dc0129f7d6",
    permissionsName: "Lead Owner",
    description: null,
    componentsId: null,
    components: null
 }  , 
 LeadAutomation :
 {
    permissionsId: "c0c19d0f-51cb-4102-f94b-08dc0f48c1d2",
    permissionsName: "Lead Automation Dashboard",
    description: null,
    componentsId: null,
    components: null
 }  , 
 UnqualifiedLeads :
 {
    permissionsId: "d0d9a9a7-7505-4e47-f94c-08dc0f48c1d2",
    permissionsName: "Unqualified Leads Dashboard",
    description: null,
    componentsId: null,
    components: null
 }  , 
 InternationalSalesPlus :
 {
    permissionsId: "d481fbfe-43c1-4e98-f94e-08dc0f48c1d2",
    permissionsName: "International Sales+",
    description: null,
    componentsId: null,
    components: null
 }  , 
 UsersDetail :
 {
    permissionsId: "ed6e5644-eec3-4dcd-f94f-08dc0f48c1d2",
    permissionsName: "Users Details",
    description: null,
    componentsId: null,
    components: null
 }  , 
 AllListingUnits :
 {
    permissionsId: "824ae3d9-29b2-450f-5a64-08dc32a402e7",
    permissionsName: "Listing Units",
    description: null,
    componentsId: null,
    components: null
 }  , 
 CampaignRequest :
 {
    permissionsId: "1a0e14bb-e304-42e4-c4ce-08dc683b0c43",
    permissionsName: "Campaign Request",
    description: null,
    componentsId: null,
    components: null
 }  , 
 LeadPoolDashborad : {
   permissionsId: "44184cc4-0fc6-49fb-5dc8-08dc7403ebc2",
   permissionsName: "Lead Pool Dashborad",
   description: null,
   componentsId: null,
   components: null
},
InquiryDashboard :{
  permissionsId: "768d7f99-8e6c-46c9-7953-08dcaf947ea8",
  permissionsName: "Inquiry dashboard",
  description: null,
  componentsId: null,
  components: null
},
 DeveloperDashborad : {
  permissionsId: "f7f6b333-1988-4619-7971-08dcaf947ea8",
  permissionsName: "Developer Dashborad",
  description: null,
  componentsId: null,
  components: null
},
ActivitiesDashborad : 
{
  permissionsId: "5d5d4956-0dd5-494c-7973-08dcaf947ea8",
  permissionsName: "Activities Dashborad",
  description: null,
  componentsId: null,
  components: null
  }
 
};





