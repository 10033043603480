import React from "react";
import { Box } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { ContactCommentSkeleton, CustomCard } from "../../../../Components";

// Styles
import useStyles from "./styles";

function ContactOverviewCommentsCardSkeleton({ numberOfCards }) {
  const styles = useStyles();

  const skeletonCards = Array.from({ length: numberOfCards }, (_, index) => (
    <ContactCommentSkeleton key={index} />
  ));

  return (
    <CustomCard
      boxShadow="xs"
      borderRadius="xl"
      borderColor="secondary"
      classes={styles.contactOverViewCard}
    >
      <Box className={styles.commentsCardTitleWrapper}>
        <div className={styles.titleContainer}>
          <Skeleton
            variant="text"
            width={95}
            height={28}
            style={{ borderRadius: "4px" }}
            animation={false}
          />
          <Skeleton
            variant="text"
            width={162}
            height={24}
            style={{ borderRadius: "4px" }}
            animation={false}
          />
        </div>
      </Box>

      <Box className={styles.textareaContainer}>
        <Skeleton
          variant="rectangular"
          width="100%"
          height={75}
          style={{ borderRadius: "8px" }}
        />
      </Box>

      <Box className={styles.actionContainer}>
        <Skeleton
          variant="rectangular"
          width={147}
          height={44}
          style={{ borderRadius: "8px" }}
        />
      </Box>

      <Box className={styles.commentsContainer}>{skeletonCards}</Box>
    </CustomCard>
  );
}

export default ContactOverviewCommentsCardSkeleton;
