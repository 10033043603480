import React, { useCallback, useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import { ButtonBase } from "@material-ui/core";
import {
  Tables,
  Spinner,
  AutocompleteComponent,
  DataFileAutocompleteComponent,
  SelectComponet,
} from "../../../../../../Components";
import { DateRangePickerComponent } from "../../../../../../Components/Controls/DateRangePickerComponent/DateRangePickerComponent";
import {
  GetAllActivities,
  OrganizationUserSearch,
  getUnits,
  GetActivityById,
} from "../../../../../../Services";
import { useTitle } from "../../../../../../Hooks";
import { GlobalOrderFilterActions } from "../../../../../../store/GlobalOrderFilter/GlobalOrderFilterActions";
import { Inputs } from "../../../../../../Components/Controls/Inputs/Inputs";
import { ActivitiesManagementDialog } from "../../../../../../SharedComponents/ActivitiesManagementDialog/ActivitiesManagementDialog";
import { TableActions, UnitsOperationTypeEnum } from "../../../../../../Enums";
import { GetParams } from "../../../../../../Helper";
import { UnitMapper } from "../../../../UnitsView/UnitMapper";
import { ReplyActivityDialog } from "../../../../ActivitiesView/ReplyActivitesView/ReplyActivityDialog";
import "./LeadActivitiesComponent.scss";

const parentTranslationPath = "LeadsProfileManagementView";
const translationPath = "";

export const LeadActivitiesComponent = ({ leadWithPolicy }) => {
  const dateRangeDefault = {
    startDate: null,
    endDate: null,
    key: "selection",
  };
  
  const [dateFilter, setDateFilter] = useState(dateRangeDefault);
  const [openDialog, setOpenDialog] = useState(false);
  const [isLoadingActivities, setIsLoadingActivities] = useState(false);
  const [isReplay, setIsReplay] = useState(false);
  const [allAgents, setAllAgents] = useState({
    result: [],
    totalCount: 0,
  });

  const [isLoading, setIsLoading] = useState({
    agents: false,
    units: false,
    leads: false,
  });
  const addNewHandler = () => {
    setOpenDialog(true);
  };

  const dispatch = useDispatch();
  const orderFilter = useSelector((state) => state.GlobalOrderFilterReducer);

  const [activities, setActivities] = useState({
    result: [],
    totalCount: 0,
  });
  const [activeItem, setActiveItem] = useState(null);
  const [searchedItem, setSearchedItem] = useState("");
  const [filter, setFilter] = useState({
    pageSize: parseInt(localStorage.getItem("Pagination")) || 25,
    pageIndex: 0,
    search: "",
    guidAgentId: null,
    relatedUnitId: null,
    relatedLeadId: +GetParams("id"),
  });
  const [orderBy, setOrderBy] = useState({ filterBy: null, orderBy: null });
  const [sortBy, setSortBy] = useState(null);
  const [openReplyActivityDialog, setOpenReplyActivityDialog] = useState(false);

  const { t } = useTranslation(parentTranslationPath);
  useTitle(t(`${translationPath}activities`));

  const [averageScore, setAverageScore] = useState({score: null, scoreText: '', styleClasses: ''});
  const [selectedRelatedUnit, setSelectedRelatedUnit] = useState(null);
  const [selectedRelationType, setSelectedRelationType] = useState(0);
  const [data, setData] = useState({
    relatedUnits: [],
  });

  const onPageIndexChanged = (pageIndex) => {
    setFilter((item) => ({
      ...item,
      pageIndex,
      search: "",
      guidAgentId: null,
      relatedUnitId: null,
    }));
  };
  const onPageSizeChanged = (pageSize) => {
    setFilter((item) => ({
      ...item,
      pageIndex: 0,
      pageSize,
      search: "",
      guidAgentId: null,
      relatedUnitId: null,
    }));
  };

  useEffect(() => {
    if (sortBy) {
      setOrderBy((item) => ({
        ...item,
        filterBy: sortBy.filterBy,
        orderBy: sortBy.orderBy,
      }));
      dispatch(
        GlobalOrderFilterActions.globalOrderFilterRequest({
          ...orderFilter,
          SaleLeadsActivitiesFilter: {
            filterBy: sortBy.filterBy,
            orderBy: sortBy.orderBy,
          },
        })
      );
      setOrderBy((item) => ({
        ...item,
        filterBy: sortBy.filterBy,
        orderBy: sortBy.orderBy,
      }));
    }
  }, [sortBy]);
  const searchTimer = useRef(null);

  const searchHandler = (event) => {
    const { value } = event.target;
    if (searchTimer.current) clearTimeout(searchTimer.current);
    searchTimer.current = setTimeout(() => {
      setFilter((item) => ({
        ...item,
        search: value,
        pageIndex: 0,
        guidAgentId: null,
        relatedUnitId: null,
      }));
    }, 700);
  };

  const tableActionClicked = useCallback(
    (actionEnum, item, focusedRow, event) => {
      event.stopPropagation();
      event.preventDefault();
      if (actionEnum === TableActions.editText.key) {
        getActivityById(item.activityId);
        setOpenDialog(true);
      } else if (actionEnum === TableActions.replyText.key) {
        getActivityById(item.activityId);
        setOpenReplyActivityDialog(true);
      }
    },
    []
  );

  const focusedRowChanged = (activeRow) => {
    const item = activities.result[activeRow];
    if (!item) return;
    setIsReplay(item.isReplyAble);
  };
  const getTableActionsWithPermissions = () => {
    if (isReplay)
      return [
        { enum: TableActions.editText.key },
        { enum: TableActions.replyText.key, isDisabled: false },
      ];

    return [{ enum: TableActions.editText.key }];
  };

  const getAllActivities = useCallback(async () => {
    let activitiesDate = { fromDate: null, toDate: null };
    if (dateFilter && dateFilter.startDate && dateFilter.endDate) {
      activitiesDate = {
        fromDate: moment(dateFilter.startDate)
          .startOf("day")
          .format("YYYY-MM-DDTHH:mm:ss"),
        toDate: moment(dateFilter.endDate).format("YYYY-MM-DDTHH:mm:ss"),
      };
    }
    setIsLoadingActivities(true);

    const relationType = !(selectedRelationType === 0)
      ? selectedRelationType
      : null;
    const body = {
      ...filter,
      isWithoutRelatedActivities: false,
      pageIndex: filter.pageIndex + 1,
      filterBy: orderBy.filterBy,
      orderBy: orderBy.orderBy,
      pageSize: filter.pageSize,
      fromDate: activitiesDate.fromDate,
      toDate: activitiesDate.toDate,
      relationType,
    };
    const res = await GetAllActivities(body);

    if (!(res && res.status && res.status !== 200)) {
      setActivities({
        result: (res && res.result) || [],
        totalCount: (res && res.totalCount) || 0,
      });
      let score = res?.result[0]?.averageLeadScore ?? "N/A"
      let scoreText = "";
      let styleClasses = "";
      
      if (score > 6.5) {
        scoreText = t("hot-lead");
        styleClasses = "hot-score";
      } else if (score <= 6.5 && score > 2.6) {
        scoreText = t("warm");
        styleClasses = "warm-score";
      } else if (score <= 2.6 || score === 0 ) {
        scoreText = t("cold");
        styleClasses = "cold-score";
      } else {
        score = 'N/A'
        scoreText = t("cold");
        styleClasses = "cold-score";
      }
      setAverageScore({score,scoreText,styleClasses});
    } else {
      setActivities({
        result: [],
        totalCount: 0,
      });
    }

    setIsLoadingActivities(false);
  }, [filter, dateFilter, orderBy, selectedRelationType]);

  const getActivityById = useCallback(async (activityId) => {
    const res = await GetActivityById(activityId);
    if (!(res && res.status && res.status !== 200)) setActiveItem(res);
  }, []);

  const getAllAgents = useCallback(async () => {
    setIsLoading((loading) => ({ ...loading, agents: true }));
    const res = await OrganizationUserSearch({});
    if (!(res && res.status && res.status !== 200)) {
      setAllAgents({
        result: (res && res.result) || [],
        totalCount: (res && res.totalCount) || 0,
      });
    } else {
      setAllAgents({
        result: [],
        totalCount: 0,
      });
    }
    setIsLoading((loading) => ({ ...loading, agents: false }));
  }, []);

  const getAllRelatedUnits = useCallback(async (value) => {
    setIsLoading((loading) => ({ ...loading, units: true }));

    const response = await getUnits({
      search: value,
      operationType: UnitsOperationTypeEnum.sale.key,
    });
    if (!(response && response.status && response.status !== 200)) {
      const unitMapped = ((response && response.result) || []).map((item) =>
        UnitMapper(item)
      );
      setData({
        relatedUnits: unitMapped || [],
      });
    } else {
      setData({
        relatedUnits: [],
      });
    }
    setIsLoading((loading) => ({ ...loading, units: false }));
  }, []);

  const changeRelationType = (value) => {
    setSelectedRelationType(value);
    setSelectedRelatedUnit(null);
    setFilter((item) => ({
      ...item,
      relatedUnitId: null,
      filterBy: null,
      orderBy: null,
      search: "",
      fromDate: null,
      toDate: null,
      pageIndex: 0,
      guidAgentId: null,
    }));
  };

  const getActivityScoreFunction = (item) => {
    if (typeof item.leadScore !== "number") {
      return "N/A";
    } else {
      const mark = item.leadScore === 0 ? "" : (item.leadScoreOption === 1 ? "+" : "-");
      return <span>{`${mark} ${item.leadScore}`}</span>;
    }
  };
  

  const GetAverageScore = ({ averageScore }) => {
    let scoreText = "";
    let styleClasses = "";

    if (averageScore > 6.5) {
      scoreText = t("hot-lead");
      styleClasses = "hot-score";
    } else if (averageScore <= 6.5 && averageScore > 2.6) {
      scoreText = t("warm");
      styleClasses = "warm-score";
    } else if (averageScore <= 2.6 || averageScore === 0) {
      scoreText = t("cold");
      styleClasses = "cold-score";
    } else {
      setAverageScore("N/A");
    }
    return (
      <ButtonBase className={`btns w-25 ${styleClasses}`}>
        <span>{`${scoreText} (${averageScore})`}</span>
      </ButtonBase>
    );
  };

  

  useEffect(() => {
    getAllActivities();
  }, [getAllActivities]);

  useEffect(() => {
    getAllAgents();
  }, []);

  useEffect(() => {
    getAllRelatedUnits();
  }, []);


  return (
    <div className="view-wrapper">
      <Spinner isActive={isLoadingActivities} />
      <div className="d-flex-column">
        <div className="activities-top-section">
          <div className="buttons-container">
            <ButtonBase
              className="btns theme-solid mb-2 w-25"
              onClick={addNewHandler}
            >
              <span className="mdi mdi-plus" />
              {t(`${translationPath}add-new`)}
            </ButtonBase>
            <ButtonBase className={`btns w-25 ${averageScore.styleClasses}`}>
              <span>{`${averageScore.scoreText} (${averageScore.score})`}</span>
            </ButtonBase>
            {/* </PermissionsComponent> */}
          </div>
          <div className="section autocomplete-section mt-3">
            <div className="d-flex-column px-2 w-100 p-relative">
              <div className="d-flex-column w-100">
                <Inputs
                  value={searchedItem}
                  onKeyUp={searchHandler}
                  idRef="activitiesSearchRef"
                  label={t(`${translationPath}search-activity`)}
                  onInputChanged={(e) => setSearchedItem(e.target.value)}
                  inputPlaceholder={t(`${translationPath}search-activity`)}
                  beforeIconClasses="mdi mdi-magnify mdi-24px c-gray-primary"
                />
              </div>
              <div className="d-inline-flex pl-5-reversed agentSection">
                <div className="agentSection">
                  <div className="agentSection1">
                    <AutocompleteComponent
                      idRef="referredToRef"
                      isLoading={isLoading.agents}
                      multiple={false}
                      data={allAgents.result || []}
                      value={filter.guidAgentId}
                      chipsLabel={(option) => option.fullName || ""}
                      displayLabel={(option) => option.fullName || ""}
                      withoutSearchButton
                      inputPlaceholder={t(`${translationPath}Agent`)}
                      parentTranslationPath={parentTranslationPath}
                      translationPath={translationPath}
                      onChange={(event, newValue) => {
                        setFilter((item) => ({
                          ...item,
                          filterBy: null,
                          orderBy: null,
                          search: "",
                          fromDate: null,
                          toDate: null,
                          relatedUnitId: null,
                          pageIndex: 0,
                          guidAgentId: (newValue && newValue.id) || null,
                        }));
                      }}
                    />
                  </div>
                  <div className="agentSection1">
                    <DataFileAutocompleteComponent
                      idRef="RelatedToRef"
                      isDisabled={selectedRelationType === 0}
                      isLoading={isLoading.units}
                      labelClasses="Requierd-Color"
                      multiple={false}
                      selectedValues={selectedRelatedUnit}
                      data={data.relatedUnits}
                      displayLabel={(option) => option.unitRefNo || ""}
                      renderFor="unit"
                      getOptionSelected={(option) =>
                        option.id === filter.relatedUnitId
                      }
                      onChange={(event, newValue) => {
                        if (!newValue) getAllRelatedUnits(newValue);
                        setSelectedRelatedUnit(newValue);
                        setFilter((item) => ({
                          ...item,
                          relatedUnitId: (newValue && newValue.id) || null,
                          filterBy: null,
                          orderBy: null,
                          search: "",
                          fromDate: null,
                          toDate: null,
                          pageIndex: 0,
                          guidAgentId: null,
                        }));
                      }}
                      filterOptions={(options) => options}
                      onInputKeyUp={(e) => {
                        const { value } = e.target;
                        if (searchTimer.current)
                          clearTimeout(searchTimer.current);
                        searchTimer.current = setTimeout(() => {
                          getAllRelatedUnits(value);
                        }, 700);
                      }}
                      withoutSearchButton
                      inputStartAdornment={
                        <SelectComponet
                          data={[
                            {
                              key: 0,
                              value: "all",
                            },
                            {
                              key: 2,
                              value: "unit",
                            },
                          ]}
                          displayEmpty={false}
                          value={selectedRelationType}
                          onSelectChanged={changeRelationType}
                          inputPlaceholder={t(`${translationPath}Agent`)}
                          valueInput="key"
                          textInput="value"
                          wrapperClasses="over-input-select w-auto"
                          idRef="relatedToTypeRef"
                          parentTranslationPath={parentTranslationPath}
                          translationPath={translationPath}
                          translationPathForData={translationPath}
                        />
                      }
                      isWithError
                      parentTranslationPath={parentTranslationPath}
                      translationPath={translationPath}
                    />
                  </div>
                  <div className="agentSection1">
                    <DateRangePickerComponent
                      onClearClicked={() => setDateFilter(dateRangeDefault)}
                      ranges={[dateFilter]}
                      parentTranslationPath={parentTranslationPath}
                      translationPath={translationPath}
                      onDateChanged={(selectedDate) => {
                        setDateFilter({
                          startDate:
                            selectedDate.selection &&
                            selectedDate.selection.startDate,
                          endDate: new Date(
                            moment(
                              selectedDate.selection &&
                                selectedDate.selection.endDate
                            ).endOf("day")
                          ),
                          key: "selection",
                        });
                        setFilter((item) => ({
                          ...item,
                          pageIndex: 0,
                          search: "",
                          relatedUnitId: null,
                          filterBy: null,
                          orderBy: null,
                        }));
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="w-100 px-3">
          <Tables
            data={activities.result}
            headerData={[
              {
                id: 1,
                isSortable: true,
                label: "date",
                input: "activityDate",
                isDate: true,
              },
              {
                id: 2,
                isSortable: true,
                label: "created-on",
                input: "createdOn",
                isDate: true,
                dateFormat: "DD/MM/YYYY",
              },
              {
                id: 3,
                label: "created-time",
                input: "createdOn",
                isDate: true,
                dateFormat: " hh:mm A",
              },
              {
                id: 4,
                label: "created-By-Name",
                input: "createdBy",
                component: (item) => (
                  <span className="c-primary">
                    {item.createdByName || "N/A"}
                  </span>
                ),
              },
              {
                id: 5,
                label: "related-to",
                component: (item) => (
                  <span className="c-primary">
                    {(item.relatedLeadNumberId &&
                      item.relatedUnitNumberId &&
                      t(`${translationPath}Lead/Unit`)) ||
                      (item.relatedLeadNumberId &&
                        t(`${translationPath}lead`)) ||
                      (item.relatedMaintenanceContractId &&
                        t(`${translationPath}MaintenanceContract`)) ||
                      (item.relatedUnitNumberId &&
                        t(`${translationPath}unit`)) ||
                      (item.relatedPortfolioName &&
                        t(`${translationPath}Portfolio`)) ||
                      (item.relatedWorkOrderRefNumber &&
                        t(`${translationPath}WorkOrder`)) ||
                      (item.relatedUnitPropertyName &&
                        t(`${translationPath}Property`)) ||
                      (item.relatedPortfolioId &&
                        t(`${translationPath}Portfolio`)) ||
                      (item.relatedWorkOrderId &&
                        t(`${translationPath}WorkOrder`)) ||
                      "N/A"}
                  </span>
                ),
              },
              {
                id: 6,
                label: "related-to-number",
                component: (item) => (
                  <span className="c-primary">
                    {item.relatedLeadFullName || "N/A"}
                  </span>
                ),
              },
              {
                id: 7,
                label: "related-to-name",
                component: (item) => (
                  <span className="c-primary">
                    {item.relatedLeadFullName || "N/A"}
                  </span>
                ),
              },
              {
                id: 8,
                label: "status",
                input: "isOpen",
                component: (item, index) =>
                  (item.isOpen && (
                    <div className="globel-open-style" index={index}>
                      {t(`${translationPath}open`)}
                    </div>
                  )) || (
                    <div className="globel-closed-style" index={index}>
                      {t(`${translationPath}closed`)}
                    </div>
                  ),
              },
              {
                id: 9,
                label: "assigned-to",
                input: "assignedTo",
              },
              {
                id: 10,
                label: "contact-name",
                input: "relatedLeadFullName",
              },
              {
                id: 11,
                label: "stage",
                input: "leadStage",
              },
              {
                id: 12,
                label: "category",
                input: "categoryName",
              },
              {
                id: 13,
                label: "activity-type",
                input: "activityTypeName",
              },
              {
                id: 14,
                label: "subject",
                input: "subject",
              },
              {
                id: 15,
                label: "Copy-To",
                input: "copyToFullName",
                isDefaultFilterColumn: true,
              },
              {
                id: 16,
                label: "comments",
                input: "comments",
                isDefaultFilterColumn: true,
              },
              {
                id: 17,
                label: "score",
                component: (item) => <>{getActivityScoreFunction(item)}</>,
              },
            ]}
            defaultActions={getTableActionsWithPermissions()}
            focusedRowChanged={focusedRowChanged}
            onPageIndexChanged={onPageIndexChanged}
            onPageSizeChanged={onPageSizeChanged}
            actionsOptions={{
              onActionClicked: tableActionClicked,
            }}
            itemsPerPage={filter.pageSize}
            activePage={filter.pageIndex}
            translationPath={translationPath}
            parentTranslationPath={parentTranslationPath}
            totalItems={activities.totalCount}
            setSortBy={setSortBy}
          />

          {openDialog && (
            <ActivitiesManagementDialog
              leadClass={leadWithPolicy?.lead?.leadClass}
              leadStageId={leadWithPolicy?.lead?.lead_stage?.lookupItemId}
              activeItem={activeItem}
              open={openDialog}
              onSave={() => {
                setOpenDialog(false);
                onPageIndexChanged(0);
                setActiveItem(null);
              }}
              close={() => {
                setOpenDialog(false);
                setActiveItem(null);
              }}
              translationPath={translationPath}
              parentTranslationPath={parentTranslationPath}
            />
          )}

          {openReplyActivityDialog && (
            <ReplyActivityDialog
              open={openReplyActivityDialog}
              close={() => {
                setActiveItem(null);
                setOpenReplyActivityDialog(false);
              }}
              activeItem={activeItem}
              onSave={() => {
                setOpenReplyActivityDialog(false);
                setActiveItem(null);
                onPageIndexChanged(0);
              }}
              translationPath={translationPath}
              parentTranslationPath={parentTranslationPath}
            />
          )}
        </div>
      </div>
    </div>
  );
};
