import React, { memo } from "react";
import { Icon } from "@material-ui/core";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { PropTypes } from "prop-types";

import EventsComponent from "../EventsComponent/EventsComponent";

import "./MobileCalendarPage.scss";

const useStyles = makeStyles((theme) => ({
  arrow: {
    color: theme.palette.foreground.quarterary,
  },
  backTitle: {
    color: theme.palette.text.tertiary,
  },
  mainTitle: {
    color: theme.palette.text.primary,
  },
}));

const MobileCalendarPage = memo(({ setMobileTabValue }) => {
  const styles = useStyles();
  const { t } = useTranslation("HeaderView");

  return (
    <div className="mobileCalenderWrapper">
      <Link to={"/home/userlogin-dashboard"} target="_self">
        <div className="mobileCalenderContainer">
          <Icon className={clsx("icon-back-arrow", styles.arrow)} />

          <label className={clsx("title", styles.backTitle)}>
            {t(`back-dashboard`)}
          </label>
        </div>
      </Link>

      <div className="text">
        <span className={styles.mainTitle}>{t(`calendar`)}</span>
      </div>

      <EventsComponent
        isOpen={true}
        top={0}
        onClose={() => {
          setMobileTabValue(-1);
        }}
      />
    </div>
  );
});

MobileCalendarPage.propTypes = {
  setMobileTabValue: PropTypes.func,
};
MobileCalendarPage.defaultProps = {
  setMobileTabValue: () => {},
};

export default MobileCalendarPage;
