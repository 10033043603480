import React, { useCallback, useReducer } from "react";
import { useTranslation } from "react-i18next";
import xCloseIcon from "../../../../../assets/images/icons/x-close-dialog.svg";
import btnLoader from "../../../../../assets/images/GIF_images/btn-loader.gif";
import {
  DialogComponent,
  Inputs,
  AutocompleteComponent,
} from "../../../../../Components";
import { DialogActions, DialogContent, ButtonBase } from "@material-ui/core";
import { showWarn } from "../../../../../Helper";
import {
  GetLookupItemsByLookupTypeName,
  lookupItemsGetId,
} from "../../../../../Services";
import { BankAccount } from "../../../../../Enums";
import { PageHeading } from "../../../../../SharedComponents";

export const AddCustomAccountDialog = ({
  onClose,
  isLoading,
  onSubmit,
  isOpen,
  parentTranslationPath,
}) => {
  const { t } = useTranslation(parentTranslationPath);
  const translationPath = "Utilities.";

  const reducer = useCallback((state, action) => {
    if (action.id !== "edit") return { ...state, [action.id]: action.value };
    return {
      ...action.value,
    };
  }, []);

  const [data, setData] = useReducer(reducer, {
    banks: [],
    currencies: [],
  });

  const [selected, setSelected] = useReducer(reducer, {
    bank: null,
    currency: null,
    accountName: "",
    accountNumber: "",
    ibanCode: "",
    swiftCode: "",
  });

  const getBanks = async () => {
    const res = await lookupItemsGetId({
      lookupTypeId: BankAccount.Babk.value,
    });
    if (!(res && res.status && res.status !== 200))
      setData({ id: "banks", value: res || [] });
    else setData({ id: "banks", value: [] });
  };

  const getCurrencies = async () => {
    const res = await GetLookupItemsByLookupTypeName({
      lookUpName: "Currencies",
      pageSize: 100,
      pageIndex: 1,
    });
    if (!(res && res.status && res.status !== 200))
      setData({ id: "currencies", value: res?.result || [] });
    else setData({ id: "currencies", value: [] });
  };

  const saveHandler = () => {
    //todo:: add schema

    // if (!selected.leasingStartDate || !selected.leasingEndDate) {
    //   showError(t("Shared:please-fix-all-errors"));
    //   return;
    // }

    const customAccountDetails = {
      accountName: selected.accountName,
      accountNumber: selected.accountNumber,
      bank: selected.bank?.lookupItemName || null,
      bankId: selected.bank?.lookupItemId || null,
      ibanCode: selected.ibanCode,
      currency: selected.currency?.lookupItemName || null,
      currencyId: selected.currency?.lookupItemId || null,
      swiftCode: selected.swiftCode,
    };

    onSubmit(customAccountDetails);
  };

  return (
    <DialogComponent
      isOpen={isOpen}
      maxWidth="sm"
      dialogTitle={
        <div className="users-manage-view add-task-dialog pb-3 w-100">
          <div className="d-flex-v-center-h-between">
            <div className="fw-simi-bold">
              <PageHeading
                parentTranslationPath={parentTranslationPath}
                translationPath={translationPath}
                headerTitle={"bank-account-share"}
                subTitle={"bank-account-share-heading"}
                headerTitleClasses={"fz-16 mb-1"}
                subTitleClasses={"fz-12 m-0 ml-0"}
              />
            </div>
            <div className="xCloseIcon pointer" onClick={onClose}>
              <img src={xCloseIcon} />
            </div>
          </div>
        </div>
      }
      dialogContent={
        <div className="users-manage-view">
          <DialogContent>
            <>
              <div className="b-bottom">
                <div className="d-flex fj-start gap-16 w-100">
                  <div className="w-25 mt-2 pt-1">
                    <p className="fw-simi-bold m-0">
                      {`${t(`${translationPath}Account-name`)}*`}
                    </p>
                  </div>
                  <div className="w-75 mt-2 mb-3 pt-1">
                    <Inputs
                      idRef="accountNameRef"
                      inputPlaceholder={t(`${translationPath}enter`)}
                      value={selected.accountName || ""}
                      parentTranslationPath={parentTranslationPath}
                      onInputChanged={(event) => {
                        const value = event.target.value;
                        const stringifiedValue = `${value}`;

                        setSelected({
                          id: "accountName",
                          value: stringifiedValue,
                        });
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="b-bottom">
                <div className="d-flex fj-start gap-16 w-100">
                  <div className="w-25 mt-2 pt-1">
                    <p className="fw-simi-bold m-0">
                      {`${t(`${translationPath}Bank-name`)}*`}
                    </p>
                  </div>
                  <div className="w-75 mt-2 mb-3 pt-1">
                    <AutocompleteComponent
                      idRef="bankRef"
                      inputPlaceholder="select"
                      selectedValues={selected.bank}
                      multiple={false}
                      data={data.banks || []}
                      displayLabel={(option) => option.lookupItemName || ""}
                      withoutSearchButton
                      isWithError
                      parentTranslationPath={parentTranslationPath}
                      translationPath={translationPath}
                      onOpen={() => {
                        if (data.banks && data.banks.length == 0) getBanks();
                      }}
                      onChange={(_, newValue) => {
                        setSelected({ id: "bank", value: newValue });
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="b-bottom">
                <div className="d-flex fj-start gap-16 w-100">
                  <div className="w-25 mt-2 pt-1">
                    <p className="fw-simi-bold m-0">
                      {`${t(`${translationPath}Currency`)}*`}
                    </p>
                  </div>
                  <div className="w-75 mt-2 mb-3 pt-1">
                    <AutocompleteComponent
                      idRef="currencyRef"
                      inputPlaceholder="select"
                      selectedValues={selected.currency}
                      multiple={false}
                      data={data.currencies || []}
                      displayLabel={(option) => option.lookupItemName || ""}
                      withoutSearchButton
                      isWithError
                      parentTranslationPath={parentTranslationPath}
                      translationPath={translationPath}
                      onOpen={() => {
                        if (data.currencies && data.currencies.length == 0)
                          getCurrencies();
                      }}
                      onChange={(_, newValue) => {
                        setSelected({ id: "currency", value: newValue });
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="b-bottom">
                <div className="d-flex fj-start gap-16 w-100">
                  <div className="w-25 mt-2 pt-1">
                    <p className="fw-simi-bold m-0">
                      {`${t(`${translationPath}Account-number`)}*`}
                    </p>
                  </div>
                  <div className="w-75 mt-2 mb-3 pt-1">
                    <Inputs
                      idRef="accountNumberRef"
                      inputPlaceholder={t(`${translationPath}enter`)}
                      value={selected.accountNumber || ""}
                      type="number"
                      parentTranslationPath={parentTranslationPath}
                      onInputChanged={(event) => {
                        const value = event.target.value;

                        setSelected({
                          id: "accountNumber",
                          value: value,
                        });
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="b-bottom">
                <div className="d-flex fj-start gap-16 w-100">
                  <div className="w-25 mt-2 pt-1">
                    <p className="fw-simi-bold m-0">
                      {`${t(`${translationPath}IBAN-code`)}*`}
                    </p>
                  </div>
                  <div className="w-75 mt-2 mb-3 pt-1">
                    <Inputs
                      idRef="IBANcodeRef"
                      inputPlaceholder={t(`${translationPath}enter`)}
                      value={selected.ibanCode || ""}
                      parentTranslationPath={parentTranslationPath}
                      onInputChanged={(event) => {
                        const value = event.target.value;
                        const stringifiedValue = `${value}`;

                        setSelected({
                          id: "ibanCode",
                          value: stringifiedValue,
                        });
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="b-bottom">
                <div className="d-flex fj-start gap-16 w-100">
                  <div className="w-25 mt-2 pt-1">
                    <p className="fw-simi-bold m-0">
                      {`${t(`${translationPath}Swift-code`)}*`}
                    </p>
                  </div>
                  <div className="w-75 mt-2 mb-3 pt-1">
                    <Inputs
                      idRef="swiftcodeRef"
                      inputPlaceholder={t(`${translationPath}enter`)}
                      value={selected.swiftCode || ""}
                      parentTranslationPath={parentTranslationPath}
                      onInputChanged={(event) => {
                        const value = event.target.value;
                        const stringifiedValue = `${value}`;

                        setSelected({
                          id: "swiftCode",
                          value: stringifiedValue,
                        });
                      }}
                    />
                  </div>
                </div>
              </div>
            </>
          </DialogContent>
          <DialogActions>
            <div className="d-flex-center fj-end py-0 pt-3">
              <ButtonBase
                onClick={onClose}
                className="btns theme-propx outlined"
              >
                {t(`${translationPath}cancel`)}
              </ButtonBase>
              <ButtonBase
                className="btns theme-propx solid mr-0"
                onClick={saveHandler}
              >
                {t(`${translationPath}Confirm-Details`)}
                {isLoading ? (
                  <img
                    src={btnLoader}
                    className="ml-1"
                    width="12"
                    height="12"
                  />
                ) : (
                  false
                )}
              </ButtonBase>
            </div>
          </DialogActions>
        </div>
      }
    />
  );
};

export default AddCustomAccountDialog;
