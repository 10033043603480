import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import image from '../../assets/images/defaults/loader.gif';
import { getMimeTypeHandler } from '../../Utils';

export const LoadableDocumentComponent = ({ classes, fileName }) => {
  const [state, setState] = useState(false);

  return (
    <div className="loadable-doc-wrapper">
      {(fileName && (
        <img
          onLoad={() => {
            setState(true);
          }}
          className={`${classes || ''}`}
          src={getMimeTypeHandler(fileName).image}
        />
      )) || (
        <img
          src={image}
          className={`${classes} ${!state ? ' show' : ' hidden'}`}
        />
      )}
    </div>
  );
};
LoadableDocumentComponent.propTypes = {
  classes: PropTypes.string,
};
LoadableDocumentComponent.defaultProps = {
  classes: '',
};
