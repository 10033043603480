import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => {
  return {
    tabButtonContainer: (props) => ({
      display: "flex",
      gap: "8px",
      padding: props?.variant === "default" ? "0 4px 10px" : "0 4px 12px",
    }),
    tabButton: {
      opacity: 1,
      color: theme.palette.text.text_quarterary, // not selected tab button color
      lineHeight: "20px",
      display: "flex !important",
      flexDirection: "row !important",
      gap: "5px !important",
      width: "fit-content !important",
      minWidth: "fit-content !important",
      minHeight: "fit-content",
      margin: 0,
      padding: 0,
      fontWeight: 600,
      textTransform: "none",
      "& .MuiTab-wrapper": {
        alignItems: "flex-start !important",
      },
      "&:hover": {
        color: theme.palette.text.brand_secondary,
        borderBottom: `2px solid ${theme.palette.text.brand_secondary}`,
      },
    },
    tabs: {
      borderBottom: `1px solid ${theme.palette.border.secondary}`,
      minHeight: "fit-content",
      marginBottom: "24px",
      overflowX: "auto",
      whiteSpace: "nowrap",
    },
    flexContainer: {
      gap: "12px",
      display: "inline-flex",
    },
    tabsScroller: {
      overflowX: "auto !important",
      overflowY: "hidden !important",

      /* Hide scrollbar for Chrome, Safari, and Opera */
      "&::-webkit-scrollbar": {
        display: "none",
      },
      /* Hide scrollbar for IE, Edge, and Firefox */
      "-ms-overflow-style": "none",  /* IE and Edge */
      "scrollbar-width": "none",  /* Firefox */
    },
    tab: {
      color: theme.palette.text.brand_secondary, // selected tab button color
      lineHeight: "20px",
      fontWeight: 600,
      borderBottom: `2px solid ${theme.palette.border.brand_primary_alt}`,
    },
    tabLabel: {
      textTransform: "none",
    },
    font14: {
      fontSize: "14px",
    },
    font16: {
      fontSize: "16px",
      fontWeight: 600,
      lineHeight: "24px",
    },
  };
});
