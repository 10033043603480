

import { getProperties } from '../../../../../Services' ;

export async function PropertyRule(item, value,setItems , setIsLoading) {
  if (!item.data.searchKey) return;
  if (item.data.searchKey !== 'property') return;
  setIsLoading(true);

  const rs = await getProperties({ pageIndex: 0, pageSize: 10, search: value });
  item.data.enum = [];
  if ((rs && rs.data && rs.data.ErrorId) || !rs || !rs.result) return;
  rs.result.map((valueLoop) => {
    item.data.enum.push({
      id: valueLoop.propertyId,
      name: valueLoop.property.property_name,
      city: (valueLoop.property.city && valueLoop.property.city.lookupItemName) || '',
    });
  });
  setItems(item.data.enum);
  setIsLoading(false);
}