import React, { useCallback, useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import {
  GetRotationSchemeByIdServices,
  SetAgentInRotationSchemeAsActiveOrInActive,
  UpdateRotationScheme,
} from "../../../../Services/RotaionSchemaService/RotationSchemaService";
import {
  Inputs,
  SelectComponet,
  Tables,
  Spinner,
  SwitchComponent,
} from "../../../../Components";
import { GetAgentRotationSchemes } from "../../../../Services";
import { showError, showSuccess } from "../../../../Helper";

export const ManageRotaionView = ({
  parentTranslationPath,
  translationPath,
  activeItem,
}) => {
  const { t } = useTranslation(parentTranslationPath);
  const [filter] = useState({
    pageIndex: 0,
    pageSize: 1000,
  });

  const [isLoading, setIsLoading] = useState(false);
  const [AgentsRotationSchemes, setAgentsRotationSchemes] = useState([]);
  const [rotationNameSearch, setRotationNameSearch] = useState(null);
  const [rotationStatusSearch, setRotationStatusSearch] = useState(null);
  const searchTimer = useRef(null);

  const GetAgentRotationSchemesCallAPI = useCallback(async () => {
    setIsLoading(true);
    const result = await GetAgentRotationSchemes(
      activeItem.agentId,
      rotationNameSearch,
      rotationStatusSearch
    );
    if (!(result && result.status && result.status !== 200)) {
      setAgentsRotationSchemes(result);
    } else setAgentsRotationSchemes([]);

    setIsLoading(false);
  }, [activeItem, rotationNameSearch, rotationStatusSearch]);

  const UpdateRotationSchemeAPIcall = useCallback(
    async (item) => {
      setIsLoading(true);
      const res = await SetAgentInRotationSchemeAsActiveOrInActive(
        activeItem.agentId,
        item.rotationSchemeId,
        !item.isActive
      );
      if (!(res && res.status && res.status !== 200)) {
        showSuccess(
          t`${translationPath}Edit-Rotation-Scheme-status-successfully`
        );
      } else showError(t("Shared:Server-Error"));

      GetAgentRotationSchemesCallAPI();
      setIsLoading(false);
    },
    [rotationNameSearch, rotationStatusSearch]
  );

  const searchHandler = (search) => {
    if (searchTimer.current) clearTimeout(searchTimer.current);
    searchTimer.current = setTimeout(() => {
      setRotationNameSearch(search);
    }, 700);
  };

  useEffect(() => {
    GetAgentRotationSchemesCallAPI();
  }, [rotationNameSearch, rotationStatusSearch]);

  return (
    <>
      <div className="Agents-wrapper view-wrapper ">
        <div className="filter-section-item " />
        <Spinner isActive={isLoading} isAbsolute />
        <div className="w-100 px-2">
          <div className="seaction-title">
            {t(`${translationPath}Agent-info`)}
          </div>
          <div className="main-title">
            <div className="px-3">
              {(activeItem && activeItem.agentName) || "N/A"}
            </div>
            <div className="px-3">
              <span className="mdi mdi-phone px-1 " />
              {(activeItem && activeItem.agentMobile) || "N/A"}
            </div>
            <div className="px-3">
              <span className="mdi mdi-email-outline px-1" />
              {(activeItem && activeItem.agentEmail) || "N/A"}
            </div>
          </div>
          <div className="seaction-title">
            {t(`${translationPath}Rotation-info`)}
          </div>
        </div>

        <div className="d-flex flex-h-between  flex-v-center ">
          <div className="d-flex flex-h-between flex-v-center ">
            <h4 className="m-2">
              <b>{t(`${translationPath}Rotation-Name`)}</b>
            </h4>
            <Inputs
              idRef="RotationNameSearchInputRef"
              wrapperClasses="w-50 flex-v-center"
              inputPlaceholder={"Search"}
              defaultValue={undefined}
              onInputChanged={(e) => {
                searchHandler(e.target.value);
              }}
            />
          </div>

          <div className="d-flex flex-h-between  flex-v-center">
            <h4 className="m-2">
              <b>{t(`${translationPath}Rotation-Status`)}</b>
            </h4>
            <SelectComponet
              data={[
                {
                  key: 0,
                  value: undefined,
                  StatusSelectedValue: "...",
                },
                {
                  key: 1,
                  value: "true",
                  StatusSelectedValue: "On",
                },
                {
                  key: 2,
                  value: "false",
                  StatusSelectedValue: "Off",
                },
              ]}
              valueInput="value"
              textInput="StatusSelectedValue"
              idRef="RotationStatusSelectRef"
              defaultValue={undefined}
              wrapperClasses="w-50 flex-v-center"
              onSelectChanged={(selectedValue) => {
                setRotationStatusSearch(selectedValue);
              }}
            />
          </div>
        </div>

        <div className="w-100 px-2  Tables-ManageRotaionViewDialogView">
          <Tables
            data={AgentsRotationSchemes || []}
            headerData={[
              {
                id: 1,
                label: t(`${translationPath}Rotation-Name`),
                input: "rotationName",
              },
              {
                id: 2,
                label: t(`${translationPath}Assignee-Date`),
                input: "assignDate",
                isDate: true,
              },
              {
                id: 3,
                label: t(`${translationPath}Assignee-By`),
                input: "assignBy",
              },
              {
                id: 4,
                label: t(`${translationPath}status`),
                input: "isActive",
                component: (item) => (
                  <SwitchComponent
                    idRef={`isActiveSwitchRef`}
                    isChecked={item.isActive}
                    labelClasses="px-0"
                    themeClass="theme-line"
                    onChangeHandler={() => UpdateRotationSchemeAPIcall(item)}
                  />
                ),
              },
              {
                id: 5,
                label: t(`${translationPath}Active-Deactive-Date`),
                component: (item) =>
                  item.isActive ? item.activateDate : item.deactivateDate,
                isDate: true,
              },
              {
                id: 6,
                label: t(`${translationPath}Active-By`),
                input: "activateBy",
              },
            ]}
            totalItems={[].length || 0}
            defaultActions={[]}
            itemsPerPage={filter.pageSize}
            activePage={filter.pageIndex}
          />
        </div>
      </div>
    </>
  );
};
ManageRotaionView.propTypes = {
  parentTranslationPath: PropTypes.string.isRequired,
  translationPath: PropTypes.string.isRequired,
};