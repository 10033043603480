import React, { useCallback, useReducer, useEffect } from 'react';
import { DialogActions, DialogContent, ButtonBase } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import './CampaignTeam.scss';
import {
  showError,
  showSuccess,
} from '../../../../../../../../Helper';
import {
  AutocompleteComponent,
  DialogComponent,
  SelectComponet,
} from '../../../../../../../../Components';
import {
  UpdateCampaignTeamMember,
  GetCampaignUserTypes,
} from '../../../../../../../../Services';

export const EditTeamMemberDialog = ({ activeItem, isDialogOpen, onClose, onSave, teamMembersData }) => {
  const parentTranslationPath = 'Campaign';
  const translationPath = 'campaign-team.';

  const { t } = useTranslation(parentTranslationPath);

  const reducer = useCallback((state, action) => {
    if (action.id !== 'edit') return { ...state, [action.id]: action.value };
    return {
      ...action.value,
    };
  }, []);

  const [state, setState] = useReducer(reducer, {
    id: null,
    campaignTaskUserTypeId: null,
  });
  const [selected, setSelected] = useReducer(reducer, {
    user: null,
  });
  const [isLoading, setIsLoading] = useReducer(reducer, {
    userTypes: false,
    saving: false,
  });
  const [data, setData] = useReducer(reducer, {
    userTypes: [],
  });


  const updateCampaignTeamMember = async () => {
    setIsLoading({ id: 'saving', value: true });
    const body = {
      ...state,
    };
    const res = await UpdateCampaignTeamMember(body);
    if (!(res && res.status && res.status !== 200)) {
      showSuccess(t(`${translationPath}team-members-updated-successfully`));
      if (onSave) onSave();
    } else showError(t(`${translationPath}team-members-update-failed`));
    setIsLoading({ id: 'saving', value: false });
  };

  const getCampaignUserTypes = async (searchValue) => {
    setIsLoading({ id: 'userTypes', value: true });
    const res = await GetCampaignUserTypes({
      search: searchValue || '',
    });
    if (!(res && res.status && res.status !== 200)) {
      setData({ id: 'userTypes', value: res || [] });
    } else setData({ id: 'userTypes', value: [] });

    setIsLoading({ id: 'userTypes', value: false });
  };

  const checkIfTeamHasManager = () => {
    const isTeamHasManager = (teamMembersData && teamMembersData.find(item => item.campaignTaskUserTypeId == 1))? true : false;
 
    return (isTeamHasManager);
   }

  const saveHandler = async (event) => {
    event.preventDefault();

    const isManagerRole = state.campaignTaskUserTypeId === 1;

    if (isManagerRole && checkIfTeamHasManager()) {
      showError(t(`${translationPath}team-must-have-one-campaign-manager`));
      return;
    }
    if(isLoading.saving) return;

    updateCampaignTeamMember();
  };

  useEffect(() => {
    if(activeItem) {
      const selectedUserItem = {
        fullName: activeItem.name,
      }

      setSelected({
        id: 'edit',
        value: {
          user: selectedUserItem,
        }
      })
      setState({
        id: 'edit',
        value: {
          id: activeItem.id,
          campaignTaskUserTypeId: activeItem.campaignTaskUserTypeId,
        }
      })
    }

  }, [activeItem]);

  useEffect(() => {
    getCampaignUserTypes();
  }, []);

  return (
    <div>
      <DialogComponent
        isOpen={isDialogOpen}
        maxWidth='sm'
        titleText={t(`${translationPath}edit-team-members`)}
        dialogContent={
          <>
            <form noValidate onSubmit={saveHandler}>
              <DialogContent>
                <div className='camp-req-f1 campaign-team-f1'>
                  <div className='w-75 m-auto'>
                    <div className='mb-2'>
                      <AutocompleteComponent
                        idRef='teamMemberRef'
                        inputPlaceholder='select-team-member'
                        selectedValues={selected.user}
                        data={[]}
                        multiple={false}
                        isDisabled
                        displayLabel={(option) => option.fullName || ''}
                        withoutSearchButton
                        translationPath={translationPath}
                        parentTranslationPath={parentTranslationPath}
                        wrapperClasses='team-member-field'
                      />
                    </div>
                    <div className='mb-2'>
                      <SelectComponet
                        data={data.userTypes || []}
                        defaultValue={0}
                        value={state.campaignTaskUserTypeId || 0}
                        emptyItem={{ value: 0, text: 'select-role', isHiddenOnOpen: true }}
                        valueInput='campaignTaskUserTypeId'
                        textInput='campaignTaskUserTitle'
                        onSelectChanged={(newValue) =>{
                          setState({ id: 'campaignTaskUserTypeId', value: newValue || null })
                        }}
                        isLoading={isLoading.userTypes}
                        idRef='TeamMemberRoleRef'
                        parentTranslationPath={parentTranslationPath}
                        translationPath={translationPath}
                      />
                    </div>
                  </div>
                </div>
              </DialogContent>
              <DialogActions>
                <ButtonBase onClick={onClose} className='btns theme-solid bg-cancel'>
                  {t(`cancel`)}
                </ButtonBase>
                <ButtonBase className='btns theme-solid' disabled={isLoading.saving} onClick={saveHandler} type='submit'>
                  {t(`save`)}
                </ButtonBase>
              </DialogActions>
            </form>
          </>
        }
      />
    </div>
  );
};
