import React, {
  useCallback, useState, useEffect, useReducer
} from 'react';
import { useTranslation } from 'react-i18next';
import Joi from 'joi';
import moment from 'moment';
import exportFromJSON from 'export-from-json';
import { Button, Tooltip } from '@material-ui/core';
import { Spinner, SelectComponet, Inputs } from '../../../../Components';
import {
  bottomBoxComponentUpdate, showSuccess, showError, GlobalHistory, GetParams
} from '../../../../Helper';
import { DateRangePickerComponent } from '../../../../Components/Controls/DateRangePickerComponent/DateRangePickerComponent';
import { SaveClientsSegmentation } from '../AddClientsSegmentation/Dialogs/SaveClientsSegmentation';
import {
  UpdateClientSigmantationServices, GetSystemReportsResultClientsSegmentation, ExportReportServices, GetClientSigmantationById
} from '../../../../Services';
import { QeuriesComponents, SearchClientsSegmentationTable, SearchAndAddComponent } from './Components';
import { PaginationComponent } from '../../../../Components/PaginationComponent/PaginationComponent';

const parentTranslationPath = 'ClientsSegmentation';
const translationPath = '';

export const EditMarketingClientsSegmentation = ({ }) => {
  const [clientsSegmentationId, setClientsSegmentationId] = useState(+GetParams('clientSegmentationId'));
  const { t } = useTranslation([parentTranslationPath, 'Shared']);
  const [isLoading, setIsLoading] = useState(false);
  const [sortBy, setSortBy] = useState(null);
  const [isOpenSaveDialog, setIsOpenSaveDialog] = useState(false);
  const [tableFilterData, setTableFilterData] = useState([]);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [clientSegmentation, setClientSegmentation] = useState(null);
  const [fromIndex, setFromIndex] = useState('');
  const [formFields, setFormFields] = useState(null);
  const [searchWhenOpenPage, setSearchWhenOpenPage] = useState(false);
  const [isSelect, setIsSelected] = useState(false);
  const [orderTypeBy, setOrderTypeBy] = useState({
    index: 3, order: 2, searchKey: 'createdOn', orderByCategory: 'Contacts'
  });

  const [searchResponse, setSearchResponse] = useState({
    result: [],
    totalCount: 0
  });
  const [selectedOrderBy, setSelectedOrderBy] = useState({
    filterBy: null,
    orderBy: null,
  });
  const reducer = (state, action) => {
    if (action.id !== 'edit') return { ...state, [action.id]: action.value };
    if (action.id === 'edit') {
      return {
        ...action.value,
      };
    }
    return undefined;
  };

  const [state, setState] = useReducer(reducer, {
    conditions: [{
      category: null,
      categoryType: null,
      searchKey: null,
      value: null,
      operator: null,
      operand: 'AND',
      fieldName: null,
    }],
    fields: [],
    clientSegmantationName: null,
    systemReportFilters: {
      orderBy: 'createdOn',
      orderByCategory: 'Contacts',
      orderType: 2,
      searchKey: null,
      fromDate: null,
      toDate: null,
      dateType: null,
    } , 
    isAngryBird : false , 
    isHighProfileMangement : false , 
    clientSegmentationRoleDtos :[] , 
    clientSegmentationUserDtos:[] , 

  });

  const filterByChanged = (value) => {
    const systemReportFilters = { ...state.systemReportFilters };
    let sf = null;
    if (!value) {
      setDateFilter(dateRangeDefault);
      sf = {
        ...systemReportFilters,
        dateType: null,
        fromDate: null,
        toDate: null
      };
    } else
      sf = { ...systemReportFilters, dateType: value };

    setState({ id: 'edit', value: { ...state, systemReportFilters: sf } });
    if (isSearchFirst)
      setFilter((item) => ({ ...item, pageIndex: 0 }));
  };

  const [fields, setFields] = useState([]);
  const [filter, setFilter] = useState({
    pageSize: parseInt(localStorage.getItem('Pagination')) || 25,
    pageIndex: 0,
    search: '',
  });

  const [ifHaveAnyErrors, setIfHaveAnyErrors] = useState(false);
  const [tableColumns, setTableColumns] = useState([]);
  const [isSearchFirst, setIsSearchFirst] = useState(false);

  const dateRangeDefault = {
    startDate: null,
    endDate: null,
    key: 'selection',
  };

  const [dateFilter, setDateFilter] = useState(dateRangeDefault);
  const schema = Joi.array()
    .items(
      Joi.object({
        category: Joi.any()
          .custom((value, helpers) => {
            if (!value) return helpers.error('state.userNotSet');
            return value;
          }),
        categoryType: Joi.number()
          .required(),
        fieldName: Joi.object()
          .required(),
        value: Joi.any().allow('')
          .custom((value, helpers) => {
            if (!value) return helpers.error('state.typeNotSet');
            return value;
          }),
        operator: Joi.number()
          .required()
      })
    )
    .options({
      abortEarly: false,
      allowUnknown: true,
    })
    .validate(state.conditions);

  const localSchema = Joi.object({
    clientSegmantationName: Joi.string()
      .required()
      .messages({
        'string.empty': t(`${translationPath}client-segmantation-name-is-required`),
        'string.base': t(`${translationPath}client-segmantation-name-is-required`)
      }),
    ownerId: Joi.number()
      .required()
      .messages({
        'number.base': t(`${translationPath}owner-is-required`),
        'number.empty': t(`${translationPath}owner-is-required`),
      }),
  })
    .options({
      abortEarly: false,
      allowUnknown: true,
    })
    .validate(state);

  const onPageIndexChanged = (pageIndex) => {
    setFilter((item) => ({ ...item, pageIndex }));
    setIsSelected(true);
  };

  const onPageSizeChanged = (pageSize) => {
    setFilter((item) => ({ ...item, pageIndex: 0, pageSize }));
    setIsSelected(true);
  };

  const getClientSegmentationyId = useCallback(async () => {
    setIsLoading(true);
    const res = await GetClientSigmantationById(clientsSegmentationId);
    if (!(res && res.status && res.status !== 200))
      setClientSegmentation(res);
    else setClientSegmentation(null);
    setIsLoading(false);
  }, [clientsSegmentationId]);

  useEffect(() => {
    getClientSegmentationyId();
  }, [clientsSegmentationId]);

  useEffect(() => {
    const checkValueIsString = !!((clientSegmentation && (clientSegmentation.fieldsJson === 'string' || clientSegmentation.conditionsJson === 'string')));
    if (clientSegmentation) {
    const  conditionsValues = !checkValueIsString ? clientSegmentation.conditionsJson &&  JSON.parse(clientSegmentation.conditionsJson).filter((x) => ( x.searchKey !== 'angry_bird' &&  x.searchKey !== 'high_profile_management' )):
    [{
      category: null, categoryType: null, fieldName: null, operand: null, searchKey: null
    }];
    const  isAngryBird = clientSegmentation.conditionsJson && JSON.parse(clientSegmentation.conditionsJson).find((x) => ( x.searchKey === 'angry_bird')) ; 
    const  isHighProfileMangement  =  clientSegmentation.conditionsJson && JSON.parse(clientSegmentation.conditionsJson).find((x) => ( x.searchKey === 'high_profile_management')) ; 
    
  
      const currentclientSegmentation = {
        clientSegmentationId: clientSegmentation && clientSegmentation.clientSegmentationId,
        conditions: conditionsValues , 
        fields: !checkValueIsString ? (clientSegmentation && clientSegmentation.fieldsJson && JSON.parse(clientSegmentation.fieldsJson)) :
          [{
            name: 'Full Name (Contact)',
            displayPath: 'FullName',
            category: 'Contacts',
            searchKey: 'FullName',
            categoryType: 1
          },
          {
            name: 'Email (Contact)',
            displayPath: 'Email',
            category: 'Contacts',
            searchKey: 'Email',
            categoryType: 1
          },
          {
            name: 'Mobile (Contact)',
            displayPath: 'mobile.phone',
            category: 'Contacts',
            searchKey: 'mobile',
            categoryType: 1
          },
          {
            name: 'Created On (Contact)',
            displayPath: 'createdOn',
            category: 'Contacts',
            searchKey: 'createdOn',
            categoryType: 1
          },
          {
            name: 'Created By (Contact)',
            displayPath: 'createdBy.name',
            category: 'Contacts',
            searchKey: 'createdBy',
            categoryType: 1
          }],
        isAngryBird  :  (isAngryBird  &&  isAngryBird.value  && isAngryBird.value === 'Yes' )|| false, 
        isHighProfileMangement :  (isHighProfileMangement  &&  isHighProfileMangement.value  && isHighProfileMangement.value === 'Yes' )|| false, 
        clientSegmantationName: clientSegmentation && clientSegmentation.clientSegmentationName,
        clientSegmentationFiles: !checkValueIsString ? clientSegmentation && clientSegmentation.clientSegmentationFiles : [],
        isRolesPermission: (clientSegmentation && clientSegmentation.isRolesPermission) || false,
        isUsersPermission: (clientSegmentation && clientSegmentation.isUsersPermission) || false,
        owner: (clientSegmentation && clientSegmentation.owner),
        ownerId: (clientSegmentation && clientSegmentation.ownerId),
        privacyType: (clientSegmentation && clientSegmentation.privacyType),
        description: (clientSegmentation && clientSegmentation.description),
        clientSegmentationRoleDtos  :  (clientSegmentation && clientSegmentation.clientSegmentationRoleDtos), 
        clientSegmentationUserDtos : (clientSegmentation && clientSegmentation.clientSegmentationUserDtos), 


      };
      const systemReportFilters = JSON.parse(clientSegmentation.clientSegmentationFilters);

      const filterObject =
      {
        orderBy: (systemReportFilters && systemReportFilters !== '') ? ((systemReportFilters && systemReportFilters.orderBy) || 'createdOn') : 'createdOn',
        orderType: (systemReportFilters && systemReportFilters !== '') ? ((systemReportFilters && systemReportFilters.orderType) || 2) : 2,
        orderByCategory: (systemReportFilters && systemReportFilters !== '') ? ((systemReportFilters && systemReportFilters.orderByCategory) || 'Contacts') : 'Contacts',
        fromDate: (systemReportFilters && systemReportFilters !== '') ? (systemReportFilters && systemReportFilters.fromDate) : null,
        toDate: (systemReportFilters && systemReportFilters !== '') ? (systemReportFilters && systemReportFilters.toDate) : null,
        dateType: (systemReportFilters && systemReportFilters !== '') ? (systemReportFilters && systemReportFilters.dateType) : null,
      };

      setDateFilter({
        startDate: (systemReportFilters && systemReportFilters !== '') ? (systemReportFilters.fromDate && new Date(moment(systemReportFilters && systemReportFilters.fromDate))) || null : null,
        endDate: (systemReportFilters && systemReportFilters !== '') ? systemReportFilters.toDate && new Date(moment(systemReportFilters && systemReportFilters.toDate)) || null : null,
        key: 'selection',
      });

      setState({ id: 'edit', value: { ...currentclientSegmentation, systemReportFilters: filterObject } });
      setSearchWhenOpenPage(true);
    }
  }, [clientSegmentation]);

  useEffect(() => {
    if (searchWhenOpenPage && clientSegmentation && state.conditions.length > 0 && state.fields.length > 0 && !isSearchFirst)
      searchHandler();
  }, [searchWhenOpenPage]);

  useEffect(() => {
    if (isSelect)
      searchHandler();
  }, [filter, state.fields, state.systemReportFilters , state.fromDate , state.toDate ,  state.isAngryBird ,  state.isHighProfileMangement ]);


  // useEffect(() => {
  //   const currentState = {
  //     ...state.systemReportFilters,
  //     fromDate: (dateFilter.startDate && moment(dateFilter.startDate).startOf('day').format('YYYY-MM-DDTHH:mm:ss')) || null,
  //     toDate: (dateFilter.endDate && moment(dateFilter.endDate).format('YYYY-MM-DDTHH:mm:ss')) || null,
  //   };
  //   setState({ id: 'edit', value: currentState });
  // }, [dateFilter]);

  const contantValue = (conditionItem) => {
    if (conditionItem) {
      if (conditionItem.fieldName && conditionItem.fieldName && conditionItem.fieldName.field && conditionItem.fieldName.field.id &&
        (conditionItem.fieldName.field.id === 'createdBy' || conditionItem.fieldName.field.id === 'updatedBy'))
        return (conditionItem.value && conditionItem.value.id);

      return (conditionItem.value && (conditionItem.value.lookupItemName || conditionItem.value.name || conditionItem.value));
    }
  };

  const returnFinalObject = (isSearch  , isAngryBird , isHighProfileMangement ) => {
    const conditions = [];
    let finalObj = null;
    const conditionsWithAngryBirdList = [
      {category: "contacts",operand: "AND",operator: 1,searchKey: "angry_bird",value: (state.isAngryBird ? 'Yes' :  'No')},
      {category: "contacts", operand: null, operator: 1,searchKey: "high_profile_management",value: (state.isHighProfileMangement ? 'Yes' :  'No')}];

    state.conditions.map((element) => {
      conditions.push({
        category: (element.category !== 'activities' ? element.category : 'leads'),
        categoryType: (element.categoryType !== 8 ? element.categoryType : 6),
        formId: element.categoryType,
        searchKey: element.searchKey,
        value: (contantValue(element)),
        operator: element.operator,
        operand: element.operand,
      });
    });
    if (isSearch) {
      finalObj = {
        report:
        {
          conditions : [...conditions ,  ...conditionsWithAngryBirdList],
          fields: state.fields,
          systemReportFilters: {
          ...state.systemReportFilters,
           fromDate: state.systemReportFilters && state.systemReportFilters.fromDate && moment(state.systemReportFilters.fromDate).startOf('day').format('YYYY-MM-DDTHH:mm:ss'),
           toDate: (state.systemReportFilters && state.systemReportFilters.toDate && moment(state.systemReportFilters.toDate).format('YYYY-MM-DDTHH:mm:ss')) || null,

          },
        }
      };
      return finalObj;
    }
    const conditionsWithAngryBird =  [...state.conditions , ...conditionsWithAngryBirdList ] ;

    finalObj =
    {
      conditionsJson: state.conditions ? JSON.stringify(conditionsWithAngryBird) : '',
      fieldsJson: state.fields ? JSON.stringify(state.fields) : '',
      clientSegmentationName: state.clientSegmantationName,
      clientSegmentationFiles: [],
      systemReportFilters: state.systemReportFilters ? JSON.stringify(state.systemReportFilters) : '',
      description: state.description,
      ownerId: state.ownerId,
      isRolesPermission: state.isRolesPermission,
      isUsersPermission: state.isUsersPermission,
      privacyType: state.privacyType,
      clientSegmentationUserDtos: state.clientSegmentationUserDtos,
      clientSegmentationRoleDtos: state.clientSegmentationRoleDtos,
      clientSegmentationFilters: JSON.stringify(
        {
          orderBy: orderTypeBy && orderTypeBy.searchKey,
          orderType: (orderTypeBy && orderTypeBy.order),
          orderByCategory: (orderTypeBy && orderTypeBy.orderByCategory),
          fromDate: (state && state.fromDate),
          toDate: (state && state.toDate),
          dateType: (selectedOrderBy && selectedOrderBy.filterBy),
        }
      ),
    };
    return finalObj;
  };

  const searchHandler = useCallback(async () => {
    setSearchWhenOpenPage(false);

    if (!state.fields.length) {
      showError(t(`${translationPath}please_select_columns`));
      return;
    }

    if (schema.error) {
      showError(t('Shared:please-fix-all-errors'));
      return;
    }

    if (state && state.systemReportFilters && state.systemReportFilters.orderBy) {
      const findIndexOfOrderBy = state.fields.indexOf(
        state.fields.find((f) => (f.searchKey === (state && state.systemReportFilters && state.systemReportFilters.orderBy)))
      );

      if (findIndexOfOrderBy === -1) {
        showError(t(`${translationPath}please_select_columns`));
        return;
      }
    }
    const reportJson = returnFinalObject(true );
    const searchObj = { spName: 'GetSegmentationContacts', reportJson };
    setIsLoading(true);
    const res = await GetSystemReportsResultClientsSegmentation(filter.pageIndex + 1, filter.pageSize, searchObj);
    if (!(res && res.status && res.status !== 200)) {
      setSearchResponse({ result: res, totalCount: (res && res.length ? res[0].TotalCount : 0) });
      setIsSearchFirst(true);
      setIsLoading(false);
    } else {
      setSearchResponse({ result: [], totalCount: 0 });
      showError(t(`${translationPath}search-clients-segmentation-failure`));
    }
    setIsLoading(false);
  }, [state.fields, filter, clientSegmentation , state.isAngryBird , state.isHighProfileMangement]);

  const saveHandler = useCallback(async () => {
    setIsSubmitted(true);
    if (localSchema.error) {
      showError(t('Shared:please-fix-all-errors'));
      return;
    }
    
    else if (schema.error) {
      showError(t('Shared:please-fix-all-errors'));
      return;
    }
    if (!orderTypeBy || !state.fields.length) {
      showError(t(`${translationPath}please_select_columns`));
      return;
    }
    if (state && state.systemReportFilters && state.systemReportFilters.orderBy) {
      const findIndexOfOrderBy = state.fields.indexOf(
        state.fields.find((f) => (f.searchKey === (state && state.systemReportFilters && state.systemReportFilters.orderBy)))
      );

      if (findIndexOfOrderBy === -1) {
        showError(t(`${translationPath}please_select_columns`));
        return;
      }
    }
    setIsLoading(true);
    const addCilent = returnFinalObject(false);
    const res = await UpdateClientSigmantationServices(clientsSegmentationId, addCilent);
    if (!(res && res.status && res.status !== 200)) {
      showSuccess(t(`${translationPath}edit-clients-segmentation-successfully`));
      GlobalHistory.goBack();
      setIsOpenSaveDialog(false);
    } else showError(t(`${translationPath}edit-clients-segmentation-failure`));
    setIsLoading(false);
  }, [state]);

  useEffect(() => {
    const selectedfields = [];
    tableColumns.map((e) => {
      selectedfields.push({
        name: e.label,
        isHidden: e.isHidden,
        displayPath: e.displayPath,
        category: e.category,
        searchKey: e.searchableKey,
        categoryType: e.categoryType,
      });
    });
    setState({ id: 'fields', value: selectedfields });
  }, [tableColumns]);

  // useEffect(() => {
  //   if(state.fields.length > 0 && isSearchFirst )
  //     searchHandler();
  // }, [state.fields , filter  ]);

  const exportDataToFormats = async (Format, fileName) => {
    if (!searchResponse.result.length) {
      showError(t(`${translationPath}Dont_have_data_to_export_excel`));
      return;
    }
    if (searchResponse.result.length) {
      setIsLoading(true);
      const reportJson = returnFinalObject(true);
      const searchObj = { spName: 'GetSegmentationContacts', reportJson };
      setIsLoading(true);
      const res = await GetSystemReportsResultClientsSegmentation(1, searchResponse.result[0].TotalCount, searchObj);
      if (!(res && res.status && res.status !== 200)) {
        setIsLoading(false);
        if (res && res.length === 0) return;
        const data = res;
        let exportType = null;
        switch (Format) {
          case 'xls':
            exportType = exportFromJSON.types.xls;
            break;
          case 'csv':
            exportType = exportFromJSON.types.csv;
            break;
          case 'xml':
            exportType = exportFromJSON.types.xml;
            break;
          case 'json':
            exportType = exportFromJSON.types.json;
            break;
          default:
            exportType = exportFromJSON.types.xls;
            break;
        }
        exportFromJSON({ data, fileName, exportType });
        const res2 = await ExportReportServices();
        if (res2) {

        }
      } else
        showError(t(`${translationPath}search-clients-segmentation-failure`));
    }
  };

  const getValue = (object, values) => {
    // eslint-disable-next-line no-restricted-syntax
    for (const [key, value] of Object.entries(object)) {
      if (key === values) {
        const valueIs = ((value !== null) ? value : 'N/A');
        return valueIs;
      }
    }
  };

  useEffect(() => {
    bottomBoxComponentUpdate(
      <PaginationComponent
        pageIndex={filter.pageIndex}
        pageSize={filter.pageSize}
        totalCount={searchResponse && searchResponse.result && searchResponse.result.length ? searchResponse.result[0].TotalCount : 0}
        onPageIndexChanged={onPageIndexChanged}
        onPageSizeChanged={onPageSizeChanged}
      />
    );
  });
  useEffect(
    () => () => {
      bottomBoxComponentUpdate(null);
    },
    []
  );

  const sortHandler = (item, index) => {
    const currentState = {
      ...state,
      systemReportFilters: {
        dateType: state.systemReportFilters && state.systemReportFilters.dateType,
        fromDate: state.systemReportFilters && state.systemReportFilters.fromDate,
        toDate: state.systemReportFilters && state.systemReportFilters.toDate,
        searchKey: item.searchableKey,
        orderByCategory: item.category,
        orderBy: item.searchableKey,
        orderType: (state.systemReportFilters && state.systemReportFilters.orderType) === 1 ? 2 : 1
      }
    };
    setState({ id: 'edit', value: currentState });
    setFilter((item) => ({ ...item, pageIndex: 0 }));
  };

  const byChanged = (index, item) => {
    setTableColumns((items) => {
      items.splice(fromIndex, 1);
      items.splice(index, 0, formFields);
      if (fromIndex !== -1)
        return [...items];
    });
  };

  const tables = document.getElementsByClassName('resizable');
  for (let i = 0; i < tables.length; i++)
    resizableGrid(tables[i]);

  function resizableGrid(table) {
    const row = table.getElementsByTagName('tr')[0];
    const cols = row ? row.children : undefined;
    if (!cols) return;

    table.style.overflow = 'hidden';

    const tableHeight = table.offsetHeight;

    for (let i = 0; i < cols.length; i++) {
      const div = createDiv(tableHeight);
      cols[i].appendChild(div);
      cols[i].style.position = 'relative';
      setListeners(div);
    }

    function setListeners(div) {
      let pageX; let curCol; let nxtCol; let curColWidth; let nxtColWidth; let
        tableWidth;

      div.addEventListener('mousedown', (e) => {
        tableWidth = 200;
        curCol = e.target.parentElement;
        nxtCol = curCol.nextElementSibling;
        pageX = e.pageX;

        const padding = paddingDiff(curCol);

        curColWidth = curCol.offsetWidth - padding;
        if (nxtCol)
          nxtColWidth = nxtCol.offsetWidth - padding;
      });

      div.addEventListener('mouseover', (e) => {
        e.target.style.borderRight = '2px solid #0000ff';
      });

      div.addEventListener('mouseout', (e) => {
        e.target.style.borderRight = '';
      });

      document.addEventListener('mousemove', (e) => {
        if (curCol) {
          const diffX = e.pageX - pageX;

          if (nxtCol)
            nxtCol.style.width = `${nxtColWidth - (diffX)}px`;

          curCol.style.width = `${curColWidth + diffX}px`;
          document.getElementById('tableId').style.width = `${tableWidth + diffX}px`;
        }
      });

      document.addEventListener('mouseup', (e) => {
        curCol = undefined;
        nxtCol = undefined;
        pageX = undefined;
        nxtColWidth = undefined;
        curColWidth = undefined;
      });
    }

    function createDiv(height) {
      const div = document.createElement('div');
      div.style.top = 0;
      div.style.right = 0;
      div.style.width = '5px';
      div.style.position = 'absolute';
      div.style.cursor = 'col-resize';
      div.style.userSelect = 'none';
      div.style.height = `${height}px`;
      return div;
    }

    function paddingDiff(col) {
      if (getStyleVal(col, 'box-sizing') == 'border-box')
        return 0;

      const padLeft = getStyleVal(col, 'padding-left');
      const padRight = getStyleVal(col, 'padding-right');
      return (parseInt(padLeft) + parseInt(padRight));
    }

    function getStyleVal(elm, css) {
      return (window.getComputedStyle(elm, null).getPropertyValue(css));
    }
  }

  return (
    <div className='ClientsSegmentationView-wrapper view-wrapper'>
      <Spinner isActive={isLoading} isAbsolute />
      <div className='w-100 px-2'>
        <div className='filterByDate'>
          <div className='CenterDate'>
            <SelectComponet
              idRef='filterByRef'
              data={[
                { id: 1, filterBy: 'created-on' },
                { id: 2, filterBy: 'last-updated' },
              ]}
              wrapperClasses='mb-3'
              isRequired
              value={state.systemReportFilters && state.systemReportFilters.dateType}
              onSelectChanged={filterByChanged}
              valueInput='id'
              textInput='filterBy'
              emptyItem={{
                value: null,
                text: 'select-filter-by',
                isDisabled: false,
              }}
              parentTranslationPath={parentTranslationPath}
              translationPath={translationPath}
              translationPathForData={translationPath}
              endAdornment={(
                <div className='d-flex-v-center'>
                  <span className='px-2'>   </span>
                  <DateRangePickerComponent
                    onClearClicked={() => {
                      setDateFilter({
                        startDate: null,
                        endDate: null,
                        key: 'selection'
                      });
                      const filterBy = { ...state.systemReportFilters };
                      const currentState = {
                        ...state,
                        systemReportFilters: {
                          filterBy, fromDate: null, toDate: null, dateType: null
                        }
                      };
                      setState({ id: 'edit', value: currentState });
                      if (isSearchFirst) {
                        setFilter((item) => ({ ...item, pageIndex: 0 }));
                        setIsSelected(true);
                      }
                    }}
                    ranges={[dateFilter]}
                    parentTranslationPath={parentTranslationPath}
                    translationPath={translationPath}
                    onDateChanged={(selectedDate) => {
                      setDateFilter({
                        startDate: selectedDate.selection && selectedDate.selection.startDate,
                        endDate: new Date(moment(selectedDate.selection && selectedDate.selection.endDate).endOf('day')),
                        key: 'selection',
                      });
                      const currentState = {
                        ...state,
                        systemReportFilters: {
                          ...state.systemReportFilters,
                          fromDate: selectedDate.selection && selectedDate.selection.startDate,
                          toDate: new Date(moment(selectedDate.selection && selectedDate.selection.endDate).endOf('day')),
                        }
                      };
                      setState({ id: 'edit', value: currentState });
                      if (isSearchFirst) {
                        setFilter((item) => ({ ...item, pageIndex: 0 }));
                        setIsSelected(true);
                      }
                    }}
                    isDisabled={!(state.systemReportFilters && state.systemReportFilters.dateType)}
                  />
                </div>
              )}
            />

          </div>
          <SearchAndAddComponent
            parentTranslationPath={parentTranslationPath}
            translationPath={translationPath}
            state={state}
            schema={schema}
            ifHaveAnyErrors={ifHaveAnyErrors}
            searchHandler={searchHandler}
            saveHandler={saveHandler}
            setIsOpenSaveDialog={setIsOpenSaveDialog}
            exportDataToFormats={(format, name) => {
              exportDataToFormats(format, name);
            }}
          />
        </div>
      </div>
      <div className='w-100 px-2'>
        <div className='client-segmentation-wrapper'>
          <QeuriesComponents
            state={state}
            schema={schema}
            setState={setState}
            parentTranslationPath={parentTranslationPath}
            translationPath={translationPath}
            ifHaveAnyErrors={ifHaveAnyErrors}
            setIfHaveAnyErrors={setIfHaveAnyErrors}
            fields={fields}
            setFields={setFields}
            searchHandler={searchHandler}
            saveHandler={saveHandler}
            setIsOpenSaveDialog={setIsOpenSaveDialog}
          />
        </div>
      </div>
      <div className='w-100 px-2 mt-2'>
        <SearchClientsSegmentationTable
          tableColumns={tableColumns}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          setTableColumns={setTableColumns}
          setSortBy={setSortBy}
          tableFilterData={tableFilterData}
          filter={filter}
          onPageIndexChanged={onPageIndexChanged}
          onPageSizeChanged={onPageSizeChanged}
          setState={setState}
          searchResponse={searchResponse}
          clientSegmentation={clientSegmentation}
          fields={state.fields}
          state={state}
          setIsSelected={setIsSelected}
        />
      </div>
      {
        isOpenSaveDialog && (
          <SaveClientsSegmentation
            open={isOpenSaveDialog}
            localSchema={localSchema}
            close={() => { setIsOpenSaveDialog(false); }}
            parentTranslationPath={parentTranslationPath}
            translationPath={translationPath}
            onSave={() => {
              saveHandler();
            }}
            setState={setState}
            state={state}
            setIsSubmitted={setIsSubmitted}
            isSubmitted={isSubmitted}
            isLoading={isLoading}
            clientSegmentation={clientSegmentation}
          />
        )
      }
      <div className='w-100 px-2'>
        <div className='TableReportsView-view-wrapers mt-3 searchTable'>
          <table
            id='tableId'
            className='table'
          >
            <thead
              className=''
            >
              <tr>
                {
                  tableColumns.map((item, itemIndex) => (
                    <Tooltip
                      title={item.label}
                      aria-label='CompanyTip'
                      arrow
                    >
                      <th
                        index={itemIndex + 1000}
                        onDragOver={(event) => {
                          event.preventDefault();
                        }}
                        draggable
                        onDrop={() => (byChanged(itemIndex, item)) || ''}
                        onDragCapture={() => {
                          setFromIndex(itemIndex);
                          setFormFields(item);
                        }}
                      >
                        <Inputs
                          idRef={`items${itemIndex + 1}`}
                          parentTranslationPath={parentTranslationPath}
                          translationPath={translationPath}
                          value={item.label}
                          onInputChanged={(event) => {
                          }}
                          endAdornment={(
                            <>
                              <Tooltip

                                size='small'
                                title={t(`${translationPath}sort`)}
                              >
                                <Button
                                  onClick={() => {
                                    sortHandler(item, itemIndex);
                                    setIsSelected(true);
                                  }}
                                  className={`trash-bbt  ${item.searchableKey === (state.systemReportFilters && state.systemReportFilters.orderBy) && item.category === (state.systemReportFilters && state.systemReportFilters.orderByCategory) ? 'is-selected' : ''} is-red`}
                                >
                                  <span className='p-1 youtube-wraper'>
                                    <span className='mdi mdi-swap-vertical-bold mdi-24px' />
                                  </span>
                                </Button>
                              </Tooltip>
                            </>
                          )}
                        />
                      </th>
                    </Tooltip>
                  ))
                }
              </tr>
            </thead>
            <tbody>
              {searchResponse && searchResponse.result.map((item, index) => (
                <tr index={index}>
                  {state && state.fields.map((itemrrr, index2) => (
                    <td index={index2 * Math.random()}>
                      {getValue(item, itemrrr.name) || ''}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>

          <div />

        </div>

      </div>

    </div>
  );
};
