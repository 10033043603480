import React, { useState, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import './PropertyInfoStyle.scss';
import { PropertiesAdvanceSearchTest } from '../../../../../../../Services';

export const PropertyInfo = ({ parentTranslationPath, translationPath, propertyId }) => {
  const { t } = useTranslation([parentTranslationPath, 'Shared']);
  const [propertyData, setPropertyData] = useState({
    property_type: null,
    property_plan: null,
    completion_date: null,
    service_charge: null,
    amenities: null,
  });


  const getUnitPropertyInformation = useCallback(async () => {

    let body = {
      criteria: {
        Ids: [{ searchType: 1, value: propertyId }],
      },
      filterBy: 'createdOn',
      orderBy: 2,
    };

    const res = await PropertiesAdvanceSearchTest({ pageIndex: 0, pageSize: 10 }, body);
    if (!(res && res.status && res.status !== 200)) {
      if (res?.result && res.result.length > 0) {
        const parsedPropertyJson = JSON.parse(res.result[0].propertyJson);

        const amenitiesNamesList =
          parsedPropertyJson?.property?.amenities &&
          parsedPropertyJson.property.amenities.map((item) => item?.lookupItemName);

        setPropertyData({
          property_type: parsedPropertyJson?.property?.property_type?.lookupItemName,
          property_plan: parsedPropertyJson?.property?.property_plan?.lookupItemName,
          completion_date: parsedPropertyJson?.property?.completion_date,
          service_charge: (parsedPropertyJson && parsedPropertyJson.property &&  parsedPropertyJson.property.service_charge?.lookupItemName) ||  (parsedPropertyJson && parsedPropertyJson.property &&  parsedPropertyJson.property.service_charge),
          amenities: amenitiesNamesList,
        });
      }
    }
  }, [propertyId]);

  useEffect(() => {
    getUnitPropertyInformation();
  }, [propertyId]);

  return (
    <div className='PropertyInformation'>
      <div className='card'>
        <div>
          <span className='titleSection'>
            {t('PropertyInformation')}
          </span>
        </div>
        <div className='PropertyInfoContant'>
          <div className='sections-wrapper'>
            <div className='sectionItem'>
              <span className='mdi mdi-floor-plan'> </span>
              <span className='headtitle mx-1'>{`${t('property_plan')} : `}</span>
              {propertyData?.property_plan || 'N/A'}
            </div>
            <div className='sectionItem'>
              <span className='mdi mdi-home-modern'> </span>
              <span className='headtitle mx-1'>{`${t('property_type')} : `}</span>{' '}
              {propertyData?.property_type || 'N/A'}
            </div>
            <div className='sectionItem'>
              <span className='mdi mdi-timetable'> </span>
              <span className='headtitle mx-1'>{`${t('completion_date')} : `}</span>{' '}
              {(propertyData?.completion_date &&
                moment(propertyData.completion_date).format('DD/MM/YYYY')) ||
                'N/A'}
            </div>
            <div className='sectionItem'>
              <span className='mdi mdi-currency-usd'> </span>
              <span className='headtitle mx-1'>{`${t('service_charge')} : `}</span>{' '}
              {propertyData?.service_charge || 'N/A'}
            </div>
          </div>
          <div className='capsules-container'>
            <span className='headtitle mx-1'>{`${t('amenities')} : `}</span>{' '}
            <div className='capsules-container'>
              {(propertyData?.amenities &&
                propertyData.amenities.map((item) =>
                  item ? <span className='capsule-element'>{item}</span> : null
                )) ||
                'N/A'}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

