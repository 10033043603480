import React, {
  useCallback, useEffect, useRef, useState
} from 'react';
import PropTypes from 'prop-types';
import { ButtonBase } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { DefaultImagesEnum } from '../../../Enums';
import { DialogComponent } from '../../DialogComponent/DialogComponent';
import { LoadableVideoComponant } from '../../../Components';
import defaultVideoImage from '../../../assets/images/defaults/defaultVideoImage.png';

import { getIsValidURL, getIsValidDataURL, getDownloadableLink } from '../../../Helper';

import { useLocalStorage } from '../../../Hooks';

export const VideoPreviewDialog = ({
  data,
  titleText,
  parentTranslationPath,
  translationPath,
  videoInput,
  defaultImage,
  isOpen,
  onOpenChanged,
  keyRef,
  clickedActiveVideoIndex,
  nextHandle,
  backHandle,
}) => {
  const { t } = useTranslation([parentTranslationPath, 'Shared']);
  const [activeVideo, setActiveVideo] = useState(null);
  const [currentDirection] = useLocalStorage('localization', { isRtl: false });
  const thumbnailWrapperRef = useRef(null);
  const scrollTimer = useRef(null);
  const [scrollCurrentItem, setScrollCurrentItem] = useState(0);

  const openLink = (url) => {
    if (!url.match(/^https?:\/\//i)) url = 'http://' + url;
    if (getIsValidURL(url)) {
      window.open(url || '');
    } else if (getIsValidDataURL(url)) {
      let windowObj = window.open();
      const iframeElement = `<iframe src="${url}" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>`
      windowObj.document.write(iframeElement);
    }
  }
  const getVideoFileId = (dataItem, columnPath) => {
    if (!dataItem) return '';
    if (!columnPath) return (typeof dataItem !== 'object' && dataItem) || '';
    if (!columnPath.includes('.')) return dataItem[columnPath];
    let a = dataItem;
    columnPath.split('.').map((item) => {
      if (a) a = a[item];
      return item;
    });
    return a;
  };

  const scrollPositionHandler = useCallback(() => {
    const element = thumbnailWrapperRef.current;
    if (
      !(element && element.firstChild && element.firstChild.childNodes.length > scrollCurrentItem)
    )
      return;
    const nodeElement = element.firstChild.childNodes[scrollCurrentItem];
    const isVisable =
      (currentDirection.isRtl &&
        nodeElement.offsetLeft < element.scrollLeft &&
        nodeElement.offsetLeft - nodeElement.offsetWidth >
        element.offsetWidth - element.scrollLeft) ||
      (nodeElement.offsetLeft > element.scrollLeft &&
        nodeElement.offsetLeft + nodeElement.offsetWidth <
        element.offsetWidth + element.scrollLeft);
    if (!isVisable) {
      element.scrollTo({
        left: nodeElement.offsetLeft - 35,
        behavior: 'smooth',
      });
    }
  }, [currentDirection.isRtl, scrollCurrentItem]);

  const toHandler = (direction) => () => {
    setScrollCurrentItem((item) => {
      let currentItemLocal = item;
      if (direction === 'next') currentItemLocal += 1;
      else currentItemLocal -= 1;
      return currentItemLocal;
    });
  };

  const activeVideoHandler = useCallback(
    (item, index) => () => {
      setScrollCurrentItem(index);
    }, []);

  useEffect(() => {
    scrollPositionHandler();
  }, [scrollPositionHandler, scrollCurrentItem]);

  useEffect(() => {
    setActiveVideo(data[clickedActiveVideoIndex]);
    setScrollCurrentItem(clickedActiveVideoIndex);

  }, []);

  useEffect(() => {
    if (data && data.length) {
      setActiveVideo(data[scrollCurrentItem]);
    }
  }, [data, scrollCurrentItem]);

  useEffect(
    () => () => {
      if (scrollTimer.current) clearTimeout(scrollTimer.current);
    }, []);

  return (
    <DialogComponent
      titleText={titleText}
      dialogContent={(
        <div className='video-preview-wrapper'>

          <div className='video-preview-wrapper-inputs'>
            {activeVideo && (
              <>
                <div className='video-preview-active-wrapper'>
                  <div className='video-preview-active-video-wrapper ' onClick={() =>
                    activeVideo.videoLink && openLink(activeVideo.videoLink)
                  }>
                    {activeVideo && (
                      <LoadableVideoComponant
                        src={
                          (getVideoFileId(activeVideo, videoInput) &&
                            getDownloadableLink(getVideoFileId(activeVideo, videoInput))) ||
                          defaultVideoImage
                        }
                        isLink={activeVideo.videoLink && true}
                        // fileId={activeVideo.fileId || null}
                        alt={t(`${translationPath}active-video`)}
                        width={500}
                        height={500}
                        classes='video-preview-active-image'
                        preload='none'
                      />
                    )}
                  </div>
                </div>
              </>
            )}
          </div>
          {data && data.length > 0 && (
            <div className='w-100 d-flex-center flex-wrap mb-2'>
              <ButtonBase
                className='btns-icon theme-solid mx-2 mb-2'
                disabled={scrollCurrentItem === 0}
                onClick={backHandle ? backHandle(toHandler, 'previous') : toHandler('previous')}
              >
                <span className='mdi mdi-chevron-left' />
              </ButtonBase>
              <ButtonBase
                className='btns-icon theme-solid mx-2 mb-2'
                disabled={scrollCurrentItem >= data.length - 1}
                onClick={
                  nextHandle ?
                    nextHandle(toHandler, 'next', scrollCurrentItem + 1) :
                    toHandler('next')
                }
              >
                <span className='mdi mdi-chevron-right' />
              </ButtonBase>
            </div>
          )}
          {data && data.length > 0 && (
            <div className='video-preview-thumbnail-wrapper' ref={thumbnailWrapperRef}>
              <div className='video-preview-thumbnail-items-wrapper'>
                {data.map((video, index) => (
                  <ButtonBase
                    onClick={() => {
                      activeVideoHandler(video, index)
                      setActiveVideo(video)
                    }}
                    className={`video-preview-thumbnail-item${(activeVideo && getVideoFileId(activeVideo, videoInput) ?
                      getVideoFileId(activeVideo, videoInput) === getVideoFileId(video, videoInput) && ' active-video' :
                      getVideoFileId(activeVideo, 'videoLink') === getVideoFileId(video, 'videoLink') && ' active-video') ||
                      ''
                      }`}
                    key={`${keyRef}${index + 1}`}
                  >
                    <LoadableVideoComponant
                      classes='video-preview-thumbnail-image'
                      hideVideoControls
                      // fileId={video.fileId}
                      src={`${(video && video.fileId && getDownloadableLink(video.fileId))}`}
                      alt={t(`${translationPath}video-slider`)}
                      width={100}
                      height={100}
                      preload='auto'
                      isLink={video.videoLink && true}
                      muted
                    />

                  </ButtonBase>
                ))}
              </div>
            </div>
          )}
        </div>
      )}
      isOpen={isOpen}
      saveType='button'
      parentTranslationPath={parentTranslationPath}
      translationPath={translationPath}
      onCloseClicked={onOpenChanged}
      onCancelClicked={onOpenChanged}
    />
  );
};

VideoPreviewDialog.propTypes = {
  data: PropTypes.instanceOf(Array).isRequired,
  defaultImage: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.oneOf(Object.values(DefaultImagesEnum).map((item) => item.defaultImg)),
  ]),
  nextHandle: PropTypes.func,
  updateData: PropTypes.func,
  backHandle: PropTypes.func,
  onOpenChanged: PropTypes.func.isRequired,
  activeVideoTooltipComponent: PropTypes.func,
  thumbnail: PropTypes.func,
  clickedActiveVideoIndex: PropTypes.number,
  titleText: PropTypes.string,
  altInput: PropTypes.string,
  videoInput: PropTypes.string,
  parentTranslationPath: PropTypes.string,
  translationPath: PropTypes.string,
  keyRef: PropTypes.string,
  isOpen: PropTypes.bool,
  alt: PropTypes.string,
  WithUnitDetails: PropTypes.bool,
};
VideoPreviewDialog.defaultProps = {
  nextHandle: null,
  backHandle: null,
  defaultImage: DefaultImagesEnum.videoImage.defaultImg,
  clickedActiveVideoIndex: 0,
  titleText: undefined,
  activeVideoTooltipComponent: undefined,
  updateData: undefined,
  videoInput: null,
  thumbnail: () => { },
  parentTranslationPath: '',
  translationPath: '',
  keyRef: 'videoGalleryRef',
  altInput: null,
  WithUnitDetails: false,
  alt: null,
  isOpen: false,
};
