import React from "react";
import moment from "moment";

export const PaymentDetailsHeaderData = [
    {
        id: 2,
        label: 'Installment date',
        input: 'installmentDate',
        component: (item) =>
            (
              <span>
                {item.installmentDate
                  ? moment(item.installmentDate).format("LL")
                  : null}
              </span>
            ) || "",
    },
    {
        id: 3,
        label: 'Installment details',
        input: 'installmentDetails',
    },
    {
        id: 4,
        label: 'Amount',
        input: 'amount',
    },
    {
        id: 5,
        label: 'PSI Recipe No.',
        input: 'psiReciptNo',
    },
    {
        id: 6,
        label: 'Mode of payment',
        input: 'modeOfPayment',
    },
    {
        id: 7,
        label: 'Bank Name',
        input: 'bankName',
    },
    {
        id: 8,
        label: 'Cheque no/Transfare No.',
        input: 'refNo',
    },
]