import React from "react";
import { Box } from "@material-ui/core";
import moment from "moment";

import {
  CustomBadge,
  CustomCopyToClipboard,
  LeadTypeBadges,
  LeadsPriorityBadges,
} from "../../../../../../Components";
import { AgentTypesEnum } from "../../../../../../Enums";

// Icons
import { DotIcon } from "../../../../../../assets/icons";

export const leadTableHeaderData = (isDarkMode = false) => [
  {
    id: 1,
    formFieldId: 1,
    minWidth: 140,
    headerName: "Lead Type",
    field: "leadClass",
    sortable: true,
    filterable: true,
    fieldType: "text",
    displayPath: "leadClass",
    isDefaultFilterColumn: true,
    fieldKey: "leadClass",
    formFieldTitle: "leadClass",
    disableColumnMenu: true,
    searchKey: "leadClass",
    renderCell: (item) => {
      const getAgentTypeKey = (value) => {
        const agentType = Object.values(AgentTypesEnum).find(
          (type) => type.value === value
        );
        return agentType.key.toString();
      };

      return (
        <LeadTypeBadges
          leadTypesList={getAgentTypeKey(item?.row?.leadClass)}
          translateText={false}
        />
      );
    },
  },
  {
    id: 2,
    formFieldId: 2,
    minWidth: 130,
    headerName: "Lead id",
    field: "leadId",
    fieldType: "text",
    sortable: true,
    filterable: true,
    displayPath: "leadId",
    isDefaultFilterColumn: true,
    disableColumnMenu: true,
    fieldKey: "leadId",
    formFieldTitle: "Lead id",
    renderCell: (item) => {
      return (
        <CustomCopyToClipboard
          data={item?.row?.id} // copy
          childrenData={"#" + item?.row?.id} // render
          copyDoneShowTime={1000}
          fontSize="14px"
        />
      );
    },
  },
  {
    id: 3,
    formFieldId: 3,
    minWidth: 130,
    headerName: "Matches",
    field: "matchUnit",
    fieldType: "text",
    fieldKey: "matchUnit",
    sortable: true,
    displayPath: "matchUnit",
    filterable: true,
    disableColumnMenu: true,
    isDefaultFilterColumn: true,
    formFieldTitle: "Matches",
    renderCell: (item) => {
      return (
        <label
          style={{
            lineHeight: "14px",
            fontWeight: 600,
            color: !isDarkMode ? "#635235" : "#cecfd2",
          }}
        >
          {item?.row?.matchUnit}
        </label>
      );
    },
  },
  {
    id: 4,
    formFieldId: 4,
    minWidth: 130,
    headerName: "Status",
    field: "status.lookupItemName",
    fieldType: "text",
    fieldKey: "status.lookupItemName",
    sortable: true,
    displayPath: "status.lookupItemName",
    filterable: true,
    disableColumnMenu: true,
    isDefaultFilterColumn: true,
    formFieldTitle: "Status",
    renderCell: (item) => {
      const isOpen = item?.row?.status?.lookupItemName === "Open";

      const color = isOpen
        ? !isDarkMode
          ? "#067647"
          : "#75E0A7"
        : !isDarkMode
        ? "#B42318"
        : "#FDA29B";

      const fill = isOpen ? "#17B26A" : "#F04438";

      return (
        <CustomBadge
          label={item?.row?.status?.lookupItemName}
          BackgroundColor={"transparent"}
          BorderColor={"transparent"}
          Color={color}
          LeftIcon={<DotIcon width="8" height="8" fill={fill} />}
        />
      );
    },
  },

  {
    id: 5,
    formFieldId: 5,
    minWidth: 130,
    headerName: "Rating",
    field: "rating.lookupItemName",
    fieldType: "text",

    fieldKey: "rating.lookupItemName",
    sortable: true,
    displayPath: "rating.lookupItemName",
    filterable: true,
    disableColumnMenu: true,
    isDefaultFilterColumn: true,
    formFieldTitle: "Rating",
    renderCell: (item) => {
      return (
        item?.row?.rating?.lookupItemName && (
          <LeadsPriorityBadges
            leadTypeKey={item?.row?.rating?.lookupItemName}
          />
        )
      );
    },
  },
  {
    id: 6,
    formFieldId: 6,
    minWidth: 130,
    headerName: "Activities",
    field: "noActivities",
    fieldType: "text",
    fieldKey: "noActivities",
    sortable: true,
    displayPath: "noActivities",
    filterable: true,
    disableColumnMenu: true,
    isDefaultFilterColumn: true,
    formFieldTitle: "Activities",
    renderCell: (item) => {
      return (
        <label
          style={{
            lineHeight: "14px",
            fontWeight: 600,
            color: !isDarkMode ? "#635235" : "#cecfd2",
          }}
        >
          {item?.row?.noActivities}
        </label>
      );
    },
  },

  {
    id: 7,
    formFieldId: 7,
    minWidth: 190,
    headerName: "Creation Date",
    field: "createdOn",
    filterable: true,
    disableColumnMenu: true,
    fieldType: "datePicker",
    displayPath: "createdOn",
    isDate: true,
    sortable: true,
    isDefaultFilterColumn: true,
    fieldKey: "createdOn",
    formFieldTitle: "Creation Date",
    renderCell: (item) => {
      return (
        <Box style={{ display: "flex", flexDirection: "column" }}>
          {item?.row?.createdOn && (
            <label style={{ lineHeight: "20px", fontWeight: 500 }}>
              {moment(item.row.createdOn).format("DD MMM YYYY")}
            </label>
          )}
          {item?.row?.createdBy && (
            <span
              style={{
                lineHeight: "20px",
                fontWeight: 400,
                color: !isDarkMode ? "#475467" : "#94969C",
              }}
            >
              {item.row.createdBy}
            </span>
          )}
        </Box>
      );
    },
  },
  {
    id: 8,
    formFieldId: 8,
    minWidth: 190,
    headerName: "Referred to",
    field: "referredto",
    filterable: true,
    disableColumnMenu: true,
    fieldType: "text",
    displayPath: "referredto",
    isDate: false,
    sortable: true,
    isDefaultFilterColumn: true,
    fieldKey: "referredto",
    formFieldTitle: " Referred to",
    renderCell: (item) => {
      return (
        <label style={{ fontSize: "16px", fontWeight: 500 }}>
          {item?.row?.referredto?.name}
        </label>
      );
    },
  },
  {
    id: 9,
    formFieldId: 9,
    minWidth: 220,
    headerName: "Media details",
    field: "media_detail.lookupItemName",
    filterable: true,
    disableColumnMenu: true,
    fieldType: "text",
    displayPath: "media_detail.lookupItemName",
    isDate: false,
    sortable: true,
    isDefaultFilterColumn: true,
    fieldKey: "media_detail.lookupItemName",
    formFieldTitle: " Media details",
    renderCell: (item) => {
      return (
        <label style={{ fontSize: "14px", fontWeight: 500 }}>
          {item?.row?.media_detail?.lookupItemName}
        </label>
      );
    },
  },
  {
    id: 10,
    formFieldId: 10,
    minWidth: 220,
    headerName: "Lead stage",
    field: "lead_stage.lookupItemName",
    filterable: true,
    disableColumnMenu: true,
    fieldType: "text",
    displayPath: "lead_stage.lookupItemName",
    isDate: false,
    sortable: true,
    isDefaultFilterColumn: true,
    fieldKey: "lead_stage.lookupItemName",
    formFieldTitle: " Lead stage",
    renderCell: (item) => {
      return (
        <label style={{ fontSize: "14px", fontWeight: 500 }}>
          {item?.row?.lead_stage?.lookupItemName}
        </label>
      );
    },
  },
];
