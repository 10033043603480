import PropTypes from 'prop-types';
import { Tables } from '../../../../../../Components';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonBase } from '@material-ui/core';
import './UserLeaves.scss';
import { AddLeavesDialog } from './AddLeavesDialog';
import { DeleteLeavesDialog } from './DeleteLeavesDialog';
import { GetAllUserLeaves } from '../../../../../../Services'
import { GetParams } from '../../../../../../Helper';
import { useEffect } from 'react';
import { TableActions, } from '../../../../../../Enums';
export const UserLeaves = ({
    parentTranslationPath,
    translationPath,
}) => {
    const { t } = useTranslation(parentTranslationPath)
    const [allUserLeaves, setAllUserLeaves] = useState([]);
    const [isOpenAddLeaves, setIsOpenAddLeaves] = useState(false);
    const [activeItem, setActiveItem] = useState(null);
    const [isOpenDeleteDialog, setIsOpenDeleteDialog] = useState(false);

    const getAllUserLeaves = useCallback(async () => {
        const res = await GetAllUserLeaves(GetParams('id'));
        if (!(res && res.status && res.status !== 200)) setAllUserLeaves(res);
        else setAllUserLeaves([]);

    }, [])

    const tableActionClicked = useCallback((actionEnum, item) => {
        setActiveItem(item);
        if (actionEnum === TableActions.editText.key) {
            setIsOpenAddLeaves(true);
        } else if (actionEnum === TableActions.deleteText.key) {
            setIsOpenDeleteDialog(true);
        }

    }, []);

    useEffect(() => {
        if (GetParams('id')) getAllUserLeaves();
    }, [getAllUserLeaves]);

    return (
        <>
            <div className='leave-view-wrapper'>
                <div className='header-leaves'>
                    <div className='title'>{t(`${translationPath}user-leave`)}</div>
                    <div className='add-btn'>
                        <ButtonBase
                            className='btns theme-solid'
                            onClick={() => {
                                setActiveItem(null)
                                setIsOpenAddLeaves(true);
                            }}
                        >
                            <span className='mdi mdi-plus' />
                            <span className='px-1'>{t(`${translationPath}add-leave`)}</span>
                        </ButtonBase>
                    </div>
                </div>
                <Tables
                    data={allUserLeaves || []}
                    headerData={[
                        {
                            id: 1,
                            label: 'name',
                            input: 'userName',
                        },
                        {
                            id: 2,
                            label: 'leave-from',
                            input: 'leaveFrom',
                            dateFormat: 'DD/MM/YYYY',
                            isDate: true
                        },
                        {
                            id: 3,
                            label: 'leave-to',
                            input: 'leaveTo',
                            dateFormat: 'DD/MM/YYYY',
                            isDate: true
                        }
                    ]}
                    defaultActions={[{
                        enum: TableActions.deleteText.key,
                    },
                    {
                        enum: TableActions.editText.key,
                    }]}
                    actionsOptions={{
                        onActionClicked: tableActionClicked,
                    }}
                    parentTranslationPath={parentTranslationPath}
                    translationPath={translationPath}
                    itemsPerPage={allUserLeaves.length || 0}
                    activePage={0}
                    totalItems={allUserLeaves.length || 0}
                />
            </div>
            {
                isOpenAddLeaves && (
                    <AddLeavesDialog
                        setIsOpenAddLeaves={setIsOpenAddLeaves}
                        isOpenAddLeaves={isOpenAddLeaves}
                        activeItem={activeItem}
                        setActiveItem={setActiveItem}
                        parentTranslationPath={parentTranslationPath}
                        translationPath={translationPath}
                        getAllUserLeaves={getAllUserLeaves}
                    />)
            }
            {isOpenDeleteDialog && (
                <DeleteLeavesDialog
                    setIsOpenDeleteDialog={setIsOpenDeleteDialog}
                    activeItem={activeItem}
                    isOpenDeleteDialog={isOpenDeleteDialog}
                    parentTranslationPath={parentTranslationPath}
                    translationPath={translationPath}
                    setActiveItem={setActiveItem}
                    getAllUserLeaves={getAllUserLeaves}
                />)}
        </>
    )
}
UserLeaves.propTypes = {
    parentTranslationPath: PropTypes.string.isRequired,
    translationPath: PropTypes.string.isRequired,
}
