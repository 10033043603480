export const LeadConvertSizeSqftToSqmRuleSlider = (value, onValueChanged) => {
    const sm = [];
    if (value && value[0] && value[1]) {
        sm[0] = (parseFloat(value[0]) * 0.092903);
        sm[1] = (parseFloat(value[1]) * 0.092903);
    }
    onValueChanged(sm, 0, 'size_sqm');
};

export const LeadConvertSizeSqmToSqftRuleSlider = (value, onValueChanged) => {
    const sqftValues = [];
    if (value && value[0] && value[1]) {
        sqftValues[0] = (parseFloat(value[0]) * 10.76391042);
        sqftValues[1] = (parseFloat(value[1]) * 10.76391042);
    }

    onValueChanged(sqftValues, 0, 'size_sqft');
};

export const LeadConvertSizeSqftToSqmRuleSlider1 = (value, setData, setRerender) => {
    const sm = [];
    if (value && value[0] && value[1]) {
        sm[0] = (value[0] * 0.092903);
        sm[1] = (value[1] * 0.092903);
    }
    setData('size_sqm', sm);
    setRerender(Math.random());
};

export const LeadConvertSizeSqmToSqftRuleSlider1 = (value, setData, setRerender) => {
    const sqftValues = [];
    if (value && value[0] && value[1]) {
        sqftValues[0] = (value[0] * 10.76391042);
        sqftValues[1] = (value[1] * 10.76391042);
    }
    setData('size_sqft', sqftValues);
    setRerender(Math.random());
};
