import React, { useCallback, useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import {
  PaginationComponent,
  Spinner,
  Tables,
  ViewTypes,
  TabsComponent,
  Inputs,
} from "../../../../../../Components";
import {
  ActionsEnum,
  TableActions,
  ViewTypesEnum,
} from "../../../../../../Enums";
import { UnitsCardsComponent } from "../../../../UnitsLeaseView/UnitsLeaseUtilities/UnitsCardsComponent/UnitsCardsComponent.jsx";
import { UnitsAdvanceSearchTest } from "../../../../../../Services";
import {
  bottomBoxComponentUpdate,
  GetParams,
  GlobalHistory,
} from "../../../../../../Helper";
import { ActiveItemActions } from "../../../../../../store/ActiveItem/ActiveItemActions";
import { UnitMapper } from "../../../../UnitsLeaseView/UnitLeaseMapper/UnitMapper.jsx";

export const PropertiesUnitsLease = ({
  operationType,
  viewType,
  onTypeChanged,
  parentTranslationPath,
  translationPath,
}) => {
  const { t } = useTranslation(parentTranslationPath);
  const location = useLocation();
  const searchTimer = useRef(null);
  // eslint-disable-next-line no-unused-vars
  const [propertyId, setPropertyId] = useState(+GetParams("id"));
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [filter, setFilter] = useState({
    pageSize: parseInt(localStorage.getItem("Pagination")) || 25,
    pageIndex: 0,
  });
  const [unitsDetailes, setUnitsDetailes] = useState({});
  const [sortBy, setSortBy] = useState(null);
  const orderFilter = useSelector((state) => state.GlobalOrderFilterReducer);
  const [selectedOrderBy, setSelectedOrderBy] = useState({
    filterBy: orderFilter.unitsSalesFilter.filterBy,
    orderBy: orderFilter.unitsSalesFilter.orderBy,
  });
  const [orderBy, setOrderBy] = useState(
    selectedOrderBy.filterBy
      ? selectedOrderBy
      : { filterBy: "createdOn", orderBy: 2 }
  );

  const pathName = window.location.pathname
    .split("/home/")[1]
    .split("/view")[0]
    .split("/property-profile-edit")[0];
  useEffect(() => {
    setPropertyId(+GetParams("id"));
  }, [location]);

  const getPropertyUnits = useCallback(async (unitNumber) => {
    setIsLoading(true);

    let localFilterDto = {
      propertyId: [{ searchType: 1, value: propertyId }],
    };
    if(unitNumber) {
      localFilterDto = {
        ...localFilterDto,
        unit_number: [{ searchType: 1, value: unitNumber }],
      };
    }
    const body = {
      criteria: localFilterDto,
      operationType,
      ...orderBy,
    };

    const res = await UnitsAdvanceSearchTest(filter, body);
    if (!(res && res.status && res.status !== 200)) {
      setUnitsDetailes({
        result: ((res && res.result) || []).map(
          (item) =>
            item.unitJson && UnitMapper(item, JSON.parse(item.unitJson).unit)
        ),
        totalCount: (res && res.totalCount) || 0,
      });
    }
    setIsLoading(false);
  }, [filter, propertyId, operationType, orderBy]);

  const tableActionClicked = useCallback(
    (actionEnum, item) => {
      dispatch(ActiveItemActions.activeItemRequest(item));
      const unitOperationType =
        item && item.operation_type && item.operation_type.lookupItemName;
      const unitOperationTypeList = ["Sale", "Rent", "SaleAndRent"];
      if (actionEnum === TableActions.openFile.key) {
        if (unitOperationType === unitOperationTypeList[0]) {
          GlobalHistory.push(
            `/home/units-sales/unit-profile-edit?formType=${item.unitTypeId}&id=${item.id}`
          );
        } else if (unitOperationType === unitOperationTypeList[1]) {
          GlobalHistory.push(
            `/home/units-lease/unit-profile-edit?formType=${item.unitTypeId}&id=${item.id}`
          );
        } else if (unitOperationType === unitOperationTypeList[2]) {
          GlobalHistory.push(
            `/home/units-lease/unit-profile-edit?formType=${item.unitTypeId}&id=${item.id}`
          );
        } else {
          GlobalHistory.push(
            `/home/units/unit-profile-edit?formType=${item.unitTypeId}&id=${item.id}`
          );
        }
      }
    },
    [dispatch, pathName]
  );

  const detailedCardSideActionClicked = useCallback(
    (actionEnum, activeData) => async (event) => {
      dispatch(ActiveItemActions.activeItemRequest(activeData));
      event.stopPropagation();
      const unitOperationType =
        activeData &&
        activeData.operation_type &&
        activeData.operation_type.lookupItemName;
      const unitOperationTypeList = ["Sale", "Rent", "SaleAndRent"];
      if (actionEnum === ActionsEnum.folder.key) {
        if (unitOperationType === unitOperationTypeList[0]) {
          GlobalHistory.push(
            `/home/units-sales/unit-profile-edit?formType=${activeData.unitTypeId}&id=${activeData.id}`
          );
        } else if (unitOperationType === unitOperationTypeList[1]) {
          GlobalHistory.push(
            `/home/units-lease/unit-profile-edit?formType=${activeData.unitTypeId}&id=${activeData.id}`
          );
        } else if (unitOperationType === unitOperationTypeList[2]) {
          GlobalHistory.push(
            `/home/units-lease/unit-profile-edit?formType=${activeData.unitTypeId}&id=${activeData.id}`
          );
        } else {
          GlobalHistory.push(
            `/home/units/unit-profile-edit?formType=${activeData.unitTypeId}&id=${activeData.id}`
          );
        }
      } else if (actionEnum === ActionsEnum.matching.key) {
        GlobalHistory.push(
          `/home/units-lease/unit-profile-edit?formType=${
            activeData.unitTypeId
          }&id=${activeData.id}&operationType=${
            activeData.operationType
          }&matching=${true}`
        );
      }
    },
    [dispatch, pathName]
  );

  const onPageIndexChanged = (pageIndex) => {
    setFilter((item) => ({ ...item, pageIndex }));
  };
  const onPageSizeChanged = (pageSize) => {
    setFilter((item) => ({ ...item, pageIndex: 0, pageSize }));
  };
  useEffect(() => {
    bottomBoxComponentUpdate(
      <PaginationComponent
        pageIndex={filter.pageIndex}
        pageSize={filter.pageSize}
        totalCount={unitsDetailes && unitsDetailes.totalCount}
        onPageIndexChanged={onPageIndexChanged}
        onPageSizeChanged={onPageSizeChanged}
      />
    );
  });
  const focusedRowChanged = useCallback(() => {}, []);

  useEffect(() => {
    if (operationType) getPropertyUnits();
  }, [getPropertyUnits]);

  useEffect(() => {
    if (sortBy)
      setOrderBy((item) => ({
        ...item,
        filterBy:
          sortBy.filterBy &&
          sortBy.filterBy[0].toLowerCase() + sortBy.filterBy.slice(1),
        orderBy: sortBy.orderBy,
        fieldType: sortBy.fieldType,
      }));
  }, [sortBy]);

  return (
    <div className="associated-Properties-wrapper">
      <Spinner isActive={isLoading} />
      <div className="title-section d-flex-v-center-h-between flex-wrap m-3">
        <span>{t(`${translationPath}lease-units`)}</span>
        <div className="search-properties pl-5-reversed">
        <div className='mr-1-reversed w-100 d-flex'>
        <Inputs
          idRef="activitiesSearchRef"
          inputPlaceholder={t(`${translationPath}UnitNumber`)}
          onKeyUp={(e) => {
            const value = e?.target?.value;
            if (searchTimer.current) clearTimeout(searchTimer.current);
            searchTimer.current = setTimeout(() => {
              const unitNumber = value;
              getPropertyUnits(unitNumber);
            }, 1200);
          }}

        />
      </div>
        <ViewTypes
          initialActiveType={viewType}
          onTypeChanged={onTypeChanged}
          activeTypes={[ViewTypesEnum.tableView.key, ViewTypesEnum.cards.key]}
        />
        </div>
      </div>

      {viewType === ViewTypesEnum.tableView.key && (
        <div className="w-100 px-2">
          <Tables
            data={unitsDetailes.result}
            headerData={[
              {
                id: 1,
                isSortable: true,
                label: "Unit Id",
                input: "unitId",
                component: (item) => item.id || <span />,
              },
              {
                id: 2,
                isSortable: true,
                label: "Unit Type",
                input: "unit_type",
                component: (item) =>
                  item.unitType && <span> {item.unitType}</span>,
              },
              {
                id: 3,
                isSortable: true,
                label: "Operation Type",
                input: "operation_type.lookupItemName",
                component: (item) =>
                  item &&
                  item.unitOperationType && (
                    <span> {item.unitOperationType}</span>
                  ),
              },
              {
                id: 5,
                isSortable: true,
                label: "Lease Unit Status",
                input: "stringStatus",
                component: (item) =>
                  item &&
                  item.leaseUnitStatus && (
                    <span>
                      {" "}
                      {item.leaseUnitStatus.name}
                    </span>
                  ),
              },
              {
                id: 6,
                isSortable: true,
                label: "Owner Name",
                input: "lead_owner.name",
                component: (item) =>
                  item &&
                  item.owner && (
                    <span>
                      {" "}
                      {item.owner.map((item) =>
                        item.name ? `${item.name}` : ""
                      )}
                    </span>
                  ),
              },
              {
                id: 7,
                isSortable: true,
                label: "Unit Reference no",
                input: "unit_ref_no",
                component: (item) =>
                  item && item.unit_ref_no && <span> {item.unit_ref_no}</span>,
              },
              {
                id: 8,
                isSortable: true,
                label: "Listing Agent",
                input: "listing_agent",
                component: (item) =>
                  item?.listing_agent?.name && (
                    <span> {item.listing_agent.name}</span>
                  ),
              },
              {
                id: 9,
                isSortable: true,
                label: "Rent Listing Agent",
                input: "rent_listing_agent",
                component: (item) =>
                  item?.rent_listing_agent?.name && (
                    <span> {item.rent_listing_agent.name}</span>
                  ),
              },
              {
                id: 10,
                isSortable: true,
                label: "Unit Number",
                input: "unit_number",
                component: (item) =>
                  item.unit_number && <span> {item.unit_number}</span>,
              },
            ]}
            onPageIndexChanged={onPageIndexChanged}
            onPageSizeChanged={onPageSizeChanged}
            actionsOptions={{
              onActionClicked: tableActionClicked,
            }}
            defaultActions={[
              {
                enum: TableActions.openFile.key,
                isDisabled: pathName === "property",
              },
            ]}
            setSortBy={setSortBy}
            itemsPerPage={filter.pageSize}
            activePage={filter.pageIndex}
            parentTranslationPath={parentTranslationPath}
            translationPath={translationPath}
            focusedRowChanged={focusedRowChanged}
            totalItems={
              unitsDetailes && unitsDetailes.totalCount
                ? unitsDetailes.totalCount
                : 0
            }
          />
        </div>
      )}
      {viewType === ViewTypesEnum.cards.key &&
        unitsDetailes &&
        unitsDetailes.result && (
          <UnitsCardsComponent
            data={unitsDetailes}
            translationPath={translationPath}
            parentTranslationPath={parentTranslationPath}
            displyMatchingButton
            displyOpenFileButton
            onFooterActionsClicked={detailedCardSideActionClicked}
          />
        )}
    </div>
  );
};
