import React from "react";
import { useTranslation } from "react-i18next";
import Rating from "@material-ui/lab/Rating";

export const AmenitiesComponent = ({
  parentTranslationPath,
  translationPath,
  state,
  setState,
}) => {
  const { t } = useTranslation(parentTranslationPath);

  return (
    <>
      <div className="fw-bold">
        <span> {t(`${translationPath}amenities`)} </span>
      </div>
      <div className="w-100 px-2">
        {state &&
          state.updatePropertyRatingFacilitiesAndAmentiesDto &&
          state.updatePropertyRatingFacilitiesAndAmentiesDto
            .filter((f) => f.type === "UnitsAmenties")
            .map((item, index) => (
              <div className="d-flex-inline b-bottom" key={`UnitsAmenties${index}`}>
                <div className="facilities-amenities-card mt-3 mb-3">
                  <div className="px-2 fw-bold">
                    <span> {item.lookupItemName} </span>
                  </div>
                  <div className="">
                    <Rating
                      className=""
                      size="large"
                      name={`UnitsAmenties${item.lookupsId}`}
                      max={10}
                      value={item.score}
                      precision={1}
                      onChange={(event, newValue) => {
                        const updateList = [
                          ...state.updatePropertyRatingFacilitiesAndAmentiesDto,
                        ];
                        const itemIndex = updateList.indexOf(
                          updateList.find((f) => f.lookupsId === item.lookupsId)
                        );
                        if (itemIndex !== -1)
                          updateList[itemIndex] = {
                            ...updateList[itemIndex],
                            score: newValue || 0 ,
                          };

                        updateList[itemIndex].score = newValue || 0 ;
                        setState({
                          id: "updatePropertyRatingFacilitiesAndAmentiesDto",
                          value: updateList,
                        });
                      }}
                    />
                  </div>
                </div>
              </div>
            ))}
      </div>
    </>
  );
};
