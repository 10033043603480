import React from "react";
import Skeleton from "@material-ui/lab/Skeleton";
import { Box } from "@material-ui/core";
import useStyles from "./styles";

const UnitCardSkeleton = ({ index }) => {
  const styles = useStyles();

  return (
    <Box key={`CardSkeletonLoader-${index}`} className={styles.cardWrapper}>
      <Box>
        <Skeleton
          style={{ borderRadius: "12px 12px 0 0" }}
          animation="wave"
          height="240px"
          variant="rectangular"
          width="314px"
        />
      </Box>
      <Box className={styles.cardContent}>
        <Box className={styles.detailsContainer}>
          <Box className={styles.header}>
            <Box className={styles.headerDetails}>
              <Skeleton
                animation="wave"
                width="72px"
                height="30px"
                variant="text"
              />

              <Skeleton
                animation="wave"
                width="20px"
                height="29px"
                variant="text"
              />
            </Box>

            <Skeleton
              animation="wave"
              width="20px"
              height="20px"
              variant="rectangular"
            />
          </Box>

          <Box className={styles.name}>
            <Skeleton
              animation="wave"
              width="160px"
              height="28px"
              variant="text"
            />
          </Box>
          <Box className={styles.idInfo}>
            <Skeleton
              animation="wave"
              width="60px"
              height="30px"
              variant="text"
            />

            <Skeleton
              animation="wave"
              height="20px"
              variant="rectangular"
              width="20px"
            />

            <Box className={styles.additionalDetails}>
              <Skeleton
                animation="wave"
                width="40px"
                height="20px"
                variant="text"
              />

              <Skeleton
                animation="wave"
                width="20px"
                height="20px"
                variant="text"
              />
            </Box>
          </Box>
        </Box>

        <Box className={styles.actionContainer}>
          <Skeleton
            animation="wave"
            width="45px"
            height="20px"
            variant="text"
          />

          <Skeleton
            animation="wave"
            width="45px"
            height="20px"
            variant="text"
          />
        </Box>

        <Box className={styles.iconContainer}>
          <Skeleton
            className={styles.rounded}
            animation="wave"
            width="42px"
            variant="circular"
            height="22px"
          />

          <Skeleton
            className={styles.rounded}
            animation="wave"
            width="42px"
            variant="circular"
            height="22px"
          />

          <Skeleton
            animation="wave"
            width="30px"
            height="20px"
            variant="text"
          />
        </Box>

        <Box className={styles.featuresContainer} style={{ margin: "8px 0" }}>
          <Box className={styles.featureItem}>
            <Skeleton
              animation="wave"
              height="20px"
              variant="rectangular"
              width="20px"
            />

            <Skeleton
              animation="wave"
              width="40px"
              height="24px"
              variant="text"
            />
          </Box>

          <Box className={styles.featureItem}>
            <Skeleton
              animation="wave"
              height="20px"
              variant="rectangular"
              width="20px"
            />

            <Skeleton
              animation="wave"
              width="40px"
              height="24px"
              variant="text"
            />
          </Box>

          <Box className={styles.featureItem}>
            <Skeleton
              animation="wave"
              height="20px"
              variant="rectangular"
              width="20px"
            />

            <Skeleton
              animation="wave"
              width="40px"
              height="24px"
              variant="text"
            />
          </Box>
        </Box>

        <Box className={styles.divider} />

        <Box className={styles.dateSection}>
          <Skeleton
            animation="wave"
            width="58px"
            height="20px"
            variant="text"
          />

          <Box className={styles.dateInfo}>
            <Skeleton
              animation="wave"
              width="30px"
              height="20px"
              variant="text"
            />

            <Skeleton
              animation="wave"
              width="120px"
              height="20px"
              variant="text"
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default UnitCardSkeleton;
