



import React , { useState , useCallback , useEffect  , useRef  } from 'react';
import { useTranslation } from 'react-i18next'; 
import { AutocompleteComponent   } from '../../../../../../../../../../../../../Components' ;
import { PaymentMethodEnum } from '../../../../../../../../../../../../../Enums' ; 
import { lookupItemsGetId  , GetFinderCitiesAPI , GetFinderCommunitiesAPI  , GetFinderSubCommunities  , GetFinderPropertyNames  } from '../../../../../../../../../../../../../Services' ;
import {  getErrorByName } from '../../../../../../../../../../../../../Helper' ;


export const AddressPropertyFinderContent = 
  ({
              translationPath , 
              parentTranslationPath , 
              state , 
              setState , 
              schema , 
              isSubmitted , 
              selected , 
              setSelected ,
              propertyFinderDetails , 
              setPropertyFinderDetails , 
  }) => {

   const { t } = useTranslation(parentTranslationPath);
   const searchTimer = useRef(null);
   const [isLoading , setIsLoading ] = useState({cities : false , communities :  false , subCommunities : false  , properties : false  });

   const [list, setList] = useState({
      cities : [] , 
      communities : [] , 
      subCommunities : [] , 
      properties : [] , 
    });

    const GetAllCities = useCallback(async (search) => {

      setIsLoading((item) => ({ ...item, cities : true }));
      const res = await GetFinderCitiesAPI(search || '') ;

      if (!(res && res.status && res.status !== 200)) 
      {
         setList((item) => ({ ...item, cities : res }));
      } 
    else  setList((item) => ({ ...item, cities : [] , communities : [] , subCommunities : [] , properties : []}));
    
    setIsLoading((item) => ({ ...item, cities : false }));
  },[]);

    const GetAllCommunities = useCallback(async (search) => {

        setIsLoading((item) => ({ ...item, communities : true }));
        const res = await GetFinderCommunitiesAPI(search ||'') ;

        if (!(res && res.status && res.status !== 200)) 
        {

         setList((item) => ({ ...item, communities : res , subCommunities : [] , properties : []}));
        }

      else  setList((item) => ({ ...item, communities : [] , subCommunities : [] , properties : [] }));
      
      setIsLoading((item) => ({ ...item, communities : false }));
    });


    const GetAllSubCommunities = useCallback(async (search) => {

      setIsLoading((item) => ({ ...item, subCommunities : true }));
      const res = await GetFinderSubCommunities(search  || (propertyFinderDetails && propertyFinderDetails.community)||'') ;
      
      if (!(res && res.status && res.status !== 200)) 
      {
         setPropertyFinderDetails((item) => ({ ...item , subCommunity : (res && res[0]) , propertyName : null  }));
         setList((item) => ({ ...item, subCommunities : res ,  properties : []}));

      }
    else  setList((item) => ({ ...item, subCommunities : [] , properties : [] }));
    
    setIsLoading((item) => ({ ...item, subCommunities : false }));
  });

   const GetAllProperties = useCallback(async (search) => {

        setIsLoading((item) => ({ ...item, properties : true }));
        const res = await GetFinderPropertyNames(search || (propertyFinderDetails && propertyFinderDetails.subCommunity)|| '') ;
        
        if (!(res && res.status && res.status !== 200)) 
        {
         setPropertyFinderDetails((item) => ({ ...item ,   propertyName : (res && res[0])  }));
         setList((item) => ({ ...item, properties : res }));
        }   
      else  setList((item) => ({ ...item, properties : [] }));
      
      setIsLoading((item) => ({ ...item, properties : false }));
    });

  useEffect(() => {
    GetAllCities();
  }, []);

  useEffect(() => {
   if(propertyFinderDetails && propertyFinderDetails.city)
    GetAllCommunities(propertyFinderDetails && propertyFinderDetails.city) ; 

 }, [(propertyFinderDetails && propertyFinderDetails.city)]);

 useEffect(() => {
   if(propertyFinderDetails && propertyFinderDetails.community)
    GetAllSubCommunities(propertyFinderDetails && propertyFinderDetails.community) ; 

 }, [(propertyFinderDetails && propertyFinderDetails.community)]);

 useEffect(() => {
   if(propertyFinderDetails && propertyFinderDetails.subCommunity)
    GetAllProperties(propertyFinderDetails && propertyFinderDetails.subCommunity) ; 

 }, [(propertyFinderDetails && propertyFinderDetails.subCommunity)]);


  return (
    <>
    <div className='dialog-content-wrapper ' >
        <div className='dialog-content-item w-50  mt-2'>
             <AutocompleteComponent
                idRef='cityIdRef'
                labelValue={t(`${translationPath}city`)}
                labelClasses='Requierd-Color'
                multiple={false}
                isLoading={isLoading.cities}
                selectedValues={(propertyFinderDetails && propertyFinderDetails.city)}
                data={(list && list.cities) || []}
                chipsLabel={(option) => (option) || ''}
                displayLabel={(option) => (option ) || ''}
                withoutSearchButton
                parentTranslationPath={parentTranslationPath}
                translationPath={translationPath}
                onChange={(event, newValue) => 
                {
                     setPropertyFinderDetails((item) => ({ ...item, city : newValue ,  community : null , subCommunity : null , propertyName : null  }));

                }}
              
                helperText={getErrorByName(schema, 'city').message}
                error={getErrorByName(schema, 'city').error}
                isWithError
                isSubmitted={isSubmitted}
                withLoader
             />
          </div>
          <div className='dialog-content-item w-50 mt-2 '>
             <AutocompleteComponent
                idRef='CommunityRef'
                labelClasses='Requierd-Color'
                multiple={false}
                data={(list && list.communities) || []}
                isLoading={isLoading.communities}
                selectedValues={(propertyFinderDetails && propertyFinderDetails.community)}    
                labelValue={t(`${translationPath}community`)}
                chipsLabel={(option) => (( option) || '')}
                displayLabel={(option) =>  ((option )|| '')}
                withoutSearchButton
                parentTranslationPath={parentTranslationPath}
                translationPath={translationPath}
                onChange={(event, newValue) => 
                {
                  if(!newValue)
                    return ; 

                  setPropertyFinderDetails((item) => ({ ...item, community : newValue , subCommunity : null , propertyName : null  }));
 
                }}
                helperText={getErrorByName(schema, 'community').message}
                error={getErrorByName(schema, 'community').error}
                isWithError
                isSubmitted={isSubmitted}

             />
          </div>
          <div className='dialog-content-item w-50 mt-2'>
             <AutocompleteComponent
                idRef='SubCommunityRef'
                multiple={false}
                data={(list && list.subCommunities) || []}
                isLoading={isLoading.subCommunities}
                selectedValues={(propertyFinderDetails && propertyFinderDetails.subCommunity)}
                labelValue={t(`${translationPath}SubCommunity`)}
                chipsLabel={(option) => (( option) || '')}
                displayLabel={(option) =>  ((option)|| '')}
                withoutSearchButton
                parentTranslationPath={parentTranslationPath}
                translationPath={translationPath}
                onChange={(event, newValue) => 
                {
                   if(!newValue)
                    return ;  

                    setPropertyFinderDetails((item) => ({ ...item, subCommunity : newValue , propertyName : null  }));
 
                }}
             />
          </div>
          <div className='dialog-content-item w-50 mt-2'>
             <AutocompleteComponent
                idRef='propertiesRef'
                 multiple={false}
                data={(list.properties)|| []}
                labelValue={t(`${translationPath}property`)}
                selectedValues={(propertyFinderDetails && propertyFinderDetails.propertyName)}
                chipsLabel={(option) => (option) || ''}
                displayLabel={(option) => (option) || ''}
                withoutSearchButton
                parentTranslationPath={parentTranslationPath}
                translationPath={translationPath}
                onChange={(event, newValue) => 
                 {
                  if(!newValue)
                    return ;

                  setPropertyFinderDetails((item) => ({ ...item, propertyName : newValue }));
                 }}
                // getOptionSelected={(option) => state && state.secondaryView && state.secondaryView.findIndex((item) => item.lookupItemId === option.lookupItemId) !== -1 || ''}
                isLoading={isLoading.properties}
             />
          </div>
          <div className='dialog-content-item w-50 mt-2'>
             <AutocompleteComponent
                idRef='PaymentMethodRef'
                multiple={false}
                data={(Object.values(PaymentMethodEnum))|| []}
                labelValue={t(`${translationPath}paymentMethod`)}
                labelClasses='Requierd-Color'
                chipsLabel={(option) => (option && option.name) || ''}
                displayLabel={(option) => (option && option.name) || ''}
                withoutSearchButton
                parentTranslationPath={parentTranslationPath}
                translationPath={translationPath}
                onChange={(event, newValue) => 
                 {
                  setPropertyFinderDetails((item) => ({ ...item, paymentTypes : (newValue && newValue.key)}));
                 }}
                 helperText={getErrorByName(schema, 'paymentTypes').message}
                 error={getErrorByName(schema, 'paymentTypes').error}
                 isWithError
                 isSubmitted={isSubmitted}
             />
          </div>
        </div>

     </>
  );
};
