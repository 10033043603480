
import React, { useState , useCallback   , useEffect , useReducer}  from 'react';
import { useTranslation } from 'react-i18next';
import Button from "@material-ui/core/Button";
import {  Spinner   } from '../../../../../Components' ; 
import { PageHeading } from "../../../../../SharedComponents";
import { showError , showSuccess , bottomBoxComponentUpdate } from  "../../../../../Helper" ; 
import { GetAllGeneralPropertyCriteriaScores , UpdateGeneralPropertyCriteriaScores  } from '../../../../../Services' ; 
import { GeneralPropertyCriteriaContent } from './GeneralPropertyCriteriaContent' ;

  export const GeneralPropertyCriteriaView = ({
    parentTranslationPath ,
    translationPath ,
    ratingPercentage 
   } ) => { 

    const { t } = useTranslation(parentTranslationPath);
    const [isLoading , setIsLoading] = useState(false);
    const [generalPropertyCriteriaPercentage ,setGeneralPropertyCriteriaPercentage]  = useState(0) ;
    const reducer = (state, action) => {
        if (action.id !== 'edit') return { ...state, [action.id]: action.value };
        if (action.id === 'edit') {
          return {
            ...action.value,
          };
        }
        return undefined;
      };

      const [state, setState] = useReducer(reducer, {
        generalPropertyLookupInfo :[] , 
        generalPropertyRangeInfo :[] , 
        twentyFourHourSecurityDto :null , 
        twentyFourHourReceptionDto : null 
      });

      const updateGeneralPropertyCriteriaDto = () => 
     {
        let generalPropertyLookupInfo = [] ;
        state.generalPropertyLookupInfo.map((item)=>
        {
          item.generalPropertyLookupDtos.map((element) => 
          {
            generalPropertyLookupInfo.push({generalPropertyCriteriaType : item.generalPropertyCriteriaType ,  ...element });
          })
        });

        let generalPropertyRangeInfo = [] ;
        state.generalPropertyRangeInfo.map((item)=>
        {
          item.generalPropertyRangeDtos.map((element) => 
          {
            generalPropertyRangeInfo.push({generalPropertyCriteriaType : item.generalPropertyCriteriaType ,  ...element });
          })
        });

        return { twentyFourHourSecurityDto : state.twentyFourHourSecurityDto , generalPropertyLookupInfo ,generalPropertyRangeInfo , twentyFourHourReceptionDto : state.twentyFourHourReceptionDto } ; 
     }
      

    const saveHandler = async () => {
      setIsLoading(true);
      const res = await UpdateGeneralPropertyCriteriaScores(updateGeneralPropertyCriteriaDto()); 
      if (!((res && res.StatusCode) || !res)) {
        showSuccess(t`${translationPath}general-property-criteria-updated-successfully`);
          reloadData();
      }
      else  showError(t`${translationPath}general-property-criteria-updated-failed`);
       
      setIsLoading(false) ;
  };

    const GetAllGeneralPropertyCriteriaScoresAPI = useCallback(async () => 
    {
        setIsLoading(true);
        const res = await GetAllGeneralPropertyCriteriaScores();
        if (!((res && res.data && res.data.ErrorId) || !res)) {
            setState({ id: 'edit', value: res});
        } 
        else 
        setState({ id: 'edit', value: []});

       setIsLoading(false) ; 
    });


    const reloadData = () => 
    {
      GetAllGeneralPropertyCriteriaScoresAPI();
      
    };

    useEffect(() => {
      reloadData();
      if(ratingPercentage)
        {
          const rating = ratingPercentage.find((f) => f.propertyRatingTabName === "General property criteria");
          setGeneralPropertyCriteriaPercentage((rating && rating.score) || 0 ) ; 
        }
    }, [ratingPercentage]);
    
    useEffect( () => {
      bottomBoxComponentUpdate(null);
    },
    []
  );

    return (
      <div className="w-100 px-4 mt-4">
      <Spinner isActive={isLoading} isAbsolute />
      <div className="d-flex-v-center-h-between">
          <PageHeading
            parentTranslationPath={parentTranslationPath}
            translationPath={translationPath}
            headerTitle={"general-property-criteria"}
            subTitle={"anage-your-team-members-and-their-account-permissions-here"}
            wrapperClasses={"px-4 pt-2 w-75"}
            headerTitleClasses={"fz-30"}
            subTitleClasses={"fz-12 m-0"}
            titleWithPercentage={`${generalPropertyCriteriaPercentage}%`}
          />
          <div className="w-25 px-4 d-flex-center fj-end">
            <Button
              className="btns theme-propx outlined"
              id="btn-cancel"
              onClick={() => {
                reloadData()
              }}
            >
              {t(`${translationPath}cancel`)}
            </Button>
            <Button
              className="btns theme-propx solid"
              id="btn-save"
              onClick={() => saveHandler()}
            >
              {t(`${translationPath}save-changes`)}
            </Button>
           
          </div>
         </div>
         <GeneralPropertyCriteriaContent
           parentTranslationPath={parentTranslationPath}
           translationPath={translationPath}
           state={state}
           setState={setState}
          />
      </div>  
    );
  } ; 
  