import React, {
 useCallback, useEffect, useRef, useState
} from 'react';
import PropTypes from 'prop-types';
import {
  AutocompleteComponent,
  DatePickerComponent,
  Inputs,
  RadiosGroupComponent,
  Spinner,
} from '../../../../../../../Components';
import {
  StaticLookupsIds
} from '../../../../../../../assets/json/StaticLookupsIds';
import { floatHandler, getErrorByName } from '../../../../../../../Helper';
import {
  GetUserByApplicationUserId,
  lookupItemsGetId,
  OrganizationUserSearch,
} from '../../../../../../../Services';
import { GetApplicationUserById } from '../../../../../../../Services/userServices';
import { AgentRoleEnum } from '../../../../../../../Enums/AgentRoleEnum';

export const AgentInfoRentRelatedComponent = ({
  state,
  selected,
  onSelectedChanged,
  schema,
  unitTransactionId,
  isSubmitted,
  onStateChanged,
  isReadOnly,
  parentTranslationPath,
  translationPath,
}) => {
  const searchTimer = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [loadings, setLoadings] = useState({
    agents: false,
    referrals: false,
    reservationTypes: false,
    transactedBy: false,
    externalAgencies: false,
    rentFreePeriods: false,
  });
  const [filter] = useState({
    pageIndex: 0,
    pageSize: 10,
  });
  const [agents, setAgents] = useState([]);
  const [referrals, setReferrals] = useState([]);
  const [reservationTypes, setReservationTypes] = useState([]);
  const [transactedBy, setTransactedBy] = useState([]);
  const [rentFreePeriods, setRentFreePeriods] = useState([]);
  const getAllAgents = useCallback(
    async (value, selectedValue) => {
      setLoadings((items) => ({ ...items, agents: true }));
      const res = await OrganizationUserSearch({
        ...filter,
        name: value,
        userTypeId: AgentRoleEnum.LeaseAgent.value,
      });
      if (!(res && res.status && res.status !== 200)) {
        setAgents(
          (selectedValue &&
            ((res && res.result && [...res.result, selectedValue]) || [selectedValue])) ||
            (res && res.result) ||
            []
        );
      } else setAgents([]);
      setLoadings((items) => ({ ...items, agents: false }));
    },
    [filter]
  );

  const getAllReservationTypes = useCallback(async () => {
    setLoadings((items) => ({ ...items, reservationTypes: true }));
    const res = await lookupItemsGetId({
      lookupTypeId: StaticLookupsIds.ReservationType,
    });
    if (!(res && res.status && res.status !== 200)) setReservationTypes(res || []);
    else setReservationTypes([]);
    setLoadings((items) => ({ ...items, reservationTypes: false }));
  }, []);
  const getAllRentFreePeriods = useCallback(async () => {
    setLoadings((items) => ({ ...items, rentFreePeriods: true }));
    const res = await lookupItemsGetId({
      lookupTypeId: StaticLookupsIds.RentFreePeriod,
    });
    if (!(res && res.status && res.status !== 200)) setRentFreePeriods(res || []);
    else setRentFreePeriods([]);
    setLoadings((items) => ({ ...items, rentFreePeriods: false }));
  }, []);
  const getUserById = useCallback(async (id) => {
    setIsLoading(true);
    const res = await GetUserByApplicationUserId(id);
    setIsLoading(false);
    if (!(res && res.status && res.status !== 200)) return res;
    return null;
  }, []);

  const getUserByGUID = useCallback(async (id) => {
    setIsLoading(true);
    const res = await GetApplicationUserById(id);
    setIsLoading(false);
    if (!(res && res.status && res.status !== 200)) return res;
    return null;
  }, []);

  const handleAgentsEditInit = useCallback(async () => {
    if (selected && selected.agents && !selected.tenantAgent) {
      const tenantAgent = selected.agents.find((item) => item.isOriginalAgent == true) || null;
      const otherTenantAgents = selected.agents.filter((item) => item.isOriginalAgent == false) || [];

      onSelectedChanged({ id: 'tenantAgent', value: tenantAgent });
      onSelectedChanged({ id: 'otherTenantAgents', value: (otherTenantAgents.length > 0) ? otherTenantAgents : [] });
    }

    if (selected && selected.listingAgents && !selected.landlordAgent) {
      const landlordAgent = selected.listingAgents.find((item) => item.isListingAgent == true) || null;
      const otherLandlordAgents = selected.listingAgents.filter((item) => item.isListingAgent == false) || [];

      onSelectedChanged({ id: 'landlordAgent', value: landlordAgent });
      onSelectedChanged({ id: 'otherLandlordAgents', value: (otherLandlordAgents.length > 0) ? otherLandlordAgents : [] });
    }
  }, [
    selected.agents,
    selected.listingAgents,
    selected.landlordAgent,
    selected.tenantAgent
  ]);

  const getLandlordReferralEditInit = useCallback(async () => {
    if (state && state.landlordReferralId && !selected.landlordReferral) {
        const res = await getUserById(state.landlordReferralId);
        if (res) {
         const selectedLandlordReferral = res;
         onSelectedChanged({ id: 'landlordReferral', value: selectedLandlordReferral });
          setReferrals((items) => {
            items.push(selectedLandlordReferral);
            return [...items];
          });
        } else onStateChanged({ id: 'landlordReferralId', value: null });
      }
  }, [referrals,
    selected.landlordReferral,
    state.landlordReferralId]);

    const getTenantReferralEditInit = useCallback(async () => {
      if (state && state.referralId && !selected.referral) {
          const res = await getUserById(state.referralId);
          if (res) {
            const selectedReferral = res;
            onSelectedChanged({ id: 'referral', value: selectedReferral });
            setReferrals((items) => {
              items.push(selectedReferral);
              return [...items];
            });
          } else onStateChanged({ id: 'referralId', value: null });
      }
    }, [referrals,
      selected.referral,
      state.referralId
    ]);

    const getTransactedByEditInit = useCallback(async () => {
      if (state && state.transactedById && !selected.transactedBy) {
          const res = await getUserByGUID(state && state.transactedById);
          if (res) {
            const selectedTransactedBy = res;
            onSelectedChanged({ id: 'transactedBy', value: selectedTransactedBy });

            setTransactedBy((items) => {
              items.push(selectedTransactedBy);
              return [...items];
            });
          } else onStateChanged({ id: 'transactedById', value: null });
      }
    }, [
      state.transactedById,
      transactedBy,
    ]);

    const getEditInit = useCallback(async () => {
    if (state && state.reservationTypeId && !selected.reservationType && reservationTypes.length > 0) {
      const reservationTypeIndex = reservationTypes.findIndex(
        (item) => item.lookupItemId === state.reservationTypeId
      );
      if (reservationTypeIndex !== -1) {
        selected.reservationType = reservationTypes[reservationTypeIndex];
        if (onSelectedChanged) onSelectedChanged({ id: 'edit', value: selected });
      } else onStateChanged({ id: 'reservationTypeId', value: null });
    }
    if (state && state.rentFreePeriodId && !selected.rentFreePeriod && rentFreePeriods.length > 0) {
      const rentFreePeriodIndex = rentFreePeriods.findIndex(
        (item) => item.lookupItemId === state.rentFreePeriodId
      );
      if (rentFreePeriodIndex !== -1) {
        selected.rentFreePeriod = rentFreePeriods[rentFreePeriodIndex];
        if (onSelectedChanged) onSelectedChanged({ id: 'edit', value: selected });
      } else onStateChanged({ id: 'rentFreePeriodId', value: null });
    }
  }, [
    rentFreePeriods,
    reservationTypes,
    state.rentFreePeriodId,
    state.reservationTypeId,
  ]);
  useEffect(() => {
    getAllAgents();
    getAllReservationTypes();
    getAllRentFreePeriods();
  }, [
    getAllAgents,
    getAllRentFreePeriods,
    getAllReservationTypes,
  ]);
  useEffect(() => {
    if (unitTransactionId) getEditInit();
  }, [getEditInit, unitTransactionId]);

  useEffect(() => {
    if (unitTransactionId) handleAgentsEditInit();
  }, [handleAgentsEditInit, unitTransactionId]);

  useEffect(() => {
    if (unitTransactionId) getTenantReferralEditInit();
  }, [getTenantReferralEditInit, unitTransactionId]);

  useEffect(() => {
    if (unitTransactionId) getLandlordReferralEditInit();
  }, [getLandlordReferralEditInit, unitTransactionId]);

  useEffect(() => {
    if (unitTransactionId) getTransactedByEditInit();
  }, [getTransactedByEditInit, unitTransactionId]);

  return (
    <div className='unit-status-agent-info-wapper childs-wrapper p-relative agent-info-sale-related-component-wrapper'>
      <Spinner isActive={isLoading} isAbsolute />

      <div className='form-item'>
        <AutocompleteComponent
          idRef='tenant-agentIdRef'
          labelValue='tenant-agent'
          selectedValues={(selected && selected.tenantAgent) || {}}
          multiple={false}
          isDisabled
          data={selected.agents || []}
          displayLabel={(option) => option.fullName || ''}
          renderOption={(option) =>
            ((option.userName || option.fullName) && `${option.fullName} (${option.userName})`) ||
            ''}
          withoutSearchButton
          isLoading={loadings.agents}
          isWithError
          isSubmitted={isSubmitted}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
        />
      </div>
      <div className='form-item'>
        <AutocompleteComponent
          idRef='other-tenant-agentsIdRef'
          labelValue='other-tenant-agents'
          selectedValues={(selected && selected.otherTenantAgents) || []}
          multiple
          isDisabled
          data={selected.agents || []}
          displayLabel={(option) => option.fullName || ''}
          chipsLabel={(option) => option.fullName || ''}
          renderOption={(option) =>
            ((option.userName || option.fullName) && `${option.fullName} (${option.userName})`) ||
            ''}
          getOptionSelected={(option) => selected && selected.otherTenantAgents && selected.otherTenantAgents.findIndex((item) => item.id === option.id) !== -1 || ''}
          withoutSearchButton
          isLoading={loadings.agents}
          isSubmitted={isSubmitted}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
        />
      </div>
      <div className='form-item'>
        <AutocompleteComponent
          idRef='landlord-agentIdRef'
          labelValue='landlord-agent'
          selectedValues={(selected && selected.landlordAgent) || {}}
          multiple={false}
          isDisabled
          data={(selected && selected.listingAgents) || []}
          displayLabel={(option) => option.fullName || ''}
          renderOption={(option) =>
            ((option.userName || option.fullName) && `${option.fullName} (${option.userName})`) ||
            ''}
          withoutSearchButton
          isLoading={loadings.agents}
          isWithError
          isSubmitted={isSubmitted}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
        />
      </div>
      <div className='form-item'>
        <AutocompleteComponent
          idRef='other-landlord-agentsIdRef'
          labelValue='other-landlord-agents'
          selectedValues={(selected && selected.otherLandlordAgents) || []}
          isDisabled
          multiple
          data={(selected && selected.listingAgents) || []}
          displayLabel={(option) => option.fullName || ''}
          chipsLabel={(option) => option.fullName || ''}
          renderOption={(option) =>
            ((option.userName || option.fullName) && `${option.fullName} (${option.userName})`) ||
            ''}
          getOptionSelected={(option) => selected && selected.otherLandlordAgents && selected.otherLandlordAgents.findIndex((item) => item.id === option.id) !== -1 || ''}
          withoutSearchButton
          isLoading={loadings.agents}
          isSubmitted={isSubmitted}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
        />
      </div>
      <div className='form-item'>
        <RadiosGroupComponent
          idRef='contractSignedRef'
          labelValue='is-contract-ratified'
          data={[
            {
              key: true,
              value: 'yes',
            },
            {
              key: false,
              value: 'no',
            },
          ]}
          value={state && state.contractSigned}
          isDisabled
          parentTranslationPath={parentTranslationPath}
          translationPathForData={translationPath}
          translationPath={translationPath}
          labelInput='value'
          valueInput='key'
          onSelectedRadioChanged={(e, newValue) =>
            onStateChanged({ id: 'contractSigned', value: newValue === 'true' })}
        />
      </div>
      <div className='form-item'>
        <Inputs
          idRef='premisesRef'
          labelValue='premises'
          value={state.premises || ''}
          helperText={getErrorByName(schema, 'premises').message}
          error={getErrorByName(schema, 'premises').error}
          isWithError
          isDisabled
          isSubmitted={isSubmitted}
          multiline
          rows={4}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          onInputChanged={(event) => {
            onStateChanged({ id: 'premises', value: event.target.value });
          }}
        />
      </div>
      <div className='form-item'>
        <AutocompleteComponent
          idRef='referralIdRef'
          labelValue='trans-description-tenant'
          selectedValues={selected && selected.referral}
          multiple={false}
          data={referrals || []}
          displayLabel={(option) => option.firstName && (`${option.firstName} (${option.lastName})`) || ''}
          renderOption={(option) =>
            option.firstName && (`${option.firstName} (${option.lastName})`) ||
            ''}
          getOptionSelected={(option) => option.id === state.referralId}
          withoutSearchButton
          helperText={getErrorByName(schema, 'referralId').message}
          error={getErrorByName(schema, 'referralId').error}
          isLoading={loadings.referrals}
          isWithError
          isSubmitted={isSubmitted}
          isDisabled
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          onChange={(event, newValue) => {
            onSelectedChanged({ id: 'referral', value: newValue });
            onStateChanged({
              id: 'referralId',
              value: (newValue && newValue.applicationUserId) || null,
            });
          }}
        />
      </div>
      <div className='form-item'>
        <Inputs
          idRef='referralPercentageRef'
          labelValue='trans-percentage-description-tenant'
          value={(state && state.referralPercentage) || 0}
          helperText={getErrorByName(schema, 'referralPercentage').message}
          error={getErrorByName(schema, 'referralPercentage').error}
          isWithError
          isSubmitted={isSubmitted}
          type='number'
          min={0}
          max={100}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          isDisabled
          onInputChanged={(event) => {
            let value = +(floatHandler(event.target.value, 3));
            if (value > 100) value = 100;
            onStateChanged({ id: 'referralPercentage', value: parseInt(value) });
          }}
        />
      </div>
      <div className='form-item'>
        <AutocompleteComponent
          idRef='landlordReferralIdRef'
          labelValue='trans-description-landlord'
          selectedValues={(selected && selected.landlordReferral)}
          multiple={false}
          data={referrals || []}
          displayLabel={(option) => option.firstName && (`${option.firstName} (${option.lastName})`) || ''}
          renderOption={(option) =>
            option.firstName && (`${option.firstName} (${option.lastName})`) ||
            ''}
          getOptionSelected={(option) => option.id === state && state.landlordReferralId}
          withoutSearchButton
          helperText={getErrorByName(schema, 'landlordReferralId').message}
          error={getErrorByName(schema, 'landlordReferralId').error}
          isLoading={loadings.referrals}
          isWithError
          isSubmitted={isSubmitted}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          isDisabled
          onChange={(event, newValue) => {
            onSelectedChanged({ id: 'landlordReferral', value: newValue });
            onStateChanged({
              id: 'landlordReferralId',
              value: (newValue && newValue.applicationUserId) || null,
            });
          }}
        />
      </div>
      <div className='form-item'>
        <Inputs
          idRef='landlordReferralPercentageRef'
          labelValue='trans-percentage-description-landlord'
          value={(state && state.landlordReferralPercentage) || 0}
          helperText={getErrorByName(schema, 'landlordReferralPercentage').message}
          error={getErrorByName(schema, 'landlordReferralPercentage').error}
          isWithError
          isSubmitted={isSubmitted}
          type='number'
          min={0}
          max={100}
          parentTranslationPath={parentTranslationPath}
          isDisabled
          translationPath={translationPath}
          onInputChanged={(event) => {
            let value = +(floatHandler(event.target.value, 3));
            if (value > 100) value = 100;
            onStateChanged({ id: 'landlordReferralPercentage', value: parseInt(value) });
          }}
        />
      </div>
      <div className='form-item'>
        <DatePickerComponent
          idRef='contractDateRef'
          labelValue='contract-date'
          placeholder='DD/MM/YYYY'
          value={state && state.contractDate}
          helperText={getErrorByName(schema, 'contractDate').message}
          error={getErrorByName(schema, 'contractDate').error}
          isSubmitted={isSubmitted}
          isDisabled
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          onDateChanged={(newValue) => {
            onStateChanged({ id: 'contractDate', value: newValue });
          }}
        />
      </div>
      <div className='form-item px-0'>
        <div className='form-item w-50'>
          <DatePickerComponent
            idRef='startDateRef'
            labelValue='start-date'
            placeholder='DD/MM/YYYY'
            value={state && state.startDate}
            helperText={getErrorByName(schema, 'startDate').message}
            error={getErrorByName(schema, 'startDate').error}
            isSubmitted={isSubmitted}
            isDisabled
            parentTranslationPath={parentTranslationPath}
            translationPath={translationPath}
            onDateChanged={(newValue) => {
              onStateChanged({ id: 'startDate', value: newValue });
            }}
          />
        </div>
        <div className='form-item w-50'>
          <DatePickerComponent
            idRef='endDateRef'
            labelValue='end-date'
            placeholder='DD/MM/YYYY'
            value={state && state.endDate}
            helperText={getErrorByName(schema, 'endDate').message}
            error={getErrorByName(schema, 'endDate').error}
            isSubmitted={isSubmitted}
            isDisabled
            parentTranslationPath={parentTranslationPath}
            translationPath={translationPath}
            onDateChanged={(newValue) => {
              onStateChanged({ id: 'endDate', value: newValue });
            }}
          />
        </div>
      </div>
      <div className='form-item'>
        <Inputs
          idRef='contactRefNoRef'
          labelValue='contract-ref-no'
          value={(state && state.contractRefNo) || ''}
          helperText={getErrorByName(schema, 'contractRefNo').message}
          error={getErrorByName(schema, 'contractRefNo').error}
          isWithError
          isSubmitted={isSubmitted}
          isDisabled
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          onInputChanged={(event) => {
            onStateChanged({ id: 'contractRefNo', value: event.target.value });
          }}
        />
      </div>
      <div className='form-item'>
        <AutocompleteComponent
          idRef='reservationTypeIdRef'
          labelValue='reservation-type'
          selectedValues={selected && selected.reservationType}
          multiple={false}
          data={reservationTypes || []}
          displayLabel={(option) => option.lookupItemName || ''}
          getOptionSelected={(option) => option.lookupItemId === state && state.reservationTypeId}
          withoutSearchButton
          helperText={getErrorByName(schema, 'reservationTypeId').message}
          error={getErrorByName(schema, 'reservationTypeId').error}
          isWithError
          isLoading={loadings.reservationTypes}
          isSubmitted={isSubmitted}
          isDisabled
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          onChange={(event, newValue) => {
            onSelectedChanged({ id: 'reservationType', value: newValue });
            onStateChanged({
              id: 'reservationTypeId',
              value: (newValue && newValue.lookupItemId) || null,
            });
          }}
        />
      </div>
      <div className='form-item'>
        <AutocompleteComponent
          idRef='rentFreePeriodIdRef'
          labelValue='rent-free-period-description'
          selectedValues={selected && selected.rentFreePeriod}
          multiple={false}
          data={rentFreePeriods || []}
          displayLabel={(option) => option.lookupItemName || ''}
          getOptionSelected={(option) => option.lookupItemId === state && state.rentFreePeriod}
          withoutSearchButton
          helperText={getErrorByName(schema, 'rentFreePeriodId').message}
          error={getErrorByName(schema, 'rentFreePeriodId').error}
          isWithError
          isLoading={loadings.rentFreePeriods}
          isSubmitted={isSubmitted}
          isDisabled
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          onChange={(event, newValue) => {
            onSelectedChanged({ id: 'rentFreePeriod', value: newValue });
            onStateChanged({
              id: 'rentFreePeriodId',
              value: (newValue && newValue.lookupItemId) || null,
            });
          }}
        />
      </div>
      {/* <div className='form-item px-0'>
        <div className='px-2 w-100'>
          <Inputs
            idRef='rentFreePeriodRef'
            labelValue='rent-free-period'
            value={state.rentFreePeriod || 0}
            helperText={getErrorByName(schema, 'rentFreePeriod').message}
            error={getErrorByName(schema, 'rentFreePeriod').error}
            isWithError
            isSubmitted={isSubmitted}
            type='number'
            min={0}
            parentTranslationPath={parentTranslationPath}
            translationPath={translationPath}
            onInputChanged={(event) => {
              const value = floatHandler(event.target.value, 3);
              onStateChanged({ id: 'rentFreePeriod', value });
            }}
          />
        </div>
        <div className='px-2'>
          <SelectComponet
            idRef='rentFreePeriodTypeRef'
            data={[
              {
                key: 1,
                value: 'months',
              },
              {
                key: 2,
                value: 'days',
              },
            ]}
            labelValue='type'
            value={state.rentFreePeriodType || 1}
            onSelectChanged={(value) => onStateChanged({ id: 'rentFreePeriodType', value })}
            valueInput='key'
            textInput='value'
            parentTranslationPath={parentTranslationPath}
            translationPath={translationPath}
            translationPathForData={translationPath}
          />
        </div>
      </div> */}
      <div className='form-item'>
        <DatePickerComponent
          idRef='transactionEntryDateRef'
          labelValue='transaction-entry-date'
          placeholder='DD/MM/YYYY'
          value={state && state.transactionEntryDate}
          helperText={getErrorByName(schema, 'transactionEntryDate').message}
          error={getErrorByName(schema, 'transactionEntryDate').error}
          isSubmitted={isSubmitted}
          isDisabled
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          onDateChanged={(newValue) => {
            onStateChanged({ id: 'transactionEntryDate', value: newValue });
          }}
        />
      </div>
      <div className='form-item'>
        <Inputs
          idRef='observationsRef'
          labelValue='observations'
          value={state && state.observations || ''}
          helperText={getErrorByName(schema, 'observations').message}
          error={getErrorByName(schema, 'observations').error}
          isWithError
          isSubmitted={isSubmitted}
          isDisabled
          multiline
          rows={4}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          onInputChanged={(event) => {
            onStateChanged({ id: 'observations', value: event.target.value });
          }}
        />
      </div>
      <div className='form-item px-0'>
        <div className='px-2 w-50'>
          <Inputs
            idRef='occupantsAdultsRef'
            labelValue='number-of-adults-description'
            value={(state && state.occupantsAdults) || 0}
            helperText={getErrorByName(schema, 'occupantsAdults').message}
            error={getErrorByName(schema, 'occupantsAdults').error}
            isWithError
            isSubmitted={isSubmitted}
            isDisabled
            type='number'
            min={0}
            parentTranslationPath={parentTranslationPath}
            translationPath={translationPath}
            onInputChanged={(event) => {
              const value = floatHandler(event.target.value, 3);
              onStateChanged({ id: 'occupantsAdults', value });
            }}
          />
        </div>
        <div className='px-2 w-50'>
          <Inputs
            idRef='occupantsChildrenRef'
            labelValue='number-of-children-description'
            value={(state && state.occupantsChildren) || 0}
            helperText={getErrorByName(schema, 'occupantsChildren').message}
            error={getErrorByName(schema, 'occupantsChildren').error}
            isWithError
            isSubmitted={isSubmitted}
            isDisabled
            type='number'
            min={0}
            parentTranslationPath={parentTranslationPath}
            translationPath={translationPath}
            onInputChanged={(event) => {
              const value = floatHandler(event.target.value, 3);
              onStateChanged({ id: 'occupantsChildren', value });
            }}
          />
        </div>
      </div>
      <div className='form-item'>
        <AutocompleteComponent
          idRef='transactedByIdRef'
          labelValue='transacted-by-leasing-description'
          selectedValues={selected && selected.transactedBy}
          multiple={false}
          data={transactedBy || []}
          displayLabel={(option) => option.fullName || ''}
          renderOption={(option) =>
            ((option.userName || option.fullName) && `${option.fullName} (${option.userName})`) ||
            ''}
          getOptionSelected={(option) => option.id === state && state.transactedById}
          withoutSearchButton
          helperText={getErrorByName(schema, 'transactedById').message}
          error={getErrorByName(schema, 'transactedById').error}
          isWithError
          isSubmitted={isSubmitted}
          isDisabled
          isLoading={loadings.transactedBy}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          onChange={(event, newValue) => {
            onSelectedChanged({ id: 'transactedBy', value: newValue });
            onStateChanged({
              id: 'transactedById',
              value: (newValue && newValue.id) || null,
            });
          }}
        />
      </div>
    </div>
  );
};

AgentInfoRentRelatedComponent.propTypes = {
  state: PropTypes.instanceOf(Object).isRequired,
  selected: PropTypes.instanceOf(Object).isRequired,
  schema: PropTypes.instanceOf(Object),
  unitTransactionId: PropTypes.number,
  onStateChanged: PropTypes.func.isRequired,
  onSelectedChanged: PropTypes.func.isRequired,
  isSubmitted: PropTypes.bool,
  isReadOnly: PropTypes.bool,
  parentTranslationPath: PropTypes.string.isRequired,
  translationPath: PropTypes.string.isRequired,
};
AgentInfoRentRelatedComponent.defaultProps = {
  unitTransactionId: undefined,
  schema: undefined,
  isReadOnly: false,
  isSubmitted: false,
};
