import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => {
  return {
    timelineContainer: {
      marginInlineStart: "3px"
    },
    timeLine: {
      padding: 0,
    },
    missingOppositeContent: {
      "&:before": {
        flex: 0,
        padding: 0,
      },
    },
    timelineSeparator: {
      position: "relative",
      paddingRight: "6px",
      paddingLeft: "6px",
      zIndex: 2,
    },
    timelineDot: {
      margin: 0,
      position: "absolute",
      top: (props) =>
        props?.timelineDotTopMargin
          ? `calc(4px + ${props.timelineDotTopMargin})`
          : "4px",
      left: "1.4px",
      background: theme.palette.utility.brand_500,
      "&::after": {
        content: '""',
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "20px",
        height: "20px",
        borderRadius: "50%",
        background: theme.palette.utility.brand_200,
        zIndex: -1,
      },
    },
    timelineConnector: {
      zIndex: -2,
      background: theme.palette.utility.utility_gray_400,
    },
    timelineContent: {
      padding: 0,
      marginInlineStart: "3px",
    },
    headingWrapper: {
      display: "flex",
      justifyContent: "space-between",
    },
    headingTitle: {
      fontWeight: 600,
      fontSize: "16px",
      lineHeight: "24px",
    },
    closed: {
      fontSize: "14px",
      lineHeight: "20px",
      fontWeight: 500,
      color: theme.palette.text.error_primary,
    },
    open: {
      fontSize: "14px",
      lineHeight: "20px",
      fontWeight: 500,
      color: theme.palette.text.success_primary,
    },
    activityInfo: {
      marginTop: "8px",
      marginBottom: "8px",
      display: "flex",
      flexDirection: "column",
      gap: "8px",
    },
    infoItemWrapper: {
      display: "flex",
      gap: "6px",
      alignItems: "center",
    },
    label: {
      color: theme.palette.text.primary,
      fontSize: "14px",
      lineHeight: "20px",
      fontWeight: 400,
    },
    value: {
      color: theme.palette.text.primary,
      fontSize: "14px",
      lineHeight: "20px",
      fontWeight: 600,
    },
    date: {
      color: theme.palette.text.text_quarterary,
      fontSize: "14px",
      lineHeight: "20px",
      fontWeight: 500,
      paddingInlineStart: "7px",
    },
    time: {
      paddingInlineStart: "4px",
    },
    copyToClipboardWrapper: {
      fontSize: "14px",
      lineHeight: "20px",
      fontWeight: 600,
      color: theme.palette.button.tertiary_fg,
      cursor: "pointer",
      padding: 0,
      margin: "0px !important",
      borderRadius: theme.borderRadius[4],
      direction: "ltr",
      paddingInlineEnd: "2px",

      "&:hover": {
        color: theme.palette.button.tertiary_fg_hover,
        "& svg, & svg path": {
          fill: theme.palette.button.tertiary_fg_hover,
        },
      },
    },
    actions: {
      display: "flex",
      justifyContent: "center",
      margin: "16px 0px",
    },
  };
});
