
import React from 'react'; 
import { useTranslation } from 'react-i18next';
import {  Tables   } from '../../../../../../Components';
import { TableActions } from '../../../../../../Enums';


export const UnitsModelsTable = ({unitsModels , parentTranslationPath  , translationPath ,tableActionClicked , onPageSizeChanged ,onPageIndexChanged ,filter }) => {
  const { t } = useTranslation(parentTranslationPath);

  const getActionTableWithPermissions = () => {
    const list = [];
    if (true) 
      list.push({
        enum: TableActions.editText.key,
        isDisabled: false,
        externalComponent: null,
      });
    if (true) 
       list.push({
        enum: TableActions.deleteText.key,
        isDisabled: false,
        externalComponent: null,
      });
    return list;
  };

 
 return(
    
     <div className='w-100 px-3'>
        <Tables
           data={(unitsModels.result)||[]}
           className=''
           headerData={[
             {
               id: 1,
               label: 'name',
               component: (item) => (
                 <span>{item.propertyUnitModelName || ''}</span>
               ),
             },
             {
               id: 2,
               label: 'Area',
               component: (item) => <span>{item.area}</span>,
             },
            {
               id: 3,
               label: 'bedroomNo',
               component: (item) => (
                <span>{((item.bedroomNo === 0 ||item.bedroomNo)? item.bedroomNo :'' )}</span>
              ),
             },
             {
               id: 4,
               label: 'bathroomNo',
               component: (item) => <span>{((item.bathroomNo || item.bathroomNo === 0) ?item.bathroomNo  : '' ) }</span>,
             },
             {
              id: 5,
              label: 'unitType',
              component: (item) => <span>{(item.propertyUnitType && item.propertyUnitType.lookupItemName) || ''}</span>,
            },
             {
               id: 6,
               label: 'sellingPriceFrom',
               component: (item) => (
                <span>{(( item.sellingPriceFrom  || item.sellingPriceFrom === 0) ?  item.sellingPriceFrom  : '' )}</span>
              ),
             },
             {
               id: 7,
               label: 'sellingPriceTo',
               component: (item) => (
                 <span>{((item.sellingPriceTo || item.sellingPriceTo === 0) ? item.sellingPriceTo  : '')}</span>
               ),
            },
            {
              id: 8,
              label: 'rentPerYearFrom',
              component: (item) => (
                <span>{((item.rentPerYearFrom || item.rentPerYearFrom === 0 )? item.rentPerYearFrom : '')}</span>
              ),
            },
            {
              id: 9,
              label: 'rentPerYearTo',
              component: (item) => (
                <span>{(( item.rentPerYearTo || item.rentPerYearTo  === 0) ?  item.rentPerYearTo : '')}</span>
              ),
            },
          ]}
          defaultActions={getActionTableWithPermissions()}
          onPageIndexChanged={onPageIndexChanged}
          onPageSizeChanged={onPageSizeChanged}
          actionsOptions={{
            onActionClicked: tableActionClicked,
          }}
          itemsPerPage={filter.pageSize}
          activePage={filter.pageIndex}
          translationPath={translationPath}
          parentTranslationPath={parentTranslationPath}
          totalItems={unitsModels.totalCount}
        />
       </div>
   ) 
};
