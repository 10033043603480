import React, { useState, useEffect, useCallback, useReducer } from "react";
import { useTranslation } from "react-i18next";
import { PageHeading } from "../../../../../SharedComponents";
import "./ActivityStageView.scss";
import {
  GetAllActivityTypeLeadStage,
  GetAllActivityTypeRelatedToLead,
  GetLookupItemsByLookupTypeName,
} from "../../../../../Services";
import {
  ActivityExplorerComponent,
  StageActivitiesContainer,
} from "./Utilities";

export const ActivityStageView = ({ parentTranslationPath }) => {
  const translationPath = "ActivityStageView.";
  const { t } = useTranslation(parentTranslationPath);
  const reducer = useCallback((state, action) => {
    if (action.id !== "edit") return { ...state, [action.id]: action.value };
    return {
      ...action.value,
    };
  }, []);
  const [activities, setActivities] = useState([]);
  const [activeLeadStage, setActiveLeadStage] = useState(null);
  const [searchByName, setSearchByName] = useState("");
  const [activeCard, setActiveCard] = useState(null);

  const [data, setData] = useState({
    leadStages: [],
    stageActivities: [],
    activities: [],
  });

  const [loadings, setLoadings] = useReducer(reducer, {
    leadStages: false,
    activities: false,
    stageActivities: false,
  });

  const getLeadStages = async () => {
    const res = await GetLookupItemsByLookupTypeName({
      lookUpName: "LeadStage",
      pageSize: 100,
      pageIndex: 1,
    });
    if (!(res?.status && res.status !== 200)) {
      const firstStageId = res?.result?.length > 0 ? res.result[0] : null;
      setActiveLeadStage(firstStageId);

      setData((current) => ({ ...current, leadStages: res.result }));
    }
  };

  const getAllActivityTypeRelatedToLead = async () => {
    setLoadings({ id: "activities", value: true });
    const res = await GetAllActivityTypeRelatedToLead();
    if (!(res?.status && res.status !== 200)) {
      setActivities(res.result);
      setData((current) => ({ ...current, activities: res.result }));
    }
    setLoadings({ id: "activities", value: false });
  };

  const getAllActivityTypeLeadStage = useCallback(
    async (activeLeadStage) => {
      setLoadings({ id: "stageActivities", value: true });
      try {
        const res = await GetAllActivityTypeLeadStage({
          leadStageId: activeLeadStage.lookupItemId,
          searchByName: searchByName,
        });
        if (!(res?.status && res.status !== 200)) {
          setData((data) => ({
            ...data,
            stageActivities: res.result,
            activities: activities.filter(
              (item) =>
                !res.result.find(
                  (s) => s.activityTypeId === item.activityTypeId
                )
            ),
          }));
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoadings({ id: "stageActivities", value: false });
      }
    },
    [activities, data, searchByName]
  );

  useEffect(() => {
    if (activeLeadStage && activities.length > 0) {
      getAllActivityTypeLeadStage(activeLeadStage);
    }
  }, [activeLeadStage, activities, searchByName]);

  useEffect(() => {
    getLeadStages();
    getAllActivityTypeRelatedToLead();
  }, []);

  return (
    <div className="ActivityStageView">
      <PageHeading
        parentTranslationPath={parentTranslationPath}
        translationPath={translationPath}
        headerTitle={"ActivityStage"}
        subTitle={"ActivityStage-heading"}
        wrapperClasses={"px-4 w-75"}
        headerTitleClasses={"fz-18 mb-1"}
        subTitleClasses={"fz-14 m-0 ml-0"}
      />
      <div className="w-100 d-flex flex-wrap px-4 mb-3">
        {data?.leadStages?.map((item) => (
          <div
            key={item.lookupItemId}
            className={`stage-button mr-3 mb-2 ${
              activeLeadStage?.lookupItemId === item.lookupItemId
                ? "active-stage-btn"
                : ""
            }`}
            onClick={() => setActiveLeadStage(item)}
          >
            <span>{item.lookupItemName}</span>
          </div>
        ))}
      </div>

      <div className="d-flex-v-center-h-between px-4">
        <ActivityExplorerComponent
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          activities={data.activities}
          reload={() => getAllActivityTypeLeadStage(activeLeadStage)}
          isLoading={loadings.activities}
          currentStageId={activeLeadStage?.lookupItemId}
          activeCard={activeCard}
          setActiveCard={setActiveCard}
        />
        <div className="mx-4">
          <span className="icons b-icon i-24 i-switch-horizontal"></span>
        </div>
        <StageActivitiesContainer
          activeLeadStage={activeLeadStage}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          reload={() => getAllActivityTypeLeadStage(activeLeadStage)}
          selectedActivities={data.stageActivities}
          isLoading={loadings.stageActivities}
          activeCard={activeCard}
          setActiveCard={setActiveCard}
          setSearchByName={setSearchByName}
        />
      </div>
    </div>
  );
};
