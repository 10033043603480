

import React , { useState , useEffect , useCallback , useRef  } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { CheckboxesComponent , AutocompleteComponent  } from '../../../../../../../Components' ; 
import { OrganizationUserSearch } from '../../../../../../../Services' ;  


    
export const ActivitiesAssignTo  = ({
  parentTranslationPath,
  translationPath,
  state,
  setState , 
  checkAtLeastOneSelected, 
  checkAtLeastOneSelected2 ,
  setDefaultSelected , 
}) => {
    const { t } = useTranslation(parentTranslationPath);
    const [allUsers , setAllUsers] =  useState([]) ;
    const [isLoading ,   setIsLoading ] = useState(false) ; 

    const searchTimer = useRef(null);
    let timer = null; 


    const getAllOrganizationUserSearch = useCallback(async (searchValue) => {
      setIsLoading(true);
      const res = await OrganizationUserSearch({ pageIndex: 0, pageSize: 10, name: searchValue  , userStatusId : 2 });
      if (!(res && res.status && res.status !== 200)) 
      setAllUsers(res.result.map((item) => ({
        id :  item.id , 
        fullName : item.fullName  , 
        branch : item.branch  || null  , 
      })));
       else 
       setAllUsers([]);  

       setIsLoading(false);

    }, []); 


    useEffect(() => {
      getAllOrganizationUserSearch() ;
    }, []);
 
  return (
    <div className='content'>
    <div className='w-100 px-2 mt-2 checkBoxsPart'>
       <div className='checkboxesItem'>
         <CheckboxesComponent
          idRef='ActivitiesAssignToViewAllActivitiesRef'
          label={t(`${translationPath}view-all-activities`)}
          parentTranslationPath={parentTranslationPath}
          translationPathForData={translationPath}
          singleChecked={state.activities.assignedTo.allUsers}
          onSelectedCheckboxClicked={(e)=> {

             const checkIfCanSelected  = checkAtLeastOneSelected({ ...state.activities.assignedTo ,  allUsers : e.target.checked } );
             if(checkAtLeastOneSelected2(state.activities.assignedTo.specificUsers));
              else if(!checkIfCanSelected)
                return ; 

             const activitiesAssignedTo =  { ...state.activities.assignedTo ,
                allUsers : (e.target.checked)  , 
                myUser  : false , 
                myTeamUsers : false , 
                myBusinessGroupUsers : false , 
                myBranchUsers :false , 
                myManageUsers : false ,
                specificUsers:[] , 
                myTeamLeadUsers : false , 
                
              } ;
          setState({id : 'activities' , value : { ...state.activities , assignedTo :   activitiesAssignedTo  } });
         }}
         />
        </div>
        <div className='checkboxesItem'>
         <CheckboxesComponent
          idRef='ActivitiesAssignToViewMyActivitiesRef'
          label={t(`${translationPath}view-my-activities`)}
          parentTranslationPath={parentTranslationPath}
          translationPathForData={translationPath}
          singleChecked={state.activities.assignedTo.myUser}
          onSelectedCheckboxClicked={(e)=> {
            const checkIfCanSelected  = checkAtLeastOneSelected({ ...state.activities.assignedTo ,  myUser : e.target.checked } );
            if(checkAtLeastOneSelected2(state.activities.assignedTo.specificUsers));
              else if(!checkIfCanSelected)
                   return ;    

             const activitiesAssignedTo =  { ...state.activities.assignedTo ,
                allUsers : false  , 
                myUser  : (e.target.checked) , 
                myTeamUsers : false , 
                myBusinessGroupUsers : false , 
              } ; 
              
          setState({id : 'activities' , value : { ...state.activities , assignedTo :   activitiesAssignedTo  } });
         }}
         />
         </div>
        <div className='checkboxesItem'>
         <CheckboxesComponent
          idRef='ActivitiesAssignToViewMyTeamActivitiesRef'
          label={t(`${translationPath}view-my-team-activities`)}
          parentTranslationPath={parentTranslationPath}
          translationPathForData={translationPath}
          singleChecked={state.activities.assignedTo.myTeamUsers}
          onSelectedCheckboxClicked={(e)=> {

            const checkIfCanSelected  = checkAtLeastOneSelected({ ...state.activities.assignedTo ,  myTeamUsers : e.target.checked } );
              if(checkAtLeastOneSelected2(state.activities.assignedTo.specificUsers));
               else if(!checkIfCanSelected)
                return ; 
              
            const activitiesAssignedTo =  { ...state.activities.assignedTo ,
               allUsers : false   , 
               myUser  : false  , 
               myTeamUsers : (e.target.checked) , 
               myBusinessGroupUsers : false , 

             } ;
          setState({id : 'activities' , value : { ...state.activities , assignedTo :   activitiesAssignedTo  } });
         }}
         />
         </div>
         <div className='checkboxesItem'>
          <CheckboxesComponent
            idRef='ActivitiesAssignToViewMyBusinessGroupActivitiesRef'
            label={t(`${translationPath}view-my-business-group-activities`)}
            parentTranslationPath={parentTranslationPath}
            translationPathForData={translationPath}
            singleChecked={state.activities.assignedTo.myBusinessGroupUsers}
            onSelectedCheckboxClicked={(e)=> {

              const checkIfCanSelected  = checkAtLeastOneSelected({ ...state.activities.assignedTo ,  myBusinessGroupUsers : e.target.checked } );

              if(checkAtLeastOneSelected2(state.activities.assignedTo.specificUsers));
                else if(!checkIfCanSelected)
                     return ;
                     
              const activitiesAssignedTo =  { ...state.activities.assignedTo ,
                 allUsers : false   , 
                 myUser  : false  , 
                 myTeamUsers : false , 
                 myBusinessGroupUsers : (e.target.checked) , 
  
               } ;
            setState({id : 'activities' , value : { ...state.activities , assignedTo :   activitiesAssignedTo  } });
           }}
         />
          </div>
        <div className='checkboxesItem'>
         <CheckboxesComponent
          idRef='ActivitiesAssignToViewMyBranchActivitiesRef'
          label={t(`${translationPath}view-my-branch-activities`)}
          parentTranslationPath={parentTranslationPath}
          translationPathForData={translationPath}
          singleChecked={state.activities.assignedTo.myBranchUsers}
          onSelectedCheckboxClicked={(e)=> {

            const checkIfCanSelected  = checkAtLeastOneSelected({ ...state.activities.assignedTo,  myBranchUsers : e.target.checked });

            if(checkAtLeastOneSelected2(state.activities.assignedTo.specificUsers));
              else if(!checkIfCanSelected)
                   return ;
            const activitiesAssignedTo =  { ...state.activities.assignedTo ,
               allUsers : false   , 
               myBranchUsers :(e.target.checked) , 

             } ;
          setState({id : 'activities' , value : { ...state.activities , assignedTo :   activitiesAssignedTo  } });
         }}
         />
         </div>
         <div className='checkboxesItem'>
         <CheckboxesComponent
          idRef='ActivitiesAssignToviewMyManagedTeamActivitiesRef'
          label={t(`${translationPath}view-my-managed-team-activities`)}
          parentTranslationPath={parentTranslationPath}
          translationPathForData={translationPath}
          singleChecked={state.activities.assignedTo.myManageUsers}
          onSelectedCheckboxClicked={(e)=> {
            const checkIfCanSelected  = checkAtLeastOneSelected({ ...state.activities.assignedTo ,  myManageUsers : e.target.checked } );
            if(checkAtLeastOneSelected2(state.activities.assignedTo.specificUsers));
                else if(!checkIfCanSelected)
                     return ; 
            const activitiesAssignedTo =  { ...state.activities.assignedTo ,
               allUsers : false    , 
               myManageUsers : (e.target.checked) ,

             } ;
          setState({id : 'activities' , value : { ...state.activities , assignedTo :   activitiesAssignedTo  } });
         }}
         />
         </div>
         <div className='checkboxesItem'>
          <CheckboxesComponent
            idRef='ActivitiesAssignToViewMyTeamLeadUsersRef'
            label={t(`${translationPath}view-my-team-lead-users`)}
            parentTranslationPath={parentTranslationPath}
            translationPathForData={translationPath}
            singleChecked={state.activities.assignedTo.myTeamLeadUsers}
            onSelectedCheckboxClicked={(e)=> {
              const checkIfCanSelected  = checkAtLeastOneSelected({ ...state.activities.assignedTo ,  myTeamLeadUsers : e.target.checked } );
             
              if(checkAtLeastOneSelected2(state.activities.assignedTo.specificUsers));
             else if(!checkIfCanSelected)
                    return ;
                
              const activitiesAssignedTo =  { ...state.activities.assignedTo ,
                 allUsers : false  , 
                 myTeamLeadUsers : (e.target.checked)
               } ;
            setState({id : 'activities' , value : { ...state.activities , assignedTo  :  activitiesAssignedTo  } });
           }}
          />
         </div>
         <div className='w-100 px-2 mt-2 mb-2'>
          <AutocompleteComponent
              idRef='activitiesAssignedToSpecificsUsersRef'
              labelValue={t(`${translationPath}specific_users`)}
              data={ allUsers || []}
              selectedValues={state.activities.assignedTo.specificUsers}
              multiple
              displayLabel={(option) =>
              (option && option.fullName) || ''}
              chipsLabel={(option) => (option && option.fullName) || ''}
              withoutSearchButton
              parentTranslationPath={parentTranslationPath}
              translationPath={translationPath}
              onInputKeyUp={(e) => {
                const { value } = e.target;
                 if (searchTimer.current) clearTimeout(searchTimer.current);
                   searchTimer.current = setTimeout(() => {
                    getAllOrganizationUserSearch(value) ;  
                }, 700); 
                }}
                renderOption={
                  ((option) =>
                  <div className='d-flex-column'>
                   <div className='d-flex-v-center-h-between w-100 texts-truncate'>
                    {option.fullName}
                   </div>
                   <span className='c-gray-secondary'> {option.branch} </span>
                 </div>
               )}
              value={state.activities.assignedTo.specificUsers}
              onChange={(e, newValues) => 
                {
                  let  activitiesAssignedTo = { ...state.activities.assignedTo  , specificUsers : newValues };
                  if( !newValues.length && setDefaultSelected({ ...state.activities.assignedTo , specificUsers :false }))
                   activitiesAssignedTo = { ...state.activities.assignedTo  , specificUsers : newValues , allUsers : true }
                  
                  setState({ id: 'activities', value: { ...state.activities , assignedTo  : activitiesAssignedTo } })
                }}
             isLoading={isLoading} 
             withLoader
             filterOptions={(options) => {
              const isFind = (id) => state.activities.assignedTo.specificUsers.findIndex((w) => w.id === id) === -1;
             return options.filter((w) => isFind(w.id));
            }}
            isDisabled={state.activities.assignedTo.allUsers}
         /> 
        </div>
      </div>
    </div>
  );
};

ActivitiesAssignTo.propTypes = {
  parentTranslationPath: PropTypes.string.isRequired,
  translationPath: PropTypes.string.isRequired,
  state:PropTypes.instanceOf(Object).isRequired,
  setState : PropTypes.func.isRequired,
  checkAtLeastOneSelected :PropTypes.func.isRequired, 
  checkAtLeastOneSelected2 :PropTypes.func.isRequired,
  setDefaultSelected  : PropTypes.func.isRequired,
};

