import React from "react";
import { useSelector } from "react-redux";
import { Box } from "@material-ui/core";

// Styles
import useStyles from "./styles";

const NoResultsFound = ({ searchText = "" }) => {
  const { isDarkMode } = useSelector((state) => state.theme);

  const styles = useStyles({ isDarkMode });

  return (
    <Box className={styles.flexCenter}>
      <Box className={styles.container}>
        <span>
          {`Your search  “${searchText}” did not match any projects. Please try again.`}
        </span>
        <h4>No projects found</h4>
      </Box>
    </Box>
  );
};

export default NoResultsFound;
