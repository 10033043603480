import React, { useCallback, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
import { Spinner, Tables, SwitchComponent } from '../../../../../Components';
import { TableActions, TableFilterTypesEnum } from '../../../../../Enums';
import { ZeroMatchingLeasePermissions } from '../../../../../Permissions';
import {
  GlobalHistory,
  showError,
  returnPropsByPermissions,
} from '../../../../../Helper';
import { ActiveItemActions } from '../../../../../store/ActiveItem/ActiveItemActions';

import { DeleteInquiryDialog } from '../../DeleteInquiry/DeleteInquiryDialog';
import { CancelInquiryDialog } from '../../CancelInquiry/CancelInquiryDialog';

export const ZeroMatchingLeaseViewTable = ({
  parentTranslationPath,
  translationPath,
  filter,
  data,
  isLoading,
  activeItem,
  setActiveItem,
  editZeroMatching,
  onSave,
  setFilter,
  setSortBy,
  setCriteria,
}) => {
  const dispatch = useDispatch();
  const [isOpenDeleteDialog, setIsOpenDeleteDialog] = useState(false);
  const { t } = useTranslation([parentTranslationPath, 'Shared']);
  const [isOpenCancelInquiryDialog, setIsOpenCancelInquiryDialog] =
    useState(false);

  const headerData = [
    {
      id: 1,
      isSortable: false,
      input: 'inquiryId',
      label: t(`${translationPath}inquiryId`),
      isFilterHidden: true,
    },
    {
      id: 2,
      isSortable: true,
      input: 'leadId',
      label: t(`${translationPath}leadId`),
      isFilterHidden: true,
    },
    {
      id: 3,
      isSortable: true,
      input: 'inquiryStatus',
      label: t(`${translationPath}inquiry-status`),
      isFilterHidden: true,
    },
    {
      id: 4,
      isSortable: true,
      input: 'createdOn',
      isDate: true,
      label: t(`${translationPath}createdOn`),
      isFilterHidden: true,
    },
    {
      id: 5,
      isSortable: true,
      input: 'updateOn',
      label: t(`${translationPath}updateOn`),
      isDate: true,
      isFilterHidden: true,
    },
    {
      id: 6,
      isSortable: true,
      input: 'createdByName',
      isDate: false,
      label: t(`${translationPath}createdBy`),
      isFilterHidden: true,
    },
    {
      id: 7,
      isSortable: true,
      input: 'assignedTo',
      isDate: false,
      label: t(`${translationPath}assignedTo`),
      isFilterHidden: true,
    },
    {
      id: 8,
      isSortable: true,
      input: 'updatedByName',
      isDate: false,
      label: t(`${translationPath}updatedBy`),
      isFilterHidden: true,
    },
    {
      id: 9,
      isSortable: true,
      input: 'closeReason',
      isDate: false,
      label: t(`${translationPath}cancelReason`),
      isFilterHidden: true,
    },
    {
      id: 11,
      input: 'community.lookupItemName',
      displayPath: 'community.lookupItemName',
      label: t(`${translationPath}community`),
      isSortable: true,
      filterType: TableFilterTypesEnum.textInput.key,
      component: (row) => row.community?.lookupItemName,
    },
    {
      id: 12,
      input: 'property.name',
      label: t(`${translationPath}property`),
      isSortable: true,
      filterType: TableFilterTypesEnum.textInput.key,
      component: (row) => row.property?.name,
      displayPath: 'property.name',
    },
    {
      id: 13,
      input: 'unit_type.lookupItemName',
      displayPath: 'unit_type.lookupItemName',
      filterType: TableFilterTypesEnum.textInput.key,
      label: t(`${translationPath}unit_type`),
      component: (row) =>
        (Array.isArray(row.unit_type) &&
      row.unit_type?.map((item) => item.lookupItemName).join(', ')) ||
      (typeof row.unit_type === 'object' && row.unit_type?.lookupItemName),
      isSortable: true,
    },
    {
      id: 13,
      input: 'bedroom',
      label: t(`${translationPath}bedroom`),
      isSortable: true,
      filterType: TableFilterTypesEnum.textInput.key,
    },
    {
      id: 13,
      input: 'client_budget_from',
      isSortable: true,
      label: t(`${translationPath}client_budget_from`),
      filterType: TableFilterTypesEnum.textInput.key,
    },
    {
      id: 13,
      input: 'client_budget_to',
      isSortable: true,
      label: t(`${translationPath}client_budget_to`),
      filterType: TableFilterTypesEnum.textInput.key,
    },
    {
      id: 13,
      input: 'area_size_fromsqft',
      label: t(`${translationPath}area_size_fromsqft`),
      isSortable: true,
      filterType: TableFilterTypesEnum.textInput.key,
    },
    {
      id: 13,
      input: 'area_size_tosqft',
      label: t(`${translationPath}area_size_tosqft`),
      filterType: TableFilterTypesEnum.textInput.key,
      isSortable: true,
    },
    {
      id: 10,
      isSortable: true,
      input: 'remarks',
      isDate: false,
      label: t(`${translationPath}remarks`),
    },
  ];

  const tableActionClicked = useCallback((actionEnum, item) => {
    setActiveItem(item);
    if (actionEnum === TableActions.edit.key) {
      dispatch(ActiveItemActions.activeItemRequest(item));
      GlobalHistory.push(
        `/home/zero-matching-lease/zero-matching-lease-profile-edit?formType=${47}&id=${
          item && item.inquiryId
        }&activeFormType=${2}`
      );
    } else if (actionEnum === TableActions.delete.key)
      setIsOpenDeleteDialog(true);
    else if (actionEnum === TableActions.close.key) {
      if (item.inquiryStatus === 'Canceled')
        showError(t(`${translationPath}inquiryStatusIsCanceled`));
      else setIsOpenCancelInquiryDialog(true);
    }
  }, []);

  const getActionTableWithPermissions = () => {
    const list = [];
    if (
      returnPropsByPermissions(
        ZeroMatchingLeasePermissions.EditInquiry.permissionsId
      )
    )
      list.push({
        enum: TableActions.edit.key,
        title: t(`${translationPath}Shared:edit`),
      });
    if (
      returnPropsByPermissions(
        ZeroMatchingLeasePermissions.DeleteInquiry.permissionsId
      )
    )
      list.push({
        enum: TableActions.delete.key,
        title: t(`${translationPath}Shared:delete`),
      });
    if (
      returnPropsByPermissions(
        ZeroMatchingLeasePermissions.CancelInquiry.permissionsId
      )
    )
      list.push({
        enum: TableActions.close.key,
        title: t(`${translationPath}Shared:cancel`),
      });

    return list;
  };

  return (
    <div className='ZeroMatchingLeaseViewTable'>
      <Spinner isActive={isLoading.Inquiries} isAbsolute />
      <div className='filter-section-item' />
      <div className='w-100 px-2'>
        <Tables
          data={(data && data.result) || []}
          headerData={headerData}
          isWithFilter
          filterData={headerData.map((item) => ({
            ...item,
            searchableKey: item.displayPath ?? item.input,
          }))}
          FilterDisabledButton
          defaultActions={getActionTableWithPermissions()}
          actionsOptions={{
            onActionClicked: tableActionClicked,
          }}
          itemsPerPage={filter.pageSize}
          activePage={filter.pageIndex}
          translationPath={translationPath}
          parentTranslationPath={parentTranslationPath}
          totalItems={(data && data.totalCount) || 0}
          setSortBy={setSortBy}
          onFilterValuesChanged={(e) => {
            if (e && !!Object.values(e).length) {
              const { value, searchableKey } = Object.values(e)[0];
              if (!!value) {
                setCriteria((criteria) => ({
                  ...criteria,
                  [searchableKey]: [
                    {
                      searchType:
                        searchableKey === 'unit_type.lookupItemName' ? 1 : 2,
                      value,
                    },
                  ],
                }));
              } else {
                setCriteria((criteria) => ({
                  ...criteria,
                  [searchableKey]: undefined,
                }));
              }
            } else {
              setCriteria((criteria) => {
                const updatedFilter = Object.entries(criteria).filter(
                  ([_key, value]) => !!value
                );
                return Object.fromEntries(updatedFilter);
              });
            }
          }}
        />
      </div>
      {isOpenDeleteDialog && (
        <DeleteInquiryDialog
          open={isOpenDeleteDialog}
          close={() => {
            setIsOpenDeleteDialog(false);
          }}
          onSave={() => {
            setIsOpenDeleteDialog(false);
            setFilter();
          }}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          activeItem={activeItem}
        />
      )}
      {isOpenCancelInquiryDialog && (
        <CancelInquiryDialog
          open={isOpenCancelInquiryDialog}
          close={() => {
            setIsOpenCancelInquiryDialog(false);
          }}
          onSave={() => {
            setIsOpenCancelInquiryDialog(false);
            setFilter();
          }}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          activeItem={activeItem}
        />
      )}
    </div>
  );
};

ZeroMatchingLeaseViewTable.propTypes = {
  parentTranslationPath: PropTypes.string.isRequired,
  translationPath: PropTypes.string.isRequired,
  filter: PropTypes.number.isRequired,
  data: PropTypes.instanceOf(Object).isRequired,
  isLoading: PropTypes.bool.isRequired,
};
