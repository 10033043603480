import React, {
    useEffect, useState, useCallback, useRef
} from 'react';
import { PopoverComponent } from '../../../../../../../Components';
import { ButtonBase } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';

export const ModuelPopOverComponent = ({ translationPath, setDublicatModule, t }) => {

    const moduelOptions = useRef([
        { key: 1, value: 'Leasing' },
        { key: 2, value: 'Sales' },
        { key: 3, value: 'PM' },
        { key: 4, value: 'Mortgage' },
    ])
    const [attachedWith, setAttachedWith] = useState(null);

    const handleClose = useCallback(() => {
        setAttachedWith(null);
    }, []);

    const getOperatorOpetion = useCallback((e, item) => {
        setAttachedWith(e.currentTarget);
    }, [moduelOptions]);

    return (
        <>
                <Tooltip
                    title={t(`${translationPath}duplicate`)}
                >
                    <ButtonBase className={`btns theme-outline actions dublicat-btn`}
                        onClick={(event) => {
                            getOperatorOpetion(event)
                        }}>
                        <span className={'mdi mdi-content-duplicate'} />
                    </ButtonBase>
                </Tooltip>
            <PopoverComponent
                idRef='tableFilterOperatorPopRef'
                attachedWith={attachedWith}
                popoverClasses='table-filter-operator-popover moduel-popOver-wrapper'
                handleClose={handleClose}
                component={(
                    <>
                        {moduelOptions.current.map((selectItem) => (
                            <ButtonBase
                                key={`tableFilterOperatorItemRef`}
                                className={` btns theme-transparent table-filter-operator-wrapper`}
                                onClick={() => {
                                    setDublicatModule(selectItem)
                                    handleClose(null)
                                }}
                            >
                                <span>{selectItem && selectItem.value}</span>
                            </ButtonBase>
                        ))}

                    </>
                )}
            />
        </>
    )
}
