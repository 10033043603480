import React, { useEffect } from 'react';
import { ButtonBase } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  bottomBoxComponentUpdate,
  GlobalHistory,
  showError,
  showSuccess,
  showWarn,
  sideMenuComponentUpdate,
  sideMenuIsOpenUpdate,
} from '../../../../../Helper';
import { BulkUpdateActivityType  } from '../../../../../Services';

export const BulkAssignActions = ({
  state, 
  formFieldIds, 
  parentTranslationPath,
  translationPath,
  setIsSubmitted,
  setIsLoading
}) => {
  const { t } = useTranslation(parentTranslationPath);
  const cancelHandler = () => {
    GlobalHistory.goBack();
  };
  const saveHandler = async () => {
    setIsLoading(true);
    setIsSubmitted(true);
    if(formFieldIds.some((f)=> f.isRequired && (!f.fieldValues  || f.fieldValues ==='') ))
    {
        showError(t(`${translationPath}please-fill-all-required-field`));
        setIsLoading(false);
          return;

    }
    else if(formFieldIds && formFieldIds.length < 1 )
    {
      showError(t(`${translationPath}please-select-fields-and-enter-new-values-to-updated`));
      setIsLoading(false);
        return;
    }
    const result = await BulkUpdateActivityType(state);
    if (!(result && result.status && result.status !== 200))
    {
      showSuccess(t(`${translationPath}activities-types-updated-successfully`));
      GlobalHistory.goBack();
      
    } else showError(t(`${translationPath}activities-types-update-failed`));
    setIsLoading(false);
  };
  useEffect(() => {
    bottomBoxComponentUpdate(
      <div className='bulk-actions-wrapper w-100'>
        <ButtonBase className='btns c-danger' onClick={()=> cancelHandler()}>
          <span>{t(`${translationPath}cancel`)}</span>
        </ButtonBase>
        <ButtonBase className='btns theme-solid' onClick={()=> saveHandler()}>
          <span className='px-3'>
              {t(`${translationPath}save-changes`)}
          </span>
        </ButtonBase>
      </div>
    );
  });
  useEffect(
    () => () => {
      bottomBoxComponentUpdate(null);
      sideMenuComponentUpdate(null);
      sideMenuIsOpenUpdate(false);
    },
    []
  );
  return <div />;
};
BulkAssignActions.propTypes = {
  setIsSubmitted: PropTypes.func.isRequired,
  translationPath: PropTypes.string.isRequired,
  parentTranslationPath: PropTypes.string.isRequired,
  setIsLoading: PropTypes.func.isRequired,
};
