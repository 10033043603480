

import React , {useState , useCallback , useEffect  , useRef  } from 'react';
import { useTranslation } from 'react-i18next'; 
import {  getErrorByName , GetParams  } from  '../../../../../../../../../../../../../Helper' ; 
import { AutocompleteComponent } from '../../../../../../../../../../../../../Components' ;
import { OrganizationUserSearch  , GetBranchByUserIdAPI   } from '../../../../../../../../../../../../../Services' ; 
import { AgentRoleEnum } from '../../../../../../../../../../../../../Enums/AgentRoleEnum' ; 



export const ListingAgentComponent = ({setState , state , translationPath , parentTranslationPath , selected , setSelected , schema , isSubmitted }) => {
   const { t } = useTranslation(parentTranslationPath);
   const [isLoading , setIsLoading ] = useState(false);
   const [branch , setBranch] =  useState(false);

   const searchTimer = useRef(null);
   const [listingAgents, setListingAgents] = useState({
      result: [],
      totalCount: 0,
    });

    const pathName = window.location.pathname.split('/home/')[1].split('/view')[0];

    const getAgentRuleId = () => 
    {

      if(pathName.includes('units-sales/unit-profile-edit') || (pathName.includes('lead-owner-units-sale/unit-profile-edit')))
       return   AgentRoleEnum.SaleListingAgent.value ; 

       else if(pathName.includes('units-lease/unit-profile-edit') || (pathName.includes('lead-owner-units-lease/unit-profile-edit')))
       return   AgentRoleEnum.LeaseListingAgent.value ;
      return null ;  
    };


   
  
  


    const GetAllOrganizationUsersAPI = useCallback(async (search) => {
      setIsLoading(true) ; 
        const res = await OrganizationUserSearch({
           pageIndex : 1 ,
           pageSize : 50 ,
           userStatusId : 2  ,
           name: search,
           userTypeId : (getAgentRuleId())  , 

         });

        if (!(res && res.status && res.status !== 200)) 
        setListingAgents({
            result: res.result,
            totalCount: res.totalCount
          });
        else setListingAgents({
            result: [],
            totalCount: 0
          });

          setIsLoading(false) ; 
      }, []);

     
      const  GetBranchByUserId = useCallback(async (userId) => {
        if(userId)
        {
          setIsLoading(true) ; 
          const res = await GetBranchByUserIdAPI(userId);
          if (!(res && res.status && res.status !== 200)) 
          setBranch(res) ; 
         else setBranch(null) ; 
          setIsLoading(false) ; 

        }
        }, []);

     


  useEffect(() => {
    GetAllOrganizationUsersAPI();
  }, [GetAllOrganizationUsersAPI]);

  
  useEffect(() => {
    const updateState =  {...state , branchId :(branch && branch.branchId) , portalAccountId : null , portalId : null  } ; 
    setState({ id: 'edit', value: (updateState)});
    setSelected((item) => ({...item ,
       branch :branch || null , 
       portalAccount  : null ,
       portal :  null 
      })) 
  }, [branch]);


  return (
    <>
        <div className='dialog-content-item mt-2'>
             <AutocompleteComponent
                idRef='listingAgentIdRef'
                labelValue='listingAgent'
                multiple={false}
                value={selected.listingAgent}
                selectedValues={selected.listingAgent}
                data={(listingAgents && listingAgents.result) || []}
                chipsLabel={(option) => (option && option.fullName) || ''}
                displayLabel={(option) => (option && option.fullName) || ''}
                withoutSearchButton
                parentTranslationPath={parentTranslationPath}
                translationPath={translationPath}
                onChange={(event, newValue) => 
                {  
                  if(newValue)
                  {
                    GetBranchByUserId(newValue.id) ;  

                  }
                  else setBranch(null) ; 
                  const updateState =  { ...state ,
                     listingAgentId : (newValue && newValue.id) || null  ,
                     } ; 
                   setState({ id: 'edit', value: updateState  });
                   setSelected((item) => ({...item , 
                     listingAgent : newValue ,
                     
                     })) ;
                }}
                onInputKeyUp={(e) => {
                  const { value } = e.target;
                  if (searchTimer.current)
                    clearTimeout(searchTimer.current);
                  searchTimer.current = setTimeout(() => {
                    GetAllOrganizationUsersAPI(value)
                  }, 700);
                }}
                helperText={getErrorByName(schema, 'listingAgentId').message}
                error={getErrorByName(schema, 'listingAgentId').error}
                isWithError
                isSubmitted={isSubmitted}
                withLoader 
                isLoading={isLoading}
             />
         </div>
     </>
  );
};
