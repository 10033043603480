import React from "react";
import { CustomCard } from "../../CustomCard";
import { Skeleton } from "@material-ui/lab";
import { Box } from "@material-ui/core";


//styles
import useStyles from "./styles";

const AssignedAgentsCradSkeleton = ({ numberOfCardsSkeleton }) => {
  const styles = useStyles();

  const skeletonCards = Array.from(
    { length: numberOfCardsSkeleton },
    (_, index) => (
      <CustomCard
        key={index}
        borderRadius="xl"
        borderColor="secondary"
        classes={styles.containerCard_Assign_AgentSkeleton}
      >
        <Box className={styles.container_Avatar_PersonalInfo}>
          <Skeleton
            animation="wave"
            variant="circle"
            width={72}
            height={72}
          ></Skeleton>
          <Box className={styles.containerPersonalInfo}>
            <Skeleton
              animation="wave"
              width="150px"
              variant="text"
              height="28px"
            ></Skeleton>
            <Skeleton
              animation="wave"
              width="150px"
              variant="text"
              height="24px"
            ></Skeleton>
          </Box>
        </Box>
      </CustomCard>
    )
  );
  return <>{skeletonCards}</>;
};

export default AssignedAgentsCradSkeleton;
