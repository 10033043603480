import { Description } from '@material-ui/icons';
import i18next from 'i18next';
import React from 'react';
import { Link } from 'react-router-dom/cjs/react-router-dom';
const parentTranslationPath = 'ContactOpportunity';

const headerData = [
  [
    {
      label: '#',
      input: '',
      component: (_row, rowIndex, _, __, activePage, itemsPerPage) =>
        activePage * itemsPerPage + (rowIndex + 1),
    },
    {
      label: 'contact-name',
      input: 'name',
      component: (row) => (
        <Link
          target='_blank'
          to={`/home/Contacts-CRM/contact-profile-edit?formType=${
            row.contactsType === 'Individuals' ? 1 : 2
          }&id=${row.contactId}`}
        >
          {row.name}
        </Link>
      ),
    },
    {
      label: 'lead_id',
      input: 'leadId',
      component: (row) =>
        row.leadId ? (
          <Link
            target='_blank'
            to={`/home/leads/lead-profile-edit?formType=${
              row.contactsType === 'Individuals' ? 1 : 2
            }&id=${row.leadId}`}
          >
            {row.leadId}
          </Link>
        ) : (
          '-'
        ),
    },
    { label: 'nationality', input: 'nationality' },
    {
      label: 'status',
      input: 'status',
      component: (item) =>
        i18next.t(item.status, { ns: parentTranslationPath }),
    },
    { label: 'pulled-by', input: 'createdBy' },
    {
      label: 'pulling-date',
      input: 'createdOn',
      isDate: true,
    },
    {
      label: 'pulling-time',
      input: 'createdOn',
      isDate: true,
      dateFormat: 'hh:mm A',
    },
    {
      label: 'documents-count',
      input: 'contactDocumentsCount',
      component: (row) => (
        <Link
          target='_blank'
          to={`/home/Contacts-CRM/contact-profile-edit?formType=${
            row.contactsType === 'Individuals' ? 1 : 2
          }&id=${row.contactId}&tab=documents`}
        >
          <div className='flex fa-center'>
            <Description />
            {row.contactDocumentsCount}
          </div>
        </Link>
      ),
    },
    {
      label: 'is-merged',
      input: 'isMarged',
      component: (item) =>
        i18next.t(item.isMarged ? 'yes' : 'no', { ns: parentTranslationPath }),
    },
  ],
  [
    {
      label: '#',
      input: '',
      component: (_row, rowIndex) => rowIndex + 1,
    },
    {
      label: 'company-name',
      input: 'name',
      component: (row) => (
        <Link
          target='_blank'
          to={`/home/Contacts-CRM/contact-profile-edit?formType=${
            row.contactsType === 'Individuals' ? 1 : 2
          }&id=${row.contactId}`}
        >
          {row.name}
        </Link>
      ),
    },
    {
      label: 'lead_id',
      input: 'leadId',
      component: (row) =>
        row.leadId ? (
          <Link
            target='_blank'
            to={`/home/leads/lead-profile-edit?formType=${
              row.contactsType === 'Individuals' ? 1 : 2
            }&id=${row.leadId}`}
          >
            {row.leadId}
          </Link>
        ) : (
          '-'
        ),
    },
    { label: 'company-nationality', input: 'nationality' },
    {
      label: 'status',
      input: 'status',
      component: (item) =>
        i18next.t(item.status, { ns: parentTranslationPath }),
    },
    { label: 'pulled-by', input: 'createdBy' },
    { label: 'pulling-date', input: 'createdOn', isDate: true },
    {
      label: 'pulling-time',
      input: 'createdOn',
      isDate: true,
      dateFormat: 'hh:mm A',
    },
    {
      label: 'documents-count',
      input: 'contactDocumentsCount',
      component: (row) => (
        <Link
          target='_blank'
          to={`/home/Contacts-CRM/contact-profile-edit?formType=${
            row.contactsType === 'Individuals' ? 1 : 2
          }&id=${row.contactId}&tab=documents`}
        >
          <div className='flex fa-center'>
            <Description />
            {row.contactDocumentsCount}
          </div>
        </Link>
      ),
    },
    {
      label: 'is-merged',
      input: 'isMarged',
      component: (item) =>
        i18next.t(item.isMarged ? 'yes' : 'no', { ns: parentTranslationPath }),
    },
  ],
];

export default headerData;
