import React, { useState, useEffect, useCallback } from 'react';
import './QualificationFilter.Style.scss';
import { ButtonBase } from '@material-ui/core';
import { PropTypes } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { StaticLookupsIds } from '../../../assets/json/StaticLookupsIds';
import { lookupItemsGetId } from '../../../Services';

export const QualificationFilter = ({
  parentTranslationPath,
  onButtonClicked,
  translationPath,
  defaultValueis
}) => {
  const { t } = useTranslation(parentTranslationPath);
  const [isLoading, setIsLoading] = useState( false);
  const [SelectedIndex, setSelectedIndex] = useState(defaultValueis || null);
  const [response, setResponse] = useState([]);
  const UnittypeAPI = useCallback(async () => {
    setIsLoading(true);
    const results = await lookupItemsGetId({ lookupTypeId: StaticLookupsIds.Qualification });
    setResponse(results);
    setIsLoading(false);
  }, []);

  const onClickChanged = (index, item) => {
    if (SelectedIndex !== index && item !== null) {
      onButtonClicked({ index, item });
      setSelectedIndex(index);
    } else {
      setSelectedIndex(null);
      onButtonClicked(null);
    }
  };

  const ReturnClassColor = (item) => {
    const itemName = item.lookupItemName;
    const itemClassName = itemName ? itemName.replace(/\s/g, '') : '';
    return itemClassName
  };

  useEffect(() => {
    UnittypeAPI();
  }, []);

  useEffect(() => {
    if(defaultValueis===null)
    setSelectedIndex(null);
  }, [defaultValueis]);


  return (
    <div className='QualificationFilter-wrapper'>
      {response? response.map((item, index) => (
        <ButtonBase
          index={index}
          className={`btns c-black-light history-button ${SelectedIndex === index ? 'Activeitem' : ''} ${ReturnClassColor(item)}`}
          onClick={() => { onClickChanged(index, item); }}
        >
          <div>
            {item?.lookupItemName || ''}
          </div>
        </ButtonBase>
      )) : null}
    </div>
  );
};
