import React from "react";

function DropdownIcon({ fill, ...restProps }) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill={fill ?? ""}
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <path
        d="M4.844 6.703a.804.804 0 0 0-.596.479.943.943 0 0 0 .012.656c.05.109.718.795 2.695 2.767 2.821 2.813 2.696 2.703 3.045 2.703.349 0 .224.11 3.045-2.703 1.977-1.972 2.645-2.658 2.695-2.767a.852.852 0 0 0 .067-.338.786.786 0 0 0-.791-.807c-.363-.003-.25-.101-2.724 2.369L10 11.349 7.708 9.062C5.925 7.282 5.391 6.766 5.3 6.74c-.191-.055-.309-.065-.456-.037"
        fill={fill ?? ""}
      />
    </svg>
  );
}

export default DropdownIcon;
