import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => {
  return {
    container_Task_Card: {
      display: "flex",
      alignItems: "flex-start",
      flexDirection: "column",
      width: "314px",
      padding: "20px 16px",
      gap: "8px",
    },
    header_Card_Container: {
      display: "flex",
      flexDirection: "column",
    },
    Container_CreatedDate: {
      display: "flex",
      flexDirection: "row",
      gap: "10px",
      color: theme.palette.text.text_quarterary,
      fontWeight: 500,
      lineHeight: "20px",
      fontSize: "14px",
    },
    Container_Title_Header: {
      fontWeight: 700,
      lineHeight: "24px",
      fontSize: "16px",
      color: theme.palette.text.primary,
      marginTop: "8px",
    },
    TaskStatus: {
      padding: "4px",
    },
    Status_Task_Container: {
      display: "flex",
      flexDirection: "row",
      gap: "10px",
      alignItems: "center",
    },
    Details_Task_Container: {
      display: "flex",
      flexDirection: "column",
    },
    Deatails_Row: {
      display: "flex",
      flexDirection: "row",
      gap: "6px",
      color: theme.palette.text.primary,
      alignItems: "center",
    },
    container_Avatar_AssignTo: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      marginLeft: "4px",
    },
    TitleDetails: {
      fontWeight: 400,
      lineHeight: "20px",
      fontSize: "14px",
    },
    ValueDetails: {
      fontWeight: 600,
      lineHeight: "24px",
      fontSize: "14px",
    },
    containerAvatar: {
      backgroundColor: "#98A2B324",
      position: "relative",
    },
    containerItemAvatar: {
      border: `2px solid ${theme.palette.background.primary_alt}`,
      borderRadius: "50%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      marginLeft: "-7px",
      zIndex: "2",
    },
    defaultAvatarWithNameStyle: {
      width: "24px",
      height: "24px",
      fontSize: "12px",
      lineHeight: "18px",
      fontWeight: "600",
      backgroundColor: theme.palette.background.tertiary,
      color: theme.palette.text.text_quarterary,
      border: `0.5px solid rgba(0, 0, 0, 0.08)`,
      "& img": {
        height: "24px",
        width: "24px",
      },
    },
    moreAvatar: {
      width: "24px",
      height: "24px",
      fontSize: "12px",
      lineHeight: "18px",
      fontWeight: "600",
      backgroundColor: theme.palette.background.tertiary,
      color: theme.palette.text.text_quarterary,
      border: `0.5px solid rgba(0, 0, 0, 0.08)`,
    },
    AvatarStyle: {
      width: "24px",
      height: "24px",
    },
    Creator_Task_Container: {
      display: "flex",
      flexDirection: "row",
      gap: "12px",
    },
    CreatorTitle: {
      color: theme.palette.text.secondary,
      fontWeight: 400,
      lineHeight: "20px",
      fontSize: "14px",
    },
    CreatorValue: {
      color: theme.palette.text.tertiary,
      fontWeight: 400,
      lineHeight: "20px",
      fontSize: "14px",
    },
    IsWithApproval: {
      color: theme.palette.text.primary,
      fontWeight: 500,
      lineHeight: "20px",
      fontSize: "14px",
    },
    container_Assignto: {
      display: "flex",
      flexDirection: "column",
      gap: "7px",
    },
    divider: {
      backgroundColor: theme.palette.border.disabled_subtle,
      width: "100%",
      height: "1px",
    },
    Assigned_toValue: {
      fontWeight: 600,
      lineHeight: "24px",
      fontSize: "14px",
      color: theme.palette.text.primary,
    },
    containerAreaDescription: {
      backgroundColor: theme.palette.background.overlay,
    },
    customArrow: {
      color: theme.palette.background.overlay,
    },
  };
});
