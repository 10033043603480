import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => {
  return {
    contactOverViewCard: {
      padding: "24px",
    },
    activityCardTitleWrapper: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      borderBottom: `1px solid ${theme.palette.border.secondary}`,
      paddingBottom: "20px",
    },
    titleContainer: {
      display: "flex",
      flexWrap: "wrap",
      gap: "8px ",
      alignItems: "center",
      [theme.breakpoints.down("sm")]: {
        minWidth: "60px",
      },
    },
    timelineContainer: {
      marginInlineStart: "3px",
      marginTop: "16px",
    },
    timeLine: {
      padding: 0,
    },
    missingOppositeContent: {
      "&:before": {
        flex: 0,
        padding: 0,
      },
    },
    timelineSeparator: {
      position: "relative",
      paddingRight: "6px",
      paddingLeft: "6px",
      zIndex: 2,
    },
    timelineDot: {
      margin: 0,
      position: "absolute",
      top: "9px",
      left: "1.4px",
      background: theme.palette.skeleton.original,
      "&::after": {
        content: '""',
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "20px",
        height: "20px",
        borderRadius: "50%",
        background: theme.palette.skeleton.light,
        zIndex: -1,
      },
    },
    timelineConnector: {
      zIndex: -2,
      background: theme.palette.border.tertiary,
    },
    timelineContent: {
      padding: 0,
      marginInlineStart: "3px",
    },
    timelineContainer: {
      marginTop: "16px",
    },
    timelineContentWrapper: {
      paddingInlineStart: "20px",
      paddingBottom: "24px",
      display: "flex",
      justifyContent: "space-between",
      [theme.breakpoints.down("xs")]: {
        flexDirection: "column",
        gap: "20px",
      },
    },
    infoSection: {
      display: "flex",
      flexDirection: "column",
      minWidth: "200px",
    },
    infoItem: {
      fontSize: "14px",
      fontWeight: 400,
      lineHeight: "20px",
      display: "flex",
      alignItems: "center",
      gap: "8px",
      width: "100%",
    },
    infoValue: {
      fontWeight: 600,
    },
    open: {
      color: theme.palette.text.success_primary,
      fontWeight: 500,
      lineHeight: "20px",
      fontSize: "14px",
    },
    close: {
      color: theme.palette.text.error_primary,
      fontWeight: 500,
      lineHeight: "20px",
      fontSize: "14px",
    },
    dateSection: {
      display: "flex",
      gap: "10px",
    },
    dateAndTime: {
      display: "flex",
      gap: "10px",
      color: theme.palette.text.text_quarterary,
    },
    dateDetails: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-end",
      width: "160px",
      [theme.breakpoints.down("xs")]: {
        alignItems: "flex-start",
      },
    },
    date: {
      fontSize: "14px",
      fontWeight: 500,
    },
    cornerUpLeftIcon: {
      marginTop: "18px",
    },
    icon: {
      borderRadius: "4px",
    },
  };
});
