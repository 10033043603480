import {
  DescriptionsView,
  PublishedImages
} from '../Sections';
import { LeadOwnerUnitSalePermissions   } from  '../../../../../../../Permissions' ; 
import { WebPortal } from '../../../../../UnitsSalesView/UnitsSalesProfileManagement/Sections/Marketing/Sections/WebPortal'

export const UnitProfileMarketingTabsData = [
  { label: 'descriptions', component: DescriptionsView },
  { 
    label: 'web-portal',
    component: WebPortal , 
    permissionsList: Object.values(LeadOwnerUnitSalePermissions),
    permissionsId: LeadOwnerUnitSalePermissions.ShowWebPortals.permissionsId,
  },
  { label: 'published-images', component: PublishedImages },
];




