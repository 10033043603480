import { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";

export const useScrollToElement = (isReadyToLoad) => {
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    const hash = location.hash;

    if (!hash || !isReadyToLoad) {
      console.warn("there is no hash or the list still loading");
      return;
    }

    const elementId = hash.split("#")?.[1];

    if (!elementId) {
      console.warn("the element id doesn't exist");
      return;
    }

    const element = document.getElementById(elementId);

    if (!element) {
      console.warn("the element doesn't exist");
      return;
    }

    element.scrollIntoView({ behavior: "smooth", block: "center" });
  }, [location, isReadyToLoad]);

  const setHash = (hashId) => {
    history.push(`${window.location.pathname}#${hashId}`);
  };

  return {
    setHash,
  };
};
