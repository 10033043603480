import {CampaignTasksView} from '../CampaignTasks/CampaignTasksView';
import {CampaignTeamView} from '../CampaignTeam/CampaignTeamView';
import {CampaignAttachedFilesView} from '../CampaignAttachedFiles/CampaignAttachedFilesView';
import { ViewRequestForm } from '../ViewRequestForm/ViewRequestForm';

export const MarketingApprovalTabsData = [
  {
    label: 'overview',
    component: ViewRequestForm,
    permissionsList: [],
    permissionsId: null,
  },
  {
    label: 'team',
    component: CampaignTeamView,
    permissionsList: [],
    permissionsId: null,
  },
  {
    label: 'tasks',
    component: CampaignTasksView,
    permissionsList: [],
    permissionsId: null,
  },
  {
    label: 'attached-files',
    component: CampaignAttachedFilesView,
    permissionsList: [],
    permissionsId: null,
  },
];
