import React, { useCallback, useEffect, useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  ButtonBase,
} from '@material-ui/core';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import {
  DialogComponent,
  PaginationComponent,
  PopoverComponent,
  Spinner,
  Tables,
} from '../../../../../../Components';
import { GetAllDFMTransactionForActivityType } from '../../../../../../Services';
import { MoreHoriz } from '@material-ui/icons';

export const ActivityTypeHistoryDialog = ({
  id,
  isOpen,
  isOpenChanged,
  parentTranslationPath,
  translationPath,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [activeItem, setactiveItem] = useState(null);
  const [portalBtn, setPortalBtn] = useState(null);
  const [portalBtn2, setPortalBtn2] = useState(null);

  const { t } = useTranslation(parentTranslationPath);
  const [expanded, setExpanded] = useState(false);
  const [statusHistory, setStatusHistory] = useState({
    result: [],
    totalCount: 0,
  });
  const [changeFields, setChangeFields] = useState([]);

  const [filter, setFilter] = useState({
    pageIndex: 0,
    pageSize: 25,
  });
  const onPageIndexChanged = (pageIndex) => {
    setFilter((item) => ({ ...item, pageIndex }));
  };
  const onPageSizeChanged = (pageSize) => {
    setFilter((item) => ({ ...item, pageIndex: 0, pageSize }));
  };
  const GetAllDFMTransactionForActivityTypeAPI = useCallback(async () => {
    setIsLoading(true);
    const res = await GetAllDFMTransactionForActivityType({ ...filter }, id);
    if (!(res && res.status && res.status !== 200)) {
      setStatusHistory({
        result: (res && res.result) || [],
        totalCount: (res && res.totalCount) || 0,
      });
    } else {
      setStatusHistory({
        result: [],
        totalCount: 0,
      });
    }
    setIsLoading(false);
  }, [filter, id]);
  useEffect(() => {
    if (id) GetAllDFMTransactionForActivityTypeAPI(id);
  }, [GetAllDFMTransactionForActivityTypeAPI, filter, id]);

  const findRequestType = (requestType) => requestType;

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const contactFieldChangeMapper = useCallback((itemValue) => {
    setactiveItem(itemValue.dfMTransactionId);
    const changes = [];
    const beforeChangeArr = JSON.parse(itemValue.response);
    const afterChangeArr = JSON.parse(itemValue.responseAfterChanged);

    if (beforeChangeArr !== null) changes.push(beforeChangeArr);
    if (afterChangeArr !== null) changes.push(afterChangeArr);

    setChangeFields(() => changes.length > 0 && changes);
  }, []);
  const handleClose = () => {
    setPortalBtn(null);
    setPortalBtn2(null);
  };
  return (
    <DialogComponent
      titleText='status-history'
      dialogContent={
        <div className='history-component-content-wrapper w-100 mt-2'>
          <div className='history-table-conent-wrapper history-dialog-wrapper d-flex-column-center w-100 transaction-history-wrapper'>
            <Spinner isActive={isLoading} />
            <div className='w-100 history-table-header'>
              <span>{t(`${translationPath}createdByName`)}</span>
              <span>{t(`${translationPath}createdOn`)}</span>
            </div>
            {statusHistory &&
              statusHistory.result &&
              statusHistory.result.map((item, index) => (
                <Accordion
                  key={`${index + 1}-history`}
                  className={`expand-history-icon ${
                    index % 2 === 0 ? 'is-gray' : ''
                  }`}
                  expanded={expanded && activeItem === item.dfMTransactionId}
                  onChange={handleChange(item.dfMTransactionId)}
                >
                  <AccordionSummary
                    onClick={() => item && contactFieldChangeMapper(item)}
                  >
                    <div
                      className={`history-table-content w-100 ${
                        index % 2 === 0 ? 'is-gray' : ''
                      }`}
                    >
                      <div
                        className={`history-expand-icon ${
                          (item.ActivityTypeId &&
                            findRequestType(item.ActivityTypeId)) === 'Update'
                            ? ''
                            : 'is-gray'
                        } `}
                      >
                        <span
                          className={`mdi mdi-chevron-${
                            expanded === item.ActivityTypeId &&
                            (item.ActivityTypeId &&
                              findRequestType(item.ActivityTypeId)) === 'Update'
                              ? 'up'
                              : 'down'
                          }`}
                        />
                      </div>

                      <span>
                        {(item.createdByName && item.createdByName) || 'N/A'}
                      </span>
                      <span>
                        {(item.createdOn &&
                          moment(item.createdOn).format(
                            'DD/MM/YYYY - hh:mm A'
                          )) ||
                          'N/A'}
                      </span>

                      {/* <span>
                        {(item.requestType && findRequestType(item.requestType)) || 'N/A'}
                      </span> */}
                    </div>
                  </AccordionSummary>
                  {item.responseAfterChanged && (
                    <AccordionDetails>
                      <Tables
                        data={changeFields || []}
                        headerData={[
                          {
                            id: 2,
                            label: 'CategoryName',
                            input: 'CategoryName',
                          },
                          {
                            id: 3,
                            label: 'ActivityType',
                            input: 'ActivityTypeName',
                          },
                          {
                            id: 6,
                            label: 'UpdateOn',
                            component: (el) => (
                              <span>
                                {(el &&
                                  el.UpdateOn &&
                                  moment(el && el.UpdateOn).format(
                                    'DD/MM/YYYY - hh:mm '
                                  )) ||
                                  ''}
                              </span>
                            ),
                          },
                          {
                            id: 7,
                            label: 'LeadStage',
                            input: 'LeadStageName',
                          },
                          {
                            id: 8,
                            label: 'LeadQualification',
                            input: 'LeadQualificationName',
                          },
                          {
                            id: 9,
                            label: 'WithReminder',
                            component: (el) => (
                              <span>
                                {(el &&
                                  el.WithReminder &&
                                  t(`${translationPath}Yes`)) ||
                                  t(`${translationPath}No`)}
                              </span>
                            ),
                          },
                          {
                            id: 10,
                            label: 'WithDateTime',
                            component: (el) => (
                              <span>
                                {(el &&
                                  el.WithDateTime &&
                                  t(`${translationPath}Yes`)) ||
                                  t(`${translationPath}No`)}
                              </span>
                            ),
                          },
                          {
                            id: 11,
                            label: 'WithDuration',
                            component: (el) => (
                              <span>
                                {(el &&
                                  el.WithDuration &&
                                  t(`${translationPath}Yes`)) ||
                                  t(`${translationPath}No`)}
                              </span>
                            ),
                          },
                          {
                            id: 12,
                            label: 'ActivityRate',
                            input: 'ActivityRateName',
                          },
                          {
                            id: 13,
                            label: 'IsForMobile',
                            component: (el) => (
                              <span>
                                {(el &&
                                  el.IsForMobile &&
                                  t(`${translationPath}Yes`)) ||
                                  t(`${translationPath}No`)}
                              </span>
                            ),
                          },
                          {
                            id: 18,
                            label: 'SLAExpirationPeriod',
                            input: 'SLAExpirationPeriod',
                          },
                          {
                            id: 19,
                            label: 'SLAExpirationPeriodFreqency',
                            input: 'SLAExpirationPeriodFreqency',
                          },
                          {
                            id: 20,
                            label: 'SLAExpirationPeriodComparisonDate',
                            component: (el) => (
                              <span>
                                {(el && el.SLAExpirationPeriodComparisonDate) ||
                                  ' '}
                              </span>
                            ),
                          },
                          {
                            id: 21,
                            label: 'SLAAppliedFor',
                            component: (el) => (
                              <span>{(el && el.SLAAppliedFor) || ' '}</span>
                            ),
                          },
                          {
                            id: 22,
                            label: 'ExpiredPeriod',
                            component: (el) => (
                              <span>{(el && el.ExpiredPeriod) || ' '}</span>
                            ),
                          },
                          {
                            id: 27,
                            label: 'FollowUpRequired',
                            component: (el) => (
                              <span>
                                {(el &&
                                  el.FollowUpRequired &&
                                  t(`${translationPath}Yes`)) ||
                                  t(`${translationPath}No`)}
                              </span>
                            ),
                          },
                          {
                            id: 29,
                            label: 'IsImportant',
                            component: (el) => (
                              <span>
                                {(el &&
                                  el.IsImportant &&
                                  t(`${translationPath}Yes`)) ||
                                  t(`${translationPath}No`)}
                              </span>
                            ),
                          },
                          {
                            id: 30,
                            label: 'RelatedTo',
                            component: (data) => (
                              <div>
                                {data &&
                                  data.RelatedTo.map(
                                    (el) =>
                                      `${(el && el.RelatedToName) || ' '}, `
                                  )}
                              </div>
                            ),
                          },
                          {
                            id: 31,
                            label: 'CreateActivityTypeRoles',
                            component: (data) => (
                              <div>
                                {data?.CreateActivityTypeRoles.length > 2 ? (
                                  <>
                                    {data?.CreateActivityTypeRoles?.slice(
                                      0,
                                      2
                                    ).map(
                                      (el) => `${(el && el.RoleName) || ' '}, `
                                    )}
                                    <ButtonBase
                                      onClick={(e) => setPortalBtn(e.target)}
                                    >
                                      <MoreHoriz color='primary' />
                                    </ButtonBase>
                                    <PopoverComponent
                                      idRef='CreateActivityTypeRoles'
                                      handleClose={handleClose}
                                      attachedWith={portalBtn}
                                      component={
                                        <div className='p-1'>
                                          {data?.CreateActivityTypeRoles?.map(
                                            (el) =>
                                              `${(el && el.RoleName) || ' '}, `
                                          )}
                                        </div>
                                      }
                                    />
                                  </>
                                ) : (
                                  data?.CreateActivityTypeRoles?.map(
                                    (el) => `${(el && el.RoleName) || ' '}, `
                                  )
                                )}
                              </div>
                            ),
                          },
                          {
                            id: 32,
                            label: 'AssignedToActivityTypeRoles',
                            component: (data) => (
                              <div>
                                {data?.AssignedToActivityTypeRoles.length >
                                2 ? (
                                  <>
                                    {data?.AssignedToActivityTypeRoles.slice(
                                      0,
                                      2
                                    ).map(
                                      (el) => `${(el && el.RoleName) || ' '}, `
                                    )}
                                    <ButtonBase
                                      onClick={(e) => setPortalBtn2(e.target)}
                                    >
                                      <MoreHoriz color='primary' />
                                    </ButtonBase>
                                    <PopoverComponent
                                      idRef='AssignedToActivityTypeRoles'
                                      handleClose={handleClose}
                                      attachedWith={portalBtn2}
                                      component={
                                        <div className='p-1'>
                                          {data &&
                                            data.AssignedToActivityTypeRoles.map(
                                              (el) =>
                                                `${
                                                  (el && el.RoleName) || ' '
                                                }, `
                                            )}
                                        </div>
                                      }
                                    />
                                  </>
                                ) : (
                                  data &&
                                  data.AssignedToActivityTypeRoles.map(
                                    (el) => `${(el && el.RoleName) || ' '}, `
                                  )
                                )}
                              </div>
                            ),
                          },
                          {
                            id: 33,
                            label: 'ActivityTypeRotationRoles',
                            component: (data) => (
                              <div>
                                {data &&
                                  data.ActivityTypeRotationRoles.map(
                                    (el) => `${(el && el.RolesName) || ' '}, `
                                  )}
                              </div>
                            ),
                          },
                          {
                            id: 34,
                            label: 'ActivityTypeRotationUsers',
                            component: (data) => (
                              <div>
                                {data &&
                                  data.ActivityTypeRotationUsers.map(
                                    (el) => `${(el && el.UsersName) || ' '}, `
                                  )}
                              </div>
                            ),
                          },
                        ]}
                        activePage={1}
                        defaultActions={[]}
                        translationPath={translationPath}
                        parentTranslationPath={parentTranslationPath}
                      />
                    </AccordionDetails>
                  )}
                </Accordion>
              ))}
          </div>
          <div className='pagination-history-wrapper'>
            <PaginationComponent
              pageIndex={filter.pageIndex}
              pageSize={filter.pageSize}
              totalCount={statusHistory.totalCount || 0}
              onPageIndexChanged={onPageIndexChanged}
              onPageSizeChanged={onPageSizeChanged}
            />
          </div>
        </div>
      }
      isOpen={isOpen}
      onCloseClicked={isOpenChanged}
      parentTranslationPath={parentTranslationPath}
      translationPath={translationPath}
    />
  );
};

ActivityTypeHistoryDialog.defaultProps = {
  id: undefined,
};
