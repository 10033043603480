


import React , { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { CheckboxesComponent , TabsComponent } from '../../../../../../../Components' ; 
import { UnitsPoliciesTabs  } from './UnitsPoliciesTabs' ;  


export const UnitsPolicies  = ({
   parentTranslationPath,
   translationPath,
   state,
   setState , 
   checkAtLeastOneSelected ,
   checkAtLeastOneSelected2 ,  
   setDefaultSelected , 
   
}) => {
    const { t } = useTranslation(parentTranslationPath);
    const [activeTab, setActiveTab] = useState(0);

    const onTabChanged = (e, newTap) => {
      setActiveTab(newTap);
    };

 
  return (
    <div className='childs-wrapper b-0 part'>
         <TabsComponent
          data={UnitsPoliciesTabs}
          labelInput='label'
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          themeClasses='theme-curved'
          dynamicComponentProps={{
          parentTranslationPath,
          translationPath,
           state,
           setState , 
           checkAtLeastOneSelected ,
           checkAtLeastOneSelected2 , 
           setDefaultSelected, 
        }}
        currentTab={activeTab}
        onTabChanged={onTabChanged}
      /> 
      <div className='w-100 px-2 mt-2'>
           <CheckboxesComponent
            idRef='units_view-sensitive-dataRef'
            label={t(`${translationPath}view-sensitive-data`)}
            singleChecked={state.units.viewSensitive}
            parentTranslationPath={parentTranslationPath}
            translationPathForData={translationPath}
            onSelectedCheckboxClicked={()=> {
            setState({id : 'units' , value : { ...state.units , viewSensitive : !(state.units.viewSensitive)} })
          }}
         />
      </div> 
     </div>  
  );
};

UnitsPolicies.propTypes = {
  parentTranslationPath: PropTypes.string.isRequired,
  translationPath: PropTypes.string.isRequired,
  state:PropTypes.instanceOf(Object).isRequired,
  setState : PropTypes.func.isRequired,
  checkAtLeastOneSelected  : PropTypes.func.isRequired, 
  checkAtLeastOneSelected2 :PropTypes.func.isRequired, 
  setDefaultSelected:PropTypes.func.isRequired, 
};

