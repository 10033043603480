import React, { useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Inputs, RadiosGroupComponent, SwitchComponent } from "../../../../../../../Components";
import { getErrorByName } from "../../../../../../../Helper";

export const FinancialSection = ({
  isSubmitted,
  schema,
  isReadOnly,
  selected,
  onSelectedChanged,
  parentTranslationPath,
  translationPath,
}) => {
  const { t } = useTranslation(parentTranslationPath);
  const [isFieldShown, setIsFieldShown] = useState({
    externalAgencyFee: false,
  });

  return (
    <>
      <div className="d-flex fj-start b-bottom">
        <div className="w-33 mt-2 pt-1">
          <span className="fw-simi-bold">{t(`${translationPath}Rent-per-year`)}</span>
        </div>
        <div className="w-50 mt-2 mb-2 pt-1">
          <Inputs
            withNumberFormat
            idRef="rentPerYearRef"
            inputPlaceholder={`1,000,00`}
            value={selected.rentPerYear}
            helperText={getErrorByName(schema, 'rentPerYear').message}
            error={getErrorByName(schema, 'rentPerYear').error}
            endAdornment={<span className="px-2">AED</span>}
            type="number"
            min={0}
            isWithError
            isSubmitted={isSubmitted}
            parentTranslationPath={parentTranslationPath}
            translationPath={translationPath}
            onInputChanged={(e) => {
              const value = e?.target?.value ? (e.target.value) : 0;
              onSelectedChanged({ id: 'rentPerYear', value });
            }}
            isDisabled={isReadOnly}
          />
        </div>
      </div>
      <div className="d-flex fj-start b-bottom">
        <div className="w-33 mt-2 pt-1">
          <span className="fw-simi-bold">{t(`${translationPath}Contract-rent`)}</span>
        </div>
        <div className="w-50 mt-2 mb-2 pt-1">
          <Inputs
            withNumberFormat
            idRef="contractRentRef"
            inputPlaceholder={`1,000,00`}
            value={selected.contractRent}
            endAdornment={<span className="px-2">AED</span>}
            type="number"
            min={0}
            isWithError
            parentTranslationPath={parentTranslationPath}
            translationPath={translationPath}
            onInputChanged={(e) => {
              const value = e?.target?.value ? (e.target.value) : 0;
              onSelectedChanged({ id: 'contractRent', value });
            }}
            isDisabled={isReadOnly}
          />
        </div>
      </div>
      <div className="d-flex fj-start b-bottom">
        <div className="w-33 mt-2 pt-1">
          <span className="fw-simi-bold">
            {t(`${translationPath}Security-deposit`)}
          </span>
        </div>
        <div className="w-50 mt-2 mb-2 pt-1">
          <Inputs
            idRef="securityDepositRef"
            inputPlaceholder={`1,000,00`}
            withNumberFormat
            value={selected.securityDeposit}
            endAdornment={<span className="px-2">AED</span>}
            type="number"
            min={0}
            isWithError
            parentTranslationPath={parentTranslationPath}
            translationPath={translationPath}
            onInputChanged={(e) => {
              const value = e?.target?.value ? (e.target.value) : 0;
              onSelectedChanged({ id: "securityDeposit", value });
            }}
            isDisabled={isReadOnly}
          />
        </div>
      </div>
      <div className="d-flex fj-start b-bottom">
        <div className="w-33 mt-1 pt-1">
          <span className="fw-simi-bold">{t(`${translationPath}Renewal-fee`)}</span>
        </div>
        <div className="w-50 mt-2 mb-2 pt-1">
          <Inputs
            idRef="renewalFeeRef"
            inputPlaceholder={`1,000,00`}
            withNumberFormat
            value={selected.renewalFee}
            endAdornment={<span className="px-2">AED</span>}
            type="number"
            min={0}
            isWithError
            parentTranslationPath={parentTranslationPath}
            translationPath={translationPath}
            onInputChanged={(e) => {
              const value = e?.target?.value ? (e.target.value) : 0;
              onSelectedChanged({ id: 'renewalFee', value });
            }}
            isDisabled={isReadOnly}
          />
        </div>
      </div>
      <div className="d-flex fj-start b-bottom">
        <div className="w-33 mt-2 pt-1">
          <span className="fw-simi-bold">{t(`${translationPath}Agency-fee`)}</span>
        </div>
        <div className="w-50 mt-1 mb-2 pt-1">
          <div className="d-flex-column gap-12">
            <Inputs
              isAttachedInput //????????
              withNumberFormat
              idRef="agencyFeeLandlordRef"
              labelValue="Landlord-Agency-fee"
              inputPlaceholder={`1,000,00`}
              labelClasses="px-0"
              value={selected.agencyFeeLandlord}
              endAdornment={<span className="px-2 inner-span">AED</span>}
              type="number"
              min={0}
              max={(selected.rentPerYear)}
              isWithError
              isDisabled={isReadOnly}
              parentTranslationPath={parentTranslationPath}
              translationPath={translationPath}
              onKeyUp={(e) => {
                const value = e?.target?.value ? (e.target.value) : 0;
                if (Number.isNaN(value))
                  value = 0;
                const fixed = (value && value.replace(/,/g, ''));
                let parsedValue = fixed ? parseFloat(fixed) : 0;
                if (parsedValue > selected.rentPerYear) parsedValue = selected.rentPerYear;
                let effectedValue = selected.rentPerYear ? ((parsedValue / selected.rentPerYear) * 100) : 0;
                const netAmountLandLord = parsedValue - selected.externalAgencyFeeLandlord;
                onSelectedChanged({
                  id: 'edit',
                  value: {
                    ...selected,
                    agencyFeeLandlord: selected.rentPerYear ? parsedValue : 0,
                    agencyFeeLandlordPercentage: effectedValue,
                    netAmountLandLord
                  },
                });
              }}
            />
            <Inputs
              idRef="agencyFeeTenantRef"
              labelValue="Tenant-agency-fee"
              labelClasses="px-0"
              inputPlaceholder={`1,000,00`}
              isAttachedInput //??????????
              withNumberFormat
              value={selected.agencyFeeTenant}
              endAdornment={<span className="px-2 inner-span">AED</span>}
              type="number"
              min={0}
              max={selected.rentPerYear}
              isWithError
              isDisabled={isReadOnly}
              parentTranslationPath={parentTranslationPath}
              translationPath={translationPath}
              onKeyUp={(e) => {
                const value = e?.target?.value ? (e.target.value) : 0;
                if (Number.isNaN(value))
                  value = 0;
                const fixed = (value && value.replace(/,/g, ''));
                let parsedValue = fixed ? parseFloat(fixed) : 0;
                const effectedByValue = (selected.rentPerYear);
                if (parsedValue > effectedByValue) parsedValue = effectedByValue;
                let effectedValue = effectedByValue ? ((parsedValue / effectedByValue) * 100) : 0;
                const netAmountTenant = parsedValue - selected.externalAgencyFeeTenant;
                onSelectedChanged({
                  id: 'edit',
                  value: {
                    ...selected,
                    agencyFeeTenant: parsedValue,
                    agencyFeeTenantPercentage: effectedValue,
                    netAmountTenant
                  },
                });
              }}
            />
          </div>
        </div>
      </div>
      <div className="d-flex fj-start b-bottom">
        <div className="w-33 mt-2 pt-1">
          <span className="fw-simi-bold">{t(`${translationPath}External-agency-fee`)}</span>
        </div>
        <div className="w-50 mt-0 mb-2">
          <SwitchComponent
            idRef="Internal-referral-landlordRef"
            themeClass="thick-theme"
            wrapperClasses="mb-3"
            isChecked={isFieldShown.externalAgencyFee || false}
            onChangeHandler={(_, isChecked) => {
              setIsFieldShown(prevState => ({
                ...prevState,
                externalAgencyFee: isChecked,
              }))
            }}
          />
          {isFieldShown.externalAgencyFee &&
            <div className="d-flex-column gap-12">
              <Inputs
                idRef='externalAgencyFeeLandlordRef'
                labelValue='Landlord-External-Agency-Fee'
                labelClasses='px-0'
                inputPlaceholder={`1,000,00`}
                isAttachedInput
                withNumberFormat
                value={selected.externalAgencyFeeLandlord}
                endAdornment={(
                  <span className='px-2 inner-span'>AED</span>
                )}
                type='number'
                min={0}
                max={(selected.rentPerYear)}
                isWithError
                isDisabled={isReadOnly}
                parentTranslationPath={parentTranslationPath}
                translationPath={translationPath}
                onKeyUp={(e) => {
                  const value = e?.target?.value ? (e.target.value) : 0;
                  if (Number.isNaN(value))
                    value = 0;
                  const fixed = (value && value.replace(/,/g, ''));
                  let parsedValue = fixed ? parseFloat(fixed) : 0;

                  if (parsedValue > selected.rentPerYear) parsedValue = selected.rentPerYear;
                  const effectedValue = selected.rentPerYear ? ((parsedValue / selected.rentPerYear) * 100) : 0;

                  const netAmountLandLord = selected.agencyFeeLandlord - parsedValue;

                  onSelectedChanged({
                    id: 'edit',
                    value: {
                      ...selected,
                      externalAgencyFeeLandlord: selected.rentPerYear ? parsedValue : 0,
                      externalAgencyFeeLandlordPercentage: effectedValue,
                      netAmountLandLord
                    },
                  });
                }}
              />
              <Inputs
                idRef='externalAgencyFeeTenantRef'
                labelValue='Tenant-External-Agency-Fee'
                labelClasses='px-0'
                inputPlaceholder={`1,000,00`}
                isAttachedInput
                withNumberFormat
                value={selected.externalAgencyFeeTenant}
                endAdornment={(
                  <span className='px-2 inner-span'>AED</span>
                )}
                type='number'
                min={0}
                max={(selected.rentPerYear)}
                isWithError
                isDisabled={isReadOnly}

                // isSubmitted={isSubmitted}
                parentTranslationPath={parentTranslationPath}
                translationPath={translationPath}
                onKeyUp={(e) => {
                  const value = e?.target?.value ? (e.target.value) : 0;
                  if (Number.isNaN(value))
                    value = 0;
                  const fixed = (value && value.replace(/,/g, ''));
                  let parsedValue = fixed ? parseFloat(fixed) : 0;

                  const effectedByValue = (selected.rentPerYear);
                  if (parsedValue > effectedByValue) parsedValue = effectedByValue;
                  let effectedValue = effectedByValue ? ((parsedValue / effectedByValue) * 100) : 0;

                  const netAmountTenant = selected.agencyFeeTenant - parsedValue;

                  onSelectedChanged({
                    id: 'edit',
                    value: {
                      ...selected,
                      externalAgencyFeeTenant: parsedValue,
                      externalAgencyFeeTenantPercentage: effectedValue,
                      netAmountTenant
                    },
                  });
                }}
              />
              <Inputs
                idRef='netAmountLandLordRef'
                labelValue='Landlord-Net-Amount'
                labelClasses='px-0'
                inputPlaceholder={`1,000,00`}
                value={selected.netAmountLandLord}
                endAdornment={<span className='px-2'>AED</span>}
                // isSubmitted={isSubmitted}
                type='number'
                min={0}
                isDisabled
                withNumberFormat
                isWithError
                parentTranslationPath={parentTranslationPath}
                translationPath={translationPath}
              />
              <Inputs
                idRef='netAmountTenantRef'
                labelValue='Tenant-Net-Amount'
                labelClasses='px-0'
                inputPlaceholder={`1,000,00`}
                value={selected.netAmountTenant}
                endAdornment={<span className='px-2'>AED</span>}
                type='number'
                min={0}
                // isSubmitted={isSubmitted}
                isDisabled
                withNumberFormat
                parentTranslationPath={parentTranslationPath}
                translationPath={translationPath}
              />
            </div>}
        </div>
      </div>
      <div className="d-flex fj-start b-bottom">
        <div className="w-33 mt-2 pt-1">
          <span className="fw-simi-bold">
            {t(`${translationPath}Is-payment-to-external-agency?`)}
          </span>
        </div>
        <div className="w-50  mt-0 mb-2 pt-1">
          <RadiosGroupComponent
            idRef="paymentToExternalAgencyRef"
            themeClass="theme-default theme-propx"
            data={[
              {
                key: true,
                value: "yes",
              },
              {
                key: false,
                value: "no",
              },
            ]}
            value={selected.paymentToExternalAgency}
            parentTranslationPath={parentTranslationPath}
            translationPathForData={translationPath}
            translationPath={translationPath}
            labelInput="value"
            valueInput="key"
            isDisabled={isReadOnly}
            onSelectedRadioChanged={(_, newValue) => onSelectedChanged({ id: 'paymentToExternalAgency', value: newValue === 'true' })}
          />
        </div>
      </div>
    </>
  );
};

FinancialSection.propTypes = {
  schema: PropTypes.object,
  isSubmitted: PropTypes.bool,
}
FinancialSection.defaultProps = {
  schema: {},
  isSubmitted: false,
}