import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  tabButtonContainer: {
    display: "flex",
    gap: "4px",
  },
  notificationCounter: {
    fontFamily: "Inter",
    alignItems: "center",
    alignSelf: "center",
    background: theme.palette.background.brand_50,
    border: `1px solid ${theme.palette.border.brand_200}`,
    borderRadius: "50%",
    color: theme.palette.text.brand_700,
    display: "flex",
    fontSize: "12px",
    height: "22px",
    justifyContent: "center",
    width: "24px",
  },
  inactiveNotificationCounter: {
    fontFamily: "Inter",
    alignItems: "center",
    alignSelf: "center",
    background: theme.palette.background.gray_50,
    border: `1px solid ${theme.palette.border.gray_200}`,
    borderRadius: "50%",
    color: theme.palette.text.gray_700,
    display: "flex",
    fontSize: "12px",
    height: "22px",
    justifyContent: "center",
    width: "24px",
  },
  tabButton: {
    opacity: 1,
    color: theme.palette.text.text_quarterary,
    fontFamily: "Inter",
    display: "flex !important",
    flexDirection: "row !important",
    gap: "5px !important",
    width: "102px !important",
    minWidth: "99px",
    margin: 0,
    padding: 0,
    fontWeight: 600,
  },
  tabs: {
    padding: "0 24px",
    margin: "5px 0 0",
    borderBottom: `1px solid ${theme.palette.border.secondary}`,
  },
  tab: {
    color: theme.palette.text.brand_secondary,
    fontFamily: "Inter",
    fontWeight: 600,
    borderBottom: `2px solid ${theme.palette.border.brand_primary_alt}`,
  },
  tabLabel: {
    textTransform: "none",
  },
  font14: {
    fontFamily: "Inter",
    fontSize: "14px",
  },

  menuContainer: {
    width: "448px",
    maxHeight: "727px !important",
  },
  buttonStyle: {
    height: "44px",
    fontFamily: "Inter",
    padding: "10px 16px",
    fontSize: "16px",
    fontWeight: 600,
    borderRadius: "8px",
    lineHeight: "24px",
  },
  secondaryButtonStyle: {
    borderColor: theme.palette.border.main,
    color: theme.palette.text.secondary,
  },
  actions: {
    display: "flex",
    gap: "12px",
    padding: "24px",
    flexDirection: "row-reverse",
  },
  trinityColor: { color: theme.palette.text.tertiary },
  notificationBody: {
    backgroundColor: theme.palette.background.secondary_hover,
  },
  title: {
    fontFamily: "Inter",
    fontWeight: 500,
    fontSize: "14px",
    color: theme.palette.text.secondary,
  },
  textContainer: {
    display: "flex",
    gap: "4px",
  },
  divider: { backgroundColor: theme.palette.background.secondary },
}));
