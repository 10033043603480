import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  cardStyle: {
    padding: theme.spacing(3),
    flex: 1,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
    cursor: "pointer",
  },
  title: {
    marginBottom: theme.spacing(1),
    color: theme.palette.text.tertiary,
    fontSize: 16,
    fontWeight: 500,
    lineHeight: "24px",
  },
  subTitle: {
    fontSize: 36,
    fontWeight: 600,
    color: theme.palette.text.primary,
    lineHeight: "44px",
  },
}));
