export const TaskConfigurationRelatedTo = {
    Property: {
        key: 'Property',
        value: 1,
        labelValue: 'property'
    },
    Contact: {
        key: 'Contact',
        value: 2,
        labelValue: 'contact'
    },
    Unit: {
        key: 'Unit',
        value: 3,
        labelValue: 'reference-no'
    },
    Lead: {
        key: 'Lead',
        value: 4,
        labelValue: 'lead'
    },
}