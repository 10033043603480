import { PortalView } from '../../Views/Home/PortalView' ; 
export const PortalsRoutes = [
  {
    path: "/view",
    name: 'Portals:portals-configuration',
    component: PortalView,
    layout: "/home/portals-configuration",
    default: true,
    isRoute: true,
    authorize: true,
    roles: [],
    isDisabled: false,
    isExact: true,
    breadcrumbs: [
      {
        name: 'Portals:portals-configuration',
        isDisabled: true,
        route: "/home/portals-configuration/view",
        groupName: "system-configuration",
      },
    ],
  },
];
