import React from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";
import { Box, Avatar } from "@material-ui/core";
import {
  CustomCopyToClipboard,
  CustomIconButton,
  CustomTableHeader,
} from "../../../../../../Components";
import { getDownloadableLink } from "../../../../../../Helper";

// Icons
import { ArrowUpRight } from "../../../../../../assets/icons";

const classes = makeStyles((theme) => ({
  defaultAvatar: {
    backgroundColor: "#F2F4F7", // avatar background
    border: `1px solid ${theme.palette.border.secondary}`,
    height: "40px",
    width: "40px",
    color: theme.palette.foreground.quarterary,
    fontSize: "16px",
    fontWeight: 600,
    "& img": {
      height: "24px",
      width: "24px",
    },
  },
  avatar: {
    backgroundColor: "#F2F4F7", // avatar background
    border: `1px solid ${theme.palette.border.secondary}`,
    height: "40px",
    width: "40px",

    "& img": {
      objectFit: "contain",
    },
  },
  container: {
    display: "flex",
    gap: "12px",
    alignItems: "center",
  },
  detailsColumn: {
    display: "flex",
    flexDirection: "column",
    gap: "6px",
  },
  nameRow: {
    display: "flex",
    alignItems: "center",
    gap: "6px",
    cursor: "pointer",
  },
  nameText: (props) => ({
    lineHeight: "24px",
    fontSize: "16px",
    fontWeight: 500,
    color: props?.isDarkMode ? "#F5F5F6" : "#101828",
  }),
}));

const ContactName = ({ item }) => {
  const { isDarkMode } = useSelector((state) => state.theme);

  const styles = classes({ isDarkMode });

  const history = useHistory();

  const onContactPress = (item) => {
    history.push(
      `/home/Contacts-CRM/contact-profile-overview?formType=${
        item?.row?.contact_type_id ?? 1
      }&id=${item?.row?.relatedAssociatedContactsId}`
    );
  };

  const contactName = item?.row?.relatedContactName?.match(/\b(\w)/gm).join("")?.toUpperCase();

  return (
    <Box className={styles.container}>
      <Box>
        <Avatar
          className={
            item?.row?.relatedContactImageFileId
              ? styles.avatar
              : styles.defaultAvatar
          }
          src={getDownloadableLink(item?.row?.relatedContactImageFileId) ?? null}
        >
          {contactName.length === 1 ? contactName : contactName.charAt(0) + contactName.charAt(contactName.length - 1)}
        </Avatar>
      </Box>

      <Box className={styles.detailsColumn}>
        <Box className={styles.nameRow} onClick={() => onContactPress(item)}>
          <span className={styles.nameText}>{item?.row?.relatedContactName}</span>
          <CustomIconButton
            variant="text"
            size="xs"
            boxShadow="none"
            color="secondary"
          >
            <ArrowUpRight
              width="20"
              height="20"
              fill={!isDarkMode ? "#344054" : "#CECFD2"}
            />
          </CustomIconButton>
        </Box>
      </Box>
    </Box>
  );
};

export const associatedContactTableHeaderData = (isDarkMode = false) => [
  {
    id: 1,
    formFieldId: 1,
    minWidth: 292,
    headerName: "Contact name",
    field: "relatedContactName",
    fieldKey: "relatedContactName",
    displayPath: "relatedContactName",
    isHiddenFilter: true,
    sortable: false,
    filterable: false,
    fieldType: "text",
    isDefaultFilterColumn: true,
    disableColumnMenu: true,
    formFieldTitle: "relatedContactName",
    searchKey: "relatedContactName",
    renderHeader: (params) => (
      <CustomTableHeader params={params} title="Contact name" isHiddenFilter />
    ),
    renderCell: (item) => {
      return <ContactName item={item} />;
    },
  },
  {
    id: 2,
    formFieldId: 2,
    minWidth: 196,
    headerName: "Associated contact ID",
    isHiddenFilter: true,
    field: "relatedAssociatedContactsId",
    fieldType: "text",
    sortable: false,
    filterable: false,
    displayPath: "relatedAssociatedContactsId",
    isDefaultFilterColumn: true,
    disableColumnMenu: true,
    fieldKey: "relatedAssociatedContactsId",
    formFieldTitle: "Associated contact ID",
    renderHeader: (params) => (
      <CustomTableHeader
        params={params}
        title="Associated contact ID"
        isHiddenFilter
      />
    ),
    renderCell: (item) => {
      return (
        <CustomCopyToClipboard
          data={item?.row?.relatedAssociatedContactsId} // copy
          childrenData={"#" + item?.row?.relatedAssociatedContactsId} // render
          fontSize="14px"
        />
      );
    },
  },
  {
    id: 3,
    formFieldId: 3,
    headerName: "Relationship",
    width: 131,
    isHiddenFilter: true,
    field: "contactRelationshipName",
    fieldKey: "contactRelationshipName",
    displayPath: "contactRelationshipName",
    fieldType: "text",
    disableColumnMenu: true,
    sortable: false,
    filterable: false,
    isDefaultFilterColumn: true,
    formFieldTitle: "Relationship",
    renderHeader: (params) => (
      <CustomTableHeader params={params} title="Relationship" isHiddenFilter />
    ),
    renderCell: (item) => {
      return (
        <label style={{ lineHeight: "20px", fontWeight: 500 }}>
          {item?.row?.contactRelationshipName}
        </label>
      );
    },
  },
  {
    id: 4,
    formFieldId: 4,
    minWidth: 190,
    headerName: "Creation On",
    field: "createdOn",
    fieldKey: "createdOn",
    displayPath: "createdOn",
    fieldType: "text",
    isHiddenFilter: true,
    sortable: false,
    filterable: false,
    disableColumnMenu: true,
    isDefaultFilterColumn: true,
    formFieldTitle: "Creation On",
    renderHeader: (params) => (
      <CustomTableHeader params={params} title="Creation On" isHiddenFilter />
    ),
    renderCell: (item) => {
      return (
        <Box style={{ display: "flex", flexDirection: "column" }}>
          {item?.row?.createdOn && (
            <label style={{ lineHeight: "20px", fontWeight: 500 }}>
              {moment(item.row.createdOn).format("DD MMM YYYY")}
            </label>
          )}
          {item?.row?.createdOn && (
            <span
              style={{
                lineHeight: "20px",
                fontWeight: 400,
                color: !isDarkMode ? "#475467" : "#94969C",
              }}
            >
              {moment(item.row.createdOn).format("h:mm a")}
            </span>
          )}
        </Box>
      );
    },
  },
  {
    id: 5,
    formFieldId: 5,
    minWidth: 172,
    headerName: "Email",
    field: "relatedContactEmail",
    fieldKey: "relatedContactEmail",
    displayPath: "relatedContactEmail",
    isHiddenFilter: true,
    disableColumnMenu: true,
    fieldType: "text",
    isDate: true,
    sortable: false,
    filterable: false,
    isDefaultFilterColumn: true,
    formFieldTitle: "Email",
    renderHeader: (params) => (
      <CustomTableHeader params={params} title="Email" isHiddenFilter />
    ),
    renderCell: (item) => {
      return item?.row?.relatedContactEmail;
    },
  },
  {
    id: 6,
    formFieldId: 6,
    minWidth: 170,
    headerName: "Phone number",
    field: "relatedContactMobile",
    fieldKey: "relatedContactMobile",
    displayPath: "relatedContactMobile",
    isHiddenFilter: true,
    fieldType: "text",
    disableColumnMenu: true,
    isDate: true,
    sortable: false,
    filterable: false,
    isDefaultFilterColumn: true,
    formFieldTitle: "Phone number",
    renderHeader: (params) => (
      <CustomTableHeader params={params} title="Phone number" isHiddenFilter />
    ),
    renderCell: (item) => {
      return (
        <label style={{ fontWeight: 400 }}>
          {item?.row?.relatedContactMobile || "N/A"}
        </label>
      );
    },
  },
  {
    id: 7,
    formFieldId: 7,
    minWidth: 190,
    headerName: "Nationality",
    field: "relatedContactNationality",
    fieldKey: "relatedContactNationality",
    displayPath: "relatedContactNationality",
    isHiddenFilter: true,
    fieldType: "text",
    disableColumnMenu: true,
    isDate: true,
    sortable: false,
    filterable: false,
    isDefaultFilterColumn: true,
    formFieldTitle: "Nationality",
    renderHeader: (params) => (
      <CustomTableHeader params={params} title="Nationality" isHiddenFilter />
    ),
    renderCell: (item) => {
      return (
        <label style={{ fontWeight: 400 }}>
          {item?.row?.relatedContactNationality ?? "N/A"}
        </label>
      );
    },
  },
  {
    id: 8,
    formFieldId: 8,
    minWidth: 131,
    headerName: "Language",
    field: "relatedContactLanguage",
    fieldKey: "relatedContactLanguage",
    displayPath: "relatedContactLanguage",
    isHiddenFilter: true,
    fieldType: "text",
    disableColumnMenu: true,
    isDate: true,
    sortable: false,
    filterable: false,
    isDefaultFilterColumn: true,
    formFieldTitle: "Language",
    renderHeader: (params) => (
      <CustomTableHeader params={params} title="Language" isHiddenFilter />
    ),
    renderCell: (item) => {
      return (
        <label style={{ fontWeight: 400 }}>
          {item?.row?.relatedContactLanguage ?? "N/A"}
        </label>
      );
    },
  },
];
