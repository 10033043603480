import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => {
  return {
    Task_Contact_Wrapper: {
      display: "flex",
      flexDirection: "column",
      gap: "32px",
    },
    Task_Contact_Crads_Container: {
      display: "flex",
      flex: "1 1 100%",
      flexWrap: "wrap",
      gap: "8px",
      [theme.breakpoints.down('sm')]: {
        justifyContent: 'center' 
      }
    },
  };
});
