import React, { useCallback, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Spinner, Tables } from '../../../../Components';
import { TableActions } from '../../../../Enums';
import { GetClientSigmantationById } from '../../../../Services' ; 
import { GlobalHistory , returnPropsByPermissions } from '../../../../Helper' ; 
import {  ClientsSegmentationPermissions } from '../../../../Permissions' ;  
import { DeleteClientSegmentationDialog } from '../DeleteClientSegmentation/DeleteClientSegmentationDialog' ;


export const TableClientSegmentation = ({
  parentTranslationPath,
  translationPath,
  filter,
  data,
  onPageSizeChanged,
  onPageIndexChanged,
  setActiveItem,
  setFilter , 
  activeItem , 
}) => {

  const [isOpenDeleteDialog, setIsOpenDeleteDialog] = useState(false);
  const tableActionClicked = useCallback((actionEnum, item) => {
    setActiveItem(item);
      if (actionEnum === TableActions.runFile.key)
        window.open(`/share/ClientSegmentationRunView?clientSegmentationId=${item.clientSegmentationId}`, '', 'width=1400,height=1000');
      
      else if (actionEnum === TableActions.editText.key)
        GlobalHistory.push(`/home/clients-segmentation/edit?clientSegmentationId=${item.clientSegmentationId}`);
      
      else if (actionEnum === TableActions.deleteText.key)
              setIsOpenDeleteDialog(true) ;
       
  }, []);


  const getTableActionsByPermissions = () => {
    let list = [];
    if (returnPropsByPermissions(ClientsSegmentationPermissions.RunClientSegmentation.permissionsId)) {
      list.push({ enum: TableActions.runFile.key})    
    }
    if (returnPropsByPermissions(ClientsSegmentationPermissions.EditClientSegmentation.permissionsId)) {
      list.push({ enum: TableActions.editText.key });  
    }
    
      list.push({ enum: TableActions.deleteText.key });  
    
    return list;
  };

  const { t } = useTranslation([parentTranslationPath, 'Shared']);

  return (
    <div className='RotationSchemaTabelView '>
      <div className='filter-section-item' />
      <div className='w-100 px-2'>
        <Tables
          data={(data && data.result) || []}
          headerData={[
            {
              id: 1,
              input: 'clientSegmentationName',
              label: t(`${translationPath}clientSegmentationName`),
              isSortable: true,
              component: (item) => (
                <div>
                  {item && item.clientSegmentationName}
                </div>
              ),
            },
            {
              id: 2,
              input: 'owner',
              label: t(`${translationPath}owner`),
              isSortable: false,
              component: (item) => (
                <div>
                  {item && item.owner}
                </div>
              ),
            },
            {
              id: 3,
              input: 'description',
              label: t(`${translationPath}description`),
              isSortable: false,
              component: (item) => (
                <div>
                  {item && item.description}
                </div>
              ),
            },
          ]}
          defaultActions={getTableActionsByPermissions()}
          actionsOptions={{
            onActionClicked: tableActionClicked,
          }}
          onPageIndexChanged={onPageIndexChanged}
          onPageSizeChanged={onPageSizeChanged}
          itemsPerPage={filter.pageSize}
          activePage={filter.pageIndex}
          translationPath={translationPath}
          parentTranslationPath={parentTranslationPath}
          totalItems={(data && data.totalCount) || 0}
        />
      </div>
      {isOpenDeleteDialog && (
        <DeleteClientSegmentationDialog
          open={isOpenDeleteDialog}
          close={() => {
            setIsOpenDeleteDialog(false);
          }}
          onSave={() => {
            setIsOpenDeleteDialog(false);
            setFilter();
          }}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          activeItem={activeItem}
        />
      )}

    </div>
  );
};

TableClientSegmentation.propTypes = {
  parentTranslationPath: PropTypes.string.isRequired,
  translationPath: PropTypes.string.isRequired,
  filter: PropTypes.number.isRequired,
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  onPageIndexChanged: PropTypes.func.isRequired,
  onPageSizeChanged: PropTypes.func.isRequired,
};
