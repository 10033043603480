export function SensitiveFieldsForUserRule(itemList, sensitiveFields) {
  const result = sensitiveFields.map((sensitiveField) => itemList.findIndex((formField) => ((sensitiveField.key).replace('-', '_').replace('-', '_')) === (formField.field.id)));
  result.map((indexOfSensitive) => {
    if (indexOfSensitive === -1)
      return;

    if (indexOfSensitive !== -1)
      itemList[indexOfSensitive].data.isReadonly = true;
  });
}
