import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import { CustomBadge, CustomButton, CustomCard } from '../../../../../../Components';
import { useSelectedTheme } from '../../../../../../Hooks';

// Styles
import useStyles from './styles';

function OverviewCard({
  title,
  label,
  buttonText,
  children,
  hideActionButton,
  onActionClick,
}) {
  const styles = useStyles();

  const {
    theme: { palette },
  } = useSelectedTheme();

  return (
    <CustomCard
      boxShadow='xs'
      borderRadius='xl'
      borderColor='secondary'
      classes={styles.contactOverViewCard}
    >
      <Box className={styles.unitCardTitleWrapper}>
        <div className={styles.titleContainer}>
          <span className={styles.title}>{title}</span>
          {!!label && (
            <CustomBadge
              label={label}
              SizeVariant={'small'}
              BackgroundColor={palette.utility.brand_50}
              BorderColor={palette.utility.brand_200}
              Color={palette.utility.brand_700}
            />
          )}
        </div>
        {!hideActionButton && (
          <div>
            <CustomButton
              size='md'
              variant='text'
              color='tertiaryGray'
              onClick={onActionClick}
            >
              {buttonText}
            </CustomButton>
          </div>
        )}
      </Box>
      {children}
    </CustomCard>
  );
}

OverviewCard.propTypes = {
  title: PropTypes.string,
  label: PropTypes.string,
  buttonText: PropTypes.string,
  children: PropTypes.node,
  hideActionButton: PropTypes.bool,
  onActionClick: PropTypes.func,
};

OverviewCard.defaultProps = {
  title: 'Title',
  buttonText: 'View all',
  children: null,
  hideActionButton: false,
  onActionClick: () => {},
};

export default OverviewCard;
