
export const LeadOpportunitiesLogsPermissions = {
  ViewOpportunitiesLog: {
      permissionsId: "6ad52881-67ab-48af-7974-08dcaf947ea8",
      permissionsName: "View Opportunities Log",
      description: null,
      componentsId: null,
      components: null,
    },
  };
  