import React, { useState } from 'react';
import { TabsComponent } from '../../../Components';
import { tabsData } from './BillingTabs';

const parentTranslationPath = 'Billing';
const translationPath = '';

export const Billing = () => {
  const [activeTab, setActiveTab] = useState(0);
  const onTabChanged = (_, tabView) => {
    setActiveTab(tabView);
  };
  return (
    <div >
      <TabsComponent
        wrapperClasses='w-100'
        data={tabsData}
        labelInput='label'
        componentInput={'component'}
        parentTranslationPath={parentTranslationPath}
        translationPath={translationPath}
        themeClasses='theme-solid'
        currentTab={activeTab}
        onTabChanged={onTabChanged}
        withDynamicComponents
        dynamicComponentProps={{
          parentTranslationPath,
          translationPath,
        }}
      />
    </div>
  );
};
