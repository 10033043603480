import bankIcon from '../../../../../assets/images/Propxicon/bank.svg';
import bankNoteIcon from '../../../../../assets/images/Propxicon/bank-note-01.svg';
import creditCardIcon from '../../../../../assets/images/Propxicon/credit-card-01.svg';
import pencilLineIcon from '../../../../../assets/images/Propxicon/pencil-line.svg';

export const PaymentMethodsData = {
    BankTransfer: {
        id: 20695,
        key: 'BankTransfer',
        title: 'Bank-transfer',
        icon: bankIcon,
    },
    Cash: {
        id: 20693,
        key: 'Cash',
        title: 'Cash',
        icon: bankNoteIcon,
    },
    Cheque: {
        id: 20694,
        key: 'Cheque',
        title: 'Cheque',
        icon: pencilLineIcon,
    },
    Creditcard: {
        id: 20696,
        key: 'Creditcard',
        title: 'Credit-card',
        icon: creditCardIcon,
    },
}