import React, { useCallback, useEffect, useReducer, useRef, useState } from "react";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { PageHeading } from "../../../../../../../SharedComponents";
import { Avatar, ButtonBase } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { AutocompleteComponent, Inputs, Tables } from "../../../../../../../Components";
import { PendingDeactivationTabs } from "../PendingDeactivationTabs";
import {
  getDownloadableLink,
  getFirstLastNameLetters,
  showError,
  showSuccess,
} from "../../../../../../../Helper";
import { ApproveUserDeActivation, OrganizationUserSearch } from "../../../../../../../Services";
import { UserDeActivationRejectDialog } from "../../../Dialogs";
import { UpdatedUserStatus } from "../../../../../../../Enums";

export const PendingDeactivationView = ({
  parentTranslationPath,
  translationPath,
  usersData,
  totalCount,
  setFilter,
  filter,
  reloadData,
  onPageSizeChanged,
  onPageIndexChanged,
  setActiveView,
  setDeActiveUserId,
  setSelected,
  selected ,
  dateRangeDefault,
  initialFilter
}) => {
  const { t } = useTranslation(parentTranslationPath);
  const typingTimer = useRef(null);
  const reducer = (state, action) => {
    if (action.id !== "edit") return { ...state, [action.id]: action.value };
    return {
      ...action.value,
    };
  };
  const [isRejectDialogOpen, setIsRejectDialogOpen] = useState(false);
  const [activeItem, setActiveItem] = useState(null);
  const [data, setData] = useReducer(reducer, {
    systemUsers: [],
    branches: [],
  });
  const [isLoading, setIsLoading] = useState(false);

  const getSystemUsers = async ({name ,userName}) => {
    const res = await OrganizationUserSearch({
      pageIndex: 0,
      pageSize: 10,
      name: name || "",
      userName:userName
    });
    if (!(res && res.status && res.status !== 200)) {
      const localValue = (res && res.result) || [];
      if (localValue.length > 0) {
        setData({ id: "systemUsers", value: localValue });
      } else setData({ id: "systemUsers", value: [] });
    }
  };
  const approveUserDeActivation = async (userId) => {
    setIsLoading(true);
    const res = await ApproveUserDeActivation(userId);
    if (!(res && res.status && res.status !== 200)) {
      showSuccess(t(`${translationPath}deactivate-approval-success`));
    } else showError(t(`${translationPath}deactivate-approval-failed`));
    await reloadData();
    setIsLoading(false);
  };


  const getActionButtons = (item) => {
    const actionsList = [];

    const rejectionAction = (
      <Button
        className="btns propx-action-btn theme-propx outlined danger"
        onClick={() => {
          setActiveItem(item);
          setIsRejectDialogOpen(true);
        }}
      >
        {t(`${translationPath}reject`)}
      </Button>
    );

    const approvalAction = (
      <Button
        className="btns propx-action-btn theme-propx outlined gold"
        onClick={() => ((item?.id && !isLoading)? approveUserDeActivation(item?.id) : null)}
      >
        {t(`${translationPath}confirm`)}
      </Button>
    );
 
    const completeAction = (
      <Button
        className="btns propx-action-btn theme-propx outlined gold"
        onClick={() => ((item?.id && !isLoading)? approveUserDeActivation(item?.id) : null)}
      >
        <span className="fz-14 fw-simi-bold light-text ml-1">
          {t(`${translationPath}complete`)}
        </span>
      </Button>
    );

    const manageAction = (
      <span className="d-flex-center fj-start pointer px-3 py-2" onClick={() => {
        setActiveView(PendingDeactivationTabs.UnitListing.key);
        setDeActiveUserId(item.id);
      }}>
        <span className="icons b-icon i-users-edit"></span>
        <span className="fz-14 fw-simi-bold light-text ml-1">
          {t(`${translationPath}manage`)}
        </span>
      </span>
    );
    
    if (item?.showCompleteButton)
      actionsList.push(completeAction);
    else if (!item?.showConfirmButton && !item?.showRejectButton)
      actionsList.push(manageAction);
    else {
      if (item?.showRejectButton) actionsList.push(rejectionAction);
      if (item?.showConfirmButton) actionsList.push(approvalAction);
    }

    return (
      <span className="d-flex-center fj-start">{actionsList.map((item) => item)}</span>
    );
  };
    const onClearedAllFiltersClick = useCallback(() => {
    setFilter((currentFilter) => ({
      ...initialFilter,             
      status: currentFilter.status,
    }));
    setSelected({
      id: "edit",
      value: {
        lineManager: null,
        branch: null,
        userName:null,
        dateFilter: dateRangeDefault
      },
    });
  }, []);
  useEffect(()=>{
    setFilter((items) => ({
    ...items,
    status: UpdatedUserStatus.PendingDeactivation.value|| 1,
  }));
  },[])
  return (
    <div>
      <div className="d-flex-column">
        <PageHeading
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          headerTitle={"PendingDeactivation"}
          subTitle={"Pending-dectivation-heading"}
          wrapperClasses={"px-4 w-50"}
          headerTitleClasses={"fz-16 mb-1"}
          subTitleClasses={"fz-14 m-0 ml-0"}
        />

        <div className="d-flex">
          <div className="w-100 px-4 d-flex flex-wrap">
            <div className="w-100 d-flex fa-center fa-start">
              <Inputs
                idRef="user-nameRef"
                labelClasses="mt-4"
                wrapperClasses="w-50 mt-2 mr-3"
                value={filter.userName || ''}
                onInputChanged={(event) => {
                  const userName = event?.target?.value;
                  setFilter((items) => ({
                    ...items,
                    userName: userName || null,
                    userId: null,
                  }));
                }}
                inputPlaceholder={t(`${translationPath}full-Name`)}
              />
              <Inputs
                idRef="mobileNumber-ref"
                labelClasses="mt-4"
                wrapperClasses="w-50 mt-2 mr-3"
                value={filter.mobileNumber || ''}
                onInputChanged={(event) => {
                  const mobileNumber = event?.target?.value;
                  setFilter((items) => ({
                    ...items,
                    mobileNumber: mobileNumber || null,
                  }));
                }}
                inputPlaceholder={t(`${translationPath}mobile-Number`)}
              />
              <Inputs
                idRef="email-ref"
                labelClasses="mt-4"
                wrapperClasses="w-50 mt-2 mr-3"
                value={filter.email || ''}
                onInputChanged={(event) => {
                  const email = event?.target?.value;
                  setFilter((items) => ({
                    ...items,
                    email: email || null,
                  }));
                }}
                inputPlaceholder={t(`${translationPath}email`)}
              />
              <Inputs
                idRef="branch-NameRef"
                labelClasses="mt-4"
                wrapperClasses="w-50 mt-2 mr-3"
                value={filter.branchName || ''}
                onInputChanged={(event) => {
                  const branchName = event?.target?.value;
                  setFilter((items) => ({
                    ...items,
                    branchName: branchName || null,
                  }));
                }}
                inputPlaceholder={t(`${translationPath}branch-Name`)}
              />
              <Inputs
                idRef="Createdby-ref"
                labelClasses="mt-4"
                wrapperClasses="w-50 mt-2 mr-3"
                value={filter.staffId || ''}
                onInputChanged={(event) => {
                  const staffId = event?.target?.value;
                  setFilter((items) => ({
                    ...items,
                    staffId: staffId || null,
                  }));
                }}
                inputPlaceholder={t(`${translationPath}staff-ID`)}
              />
            </div>
            <div className="w-25 d-flex flex-wrap fa-start">
              <AutocompleteComponent
                idRef="user-NameRef"
                labelClasses="Requierd-Color"
                inputPlaceholder={t(`${translationPath}user-Name`)}
                selectedValues={selected.userName}
                wrapperClasses="w-25 mt-2"
                data={data.systemUsers || []}
                withoutSearchButton
                multiple={false}
                displayLabel={(option) => option?.userName || ""}
                onChange={(event, newValue) => {
                  setSelected({
                    id: "userName",
                    value: newValue,
                  });
                  setFilter((items) => ({
                    ...items,
                    userUserName: newValue?.userName || null,
                  }));
                }}
                onOpen={() => {
                  if (data.systemUsers && data.systemUsers.length == 0)
                    getSystemUsers({});
                }}
                onInputKeyUp={(e) => {
                  const { value } = e.target;
                  if (typingTimer) clearTimeout(typingTimer.current);
                  typingTimer.current = setTimeout(() => {
                    getSystemUsers({ userName: value });
                  }, 1200);
                }}
              />
            </div>
          </div>
          <div className="mt-2">
            <ButtonBase
              onClick={onClearedAllFiltersClick}
              id="onClearedAllFiltersref"
              // disabled={isAllFormFieldsLoading}
              className="btns theme-solid bg-danger clear-all-btn px-4"
            >
              <span className="mdi mdi-filter-remove m-1" />
              {t(`${translationPath}clear-filters`)}
            </ButtonBase>
          </div>
        </div>
      </div>
      <div className="propx-view-container sm  m-3">
        <Tables
          data={usersData || []}
          headerData={[
            {
              id: 1,
              label: "user",
              component: (item) =>
                (item?.name ? (
                  <div className="d-flex-v-center">
                    <div className="campaign-team-f1">
                      <Avatar
                        className="avatars-wrapper team-avatar fz-12 sm-theme"
                        src={
                          item?.profileImage
                            ? getDownloadableLink(item?.profileImage)
                            : ""
                        }
                      >
                        {getFirstLastNameLetters(item?.name || "")}
                      </Avatar>
                    </div>
                    <div className="d-flex-column">
                      <span className="fz-12 fw-simi-bold">{item?.name || "-"}</span>
                      <span className="fz-10 text-tertiary">
                        {item?.email || "-"}
                      </span>
                    </div>
                  </div>
                ) : (
                  "-"
                )) || "",
            },
            {
              id: 2,
              label: "leads",
              input: "leads",
            },
            {
              id: 3,
              label: "units",
              input: "units",
            },
            {
              id: 4,
              label: "activation-date",
              input: "activationDate",
              component: (item) =>
                (
                  <span>
                    {item.activationDate
                      ? moment(item.activationDate).format("LL")
                      : null}
                  </span>
                ) || "",
            },
            {
              id: 5,
              label: "last-activity-date",
              input: "lastActivityDate",
              component: (item) =>
                (
                  <span>
                    {item.lastActivityDate
                      ? moment(item.lastActivityDate).format("LL")
                      : null}
                  </span>
                ) || "",
            },
            {
              id: 6,
              label: "actions",
              component: (item) => getActionButtons(item),
            },
          ]}
          itemsPerPage={filter.pageSize}
          activePage={filter.pageIndex}
          totalItems={totalCount}
          onPageIndexChanged={onPageIndexChanged}
          onPageSizeChanged={onPageSizeChanged}
          defaultActions={[]}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
        />
      </div>
      {isRejectDialogOpen && (
        <UserDeActivationRejectDialog
          activeItem={activeItem}
          isDialogOpen={isRejectDialogOpen}
          setIsDialogOpen={setIsRejectDialogOpen}
          reloadData={reloadData}
          onCloseClicked={() => {
            setIsRejectDialogOpen(false);
            setActiveItem(null);
          }}
          onCancelClicked={() => {
            setIsRejectDialogOpen(false);
            setActiveItem(null);
          }}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
        />
      )}
    </div>
  );
};
