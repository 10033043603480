import React, { useEffect, useState } from "react";
import { PowerBIEmbed } from "powerbi-client-react";
import { models } from "powerbi-client";
import "../MasterStyleDashborads/MasterStyleDashboards.scss";
import {
  MicrosoftToken,
} from "../../../../Services/DashboardService";
import { PowerBiEnum } from "../../../../Enums";
import { showError } from "../../../../Helper";

export const DubaiLeaseAgent = () => {
  const [report, setReport] = useState();
  const [userid, setUserid] = useState(null);
  const [render, setRender] = useState(false);

  const Filter = {
    $schema: "http://powerbi.com/product/schema#basic",
    target: {
      table: "Users",
      column: "UsersId",
    },
    operator: "In",
    values: [userid],
    filterType: models.FilterType.BasicFilter,
    requireSingleSelection: true,
  };

  const Microsoft = async (isExpired) => {
    const res = await MicrosoftToken(isExpired);
    if (!(res && res.status && res.status !== 200)) {
      setReport(res);
      setRender(true);
    } else {
      setReport("");
      showError("Contact Your Bi Admin For Help");
    }
  };

  useEffect(() => {
    Microsoft(false);
    setUserid(JSON.parse(localStorage.getItem("session")).userId || null);
  }, []);
  return (
    <div className="dashboardMain-PowerBIEmbed">
      {render && (
        <div className="dashboardMain">
          <PowerBIEmbed
            embedConfig={{
              type: "report",
              id: PowerBiEnum.dubai_lease_listing_agent.reportid,
              embedUrl: PowerBiEnum.dubai_lease_listing_agent.url,
              accessToken: report,
              pageView: "fitToWidth",
              filters: [Filter],
              settings: {
                customLayout: {
                  displayOption: models.DisplayOption.FitToWidth,
                },
                filterPaneEnabled: false,
                navContentPaneEnabled: false,
                panes: {
                  filters: {
                    expanded: false,
                    visible: false,
                  },
                },
                background: models.BackgroundType.Transparent,
              },
            }}
            eventHandlers={
              new Map([
                [
                  "loaded",
                  () => {
                    console.log("Report loaded");
                  },
                ],
                [
                  "rendered",
                  () => {
                    console.log("Report rendered");
                  },
                ],
                [
                  "error",
                  (event, embed) => {
                    const error = event.detail;

                    if (
                      error.detailedMessage === "Get report failed" ||
                      error.detailedMessage ===
                        "Access token has expired, resubmit with a new access token" ||
                      error.detailedMessage ===
                        "Fail to initialize - Could not resolve cluster"
                    ) {
                      Microsoft(true);
                      setRender(false);
                    } else console.log(error.detailedMessage);
                    showError(error.detailedMessage);
                  },
                ],
              ])
            }
            cssClassName="report-style-class"
            getEmbeddedComponent={(embeddedReport) => {
              window.report = embeddedReport;
            }}
          />
        </div>
      )}
    </div>
  );
};
