

import React, { useState }  from 'react';
import { useTranslation } from 'react-i18next';
import { 
  UnitMix  ,
  UnitLayout ,
  UnitFinishing ,
  PropertyAge ,
  Balcony ,
  CentralAC  ,
  MaidsRoom  , 
  BuiltInWardrobes  , 
  BuiltInKitchen ,
  SmartHomeTechnology ,
  LaundryRoom  , 
  WalkInCloset  , 
  UnitsFacilities ,
  UnitsAmenities 
} from '../TowerUnitsFinishingContent' ; 
   
  export const TowerUnitsFinishingContant = ({
    parentTranslationPath ,
    translationPath ,
    state ,
    setState ,
    setSelectedList , 
    selectedList , 
    onChangeSelectedItem
   } ) => { 


    return (
      <>
        <div className="tower-units-finishing-section b-bottom mb-2">
          <UnitMix
           parentTranslationPath={parentTranslationPath}
           translationPath={translationPath}
           state={state}
           setState={setState}
           setSelectedList={setSelectedList}
           selectedList={selectedList}
           onChangeSelectedItem={onChangeSelectedItem}
          /> 
        </div> 
        <div className="tower-units-finishing-section b-bottom mb-2">
          <UnitLayout
           parentTranslationPath={parentTranslationPath}
           translationPath={translationPath}
           state={state}
           setState={setState}
          /> 
        </div> 
        <div className="tower-units-finishing-section b-bottom mb-2">
          <UnitFinishing
           parentTranslationPath={parentTranslationPath}
           translationPath={translationPath}
           state={state}
           setSelectedList={setSelectedList}
           selectedList={selectedList}
           onChangeSelectedItem={onChangeSelectedItem}
          /> 
        </div>  
        <div className="tower-units-finishing-section">
          <UnitsFacilities
           parentTranslationPath={parentTranslationPath}
           translationPath={translationPath}
           state={state}
           setSelectedList={setSelectedList}
           selectedList={selectedList}
           onChangeSelectedItem={onChangeSelectedItem}
          /> 
        
          <UnitsAmenities
           parentTranslationPath={parentTranslationPath}
           translationPath={translationPath}
           state={state}
           setSelectedList={setSelectedList}
           selectedList={selectedList}
           onChangeSelectedItem={onChangeSelectedItem}
          /> 
        </div>          

      
      </>
    );
  } ; 
  
