import React from 'react';
import { Box, Avatar } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';

// Styles
import useStyles from './styles';

function ContactCommentSkeleton() {
  const styles = useStyles();

  return (
    <Box className={styles.commentContainer}>
      <Avatar className={styles.defaultAvatar}>
        <Skeleton variant="circle" width={40} height={40} />
      </Avatar>
      <Box>
        <Skeleton variant="text" width={200} height={20} />
        <Box className={styles.commentDetails}>
          <Skeleton variant="text" width={100} height={20} />
          <Skeleton variant="text" width={60} height={20} />
        </Box>
      </Box>
    </Box>
  );
};

export default ContactCommentSkeleton;
