
import React, { useState , useCallback   , useEffect}  from 'react';
import { useTranslation } from 'react-i18next';
import Rating from '@material-ui/lab/Rating';
import  PlusIcon  from '../../../../../../assets/images/defaults/plus.svg' ; 
import {  Spinner , AutocompleteComponent  } from '../../../../../../Components' ; 
import { GetLookupItemsByLookupTypeName } from '../../../../../../Services' ; 
import { GeneralPropertyCriteriaTypesEnum } from '../../../../../../Enums' ;
import { showError } from '../../../../../../Helper';


  export const NeighborhoodCriteria = ({
    parentTranslationPath ,
    translationPath ,
    state ,
    setState ,
   } ) => { 

    const { t } = useTranslation(parentTranslationPath);
    const [isLoading , setIsLoading] = useState(false);
    const [allNeighborhoodsCriteria , setNeighborhoodsCriteria]=useState([]);
    const [selectedValue, setSelectedValue] = useState(null);

    const onChangeSelectedItem = (key , value , index) => 
    {
      const newListUpdated = [...state.generalPropertyLookupInfo] ;
        const newSelectedArray =  [...selectedValue.generalPropertyLookupDtos];
         if(key === 'lookupsId' &&  selectedValue.generalPropertyLookupDtos.some((s)=>s.lookupsId === value?.lookupsId))
           {
                showError(t`${translationPath}this-neighborhood-was-selected-please-select-another`);
                return ; 
           }
          newSelectedArray[index] =  key  === 'lookupsId' ? 
              {  
                  ...newSelectedArray[index] ,
                 lookupItemName : (value && value.lookupItemName) , 
                 lookupsId: (value && value.lookupsId)
               }: 
              {  
                  ...newSelectedArray[index] ,
                  score : (value || 0) , 
              };
              if(key === 'lookupsId' && !value )
               {
                 newSelectedArray[index] = 
                {  
                  ...newSelectedArray[index] ,
                   score : 0 
                 } ;  
              }
            const generalPropertyInfoIndex = newListUpdated.findIndex((f)=> f.generalPropertyCriteriaType === (selectedValue.generalPropertyCriteriaType));
            let newSelectedObject = { generalPropertyCriteriaType : (selectedValue?.generalPropertyCriteriaType)   , generalPropertyLookupDtos  : newSelectedArray };
            setSelectedValue(newSelectedObject);

            if(generalPropertyInfoIndex !== -1 )
            {
              newListUpdated[generalPropertyInfoIndex] =  newSelectedObject ; 
              setState({ id: 'generalPropertyLookupInfo', value: newListUpdated});
            }
           else 
           {
            newListUpdated.push({...newSelectedObject});
            setState({ id: 'generalPropertyLookupInfo', value: newListUpdated});
           }
     };

     const GetLookupItemsByLookupTypeNameAPI = useCallback(async () => 
     {
        setIsLoading(true);
         const res = await GetLookupItemsByLookupTypeName({ lookUpName : 'Neighborhood' , pageIndex :1 , pageSize :150});
         if (!((res && res.data && res.data.ErrorId) || !res)) {
          setNeighborhoodsCriteria(res?.result.map((i) => ({
            lookupsId:  (i.lookupItemId),
            lookupItemName: (i.lookupItemName)
          }))) ; 
        } 
         else 
         setNeighborhoodsCriteria([]) ; 
         setIsLoading(false) ; 
     });

     useEffect(() => {
      if(!(state && state.generalPropertyLookupInfo && state.generalPropertyLookupInfo.find((f)=>f.generalPropertyCriteriaType === GeneralPropertyCriteriaTypesEnum.Neighborhood.key )))
      setSelectedValue({generalPropertyCriteriaType : GeneralPropertyCriteriaTypesEnum.Neighborhood.key  ,
        generalPropertyLookupDtos : [
        {
          lookupItemName :null ,
          lookupsId :null ,
          propertyRatingGeneralPropertyCriteriaScoreId :null  , 
          score :0
        }
      ] });
      else 
      setSelectedValue(state && state.generalPropertyLookupInfo && state.generalPropertyLookupInfo.find((f)=>f.generalPropertyCriteriaType === GeneralPropertyCriteriaTypesEnum.Neighborhood.key))
  
   }, [state]);


    return (
      <div className="w-75">
        <div className='general-property-section'>
          <div>
          <span className='fw-bold'> {t(`${translationPath}neighborhood`)} </span>
          </div>
          <div className='w-100  mt-2 mb-2'>
          {
                selectedValue  &&  selectedValue.generalPropertyLookupDtos && 
                selectedValue.generalPropertyLookupDtos.map((item, index) =>                 
                (
                  <div className='d-flex d-flex-v-center general-property-card mt-2 mb-2 b-bottom' key={`Neighborhood${index}`}>
                  <div className='mb-2'>
                   <AutocompleteComponent
                         idRef={`NeighborhoodRef${index}`}
                         multiple={false}
                         data={allNeighborhoodsCriteria}
                         selectedValues={item}
                         displayLabel={(option) => option.lookupItemName || ''}
                         renderOption={(option) => (option.lookupItemName || '')}   
                         withoutSearchButton
                         isDisabled={item?.propertyRatingGeneralPropertyCriteriaScoreId}
                         isLoading={isLoading}
                         withLoader
                         parentTranslationPath={parentTranslationPath}
                         translationPath={translationPath}
                         onChange={(event, newValue) => 
                         { 
                           onChangeSelectedItem('lookupsId' ,  newValue , index);
     
                         }}
                         inputPlaceholder={'select-neighborhood'}
                         onOpen={() => {
                          if (
                            allNeighborhoodsCriteria &&
                            allNeighborhoodsCriteria.length === 0
                          )
                          GetLookupItemsByLookupTypeNameAPI();
                        }}
                         />
                       </div>
                       <div className=''>
                      <Rating  
                           name={`RatingNeighborhood${index}`}
                           max={10}
                           precision={1}
                           value={item.score}
                           disabled={!item.lookupsId}
                           onChange={(event, newValue) => 
                           {
                             onChangeSelectedItem('score' ,  newValue || 0 , index);
                        
                           }}
                        /> 
                        </div> 
                    </div>
                   ))}
                 {/* <div className='d-flex d-flex-v-center  mt-2 mb-2'
                   onClick={() => {
                    let newSelectedObj = {  ...selectedValue } ;
                    let newList  =  selectedValue.generalPropertyLookupDtos ? [...selectedValue.generalPropertyLookupDtos] : [] ; 
                    newList.push(
                      { 
                        lookupItemName :null ,
                        lookupsId :null ,
                        propertyRatingGeneralPropertyCriteriaScoreId :null  , 
                        score :0
                    });
                    setSelectedValue({...newSelectedObj , generalPropertyLookupDtos : newList }) ;
                  }}
                   >
                   <img className="mr-2 ml-2" 
                     src={PlusIcon}
                     alt={t(`${translationPath}permission-denied`)}
                   /> 
                   <span>
                     
                    {t(`${translationPath}add-another`)}
                   </span>
                 </div> */}
            </div>  
          </div>
        </div>
       );
     } ; 
  
  
  