import React, { useState, useCallback, useReducer, useEffect, useRef } from 'react';
import moment from 'moment';
import '../KenbanBoardView/KenbanBoardView.scss';
import clipboardIcon from '../../../../assets/images/icons/clipboard.svg';
import { DialogActions, DialogContent, ButtonBase } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { showError, showSuccess, GetParams } from '../../../../Helper';
import { Inputs, DialogComponent } from '../../../../Components';
import { GetTaskUploadedData, UploadTaskData } from '../../../../Services';
import { ActivityFilesUploader } from '../../../../Components/UploaderActivitieFileComponent/ActivityFilesUploader';
import { LinkUploader } from '../../../../SharedComponents/LinkUploader/LinkUploader';

export const TaskUploadDialog = ({ isDialogOpen, onClose, onSave }) => {
  const parentTranslationPath = 'Campaign';
  const translationPath = 'campaign-task.';
  const { t } = useTranslation(parentTranslationPath);

  const reducer = useCallback((state, action) => {
    if (action.id !== 'edit') return { ...state, [action.id]: action.value };
    return {
      ...action.value,
    };
  }, []);

  const [state, setState] = useReducer(reducer, {
    content: null,
    note: null,
    deadline: moment().format('YYYY-MM-DDTHH:mm:ss'),
    taskAttachments: [],
  });
  const [selected, setSelected] = useReducer(reducer, {
    links: [],
    files: [],
  });
  const [isLoading, setIsLoading] = useReducer(reducer, {
    isSubmitted: false,
  });
  const [data, setData] = useReducer(reducer, {
    uploadData: null,
  });

  const [isEditDataLoaded, setIsEditDataLoaded] = useState(false);

  const mapSelectedFiles = (attachments) => {
    let selectedFiles = [];

    const localAttachmentFiles = attachments && attachments.filter((item) => item.fileId);

    selectedFiles =
      localAttachmentFiles &&
      localAttachmentFiles.map((item) => ({
        id: item.id,
        uuid: item.fileId,
        fileName: item.urlDescription || '-',
      }));

    return selectedFiles || [];
  };

  const mapSelectedLinks = (attachments) => {
    let selectedLinks = [];

    const localAttachmentLinks = attachments && attachments.filter((item) => item.url);

    selectedLinks =
      localAttachmentLinks &&
      localAttachmentLinks.map((item) => ({
        id: item.id,
        documentLink: item.url,
        fileName: item.urlDescription || '-',
      }));

    return selectedLinks || [];
  };

  const mapStateAttachements = () => {
    const allAttachments = [...(selected.files || []), ...(selected.links || [])];
    const localStateAttachments = allAttachments.map((item) => ({
      id: item.id,
      fileId: item.uuid,
      url: item.documentLink,
      urlDescription: item.fileName,
    }));

    return localStateAttachments || null;
  };

  const uploadTaskData = async () => {
    setIsLoading({ id: 'isSubmitted', value: true });
    const body = {
      ...state,
      id: +GetParams('id') || null,
      taskAttachments: mapStateAttachements(),
    };
    const res = await UploadTaskData(body);
    if (!(res && res.status && res.status !== 200)) {
      showSuccess(t(`${translationPath}Task-uploaded-successfully`));
      if (onSave) await onSave();
    } else showError(t(`${translationPath}Task-upload-failed`));

    setIsLoading({ id: 'isSubmitted', value: false });
  };

  const getTaskUploadedData = async () => {

    const res = await GetTaskUploadedData({
      taskId: +GetParams('id') || null,
    });
    if (!(res && res.status && res.status !== 200)) {
      setData({ id: 'uploadData', value: res || [] });
    }
  };

  const saveHandler = async (event) => {
    event.preventDefault();
    // if (schema?.error?.message) {
    //   showError(schema.error.message);
    //   return;
    // }

     uploadTaskData();
  };

  useEffect(() => {
    if (data.uploadData) {

      setSelected({
        id: 'edit',
        value: {
          files: mapSelectedFiles(data.uploadData.taskAttachments),
          links: mapSelectedLinks(data.uploadData.taskAttachments),
        },
      });
      setState({
        id: 'edit',
        value: {
          ...data.uploadData,
        },
      });
      
      setIsEditDataLoaded(true);
    }
  }, [data.uploadData]);

  useEffect(() => {
    getTaskUploadedData();
  }, []);
  
  return (
    <div>
      <DialogComponent
        isOpen={isDialogOpen}
        maxWidth='xs'
        contentClasses='px-0'
        dialogTitle={
          <div className='marketing-module-view marketing-dialog'>
            <div className='d-flex-center'>
              <div className='dialog-title-icon'>
                <img src={clipboardIcon} />
              </div>
              <div className='ml-3'>
                <p className='fz-16 fw-simi-bold mb-1'>{t(`${translationPath}upload-task`)}</p>
                <p className='fz-12 text-tertiary m-0'>
                  {t(`${translationPath}upload-task-heading`)}
                </p>
              </div>
            </div>
          </div>
        }
        dialogContent={
          <>
            <form noValidate onSubmit={saveHandler}>
              <DialogContent>
                <div className='marketing-dialog dialog-content py-3'>
                  <Inputs
                    idRef='contentRef'
                    labelValue='content'
                    wrapperClasses='mb-3'
                    value={state.content || ''}
                    multiline
                    rows={4}
                    parentTranslationPath={parentTranslationPath}
                    translationPath={translationPath}
                    onInputChanged={(event) =>
                      setState({ id: 'content', value: event?.target?.value })
                    }
                  />
                  <Inputs
                    idRef='noteRef'
                    labelValue='note'
                    wrapperClasses='mb-3'
                    value={state.note || ''}
                    multiline
                    rows={4}
                    parentTranslationPath={parentTranslationPath}
                    translationPath={translationPath}
                    onInputChanged={(event) =>
                      setState({ id: 'note', value: event?.target?.value })
                    }
                  />
                  {isEditDataLoaded && (
                    <div className='marketing-attachments-uploader'>
                      <fieldset className='input-fieldset'>
                        <legend className='input-fieldset-legend'>
                          {t(`${translationPath}attachments`)}
                        </legend>
                        <div>
                          <div className='w-100'>
                            <ActivityFilesUploader state={selected} setState={setSelected} />
                          </div>
                          <div className='uploader-type mb-3'>
                            <LinkUploader
                              initUploaded={selected.links || []}
                              uploadedChanged={(links) => {
                                setSelected({ id: 'links', value: links });
                              }}
                            />
                          </div>
                        </div>
                      </fieldset>
                    </div>
                  )}
                </div>
              </DialogContent>
              <DialogActions>
                <div className='marketing-module-view d-flex-center px-4 py-3'>
                  <ButtonBase onClick={onClose} className='marketing-buttons outlined  w-100'>
                    {t(`cancel`)}
                  </ButtonBase>
                  <ButtonBase
                    className='marketing-buttons solid w-100'
                    disabled={isLoading.isSubmitted || isLoading.uploadData}
                    onClick={saveHandler}
                    type='submit'>
                    {t(`confirm`)}
                  </ButtonBase>
                </div>
              </DialogActions>
            </form>
          </>
        }
      />
    </div>
  );
};
