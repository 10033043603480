import React, { useEffect, useState } from 'react';
import { CopyToClipboardComponents } from '../../../../../../ReusableComponents/UtilityComponents';
import { Grid, makeStyles } from '@material-ui/core';
import { ListingAgentCard } from './sections/ListingAgentCard';
import { UnitDetailsCard } from './sections/UnitDetailsCard';
import { HistoryStepper } from './sections/HistoryStepper';
import './LeasingHistory.scss';
import { GetUnitLeaseHistory } from '../../../../../../Services';
import { GetParams } from '../../../../../../Helper';
import { Spinner } from '../../../../../../Components';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    padding: '12px',
  },
  details: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  content: {
    flex: '1 0 auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  cover: {
    height: 180,
  },
  controls: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  playIcon: {
    height: 38,
    width: 38,
  },
  listingAgentCard: {
    height: '100%',
  },
  content2: {
    display: 'flex',
    alignItems: 'flex-end',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
}));

export const LeasingHistory = ({ parentTranslationPath }) => {
  const { t } = useTranslation(parentTranslationPath);
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const classes = useStyles();
  const getUnitLeaseHistory = async () => {
    const response = await GetUnitLeaseHistory(+GetParams('id'));
    setData(response);
    setIsLoading(false);
  };

  useEffect(() => {
    getUnitLeaseHistory();
  }, []);
  if (isLoading) {
    return <Spinner isActive />;
  }
  return (
    <>
      <div className='d-flex px-5 mt-3'>
        <h3 className='mt-1 mx-2'>{t('leasing-history')}</h3>
        <CopyToClipboardComponents
          data={data?.unitId}
          childrenData={data?.unitId}
        />
      </div>
      <Grid container spacing={3} justifyContent='center' alignItems='stretch'>
        <Grid xs={8} item>
          <UnitDetailsCard classes={classes} {...data} />
        </Grid>
        <Grid xs={3} item>
          <ListingAgentCard classes={classes} {...data} />
        </Grid>
        <Grid item xs={11}>
          <HistoryStepper t={t} data={data?.ownersDetails} />
        </Grid>
      </Grid>
    </>
  );
};
