import React, { useState, useCallback, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import Rating from "@material-ui/lab/Rating";
import { Spinner, Inputs } from "../../../../../Components";
import { GetGeographicCriteriaWithScores } from "../../../../../Services";
import { ReactComponent as Minimize } from "../../../../../assets/images/defaults/minimize.svg";

export const DistrictGeographicCriteria = ({
  parentTranslationPath,
  translationPath,
  districtGeographicCriteriaWithScores,
  setDistrictGeographicCriteriaWithScores,
  setCommunityGeographicCriteriaWithScores,
  communityGeographicCriteriaWithScores,
  setActiveDistrict,
  activeCity,
  setActiveCommunity,
  setActiveSubCommunity,
  propertyRatingLocationTypeScoreId,
  activeDistrict,
  displayAllReletedLocation,
  setDisplayAllReletedLocation,
}) => {
  const { t } = useTranslation(parentTranslationPath);
  const [isLoading, setIsLoading] = useState(false);
  const [displayDistrictSelected, setDisplayDistrictSelected] = useState([]);
  const [filterDistrictByName, setFilterDistrictByName] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const searchTimer = useRef(null);

  const GetGeographicCriteriaWithScoresAPI = useCallback(async (search) => {
    setIsLoading(true);
    const res = await GetGeographicCriteriaWithScores({
      parentLookupItemId: search,
    });
    if (!((res && res.data && res.data.ErrorId) || !res)) {
      const communities =
        (res &&
          res.locationTypeScores &&
          res.locationTypeScores.length > 0 &&
          res.locationTypeScores[0] &&
          res.locationTypeScores[0].geographicCriteriaScores &&
          res.locationTypeScores[0].geographicCriteriaScores.map((el) => ({
            ...el,
            propertyRatingLocationTypeScoreId:
              propertyRatingLocationTypeScoreId,
          }))) ||
        [];
      let newList = [...communityGeographicCriteriaWithScores, ...communities];
      let filterArray = newList.filter(
        (value, index, self) =>
          index === self.findIndex((t) => t.lookupsId === value.lookupsId)
      );
      setCommunityGeographicCriteriaWithScores([...filterArray]);
    } else {
      setCommunityGeographicCriteriaWithScores([]);
    }
    setIsLoading(false);
  });

  useEffect(() => {
    if (activeDistrict && activeDistrict.lookupsId) {
      setActiveSubCommunity(null);
      setActiveCommunity(null);
      GetGeographicCriteriaWithScoresAPI(activeDistrict.lookupsId);
    }
    setDisplayAllReletedLocation((e) => ({
      ...e,
      displayAllCommunitySubCommunities: null,
    }));
  }, [activeDistrict]);

  useEffect(() => {
    if (
      displayAllReletedLocation &&
      displayAllReletedLocation.displayAllDistrictCommunities
    ) {
      const selectedDistrict = districtGeographicCriteriaWithScores.filter(
        (item) =>
          item.lookupsId ===
          displayAllReletedLocation.displayAllDistrictCommunities.id
      );
      setDisplayDistrictSelected([...selectedDistrict]);
    } else setDisplayDistrictSelected([]);
  }, [displayAllReletedLocation, districtGeographicCriteriaWithScores]);

  useEffect(() => {
    if (searchValue !== "") {
      const newList =
        districtGeographicCriteriaWithScores &&
        districtGeographicCriteriaWithScores.filter((a) =>
          a.lookupItemName
            .toLocaleLowerCase()
            .includes(searchValue && searchValue.toLocaleLowerCase())
        );
      const newList2 =
        displayDistrictSelected &&
        displayDistrictSelected.filter((a) =>
          a.lookupItemName
            .toLocaleLowerCase()
            .includes(searchValue && searchValue.toLocaleLowerCase())
        );
      setDisplayDistrictSelected([...newList2]);
      setFilterDistrictByName([...newList]);
    } else if (searchValue === "") {
      setFilterDistrictByName([]);
      setDisplayDistrictSelected([]);
      const displayAllDistrictCommunities =
        displayAllReletedLocation &&
        displayAllReletedLocation.displayAllDistrictCommunities
          ? null
          : null;
      if (setDisplayAllReletedLocation)
        setDisplayAllReletedLocation((e) => ({
          ...e,
          displayAllDistrictCommunities: displayAllDistrictCommunities,
        }));
    }
  }, [searchValue]);

  return (
    <>
      {" "}
      {activeCity && (
        <>
          <Spinner isActive={isLoading} isAbsolute />
          <div>
            <span className="fw-bold"> {t(`${translationPath}District`)} </span>
          </div>
          <div className="w-100 mt-2 mb-2 sores-part">
            <div className="b-bottom w-100">
              <div className="search-filter">
                <div className="">{""}</div>
                <div className="">
                  <Inputs
                    idRef="DistrictOptionsFilterInputRef"
                    wrapperClasses="mt-2"
                    inputPlaceholder={`${t("search-for-district")}`} 
                    onInputBlur={(event) => {
                      event.stopPropagation();
                    }}
                    onKeyUp={(event) => {
                      if (searchTimer.current)
                        clearTimeout(searchTimer.current);
                      const newValue = event.target.value;
                      searchTimer.current = setTimeout(() => {
                        setSearchValue(newValue);
                      }, 300);
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="w-100 mt-2 mb-2 district">
            {searchValue === "" &&
              displayAllReletedLocation &&
              !displayAllReletedLocation.displayAllDistrictCommunities &&
              districtGeographicCriteriaWithScores &&
              districtGeographicCriteriaWithScores
                .filter(
                  (d) =>
                    d.parentLookupItemId ===
                    (activeCity && activeCity.lookupsId)
                )
                .map((item, index) => (
                  <div
                    className="b-bottom section"
                    key={`DistrictCardItemKey${item.lookupsId}`}
                    onClick={() => setActiveDistrict(item)}
                  >
                      <div className="address">
                        <span className="fw-bold">{item.lookupItemName}</span>
                      </div>
                      <div className="address">
                        <Rating
                          size="small"
                          name={`DistrictCardItemId${item.lookupTypeName}-${
                            item.lookupsId
                          }}`}
                          max={10}
                          value={item.score}
                          precision={1}
                          onChange={(event, newValue) => {
                            GetGeographicCriteriaWithScoresAPI(item.lookupsId);
                            let updatelist = [
                              ...districtGeographicCriteriaWithScores,
                            ];
                            const itemIndex = updatelist.indexOf(
                              updatelist.find(
                                (f) => f.lookupsId === item.lookupsId
                              )
                            );
                            if (itemIndex !== -1)
                              updatelist[itemIndex] = {
                                ...updatelist[itemIndex],
                                score: newValue || 0,
                              };

                            setDistrictGeographicCriteriaWithScores([
                              ...updatelist,
                            ]);
                          }}
                        />
                        <span
                          className="px-2"
                          onClick={() => {
                            const displayAllDistrictCommunities =
                              displayAllReletedLocation &&
                              displayAllReletedLocation.displayAllDistrictCommunities
                                ? null
                                : { id: item.lookupsId, isSelected: true };
                            if (setDisplayAllReletedLocation)
                              setDisplayAllReletedLocation((e) => ({
                                ...e,
                                displayAllDistrictCommunities:
                                  displayAllDistrictCommunities,
                              }));

                          }}
                        >
                          <Minimize />
                        </span>
                      </div>
                    </div>
                ))}
            {searchValue === "" &&
              displayAllReletedLocation &&
              displayAllReletedLocation.displayAllDistrictCommunities &&
              displayAllReletedLocation.displayAllDistrictCommunities
                .isSelected &&
              displayDistrictSelected.map((item, index) => (
                <div
                  className="b-bottom section"
                  key={`DistrictCardItemKey${
                    item.lookupsId + 1
                  }`}
                  onClick={() => setActiveDistrict(item)}
                >
                 
                    <div className="address">
                      <span className="fw-bold">{item.lookupItemName}</span>
                    </div>
                    <div className="address">
                      <Rating
                        size="small"
                        name={`DistrictCardItemId${item.lookupTypeName}-${
                          item.lookupsId + 1
                        }}`}
                        max={10}
                        value={item.score}
                        precision={1}
                        onChange={(event, newValue) => {
                          GetGeographicCriteriaWithScoresAPI(item.lookupsId);
                          let updatelist = [
                            ...districtGeographicCriteriaWithScores,
                          ];
                          const itemIndex = updatelist.indexOf(
                            updatelist.find(
                              (f) => f.lookupsId === item.lookupsId
                            )
                          );
                          if (itemIndex !== -1)
                            updatelist[itemIndex] = {
                              ...updatelist[itemIndex],
                              score: newValue || 0,
                            };
                          setDistrictGeographicCriteriaWithScores([
                            ...updatelist,
                          ]);
                        }}
                      />
                      <span
                        className="px-2"
                        onClick={() => {
                          setDisplayAllReletedLocation((e) => ({
                            ...e,
                            displayAllDistrictCommunities: null,
                          }));
                        }}
                      >
                        <Minimize />
                      </span>
                    </div>
                  </div>
              ))}
            {searchValue !== "" &&
              displayAllReletedLocation &&
              !displayAllReletedLocation.displayAllDistrictCommunities &&
              filterDistrictByName.map((item, index) => (
                <div
                  className="b-bottom section"
                  key={`DistrictFilterNameCardItemKey${item.lookupsId}`}
                  onClick={() => setActiveDistrict(item)}
                >
                  
                    <div className="address">
                      <span className="fw-bold">{item.lookupItemName}</span>
                    </div>
                    <div className="address">
                      <Rating
                        size="small"
                        name={`DistrictFilterNameCardItemId${item.lookupTypeName}-${item.lookupsId}}`}  
                        max={10}
                        value={item.score}
                        precision={1}
                        onChange={(event, newValue) => {
                          GetGeographicCriteriaWithScoresAPI(item.lookupsId);
                          let updatelist = [
                            ...districtGeographicCriteriaWithScores,
                          ];
                          const itemIndex = updatelist.indexOf(
                            updatelist.find(
                              (f) => f.lookupsId === item.lookupsId
                            )
                          );
                          if (itemIndex !== -1)
                            updatelist[itemIndex] = {
                              ...updatelist[itemIndex],
                              score: newValue || 0,
                            };
                          let updatelist2 = [...filterDistrictByName];
                          const itemIndex2 = updatelist2.indexOf(
                            updatelist2.find(
                              (f) => f.lookupsId === item.lookupsId
                            )
                          );
                          if (itemIndex2 !== -1)
                            updatelist2[itemIndex2] = {
                              ...updatelist2[itemIndex2],
                              score: newValue || 0,
                            };

                          setFilterDistrictByName([...updatelist2]);
                          setDistrictGeographicCriteriaWithScores([
                            ...updatelist,
                          ]);
                        }}
                      />
                      <span
                        className="px-2"
                        onClick={() => {
                          setDisplayAllReletedLocation((e) => ({
                            ...e,
                            displayAllDistrictCommunities: {
                              id: item.lookupsId,
                              isSelected: true,
                            },
                          }));
                        }}
                      >
                        <Minimize />
                      </span>
                    </div>
                  </div>
              ))}
            {searchValue !== "" &&
              displayAllReletedLocation &&
              displayAllReletedLocation.displayAllDistrictCommunities &&
              displayAllReletedLocation.displayAllDistrictCommunities
                .isSelected &&
              displayDistrictSelected.map((item, index) => (
                <div
                  className="b-bottom section"
                  key={`DistrictFilterNameCardItemKey${item.lookupsId}`}
                  onClick={() => setActiveDistrict(item)}
                >
                  
                    <div className="address">
                      <span className="fw-bold">{item.lookupItemName}</span>
                    </div>
                    <div className="address">
                      <Rating
                        size="small"
                        name={`DistrictFilterNameCardItemId${item.lookupTypeName}-${
                          item.lookupsId + 1
                        }}`}
                        max={10}
                        value={item.score}
                        precision={1}
                        onChange={(event, newValue) => {
                          GetGeographicCriteriaWithScoresAPI(item.lookupsId);
                          let updatelist = [
                            ...districtGeographicCriteriaWithScores,
                          ];
                          const itemIndex = updatelist.indexOf(
                            updatelist.find(
                              (f) => f.lookupsId === item.lookupsId
                            )
                          );
                          if (itemIndex !== -1)
                            updatelist[itemIndex] = {
                              ...updatelist[itemIndex],
                              score: newValue || 0,
                            };
                          let updatelist2 = [...filterDistrictByName];
                          const itemIndex2 = updatelist2.indexOf(
                            updatelist2.find(
                              (f) => f.lookupsId === item.lookupsId
                            )
                          );
                          if (itemIndex2 !== -1)
                            updatelist2[itemIndex2] = {
                              ...updatelist2[itemIndex2],
                              score: newValue || 0,
                            };

                          setFilterDistrictByName([...updatelist2]);
                          setDistrictGeographicCriteriaWithScores([
                            ...updatelist,
                          ]);
                        }}
                      />
                      <span
                        className="px-2"
                        onClick={() => {
                          setDisplayAllReletedLocation((e) => ({
                            ...e,
                            displayAllDistrictCommunities: null,
                          }));
                        }}
                      >
                        <Minimize />
                      </span>
                    </div>
                  </div>
              ))}
              </div>
          </div>
        </>
      )}
    </>
  );
};
