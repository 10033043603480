import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { InvoiceDetailsForm } from "./InvoiceDetailsForm";
import { InvoiceReceiptsTable } from "./InvoiceReceiptsTable";
import { ButtonBase } from "@material-ui/core";
import { PaymentDetailsTabsData } from "../PaymentDetailsTabsData";

export const InvoiceDetailsView = ({
  activeInvoiceId,
  activeViewHandler,
  parentTranslationPath,
  translationPath,
}) => {
  const { t } = useTranslation(parentTranslationPath);

  return (
    <>
      <InvoiceDetailsForm
        invoiceId={activeInvoiceId}
        parentTranslationPath={parentTranslationPath}
        translationPath={translationPath}
      />
      <div className="d-flex-v-center-h-between mt-3">
        <p className="fw-simi-bold m-0 mb-1 fz-16 mb-1">{t(`${translationPath}Invoice-Receipts`)}</p>
        <ButtonBase
          className="btns theme-propx solid mr-0"
          onClick={()=> activeViewHandler(PaymentDetailsTabsData.InvoiceReceipt)}
        >
          {t(`${translationPath}create-Receipt`)}
        </ButtonBase>
      </div>
      <InvoiceReceiptsTable
        invoiceId={activeInvoiceId}
        parentTranslationPath={parentTranslationPath}
        translationPath={translationPath}
      />
    </>
  );
};
