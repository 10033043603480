import React, { useState, useEffect, useCallback } from 'react';
import { Spinner, Tables } from '../../../../Components';
import { ButtonBase } from '@material-ui/core';
import { UnitDetailsDialog } from '../PublishUnitApprovals/UnitDetailsDialog';
import { useTranslation } from 'react-i18next';
import { GetAllUnitApprovelSettings } from '../../../../Services/UnitApprovalServices/UnitApprovalServices';
import { unitDetailsApprovalHeaderData } from '../PublishUnitApprovals/unitDetailsApprovalHeaderData';
import { TableActions } from '../../../../Enums';
import { DeleteDialogUnitPublishUnit } from '../PublishUnitApprovals/DeleteDialogUnitPublishUnit';

export const UnitDetailsApprovals = ({
  parentTranslationPath,
  translationPath,
  data,
  setData,
  isLoading,
  reducer,
  setIsLoading,
}) => {
  const [loading, setLoading] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [unitApprovalData, setUnitApprovalData] = useState([]);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [activeItem, setActiveItem] = useState(null);

  const { t } = useTranslation(parentTranslationPath);

  const getAllUnitApprovalSettings = async () => {
    setLoading(true);
    const res = await GetAllUnitApprovelSettings({
      pageIndex: 1,
      pageSize: 25,
      approvalSettingType: 2,
    });
    if (!(res && res.status && res.status !== 200)) {
      setUnitApprovalData(res?.result);
    }
    setLoading(false);
  };

  const getActionTable = () => {
    const list = [];

    list.push({
      enum: TableActions.editText.key,
      isDisabled: false,
      externalComponent: null,
    });
    list.push({
      enum: TableActions.deleteText.key,
      isDisabled: false,
      externalComponent: null,
    });
    return list;
  };

  const tableActionClicked = useCallback(
    (actionEnum, item, focusedRow, event) => {
      event.stopPropagation();
      event.preventDefault();

      if (actionEnum === TableActions.editText.key) {
        setIsDialogOpen(true);
        setActiveItem(item);
      }
      if (actionEnum === TableActions.deleteText.key) {
        setIsDeleteDialogOpen(true);
        setActiveItem(item);
      }
    },
    []
  );

  const reloadData = () => {
    getAllUnitApprovalSettings();
  };
  useEffect(() => {
    getAllUnitApprovalSettings();
  }, []);

  return (
    <div className='publish-unit-approvals-wrapper propx-view propx-bg'>
      <Spinner isActive={loading} isAbsolute />
      <div className='header-section'>
        <ButtonBase
          className='btns theme-solid px-3'
          onClick={() => setIsDialogOpen(true)}
        >
          <span className='mdi mdi-plus' />
          {t(`${translationPath}add-new`)}
        </ButtonBase>
      </div>
      <div className='p-3'>
      <Tables
        data={unitApprovalData || []}
        headerData={unitDetailsApprovalHeaderData || []}
        defaultActions={getActionTable()}
        translationPath={translationPath}
        parentTranslationPath={parentTranslationPath}
        activePage={0}
        itemsPerPage={100}
        actionsOptions={{
          onActionClicked: tableActionClicked,
        }}
      />
      </div>
      {isDialogOpen && (
        <UnitDetailsDialog
          isDialogOpen={isDialogOpen}
          onSave={() => {
            setIsDialogOpen(false);
            reloadData();
            setActiveItem(null);
          }}
          isOpenChange={() => {
            setIsDialogOpen(false);
            setActiveItem(null);
          }}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          data={data}
          setData={setData}
          isLoading={isLoading}
          reducer={reducer}
          setIsLoading={setIsLoading}
          activeItem={activeItem}
        />
      )}
      {isDeleteDialogOpen && (
        <DeleteDialogUnitPublishUnit
          activeItem={activeItem}
          open={isDeleteDialogOpen}
          reloadData={reloadData}
          translationPath={translationPath}
          parentTranslationPath={parentTranslationPath}
          close={() => {
            setIsDeleteDialogOpen(false);
            setActiveItem(null);
          }}
          onSave={() => {
            setIsDeleteDialogOpen(false);
            setActiveItem(null);
          }}
        />
      )}
    </div>
  );
};
