export const ActitvityRateEnum = {
    Negative: {
        key: 20704,
        value: 'Negative',
    },
    Neutral: {
        key: 20705,
        value: 'Neutral',
    },
    'Super Negative': {
        key: 20706,
        value: 'Super Negative',
    },
    Positive: {
        key: 20707,
        value: 'Positive',
    },
    'Super Positive': {
        key: 20708,
        value: 'Super Positive',
    },
};
