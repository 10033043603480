import React from 'react';
import { Inputs, SelectComponet } from '../../../../../../Components';
import { useTranslation } from 'react-i18next';

export const SLAExpirationPeriodInputComponent = ({
  parentTranslationPath,
  translationPath,
  state,
  setState,
}) => {
  const timeTypes = [
    {
      key: 1,
      value: 'minutes',
    },
    {
      key: 2,
      value: 'hours',
    },
    {
      key: 3,
      value: 'days',
    },
  ];

  return (
    <Inputs
      idRef='ExpiredPeriodRef'
      labelValue='ExpiredPeriod'
      labelClasses='Requierd-Color'
      value={state.slaExpirationPeriod || 0}
      isWithError
      parentTranslationPath={parentTranslationPath}
      translationPath={translationPath}
      min={0}
      onInputChanged={(event) => {
        let { value } = event.target;
        setState({
          slaExpirationPeriod: +value,
          slaExpirationPeriodFreqency: state.slaExpirationPeriodFreqency ?? 1,
        });
      }}
      endAdornment={
        <SelectComponet
          idRef='ExpiredPeriodTimeTypeRef'
          data={timeTypes}
          value={state.slaExpirationPeriodFreqency ?? 1}
          valueInput='key'
          textInput='value'
          onSelectChanged={(value) => {
            setState({
              slaExpirationPeriodFreqency: +value,
              slaExpirationPeriod: state.slaExpirationPeriod,
            });
          }}
          wrapperClasses='over-input-select w-auto'
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          translationPathForData={translationPath}
        />
      }
    />
  );
};
