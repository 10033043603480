import React, { useCallback, useEffect, useState } from "react";
import moment from "moment";
import clsx from "clsx";
import { Box } from "@material-ui/core";
import {
  ContactClassifications,
  GeneralInformation,
  IdentityDetails,
  VisaDetails,
  SourceFile,
} from "./UI";
import { SourceFileSkeleton } from "../../../../../../Components";
import { useVerticalNav } from "../../../../../../Contexts/VerticalNavContext";
import { useIsDesktop, useQuery } from "../../../../../../Hooks";
import { GetSourceFileForContact } from "../../../../../../Services";

// Styles
import useStyles from "./styles";

function ContactDetails({ details }) {
  const styles = useStyles();

  const [isSourceFileLoading, setIsSourceFileLoading] = useState(true);
  const [sourceFileData, setSourceFileData] = useState({});

  const { isDesktop } = useIsDesktop();
  const { isExpanded } = useVerticalNav();

  const query = useQuery();
  const contactId = query.get("id");

  const getSourceFile = useCallback(async () => {
    setIsSourceFileLoading(true);
    
    try {
      const response = await GetSourceFileForContact(contactId);

      const oldest = response?.reduce((oldest, current) => {
        return moment(oldest.createdOn).isBefore(moment(current.createdOn)) ? oldest : current;
      });

      setSourceFileData(oldest);
    } catch (error) {
      console.error("Failed to fetch activity:", error);
    } finally {
      setIsSourceFileLoading(false);
    }
  }, []);

  useEffect(() => {
    getSourceFile();
  }, []);

  return (
    <Box
      className={clsx(styles.overviewContainer, {
        [styles.overviewContainerExpanded]: isExpanded && isDesktop,
      })}
    >
      <Box className={styles.mainContentBox}>
        <IdentityDetails 
          details={details}
        />
        <VisaDetails 
          details={details}
        />
        <GeneralInformation 
          details={details}
        />
      </Box>
      <Box className={styles.sideContentBox}>
        <ContactClassifications
          details={details}
         />
        {
          isSourceFileLoading ?
            <SourceFileSkeleton />
            :
            <SourceFile
              details={sourceFileData}
            />
        } 
  
      </Box>
    </Box>
  );
}

export default ContactDetails;
