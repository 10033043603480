import React from 'react';

function EditIcon({ fill, ...restProps }) {
  return (
    <svg
      viewBox='0 0 24 24'
      fill={fill ?? ''}
      xmlns='http://www.w3.org/2000/svg'
      {...restProps}
    >
      <path
        d='M18.611 1.204a3.808 3.808 0 0 0-1.991.812c-.176.142-3.41 3.351-7.186 7.131-5.038 5.042-6.91 6.941-7.029 7.127a3.332 3.332 0 0 0-.462 1.286c-.146.947-.458 3.984-.425 4.131.091.403.383.691.802.792.139.033 3.164-.279 4.12-.426a3.316 3.316 0 0 0 1.286-.462c.186-.119 2.089-1.996 7.127-7.029 3.78-3.776 6.988-7.009 7.129-7.183.326-.404.592-.933.725-1.443.087-.336.104-.487.104-.94 0-.453-.017-.604-.104-.94-.482-1.843-2.189-3.033-4.096-2.856m1.013 2.082c1.008.371 1.473 1.527 1.012 2.514-.108.231-.261.4-1.378 1.52l-1.257 1.26-1.29-1.291-1.291-1.29 1.26-1.257c1.144-1.141 1.285-1.269 1.534-1.385.434-.203.976-.23 1.41-.071M15.3 8.72 16.58 10l-4.959 4.959c-3.99 3.991-4.994 4.974-5.14 5.036-.131.057-.544.119-1.493.226-.722.081-1.321.14-1.331.13-.018-.019.244-2.442.288-2.651.013-.066.051-.174.084-.24C4.08 17.355 13.947 7.44 14 7.44c.011 0 .596.576 1.3 1.28'
        fill-rule='evenodd'
        fill={fill ?? ''}
      />
    </svg>
  );
}

export default EditIcon;
