import { UserDataView, CollapsePanelsView } from '../../Views/Home/Administration/UserDataView';

export const UserDataViewRoutes = [
  {
    id: 4,
    path: '/index',
    name: 'SideMenuView.Administration.UsersDataViewing',
    component: UserDataView,
    layout: '/home/Administration/UserDataView',
    default: true,
    isRoute: true,
    authorize: true,
    roles: [],
    groupId: 4,
    order: 2,
    icon: 'mdi mdi-view-list-outline',
    iconActive: 'mdi mdi-view-list-outline c-primary',
    isDisabled: false,
    showInMenu: true,
    isExact: true,
    breadcrumbs: [
      {
        name: 'SideMenuView.Administration.UsersDataViewing',
        isDisabled: false,
        route: '/home/Administration/UserDataView/index',
        groupName: 'system-admin',
      },
    ],
  },
  {
    id: 5,
    path: '/Add',
    name: 'SideMenuView.Administration.CollapsePanelsView',
    component: CollapsePanelsView,
    layout: '/home/Administration/UserDataView',
    default: false,
    isRoute: true,
    authorize: true,
    roles: [],
    groupId: 4,
    order: 3,
    icon: 'icons i-contacts-gray',
    iconActive: 'icons i-contacts-blue-dark',
    // routerLink: '/home/contacts',
    isDisabled: false,
    showInMenu: false,
    isExact: true,
    breadcrumbs: [
      {
        name: 'SideMenuView.Administration.UsersDataViewing',
        isDisabled: false,
        route: '/home/Administration/UserDataView/index',
        groupName: 'system-admin',
      },
      {
        name: 'Add View',
        isDisabled: false,
        route: '/home/Administration/UserDataView/Add',
      },
    ],
  },
  {
    id: 6,
    path: '/View',
    name: 'UserDataView:View',
    component: CollapsePanelsView,
    layout: '/home/Administration/UserDataView',
    default: false,
    isRoute: true,
    authorize: true,
    roles: [],
    groupId: 4,
    order: 3,
    icon: 'icons i-contacts-gray',
    iconActive: 'icons i-contacts-blue-dark',
    isDisabled: false,
    showInMenu: false,
    isExact: true,
    breadcrumbs: [
      {
        name: 'SideMenuView.Administration.UsersDataViewing',
        isDisabled: false,
        route: '/home/Administration/UserDataView/index',
        groupName: 'system-admin',
      },
      {
        name: 'View',
        isDisabled: false,
        route: '/home/Administration/UserDataView/View',
      },
    ],
  },
  {
    id: 7,
    path: '/Edit',
    name: 'UserDataView:EditView',
    component: CollapsePanelsView,
    layout: '/home/Administration/UserDataView',
    default: false,
    isRoute: true,
    authorize: true,
    roles: [],
    groupId: 4,
    order: 3,
    icon: 'icons i-contacts-gray',
    iconActive: 'icons i-contacts-blue-dark',
    isDisabled: false,
    showInMenu: false,
    isExact: true,
    breadcrumbs: [
      {
        name: 'SideMenuView.Administration.UsersDataViewing',
        isDisabled: false,
        route: '/home/Administration/UserDataView/index',
        groupName: 'system-admin',
      },
      {
        name: 'Edit View',
        isDisabled: false,
        route: '/home/Administration/UserDataView/Edit',
      },
    ],
  },
];
