import React, { useState, useCallback } from 'react';
import { PropTypes } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { CreateForm } from '../../../Services';
import { showError, showSuccess } from '../../../Helper';
import { DialogComponent, Spinner, AutocompleteComponent, Inputs } from '../../../Components';

export const AddNewFormDialog = ({
    formsCategoryTabs,
    isOpen,
    reloadData,
    translationPath,
    parentTranslationPath,
    setOpenAddNewForm
}) => {
    const { t } = useTranslation(parentTranslationPath);
    const [isLoading, setIsLoading] = useState(false);
    const [formName, setFormName] = useState([]);
    const [formsCategory, setFormsCategory] = useState([]);
    const [selected, setSelected] = useState(null);

    const handleAddForm = useCallback(async () => {
        setIsLoading(true);
        const body = {
            formsName: formName,
            formsContent: `[{\"data":{\"schema":{\"type":\"object",\"title":\"${formName}",\"properties":{}},\"uiSchema":{\"ui:order":[]}}}]`,
            categoryName: formsCategory
        }
        const res = await CreateForm(body);
        if (!(res && res.status && res.status !== 200)) {
            showSuccess(t(`${translationPath}new-form-added-successfully`));
        } else {
            showError(t(`${translationPath}form-is-already-exist`));
        }
        reloadData();
        setIsLoading(false);
    });

    return (
        <DialogComponent
            maxWidth='sm'
            saveType='button'
            saveText='save'
            titleText='add-new-form'
            SmothMove
            onCancelClicked={() => {
                setOpenAddNewForm(false)
            }}
            onSaveClicked={handleAddForm}
            dialogContent={(
                <div className='AddNewForm-wrapper'>
                    <Spinner isActive={isLoading} isAbsolute />
                    <AutocompleteComponent
                        idRef='formCategoryRef'
                        selectedValues={selected || []}
                        data={formsCategoryTabs || []}
                        multiple={false}
                        chipsLabel={(option) => option.label || ''}
                        displayLabel={(option) => t(`${option.label || ''}`)}
                        getOptionSelected={(option) => formsCategory === option.label}
                        withoutSearchButton
                        inputPlaceholder={t(`${translationPath}form-category`)}
                        isLoading={isLoading}
                        isWithError
                        wrapperClasses={'mb-3'}
                        parentTranslationPath={parentTranslationPath}
                        translationPath={translationPath}
                        onChange={(event, newValue) => {
                            setSelected(newValue);
                            setFormsCategory(newValue.label);
                        }}
                    />
                    <Inputs
                        idRef='formNameRef'
                        variant='outlined'
                        value={formName}
                        inputPlaceholder={t('form-name')}
                        onInputChanged={(e) => { setFormName(e.target.value) }}
                    />
                </div>
            )}
            disableBackdropClick
            isOpen={isOpen}
            translationPath={translationPath}
            parentTranslationPath={parentTranslationPath}
            saveClasses='btns theme-solid bg-primary w-100 mx-2 mb-2'
        />
    );
};
AddNewFormDialog.propTypes = {
    formType: PropTypes.number,
    isOpen: PropTypes.bool.isRequired,
    setState: PropTypes.func.isRequired,
    isQuickAdd: PropTypes.bool.isRequired,
    reloadData: PropTypes.func.isRequired,
    isOpenChanged: PropTypes.func.isRequired,
    translationPath: PropTypes.string.isRequired,
    state: PropTypes.instanceOf(Object).isRequired,
    schema: PropTypes.instanceOf(Object).isRequired,
    selected: PropTypes.instanceOf(Object).isRequired,
    parentTranslationPath: PropTypes.string.isRequired,
    onSelectedChangeHandler: PropTypes.func.isRequired,
};
AddNewFormDialog.defaultProps = {
    formType: null,
};
