import React from 'react';
import { GroupedAvatarComponent } from '../../../../SharedComponents';

const headerData = [
  {
    id: 1,
    label: 'branch',
    input: 'branchName',
  },
  {
    id: 2,
    label: 'active-users',
    input: 'activeUsers',
    component: (row) => {
      while (row.numberOfActiveUserDto?.length < row.numberOfActiveUser) {
        row.numberOfActiveUserDto.push({});
      }
      return (
        <GroupedAvatarComponent
          data={row.numberOfActiveUserDto}
          maxAvatarsNumber={5}
        />
      );
    },
  },
  {
    id: 2,
    label: 'price',
    input: 'price',
    isNumber: true,
    component: (row) => `${row.price} AED`,
  },
  {
    id: 2,
    label: 'invoice-date',
    input: 'invoiceDate',
    isDate: true,
  },
];

export default headerData;
