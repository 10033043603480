/* eslint-disable no-shadow */
import React, { useEffect, useRef } from 'react';
import { Button } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import AddTeamDialog from './TeamUtilties/TeamDialog/AddTeamDialog';
import {
  Spinner, NoDataFoundComponent, Inputs, PermissionsComponent
} from '../../../../Components';
import { ViewTypesTeamANDBusinessGroups } from '../../../../Enums';
import { TreeTeamView } from './TeamUtilties/ViewTreeTeam/TreeTeamView';
import { GetTeam, GetallBusinessGroups } from '../../../../Services/Team';
import { useTitle } from '../../../../Hooks';
import { TeamPermissions } from '../../../../Permissions';
import { getIsAllowedPermission } from '../../../../Helper/Permissions.Helper';
import { TypesTeamANDBusinessGroups } from '../../../../Components/ViewTypesComponent/TypesTeamANDBusinessGroups';

const TeamView = () => {
  const loginResponse = useSelector((state) => state.login.loginResponse);
  const { t } = useTranslation(['TeamView', 'Shared']);
  const [loading, setLoading] = React.useState(false);
  const [editGroup, setEditGroup] = React.useState(false);
  const [groupName, setGroupName] = React.useState('');
  const [groupId, setGroupId] = React.useState('');
  const [groupParent, setGroupParent] = React.useState('');
  const [groupParentId, setGroupParentId] = React.useState('');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [, setResponse] = React.useState({});
  const [responseGroups, setResponseGroups] = React.useState({});
  const [searchedItem, setSearchedItem] = React.useState('');
  const [layoutview, setLayoutview] = React.useState(ViewTypesTeamANDBusinessGroups.family_tree.key);
  const [treeGroup, setTreeGroup] = React.useState(false);
  const [openAddDialog, setopenAddDialog] = React.useState(false);
  const [searchNode, setSearchNode] = React.useState([]);
  const [Response, SetResponse] = React.useState({});
  const [UserData, SetUserData] = React.useState({});
  const [teamId, setTeamId] = React.useState();
  const [isTreeSearch, setIsTreeSearch] = React.useState(true);
  const [isFirstLoad, setisFirstLoad] = React.useState(true);
  const searchTimer = useRef(null);

  useTitle(t('Shared:SideMenuView.Administration.Team'));

  const GetMyTeam = async (pageValue, rowsPerPageValue, searchItem) => {
    setLoading(true);
    if (getIsAllowedPermission(
      Object.values(TeamPermissions),
      loginResponse,
      TeamPermissions.ViewTeam.permissionsId
    )) {
      const res = await GetTeam(pageValue + 1, rowsPerPageValue, searchItem);
      SetResponse(res);
      if (res && res.totalCount === 0) setisFirstLoad(false);
    }
    setLoading(false);
  };

  const GetMyGroups = async () => {
    setLoading(true);
    const res = await GetallBusinessGroups();
    setResponseGroups(res);
    setLoading(false);
  };

  const handleSentData = (groupName, groupParent, groupId, editGroup, groupParentId, teamId) => {
    setGroupName(groupName);
    setGroupParent(groupParent);
    setGroupId(groupId);
    setEditGroup(editGroup);
    setGroupParentId(groupParentId);
    setTeamId(teamId);
  };

  const handleTeamsSearch = async (value) => {
    const res = await GetTeam(1, 100, value);
    setSearchNode(
      res.result
      // .filter((el) => el.teamsName.toLowerCase().includes(e.toLowerCase()))
    );

    if (value === '') setSearchNode('');
  };

  const searchHandler = (event) => {
    const { value } = event.target;
    if (searchTimer.current) clearTimeout(searchTimer.current);

    searchTimer.current = setTimeout(() => {
      if (isTreeSearch) handleTeamsSearch(value);

      else {
        setResponse();
        GetMyTeam(page, rowsPerPage, value);
      }
    }, 700);
  };

  useEffect(() => {
    GetMyGroups();
  }, [setResponseGroups]);

  useEffect(() => {
    GetMyTeam(page, rowsPerPage, searchedItem);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage, searchedItem, setResponse]);

  useEffect(() => {
    if (Response) SetUserData(Response);
  }, [Response]);

  return (
    <>
      <div className='view-wrapper'>
        <Spinner isActive={loading} />
        <div className='header-section'>
          <div className='filter-section px-2'>
            <div className='section'>
              <PermissionsComponent
                permissionsList={Object.values(TeamPermissions)}
                permissionsId={TeamPermissions.AddRecordCard.permissionsId}
              >

                <Button
                  className='btns theme-solid bg-primary'
                  onClick={() => {
                    setopenAddDialog(true);
                    setTreeGroup(false);
                    setGroupParent('');
                    setGroupId('');
                    setGroupName('');
                    setGroupParentId('');
                  }}
                >
                  <span className='mdi mdi-plus' />
                  {t('AddTeamDialog.AddTeam')}
                </Button>
              </PermissionsComponent>
            </div>
            <div className='section px-2'>
              <PermissionsComponent
                permissionsList={Object.values(TeamPermissions)}
                permissionsId={TeamPermissions.ViewTeam.permissionsId}
              >

                <Inputs
                  idRef='SearchTeamhRef'
                  variant='outlined'
                  label={t('SearchTeam')}
                  beforeIconClasses='mdi mdi-magnify mdi-24px c-gray-primary'
                  onInputChanged={searchHandler}
                  inputPlaceholder={t('SearchTeam')}

                />
              </PermissionsComponent>
              <PermissionsComponent
                permissionsList={Object.values(TeamPermissions)}
                permissionsId={TeamPermissions.ViewTeam.permissionsId}
              >
                <TypesTeamANDBusinessGroups
                  activeTypes={[ViewTypesTeamANDBusinessGroups.family_tree.key, ViewTypesTeamANDBusinessGroups.file_tree.key]}
                  initialActiveType={layoutview}
                  onTypeChanged={(type) => {
                    if (type === 'tree') {
                      setIsTreeSearch(true);
                      setLayoutview('tree');
                      setTreeGroup(true);
                      setSearchNode([]);
                      setSearchedItem('');
                    } else if (type === 'filetree') {
                      setIsTreeSearch(true);
                      setLayoutview('filetree');
                      setTreeGroup(true);
                      setSearchNode([]);
                      setSearchedItem('');
                    } else {
                      setIsTreeSearch(false);
                      setLayoutview('tableView');
                      setTreeGroup(false);
                      setSearchNode([]);
                      setSearchedItem('');
                      GetMyTeam(0, 10, '');
                    }
                  }}
                  className='mb-3'
                />
              </PermissionsComponent>
            </div>
          </div>
        </div>
        <PermissionsComponent
          permissionsList={Object.values(TeamPermissions)}
          permissionsId={TeamPermissions.ViewTeam.permissionsId}
        >
          <NoDataFoundComponent />
        </PermissionsComponent>
        <PermissionsComponent
          permissionsList={Object.values(TeamPermissions)}
          permissionsId={TeamPermissions.ViewTeam.permissionsId}
        >
          {
            // layoutview === 'tableView' && (
            //   <ListTeam
            //     handleSentData={handleSentData}
            //     setopenAddDialog={() => setopenAddDialog(true)}
            //     page={page}
            //     isFirstLoad={isFirstLoad}
            //     setPage={(x) => setPage(x)}
            //     rowsPerPage={rowsPerPage}
            //     setRowsPerPage={(x) => setRowsPerPage(x)}
            //     response={UserData}
            //     searchedItem={searchedItem}
            //     reopen={() => setopenUserDialog(true)}
            //     reloadData={async (pageValue, rowsPerPageValue) => {
            //       await GetMyTeam(pageValue, rowsPerPageValue, searchedItem);
            //     }}
            //   />
            // ) ||

            layoutview === 'tree' && (
              <div className='TreeTeamView-groups-wrapper'>
                <TreeTeamView
                  searchNode={searchNode}
                  page={page}
                  setPage={(x) => setPage(x)}
                  rowsPerPage={rowsPerPage}
                  searchedItem={searchedItem}
                  setRowsPerPage={(x) => setRowsPerPage(x)}
                  handleSentData={handleSentData}
                  ChartType=''
                  setopenAddDialog={() => setopenAddDialog(true)}
                  close={() => setopenAddDialog(false)}
                  response={responseGroups}
                  setTreeGroup={(x) => setTreeGroup(x)}
                  reloadData={() => GetMyGroups()}
                  isEdit={editGroup}
                  setIsEdit={(x) => setEditGroup(x)}
                />
              </div>
            ) || layoutview === 'filetree' && (
              <div className='TreeTeamView-groups-wrapper'>
                <TreeTeamView
                  searchNode={searchNode}
                  page={page}
                  ChartType='filetree'
                  setPage={(x) => setPage(x)}
                  rowsPerPage={rowsPerPage}
                  searchedItem={searchedItem}
                  setRowsPerPage={(x) => setRowsPerPage(x)}
                  handleSentData={handleSentData}
                  setopenAddDialog={() => setopenAddDialog(true)}
                  close={() => setopenAddDialog(false)}
                  response={responseGroups}
                  setTreeGroup={(x) => setTreeGroup(x)}
                  reloadData={() => GetMyGroups()}
                  isEdit={editGroup}
                  setIsEdit={(x) => setEditGroup(x)}
                />
              </div>
            )
          }
        </PermissionsComponent>
        <AddTeamDialog
          teamId={teamId}
          setGroupParentId={(x) => setGroupParentId(x)}
          groupParentId={groupParentId}
          groupId={groupId}
          response={UserData}
          setGroupName={(x) => setGroupName(x)}
          setGroupParent={(x) => setGroupParent(x)}
          groupName={groupName}
          groupParent={groupParent}
          isEdit={editGroup}
          setIsEdit={(x) => setEditGroup(x)}
          isTree={treeGroup}
          setTreeGroup={(x) => setTreeGroup(x)}
          open={openAddDialog}
          setopenAddDialog={() => setopenAddDialog(true)}
          close={() => setopenAddDialog(false)}
          reloadData={() => {
            GetMyTeam(page, rowsPerPage, searchedItem);
          }}
          reloadDatalist={() => GetMyGroups()}
        />
      </div>
    </>
  );
};
export default TeamView;
