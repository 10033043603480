import React from 'react'
import { LoadableImageEnum, DefaultImagesEnum } from '../../../Enums';
import { LoadableImageComponant, AutocompleteComponent } from '../../../Components';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useDispatch , useSelector  } from 'react-redux';
import { GlobalOrderFilterActions  } from '../../../store/GlobalOrderFilter/GlobalOrderFilterActions';
import { getDownloadableLink } from '../../../Helper'

export const AgentsComponent = ({
    allLeadAuditorAgents,
    filterByAgent,
    setFilterByAgent,
    translationPath,
    parentTranslationPath,
    filterByTeams,
    setFilterByTeams,
    searchHandler,
    allTeams,
    filterByTeamLead,
    setFilterByTeamLead,
    allTeamLeads,
    allAgents,
    isLoading,
}) => {
    const { t } = useTranslation(parentTranslationPath);
    
    const dispatch = useDispatch();
    const orderFilter = useSelector((state) => state.GlobalOrderFilterReducer);
    return (
        <div className='agents-wrapper'>
            <div className='Lead-auditor-title'>
                <span>{t(`${translationPath}agents`)} </span>
                <span>{allLeadAuditorAgents && allLeadAuditorAgents.totalCount || 0} </span>
            </div>
            <div className='agents-search'>
                <AutocompleteComponent
                    idRef='agentFilterRef'
                    selectedValues={filterByAgent || null}
                    wrapperClasses={'agent-search'}
                    multiple={false}
                    data={allAgents || []}
                    displayLabel={(option) => (option && option.agentName) || ''}
                    getOptionSelected={(option) => (option && option.agentName) || ''}
                    withoutSearchButton
                    inputPlaceholder={t(`${translationPath}filter-by-agent`)}
                    isWithError
                    isLoading={isLoading.getAllAgents}
                    parentTranslationPath={parentTranslationPath}
                    translationPath={translationPath}
                    onInputKeyUp={searchHandler}
                    onChange={(event, newValue) => {
                        setFilterByAgent(newValue);
                    }}
                />
                <AutocompleteComponent
                    idRef='TeamLeadFilterRef'
                    selectedValues={filterByTeamLead || null}
                    wrapperClasses={'agent-search'}
                    multiple={false}
                    data={allTeamLeads || []}
                    displayLabel={(option) => (option && option.teamLeadName) || ''}
                    getOptionSelected={(option) => (option && option.teamLeadName) || ''}
                    withoutSearchButton
                    inputPlaceholder={t(`${translationPath}filter-by-team-lead`)}
                    isWithError
                    isLoading={isLoading.getAllTeamLeads}
                    parentTranslationPath={parentTranslationPath}
                    translationPath={translationPath}
                    onInputKeyUp={searchHandler}
                    onChange={(event, newValue) => {
                        setFilterByTeamLead(newValue);
                    }}
                />
                <AutocompleteComponent
                    idRef='TeamFilterRef'
                    selectedValues={filterByTeams || null}
                    wrapperClasses={'agent-search'}
                    multiple={false}
                    data={allTeams || []}
                    displayLabel={(option) => (option && option.teamsName) || ''}
                    getOptionSelected={(option) => (option && option.teamsName) || ''}
                    withoutSearchButton
                    inputPlaceholder={t(`${translationPath}filter-team`)}
                    isWithError
                    isLoading={isLoading.getAllTeam}
                    parentTranslationPath={parentTranslationPath}
                    translationPath={translationPath}
                    onInputKeyUp={searchHandler}
                    onChange={(event, newValue) => {
                        setFilterByTeams(newValue);
                    }}
                />
            </div>
            <div className='agents-cards-wrapper'>
                {allLeadAuditorAgents && allLeadAuditorAgents.result.map((item, index) =>
                    <div className='leads-cards-wrapper '>
                        <div className='avatar w-100 d-flex'>
                            <LoadableImageComponant
                                type={LoadableImageEnum.image.key}
                                classes='agents-image'
                                alt={'user-image'}
                                src={item.profileImg && getDownloadableLink(item.profileImg) || DefaultImagesEnum.man.defaultImg}
                            />
                            <div className='w-100'>
                                <div className='title-wrapper'>
                                    <span>{item.agentName}</span>
                                    <span>{t(`${translationPath}lead-owner `)}{item.leadAduitorName || 'N/A'}</span>
                                </div>
                                <div className='leads-row'>
                                    <div className='leads-numbers'>
                                        {<span className='hot lead-number'>
                                          {(item && item.numberOfHotLead)|| '0'}</span>}
                                        {<span className='warm lead-number'> 

                                        {(item && item.numberOfWarmLead) || '0'}</span>}

                                        {<span className='cold lead-number' >

                                          {(item && item.numberOfColdLead) || '0'}</span>}

                                        {<span className='hot lead-number'>

                                          {item.percentageOfHotLead && `${item.percentageOfHotLead}%` || '0'}</span>}
                                        {<span className='warm lead-number'>

                                            {item.percentageOfWarmLead && `${item.percentageOfWarmLead}%` || '0'}</span>}
                                         {
                                            <span className='cold lead-number'>
                                             {item.percentageOfColdLead && `${item.percentageOfColdLead}%` || '0'}
                                             </span>
                                         }
                                      </div>
                                </div>

                            </div>
                        </div>
                        <div className='agent-row d-flex'>
                            <div className='mr-2'>
                                <span>{t(`${translationPath}team-lead`)}</span>
                                <span>{item.teamLeadName || 'N/A'}</span></div>
                            <div className='mr-2'>
                                <span>{t(`${translationPath}team`)}</span>
                                <span>{item.teamName || 'N/A'}</span></div>
                            <div className='mr-2'>
                                <span>{t(`${translationPath}active`)}</span>
                                <span>{item.numberOfOpenLead || 'N/A'}</span>
                            </div>
                            <div className='mr-2'> 
                                <span>{t(`${translationPath}inactive`)}</span>
                                <span>{item.numberOfClosedLead || 'N/A'}</span>
                            </div>

                        </div>
                    </div>
                ) || null}
            </div>
        </div>
    )
}

AgentsComponent.propTypes = {
    allLeadAuditorAgents: PropTypes.instanceOf(Object).isRequired,
    setFilterByAgent: PropTypes.func.isRequired,
    parentTranslationPath: PropTypes.string.isRequired,
    translationPath: PropTypes.string.isRequired,
    setFilterByTeams: PropTypes.func.isRequired,
    filterByTeams: PropTypes.instanceOf(Object).isRequired,
    searchHandler: PropTypes.func.isRequired,
    allTeams: PropTypes.arrayOf(PropTypes.instanceOf(Object)).isRequired,
    allTeamLeads: PropTypes.arrayOf(PropTypes.instanceOf(Object)).isRequired,
    allAgents: PropTypes.arrayOf(PropTypes.instanceOf(Object)).isRequired,
    filterByTeamLead: PropTypes.func.isRequired,
    setFilterByTeamLead: PropTypes.instanceOf(Object).isRequired,
    isLoading: PropTypes.bool,
};

