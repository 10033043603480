import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  Button,
  DialogTitle,
  DialogContent,
  DialogActions,
  Dialog,
  Tooltip,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import Joi from 'joi';
import {
  CreateCompanyFinance,
  ViewFinanceCorporate,
} from '../../../../../../../Services';
import {
  getErrorByName,
  GetParams,
  setloginDialog,
  showError,
  showSuccess,
} from '../../../../../../../Helper';
import {
  AutocompleteComponent,
  Inputs,
  PermissionsComponent,
} from '../../../../../../../Components';
import { PropertiesPermissionsCRM } from '../../../../../../../Permissions/PropertiesPermissions';

export const AddFinanceCompanyDialog = ({
  open,
  close,
  translationPath,
  t,
  reloadData,
}) => {
  const defaultState = {
    company: {},
    companyName: '',
    companyId: null,
    termOfLoanYears: '',
    downPaymentPercentage: '',
    interestRate: '',
    remarks: '',
    propertyId: +GetParams('id'),
  };
  const [state, setState] = useState(defaultState);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [propertiesFinanceCorporate, setPropertiesFinanceCorporate] = useState(
    []
  );
  const [filter, setFilter] = useState({
    pageIndex: 1,
    pageSize: 25,
    search: null,
  });
  const [isLoading, setIsLoading] = useState(false);
  const timerRef = useRef();

  const schema = Joi.object({
    companyName: Joi.string()
      .required()
      .messages({
        'string.base': t(`${translationPath}company-name-is-required`),
        'string.empty': t(`${translationPath}company-name-is-required`),
      }),
    termOfLoanYears: Joi.number()
      .required()
      .min(1)
      .messages({
        'number.base': t(`${translationPath}term-of-loan-is-required`),
        'number.empty': t(`${translationPath}term-of-loan-is-required`),
        'number.min': t(
          `${translationPath}term-of-loan-years-must-be-greater-than-one`
        ),
      }),

    downPaymentPercentage: Joi.number()
      .min(0.1)
      .max(100)
      .required()
      .messages({
        'number.base': t(`${translationPath}down-payment-is-required`),
        'number.min': t(
          `${translationPath}down-payment-it-must-be-greater-than-zero`
        ),
        'number.max': t(`${translationPath}down-payment-is-must-less-than-100`),
        'number.empty': t(`${translationPath}down-payment-is-required`),
      }),
    interestRate: Joi.number()
      .required()
      .min(0.1)
      .max(100)
      .messages({
        'number.base': t(`${translationPath}intrest-rate-is-required`),
        'number.min': t(
          `${translationPath}intrest-rate-it-must-be-greater-than-zero`
        ),
        'number.max': t(`${translationPath}intrest-rate-is-must-less-than-100`),
        'number.empty': t(`${translationPath}intrest-rate-is-required`),
      }),
  })
    .options({
      abortEarly: false,
      allowUnknown: true,
    })
    .validate(state);

  const saveHandler = useCallback(async () => {
    setIsSubmitted(true);
    if (schema.error) {
      showError(t('Shared:please-fix-all-errors'));
      setloginDialog(false);
      return;
    }
    const result = await CreateCompanyFinance(state);
    if (!(result && result.status && result.status !== 200)) {
      close();
      setState(defaultState);
      showSuccess(t(`${translationPath}company-finance-created-successfully`));
      reloadData();
    } else showError(t(`${translationPath}company-finance-created-failed`));
  }, [
    close,
    defaultState,
    reloadData,
    schema.error,
    state,
    t,
    translationPath,
  ]);

  const PropertiesFinanceCorporate = async (search) => {
    setIsLoading(true);
    const result = await ViewFinanceCorporate({ ...filter, search });
    if (!(result && result.status && result.status !== 200)) {
      setPropertiesFinanceCorporate(result.result);
    }
    setIsLoading(false);
  };

  const searchHandler = (e) => {
    const { value } = e.target;
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
    timerRef.current = setTimeout(() => PropertiesFinanceCorporate(value), 700);
  };

  return (
    <Dialog
      open={open}
      keepMounted
      onClose={() => {
        close();
        setIsSubmitted(true);
        setState(defaultState);
      }}
      className='add-new-company-finance'
    >
      <form
        noValidate
        onSubmit={(event) => {
          event.preventDefault();
          close();
        }}
      >
        <DialogTitle>{t(`${translationPath}AddnewFinance`)}</DialogTitle>
        <DialogContent>
          <Tooltip
            title={<strong>Finance and Bank industry contacts only</strong>}
            placement='right'
          >
            <div>
              <AutocompleteComponent
                idRef='activitiesSearchRef'
                labelValue={t(`${translationPath}Companyname`)}
                selectedValues={state.company ?? ''}
                data={propertiesFinanceCorporate || []}
                isRequired
                helperText={getErrorByName(schema, 'companyName').message}
                error={getErrorByName(schema, 'companyName').error}
                isWithError
                isSubmitted={isSubmitted}
                labelClasses='Requierd-Color'
                onInputKeyUp={(e) => searchHandler(e)}
                isLoading={isLoading}
                displayLabel={(option) => option?.contact?.company_name ?? ''}
                multiple={false}
                onChange={(e, newValue) =>
                  setState((currentState) => ({
                    ...currentState,
                    companyId: newValue?.contactsId,
                    companyName: newValue?.contact?.company_name,
                    company: newValue,
                  }))
                }
                getOptionSelected={(option) =>
                  option.contactId === state.contactId || ''
                }
                withoutSearchButton
              />
            </div>
          </Tooltip>
          <Inputs
            isRequired
            helperText={getErrorByName(schema, 'termOfLoanYears').message}
            error={getErrorByName(schema, 'termOfLoanYears').error}
            isWithError
            isSubmitted={isSubmitted}
            value={state.termOfLoanYears}
            type='number'
            min={0}
            idRef='activitiesSearchRef'
            labelValue={t(`${translationPath}Termofloan`)}
            labelClasses='Requierd-Color'
            onInputChanged={(e) =>
              setState({ ...state, termOfLoanYears: +e.target.value })
            }
          />
          <Inputs
            isRequired
            helperText={getErrorByName(schema, 'downPaymentPercentage').message}
            error={getErrorByName(schema, 'downPaymentPercentage').error}
            isWithError
            type='number'
            min={0}
            isSubmitted={isSubmitted}
            value={state.downPaymentPercentage}
            idRef='activitiesSearchRef'
            labelClasses='Requierd-Color'
            labelValue={t(`${translationPath}DownPayment`)}
            onInputChanged={(e) =>
              setState({ ...state, downPaymentPercentage: +e.target.value })
            }
          />
          <Inputs
            isRequired
            helperText={getErrorByName(schema, 'interestRate').message}
            error={getErrorByName(schema, 'interestRate').error}
            isWithError
            type='number'
            min={0}
            isSubmitted={isSubmitted}
            value={state.interestRate}
            idRef='activitiesSearchRef'
            labelClasses='Requierd-Color'
            labelValue={t(`${translationPath}Interestrate`)}
            onInputChanged={(e) =>
              setState({ ...state, interestRate: +e.target.value })
            }
          />
          <Inputs
            value={state.remarks}
            rows={4}
            rowsMax={10}
            multiline
            idRef='activitiesSearchRef'
            labelValue={t(`${translationPath}Remarks`)}
            onInputChanged={(e) =>
              setState({ ...state, remarks: e.target.value })
            }
          />
        </DialogContent>
        <DialogActions>
          <Button
            className='btns theme-solid bg-cancel'
            onClick={() => close()}
          >
            {t(`${translationPath}Cancel`)}
          </Button>
          <PermissionsComponent
            permissionsList={Object.values(PropertiesPermissionsCRM)}
            permissionsId={
              PropertiesPermissionsCRM.AddFinanceCompanyForPropertry
                .permissionsId
            }
          >
            <Button
              onClick={saveHandler}
              className='btns theme-solid'
              variant='contained'
            >
              {t(`${translationPath}AddNew`)}
            </Button>
          </PermissionsComponent>
        </DialogActions>
      </form>
    </Dialog>
  );
};

AddFinanceCompanyDialog.propTypes = {
  translationPath: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  close: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
  reloadData: PropTypes.func.isRequired,
};
