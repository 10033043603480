import React from "react";
import { Box, Avatar } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

import {
  CustomBadge,
  CustomTableHeader,
  CustomCopyToClipboard,
  CustomIconButton,
} from "../../../../../../Components";
import { getDownloadableLink, truncateString } from "../../../../../../Helper";
import { formatCommasForPriceUnit } from "../../../../../../Helper/formatCommas.Helper";
import {
  ArrowUpRight,
  UnitSizeIcon,
  BedIcon,
  BathIcon,
  MaidIcon,
  EmptyUnitGalleryIcon,
} from "../../../../../../assets/icons";

import useStyles from "./styles";

const ContactName = ({ item, selectType }) => {
  const { isDarkMode } = useSelector((state) => state.theme);
  const styles = useStyles();
  const history = useHistory();

  const handleClick = (item) => {
    if (history) {
      if (selectType === "Sale") {
        history.push(
          `/home/units-sales/unit-profile-edit?formType=${item?.row?.unitTypeId}&id=${item?.row?.id}&operationType=${item?.row?.operationType}`
        );
      } else {
        history.push(
          `/home/units-lease/unit-profile-edit?formType=${item?.row?.unitTypeId}&id=${item?.row?.id}&operationType=${item?.row?.operationType}`
        );
      }
    }
  };

  const getFullName = () => {
    return item?.row?.name || "N/A";
  };

  const displayName = getFullName();
  const truncatedName =
    displayName?.length >= 18 ? truncateString(displayName, 35) : displayName;

  return (
    <Box className={styles.container}>
      <Box>
        {item?.row?.allunitImages ? (
          getDownloadableLink(item?.row?.allunitImages?.fileId) && (
            <Avatar
              className={styles.galleryImg}
              src={
                item?.row?.allunitImages?.fileId
                  ? getDownloadableLink(item?.row?.allunitImages?.fileId)
                  : ""
              }
            />
          )
        ) : (
          <EmptyUnitGalleryIcon
            width="63"
            height="40"
            fill={!isDarkMode ? "#98A2B3" : "#85888E"}
          />
        )}
      </Box>

      <Box className={styles.detailsColumn}>
        <Box className={styles.nameRow} onClick={() => handleClick(item)}>
          <span className={styles.nameText}>{truncatedName}</span>
          <CustomIconButton
            variant="text"
            size="xs"
            boxShadow="none"
            color="secondary"
          >
            <ArrowUpRight
              width="20"
              height="20"
              fill={!isDarkMode ? "#344054" : "#CECFD2"}
            />
          </CustomIconButton>
        </Box>

        <p className={styles.communityText}>
          {item?.row?.community?.lookupItemName +
            (item?.row?.sub_community?.lookupItemName
              ? ", " + item?.row?.sub_community?.lookupItemName
              : "")}
        </p>

        <Box className={styles.unitDetails}>
          <Box className={styles.detailItem}>
            <UnitSizeIcon
              width="20"
              height="20"
              fill={!isDarkMode ? "#98A2B3" : "#85888E"}
            />
            <span>{item?.total_area_size_sqft || "N/A"} sq.ft</span>
          </Box>
          <Box className={styles.detailItem}>
            <BedIcon
              width="20"
              height="20"
              fill={!isDarkMode ? "#98A2B3" : "#85888E"}
            />
            <span>{item?.bedrooms || "any"}</span>
          </Box>
          <Box className={styles.detailItem}>
            <BathIcon
              width="20"
              height="20"
              fill={!isDarkMode ? "#98A2B3" : "#85888E"}
            />
            <span>{item?.bathrooms || "any"}</span>
          </Box>
          <Box className={styles.detailItem}>
            <MaidIcon
              width="20"
              height="20"
              fill={!isDarkMode ? "#98A2B3" : "#85888E"}
            />
            <span>{item?.maid || "N/A"}</span>
          </Box>
        </Box>

        <CustomCopyToClipboard
          data={item?.row?.unit_ref_no} // copy
          childrenData={"#" + item?.row?.unit_ref_no} // render
          copyDoneShowTime={1000}
        />
      </Box>
    </Box>
  );
};

export const unitTableHeaderData = (
  isDarkMode = false,
  selectType = "Sale"
) => [
  {
    id: 1,
    formFieldId: 1,
    minWidth: 500,
    headerName: "Unit name",
    field: "name",
    isHiddenFilter: true,
    sortable: true,
    filterable: true,
    fieldType: "text",
    displayPath: "name",
    isDefaultFilterColumn: true,
    fieldKey: "name",
    formFieldTitle: "name",
    disableColumnMenu: true,
    searchKey: "name",
    renderHeader: (params) => (
      <CustomTableHeader
        params={params}
        title="Unit name"
        isHiddenFilter={true}
      />
    ),
    renderCell: (item) => {
      return <ContactName item={item} selectType={selectType} />;
    },
  },
  {
    id: 2,
    formFieldId: 2,
    minWidth: 130,
    headerName: "Unit number",
    isHiddenFilter: true,
    field: "unit_ref_no",
    fieldType: "text",
    sortable: true,
    filterable: true,
    displayPath: "unit_ref_no",
    isDefaultFilterColumn: true,
    disableColumnMenu: true,
    fieldKey: "unit_ref_no",
    formFieldTitle: "Unit number",
    renderHeader: (params) => (
      <CustomTableHeader
        params={params}
        title="Unit number"
        isHiddenFilter={true}
      />
    ),
    renderCell: (item) => {
      return (
        <label
          style={{
            fontWeight: 400,
            color: !isDarkMode ? "#475467" : "#94969C",
          }}
        >
          {item?.row?.unit_ref_no || "N/A"}
        </label>
      );
    },
  },
  {
    id: 3,
    formFieldId: 3,
    headerName: "Unity Type",
    width: 130,
    isHiddenFilter: true,
    field: "unitType",
    fieldKey: "unitType",
    fieldType: "text",
    disableColumnMenu: true,
    displayPath: "unitType",
    sortable: true,
    filterable: true,
    isDefaultFilterColumn: true,
    formFieldTitle: "Unity Type",
    renderHeader: (params) => (
      <CustomTableHeader
        params={params}
        title="Unity Type"
        isHiddenFilter={true}
      />
    ),
    renderCell: (item) => {
      return (
        <label
          style={{
            fontWeight: 400,
            color: !isDarkMode ? "#475467" : "#94969C",
          }}
        >
          {item?.row?.unitType || "N/A"}
        </label>
      );
    },
  },
  {
    id: 4,
    formFieldId: 4,
    minWidth: 130,
    headerName: "Status",
    field: "status",
    fieldType: "text",
    isHiddenFilter: true,
    fieldKey: "status",
    sortable: true,
    displayPath: "status",
    filterable: true,
    disableColumnMenu: true,
    isDefaultFilterColumn: true,
    formFieldTitle: "Status",
    renderHeader: (params) => (
      <CustomTableHeader params={params} title="Status" isHiddenFilter={true} />
    ),
    renderCell: (item) => {
      return (
        <CustomBadge
          key={item?.row?.id}
          label={item?.row?.unitItem?.status}
          BackgroundColor={!isDarkMode ? "#F8F9FC" : "#101323"}
          BorderColor={!isDarkMode ? "#D5D9EB" : "#293056"}
          Color={!isDarkMode ? "#363F72" : "#B3B8DB"}
        />
      );
    },
  },
  {
    id: 5,
    formFieldId: 5,
    minWidth: 130,
    headerName: "Sale price",
    field: "selling_price_agency_fee",
    isHiddenFilter: true,
    filterable: true,
    disableColumnMenu: true,
    fieldType: "datePicker",
    displayPath: "selling_price_agency_fee",
    isDate: true,
    sortable: true,
    isDefaultFilterColumn: true,
    fieldKey: "selling_price_agency_fee",
    formFieldTitle: "Sale price",
    renderHeader: (params) => (
      <CustomTableHeader
        params={params}
        title="Sale price"
        isHiddenFilter={true}
      />
    ),
    renderCell: (item) => {
      return (
        <label
          style={{
            fontWeight: 400,
            color: !isDarkMode ? "#475467" : "#94969C",
          }}
        >
          {item?.row?.selling_price_agency_fee &&
          item?.row?.selling_price_agency_fee !== "N/A"
            ? `AED ${formatCommasForPriceUnit(
                item?.row?.selling_price_agency_fee
              )}`
            : "N/A"}
        </label>
      );
    },
  },
  {
    id: 6,
    formFieldId: 6,
    minWidth: 222,
    headerName: "Listing Agent",
    field: "listing_agent",
    displayPath: "listing_agent",
    isHiddenFilter: true,
    filterable: true,
    fieldType: "datePicker",
    disableColumnMenu: true,
    fieldKey: "listing_agent",
    isDate: true,
    sortable: true,
    isDefaultFilterColumn: true,
    formFieldTitle: "Listing Agent",
    renderHeader: (params) => (
      <CustomTableHeader
        params={params}
        title="Listing Agent"
        isHiddenFilter={true}
      />
    ),
    renderCell: (item) => {
      return (
        <label style={{ fontWeight: 400 }}>
          {item?.row?.unitItem?.listing_agent?.name || "N/A"}
        </label>
      );
    },
  },
];
