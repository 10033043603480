import React, {
  useEffect, useState, useCallback, useReducer
} from 'react';
import {
  BulkSelectedUnitsSection,
  NewValuesSection,
  UnitFieldsSection,
  BulkAssignActions,
} from './Sections';
import { FormErrorsHandler, GlobalHistory, formItemsBuilderV3 } from '../../../Helper';
import { useLocalStorage } from '../../../Hooks';
import { GetAllFormFieldsByFormId, GetFieldsNeedingApprovalForChange, GetPendingUserFieldsForUnits } from '../../../Services';
import { Spinner } from '../../../Components';
import { FormsIdsEnum } from '../../../Enums';
import { useSelector } from 'react-redux';
import { FieldsNeedsApprovalDialogBulkUnits } from '../UnitsSalesView/UnitsSalesUtilities/Dialogs/UnitsBulkStatusUpdateDialog/FieldsNeedsApprovalDialogBulkUnits';


const excludedFields = [
  'operation_type', 
  'owner', 
  'amount_due-to-developer', 
  'pricesqm', 
  'unit_ref-no', 
  'builtup_area-sqm',
  'unit_model'
];
const parentTranslationPath = 'BulkAssign';
const translationPath = '';
export const UnitsBulkAssignView = () => {
  const [bulkedUnits] = useLocalStorage('bulk-assign-ids');
  const [UnitsIds] = useLocalStorage('units-ids-Array');
  

  const [isLoading, setIsLoading] = useState(false);
  const [formFields, setFormFields] = useState([]);
  const [formFieldIds, setFormFieldIds] = useState(formFields);
  const [unitStatus, setUnitStatus] = useState({
    failure: [],
    success: [],
    message: []
  });
  const [formFieldsData, setFormFieldsData] = useState([]);
  const [formData, setFormData] = useState([]);
  const [unitCards, setUnitCards] = useState(bulkedUnits);
  
  const [unitIds, setUnitIds] = useState(UnitsIds);
  
  
  const [unitDetails, setUnitDetails] = useState({});
  
  const [errors, setErrors] = useState([]);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [unitInitDetails, setIntUnitDetails] = useState([]);
  const [initialData, setInitialData] = useState({});
  
  
  const [pendingUserFields, setPendingUserFields] = useState(null);
  const [fieldsNeedsApproval, setFieldsNeedsApproval] = useState([]);
  const [fieldsNeedsApprovalDialog , setFieldsNeedsApprovalDialog] = useState(false)
  const loginResponse = useSelector((state) => state.login.loginResponse);
  const getResponseErrorMessage = (unitId) => {
    unitStatus.message.find((message) => {
      const [responseUnitId, errorMessage] = message.split('-');
      if (+responseUnitId.trim() === unitId) {
        return errorMessage.trim();
      }
      return;
    });
  };
  useEffect(() => {
    if (bulkedUnits && bulkedUnits.length < 2)
      GlobalHistory.goBack();
  }, [bulkedUnits]);
  const reducer = useCallback((state, action) => {
    if (action.id !== 'edit') return { ...state, [action.id]: action.value };
    return {
      ...action.value,
    };
  }, []);
  const defaultState = {
    bulkUpdateUnits: [
      {
        unitId: 0,
        rowVersion: '',
      },
    ],
    fieldsName: [],
    updatedUnitJson: {},
  };
  const [state, setState] = useReducer(reducer, defaultState);
  useEffect(() => {
    let units = [];
    const fieldsNames = [];
    unitInitDetails.map((item) => {
      if (!unitDetails[item]) unitDetails[item] = null;
    });
    unitCards.map((item) =>
      units.push({
        unitId: item.id,
        rowVersion: item.rowVersion,
      }));
    if (!!unitStatus.failure.length) {
      units = units.filter((item) => unitStatus.failure.includes(item.unitId));
    }
    formFieldIds.map((item) => fieldsNames.push(item.formFieldName));
    setState({ id: 'bulkUpdateUnits', value: units });
    setState({ id: 'updatedUnitJson', value: unitDetails });
    setState({ id: 'fieldsName', value: fieldsNames });
  }, [formFieldIds, unitCards, unitDetails, unitInitDetails, unitStatus]);
  const getAllFormFieldsByFormId = useCallback(async () => {
    setIsLoading(true);
    const result = await GetAllFormFieldsByFormId(FormsIdsEnum.units.id);
    if (!(result && result.status && result.status !== 200)) {
      const listWithoutUpdateSameFields = result?.filter((item) => !excludedFields.includes(item.formFieldKey));
      setFormFields(listWithoutUpdateSameFields || []);
    } else setFormFields([]);
    setIsLoading(false);
  }, []);
  const onUnitCardsChange = (value) => {
    setUnitCards(value);
  };
  useEffect(() => {
    getAllFormFieldsByFormId();
  }, [getAllFormFieldsByFormId]);
  useEffect(() => {
    setFormData(formItemsBuilderV3([], formFieldsData , undefined , pendingUserFields));
  }, [formFieldsData ,pendingUserFields]);
  useEffect(() => {
    if (formData.length > 0) setErrors(FormErrorsHandler(formData, unitDetails));
  }, [unitDetails, formData]);

  const getFieldsNeedingApproval = async () => {
    const userRoleIds = loginResponse?.roles.map((item) => item?.rolesId);

    const res = await GetFieldsNeedingApprovalForChange({ userRoleIds });
    if (!(res && res.status && res.status !== 200)) {
      setFieldsNeedsApproval(res);
    } else setFieldsNeedsApproval([]);
  };

  const getPendingUserFields = async () => {
    const res = await GetPendingUserFieldsForUnits(unitIds);
    if (!((res && res.data && res.data.ErrorId) || !res)) {
     
    const pendingFields= res?.map(field=>field.pendingFields) 
      setPendingUserFields(pendingFields[0])
      return res;
    }
  };

  useEffect(() => {
    getFieldsNeedingApproval();
    getPendingUserFields();
  }, []);

  

  useEffect(() => {
    if ( unitDetails && fieldsNeedsApproval) {
      const details = {
        unit_number: unitDetails?.unit_number,
        unit_type: unitDetails?.unit_type,
        property_name: unitDetails?.property_name,
        bedrooms: unitDetails?.bedrooms,
        bathrooms: unitDetails?.bathrooms,
        selling_price_agency_fee:unitDetails?.selling_price_agency_fee,
        rent_price_fees : unitDetails?.rent_price_fees,
        sale_type : unitDetails?.sale_type,
        builtup_area_sqm : unitDetails?.builtup_area_sqm,
        builtup_area_sqft : unitDetails?.builtup_area_sqft,

      };

      const fieldsNeedsApprovalWithUnderscore = fieldsNeedsApproval.map(field =>
        field.replaceAll('-', '_')
      );

      const requiredFields = [
        "unit_number",
        "unit_type",
        "property_name",
        "bedrooms",
        "bathrooms",
        'sale_type',
        'builtup_area_sqm',
        'builtup_area_sqft'
      ];

      const filteredFields = fieldsNeedsApprovalWithUnderscore.filter(field =>
        requiredFields.includes(field)
      );
      const neededFields = {};
      filteredFields.forEach((field) => {
        neededFields[field] = details[field];
      });
      setInitialData(neededFields);
      
    }
  }, [unitDetails , fieldsNeedsApproval]);
  return (
    <>
      <div className='px-5 units-bulk-assign-view-wrapper'>
        <Spinner isActive={isLoading} />
        <div className='bulk-section-wrapper'>
          <BulkSelectedUnitsSection
            unitCards={unitCards}
            unitStatus={unitStatus}
            bulkedUnits={bulkedUnits}
            translationPath={translationPath}
            onUnitCardsChange={onUnitCardsChange}
            parentTranslationPath={parentTranslationPath}
          />
        </div>
        <span className='section-seperator mdi mdi-arrow-collapse-right c-gray' />
        <div className='bulk-section-wrapper'>
          <UnitFieldsSection
            formFields={formFields}
            unitDetails={unitDetails}
            formFieldIds={formFieldIds}
            setFormFieldIds={setFormFieldIds}
            translationPath={translationPath}
            setIntUnitDetails={setIntUnitDetails}
            setFormFieldsData={setFormFieldsData}
            parentTranslationPath={parentTranslationPath}
          />
        </div>
        <span className='section-seperator mdi mdi-arrow-collapse-right c-gray' />
        <div className='bulk-section-wrapper'>
          <NewValuesSection
            errors={errors}
            formData={formData}
            setErrors={setErrors}
            isSubmitted={isSubmitted}
            unitDetails={unitDetails}
            setFormData={setFormData}
            formFieldIds={formFieldIds}
            setUnitDetails={setUnitDetails}
            translationPath={translationPath}
            setIntUnitDetails={setIntUnitDetails}
            parentTranslationPath={parentTranslationPath}
            initialData={initialData}
            fieldsNeedsApproval={fieldsNeedsApproval}
          />
        </div>
      </div>
      <BulkAssignActions
        state={state}
        errors={errors}
        setIsLoading={setIsLoading}
        unitStatus={unitStatus}
        setUnitStatus={setUnitStatus}
        setIsSubmitted={setIsSubmitted}
        translationPath={translationPath}
        parentTranslationPath={parentTranslationPath}
        initialData={initialData}
        fieldsNeedsApproval={fieldsNeedsApproval}
        setFieldsNeedsApprovalDialog={setFieldsNeedsApprovalDialog}
      />
      {fieldsNeedsApprovalDialog && (
        <FieldsNeedsApprovalDialogBulkUnits
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          setIsDialogOpen={setFieldsNeedsApprovalDialog}
          isDialogOpen={fieldsNeedsApprovalDialog}
          checkedCards={unitCards}
          unitsIdUpdateArray={unitIds}
          initialData={initialData}
          unitCards={unitCards}
          reload={()=>{
            getFieldsNeedingApproval();
            getPendingUserFields();
            getAllFormFieldsByFormId()
          }}
        />
      )}
    </>
  );
};
