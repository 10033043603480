import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { ButtonBase } from '@material-ui/core';
import { useLocation } from "react-router-dom";
import { GetParams, showError, showSuccess } from '../../../../../../../Helper';
import { DialogComponent, Spinner } from '../../../../../../../Components';
import {
  CreatePropertyImage,
  GetAllPropertyImagesCategoryByPropertyId,
  UpdatePropertyImage,
} from '../../../../../../../Services';
import { ImagesGalleryAlbumsCardComponent } from '../../../../../ImagesGallery/Sections/ImagesGalleryAlbumsComponent/Sections';
import { UnitProfileImagesCardActionEnum } from '../../../../../../../Enums';
import { ImageDialogsProperties } from './ImageDialogsProperties';

export const PropertiesImage = ({
  parentTranslationPath,
  translationPath,
  HideEdit,
  canEditImage,
  activeTab
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isCategoryExist, setIsCategoryExist] = useState(false);
  // const [uploaderImg, setUploaderImg] = useState([]);

  const [response, setresponse] = useState([]);
  const [state, setstate] = useState([]);
  const { t } = useTranslation(parentTranslationPath);
  const [isOpenDialog, setIsOpenDialog] = useState(false);
  const [activeItem, setActiveItem] = useState(null);
  const property = true;
  const location = useLocation();
  const getAllPropertyImagesCategory = useCallback(async (propertyId) => {
    setIsLoading(true);
    const result = await GetAllPropertyImagesCategoryByPropertyId(propertyId);
    if (!(result && result.status && result.status !== 200)) setresponse(result || []);
    else setresponse([]);
    setIsLoading(false);
  }, []);

  const AddPropertiesImage = useCallback(async () => {
    setIsLoading(true);
    const res =
      activeItem || isCategoryExist ? await UpdatePropertyImage(state) : await CreatePropertyImage(state);
    if (!(res && res.status && res.status !== 200)) {
      showSuccess(activeItem || isCategoryExist ? t(`${translationPath}UpdatePropertyImage-successfully`) : t(`${translationPath}CreatePropertyImage-successfully`));
      setIsOpenDialog(false);
      getAllPropertyImagesCategory(+GetParams('id'));
    } else {
      showError(activeItem || isCategoryExist ? t(`${translationPath}Update-PropertyImage-failed`) : t(`${translationPath}Create-PropertyImage-failed`));
      setIsOpenDialog(false);
    }
    setIsLoading(false);
  }, [getAllPropertyImagesCategory, activeItem, state, t, translationPath]);

  useEffect(() => {
    const propertyId = +GetParams('id');
    if (propertyId !== null) getAllPropertyImagesCategory(propertyId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <div className='property-imgaes-wrapers'>
      <Spinner isActive={isLoading} isAbsolute />
      <div className='property-cards'>
        <div className='childs-wrapper'>
          <div
            className={
              response.length === 0 ?
                'section px-2 d-flex-center mt-3 mb-3 ' :
                'section px-2  mt-3 mb-3'
            }
          >
            <ButtonBase
              className='btns theme-solid bg-primary'
              onClick={() => {
                setIsOpenDialog(true);
                setActiveItem(null);
              }}
            >
              <span className='mdi mdi-plus' />
              <span className='px-1'>{t(`${translationPath}Add-new`)}</span>
            </ButtonBase>
          </div>
          {response.length === 0 ? (
            <div className='EmptyPage' />
          ) : (
            <ImagesGalleryAlbumsCardComponent
              data={response || []}
              Display={UnitProfileImagesCardActionEnum.Show}
              HideEdit={false}
              isEdit
              property={property}
              isOpenDialog
              hidePhotosTooltip
              WithUnitDetails
              // onIconImageClick
              propertyId={+GetParams('id')}
              onClickEdit={() => setIsOpenDialog(true)}
              EdititemData={(activeitem) => setActiveItem(activeitem)}
              parentTranslationPath={parentTranslationPath}
              translationPath={translationPath}
              canEditImage={canEditImage}
              reloadData={getAllPropertyImagesCategory}
            />
          )}
        </div>
        <ImageDialogsProperties
          setData={(data) => setstate(data)}
          data={response}
          activeItem={activeItem}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          isCategoryExist={isCategoryExist}
          setIsCategoryExist={setIsCategoryExist}
          activeTab={activeTab}
          setActiveItem={setActiveItem}
          isOpenDialog={isOpenDialog}
          setIsOpenDialog={setIsOpenDialog}
          submitHandler={AddPropertiesImage}
        />
      </div>
    </div>
  );
};
PropertiesImage.propTypes = {
  HideEdit: PropTypes.bool,
  parentTranslationPath: PropTypes.string.isRequired,
  translationPath: PropTypes.string.isRequired,
  canEditImage: PropTypes.bool,
};
PropertiesImage.defaultProps = {
  HideEdit: true,
  canEditImage: false,
};
