import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@material-ui/core';
import { DuplicatedContactsHeaderData } from './DuplicatedContactsHeaderData';
import { PaginationComponent, Spinner, Tables } from '../../../../Components';
import { bottomBoxComponentUpdate } from '../../../../Helper';
import { GetContactsDuplicationCriteria, GetDuplicatedContacts } from '../../../../Services';

function DuplicatedContactsView() {
  const parentTranslationPath = 'ContactsView';
  const translationPath = '';
  const { t } = useTranslation(parentTranslationPath);

  const [duplicatedContacts, setDuplicatedContacts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState(false);

  const [filter, setFilter] = useState({
    pageIndex: 0,
    pageSize: parseInt(localStorage.getItem('Pagination')) || 25,
  });

  const [duplicatedContactsCriteria, setDuplicatedContactsCriteria] = useState({
    result: [],
    totalCount: 0,
  });

  const [activeStep, setActiveStep] = useState(null);

  const APIGetAllDuplicatedContactsCriteria = useCallback(async () => {
    setIsLoading(true);

    const result = await GetContactsDuplicationCriteria({
      pageSize: filter.pageSize || 25,
      pageIndex: filter.pageIndex + 1,
    });

    if (!(result && result.status && result.status !== 200)) {
      setDuplicatedContactsCriteria(result);
    } else {
      setDuplicatedContactsCriteria({ result: [], totalCount: 0 });
    }

    setIsLoading(false);
  });

  const APIGetDuplicatedCriteriaUnits = useCallback(
    async (contactDuplicationCriteriaId) => {
      try {
        const result = await GetDuplicatedContacts(
          contactDuplicationCriteriaId
        );

        if (!(result && result.status && result.status !== 200)) {
          setDuplicatedContacts ( result );
        } else {
          setDuplicatedContacts({ result: [], totalCount: 0 });
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    },
    [setDuplicatedContacts]
  );

  const handleShowClick = async (contactDuplicationCriteriaId) => {
    setIsLoading(true);
    try {
      await APIGetDuplicatedCriteriaUnits(contactDuplicationCriteriaId);
    } catch (error) {
      console.error('Error handling show click:', error);
    } finally {
      setActiveStep(
        contactDuplicationCriteriaId === activeStep
          ? null
          : contactDuplicationCriteriaId
      );
      setIsLoading(false);
    }
  };

  const onPageIndexChanged = (pageIndex) => {
    setFilter((item) => ({ ...item, pageIndex }));
  };

  const onPageSizeChanged = (pageSize) => {
    setFilter((item) => ({ ...item, pageIndex: 0, pageSize }));
  };

  useEffect(() => {
    APIGetAllDuplicatedContactsCriteria();
  }, [filter]);

  useEffect(
    () => () => {
      bottomBoxComponentUpdate(null);
    },
    []
  );

  useEffect(() => {
    bottomBoxComponentUpdate(
      <PaginationComponent
        pageIndex={filter.pageIndex}
        pageSize={filter.pageSize}
        totalCount={duplicatedContactsCriteria.totalCount}
        onPageIndexChanged={onPageIndexChanged}
        onPageSizeChanged={onPageSizeChanged}
      />
    );
  });

  const Row = (props) => {
    const { row } = props;

    return (
      <>
        <TableRow
          className={`TableRow-TableBody-aml ${
            data.index === props.index ? 'isselect' : ''
          }`}
          index={props.index}
        >
          <TableCell align='right'>
            {row.name}
          </TableCell>
          <TableCell align='right'>{row.mobileNumber}</TableCell>
          <TableCell align='right'>{row.email}</TableCell>
          <TableCell align='right'>
            <IconButton aria-label='expand row' size='small'>
              {activeStep !== null ? (
                <KeyboardArrowUpIcon
                  id='KeyboardArrowUpIcon-ref'
                  onClick={() =>
                    handleShowClick(row.contactDuplicationCriteriaId)
                  }
                />
              ) : (
                <KeyboardArrowDownIcon
                  id='KeyboardArrowDownIcon-ref'
                  onClick={() =>
                    handleShowClick(row.contactDuplicationCriteriaId)
                  }
                />
              )}
            </IconButton>
          </TableCell>
        </TableRow>
        {activeStep === row.contactDuplicationCriteriaId && (
          <TableRow>
            <TableCell colSpan={6} style={{ maxWidth: '1557px' }}>
              <div>
                <Tables
                  headerData={DuplicatedContactsHeaderData || null}
                  data={duplicatedContacts || []}
                  itemsPerPage={25}
                  isLoading={isLoading}
                  defaultActions={[]}
                  translationPath={translationPath}
                  parentTranslationPath={parentTranslationPath}
                />
              </div>
            </TableCell>
          </TableRow>
        )}
      </>
    );
  };

  function createData(
    contactDuplicationCriteriaId,
    name,
    mobileNumber,
    email
  ) {
    return {
      contactDuplicationCriteriaId,
      name,
      mobileNumber,
      email,
    };
  }

  const rows =
    duplicatedContactsCriteria && duplicatedContactsCriteria.result
      ? duplicatedContactsCriteria.result.map((row) =>
          createData(
            row.contactDuplicationCriteriaId,
            row.name,
            row.mobileNumber,
            row.email
          )
        )
      : [];

  return (
    <>
      <Spinner isActive={isLoading} isAbsolute />
      <div className='AML-TABEL d-flex-column'>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align='center'>
                  {t(`${translationPath}duplicated-contact-name`)}
                </TableCell>
                <TableCell align='center'>
                  {t(`${translationPath}duplicated-number`)}
                </TableCell>
                <TableCell align='center'>
                  {t(`${translationPath}email`)}
                </TableCell>
                <TableCell align='center'></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, rowIndex) => (
                <Row
                  key={row.contactDuplicationCriteriaId}
                  row={row}
                  index={rowIndex}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </>
  );
}

export default DuplicatedContactsView;
