import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { PropertyRatingLocationTypeScoreEnum } from "../../../../../Enums";
import {
  LocationGeographicCriteria,
  CityGeographicCriteria,
  DistrictGeographicCriteria,
  CommunityGeographicCriteria,
  SubCommunityGeographicCriteria,
} from "../GeographicCriteria";

export const GeographicCriteriacContentView = ({
  parentTranslationPath,
  translationPath,
  locationValues,
  setLocationValues,
  cityGeographicCriteriaWithScores,
  setCityGeographicCriteriaWithScores,
  districtGeographicCriteriaWithScores,
  setDistrictGeographicCriteriaWithScores,
  communityGeographicCriteriaWithScores,
  setCommunityGeographicCriteriaWithScores,
  subCommunityGeographicCriteriaWithScores,
  setSubCommunityGeographicCriteriaWithScores,
}) => {
  const { t } = useTranslation(parentTranslationPath);
  const [activeCity, setActiveCity] = useState(null);
  const [activeDistrict, setActiveDistrict] = useState(null);
  const [activeCommunity, setActiveCommunity] = useState(null);
  const [activeSubCommunity, setActiveSubCommunity] = useState(null);
  const [displayAllReletedLocation, setDisplayAllReletedLocation] = useState({
    displayAllCityDistricts: null,
    displayAllDistrictCommunities: null,
    displayAllCommunitySubCommunities: null,
  });

  return (
    <>
      <div className="geographic-criteria-section b-bottom mb-2 mt-2">
        <LocationGeographicCriteria
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          locationValues={locationValues}
          setLocationValues={setLocationValues}
        />
      </div>
      {cityGeographicCriteriaWithScores &&
        cityGeographicCriteriaWithScores.length > 0 && (
          <div className="geographic-criteria-section b-bottom mb-2 mt-2">
            <CityGeographicCriteria
              parentTranslationPath={parentTranslationPath}
              translationPath={translationPath}
              cityGeographicCriteriaWithScores={
                cityGeographicCriteriaWithScores
              }
              setCityGeographicCriteriaWithScores={
                setCityGeographicCriteriaWithScores
              }
              districtGeographicCriteriaWithScores={
                districtGeographicCriteriaWithScores
              }
              setDistrictGeographicCriteriaWithScores={
                setDistrictGeographicCriteriaWithScores
              }
              setActiveCity={setActiveCity}
              setActiveCommunity={setActiveCommunity}
              setActiveDistrict={setActiveDistrict}
              propertyRatingLocationTypeScoreId={
                PropertyRatingLocationTypeScoreEnum.District
                  .propertyRatingLocationTypeScoreId
              }
              activeCity={activeCity}
              setActiveSubCommunity={setActiveSubCommunity}
              displayAllReletedLocation={displayAllReletedLocation}
              setDisplayAllReletedLocation={setDisplayAllReletedLocation}
            />
          </div>
        )}
      {districtGeographicCriteriaWithScores &&
        districtGeographicCriteriaWithScores.length > 0 && (
          <div className="geographic-criteria-section b-bottom  mb-2 mt-2">
            <DistrictGeographicCriteria
              parentTranslationPath={parentTranslationPath}
              translationPath={translationPath}
              districtGeographicCriteriaWithScores={
                districtGeographicCriteriaWithScores
              }
              setCommunityGeographicCriteriaWithScores={
                setCommunityGeographicCriteriaWithScores
              }
              setDistrictGeographicCriteriaWithScores={
                setDistrictGeographicCriteriaWithScores
              }
              communityGeographicCriteriaWithScores={
                communityGeographicCriteriaWithScores
              }
              setActiveDistrict={setActiveDistrict}
              activeCity={activeCity}
              setActiveCommunity={setActiveCommunity}
              activeDistrict={activeDistrict}
              setActiveSubCommunity={setActiveSubCommunity}
              propertyRatingLocationTypeScoreId={
                PropertyRatingLocationTypeScoreEnum.Community
                  .propertyRatingLocationTypeScoreId
              }
              displayAllReletedLocation={displayAllReletedLocation}
              setDisplayAllReletedLocation={setDisplayAllReletedLocation}
            />
          </div>
        )}
      {communityGeographicCriteriaWithScores &&
        communityGeographicCriteriaWithScores.length > 0 && (
          <div className="geographic-criteria-section b-bottom mb-2 mt-2">
            <CommunityGeographicCriteria
              parentTranslationPath={parentTranslationPath}
              translationPath={translationPath}
              communityGeographicCriteriaWithScores={
                communityGeographicCriteriaWithScores
              }
              setCommunityGeographicCriteriaWithScores={
                setCommunityGeographicCriteriaWithScores
              }
              subCommunityGeographicCriteriaWithScores={
                subCommunityGeographicCriteriaWithScores
              }
              setSubCommunityGeographicCriteriaWithScores={
                setSubCommunityGeographicCriteriaWithScores
              }
              setActiveCommunity={setActiveCommunity}
              activeDistrict={activeDistrict}
              setActiveSubCommunity={setActiveSubCommunity}
              activeCommunity={activeCommunity}
              propertyRatingLocationTypeScoreId={
                PropertyRatingLocationTypeScoreEnum.SubCommunity
                  .propertyRatingLocationTypeScoreId
              }
              displayAllReletedLocation={displayAllReletedLocation}
              setDisplayAllReletedLocation={setDisplayAllReletedLocation}
            />
          </div>
        )}

      {subCommunityGeographicCriteriaWithScores &&
        subCommunityGeographicCriteriaWithScores.length > 0 && (
          <div className="geographic-criteria-section  mb-2 mt-2">
            <SubCommunityGeographicCriteria
              parentTranslationPath={parentTranslationPath}
              translationPath={translationPath}
              subCommunityGeographicCriteriaWithScores={
                subCommunityGeographicCriteriaWithScores
              }
              setSubCommunityGeographicCriteriaWithScores={
                setSubCommunityGeographicCriteriaWithScores
              }
              activeCommunity={activeCommunity}
              setActiveSubCommunity={setActiveSubCommunity}
              displayAllReletedLocation={displayAllReletedLocation}
              setDisplayAllReletedLocation={setDisplayAllReletedLocation}
            />
          </div>
        )}
    </>
  );
};
