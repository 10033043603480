import React from "react";

const SearchIcon = ({ fill, ...restProps }) => {
  return (
    <svg
      viewBox="0 0 20 20"
      fill={fill ?? ""}
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <path
        d="M9.033 1.701c-3.44.235-6.349 2.712-7.144 6.082a8.13 8.13 0 0 0 0 3.6 7.93 7.93 0 0 0 5.894 5.894 8.13 8.13 0 0 0 3.6 0 8.1 8.1 0 0 0 2.895-1.337l.295-.216 1.255 1.251c1.353 1.349 1.337 1.335 1.688 1.332a.788.788 0 0 0 .791-.788c.003-.355.018-.336-1.332-1.691l-1.251-1.255.216-.295a8.05 8.05 0 0 0 1.479-3.661 9.782 9.782 0 0 0 0-2.067 7.92 7.92 0 0 0-8.386-6.849m1.429 1.684a6.304 6.304 0 0 1 2.429.886c.749.459 1.546 1.256 2.004 2.005.639 1.044.938 2.098.938 3.307 0 1.21-.299 2.264-.938 3.308a6.583 6.583 0 0 1-2.004 2.004c-1.044.639-2.098.938-3.308.938-1.209 0-2.263-.299-3.307-.938-.749-.458-1.546-1.255-2.005-2.004-.639-1.044-.938-2.098-.938-3.308 0-1.702.626-3.211 1.832-4.418a6.19 6.19 0 0 1 3.502-1.777c.435-.066 1.352-.068 1.795-.003"
        fill-rule="evenodd"
        fill={fill ?? ""}
      />
    </svg>
  );
};

export default SearchIcon;
