import React, { useState, useRef, useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { ButtonBase } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import {
  Inputs,
  SelectComponet,
  Spinner,
  ViewTypes,
  NoContentComponent,
  DialogComponent,
} from "../../../Components";
import { ViewTypesEnum } from "../../../Enums/ViewTypes.Enum";
import { ShareUnitDialog } from "./ShareUnitUtitities/ShareUnitDialog";
import {
  ReassignLeads,
  MyShareUnitsSearchAPI,
  RemoveSavedShareUnitsFromCriteria,
} from "../../../Services";
import { ShareUnitTableComponent } from "./ShareUnitUtitities/ShareUnitTableComponent";
import { ActiveItemActions } from "../../../store/ActiveItem/ActiveItemActions";
import { ActionsEnum } from "../../../Enums/Actions.Enum";
import {
  returnPropsByPermissions,
  sideMenuComponentUpdate,
  sideMenuIsOpenUpdate,
  showError,
  showSuccess,
} from "../../../Helper";

import { bottomBoxComponentUpdate } from "../../../Helper/Middleware.Helper";
import { PaginationComponent } from "../../../Components/PaginationComponent/PaginationComponent";
import { QACallCenterPermissions } from "../../../Permissions";
import { GlobalOrderFilterActions } from "../../../store/GlobalOrderFilter/GlobalOrderFilterActions";
import { LeadsReassignDialog } from "../LeadsSalesView/LeadsSalesUtilities/Dialogs/LeadsReassignDialog/LeadsReassignDialog";
import { useTitle } from "../../../Hooks";
import { SharetUnitsCardsComponent } from "./ShareUnitUtitities/SharetUnitsCardsComponent";
import { isAdminInquiries } from "../../../Helper/isAdmin.Helper";
import { FavouriteShareUnitsDialog } from "./ShareUnitUtitities/FavouriteShareUnitsDialog";
import { AddToFavouriteShareUnitsDialog } from "./ShareUnitUtitities/AddToFavouriteShareUnitsDialog";
import { PauseShareUnitsComponent } from "./ShareUnitUtitities/PauseShareUnitsComponent";
import { TableFilterOperatorsEnum } from "../../../Enums";

const parentTranslationPath = "ShareUnit";
const translationPath = "";

export const MyShareUnitView = () => {
  const { t } = useTranslation(parentTranslationPath);
  useTitle(t(`${translationPath}share-unit`));
  const dateRangeDefault = {
    startDate: new Date(moment().subtract(1, "days")),
    endDate: new Date(moment(moment().format()).endOf("day")),
    key: "selection",
  };
  const searchTimer = useRef(null);
  const orderFilter = useSelector((state) => state.GlobalOrderFilterReducer);
  const [selectedOrderBy, setSelectedOrderBy] = useState({
    filterBy:
      (orderFilter.ShareUnitFilter && orderFilter.ShareUnitFilter.filterBy) ||
      null,
    orderBy:
      (orderFilter.ShareUnitFilter && orderFilter.ShareUnitFilter.orderBy) ||
      null,
  });
  const [searchValue, setSearchValue] = useState("");
  const [sortBy, setSortBy] = useState(null);
  const [orderBy, setOrderBy] = useState(null);
  const [activeActionType, setActiveActionType] = useState(
    ViewTypesEnum.cards.key
  );
  const [shareTableFilter, setShareTableFilter] = useState({});
  const [isClearFiltersClicked, setIsClearFiltersClicked] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const isAdminInquiriesValue = isAdminInquiries();
  const [isLoadingReassign, setIsLoadingReassign] = useState(false);
  const [activtiesRate, setActivitesRate] = useState([]);
  const [
    isDialogOpenPauseShareUnitsComponent,
    SetisDialogOpenPauseShareUnitsComponent,
  ] = useState(false);
  const [FiltersavedShareUnitCriteriaId, setFiltersavedShareUnitCriteriaId] =
    useState();
  const dispatch = useDispatch();
  const [SavedShareUnitCriteriaIdValue, setSavedShareUnitCriteriaIdValue] =
    useState("");
  const [activeCard, setActiveCard] = useState(null);
  const [detailsUnitsList, setDetailsUnitsList] = useState(() => ({
    result: [],
    totalCount: 0,
  }));
  const [filter, setFilter] = useState({
    pageIndex: 0,
    pageSize: parseInt(localStorage.getItem("Pagination")) || 25,
    criteria: {},
    filterBy: (selectedOrderBy && selectedOrderBy.filterBy) || null,
    orderBy: (selectedOrderBy && selectedOrderBy.orderBy) || null,
    operationType: null,
    isAdmin: isAdminInquiriesValue,
  });
  const [activeSelectedAction, setActiveSelectedAction] = useState(false);
  const [Singalid, setSingalid] = useState(false);
  const [AddToFavouriteDialog, setAddToFavouriteDialog] = useState(false);
  const [checkedDetailed, setCheckedDetailed] = useState([]);
  const [isOpenFavouriteShareUnitsDialog, setisOpenFavouriteShareUnitsDialog] =
    useState(false);

  useState("");
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [ShareUnit, setShareUnit] = useState({
    result: [],
    totalCount: 0,
  });
  const [isOpenReassign, setIsOpenReassign] = useState(false);
  const [activeItem, setActiveItem] = useState(null);
  const filterByChanged = (value) => {
    setSelectedOrderBy((item) => ({ ...item, filterBy: value }));
  };
  const changeActivityRate = (value) => {
    setFilter((item) => ({ ...item, activityRate: value }));
  };
  const changeActivitystatus = (value) => {
    setFilter((item) => ({ ...item, isOpen: value }));
  };
  const changeQaUsers = (value) => {
    setFilter((item) => ({ ...item, userId: value }));
  };
  const orderByChanged = (value) => {
    setSelectedOrderBy((item) => ({ ...item, orderBy: value }));
  };
  const onPageIndexChanged = (pageIndex) => {
    setFilter((item) => ({ ...item, pageIndex }));
    sideMenuComponentUpdate(null);
    sideMenuIsOpenUpdate(false);
  };
  const onPageSizeChanged = (pageSize) => {
    setFilter((item) => ({ ...item, pageIndex: 0, pageSize }));
    sideMenuComponentUpdate(null);
    sideMenuIsOpenUpdate(false);
  };
  const searchHandler = (event) => {
    const { value } = event.target;
    if (searchTimer.current) clearTimeout(searchTimer.current);
    searchTimer.current = setTimeout(() => {
      setFilter((item) => ({ ...item, search: value }));
    }, 700);
  };
  const GetAllUnitsSearch = useCallback(
    async (SavedShareUnitCriteriaIdValue) => {
      const params = { ...filter, criteria: {} };
      delete params.pageIndex;
      delete params.pageSize;

      if (searchValue) {
        params.criteria.unit_ref_no = [{ searchType: 2, value: searchValue }];
      } else {
        delete params.criteria.unit_ref_no;
      }

      if (SavedShareUnitCriteriaIdValue) {
        params.criteria.savedShareUnitCriteriaId = [
          {
            searchType: 2,
            value:
              SavedShareUnitCriteriaIdValue.savedShareUnitCriteriaId ||
              SavedShareUnitCriteriaIdValue,
          },
        ];
      } else {
        delete params.criteria.savedShareUnitCriteriaId;
      }

      if (shareTableFilter) {
        Object.values(shareTableFilter)
          .filter((item) => item?.searchableKey || item?.displayPath)
          .map((item) => {
            if (params.criteria[item.searchableKey || item.displayPath]) {
              params.criteria[item.searchableKey || item.displayPath].push({
                searchType: item.operator,
                value: item.value,
              });
            } else if (item?.value) {
              params.criteria[item.searchableKey || item.displayPath] = [
                {
                  searchType: item.operator,
                  value: item.value,
                },
              ];
            } else if (
              !item.value &&
              (item.operator === TableFilterOperatorsEnum.isNotBlank.key ||
                item.operator === TableFilterOperatorsEnum.isBlank.key)
            ) {
              params.criteria[item.searchableKey || item.displayPath] = [
                {
                  searchType: item.operator,
                  value: null,
                },
              ];
            } else if (item?.value === "") {
              delete params.criteria[item.searchableKey || item.displayPath]
            }
            return undefined;
          });
      }

      setIsLoading(true);
      const result = await MyShareUnitsSearchAPI(
        {
          pageSize: filter.pageSize,
          pageIndex: filter.pageIndex,
        },
        params
      );

      const updatedResult = {
        result: result.result,
        totalCount: result.totalCount || 0,
      };

      setDetailsUnitsList(updatedResult);

      if (result && result.status && result.status !== 200) {
        setShareUnit({ result: [], totalCount: 0 });
      } else {
        setShareUnit(updatedResult);
      }

      setIsLoading(false);
    },
    [filter, searchValue, shareTableFilter]
  );
  useEffect(() => {
    GetAllUnitsSearch();

  }, [filter, shareTableFilter]);
  const onClearedAllFiltersClick = useCallback(() => {
    dispatch(GlobalOrderFilterActions.globalOrderFilterRequest({}));
    setOrderBy({ filterBy: null, orderBy: null });
    setSelectedOrderBy({
      filterBy: null,
      orderBy: null,
    });
    setSearchValue("");
    setShareTableFilter({})
    setIsClearFiltersClicked(true);
  }, [dispatch]);
  const orderBySubmitted = (event) => {
    event.preventDefault();
    if (!selectedOrderBy.filterBy || !selectedOrderBy.orderBy) return;
    dispatch(
      GlobalOrderFilterActions.globalOrderFilterRequest({
        ...orderFilter,
        ShareUnitFilter: {
          filterBy: selectedOrderBy.filterBy,
          orderBy: selectedOrderBy.orderBy,
        },
      })
    );
    setOrderBy({
      filterBy: selectedOrderBy.filterBy,
      orderBy: selectedOrderBy.orderBy,
    });
  };

  const detailedCardSideActionClicked = useCallback(
    (actionEnum, activeData) => async (event) => {
      dispatch(ActiveItemActions.activeItemRequest(activeData));
      event.stopPropagation();
      if (actionEnum === ActionsEnum.folder.key) {
        setIsOpenReassign(true);
        setActiveItem(activeData);
        setActiveCard(activeData);
      } else if (actionEnum === ActionsEnum.delete.key) {
        SetisDialogOpenPauseShareUnitsComponent(true);
        setActiveItem(activeData);
        setActiveCard(activeData);
      }
    },
    [dispatch]
  );

  const onTypeChanged = useCallback(
    (activeType) => {
      setActiveActionType(activeType);
    },
    [setActiveActionType]
  );
  const reassignHandler = async (reassignItem) => {
    setFilter((item) => ({ ...item, pageIndex: 0, leadStatus: null }));
    setIsOpenReassign(false);
    showSuccess(t(`${translationPath}leads-reassign-notification`));
    const obj = {
      leadIds: [activeItem && activeItem.leadId],
      referredToId: reassignItem.referredToId,
      isCopyTo: reassignItem.isCopyTo,
    };
    await ReassignLeads(obj);
  };
  const onFilterValuesChanged = (newValue) => {
    setShareTableFilter(newValue)
  };
  const reest = async () => {
    setCheckedDetailed([]);
    setActiveSelectedAction(false);
    setisOpenFavouriteShareUnitsDialog(false);
    setAddToFavouriteDialog(false);
    setActiveItem(null);
  };



  useEffect(() => {
    if (orderBy)
      setFilter((item) => ({
        ...item,
        filterBy: orderBy.filterBy,
        orderBy: orderBy.orderBy,
      }));
  }, [orderBy]);
  useEffect(() => {
    if (sortBy)
      setFilter((item) => ({
        ...item,
        filterBy: sortBy.filterBy,
        orderBy: sortBy.orderBy,
      }));
  }, [sortBy]);

  useEffect(() => {
    if (
      returnPropsByPermissions(
        QACallCenterPermissions.ViewQaActivitiesWithLeadInfo.permissionsId
      )
    ) {
      bottomBoxComponentUpdate(
        <PaginationComponent
          pageIndex={filter.pageIndex}
          pageSize={filter.pageSize}
          totalCount={ShareUnit.totalCount}
          onPageIndexChanged={onPageIndexChanged}
          onPageSizeChanged={onPageSizeChanged}
        />
      );
    }
  });

  // useMemo(() => {
  //   setFilter((item) => ({
  //     ...item,
  //     fromDate: dateFilter.startDate,
  //     toDate: dateFilter.endDate,
  //   }));
  // }, [dateFilter]);
  useEffect(
    () => () => {
      bottomBoxComponentUpdate(null);
      sideMenuComponentUpdate(null);
      sideMenuIsOpenUpdate(false);
    },
    []
  );

  const DeleteSavedShareUnits = useCallback(
    async (
      items,
      SavedShareUnitCriteriaIdValue,
      FiltersavedShareUnitCriteriaId,
      Singalid
    ) => {
      const res = await RemoveSavedShareUnitsFromCriteria({
        savedShareUnitCriteriaId: SavedShareUnitCriteriaIdValue,
        shareUnitsIds: Singalid && [Singalid.shareUnitId] || items.map((item) => item.shareUnitId),
      });
      if (!(res && res.status && res.status !== 200)) {
        GetAllUnitsSearch(FiltersavedShareUnitCriteriaId);
        setCheckedDetailed([]);
        setActiveSelectedAction(false);
        setisOpenFavouriteShareUnitsDialog(false);
        setAddToFavouriteDialog(false);
        setActiveItem(null);
        setIsDeleteDialogOpen(false);
        showSuccess(t("Deleted-Successfully"));
      } else {
        showError(t("Deleting-Has-Failed"));
      }
    },
    []
  );

  const onCardClick = useCallback(
    (item, selectedIndex) => (event) => {
      event.stopPropagation();
      event.preventDefault();
      setActiveCard(item);
      // sideMenuComponentUpdate(
      //   <CardDetailsComponent
      //     activeData={detailsUnitsList.result[selectedIndex]}
      //     cardDetailsActionClicked={detailedCardSideActionClicked}
      //     parentTranslationPath={parentTranslationPath}
      //     translationPath={translationPath}
      //   />
      // );
      // sideMenuIsOpenUpdate(true);
    },
    [detailedCardSideActionClicked, detailsUnitsList.result]
  );

  const cardCheckboxClicked = useCallback((itemIndex, element) => {
    setCheckedDetailed((items) => {
      const index = items.findIndex(
        (item) => item.shareUnitId === element.shareUnitId
      );
      if (index !== -1) items.splice(index, 1);
      else items.push(element);
      return [...items];
    });
  }, []);
  const cardCheckboxClickedTabel = useCallback((element, itemIndex) => {
    setCheckedDetailed((items) => {
      const index = items.findIndex(
        (item) => item.shareUnitId === element.shareUnitId
      );
      if (index !== -1) items.splice(index, 1);
      else items.push(element);
      return [...items];
    });
  }, []);

  const getIsSelectedCard = useCallback(
    (itemIndex) =>
      checkedDetailed.findIndex(
        (item) =>
          item.shareUnitId === itemIndex.shareUnitId || item === itemIndex
      ) !== -1,
    [checkedDetailed]
  );

  return (
    <div className="view-wrapper QA-view-wrapper">
      <Spinner isActive={isLoading} />
      <div className="d-flex-column">
        <div className="header-section">
          <div className="filter-section">
            <div className="section" />
            <div className="section autocomplete-section">
              <div className="d-flex-column px-2 w-100 p-relative">
                <div className="d-flex-column w-100">
                  <Inputs
                    idRef="userNameFilterRef"
                    label="filter-by-unit-ref"
                    // inputPlaceholder="type-contactId"
                    fieldClasses="inputs theme-solid"
                    parentTranslationPath={parentTranslationPath}
                    value={searchValue}
                    onInputChanged={(e) => {
                      setSearchValue(e?.target?.value || "");
                    }}
                    onKeyUp={(event) => {
                      if (searchTimer.current)
                        clearTimeout(searchTimer.current);
                      const newValue = event.target.value;
                      searchTimer.current = setTimeout(() => {
                        setFilter({
                          ...filter,
                          id: "contactId",
                          value: newValue,
                        });
                      }, 700);
                    }}
                  />
                </div>
                <div className="buttom-filters-section">
                  {/* <div className='d-flex'>
                    <div className='w-50 mr-1-reversed'>
                      <Inputs
                        idRef='leadIdFilterRef'
                        fieldClasses='inputs theme-solid'
                        parentTranslationPath={parentTranslationPath}
                        value={state.leadId}
                        onInputChanged={(event) => {
                        const { value } = event.target;
                        setState({
                          ...state,
                          leadId: value || null,
                        });
                      }}
                        onKeyUp={(event) => {
                        if (searchTimer.current) clearTimeout(searchTimer.current);
                        const newValue = event.target.value;
                        searchTimer.current = setTimeout(() => {
                          setFilter({
                            ...filter,
                            leadId: newValue && +newValue || null,
                          });
                        }, 700);
                      }}
                        inputPlaceholder='Lead-ID'
                      />
                    </div>
                    <div className='w-50 mr-2'>
                      <Inputs
                        idRef='contactNameFilterRef'
                        fieldClasses='inputs theme-solid'
                        parentTranslationPath={parentTranslationPath}
                        value={state.contactName}
                        onInputChanged={(event) => {
                        const { value } = event.target;
                        setState({
                          ...state,
                          contactName: value || null,
                        });
                      }}
                        onKeyUp={(event) => {
                        if (searchTimer.current) clearTimeout(searchTimer.current);
                        const newValue = event.target.value;
                        searchTimer.current = setTimeout(() => {
                          setFilter({
                            ...filter,
                            contactName: newValue || null,
                          });
                        }, 700);
                      }}
                        inputPlaceholder='Contact-Name'
                      />
                    </div>
                    <div className='w-50 mr-1-reversed'>
                      <Inputs
                        idRef='contactIdFilterRef'
                        fieldClasses='inputs theme-solid'
                        parentTranslationPath={parentTranslationPath}
                        value={state.contactId}
                        onInputChanged={(event) => {
                        const { value } = event.target;
                        setState({
                          ...state,
                          contactId: value || null,
                        });
                      }}
                        onKeyUp={(event) => {
                        if (searchTimer.current) clearTimeout(searchTimer.current);
                        const newValue = event.target.value;
                        searchTimer.current = setTimeout(() => {
                          setFilter({
                            ...filter,
                            contactId: newValue && +newValue || null,
                          });
                        }, 700);
                      }}
                        inputPlaceholder='Contact-ID'
                      />
                    </div>
                  </div> */}
                  <div className="d-flex">
                    {/* <div className='mr-2'>
                        <DateRangePickerComponent
                          onClearClicked={() => setDateFilter({
                        startDate: null,
                        endDate: null,
                        key: 'selection',
                      })}
                          ranges={[dateFilter]}
                          parentTranslationPath={parentTranslationPath}
                          translationPath={translationPath}
                          onDateChanged={(selectedDate) =>
                        setDateFilter({
                          startDate: selectedDate.selection && selectedDate.selection.startDate,
                          endDate: new Date(moment(selectedDate.selection && selectedDate.selection.endDate).endOf('day')),
                          key: 'selection',
                        })}
                        />
                      </div> */}
                    {/* <div className='mr-2'>
                        <SelectComponet
                          data={[{ lookupItemId: true, lookupItemName: t('open') }, { lookupItemId: false, lookupItemName: t('close') }]}
                          defaultValue
                          emptyItem={{
                        value: null,
                        text: t('Activities-Status'),
                        isDisabled: false,
                        // isHiddenOnOpen: true,
                      }}
                          value={filter.isOpen}
                          className='px-2'
                          valueInput='lookupItemId'
                          textInput='lookupItemName'
                          wrapperClasses='w-auto'
                          idRef='Activities_Status'
                          onSelectChanged={changeActivitystatus}
                          parentTranslationPath={parentTranslationPath}
                          translationPath={translationPath}
                          translationPathForData={translationPath}
                        />
                      </div>
                      <div className='mr-2'>
                        <SelectComponet
                          data={(activtiesRate && activtiesRate) || []}
                          defaultValue={null}
                          emptyItem={{
                        value: null,
                        text: t('Select-Activities-rate'),
                        isDisabled: false,
                        // isHiddenOnOpen: true,
                      }}
                          value={filter.activityRate || null}
                          className='px-2'
                          valueInput='lookupItemId'
                          textInput='lookupItemName'
                          wrapperClasses='w-auto'
                          idRef='Select_Activities_Rate'
                          onSelectChanged={changeActivityRate}
                          parentTranslationPath={parentTranslationPath}
                          translationPath={translationPath}
                          translationPathForData={translationPath}
                        />
                      </div>
                      <div className='mr-2'>
                        <SelectComponet
                          data={(qaUsers && qaUsers) || []}
                          defaultValue={null}
                          emptyItem={{
                        value: null,
                        text: t('Select-QA-Users'),
                        isDisabled: false,
                        // isHiddenOnOpen: true,
                      }}
                          value={filter.userId || null}
                          onSelectChanged={changeQaUsers}
                          className='px-2'
                          valueInput='usersId'
                          textInput='fullName'
                          wrapperClasses='w-auto'
                          idRef='Select_QA_Users'
                          parentTranslationPath={parentTranslationPath}
                          translationPath={translationPath}
                          translationPathForData={translationPath}
                        />
                      </div>
                    <div className='buttons mt-1'>
                        <ButtonBase
                          className='btns theme-solid reset'
                          onClick={() => {
                          setDateFilter({
                            startDate: null,
                            endDate: null,
                            key: 'selection',
                          });
                          setFilter(() => ({
                            pageIndex: 0,
                            pageSize: 25,
                            filterBy: null,
                            orderBy: null,
                            activityRate: null,
                            fromDate: null,
                            toDate: null,
                            userId: null,
                            search: '',
                            isOpen: null,
                            leadId: null,
                            contactName: null,
                            contactId: null,
                          }));
                          setState({
                            leadId: '',
                            contactName: '',
                            contactId: '',
                          });
                        }}
                        >
                          <span>{t(`${translationPath}reset`)}</span>
                        </ButtonBase>
                    </div> */}
                  </div>
                </div>
              </div>
              <div className="view-search-wrapper gap-3">
                <ViewTypes
                  onTypeChanged={onTypeChanged}
                  activeTypes={[
                    ViewTypesEnum.tableView.key,
                    ViewTypesEnum.cards.key,
                  ]}
                  className="mb-3"
                />
                <ButtonBase
                  onClick={onClearedAllFiltersClick}
                  id="onClearedAllFiltersref"
                  // disabled={isAllFormFieldsLoading}
                  className="btns theme-solid bg-danger clear-all-btn"
                >
                  <span className="mdi mdi-filter-remove m-1" />
                  {t(`${translationPath}clear-filters`)}
                </ButtonBase>
              </div>

            </div>
          </div>
          <div className="d-flex px-2">
            <span className="mx-2 mt-1">{t("select")}</span>
            <span className="separator-v s-primary s-reverse s-h-25px mt-1" />
            <span className="px-2 d-flex">
              <span className="texts-large mt-1">{t("order-by")}:</span>
              <div className="px-2">
                <SelectComponet
                  idRef="filterByRef"
                  data={[
                    { id: "CreationDate", filterBy: "created-date" },
                    // { id: "UpdateOn", filterBy: "Updated-date" },
                  ]}
                  value={selectedOrderBy.filterBy}
                  onSelectChanged={filterByChanged}
                  wrapperClasses="mb-3"
                  isRequired
                  valueInput="id"
                  textInput="filterBy"
                  emptyItem={{
                    value: null,
                    text: "select-filter-by",
                    isDisabled: false,
                    isHiddenOnOpen: false,
                  }}
                  parentTranslationPath={parentTranslationPath}
                  translationPath={translationPath}
                  translationPathForData={translationPath}
                />
              </div>
              <div className="px-2">
                <SelectComponet
                  idRef="orderByRef"
                  data={[
                    { id: 1, orderBy: "ascending" },
                    { id: 2, orderBy: "descending" },
                  ]}
                  emptyItem={{
                    value: null,
                    text: "select-sort-by",
                    isDisabled: false,
                    isHiddenOnOpen: false,
                  }}
                  value={selectedOrderBy.orderBy}
                  onSelectChanged={orderByChanged}
                  wrapperClasses="mb-3"
                  isRequired
                  valueInput="id"
                  textInput="orderBy"
                  parentTranslationPath={parentTranslationPath}
                  translationPath={translationPath}
                  translationPathForData={translationPath}
                />
              </div>
              <div className="mt-1">
                <ButtonBase
                  className="btns theme-solid"
                  onClick={orderBySubmitted}
                  id="action_apply"
                  disabled={
                    !selectedOrderBy.filterBy || !selectedOrderBy.orderBy
                  }
                >
                  <span>{t(`${translationPath}apply`)}</span>
                </ButtonBase>
              </div>
              <div className="mt-1">
                <ButtonBase
                  onClick={() => {
                    (!activeSelectedAction &&
                      setActiveSelectedAction(!activeSelectedAction)) ||
                      (activeSelectedAction &&
                        SetisDialogOpenPauseShareUnitsComponent(true));
                  }}
                  disabled={
                    activeSelectedAction &&
                    checkedDetailed &&
                    checkedDetailed.length === 0
                  }
                  className="btns theme-solid bg-danger"
                >
                  {(!activeSelectedAction && (
                    <span>
                      <span class="mdi mdi-trash-can-outline"></span>{" "}
                      {t(`${translationPath}Disabled-fom-shere`)}
                    </span>
                  )) || <span>{t(`${translationPath}Remove-From-Shere`)}</span>}
                </ButtonBase>
              </div>
            </span>
            {SavedShareUnitCriteriaIdValue && (
              <div className="buttons mt-1">
                <ButtonBase
                  onClick={() => {
                    setSavedShareUnitCriteriaIdValue("");
                    GetAllUnitsSearch();
                  }}
                  className="btns theme-solid bg-danger"
                >
                  <span>
                    {t(`${translationPath}Clear-filter-of-Favourite`)}
                  </span>
                </ButtonBase>
              </div>
            )}
            {activeSelectedAction && (
              <div className="buttons mt-1">
                <ButtonBase
                  onClick={() => {
                    setActiveSelectedAction(false);
                    setCheckedDetailed([]);
                  }}
                  className="btns btns theme-solid bg-cancel  "
                >
                  <span><span class="mdi mdi-close"></span>{t(`${translationPath}Cansel-select`)}</span>
                </ButtonBase>
              </div>
            )}
            <div className="buttons mt-1">
              <ButtonBase
                onClick={() => {
                  (!activeSelectedAction &&
                    setActiveSelectedAction(!activeSelectedAction)) ||
                    (activeSelectedAction && setAddToFavouriteDialog(true));
                }}
                disabled={
                  activeSelectedAction &&
                  checkedDetailed &&
                  checkedDetailed.length === 0
                }
                className="btns theme-solid"
              >
                {(!activeSelectedAction && (
                  <span className="mdi mdi-vector-point ">
                    {" "}
                    &nbsp;{t(`${translationPath}Select-Favourite-ADD-DEL`)}{" "}
                  </span>
                )) || <span>{t(`${translationPath}add-to-Favourite`)}</span>}
              </ButtonBase>
            </div>
            {activeSelectedAction && SavedShareUnitCriteriaIdValue && (
              <div className="buttons mt-1">
                <ButtonBase
                  onClick={() => {
                    setIsDeleteDialogOpen(true);
                  }}
                  disabled={
                    activeSelectedAction &&
                    checkedDetailed &&
                    checkedDetailed.length === 0
                  }
                  className="btns theme-solid bg-danger"
                >
                  {(!activeSelectedAction && (
                    <span className="mdi mdi-vector-point ">
                      {" "}
                      &nbsp;{t(`${translationPath}Select-Favourite`)}{" "}
                    </span>
                  )) || (
                      <span>{t(`${translationPath}Remove-from-Favourite`)}</span>
                    )}
                </ButtonBase>
              </div>
            )}

            <div className="buttons mt-1">
              <ButtonBase
                onClick={() => {
                  setisOpenFavouriteShareUnitsDialog(true);
                }}
                className="btns theme-solid reset"
              >
                <span className="mdi mdi-heart-box-outline ml-1">
                  {t(`${translationPath}View-Favourite`)}
                </span>
              </ButtonBase>
            </div>
          </div>
        </div>
        <div className="w-100 px-3">
          {activeActionType === ViewTypesEnum.tableView.key && (
            <ShareUnitTableComponent
              filter={filter}
              data={ShareUnit.result || []}
              openFile={detailedCardSideActionClicked}
              parentTranslationPath={parentTranslationPath}
              translationPath={translationPath}
              checkedDetailedCards={checkedDetailed}
              setIsOpenReassign={setIsOpenReassign}
              IsFavourite={SavedShareUnitCriteriaIdValue}
              setSingalid={setSingalid}
              setIsDeleteDialogOpen={() => setIsDeleteDialogOpen(true)}
              setActiveItem={setActiveItem}
              activeItem={activeItem}
              getIsSelected={getIsSelectedCard}
              onSelectClicked={cardCheckboxClickedTabel}
              withCheckbox={activeSelectedAction}
              setOrderBy={setOrderBy}
              onFilterValuesChanged={onFilterValuesChanged}
              isClearFiltersClicked={isClearFiltersClicked}
              setIsClearFiltersClicked={setIsClearFiltersClicked}
              shareTableFilter={shareTableFilter}
            />
          )}
          {ShareUnit && ShareUnit.result && ShareUnit.result.length != 0 ? (
            activeActionType !== ViewTypesEnum.tableView.key && (
              <SharetUnitsCardsComponent
                data={detailsUnitsList}
                onCardClicked={onCardClick}
                IsFavourite={SavedShareUnitCriteriaIdValue}
                setSingalid={setSingalid}
                setIsDeleteDialogOpen={() => setIsDeleteDialogOpen(true)}
                onFooterActionsClicked={detailedCardSideActionClicked}
                parentTranslationPath={parentTranslationPath}
                translationPath={translationPath}
                activeCard={activeCard}
                onCardCheckboxClick={cardCheckboxClicked}
                checkedDetailedCards={checkedDetailed}
                from="myshareunit"
                activeSelectedAction={activeSelectedAction}
                withCheckbox={activeSelectedAction}
              />
            )
          ) : (
            <NoContentComponent />
          )}
          {isOpenReassign && (
            <ShareUnitDialog
              isOpen={isOpenReassign}
              activeItem={activeItem && activeItem}
              activeCard={activeCard}
              commonExclusionsExpetitions="sourceSystemName"
              commonNOTExclusionsExpetitions="targetSystemName"
              setIsLoading={isLoadingReassign}
              setIsLoadingReassign={setIsLoadingReassign}
              onSave={() => {
                setActiveItem(null);
              }}
              parentTranslationPath={parentTranslationPath}
              translationPath=""
              onClose={() => {
                setIsOpenReassign(false);
                setActiveItem(null);
              }}
            />
          )}

          {isOpenFavouriteShareUnitsDialog && (
            <FavouriteShareUnitsDialog
              isOpen={isOpenFavouriteShareUnitsDialog}
              checkedDetailed={checkedDetailed && checkedDetailed}
              onSave={() => {
                setActiveItem(null);
              }}
              parentTranslationPath={parentTranslationPath}
              translationPath=""
              setSavedShareUnitCriteriaIdValue={
                setSavedShareUnitCriteriaIdValue
              }
              GetAllUnitsSearch={GetAllUnitsSearch}
              setFiltersavedShareUnitCriteriaId={
                setFiltersavedShareUnitCriteriaId
              }
              closeHandler={() => {
                setisOpenFavouriteShareUnitsDialog(false);
                setActiveItem(null);
              }}
            />
          )}
          {AddToFavouriteDialog && (
            <AddToFavouriteShareUnitsDialog
              isOpen={AddToFavouriteDialog}
              checkedDetailed={checkedDetailed && checkedDetailed}
              reest={reest}
              onSave={() => {
                setActiveItem(null);
              }}
              parentTranslationPath={parentTranslationPath}
              translationPath=""
              closeHandler={() => {
                setAddToFavouriteDialog(false);
                setActiveItem(null);
              }}
            />
          )}
          <DialogComponent
            titleTextClasses={"release-confirm-text"}
            titleText=""
            saveText="confirm"
            saveType="button"
            maxWidth="sm"
            dialogContent={
              <div className="d-flex-column-center">
                <span className="mdi mdi-close-octagon c-danger mdi-48px" />
                <span>{t("Are-You-Sure-You-Want-To-Delete-Slected-Item")}</span>
                {checkedDetailed && checkedDetailed.map((item) => (
                  <div className="fw-bold">
                    {item.unitName || item.property_name || ""}
                  </div>
                ))}
              </div>
            }
            saveClasses="btns theme-solid bg-danger w-100 mx-2 mb-2"
            isOpen={isDeleteDialogOpen}
            onSaveClicked={() =>
              DeleteSavedShareUnits(
                checkedDetailed,
                SavedShareUnitCriteriaIdValue,
                FiltersavedShareUnitCriteriaId,
                Singalid
              )
            }
            onCloseClicked={() => {
              setIsDeleteDialogOpen(false);
            }}
            onCancelClicked={() => {
              setIsDeleteDialogOpen(false);
            }}
          />
        </div>
        {isDialogOpenPauseShareUnitsComponent && (
          <PauseShareUnitsComponent
            data={checkedDetailed || []}
            isDialogOpen={isDialogOpenPauseShareUnitsComponent}
            setisDialogOpen={() => {
              SetisDialogOpenPauseShareUnitsComponent(false);
              setActiveItem(null);
            }}
            onsave={() => {
              GetAllUnitsSearch();
              SetisDialogOpenPauseShareUnitsComponent(false);
            }}
            parentTranslationPath={parentTranslationPath}
            translationPath={translationPath}
          />
        )}
      </div>
    </div>
  );
};
