import React, {
  useCallback,
  useEffect,
  useReducer,
  useState,
  useRef,
} from 'react';
import moment from 'moment';
import { ButtonBase } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import {
  DatePickerComponent,
  Inputs,
  Spinner,
} from '../../../../../../../../Components';
import { AgentTypesEnum } from '../../../../../../../../Enums';
import {
  bottomBoxComponentUpdate,
  showSuccess,
  showError,
  GlobalHistory,
  GetParams,
} from '../../../../../../../../Helper';
import {
  UpdateSaleUnitTransaction,
  GetSaleTransactionDetails,
} from '../../../../../../../../Services';

export const TransactionsDetails = ({
  unitTransactionId,
  parentTranslationPath,
  translationPath,
}) => {

  const { t } = useTranslation(parentTranslationPath, 'Shared');
  const isEditForm = useRef(+GetParams('actionType') === 1);
  const reducer = useCallback((state, action) => {
    if (action.id !== 'edit') return { ...state, [action.id]: action.value };
    return {
      ...action.value,
    };
  }, []);
  const [isLoading, setIsLoading] = useState(false);
  const [state, setState] = useReducer(reducer, {
    cityName: null,
    propertyName: null,
    unitReferenceNo: null,
    leadReferenceNo: null,
    claimDate: null,
    sellingPrice: null,
    agencyFeeSeller: 0,
    agencyFeeBuyer: 0,
    externalAgencyFeeSeller: 0,
    externalAgencyFeeBuyer: 0,
    netAmountBuyer: 0,
    netAmountSeller: 0,
    agentCommissions: [],
    transactionEntryDate : null,
    invoiceNotes: null
  });
  const getSaleTransactionDetails = useCallback(async () => {
    setIsLoading(true);
    const res = await GetSaleTransactionDetails(unitTransactionId);
    if (!((res && res.data && res.data.ErrorId) || !res))
      setState({
        id: 'edit',
        value: res,
      });
    setIsLoading(false);
  }, [unitTransactionId]);

  const updateSaleUnitTransaction = useCallback(async () => {
    setIsLoading(true);

    const mappedAgentCommissions =
      state.agentCommissions &&
      state.agentCommissions.map((item) => ({
        ...item,
        agentType:
          AgentTypesEnum[item.agentType] && AgentTypesEnum[item.agentType].key,
      }));
    const body = {
      claimDate: state.claimDate,
      agencyFeeSeller: state.agencyFeeSeller,
      agencyFeeBuyer: state.agencyFeeBuyer,
      externalAgencyFeeBuyer: state.externalAgencyFeeBuyer,
      externalAgencyFeeSeller: state.externalAgencyFeeSeller,
      netAmountBuyer: state.netAmountBuyer,
      netAmountSeller: state.netAmountSeller,
      sellingPrice: state.sellingPrice,
      agentCommissions: mappedAgentCommissions,
      transactionEntryDate: state.transactionEntryDate,
    };
    const res = await UpdateSaleUnitTransaction(unitTransactionId, body);
    if (!(res && res.status && res.status !== 200)) {
      reloadDetailsData();
      showSuccess(`Transaction Updated Successfully`);
    } else {
      showError('Transaction Update Failed');
    }
    setIsLoading(false);
  }, [unitTransactionId, state]);

  const reloadDetailsData = () => {
    getSaleTransactionDetails();
  };

  const saveHandler = () => {
    updateSaleUnitTransaction();
  };

  const cancelHandler = () => {
    GlobalHistory.push(`/home/sales-transactions/view`);
  };
  useEffect(() => {
    if (unitTransactionId) getSaleTransactionDetails();
  }, [getSaleTransactionDetails, unitTransactionId]);
  useEffect(() => {
    bottomBoxComponentUpdate(
      <div className='d-flex-v-center-h-end flex-wrap'>
        <ButtonBase
          className='btns theme-transparent mb-2'
          onClick={cancelHandler}
        >
          <span>{t('Shared:cancel')}</span>
        </ButtonBase>
        <ButtonBase
          disabled={!isEditForm.current}
          className='btns theme-solid mb-2'
          onClick={saveHandler}
        >
          <span>{t('Shared:save')}</span>
        </ButtonBase>
      </div>
    );
  });

  useEffect(
    () => () => {
      bottomBoxComponentUpdate(null);
    },
    []
  );
  return (
    <div className='transactions-details-wrapper childs-wrapper'>
      <Spinner isActive={isLoading} isAbsolute />
      <div className='form-item'>
        <Inputs
          idRef='cityNameRef'
          labelValue='state'
          value={state.cityName || ''}
          isDisabled
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
        />
      </div>
      <div className='form-item'>
        <Inputs
          idRef='propertyNameRef'
          labelValue='property-name'
          value={state.propertyName || ''}
          isDisabled
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
        />
      </div>
      <div className='form-item'>
        <Inputs
          idRef='unitReferenceNoRef'
          labelValue='unit-no'
          value={state.unitReferenceNo || ''}
          isDisabled
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
        />
      </div>
      <div className='form-item'>
        <Inputs
          idRef='leadReferenceNoRef'
          labelValue='lead-ref-no'
          value={state.leadReferenceNo || ''}
          isDisabled
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
        />
      </div>
      <div className='form-item'>
        <DatePickerComponent
          idRef='claimDateRef'
          labelValue='claim-date'
          placeholder='DD/MM/YYYY'
          value={state.claimDate}
          isDisabled={!isEditForm.current}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          onDateChanged={(newValue) => {
            setState({
              id: 'claimDate',
              value: (newValue && moment(newValue).format()) || null,
            });
          }}
        />
      </div>
      <div className='form-item'>
        <DatePickerComponent
          idRef='transactionEntryDate'
          labelValue='transaction-date'
          placeholder='DD/MM/YYYY'
          value={state.transactionEntryDate}
          isDisabled={!isEditForm.current}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          onDateChanged={(newValue) => {
            setState({
              id: 'transactionEntryDate',
              value: (newValue && moment(newValue).format()) || null,
            });
          }}
        />
      </div>
      <div className='form-item'>
        <Inputs
          idRef='sellingPriceRef'
          labelValue='selling-price'
          value={state.sellingPrice || 0}
          isDisabled={!isEditForm.current}
          onInputChanged={(event) => {
            const newValue = event.target.value;
            if (newValue) setState({ id: 'sellingPrice', value: +newValue });
            else setState({ id: 'sellingPrice', value: 0 });
          }}
          type='number'
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
        />
      </div>
      <div className='form-item'>
        <Inputs
          idRef='agencyFeeSellerRef'
          labelValue='seller-agency-fee'
          value={state.agencyFeeSeller || 0}
          isDisabled={!isEditForm.current}
          onInputChanged={(e) => {
            const value = e && e.target && e.target.value ? (e.target.value) : 0;
            if (Number.isNaN(value))
              value = 0;
            const fixed = (value && value.replace(/,/g, ''));
            let parsedValue = fixed ? parseFloat(fixed) : 0;

            const effectedByValue = (state.rentPerYear);
            if (parsedValue > effectedByValue) parsedValue = effectedByValue;

            const netAmountSeller= parsedValue - state.externalAgencyFeeSeller;
            
            setState({
              id: 'edit',
              value: {
                ...state,
                agencyFeeSeller: parsedValue,
                netAmountSeller
              },
            });
          }}
          type='number'
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
        />
      </div>
      <div className='form-item'>
        <Inputs
          idRef='agencyFeeBuyerRef'
          labelValue='External-Agency-Fee-(Seller)'
          value={state.externalAgencyFeeSeller || 0}
          isDisabled={!isEditForm.current}

          onInputChanged={(e) => {
            const value = e && e.target && e.target.value ? (e.target.value) : 0;
            if (Number.isNaN(value))
              value = 0;
            const fixed = (value && value.replace(/,/g, ''));
            let parsedValue = fixed ? parseFloat(fixed) : 0;

            const effectedByValue = (state.rentPerYear);
            if (parsedValue > effectedByValue) parsedValue = effectedByValue;

            const netAmountSeller = state.agencyFeeSeller - parsedValue;

            setState({
              id: 'edit',
              value: {
                ...state,
                externalAgencyFeeSeller: parsedValue,
                netAmountSeller
              },
            });
          }}
          type='number'
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
        />
      </div>
      <div className='form-item'>
        <Inputs
          idRef='netAmountBuyerRef'
          labelValue='Net-Amount-(Seller)'
          value={state.netAmountSeller || 0}
          isDisabled
          type='number'
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
        />
      </div>
      <div className='form-item'>
        <Inputs
          idRef='agencyFeeBuyerRef'
          labelValue='buyer-agency-fee'
          value={state.agencyFeeBuyer || 0}
          isDisabled={!isEditForm.current}
          onInputChanged={(e) => {
            const value = e && e.target && e.target.value ? (e.target.value) : 0;
            if (Number.isNaN(value))
              value = 0;
            const fixed = (value && value.replace(/,/g, ''));
            let parsedValue = fixed ? parseFloat(fixed) : 0;

            const effectedByValue = (state.rentPerYear);
            if (parsedValue > effectedByValue) parsedValue = effectedByValue;

            const netAmountBuyer= parsedValue - state.externalAgencyFeeBuyer;
            
            setState({
              id: 'edit',
              value: {
                ...state,
                agencyFeeBuyer: parsedValue,
                netAmountBuyer
              },
            });
          }}
          type='number'
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
        />
      </div>
      <div className='form-item'>
        <Inputs
          idRef='externalAgencyFeeBuyerRef'
          labelValue='External-Agency-Fee-(Buyer)'
          value={state.externalAgencyFeeBuyer || 0}
          isDisabled={!isEditForm.current}
          onInputChanged={(e) => {
            const value = e && e.target && e.target.value ? (e.target.value) : 0;
            if (Number.isNaN(value))
              value = 0;
            const fixed = (value && value.replace(/,/g, ''));
            let parsedValue = fixed ? parseFloat(fixed) : 0;

            const effectedByValue = (state.rentPerYear);
            if (parsedValue > effectedByValue) parsedValue = effectedByValue;

            const netAmountBuyer = state.agencyFeeBuyer - parsedValue;

            setState({
              id: 'edit',
              value: {
                ...state,
                externalAgencyFeeBuyer: parsedValue,
                netAmountBuyer
              },
            });
          }}
          type='number'
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
        />
      </div>
      <div className='form-item'>
        <Inputs
          idRef='netAmountBuyerRef'
          labelValue='Net-Amount-(Buyer)'
          value={state.netAmountBuyer || 0}
          isDisabled
          type='number'
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
        />
      </div>

      <div className='form-item'>
        <Inputs
          idRef='propertyNameRef'
          labelValue='property-name'
          value={state.propertyName || ''}
          isDisabled
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
        />
      </div>



      {state.agentCommissions &&
        state.agentCommissions.map((item, index) => (
          <div className='form-item'>
            <Inputs
              withNumberFormat
              idRef={`agent-commission-${index})`}
              labelValue={`${item.agentType} Agent Commission (${item.agentName})`}
              value={state.agentCommissions[index].commission || 0}
              endAdornment={<span className='px-2'>%</span>}
              type='number'
              min={0}
              max={100}
              isDisabled={!isEditForm.current}
              isWithError
              parentTranslationPath={parentTranslationPath}
              translationPath={translationPath}
              onInputChanged={(event) => {
                const { value } = event.target;
                const stateAgents = [...state.agentCommissions];
                const changedAgent = stateAgents[index];
                stateAgents[index] = {
                  ...changedAgent,
                  commission: value,
                };
                setState({ id: 'agentCommissions', value: stateAgents });
              }}
            />
          </div>
        ))}
      <div className='form-item'>
        <Inputs
          idRef='invoiceNotes'
          labelValue='notes'
          value={state.invoiceNotes || ''}
          isDisabled
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
        />
      </div>
    </div>
  );
};

TransactionsDetails.propTypes = {
  unitTransactionId: PropTypes.number,
  parentTranslationPath: PropTypes.string.isRequired,
  translationPath: PropTypes.string.isRequired,
};
TransactionsDetails.defaultProps = {
  unitTransactionId: null,
};
