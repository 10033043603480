

import React , {useState , useCallback , useEffect  } from 'react';
import { useTranslation } from 'react-i18next'; 
import { AutocompleteComponent , Inputs   } from '../../../../../../../Components' ;
import { lookupItemsGetId } from '../../../../../../../Services' ;
import { UnitViewsTypeIdEnum } from '../../../../../../../Enums' ;   


export const UnitsModelsFieldsFourthSectionComponent = ( {setState , state , translationPath , parentTranslationPath , propertyUnitTypes}) => {
   const { t } = useTranslation(parentTranslationPath);
   const [isLoading , setIsLoading ] = useState(false);

   const [views, setViews] = useState({
      result: [],
      totalCount: 0,
    });

    const GetAllViews = useCallback(async () => {
      setIsLoading(true);
      const res = await lookupItemsGetId({lookupTypeId:UnitViewsTypeIdEnum.lookupTypeId}) ;
      if (!(res && res.status && res.status !== 200)) {
         setViews({
            result: (res) || [],
           totalCount: 0,
        });
      } else {
         setViews({
          result: [],
          totalCount: 0,
        });
      }
      setIsLoading(false);
    },[]);


  useEffect(() => {
   GetAllViews();
  }, []);
  
  return (
    <>
        <div className='dialog-content-item'>
             <AutocompleteComponent
                idRef='unitTypeIdRef'
                labelValue='unitType'
                multiple={false}
                value={state.propertyUnitType}
                selectedValues={state.propertyUnitType}
                data={(propertyUnitTypes && propertyUnitTypes.result) || []}
                chipsLabel={(option) => (option && option.lookupItemName) || ''}
                displayLabel={(option) => (option && option.lookupItemName) || ''}
                withoutSearchButton
                parentTranslationPath={parentTranslationPath}
                translationPath={translationPath}
                onChange={(event, newValue) => 
                {
                    setState({ id: 'propertyUnitType', value: newValue  });
                }}
             />
          </div>
          <div className='dialog-content-item'>
             <AutocompleteComponent
                idRef='primaryViewRef'
                multiple
                data={views.result || []}
                isLoading={isLoading}
                value={(state.primaryView) || []}
                selectedValues={(state && state.primaryView) || []}
                labelValue='primary-view'
                chipsLabel={(option) => (( option.lookupItemName) || '')}
                displayLabel={(option) =>  ((option.lookupItemName )|| '')}
                withoutSearchButton
                parentTranslationPath={parentTranslationPath}
                translationPath={translationPath}
                onChange={(event, newValue) => 
                {
                    setState({ id: 'primaryView', value: (newValue && newValue.length ? newValue : null) });
 
                }}
                getOptionSelected={(option) => state && state.primaryView && state.primaryView.findIndex((item) => item.lookupItemId === option.lookupItemId) !== -1 || ''}

             />
          </div>
          <div className='dialog-content-item'>
             <AutocompleteComponent
                idRef='secondaryViewRef'
                multiple
                data={(views && views.result)|| []}
                labelValue='secondary-view'
                value={(state && state.secondaryView)||[]}
                selectedValues={(state && state.secondaryView) || []}
                chipsLabel={(option) => (option && option.lookupItemName) || ''}
                displayLabel={(option) => (option && option.lookupItemName) || ''}
                withoutSearchButton
                parentTranslationPath={parentTranslationPath}
                translationPath={translationPath}
                onChange={(event, newValue) => 
                  {
                      setState({ id: 'secondaryView', value: (newValue && newValue.length ? newValue : null) });
   
                 }}
                 getOptionSelected={(option) => state && state.secondaryView && state.secondaryView.findIndex((item) => item.lookupItemId === option.lookupItemId) !== -1 || ''}
                isLoading={isLoading}
             />
          </div>
          <div className='dialog-content-item'>
          <Inputs
                  idRef='unitsNoRef'
                  labelValue='unitsNo'
                  multiline
                  rows={6}
                  value={state.unitsNo}
                  onInputChanged={(event) => {
                    setState({ id: 'unitsNo', value: (event.target.value)   });
                  }}
                  translationPath={translationPath} 
                  parentTranslationPath={parentTranslationPath} 
               />
          </div>
        
     </>
  );
};
