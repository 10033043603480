export function RentDeposit(allValues, setData, formType) {
  let sum = 0;
  if (allValues) {
    let rentSecurityDeposit = 0;
    let chillerDeposit = 0;
    let rentGeneralDeposit = 0;
    if (allValues.rent_security_deposit && isNaN(allValues.rent_security_deposit)) {
      if (formType === 1) {
        setData('rent_security_deposit', null || 0 || '0');
        setData('rent_security-deposit', null || 0 || '0');
      }
    } else if (allValues.rent_security_deposit)
      rentSecurityDeposit = Number(allValues.rent_security_deposit);

    if (allValues.chiller_deposit && isNaN(allValues.chiller_deposit)) {
      if (formType === 1)
        setData('chiller_deposit', null || 0 || '0');
    } else if (allValues.chiller_deposit)
      chillerDeposit = Number(allValues.chiller_deposit);

    if (allValues.rent_general_deposit && isNaN(allValues.rent_general_deposit)) {
      if (formType === 1) {
        setData('rent_general_deposit', null || 0 || '0');
        setData('rent_general-deposit', null || 0 || '0');
      }
    } else if (allValues.rent_general_deposit)
      rentGeneralDeposit = Number(allValues.rent_general_deposit);

    sum = (Number(rentSecurityDeposit) + Number(chillerDeposit) + Number(rentGeneralDeposit));
    return Number(sum);
  }
  return Number(sum);
}

export function RentDeposit2(allValues, setData, formType, itemList) {
  if (checkIfFieldIsSensitive(formType, itemList))
    return (allValues && allValues.rent_price_fees && (allValues.rent_price_fees.deposit || 0)) || 0;

  let sum = 0;
  if (allValues) {
    let rentSecurityDeposit = 0;
    let chillerDeposit = 0;
    let rentGeneralDeposit = 0;

    if (allValues.rent_security_deposit && isNaN(allValues.rent_security_deposit))
      setData(null, 0, 'rent_security_deposit');

    else if (allValues.rent_security_deposit)
      rentSecurityDeposit = Number(allValues.rent_security_deposit);

    if (allValues.chiller_deposit && isNaN(allValues.chiller_deposit))
      setData(null, 0, 'chiller_deposit');

    else if (allValues.chiller_deposit)
      chillerDeposit = Number(allValues.chiller_deposit);

    if (allValues.rent_general_deposit && isNaN(allValues.rent_general_deposit))
      setData(null, 0, 'rent_general_deposit');

    else if (allValues.rent_general_deposit)
      rentGeneralDeposit = Number(allValues.rent_general_deposit);

    sum = (Number(rentSecurityDeposit) + Number(chillerDeposit) + Number(rentGeneralDeposit));
    return Number(sum);
  }
  return Number(sum);
}

export function checkIfFieldIsSensitive(formType, itemList) {
  if (formType === 2) {
    const rentSecurityDepositIndex = itemList.indexOf(itemList.find((f) => f.field.id === 'rent_security_deposit'));
    const rentGeneralDepositIndex = itemList.indexOf(itemList.find((f) => f.field.id === 'rent_general_deposit'));
    const chillerDepositIndex = itemList.indexOf(itemList.find((f) => f.field.id === 'chiller_deposit'));
    if (rentSecurityDepositIndex !== -1 && rentGeneralDepositIndex !== -1 && chillerDepositIndex !== -1) {
      if (itemList[rentSecurityDepositIndex].data.isReadonly || itemList[rentGeneralDepositIndex].data.isReadonly || itemList[chillerDepositIndex].data.isReadonly)
        return true;

      return false;
    }
  }
}
