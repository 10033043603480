import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useReducer,
} from "react";
import { ButtonBase } from "@material-ui/core";
import Joi from "joi";
import { useTranslation } from "react-i18next";
import { Inputs, Spinner } from "../../../../../Components";
import {
  GetParams,
  showError,
  showSuccess,
  getErrorByName,
} from "../../../../../Helper";
import {
  CreateBranchLocationAPI,
  GetBranchLocationsByDeveloperIdAPI,
} from "../../../../../Services";
import { BranchesLocationTable } from "./BranchesLocationComponents";
import { AddressLocationComponents } from "./BranchesLocationComponents/AddressLocationComponents";

export const BranchesLocation = ({
  translationPath,
  parentTranslationPath,
}) => {
  const { t } = useTranslation(parentTranslationPath, "Shared");
  const [items, setItems] = useState({
    allBranchesLocation: [
      {
        result: [],
        totalCount: 0,
      },
    ],
  });
  const searchTimer = useRef(null);
  const [openMapDialog, setOpenMapDialog] = useState(false);

  const [filter, setFilter] = useState({
    pageIndex: 0,
    pageSize: 25,
    search: "",
  });
  const [isLoading, setIsLoading] = useState({
    allBranchesLocation: false,
    addBranchesLocation: false,
  });
  const [isSubmitted, setIsSubmitted] = useState(false);

  const [defaultState, setDefaultState] = useState({
    developerContactId: +GetParams("id"),
    branchName: null,
    countryId: null,
    cityId: null,
    districtId: null,
    communityId: null,
    subCommunityId: null,
    street: null,
    buildingName: null,
    buildingNumber: null,
    floorNumber: null,
    officeNumber: null,
    mapCoordinates: null,
    postalCode: null,
    poBox: null,
    latitude: null,
    longitude: null,
  });

  const [defaultSelected, setDefaultSelected] = useState({
    country: null,
    city: null,
    district: null,
    community: null,
    subCommunity: null,
  });

  const reducer = (select, action) => {
    if (action.id !== "edit") return { ...select, [action.id]: action.value };
    if (action.id === "edit") {
      return {
        ...action.value,
      };
    }
  };

  const reducer2 = (select, action) => {
    if (action.id !== "edit") return { ...select, [action.id]: action.value };
    if (action.id === "edit") {
      return {
        ...action.value,
      };
    }
  };

  const [state, setState] = useReducer(reducer, {
    ...defaultState,
  });

  const [selected, setSelected] = useReducer(reducer2, {
    ...defaultSelected,
  });
  const schema = Joi.object({
    branchName: Joi.string()
      .required()
      .messages({
        "string.base": t(`${translationPath}branch-name-is-required`),
        "string.empty": t(`${translationPath}branch-name-is-required`),
        "string.required": t(`${translationPath}branch-name-is-required`),
      }),
  })
    .options({
      abortEarly: false,
      allowUnknown: true,
    })
    .validate(state);

  const onChangeHandler = (
    key,
    values,
    selectedObj,
    selectedValues,
    lookupItemParents
  ) => {
    if (!selectedObj) {
      setState({ id: [key], value: values });
    } else {
      // if(key === 'countryId' || key === 'cityId'  || key === 'districtId'  || key === 'communityId'  || key === 'subCommunityId'  )
      // {
      //   // onSelectAddressHandler(key ,values ,selectedValues , lookupItemParents) ;
      // }
      // else {
      setState({ id: [key], value: values });
      setSelected({ id: [selectedObj], value: selectedValues });
      // }
    }
  };

  const GetBranchLocationsByDeveloperId = async (search) => {
    setIsLoading((item) => ({ ...item, allBranchesLocation: true }));
    const rs = await GetBranchLocationsByDeveloperIdAPI(+GetParams("id"), {
      pageIndex: filter.pageIndex + 1,
      pageSize: filter.pageSize,
      name: search,
    });
    if (!rs) {
      setItems((item) => ({
        ...item,
        allBranchesLocation: { result: [], totalCount: 0 },
      }));
      return;
    } else {
      setItems((item) => ({
        ...item,
        allBranchesLocation: {
          result: rs?.result || [],
          totalCount: rs.totalCount || 0,
        },
      }));
    }
    setIsLoading((item) => ({ ...item, allBranchesLocation: false }));
  };

  const reloadData = () => {
    setState({ id: "edit", value: defaultState });
    setSelected({ id: "edit", value: defaultSelected });
    GetBranchLocationsByDeveloperId();
  };

  const CreateBranchLocationHandler = useCallback(async () => {
    setIsSubmitted(true);

    setIsLoading((item) => ({ ...item, addBranchesLocation: true }));
    const body = { ...state };
    const res = await CreateBranchLocationAPI(body);
    setIsLoading((item) => ({ ...item, addBranchesLocation: false }));
    if (!(res && res.status && res.status !== 200)) {
      showSuccess(t`${translationPath}branches-location-created-successfully`);
      reloadData();
    } else showError(t`${translationPath}branches-location-create-failed`);
  }, [state]);

  useEffect(() => {
    reloadData();
  }, [filter]);

  return (
    <div className="agreement-details-deveoloper-wrapper childs-wrapper d-flex-column">
      <div className="w-100 agreement-details-add">
        <Spinner
          isActive={
            isLoading.addBranchesLocation || isLoading.allBranchesLocation
          }
        />

        <div className="w-100">
          <div className="part1 mt-2 mb-2">
            <div className="px-2 item">
              <Inputs
                isAttachedInput
                idRef={"branch-NameRef"}
                labelValue={"branch-name"}
                type={"text"}
                value={state.branchName || ""}
                onInputChanged={(e) => {
                  const { value } = e.target;
                  onChangeHandler("branchName", value);
                }}
                inputPlaceholder={"enter-branch-name"}
                translationPath={translationPath}
                parentTranslationPath={parentTranslationPath}
                helperText={getErrorByName(schema, "branchName").message}
                error={getErrorByName(schema, "branchName").error}
                isWithError
                isSubmitted={isSubmitted}
              />
            </div>
            <AddressLocationComponents
              className={"px-2 item"}
              translationPath={translationPath}
              parentTranslationPath={parentTranslationPath}
              state={state}
              setState={setState}
              setSelected={setSelected}
              selected={selected}
            />
            <div className="px-2 item">
              <Inputs
                isAttachedInput
                idRef={"StreetRef"}
                labelValue={"street"}
                value={state.street || ""}
                type={"text"}
                onInputChanged={(e) => {
                  const { value } = e.target;
                  onChangeHandler("street", value);
                }}
                translationPath={translationPath}
                parentTranslationPath={parentTranslationPath}
              />
            </div>
            <div className="px-2 item">
              <Inputs
                isAttachedInput
                idRef={"BuildingNameRef"}
                labelValue={"building-name"}
                type={"text"}
                value={state.buildingName || ""}
                onInputChanged={(e) => {
                  const { value } = e.target;
                  onChangeHandler("buildingName", value);
                }}
                translationPath={translationPath}
                parentTranslationPath={parentTranslationPath}
              />
            </div>
            <div className="px-2 item">
              <Inputs
                isAttachedInput
                idRef={"BuildingNumberRef"}
                labelValue={"building-number"}
                type={"text"}
                value={state.buildingNumber || ""}
                onInputChanged={(e) => {
                  const { value } = e.target;
                  onChangeHandler("buildingNumber", value);
                }}
                translationPath={translationPath}
                parentTranslationPath={parentTranslationPath}
              />
            </div>
            <div className="px-2 item">
              <Inputs
                isAttachedInput
                idRef={"FloorNumberRef"}
                labelValue={"floor-number"}
                type={"text"}
                value={state.floorNumber || ""}
                onInputChanged={(e) => {
                  const { value } = e.target;
                  onChangeHandler("floorNumber", value);
                }}
                translationPath={translationPath}
                parentTranslationPath={parentTranslationPath}
              />
            </div>
            <div className="px-2 item">
              <Inputs
                isAttachedInput
                idRef={"OfficeNumberRef"}
                labelValue={"office-number"}
                type={"text"}
                value={state.officeNumber || ""}
                onInputChanged={(e) => {
                  const { value } = e.target;
                  onChangeHandler("officeNumber", value);
                }}
                translationPath={translationPath}
                parentTranslationPath={parentTranslationPath}
              />
            </div>
            <div className="px-2 item">
              <Inputs
                isAttachedInput
                idRef={"Postal-ZIPCodeRef"}
                labelValue={"postal-zip-code"}
                type={"text"}
                value={state.postalCode || ""}
                onInputChanged={(e) => {
                  const { value } = e.target;
                  onChangeHandler("postalCode", value);
                }}
                translationPath={translationPath}
                parentTranslationPath={parentTranslationPath}
              />
            </div>
            <div className="px-2 item">
              <Inputs
                isAttachedInput
                idRef={"poBoxRef"}
                labelValue={"po-box"}
                type={"text"}
                value={state.poBox || ""}
                onInputChanged={(e) => {
                  const { value } = e.target;
                  onChangeHandler("poBox", value);
                }}
                translationPath={translationPath}
                parentTranslationPath={parentTranslationPath}
              />
            </div>
            <div className="mt-3 addBtn">
              <ButtonBase
                className="btns theme-solid"
                disabled={schema.error || isLoading.addBranchesLocation}
                onClick={() => {
                  CreateBranchLocationHandler();
                }}
              >
                <span className="mdi mdi-plus" />
                {t(`${translationPath}add-branch-location`)}
              </ButtonBase>
            </div>
          </div>
        </div>
      </div>
      <BranchesLocationTable
        translationPath={translationPath}
        parentTranslationPath={parentTranslationPath}
        data={items.allBranchesLocation}
        filter={filter}
        setFilter={setFilter}
        reloadData={reloadData}
      />
    </div>
  );
};
