import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => {
    return {
        containerCardTransactionItem: {
          padding: '12px 24px',
          display:'flex',
          flexDirection:'row',
          justifyContent:'space-between',
          backgroundColor:theme.palette.background.paper,
          [theme.breakpoints.down('sm')]: {
            display:'flex',
            flexDirection:'column',
          },
        },
        cardwithborder: {
            border: '1px solid', 
            borderRadius: '12px',
            borderColor:theme.palette.border.secondary
        },
        cardwithPadding:{
            padding: '20px 0px',
        },
        transactionInfoContainer:{
          display:'flex',
          gap:'12px',
          flexDirection:'column'
        },
        topTransactionInfoBox: {
          display: 'flex',
          flexDirection: 'column',
          gap: '2px'
        },
        transactionTypeBox: {
          display: 'flex',
          alignItems: 'flex-start',
          flexDirection: 'row',
          gap: '8px',
        },
        transactionBoxId: {
          display: 'flex',
          alignItems: 'flex-start',
          flexDirection: 'row',
          gap: '8px',
        },
        transactionTitleType: {
          color: theme.palette.text.primary,
          fontSize: '16px',
          lineHeight: '20px'
        },
        transactionTitleId: {
            color: theme.palette.text.primary,
            fontSize: '16px',
            lineHeight: '20px'
        },
        transactionTitleValue: {
          fontSize: '16px',
          lineHeight: '20px',
          color:theme.palette.text.primary
        },
        transactionIdValue: {
          fontSize: '14px',
          lineHeight: '20px',
          color:theme.palette.text.primary
        },
        bottomTransactionInfoBox:{
          display:'flex',
          flexDirection:'row',
          gap:'20px',
          [theme.breakpoints.down('sm')]: {
            display:'flex',
            flexDirection:'column',
          }
        },
        leadInfoBox:{
          display: 'flex',
          gap: '12px',
          alignItems: 'flex-start',
          flexDirection:'row'
        },
        leadInfo:{
          display:'flex',
          alignItems:'flex-start',
          gap:'2px',
          flexDirection:'column'
        },
        leadId:{
          display:'flex',
          alignItems:'flex-start',
          gap:'8px',
          flexDirection:'row',
          fontSize:'14px',
          color: theme.palette.text.primary,
          lineHeight: '20px'
        },
        containerLeadType:{
          display:'flex',
          flexDirection:'row',
          gap:'8px'
        },
        containerLead:{
            display:'flex',
            flexDirection:'row',
            gap:'8px'
        },
        containerLeadId:{
          display:'flex',
          flexDirection:'row',
          gap:'8px'
        },
        unitIdBox:{
          display:'flex',
          flexDirection:'row',
          gap:'8px'
        },
        containerunit:{
          display:'flex',
          flexDirection:'row',
        },
        timeInfo:{
          display:'flex',
          alignItems:'flex-start',
          flexDirection:'column',
          gap:'4px'
        },
        container_ProprtyName:{
          display:'flex',
          flexDirection:'row',
          gap:'8px',
          fontSize:'14px',
          color: theme.palette.text.primary,
        },
        containerTime_Date:{
          display: 'flex',
          flexDirection: 'row',
          gap: '10px',
          justifyContent:'end',
          color:theme.palette.utility.utility_gray_500,
          [theme.breakpoints.down('sm')]: {
            justifyContent:'start',
          }
        },
        leadTypeTitle:{
            fontSize:'14px',
            color: theme.palette.text.primary,
            lineHeight: '20px'
        },
        unitId:{
            fontSize:'14px',
            color: theme.palette.text.primary,
            lineHeight: '20px',
            display: 'flex',
            flexDirection: 'row',
            gap: '8px',
            alignItems: 'center'
        }
      };
})