

export const PolicyPermissions = 
{
    EditPolicy : {
        permissionsId : "1b44330a-2805-4925-a5ce-08db04f6cbfc",
        permissionsName: "Edit Policy",
        componentsId: null,
        components: null
      },
      AddPolicy :  {
        permissionsId: "7fa29439-aac2-4ef2-a5cd-08db04f6cbfc",
        permissionsName: "Add Policy",
        componentsId: null,
        components: null
      },
      DeletePolicy :  {
        permissionsId: "393eb863-6f5c-40e9-a5cf-08db04f6cbfc",
        permissionsName: "Delete Policy",
        componentsId: null,
        components: null
      },
      AssignPolicy :  {
        permissionsId: "23a81ffc-c67c-45f2-a5d0-08db04f6cbfc",
        permissionsName: "Assign Policy",
        componentsId: null,
        components: null
      },
      ShowModule : {
        permissionsId: "fd284cce-16bc-45fd-a5d1-08db04f6cbfc",
        permissionsName: "Show Module",
        componentsId: null,
        components: null
      }
    
};
  


