import React, { useState, useCallback, useReducer, useRef } from "react";
import PropTypes from "prop-types";
import moment from 'moment';
import { useTranslation } from "react-i18next";
import {
  AutocompleteComponent,
  DatePickerComponent,
  RadiosGroupComponent,
} from "../../../../../../../Components";
import { OrganizationUserSearch } from "../../../../../../../Services";
import { getErrorByName } from "../../../../../../../Helper";

export const ContractSection = ({
  isSubmitted,
  schema,
  isReadOnly,
  selected,
  onSelectedChanged,
  parentTranslationPath,
  translationPath }) => {
  const { t } = useTranslation(parentTranslationPath);
  const searchTimer = useRef(null);

  const reducer = useCallback((selected, action) => {
    if (action.id !== "edit") return { ...selected, [action.id]: action.value };
    return {
      ...action.value,
    };
  }, []);

  const [data, setData] = useReducer(reducer, {
    systemUsers: [],
  });

  const getSystemUsers = async (searchedItem) => {
    const res = await OrganizationUserSearch({
      pageIndex: 0,
      pageSize: 10,
      name: searchedItem || "",
      userStatusId: 2,
    });
    if (!(res && res.status && res.status !== 200))
      setData({
        id: "systemUsers",
        value: res?.result,
      });
    else setData({ id: "systemUsers", value: [] });
  };

  return (
    <div>
      <div className="d-flex fj-start b-bottom">
        <div className="w-33 mt-2 pt-1">
          <span className="fw-simi-bold">
            {t(`${translationPath}Is-tenancy-contract-issued?`)}
          </span>
        </div>
        <div className="w-50 mt-0 mb-3 pt-1">
          <div className="d-flex-column gap-12">
            <RadiosGroupComponent
              idRef="tenancyContractIssuedRef"
              themeClass="theme-default theme-propx"
              data={[
                {
                  key: true,
                  value: "yes",
                },
                {
                  key: false,
                  value: "no",
                },
              ]}
              value={selected.tenancyContractIssued}
              parentTranslationPath={parentTranslationPath}
              translationPathForData={translationPath}
              translationPath={translationPath}
              labelInput="value"
              valueInput="key"
              onSelectedRadioChanged={
                (_, newValue) => onSelectedChanged({ id: 'tenancyContractIssued', value: newValue === 'true' })
              }
              isDisabled={isReadOnly}
            />
            <DatePickerComponent
              idRef="contractIssuedDateRef"
              placeholder="DD/MM/YYYY"
              value={selected.contractIssuedDate}
              onDateChanged={(newValue) => onSelectedChanged({ id: 'contractIssuedDate', value: (newValue && moment(newValue).format()) || null, })}
              isDisabled={isReadOnly}
            />
          </div>
        </div>
      </div>
      <div className="d-flex fj-start b-bottom">
        <div className="w-33 mt-2 pt-1">
          <span className="fw-simi-bold">
            {t(`${translationPath}Contract-issued-by`)}
          </span>
        </div>
        <div className="w-50 mt-2 mb-3 pt-1">
          <AutocompleteComponent
            idRef="contractIssuedByRef"
            inputPlaceholder="select"
            selectedValues={selected.contractIssuedBy}
            multiple={false}
            data={data.systemUsers || []}
            displayLabel={(option) => option.fullName || ""}
            renderOption={(option) =>
              ((option.userName || option.fullName) &&
                `${option.fullName} (${option.userName})`) ||
              ""
            }
            // getOptionSelected={(option) => option.id === selected.contractIssuedById}
            withoutSearchButton
            helperText={getErrorByName(schema, 'contractIssuedById').message}
            error={getErrorByName(schema, 'contractIssuedById').error}
            isSubmitted={isSubmitted}
            // isLoading={loadings.users}
            onOpen={() => {
              if (data.systemUsers && data.systemUsers.length == 0)
                getSystemUsers();
            }}
            onInputKeyUp={(e) => {
              const { value } = e.target;

              if (searchTimer) clearTimeout(searchTimer.current);

              searchTimer.current = setTimeout(() => {
                getSystemUsers(value);
              }, 1200);
            }}
            isWithError
            parentTranslationPath={parentTranslationPath}
            translationPath={translationPath}
            onChange={(_, newValue) => {
              onSelectedChanged({ id: 'contractIssuedBy', value: newValue });
              // onSelectedChanged({
              //   id: 'contractIssuedById',
              //   value: (newValue && newValue.id) || null,
              // });
            }}
            isDisabled={isReadOnly}
          />
        </div>
      </div>
      <div className="d-flex fj-start b-bottom">
        <div className="w-33 mt-2 pt-1">
          <span className="fw-simi-bold">
            {t(`${translationPath}Is-contract-renewable?`)}
          </span>
        </div>
        <div className="w-50 mt-0 mb-3 pt-1">
          <RadiosGroupComponent
            idRef="contractRenewableRef"
            themeClass="theme-default theme-propx"
            data={[
              {
                key: true,
                value: "yes",
              },
              {
                key: false,
                value: "no",
              },
            ]}
            isDisabled={isReadOnly}
            value={selected.contractRenewable}
            parentTranslationPath={parentTranslationPath}
            translationPathForData={translationPath}
            translationPath={translationPath}
            labelInput="value"
            valueInput="key"
            onSelectedRadioChanged={
              (e, newValue) => onSelectedChanged({ id: 'contractRenewable', value: newValue === 'true' })
            }
          />
        </div>
      </div>
      <div className="d-flex fj-start b-bottom">
        <div className="w-33 mt-2 pt-1">
          <span className="fw-simi-bold">
            {t(`${translationPath}Print-contract-on`)}
          </span>
        </div>
        <div className="w-50 mt-0 mb-3 pt-1">
          <RadiosGroupComponent
            idRef="printContractOnRef"
            themeClass="theme-default theme-propx"
            data={[
              {
                key: true,
                value: "tenant",
              },
              {
                key: false,
                value: "company",
              },
            ]}
            value={selected.printContractOn}
            parentTranslationPath={parentTranslationPath}
            translationPathForData={translationPath}
            translationPath={translationPath}
            labelInput="value"
            valueInput="key"
            onSelectedRadioChanged={(e, newValue) => onSelectedChanged({ id: 'printContractOn', value: newValue === 'true' })
            }
            isDisabled={isReadOnly}
          />
        </div>
      </div>
    </div>
  );
};

ContractSection.propTypes = {
  schema: PropTypes.object,
  isSubmitted: PropTypes.bool,
}
ContractSection.defaultProps = {
  schema: {},
  isSubmitted: false,
}