import React, { useCallback, useEffect, useReducer, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  AutocompleteComponent,
  CheckboxesComponent,
  DialogComponent,
  Spinner,
  SwitchComponent,
} from '../../../../Components';
import '../style/PublishUnitApprovals.scss';
import { UnitApprovalsPeriodInputComponent } from './UnitApprovalsPeriodInputComponent';
import { GetUsersByBranchId } from '../../../../Services';
import {
  CreateSettingsForUnitApproval,
  UpdateSettingsForUnitApproval,
} from '../../../../Services/UnitApprovalServices/UnitApprovalServices';
import { getErrorByName, showError, showSuccess } from '../../../../Helper';
import Joi from 'joi';

export const UnitApprovalsForm = ({
  parentTranslationPath,
  translationPath,
  isDialogOpen,
  isOpenChanged,
  setIsDialogOpen,
  activeItem,
  onSave,
  data,
  isLoading,
  reducer,
  setData
}) => {
  const initialState = {
    branchId: undefined,
    expirationPeriod: undefined,
    durationType: 1,
    isApplyOnpublish: false,
    isApplyOnUnpublish: false,
    isSendNotification: false,
    isSendEmail: false,
    excludedRoleIds: [],
    excludedUserIds: [],
    approverId: [],
    approvalSettingType: undefined,
  };

  const { t } = useTranslation(parentTranslationPath);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [selected, setSelected] = useReducer(reducer, {
    branch: {},
    roles: [],
    users: [],
    usersApproval: [],
  });
  const [userRelatedBranchData,setUserRelatedBranchData]=useState([])
  const [state, setState] = useReducer(reducer, initialState);
  const schema = Joi.object({
    branchId: Joi.number()
      .required()
      .messages({
        'number.empty': t(`${translationPath}branch-is-required`),
        'number.base': t(`${translationPath}branch-is-required`),
        'any.required': t(`${translationPath}branch-is-required`),
      }),
    approverId: Joi.array()
      .items(Joi.string())
      .min(1)
      .required()
      .messages({
        'number.empty': t(`${translationPath}user-approved-is-required`),
        'number.base': t(`${translationPath}user-approved-is-required`),
        'any.required': t(`${translationPath}user-approved-is-required`),
        'array.min': t(`${translationPath}user-approved-is-required`),
      }),
    expirationPeriod: Joi.number()
      .min(1)
      .required()
      .messages({
        'number.empty': t(`${translationPath}expiredPeriod-is-required`),
        'number.base': t(`${translationPath}expiredPeriod-is-required`),
        'any.required': t(`${translationPath}expiredPeriod-is-required`),
        'number.min': t(
          `${translationPath}expiredPeriod-must-be-greater-than-zero`
        ),
      }),
  })
    .options({
      abortEarly: false,
      allowUnknown: true,
    })
    .validate(state);

  const handleCreateUnitApproval = async (event) => {
    event.preventDefault();
    setIsSubmitted(true);
    if (schema.error) {
      showError(t(`${translationPath}Please-fill-all-the-required-fields.`));
      return;
    }
    const body = { ...state, approvalSettingType: 1 };
    const res = activeItem
      ? await UpdateSettingsForUnitApproval(
          activeItem.unitApprovalSettingId,
          body
        )
      : await CreateSettingsForUnitApproval(body);
    if (!(res && res.status && res.status !== 200)) {
      showSuccess(
        t(
          `${translationPath}${
            (activeItem && `setting-for-unit-approval-updated-successfully`) ||
            `setting-for-unit-approval-created-successfully`
          }`
        )
      );
      setIsDialogOpen(false);
      onSave();
    } else if (res?.data?.Message) {
      const errorMessage =
        res?.data.Message.split(' : ')[1] || res.data.Message;
      showError(t(`${translationPath}${errorMessage}`));
    } else {
      showError(
        t(
          `${translationPath}${
            (activeItem && `setting-for-unit-approval-updated-failed`) ||
            `setting-for-unit-approval-created-failed`
          }`
        )
      );
    }
  };

  const getUsersByBranchId = useCallback(async ({ branchId }) => {
    if(!branchId){
      return
    }
    const res = await GetUsersByBranchId(branchId);
    if (!(res && res.status && res.status !== 200)) {
      if (res.length > 0) {
        setUserRelatedBranchData(res || [])
      } else {
        setUserRelatedBranchData([])
      }
    }
  }, [])
const resetRelatedTo =()=>{
  setState({ id: 'excludedUserIds', value: [] });
  setSelected({ id: 'users', value: [] });
}
useEffect(() => {
  getUsersByBranchId({ branchId :activeItem?.branchId })
},[])
  useEffect(() => {
    if (activeItem) {
      setState({
        id: 'edit',
        value: {
          branchId: activeItem.branchId,
          expirationPeriod: activeItem.expirationPeriod,
          durationType: activeItem.durationTypeId,
          isApplyOnpublish: activeItem.isApplyOnpublish,
          isApplyOnUnpublish: activeItem.isApplyOnUnpublish,
          isSendNotification: activeItem.isSendNotification,
          isSendEmail: activeItem.isSendEmail,
          excludedRoleIds: activeItem.excludedRoleNames.map(
            (item) => item.roleId
          ),
          excludedUserIds: activeItem.excludedUserNames.map(
            (item) => item.excludedUserId
          ),
          approverId: activeItem.approverNames.map(
            (item) => item.approverUserId
          ),
        },
      });
      setSelected({
        id: 'edit',
        value: {
          branch: {
            branchName: activeItem.branchName,
            branchId: activeItem.branchId,
          },
          roles: activeItem.excludedRoleNames.map((item) => ({
            rolesName: item.roleName,
            rolesId: item.roleId,
          })),
          users: activeItem.excludedUserNames.map((item) => ({
            userName: item.excludedUserName,
            userId: item.excludedUserId,
          })),
          usersApproval: activeItem.approverNames.map((item) => ({
            fullName: item.approverName,
            usersId: item.approverUserId,
          })),
        },
      });
    }
  }, [activeItem, data]);

  return (
    <>
      <Spinner
        isActive={isLoading && Object?.values(isLoading).some((load) => load)}
      />
      <DialogComponent
        isOpen={isDialogOpen}
        saveType='button'
        titleText={'publish-unit'}
        parentTranslationPath={parentTranslationPath}
        translationPath={translationPath}
        maxWidth={'sm'}
        saveClasses='btns theme-solid w-100 mx-2 mb-2'
        onCloseClicked={isOpenChanged}
        onCancelClicked={isOpenChanged}
        onSaveClicked={handleCreateUnitApproval}
        dialogContent={
          <div className='unit-approvals-dialog-wrapper'>
            <div className='form-item w-100 mt-1'>
              <div className='border-head'>
                <div className='line'></div>
                <div className='section-1'>
                  <div className='action-box'>
                    <CheckboxesComponent
                      idRef='publishUnitIdRef'
                      label={t(`${translationPath}publish-unit`)}
                      parentTranslationPath={parentTranslationPath}
                      translationPath={translationPath}
                      singleChecked={state?.isApplyOnpublish}
                      onSelectedCheckboxChanged={(event) => {
                        setState({
                          id: 'isApplyOnpublish',
                          value: event?.target.checked,
                        });
                      }}
                    />
                    <CheckboxesComponent
                      idRef='removePublishUnitIdRef'
                      label='remove-publish-unit'
                      isChecked={state?.isApplyOnUnpublish}
                      parentTranslationPath={parentTranslationPath}
                      translationPath={translationPath}
                      singleChecked={state?.isApplyOnUnpublish}
                      onSelectedCheckboxChanged={(event) => {
                        setState({
                          id: 'isApplyOnUnpublish',
                          value: event?.target.checked,
                        });
                      }}
                    />
                  </div>
                </div>
                <div className='line'></div>
                <div className='section-2'>
                  <div className='form-item mt-3 w-25'>
                    <AutocompleteComponent
                      idRef='branchIdRef'
                      labelValue='branch'
                      data={data?.branches || []}
                      withoutSearchButton
                      displayLabel={(option) => option?.branchName || ''}
                      renderOption={(option) => option?.branchName || ''}
                      getOptionSelected={(option) =>
                        option.branchId === state.branchId
                      }
                      multiple={false}
                      selectedValues={selected?.branch || {}}
                      isLoading={isLoading?.branches}
                      onChange={(e, newValue) => {
                        setState({ id: 'branchId', value: newValue?.branchId });
                        setSelected({ id: 'branch', value: newValue ?? {} });

                        resetRelatedTo();
                        getUsersByBranchId({ branchId: (newValue && newValue.branchId) || null })
                      }}
                      parentTranslationPath={parentTranslationPath}
                      translationPath={translationPath}
                      isWithError
                      isSubmitted={isSubmitted}
                      helperText={getErrorByName(schema, 'branchId').message}
                      error={getErrorByName(schema, 'branchId').error}
                    />
                    <AutocompleteComponent
                      idRef='RoleIdRef'
                      labelValue='role-without'
                      data={data?.roles || []}
                      displayLabel={(option) => option?.rolesName || ''}
                      withoutSearchButton
                      multiple
                      isLoading={isLoading?.roles}
                      chipsLabel={(option) => option.rolesName || ''}
                      selectedValues={selected?.roles || []}
                      onChange={(e, newValue) => {
                        setState({
                          id: 'excludedRoleIds',
                          value: newValue?.map((role) => role.rolesId),
                        });
                        setSelected({ id: 'roles', value: newValue });
                      }}
                      inputPlaceholder={t(`${translationPath}select-multiple`)}
                      parentTranslationPath={parentTranslationPath}
                      translationPath={translationPath}
                    />
                    <AutocompleteComponent
                      labelValue='user-without'
                      idRef='userWithoutIdRef'
                      data={userRelatedBranchData || []}
                      displayLabel={(option) =>
                        option.userName || ''
                      }
                      multiple
                      isLoading={isLoading?.users}
                      chipsLabel={(option) => option?.userName  || ''}
                      selectedValues={selected?.users || []}
                      onChange={(e, newValue) => {
                        setState({
                          id: 'excludedUserIds',
                          value: newValue?.map((user) => user.userId),
                        });
                        setSelected({ id: 'users', value: newValue });
                      }}
                      withoutSearchButton
                      inputPlaceholder={t(`${translationPath}select-multiple`)}
                      parentTranslationPath={parentTranslationPath}
                      translationPath={translationPath}
                      isDisabled={!state.branchId}
                    />
                    <AutocompleteComponent
                      labelValue='approved-by'
                      idRef='approvedByIdRef'
                      isSubmitted={isSubmitted}
                      data={data?.usersApproval || []}
                      displayLabel={(option) => option?.fullName || ''}
                      multiple
                      isLoading={isLoading?.users}
                      chipsLabel={(option) => option.fullName || ''}
                      selectedValues={selected?.usersApproval || []}
                      onChange={(e, newValue) => {
                        setState({
                          id: 'approverId',
                          value: newValue?.map((user) => user.usersId),
                        });
                        setSelected({ id: 'usersApproval', value: newValue });
                      }}
                      withoutSearchButton
                      isWithError
                      inputPlaceholder={t(`${translationPath}select-multiple`)}
                      parentTranslationPath={parentTranslationPath}
                      translationPath={translationPath}
                      helperText={getErrorByName(schema, 'approverId').message}
                      error={getErrorByName(schema, 'approverId').error}
                    />
                    <div className='period-input'>
                      <UnitApprovalsPeriodInputComponent
                        state={state}
                        setState={(item) => {
                          setState({
                            id: 'edit',
                            value: {
                              ...state,
                              ...item,
                            },
                          });
                        }}
                        isSubmitted={isSubmitted}
                        parentTranslationPath={parentTranslationPath}
                        translationPath={translationPath}
                        schema={schema}
                        setSelected={setSelected}
                        selected={selected}
                      />
                    </div>
                    <div className='checkbox-container'>
                      <SwitchComponent
                        idRef='notificationRef'
                        label='notification'
                        isChecked={state?.isSendNotification}
                        parentTranslationPath={parentTranslationPath}
                        translationPath={translationPath}
                        onChangeHandler={(e, isChecked) =>
                          setState({
                            id: 'isSendNotification',
                            value: isChecked,
                          })
                        }
                      />
                      <SwitchComponent
                        idRef='emailRef'
                        label='email'
                        isChecked={state?.isSendEmail}
                        parentTranslationPath={parentTranslationPath}
                        translationPath={translationPath}
                        onChangeHandler={(e, isChecked) =>
                          setState({ id: 'isSendEmail', value: isChecked })
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
      />
    </>
  );
};
