import React, { useEffect, useState } from "react";
import arrowLeft from "../../../../../assets/images/icons/arrow-left.png";
import { AccordionTable, DocListHolder } from "../../Utilities";
import { useTranslation } from "react-i18next";
import { TransactionDocsHeaderData } from "./TransactionDocsHeaderData";
import { GetTransactionDocumentsCheckList } from "../../../../../Services";
import { useCallback } from "react";
import { DocumentSourceTypeEnum } from "../../../../../Enums";
import { ButtonBase } from "@material-ui/core";
import { GlobalHistory } from "../../../../../Helper";
import { TransactionStepsData } from "../TransactionStepsData";

export const DocumentsChecklist = ({
  selected,
  onSelectedChanged,
  unitId,
  isSubmitted,
  changeActiveSubmitButton,
  parentTranslationPath,
}) => {
  const { t } = useTranslation(parentTranslationPath);
  const translationPath = "DocumentChecklist.";

  const [docChecklist, setDocChecklist] = useState([]);

  const mapSingleRetrievedDoc = (document) => {
    return {
      fileName: document.fileName,
      uuid: document.fileId,
      isRetrieved: true,
    };
  };

  const mapSingleUploadedDoc = ({ document, row }) => ({
    fileId: document?.uuid,
    fileName: document?.fileName,
    categoryId: row?.categoryId,
    documentSourceTypeId: row?.documentSourceTypeId,
    isLandlordOfferLatter:
      row?.documentSourceTypeId ===
      DocumentSourceTypeEnum.LandlordOfferLetter.key,
    isTenantOfferLatter:
      row?.documentSourceTypeId ===
      DocumentSourceTypeEnum.TenantOfferLetter.key,
    isLandlordTenancyContract:
      row?.documentSourceTypeId ===
      DocumentSourceTypeEnum.LandlordTenancyContract.key,
    isTenantTenancyContract:
      row?.documentSourceTypeId ===
      DocumentSourceTypeEnum.TenantTenancyContract.key,
    isUploaded: true,
  });

  const docChangeHandler = useCallback(
    (documents, row) => {
      const localDocChecklist = [...(docChecklist || [])];
      const DocCategoryIndex = localDocChecklist.findIndex(
        (item) => item.id == row.id
      );
      const updatedDocCategory = {
        ...(row || {}),
        documents: documents.map((item) =>
          item.isRetrieved
            ? item
            : mapSingleUploadedDoc({
              document: item,
              row,
            })
        ),
      };

      localDocChecklist[DocCategoryIndex] = updatedDocCategory;

      setDocChecklist(localDocChecklist);
    },
    [docChecklist]
  );

  const filterUploadedDocuments = () => {
    let localDocs = [];

    if (docChecklist)
      docChecklist.forEach((item) => {
        const uploadedDocs = item.documents.filter((item) => item.isUploaded);
        localDocs = [...localDocs, ...(uploadedDocs || [])];
      });

    return localDocs;
  };

  const DTOChangeHandler = async () => {
    const DocumentChecklistSaveBody = {
      unitId,
      missingDocumentsFiles: filterUploadedDocuments(),
    };

    const DocumentChecklistDTO = {
      ...(selected.transactionDTOs || {}),
      DocumentChecklistSave: DocumentChecklistSaveBody,
    };

    onSelectedChanged({ id: "transactionDTOs", value: DocumentChecklistDTO });
  };

  const getTransactionDocumentsCheckList = async () => {
    const res = await GetTransactionDocumentsCheckList(unitId);
    if (!(res && res.status && res.status !== 200)) {
      const mappedCheckList =
        res &&
        res.map((item) => {
          const mappedDocs = item.documents.map((doc) =>
            mapSingleRetrievedDoc(doc, false, false)
          );
          return {
            ...item,
            documents: mappedDocs,
          };
        });
      setDocChecklist(mappedCheckList || []);

    } else setDocChecklist([]);
  };

  const getExpandedRowContent = (row) => {
    const isReceiptDocs = row.documentSourceTypeName == DocumentSourceTypeEnum.RentPerYearReceipts.name ||
      row.documentSourceTypeName == DocumentSourceTypeEnum.SecurityDepositReceipts.name;

    return (<>
      {(isReceiptDocs && row?.documents?.length == 0) ?
        <div className="d-flex-column-center mb-3">
          <span className="fw-simi-bold fz-14 mb-1">No receipt documents available.</span>
          <span className="fz-10 mb-3 light-text">You can add missing receipt documents in payment details page .</span>
          <ButtonBase
            className="btns theme-propx solid mr-0 mb-3"
            onClick={() => GlobalHistory.push(`/home/lease-transaction-journey?id=${unitId}&step=${TransactionStepsData.PaymentDetails.key}`)}
            disabled={row?.numberOfDocuments > 0}
          >
            {t(`${translationPath}move-to-payment-details`)}
          </ButtonBase>
        </div> :
        <DocListHolder
          documents={row?.documents || []}
          haveToResetInitDOCs={false}
          onDocumentsChange={(documents) => docChangeHandler(documents, row)}
          headerText={`${row.documentName} documents`}
          isWithoutCheckbox
          isDisabled={isReceiptDocs}
        />}
    </>
    );
  };

  const activeActionButtonHandler = () => {

    const uploadedDocs = filterUploadedDocuments();
    const isThereUploadedDocs = uploadedDocs?.length > 0;
    const isThereMissingDocs = docChecklist.findIndex(item => item.isMandatory && !item.isExist) !== -1 ? true : false;

    if (isThereMissingDocs || isThereUploadedDocs) changeActiveSubmitButton("Save");
    else changeActiveSubmitButton("Finish");
  }


  useEffect(() => {
    DTOChangeHandler();

    activeActionButtonHandler()
  }, [docChecklist]);

  useEffect(() => {
    if (unitId && !isSubmitted) getTransactionDocumentsCheckList();
  }, [unitId, isSubmitted]);

  return (
    <>
      <AccordionTable
        wrapperClasses="my-4"
        headerData={TransactionDocsHeaderData}
        data={docChecklist}
        getExpandedRowContent={getExpandedRowContent}
      />
    </>
  );
};
