
import React from 'react';
import { SwitchRoute } from '../../../Components/Route/SwitchRoute';
import {  DevelopersRoutes } from '../../../routes';

export const DevelopersLayout = () => (
  <>
    <SwitchRoute routes={DevelopersRoutes} />
  </>
);
