import React, { useState } from "react";
import { Radio } from "@material-ui/core";
import clsx from "clsx";

import useStyles from "./styles";

const CustomRadioButton = ({ checked, ...props }) => {
  const styles = useStyles();

  return (
    <Radio
      checked={checked}
      className={styles.radioRoot}
      disableRipple
      color="default"
      checkedIcon={
        <span className={clsx(styles.radioIcon, styles.radioCheckedIcon)} />
      }
      icon={<span className={styles.radioIcon} />}
      {...props}
    />
  );
};

export default CustomRadioButton;
