import React, { useState } from "react";
import { SwitchRoute } from "../../Components/Route/SwitchRoute";
import { NewHomeRoutes } from "../../routes/HomeRoutes/HomeRoutes";
import { VerticalNavProvider } from "../../Contexts/VerticalNavContext";
import { useNewLayoutStyles, useIsDesktop } from "../../Hooks";
import { MobileCalendarPage } from "../../Components/V2/MobileCalendarPage";
import { MobileNotificationsPage } from "../../Components/V2/MobileNotificationsPage";
import { MobileTabsWindow } from "../../Components/V2/MobileTabsWindow";
import {
  Header,
  MobileSideNav,
  SideNav,
  Footer,
  MobileNav,
  VerticalLayout,
  LayoutContent,
} from "../../Components";

// Styles
import "./_NewHomeLayout.scss";

const NewHomeLayout = () => {
  useNewLayoutStyles();

  const { isDesktop } = useIsDesktop();

  const [mobileTabValue, setMobileTabValue] = useState(-1);

  return (
    <VerticalNavProvider>
      <VerticalLayout
        sideNav={<SideNav />}
        header={<Header />}
        footer={<Footer />}
        mobileNav={
          <MobileNav
            mobileTabValue={mobileTabValue}
            setMobileTabValue={setMobileTabValue}
          />
        }
        mobileSideNav={<MobileSideNav />}
      >
        {!isDesktop && mobileTabValue === 1 && (
          <MobileTabsWindow>
            <MobileCalendarPage setMobileTabValue={setMobileTabValue} />
          </MobileTabsWindow>
        )}

        {!isDesktop && mobileTabValue === 2 && (
          <MobileTabsWindow>
            <MobileNotificationsPage setMobileTabValue={setMobileTabValue} />
          </MobileTabsWindow>
        )}

        <LayoutContent>
          <SwitchRoute
            routes={NewHomeRoutes?.filter((route) => !route.hasNewLayout)}
          />
        </LayoutContent>
        
      </VerticalLayout>
    </VerticalNavProvider>
  );
};

export { NewHomeLayout };
