
export const ClientsSegmentationFilterFields = 
{
    Contact :[
      {
         
            formFieldId : 1995 , 
            formFieldName: "updatedOn",
            formFieldKey: "updatedOn",
            searchableKey: "",
            displayPath: "updateOn",
            uiWidgetType: "alt-date",
            formFieldTitle: "Updated On",
            formsId: 1,
            isRequired: false,
            searchableKey: "updateOn",
            searchable: false ,
            isNumber: false
       
     } , 
     {
        formFieldId : 1996 , 
        formFieldName: "updatedBy",
        formFieldKey: "UpdatedByName",
        searchableKey :"UpdatedByName",
        displayPath: "UpdatedByName",
        uiWidgetType: "searchField",
        formFieldTitle: "Updated By",
        formsId: 1,
        isRequired: false,
        isSearchable: true
      } , 
    ] ,
    Lead :[
        {
            formFieldId: 1997,
            formFieldName: "leadClass",
            formFieldKey: "leadClass",
            searchableKey: "leadClass",
            displayPath: "leadClass",
            uiWidgetType: "select",
            formFieldTitle: "Lead Class",
            formsId: 6,
            isRequired: true,
            isSearchable: true,
        } ,
        { 
            formFieldId : 1998 , 
            formFieldName: "createdOn",
            formFieldKey: "createdOn",
            searchableKey: "",
            displayPath: "createdOn",
            uiWidgetType: "alt-date",
            formFieldTitle: "Created On",
            formsId: 6,
            isRequired: false,
            searchableKey: "createdOn",
            searchable: false ,
            isNumber: false

       } , 
       {
        formFieldId : 1999 , 
        formFieldName: "createdBy",
        formFieldKey: "CreatedByName",
        searchableKey : "CreatedByName",
        displayPath: "CreatedByName",
        uiWidgetType: "searchField",
        formFieldTitle: "Created By",
        formsId: 6 , 
        isRequired: false,
        isSearchable: true
      } , 
      {
            formFieldId : 2000 , 
            formFieldName: "updatedOn",
            formFieldKey: "updatedOn",
            searchableKey: "",
            displayPath: "updateOn",
            uiWidgetType: "alt-date",
            formFieldTitle: "Updated On",
            formsId: 6,
            isRequired: false,
            searchableKey: "updateOn",
            searchable: false ,
            isNumber: false

       
     } , 
     {
        formFieldId : 2001 , 
        formFieldName: "updatedBy",
        formFieldKey: "UpdatedByName",
        searchableKey :"UpdatedByName",
        displayPath: "UpdatedByName",
        uiWidgetType: "searchField",
        formFieldTitle: "Updated By",
        formsId: 6,
        isRequired: false,
        isSearchable: true
      } , 

    ] ,
    Activities :[
    { 
        formFieldId : 2002 , 
        formFieldName: "AssignDate",
        formFieldKey: "AssignDate",
        searchableKey: "",
        displayPath: "AssignDate",
        uiWidgetType: "alt-date",
        formFieldTitle: "Last Assign Date",
        formsId: 8,
        isRequired: false,
        searchableKey: "last_assign_date",
        searchable: false ,
        isNumber: false

   } ,  
   {
    formFieldId : 2004 , 
    formFieldName: "last_activity_type_name",
    formFieldKey: "last_activity_type_name",
    searchableKey :"last_activity_type_name",
    displayPath: "last_activity_type_name.name",
    uiWidgetType: "searchField",
    formFieldTitle: "Last Activity Type Name",
    formsId: 8,
    isRequired: false,
    isSearchable: true
  } ,

{
  formFieldId : 2006 , 
  formFieldName: "last_activity_createdBy",
  formFieldKey: "last_activity_createdBy",
  searchableKey :"last_activity_createdBy",
  displayPath: "last_activity_createdBy.name",
  uiWidgetType: "searchField",
  formFieldTitle: "Last Activity Created By",
  formsId: 8,
  isRequired: false,
  isSearchable: true
} 
]};
    


  