import React from "react";
import { useTranslation } from "react-i18next";
import { ShareMethodsDataEnum } from "../../../Enums";

export const PickMethodForm = ({
  activeCard,
  onActiveCardChange,
  parentTranslationPath,
  translationPath,
}) => {
  const { t } = useTranslation(parentTranslationPath);


  const getCardStatusClass = (cardInfo) => {
    let cardClass = "primary";

    if (cardInfo.id == activeCard?.id) cardClass = "active";
    return cardClass;
  };

  return (
    <div className="MethodBanner gap-16 py-3">
      {Object.values(ShareMethodsDataEnum)
        .filter(method => method.key !== "SMS")
        .map((item) => (
        <div
          className={`MethodBanner_card ${getCardStatusClass(item)}`}
          key={item.key}
          id={`MethodBanner_card-${item.id}`}
          onClick={() => onActiveCardChange(item)}
        >
          <span className={`mdi ${item.mdiIconClass || ''}`}></span>
          <span className="fw-simi-bold fz-16">
            {t(`${translationPath}${item.title}`)}
          </span>
        </div>
      ))}
    </div>
  );
};
