export const LeadsPoolCallCenterPermissions = {
  ViewLeadsPool: {
    permissionsId: '71c335ea-99c2-4d87-c4bd-08dc683b0c43',
    permissionsName: 'View Leads Pool',
    description: null,
    componentsId: null,
    components: null,
  },
  AssignToMe: {
    permissionsId: '052ba305-bd4d-4ff7-c4be-08dc683b0c43',
    permissionsName: 'Assign To Me',
    description: null,
    componentsId: null,
    components: null,
  },
  ReassignLeads: {
    permissionsId: '6d8f2dac-4338-41b7-c4bf-08dc683b0c43',
    permissionsName: 'Reassign Leads',
    description: null,
    componentsId: null,
    components: null,
  },
  SendToRotation: {
    permissionsId: '641d686f-2f54-4946-c4c0-08dc683b0c43',
    permissionsName: 'Send To Rotation',
    description: null,
    componentsId: null,
    components: null,
  },
  ViewLeadName: {
    permissionsId: '44a83c87-3ca7-4187-5ddb-08dc7403ebc2',
    permissionsName: 'View Lead Name',
    description: null,
    componentsId: null,
    components: null,
  },
  ViewRemarksColumn: {
    permissionsId: 'ea076707-4f5d-4080-5ddc-08dc7403ebc2',
    permissionsName: 'View Remarks Column',
    description: null,
    componentsId: null,
    components: null,
  },
  OpenLeadFile: {
    permissionsId: '755ef299-8f97-4fe1-b502-08dc9a985192',
    permissionsName: 'Open Lead File',
    description: null,
    componentsId: null,
    components: null,
  },
};
