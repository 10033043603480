/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useCallback, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import moment from "moment";
import i18next from "i18next";
import { ButtonBase, Tooltip } from "@material-ui/core";
import {
  CheckboxesComponent,
  LoadableImageComponant,
} from "../../../../Components";
import {
  ActionsEnum,
  ContactTypeEnum,
  LoadableImageEnum,
  UserOperationTypeEnum,
} from "../../../../Enums";
import { showinfo } from "../../../../Helper";
import { formatCommasForPriceUnit } from "../../../../Helper/formatCommas.Helper";
import { CopyToClipboardComponents } from "../../../../ReusableComponents/UtilityComponents/CopyToClipboardComponents/CopyToClipboardComponents";

const translationPath = "";

export const SharetUnitsCardsComponent = ({
  data,
  activeCard,
  isExpanded,
  onCardClicked,
  onFooterActionsClicked,
  notExpandedMax,
  withCheckbox,
  parentTranslationPath,
  checkedDetailedCards,
  onCardCheckboxClick,
  selectedDetailsUnitItem,
  cardCheckboxClicked,
  IsFavourite,
  setIsDeleteDialogOpen,
  setCheckedDetailed,
  setSingalid,
  from,
  filterCriteria
}) => {
  const [filterOrationType ,setFilterOrationType]=useState(null)
  const { t } = useTranslation(parentTranslationPath);
  const textArea = useRef(null);
  const getDefaultUnitImage = useCallback(
    (unitType) =>
      ContactTypeEnum[unitType] && ContactTypeEnum[unitType].defaultImg,
    []
  );
  const getIsSelectedCard = useCallback(
    (itemIndex) =>
      checkedDetailedCards.findIndex(
        (item) =>
          item.shareUnitId === itemIndex.shareUnitId || item === itemIndex
      ) !== -1,
    [checkedDetailedCards]
  );

  const colorPikerset = (item) => {
    if (item && item.toLowerCase() === "available") return "c-success-light";
    if (item && item.toLowerCase() === "leased") return "c-primary";
    if (item && item.toLowerCase() === "sale") return "c-primary";
    if (item && item.toLowerCase() === "reservedsale") return "c-primary";
    if (item && item.toLowerCase() === "reservedleased") return "c-primary";
    if (item && item.toLowerCase() === "draft") return "";

    return "";
  };
  const copyTextToClipboard = (id) => {
    const context = textArea.current;
    if (id && context) {
      context.value = id;
      context.select();
      document.execCommand("copy");
      showinfo(`${t("Shared:Copy-id-successfully")}  (${id})`);
    } else showinfo(`${t("Shared:Copy-id-successfully")}  (${id})`);
  };

 useEffect(() => {
  if (filterCriteria?.operation_type?.length > 0) {
    const operationTypeValue = filterCriteria.operation_type[0].value;

    if (operationTypeValue === 'Sale') {
      setFilterOrationType(UserOperationTypeEnum.Sale.id);
    } else if (operationTypeValue === 'Rent') {
      setFilterOrationType(UserOperationTypeEnum.Listing.id);
    }
  }else{
    setFilterOrationType(null)
  }
}, [filterCriteria,setFilterOrationType]); 
  return (
    <div className="units-cards-wrapper childs-wrapper">
      {data.result &&
        data.result.map((item, index) => (
          <div
            className="units-card-wrapper"
            // className={`${isExpanded ? ' is-expanded' : ''}${(((activeCard && activeCard.id === item.id) ||
            //   (selectedDetailsUnitItem && selectedDetailsUnitItem.id === item.id)) &&
            //   ' is-open') ||
            //   ''
            //   }`}
            key={`unitsCardItemRef${index + 1}`}
            id={item.id}
          >
            {withCheckbox && (
              <div className="card-checkbox-wrapper mt-4">
                <CheckboxesComponent
                  idRef={`unitsCheckboxItemRef${index + 1}`}
                  singleChecked={getIsSelectedCard(item)}
                  onSelectedCheckboxClicked={(event) => {
                    event.preventDefault();
                    onCardCheckboxClick(index, item);
                  }}
                />
              </div>
            )}

            <div className={`ribbon-wrapper my-2 `}>
              {item && item.isPublishUnitSale && (
                <Tooltip
                  title={
                    (item.lastSalePublishDate &&
                      moment(item.lastSalePublishDate).format(
                        "DD/MM/YYYY HH:mm A"
                      )) ||
                    `${t("Shared:not-available")}`
                  }
                >
                  <div className="mb-1 ribbon  green ">Published</div>
                </Tooltip>
              )}
              <div className={`ribbon-wrapper my-2 ${withCheckbox && "mt-6"}`}>
                <div className="mb-1 ribbon with-bulk ">
                  {(from === "myshareunit" && item && item.targetSystemName) ||
                    (item && item.sourceSystemName)}
                </div>
              </div>
            </div>
            {item && (
              <div className="container-wrapper">
                <a
                  onClick={onCardClicked && onCardClicked(item, index)}
                  className="cards-wrapper"
                >
                  <div className="cards-body-wrapper">
                    <div className="card-body-section">
                      <div className="body-item-wrapper">
                        <div
                          className={`flex-wrapper ${
                            !isExpanded ? "w-100" : ""
                          }`}
                        >
                          <div className="body-images-wrapper">
                            <div className="body-image-item-wrapper">
                              <div className="body-image-item">
                                <LoadableImageComponant
                                  classes="cover-image"
                                  type={LoadableImageEnum.div.key}
                                  alt={t(`${translationPath}unit-image`)}
                                  src={
                                    (item.imageUrl && item.imageUrl) ||
                                    ContactTypeEnum.corporate.defaultImg ||
                                    getDefaultUnitImage(item.type)
                                  }
                                />
                              </div>
                            </div>
                            <div className="body-image-item-wrapper">
                              <div className="body-title-wrapper">
                                <span className="body-title">
                                  {item?.unit_type || ""}{" "}
                                  {item?.property_name || " "}
                                </span>
                              </div>
                            </div>
                            {IsFavourite && (
                              <div
                                onClick={() => {
                                  setIsDeleteDialogOpen();
                                  setSingalid(item);
                                }}
                                className="is-Fav-heart lead mt-5 m-3"
                              >
                                <span className="mdi mdi-heart"></span>
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="w-100 d-flex-h-between">
                          <div className="price-wrapper">
                          { filterOrationType !== UserOperationTypeEnum.Sale.id &&
                             <div
                              className={`for-lable ${
                                (item.unitOperationSource === 1 && "Sale") ||
                                "Rent "
                              }`}
                            >
                              {t(`${translationPath}for`)}
                            </div>
                          }
                         
                                          
                         {
                          filterOrationType !== UserOperationTypeEnum.Sale.id && filterOrationType !== UserOperationTypeEnum.Listing.id &&(
                            <div
                            className={`   ${
                              (item.unitOperationSource === 1 && "Sale") || 
                              "Rent "
                            } `}
                          >
                            {`   ${
                              (item.unitOperationSource === 1 && "Sale") ||
                              "Rent "
                            } `}
                            :
                          </div>
                          )
                         }
                            {
                              filterCriteria?.operation_type?.length > 0 && filterCriteria?.operation_type[0]?.value === 'Sale' && filterOrationType === UserOperationTypeEnum.Sale.id && (
                             <>
                             <div
                             className={`for-lable ${
                               (item.unitOperationSource === 1 && item.unitOperationSource === 2 && "Sale") ||
                               "Sale "
                             }`}
                           >
                             {t(`${translationPath}for`)}
                           </div>
                             <div
                             className={`   ${
                               (item.unitOperationSource === 1 && item.unitOperationSource === 2 &&  "Sale") ||
                               "Sale "
                             } `}
                           >
                             {`   ${
                               (item.unitOperationSource === 1 && item.unitOperationSource === 2 && "Sale") ||
                               "Sale "
                             } `}
                             :
                           </div>
                             </>
                              )
                             }
                             {
                              filterCriteria?.operation_type?.length > 0 && filterCriteria?.operation_type[0]?.value === 'Rent' && filterOrationType === UserOperationTypeEnum.Listing.id &&(
                                <div
                                className={`   ${
                                  (item.unitOperationSource === 2 && item.unitOperationSource === 1 && "Rent") ||
                                  "Rent "
                                } `}
                              >
                                {`   ${
                                  (item.unitOperationSource === 2 &&  item.unitOperationSource === 1 && "Rent") ||
                                  "Rent "
                                } `}
                                :
                              </div>
                              )
                             }  
                            <div className="unit-price">
                              {item.selling_price &&
                              item.selling_price !== "N/A"
                                ? `${formatCommasForPriceUnit(
                                    item.selling_price
                                  )} AED`
                                : "N/A"}
                            </div>
                          </div>
                          <div className="contact-id-wrapper">
                            {t(`${translationPath}ref-#`)}:
                            <div className="contact-id">
                              {(item && item.unit_ref_no) || ""}
                              <textarea
                                readOnly
                                aria-disabled
                                value={item.unit_ref_no}
                                ref={textArea}
                              />
                            </div>
                            <Tooltip title={t(`${translationPath}copy`)}>
                              <span
                                onClick={(e) => {
                                  e.stopPropagation();
                                  e.preventDefault();
                                  copyTextToClipboard(item.unit_ref_no);
                                }}
                                className="mdi mdi-content-copy"
                              />
                            </Tooltip>
                          </div>
                        </div>

                        <div class="flat-contents-wrapper">
                          {item && item.bedroom && (
                            <div class="flat-content-item">
                              <span class="flat-content-icon mdi mdi-bed-outline mdi-18px"></span>
                              <span class="px-1">{item.bedroom || "Any"}</span>
                              <span></span>
                            </div>
                          )}

                          {item && item.bathroom && (
                            <div class="flat-content-item">
                              <span class="flat-content-icon mdi mdi-shower mdi-18px"></span>
                              <span class="px-1">{item.bathroom || "Any"}</span>
                              <span></span>
                            </div>
                          )}

                          {item && item.builtup_area_sqft && (
                            <div class="flat-content-item">
                              <span class="flat-content-icon mdi mdi-ruler-square mdi-18px"></span>
                              <span class="px-1">
                                {item.builtup_area_sqft || "Any"}
                              </span>
                              <span></span>
                            </div>
                          )}

                          <div class="flat-content-item">
                            <span class="flat-content-icon mdi mdi-broom mdi-18px"></span>
                            <span class="px-1">N/A</span>
                            <span></span>
                          </div>
                        </div>
                        <div className="id-date-wrapper pl-2 pr-2">
                          <div className="created-on">
                            {/* <span className="details-icon mdi mdi-calendar mdi-16px" /> */}
                            <span>
                              <span className="details-text">
                                {t(`${translationPath}unitId`)}
                              </span>
                              <span className="px-1">
                                {(item.unitId && item.unitId) || "N/A"}
                              </span>
                            </span>
                          </div>

                          {item.rating && (
                            <span
                              className={`card-tooltip card-badge ${item.rating}`}
                            >
                              {item.rating}
                            </span>
                          )}
                        </div>
                        {/* <div className='body-item d-flex-v-center mb-1'>
                                        <span className='card-tooltip bg-secondary-light c-primary'>
                                          <span>{t(`${translationPath}match-with`)}</span>
                                          <span className='px-1'>10</span>
                                          <span>{t(`${translationPath}buyers`)}</span>
                                        </span>
                                        <ButtonBase className='btns fw-simi-bold'>
                                          <span>{t(`${translationPath}view`)}</span>
                                          <span className='px-1 mdi mdi-chevron-right' />
                                        </ButtonBase>
                                      </div> */}
                      </div>
                    </div>
                    <div class="flex-section">
                      <div class="card-body-section">
                        <div class="details-item-wrapper">
                          <span class="details-item">
                            <span class="details-text">
                              {t(`${translationPath}property_name`)}:
                            </span>
                            <span class="px-1" title={item.property_name || ""}>
                              {item.property_name || "N/A"}
                            </span>
                          </span>
                        </div>
                        <div class="details-item-wrapper">
                          <span class="details-item">
                            <span class="details-text">
                              {t(`${translationPath}Furnished`)}:
                            </span>
                            <span class="px-1" title="No">
                              {item.furnished || "N/A"}
                            </span>
                          </span>
                        </div>
                        <div class="details-item-wrapper">
                          <span class="details-item">
                            <span class="details-text">
                              {t(`${translationPath}listing_agent`)}:
                            </span>
                            <span class="px-1" title="MOH  bayyat">
                              {item.listing_agent || "N/A"}
                            </span>
                          </span>
                        </div>
                        <div class="details-item-wrapper">
                          <span class="details-item">
                            <span class="details-text">
                              {t(`${translationPath}UnitType`)}:
                            </span>
                            <span class="px-1" title="MOH  bayyat">
                              {item.unit_type || "N/A"}
                            </span>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="flex-section">
                      {/* <div className={`card-body-section${isExpanded ? ' is-expanded' : ''}`}>
                        {item.details
                          .filter(
                            (filterItem, filterIndex) =>
                              (!isExpanded && filterIndex < notExpandedMax) || isExpanded
                          )
                          .map(
                            (subItem, subIndex) =>
                              subIndex < notExpandedMax && (
                                <div
                                  key={`unitsCardItemRef${subIndex + 1}`}
                                  className='details-item-wrapper'
                                >
                                 
                                  <span className='details-item'>
                                    <span className='details-text'>
                                      {t(`${translationPath}${subItem.title}`)}
                                      :
                                    </span>
                                    <span className='px-1' title={subItem.value}>
                                      {subItem.value}
                                    </span>
                                  </span>
                                </div>
                              )
                          )}
                        {
                          <div
                            key='unitsCardItemRef'
                            className='details-item-wrapper'
                          >
                            <span className='details-item'>
                              <span className='details-text'>
                                {t(`${translationPath}${'owner-name'}`)}
                                {' '}
                                :
                              </span>
                              <span className='px-1' title='rent_listing_agent'>
                                {(item && item.lead_owner && item.lead_owner.name) || 'N/A'}
                              </span>
                            </span>
                          </div> ||
                          ''
                        }
                      </div> */}
                    </div>
                  </div>

                  {/* <div className='cards-progress-wrapper'>
                    <ProgressComponet
                      value={item.progress}
                      progressText={`${item.progress}%`}
                      themeClasses='theme-gradient'
                    />
                  </div> */}

                  <div className="cards-footer-wrapper">
                    <ButtonBase
                      className={`btns theme-transparent mx-0  maxWidth_100`}
                      onClick={onFooterActionsClicked(
                        ActionsEnum.folder.key,
                        item,
                        index
                      )}
                      id="ActionsClickedfolder"
                    >
                      <span className="mdi mdi-folder-outline" />
                      <span className="px-1">{t("open-file")}</span>
                    </ButtonBase>

                    {/*                        
                            <ButtonBase
                              disabled={item.matchUnit === 0}
                              className={`btns theme-transparent mx-0  ${'w-50' ? 'maxWidth_50' : 'maxWidth_100'}`}
                              onClick={onFooterActionsClicked(ActionsEnum.delete.key, item, index)}
                            >
                              <span className={ActionsEnum.delete.icon} />
                              <span className='px-1'>{item.matchUnit}</span>
                              <span>{t(ActionsEnum.delete.label)}</span>
                            </ButtonBase> */}
                  </div>
                </a>
              </div>
            )}
          </div>
        ))}
    </div>
  );
};

SharetUnitsCardsComponent.propTypes = {
  data: PropTypes.shape({
    result: PropTypes.instanceOf(Array),
    totalCount: PropTypes.number,
  }).isRequired,
  activeCard: PropTypes.instanceOf(Object),
  selectedDetailsUnitItem: PropTypes.instanceOf(Object),
  isExpanded: PropTypes.bool.isRequired,
  withCheckbox: PropTypes.bool.isRequired,
  onCardClicked: PropTypes.func.isRequired,
  onFooterActionsClicked: PropTypes.func.isRequired,
  onCardCheckboxClick: PropTypes.func.isRequired,
  translationPath: PropTypes.string.isRequired,
  notExpandedMax: PropTypes.number,
  checkedDetailedCards: PropTypes.arrayOf(PropTypes.number).isRequired,
};
SharetUnitsCardsComponent.defaultProps = {
  notExpandedMax: 4,
  selectedDetailsUnitItem: undefined,
  IsFavourite: false,
  activeCard: undefined,
};
