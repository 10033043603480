import React, { useState, useCallback, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import Rating from "@material-ui/lab/Rating";
import { Spinner, Inputs } from "../../../../../Components";
import { GetGeographicCriteriaWithScores } from "../../../../../Services";
import { ReactComponent as Minimize } from "../../../../../assets/images/defaults/minimize.svg";

export const CommunityGeographicCriteria = ({
  parentTranslationPath,
  translationPath,
  communityGeographicCriteriaWithScores,
  setCommunityGeographicCriteriaWithScores,
  subCommunityGeographicCriteriaWithScores,
  setSubCommunityGeographicCriteriaWithScores,
  setActiveCommunity,
  activeDistrict,
  setActiveSubCommunity,
  propertyRatingLocationTypeScoreId,
  activeCommunity,
  displayAllReletedLocation,
  setDisplayAllReletedLocation,
}) => {
  const { t } = useTranslation(parentTranslationPath);
  const searchTimer = useRef(null);

  const [isLoading, setIsLoading] = useState(false);
  const [displayCommunitySelected, setDisplayCommunitySelected] = useState([]);
  const [filterCommunitiesByName, setFilterCommunitiesByName] = useState([]);
  const [searchValue, setSearchValue] = useState("");

  const GetGeographicCriteriaWithScoresAPI = useCallback(async (search) => {
    setIsLoading(true);
    const res = await GetGeographicCriteriaWithScores({
      parentLookupItemId: search,
    });
    if (!((res && res.data && res.data.ErrorId) || !res)) {
      const subCommunities =
        (res &&
          res.locationTypeScores &&
          res.locationTypeScores.length > 0 &&
          res.locationTypeScores[0] &&
          res.locationTypeScores[0].geographicCriteriaScores &&
          res.locationTypeScores[0].geographicCriteriaScores.map((el) => ({
            ...el,
            propertyRatingLocationTypeScoreId:
              propertyRatingLocationTypeScoreId,
          }))) ||
        [];

      let newList = [
        ...subCommunityGeographicCriteriaWithScores,
        ...subCommunities,
      ];
      let filterArray = newList.filter(
        (value, index, self) =>
          index === self.findIndex((t) => t.lookupsId === value.lookupsId)
      );
      setSubCommunityGeographicCriteriaWithScores([...filterArray]);
    } else {
      setSubCommunityGeographicCriteriaWithScores([]);
    }
    setIsLoading(false);
  });

  useEffect(() => {
    if (activeCommunity && activeCommunity.lookupsId) {
      setActiveSubCommunity(null);
      GetGeographicCriteriaWithScoresAPI(activeCommunity.lookupsId);
    }
  }, [activeCommunity]);

  useEffect(() => {
    if (
      displayAllReletedLocation &&
      displayAllReletedLocation.displayAllCommunitySubCommunities
    ) {
      const selectdCommunityList = communityGeographicCriteriaWithScores.filter(
        (item) =>
          item.lookupsId ===
          displayAllReletedLocation.displayAllCommunitySubCommunities.id
      );
      setDisplayCommunitySelected([...selectdCommunityList]);
    } else setDisplayCommunitySelected([]);
  }, [displayAllReletedLocation, communityGeographicCriteriaWithScores]);

  useEffect(() => {
    if (searchValue !== "") {
      const newList =
        communityGeographicCriteriaWithScores &&
        communityGeographicCriteriaWithScores.filter((a) =>
          a.lookupItemName
            .toLocaleLowerCase()
            .includes(searchValue && searchValue.toLocaleLowerCase())
        );
        const newList2 =
        displayCommunitySelected &&
        displayCommunitySelected.filter((a) =>
          a.lookupItemName
            .toLocaleLowerCase()
            .includes(searchValue && searchValue.toLocaleLowerCase())
        );

        setDisplayCommunitySelected([...newList2]);
      setFilterCommunitiesByName(newList);
    } else if (searchValue === "")
      {
        setFilterCommunitiesByName([]);
        setDisplayCommunitySelected([]);
                      if (setDisplayAllReletedLocation)
                        setDisplayAllReletedLocation((e) => ({
                          ...e,
                          displayAllCommunitySubCommunities: null,
                        }));

      }
  }, [searchValue]);

  return (
    <>
      {activeDistrict && (
        <>
          <Spinner isActive={isLoading} isAbsolute />
          <div>
            <span className="fw-bold">
             {t(`${translationPath}Community`)}{" "}
            </span>
          </div>
          <div className="w-100 mt-2 mb-2 sores-part">
            <div className="b-bottom w-100">
              <div className="search-filter">
                <div className="">{""}</div>
                <div className="">
                  <Inputs
                    idRef="communityOptionsFilterInputRef"
                    wrapperClasses="mt-2"
                    inputPlaceholder={`${t("search-for-community")}`}
                    onInputBlur={(event) => {
                      event.stopPropagation();
                    }}
                    onKeyUp={(event) => {
                      if (searchTimer.current)
                        clearTimeout(searchTimer.current);
                      const newValue = event.target.value;
                      searchTimer.current = setTimeout(() => {
                        setSearchValue(newValue);
                      }, 300);
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="w-100 mt-2 mb-2 community">
            {searchValue === "" &&
              displayAllReletedLocation &&
              !displayAllReletedLocation.displayAllCommunitySubCommunities &&
              communityGeographicCriteriaWithScores &&
              communityGeographicCriteriaWithScores
                .filter(
                  (d) =>
                    d.parentLookupItemId ===
                    (activeDistrict && activeDistrict.lookupsId)
                )
                .map((item, index) => (
                  <div
                    className="b-bottom section"
                    key={`CommunityCardItemKey${item.lookupsId}`}
                    onClick={() => setActiveCommunity(item)}
                  >
                   
                      <div className="address">
                        <span className="fw-bold">{item.lookupItemName}</span>
                      </div>
                      <div className="address">
                        <Rating
                          size="small"
                          name={`CommunityCardItemId${item.lookupsId}`}
                          max={10}
                          value={item.score}
                          precision={1}
                          onChange={(event, newValue) => {
                            GetGeographicCriteriaWithScoresAPI(item.lookupsId);
                            let updatelist = [
                              ...communityGeographicCriteriaWithScores,
                            ];
                            const itemIndex = updatelist.indexOf(
                              updatelist.find(
                                (f) => f.lookupsId === item.lookupsId
                              )
                            );
                            if (itemIndex !== -1)
                              updatelist[itemIndex] = {
                                ...updatelist[itemIndex],
                                score: newValue || 0,
                              };
                            setCommunityGeographicCriteriaWithScores([
                              ...updatelist,
                            ]);
                          }}
                        />
                        <span
                          className="px-2"
                          onClick={() => {
                            setDisplayAllReletedLocation((e) => ({
                              ...e,
                              displayAllCommunitySubCommunities: {
                                id: item.lookupsId,
                                isSelected: true,
                              },
                            }));
                          }}
                        >
                          <Minimize />
                        </span>
                      </div>
                    </div>
                ))}
            {searchValue === "" &&
              displayAllReletedLocation &&
              displayAllReletedLocation.displayAllCommunitySubCommunities &&
              displayAllReletedLocation.displayAllCommunitySubCommunities
                .isSelected &&
              displayCommunitySelected.map((item, index) => (
                <div
                  className="b-bottom section"
                  key={`CommunityCardItemKey${item.lookupsId + 1}`}
                  onClick={() => setActiveCommunity(item)}
                >
                  
                    <div className="address">
                      <span className="fw-bold">{item.lookupItemName}</span>
                    </div>
                    <div className="address">
                      <Rating
                        size="small"
                        name={`CommunityCardItemId${item.lookupTypeName}-${item.lookupsId +1}}`}
                        max={10}
                        value={item.score}
                        precision={1}
                        onChange={(event, newValue) => {
                          GetGeographicCriteriaWithScoresAPI(item.lookupsId);
                          let updatelist = [
                            ...communityGeographicCriteriaWithScores,
                          ];
                          const itemIndex = updatelist.indexOf(
                            updatelist.find(
                              (f) => f.lookupsId === item.lookupsId
                            )
                          );
                          if (itemIndex !== -1)
                            updatelist[itemIndex] = {
                              ...updatelist[itemIndex],
                              score: newValue || 0,
                            };
                          setCommunityGeographicCriteriaWithScores([
                            ...updatelist,
                          ]);
                        }}
                      />
                      <span
                        className="px-2"
                        onClick={() => {
                          setDisplayAllReletedLocation((e) => ({
                            ...e,
                            displayAllCommunitySubCommunities: null,
                          }));
                        }}
                      >
                        <Minimize />
                      </span>
                    </div>
                </div>
              ))}
            {searchValue &&
              searchValue !== "" &&
              displayAllReletedLocation &&
              !displayAllReletedLocation.displayAllCommunitySubCommunities &&
              filterCommunitiesByName &&
              filterCommunitiesByName.map((item, index) => (
                <div
                  className="b-bottom section"
                  key={`filterCommunityNameCardItemKey${item.lookupsId}`}
                  onClick={() => setActiveCommunity(item)}
                >
                 
                    <div className="address">
                      <span className="fw-bold">{item.lookupItemName}</span>
                    </div>
                    <div className="address">
                      <Rating
                        size="small"
                        name={`filterCommunityNameCardItemId${item.lookupTypeName}-${item.lookupsId}}`}
                        max={10}
                        value={item.score}
                        precision={1}
                        onChange={(event, newValue) => {
                          GetGeographicCriteriaWithScoresAPI(item.lookupsId);
                          let updatelist = [
                            ...communityGeographicCriteriaWithScores,
                          ];
                          const itemIndex = updatelist.indexOf(
                            updatelist.find(
                              (f) => f.lookupsId === item.lookupsId
                            )
                          );
                          if (itemIndex !== -1)
                            updatelist[itemIndex] = {
                              ...updatelist[itemIndex],
                              score: newValue || 0,
                            };
                          let updatelist2 = [...filterCommunitiesByName];
                          const itemIndex2 = updatelist2.indexOf(
                            updatelist2.find(
                              (f) => f.lookupsId === item.lookupsId
                            )
                          );
                          if (itemIndex2 !== -1)
                            updatelist2[itemIndex2] = {
                              ...updatelist2[itemIndex2],
                              score: newValue || 0,
                            };

                          setFilterCommunitiesByName([...updatelist2]);
                          setCommunityGeographicCriteriaWithScores([
                            ...updatelist,
                          ]);
                        }}
                      />
                      <span
                          className="px-2"
                          onClick={() => {
                            setDisplayAllReletedLocation((e) => ({
                              ...e,
                              displayAllCommunitySubCommunities: {
                                id: item.lookupsId,
                                isSelected: true,
                              },
                            }));
                          }}
                        >
                          <Minimize />
                        </span>
                    </div>
                  </div>
              ))}
            { searchValue &&
              searchValue !== "" &&
              displayAllReletedLocation &&
              displayAllReletedLocation.displayAllCommunitySubCommunities && displayAllReletedLocation.displayAllCommunitySubCommunities.isSelected && 
              displayCommunitySelected &&
              displayCommunitySelected.map((item, index) => (
                <div
                  className="b-bottom section"
                  key={`filterCommunityNameCardItemKey${item.lookupsId + 1}`}
                  onClick={() => setActiveCommunity(item)}
                >
                 
                    <div className="address">
                      <span className="fw-bold">{item.lookupItemName}</span>
                    </div>
                    <div className="address">
                      <Rating
                        size="small"
                        name={`filterCommunityNameCardItemId${item.lookupTypeName}-${item.lookupsId+1}}`}
                        max={10}
                        value={item.score}
                        precision={1}
                        onChange={(event, newValue) => {
                          GetGeographicCriteriaWithScoresAPI(item.lookupsId);
                          let updatelist = [
                            ...communityGeographicCriteriaWithScores,
                          ];
                          const itemIndex = updatelist.indexOf(
                            updatelist.find(
                              (f) => f.lookupsId === item.lookupsId
                            )
                          );
                          if (itemIndex !== -1)
                            updatelist[itemIndex] = {
                              ...updatelist[itemIndex],
                              score: newValue || 0,
                            };
                          let updatelist2 = [...filterCommunitiesByName];
                          const itemIndex2 = updatelist2.indexOf(
                            updatelist2.find(
                              (f) => f.lookupsId === item.lookupsId
                            )
                          );
                          if (itemIndex2 !== -1)
                            updatelist2[itemIndex2] = {
                              ...updatelist2[itemIndex2],
                              score: newValue || 0,
                            };

                          setFilterCommunitiesByName([...updatelist2]);
                          setCommunityGeographicCriteriaWithScores([
                            ...updatelist,
                          ]);
                        }}
                      />
                      <span
                      className="px-2"
                      onClick={() => {
                      const displayAllCommunitySubCommunities =
                        displayAllReletedLocation &&
                        displayAllReletedLocation.displayAllCommunitySubCommunities
                          ? null
                          : { id: item.lookupsId, isSelected: true };
                      if (setDisplayAllReletedLocation)
                        setDisplayAllReletedLocation((e) => ({
                          ...e,
                          displayAllCommunitySubCommunities: displayAllCommunitySubCommunities,
                        }));
                    }}
                  >
                    <Minimize />
                  </span>
                    </div>
                  </div>
              ))}
              </div>
          </div>
        </>
      )}
    </>
  );
};
