import React, { useState, useCallback, useReducer, useEffect, useRef } from 'react';
import Joi from 'joi';
import { DialogActions, DialogContent, DialogTitle, Dialog, ButtonBase } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { showError, showSuccess } from '../../../Helper';
import { Spinner, AutocompleteComponent } from '../../../Components';
import { CreateOrUpdateCampaignConfig, GetAllCampaignForms, GetAllCampaignDepartments } from '../../../Services';
import { ApplicationUserSearch } from '../../../Services/userServices';

export const ConfigManagementDialog = ({
  parentTranslationPath,
  translationPath,
  activeItem,
  isDialogOpen,
  onClose,
  onSave,
}) => {
  const { t } = useTranslation(parentTranslationPath);
  const searchTimer = useRef(null);

  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const reducer = useCallback((state, action) => {
    if (action.id !== 'edit') return { ...state, [action.id]: action.value };
    return {
      ...action.value,
    };
  }, []);
  const [state, setState] = useReducer(reducer, {
    id: null,
    departmentId: null,
    formId: null,
    departmentApprovalUserId: null,
    marketingApprovalUserId: null,
  });
  const [selected, setSelected] = useReducer(reducer, {
    department: null,
    form: null,
    departmentApprovalUser: null,
    marketingApprovalUser: null,
  });
  const [isLoading, setIsLoading] = useReducer(reducer, {
    departments: false,
    forms: false,
    users: false,
  });
  const [data, setData] = useReducer(reducer, {
    departments: [],
    forms: [],
    departmentUsers: [],
    marketingUsers: [],
  });

  const schema = Joi.object({
    departmentId: Joi.number()
      .required()
      .messages({
        'number.base': t(`${translationPath}department-is-required`),
        'state.required': t(`${translationPath}department-is-required`),
      }),
    formId: Joi.number()
      .required()
      .messages({
        'number.base': t(`${translationPath}form-is-required`),
        'state.required': t(`${translationPath}form-is-required`),
      }),
    departmentApprovalUserId: Joi.number()
      .required()
      .custom((value, helpers) => {
        if (value === state.marketingApprovalUserId) return helpers.error('state.same-user');
        return value;
      })
      .messages({
        'number.base': t(`${translationPath}departmentApprovalUser-is-required`),
        'state.required': t(`${translationPath}departmentApprovalUser-is-required`),
        'state.same-user': t(`${translationPath}marketing-and-department-manager-same-user`),
      }),
    marketingApprovalUserId: Joi.number()
      .required()
      .messages({
        'number.base': t(`${translationPath}marketingApprovalUser-is-required`),
        'state.required': t(`${translationPath}marketingApprovalUser-is-required`),
      }),
  })
    .options({
      abortEarly: false,
      allowUnknown: true,
    })
    .validate(state);

  const getAllUsers = async (searchValue, userType) => {
    setIsLoading({ id: 'users', value: true });
    const res = await ApplicationUserSearch({
      pageIndex: 0,
      pageSize: 25,
      name: searchValue || '',
    });
    if (!(res && res.status && res.status !== 200)) {
      const localValue = (res && res.result) || [];
      if (localValue.length > 0) {
        if (userType === "department") setData({ id: 'departmentUsers', value: localValue });
        else if (userType === "marketing") setData({ id: 'marketingUsers', value: localValue });
        else {
          setData({ id: 'departmentUsers', value: localValue });
          setData({ id: 'marketingUsers', value: localValue });
        }
      } else {
        setData({ id: 'departmentUsers', value: [] });
        setData({ id: 'marketingUsers', value: [] });
      }

      setIsLoading({ id: 'users', value: false });
    }
  }

  const getAllCampaignForms = async (searchValue) => {
    setIsLoading({ id: 'forms', value: true });
    const res = await GetAllCampaignForms({
      search: searchValue || '',
    });
    if (!(res && res.status && res.status !== 200)) {
      setData({ id: 'forms', value: res || [], });
    } else setData({ id: 'forms', value: [], });

    setIsLoading({ id: 'forms', value: false });
  };

  const getAllCampaignDepartments = async (searchValue) => {
    setIsLoading({ id: 'departments', value: true });
    const res = await GetAllCampaignDepartments({
      search: searchValue || '',
    });
    if (!(res && res.status && res.status !== 200)) {
      setData({ id: 'departments', value: res || [], });
    } else setData({ id: 'departments', value: [], });

    setIsLoading({ id: 'departments', value: false });
  };

  const autoSelectFomHandler = (selectedDepartment) =>{
    const firstFormItem = data.forms?.length && data.forms[0];
    setState({
      id: 'formId',
      value: selectedDepartment? firstFormItem?.campaignFormId : null,
    });
    setSelected({
      id: 'form',
      value: selectedDepartment? firstFormItem : null,
    });
  }

  const saveHandler = async (event) => {
    event.preventDefault();
    if (schema?.error?.message) {
      showError(schema.error.message);
      return;
    }
    setIsSubmitLoading(true);
    const body = {
      id: activeItem ? activeItem.campaignConfigId : null,
      ...state
    };
    const res = await CreateOrUpdateCampaignConfig(body);
    if (!(res && res.status && res.status !== 200)) {
      showSuccess(
        t(
          `${translationPath}${(activeItem && `campaign-config-updated-successfully`) || `campaign-config-created-successfully`
        }`
        )
        );
        if (onSave) onSave();
      } else {
        showError(
          t(
            `${translationPath}${(activeItem && `campaign-config-updated-failed`) || `campaign-config-created-failed`
          }`
          )
          );
        }
        setIsSubmitLoading(false);
      };

  useEffect(() => {
    getAllUsers();
    getAllCampaignDepartments();
    getAllCampaignForms();
  }, []);

  useEffect(() => {
    if (activeItem) {

      const department = activeItem.departmentId ? {
        departmentId: activeItem.departmentId,
        departmentName: activeItem.departmentName,
      } : null;

      const form = activeItem.campaignFormId ? {
        campaignFormId: activeItem.campaignFormId,
        campaignFormName: activeItem.campaignFormName,
      } : null;

      const departmentApprovalUser = activeItem.departmentApprovalUserId ? {
        applicationUserId: activeItem.departmentApprovalUserId,
        fullName: activeItem.departmentApprovalUser,
      } : null;

      const marketingApprovalUser = activeItem.marketingApprovalUserId ? {
        applicationUserId: activeItem.marketingApprovalUserId,
        fullName: activeItem.marketingApprovalUser,
      } : null;

      setSelected({
        id: 'edit',
        value: {
          department,
          form,
          marketingApprovalUser,
          departmentApprovalUser,
        },
      });
      setState({
        id: 'edit',
        value: {
          id: activeItem.campaignConfigId,
          departmentId: activeItem.departmentId,
          formId: activeItem.campaignFormId,
          departmentApprovalUserId: activeItem.departmentApprovalUserId,
          marketingApprovalUserId: activeItem.marketingApprovalUserId,
        },
      });
    }
  }, [activeItem]);

  return (
    <div>
      <Dialog
        open={isDialogOpen}
        onClose={onClose}
        className='activities-management-dialog-wrapper'>
        <Spinner isActive={isSubmitLoading} isAbsolute />
        <form noValidate onSubmit={saveHandler}>
          <DialogTitle id='alert-dialog-slide-title'>
            {t(
              `${translationPath}${(activeItem && 'edit-configuration') || 'add-new-configuration'}`
            )}
          </DialogTitle>
          <DialogContent>
            <>
              <div className='d-flex'>
                <div className='w-50 mx-2'>
                  <div className='my-3'>
                    <AutocompleteComponent
                      idRef='departmentRef'
                      labelValue={t(`${translationPath}department`)}
                      selectedValues={selected.department}
                      isDisabled={isLoading.departments || activeItem}
                      data={data.departments || []}
                      displayLabel={(option) => option.name || option.departmentName ||  ''}
                      multiple={false}
                      withoutSearchButton
                      onChange={(event, newValue) => {
                        setState({
                          id: 'departmentId',
                          value: newValue && newValue.departmentId,
                        });
                        setSelected({
                          id: 'department',
                          value: newValue,
                        });
                        autoSelectFomHandler(newValue);

                      }}
                      isLoading={isLoading.departments}
                    />
                  </div>

                  <div className='my-3'>
                    <AutocompleteComponent
                      idRef='departmentApprovalUserRef'
                      labelValue={t(`${translationPath}department-approved-by`)}
                      selectedValues={selected.departmentApprovalUser}
                      isDisabled={isLoading.users}
                      data={data.departmentUsers || []}
                      displayLabel={(option) => option.fullName || ''}
                      multiple={false}
                      withoutSearchButton
                      onChange={(event, newValue) => {
                        setState({
                          id: 'departmentApprovalUserId',
                          value: newValue && newValue.applicationUserId,
                        });
                        setSelected({
                          id: 'departmentApprovalUser',
                          value: newValue,
                        });
                      }}
                      onInputKeyUp={(e) => {
                        const { value } = e.target;
                        if (searchTimer.current) clearTimeout(searchTimer.current);
                        const userType = "department";
                        searchTimer.current = setTimeout(() => {
                          getAllUsers(value, userType);
                        }, 1200);
                      }}
                      onKeyDown={()=>{
                        setState({
                          id: 'departmentApprovalUserId',
                          value: null,
                        });
                        setSelected({
                          id: 'departmentApprovalUser',
                          value: null,
                        });
                      }}
                      isLoading={isLoading.users}
                    />
                  </div>
                </div>

                <div className='w-50 mx-2'>
                  <div className='my-3'>
                    <AutocompleteComponent
                      idRef='formRef'
                      labelValue={t(`${translationPath}form`)}
                      selectedValues={selected.form}
                      isDisabled={isLoading.forms || activeItem}
                      data={data.forms || []}
                      displayLabel={(option) => option.name || option.campaignFormName || ''}
                      multiple={false}
                      withoutSearchButton
                      onChange={(event, newValue) => {
                        setState({
                          id: 'formId',
                          value: newValue && newValue.campaignFormId,
                        });
                        setSelected({
                          id: 'form',
                          value: newValue,
                        });
                      }}
                      isLoading={isLoading.forms}
                    />
                  </div>
                  <div className='my-3'>
                    <AutocompleteComponent
                      idRef='marketingApprovalUserRef'
                      labelValue={t(`${translationPath}marketing-approved-by`)}
                      selectedValues={selected.marketingApprovalUser}
                      isDisabled={isLoading.users}
                      data={data.marketingUsers || []}
                      displayLabel={(option) => option.fullName || ''}
                      multiple={false}
                      withoutSearchButton
                      onChange={(event, newValue) => {
                        setState({
                          id: 'marketingApprovalUserId',
                          value: newValue && newValue.applicationUserId,
                        });
                        setSelected({
                          id: 'marketingApprovalUser',
                          value: newValue,
                        });
                      }}
                      onInputKeyUp={(e) => {
                        const { value } = e.target;
                        if (searchTimer.current) clearTimeout(searchTimer.current);
                        const userType = "marketing";
                        searchTimer.current = setTimeout(() => {
                          getAllUsers(value, userType);
                        }, 1200);
                      }}
                      onKeyDown={()=>{
                        setState({
                          id: 'marketingApprovalUserId',
                          value: null,
                        });
                        setSelected({
                          id: 'marketingApprovalUser',
                          value: null,
                        });
                      }}
                      isLoading={isLoading.users}
                    />
                  </div>
                </div>
              </div>
            </>
          </DialogContent>
          <DialogActions>
            <ButtonBase onClick={onClose} className='btns theme-solid bg-cancel'>
              {t(`${translationPath}cancel`)}
            </ButtonBase>
            <ButtonBase className='btns theme-solid' disabled={isSubmitLoading} onClick={saveHandler} type='submit'>
              {t(`${translationPath}save`)}
            </ButtonBase>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
};
