
import React from 'react';
import { SwitchRoute } from '../../../Components/Route/SwitchRoute';
import { InquiryRotationRoutes } from '../../../routes';

export const InquiryRotationLayout = () => (
  <>
    <SwitchRoute routes={InquiryRotationRoutes} />
  </>
);
