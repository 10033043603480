export const InquireEnum = [
  {
    key: 1,
    value: 'request-for-viewing',
    activityTypeId: 99,
  },
  {
    key: 2,
    value: 'request-for-price-negotiation',
    activityTypeId: 98,
  },
  {
    key: 3,
    value: 'unit-proposal-request',
    activityTypeId: 107,
  },
];
