import React, { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { ButtonBase } from '@material-ui/core';
import { DatePickerComponent, DialogComponent, Spinner } from '../../../../../../Components';
import moment from 'moment';
import { CreateUserLeave, UpdateUserLeave } from '../../../../../../Services'
import { GetParams, showError, showSuccess, getErrorByName } from '../../../../../../Helper';
import { useEffect } from 'react';
import Joi from 'joi';

export const AddLeavesDialog = ({
    setIsOpenAddLeaves,
    isOpenAddLeaves,
    parentTranslationPath,
    translationPath,
    activeItem,
    getAllUserLeaves,
    setActiveItem
}) => {
    const { t } = useTranslation(parentTranslationPath);
    const [leaves, setLeaves] = useState({
        leaveFrom: activeItem?.leaveFrom || null,
        leaveTo: activeItem?.leaveTo || null
    });
    const [isLoading, setIsLoading] = useState(false);

    const schema = Joi.object({
        leaveFrom: Joi.date()
            .required()
            .messages({
                'date.base': t(`${translationPath}leave-from-is-required`),
                'date.empty': t(`${translationPath}leave-from-is-required`),
            }),
        leaveTo:
            Joi.date()
                .iso().min(Joi.ref('leaveFrom'))
                .required()
                .messages({
                    'date.base': t(`${translationPath}leave-to-is-required`),
                    'date.empty': t(`${translationPath}leave-to-is-required`),
                    'date.min': t(`${translationPath}can't-select-date-in-the-past`),
                })
    })
        .options({
            abortEarly: false,
            allowUnknown: true,
        })
        .validate(leaves);

    const saveLeaves = useCallback(async () => {
        if (schema.error) {
            showError(t(`${translationPath}please-fix-all-errors`));
            return;
        }
        setIsLoading(true);
        let addBody = {
            userId: GetParams('id'),
            leaveFrom: leaves.leaveFrom,
            leaveTo: leaves.leaveTo,
        }
        let editBody = {
            userLeavesId: activeItem && activeItem.userLeavesId,
            leaveFrom: leaves.leaveFrom,
            leaveTo: leaves.leaveTo,
        }
        const res = (activeItem && await UpdateUserLeave(editBody)) || await CreateUserLeave(addBody);
        if (!(res && res.status && res.status !== 200)) {
            if (activeItem) {
                showSuccess(t(`${translationPath}leave-edit-successfully`))
            } else {
                showSuccess(t(`${translationPath}leave-saved-successfully`));
            }
        }
        else {
            if (activeItem) {
                showError(t(`${translationPath}leave-edit-failed`));
            } else {
                showError(t(`${translationPath}leave-save-failed`))
            }
        }
        setActiveItem(null);
        getAllUserLeaves();
        setIsLoading(false);
        setIsOpenAddLeaves(false);
    }, [leaves]);

    return (
        <>
            <Spinner isActive={isLoading} />
            <DialogComponent
                isOpen={isOpenAddLeaves}
                titleText={activeItem && 'edit-leave' || 'add-new-leave'}
                maxWidth='sm'
                // saveText='save'
                disableBackdropClick
                dialogContent={(
                    <>
                        <DatePickerComponent
                            idRef='leaveFromRef'
                            labelValue={t(`${translationPath}leave-from`)}
                            placeholder='DD/MM/YYYY'
                            format='YYYY/MM/DD'
                            parentTranslationPath={parentTranslationPath}
                            value={leaves.leaveFrom}
                            maxDate={leaves.leaveTo || ''}
                            onDateChanged={(newValue) => {
                                setLeaves((values) => ({ ...values, leaveFrom: newValue && moment(newValue).format('YYYY-MM-DDTHH:mm:ss') || null }))
                            }}
                            helperText={getErrorByName(schema, 'leaveFrom').message}
                        />
                        <DatePickerComponent
                            idRef='leavetoRef'
                            labelValue={t(`${translationPath}leave-to`)}
                            placeholder='DD/MM/YYYY'
                            format='YYYY/MM/DD'
                            labelClasses='mt-3'
                            parentTranslationPath={parentTranslationPath}
                            value={leaves.leaveTo}
                            minDate={leaves.leaveFrom || ''}
                            onDateChanged={(newValue) => {
                                setLeaves((values) => ({ ...values, leaveTo: newValue && moment(newValue).format('YYYY-MM-DDTHH:mm:ss') || null }))
                            }}
                            helperText={getErrorByName(schema, 'leaveTo').message}
                        />
                        <div className='actions-btns'>
                            <ButtonBase id='save-ref-action' onClick={saveLeaves} className='btns theme-solid'>
                                {t(`${translationPath}save`)}
                            </ButtonBase>
                            <ButtonBase id='cancel-ref-action' onClick={() => {
                                setIsOpenAddLeaves(false);
                            }} className='btns cancel-btn-wrapper'>
                                {t(`${translationPath}cancel`)}
                            </ButtonBase>
                        </div>

                    </>
                )}
                parentTranslationPath={parentTranslationPath}
                translationPath={translationPath}
            />
        </>
    )
}
AddLeavesDialog.propTypes = {
    parentTranslationPath: PropTypes.string.isRequired,
    translationPath: PropTypes.string.isRequired,
}
