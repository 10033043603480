import React, { useState, useEffect, useCallback, useReducer } from "react";
import { ButtonBase } from '@material-ui/core';

import { useTranslation } from "react-i18next";
import "../../../TaskConfiguration.scss";
import { TableActions } from "../../../../../../Enums";
import { Tables  , Spinner} from "../../../../../../Components";
import { GetProjectTasks } from "../../../../../../Services";
import { GetParams , returnPropsByPermissions  } from "../../../../../../Helper";
import { ContactsPermissions , PropertiesPermissionsCRM , UnitsSalesPermissions ,
         UnitsLeasePermissions , LeadOwnerUnitLeasePermissions , LeadOwnerUnitSalePermissions ,
         LeadsSalesPermissions , LeadsLeasePermissions , LeadsCAllCenterPermissions ,
         LeadOwnerLeadsPermissions , LeadsMortgagesPermissions 
        } from '../../../../../../Permissions' ; 
import { DeleteConfigDialog, TaskDetailsDialog } from "../../../Dialogs";
import { useTitle } from '../../../../../../Hooks';
import { AddTaskDialog } from "../../../Dialogs";

export const ProjectTasksView = ({
    taskRelatedTo , 
    formType , 
}) => {
  const parentTranslationPath = "TaskConfiguration";
  const translationPath = "";
  const relatedToId =  +GetParams('id');
  const [displayDependingOnPermissions ,  setDisplayDependingOnPermissions ]= useState(null);
 
  const [isLoading , setIsLoading] = useState(false) ; 

  const { t } = useTranslation(parentTranslationPath);

  useTitle(t("Tasks"));

  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [filter, setFilter] = useState({
    pageIndex: 1,
    pageSize: 150,
  });
  const [isTaskDetailsDialogOpen, setIsTaskDetailsDialogOpen] = useState(false);
  const [actionsList, setActionsList] = useState([
    {
      enum: TableActions.viewDetails.key,
    },
  ]);
  const [activeItem, setActiveItem] = useState(null);
  const [tasks, setTasks] = useState([]);
  const [isAddTaskDialogOpen, setIsAddTaskDialogOpen] = useState(false);

  const getRelatedToIds = () => {
    return ({
      relatedToLeadId: taskRelatedTo === "Lead"? relatedToId : null,
      relatedToUnitId: taskRelatedTo === "Unit"? relatedToId : null,
      relatedToPropertyId: taskRelatedTo === "Property"? relatedToId : null,
      relatedToContactId: taskRelatedTo === "Contact"? relatedToId : null,
    })
  }

  const getProjectTasks = async () => {
    setIsLoading(true) ;
    const relatedToIds = getRelatedToIds();

    const body = {
      ...(filter || {}),
      ...relatedToIds,
    };

    const res = await GetProjectTasks(body);
    if (!(res && res.status && res.status !== 200)) {
        setTasks( res?.result || [] );
    }
    setIsLoading(false) ; 
  };


  const actionClickHandler = useCallback(
    (actionEnum, item, focusedRow, event) => {
      event.stopPropagation();
      event.preventDefault();
      if (actionEnum === TableActions.viewDetails.key) {
        setIsTaskDetailsDialogOpen(true);
        setActiveItem(item);
      }
    },
    []
  );

  useEffect(() => {
    getProjectTasks();
  }, [filter]);

  
  useEffect(() => {
    if(formType)
    {
      let displayWithPermissions = { displayAddBtn  : false , viewAllTasks : false  } ; 
      switch (formType) {
        
        case 'contact':
          displayWithPermissions.displayAddBtn =  returnPropsByPermissions(ContactsPermissions.AddNewTask.permissionsId) ;
          displayWithPermissions.viewAllTasks =  returnPropsByPermissions(ContactsPermissions.ViewTasksForContact.permissionsId) ;
          break;

        case 'property': 
           displayWithPermissions.displayAddBtn  =  returnPropsByPermissions(PropertiesPermissionsCRM.AddNewTask.permissionsId) ;
           displayWithPermissions.viewAllTasks   =  returnPropsByPermissions(PropertiesPermissionsCRM.ViewTasksForProperty.permissionsId) ;
           break;

        case 'unitSale': 
           displayWithPermissions.displayAddBtn =  returnPropsByPermissions(UnitsSalesPermissions.AddNewTask.permissionsId) ;
           displayWithPermissions.viewAllTasks =  returnPropsByPermissions(UnitsSalesPermissions.ViewTasksForUnit.permissionsId) ;
           break;

        case 'unitLease': 
           displayWithPermissions.displayAddBtn =  returnPropsByPermissions(UnitsLeasePermissions.AddNewTask.permissionsId) ;
           displayWithPermissions.viewAllTasks =  returnPropsByPermissions(UnitsLeasePermissions.ViewTasksForUnit.permissionsId) ;
           break;

        case 'saleLead': 
           displayWithPermissions.displayAddBtn =  returnPropsByPermissions(LeadsSalesPermissions.AddNewTask.permissionsId) ;
           displayWithPermissions.viewAllTasks =  returnPropsByPermissions(LeadsSalesPermissions.ViewTasksForLead.permissionsId) ;
           break;

        case 'leaseLead': 
           displayWithPermissions.displayAddBtn =  returnPropsByPermissions(LeadsLeasePermissions.AddNewTask.permissionsId) ;
           displayWithPermissions.viewAllTasks =  returnPropsByPermissions(LeadsLeasePermissions.ViewTasksForLead.permissionsId) ;
           break;

        case 'contactCenterLead': 
           displayWithPermissions.displayAddBtn =  returnPropsByPermissions(LeadsCAllCenterPermissions.AddNewTask.permissionsId) ;
           displayWithPermissions.viewAllTasks =  returnPropsByPermissions(LeadsCAllCenterPermissions.ViewTasksForLead.permissionsId) ;
           break;

        case 'mortgageLead': 
           displayWithPermissions.displayAddBtn = true ;
           displayWithPermissions.viewAllTasks = true ;  ;
           break;

        case 'leadOwnerLead': 
           displayWithPermissions.displayAddBtn = true  ; 
           displayWithPermissions.viewAllTasks =  true  ;
           break;

        case 'leadOwnerUnitSale': 
           displayWithPermissions.displayAddBtn =   true ;
           displayWithPermissions.viewAllTasks =  true ;
           break;

        case 'leadOwnerUnitLease': 
           displayWithPermissions.displayAddBtn =   true ;
           displayWithPermissions.viewAllTasks =  true ;
           break;

         default:
            displayWithPermissions.displayAddBtn =   true ;
            displayWithPermissions.viewAllTasks =  true ;
            break;
        }
        setDisplayDependingOnPermissions(displayWithPermissions) ; 
        }
  }, []);

  return (
    <div className="propx-view propx-bg my-3 childs-wrapper">
       <Spinner isActive={isLoading} isAbsolute />
      <div className='title-section'>
       
      </div>
      <div className='d-flex-column'>
        <div className='header-section'>
            <div className='section'>
              {
               displayDependingOnPermissions && displayDependingOnPermissions.displayAddBtn  && (
               <ButtonBase className='btns theme-solid px-3' onClick={()=> setIsAddTaskDialogOpen(true)}>
                  <span className='mdi mdi-plus' />
                  {t(`${translationPath}add-new-task`)}
                </ButtonBase>
                )
              } 
            </div> 
          </div>
      </div>  
      {
        displayDependingOnPermissions && displayDependingOnPermissions.viewAllTasks && (
          <div className="propx-view-container md">
          <Tables
            data={tasks || []}
            headerData={[
              {
                id: 1,
                label: "name",
                input: "taskConfig",
              },
              {
                id: 2,
                label: "taskCategory",
                input: "taskCategory",
              },
              {
                id: 3,
                label: "taskStatus",
                input: "projectTaskStatus",
              },
  
              {
                id: 7,
                label: "expirationDate",
                input: "expirationDate",
                isDate: true,
                dateFormat: "DD/MM/YYYY",
              },
              {
                id: 8,
                label: "Assigned-to",
                input: "projectTaskAssignTo",
                component: (item) =>
                  (
                    <span>
                      {item?.projectTaskAssignTo &&
                        item.projectTaskAssignTo
                          .map((item) => item.user || "")
                          .join(" , ")}
                    </span>
                  ) || "",
              },
              {
                id: 6,
                label: "createdByName",
                input: "createdByName",
              },
            ]}
            actionsOptions={{
              onActionClicked: actionClickHandler,
            }}
            itemsPerPage={filter.pageSize}
            activePage={filter.pageIndex}
            defaultActions={actionsList}
            parentTranslationPath={parentTranslationPath}
            translationPath={translationPath}
            //   isLoading={isLoading.campaignRequests}
          />
        </div>
        )
      }
      {isDeleteDialogOpen && (
        <DeleteConfigDialog
          activeItem={activeItem}
          isDeleteDialogOpen={isDeleteDialogOpen}
          setIsDeleteDialogOpen={setIsDeleteDialogOpen}
          //   reloadData={reloadData}
          onCloseClicked={() => {
            setIsDeleteDialogOpen(false);
            setActiveItem(null);
          }}
          onCancelClicked={() => {
            setIsDeleteDialogOpen(false);
            setActiveItem(null);
          }}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
        />
      )}

      {isTaskDetailsDialogOpen && (
        <TaskDetailsDialog
          isDialogOpen={isTaskDetailsDialogOpen}
          onClose={() => {
            setIsTaskDetailsDialogOpen(false);
          }}
          activeTask={activeItem || null}
          translationPath={translationPath}
          parentTranslationPath={parentTranslationPath}
        />
      )}

        {isAddTaskDialogOpen &&
          <AddTaskDialog
          isDialogOpen={isAddTaskDialogOpen}
          onSave={() => {
            getProjectTasks();
            setIsAddTaskDialogOpen(false);
          }}
          onClose={() => {
            setIsAddTaskDialogOpen(false);
          }}
          translationPath={translationPath}
          parentTranslationPath={parentTranslationPath}
          taskRelatedToIds={getRelatedToIds()}
        />
        }
    </div>
  );
};
