export const TaskStatusEnum = {
    toDoTasks: {
      id: 1,
      key: "toDoTasks",
      label: 'To-Do',
      classes: 'bg-col-gray',
    },
    inprogressTasks: {
      id: 2,
      key: "inprogressTasks",
      label: 'In-Progress',
      classes: 'bg-col-blue',
    },
    reviewTasks: {
      id: 3,
      key: "reviewTasks",
      label: 'Review',
      classes: 'bg-col-yellow',
    },
    completedTasks: {
      id: 4,
      key: "completedTasks",
      label: 'Completed',
      classes: 'bg-col-green',
    },
  };
  
