import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Spinner, Tables , PermissionsComponent  } from '../../../../../../../Components';
import { ButtonBase, Chip } from '@material-ui/core';
import { TableActions } from '../../../../../../../Enums';
import { MediaPreviewDialog } from '../../../../../TemplatesView/Dialogs';
import {
  getDownloadableLink,
  getIsValidDataURL,
  getIsValidURL,
  showError,
  returnPropsByPermissions 
} from '../../../../../../../Helper';
import { BranchDocumentManagementDialog } from './BranchDocumentManagementDialog';
import { BranchDeleteDocumentDialog } from './BranchDeleteDocumentDialog';
import { BranchesPermissions } from '../../../../../../../Permissions' ; 
 
export const BranchDocumentsComponent = ({
  parentTranslationPath,
  translationPath,
  state,
  setState,
  isSubmitted,
  isLoading,
}) => {
  const [isOpenManagementDialog, setIsOpenManagementDialog] = useState(false);
  const [activeItem, setActiveItem] = useState(null);
  const [tableActions, setTableActions] = useState([]);
  const [isOpenPreviewDialog, setIsOpenPreviewDialog] = useState(false);
  const [isOpenDeleteDialog, setIsOpenDeleteDialog] = useState(false);

  const { t } = useTranslation(parentTranslationPath);

  const updateBranchDocuments = (documents) => {
    if (activeItem) {
      const addedDocs = documents.filter(
        (doc) =>
          !state.branchDocument.find((item) => item.fileId === doc.fileId)
      );
      const updatedDocs = state.branchDocument.map(
        (item) => documents.find((doc) => doc.fileId === item.fileId) ?? item
      );
      setState({
        id: 'branchDocument',
        value: updatedDocs.concat(addedDocs),
      });
      setActiveItem(null);
    } else {
      setState({
        id: 'branchDocument',
        value: state.branchDocument.concat(documents),
      });
    }
  };

  const updateDeletedDocument = (documentId) => {
    setState({
      id: 'branchDocument',
      value: state.branchDocument.filter(
        (document) =>
          document?.fileId !== documentId &&
          document?.branchDocumentId !== documentId
      ),
    });
  };

  const openLink = (url) => {
    if (!url.match(/^https?:\/\//i)) url = 'http://' + url;
    if (getIsValidURL(url)) {
      window.open(url || '');
    } else if (getIsValidDataURL(url)) {
      let windowObj = window.open();
      const iframeElement = `<iframe src="${url}" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>`;
      windowObj.document.write(iframeElement);
    }
  };

  const focusedRowChanged = (rowIndex, item) => {
    if (rowIndex !== -1) {
      let  currentActions = [] ; 
      if(returnPropsByPermissions(BranchesPermissions.EditDocuments.permissionsId))
        currentActions.push({enum: TableActions.editText.key});
      if(returnPropsByPermissions(BranchesPermissions.DeleteDocuments.permissionsId))
        currentActions.push({enum: TableActions.deleteText.key});
      if (item?.fileLink) {
        setTableActions([
          ...currentActions,
          {
            enum: TableActions.openLinkText.key,
          },
        ]);
      } else {
        setTableActions([
          ...currentActions,
          {
            enum: TableActions.openFile.key,
          },
          {
            enum: TableActions.downloadText.key,
          },
        ]);
      }
    }
  };

  const tableActionClicked = useCallback((actionEnum, item) => {
    
    if (actionEnum === TableActions.editText.key) {
      setActiveItem(item);
      setIsOpenManagementDialog(true);
    }
    if (actionEnum === TableActions.openFile.key) {
      setActiveItem({
        templateFileId: item.documentId || item.uuid || item.fileId || null,
        templateFileName:
          item.documentName || item.fileName || item.fullfileName,
      });
      setIsOpenPreviewDialog(true);
    }
    if (actionEnum === TableActions.openLinkText.key) {
      try {
        openLink(item.fileLink);
      } catch (error) {
        showError(t(`${translationPath}this-link-is-not-valid`));
      }
    }

    if (actionEnum === TableActions.deleteText.key) {
      setActiveItem(item);
      setIsOpenDeleteDialog(true);
    }

    if (actionEnum === TableActions.downloadText.key) {
      try {
        const link = document.createElement('a');
        link.setAttribute('download', item.fileName);
        link.href = getDownloadableLink(item.fileId);
        document.body.appendChild(link);
        link.click();
        link.remove();
        setActiveItem(null);
      } catch (error) {
        showError('Error occurred while downloading file:', error);
      }
    }
  }, []);

  useEffect(() => {
    setTableActions();
  }, []);

  return (
    <div className='associated-contacts-wrapper childs-wrapper'>
      <Spinner isActive={isLoading} isAbsolute />
      <div className='title-section'>
        <span>{t(`${translationPath}documents`)}</span>
      </div>
      <div className='filter-section px-2 mb-3'>
       <PermissionsComponent
              permissionsList={Object.values(BranchesPermissions)}
              permissionsId={BranchesPermissions.AddDocuments.permissionsId}
       >
        <ButtonBase
          className='btns theme-solid px-3'
          onClick={() => {
            setIsOpenManagementDialog(true);
          }}
        >
          <span className='mdi mdi-plus' />
          {t(`${translationPath}add-new`)}
        </ButtonBase>
       </PermissionsComponent>
      </div>
      <div className='w-100 px-2'>
        <Tables
          data={state.branchDocument ?? []}
          headerData={[
            {
              id: 1,
              label: 'category',
              input: 'categoryName',
            },
            {
              id: 2,
              label: `title`,
              component: (item) => (
                <span>{item?.fileName || item?.fileLink}</span>
              ),
            },

            {
              id: 3,
              label: 'created-date',
              isDate: true,
              input: 'createdOn',
              dateFormat: 'DD/MM/YYYY',
            },
            {
              id: 4,
              label: 'created-by',
              input: 'createdBy',
            },
            {
              id: 5,
              input: 'expiryDate',
              label: 'expiry-date',
              isDate: true,
            },
            {
              id: 6,
              label: 'is-active',
              component: (item) => (
                <span>
                  {item.isActive ? (
                    <Chip className='active-chip' label='active' />
                  ) : (
                    <Chip className='expired-chip' label='expired' />
                  )}
                </span>
              ),
            },
          ]}
          defaultActions={tableActions}
          actionsOptions={{
            onActionClicked: tableActionClicked,
          }}
          focusedRowChanged={focusedRowChanged}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          itemsPerPage={state.branchDocument?.length}
          activePage={0}
        />
      </div>
      {isOpenManagementDialog && (
        <BranchDocumentManagementDialog
          activeItem={activeItem}
          isOpen={isOpenManagementDialog}
          onSave={(documents) => {
            updateBranchDocuments(documents);
            setIsOpenManagementDialog(false);
          }}
          isOpenChanged={() => {
            setIsOpenManagementDialog(false);
            setActiveItem(null);
          }}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          setIsOpenManagementDialog={setIsOpenManagementDialog}
          isOpenManagementDialog={isOpenManagementDialog}
        />
      )}
      {isOpenPreviewDialog && (
        <MediaPreviewDialog
          activeItem={activeItem}
          maintitleText='FILE-VIEW'
          isOpen={isOpenPreviewDialog}
          isOpenChanged={() => {
            setIsOpenPreviewDialog(false);
            setActiveItem(null);
          }}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
        />
      )}
      {isOpenDeleteDialog && (
        <BranchDeleteDocumentDialog
          activeItem={activeItem}
          open={isOpenDeleteDialog}
          close={() => {
            setIsOpenDeleteDialog(false);
            setActiveItem(null);
          }}
          onSave={(documentId) => {
            setIsOpenDeleteDialog(false);
            setActiveItem(null);
            updateDeletedDocument(documentId);
          }}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
        />
      )}
    </div>
  );
};
