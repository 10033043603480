import React, {
  useEffect,
  useState,
  useReducer
} from "react";
import Joi from 'joi';
import "./LeaseTransactionJourney.scss";
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { PageHeading, ReelStepper, ReelStepperPreview, AboutUnitCard } from "../../../SharedComponents";
import { UnitMapper } from "../UnitsLeaseView/UnitLeaseMapper/UnitMapper.jsx";
import { UnitsAdvanceSearchTest, SetUnitAsUnderOfferOrOfferSigned, UploadSignedOfferLetter,
   SetUnitAsReserveOrLease, GetUnitTransactionOfferLetters, GetAllSystemConfigurationsStatus, 
   GetReservedUnitTransactionDataForLeaseByUnitId,
   SetUnitAsUnderContractOrContractSigned,
   UploadSignedTenancyContract,
   GetUnitTransactionTenancyContracts,
   AddTransactionMissingDocuments} from "../../../Services";
import { ContractDetailsDialog, TransactionActionButtons } from "./Utilities";
import { GetParams, GlobalHistory, showError, showSuccess, showWarn } from '../../../Helper';
import { TransactionStepsData } from "./TransactionSteps";
import { useTitle } from '../../../Hooks';
import { UnitsStatusEnum } from "../../../Enums";


export const LeaseTransactionJourney = () => {

  const parentTranslationPath = "LeaseTransactionJourney";
  const translationPath = "";
  const { t } = useTranslation(parentTranslationPath);


  const URLPathStep = GetParams('step');
  const unitId = +GetParams('id');
  const loginResponse = useSelector((state) => state.login && state.login.loginResponse);

  const reducer = (state, action) => {
    if (action.id !== "edit") return { ...state, [action.id]: action.value };
    return {
      ...action.value,
    };
  };
  const [unitTransactionId, setUnitTransactionId] = useState(null);
  const [unitData, setUnitData] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [stepperData, setStepperData] = useState(Object.values(TransactionStepsData));
  const [activeStep, setActiveStep] = useState(Object.values(TransactionStepsData)[0]);
  const [state, setState] = useReducer(reducer, {
  });
  const [isDialogOpen, setIsDialogOpen] = useReducer(reducer, {
    ContractDetails: false,
  });
  const [isBackClicked, setIsBackClicked] = useState(false);
  const [haveToResetInitDOCs, setHaveToResetInitDOCs] = useState(false);
  const [selected, setSelected] = useReducer(reducer, {
    tenantAgent: null,
    offerLetterType: null,
    contacts: [],
    invoicesIds: [],
    transactionDTOs: {
      OfferLetterSend: {},
      OfferLetterUpload: {},
      TenancyContractSend: {},
      TenancyContractUpload: {},
      UnitReservation: {},
      TransactionExecution: {},
    },
    contactDocuments: [],
    offerLetterDocs : {
      tenantDocuments: [],
      landlordDocuments: [],
    },
    tenancyContractDocs : {
      tenantDocuments: [],
      landlordDocuments: [],
    },
    autoFilled: {
      transactedBy: null,
      tenantAgent: null,
      startDate: null,
      endDate: null,
      offerLetterContent: null,
      tenancyContractContent: null,
    }
  });

  const [transactionSchema, setTransactionSchema] = useReducer(reducer, {
    TenantSelection: {},
    OfferLetterDispatch: {},
    UnitReservation: {},
    TransactionExecution: {},
  });

  useTitle(t(`${translationPath}${activeStep.title}`));

  const onSelectedChanged = (newValue) => {
    setSelected(newValue);
  };
  const onStateChanged = (newValue) => {
    setState(newValue);
  };

  const getUnitsDetails = async () => {
    const criteria = {};

    if (unitId)
      criteria["unitId"] = [
        {
          searchType: 2,
          value: unitId,
        },
      ];


    const body = {
      criteria,
      filterBy: "createdOn",
      orderBy: 2,
      OperationType: "Lease",
    };

    const res = await UnitsAdvanceSearchTest(
      { pageIndex: 0, pageSize: 1 },
      body
    );
    if (!(res && res.status && res.status !== 200)) {
      const units = ((res && res.result) || []).map(
        (item) => item.unitJson && UnitMapper(item, JSON.parse(item.unitJson).unit));
      if (units?.length > 0) {
        const unitData = units[0];
        setUnitData(unitData);
      }
    }
  };

  const reloadUnit = () => getUnitsDetails()

  const getIsStepAllowed = (clickedStep) => {
    const nextAllowedStep =  stepperData && stepperData.find(item => item.isPassed === false);
    const isStatusAllowed = clickedStep.unitStatus && clickedStep.unitStatus.includes(unitData?.lease_status);
    const isPassedStep = clickedStep?.isPassed;
    const isNextAllowedStep = clickedStep?.id === nextAllowedStep?.id;

    return isNextAllowedStep || isPassedStep || isStatusAllowed;
  }

  const stepClickHandler = (step) => {
    if (getIsStepAllowed(step)) {
      setActiveStep(step);
      GlobalHistory.push(`/home/lease-transaction-journey?id=${unitId}&step=${step.key}`);
    }
  }

  const setUnitAsUnderOfferOrOfferSigned = async (dialogSelected) => {
    setLoading(true);
    const body = {
      ...(selected.transactionDTOs?.OfferLetterSend || {}),
      ...(dialogSelected || {})
    }
    const res = await SetUnitAsUnderOfferOrOfferSigned(body);
    if (!(res && res.status && res.status !== 200)) {
      setCurrentStepAsPassed();
      showSuccess(t(`${translationPath}Offer-sent-successfully`));
      setHaveToResetInitDOCs(true);
      await reloadUnit();
      setIsDialogOpen({ id: "ContractDetails", value: false })
    } else showError(t(`${translationPath}Offer-sending-failed`));

    setLoading(false);
  }

  const setUnitAsUnderContractOrContractSigned = async () => {
    setLoading(true);
    const body = {
      ...(selected.transactionDTOs?.TenancyContractSend || {}),
    }
    const res = await SetUnitAsUnderContractOrContractSigned(body);
    if (!(res && res.status && res.status !== 200)) {
      setCurrentStepAsPassed();
      await reloadUnit();
      showSuccess(t(`${translationPath}Contract-sent-successfully`));
      setHaveToResetInitDOCs(true);
    } else showError(t(`${translationPath}Contract-sending-failed`));

    setLoading(false);
  }

  const uploadSignedOfferLetter = async (isForLandlord) => {
    setLoading(true);
    const UploadTypeKey = isForLandlord ? "Landlord" : "Tenant";

    const dto = selected.transactionDTOs?.OfferLetterUpload?.[UploadTypeKey];

    if (dto?.documents?.length > 0) {
      const body = {
        ...dto,
        isForLandlord,
      }
      const res = await UploadSignedOfferLetter(body);
      if (!(res && res.status && res.status !== 200)) {
        setCurrentStepAsPassed();
        await reloadUnit();
        if (isForLandlord) {
          onSelectedChanged({ id: "offerLetterDocs", value: {
            ...(selected.offerLetterDocs || []),
            landlordDocuments: []
         }});
         showSuccess(t(`${translationPath}landlord-submitted-successfully`));
        } else {
          onSelectedChanged({ id: "offerLetterDocs", value: {
            ...(selected.offerLetterDocs || []),
            tenantDocuments: []
         }});
         showSuccess(t(`${translationPath}tenant-submitted-successfully`));
        }
        
      } else {
        if (isForLandlord) showError(t(`${translationPath}landlord-submit-failed`));
        else showError(t(`${translationPath}tenant-submit-failed`));
      }
    }
    setLoading(false);
  }

  const uploadSignedTenancyContract = async (isForLandlord) => {
    setLoading(true);
    const UploadTypeKey = isForLandlord ? "Landlord" : "Tenant";

    const dto = selected.transactionDTOs?.TenancyContractUpload?.[UploadTypeKey];

    if (dto?.documents?.length > 0) {
      const body = {
        ...dto,
        isForLandlord,
      }
      const res = await UploadSignedTenancyContract(body);
      if (!(res && res.status && res.status !== 200)) {
        setCurrentStepAsPassed();
        await reloadUnit();
        if (isForLandlord) {
          onSelectedChanged({ id: "tenancyContractDocs", value: {
            ...(selected.tenancyContractDocs || []),
            landlordDocuments: []
         }});
          showSuccess(t(`${translationPath}landlord-contract-submitted-successfully`));
        } else {
          onSelectedChanged({ id: "tenancyContractDocs", value: {
            ...(selected.tenancyContractDocs || []),
            tenantDocuments: []
         }});
          showSuccess(t(`${translationPath}tenant-contract-submitted-successfully`));
        }
      } else {
        if (isForLandlord) showError(t(`${translationPath}landlord-contract-submit-failed`));
        else showError(t(`${translationPath}tenant-contract-submit-failed`));
      }
    }
    setLoading(false);
  }

  const activeSchemaHandler = (schema) => activeStep?.key && setTransactionSchema({ id: activeStep?.key, value: schema })
  const getActiveStepSchema = () => transactionSchema[activeStep?.key] || {};
  const getSchemaState = () => {
    
    const UnitReservationState = selected.transactionDTOs?.UnitReservation?.leaseTransactionDetails || {};
    const TransactionExecutionState = selected.transactionDTOs?.TransactionExecution?.leaseTransactionDetails || {};

    const state = {
      UnitReservations: UnitReservationState,
      TransactionExecution: TransactionExecutionState,
    }

    let schemaState = {
      ...(state[activeStep?.key] || {}),
    };

    return schemaState;
  }

  const schema = Joi.object(getActiveStepSchema())
    .options({
      abortEarly: false,
      allowUnknown: true,
    })
    .validate(getSchemaState());

  const setUnitAsReserveOrLease = async () => {
    setLoading(true);
    let body = {}
    if(checkUnitStatus(UnitsStatusEnum.OfferSigned.name)) body = selected.transactionDTOs?.UnitReservation;
    else if(checkUnitStatus(UnitsStatusEnum.ContractSigned.name)) body = selected.transactionDTOs?.TransactionExecution;

    const res = await SetUnitAsReserveOrLease(body);
    if (!(res && res.status && res.status !== 200)) {
      showSuccess(t(`${translationPath}success!`));
      await reloadUnit();
      setIsSubmitted(false);
    } else {
      showError(t(`${translationPath}failed`));
      setIsSubmitted(false);
    }
    setLoading(false);
  }

  const addTransactionMissingDocuments = async () => {

    const body = selected.transactionDTOs?.DocumentChecklistSave;
    const res = await AddTransactionMissingDocuments(body);
    if (res === true) {
      setIsSubmitted(false);
      showSuccess(t(`${translationPath}documents-uploaded-successfully`));
    } else {
      setIsSubmitted(false);
      showError(t(`${translationPath}documents-upload-failed`));
    }
  };

  const changeActiveSubmitButton = (newSubmitButton) => stepDataChangeHandler("submitButton", newSubmitButton)

  const setCurrentStepAsPassed = () =>  stepDataChangeHandler("isPassed", true);
  const getSubmitButtonHandler = () => {

    const checkIsTemplateLoaded = () => {
      const DTO = URLPathStep === TransactionStepsData.OfferLetterDispatch.key
        ? selected.transactionDTOs?.OfferLetterSend : selected.transactionDTOs?.TenancyContractSend;

      const isTemplateExist =  !DTO?.systemTemplateId || 
      (DTO?.systemTemplateId && (DTO?.offerLetterContent ||
        DTO?.tenancyContractContent)) ? true : false;

      if (!isTemplateExist) showWarn(t(`${translationPath}template-content-doesnt-exist`));
      return isTemplateExist;
    }
    

    const nextHandler = () => {
      const activeStepIndex = stepperData.findIndex(item => item.key === URLPathStep);
      const nextStep = stepperData[activeStepIndex + 1];

      if (nextStep) {
        GlobalHistory.push(
          `/home/lease-transaction-journey?id=${unitId}&step=${nextStep.key}`
        );
      }
    }

    const offerLetterSendHandler = () => {
      if (!checkIsTemplateLoaded()) return;
      setIsDialogOpen({ id: "ContractDetails", value: true })
    }

    const offerLetterSubmitHandler = async () => {

      const isUnderOffer = unitData?.lease_status === UnitsStatusEnum.UnderOffer.name;
      if (isUnderOffer) {
        const isForLandlord = false;
        await uploadSignedOfferLetter(isForLandlord);
        await uploadSignedOfferLetter(!isForLandlord);
        setHaveToResetInitDOCs(true);
        await reloadUnit();
      } else {
        if (!checkIsTemplateLoaded()) return;
        setIsDialogOpen({ id: "ContractDetails", value: true });
      }
    }
    const unitReservationSubmitHandler = () => {
      setIsSubmitted(true);
      if (schema.error) {
        showError(t('Shared:please-fix-all-errors'));
        return;
      }
      setUnitAsReserveOrLease();
    }

    const tenancyContractSendHandler = () => {
      if (!checkIsTemplateLoaded()) return;
      setUnitAsUnderContractOrContractSigned();
    }

    const tenancyContractSubmitHandler = async () => {

      const UnderContract = unitData?.lease_status === UnitsStatusEnum.UnderContract.name;
      if (UnderContract) {
        const isForLandlord = false;
        await uploadSignedTenancyContract(isForLandlord);
        await uploadSignedTenancyContract(!isForLandlord);
        setHaveToResetInitDOCs(true);
        await reloadUnit();
      } else {
        if (!checkIsTemplateLoaded()) return;
        setUnitAsUnderContractOrContractSigned();
      }
    }

    const TransactionExecutionSubmitHandler = () => {
      setIsSubmitted(true);
      if (schema.error) {
        showError(t('Shared:please-fix-all-errors'));
        return;
      }
      setUnitAsReserveOrLease();
    }
    
    const documentChecklistSaveHandler = () => {
      const isThereUploadedDocs = selected.transactionDTOs?.DocumentChecklistSave?.missingDocumentsFiles?.length > 0;
      if(isThereUploadedDocs){
        setIsSubmitted(true);
        addTransactionMissingDocuments();
      } else showWarn(t(`${translationPath}there's-no-doc-to-submit`));
    }

    const documentChecklistFinishHandler = () => {
      GlobalHistory.push('/home/units-lease/view');
    }
    
    switch (activeStep.submitButton) {
      case 'Next': return nextHandler;
      case 'Send': {
        if (activeStep.key === "OfferLetterDispatch")
          return offerLetterSendHandler;
        if (activeStep.key === "ContractIssuance")
          return tenancyContractSendHandler;
      }
      case 'Submit': {
        if (activeStep.key === "OfferLetterDispatch")
          return offerLetterSubmitHandler;
        if (activeStep.key === "UnitReservations")
          return unitReservationSubmitHandler;
        if (activeStep.key === "ContractIssuance")
          return tenancyContractSubmitHandler;
        if (activeStep.key === "TransactionExecution")
          return TransactionExecutionSubmitHandler;
      }
      case 'Save': {
        if (activeStep.key === "DocumentChecklist")
          return documentChecklistSaveHandler;
      }
      case 'Finish': {
        if (activeStep.key === "DocumentChecklist")
          return documentChecklistFinishHandler;
      }
      default: return () => { };
    }
  }

  const getSubmitButtonIsDisabled = () => {

    const tenantSelectionNext = () => selected.contacts?.length === 0;
    const OfferLetterSignOffNext = () => {
      const isOfferSigned = unitData?.lease_status !== UnitsStatusEnum.Available.name;
      return !isOfferSigned;
    }
    const OfferLetterSignOffSend = () => {
      const isTenantsFilled = selected.transactionDTOs?.OfferLetterSend?.unitTransactionContacts?.length > 0;
      const isOfferLetterTypeFilled = selected.transactionDTOs?.OfferLetterSend?.offerLetterTypeId;
      const isUnderOffer = unitData?.lease_status === UnitsStatusEnum.UnderOffer.name;

      return !isOfferLetterTypeFilled || isUnderOffer 
      || !isOfferLetterTypeFilled || !isTenantsFilled;
    }

    const OfferLetterSignOffSubmit = () => {
      const isOfferLetterTypeFilled = selected.transactionDTOs?.OfferLetterSend?.offerLetterTypeId || selected.offerLetterType;
      const isNewTenantDocsUploaded = [...(selected.offerLetterDocs?.tenantDocuments || [])].find(item => item.isUploaded);
      const isNewLandlordtDocsUploaded = [...(selected.offerLetterDocs?.landlordDocuments || [])].find(item => item.isUploaded);
      const isOnlyTenantDocs = checkUnitStatus(UnitsStatusEnum.Available.name) && (isNewTenantDocsUploaded && !isNewLandlordtDocsUploaded);
      const isNoneDocsUpload = !isNewTenantDocsUploaded && !isNewLandlordtDocsUploaded;

      return isOnlyTenantDocs || isNoneDocsUpload || !isOfferLetterTypeFilled;
    }

    const UnitReservationsNext = () => {
      return (checkUnitStatus(UnitsStatusEnum.Available.name) 
      || checkUnitStatus(UnitsStatusEnum.UnderOffer.name)
      || checkUnitStatus(UnitsStatusEnum.PendingReserveLease.name)
      || checkUnitStatus(UnitsStatusEnum.PendingReservedLease.name));
    }

    const UnitReservationsSubmit = () => false;

    const PaymentDetailsNext = () => {
      const transactionHasInvoices = selected.invoicesIds?.length > 0 ;
      return !transactionHasInvoices;
    };


    const ContractIssuanceNext = () => {
      return (checkUnitStatus(UnitsStatusEnum.ReservedLeased.name) 
      || checkUnitStatus(UnitsStatusEnum.UnderContract.name));
    }
    const ContractIssuanceSend = () => {
      const isTenancyContractTypeFilled = selected.transactionDTOs?.TenancyContractSend?.tenancyContractTypeId;
      const isUnderContract = unitData?.lease_status === UnitsStatusEnum.UnderContract.name;

      return !isTenancyContractTypeFilled || isUnderContract;
    }

    const TenancyContractSignOffSubmit = () => {
      const isNewTenantDocsUploaded = [...(selected.tenancyContractDocs?.tenantDocuments || [])].find(item => item.isUploaded);
      const isNewLandlordtDocsUploaded = [...(selected.tenancyContractDocs?.landlordDocuments || [])].find(item => item.isUploaded);
      const isOnlyTenantDocs = checkUnitStatus(UnitsStatusEnum.ReservedLeased.name) && (isNewTenantDocsUploaded && !isNewLandlordtDocsUploaded);
      const isNoneDocsUpload = !isNewTenantDocsUploaded && !isNewLandlordtDocsUploaded;

      return isOnlyTenantDocs || isNoneDocsUpload;
    }

    
    const TransactionExecutionNext = () =>  !checkUnitStatus(UnitsStatusEnum.Leased.name) 
    

    const TransactionExecutionSubmit = () => false;
    const DocumentChecklistSave = () => false;


    switch (activeStep.key) {
      case 'TenantSelection': return tenantSelectionNext();
      case 'OfferLetterDispatch': {
        if (activeStep.submitButton == "Next")
          return OfferLetterSignOffNext();
        if (activeStep.submitButton == "Send")
          return OfferLetterSignOffSend();
        if (activeStep.submitButton == "Submit")
          return OfferLetterSignOffSubmit();
      }
      case 'UnitReservations': {
        if (activeStep.submitButton == "Next")
          return UnitReservationsNext();
        if (activeStep.submitButton == "Submit")
          return UnitReservationsSubmit();
      }
      case 'PaymentDetails': {
        if (activeStep.submitButton == "Next")
          return PaymentDetailsNext();
      }
      case 'ContractIssuance': {
        if (activeStep.submitButton == "Next")
          return ContractIssuanceNext();
        if (activeStep.submitButton == "Send")
          return ContractIssuanceSend();
        if (activeStep.submitButton == "Submit")
          return TenancyContractSignOffSubmit();
      }
      case 'TransactionExecution': {
        if (activeStep.submitButton == "Next")
          return TransactionExecutionNext();
        if (activeStep.submitButton == "Submit")
          return TransactionExecutionSubmit();
      }
      case 'DocumentChecklist': {
        if (activeStep.submitButton == "Save")
          return DocumentChecklistSave();
      }
      default: return false;
    }

  }

  const stepDataChangeHandler = (key, value) => {
    const activeStepIndex = stepperData.findIndex(item => item.id === activeStep.id);
    const updatedActiveStep = {
      ...activeStep,
      [key]: value,
    }
    
    let localStepperData = [
      ...stepperData,
    ]
    localStepperData[activeStepIndex] = updatedActiveStep;

    setStepperData(localStepperData);
    setActiveStep(updatedActiveStep);
  } 
  const URLStepParamHandler = () => {
    fillIsPassedInPrevSteps();
    const activeStepIndex = stepperData.findIndex(item => item.key === URLPathStep);

    if (activeStepIndex != -1) setActiveStep(stepperData[activeStepIndex]);
  }
  const fillIsPassedInPrevSteps = () => {
    const activeStepIndex = stepperData.findIndex(item => item.key === URLPathStep);
    // const activeStepIndex = stepperData.findIndex(item => item.unitStatus.includes(unitData?.lease_status));
    let localStepperData = [
      ...stepperData,
    ];
    localStepperData = stepperData.map((item, index) => {
      const localStep = {
        ...item,
      }
      if (index < activeStepIndex)
        localStep.isPassed = true

      return localStep;
    });
    setStepperData(localStepperData);
  };


  const mapSingleRetrievedDoc = (document) => {
    return ({
      fileName: document.fileName,
      uuid: document.fileId,
      isRetrieved: true,
    })
  }
  const [data, setData] = useReducer(reducer, {
    offerLetterTypes: [],
  });

  const getOfferLetterTypes = async () => {
    const result = await GetAllSystemConfigurationsStatus();
    if (!(result && result.status && result.status !== 200)) {
      const offerLetterTypes = result && result.filter(item => item.systemConfigurationName === 'ResidentialOfferLetter' ||
        item.systemConfigurationName === 'CommercialOfferLetter'
      ) || [];

      const mappedOfferLetterTypes = offerLetterTypes.map(item => ({
        ...item,
        label: item.systemConfigurationName.split('OfferLetter')[0],
      }))
      setData({ id: 'offerLetterTypes', value: mappedOfferLetterTypes });
    }
  }

  const mapOfferLetterInfo = (response) => {
    const offerLetterType = data.offerLetterTypes.find(item => item.systemConfigurationName === response.offerLetterType) || null;



    const retrievedTenantDocuments = (response.tenantOfferLetters || [])
      .map(item => mapSingleRetrievedDoc(item));

    const retrievedLandlordDocuments = (response.landlordOfferLetters || [])
      .map(item => mapSingleRetrievedDoc(item));

    const uploadedTenantDocuments = (selected.offerLetterDocs?.tenantDocuments || [])
      .filter(item => item.isUploaded && (retrievedTenantDocuments.findIndex(file => file.uuid === item.uuid) == -1));

    const uploadedLandlordDocuments = (selected.offerLetterDocs?.landlordDocuments || [])
      .filter(item => item.isUploaded && (retrievedLandlordDocuments.findIndex(file => file.uuid === item.uuid) == -1));

    const transactionCreatedByAuto = {
      id: response.transactedBy?.transactedById,
      fullName: response.transactedBy?.transactedBy,
    }
    onSelectedChanged({
      id: "offerLetterDocs", value: {
        tenantDocuments: [
          ...retrievedTenantDocuments,
          ...uploadedTenantDocuments,
        ],
        landlordDocuments: [
          ...retrievedLandlordDocuments,
          ...uploadedLandlordDocuments,
        ],
      }
    })
    onSelectedChanged({ id: "underOfferDuration", value: response.offerLetterDuration })
    onSelectedChanged({ id: "offerLetterType", value: offerLetterType })
    onSelectedChanged({
      id: "autoFilled", value: {
        transactedBy: transactionCreatedByAuto,
        tenantAgent: transactionCreatedByAuto,
        startDate: response.leasingStartDate,
        endDate: response.leasingEndDate,
        offerLetterContent: response.offerLetterContent,
      }
    })


  }

  const mapTenancyContractInfo = (response) => {

    const retrievedTenantDocuments = (response.tenantTenancyContracts || [])
      .map(item => mapSingleRetrievedDoc(item));

    const retrievedLandlordDocuments = (response.landlordTenancyContracts || [])
      .map(item => mapSingleRetrievedDoc(item));

    const uploadedTenantDocuments = (selected.tenancyContractDocs?.tenantDocuments || [])
      .filter(item => item.isUploaded && (retrievedTenantDocuments.findIndex(file => file.uuid === item.uuid) == -1));

    const uploadedLandlordDocuments = (selected.tenancyContractDocs?.landlordDocuments || [])
      .filter(item => item.isUploaded && (retrievedLandlordDocuments.findIndex(file => file.uuid === item.uuid) == -1));

    onSelectedChanged({
      id: "tenancyContractDocs", value: {
        tenantDocuments: [
          ...retrievedTenantDocuments,
          ...uploadedTenantDocuments,
        ],
        landlordDocuments: [
          ...retrievedLandlordDocuments,
          ...uploadedLandlordDocuments,
        ],
      }
    })
    onSelectedChanged(({
      id: "autoFilled", value: {
        ...(selected.autoFilled || {}),
        tenancyContractContent: response.tenancyContractContent,
      }
    }))


  }


  //to get transaction id
  const getReservedTransactionData = async () => {
    
    const res = await GetReservedUnitTransactionDataForLeaseByUnitId(unitId);
      setUnitTransactionId(res?.unitTransactionId || null);
  }

  const getUnitTransactionOfferLetters = async () => {
    const res = await GetUnitTransactionOfferLetters(unitId);
    if (!(res && res.status && res.status !== 200)) {
      mapOfferLetterInfo(res)
    }
  }

  const getUnitTransactionTenancyContracts = async () => {
    const res = await GetUnitTransactionTenancyContracts(unitId);
    if (!(res && res.status && res.status !== 200)) {
      mapTenancyContractInfo(res)
    }
  }


  const checkUnitStatus = (unitStatusName) => {
    const isStatusMatched = unitData?.lease_status === unitStatusName;
    return isStatusMatched;
  }

  const getIsWithBackButton = () => {
    const isPaymentDetailsStep = URLPathStep == TransactionStepsData.PaymentDetails.key;
    return isPaymentDetailsStep;
  }

  const backButtonClickHandler = (isClicked) => {
    if(typeof isClicked == "boolean") setIsBackClicked(isClicked);
    else setIsBackClicked(true);
  }
  
  const setAllStepsAsPassed = () => {
        let localStepperData = [
      ...stepperData,
    ];
    localStepperData = stepperData.map((item, index) => ({
      ...item,
      isPassed : true
    }));
    setStepperData(localStepperData);
  }
  
  useEffect(() => {

   if (unitData && checkUnitStatus(UnitsStatusEnum.Leased.name)) setAllStepsAsPassed();

    if (unitData && data.offerLetterTypes?.length > 0) {

      if (!checkUnitStatus(UnitsStatusEnum.Available.name)) getUnitTransactionOfferLetters();
    }

  }, [unitData, data.offerLetterTypes]);


  useEffect(() => {

    const isOfferLetterDataExist = !!selected.autoFilled?.startDate;
    if (unitData && isOfferLetterDataExist) {

      if (checkUnitStatus(UnitsStatusEnum.ContractSigned.name) ||
        checkUnitStatus(UnitsStatusEnum.UnderContract.name) ||
        checkUnitStatus(UnitsStatusEnum.PendingLeased.name) ||
        checkUnitStatus(UnitsStatusEnum.Leased.name)) getUnitTransactionTenancyContracts();
    }
   }, [unitData, selected.autoFilled?.startDate]);

  useEffect(() => {

    if (!checkUnitStatus(UnitsStatusEnum.Leased.name)) fillIsPassedInPrevSteps();

    if (unitId) {
      getUnitsDetails();
      getReservedTransactionData();
    }

    getOfferLetterTypes();

  }, []);
  //todo:: add all stepper helper functions in a new local helper file 

  useEffect(() => {
    URLStepParamHandler();
  }, [URLPathStep]);

  useEffect(() => {
    setHaveToResetInitDOCs(prevState => !prevState);
  }, [activeStep]);
  //todo:: redesign contact + lead dialogs
  return (
    <div className='propx-view propx-bg Transaction-Layout'>
      <PageHeading
        parentTranslationPath={parentTranslationPath}
        translationPath={translationPath}
        headerTitle={"Transaction"}
        subTitle={"Lease-Transaction-Journey-heading"}
        wrapperClasses={"px-4 pt-4 w-75"}
        headerTitleClasses={"fz-30  mb-3"}
        subTitleClasses={"m-0 fz-16"}
      />

      <div className='Layout-container px-4'>
        <div className=''>
          {unitData &&
            <div className=" propx-view-container  d-flex-center m-0 mb-1">
              {/**TODO:: add spinner on this dev OR:: work on skeleton loader in AboutUnitCard */}
              <AboutUnitCard
                unitData={unitData}
                orientation="vertical"
              />
            </div>
          }
          <ReelStepper
            steps={stepperData}
            activeStep={activeStep}
            onStepClick={stepClickHandler}
            parentTranslationPath={parentTranslationPath}
            translationPath={translationPath} />
        </div>
        <div className='propx-view-container m-0 h-fit'>
          <div className="d-flex-default d-flex-h-between b-bottom">
            <PageHeading
              parentTranslationPath={parentTranslationPath}
              translationPath={translationPath}
              headerTitle={activeStep.title || ''}
              subTitle={activeStep.subTitle || ''}
              wrapperClasses={"w-75"}
              headerTitleClasses={"fz-16 mb-1"}
              subTitleClasses={"fz-14 m-0 ml-0"}
            />
            <TransactionActionButtons
              isSubmitDisabled={getSubmitButtonIsDisabled()}
              submitButton={{
                label: activeStep.submitButton,
                handler: getSubmitButtonHandler(),
                isLoading: isLoading,
              }}
              isWithBackButton={getIsWithBackButton()}
              backButtonHandler={backButtonClickHandler}
              parentTranslationPath={parentTranslationPath}
            />
          </div>
          <div>
            <ReelStepperPreview
              activeStep={activeStep}
              dynamicComponentProps={{
                schema,
                activeSchemaHandler,
                backButton : {
                  isClicked: isBackClicked,
                  clickHandler: (param) => backButtonClickHandler(param),
                },
                isSubmitted,
                unitId,
                unitTransactionId,
                unitData,
                reloadUnit,
                state,
                haveToResetInitDOCs,
                onStateChanged,
                selected,
                onSelectedChanged,
                changeActiveSubmitButton,
                parentTranslationPath,
              }}
            />
          </div>
        </div>
      </div>
      <ContractDetailsDialog
        onClose={() => setIsDialogOpen({ id: "ContractDetails", value: false })}
        onSubmit={(dialogSelected) => {
          setUnitAsUnderOfferOrOfferSigned(dialogSelected);
        }}
        isLoading={isLoading}
        isOpen={isDialogOpen.ContractDetails}
        parentTranslationPath={parentTranslationPath}
      />
    </div>
  )
}
