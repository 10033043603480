import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { DialogComponent, Inputs, Spinner, Tables, PaginationComponent } from '../../../../../../Components';
import { getErrorByName, GetParams, bottomBoxComponentUpdate } from '../../../../../../Helper';
import { GetAllDfmRolePermissionTransactionLogs } from '../../../../../../Services';
import Tooltip from '@material-ui/core/Tooltip';
import moment from 'moment';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from '@material-ui/core';

export const RolesNameComponent = ({
  rolesId,
  state,
  setState,
  schema,
  onStateChanged,
  isSubmitted,
  parentTranslationPath,
  translationPath,
}) => {
  const { t } = useTranslation(parentTranslationPath);
  const [isOpenHistoryDialog, setIsOpenHistoryDialog] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [permissionHistoryResult, setPermissionHistoryResult] = useState({
    result: [],
    totalCount: 0
  });
  const [filter, setFilter] = useState({
    pageIndex: 0,
    pageSize: 25,
  })
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const onPageIndexChanged = (pageIndex) => {
    setFilter((item) => ({ ...item, pageIndex }));
  };
  const onPageSizeChanged = (pageSize) => {
    setFilter((item) => ({ ...item, pageIndex: 0, pageSize }));
  };
  const getAllDfmRolePermissionTransactionLogs = useCallback(async () => {
    setIsLoading(true);

    const res = await GetAllDfmRolePermissionTransactionLogs({ pageIndex: filter.pageIndex + 1, pageSize: filter.pageSize, search: state.roleName });
    if (!(res && res.status && res.status !== 200)) setPermissionHistoryResult({ result: res.result, totalCount: res.totalCount })
    else setPermissionHistoryResult({ result: [], totalCount: 0 })

    setIsLoading(false);
  }, [state, filter]);

  useEffect(() => {
    if (isOpenHistoryDialog) getAllDfmRolePermissionTransactionLogs();
  }, [isOpenHistoryDialog, filter]);


  return (
    <div className='role-name-wrapper'>
      <div className='roles-title'>
        <span className='title-text'>
          {t(`${translationPath}${(rolesId && 'edit-role-name') || 'add-role-name'}`)}
        </span>
      </div>
      <div className='d-flex flex-h-between'>
        <Inputs
          idRef='roleNameRef'
          inputPlaceholder='role-name-description'
          value={state.roleName || ''}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          helperText={getErrorByName(schema, 'roleName').message}
          error={getErrorByName(schema, 'roleName').error}
          isWithError
          isSubmitted={isSubmitted}
          isDisabled={GetParams('isCore') === 'true'}
          onInputChanged={(event) => {
            if (onStateChanged) onStateChanged({ id: 'roleName', value: event.target.value });
          }}
        />
        <div className='history-icon' onClick={() => setIsOpenHistoryDialog(true)}>
          <Tooltip title={t(`${translationPath}history`)}>
            <span className='mdi mdi-history' />
          </Tooltip>
        </div>
      </div>
      {isOpenHistoryDialog && (
        <DialogComponent
          titleText={t(`${translationPath}permissions-history`)}
          dialogContent={(
            <>
              <div className='history-table-conent-wrapper roles-history'>
                <Spinner isActive={isLoading} />
                <div className='w-100 history-table-header'>
                  <span>{t(`${translationPath}action`)}</span>
                  <span>{t(`${translationPath}user-name`)}</span>
                  <span>{t(`${translationPath}change-date`)}</span>
                </div>
                {(permissionHistoryResult.result.map((item, index) =>
                  <Accordion
                    onChange={handleChange(item.dfmFieldId)}
                  >
                    <AccordionSummary
                      onClick={() => {  }}
                    >
                      <div
                        className={`history-table-content w-100 ${index % 2 === 0 ? 'is-gray' : ''}`}
                      >
                        <div
                          className={`history-expand-icon d-inline-flex`}
                        >
                          <span>
                            {item.requestTypeName === 'Create' ?
                              <div><span className='mdi mdi-checkbox-marked-circle globel-add-style' />
                                <span className='globel-add-style m-2'>{t(`added`)}</span></div>
                              :
                              <div>
                                <span className='mdi mdi-checkbox-blank-circle-outline globel-remove-style' />
                                <span className='globel-remove-style m-2'>{t(`removed`)}</span>
                              </div>
                            }
                          </span>
                          <span
                            className={`mdi mdi-chevron-${expanded === item.dfmFieldId ?
                              'up' :
                              'down'
                              }`}
                          />
                        </div>
                        <span>
                          {(item.createdByName) || 'N/A'}
                        </span>
                        {(item.createdOn &&
                          moment(item.createdOn).format('DD/MM/YYYY - hh:mm A')) ||
                          'N/A'}

                      </div>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Tables
                        data={item.response || []}
                        headerData={[
                          {
                            id: 1,
                            label: 'moduleName',
                            component: (row) => <span>{row && row.moduleName}</span>
                          },
                          {
                            id: 2,
                            label: 'permessionName',
                            component: (row) => <span>{row && row.permessionName}</span>
                          },
                        ]}
                        itemsPerPage={item.response && item.response.length || 0}
                        translationPath={translationPath}
                        parentTranslationPath={parentTranslationPath}
                        totalItems={item.response && item.response.length || 0}
                        actionsOptions={[]}
                        defaultActions={[]}
                      />
                    </AccordionDetails>
                  </Accordion>

                ))}
              </div>
              <div className='pagination-history-wrapper-roles'>
                <PaginationComponent
                  pageIndex={filter.pageIndex}
                  pageSize={filter.pageSize}
                  totalCount={permissionHistoryResult.totalCount}
                  onPageIndexChanged={onPageIndexChanged}
                  onPageSizeChanged={onPageSizeChanged}
                />
              </div>
            </>

          )}
          isOpen={isOpenHistoryDialog}
          saveType='button'
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          onCloseClicked={() => setIsOpenHistoryDialog(false)}
        />)}
    </div>
  );
};
RolesNameComponent.propTypes = {
  rolesId: PropTypes.string,
  schema: PropTypes.instanceOf(Object).isRequired,
  state: PropTypes.instanceOf(Object).isRequired,
  onStateChanged: PropTypes.func.isRequired,
  isSubmitted: PropTypes.bool.isRequired,
  parentTranslationPath: PropTypes.string.isRequired,
  translationPath: PropTypes.string.isRequired,
};
RolesNameComponent.defaultProps = {
  rolesId: null,
};
