import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  AutocompleteComponent,
  CheckboxesComponent,
  Spinner,
  Tables,
} from '../../../../Components';
import '../style/PublishUnitApprovals.scss';
import { UnitApprovalsForm } from './UnitApprovalsForm';
import { ButtonBase } from '@material-ui/core';
import { GetAllUnitApprovelSettings } from '../../../../Services/UnitApprovalServices/UnitApprovalServices';
import { unitApprovalHeaderData } from './HeaderDataTableUnitApprovals';
import { TableActions } from '../../../../Enums';
import { DeleteDialogUnitPublishUnit } from './DeleteDialogUnitPublishUnit';

export const PublishUnitApprovals = ({
  parentTranslationPath,
  translationPath,
  data,
  setData,
  isLoading,
  reducer,
}) => {
  const [loading, setLoading] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [unitApprovalData, setUnitApprovalData] = useState([]);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [activeItem, setActiveItem] = useState(null);
  const { t } = useTranslation(parentTranslationPath);

  const addNewHandler = () => {
    setIsDialogOpen(true);
  };

  const getAllUnitApprovalSettings = async () => {
    setLoading(true);
    const res = await GetAllUnitApprovelSettings({
      pageIndex: 1,
      pageSize: 25,
      approvalSettingType: 1,
    });
    if (!(res && res.status && res.status !== 200)) {
      setUnitApprovalData(res?.result);
    }
    setLoading(false);
  };

  const getActionTable = () => {
    const list = [];

    list.push({
      enum: TableActions.editText.key,
      isDisabled: false,
      externalComponent: null,
    });
    list.push({
      enum: TableActions.deleteText.key,
      isDisabled: false,
      externalComponent: null,
    });
    return list;
  };

  const tableActionClicked = useCallback(
    (actionEnum, item, focusedRow, event) => {
      event.stopPropagation();
      event.preventDefault();

      if (actionEnum === TableActions.editText.key) {
        setIsDialogOpen(true);
        setActiveItem(item);
      }
      if (actionEnum === TableActions.deleteText.key) {
        setIsDeleteDialogOpen(true);
        setActiveItem(item);
      }
    },
    []
  );

  const reloadData = () => {
    getAllUnitApprovalSettings();
  };

  useEffect(() => {
    getAllUnitApprovalSettings();
  }, []);

  return (
    <div className='publish-unit-approvals-wrapper propx-view propx-bg'>
      <Spinner isActive={loading} isAbsolute />
      <div className='d-flex-column'>
        <div className='header-section'>
          <ButtonBase className='btns theme-solid px-3' onClick={addNewHandler}>
            <span className='mdi mdi-plus' />
            {t(`${translationPath}add-new`)}
          </ButtonBase>
        </div>
      </div>
      <div className='p-3'>
        <Tables
          data={unitApprovalData || []}
          headerData={unitApprovalHeaderData || []}
          defaultActions={getActionTable()}
          actionsOptions={{
            onActionClicked: tableActionClicked,
          }}
          translationPath={translationPath}
          parentTranslationPath={parentTranslationPath}
          activePage={0}
          itemsPerPage={100}
        />
      </div>
      {isDialogOpen && (
        <UnitApprovalsForm
          activeItem={activeItem}
          isDialogOpen={isDialogOpen}
          translationPath={translationPath}
          parentTranslationPath={parentTranslationPath}
          setIsDialogOpen={setIsDialogOpen}
          onSave={() => {
            setIsDialogOpen(false);
            setActiveItem(null);
            reloadData();
          }}
          isOpenChanged={() => {
            setIsDialogOpen(false);
            setActiveItem(null);
          }}
          data={data}
          setData={setData}
          isLoading={isLoading}
          reducer={reducer}
        />
      )}
      {isDeleteDialogOpen && (
        <DeleteDialogUnitPublishUnit
          activeItem={activeItem}
          open={isDeleteDialogOpen}
          reloadData={reloadData}
          translationPath={translationPath}
          parentTranslationPath={parentTranslationPath}
          close={() => {
            setIsDeleteDialogOpen(false);
            setActiveItem(null);
          }}
          onSave={() => {
            setIsDeleteDialogOpen(false);
            setActiveItem(null);
          }}
        />
      )}
    </div>
  );
};
