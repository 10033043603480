import React from "react";
import PropTypes from "prop-types";
import { Box, Typography } from "@material-ui/core";
import { getDownloadableLink } from "../../../../../Helper";

// styles
import useStyles from "./styles";

function InfoItem({ label, value, countryCode, createdBy }) {
  const styles = useStyles({ countryCode });

  return (
    <Box className={styles.infoItemWrapper}>
      <Typography className={styles.label}>{label}</Typography>
      <Box display="flex" alignItems="center">
        {countryCode && <Box className={styles.customFlag} />}
        {!!createdBy && (
          <span className={styles.createdByImgWrapper}>
            <Box
              className={styles.createdByImg}
              style={{
                backgroundImage: `url(${getDownloadableLink(createdBy)})`,
              }}
            />
          </span>
        )}
        <Typography className={styles.value}>{value}</Typography>
      </Box>
    </Box>
  );
}

InfoItem.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  countryCode: PropTypes.string,
  createdBy: PropTypes.string,
};

InfoItem.defaultProps = {
  label: "",
  value: "",
  countryCode: "",
};

export default InfoItem;
