import {
  propertyDetailsGet
} from '../Services';

export async function UnitRefNumberRule(item, value, itemList, setData, formType, values) {
  
  const propertyNameIndex = itemList.findIndex((f) => f.field.id.toLowerCase() === 'property_name');
  const countryIndex = itemList.findIndex((f) => f.field.id.toLowerCase() === 'country');
  const cityIndex = itemList.findIndex((f) => f.field.id.toLowerCase() === 'city');
  const districtIndex = itemList.findIndex((f) => f.field.id.toLowerCase() === 'district');
  const communityIndex = itemList.findIndex((f) => f.field.id.toLowerCase() === 'community');
  const subCommunityIndex = itemList.findIndex((f) => f.field.id.toLowerCase() === 'sub_community');
  if (!value) {
    if (countryIndex !== -1)
      itemList[countryIndex].data.isReadonly = !!(values && values.property_name);
    if (cityIndex !== -1)
      itemList[cityIndex].data.isReadonly = !!(values && values.property_name);

    if (districtIndex !== -1)
      itemList[districtIndex].data.isReadonly = !!(values && values.property_name);

    if (communityIndex !== -1)
      itemList[communityIndex].data.isReadonly = !!(values && values.property_name);

    if (subCommunityIndex !== -1)
      itemList[subCommunityIndex].data.isReadonly = !!(values && values.property_name);
    return;
  }
  const res = value.propertyId ? await propertyDetailsGet({ id: +value.propertyId }) : null;
  if (res) {
    if (propertyNameIndex !== -1) {
      const property = res.property ? { name: res.property.property_name, id: res.propertyId, city: (res.property.city && res.property.city.lookupItemName) } : null;
      if (formType === 1)
        setData(itemList[propertyNameIndex].field.id, property);
      else
        setData(property, 0, 'property_name');
    }
    if (countryIndex !== -1) {
      const country = (res.property && res.property.country);
      itemList[countryIndex].data.isReadonly = true;

      if (formType === 1)
        setData(itemList[countryIndex].field.id, country);
      else
        setData(country, 0, 'country');
    }
    if (cityIndex !== -1) {
      const city = (res.property && res.property.city) || null;
      itemList[cityIndex].data.isReadonly = true;

      if (formType === 1)
        setData(itemList[cityIndex].field.id, city);
      else
        setData(city, 0, 'city');
    }
    if (districtIndex !== -1) {
      itemList[districtIndex].data.isReadonly = true;
      const district = (res.property && res.property.district) || null;
      if (formType === 1)
        setData(itemList[districtIndex].field.id, district);
      else
        setData(district, 0, 'district');
    }

    if (communityIndex !== -1) {
      itemList[communityIndex].data.isReadonly = true;
      const community = (res.property && res.property.community) || null;
      if (formType === 1)
        setData(itemList[communityIndex].field.id, community);
      else
        setData(community, 0, 'community');
    }

    if (subCommunityIndex !== -1) {
      itemList[subCommunityIndex].data.isReadonly = true;
      const subCommunity = (res.property && res.property.sub_community) || null;
      if (formType === 1)
        setData(itemList[subCommunityIndex].field.id, subCommunity);
      else
        setData(subCommunity, 0, 'sub_community');
    }
  }
}
