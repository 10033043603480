import { HttpServices } from "../../Helper";
import { config } from "../../config";

export const GetShareSystems = async () => {
  const result = await HttpServices.get(
    `${config.server_address}/ShareEntity/ShareUnit/GetShareSystems`
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};
export const SearchBranchesForShareEntity = async ({ body }) => {
  const result = await HttpServices.post(
    `${config.server_address}/ShareEntity/Branch/SearchBranchesForShareEntity`,
    body
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

export const SearchApplicationUsersForShareEntity = async ({ body }) => {
  const result = await HttpServices.post(
    `${config.server_address}/ShareEntity/User/SearchApplicationUsersForShareEntity`,
    body
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

export const CreateSavedShareUnitCriteriaApi = async (body) => {
  try {
    const result = await HttpServices.post(
      `${config.server_address}/ShareEntity/SavedShareUnit/CreateSavedShareUnitCriteria`,
      body
    );
    return result;
  } catch (error) {
    return error.response;
  }
};

export const ListSavedShareUnitCriteria = async (body) => {
  try {
    const result = await HttpServices.post(
      `${config.server_address}/ShareEntity/SavedShareUnit/ListSavedShareUnitCriteria`,
      body
    );
    return result;
  } catch (error) {
    return error.response;
  }
};

export const UpdateSavedShareUnitCriteria = async (body) => {
  try {
    const result = await HttpServices.post(
      `${config.server_address}/ShareEntity/SavedShareUnit/UpdateSavedShareUnitCriteria`,
      body
    );
    return result;
  } catch (error) {
    return error.response;
  }
};

export const AddSavedShareUnitsToCriteria = async (body) => {
  try {
    const result = await HttpServices.post(
      `${config.server_address}/ShareEntity/SavedShareUnit/AddSavedShareUnitsToCriteria`,
      body
    );
    return result;
  } catch (error) {
    return error.response;
  }
};

export const RemoveSavedShareUnitsFromCriteria = async (body) => {
  try {
    const result = await HttpServices.post(
      `${config.server_address}/ShareEntity/SavedShareUnit/RemoveSavedShareUnitsFromCriteria`,
      body
    );
    return result;
  } catch (error) {
    return error.response;
  }
};

export const DeleteSavedShareUnitCriteria = async (savedShareUnitCriteriaId) => {
  const result = await HttpServices.delete(
    `${config.server_address}/ShareEntity/SavedShareUnit/DeleteSavedShareUnitCriteria/${savedShareUnitCriteriaId}`
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};
