import moment from 'moment';

export const VisaRules = (newValue, item, onValueOrItemChanged, itemList) => {
  if (item.field.id === 'visa_issue_date') {
    const i = itemList.indexOf(
      itemList.find((f) => f.field.id === 'visa_expiry_date')
    );
    if (i !== -1) {
      itemList[i].data.minDate = moment(newValue).add(1, 'days');
      onValueOrItemChanged(null, 0, 'visa_expiry_date');
    }
  }
};
