import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { TableColumnsFilterComponent, Tables } from "../../../../../Components";
import {
  ColumnsFilterPagesEnum,
  FormsIdsEnum,
  TableFilterTypesEnum,
  HighlightRowKeys,
} from "../../../../../Enums";
import { GetAllFormFieldsByFormId } from "../../../../../Services";
import { TableColumnsFilterActions } from "../../../../../store/TableColumnsFilter/TableColumnsFilterActions";
import { LeadsSalesTableHeaderData } from "./LeadsSalesTableHeaderData";

export const LeadsSalesTableComponent = ({
  detailsLeadsList,
  tableActionClicked,
  onPageIndexChanged,
  onPageSizeChanged,
  filter,
  parentTranslationPath,
  focusedRowChanged,
  checkedCardsIds,
  getIsSelected,
  getIsDisabled,
  onSelectClicked,
  activeSelectedAction,
  leadsTableFilter,
  onFilterValuesChanged,
  defaultActions,
  setCheckedCards,
  setOrderBy,
  isClearFiltersClicked,
  setIsClearFiltersClicked,
  displyOpenFileButton,
  displyMatchButton,
  localStorageKey,
  isDeactivationView,
}) => {
  const dispatch = useDispatch();
  const [allFormFields, setAllFormFields] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [tableColumns, setTableColumns] = useState([]);
  const [tableFilterData, setTableFilterData] = useState([]);
  const [sortBy, setSortBy] = useState(null);
  const [selectedTableFilterColumns, setSelectedTableFilterColumns] = useState(
    LeadsSalesTableHeaderData.filter((item) => item.isDefaultFilterColumn).map(
      (column) => column.id
    )
  );

  const tableColumnsFilterResponse = useSelector(
    (state) => state.TableColumnsFilterReducer
  );

  const getAllFormFieldsByFormId = useCallback(async () => {
    setIsLoading(true);
    Promise.all([
      await GetAllFormFieldsByFormId(FormsIdsEnum.leadsOwner.id),
      await GetAllFormFieldsByFormId(FormsIdsEnum.leadsSeeker.id),
    ])
      .then((result) => {
        if (Array.isArray(result[0]) && Array.isArray(result[1])) {
          const concantinateFields = result[0]
            .concat(result[1])
            .filter(
              (field, index, array) =>
                array.findIndex(
                  (element) => element.formFieldKey === field.formFieldKey
                ) === index
            );
          const list = concantinateFields.filter(
            (e) =>
              e.formFieldName !== "fitting_and_fixtures" &&
              e.formFieldName !== "view" &&
              e.formFieldName !== "developer" &&
              e.formFieldName !== "bathrooms" &&
              e.formFieldName !== "bedrooms" &&
              e.formFieldName !== "size_sqft" &&
              e.formFieldName !== "budget" &&
              e.formFieldName !== "propertyunit_type"
          );
          setAllFormFields(list);
        } else setAllFormFields([]);
        setIsLoading(false);
      })
      .catch(() => {
        setAllFormFields([]);
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    getAllFormFieldsByFormId();
  }, [getAllFormFieldsByFormId]);

  useEffect(() => {
    if (
      tableColumnsFilterResponse &&
      tableColumnsFilterResponse[ColumnsFilterPagesEnum.leadsSales.key]
    ) {
      setSelectedTableFilterColumns(
        tableColumnsFilterResponse[ColumnsFilterPagesEnum.leadsSales.key]
      );
    }
  }, [tableColumnsFilterResponse]);

  useEffect(() => {
    setTableColumns([
      ...LeadsSalesTableHeaderData.filter(
        (item) =>
          selectedTableFilterColumns.findIndex(
            (element) => element === item.id
          ) !== -1
      ),
      ...allFormFields
        .filter(
          (item) =>
            selectedTableFilterColumns.findIndex(
              (element) => element === item.formFieldId
            ) !== -1
        )
        .map((field) => ({
          id: field.formFieldId,
          key: field.formFieldKey,
          isDate: field.uiWidgetType === "alt-date",
          label:
            (field.formFieldTitle === "Lead Auditor"
              ? (field.formFieldTitle = "Lead Owner")
              : field.formFieldTitle &&
                field.formFieldTitle.replace("*", "")) || "",
          input: field.displayPath || undefined,
          searchableKey: field.searchableKey || undefined,
          isSortable: true,
          isNumber:
            (field.propertyJson &&
              JSON.parse(field.propertyJson).schema &&
              field.propertyJson &&
              JSON.parse(field.propertyJson).schema.specialKey ===
                "currency") ||
            (field.propertyJson &&
              JSON.parse(field.propertyJson).schema &&
              field.propertyJson &&
              JSON.parse(field.propertyJson).schema.specialKey === "decimal") ||
            (field.propertyJson &&
              JSON.parse(field.propertyJson).schema &&
              field.propertyJson &&
              JSON.parse(field.propertyJson).schema.specialKey === "size"),
        })),
    ]);
  }, [allFormFields, selectedTableFilterColumns]);

  useEffect(() => {
    setTableFilterData(
      tableColumns.map((column) => ({
        key: column.key || column.fieldKey || column.id,
        filterType:
          (column.isDate && TableFilterTypesEnum.datePicker.key) ||
          (column.isNumber && TableFilterTypesEnum.numberInput.key) ||
          TableFilterTypesEnum.textInput.key,
        isHiddenFilter: column.isHiddenFilter,
        isHidden: column.isHidden,
        textInputType: column.textInputType,
        textInputMax: column.textInputMax,
        textInputMin: column.textInputMin,
        searchableKey: column.searchableKey || column.input,
        displayPath:
          (column.key && column.input) ||
          (column.fieldKey &&
            allFormFields &&
            allFormFields.findIndex(
              (item) => item.formFieldKey === column.fieldKey
            ) !== -1 &&
            allFormFields.find((item) => item.formFieldKey === column.fieldKey)
              .displayPath) ||
          undefined,
      }))
    );
  }, [allFormFields, tableColumns]);

  const getIsSelectedAll = useCallback(() => {
    const returnSelect =
      (checkedCardsIds &&
        detailsLeadsList.result.findIndex(
          (item) => !checkedCardsIds.includes(item.id)
        ) === -1) ||
      false;
    return returnSelect;
  });

  const onSelectAllClicked = () => {
    const cardItmes = [];
    const isSelectOneOnly = activeSelectedAction === "qualify-lead";
    if (isSelectOneOnly) return;

    if (!getIsSelectedAll()) {
      if (detailsLeadsList && detailsLeadsList.result) {
        detailsLeadsList.result.map((item) => {
          if (!getIsSelected(item)) {
            checkedCardsIds.push(item.id);
            cardItmes.push({ ...item });
          }
        });
      }
    } else {
      setCheckedCards([]);
      detailsLeadsList.result.map((item) => {
        if (getIsSelected(item)) {
          const isSelectedIndex = checkedCardsIds.findIndex(
            (element) => element === item.id
          );
          if (isSelectedIndex !== -1)
            checkedCardsIds.splice(isSelectedIndex, 1);
        }
      });
    }
    setCheckedCards(cardItmes);
  };

  useEffect(() => {
    if (sortBy) {
      setOrderBy((item) => ({
        ...item,
        filterBy:
          sortBy.filterBy &&
          sortBy.filterBy[0].toLowerCase() + sortBy.filterBy.slice(1),
        orderBy: sortBy.orderBy,
        fieldType: sortBy.fieldType,
      }));
    }
  }, [sortBy]);

  // useEffect(() => {
  //   let list = [];
  //   defaultActions.map((item) => {
  //     if (item.enum === 'openFile' && displyOpenFileButton)
  //       list.push(item);

  //       if(item.enum === 'addActivity' &&  returnPropsByPermissions(LeadsSalesPermissions.AddNewActivity.permissionsId) )
  //       list.push(item);
  //    });

  //   setTableActionsWithPermissions(list);

  // }, [displyOpenFileButton , defaultActions]);

  return (
    <div className="w-100 px-3">
      <TableColumnsFilterComponent
        columns={LeadsSalesTableHeaderData.filter((item) => !item.isHidden)
          .concat(
            allFormFields.filter(
              (item) =>
                LeadsSalesTableHeaderData.findIndex(
                  (element) => element.fieldKey === item.formFieldKey
                ) === -1
            )
          )
          .map((item) => ({
            key: item.formFieldId || item.id,
            value:
              (item.formFieldTitle === "Lead Auditor"
                ? (item.formFieldTitle = "Lead Owner")
                : item.formFieldTitle &&
                  item.formFieldTitle.replace("*", "")) || item.label,
          }))}
        isLoading={isLoading}
        selectedColumns={selectedTableFilterColumns}
        onSelectedColumnsChanged={(newValue) => {
          setSelectedTableFilterColumns(newValue);
          let localTableColumnsFilterResponse = tableColumnsFilterResponse;
          if (localTableColumnsFilterResponse)
            localTableColumnsFilterResponse[
              ColumnsFilterPagesEnum.leadsSales.key
            ] = newValue;
          else {
            localTableColumnsFilterResponse = {
              [ColumnsFilterPagesEnum.leadsSales.key]: newValue,
            };
          }
          dispatch(
            TableColumnsFilterActions.TableColumnsFilterRequest(
              localTableColumnsFilterResponse
            )
          );
        }}
        parentTranslationPath={parentTranslationPath}
        translationPath=""
      />

      <Tables
        data={detailsLeadsList.result}
        selectAllOptions={
          ((activeSelectedAction === "merge" ||
            activeSelectedAction === "close-leads" ||
            activeSelectedAction === "clone-leads" ||
            activeSelectedAction === "reassign-seeker-leads" ||
            activeSelectedAction === "reassign-owner-leads" ||
            activeSelectedAction === "send-to-rotation" ||
            activeSelectedAction === "send-to-owner-rotation" ||
            activeSelectedAction === "qualify-lead" ||
            activeSelectedAction === "unqualified-leads" ||
            activeSelectedAction === "add-to-favourite" ||
            activeSelectedAction === "send-to-leads-pool" ||
            isDeactivationView) && {
            selectedRows: checkedCardsIds,
            getIsSelected,
            disabledRows: [],
            getIsDisabled,
            withCheckAll: true,
            onSelectAllClicked,
            isSelectAll: getIsSelectedAll(),
            onSelectClicked,
          }) ||
          undefined
        }
        headerData={tableColumns}
        filterValues={leadsTableFilter}
        onFilterValuesChanged={onFilterValuesChanged}
        filterData={tableFilterData}
        isWithFilter
        onPageIndexChanged={onPageIndexChanged}
        onPageSizeChanged={onPageSizeChanged}
        setSortBy={setSortBy}
        actionsOptions={{
          onActionClicked: tableActionClicked,
        }}
        defaultActions={defaultActions}
        itemsPerPage={filter.pageSize}
        activePage={filter.pageIndex}
        parentTranslationPath={parentTranslationPath}
        focusedRowChanged={focusedRowChanged}
        totalItems={detailsLeadsList ? detailsLeadsList.totalCount : 0}
        isClearFiltersClicked={isClearFiltersClicked}
        setIsClearFiltersClicked={setIsClearFiltersClicked}
        isLoading={isLoading}
        localStorageKey={localStorageKey}
        highlightedRowKey={HighlightRowKeys.LeadSale.key}
      />
    </div>
  );
};

LeadsSalesTableComponent.propTypes = {
  detailsLeadsList: PropTypes.instanceOf(Object).isRequired,
  tableActionClicked: PropTypes.func.isRequired,
  onPageIndexChanged: PropTypes.func.isRequired,
  onPageSizeChanged: PropTypes.func.isRequired,
  filter: PropTypes.instanceOf(Object).isRequired,
  parentTranslationPath: PropTypes.string.isRequired,
  focusedRowChanged: PropTypes.func.isRequired,
  checkedCardsIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  getIsSelected: PropTypes.func.isRequired,
  getIsDisabled: PropTypes.func.isRequired,
  onSelectClicked: PropTypes.func.isRequired,
  activeSelectedAction: PropTypes.string.isRequired,
  onFilterValuesChanged: PropTypes.func.isRequired,
  leadsTableFilter: PropTypes.instanceOf(Object),
  setCheckedCards: PropTypes.func.isRequired,
  displyOpenFileButton: PropTypes.bool,
  displyMatchButton: PropTypes.bool,
};
LeadsSalesTableComponent.defaultProps = {
  leadsTableFilter: undefined,
  displyOpenFileButton: false,
  displyMatchButton: false,
};
