import React from "react";

function FilterEndWithIcon({ fill, ...restProps }) {
  return (
    <svg
      viewBox="0 0 16 16"
      fill={fill ?? ""}
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <path
        d="M9.493 1.358A.66.66 0 0 0 9.008 2c0 .27.138.483.392.603.127.06.16.063.7.063l.567.001V13.33l-.594.008c-.548.008-.601.013-.7.066a.658.658 0 0 0-.365.596c0 .27.138.483.392.603l.133.063h3.6l.134-.063a.642.642 0 0 0 .392-.603.657.657 0 0 0-.366-.596c-.098-.053-.151-.058-.7-.066L12 13.33v-1.324l.807-.013c.707-.012.834-.021 1.03-.071a1.926 1.926 0 0 0 1.423-1.432c.06-.237.06-.237.06-2.477 0-2.089-.003-2.254-.05-2.453-.145-.624-.492-1.069-1.051-1.346C13.894 4.053 13.491 4 12.58 4H12V2.667l.567-.001c.54 0 .573-.003.7-.063A.642.642 0 0 0 13.659 2a.657.657 0 0 0-.366-.596c-.106-.057-.116-.057-1.92-.062-.997-.003-1.843.005-1.88.016M2.499 4.03c-.521.05-.898.217-1.239.55-.266.26-.436.574-.53.98-.047.199-.05.364-.05 2.453 0 2.24 0 2.24.06 2.477.077.299.179.513.36.749.254.331.624.57 1.063.686l.224.059 3.18.009c2.135.006 3.213 0 3.282-.019.15-.04.31-.167.396-.313.064-.109.075-.156.075-.328 0-.169-.011-.218-.072-.322a.762.762 0 0 0-.333-.298c-.058-.025-.79-.036-3.248-.046l-3.174-.014-.126-.062a.639.639 0 0 1-.321-.364c-.049-.16-.047-4.3.002-4.463a.716.716 0 0 1 .369-.381c.08-.026.802-.037 3.276-.048l3.174-.015.127-.075c.504-.296.421-1.041-.134-1.208-.1-.031-.649-.036-3.124-.033-1.652.002-3.108.013-3.237.026m11.084 1.356c.139.042.325.233.369.378.049.163.051 4.303.002 4.463a.639.639 0 0 1-.321.364c-.122.06-.153.063-.88.071l-.753.01V5.327l.74.014c.453.008.78.026.843.045"
        fill={fill ?? ""}
      />
    </svg>
  );
}

export default FilterEndWithIcon;
