

import React, { useState ,  useEffect , useReducer  } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Joi from 'joi';
import {
  DialogActions, DialogContent, DialogTitle, Dialog
} from '@material-ui/core';
import {
  Inputs,
  Spinner, 
} from '../../../../../Components';
import { showSuccess ,  showError } from '../../../../../Helper' ; 
import { CreatePortalAccountAPI  , UpdatePortalAccountAPI } from '../../../../../Services' ; 
import { PortalAccountContentDialog   } from './PortalAccountManagementDialog/PortalAccountContentDialog' ; 
import {  PortalAccountFooterDialog  } from './PortalAccountManagementDialog/PortalAccountFooterDialog' ; 


export const PortalAccountManagementDialog = ({
   parentTranslationPath ,
   translationPath ,
   isOpen ,
   onClose ,
   onSave  , 
   editPortalAccount  , 
   isLoading , 
   
  }) => {

  const { t } = useTranslation(parentTranslationPath);
  const [isSaveLoading , setIsSaveLoading] = useState(false) ; 
  const [isSubmitted , setIsSubmitted] = useState(false) ;

  const reducer = (state, action) => {
    if (action.id !== 'edit') return { ...state, [action.id]: action.value };
    if (action.id === 'edit') {
      return {
        ...action.value,
      };
    }
    return undefined;
  }

  const [state, setState] = useReducer(reducer,
  {
    accountName : null , 
    portalId: null  ,
    status: false ,
    branchIds: [] ,   
  });

  const [selected , setSelected] = useState({
    portal : null , 
    listOfPortalAccountBranches : [] 
    
   })


  const schema = Joi.object({
    accountName: Joi.string()
      .required()
      .messages({
        'string.base': t(`${translationPath}accountName-is-required`),
        'string.empty': t(`${translationPath}accountName-is-required`),
      }),
      portalId : Joi.number()
       .required()
       .messages({
        'number.base': t(`${translationPath}portal-is-required`),
        'number.empty': t(`${translationPath}portal-is-required`),
      }),
  })
    .options({
      abortEarly: false,
      allowUnknown: true,
    })
    .validate(state);


  const saveHandler = async () => {
    setIsSubmitted(true);
    setIsSaveLoading(true) ; 
    if(schema.error)
    {
      showError(t('please-fill-all-Required-field'));
      setIsSaveLoading(false) ; 
      return ;

    }
    const res = !editPortalAccount ?  await CreatePortalAccountAPI(state) :
                await UpdatePortalAccountAPI( state.portalAccountId ,state);
   
    if(res && res.StatusCode === 500 && res.Message )
    {
      const  backendError = res.Message ; 
      
      const errorMessage  =  !editPortalAccount ?
       backendError.replace('/CrmDfm/PortalAccount/CreatePortalAccount : ', '') :
       backendError.replace('/CrmDfm/PortalAccount/UpdatePortalAccount : ', '') ; 

      showError(t(errorMessage)) ; 
      setIsSaveLoading(false) ; 
      return ; 

    }
    else if(res)
    {
      if(!editPortalAccount )
      showSuccess(t(`${translationPath}add-portal-account-successfully`));
      else 
      showSuccess(t(`${translationPath}edit-portal-account-successfully`));
      onSave() ;

    }
    setIsSaveLoading(false) ; 

  };

  useEffect(() => {
    if (editPortalAccount === undefined || editPortalAccount === null) return;
    const currntState  =
    {   portalAccountId :(editPortalAccount  && editPortalAccount.portalAccountId) , 
        portalId  :editPortalAccount  && editPortalAccount.portalDto &&  editPortalAccount.portalDto.portalId , 
        accountName	:editPortalAccount && editPortalAccount.portalAccountName , 
        status	:editPortalAccount && editPortalAccount.status , 
        branchIds:  (editPortalAccount && editPortalAccount.listOfPortalAccountBranches &&  editPortalAccount.listOfPortalAccountBranches.map((e) => e.branchId))
        
    }
    setState({ id: 'edit', value: currntState });
    setSelected((item)=> ({...item , portal : (editPortalAccount.portalDto) || null  ,  listOfPortalAccountBranches : (editPortalAccount.listOfPortalAccountBranches)})) ;
     
  }, [editPortalAccount]);


  return (
    <div>
      <Dialog
        open={isOpen}
        fullWidth
        onClose={() => {
          onClose();
        }}
        className='dialog-wrapper'
        maxWidth='sm'
        disableBackdropClick
      >
      <Spinner isActive={((isLoading && isLoading.portalAccount) ||  isSaveLoading)} isAbsolute />
          <DialogTitle id='alert-dialog-slide-title'>
            <span>
              {!editPortalAccount ?
                t(`${translationPath}add-portal-account`) :
                t(`${translationPath}edit-portal-account`)}
            </span>
          </DialogTitle>
          <DialogContent>
            <PortalAccountContentDialog
              parentTranslationPath={parentTranslationPath}
              translationPath={translationPath}
              state={state} 
              setState={setState}
              schema={schema}
              isSubmitted={isSubmitted}
              selected={selected}
              setSelected={setSelected}
              editPortalAccount={editPortalAccount}
            />
          </DialogContent>
          <DialogActions>
            <PortalAccountFooterDialog
              parentTranslationPath={parentTranslationPath}
              translationPath={translationPath}
              onClose={onClose}
              onSave ={saveHandler}
            />  
          </DialogActions>
      </Dialog>
    </div>
  );

};

PortalAccountManagementDialog.propTypes = {
  parentTranslationPath: PropTypes.string.isRequired,
  translationPath: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  editPortalAccount: PropTypes.instanceOf(Object),
  isLoading :PropTypes.instanceOf(Object),
};
PortalAccountManagementDialog.defaultProps = {
  editPortalAccount: undefined ,
  isLoadingPolicy : undefined , 

};

