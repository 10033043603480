import { HttpServices } from '../Helper';
import { config } from '../config';

export const GetUnitMPI = async (data) => {
  try {
    const response = await HttpServices.post(
      `${config.server_address}/CrmDfm/Unit/GetUnitMPI`,
      data
    );
    return response;
  } catch (error) {
    return error.response;
  }
};
