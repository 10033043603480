

import { HttpServices } from '../../Helper';
import { config } from '../../config';


export const CreatePropertyUnitModel = async (body) => 
{
    const result = await HttpServices.post(
        `${config.server_address}/CrmDfm/PropertyUnitModel/CreatePropertyUnitModel`, body)
        .then((data) => data)
        .catch((error) => error.response);
    return result;
};

export const UpdatePropertyUnitModelAPI = async ( propertyUnitModelId  , body) => 
{
    const result = await HttpServices.put(
        `${config.server_address}/CrmDfm/PropertyUnitModel/UpdatePropertyUnitModel/${propertyUnitModelId}`, body
    )
        .then((data) => data)
        .catch((error) => error.response);
    return result;
};

export const GetPropertyUnitModelById = async (propertyUnitModelId) => {
    const result = await HttpServices.get(
        `${config.server_address}/CrmDfm/PropertyUnitModel/GetPropertyUnitModelById/${propertyUnitModelId}`)
        .then((data) => data)
        .catch((error) => error.response);
    return result;
};

export const GetAllPropertyUnitModelsAPI = async ({propertyId, pageIndex, pageSize}) => {
    const result = await HttpServices.get(
        `${config.server_address}/CrmDfm/PropertyUnitModel/GetAllPropertyUnitModels/${propertyId}/${pageIndex}/${pageSize}`)
        .then((data) => data)
        .catch((error) => error.response);
    return result;
};

export const DeletePropertyUnitModelAPI = async (propertyUnitModelId) => {
    const result = await HttpServices.delete(
        `${config.server_address}/CrmDfm/PropertyUnitModel/DeletePropertyUnitModel/${propertyUnitModelId}`)
        .then((data) => data)
        .catch((error) => error.response);
    return result;
};







