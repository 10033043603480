import React from "react";
import clsx from "clsx";
import moment from "moment";
import PropTypes from "prop-types";
import { Box, Typography } from "@material-ui/core";
import LoadingSpinner, { LoadingSpinnerSize, LoadingSpinnerStyle } from "../../../LoadingSpinner";
import { CalendarV2 } from "../../../CalendarComponent/CalendarV2";
import { useSelectedTheme, useTranslate } from "../../../../../Hooks";
import { CustomBadge } from "../../../CustomBadge";

// styles
import useStyles from "./styles";

function ContactProfileSchedule({
  isActivityLoading,
  activitiesDates,
  activityDate,
  setActivityDate,
}) {
  const styles = useStyles();

  const {
    theme: { palette },
  } = useSelectedTheme();

  const { translate } = useTranslate("NewContactsView");

  const filteredEvents = activitiesDates.events.filter((event) => {
    const eventDate = moment(event.date).format("YYYY-MM-DD");
    const selectedDate = moment(activityDate).format("YYYY-MM-DD");
    return eventDate === selectedDate;
  });

  return (
    <Box className={styles.scheduleContainer}>
      {!isActivityLoading ? (
        <Box>
          <CalendarV2
            selectedDateChanged={(newDate) =>
              setActivityDate(new Date(newDate))
            }
            selectedDate={activityDate}
            withNumber
            activities={activitiesDates.events}
            events={{ selectedDays: activitiesDates.selectedDates }}
            wrapperClasses="transparent-calender calendars-new-without-dropdown"
          />

          {!!filteredEvents.length && (
            <Box className={styles.heading}>
              <Typography className={styles.date}>
                {moment(activityDate).format("D MMM YYYY")}
              </Typography>

              <CustomBadge
                label={`${filteredEvents.length} ${translate("activities")}`}
                SizeVariant={"small"}
                BackgroundColor={palette.utility["brand_50"]}
                BorderColor={palette.utility["brand_200"]}
                Color={palette.utility["brand_700"]}
              />
            </Box>
          )}
          <Box>
            {filteredEvents?.map((event, idx) => {
              return (
                <Box className={styles.eventContentWrapper} key={idx}>
                  <Typography
                    variant="body1"
                    className={clsx(styles.eventContent, styles.eventTitle)}
                  >
                    {event.activity?.activityTypeName}
                  </Typography>

                  <Typography variant="body1" className={styles.eventContent}>
                    {translate("lead_name")}{" "}
                    {event.activity?.relatedLeadFullName}
                  </Typography>

                  <Typography variant="body1" className={styles.eventContent}>
                    {translate("lead_id")} {event.activity?.relatedLeadNumberId}
                  </Typography>

                  <Typography variant="body1" className={styles.eventContent}>
                    {translate("created_by")} {event.activity?.createdByName}
                  </Typography>

                  <Typography variant="body1" className={styles.eventContent}>
                    {translate("activity_time")}{" "}
                    {moment(event.activity?.activityDate).format("hh:mm A")}
                  </Typography>

                  <Typography variant="body1" className={styles.eventContent}>
                    {translate("assign_to")} {event.activity?.assignedTo}
                  </Typography>
                </Box>
              );
            })}
          </Box>
        </Box>
      ) : (
        <LoadingSpinner
          sizeVariant={LoadingSpinnerSize.medium}
          styleVariant={LoadingSpinnerStyle.primary}
        />
      )}
    </Box>
  );
}

ContactProfileSchedule.propTypes = {};

ContactProfileSchedule.defaultProps = {};

export default ContactProfileSchedule;
