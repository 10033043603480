import React, { useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from 'prop-types';
import { Inputs, AutocompleteComponent } from "../../../../../../Components";
import { showWarn } from "../../../../../../Helper";
import { MapDialog } from "../../../../FormBuilder/Dialogs/MapDialog";
import {
  lookupItemsGet,
  lookupItemsGetId,
  GetLocationByAddress,
} from "../../../../../../Services";

export const AddressLocationComponents = ({
  translationPath,
  parentTranslationPath,
  state,
  setState,
  setSelected,
  selected,
  className,
  editForm,
}) => {
  const { t } = useTranslation(parentTranslationPath, "Shared");
  const [items, setItems] = useState({
    countries: [],
    cities: [],
    districts: [],
    communities: [],
    subCommunities: [],
  });
  const searchTimer = useRef(null);
  const [openMapDialog, setOpenMapDialog] = useState(false);

  const [isLoading, setIsLoading] = useState({
    countries: false,
    cities: false,
    districts: false,
    communities: false,
    subCommunities: false,
  });

  const effectedOnMapValue = async (
    key,
    addressName,
    lookupParentsNames,
    newSelected
  ) => {
    let currentAddress = "";
    if (key === "countryId" && addressName) currentAddress = addressName;
    else if (key === "countryId" && !addressName) {
      return null;
    } else if (key === "cityId")
      currentAddress = addressName
        ? lookupParentsNames?.country?.lookupItemName + "," + `${addressName}`
        : newSelected?.country?.lookupItemName;
    else if (key === "districtId")
      currentAddress = addressName
        ? lookupParentsNames?.country?.lookupItemName +
          "," +
          lookupParentsNames?.city?.lookupItemName +
          "," +
          `${addressName}`
        : newSelected?.country?.lookupItemName +
          "," +
          newSelected?.city?.lookupItemName;
    else if (key === "communityId")
      currentAddress = addressName
        ? lookupParentsNames?.country?.lookupItemName +
          "," +
          lookupParentsNames?.city?.lookupItemName +
          "," +
          lookupParentsNames?.district?.lookupItemName +
          "," +
          `${addressName}`
        : newSelected?.country?.lookupItemName +
          "," +
          newSelected?.city?.lookupItemName +
          "," +
          newSelected?.district?.lookupItemName;
    else if (key === "subCommunityId")
      currentAddress = addressName
        ? lookupParentsNames?.country?.lookupItemName +
          "," +
          lookupParentsNames?.city?.lookupItemName +
          "," +
          lookupParentsNames?.district?.lookupItemName +
          "," +
          lookupParentsNames?.community?.lookupItemName +
          "," +
          `${addressName}`
        : newSelected?.country?.lookupItemName +
          "," +
          newSelected?.city?.lookupItemName +
          "," +
          newSelected?.district?.lookupItemName +
          "," +
          newSelected?.community?.lookupItemName;

    if (key === "countryId" && (!addressName || addressName === "Unknown"))
      return null;

    const result = await GetLocationByAddress(currentAddress);
    let mapData = null;
    if (result && result.status && result.status === "OK") {
      const mapDataRes = result?.results[0]?.geometry?.location || null;
      mapData = mapDataRes
        ? { latitude: mapDataRes?.lat, longitude: mapDataRes?.lng }
        : null;
    } else {
      showWarn(
        t`${translationPath}cant-find-result-in-this-location-please-try-select-more-clear-address`
      );
      return null;
    }
    return mapData;
  };

  const getLookupItemsByName = async (
    lookupTypeName,
    key,
    searchValue,
    lookupParentId,
    lookupTypeId
  ) => {
    setIsLoading((item) => ({ ...item, [lookupTypeName]: true }));
    const result = await lookupItemsGet({
      lookupTypeId: lookupTypeId,
      pageSize: 25,
      pageIndex: 1,
      searchedItem: searchValue || null,
      lookupParentId: lookupParentId,
    });
    if (!(result && result.status && result.status !== 200)) {
      setItems((item) => ({ ...item, [key]: (result && result.result) || [] }));
    } else setItems((item) => ({ ...item, [key]: [] }));
    setIsLoading((item) => ({ ...item, [lookupTypeName]: false }));
  };
  const lookupItemsGetIdAPI = async (
    key,
    key2,
    lookupParentId,
    lookupTypeId
  ) => {
    setIsLoading((item) => ({ ...item, [key]: true }));
    const result = await lookupItemsGetId({
      lookupTypeId: lookupTypeId,
      lookupParentId: lookupParentId,
    });
    if (!(result && result.status && result.status !== 200)) {
      setItems((item) => ({ ...item, [key2]: result || [] }));
    } else setItems((item) => ({ ...item, [key2]: [] }));
    setIsLoading((item) => ({ ...item, [key]: false }));
  };

  const onSelectAddressHandler = async (key, value, selectedValues) => {
    let newState = null;
    let newSelected = null;
    let addressByParents = {
      country: null,
      city: null,
      district: null,
      community: null,
    };
    if (selectedValues && selectedValues.lookupItemParents) {
      JSON.parse(selectedValues.lookupItemParents).map((location) => {
        if (location.LookupType === "Country")
          addressByParents.country = {
            lookupItemId: location.LookupItemId,
            lookupItemName: location.LookupItemName,
          };
        if (location.LookupType === "City")
          addressByParents.city = {
            lookupItemId: location.LookupItemId,
            lookupItemName: location.LookupItemName,
          };
        if (location.LookupType === "District")
          addressByParents.district = {
            lookupItemId: location.LookupItemId,
            lookupItemName: location.LookupItemName,
          };
        if (location.LookupType === "Community")
          addressByParents.community = {
            lookupItemId: location.LookupItemId,
            lookupItemName: location.LookupItemName,
          };
      });
    }

    if (key === "countryId") {
      newState = {
        ...state,
        countryId: value,
        cityId: null,
        districtId: null,
        communityId: null,
        subCommunityId: null,
      };
      newSelected = {
        ...selected,
        country: selectedValues,
        city: null,
        district: null,
        community: null,
        subCommunity: null,
      };
      if (!value)
        getLookupItemsByName("districts", "districts", null, null, 18);
      getLookupItemsByName("communities", "communities", null, null, 19);
      getLookupItemsByName("subCommunities", "subCommunities", null, null, 20);
    } else if (key === "cityId") {
      newState = {
        ...state,
        countryId:
          (selectedValues && addressByParents?.country?.lookupItemId) ||
          state?.countryId ||
          null,
        cityId: value,
        districtId: null,
        communityId: null,
        subCommunityId: null,
      };
      newSelected = {
        ...selected,
        country:
          (selectedValues && addressByParents?.country) ||
          selected?.country ||
          null,
        city: selectedValues,
        district: null,
        community: null,
        subCommunity: null,
      };
      if (value) {
        getLookupItemsByName("communities", "communities", null, null, 19);
        getLookupItemsByName(
          "subCommunities",
          "subCommunities",
          null,
          null,
          20
        );
      }
    } else if (key === "districtId") {
      newState = {
        ...state,
        countryId:
          (selectedValues && addressByParents?.country?.lookupItemId) ||
          state?.countryId ||
          null,
        cityId:
          (selectedValues && addressByParents?.city?.lookupItemId) ||
          state?.cityId ||
          null,
        districtId: value,
        communityId: null,
        subCommunityId: null,
      };
      newSelected = {
        ...selected,
        country:
          (selectedValues && addressByParents?.country) ||
          selected?.country ||
          null,
        city:
          (selectedValues && addressByParents?.city) || selected?.city || null,
        district: selectedValues,
        community: null,
        subCommunity: null,
      };
      if (value) {
        lookupItemsGetIdAPI(
          "cities",
          "cities",
          (value && addressByParents?.country?.lookupItemId) || null,
          17
        );
      }
    } else if (key === "communityId") {
      newState = {
        ...state,
        countryId:
          (selectedValues && addressByParents?.country?.lookupItemId) ||
          state?.countryId ||
          null,
        cityId:
          (selectedValues && addressByParents?.city?.lookupItemId) ||
          state?.cityId ||
          null,
        districtId:
          (selectedValues && addressByParents?.district?.lookupItemId) ||
          state?.districtId ||
          null,
        communityId: value,
        subCommunityId: null,
      };
      newSelected = {
        ...selected,
        country:
          (selectedValues && addressByParents?.country) ||
          selected?.country ||
          null,
        city:
          (selectedValues && addressByParents?.city) || selected?.city || null,
        district:
          (selectedValues && addressByParents?.district) ||
          selected?.district ||
          null,
        community: selectedValues,
        subCommunity: null,
      };
      if (value) {
        lookupItemsGetIdAPI(
          "cities",
          "cities",
          (value && addressByParents?.country?.lookupItemId) || null,
          17
        );
        lookupItemsGetIdAPI(
          "districts",
          "districts",
          (value && addressByParents?.city?.lookupItemId) || null,
          18
        );
      }
    } else if (key === "subCommunityId") {
      newState = {
        ...state,
        countryId:
          (selectedValues && addressByParents?.country?.lookupItemId) ||
          state?.countryId ||
          null,
        cityId:
          (selectedValues && addressByParents?.city?.lookupItemId) ||
          state?.cityId ||
          null,
        districtId:
          (selectedValues && addressByParents?.district?.lookupItemId) ||
          state?.districtId ||
          null,
        communityId:
          (selectedValues && addressByParents?.community?.lookupItemId) ||
          state?.communityId ||
          null,
        subCommunityId: value,
      };
      newSelected = {
        ...selected,
        country:
          (selectedValues && addressByParents?.country) ||
          selected?.country ||
          null,
        city:
          (selectedValues && addressByParents?.city) || selected?.city || null,
        district:
          (selectedValues && addressByParents?.district) ||
          selected?.district ||
          null,
        community:
          (selectedValues && addressByParents?.community) ||
          selected?.community ||
          null,
        subCommunity: selectedValues,
      };
      if (value) {
        lookupItemsGetIdAPI(
          "cities",
          "cities",
          (value && addressByParents?.country?.lookupItemId) || null,
          17
        );
        lookupItemsGetIdAPI(
          "districts",
          "districts",
          (value && addressByParents?.city?.lookupItemId) || null,
          18
        );
        lookupItemsGetIdAPI(
          "communities",
          "communities",
          (value && addressByParents?.district?.lookupItemId) || null,
          19
        );
      }
    }
    const mapResponse = await effectedOnMapValue(
      key,
      selectedValues && selectedValues?.lookupItemName,
      addressByParents,
      newSelected
    );

    newState.mapCoordinates =
      (mapResponse &&
        mapResponse?.latitude &&
        mapResponse?.longitude &&
        `${mapResponse.latitude},${mapResponse.longitude}`) ||
      null;
    newSelected.latitude = mapResponse?.latitude || null;
    newSelected.longitude = mapResponse?.longitude || null;

    setState({ id: "edit", value: newState });
    setSelected({ id: "edit", value: newSelected });
  };

  return (
    <>
      <div className={`${className||'px-2 w-50 '}`}>
        <AutocompleteComponent
          key={`country`}
          selectedValues={selected.country}
          idRef={`countryRef`}
          multiple={false}
          inputPlaceholder={t("select-country")}
          data={items && items.countries}
          displayLabel={(option) => (option && option.lookupItemName) || ""}
          onChange={(e, newValue) => {
            onSelectAddressHandler(
              "countryId",
              newValue?.lookupItemId,
              newValue
            );
            lookupItemsGetIdAPI(
              "cities",
              "cities",
              (newValue?.lookupItemId && +newValue.lookupItemId) || null,
              17
            );
          }}
          isLoading={isLoading.countries}
          withLoader
          withoutSearchButton
          labelValue={"country"}
          translationPath={translationPath}
          parentTranslationPath={parentTranslationPath}
          onOpen={() => {
            if (items?.countries?.length === 0)
              getLookupItemsByName("countries", "countries", null, null, 16);
          }}
          onInputKeyUp={(e) => {
            const { value } = e.target;
            if (searchTimer.current) clearTimeout(searchTimer.current);
            searchTimer.current = setTimeout(() => {
              getLookupItemsByName("countries", "countries", value, null, 16);
            }, 700);
          }}
        />
      </div>
      <div className={`${className||'px-2 w-50 '}`}>
        <AutocompleteComponent
          key={`city`}
          selectedValues={selected.city}
          idRef={`cityRef`}
          multiple={false}
          inputPlaceholder={t("select-city")}
          data={items && items.cities}
          displayLabel={(option) => (option && option.lookupItemName) || ""}
          isLoading={isLoading.cities}
          withLoader
          withoutSearchButton
          labelValue={"city"}
          translationPath={translationPath}
          parentTranslationPath={parentTranslationPath}
          onChange={(e, newValue) => {
            onSelectAddressHandler("cityId", newValue?.lookupItemId, newValue);
            lookupItemsGetIdAPI(
              "districts",
              "districts",
              (newValue?.lookupItemId && +newValue.lookupItemId) || null,
              18
            );
          }}
          onInputKeyUp={(e) => {
            const { value } = e.target;
            if (searchTimer.current) clearTimeout(searchTimer.current);
            searchTimer.current = setTimeout(() => {
              getLookupItemsByName(
                "cities",
                "cities",
                value,
                state.countryId,
                17
              );
            }, 700);
          }}
        />
      </div>
      <div className={`${className||'px-2 w-50 '}`}>
        <AutocompleteComponent
          key={`district`}
          selectedValues={selected.district}
          idRef={`districtRef`}
          multiple={false}
          inputPlaceholder={t("select-district")}
          data={items && items.districts}
          displayLabel={(option) => (option && option.lookupItemName) || ""}
          isLoading={isLoading.districts}
          withLoader
          withoutSearchButton
          labelValue={"district"}
          translationPath={translationPath}
          parentTranslationPath={parentTranslationPath}
          onChange={(e, newValue) => {
            onSelectAddressHandler(
              "districtId",
              newValue?.lookupItemId,
              newValue
            );
            lookupItemsGetIdAPI(
              "communities",
              "communities",
              (newValue?.lookupItemId && +newValue.lookupItemId) || null,
              19
            );
          }}
          onInputKeyUp={(e) => {
            const { value } = e.target;
            if (searchTimer.current) clearTimeout(searchTimer.current);
            searchTimer.current = setTimeout(() => {
              getLookupItemsByName(
                "districts",
                "districts",
                value,
                state.cityId,
                18
              );
            }, 700);
          }}
        />
      </div>
      <div className={`${className||'px-2 w-50 '}`}>
        <AutocompleteComponent
          key={`community`}
          selectedValues={selected.community}
          idRef={`communityRef`}
          multiple={false}
          inputPlaceholder={t("community")}
          data={items && items.communities}
          displayLabel={(option) => (option && option.lookupItemName) || ""}
          isLoading={isLoading.communities}
          withLoader
          withoutSearchButton
          labelValue={"community"}
          translationPath={translationPath}
          parentTranslationPath={parentTranslationPath}
          onChange={(e, newValue) => {
            onSelectAddressHandler(
              "communityId",
              newValue?.lookupItemId,
              newValue
            );
            lookupItemsGetIdAPI(
              "subCommunities",
              "subCommunities",
              (newValue?.lookupItemId && +newValue.lookupItemId) || null,
              20
            );
          }}
          onInputKeyUp={(e) => {
            const { value } = e.target;
            if (searchTimer.current) clearTimeout(searchTimer.current);
            searchTimer.current = setTimeout(() => {
              getLookupItemsByName(
                "communities",
                "communities",
                value,
                state.districtId,
                19
              );
            }, 700);
          }}
        />
      </div>
      <div className={`${className||'px-2 w-50'}`}>
        <AutocompleteComponent
          key={`SubCommunity`}
          selectedValues={selected.subCommunity}
          idRef={`subCommunityRef`}
          multiple={false}
          inputPlaceholder={t("select-sub-community")}
          data={items && items.subCommunities}
          displayLabel={(option) => (option && option.lookupItemName) || ""}
          isLoading={isLoading.subCommunities}
          withLoader
          withoutSearchButton
          labelValue={"subCommunity"}
          translationPath={translationPath}
          parentTranslationPath={parentTranslationPath}
          onChange={(e, newValue) => {
            onSelectAddressHandler(
              "subCommunityId",
              newValue?.lookupItemId,
              newValue
            );
          }}
          onInputKeyUp={(e) => {
            const { value } = e.target;
            if (searchTimer.current) clearTimeout(searchTimer.current);
            searchTimer.current = setTimeout(() => {
              getLookupItemsByName(
                "subCommunities",
                "subCommunities",
                value,
                state.communityId,
                20
              );
            }, 700);
          }}
        />
      </div>
      <div className={`${className||'px-2 w-50 '}`}>
        <Inputs
          isAttachedInput
          idRef={"MapCoordinatesRef"}
          labelValue={"map-coordinates"}
          isDisabled
          value={state.mapCoordinates || ""}
          buttonOptions={
            {
              className: "btns-icon theme-outline c-blue-lighter",
              iconClasses: "mdi mdi-map-marker",
              isDisabled: !state.countryId,
              isRequired: false,
              onActionClicked: () => {
                setOpenMapDialog(true);
              },
            } || undefined
          }
          translationPath={translationPath}
          parentTranslationPath={parentTranslationPath}
        />
      </div>
      {openMapDialog && (
        <MapDialog
          open={openMapDialog}
          onChange={(e) => {
            setSelected({
              id: "edit",
              value: {
                ...selected,
                latitude: e.latitude,
                longitude: e.longitude,
              },
            });
            setState({
              id: "mapCoordinates",
              value: e.latitude ? `${e.latitude},${e.longitude}` : null,
            });
          }}
          initialState={selected}
          closeDialog={() => {
            setOpenMapDialog(false);
          }}
        />
      )}
    </>
  );
};
AddressLocationComponents.propTypes = {
  parentTranslationPath: PropTypes.string.isRequired,
  translationPath: PropTypes.string.isRequired,
  state: PropTypes.objectOf(PropTypes.any).isRequired,
  setState :  PropTypes.func.isRequired, 
  selected: PropTypes.objectOf(PropTypes.any).isRequired,
  setSelected :  PropTypes.func.isRequired, 
  className : PropTypes.string,
  editForm: PropTypes.bool,
};
AddressLocationComponents.defaultProps = {
  editForm: false,
};

