import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  content: {
    display: "flex",
    marginTop: "70px",
    fontSize: "18px",
    fontWeight: 600,
    lineHeight: "28px",
  },
  container: {
    minHeight: "160px",
  },
  actions: {
    paddingTop: "32px",
    alignItems: "center",
    gap: "12px",
    display: "flex",
    justifyContent: "flex-end",
  },

  modalContainer: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 11,
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
  text: {
    fontSize: "18px",
    fontWeight: 600,
  },
  modalPaper: {
    position: "absolute",
    display: "flex",
    flexDirection: "column",
    gap: "16px",
    maxWidth: "600px",
    padding: "24px",
    borderRadius: "12px",
    background: theme.palette.background.paper,
    boxShadow:
      "0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03)",
    backgroundImage: 'url("/images/contacts/agreementBackgroundIcon.svg")', // Fixed here
    backgroundPosition: "-120px -120px",
    backgroundRepeat: "no-repeat",
  },
  closeXIcon: {
    position: "absolute",
    right: "24px",
  },
}));
