export const AssetsTypeEnum = [
  {
    key: 1,
    value: 'fixed-asset',
  },
  {
    key: 2,
    value: 'loose-asset',
  },
];
