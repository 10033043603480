
import React from "react";

export const CorporateKycFormText = {
    MandatoryInformation : {
        beforeText: <div><p><b>Politically Exposed Persons (PEP)</b></p><ol><li>Head of the state or of Government.</li><li>Senior Politician.</li><li>Senior Government, Judicial or Military official.</li><li>Member of the ruling Royal family.</li><li>Senior executive of State-owned Corporation/government linked Company.</li><li>Important political party official.</li></ol></div>
    },
    Confirmation : {
        beforeText: <div><p>We further confirm that these funds are derived from legitimate sources as stated above and that we will also provide the required evidence of the source of funds if required to do so in future if the regulations required.</p><p>In accordance with applicable local laws, the undersigned agree that the Company or any of its Contracting Companies, or its affiliates including branches, referred to as collectively on behalf of 'Us' to exchange all or part of the information contained in this form in compliance with the Legislations & Laws issued in the UAE & governing Real estate brokerage business in it.</p><p>We hereby affirm that the above information is true, accurate & complete, the notice must be given of any change in status impacting the information provided within ten (10) days of said change.</p></div>
    },
    ForOfficeUseOnly : {
        beforeText: <div>I confirm that the details as per the information is verified as per the valid documents received from the above stated client.</div>,
        afterText: <div><p><b>Note:</b></p><p>This application will be considered only if signed by the Client as well as Agent/Representative of PSI along with the mandatory documents with the Company Stamp.</p><p>Documents to be submitted with the application:</p><ol><li>Shareholders' EID, Residence, and Passport of the company.</li><li>Authorized signatory’s EID, Residence, Passport Copy, and Power of attorney.</li><li>Trade License (Commercial Register)</li><li>Memorandum of association (MOA).</li><li>Proof of payment method</li><li>Proof of source of funds</li><li>Proof of Address</li></ol><p>At Property Shop Investment LLC, we are committed to protecting the confidentiality and security of the personal information you’ve provided in this form. The data collected is used solely for the purposes of verifying your identity and ensuring compliance with legal and regulatory requirements. The company is committed to safeguard your information against unauthorized access, disclosure, or misuse and will handle it in strict accordance with applicable data protection laws.</p></div>
    }
}