import { GetContacts, GetContactByEmail } from '../Services';

export async function duplicateEmailRole(item, itemsValue , setLoading  ) {
    if (item.data.CommunicationType !== 'Email') return true;
    if (setLoading) setLoading(true);
    const res = await GetContactByEmail({
      pageIndex: 0,
      pageSize: 1,
      search: itemsValue,
    });
    if (res && res.result && res.result.length !== 0) {
      if (setLoading) setLoading(false);
      return false;
    } else {
      if (setLoading) setLoading(false);
    }
    return true;
}

export async function duplicatePhoneRole(item, itemsValue , setLoading) {
    if (item.data.CommunicationType !== 'Phone') return true;
   
    if (item.field.id !== "mobile" && item.field.id !== "landline_number")
      return true;

    if (setLoading) setLoading(true);
    const res = await GetContacts({
      pageIndex: 0,
      pageSize: 1,
      search: itemsValue,
      isAdvance: false,
    });
    if (res && res.result && res.result.length !== 0) {
      if (setLoading) setLoading(false);
      return false;
    } else {
      if (setLoading) setLoading(false);
    }
    
    return true;
}
