import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { InputAdornment, Slider } from '@material-ui/core';
import { Inputs } from '../../../../Components';
import './SliderPrimaryUnits.scss'

export const ServiceChargeFilter = ({
  translationPath,
  parentTranslationPath,
  setSelected,
  selected,
  RangeSliderFilter,
}) => {
  const { t } = useTranslation(parentTranslationPath);
  const searchTimer = useRef(null);

  const handleSliderChange = (event, newValue) => {
    const [minValue, maxValue] = newValue;
    setSelected({
      id: 'servicesCharge',
      value: { from: minValue, to: maxValue },
    });
    if (searchTimer.current) {
      clearTimeout(searchTimer.current);
    }
    searchTimer.current = setTimeout(() => {
      RangeSliderFilter('services_charge', {
        from: minValue,
        to: maxValue,
      });
    }, 1000);
  };

  const handleInputChange = (value, type) => {
    if (type === 'from') {
      setSelected({
        id: 'servicesCharge',
        value: { from: value },
      });
      if (searchTimer.current) {
        clearTimeout(searchTimer.current);
      }
      searchTimer.current = setTimeout(() => {
        RangeSliderFilter('services_charge', {
          from: value,
          to: selected?.servicesCharge?.to,
        });
      }, 1000);
    } else {
      setSelected({
        id: 'servicesCharge',
        value: { from: selected?.servicesCharge?.from, to: value },
      });
      if (searchTimer.current) {
        clearTimeout(searchTimer.current);
      }
      searchTimer.current = setTimeout(() => {
        RangeSliderFilter('services_charge', {
          from: selected?.servicesCharge?.from,
          to: value,
        });
      }, 1000);
    }
  };

  return (
    <div className='filter-slider-container'>
      <fieldset className='styled-fieldset'>
        <legend className='styled-legend'>
          {t(`${translationPath}Service-Charge`)}
        </legend>
        <div className='slider-wrapper'>
          <Slider
            value={[
              Number(selected?.servicesCharge?.from) || 0,
              Number(selected?.servicesCharge?.to) || 0,
            ]}
            onChange={handleSliderChange}
            min={0}
            max={5000}
            valueLabelDisplay='auto'
          />
        </div>
        <div className='inputs-container'>
          <Inputs
            idRef='servicesChargeFromRef'
            type='number'
            labelValue={t(`${translationPath}Min`)}
            value={selected?.servicesCharge?.from || ''}
            onInputChanged={(event) => {
              const { value } = event.target;
              handleInputChange(value, 'from');
            }}
            endAdornment={<InputAdornment position='start'>AED</InputAdornment>}
            min={0}
          />
          <span className='range-separator'>-</span>
          <Inputs
            idRef='servicesChargeToRef'
            type='number'
            labelValue={t(`${translationPath}Max`)}
            value={selected?.servicesCharge?.to || ''}
            onInputChanged={(event) => {
              const { value } = event.target;
              handleInputChange(value, 'to');
            }}
            endAdornment={<InputAdornment position='start'>AED</InputAdornment>}
            min={0}
          />
        </div>
      </fieldset>
    </div>
  );
};
