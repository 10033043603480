import { config } from '../../config';
import { HttpServices } from '../../Helper';

export const GetAllCampaignTasks = async (body) => {
  const result = await HttpServices.post(
    `${config.server_address}/CrmDfm/CampaignTasks/GetAllCampaignTasks`, body
)
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

export const GetTasks = async (body) => {
  const result = await HttpServices.post(
    `${config.server_address}/CrmDfm/CampaignTasks/GetTasks`, body
)
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

export const GetAllCampaignTeamMembers = async ({ requestId }) => {
  const result = await HttpServices.get(
    `${config.server_address}/CrmDfm/CampaignTasks/GetAllCampaignTeamMembers/${requestId}`
)
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

export const CreateCampaignTask = async (body) => {
  const result = await HttpServices.post(
    `${config.server_address}/CrmDfm/CampaignTasks/CreateCampaignTask`, body
)
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

export const UpdateCampaignTask = async (body) => {
  const result = await HttpServices.put(
    `${config.server_address}/CrmDfm/CampaignTasks/UpdateCampaignTask`, body
)
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

export const GetTaskDetails = async ({ taskId }) => {
  const result = await HttpServices.get(
    `${config.server_address}/CrmDfm/CampaignTasks/GetTaskDetails/${taskId}`
)
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

export const GetTaskById = async ({ taskId }) => {
  const result = await HttpServices.get(
    `${config.server_address}/CrmDfm/CampaignTasks/GetTaskById/${taskId}`
)
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

export const DeleteCampaignTask = async ({ taskId }) => {
  const result = await HttpServices.delete(
    `${config.server_address}/CrmDfm/CampaignTasks/DeleteCampaignTask/${taskId}`
)
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

export const AddCampaignTeamMembers = async (body) => {
  const result = await HttpServices.post(
    `${config.server_address}/CrmDfm/CampaignTasks/AddCampaignTeamMembers`, body
)
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

export const UpdateCampaignTeamMember = async (body) => {
  const result = await HttpServices.put(
    `${config.server_address}/CrmDfm/CampaignTasks/UpdateCampaignTeamMember`, body
)
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

export const DeleteTeamMember = async (teamMemberId) => {
  const result = await HttpServices.delete(
    `${config.server_address}/CrmDfm/CampaignTasks/DeleteTeamMember/${teamMemberId}`
)
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

export const GetCampaignUserTypes = async () => {
  const result = await HttpServices.get(
    `${config.server_address}/CrmDfm/CampaignTasks/GetCampaignUserTypes`
)
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

export const GetTasksAssignees = async () => {
  const result = await HttpServices.get(
    `${config.server_address}/CrmDfm/CampaignTasks/GetTasksAssignees`
)
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

export const GetTaskHistory = async ({ taskId }) => {
  const result = await HttpServices.get(
    `${config.server_address}/CrmDfm/CampaignTasks/GetTaskHistory/${taskId}`
)
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

export const ApproveACampaignTask = async (taskId) => {
  const result = await HttpServices.put(
    `${config.server_address}/CrmDfm/CampaignTasks/ApproveACampaignTask?id=${taskId}`
)
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

export const RejectACampaignTask = async (body) => {
  const result = await HttpServices.put(
    `${config.server_address}/CrmDfm/CampaignTasks/RejectACampaignTask`, body
)
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

export const SetTaskStatusAsInprogress = async (taskId) => {
  const result = await HttpServices.put(
    `${config.server_address}/CrmDfm/CampaignTasks/SetTaskStatusAsInprogress/${taskId}`
)
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

export const SetCampaignTaskStatusAsReview = async (taskId) => {
  const result = await HttpServices.put(
    `${config.server_address}/CrmDfm/CampaignTasks/SetCampaignTaskStatusAsReview/${taskId}`
)
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

export const GetTaskUploadedData = async ({ taskId }) => {
  const result = await HttpServices.get(
    `${config.server_address}/CrmDfm/CampaignTasks/GetTaskUploadedData/${taskId}`
)
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

export const UploadTaskData = async (body) => {
  const result = await HttpServices.post(
    `${config.server_address}/CrmDfm/CampaignTasks/UploadTaskData`, body
)
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};
