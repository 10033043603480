import React from 'react';
import moment from 'moment';

export const CampaignTasksHeaderData = [
  {
    id: 1,
    label: 'title',
    input: 'title',
    isHiddenFilter: true,
    isSortable: true,
  },
  {
    id: 2,
    label: 'description',
    input: 'description',
    isHiddenFilter: true,
    isSortable: true,
  },
  {
    id: 2,
    label: 'status',
    input: 'taskStatus',
    isHiddenFilter: true,
    isSortable: true,
    isHiddenFilter: true,
  },
  {
    id: 4,
    label: 'creation-date',
    input: 'date',
    isHiddenFilter: true,
    isSortable: true,
    component: (item)=> <span>{item.date ? moment(item.date).format('LL'): ''}</span>
  },
  {
    id: 5,
    label: 'deadline',
    input: 'deadline',
    isHiddenFilter: true,
    isSortable: true,
    component: (item)=> <span>{item.deadline ? moment(item.deadline).format('LL'): ''}</span>
  },
  {
    id: 6,
    label: 'assignee',
    input: 'assignee',
    isHiddenFilter: true,
    isSortable: true,
  },

];
