import React, { useState, useCallback, useReducer, useEffect } from 'react';
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Dialog,
  ButtonBase,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import Joi from 'joi';
import { showError, showSuccess, GetParams } from '../../../../../../Helper';
import { UnitViewsTypeIdEnum } from '../../../../../../Enums';
import { Spinner } from '../../../../../../Components';
import {
  lookupItemsGetId,
  CreatePropertyUnitModel,
  GetPropertyUnitModelById,
  UpdatePropertyUnitModelAPI,
} from '../../../../../../Services';
import { UnitsModelsFieldsComponent } from './UnitsModelsFieldsComponent';

export const UnitsModelsManangementDialog = ({
  parentTranslationPath,
  translationPath,
  activeItem,
  isDialogOpen,
  onClose,
  onSave,
  propertyUnitTypes,
}) => {
  const { t } = useTranslation(parentTranslationPath);

  const reducer = useCallback((state, action) => {
    if (action.id !== 'edit') return { ...state, [action.id]: action.value };
    return {
      ...action.value,
    };
  }, []);

  const [filter, setFilter] = useState({
    pageSize: 100,
    pageIndex: 0,
  });

  const [isSubmitted, setIsSubmitted] = useState(false);
  const [selected, setSelected] = useReducer(reducer, {
    primaryView: null,
    secondaryView: null,
  });

  const [selectedItem, setSelectedItem] = useState(null);
  const [data, setData] = useReducer(reducer, {
    unitViews: [],
  });

  const [isLoading, setIsLoading] = useState({
    views: false,
    viewData: false,
    saveUnitModel: false,
    uploadingImages: false,
  });

  const [error, setError] = useState({
    bedroomNo: false,
    bathroomsNo: false,
    maidsRoomNo: false,
    storeRoomNo: false,
    laundryRoomNo: false,
    utilityRoomNo: false,
    studyRoomNo: false,
  });
  const [state, setState] = useReducer(reducer, {
    propertyId: +GetParams('id'),
    propertyUnitModelName: null,
    area: null,
    bedroomNo: null,
    bathroomNo: null,
    unitsNo: null,
    sellingPriceFrom: null,
    sellingPriceTo: null,
    rentPerYearFrom: null,
    rentPerYearTo: null,
    primaryView: null,
    secondaryView: null,
    unitTypeId: null,
    parkingNo: null,
    maidsRoomNo: null,
    storeRoomNo: null,
    backyardNo: null,
    laundryRoomNo: null,
    utilityRoomNo: null,
    studyRoomNo: null,
    unitModelImage: null,
    unitModelFileId: null,
    unitModelDocuments: null,
    propertyUnitType: null,
    files: [],
    unitsNo: null,
    serviceCharge: null,
  });

  const schema = Joi.object({
    propertyUnitModelName: Joi.string()
      .required()
      .messages({
        'string.base': t`${translationPath}propertyUnitModelName-is-required`,
        'string.empty': t`${translationPath}propertyUnitModelName-is-required`,
      }),
    area: Joi.number()
      .required()
      .messages({
        'number.base': t(`${translationPath}area-is-required`),
        'number.empty': t`${translationPath}area-is-required`,
      }),
  })
    .options({
      abortEarly: false,
      allowUnknown: true,
    })
    .validate(state);

  const getPropertyUnitModelById = useCallback(async () => {
    setIsLoading((item) => ({ ...item, viewData: true }));
    const res = await GetPropertyUnitModelById(
      activeItem && activeItem.propertyUnitModelId
    );
    if (!(res && res.status && res.status !== 200)) {
      setSelectedItem(res);
    } else setSelectedItem(null);

    setIsLoading((item) => ({ ...item, viewData: false }));
  }, [activeItem]);

  const getViewsLookups = useCallback(async () => {
    setIsLoading((item) => ({ ...item, views: false }));
    const res = await lookupItemsGetId({
      lookupTypeId: UnitViewsTypeIdEnum.lookupTypeId,
    });
    if (!(res && res.status && res.status !== 200)) {
      setData({ id: 'unitViews', value: res || [] });
    } else setData({ id: 'unitViews', value: [] });

    setIsLoading((item) => ({ ...item, views: false }));
  }, []);

  const saveHandler = async (event) => {
    event.preventDefault();
    setIsSubmitted(true);
    setIsLoading((item) => ({ ...item, saveUnitModel: true }));

    const errorList = Object.values(error);

    if (schema.error || errorList.some((x) => x)) {
      setIsLoading((item) => ({ ...item, saveUnitModel: false }));
      showError(t(`${translationPath}please-fill-all-Required-fields`));
      return;
    }
    const unitModelBody = {
      propertyId: state.propertyId,
      propertyUnitModelName: state.propertyUnitModelName || "",
      area: state.area || 0,
      bedroomNo: state.bedroomNo || 0,
      bathroomNo: state.bathroomNo || 0,
      unitsNo: state.unitsNo || "",
      sellingPriceFrom: state.sellingPriceFrom || 0,
      sellingPriceTo: state.sellingPriceTo || 0,
      rentPerYearFrom: state.rentPerYearFrom || 0,
      rentPerYearTo: state.rentPerYearTo || 0,
      primaryView: state.primaryView || [],
      secondaryView: state.secondaryView || [],
      unitTypeId: state.unitTypeId || 0,
      parkingNo: state.parkingNo || 0,
      maidsRoomNo: state.maidsRoomNo || 0,
      storeRoomNo: state.storeRoomNo || 0,
      backyardNo: state.backyardNo || 0,
      laundryRoomNo: state.laundryRoomNo || 0,
      utilityRoomNo: state.utilityRoomNo || 0,
      studyRoomNo: state.studyRoomNo || 0,
      unitModelImage: state.unitModelImage || 0,
      unitModelDocuments: state.files || [],
      unitsNo: state.unitsNo || 0,
      propertyUnitType: state.propertyUnitType || null,
      serviceCharge: state.serviceCharge || 0,
      unitModelFileId:
        (state &&
          state.files &&
          state.files.length &&
          state.files[0] &&
          state.files[0].uuid) ||
        null,
    };

    const res = selectedItem
      ? await UpdatePropertyUnitModelAPI(
          selectedItem.propertyUnitModelId,
          unitModelBody
        )
      : await CreatePropertyUnitModel(unitModelBody);

    if (!(res && res.status && res.status !== 200)) {
      showSuccess(
        t(
          `${translationPath}${
            (activeItem && `unitModel-updated-successfully`) ||
            `unitModel-created-successfully`
          }`
        )
      );
      if (onSave) onSave();
    } else {
      showError(
        t(
          `${translationPath}${
            (activeItem && `unitModel-updated-failed`) ||
            `unitModel-created-failed`
          }`
        )
      );
    }
    setIsLoading((item) => ({ ...item, saveUnitModel: false }));
  };

  useEffect(() => {
    getViewsLookups();
  }, [getViewsLookups]);

  useEffect(() => {
    if (activeItem) getPropertyUnitModelById();
  }, [activeItem]);

  useEffect(() => {
    if (selectedItem) {
      setState({
        id: 'edit',
        value: {
          propertyId: selectedItem.propertyId,
          propertyUnitModelName: selectedItem.propertyUnitModelName,
          propertyUnitModelId: selectedItem.propertyUnitModelId,
          area: selectedItem.area,
          bedroomNo: selectedItem.bedroomNo,
          bathroomNo: selectedItem.bathroomNo,
          unitsNo: selectedItem.unitsNo,
          sellingPriceFrom: selectedItem.sellingPriceFrom,
          sellingPriceTo: selectedItem.sellingPriceTo,
          rentPerYearFrom: selectedItem.rentPerYearFrom,
          rentPerYearTo: selectedItem.rentPerYearTo,
          primaryView: selectedItem.primaryData,
          secondaryView: selectedItem.secondaryData,
          unitTypeId: selectedItem.unitTypeId,
          parkingNo: selectedItem.parkingNo,
          maidsRoomNo: selectedItem.maidsRoomNo,
          storeRoomNo: selectedItem.storeRoomNo,
          backyardNo: selectedItem.backyardNo,
          laundryRoomNo: selectedItem.laundryRoomNo,
          utilityRoomNo: selectedItem.utilityRoomNo,
          studyRoomNo: selectedItem.studyRoomNo,
          unitModelImage: selectedItem.unitModelImage,
          unitModelFileId: selectedItem.unitModelFileId,
          propertyUnitType: selectedItem.propertyUnitType,
          unitModelDocuments: selectedItem.unitModelDocuments || [],
          serviceCharge: selectedItem.serviceCharge,
          files: selectedItem.unitModelDocuments
        },
      });
    }
  }, [selectedItem]);

  return (
    <div>
      <Dialog
        disableBackdropClick
        open={isDialogOpen}
        onClose={onClose}
        className='activities-management-dialog-wrapper unitsModelsFields'
      >
        <Spinner
          isActive={
            isLoading.views || isLoading.saveUnitModel || isLoading.viewData || isLoading.uploadingImages
          }
          isAbsolute
        />
        <form noValidate onSubmit={saveHandler}>
          <DialogTitle id='alert-dialog-slide-title'>
            {t(
              `${translationPath}${
                (activeItem && 'edit-unit-models') || 'add-unit-models'
              }`
            )}
          </DialogTitle>

          <DialogContent>
            <UnitsModelsFieldsComponent
              translationPath={translationPath}
              parentTranslationPath={parentTranslationPath}
              state={state}
              setState={setState}
              propertyUnitTypes={propertyUnitTypes}
              activeItem={activeItem}
              schema={schema}
              isSubmitted={isSubmitted}
              error={error}
              setError={setError}
              isLoadingModelsImageUploading = {isLoading.uploadingImages}
              setIsLoadingModelsImageUploading = {setIsLoading}
            />
          </DialogContent>
          <DialogActions>
            <ButtonBase
              onClick={onClose}
              className='btns theme-solid bg-cancel'
            >
              {t(`${translationPath}cancel`)}
            </ButtonBase>
            <ButtonBase
              className='btns theme-solid'
              onClick={saveHandler}
              type='submit'
            >
              {t(`${translationPath}save`)}
            </ButtonBase>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
};
