export const LeaseTransactionJourneyPermissions = {
    MakeTransaction : {
        "permissionsId": "9872fab8-1ae3-4e88-5ddd-08dc7403ebc2",
        "permissionsName": "Make Transaction",
        "description": null,
        "componentsId": null,
        "components": null
    },
    ContinueTransaction : {
        "permissionsId": "4830ec95-7534-4af5-5dde-08dc7403ebc2",
        "permissionsName": "Continue Transaction",
        "description": null,
        "componentsId": null,
        "components": null
    }
}