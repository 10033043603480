



import React  from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { CheckboxesComponent , SelectComponet  , Inputs } from '../../../../../../../Components' ; 
import { showError  } from '../../../../../../../Helper' ; 


    
export const LeadsDataDetails  = ({
  parentTranslationPath,
  translationPath,
  state,
  setState  , 

}) => {

    const { t } = useTranslation(parentTranslationPath);

  return (
    <div className='leadsDataDetailsTab'>
       <div className='w-100 leadLastDays'> 
         <div className='leadsDataDetailsItem'>
           <CheckboxesComponent
             idRef='LeadsDataDetailsIsLastDaysRef'
             label={t(`${translationPath}selectLastDays`)}
             parentTranslationPath={parentTranslationPath}
             translationPathForData={translationPath}
             singleChecked={state.leads.dataDetails.isLastDays}
             onSelectedCheckboxClicked={(e)=> {
                const leadsDataDetails =  { ...state.leads.dataDetails ,
                  isLastDays : (e.target.checked)   , 
                  lastDaysValue : (!e.target.checked ?  null : state.leads.dataDetails && (state.leads.dataDetails.lastDaysValue)), 

              } ;
             setState({id : 'leads' , value : { ...state.leads , dataDetails :   leadsDataDetails  } });
         }}

        />
        </div>  

        {
           state.leads.dataDetails && state.leads.dataDetails.isLastDays  && (
            <div className='w-50 leadsDataDetailsItem'>
             <Inputs
               idRef='lastDaysRef'
               inputPlaceholder={t(`${translationPath}enter-days`)}
               value={state.leads.dataDetails && state.leads.dataDetails.lastDaysValue}
               type='number'
               min={1}
               max={120}
               parentTranslationPath={parentTranslationPath}
               translationPath={translationPath}
               onInputChanged={(e) => 
              { 
                const value =   +e.target.value  ;
                const regex = /^[0-9\b]+$/;
                if (value === "" || regex.test(value)) 
                {
                  if(value > 90 || value <=  0 ) 
                  {
                    showError(t(`${translationPath}The-maximum-number-of-days-is-90-days`));
                    return ; 
                  }
                  const leadsDataDetails = 
                   {    ...state.leads.dataDetails ,
                        lastDaysValue : +(value)

                   }; 
                
                  setState({id : 'leads' , value : { ...state.leads , dataDetails :   leadsDataDetails  } });
                }
                
              }}
              helperText={t(`${translationPath}please-enter-days`)}
              error={ state.leads.dataDetails && state.leads.dataDetails.isLastDays &&  state.leads.dataDetails && !state.leads.dataDetails.lastDaysValue}
              isWithError
             />
             </div>
           )
        }
        
      </div>
        <div className='w-100 px-2 mt-2 mb-2'>
           <SelectComponet
                  idRef='LeadsDataDetailsLeadStatusRef'
                  labelValue={t(`lead-Status`)}
                  data={[
                    { id: 'open', value: t(`open`) },
                    { id: 'close', value: t(`close`) },
                    { id: 'both', value: t(`both`) }

                  ]}
                  value={state.leads.dataDetails.leadStatus}
                  onSelectChanged={(value)=> {
                    const leadsDataDetails =  { ...state.leads.dataDetails ,
                      leadStatus : value
                     } ;
                  setState({id : 'leads' , value : { ...state.leads , dataDetails :   leadsDataDetails  } });

                  }}
                  selectedValues={state.leads.dataDetails.leadStatus} 
                  wrapperClasses='over-input-select w-auto'
                  valueInput='id'
                  textInput='value'
                  emptyItem={{
                    value: null,
                    text: t(`leadStatus`),
                    isDisabled: false,
                  }}
                />
            </div>
       
  </div>
  );
};

LeadsDataDetails.propTypes = {
  parentTranslationPath: PropTypes.string.isRequired,
  translationPath: PropTypes.string.isRequired,
  state:PropTypes.instanceOf(Object).isRequired,
  setState : PropTypes.func.isRequired,
  checkAtLeastOneSelected : PropTypes.func.isRequired,
};
