

  export const RequestTypeEnum = [
   {
      key: 1,
      value: 'Create',
   },
   {
      key: 2,
      value: 'Update',
    },
    {
        key: 3,
        value: 'Read',
    } , 
    {
        key: 4,
        value: 'Delete',
    }  , 
    {
        key: 5,
        value: 'Marge',

    }  , 
     {
        key: 6,
        value: 'UnMarge',

    } , 
     {
        key: 7,
        value: 'Archived',

    } , 
    { 
        key: 8,
        value: 'UnArchived',
    } , 
    { 
        key: 9,
        value: 'Active',
    } , 
    { 
        key: 10,
        value: 'Deactive',
    }
];
  