export const TableFilterOperatorsNumericEnum = {
  1: {
    value: 'is-equal',
    name: 'Is equal',
    filedType: 'textFiled',
    icon: 'mdi  mdi-equal'
  },
  2: {
    value: 'is-contains',
    name: 'Is contains',
    filedType: 'textFiled',
    icon: 'mdi mdi-contain'
  },
  3: {
    value: 'is-greater-than-or-equal',
    name: 'Is greater than Or Equal',
    filedType: 'textFiled',
    icon: 'mdi mdi-chevron-double-up'
  },
  4: {
    value: 'is-less-than-or-equal',
    name: 'Is less than',
    filedType: 'textFiled',
    icon: 'mdi mdi-chevron-double-down'
  },
  5: {
    value: 'is-greater-than-or-equal',
    name: 'Is greater than date',
    filedType: 'date',
    icon: 'mdi mdi-chevron-double-up'
  },
  6: {
    value: 'is-less-than-or-equal',
    name: 'Is less than date',
    filedType: 'date',
    icon: 'mdi mdi-chevron-double-down'
  },
  7: {
    value: 'is-start-with',
    name: 'Is start with',
    filedType: 'textFiled',
    icon: 'mdi mdi-contain-start'
  },
  8: {
    value: 'is-end-with',
    name: 'Is end with',
    filedType: 'textFiled',
    icon: 'mdi mdi-contain-end'
  },
  9: {
    value: 'is-between',
    name: 'Is between',
    filedType: 'textFiled',
    icon: 'mdi mdi-ray-vertex'
  },
  10: {
    value: 'is-equal-date',
    name: 'Is equal date',
    filedType: 'date',
    icon: 'mdi mdi-equal'
  },
  11: {
    value: 'not-equal',
    name: 'not equal',
    filedType: 'textFiled',
    icon: 'mdi mdi-not-equal-variant'
  },
  12: {
    value: 'not-contains',
    name: 'not contains',
    filedType: 'date',
    icon: 'mdi mdi-tray-remove'
  },
  13: {
    value: 'is-blank',
    name: 'Is Blank',
    filedType: 'date',
    icon: 'mdi mdi-checkbox-blank-circle-outline'
  },
  14: {
    value: 'is-not-blank',
    name: 'Is Not Blank',
    filedType: 'date',
    icon: 'mdi mdi-checkbox-blank-circle'
  },
  15: {
    value: 'not-equal-date',
    name: 'not equal date',
    filedType: 'date',
    icon: 'mdi mdi-not-equal-variant'
  },
};

