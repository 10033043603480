import React, { useState } from 'react';
import { TabsComponent } from '../../../../../../../../Components';
import { MarketingApprovalTabsData } from '../TabsData';

export function MarketingApprovalForm({ setState, isSubmitted, setIsSubmitted, activeItem }) {
  const parentTranslationPath = 'Campaign';
  const translationPath = '';
  const [activeTab, setActiveTab] = useState(0);
  const onTabChanged = (e, newTap) => {
    setActiveTab(newTap);
  };
  return (
    <div>
      <TabsComponent
        data={MarketingApprovalTabsData}
        labelInput='label'
        parentTranslationPath={parentTranslationPath}
        translationPath={translationPath}
        themeClasses='theme-solid'
        currentTab={activeTab}
        onTabChanged={onTabChanged}
        dynamicComponentProps={{
          activeItem,
          setState,
          activeItem,
          isSubmitted,
          setIsSubmitted,
        }}
      />
    </div>
  );
}
