
import React from 'react';
import { useTranslation } from 'react-i18next'; 
import { Inputs } from '../../../../../../../Components' ;  

export const UnitsModelsFieldsThirdSectionComponent = ({
  setState ,
  state ,
  translationPath ,
  parentTranslationPath ,
  error ,
  setError , 
}) => {
  const { t } = useTranslation(parentTranslationPath);

  
  return (
    <>
        <div className='dialog-content-item '>
                <Inputs
                  idRef='backyardNoRef'
                  labelValue='backyardNo'
                  type='number'
                  value={state.backyardNo}
                  min={0}
                  max={100}
                  onInputChanged={(event) => {
                    const { value }= event.target ; 
                    if(value < 0 ||  value > 99 )
                    return ; 

                    setState({ id: 'backyardNo',   value:(value !== ''?  Math.trunc(value) :  '' )});
                  }}
                  translationPath={translationPath} 
                  parentTranslationPath={parentTranslationPath} 
               />
        </div>
        <div className='dialog-content-item'>
                <Inputs
                  idRef='laundryRoomNoRef'
                  labelValue='laundryRoomNo'
                  type='number'
                  value={state.laundryRoomNo}
                  min={0}
                  max={100}
                  onInputChanged={(event) => {
                    const { value }= event.target ; 
                    if(value < 0 ||  value > 99 )
                    return ;

                    // const  numberRegex = /^\d+$/;
                    // const laundryRoomNoError =  !(numberRegex.test(value)) ;
                    //  setError((item) => ({ ...item, laundryRoomNo : laundryRoomNoError })); 

                    setState({ id: 'laundryRoomNo', value:(value !== ''?  Math.trunc(value) :  '' )});
                  }}
                  // helperText={'Choose-correct-number'}
                  // error={error.laundryRoomNo}
                  // isWithError
                  translationPath={translationPath} 
                  parentTranslationPath={parentTranslationPath}
               />
         </div>
         <div className='dialog-content-item'>
                <Inputs
                  idRef='utilityRoomNoRef'
                  labelValue='utilityRoomNo'
                  type='number'
                  min={0}
                  max={100}
                  value={state.utilityRoomNo}
                  onInputChanged={(event) => {

                    const { value }= event.target ; 
                    if(value < 0  ||  value > 99 )
                    return ;
                    // const  numberRegex = /^\d+$/;
                    // const utilityRoomNoError =  !(numberRegex.test(event.target.value)) ;
                    //  setError((item) => ({ ...item, utilityRoomNo  : utilityRoomNoError })); 

                    setState({ id: 'utilityRoomNo', value:(value !== ''?  Math.trunc(value) :  '' ) });
                  }}
                  // error={error.utilityRoomNo}
                  // isSubmitted={isSubmitted}
                  // helperText={t(`${translationPath}Choose-correct-number`)}
                  // isWithError
                  translationPath={translationPath} 
                  parentTranslationPath={parentTranslationPath}
               />
        </div>
        <div className='dialog-content-item'>
                <Inputs
                  idRef='studyRoomNoRef'
                  labelValue='studyRoomNo'
                  type='number'
                  value={state.studyRoomNo}
                  min={0}
                  max={100}
                  onInputChanged={(event) => {
                    const { value } = event.target ; 
                    if(value < 0 || value > 99 )
                    {
                      return ;

                    }
                    // const  numberRegex = /^\d+$/;
                    // const studyRoomNoError =  !(numberRegex.test(event.target.value)) ;
                    //  setError((item) => ({ ...item, studyRoomNo  : studyRoomNoError })); 

                    setState({ id: 'studyRoomNo', value:(value !== ''?  Math.trunc(value) :  '' )});
                  }}
                  // isSubmitted={isSubmitted}
                  // helperText={t(`${translationPath}Choose-correct-number`)}
                  // isWithError
                  // error={error.studyRoomNo || '' }
                  translationPath={translationPath} 
                  parentTranslationPath={parentTranslationPath}
               />
         </div>
     </>
  );
};
