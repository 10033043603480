export const LeadsPoolSalesPermissions = {
  ViewLeadsPool: {
    permissionsId: '41613ece-de87-4e73-c4b5-08dc683b0c43',
    permissionsName: 'View Leads Pool',
    description: null,
    componentsId: null,
    components: null,
  },
  AssignToMe: {
    permissionsId: '953a28c9-70cf-4ab8-c4b6-08dc683b0c43',
    permissionsName: 'Assign To Me',
    description: null,
    componentsId: null,
    components: null,
  },
  ReassignLeads: {
    permissionsId: '99a7a0fa-d65b-4d83-c4b7-08dc683b0c43',
    permissionsName: 'Reassign Leads',
    description: null,
    componentsId: null,
    components: null,
  },
  SendToRotation: {
    permissionsId: '0ccc75b1-8c28-4bd3-c4b8-08dc683b0c43',
    permissionsName: 'Send To Rotation',
    description: null,
    componentsId: null,
    components: null,
  },
  ViewLeadName: {
    permissionsId: '614aa5f4-3cbe-44c9-5dd7-08dc7403ebc2',
    permissionsName: 'View Lead Name',
    description: null,
    componentsId: null,
    components: null,
  },
  ViewRemarksColumn: {
    permissionsId: '5fb35844-41fa-45d0-5dd8-08dc7403ebc2',
    permissionsName: 'View Remarks Column',
    description: null,
    componentsId: null,
    components: null,
  },
  OpenLeadFile: {
    permissionsId: '2a31f1c2-4969-4f27-b500-08dc9a985192',
    permissionsName: 'OpenLeadFile',
    description: null,
    componentsId: null,
    components: null,
  },
};
