import React, { useEffect, useState } from 'react';
import { GetUnitRequestLogs } from '../../../Services/ApprovalsHistoryServices/ApprovalsHistoryServices';
import { PaginationComponent, Spinner, Tables } from '../../../Components';
import './style/ApprovalsHistoryView.scss';
import { useTranslation } from 'react-i18next';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@material-ui/core';
import { bottomBoxComponentUpdate } from '../../../Helper';

export const ApprovalsHistoryView = () => {
  const parentTranslationPath = 'UsersView';
  const translationPath = 'UserApprovals.';
  const { t } = useTranslation(parentTranslationPath);
  const [isLoading, setIsLoading] = useState(false);
  const [requestLogs, setRequestLogs] = useState({
    result: [],
    totalCount: 0,
  });
  const [activeStep, setActiveStep] = useState(null);
  const [filter, setFilter] = useState({
    pageIndex: 0,
    pageSize: 25,
  });

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US');
  };

  const getUnitRequestLogs = async () => {
    setIsLoading(true);
    const body = {
      pageIndex: filter.pageIndex,
      pageSize: filter.pageSize,
    };
    const res = await GetUnitRequestLogs(body);
    if (!(res && res.status && res.status !== 200)) {
      setRequestLogs(res);
    }
    setIsLoading(false);
  };

  const handleShowClick = (unitRequestManagementId) => {
    setActiveStep(
      unitRequestManagementId === activeStep ? null : unitRequestManagementId
    );
  };

  useEffect(() => {
    getUnitRequestLogs();
  }, [filter]);

  const onPageIndexChanged = (pageIndex) => {
    setFilter((item) => ({ ...item, pageIndex }));
  };

  const onPageSizeChanged = (pageSize) => {
    setFilter((item) => ({ ...item, pageIndex: 0, pageSize }));
  };

  useEffect(() => {
    return () => {
      bottomBoxComponentUpdate(null);
    };
  }, []);
  const allowedFields = [
    'rent_price_fees.rentPerMonth',
    'rent_price_fees.rentPerYear',
    'sale_type.lookupItemName',
    'property_name.name',
    'Status.status',
    'unit_number',
    'bedrooms',
    'bathrooms',
    'selling_price_agency_fee.basePrice',
    'selling_price_agency_fee.sellingPrice',
    'selling_price_agency_fee.discount',
    'unit_type.lookupItemName',
    'builtup_area_sqft',
    'builtup_area_sqm',
  ];

  useEffect(() => {
    bottomBoxComponentUpdate(
      <PaginationComponent
        pageIndex={filter.pageIndex}
        pageSize={filter.pageSize}
        totalCount={requestLogs.totalCount}
        onPageIndexChanged={onPageIndexChanged}
        onPageSizeChanged={onPageSizeChanged}
      />
    );
  });

  const StatusCell = ({ row }) => {
    const statusClassMap = {
      approved: 'pass-lapel',
      rejected: 'Fail-lapel',
      pending: 'Pending-lapel',
      expired: 'Expired-lapel',
    };

    const statusKey = row.requestStatusName.toLowerCase();
    const statusClass = statusClassMap[statusKey] || null;

    return (
      <TableCell align='right'>
        <div className='d-flex-center flex-h-center'>
          {statusClass ? (
            <div className={statusClass}>{row.requestStatusName}</div>
          ) : (
            'N/A'
          )}
        </div>
      </TableCell>
    );
  };

  const Row = ({ row, index }) => {
    const filteredChangeHistory = row.changeHistory?.filter((change) =>
      allowedFields.includes(change.fieldName)
    );
    return (
      <>
        <TableRow
          className={`TableRow-TableBody-aml ${
            activeStep === row.unitRequestManagementId ? 'isselect' : ''
          }`}
        >
          <TableCell align='right'>{row.unitId || '-'}</TableCell>
          <TableCell align='right'>{row.requesterName || '-'}</TableCell>
          <TableCell align='right'>
            {row.requestDate ? formatDate(row.requestDate) : '-'}
          </TableCell>
          <StatusCell row={row} />
          <TableCell align='right'>{row.actionByName || '-'}</TableCell>
          <TableCell align='right'>
            {row.actionDate ? formatDate(row.actionDate) : '-'}
          </TableCell>
          <TableCell align='right'>
            <IconButton
              aria-label='expand row'
              size='small'
              onClick={() => handleShowClick(row.unitRequestManagementId)}
            >
              {activeStep === row.unitRequestManagementId ? (
                <KeyboardArrowUpIcon />
              ) : (
                <KeyboardArrowDownIcon />
              )}
            </IconButton>
          </TableCell>
        </TableRow>
        {activeStep === row.unitRequestManagementId && (
          <TableRow>
            <TableCell colSpan={7} style={{ maxWidth: '1557px' }}>
              <div>
                <Tables
                  headerData={[
                    {
                      id: 1,
                      label: 'field-name',
                      component: (el) => {
                        const fieldNames = Array.isArray(el?.fieldName)
                          ? el.fieldName
                          : [el.fieldName];
                        const filteredFieldNames = fieldNames?.filter(
                          (element) => allowedFields.includes(element)
                        );
                        return (
                          <span>
                            {filteredFieldNames?.map((element, index) => (
                              <span key={index}>
                                {t(
                                  `${translationPath}${element.replace(
                                    '.',
                                    '-'
                                  )}`
                                )}
                              </span>
                            )) || 'N/A'}
                          </span>
                        );
                      },
                    },
                    {
                      id: 2,
                      label: 'changed-from',
                      component: (el) => {
                        let changeFromValue = '';
                        if (el.changeFrom === '10') changeFromValue = 'Draft';
                        else if (el.changeFrom === '1')
                          changeFromValue = 'Available';
                        else changeFromValue = el.changeFrom;
                        return <span>{changeFromValue}</span> || 'N/A';
                      },
                    },
                    {
                      id: 3,
                      label: 'changed-to',
                      component: (el) => {
                        let changeToValue = '';
                        if (el.changeTo === '10') changeToValue = 'Draft';
                        else if (el.changeTo === '1')
                          changeToValue = 'Available';
                        else changeToValue = el.changeTo;
                        return <span>{changeToValue}</span>  || 'N/A';
                      },
                    },
                  ]}
                  data={filteredChangeHistory || []}
                  itemsPerPage={25}
                  isLoading={isLoading}
                  defaultActions={[]}
                  translationPath={translationPath}
                  parentTranslationPath={parentTranslationPath}
                />
              </div>
            </TableCell>
          </TableRow>
        )}
      </>
    );
  };

  const rows = requestLogs.result.map((row, rowIndex) => ({
    ...row,
    rowIndex,
  }));

  return (
    <div>
      <Spinner isActive={isLoading} />
      <div className='table-header'>
        <h2>{t(`${translationPath}approvals-log`)}</h2>
      </div>
      <div className='history-table-approvals-wrapper table-container'>
        <TableContainer component={Paper}>
          <Table>
            <TableHead className='table-head'>
              <TableRow>
                <TableCell align='center'>
                  {t(`${translationPath}unit-id`)}
                </TableCell>
                <TableCell align='center'>
                  {t(`${translationPath}requester-name`)}
                </TableCell>
                <TableCell align='center'>
                  {t(`${translationPath}request-date`)}
                </TableCell>
                <TableCell align='center'>
                  {t(`${translationPath}request-status`)}
                </TableCell>
                <TableCell align='center'>
                  {t(`${translationPath}action-by`)}
                </TableCell>
                <TableCell align='center'>
                  {t(`${translationPath}action-date`)}
                </TableCell>
                <TableCell align='center'></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <Row key={row.unitRequestManagementId} row={row} />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
};
