import { makeStyles } from '@material-ui/core/styles';
import { useIsAr } from '../../../Hooks';

export default makeStyles((theme) => {
  const { isAr } = useIsAr(theme.direction);

  return {
    contactSideMenu: {
      position: 'fixed',
      top: 0,
      ...(isAr ? {left: 0} : {right: 0}),
      width: '507px',
      height: '100%',
      backgroundColor: theme.palette.background.primary,
      zIndex: theme.zIndex.drawer + 2,
      overflowY: 'auto',
      transition: 'transform 0.3s ease-in-out',
      boxShadow: theme.shadows[7],
      padding: "32px 0px",
      borderRight: `1px solid ${theme.palette.border.primary}`,
      borderLeft: `1px solid ${theme.palette.border.primary}`,
      borderRadius: theme.borderRadius[0],
      [theme.breakpoints.down('xs')]: {
        width: '100%',
      },
    },
    hidden: {
      transform: isAr ? 'translateX(-100%)' : 'translateX(100%)',
      visibility: 'hidden',
      opacity: 0,
    },
    headingContainer: {
      padding: '0px 32px 16px',
      display: 'flex',
      justifyContent: 'space-between',
      [theme.breakpoints.down(321)]: {
        padding: '0px 12px 16px',
      },
    },
    menuHeading: {
      fontWeight: 600,
      fontSize: '30px',
      lineHeight: '38px',
      color: theme.palette.text.primary,
      [theme.breakpoints.down(321)]: {
        fontSize: '24px',
      },
    },
    detailsContainer: {
      position: 'relative',
    },
    detailsBg: {
      background: theme.palette.utility.brand_200,
      height: '68px',
      marginBottom: '57px',
    },
    detailsContent: {
      padding: '0px 32px',
      position: 'absolute',
      top: '20px',
      width: '100%',
      [theme.breakpoints.down(321)]: {
        padding: '0px 12px',
      },
    },
    avatarWithIcon: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    starIcon: {
      marginTop: '5px',
      cursor: 'pointer',
    },

    // avatar styles
    avatarWrapper: {
      position: "relative",
      alignItems: "center",
      backgroundColor: "#FFF",
      borderRadius: theme.borderRadius[10],
      boxShadow: theme.shadows[4],
      display: "flex",
      height: "104px",
      width: "104px",
      justifyContent: "center",
    },
    defaultAvatar: {
      backgroundColor: "#F2F4F7", // avatar background
      border: `1px solid ${theme.palette.border.secondary}`,
      height: "96px",
      width: "96px",

      "& img": {
        height: "48px",
        width: "48px",
      },
    },
    avatar: {
      backgroundColor: "#F2F4F7", // avatar background
      border: `1px solid ${theme.palette.border.secondary}`,
      height: "96px",
      width: "96px",
    },
    contactDetails: {
      marginTop: "16px",
    },

    nameContainer: {
      display: 'flex',
      alignItems: 'center',
      gap: '8px',
      marginBottom: '8px',
    },
    prefix: {
      fontWeight: 400,
      fontSize: '20px',
      lineHeight: '30px',
      color: theme.palette.text.primary,
      direction: 'ltr',
    },
    fullName: {
      fontWeight: 600,
      fontSize: '24px',
      lineHeight: '32px',
      color: theme.palette.text.primary,
      [theme.breakpoints.down(321)]: {
        fontSize: '20px',
      },
    },

    actionsContainer: {
      display: 'flex',
      gap: '12px',
      marginBottom: '16px',
    },

    badgesContainer: {
      marginBottom: '16px',
      display: 'flex',
      gap: '6px',
    },

    socialLinksContainer: {
      margin: '16px 0'
    },

    contactInfoContainer: {
      marginBottom: '8px',
      display: 'flex',
      gap: '8px',
      alignItems: "baseline",
    },
    contactTypeLabel: {
      fontWeight: 400,
      fontSize: "16px",
      color: theme.palette.text.tertiary,
    },
    isBulk: {
      fontWeight: 700,
      fontSize: "14px",
      color: theme.palette.text.tertiary,
    },
    badgeSection: {
      display: "flex",
      gap: "8px",
    },
    idSection: {
      alignItems: "center",
      color: theme.palette.button.tertiary_fg,
      display: "flex",
      gap: "8px",
      justifyContent: "center",

      "& label": {
        fontSize: "16px",
        fontWeight: 600,
      },
    },
  }
});
