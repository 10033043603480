import { config } from '../../config/config';
import { HttpServices } from '../../Helper';

export const GetBranchesBillingInfo = async (data) => {
  try {
    const result = await HttpServices.get(
      `${config.server_address}/Identity/Billing/GetBranchesBillingInfo`,
      { params: data }
    );
    return result;
  } catch (error) {
    return error.response;
  }
};
export const GetBranchesBillingHistoryInfo = async (data) => {
  try {
    const result = await HttpServices.get(
      `${config.server_address}/Identity/Billing/GetBranchesBillingHistoryInfo`,
      { params: data }
    );
    return result;
  } catch (error) {
    return error.response;
  }
};
export const GetBranchBillingUsersDetails = async (branchId) => {
  try {
    const result = await HttpServices.get(
      `${config.server_address}/Identity/Billing/GetBranchBillingUsersDetails`,
      { params: { branchId } }
    );
    return result;
  } catch (error) {
    return error.response;
  }
};
export const GetAllUsersForBillingByBranchId = async (data) => {
  try {
    const result = await HttpServices.get(
      `${config.server_address}/Identity/Billing/GetAllUsersForBillingByBranchId`,
      { params: data }
    );
    return result;
  } catch (error) {
    return error.response;
  }
};

export const GetInvoiceDetails = async (data) => {
  try {
    const result = await HttpServices.get(
      `${config.server_address}/Identity/Billing/GetInvoiceDetails`,
      { params: data }
    );
    return result;
  } catch (error) {
    return error.response;
  }
};
export const ShareInvoice = async ({ body, branchId, isForHistory }) => {
  try {
    const result = await HttpServices.post(
      `${config.server_address}/Identity/Billing/ShareInvoice?isForHistory=${isForHistory}&branchId=${branchId}`,
      body
    );
    return result;
  } catch (error) {
    return error.response;
  }
};
