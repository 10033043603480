
export const TaskConfigurationAssignmentType = {
    CreatedBy: {
        key: 1,
        value: 'CreatedBy',
        label: 'Creator',
    },
    AssignTo: {
        key: 2,
        value: 'AssignTo',
        label: 'Assignee',
    }
}