
import React  from 'react';
import { useTranslation } from 'react-i18next';
import Rating from '@material-ui/lab/Rating';

  export const BasementParking = ({
    parentTranslationPath ,
    translationPath ,
    onChangeSelectedItem , 
    selectedValues ,
    setSelectValues , 
   } ) => { 

    const { t } = useTranslation(parentTranslationPath);

    return (
      <>
        <div className="d-flex d-flex-v-center">
          <span className="fw-bold">
            {t(`${translationPath}basement-parking`)} 
          </span>
        </div> 
        <div className='w-75 parking-distribution'>
        <div className="d-flex d-flex-v-center parking-distribution-card mt-2 mb-2">
        <div className='d-flex d-flex-v-center'>
           <span className='d-inline-flex'></span>
         </div>
            <div>
            <Rating  
                size="large"
                name={`RatingBasementParking`}
                value={(selectedValues &&  selectedValues.basementCarPark && selectedValues.basementCarPark.score) || 0}
                max={10}
                precision={1}
                onChange={(event, newValue) => 
                {
                  onChangeSelectedItem((selectedValues.basementCarPark?.lookupItemName) || 'Basement CarPark' , newValue || 0 );
                  const newUpdateObj = selectedValues.basementCarPark ? {...selectedValues.basementCarPark , score : (newValue || 0 ) } : null
                  setSelectValues((item)=> ({...item , basementCarPark : newUpdateObj }))
                  
                }}
                />     
            </div>
         </div>  
        </div>
      </>
    );
  } ; 
  
