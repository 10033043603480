import React, {
  useCallback, useState, useReducer, useEffect
} from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Joi from 'joi';
import {
  Box,
  ButtonBase,

  Step,
  StepContent,
  StepLabel,
  Stepper,
  Typography
} from '@material-ui/core';
import { RotationSchemaPermissions } from '../../../../../Permissions';
import {
  CreateRotationSchemeServices,
  DeleteAgentByRotationScheme,
  UpdateRotationScheme,
} from '../../../../../Services/RotaionSchemaService/RotationSchemaService';
import { SchemaSetting } from './SchemaSetting';
import { RotationCriteriaDialogContent } from './RotationCriteriaDialogContent';
import {
  showError, showSuccess, returnPropsByPermissions
} from '../../../../../Helper';
import {
  DialogComponent,
} from '../../../../../Components';
import { HistoryTabsComponent } from '../../../../../Components/HistoryComponent/HistoryTabsComponent';

export const
  RotationCriteriaDialog = ({
    parentTranslationPath,
    translationPath,
    open,
    close,
    onSave,
    rotationEdit,
    isEdit,
    setRotationEdit,
  }) => {
    const [validation, setValidation] = useState(false);
    const [isOpenHistoryDialog, setIsOpenHistoryDialog] = useState(false);
    const [activeStep, setActiveStep] = React.useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [DoDeleteAPI, setDoDeleteAPI] = useState([]);
    const [currentSelected, setCurrentSelected] = useState({
      propertyCampaignIds: [],
      rotationSchemaMethodOfContacts: [],
      rotationSchemaLeadsType: [],
      rotationPreferredLanguages: [],
      rotationSchemeBranches: [],
      rotationSchemeTeams: [],
    });
    useEffect(() => {
      if (rotationEdit === undefined || rotationEdit === null) return;
      const currntState = {
        rotationSchemeId: null,
        label: '',
        rotationPreferredLanguages: [],
        rotationSchemeCountries: [],
        isForOwnerLeads: true,
        rotationSchemeCities: [],
        rotationSchemeCommunities: [],
        rotationSchemeDistricts: [],
        rotationSchemeSubCommunities: [],
        rotationSchemeUnitTypes: [],
        rotationSchemeRanges: [],
        rotationSchemaContactCLasses: [],
        rotationSchemeMedias: [],
        rotationSchemaDeveloperIds: [],
        rotationSchemaReferredBys: [],
        rotationSchemeProperties: [],
        rotationSchemaLeadsType: [],
        rotationSchemaMethodOfContacts: [],
        propertyCampaignIds: [],
        isActive: false,
        rotationSchemeTeams: [],
        rotationSchemeBranches: [],
        sla: null,
        isBulk: 2,
        isOverrideLeadCapacitySettingEnabled: true,
        rotationSchemeLeadDistributionType: 4,
      };
      currntState.rotationSchemeId = rotationEdit.rotationSchemeId;
      if (rotationEdit) {
        rotationEdit.languages.map((item) => {
          currntState.rotationPreferredLanguages.push({
            languageName: item.lookupItemName,
            languageId: item.lookupsId,
          });
        });
        rotationEdit.countries.map((item) => {
          currntState.rotationSchemeCountries.push({
            countryName: item.lookupItemName,
            countryId: item.lookupsId,
          });
        });
        rotationEdit.cities.map((item) => {
          currntState.rotationSchemeCities.push({
            cityName: item.lookupItemName,
            cityId: item.lookupsId,
          });
        });
        rotationEdit.districts.map((item) => {
          currntState.rotationSchemeDistricts.push({
            districtName: item.lookupItemName,
            districtId: item.lookupsId,
          });
        });
        rotationEdit.communities.map((item) => {
          currntState.rotationSchemeCommunities.push({
            communityName: item.lookupItemName,
            communityId: item.lookupsId,
          });
        });
        rotationEdit.subCommunities.map((item) => {
          currntState.rotationSchemeSubCommunities.push({
            subCommunityName: item.lookupItemName,
            subCommunityId: item.lookupsId,
          });
        });
        rotationEdit.leadClasses.map((item) => {
          currntState.rotationSchemaContactCLasses.push({
            contactClassId: item.lookupsId,
            contactClassName: item.lookupItemName
          });
        });
        rotationEdit.unitTypes.map((item) => {
          currntState.rotationSchemeUnitTypes.push({
            unitTypeName: item.lookupItemName,
            unitTypeId: item.lookupsId,
          });
        });
        rotationEdit.developers.map((item) => {
          currntState.rotationSchemaDeveloperIds.push({
            developerName: item.developerName,
            developerId: item.developerId,
          });
        });

        rotationEdit.properties.map((item) => {
          currntState.rotationSchemeProperties.push({
            propertyName: item.propertyName,
            propertyId: item.propertyId,
          });
        });

        rotationEdit.referredBys.map((item) => {
          currntState.rotationSchemaReferredBys.push({
            userId: item.userId,
            fullName: item.fullName
          });
        });

        rotationEdit.rotationSchemeTeams.map((item) => {
          currntState.rotationSchemeTeams.push({
            teamId: item.teamId,
            teamName: item.teamName
          });
        });

        rotationEdit.rotationSchemeBranches.map((item) => {
          currntState.rotationSchemeBranches.push({
            branchId: item.branchId,
            branchName: item.branchName
          });
        });

        rotationEdit.medias.map((item) => {
          currntState.rotationSchemeMedias.push({
            mediaName: item.mediaName,
            mediaNameId: item.mediaId === 0 ? null : item.mediaId,
            mediaDetailsName: item.mediaDetails,
            mediaDetailsId: item.mediaDetailsId === 0 ? null : item.mediaDetailsId,
          });
        });
        rotationEdit.rotationSchemeRanges.map((item) => {
          currntState.rotationSchemeRanges.push({
            ...item,
          });
        });
        rotationEdit.methodOfContact.map((item) => {
          currntState.rotationSchemaMethodOfContacts.push({
            methodOfContactName: item.methodOfContactName,
            methodOfContactId: item.methodOfContactId
          });
        });
        rotationEdit.rotationSchemeCampaigns.map((item) => {
          currntState.propertyCampaignIds.push(item.propertyCampaignId);
        });
        rotationEdit.leadsType.map((item) => {
          currntState.rotationSchemaLeadsType.push({
            leadClass: item.leadClass.toLowerCase()
          });
        });

        currntState.label = rotationEdit.label;
        currntState.sla = rotationEdit.sla;
        currntState.order = rotationEdit.order;
        currntState.isActive = rotationEdit.isActive;
        currntState.isBulk = rotationEdit.isBulk;
        currntState.isOverrideLeadCapacitySettingEnabled = rotationEdit.isOverrideLeadCapacitySettingEnabled;
        currntState.rotationSchemeLeadDistributionType = rotationEdit.rotationSchemeLeadDistributionType;
        setState({ id: 'edit', value: currntState });
        setCurrentSelected((e) => ({
          ...e,
          propertyCampaignIds: rotationEdit && rotationEdit.rotationSchemeCampaigns || [],
          rotationSchemaLeadsType: rotationEdit && rotationEdit.leadsType || [],
          rotationSchemaMethodOfContacts: rotationEdit && rotationEdit.methodOfContact || [],
          rotationPreferredLanguages: rotationEdit && rotationEdit.languages || [],
          rotationSchemeBranches: rotationEdit && rotationEdit.rotationSchemeBranches || [],
          rotationSchemeTeams: rotationEdit && rotationEdit.rotationSchemeTeams || [],
        }));
      }
    }, [rotationEdit]);
    const { t } = useTranslation([parentTranslationPath, 'Shared']);
    const [isSubmitted, setIsSubmitted] = useState(false);

    const [saveIsDisabled, setSaveIsDisabled] = useState(false);

    const reducer = useCallback((state, action) => {
      if (action.id !== 'edit') return { ...state, [action.id]: action.value };
      return {
        ...action.value,
      };
    }, []);

    const [state, setState] = useReducer(reducer, {
      rotationSchemeId: null,
      label: '',
      order: 0,
      isForOwnerLeads: true,
      rotationPreferredLanguages: [],
      rotationSchemeCountries: [],
      rotationSchemeCities: [],
      rotationSchemeCommunities: [],
      rotationSchemeDistricts: [],
      rotationSchemeSubCommunities: [],
      rotationSchemeUnitTypes: [],
      rotationSchemeRanges: [],
      rotationSchemaContactCLasses: [],
      rotationSchemeMedias: [],
      rotationSchemaDeveloperIds: [],
      rotationSchemaReferredBys: [],
      rotationSchemeProperties: [],
      rotationSchemaLeadsType: [],
      rotationSchemaMethodOfContacts: [],
      propertyCampaignIds: [],
      rotationSchemeTeams: [],
      rotationSchemeBranches: [],
      sla: null,
      isActive: false,
      isBulk: 2,
      rotationSchemeLeadDistributionType: 4,
      isOverrideLeadCapacitySettingEnabled: true,
    });
    const schema = Joi.object({
      label: Joi.string()
        .required()
        .messages({
          'string.empty': t`${translationPath}label-is-required`,
        }),
      order: Joi.number()
        .required()
        .min(1)
        .messages({
          'number.base': t(`${translationPath}order-is-required`),
          'number.empty': t`${translationPath}order-is-required`,
        }),
      rotationSchemaReferredBys: Joi.array()
        .required()
        .min(1)
        .messages({
          'array.empty': t(`${translationPath}ReferredBy-is-required`),
          'array.min': t(`${translationPath}ReferredBy-is-required`),
        }),
      // rotationSchemaContactCLasses: Joi.array()
      //   .required()
      //   .min(1)
      //   .messages({
      //     'array.empty': t(`${translationPath}lead-class-is-required`),
      //     'array.min': t(`${translationPath}lead-class-is-required`),
      //   }),
      rotationSchemaLeadsType: Joi.array()
        // .items(Joi.object({ leadClass: Joi.any() }))
        .required()
        .min(1)
        .messages({
          'array.empty': t(`${translationPath}lead-type-is-required`),
          'array.min': t(`${translationPath}lead-type-is-required`),
        }),
    })
      .options({
        abortEarly: false,
        allowUnknown: true,
      })
      .validate(state);
    const onStateChanged = (newValue) => {
      setState(newValue);
    };
    const onScemaChanged = (newValue, type) => {
      setDoDeleteAPI(newValue);
    };
    const saveHandler = useCallback(async () => {
      setIsLoading(true);
      setValidation(true);
      setSaveIsDisabled(true);
      setIsSubmitted(true);
      if (schema.error) {
        showError(t('Shared:please-fix-all-errors'));
        setSaveIsDisabled(false);
        setIsLoading(false);
        return;
      }
      setTimeout(async () => {
        const rotationSchemeId = rotationEdit ? rotationEdit.rotationSchemeId : 0;
        const res = rotationEdit ?
          await UpdateRotationScheme(rotationSchemeId, state)
          && DoDeleteAPI === 'edit' && await DeleteAgentByRotationScheme(rotationSchemeId) :
          await CreateRotationSchemeServices(state);
        if (!(res && res.status && res.status !== 200)) {
          if (!rotationEdit) showSuccess(t`${translationPath}Create-Rotation-Scheme-successfully`);
          else showSuccess(t`${translationPath}Edit-Rotation-Scheme-successfully`);
          onSave();
          close();
          setIsLoading(false);
        } else {
          setSaveIsDisabled(false);
          const errorMessage = (res && res.data && res.data.Message).split(': ')[1];
          if (errorMessage === '___DUPLICATE_ROTATION_SCHEME_ORDER___') showError(t(`${translationPath}${errorMessage}`));
          else showError(t('Shared:Server-Error'));
          setIsLoading(false);
        }
      }, 1000);
    }, [state]);

    useCallback(() => {
      setValidation(true);
    }, []);

    const steps = [
      {
        label: t(`${translationPath}Specific-Criteria-for-Rotation`),
        step: 1,
        Sublabel: t(`${translationPath}FirstStep`),
      },
      {
        label: t(`${translationPath}Schema-Settings`),
        Sublabel: t(`${translationPath}LastStep`),
        step: 2
      },
      // {
      //   label: 'Assignee New Agents',
      //   step: 3
      // },
    ];

    const handleNext = () => {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    return (
      <div>
        {/* <Spinner isActive={isLoading} /> */}
        <DialogComponent
          titleText={(!rotationEdit ? 'add-new-rotation-criteria' : 'Edit-rotation-criteria')}
          saveText='save'
          cancelText='cancel'
          saveType='button'
          maxWidth='lg'
          subTitle={(rotationEdit ? rotationEdit.label : '')}
          subTitleClass='sub-title-wrapper'
          wrapperClasses='rotationCriteriaDialog-dialog-wrapper'
          titleTextClasses='title-text'
          contentClasses='content-wrapper'
          dialogContent={(
            <div className='OwnerRotationView-DialogComponent  d-flex'>
              <div className='Stepper-Stepper'>
                {' '}
                <Box sx={{ maxWidth: 400 }}>
                  <Stepper activeStep={activeStep} orientation='vertical'>
                    {steps.map((step, index) => (
                      <Step key={step.label}>
                        <StepLabel
                          optional={
                            <Typography variant='caption'>{step.Sublabel}</Typography>
                          }
                        >
                          {step.label}
                        </StepLabel>
                        <StepContent>
                          <Typography>{step.description}</Typography>
                          <Box sx={{ mb: 2 }}>
                            <div />
                          </Box>
                        </StepContent>
                      </Step>
                    ))}
                  </Stepper>
                  {/* {activeStep === steps.length && ( */}

                </Box>
                <div className='d-flex'>
                  {/* <Button
                    className='btns theme-solid bg-cancel'
                    onClick={() => {

            }}
                  >
                    {t('shared.cancel')}
                  </Button> */}

                  <div className='save-wrapper d-inline-flex-center'>
                    <button
                      onClick={() => { handleBack(); }}
                      className='MuiButtonBase-root MuiButton-root MuiButton-text save-btn-wrapper btns theme-solid bg-warning w-100 mx-2 mb-2'
                      tabIndex='0'
                      disabled={activeStep === 0}
                      type='button'
                      id='savenull_save-btn_submit'
                    >
                      <span className='MuiButton-label'>
                        <span>
                          {t(`${translationPath}Back`)}
                          {' '}
                        </span>
                      </span>
                      <span className='MuiTouchRipple-root' />
                    </button>
                  </div>

                  <div className='save-wrapper d-inline-flex-center'>
                    <button
                      onClick={() => {
                        if (activeStep === 0)
                          handleNext();
                        else if (activeStep === 1)
                          saveHandler();
                      }}
                      className='MuiButtonBase-root MuiButton-root MuiButton-text save-btn-wrapper btns theme-solid  w-100 mx-2 mb-2'
                      tabIndex='0'
                      type='button'
                      disabled={isLoading}
                      id='savenull_save-btn_submit'
                    >
                      <span className='MuiButton-label'>
                        <span>
                          {activeStep === 1 && t(`${translationPath}Save`) ||

                            t(`${translationPath}Next`)}
                        </span>
                      </span>
                      <span className='MuiTouchRipple-root' />
                    </button>
                  </div>

                </div>
              </div>

              {activeStep === 0 && (
                <RotationCriteriaDialogContent
                  parentTranslationPath={parentTranslationPath}
                  translationPath={translationPath}
                  onStateChanged={onStateChanged}
                  onScemaChanged={onScemaChanged}
                  state={state}
                  schema={schema}
                  isSubmitted={isSubmitted}
                  rotationEdit={rotationEdit}
                  currentSelected={currentSelected}
                  setCurrentSelected={setCurrentSelected}
                  validation={validation}
                />
              )}
              {activeStep === 1 && (
                <SchemaSetting
                  parentTranslationPath={parentTranslationPath}
                  translationPath={translationPath}
                  onStateChanged={onStateChanged}
                  onScemaChanged={onScemaChanged}
                  state={state}
                  schema={schema}
                  isSubmitted={isSubmitted}
                  rotationEdit={rotationEdit}
                  currentSelected={currentSelected}
                  setCurrentSelected={setCurrentSelected}
                  validation={validation}
                />
              )}
              {

                rotationEdit && returnPropsByPermissions(RotationSchemaPermissions.RotationSchemaHistory.permissionsId) && (
                  <div>
                    <span className='completed-history-wrapper'>
                      <ButtonBase onClick={() => setIsOpenHistoryDialog(true)} className='btns c-black-light history-button'>
                        <span className='mdi mdi-clock-time-four-outline' />
                        {t`${translationPath}history`}
                      </ButtonBase>
                    </span>
                  </div>
                )
              }
            </div>

          )}
          cancelClasses='btns cancel-btn-wrapper'
          isOpen
          onCloseClicked={close}
          onCancelClicked={close}
          saveIsDisabled={saveIsDisabled}
          SmothMove
          translationPath={translationPath}
          parentTranslationPath={parentTranslationPath}
          disableBackdropClick
        />
        <HistoryTabsComponent
          isOpen={isOpenHistoryDialog}
          isOpenChanged={() => setIsOpenHistoryDialog(false)}
          typeId='rotationSchema'
          rotationSchemeId={(rotationEdit && rotationEdit.rotationSchemeId)}
        />

      </div>
    );
  };
RotationCriteriaDialog.propTypes = {
  parentTranslationPath: PropTypes.string.isRequired,
  translationPath: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  rotationEdit: PropTypes.instanceOf(Object),
  isEdit: PropTypes.bool,
};
RotationCriteriaDialog.defaultProps = {
  rotationEdit: null,
  isEdit: false,
  setRotationEdit: () => { },
};
