import React, {
  useState,
  useEffect,
  useCallback,
  useReducer,
  useRef,
} from "react";
import { GetInquiryLog } from "../../../../Services";
import {
  DialogComponent,
  Inputs,
  PaginationComponent,
  Spinner,
  Tables,
} from "../../../../Components";
import { TableActions } from "../../../../Enums";
import { InquiryLogHeadData } from "./InquiryLogHeadData";
import InquiryAgentDialog from "./InquiryAgentDialog";
import { useTranslation } from "react-i18next";
import { bottomBoxComponentUpdate } from "../../../../Helper";

function InquiryLogView() {
  const reducer = useCallback((state, action) => {
    if (action.id !== "edit") return { ...state, [action.id]: action.value };
    return {
      ...action.value,
    };
  }, []);
  const parentTranslationPath = "InquiryLogs";
  const translationPath = "";
  const { t } = useTranslation(parentTranslationPath);
  const searchTimer = useRef(null);
  const [data, setData] = useState([]);
  const [headerData, setHeaderData] = useState([]);
  const [activeItem, setActiveItem] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [inquiryId, setInquiryId] = useState(null);
  const [filter, setFilter] = useState({
    pageSize: parseInt(localStorage.getItem("Pagination")) || 25,
    pageIndex: 0,
  });

  const GetAllInquiryLogs = async () => {
    setIsLoading(true);
    const res = await GetInquiryLog(filter, inquiryId);

    if (!(res && res.status && res.status !== 200)) {
      setData(res);
    } else {
      setData([]);
    }
    setIsLoading(false);
  };

  const getActionTableWithPermissions = () => {
    const list = [
      {
        enum: TableActions.view.key,
        isDisabled: false,
        externalComponent: null,
      },
    ];

    return list;
  };

  const tableActionClicked = useCallback(
    (actionEnum, item, focusedRow, event) => {
      if (actionEnum === TableActions.view.key) {
        setIsDialogOpen(true);
        setActiveItem(item);
      }
    },
    []
  );

  const onPageIndexChanged = (pageIndex) => {
    setFilter((item) => ({ ...item, pageIndex }));
  };
  const onPageSizeChanged = (pageSize) => {
    setFilter((item) => ({ ...item, pageIndex: 0, pageSize }));
  };

  useEffect(() => {
    GetAllInquiryLogs();
  }, [filter]);

  useEffect(() => {
    bottomBoxComponentUpdate(
      <PaginationComponent
        pageIndex={filter?.pageIndex}
        pageSize={filter?.pageSize}
        totalCount={data?.totalCount}
        onPageIndexChanged={onPageIndexChanged}
        onPageSizeChanged={onPageSizeChanged}
      />
    );
  });

  async function insertHeaderData() {
    const tempHeaderData = await InquiryLogHeadData();
    setHeaderData(tempHeaderData);
  }

  useEffect(() => {
    insertHeaderData();
  }, []);

  return (
    <div className="mx-3 mt-4">
      <Spinner isActive={isLoading} isAbsolute />

      <div className="my-4 mx-3 w-50">
      <Inputs
          idRef="searchInquiryByInquiryId"
          labelValue="search-by-inquiry-id"
          inputPlaceholder="inquiry-id"
          value={filter?.inquiryId || null}
          onInputChanged={(event) => {
            let newValue = +event.target.value || null;
            if (newValue >= 0) {
              setInquiryId(newValue);
            }
          }}
          onKeyUp={(e) => {
            if (searchTimer.current) clearTimeout(searchTimer.current);
            searchTimer.current = setTimeout(() => {
              GetAllInquiryLogs();
            }, 700);
          }}
          type="number"
          min={0}
          translationPath={translationPath}
          parentTranslationPath={parentTranslationPath}
        />
      </div>

      <Tables
        data={data?.result || []}
        headerData={headerData || []}
        activePage={0}
        defaultActions={getActionTableWithPermissions()}
        actionsOptions={{
          onActionClicked: tableActionClicked,
        }}
        translationPath={translationPath}
        parentTranslationPath={parentTranslationPath}
        totalItems={data?.result}
        itemsPerPage={filter?.pageSize}
      />
      {isDialogOpen && (
        <DialogComponent
          titleText={t("inquiry-agent-dialog")}
          isOpen={isDialogOpen}
          maxWidth="lg"
          dialogContent={
            <InquiryAgentDialog
              activeItem={activeItem}
              isDialogOpen={isDialogOpen}
              setIsDialogOpen={setIsDialogOpen}
              translationPath={translationPath}
              parentTranslationPath={parentTranslationPath}
            />
          }
        />
      )}
    </div>
  );
}

export default InquiryLogView;
