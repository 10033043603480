import React from "react";

function EyeIcon({ fill, ...restProps }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill={fill ?? ""}
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <path
        d="m11.32 4.023-.52.06c-3.276.374-6.567 2.683-8.985 6.303-.524.785-.653 1.104-.653 1.614 0 .509.123.814.648 1.604 2.12 3.192 4.969 5.404 7.87 6.11.872.213 1.324.264 2.32.264.996 0 1.448-.051 2.32-.264 2.899-.706 5.727-2.899 7.865-6.1.524-.785.653-1.104.653-1.614s-.129-.829-.653-1.614c-1.697-2.541-3.849-4.467-6.154-5.509a10.053 10.053 0 0 0-2.691-.78c-.389-.057-1.739-.106-2.02-.074m1.74 2.061c1.949.278 3.713 1.247 5.483 3.014.792.79 1.327 1.437 1.923 2.324l.388.578-.388.578a14.196 14.196 0 0 1-1.923 2.323c-1.586 1.583-3.053 2.467-4.803 2.894-.556.135-1.019.184-1.74.184-.721 0-1.184-.049-1.74-.184-1.75-.427-3.222-1.314-4.803-2.893a14.37 14.37 0 0 1-1.923-2.324L3.146 12l.388-.578a14.287 14.287 0 0 1 1.923-2.324C7.404 7.154 9.265 6.21 11.52 6.024c.27-.023 1.221.014 1.54.06m-1.702 1.977c-1.117.174-2.202.904-2.779 1.871a4 4 0 0 0 1.933 5.772c2.32.935 4.903-.465 5.405-2.931.451-2.21-1.072-4.377-3.312-4.715a3.728 3.728 0 0 0-1.247.003m1.12 2.001c.672.153 1.272.739 1.449 1.415a1.984 1.984 0 0 1-.58 1.988 1.976 1.976 0 0 1-2.277.31c-.297-.15-.708-.577-.863-.895a2.003 2.003 0 0 1 .332-2.231c.505-.544 1.202-.754 1.939-.587"
        fill={fill ?? ""}
      />
    </svg>
  );
}

export default EyeIcon;
