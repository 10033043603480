import React, { useCallback, useEffect, useReducer, useState } from 'react';
import { ButtonBase, DialogActions } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import Joi from 'joi';
import { config } from '../../config';
import { StaticLookupsIds } from '../../assets/json/StaticLookupsIds';
import {
  DialogComponent,
  AutocompleteComponent,
  Inputs,
  Spinner,
} from '../../Components';
import { ImageUploader } from '../../SharedComponents/ImageUploader/ImageUploader';
import {
  lookupItemsGetId,
  UpdateContactDetailsForTransaction,
  GetScopeCategoryDocuments,
  GetContactDetailsFromIdCardDocument,
} from '../../Services';
import {
  getDownloadableLink,
  getErrorByName,
  showError,
  showSuccess,
} from '../../Helper';
import { ScopeDocumentEnum } from '../../Enums';
import { DocumentUploadDialog } from '../../Views/Home/UnitsView/UnitsProfileManagementView/Dialogs/DocumentUploadDialog/DocumentUploadDialog';
import { DocumentsUploader } from '../DocumentsUploader/DocumentsUploader';
import { UploaderFilesComponent } from '../UploaderFilesComponent/UploaderFilesComponent';
import { MultipleTypesDocumentsUploader } from '../MultipleTypesDocumentsUploader/MultipleTypesDocumentsUploader';

export const TransactionUpdateContactDialog = ({
  isOpen,
  onCloseHandler,
  addContactToTable,
  contactData,
  contactId,
}) => {
  const parentTranslationPath = 'UnitsStatusManagementView';
  const translationPath = '';
  const { t } = useTranslation(parentTranslationPath);

  const passportCategoryLookup = {
    id: config.ContactDocsCategoryIds_Passport,

    name: 'Passport',
  };
  const IdCategoryLookup = {
    id: config.ContactDocsCategoryIds_IDCard,

    name: 'ID',
  };
  const EIDCategoryLookup = {
    id: config.ContactDocsCategoryIds_EID,

    name: 'EID',
  };
  const KYCCategoryLookup = {
    id: config.ContactDocsCategoryIds_KYC,
    
    name: 'KYC',
  };

  const initialState = {
    nationalityId: contactData.nationality?.lookupItemId || null,
    visaTypeId: contactData.visa_type?.lookupItemId || null,
    passportNo: contactData.passport_no || null,
    idCardNo: contactData.id_card_no || null,
    firstName: contactData.first_name ?? null,
    lastName: contactData.last_name ?? null,
    documents: [],
  };

  const initialSelected = {
    nationality: contactData.nationality || null,
    visaType: contactData.visa_type || null,
    documents: {
      IdDocuments: [],
      passportDocuments: [],
      EIDDocuments: [],
      KYCDocuments: [],
    },
  };

  const reducer = useCallback((itemsState, action) => {
    if (action.id !== 'edit')
      return { ...itemsState, [action.id]: action.value };
    return {
      ...action.value,
    };
  }, []);

  const [selected, setSelected] = useReducer(reducer, initialSelected);
  const [state, setState] = useReducer(reducer, initialState);

  const [data, setData] = useReducer(reducer, {
    nationalities: [],
    visaTypes: [],
  });
  const [loadings, setLoadings] = useReducer(reducer, {
    nationalities: false,
    visaTypes: false,
    documents: false,
    saving: false,
    global: false,
  });

  const [visaDocumentCategory, setVisaDocumentCategory] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [isInitialDocsAPICalled, setIsInitialDocsAPICalled] = useState(false);

  const getNationalities = async () => {
    setLoadings({ id: 'nationalities', value: true });
    const res = await lookupItemsGetId({
      lookupTypeId: StaticLookupsIds.Country,
    });
    if (!(res && res.status && res.status !== 200))
      setData({ id: 'nationalities', value: res });
    else setData({ id: 'nationalities', value: res });
    setLoadings({ id: 'nationalities', value: false });
  };

  const getVisaTypes = async () => {
    setLoadings({ id: 'visaTypes', value: true });
    const res = await lookupItemsGetId({
      lookupTypeId: 47,
    });
    if (!(res && res.status && res.status !== 200))
      setData({ id: 'visaTypes', value: res });
    else setData({ id: 'visaTypes', value: res });
    setLoadings({ id: 'visaTypes', value: false });
  };

  const getVisaDocumentCategory = (visaTypeId) => {
    const nonResidantLookupId = 18072;
    const visitLookupId = 18069;

    if (
      visaTypeId &&
      (visaTypeId === visitLookupId || visaTypeId === nonResidantLookupId)
    )
      setVisaDocumentCategory(passportCategoryLookup);
    else if (visaTypeId) setVisaDocumentCategory(IdCategoryLookup);
    else setVisaDocumentCategory(null);
  };

  const getContactOldVisaDocuments = useCallback(async (documentCategory) => {
    setLoadings({ id: 'documents', value: true });
    setIsInitialDocsAPICalled(true);

    const body = {
      scopeId: contactId,
      pageSize: 25,
      pageIndex: 0,
    };

    try {
      const [
        IdDocsResponse,
        passportDocsResponse,
        KYCDocsResponse,
        EIDDocsResponse,
      ] = await Promise.all([
        GetScopeCategoryDocuments({
          ...body,
          categoryId: IdCategoryLookup.id,
        }),
        GetScopeCategoryDocuments({
          ...body,
          categoryId: passportCategoryLookup.id,
        }),
        GetScopeCategoryDocuments({
          ...body,
          categoryId: KYCCategoryLookup.id,
        }),
        GetScopeCategoryDocuments({
          ...body,
          categoryId: EIDCategoryLookup.id,
        }),
      ]);

      if (
        !(
          IdDocsResponse &&
          IdDocsResponse.status &&
          IdDocsResponse.status !== 200
        ) &&
        !(
          passportDocsResponse &&
          passportDocsResponse.status &&
          passportDocsResponse.status !== 200
        ) &&
        !(
          KYCDocsResponse &&
          KYCDocsResponse.status &&
          KYCDocsResponse.status !== 200
        ) &&
        !(
          EIDDocsResponse &&
          EIDDocsResponse.status &&
          EIDDocsResponse.status !== 200
        )
      ) {
        const mappedIdDocuments = IdDocsResponse?.result?.map((item) => ({
          uuid: item.documentId,
          fileName: item.documentName,
        }));

        const mappedPassportDocuments = passportDocsResponse?.result?.map(
          (item) => ({
            uuid: item.documentId,
            fileName: item.documentName,
          })
        );
        const mappedKYCDocuments = KYCDocsResponse?.result?.map((item) => ({
          uuid: item.documentId,
          fileName: item.documentName,
        }));
        const mappedEIDDocuments = EIDDocsResponse?.result?.map((item) => ({
          uuid: item.documentId,
          fileName: item.documentName,
        }));

        setSelected({
          id: 'documents',
          value: {
            IdDocuments: mappedIdDocuments || [],
            passportDocuments: mappedPassportDocuments || [],
            KYCDocuments: mappedKYCDocuments || [],
            EIDDocuments: mappedEIDDocuments || [],
          },
        });
      } else {
        setSelected({
          id: 'documents',
          value: {
            IdDocuments: [],
            passportDocuments: [],
            KYCDocuments: [],
            EIDDocuments: [],
          },
        });
      }
    } catch (error) {
      console.error('Error fetching documents:', error);
    } finally {
      setLoadings({ id: 'documents', value: false });
    }
  });

  const updateContactForTransaction = async () => {
    setLoadings({ id: 'saving', value: true });

    let IdDocuments = [
      ...(selected.documents.IdDocuments.map((item) => ({
        fileId: item.uuid,
        fileName: item.fileName,
      })) || []),
    ];
    let passportDocuments = [
      ...(selected.documents.passportDocuments.map((item) => ({
        fileId: item.uuid,
        fileName: item.fileName,
      })) || []),
    ];
    let KYCDocuments = [
      ...(selected.documents.KYCDocuments.map((item) => ({
        fileId: item.uuid,
        fileName: item.fileName,
      })) || []),
    ];
    let EIDDocuments = [
      ...(selected.documents.EIDDocuments.map((item) => ({
        fileId: item.uuid,
        fileName: item.fileName,
      })) || []),
    ];

    const documents = {
      scopeId: contactId,
      scopeTypeId: ScopeDocumentEnum.Contact.scopeTypeId,
      categoryFiles: [
        {
          categoryId: IdCategoryLookup.id,
          files: IdDocuments,
        },
        {
          categoryId: passportCategoryLookup.id,
          files: passportDocuments,
        },
        {
          categoryId: KYCCategoryLookup.id,
          files: KYCDocuments,
        },
        {
          categoryId: EIDCategoryLookup.id,
          files: EIDDocuments,
        },
      ],
    };

    const body = {
      ...state,
      idCardNo: state.idCardNo || null,
      passportNo: state.passportNo || null,
      documents,
    };
    const res = await UpdateContactDetailsForTransaction(body, contactId);
    if (!(res && res.status && res.status !== 200)) {
      showSuccess(`contact-inforamtion-updated-successfully`);
      addContactToTable(state);
      onCloseHandler(documents);
    } else {
      showError(`updating-contact-inforamtion-has-failed`);
    }
    setLoadings({ id: 'saving', value: false });
  };

  const saveHandler = () => {
    if (schema?.error?.message) {
      showError(schema.error.message);
      return;
    } else if (documentsSchema?.error?.message) {
      showError(documentsSchema.error.message);
      return;
    }
    updateContactForTransaction();
  };

  const OCRReadHandler = async (fileId) => {
    setLoadings({ id: 'global', value: true });
    const result = await GetContactDetailsFromIdCardDocument({
      fileId,
    });
    const { fullName, nationalityId, idNo } = result;
    const index = fullName?.indexOf(' ');
    const firstName = fullName?.slice(0, index);
    const lastName = fullName?.slice(index + 1, fullName.length);
    setState({
      id: 'edit',
      value: {
        ...state,
        firstName,
        lastName,
        idCardNo: idNo,
        nationalityId,
      },
    });
    setLoadings({ id: 'global', value: false });
  };

  const schema = Joi.object({
    firstName: Joi.string()
      .required()
      .messages({
        'string.base': t(`${translationPath}first-name-is-required`),
        'string.required': t(`${translationPath}first-name-is-required`),
      }),
    lastName: Joi.string()
      .required()
      .messages({
        'string.base': t(`${translationPath}last-name-is-required`),
        'string.required': t(`${translationPath}last-name-is-required`),
      }),
    nationalityId: Joi.number()
      .required()
      .messages({
        'number.base': t(`${translationPath}nationality-is-required`),
        'state.required': t(`${translationPath}nationality-is-required`),
      }),
    visaTypeId: Joi.number()
      .required()
      .messages({
        'number.base': t(`${translationPath}visa-Type-is-required`),
        'state.required': t(`${translationPath}visa-Type-is-required`),
      }),
    idCardNo: Joi.required()
      .custom((value, helpers) => {
        if (visaDocumentCategory?.name === 'ID' && !state.idCardNo)
          return helpers.error('idCard-number-msg-value');
        return value;
      })
      .messages({
        'idCard-number-msg-value': t(
          `${translationPath}idCard-number-is-required`
        ),
      }),
    passportNo: Joi.required()
      .custom((value, helpers) => {
        if (!state.passportNo)
          return helpers.error('passport-number-msg-value');
        return value;
      })
      .messages({
        'passport-number-msg-value': t(
          `${translationPath}visa-Type-is-required`
        ),
      }),
  })
    .options({
      abortEarly: false,
      allowUnknown: true,
    })
    .validate(state);

  const documentsSchema = Joi.object({
    IdDocuments: Joi.required()
      .custom((value, helpers) => {
        const isIdDocsEmpty =
          visaDocumentCategory?.name === 'ID' &&
          selected.documents.IdDocuments.length == 0;

        if (
          visaDocumentCategory?.name === IdCategoryLookup.name &&
          isIdDocsEmpty
        )
          return helpers.error('ID-documents-msg-value');
        return value;
      })
      .messages({
        'ID-documents-msg-value': t(
          `${translationPath}ID-documents-are-required`
        ),
      }),
    passportDocuments: Joi.required()
      .custom((value, helpers) => {
        const isPassportDocsEmpty =
          selected.documents.passportDocuments.length == 0;

        if (isPassportDocsEmpty)
          return helpers.error('Passport-documents-msg-value');
        return value;
      })
      .messages({
        'Passport-documents-msg-value': t(
          `${translationPath}passport-documents-are-required`
        ),
      }),
  })
    .options({
      abortEarly: false,
      allowUnknown: true,
    })
    .validate(selected?.documents);

  useEffect(() => {
    const visaTypeId = contactData.visa_type?.lookupItemId || null;
    getVisaDocumentCategory(visaTypeId);
  }, [contactData]);

  useEffect(() => {
    if (isInitialDocsAPICalled == false) getContactOldVisaDocuments();
  }, [isInitialDocsAPICalled]);

  useEffect(() => {
    getNationalities();
    getVisaTypes();
  }, []);

  useEffect(() => {
    if (state.nationalityId && data.nationalities?.length > 0) {
      const value = data.nationalities?.find(
        (nationality) => nationality.lookupItemId === state.nationalityId
      );
      setSelected({ id: 'edit', value: { ...selected, nationality: value } });
    }
  }, [state.nationalityId]);

  return (
    <>
      <Spinner isAbsolute isActive={loadings.global} />
      <DialogComponent
        titleText='contact-information'
        saveText='save'
        maxWidth='md'
        dialogContent={
          <div className='transaction-contact-update-dialog'>
            <div>
              <div className='w-100 my-2 mx-1'>
                <AutocompleteComponent
                  idRef='nationalityRef'
                  labelValue='nationality'
                  labelClasses='Requierd-Color'
                  multiple={false}
                  data={data.nationalities || []}
                  displayLabel={(option) => option.lookupItemName}
                  renderOption={(option) => option.lookupItemName || ''}
                  withoutSearchButton
                  isWithError
                  selectedValues={selected.nationality}
                  isLoading={loadings.nationalities}
                  parentTranslationPath={parentTranslationPath}
                  translationPath={translationPath}
                  onChange={(event, newValue) => {
                    setSelected({
                      id: 'nationality',
                      value: newValue,
                    });
                    setState({
                      id: 'nationalityId',
                      value: newValue?.lookupItemId || null,
                    });
                  }}
                />
              </div>
              <fieldset className='input-fieldset my-3'>
                <legend className='input-fieldset-legend'>
                  {t(`${translationPath}visa-details`)}
                </legend>
                <div className='d-flex'>
                  <div className='w-75 my-2 mx-1'>
                    <Inputs
                      idRef='firstNameRef'
                      labelValue='first-name'
                      labelClasses='Requierd-Color'
                      value={state.firstName || ''}
                      onInputChanged={(event) => {
                        const { value } = event.target;
                        setState({
                          id: 'firstName',
                          value,
                        });
                      }}
                      errorMessage={getErrorByName(schema, 'lastName')?.message}
                      error={getErrorByName(schema, 'lastName').error}
                      parentTranslationPath={parentTranslationPath}
                      translationPath={translationPath}
                    />
                  </div>
                  <div className='w-75 my-2 mx-1'>
                    <Inputs
                      idRef='lastNameRef'
                      labelValue='last-name'
                      labelClasses='Requierd-Color'
                      value={state.lastName || ''}
                      onInputChanged={(event) => {
                        const { value } = event.target;
                        setState({
                          id: 'lastName',
                          value,
                        });
                      }}
                      isWithError
                      errorMessage={getErrorByName(schema, 'lastName')?.message}
                      error={getErrorByName(schema, 'lastName').error}
                      parentTranslationPath={parentTranslationPath}
                      translationPath={translationPath}
                    />
                  </div>
                  <div
                    className={`${
                      selected.visaType ? 'w-50' : 'w-100'
                    } my-2 mx-1`}
                  >
                    <AutocompleteComponent
                      idRef='visaTypeRef'
                      labelValue='visaType'
                      labelClasses='Requierd-Color'
                      multiple={false}
                      data={data.visaTypes || []}
                      displayLabel={(option) => option.lookupItemName}
                      renderOption={(option) => option.lookupItemName || ''}
                      withoutSearchButton
                      isWithError
                      selectedValues={selected.visaType}
                      isLoading={loadings.visaTypes}
                      parentTranslationPath={parentTranslationPath}
                      translationPath={translationPath}
                      onChange={(event, newValue) => {
                        setSelected({ id: 'visaType', value: newValue });
                        setState({
                          id: 'visaTypeId',
                          value: newValue?.lookupItemId || null,
                        });

                        getVisaDocumentCategory(newValue?.lookupItemId || null);
                      }}
                    />
                  </div>
                  <div className='w-75 my-2 mx-1'>
                    <Inputs
                      idRef='passportNumberRef'
                      labelValue='passport-number'
                      labelClasses='Requierd-Color'
                      value={state.passportNo || ''}
                      onInputChanged={(event) => {
                        const { value } = event.target;
                        setState({
                          id: 'passportNo',
                          value: value,
                        });
                      }}
                      parentTranslationPath={parentTranslationPath}
                      translationPath={translationPath}
                    />
                  </div>

                  {isInitialDocsAPICalled && 
                  (visaDocumentCategory?.name === IdCategoryLookup.name) && (
                    <div className='w-75 my-2 mx-1'>
                      <Inputs
                        idRef='idCardNumberRef'
                        labelValue='idCard-number'
                        labelClasses='Requierd-Color'
                        value={state.idCardNo || ''}
                        onInputChanged={(event) => {
                          const { value } = event.target;
                          setState({
                            id: 'idCardNo',
                            value: value,
                          });
                        }}
                        parentTranslationPath={parentTranslationPath}
                        translationPath={translationPath}
                      />
                    </div>
                  )}
                </div>
                  {isInitialDocsAPICalled && 
                  (visaDocumentCategory?.name === IdCategoryLookup.name && (
                  <MultipleTypesDocumentsUploader
                    isWithOCR
                    onOCRClick={OCRReadHandler}
                    initUploadedFiles={selected.documents.IdDocuments ?? []}
                    labelValue='Id-card-documents'
                    labelClasses='Requierd-Color'
                    uploadedChanged={(files) => {
                      setSelected({
                        id: 'documents',
                        value: {
                          ...selected.documents,
                          IdDocuments: files,
                        },
                      });
                    }}
                    setIsUploading={setIsUploading}
                    isUploading={isUploading}
                    multiple
                    isOpenGallery
                    idRef='importFileRef'
                    viewUploadedFilesCount={false}
                    openGallery
                    isDocuments
                    chipHandler={(value) => () => {
                      const link = document.createElement('a');
                      link.setAttribute('download', value.fileName);
                      link.href = getDownloadableLink(value.uuid);
                      document.body.appendChild(link);
                      link.click();
                      link.remove();
                    }}
                    parentTranslationPath={parentTranslationPath}
                    translationPath={translationPath}
                  />)
                )}
                {isInitialDocsAPICalled && 
                <>
                <MultipleTypesDocumentsUploader
                  initUploadedFiles={selected.documents.passportDocuments || []}
                  labelValue='passport-documents'
                  labelClasses='Requierd-Color'
                  uploadedChanged={(files) => {
                    setSelected({
                      id: 'documents',
                      value: {
                        ...selected.documents,
                        passportDocuments: files,
                      },
                    });
                  }}
                  setIsUploading={setIsUploading}
                  isUploading={isUploading}
                  multiple
                  isOpenGallery
                  idRef='importFileRef'
                  viewUploadedFilesCount={false}
                  openGallery
                  isDocuments
                  chipHandler={(value) => () => {
                    const link = document.createElement('a');
                    link.setAttribute('download', value.fileName);
                    link.href = getDownloadableLink(value.uuid);
                    document.body.appendChild(link);
                    link.click();
                    link.remove();
                  }}
                  parentTranslationPath={parentTranslationPath}
                  translationPath={translationPath}
                />
                <MultipleTypesDocumentsUploader
                  initUploadedFiles={selected.documents.EIDDocuments || []}
                  labelValue='EID-documents'
                  uploadedChanged={(files) => {
                    setSelected({
                      id: 'documents',
                      value: {
                        ...selected.documents,
                        EIDDocuments: files,
                      },
                    });
                  }}
                  setIsUploading={setIsUploading}
                  isUploading={isUploading}
                  multiple
                  isOpenGallery
                  idRef='importFileRef'
                  viewUploadedFilesCount={false}
                  openGallery
                  isDocuments
                  chipHandler={(value) => () => {
                    const link = document.createElement('a');
                    link.setAttribute('download', value.fileName);
                    link.href = getDownloadableLink(value.uuid);
                    document.body.appendChild(link);
                    link.click();
                    link.remove();
                  }}
                  parentTranslationPath={parentTranslationPath}
                  translationPath={translationPath}
                />
                <MultipleTypesDocumentsUploader
                  initUploadedFiles={selected.documents.KYCDocuments || []}
                  labelValue='KYC-documents'
                  uploadedChanged={(files) => {
                    setSelected({
                      id: 'documents',
                      value: {
                        ...selected.documents,
                        KYCDocuments: files,
                      },
                    });
                  }}
                  setIsUploading={setIsUploading}
                  isUploading={isUploading}
                  multiple
                  isOpenGallery
                  idRef='importFileRef'
                  viewUploadedFilesCount={false}
                  openGallery
                  isDocuments
                  chipHandler={(value) => () => {
                    const link = document.createElement('a');
                    link.setAttribute('download', value.fileName);
                    link.href = getDownloadableLink(value.uuid);
                    document.body.appendChild(link);
                    link.click();
                    link.remove();
                  }}
                  parentTranslationPath={parentTranslationPath}
                  translationPath={translationPath}
                />
                </>
                }
              </fieldset>
            </div>

            <DialogActions>
              <div className='unit-template-action-btns'>
                <ButtonBase
                  className='MuiButtonBase-root btns bg-cancel'
                  onClick={() => onCloseHandler()}
                >
                  {t(`${translationPath}cancel`)}
                </ButtonBase>
                <ButtonBase
                  className='MuiButtonBase-root btns theme-solid '
                  onClick={saveHandler}
                >
                  {t(`${translationPath}save`)}
                </ButtonBase>
              </div>
            </DialogActions>
          </div>
        }
        isOpen={isOpen}
        parentTranslationPath={parentTranslationPath}
        translationPath={translationPath}
      />
    </>
  );
};
