import { makeStyles } from '@material-ui/core/styles';
import { useIsAr } from '../../../Hooks';

export default makeStyles((theme) => {
  const { isAr } = useIsAr(theme.direction);
  
  return {
    sideNav: (props) => ({
      position: 'absolute',
      top: 0,
      height: '100vh',
      backgroundColor: theme.palette.sidenavBackgroundColor,
      width:
        props.isExpanded || props.hoverExpanded
          ? props.sideNavWidth.expanded
          : props.sideNavWidth.collapsed,
      overflowY: 'auto',
      transition: 'width 0.3s ease-in-out',
      zIndex: 20,
      overflowX: 'hidden',
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
      padding: '41px 16px 0',
      color: '#F5F5F6',
      '& ul': {
        listStyleType: 'none',
        padding: 0,
        margin: 0,
      },
      // '&::-webkit-scrollbar': {
      //   width: '5px',
      // },
      // '-ms-overflow-style': '5px',
      // 'scrollbar-width': '5px',
      // 'scrollbar-height': '5px',
      
      // dark mode scrollbar
      '&::-webkit-scrollbar': {
        width: '8px',
      },
      '&::-webkit-scrollbar-track': {
        boxShadow: 'none',
        background: 'transparent !important',
        border: 'none',
      },
      '&::-webkit-scrollbar-track:hover': {
        background: 'transparent !important',
      },
      '&::-webkit-scrollbar-thumb': {
        background: '#333741 !important',
        border: '0px none #ffffff !important',
        borderRadius: '10rem',
      },
      '&::-webkit-scrollbar-thumb:hover': {
        background: '#333741 !important',
      },
      '&::-webkit-scrollbar-thumb:active': {
        background: '#333741 !important',
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: '#333741 !important',
        outline: '1px solid transparent',
        borderRadius: '10rem',
      },
    }),
    pinIconContainer: {
      width: '100%',
      display: 'flex',
      justifyContent: 'flex-end',
      minHeight: 24,
      marginBottom: "8px",
      '& svg': {
        cursor: 'pointer',
      },
    },
    hideScrollbar: {
      '&::-webkit-scrollbar': {
        display: 'none',
      },
      '-ms-overflow-style': 'none' /* IE and Edge */,
      'scrollbar-width': 'none' /* Firefox */,
    },
    navIcon: {
      fontSize: '24px',
      color: '#94969C',
    },
    activeNavIcon: {
      color: '#FFF',
    },
    colNavIconWrapper: {
      padding: '12px',
      borderRadius: '6px',
      marginBottom: '8px',
      maxHeight: '48px',
    },
    nestedListItem: {
      padding: "0px",
      width: "100%",
      marginBottom: "8px",
      borderRadius: "6px",
    },
    navLink: {
      width: "100%",
      padding: "8px 12px",
      paddingInlineStart: "48px",
      ...(isAr ? { textAlign: "start" } : {}),
      borderRadius: "6px",
      fontSize: "16px",
      color: "#CECFD2",
      '&:focus': {
        color: "#ECECED",
      },
      '&:hover': {
        color: "#ECECED",
        background: "#1F242F",
      }
    },
    activeNavLink: {
      color: "#ECECED",
      background: "#1F242F",
      '&:hover': {
        color: "#ECECED",
        background: "#1F242F",
      }
    },
    expandedListItemParent: {
      padding: 0,
      display: "flex",
      alignItems: "center",
      gap: "12px",
      width: "100%",
      padding: "12px",
      justifyContent: "space-between",
      color: "#CECFD2",
      '&:hover': {
        backgroundColor: '#7F6944',
        color: '#F5F5F6',
        '& $navIcon': { // Using the $ syntax to reference another class name
          color: '#FFF',
        },
        '& $dropdownIcon': { // Using the $ syntax to reference another class name
          color: '#FFF',
        },
      }
    },
    ActiveListItemWrapper: {
      backgroundColor: '#7F6944',
      '& $dropdownIcon': { // Using the $ syntax to reference another class name
        color: '#FFF !important',
      },
      color: '#F5F5F6 !important',
      '&:hover': {
        backgroundColor: '#7F6944',
      }
    },
    listItemIcon: {
      minWidth: "fit-content"
    },
    dropdownIcon: {
      fontSize: '20px',
      color: theme.palette.foreground.quarterary,
    }
  };
});
