import React from "react";
import {
  AutocompleteComponent,
  Inputs,
  SelectComponet,
} from "../../../../Components";
import { Button } from "react-bootstrap/lib/InputGroup";
import { ButtonBase } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { AMLTransactionStatusEnum } from "../../../../Enums/AMLTransactionStatus.Enum";

function AMLFilters({
  state,
  translationPath,
  parentTranslationPath,
  selected,
  setSelected,
  selectedOrderBy,
  orderBySubmitted,
  searchTimer,
  optionsData,
  filter,
  setFilter,
  getAllUnitSaleTypes,
  isLoading,
  orderByChanged,
  filterByChanged,
  getAllUnitTypes,
  clearFilterHandler,
  activeTab,
}) {
  const { t } = useTranslation(parentTranslationPath);
  const isAMLView = window.location.pathname === "/home/AMLView/AMLView";
  const data = Object.values(AMLTransactionStatusEnum).filter(
    (item) => {
      if (isAMLView) {
        return item.name !== "Pass" && item.name !== "Fail" && item.name !== "NeedRevision";
      } else {
        return item.name !== "Pass" && item.name !== "Fail" && item.name !== "ReassignedToRequester";
      }
    }
  );

  const saleTransactionType = [
    {
      id: 1,
      label: t("Sale"),
    },
    {
      id: 2,
      label: t("ReservedSale"),
    },
  ];
  const leaseTransactionType = [
    {
      id: 3,
      label: t("Lease"),
    },
    {
      id: 4,
      label: t("ReservedLease"),
    },
  ];

  return (
    <div>
      <div className="d-flex-column">
        <div className="d-flex mx-2">
          <div className="w-100 mr-1-reversed mx-2">
            <Inputs
              idRef="fullNameFilterRef"
              inputPlaceholder="filter-by-name"
              fieldClasses="inputs theme-solid"
              label="filter-by-name"
              value={selected?.contactName}
              onInputChanged={(e) => {
                setSelected({
                  id: "contactName",
                  value: e?.target?.value || "",
                });
              }}
              onKeyUp={(e) => {
                if (searchTimer.current) clearTimeout(searchTimer.current);
                const newValue = e?.target?.value;
                searchTimer.current = setTimeout(() => {
                  setFilter({ id: "contactName", value: newValue });
                }, 700);
              }}
              translationPath={translationPath}
              parentTranslationPath={parentTranslationPath}
            />
          </div>
          <div className="w-100 mr-1-reversed mx-2">
            <Inputs
              idRef="userNameFilterRef"
              label="filter-by-contactId"
              inputPlaceholder="type-contactId"
              fieldClasses="inputs theme-solid"
              value={selected.contactId}
              onInputChanged={(e) => {
                setSelected({
                  id: "contactId",
                  value: e?.target?.value || "",
                });
              }}
              onKeyUp={(event) => {
                if (searchTimer.current) clearTimeout(searchTimer.current);
                const newValue = event.target.value;
                searchTimer.current = setTimeout(() => {
                  setFilter({ id: "contactId", value: newValue });
                }, 700);
              }}
              translationPath={translationPath}
              parentTranslationPath={parentTranslationPath}
            />
          </div>
          <div className="w-100 mr-1-reversed mx-2">
            <Inputs
              idRef="unitTransactionIdFilterRef"
              fieldClasses="inputs theme-solid"
              label="filter-by-unitTransactionId"
              value={selected.unitTransactionId}
              onInputChanged={(e) => {
                setSelected({
                  id: "unitTransactionId",
                  value: e?.target?.value || "",
                });
              }}
              onKeyUp={(event) => {
                if (searchTimer.current) clearTimeout(searchTimer.current);
                const newValue = event.target.value;
                searchTimer.current = setTimeout(() => {
                  setFilter({ id: "unitTransactionId", value: newValue });
                }, 700);
              }}
              inputPlaceholder="filter-by-unitTransactionId"
              translationPath={translationPath}
              parentTranslationPath={parentTranslationPath}
            />
          </div>
          <div className="d-flex">
            <div className="mx-2 w-100">
              <SelectComponet
                data={data || []}
                value={filter?.status || 0}
                emptyItem={{
                  value: 0,
                  text: t(`${translationPath}status`),
                  isDisabled: false,
                }}
                valueInput="key"
                textInput="name"
                onSelectChanged={(newValue) => {
                  setFilter({
                    id: "edit",
                    value: {
                      ...filter,
                      status: newValue,
                      pageIndex: 0,
                    },
                  });
                  setSelected({ id: "status", value: newValue });
                }}
                translationPath={translationPath}
                parentTranslationPath={parentTranslationPath}
              />
            </div>
          </div>

          <div className="row-item-Button alia">
            <div className="btns-can">
              <Button
                className="can"
                onClick={() => {
                  setFilter((item) => ({
                    ...item,
                    unitTransactionId: "",
                    contactName: "",
                    contactId: "",
                    status: "",
                  }));
                  setSelected({
                    unitTransactionId: "",
                    contactName: "",
                    contactId: "",
                    status: "",
                  });
                }}
              >
                {t(`${translationPath}Clear`)}
              </Button>
            </div>
          </div>
        </div>
      </div>

      <div className="d-flex-column mt-2">
        <div className="d-flex mx-2">
          <div className="w-25  mx-2">
            <Inputs
              idRef="unitRefFilterRef"
              fieldClasses="inputs theme-solid"
              label="filter-by-unit-Ref"
              value={selected?.unitRefNumber}
              onInputChanged={(e) => {
                setSelected({
                  id: "unitRefNumber",
                  value: e?.target?.value || "",
                });
              }}
              onKeyUp={(e) => {
                if (searchTimer.current) clearTimeout(searchTimer.current);
                const newValue = e.target.value;
                searchTimer.current = setTimeout(() => {
                  setFilter({ id: "unitRefNumber", value: newValue });
                }, 700);
              }}
              inputPlaceholder="filter-by-unit-Ref"
              translationPath={translationPath}
              parentTranslationPath={parentTranslationPath}
            />
          </div>

          <div className="w-40 mr-1-reversed mx-2">
            <AutocompleteComponent
              inputPlaceholder="unit-type"
              idRef="unit-typeRef"
              selectedValues={selected?.unitType}
              isDisabled={isLoading}
              multiple={false}
              data={optionsData.unitTypes || []}
              displayLabel={(option) => option?.lookupItemName || ""}
              renderOption={(option) => option?.lookupItemName || ""}
              getOptionSelected={(option) =>
                option?.lookupItemName === state.unitType
              }
              onOpen={() => {
                if (optionsData.unitTypes && optionsData.unitTypes.length == 0)
                  getAllUnitTypes();
              }}
              withoutSearchButton
              isLoading={isLoading}
              onKeyDown={(newValue) => {
                setSelected({
                  id: "unitType",
                  value: newValue || "",
                });
              }}
              isWithError
              onChange={(event, newValue) => {
                setSelected({
                  id: "unitType",
                  value: newValue || "",
                });
                searchTimer.current = setTimeout(() => {
                  setFilter({
                    id: "unitType",
                    value: newValue?.lookupItemName,
                  });
                }, 700);
              }}
              translationPath={translationPath}
              parentTranslationPath={parentTranslationPath}
            />
          </div>
          {activeTab === 0 && (
            <div className="w-40 mr-1-reversed mx-2">
              <AutocompleteComponent
                inputPlaceholder="sale-type"
                idRef="saletypeRef"
                selectedValues={selected.unitSaleType}
                isDisabled={isLoading}
                multiple={false}
                data={optionsData.unitSaleTypes || []}
                displayLabel={(option) => option?.lookupItemName || ""}
                renderOption={(option) => option?.lookupItemName || ""}
                getOptionSelected={(option) =>
                  option?.lookupItemName === state.unitSaleType
                }
                onOpen={() => {
                  if (
                    optionsData.unitSaleTypes &&
                    optionsData.unitSaleTypes.length == 0
                  )
                    getAllUnitSaleTypes();
                }}
                withoutSearchButton
                isLoading={isLoading}
                onKeyDown={(newValue) => {
                  setSelected({
                    id: "unitSaleType",
                    value: newValue || "",
                  });
                }}
                isWithError
                onChange={(event, newValue) => {
                  setSelected({
                    id: "unitSaleType",
                    value: newValue || "",
                  });
                  searchTimer.current = setTimeout(() => {
                    setFilter({
                      id: "unitSaleType",
                      value: newValue?.lookupItemName,
                    });
                  }, 700);
                }}
                translationPath={translationPath}
                parentTranslationPath={parentTranslationPath}
              />
            </div>
          )}
          <div className="w-40 mr-1-reversed mx-2">
            {activeTab === 0 && (
              <AutocompleteComponent
                inputPlaceholder="transaction-type"
                idRef="transactionTypeRef"
                selectedValues={selected.transactionType}
                isDisabled={isLoading}
                multiple={false}
                data={saleTransactionType || []}
                withoutSearchButton
                displayLabel={(option) => option.label || ""}
                isLoading={isLoading}
                onKeyDown={(newValue) => {
                  setSelected({
                    id: "transactionType",
                    value: newValue || "",
                  });
                }}
                isWithError
                onChange={(event, newValue) => {
                  setSelected({
                    id: "transactionType",
                    value: newValue || "",
                  });
                  searchTimer.current = setTimeout(() => {
                    setFilter({
                      id: "transactionType",
                      value: newValue?.id,
                    });
                  }, 700);
                }}
                parentTranslationPath={parentTranslationPath}
                translationPath={translationPath}
              />
            )}
            {activeTab === 1 && (
              <AutocompleteComponent
                inputPlaceholder="transaction-type"
                idRef="transactionTypeeRef"
                selectedValues={selected.transactionType}
                isDisabled={isLoading}
                multiple={false}
                data={leaseTransactionType || []}
                withoutSearchButton
                displayLabel={(option) => option.label || ""}
                isLoading={isLoading}
                onKeyDown={(newValue) => {
                  setSelected({
                    id: "transactionType",
                    value: newValue || "",
                  });
                }}
                isWithError
                onChange={(event, newValue) => {
                  setSelected({
                    id: "transactionType",
                    value: newValue || "",
                  });
                  searchTimer.current = setTimeout(() => {
                    setFilter({
                      id: "transactionType",
                      value: newValue?.id,
                    });
                  }, 700);
                }}
                parentTranslationPath={parentTranslationPath}
                translationPath={translationPath}
              />
            )}
          </div>
        </div>
      </div>

      <div className="d-flex px-2">
        <span className="mx-2 mt-1">{t(`${translationPath}AML`)}</span>
        <span className="separator-v s-primary s-reverse s-h-25px mt-1" />
        <span className="px-2 d-flex">
          <span className="texts-large">
            {t(`${translationPath}order-by`)}:
          </span>
          <div className="px-2">
            <SelectComponet
              idRef="filterByRef"
              data={[
                { id: "CreatedOn", filterBy: "Created-Date" },
                { id: "CompleteDate", filterBy: "Completion-Date" },
              ]}
              value={selectedOrderBy.filterBy}
              onSelectChanged={filterByChanged}
              wrapperClasses="mb-3"
              isRequired
              valueInput="id"
              textInput="filterBy"
              emptyItem={{
                value: null,
                text: "Select-filter-by",
                isDisabled: false,
              }}
              parentTranslationPath={parentTranslationPath}
              translationPath={translationPath}
              translationPathForData={translationPath}
            />
          </div>
          <div className="px-2">
            <SelectComponet
              idRef="orderByRef"
              data={[
                { id: 1, orderBy: "descending" },
                { id: 2, orderBy: "ascending" },
              ]}
              value={selectedOrderBy.orderBy}
              onSelectChanged={orderByChanged}
              wrapperClasses="mb-3"
              isRequired
              valueInput="id"
              textInput="orderBy"
              emptyItem={{
                value: null,
                text: "select-Sort-by",
                isDisabled: false,
              }}
              parentTranslationPath={parentTranslationPath}
              translationPath={translationPath}
              translationPathForData={translationPath}
            />
          </div>
          <div className="d-flex">
            <div className="mt-1">
              <ButtonBase
                className="btns theme-solid"
                onClick={orderBySubmitted}
                id="action_apply"
                disabled={!selectedOrderBy.filterBy || !selectedOrderBy.orderBy}
              >
                <span>{t(`${translationPath}apply`)}</span>
              </ButtonBase>
            </div>
            <div className="mt-1">
              <ButtonBase
                onClick={clearFilterHandler}
                id="onClearedAllFiltersref"
                className="btns theme-solid bg-danger clear-all-btn"
              >
                {t(`${translationPath}clear-filters`)}
              </ButtonBase>
            </div>
          </div>
        </span>
      </div>
    </div>
  );
}

export default AMLFilters;
