import React from "react";
import "../MasterStyleDashborads/MasterStyleDashboards.scss";
import { PowerBiEnum } from "../../../../Enums";
import { AgentsDashboardPolicy } from "../AgentsDashboardPolicy";

export const AccountDashboard = () => {
 return (
    <div>
   
        <AgentsDashboardPolicy
          id={PowerBiEnum.accounts.reportid}
          embedUrl={PowerBiEnum.accounts.url}
        />
    
    </div>
  );
};
