import { createReducer } from '@reduxjs/toolkit';
import {
  TOGGLE_THEME,
  TOGGLE_THEME_DIRECTION,
  SET_SELECTED_THEME,
} from './Actions';
import themesConfig from '../../config/themesConfig/themesConfig';

const initialState = {
  isDarkMode: false,
  selectedTheme: themesConfig.goldLight,
  themeDirection: 'ltr',
};

const Reducer = createReducer(initialState, {
  [TOGGLE_THEME]: (state) => ({
    ...state,
    isDarkMode: !state.isDarkMode,
  }),
  [SET_SELECTED_THEME]: (state, { payload }) => ({
    ...state,
    selectedTheme: payload.isDarkMode
      ? { ...themesConfig.goldDark, direction: state.themeDirection }
      : { ...themesConfig.goldLight, direction: state.themeDirection },
  }),
  [TOGGLE_THEME_DIRECTION]: (state, { payload }) => ({
    ...state,
    themeDirection: payload,
    selectedTheme: {
      ...state.selectedTheme,
      direction: payload,
    },
  }),
});

export default Reducer;
