import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => {
  return {
    contactOverViewCard: {
      padding: "24px",
    },
    socialCardTitleWrapper: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      borderBottom: `1px solid ${theme.palette.border.secondary}`,
      paddingBottom: "20px",
    },
    titleContainer: {
      display: "flex",
      flexWrap: "wrap",
      gap: "8px ",
      alignItems: "center",
      [theme.breakpoints.down("sm")]: {
        minWidth: "60px",
      },
    },
    socialContainer: {
      marginTop: "20px",
      display: "flex",
      flexDirection: "column",
      gap: "8px",
    },
    socialItem: {
      display: "flex",
      alignItems: "center",
      gap: "8px",
    },
  };
});
