import React, { useState, useEffect } from 'react';
import { Spinner, DialogComponent } from '../../../Components';
import NoReportSelectionView from './Sections/NoReportSelectionView';
import ReportHeaderSectionView from './Sections/ReportHeaderSectionView';
import ReportFormDialog from "./Sections/ReportFormDialog";
import { HttpServices, ExportReportServices } from "../../../Services";
import { showError, unCamelCase, bottomBoxComponentUpdate } from "../../../Helper";
import { config } from '../../../config';
import ReportTableView from "./Sections/ReportTableView";
import { useTitle } from '../../../Hooks';
import { useTranslation } from 'react-i18next';
import exportFromJSON from 'export-from-json'
import { PrintRepertDialogView } from './PrintRepertDialogView/PrintRepertDialogView';
const parentTranslationPath = 'Reports';
const translationPath = '';
export const ReportsView = () => {
    const [activeReport, setActiveReport] = useState(null);
    const { t } = useTranslation(parentTranslationPath);
    useTitle(t(`${translationPath}Reports`));
    const [isLoading, setIsLoading] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    const [reportDto, setReportDto] = useState({});
    const [filter, setFilter] = useState({ pageIndex: 0, pageSize: 25, filterBy: null, orderBy: null });
    const [reportSchema, setReportSchema] = useState([]);
    const [reportData, setReportData] = useState([]);
    const [LoaclFilter, setLoaclFilter] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const [reportTotalCount, setReportTotalCount] = useState(0);
    const [recall, setReCall] = useState(false);
    const [sortBy, setSortBy] = useState(null);
    var DateNow = Date();
    const onPageIndexChanged = (pageIndex) => {
        setFilter((item) => ({ ...item, pageIndex }));
        setReCall(!recall)
    };
    const onPageSizeChanged = (pageSize) => {
        setFilter((item) => ({ ...item, pageIndex: 1, pageSize }));
        setReCall(!recall);
    };

    const convertFormDtoToDto = (obj) => {
        let dto = {};
        for (const key in obj) {
            if (typeof (obj[key]) === 'object') {
                if (obj[key] && obj[key]["id"]) dto[key] = obj[key]["id"];
                else if (obj[key] && obj[key]["lookupItemId"]) dto[key] = obj[key]["lookupItemId"];
                else dto[key] = obj[key]
            }
            else
                dto[key] = obj[key]
        }
        return dto;
    }

    useEffect(() => {
        autoCallReportAPi();
    }, [recall])

    useEffect(() => {
        if (activeReport !== null) {
            setOpenDialog(true);
        }
        setReportDto({});
        setReportSchema([]);
        setReportData([]);
        setReportTotalCount(0);
        setFilter({ pageIndex: 0, pageSize: 25 })
    }, [activeReport]);

    const autoCallReportAPi = async (pageSize) => {
        if (activeReport === null) return;
        setIsLoading(true);
        reportDto.pageSize = pageSize || filter.pageSize;
        reportDto.pageIndex = filter.pageIndex + 1;
        reportDto.filterBy = filter.filterBy;
        reportDto.orderBy = filter.orderBy;
        const Dto = convertFormDtoToDto(reportDto);
        setLoaclFilter(Dto);
        const res = await HttpServices.post(`${config.server_address}${activeReport.endpointName}`, Dto)
            .then((data) => data)
            .catch(() => undefined);
        if (!res) { showError("server Error"); setIsLoading(false); return; };
        if (res.result && res.result.length === 0) { setReportSchema([]); setIsLoading(false); return; }
        const temp = res.result[0];
        setReportData(res.result);
        setReportTotalCount(res.totalCount)
        const list = [];
        let i = 0;
        for (const key in temp) {
            i++;
            list.push({
                id: i,
                label: unCamelCase(key),
                component: (item) => (
                    <div>
              { ((item && item[key] !== null) && (String(item && item[key]) || 'N/A') || 'N/A')}
                    </div>
                ),
                isDefaultFilterColumn: true,
                isSortable: true,
                input: key,
            }
            );
        }
        setReportSchema(list.slice(0, -1));
        setIsLoading(false)
    }

    const exportDataToFormats = async (Format, fileName) => {

        if (activeReport === null) return;
        setIsLoading(true);
        reportDto.pageSize = 10000000;
        reportDto.pageIndex = filter.pageIndex + 1;
        const Dto = convertFormDtoToDto(reportDto);
        const res = await HttpServices.post(`${config.server_address}${activeReport.endpointName}`, Dto)
            .then((data) => data)
            .catch(() => undefined);
        if (!res) { showError("server Error"); setIsLoading(false); return; };
        if (res.result && res.result.length === 0) { setReportSchema([]); setIsLoading(false); return; }
        let reData = (res.result);

        setIsLoading(false);
        if (reData && reData.length === 0) return;
        const temp = reData[0];
        const data = reData;
        let exportType = null
        switch (Format) {
            case 'xls':
                exportType = exportFromJSON.types.xls
                break;
            case 'csv':
                exportType = exportFromJSON.types.csv
                break;
            case 'xml':
                exportType = exportFromJSON.types.xml
                break;
            case 'json':
                exportType = exportFromJSON.types.json
                break;
            default:
                exportType = exportFromJSON.types.xls
                break;
        }

        exportFromJSON({ data, fileName, exportType })
        const ress = await ExportReportServices();
        if (ress) {
            return;
        }
    }



    const onExportClickedPDF = async () => {
        setIsOpen(true);
    }


    useEffect(() => {
        if (sortBy)
            setFilter((item) => ({ ...item, filterBy: sortBy.filterBy, orderBy: sortBy.orderBy }));
    }, [sortBy]);


    useEffect(() => {
        autoCallReportAPi()
    }, [filter]);


    useEffect(() => {
        bottomBoxComponentUpdate(null);
    }, []);

    return (
        <div className=' px-2 view-wrapper reports-view-wrapers'>
            <Spinner isActive={isLoading} isAbsolute />
            <div className='d-flex-column' id='reprtttt'>
                <ReportHeaderSectionView
                    isShowExcelPart={activeReport !== null}
                    parentTranslationPath={parentTranslationPath}
                    translationPath={translationPath}
                    setLoadingView={setIsLoading}
                    OnReportChange={setActiveReport}
                    openDialog={() => setOpenDialog(true)}
                    onExportClickedPDF={onExportClickedPDF}
                    onExportClicked={() => exportDataToFormats('xls', (activeReport && activeReport.reportName || 'ReportView') + ' ' + DateNow.toString())}
                    onExportClickedxml={() => exportDataToFormats('xml', (activeReport && activeReport.reportName || 'ReportView') + ' ' + DateNow.toString())}
                    onExportClickedjson={() => exportDataToFormats('json', (activeReport && activeReport.reportName || 'ReportView') + ' ' + DateNow.toString())}
                    onExportClickedcsv={() => exportDataToFormats('csv', (activeReport && activeReport.reportName || 'ReportView') + ' ' + DateNow.toString())}
                />
                <NoReportSelectionView
                    isShow={activeReport === null}
                    parentTranslationPath={parentTranslationPath}
                    translationPath={translationPath}
                />
                <ReportTableView
                    parentTranslationPath={parentTranslationPath}
                    translationPath={translationPath}
                    tableData={reportData}
                    tableSchema={reportSchema}
                    totalItems={reportTotalCount}
                    onPageIndexChanged={onPageIndexChanged}
                    onPageSizeChanged={onPageSizeChanged}
                    filter={filter}
                    activeReport={activeReport != null ? activeReport.reportId : 0}
                    setSortBy={setSortBy}
                />
            </div>
            <ReportFormDialog
                isShow={activeReport !== null && openDialog}
                formId={activeReport?.formId}
                translationPath={translationPath}
                parentTranslationPath={parentTranslationPath}
                reportName={activeReport?.reportName}
                close={() => setOpenDialog(false)}
                onSaveReportDto={setReportDto}
                reportDto={reportDto}
                onSearchClick={async () => { setOpenDialog(false); await autoCallReportAPi(); }}
            />
            <DialogComponent
                titleText={t(`${translationPath}export-report`) + "   " + (activeReport && activeReport.reportName) || ''}
                saveText='confirm'
                saveType='button'
                maxWidth='sm'
                dialogContent={(
                    <>
                        <PrintRepertDialogView
                            activeReport={activeReport}
                            translationPath={translationPath}
                            LoaclFilter={LoaclFilter}
                            onClose={() => { setIsOpen(false); }}
                            parentTranslationPath={parentTranslationPath} />
                    </>
                )}
                saveClasses='btns theme-solid bg-danger w-100 mx-2 mb-2'
                isOpen={isOpen}
                onCloseClicked={() => { setIsOpen(false); }}
                parentTranslationPath={parentTranslationPath}
                translationPath={translationPath}
            />
        </div>
    );
};
