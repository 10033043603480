export const UserStatusEnum = {
    Pending: {
        key: 1,
        label: 'Pending',
    },
    Active: {
        key: 2,
        label: 'Active',
    },
    Canceled: {
        key: 3,
        label: 'Canceled',
    },
    PendingLineManagerUserActivation: {
        key: 4,
        label: 'Pending Line Manager Approval',
    },
    PendingTrainingCenterActivation: {
        key: 5,
        label: 'Pending Training Approval',
    },
    PendingCRMAdminActivation: {
        key: 6,
        label: 'Pending CRM Admin Approval',
    },
    HiringRejected: {
        key: 7,
        label: 'Hiring Rejected',
    },
    PendingLineManagerUserDeActivation: {
        key: 8,
        label: 'Pending Line Manager User DeActivation',
    },
    PendingLeadOwnerUserDeActivation: {
        key: 9,
        label: 'Pending Lead Owner User DeActivation',
    },
    // PendingLineManagerUserActivation: {
    //     key: 10,
    //     label: 'Pending Line Manager User Activation',
    // },
    // PendingTrainingCenterActivation: {
    //     key: 11,
    //     label: 'Pending Training Center Activation',
    // },
    // PendingCRMAdminActivation: {
    //     key: 12,
    //     label: 'Pending CRM Admin Activation',
    // },
};