import React, { useEffect } from 'react';
import { AutocompleteComponent } from '../../../../../../../../../../Components';

export const CommunityComponent = ({
  parentTranslationPath,
  translationPath,
  communityId,
  setCommunityId,
  selected,
  setSelected,
  communityList,
  addressLoadings ,
  setAddressLoadings ,
}) => {
  useEffect(() => {
    if (communityId && !selected.Community && communityList.length > 0) {
      const communityIndex = communityList.findIndex(
        (item) => item.lookupItemId === communityId
      );

      if (communityIndex !== -1) setSelected(communityList[communityIndex]);
      else setCommunityId(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [communityId, selected.Community, setSelected, setCommunityId]);

  return (
    <div>
      <AutocompleteComponent
        idRef='communityRef'
        labelValue='community'
        isLoading={addressLoadings.community}
        selectedValues={selected.Community}
        data={communityList && communityList ? communityList : []}
        displayLabel={(option) => option.lookupItemName || ''}
        getOptionSelected={(option) => option.lookupItemId === selected.Community.lookupItemId}
        multiple={false}
        withoutSearchButton
        onChange={(event, newValue) => {
          setSelected(newValue);
          setCommunityId(newValue &&  newValue.lookupItemId);
        }}
        parentTranslationPath={parentTranslationPath}
        translationPath={translationPath}
      />
    </div>
  );
};
