import React from 'react';
import PropTypes from 'prop-types';

import { AgentRotationRangeTypeEnum } from '../../../../../../Enums/AgentRotationRangeTypeEnum';
import { returnPropsByPermissions } from '../../../../../../Helper';

import { AutocompleteComponent, SwitchComponent } from '../../../../../../Components';
import { RotationSchemaPermissions } from '../../../../../../Permissions';
import {
  LeadCampaignComponent,
  MethodOfContact,
  LeadTypeComponent,
  RefferdByComponent,
  PropertyComponent,
  LeadPreferredLanguageComponent,
  LeadClassComponent,
  CountrySectionComponent,
  MediaSectionDetailComponent,
  UnitTypeComponent, RangeComponent,
  DeveloperComponent,
  LabelComponent,
  OrderComponent
} from './Sections';

export const RotationCriteriaDialogContent = ({
  parentTranslationPath,
  translationPath,
  state,
  onStateChanged,
  schema,
  isSubmitted,
  setIsLoading,
  rotationEdit,
  currentSelected,
  setCurrentSelected,
  validation,
  SelectedisBulk,
  setSelectedisBulk,
}) => (
  <div className='dialog-content-wrapper rotaion-content'>
    <LabelComponent
      state={state}
      parentTranslationPath={parentTranslationPath}
      translationPath={translationPath}
      onStateChanged={onStateChanged}
      isSubmitted={isSubmitted}
      schema={schema}
    />
    <OrderComponent
      state={state}
      parentTranslationPath={parentTranslationPath}
      translationPath={translationPath}
      onStateChanged={onStateChanged}
      isSubmitted={isSubmitted}
      schema={schema}
    />
    <MediaSectionDetailComponent
      state={state}
      schema={schema}
      isSubmitted={isSubmitted}
      values={state.rotationSchemeMedias && state.rotationSchemeMedias.filter((w) => w.mediaDetailsId)}
      onStateChanged={(newValue) => {
        const filterd = state.rotationSchemeMedias.filter((w) => w.mediaNameId);
        const localNewValue = {
          id: 'rotationSchemeMedias',
          value: [
            ...filterd,
            ...newValue,
          ],
        };
        onStateChanged(localNewValue);
      }}
      parentTranslationPath={parentTranslationPath}
      translationPath={translationPath}
    />
    <LeadCampaignComponent
      state={state}
      schema={schema}
      parentTranslationPath={parentTranslationPath}
      translationPath={translationPath}
      isSubmitted={isSubmitted}
      onStateChanged={onStateChanged}
      currentSelected={currentSelected}
      setCurrentSelected={setCurrentSelected}
      rotationEdit={rotationEdit}
    />
    <LeadTypeComponent
      state={state}
      schema={schema}
      parentTranslationPath={parentTranslationPath}
      translationPath={translationPath}
      isSubmitted={isSubmitted}
      onStateChanged={onStateChanged}
      values={state.rotationSchemaLeadsType && state.rotationSchemaLeadsType.filter((item) => item.leadClass)}
      rotationEdit={rotationEdit}
      currentSelected={currentSelected}
      setCurrentSelected={setCurrentSelected}
      validation={validation}
    />
    <MethodOfContact
      state={state}
      schema={schema}
      parentTranslationPath={parentTranslationPath}
      translationPath={translationPath}
      isSubmitted={isSubmitted}
      onStateChanged={onStateChanged}
      currentSelected={currentSelected}
      setCurrentSelected={setCurrentSelected}
      // values={state.rotationSchemaMethodOfContacts && state.rotationSchemaMethodOfContacts.filter((item) => item.methodOfContactName)}
    />
    <LeadClassComponent
      state={state}
      schema={schema}
      isSubmitted={isSubmitted}
      onStateChanged={onStateChanged}
      parentTranslationPath={parentTranslationPath}
      translationPath={translationPath}
      schemaKey='rotationSchemaContactCLasses'
    />

    <LeadPreferredLanguageComponent
      state={state}
      parentTranslationPath={parentTranslationPath}
      translationPath={translationPath}
      onStateChanged={onStateChanged}
      setCurrentSelected={setCurrentSelected}
      currentSelected={currentSelected}
    />
    <CountrySectionComponent
      state={state}
      onStateChanged={onStateChanged}
      parentTranslationPath={parentTranslationPath}
      translationPath={translationPath}
    />

    <PropertyComponent
      state={state}
      onStateChanged={onStateChanged}
      parentTranslationPath={parentTranslationPath}
      translationPath={translationPath}
    />

    <RefferdByComponent
      state={state}
      onStateChanged={onStateChanged}
      parentTranslationPath={parentTranslationPath}
      translationPath={translationPath}
      schema={schema}
      isSubmitted={isSubmitted}
      schemaKey='rotationSchemaReferredBys'
    />

    <DeveloperComponent
      state={state}
      onStateChanged={onStateChanged}
      parentTranslationPath={parentTranslationPath}
      translationPath={translationPath}
    />

    <UnitTypeComponent
      state={state}
      onStateChanged={onStateChanged}
      parentTranslationPath={parentTranslationPath}
      translationPath={translationPath}
    />
    <div className='dialog-content-item switch-btn'>
      <SwitchComponent
        idRef='isOpenRef'
        isChecked={state.isActive}
        isDisabled={!(returnPropsByPermissions(RotationSchemaPermissions.SetARotationAsActiveDeactivated.permissionsId))}
        labelClasses='px-0'
        onChangeHandler={(event, isChecked) => {
          const localNewValue = {
            id: 'isActive',
            value: !state.isActive
          };
          onStateChanged(localNewValue);
        }}
        themeClass='theme-line'
        labelValue={(state.isActive && 'Active') || 'Deactive'}
        parentTranslationPath={parentTranslationPath}
        translationPath={translationPath}
      />
      <AutocompleteComponent
        idRef='isBulk'
        labelValue={(`${translationPath}isBulk`)}
        data={
          [
          { lookupItemName: 'Yes', lookupItemId: 1 },
          { lookupItemName: 'No', lookupItemId: 0 }
          ] || []
}
        value={state.leadClassId}
        selectedValues={SelectedisBulk || []}
        getOptionSelected={(option) => option.lookupItemId === SelectedisBulk && SelectedisBulk.lookupItemId}
        multiple={false}
        displayLabel={(option) => (option && option.lookupItemName) || ''}
        withoutSearchButton
        parentTranslationPath={parentTranslationPath}
        translationPath={translationPath}
        onChange={(event, newValue) => {
          const localNewValue = {
            id: 'isBulk',
            value: newValue && newValue.lookupItemId
          };
          setSelectedisBulk({
            lookupItemName: newValue && newValue.lookupItemName,
            lookupItemId: newValue && newValue.lookupItemId
          });
          onStateChanged((localNewValue) || null);
          }}
      />
    </div>

    <RangeComponent
      state={state}
      schema={schema}
      idRef='priceRangeRef'
      labelValue='priceRange'
      agentRotationRangeType={AgentRotationRangeTypeEnum.PriceRange.key}
      onStateChanged={onStateChanged}
      parentTranslationPath={parentTranslationPath}
      translationPath={translationPath}
      max={60000000}
    />

    <RangeComponent
      state={state}
      schema={schema}
      idRef='BedroomsRef'
      labelValue='Bedrooms'
      agentRotationRangeType={AgentRotationRangeTypeEnum.Bedroom.key}
      onStateChanged={onStateChanged}
      parentTranslationPath={parentTranslationPath}
      translationPath={translationPath}
      max={100}
    />

    <RangeComponent
      state={state}
      schema={schema}
      idRef='BathroomsRef'
      labelValue='Bathrooms'
      agentRotationRangeType={AgentRotationRangeTypeEnum.Bathroom.key}
      onStateChanged={onStateChanged}
      parentTranslationPath={parentTranslationPath}
      translationPath={translationPath}
      max={100}
    />
    <RangeComponent
      state={state}
      schema={schema}
      idRef='SizeRef'
      labelValue='Size'
      agentRotationRangeType={AgentRotationRangeTypeEnum.Size.key}
      onStateChanged={onStateChanged}
      parentTranslationPath={parentTranslationPath}
      translationPath={translationPath}
      max={10000}
    />

  </div>
);
const convertJsonValueShape = PropTypes.oneOfType([
  PropTypes.number,
  PropTypes.number,
  PropTypes.number,
  PropTypes.number,
  PropTypes.number,
  PropTypes.number,
  PropTypes.number,
  PropTypes.number,
  PropTypes.number,
  PropTypes.number,
  PropTypes.number,
  PropTypes.number,
  PropTypes.number,
  PropTypes.number,
  PropTypes.number,
  PropTypes.number,
  PropTypes.number,
  PropTypes.array,
  PropTypes.array,
  PropTypes.array,
]);
RotationCriteriaDialogContent.propTypes =
{
  parentTranslationPath: PropTypes.string.isRequired,
  translationPath:
    PropTypes.string.isRequired,
  state:
    PropTypes.objectOf(convertJsonValueShape).isRequired,
  onStateChanged:
    PropTypes.func.isRequired,
  schema:
    PropTypes.instanceOf(Object).isRequired,
  isSubmitted:
    PropTypes.bool.isRequired,

  setIsLoading:
    PropTypes.func.isRequired,
};
