import React from "react";
import { Box } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import { CustomCard } from "../../CustomCard";
import { useIsDesktop } from "../../../../Hooks";

// Styles
import useStyles from "./styles";

function ContactProfileOverviewSkeleton() {
  const styles = useStyles();

  const { isDesktop } = useIsDesktop();

  return (
    <CustomCard
      borderRadius="xl"
      boxShadow="xs"
      borderColor="secondary"
      classes={styles.container}
    >
      <Skeleton variant="rectangular" width="100%" height={72} />

      <Skeleton
        className={styles.avatar}
        variant="circle"
        width={104}
        height={104}
      />

      <Box className={styles.detailsContent}>
        <Box className={styles.contactDetails}>
          <Box className={styles.nameWrapper}>
            <Skeleton variant="text" width="15%" height={30} />
            <Skeleton variant="text" width="50%" height={40} />
          </Box>

          <Box className={styles.specificationWrapper}>
            <Skeleton variant="text" width="20%" height={20} />
            <Skeleton variant="rectangular" width="8%" height={20} />
            <Skeleton variant="rectangular" width="8%" height={20} />
            <Skeleton variant="rectangular" width="14%" height={20} />
            <Skeleton
              className={styles.rounded}
              animation="wave"
              width="44px"
              variant="circular"
              height="24px"
            />
          </Box>

          <Box className={styles.classesWrapper}>
            <Skeleton
              className={styles.rounded}
              animation="wave"
              width="50px"
              variant="circular"
              height="24px"
            />
            <Skeleton
              className={styles.rounded}
              animation="wave"
              width="50px"
              variant="circular"
              height="24px"
            />
            <Skeleton
              className={styles.rounded}
              animation="wave"
              width="50px"
              variant="circular"
              height="24px"
            />
            <Skeleton
              className={styles.rounded}
              animation="wave"
              width="50px"
              variant="circular"
              height="24px"
            />
          </Box>
        </Box>

        <Box className={styles.actionsWrapper}>
          <Skeleton
            className={styles.actionBtn}
            variant="rectangular"
            width={138}
            height={40}
          />
          <Skeleton
            className={styles.actionBtn}
            variant="rectangular"
            width={117}
            height={40}
          />
        </Box>

        {!isDesktop && (
          <Box className={styles.showMoreWrapper}>
            <Skeleton
              className={styles.actionBtn}
              variant="rectangular"
              width={138}
              height={24}
            />
          </Box>
        )}

        {isDesktop && (
          <>
            <Box className={styles.infoSection}>
              <Box className={styles.infoHeading}>
                <Skeleton variant="text" width="60%" height={25} />
              </Box>
              {[...Array(9)].map((_, index) => (
                <Box key={index} className={styles.infoItem}>
                  <Skeleton variant="text" width="20%" height={25} />
                  <Skeleton variant="text" width="50%" height={25} />
                </Box>
              ))}
            </Box>

            <Box className={styles.infoSection}>
              <Box className={styles.infoHeading}>
                <Skeleton variant="text" width="60%" height={25} />
              </Box>
              {[...Array(4)].map((_, index) => (
                <Box key={index} className={styles.infoItem}>
                  <Skeleton variant="circle" width={24} height={24} />
                  <Skeleton variant="text" width="20%" height={25} />
                  <Skeleton variant="text" width="50%" height={25} />
                </Box>
              ))}
            </Box>
          </>
        )}
      </Box>
    </CustomCard>
  );
}

export default ContactProfileOverviewSkeleton;
