import React from "react";

function FilterStartWithIcon({ fill, ...restProps }) {
  return (
    <svg
      viewBox="0 0 16 16"
      fill={fill ?? ""}
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <path
        d="M3.493 1.358A.66.66 0 0 0 3.008 2c0 .27.138.483.392.603.127.06.16.063.7.063l.567.001V4h-.914c-1.258 0-1.62.039-1.972.214-.323.16-.57.374-.766.663a1.877 1.877 0 0 0-.285.683c-.047.199-.05.364-.05 2.453 0 2.24 0 2.24.06 2.477a1.925 1.925 0 0 0 1.423 1.433c.208.054.303.059 1.364.07l1.14.012V13.33l-.594.008c-.548.008-.601.013-.7.066a.658.658 0 0 0-.365.596c0 .27.138.483.392.603l.133.063h3.6l.134-.063A.642.642 0 0 0 7.659 14a.657.657 0 0 0-.366-.596c-.098-.053-.151-.058-.7-.066L6 13.33V2.667l.567-.001c.54 0 .573-.003.7-.063A.642.642 0 0 0 7.659 2a.657.657 0 0 0-.366-.596c-.106-.057-.116-.057-1.92-.062-.997-.003-1.843.005-1.88.016m4.303 2.68a.664.664 0 0 0-.331 1.023c.044.06.137.143.207.184l.128.075 2.84.015c2.197.011 2.863.022 2.943.048.14.047.325.238.369.381.049.163.051 4.303.002 4.463a.639.639 0 0 1-.321.364l-.126.062-2.84.014c-2.192.01-2.857.021-2.915.046a.762.762 0 0 0-.333.298c-.061.104-.072.153-.072.322 0 .172.01.219.075.328a.72.72 0 0 0 .396.314c.069.018 1.036.024 2.949.018 2.844-.009 2.847-.009 3.07-.068a1.95 1.95 0 0 0 1.063-.686c.181-.236.283-.45.36-.749.06-.237.06-.237.06-2.477 0-2.089-.003-2.254-.05-2.453-.145-.624-.492-1.069-1.051-1.346-.413-.205-.579-.214-3.686-.212-2.069.001-2.65.009-2.737.036M4.667 8.002v2.668l-1.087-.008c-1.077-.009-1.088-.009-1.213-.071a.66.66 0 0 1-.319-.351c-.042-.125-.063-3.919-.024-4.27.032-.28.096-.405.267-.518.147-.097.316-.111 1.422-.115l.954-.004v2.669"
        fill={fill ?? ""}
      />
    </svg>
  );
}

export default FilterStartWithIcon;
