import { makeStyles } from '@material-ui/core/styles';
import { useIsAr } from '../../../Hooks';

export default makeStyles((theme) => {
  const { isAr } = useIsAr(theme.direction);

  return {
    mobileSideNav: {
      position: 'fixed',
      top: 0,
      ...(isAr ? {right: 0,} : {left: 0,}),
      width: '312px',
      height: '100%',
      backgroundColor: 'rgb(12, 17, 29)',
      zIndex: theme.zIndex.drawer + 2,
      overflowY: 'auto',
      transition: 'transform 0.3s ease-in-out',
      display: 'flex',
      flexDirection: 'column',
      padding: "64px 16px 0px",
      '&::-webkit-scrollbar': {
        width: '8px',
      },
      '&::-webkit-scrollbar-track': {
        boxShadow: 'none',
        background: 'transparent !important',
        border: 'none',
      },
      '&::-webkit-scrollbar-track:hover': {
        background: 'transparent !important',
      },
      '&::-webkit-scrollbar-thumb': {
        background: '#333741 !important',
        border: '0px none #ffffff !important',
        borderRadius: '10rem',
      },
      '&::-webkit-scrollbar-thumb:hover': {
        background: '#333741 !important',
      },
      '&::-webkit-scrollbar-thumb:active': {
        background: '#333741 !important',
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: '#333741 !important',
        outline: '1px solid transparent',
        borderRadius: '10rem',
      },
    },
    closeIcon: {
      cursor: 'pointer',
      color: '#FFF',
      opacity: 0.7,
      fontSize: '24px',
    },
    overlay: {
      position: 'fixed',
      top: 0,
      ...(isAr ? { right: '312px' } : { left: '312px' }),
      left: '312px',
      width: 'calc(100% - 312px)',
      height: '100%',
      opacity: 0.7,
      backgroundColor: '#1F242F',
      zIndex: theme.zIndex.drawer + 1,
      transition: 'opacity 0.3s ease-in-out',
      display: 'flex',
      justifyContent: 'end',
      padding: '20px 16px',
      backdropFilter: 'blur(16px)',
      WebkitBackdropFilter: 'blur(16px)',
    },
  }
});
