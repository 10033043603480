import { config } from '../config/config';
import { HttpServices } from '../Helper';

export const CreateUnitImages = async (body) => {
  const result = await HttpServices.post(
    `${config.server_address}/CrmDfm/Unit/CreateUnitImages`,
    body
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};
export const UpdateUnitVideo = async (body) => {
  const result = await HttpServices.put(
    `${config.server_address}/CrmDfm/Unit/UpdateUnitVideo`,
    body
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

export const GetAllUnitImagesCategoryByUnitId = async (unitId) => {
  const pageIndex = 1;
  const pageSize = 1000;

  const queryList = [];
  queryList.push(`pageIndex=${pageIndex}`);
  queryList.push(`pageSize=${pageSize}`);

  const result = await HttpServices.get(
    `${config.server_address}/CrmDfm/Unit/GetAllUnitImagesCategoryByUnitId/${unitId}?${queryList.join('&')}`
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};
export const GetAllUnitVideosCategoryByUnitId = async (unitId) => {
  const result = await HttpServices.get(
    `${config.server_address}/CrmDfm/Unit/GetAllUnitVideosCategoryByUnitId/${unitId}`
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};
export const GetAllUnitVideos = async (body) => {
  const result = await HttpServices.post(
    `${config.server_address}/CrmDfm/Unit/GetAllUnitVideos`, body
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};
export const GetAllFloorPlansImagesCategoryByUnitId = async (unitId) => {
  const pageIndex = 1;
  const pageSize = 1000;

  const queryList = [];
  queryList.push(`pageIndex=${pageIndex}`);
  queryList.push(`pageSize=${pageSize}`);

  const result = await HttpServices.get(
    `${config.server_address}/CrmDfm/Unit/GetAllFloorPlansImagesCategoryByUnitId/${unitId}?${queryList.join('&')}`
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};
export const GetAllGeneralImagesCategoryByUnitId = async (unitId) => {
  const pageIndex = 1;
  const pageSize = 1000;

  const queryList = [];
  queryList.push(`pageIndex=${pageIndex}`);
  queryList.push(`pageSize=${pageSize}`);

  const result = await HttpServices.get(
    `${config.server_address}/CrmDfm/Unit/GetAllGeneralImagesCategoryByUnitId/${unitId}?${queryList.join('&')}`
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

export const GetAllUnitImages = async (body) => {
  const result = await HttpServices.post(
    `${config.server_address}/CrmDfm/Unit/GetAllUnitImages`,
    body
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};
export const UpdateUnitImages = async (body) => {
  const result = await HttpServices.put(
    `${config.server_address}/CrmDfm/Unit/UpdateUnitImages`,
    body
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};
export const DeleteUnitImagesCategory = async (categoryId, unitId) => {
  const result = await HttpServices.put(
    `${config.server_address}/CrmDfm/Unit/DeleteUnitImagesCategory?categoryId=${categoryId}&unitId=${unitId}`,
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};
export const DeleteUnitVideosCategory = async (categoryId, unitId) => {
  const result = await HttpServices.put(
    `${config.server_address}/CrmDfm/Unit/DeleteUnitVideosCategory?categoryId=${categoryId}&unitId=${unitId}`,
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

// export const CreateUnitImageInfo = async (body) => {
//   const result = await HttpServices.post(
//     `${config.server_address}/CrmDfm/Unit/CreateUnitImageInfo`,
//     body
//   )
//     .then((data) => data)
//     .catch((error) => error.response);
//   return result;
// };

export const UpdateUnitImageInfo = async (body) => {
  const result = await HttpServices.put(
    `${config.server_address}/CrmDfm/Unit/UpdateUnitImageInfo`,
    body
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

export const CreateOrUpdateUnitImageInfo = async (body) => {
  const result = await HttpServices.put(
    `${config.server_address}/CrmDfm/Unit/CreateOrUpdateUnitImageInfo`,
    body
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

export const CreateOrUpdateUnitImageInfoproperty = async (body) => {
  const result = await HttpServices.put(
    `${config.server_address}/CrmDfm/Properties/CreateOrUpdatePropertyImageInfo`,
    body
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

export const GetUnitImageInfoByImageInfoId = async (unitId, fileId, categoryId) => {
  const result = await HttpServices.get(
    `${config.server_address}/CrmDfm/Unit/GetUnitImageInfo/${unitId}/${fileId}/${categoryId}`,
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

export const GetpropertyIdImageInfoByImageInfoId = async (propertyId, fileId, categoryId) => {
  const result = await HttpServices.get(
    `${config.server_address}/CrmDfm/Properties/GetPropertyImageInfo?propertyId=${propertyId}&fileId=${fileId}&categoryId=${categoryId}`,
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

export const GetPublishedUnitImages = async (unitId) => {
  const pageIndex = 1;
  const pageSize = 1000;

  const queryList = [];
  queryList.push(`pageIndex=${pageIndex}`);
  queryList.push(`pageSize=${pageSize}`);

  const result = await HttpServices.get(
    `${config.server_address}/CrmDfm/Unit/GetPublishedUnitImages/${unitId}?${queryList.join('&')}`
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};
