

import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { bottomBoxComponentUpdate } from '../../../../Helper';
import {
  NoContentComponent,
  NoSearchResultComponent,
  PaginationComponent,
  Spinner,
} from '../../../../Components';
import {GetAllPortalAPI  ,  GetPortalByIdAPI   } from '../../../../Services';
import { PortalConfigurationTableView } from './PortalConfigurationUtilities' ; 


export const PortalConfigurationView = ({
  parentTranslationPath,
  translationPath,
  isReloadPortalData , 
  setIsReloadPortalData , 

  }) => {
  const { t } = useTranslation(parentTranslationPath);


  const [filter, setFilter] = useState({
    search: "",
    pageIndex: 0,
    pageSize: parseInt(localStorage.getItem('Pagination')) || 25,
  });

  const [portalConfiguration, setPortalConfiguration] = useState(null);
  const [activeItem, setActiveItem] = useState(null);

  const [isLoading, setIsLoading] = useState({
    allPortalsConfigurationData : false,
    portalConfiguration : false 
  });


  const [allPortalsConfiguration, setAllPortalsConfiguration] = useState({
    result: [],
    totalCount: 0,
  });


  const [isOpenPortalConfigurationDialog, setIsOpenPortalConfigurationDialog] = useState(false);

  const onPageIndexChanged = (pageIndex) => {
    setFilter((item) => ({ ...item, pageIndex }));
  };
  const onPageSizeChanged = (pageSize) => {
    setFilter((item) => ({ ...item, pageIndex: 0, pageSize }));
  };
  const reloadData = () => {
    setFilter((item) => ({ ...item, pageIndex: 0 }));
  };




  const GetAllPortalsConfiguration = useCallback(async () => {

    setIsLoading((item) => ({ ...item, allPortalsConfigurationData: true }));
      const res = await GetAllPortalAPI({ ...filter, pageIndex: (filter.pageIndex + 1) });
      if (!((res && res.data && res.data.ErrorId) || !res)) {
        setAllPortalsConfiguration({
          result: (res && res.result) || [],
          totalCount: (res && res.totalCount) || 0,
        });
      } else {
        setAllPortalsConfiguration({
          result: [],
          totalCount: 0,
        });
      }
    setIsLoading((item) => ({ ...item, allPortalsConfigurationData: false }));
  }, [filter , isReloadPortalData]);

  const GetPortalById = useCallback(async () => {
    setIsLoading((item) => ({ ...item, portalConfiguration: true }));
    const res = await GetPortalByIdAPI(activeItem && activeItem.portalId);
    if (!(res && res.status && res.status !== 200))
    setPortalConfiguration(res);
    else setPortalConfiguration(null);
    setIsLoading((item) => ({ ...item, portalConfiguration: false }));

  }, [activeItem]);


  useEffect(() => {
    GetAllPortalsConfiguration();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter , isReloadPortalData]);

  useEffect(() => {
      bottomBoxComponentUpdate(
        <PaginationComponent
          pageIndex={filter.pageIndex}
          pageSize={filter.pageSize}
          totalCount={allPortalsConfiguration.totalCount}
          onPageIndexChanged={onPageIndexChanged}
          onPageSizeChanged={onPageSizeChanged}
          translationPath=''
          parentTranslationPath='Shared'
        />
      );
  }, [filter , allPortalsConfiguration]);

  useEffect(
    () => () => {
      bottomBoxComponentUpdate(null);
    },
    []
  );

  useEffect(() => {
    if (activeItem)
    GetPortalById();
    else setPortalConfiguration(null);
  }, [activeItem]);

  


  return (
    <>
      <>
        <Spinner isActive={(isLoading && isLoading.allPortalsConfigurationData)} isAbsolute />
          {((allPortalsConfiguration && allPortalsConfiguration.result && allPortalsConfiguration.result.length === 0) || (allPortalsConfiguration && allPortalsConfiguration.totalCount === 0)) ? (
            <NoSearchResultComponent />
          ) : (

            (allPortalsConfiguration && allPortalsConfiguration.totalCount === 0 && (
              <NoContentComponent />
            )) || (
                <>
                  <div className='view-wrapers mt-4 px-2'>
                   <PortalConfigurationTableView
                      translationPath={translationPath}
                      data={allPortalsConfiguration}
                      filter={filter}
                      parentTranslationPath={parentTranslationPath}
                      setActiveItem={setActiveItem}
                      setFilter={() => setFilter((item) => ({ ...item, pageIndex: 0 }))}
                      activeItem={activeItem}
                      isLoading={isLoading}
                      editPortalConfiguration={portalConfiguration}
                      onPageSizeChanged={onPageSizeChanged}
                      onPageIndexChanged={onPageIndexChanged}
                      isReloadPortalData={isReloadPortalData} 
                      setIsReloadPortalData={setIsReloadPortalData}
                    /> 
                  </div>
                </>
              ))}
        </>
    </>
  );
};
