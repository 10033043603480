import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  dropzoneContainer: {
    cursor: "pointer",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    padding: "24px",
    borderRadius: "12px",
    border: `1px solid ${theme.palette.border.secondary}`,

    "& .dropzone": {
      width: "100%",
      background: theme.palette.background.paper,
      color: theme.palette.text.tertiary,
      fontSize: "12px",
      fontWeight: 400,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      gap: "4px",
      [theme.breakpoints.down("xs")]: {
        fontSize: "8px",
      },
    },
  },
  clickText: {
    color: theme.palette.text.brand_700,
    fontSize: "14px",
    fontWeight: 600,
    lineHeight: "20px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "10px",
    },
  },
  dragText: {
    color: theme.palette.text.tertiary,
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "20px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "10px",
    },
  },
}));
