import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import {
  ColumnsFilterPagesEnum,
  FormsIdsEnum,
  TableActions,
  TableFilterTypesEnum,
  HighlightRowKeys
} from '../../../../../Enums';
import { GetAllFormFieldsByFormId } from '../../../../../Services';
import { TableColumnsFilterComponent, Tables } from '../../../../../Components';
import { LeadsTableHeaderData } from './LeadsTableHeaderData';
import { TableColumnsFilterActions } from '../../../../../store/TableColumnsFilter/TableColumnsFilterActions';

export const LeadsMortgageTableComponent = ({
  detailsLeadsList,
  tableActionClicked,
  onPageIndexChanged,
  onPageSizeChanged,
  filter,
  parentTranslationPath,
  focusedRowChanged,
  checkedCardsIds,
  getIsSelected,
  // getIsDisabled,
  onSelectClicked,
  activeSelectedAction,
  leadsTableFilter,
  leadsFiltersCriteria,
  onFilterValuesChanged,
  defaultActions,
  setCheckedCards,
  setOrderBy,
  isClearFiltersClicked,
  setIsClearFiltersClicked,
  localStorageKey
}) => {
  const dispatch = useDispatch();
  const [allFormFields, setAllFormFields] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [sortBy, setSortBy] = useState(null);
  const [tableColumns, setTableColumns] = useState([]);
  const [tableFilterData, setTableFilterData] = useState([]);
  const [selectedTableFilterColumns, setSelectedTableFilterColumns] = useState(
    LeadsTableHeaderData.filter((item) => item.isDefaultFilterColumn).map(
      (column) => column.id
    )
  );
  const tableColumnsFilterResponse = useSelector((state) => state.TableColumnsFilterReducer);

  const getAllFormFieldsByFormId = useCallback(async () => {
    setIsLoading(true);
    Promise.all([
      await GetAllFormFieldsByFormId(FormsIdsEnum.leadsOwner.id),
      await GetAllFormFieldsByFormId(FormsIdsEnum.leadsSeeker.id),
    ])
      .then((result) => {
        if (Array.isArray(result[0]) && Array.isArray(result[1])) {
          const concantinateFields = result[0]
            .concat(result[1])
            .filter(
              (field, index, array) =>
                array.findIndex((element) => element.formFieldKey === field.formFieldKey) === index
            );
          const list = concantinateFields.filter((e) =>
            e.formFieldName !== 'fitting_and_fixtures' &&
            e.formFieldName !== 'view' &&
            e.formFieldName !== 'developer' &&
            e.formFieldName !== 'bathrooms' &&
            e.formFieldName !== 'bedrooms' &&
            e.formFieldName !== 'size_sqft' &&
            e.formFieldName !== 'budget' &&
            e.formFieldName !== 'propertyunit_type');
          setAllFormFields(list);
        } else setAllFormFields([]);
        setIsLoading(false);
      })
      .catch(() => {
        setAllFormFields([]);
        setIsLoading(false);
      });
  }, []);
  useEffect(() => {
    getAllFormFieldsByFormId();
  }, [getAllFormFieldsByFormId]);
  useEffect(() => {
    if (
      tableColumnsFilterResponse &&
      tableColumnsFilterResponse[ColumnsFilterPagesEnum.mortgageLeads.key]
    ) {
      setSelectedTableFilterColumns(
        tableColumnsFilterResponse[ColumnsFilterPagesEnum.mortgageLeads.key]
      );
    }
  }, [tableColumnsFilterResponse]);

  useEffect(() => {
    setTableColumns([
      ...LeadsTableHeaderData.filter(
        (item) => selectedTableFilterColumns.findIndex((element) => element === item.id) !== -1
      ),
      ...allFormFields
        .filter(
          (item) =>
            selectedTableFilterColumns.findIndex((element) => element === item.formFieldId) !== -1
        )
        .map((field) => ({
          id: field && field.formFieldId,
          key: field && field.formFieldKey,
          isDate: field && field.uiWidgetType === 'alt-date',
          label: (field.formFieldTitle === 'Lead Auditor' ? field.formFieldTitle = 'Lead Owner' : field.formFieldTitle && field.formFieldTitle.replace('*', '')) || '',
          input: (field && field.displayPath) || undefined,
          searchableKey: (field && field.searchableKey) || undefined,
          isSortable: true,
          isNumber: field &&
            (field.propertyJson && JSON.parse(field.propertyJson).schema &&
              field.propertyJson && JSON.parse(field.propertyJson).schema.specialKey === 'currency' ||
              field.propertyJson && JSON.parse(field.propertyJson).schema &&
              field.propertyJson && JSON.parse(field.propertyJson).schema.specialKey === 'decimal' ||
              field.propertyJson && JSON.parse(field.propertyJson).schema &&
              field.propertyJson && JSON.parse(field.propertyJson).schema.specialKey === 'size'

            )
        })),
    ]);
  }, [allFormFields, selectedTableFilterColumns]);

  useEffect(() => {
    if (tableColumns && tableColumns.length) {
      setTableFilterData(
        tableColumns.map((column) => ({
          key: column && (column.key || column.fieldKey || column.id),
          filterType:
            (column.isDate && TableFilterTypesEnum.datePicker.key) ||
            (column.isNumber && TableFilterTypesEnum.numberInput.key) ||
            TableFilterTypesEnum.textInput.key,
          isHiddenFilter: column && column.isHiddenFilter,
          isHidden: column.isHidden,
          textInputType: column && column.textInputType,
          textInputMax: column && column.textInputMax,
          textInputMin: column && column.textInputMin,
          searchableKey: column.searchableKey || column.input,
          displayPath: column &&
            (column.key && column.input) ||
            (column.fieldKey &&
              allFormFields &&
              allFormFields.findIndex((item) => item.formFieldKey === column.fieldKey) !== -1 &&
              allFormFields.find((item) => item.formFieldKey === column.fieldKey).displayPath)
        }))
      );
    } else
      setTableFilterData([]);
  }, [allFormFields, tableColumns]);

  const getIsSelectedAll = useCallback(
    () => {
      const returnSelect = (checkedCardsIds &&
        detailsLeadsList.result.findIndex((item) => !checkedCardsIds.includes(item.id)) === -1) || false;
      return returnSelect;
    }
  );

  const onSelectAllClicked = () => {
    const cardItmes = [];
    const isSelectOneOnly = activeSelectedAction === 'qualify-lead';
    if (isSelectOneOnly) return;

    if (!getIsSelectedAll()) {
      detailsLeadsList.result.map((item) => {
        if (!getIsSelected(item)) {
          checkedCardsIds.push(item.id);
          cardItmes.push({ ...item });
        }
      });
    } else {
      detailsLeadsList.result.map((item) => {
        if (getIsSelected(item)) {
          const isSelectedIndex = checkedCardsIds.findIndex(
            (element) => element === item.id
          );
          if (isSelectedIndex !== -1) checkedCardsIds.splice(isSelectedIndex, 1);
        }
      });
    }
    setCheckedCards(cardItmes);
  };
  const getIsDisabled = useCallback((row) =>
    activeSelectedAction === 'reassign-leads' &&
    (row.status.lookupItemName === 'Closed' || row.leadClass === 'Landlord' || row.leadClass === 'Seller'));

  useEffect(() => {
    if (sortBy) {
      setOrderBy((item) => ({
        ...item, filterBy: sortBy.filterBy && sortBy.filterBy[0].toLowerCase() + sortBy.filterBy.slice(1), orderBy: sortBy.orderBy, fieldType: sortBy.fieldType
      }));
    }
  }, [sortBy]);

  return (
    <div className='w-100 px-3'>
      <TableColumnsFilterComponent
        columns={LeadsTableHeaderData.filter(item => !item.isHidden ).concat(
          ...allFormFields.filter(
            (item) =>
              LeadsTableHeaderData.findIndex(
                (element) => element.fieldKey === item.formFieldKey
              ) === -1
          )
        ).map((item) => ({
          key: item.formFieldId || item.id,
          value: (item.formFieldTitle === 'Lead Auditor' ? item.formFieldTitle = 'Lead Owner' : item.formFieldTitle && item.formFieldTitle.replace('*', '')) || item.label,
        }))}
        isLoading={isLoading}
        selectedColumns={selectedTableFilterColumns}
        onSelectedColumnsChanged={(newValue) => {
          setSelectedTableFilterColumns(newValue);
          let localTableColumnsFilterResponse = tableColumnsFilterResponse;
          if (localTableColumnsFilterResponse)
            localTableColumnsFilterResponse[ColumnsFilterPagesEnum.mortgageLeads.key] = newValue;
          else {
            localTableColumnsFilterResponse = {
              [ColumnsFilterPagesEnum.mortgageLeads.key]: newValue,
            };
          }
          dispatch(
            TableColumnsFilterActions.TableColumnsFilterRequest(localTableColumnsFilterResponse)
          );
        }}
        parentTranslationPath={parentTranslationPath}
        translationPath=''
      />
      <Tables
        data={detailsLeadsList.result}
        selectAllOptions={
          ((activeSelectedAction === 'merge' || activeSelectedAction === 'close-leads' || activeSelectedAction === 'reassign-seeker-leads' ||activeSelectedAction === 'reassign-owner-leads'||
            activeSelectedAction === 'clone-leads' || activeSelectedAction === 'qualify-lead' ||
            activeSelectedAction === 'unqualified-leads' ||activeSelectedAction === 'add-to-favourite' || activeSelectedAction === 'send-to-rotation' || activeSelectedAction === 'send-to-owner-rotation'
            // activeSelectedAction === 'clone-leads' || activeSelectedAction === 'reassign-seeker-leads'|| activeSelectedAction === 'reassign-owner-leads' ||
            || activeSelectedAction === 'send-to-leads-pool'
            ) && {
            selectedRows: checkedCardsIds,
            getIsSelected,
            disabledRows: [],
            getIsDisabled,
            withCheckAll: true,
            onSelectClicked,
            onSelectAllClicked,
            isSelectAll: getIsSelectedAll(),
          }) ||
          undefined
        }
        headerData={tableColumns}
        filterValues={leadsTableFilter}
        setSortBy={setSortBy}
        onFilterValuesChanged={onFilterValuesChanged}
        filterData={tableFilterData}
        isWithFilter
        onPageIndexChanged={onPageIndexChanged}
        onPageSizeChanged={onPageSizeChanged}
        actionsOptions={{
          onActionClicked: tableActionClicked,
        }}
        defaultActions={defaultActions || [
          {
            enum: TableActions.openFile.key,
          }
        ]}
        itemsPerPage={filter.pageSize}
        activePage={filter.pageIndex}
        parentTranslationPath={parentTranslationPath}
        focusedRowChanged={focusedRowChanged}
        totalItems={detailsLeadsList ? detailsLeadsList.totalCount : 0}
        isClearFiltersClicked={isClearFiltersClicked}
        setIsClearFiltersClicked={setIsClearFiltersClicked}
        localStorageKey={localStorageKey}
        highlightedRowKey={HighlightRowKeys.LeadMortgage.key}
      />
    </div>
  );
};

LeadsMortgageTableComponent.propTypes = {
  detailsLeadsList: PropTypes.instanceOf(Object).isRequired,
  tableActionClicked: PropTypes.func.isRequired,
  onPageIndexChanged: PropTypes.func.isRequired,
  onPageSizeChanged: PropTypes.func.isRequired,
  filter: PropTypes.instanceOf(Object).isRequired,
  parentTranslationPath: PropTypes.string.isRequired,
  focusedRowChanged: PropTypes.func.isRequired,
  checkedCardsIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  getIsSelected: PropTypes.func.isRequired,
  // getIsDisabled: PropTypes.func.isRequired,
  onSelectClicked: PropTypes.func.isRequired,
  activeSelectedAction: PropTypes.string.isRequired,
  onFilterValuesChanged: PropTypes.func.isRequired,
  leadsTableFilter: PropTypes.instanceOf(Object),
  setCheckedCardsIds: PropTypes.func.isRequired,
  checkedCardsIds: PropTypes.instanceOf(Object).isRequired,
  setCheckedCards: PropTypes.func.isRequired,
};
LeadsMortgageTableComponent.defaultProps = {
  leadsTableFilter: undefined,
};
