import React, { Children, useMemo } from "react";
import { FormControlLabel } from "@material-ui/core";

export const LeadScoreRadioButtons = ({ onClick, selected }) => {
  const rates = useMemo(() => {
    const data = [];
    for (let i = 1; i <= 10; i++) {
      data.push(i);
    }
    return data;
  }, []);

  const RadioItem = ({ value, selected, onClick }) => (
    <span
      className={`radio-item-score ${selected ? "selected" : ""}`}
      style={{
        border: "1px solid #84693f",
        color: "#84693f",
      }}
      onClick={onClick}
    >
      {value}
    </span>
  );

  const onCLickHandler = (i) => {
    if (i === selected) {
      onClick(null);
    } else {
      onClick(i);
    }
  };

  return Children.toArray(
    rates.map((i) => (
      <FormControlLabel
        value={i}
        control={
          <RadioItem
            value={i}
            selected={selected === i}
            onClick={() => onCLickHandler(i)}
          />
        }
      />
    ))
  );
};
