import React, { useCallback, useEffect, useState } from 'react'; 
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { showError, showSuccess } from '../../../../../../../Helper';
import { SetUnitAsAvailableOrDraft, GetAllUnitTransactionReasons, VerifyDraftToAvailableRequirements, CreateUnitRequestToPublishOrUnPublish } from '../../../../../../../Services';
import { UnitsOperationTypeEnum, UnitsStatusEnum } from '../../../../../../../Enums';
import { DialogComponent, Inputs, Spinner, AutocompleteComponent } from '../../../../../../../Components';
import { FieldsNeedingApprovalDialog } from '../../../../UnitsSalesProfileManagement/Sections/UnitInformationComponent/FieldsNeedingApprovalDialog';
import { useSelector } from 'react-redux';

export const UnitStatusAvailableDialog = ({
  activeItem,
  isOpen,
  reloadData,
  isOpenChanged,
  translationPath,
  parentTranslationPath,
  fieldsNeedsApproval,
  loginResponse
}) => {
  
  
  
  const { t } = useTranslation([parentTranslationPath, 'Shared']);
  const [isLoading, setIsLoading] = useState(false);
  const [notes, setNotes] = useState('');
  const [cancellationReasons, setCancellationReasons] = useState([]);
  const [selectedCancelReason, setSelectedCancelReason] = useState(null);
  const [isNeedApprovalDialogOpen, setIsNeedApprovalDialogOpen] =
  useState(false);
  const [changedValues, setChangedValues] = useState({});
  const [originalVales , setOriginalVales] = useState({})
  const isForLease = false
  
  const saveHandler = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    const body = {
      unitId: activeItem?.id,
      status: UnitsStatusEnum.Available.key,
      note: notes,
      rowVersion: activeItem?.rowVersion,
      OperationType: UnitsOperationTypeEnum.sale.key,
      unitTransactionReasonId: selectedCancelReason?.unitTransactionReasonId,
    };
      // Check if 'status' is in fieldsNeedsApproval
      const needsApproval = fieldsNeedsApproval?.some(field => field.toLowerCase() === "status")
      if (needsApproval) {
        const response = await VerifyDraftToAvailableRequirements({
          unitId: activeItem?.id,
          status: UnitsStatusEnum.Available.key,
          OperationType: UnitsOperationTypeEnum.sale.key,
        });
  
        if (!(response && response.status && response.status !== 200)) {
          setChangedValues({ Status: body });
          setOriginalVales({Status : {
            unitId: activeItem?.id,
            status: UnitsStatusEnum.Draft.key,
            note: notes,
            rowVersion: activeItem?.rowVersion,
            OperationType: UnitsOperationTypeEnum.sale.key,
            unitTransactionReasonId: selectedCancelReason?.unitTransactionReasonId,
          }})
          setIsNeedApprovalDialogOpen(true);
          setIsLoading(false)
        } else {
          showError(t(`${translationPath}${response.data.Message.split(': ')[1]}`));
          setIsLoading(false);
        }
      } else {
        const res = await SetUnitAsAvailableOrDraft(body);
        if (!(res && res.status && res.status !== 200)) {
          showSuccess(t`${translationPath}unit-status-updated-successfully`);
          if (reloadData) reloadData();
        } else {
          if (res?.data?.Message) {
            showError(t(`${translationPath}${res.data.Message.split(': ')[1]}`));
          } else {
            showError(t(`unit-status-update-failed`));
          }
          setIsLoading(false);
        }
      }
      setIsLoading(false);
  };
  

  const getAllUnitTransactionReasons = useCallback( async ()=>{

    setIsLoading(true);
    const currentStatus = activeItem?.unitStatus?.key;
    const destinationStatus = UnitsStatusEnum.Available.key;

    const res = await GetAllUnitTransactionReasons(currentStatus, destinationStatus);

    setCancellationReasons(res)
    if (!(res && res.status && res.status !== 200)) {
      setCancellationReasons(res)
    }
    setIsLoading(false);
  },[activeItem.unitStatus])


  useEffect(()=>{
    getAllUnitTransactionReasons();
  },[getAllUnitTransactionReasons])

  return (
    <>
    <DialogComponent
      titleText='available-transaction'
      saveText='save'
      maxWidth='sm'
      dialogContent={(
        <div className='unit-status-draft-dialog-wrapper view-wrapper'>
          <Spinner isActive={isLoading} isAbsolute />
          <div className='title-box-wrapper'>
            <div className='box-wrapper'>
              <div className='box-item-wrapper'>
                <span className='box-title'>{t(`${translationPath}ref-no`)}</span>
                <span className='box-value'>{activeItem?.refNo || 'N/A'}</span>
              </div>
              <div className='box-item-wrapper'>
                <span className='box-title'>{t(`${translationPath}unit-no`)}</span>
                <span className='box-value'>
                  {activeItem?.unit_number || 'N/A'}
                </span>
              </div>
              <div className='box-item-wrapper'>
                <span className='box-title'>{t(`${translationPath}community`)}</span>
                <span className='box-value'>{(activeItem?.community?.lookupItemName) || 'N/A'}</span>
              </div>
              <div className='box-item-wrapper'>
                <span className='box-title'>{t(`${translationPath}property`)}</span>
                <span className='box-value'>{(activeItem?.propertyName) || 'N/A'}</span>
              </div>
            </div>
          </div>
          <div className='w-100 px-3 mb-3'>
            <Inputs
              idRef='notesRef'
              labelValue='notes'
              value={notes || ''}
              multiline
              rows={6}
              parentTranslationPath={parentTranslationPath}
              translationPath={translationPath}
              onInputChanged={(event) => setNotes(event.target.value)}
            />
          </div>
          {
          (activeItem?.unitStatus?.key !== UnitsStatusEnum.Draft.key) &&
            <div className='w-100 px-3 mb-3'>
              <AutocompleteComponent
                idRef='cancellation-reasonRef'
                labelValue='cancellation-reason'
                selectedValues={selectedCancelReason}
                data={cancellationReasons || []}
                displayLabel={(option) => t(`${option.reasonName || ''}`)}
                getOptionSelected={(option) =>
                  selectedCancelReason.unitTransactionReasonId === option.unitTransactionReasonId !== -1 || ''}
                withoutSearchButton
                isWithError
                parentTranslationPath={parentTranslationPath}
                translationPath={translationPath}
                multiple={false}
                onChange={(event, newValue) => {
                  setSelectedCancelReason(newValue);
                }}
              />
            </div>
          }
          
        </div>
      )}
      isOpen={isOpen}
      onSubmit={saveHandler}
      onCancelClicked={isOpenChanged}
      parentTranslationPath={parentTranslationPath}
      translationPath={translationPath}
    />
    {isNeedApprovalDialogOpen && (
        <FieldsNeedingApprovalDialog
          isDialogOpen={isNeedApprovalDialogOpen}
          setIsDialogOpen={setIsNeedApprovalDialogOpen}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          loginResponse={loginResponse}
          activeItem={activeItem}
          changedValues={changedValues}
          isForLease={isForLease}
          reloadData={reloadData}
          originalVales={originalVales}
        />
      )}
   </>
  );
  
};

UnitStatusAvailableDialog.propTypes = {
  activeItem: PropTypes.instanceOf(Object),
  reloadData: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  isOpenChanged: PropTypes.func.isRequired,
  parentTranslationPath: PropTypes.string,
  translationPath: PropTypes.string,
};
UnitStatusAvailableDialog.defaultProps = {
  activeItem: null,
  parentTranslationPath: 'UnitsStatusManagementView',
  translationPath: '',
};
