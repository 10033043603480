import React, {
  useState, useCallback, useEffect, useReducer
} from 'react';
import { useTranslation } from 'react-i18next';
import ButtonBase from '@material-ui/core/ButtonBase';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { CloseLeadDialog } from './Dialogs';
import {
  ActionsButtonsComponent,
  SelectComponet,
  Spinner,
  PermissionsComponent , HighPofileManagementDialog
} from '../../../../../../Components';
import {
  GetParams,
  GlobalHistory,
  showError,
  showSuccess,
  returnPropsByPermissions,
} from '../../../../../../Helper';
import {
  CloneLeads,
  LeadsAdvanceSearchTest,
  lookupItemsGetId,
  ReassignLeads,
} from '../../../../../../Services';
import { ContactProfileLeadsTable } from './ContactProfileLeadsUtilities/ContactProfileLeadsTable';
import { LeadsStatusEnum, LeadTypeIdEnum } from '../../../../../../Enums';
import { ContactsPermissions } from '../../../../../../Permissions';
import { ReassignLeadDialog } from './Dialogs/ReassignLeadDialog/ReassignLeadDialog';
import { CloneLeadsDialog } from '../../../../LeadsView/LeadsUtilities/Dialogs/CloneLeadsDialog/CloneLeadsDialog';
import { UnqualifiedLeadsDialog } from '../../../../../DFMSharedComponents/Unqualified/UnqualifiedLeadsDialog';

export const ContactProfileLeadsComponent = ({
  parentTranslationPath,
  translationPath,
  contactWithPolicy , 
}) => {
  const { t } = useTranslation(parentTranslationPath);
  const location = useLocation();
  const [activeItem, setActiveItem] = useState(null);
  const [isHighPofileManagement , setIsHighPofileManagement] = useState((contactWithPolicy && contactWithPolicy.contact && contactWithPolicy.contact.high_profile_management ==='Yes') || (
    contactWithPolicy && contactWithPolicy.contact && contactWithPolicy.contact.angry_bird  && contactWithPolicy.contact.angry_bird ==='Yes'
  ) ) ;
  const [openHighPofileManagementDialog , setOpenHighPofileManagementDialog] = useState(false) ;
  const [leadType, setLeadType] = useState(0) ; 

  const [isOpenCloseLead, setIsOpenCloseLead] = useState(false);
  const [leadsDetailes, setLeadsDetailes] = useState(null);
  const [contactId, setContactId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isOpenReassign, setIsOpenReassign] = useState(false);
  const [isOpenCloneLeads, setisOpenCloneLeads] = useState(false);
  const [isOpenClosedLeads, setIsOpenClosedLeads] = useState(false);
  const [isOpenClosedLeadsUnqualified, setIsOpenClosedLeadsUnqualified] = useState(false);
  const [leadActions, setLeadActions] = useState([]);
  const [checkedCards, setCheckedCards] = useState('');
  const [actionType, setActionType] = useState({ id: null, value: null });
  const [status, setStatus] = useState([]);
  const reducer = useCallback((state, action) => {
    if (action.id !== 'edit') return { ...state, [action.id]: action.value };
    return {
      ...action.value,
    };
  }, []);
  const [state, setState] = useReducer(reducer, {
    leadIds: [],
    leads: [],
    userType: null
  });
  const [filter, setFilter] = useState({
    pageSize: parseInt(localStorage.getItem('Pagination')) || 25,
    pageIndex: 0,
    leadStatus: null,
  });

  const getAllFinances = useCallback(async () => {
    const res = await lookupItemsGetId({
      lookupTypeId: LeadsStatusEnum.lookupTypeId,
    });
    if (!(res && res.status && res.status !== 200)) setStatus(res || []);
  }, []);
  useEffect(() => {
    getAllFinances();
  }, [getAllFinances]);
  const pathName = window.location.pathname
    .split('/home/')[1]
    .split('/view')[0]
    .split('/contact-profile-edit')[0];
  useEffect(() => {
    setContactId(+GetParams('id'));
  }, [location]);

  const getAllLeads = useCallback(async () => {
    setIsLoading(true);

    const localFilterDto = {
      contactId: [{ searchType: 1, value: contactId }],
    };

    if (filter?.leadStatus) {
      localFilterDto['status.lookupItemName'] = [{ searchType: 1, value: filter?.leadStatus }];
    }

    if (actionType.id === 'reassign-seeker-lead' || actionType.id === 'clone') {
      localFilterDto['lead_type_id'] = [{ searchType: 1, value: LeadTypeIdEnum.Seeker.leadTypeId }];
    }

    if (actionType.id === 'reassign-owner-lead') {
      localFilterDto['lead_type_id'] = [{ searchType: 1, value: LeadTypeIdEnum.Owner.leadTypeId }];
    }

    const body = {
      criteria: localFilterDto,
      filterBy: "createdOn",
      orderBy: 2
    };


    const list = [];
    let res = await LeadsAdvanceSearchTest({
      pageIndex: filter.pageIndex,
      pageSize: filter.pageSize
    }, body);
    if (!(res && res.status && res.status !== 200)) {
      res && res.result && res.result.map((value) => {
        const leadJson = JSON.parse(value.leadJson);
        list.push({
          id: value.leadId,
          name: leadJson?.lead?.contact_name?.name,
          numberOfActivities: leadJson?.lead?.Number_Of_Activities || 0,
          leadClass: leadJson?.lead?.leadClass,
          status: leadJson?.lead?.status,
          noActivities: value?.noActivities,
          createdOn: value?.createdOn,
          matchUnit: value?.matchUnit || 0,
          referredto: leadJson?.lead?.referredto,
          leadTypeId: leadJson?.lead?.lead_type_id,
          leadUnitType: value?.leadUnitType,
        });
      });
      setLeadsDetailes({
        result: list,
        totalCount: res && res.totalCount,
      });

    } else {
      setLeadsDetailes({
        result: [],
        totalCount: 0,
      });
    }
    setIsLoading(false);
  }, [actionType, filter, contactId]);


  useEffect(() => {
    if (contactId) getAllLeads();
  }, [getAllLeads]);

  const onFormTypeSelectChanged = (formType) => {
    setLeadType(formType)
    if(isHighPofileManagement)
    setOpenHighPofileManagementDialog(true); 
  
  else GlobalHistory.push(`/home/Contacts-CRM/addLead?formType=${formType}&contactId=${contactId}`) ; 
    
  };
  const filterByChanged = (newValue) => {
    setFilter((item) => ({ ...item, pageIndex: 0, leadStatus: newValue }));
    setActionType({ id: null, value: null });
  };
  const actionTypeChanged = (newValue) => {
    onStateChange('leadIds', []);
    onStateChange('leads', []);
    setActionType((item) => ({ ...item, id: newValue }));
    if (!newValue)
      setFilter((item) => ({ ...item, pageIndex: 0, leadStatus: null }));
    else
      setFilter((item) => ({ ...item, pageIndex: 0, leadStatus: newValue === 'clone' ? LeadsStatusEnum.Closed.key : LeadsStatusEnum.Open.key }));
  };

  const onStateChange = (valueId, newValue) => {
    setState({ id: valueId, value: newValue });
  };



  const cloneLeads = useCallback(async (items) => {
    setActionType({ id: null, value: null });
    setFilter((item) => ({ ...item, leadStatus: null }));
    onStateChange('leadIds', []);
    onStateChange('leads', []);
    const result = await CloneLeads(items);
    if (!(result && result.status && result.status !== 200)) {
      showSuccess(t(`${translationPath}clone-leads-notification`));
    } else { showError(t(`${translationPath}leads-clone-failed`)); }

  }, []);



  const reassignHandler = async (reassignItem) => {
    setActionType({ id: null, value: null });
    onStateChange('leadIds', []);
    onStateChange('lead', []);
    setFilter((item) => ({ ...item, pageIndex: 0, leadStatus: null }));
    showSuccess(t(`${translationPath}leads-reassign-notification`));
    await ReassignLeads({ leadIds: state.leadIds, referredToId: reassignItem.referredToId, isCopyTo: reassignItem.isCopyTo });

  };

  useEffect(() => {
    const list = [];
    list.push({ id: 'Unqualified', value: 'Unqualified' });

    if (returnPropsByPermissions(ContactsPermissions.ReassignSellerLead.permissionsId))
     list.push({ id: 'reassign-owner-lead', value: 'reassign-owner-lead' });

    if (returnPropsByPermissions(ContactsPermissions.ReassignLead.permissionsId))
      list.push({ id: 'reassign-seeker-lead', value: 'reassign-seeker-lead' });

    if (returnPropsByPermissions(ContactsPermissions.CloneLead.permissionsId))
      list.push({ id: 'clone', value: 'clone' },);

    if (returnPropsByPermissions(ContactsPermissions.CloseLead.permissionsId))
      list.push({ id: 'close-leads', value: 'close-leads' });
    setLeadActions(list);
  }, []);

  return (
    <div className='associated-contacts-wrapper childs-wrapper'>
      <Spinner isActive={isLoading} />
      <div className='title-section'>
        <span>{t(`${translationPath}leads`)}</span>
      </div>
      <div className='filter-sections-wrapper mb-2'>
        <PermissionsComponent
          permissionsList={Object.values(ContactsPermissions)}
          permissionsId={ContactsPermissions.AddLead.permissionsId}
        >
          <div className='filter-section-item'>
            <ActionsButtonsComponent
              withType
              typeData={[
                { id: '1', name: 'owner' },
                { id: '2', name: 'seeker' },
              ]}
              onFormTypeSelectChanged={onFormTypeSelectChanged}
            />
          </div>

        </PermissionsComponent>

        <div className='filter-section-item'>
          <span className='fw-simi-bold'>
            {t(`${translationPath}status`)}
            :
          </span>
          <SelectComponet
            idRef='lookupItemNamefilterByRef'
            data={status}
            value={filter.leadStatus}
            onSelectChanged={filterByChanged}
            wrapperClasses='mx-2'
            themeClass='theme-transparent'
            valueInput='lookupItemName'
            textInput='lookupItemName'
            emptyItem={{ value: null, text: 'all', isDisabled: false }}
            parentTranslationPath={parentTranslationPath}
            translationPath={translationPath}
            translationPathForData={translationPath}
          />
        </div>
        {
          leadActions.length > 0 && (
            <div className='filter-section-item'>
              <span className='fw-simi-bold'>
                {t(`${translationPath}lead-actions`)}
                :
              </span>
              <SelectComponet
                idRef='leadActionsfilterByRef'
                data={leadActions}
                valueInput='id'
                textInput='value'
                value={actionType.id}
                onSelectChanged={actionTypeChanged}
                wrapperClasses='mx-2'
                emptyItem={{ value: null, text: 'select', isDisabled: false }}
                parentTranslationPath={parentTranslationPath}
                translationPath={translationPath}
                translationPathForData={translationPath}
              />
            </div>
          )
        }
        {((actionType.id === 'reassign-seeker-lead') && state.leadIds.length > 0) && (
          <div className='filter-section-item'>
            <ButtonBase
              disabled={((actionType.id !== 'reassign-seeker-lead') || state.leadIds.length < 1)}
              onClick={() => setIsOpenReassign(true)}
              className='btns theme-transparent c-gray-primary'
            >
              <span className='mdi mdi-send-outline mdi-rotate-315' />
              <span className='px-2'>{t(`${translationPath}reassign`)}</span>
            </ButtonBase>
          </div>
        )}
        {((actionType.id === 'reassign-owner-lead') && state.leadIds.length > 0) && (
          <div className='filter-section-item'>
            <ButtonBase
              disabled={((actionType.id !== 'reassign-owner-lead') || state.leadIds.length < 1)}
              onClick={() => setIsOpenReassign(true)}

              className='btns theme-transparent c-gray-primary'
            >
              <span className='mdi mdi-send-outline mdi-rotate-315' />
              <span className='px-2'>{t(`${translationPath}reassign`)}</span>
            </ButtonBase>
          </div>
        )}
        {((actionType.id === 'close-leads') && state && state.leadIds && state.leadIds.length > 0) && (
          <div className='filter-section-item'>
            <ButtonBase
              disabled={actionType.id !== 'close-leads'}
              onClick={() => setIsOpenClosedLeads(true)}
              className='btns theme-transparent c-gray-primary'
            >
              <span className='mdi mdi-send-outline mdi-rotate-315' />
              <span className='px-2'>{t(`${translationPath}close-leads`)}</span>
            </ButtonBase>
          </div>
        )}
        {((actionType.id === 'Unqualified') && state && state.leadIds && state.leadIds.length > 0) && (
          <div className='filter-section-item'>
            <ButtonBase
              disabled={actionType.id !== 'Unqualified'}
              onClick={() => setIsOpenClosedLeadsUnqualified(true)}
              className='btns theme-transparent c-gray-primary'
            >
              <span className='mdi mdi-send-outline mdi-rotate-315' />
              <span className='px-2'>{t(`${translationPath}UnqualifiedLeads`)}</span>
            </ButtonBase>
          </div>
        )}
        {((actionType.id === 'clone') && state.leadIds.length > 0) && (
          <div className='filter-section-item'>
            <ButtonBase
              disabled={((actionType.id !== 'clone') || state.leadIds.length < 1)}
              onClick={() => setisOpenCloneLeads(true)}
              className='btns theme-transparent c-gray-primary'
            >
              <span className='mdi mdi-content-copy' />
              <span className='px-2'>{t(`${translationPath}clone`)}</span>
            </ButtonBase>
          </div>
        )}
      </div>
      <ContactProfileLeadsTable
        state={state}
        filter={filter}
        pathName={pathName}
        setFilter={setFilter}
        leadsDetailes={leadsDetailes}
        setActiveItem={setActiveItem}
        setCheckedCards={setCheckedCards}
        onStateChange={onStateChange}
        isOpenCloseLead={isOpenCloseLead}
        translationPath={translationPath}
        setIsOpenCloseLead={setIsOpenCloseLead}
        parentTranslationPath={parentTranslationPath}
        actionType={actionType.id}
      />

      {(isOpenReassign && (
        <ReassignLeadDialog
          isOpen={isOpenReassign}
          onClose={() => { setIsOpenReassign(false); }}
          userType={state && state.userType}
          onSave={(reassignItem) => {
            reassignHandler(reassignItem);
            setIsOpenReassign(false);
            setActionType((item) => ({ ...item, id: null }));
          }}
          translationPath={translationPath}
          parentTranslationPath={parentTranslationPath}
        />

      ))}
      {(isOpenCloneLeads && (
        <CloneLeadsDialog
          isOpen={isOpenCloneLeads}
          checkedCardsIds={state && state.leadIds && state.leadIds}
          checkedCards={checkedCards}
          totalCloneLeads={state && state.leadIds && state.leadIds.length}
          setIsLoading={setIsLoading}
          onSave={(item) => { cloneLeads(item); setisOpenCloneLeads(false); }}
          onClose={() => {
            setisOpenCloneLeads(false);
            setActionType({ id: null, value: null });
            setFilter((item) => ({ ...item, leadStatus: null }));
            onStateChange('leadIds', []);
            onStateChange('leads', []);
          }}
        />
      ))}
      {(isOpenClosedLeads && (
        <CloseLeadDialog
          isOpen={isOpenClosedLeads}
          onClose={() => {
            setIsOpenClosedLeads(false);
          }}
          listOfSelectedIds={state.leadIds}
          activeItem={activeItem}
          reloadData={() => {
            setIsOpenClosedLeads(false);
            setActionType({ id: null, value: null });
            setFilter((item) => ({ ...item, leadStatus: null }));

            setState({
              id: 'edit',
              value: {
                leadIds: [], leads: [], userType: null
              },
            });
          }}
          translationPath={translationPath}
          parentTranslationPath={parentTranslationPath}
        />

      ))}
      {
        isOpenClosedLeadsUnqualified && (
          <UnqualifiedLeadsDialog
            isOpen={isOpenClosedLeadsUnqualified}
            checkedCardsIds={state.leadIds}
            onSave={(item) => {
              setIsOpenClosedLeadsUnqualified(false);
              setActionType({ id: null, value: null });
              setFilter((item) => ({ ...item, leadStatus: null }));

              setState({
                id: 'edit',
                value: {
                  leadIds: [], leads: [], userType: null
                },
              });
            }}
            onClose={() => {
              setIsOpenClosedLeadsUnqualified(false);
            }}
          />
        )
      }
       {
        openHighPofileManagementDialog && (
          <HighPofileManagementDialog
           isOpenDialog={openHighPofileManagementDialog}
           onClose={()=> setOpenHighPofileManagementDialog(false)}
           parentTranslationPath={parentTranslationPath}
           translationPath={translationPath}
           onSave={()=> GlobalHistory.push(`/home/Contacts-CRM/addLead?formType=${leadType}&contactId=${contactId}`)}
           withCreateLeadForm
         
          />
        )
      }
    </div>
  );
};
ContactProfileLeadsComponent.propTypes = {
  parentTranslationPath: PropTypes.string.isRequired,
  translationPath: PropTypes.string.isRequired,
};
