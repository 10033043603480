
import React from "react";

export const IndividualKycFormText = {
    SourceOfFund : {
        beforeText: <div>
            <p>I hereby confirm that the above information provided to you is true and correct to the best of our knowledge. I/We acknowledge that if the information provided is found to be false or misleading then the business relationship may be annulled anytime at your discretion. I/We hereby agree to provide any additional information/documentation that may be required.</p>
            <p>At Property Shop Investment LLC, we are committed to protecting the confidentiality and security of the personal information you’ve provided in this form. The data collected is used solely for the purposes of verifying your identity and ensuring compliance with legal and regulatory requirements. The company is committed to safeguard your information against unauthorized access, disclosure, or misuse and will handle it in strict accordance with applicable data protection laws.</p>
        </div>
    },
}