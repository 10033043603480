import React, { useCallback, useEffect, useReducer, useState } from "react";
import {
  AutocompleteComponent,
  DialogComponent,
  Inputs,
  Spinner,
  UploaderComponent,
} from "../../../../../Components";
import {
  ApproveOrRejectContactConsent,
  GetLookupItemsByLookupTypeName,
} from "../../../../../Services";
import { useTranslation } from "react-i18next";
import LinkIcon from "@material-ui/icons/Link";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { UploaderThemesEnum } from "../../../../../Enums";
import {
  Button,
  ButtonBase,
  DialogActions,
  makeStyles,
  withStyles,
} from "@material-ui/core";
import { GetParams, showError, showSuccess } from "../../../../../Helper";

const CancelButton = withStyles(() => ({
  root: {
    color: "#000000",
    backgroundColor: "#",
    border: "1px solid #e0e0e0",
    borderRadius: "8px",
    "&:hover": {
      backgroundColor: "#f5f5f5",
    },
  },
}))(Button);

const SubmitButton = withStyles(() => ({
  root: {
    color: "white",
    backgroundColor: "#84693f",
    border: "2px solid #84693f",
    borderRadius: "8px",
    "&:hover": {
      backgroundColor: "#6d5734",
    },
  },
}))(Button);

const useStyles = makeStyles({
  Inactive: {
    color: "#fff",
    backgroundColor: "#c7c7cc",
    padding: "6px 16px",
    borderRadius: "0.75rem",
  },
  Unblock: {
    color: "#fff",
    backgroundColor: "#8fd14f",
    padding: "6px 16px",
    borderRadius: "0.75rem",
  },
  Block: {
    color: "#fff",
    backgroundColor: "#f24726",
    padding: "6px 16px",
    borderRadius: "0.75rem",
  },
});

function DNCRDialog({
  isOpen,
  onClose,
  reload,
  setReload,
  translationPath,
  parentTranslationPath,
}) {
  const { t } = useTranslation(parentTranslationPath);
  const reducer = useCallback((state, action) => {
    if (action.id !== "edit") return { ...state, [action.id]: action.value };
    return {
      ...action.value,
    };
  }, []);
  const classes = useStyles();
  const contactId = GetParams("id");
  const [copied, setCopied] = useState(false);
  const [isApproved, setIsApproved] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useReducer(reducer, {
    reasons: [],
  });

  const [selected, setSelected] = useReducer(reducer, {
    reason: null,
    link: null,
    document: null,
    evidence: null,
    remarks: null,
  });

  const getReasons = useCallback(async () => {
    setIsLoading(true);
    const res = await GetLookupItemsByLookupTypeName({
      lookUpName: "DNCRReasons",
      pageIndex: 0,
      pageSize: 99999,
    });

    if (!(res && res.status && res.status !== 200)) {
      setData({ id: "reasons", value: res });
    } else setData([]);
    setIsLoading(false);
  }, []);

  function onCopyLinkClicked() {
    navigator.clipboard
      .writeText(selected.link || "")
      .then(() => {
        setCopied(true);
        setTimeout(() => setCopied(false), 2000);
      })
      .catch((err) => {
        console.error("Failed to copy text: ", err);
      });
  }

  const onSubmitClicked = async () => {
    setIsLoading(true);
    if (selected.link || selected.document || selected.evidence) {
      const body = {
        contactId: contactId,
        isApproved: isApproved,
        isDNCR: true,
        changeReasonId: selected?.reason?.lookupItemId,
        remarks: selected?.remarks,
        evidenceDtos: [
          {
            fileId: selected?.document?.uuid,
            fileName: selected?.document?.fileName,
            evidenceLink: selected?.link,
            writtenEvidence: selected?.evidence,
          },
        ],
      };
      const res = await ApproveOrRejectContactConsent(body);

      if (!(res && res.status && res.status !== 200)) {
        showSuccess("submitted-sucessfully");
        setReload(!reload);
        onClose();
      } else {
        showError("there was an error");
      }
    } else showError("please-enter-one-evidance-at-least");
    setIsLoading(false);
  };

  useEffect(() => {
    getReasons();
  }, []);

  return (
    <React.Fragment>
      <Spinner isActive={isLoading} isAbsolute />
      <DialogComponent
        isOpen={isOpen}
        dialogTitle={"DNCR"}
        maxWidth={"lg"}
        dialogContent={
          <React.Fragment>
            <div className="mt-4 mb-2">
              <div className="d-flex-col">
                <div className="d-flex mx-4 mb-3">
                  <DialogActions>
                    <ButtonBase
                      onClick={(e) => setIsApproved(true)}
                      className={`${
                        isApproved ? classes.Unblock : classes.Inactive
                      }`}
                    >
                      {t("unblock")}
                    </ButtonBase>
                    <ButtonBase
                      onClick={(e) => setIsApproved(false)}
                      className={`${
                        isApproved ? classes.Inactive : classes.Block
                      }`}
                    >
                      {t("block")}
                    </ButtonBase>
                  </DialogActions>
                </div>
                <div className="d-flex">
                  <div className="w-50 mx-4">
                    <AutocompleteComponent
                      idRef="reasonRef"
                      labelValue="reason"
                      labelClasses="Requierd-Color"
                      data={data?.reasons?.result || []}
                      selectedValues={selected?.reason || null}
                      multiple={false}
                      withoutSearchButton
                      displayLabel={(option) => option?.lookupItemName || ""}
                      renderOption={(option) => option?.lookupItemName || ""}
                      onChange={(event, newValue) => {
                        setSelected({
                          id: "reason",
                          value: newValue || null,
                        });
                      }}
                      translationPath={translationPath}
                      parentTranslationPath={parentTranslationPath}
                    />
                  </div>
                  <div className="w-50 mx-4">
                    <Inputs
                      idRef="linkRef"
                      labelClasses="Requierd-Color"
                      labelValue={t(`attached-link`)}
                      value={selected?.link || ""}
                      onInputChanged={(e) => {
                        const { value } = e?.target;
                        if (value)
                          setSelected({
                            id: "link",
                            value: value,
                          });
                        else {
                          setSelected({
                            id: "link",
                            value: null,
                          });
                        }
                      }}
                      endAdornment={
                        <div className="mr-2 mt-1">
                          {copied ? (
                            <CheckCircleIcon
                              className="pointer"
                              color="primary"
                            />
                          ) : (
                            <LinkIcon
                              className="pointer"
                              color="primary"
                              onClick={onCopyLinkClicked}
                            />
                          )}
                        </div>
                      }
                      translationPath={translationPath}
                      parentTranslationPath={parentTranslationPath}
                    />
                  </div>
                </div>
                <div className="d-flex my-2">
                  <div className="w-100 mx-4">
                    <UploaderComponent
                      idRef="uploadDocumentsRef"
                      labelValue="upload-documents"
                      multiple={false}
                      accept="image/*"
                      isOneFile
                      uploaderTheme={UploaderThemesEnum.input.key}
                      uploadedChanged={(files) => {
                        setSelected({
                          id: "document",
                          value: (files && files.length && files[0]) || null,
                        });
                      }}
                      translationPath={translationPath}
                      parentTranslationPath={parentTranslationPath}
                    />
                  </div>
                </div>
                <div className="d-flex mt-4 mb-2">
                  <div className="w-50 mx-4">
                    <Inputs
                      idRef="evidenceRef"
                      labelClasses="Requierd-Color"
                      labelValue={t(`written-evidence`)}
                      value={selected?.evidence || ""}
                      multiline
                      rows={6}
                      onInputChanged={(e) => {
                        const { value } = e?.target;
                        if (value)
                          setSelected({
                            id: "evidence",
                            value: value,
                          });
                        else {
                          setSelected({
                            id: "evidence",
                            value: null,
                          });
                        }
                      }}
                      translationPath={translationPath}
                      parentTranslationPath={parentTranslationPath}
                    />
                  </div>
                  <div className="w-50 mx-4">
                    <Inputs
                      idRef="remarksRef"
                      labelValue={t(`remarks`)}
                      value={selected?.remarks || ""}
                      multiline
                      rows={6}
                      onInputChanged={(e) => {
                        const { value } = e?.target;
                        if (value)
                          setSelected({
                            id: "remarks",
                            value: value,
                          });
                        else {
                          setSelected({
                            id: "remarks",
                            value: null,
                          });
                        }
                      }}
                      translationPath={translationPath}
                      parentTranslationPath={parentTranslationPath}
                    />
                  </div>
                </div>
              </div>
              <DialogActions>
                <CancelButton onClick={(e) => onClose()}>
                  {t("cancel")}
                </CancelButton>
                <SubmitButton onClick={(e) => onSubmitClicked()}>
                  {t("submit")}
                </SubmitButton>
              </DialogActions>
            </div>
          </React.Fragment>
        }
        translationPath={translationPath}
        parentTranslationPath={parentTranslationPath}
      />
    </React.Fragment>
  );
}

export default DNCRDialog;
