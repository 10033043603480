import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
    containerCard: {
        padding: '24px'
    },
    divider: {
        marginTop: '15px',
        marginBottom: '16px',
    },
    containerinfoItems: {
        display: 'flex',
        flexDirection: 'column',
        gap: '12px',
    },
    InfoItem:{
        display: 'flex',
        alignItems: 'center',
        gap: '16px',
        [theme.breakpoints.down('xs')]: {
          flexDirection: "column",
          gap: '0px',
          alignItems: "flex-start",
        },
    }

}));
