
export const ContactClassTypesEnum = {
    Normal: {
      key: 1,
      name:'Normal' , 
      value: 'normal',
    },
    VIP: {
      key: 2,
      name:'VIP' , 
      value: 'vip',
    },
    VVIP: {
      key: 3,
      name:'VVIP' , 
      value: 'vvip',
    },
  };
