import React, {
    useCallback, useEffect, useRef, useState
} from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import Joi from 'joi';
import { AutocompleteComponent, Inputs, Spinner } from '../../../../../../Components';
import { GetContacts } from '../../../../../../Services';
import {
    getErrorByName, GlobalTranslate, showError, showSuccess
} from '../../../../../../Helper';

export const ContactsActionWhatsappDialogsComponent = ({
    isOpenChanged,
    item,
    translationPath,
    parentTranslationPath,
    unitItem,
    showlink
}) => {
    const [state, setstate] = useState(() => ({
        contactIds: '',
        message: '',
        mobileNumber: ''
    }));
    const { t } = useTranslation(parentTranslationPath);
    const [res, setres] = useState([]);
    // const [Selected, setSelected] = useState([]);
    const [isLoading, setisLoading] = useState(false);
    const [isLoading2, setisLoading2] = useState(false);
    const searchTimer = useRef(null);
    const ContactsAPI = useCallback(async (search) => {
        setisLoading(true);
        const results = await GetContacts({
            pageIndex: 0, pageSize: 100, search, isAdvance: false
        });
        setres(results.result);
        setisLoading(false);
    }, []);

    const schema = Joi.object({
        contactIds: Joi.array()
            .min(1)
            .required()
            .messages({
                'array.min': t(`${translationPath}please-select-at-least-one-contact`),
            }),
        message: Joi.string()
            .required()
            .messages({
                'string.base': t(`${translationPath}message-is-required`),
                'string.empty': t(`${translationPath}message-is-required`),
            }),
    })
        .options({
            abortEarly: false,
            allowUnknown: true,
        })
        .validate(state);

    useEffect(() => {
        ContactsAPI();
    }, [ContactsAPI]);

    useEffect(() => {
        if (unitItem) {
            setstate({
                ...state,
                message: `${unitItem.name} -  ${unitItem.bedrooms} bedrooms - ${unitItem.bathrooms} bathrooms\n\rTo See More click Here: ${window.location.origin}/share/UnitCard?id=${unitItem.id}`
            });
        }
    }, [unitItem]);
    useEffect(
        () => () => {
            if (searchTimer.current) clearTimeout(searchTimer.current);
        },
        []
    );
    const searchHandler = (e) => {
        const { value } = e.target;
        if (searchTimer.current) clearTimeout(searchTimer.current);
        searchTimer.current = setTimeout(() => {
            ContactsAPI(value);
        }, 700);
    };

    const saveHandler = useCallback(async () => {
        setisLoading2(true);
        setisLoading(false);
        const element = document.createElement('a');
        if (state.mobileNumber) {
            // showSuccess(t`${translationPath}whatsapp-sernd-successfully`);
            isOpenChanged();
            element.href = `https://api.whatsapp.com/send/?phone= ${state.mobileNumber}&text=${state.message}&app_absent=0`;
            element.target = 'blank';
            element.click();
            setisLoading2(false);
        }
        else {
            // showError(`${translationPath}whatsapp-sernd-failed`);
            isOpenChanged();
        }
    }, [isOpenChanged, state, t, translationPath]);

    return (
        <div className='w-100 px-3'>
            <Spinner isActive={isLoading2} isAbsolute />
            <div>
                <AutocompleteComponent
                    idRef='ToRef'
                    labelValue='To'
                    data={res}
                    isLoading={isLoading}
                    chipsLabel={(option) =>
                        option.contact.company_name ||
                        (option.contact &&
                            (option.contact.first_name || option.contact.last_name) &&
                            `${option.contact.first_name} ${option.contact.last_name}`) ||
                        ''}
                    displayLabel={(option) =>
                        option.contact && option.contact.company_name ||
                        (option.contact &&
                            (option.contact.first_name || option.contact.last_name) &&
                            `${option.contact.first_name} ${option.contact.last_name}`) ||
                        ''}
                    withoutSearchButton
                    inputPlaceholder={t(`${translationPath}Selectcont`)}
                    isSubmitted
                    isWithError
                    onInputKeyUp={(e) => searchHandler(e)}
                    parentTranslationPath={parentTranslationPath}
                    translationPath={translationPath}
                    onChange={(newValue, event) => {
                        setstate((items) => ({
                            ...items,
                            mobileNumber: event && event.contact.mobile && event.contact.mobile.phone
                        }));
                    }}
                    multiple={false}
                    value={state && state.mobile && state.mobile.phone}
                />
            </div>
            <div className='massage-style-box'>
                <Inputs
                    idRef='MassageRef'
                    labelValue='Massage'
                    value={state.message || ''}
                    helperText={getErrorByName(schema, 'message').message}
                    error={getErrorByName(schema, 'message').error}
                    isWithError
                    multiline
                    rows={7}
                    parentTranslationPath={parentTranslationPath}
                    translationPath={translationPath}
                    onInputChanged={(event) => {
                        const { value } = event.target;
                        setstate((items) => ({
                            ...items,
                            message: value,
                        }));
                    }}
                />
            </div>
            <span>{t(`${translationPath}number-of-characters`)}</span>
            {' '}
            <span>
                :
                {' '}
                {' '}
                {state.message.length}
            </span>
            <div className='d-flex-v-center-h-end flex-wrap'>
                <Button
                    className='MuiButtonBase-root btns theme-transparent mb-2'
                    onClick={() => {
                        isOpenChanged();
                    }}
                >
                    <span>{t(`${translationPath}Cancel`)}</span>
                    <span className='MuiTouchRipple-root' />
                </Button>
                <Button
                    disabled={!state.mobileNumber}
                    className='MuiButtonBase-root btns theme-solid mb-2'
                    onClick={() => { saveHandler(); }}
                >
                    <span>{t(`${translationPath}Send`)}</span>
                </Button>
            </div>
        </div>
    );
};
ContactsActionWhatsappDialogsComponent.propTypes = {
    isOpenChanged: PropTypes.func.isRequired,
    item: PropTypes.instanceOf(Object).isRequired,
    unitItem: PropTypes.instanceOf(Object),
    translationPath: PropTypes.string.isRequired,
    showlink: PropTypes.bool,
    parentTranslationPath: PropTypes.string.isRequired,
};
ContactsActionWhatsappDialogsComponent.defaultProps = {
    showlink: false
};
