import React, { useCallback, useEffect, useReducer, useRef, useState } from 'react'
import { DialogActions, Dialog, DialogTitle, DialogContent, ButtonBase } from '@material-ui/core';
import { AutocompleteComponent } from '../../../../../../Components';
import { useTranslation } from 'react-i18next';
import { Spinner } from '../../../../../../Components';

import { SaveShareUnits } from '../../../../../../Services';
import Joi from 'joi';
import { getErrorByName } from '../../../../../../Helper';
import { showError, showSuccess } from '../../../../../../Helper';
import { GetShareSystems, SearchApplicationUsersForShareEntity, SearchBranchesForShareEntity } from '../../../../../../Services/ShareEntity/ShareUnit';

export const ShareUnitsDialog = ({
    activeData, parentTranslationPath, isOpen, onClose, unitOperationSource }) => {
    const translationPath = 'UnitsView:utilities.ShareUnitDialog.';
    const reducer = useCallback((state, action) => {
        if (action.id !== 'edit') return { ...state, [action.id]: action.value };
        return {
            ...action.value,
        };
    }, []);
    const searchTimer = useRef(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isEditDialog, setIsEditDialog] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [loadings, setLoadings] = useReducer(reducer, {
        systemName: false,
        branchName: false,
        specificUser: false,
    });
    const [data, setData] = useReducer(reducer, {
        allSystemName: [],
        allSpecificUser: [],
        allBranchName: [],
    });
    const [selected, setSelected] = useReducer(reducer, {
        systemName: null,
        specificUser: [],
        branchName: [],
    });
    const [state, setState] = useReducer(reducer, {
        unitsIds: [activeData.id],
        SystemNameId: null,
        branches: [
            {
                branchId: null,
                name: null
            }
        ],
        users: [
            {
                userId: null,
                name: null
            }
        ]
    });
    const { t } = useTranslation(parentTranslationPath);

    const resetRelatedTo = () => {
        setState({ id: 'branches', value: [] });
        setState({ id: 'users', value: [] });
        setSelected({ id: 'specificUser', value: [] });
        setSelected({ id: 'branchName', value: [] });
    };

    const getAllShareSystems = useCallback(async () => {
        setLoadings({
            id: 'systemName',
            value: true
        })
        const res = await GetShareSystems();
        if (!(res && res.status && res.status !== 200)) {
            const dataResponse = res
            const updatedSystems = dataResponse.map(system =>
                system.isCurrentSystem ? { ...system, name: 'current system', id: system.id } : system
            );

            setData({
                id: 'allSystemName',
                value: updatedSystems,
            });
        } else {
            setData({
                id: 'allSystemName',
                value: [],
            });
        }

        setLoadings({
            id: 'systemName',
            value: false
        });
    }, []);

    const searchBranchesForShareEntity = useCallback(async ({ systemId, branchName }) => {
        if (!systemId || systemId === null) {
            systemId = state.SystemNameId
        }
        setLoadings({
            id: 'branchName',
            value: true
        })
        const body = {
            sourceSystemId: systemId || state.SystemNameId,
            branchName: branchName,
            pageIndex: 1,
            pageSize: 15
        }
        const res = await SearchBranchesForShareEntity({ body });
        if (!(res && res.status && res.status !== 200)) {
            if (res.result.length > 0) {
                setData({
                    id: 'allBranchName',
                    value: res.result,
                });
            } else
                setData({
                    id: 'allBranchName',
                    value: [],
                });
        }
        setLoadings({
            id: 'branchName',
            value: false
        })
    }, [state.SystemNameId])

    const searchApplicationUsersForShareEntity = useCallback(async ({ systemId, userName }) => {
        if (!systemId || systemId === null) {
            systemId = state.SystemNameId
        }
        setLoadings({
            id: 'specificUser',
            value: true
        })

        const body = {
            sourceSystemId: systemId,
            usersId: null,
            name: null,
            userName: userName,
            whatsAppNumber: null,
            phoneNumber: null,
            email: null,
            pageIndex: 1,
            pageSize: 15
        }
        const res = await SearchApplicationUsersForShareEntity({ body });
        if (!(res && res.status && res.status !== 200)) {
            if (res.result.length > 0) {
                setData({
                    id: 'allSpecificUser',
                    value: res.result,
                });
            } else
                setData({
                    id: 'allSpecificUser',
                    value: [],
                });
        }
        setLoadings({
            id: 'specificUser',
            value: false
        })
    }, [state.SystemNameId])

    const saveHandler = async (event) => {
        event.preventDefault();
        if (schema?.error?.message) {
            showError(schema.error.message);
            return;
        }
        setIsLoading(true);

        const body = [{
            unitsIds: state.unitsIds || [],
            users: state.users || [],
            branches: state.branches || [],
            targetSystemId: state.SystemNameId || 0,
            unitOperationSource: unitOperationSource
        }]
        const res = await SaveShareUnits(body);
        setIsLoading(false);
        if (!(res && res.status && res.status !== 200)) {
            showSuccess(t(`${translationPath}Shared-unit-successfully`));
            onClose()
        } else {
            showError(t(`${translationPath}failed-Shared-unit`));

        }
    }
    const schema = Joi.object({
        SystemNameId: Joi.number()
            .required()
            .messages({
                'number.base': t(`${translationPath}System-name-is-required`),
            }),
        branches: Joi.array()
            .custom((value, helpers) => {
                if (state.branches?.length === 0 && state.users?.length === 0)
                    return helpers.error('custom-select');
                return value;
            })
            .messages({
                // 'array.base': t(`${translationPath}specificUser-is-required`),
                'custom-select': t(`${translationPath}select-at-least-branchName-or-spacific-user`),
            }),
        users: Joi.array()
            .custom((value, helpers) => {
                if (state.branches?.length === 0 && state.users?.length === 0)
                    return helpers.error('custom-select');
                return value;
            })
            .messages({
                // 'array.base': t(`${translationPath}specificUser-is-required`),
                'custom-select': t(`${translationPath}select-at-least-branchName-or-spacific-user`),
            }),
    })
        .options({
            abortEarly: false,
            allowUnknown: true,
        })
        .validate(state);


    useEffect(() => {
        getAllShareSystems()
    }, [])

    return (
        <Dialog
            open={isOpen}
            onClose={onClose}
            className='activities-management-dialog-wrapper'
        >
            <form noValidate onSubmit={saveHandler}>
                <DialogTitle>
                    {t(`${translationPath}Share-Unit`)}
                </DialogTitle>
                <DialogContent>
                    <div className='dialog-content-wrapper'>
                        <Spinner isActive={isLoading} isAbsolute />
                        <div className='dialog-content-item'>
                            <AutocompleteComponent
                                idRef='SystemNameRef'
                                labelValue={t(`${translationPath}System-Name`)}
                                isSubmitted={isSubmitted}
                                isWithError
                                selectedValues={selected.systemName || null}
                                data={data.allSystemName}
                                displayLabel={(option) => option.name || ''}
                                withoutSearchButton
                                multiple={false}
                                isDisabled={isEditDialog}
                                helperText={getErrorByName(schema, 'SystemNameId').message}
                                error={getErrorByName(schema, 'SystemNameId').error}
                                onChange={(event, newValue) => {
                                    setState({
                                        id: 'SystemNameId',
                                        value: (newValue && newValue.id) || null,
                                    });
                                    setSelected({
                                        id: 'systemName',
                                        value: newValue
                                    });
                                    setState({
                                        id: 'branches',
                                        value: null,
                                    });
                                    setState({
                                        id: 'users',
                                        value: null,
                                    });
                                    setSelected({
                                        id: 'branchName',
                                        value: null,
                                    });
                                    setSelected({
                                        id: 'specificUser',
                                        value: null,
                                    });
                                    resetRelatedTo();
                                    searchBranchesForShareEntity({ systemId: (newValue && newValue.id) || null })
                                    searchApplicationUsersForShareEntity({ systemId: (newValue && newValue.id) || null })
                                }}
                                chipsLabel={(option) => t(`${option.name || ''}`)}
                                isLoading={loadings.systemName}
                            />
                        </div>
                        <div className='dialog-content-item'>
                            <AutocompleteComponent
                                idRef='BranchNameRef'
                                labelValue={t(`${translationPath}Branch-Name`)}
                                selectedValues={selected.branchName || []}
                                data={data.allBranchName}
                                displayLabel={(option) => option.branchName || ''}
                                withoutSearchButton
                                isWithError
                                helperText={getErrorByName(schema, 'branches').message}
                                error={getErrorByName(schema, 'branches').error}
                                multiple={true}
                                isDisabled={state.SystemNameId === null}
                                onChange={(event, newValue) => {

                                    const updatedBranches = newValue.map((item) => ({
                                        branchId: item.branchId,
                                        name: item.branchName,
                                    }));

                                    setState({
                                        id: 'branches',
                                        value: updatedBranches,
                                    });
                                    setSelected({
                                        id: 'branchName',
                                        value: newValue,
                                    });
                                }}
                                onKeyDown={() => {
                                    setSelected({ id: 'branchName', value: null });
                                }}
                                onInputKeyUp={(e) => {
                                    const searchText = e?.target?.value;
                                    if (searchTimer.current) clearTimeout(searchTimer.current);
                                    searchTimer.current = setTimeout(() => {
                                        searchBranchesForShareEntity({ branchName: searchText });
                                    }, 700);
                                }}
                                renderOption={(option) =>
                                    ((option.branchName || option.branchId) &&
                                        `${option.branchName}`) ||
                                    ''}
                                chipsLabel={(option) => t(`${option.branchName || ''}`)}
                                isLoading={loadings.branchName}
                                isSubmitted={isSubmitted}
                            />
                        </div>
                        <div className='dialog-content-item'>
                            <AutocompleteComponent
                                idRef='SpecificUseRef'
                                labelValue={t(`${translationPath}Specific-User`)}
                                selectedValues={selected.specificUser || []}
                                data={data.allSpecificUser}
                                displayLabel={(option) => option.fullName || ''}
                                multiple={true}
                                isWithError
                                helperText={getErrorByName(schema, 'users').message}
                                error={getErrorByName(schema, 'users').error}
                                withoutSearchButton
                                isDisabled={state.SystemNameId === null}
                                chipsLabel={(option) => t(`${option.fullName || ''}`)}
                                onChange={(event, newValue) => {
                                    const updatedusers = newValue.map((item) => ({
                                        userId: item.id,
                                        name: item.fullName,
                                        email:item.email
                                    }));

                                    setState({
                                        id: 'users',
                                        value: updatedusers,
                                    });
                                    setSelected({
                                        id: 'specificUser',
                                        value: newValue,
                                    });
                                }}
                                onKeyDown={() => {
                                    setSelected({ id: 'specificUser', value: null });
                                }}
                                onInputKeyUp={(e) => {
                                    const searchText = e?.target?.value;
                                    if (searchTimer.current) clearTimeout(searchTimer.current);
                                    searchTimer.current = setTimeout(() => {
                                        searchApplicationUsersForShareEntity({ userName: searchText });
                                    }, 700);
                                }}
                                renderOption={(option) =>
                                    ((option.userName || option.id) &&
                                        `${option.fullName}`) ||
                                    ''}
                                isLoading={loadings.specificUser}
                                isSubmitted={isSubmitted}
                            />
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <div className='form-builder-wrapper'>
                        <div className='form-builder-footer-wrapper is-dialog w-100 MuiGrid-align-items-xs-center MuiGrid-justify-xs-space-between'>
                            <div className='MuiGrid-root-left'>
                                <ButtonBase
                                    className='MuiButtonBase-root MuiButton-root MuiButton-text btns c-danger'
                                    onClick={() => onClose()}
                                >
                                    <span className='MuiButton-label'>
                                        <span className='mx-2'>
                                            {t(`${translationPath}cancel`)}
                                        </span>
                                    </span>
                                    <span className='MuiTouchRipple-root' />
                                </ButtonBase>
                            </div>
                            <div className='MuiGrid-root-right'>
                                <ButtonBase
                                    className='MuiButtonBase-root MuiButton-root MuiButton-text btns theme-solid bg-primary'
                                    id='Submit-bbt'
                                    onClick={saveHandler}
                                >
                                    <span className='MuiButton-label'>
                                        <span className='mx-2'>
                                            {t(`${translationPath}save`)}
                                        </span>
                                    </span>
                                    <span className='MuiTouchRipple-root' />
                                </ButtonBase>
                            </div>
                        </div>
                    </div>
                </DialogActions>
            </form>
        </Dialog>
    )
}

// ShareUnitsDialog.PropTypes = {
//     isOpen: PropTypes.bool.isRequired,
//     onClose: PropTypes.func.isRequired,
//     activeData: PropTypes.instanceOf(Object).isRequired,
// };
// ShareUnitsDialog.defaultProps ={
//     isOpen:false ,
//     onClose:()=>{} ,
//     activeData:undefined ,
//  }