import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => {
  return {
    iconWrapper: {
      display: "inline-flex",
      alignItems: "center",
      justifyContent: "center",
      width: "24px",
      height: "24px",
      background: theme.palette.utility.utility_gray_200,
      borderRadius: theme.borderRadius[6],
    },
  }
});
