import { UnitsCreatedBy, UnitsAddress } from ".";
import { UnitsRentListedBy } from "./UnitsRentListedBy";
import { UnitsSaleListedBy } from "./UnitsSaleListedBy";
import { SaleTransactedBy } from "./SaleTransactedBy";
import { LeaseTransactedBy } from "./LeaseTransactedBy";
export const UnitsPoliciesTabs = [
  {
    label: "createdBy",
    component: UnitsCreatedBy,
  },
  {
    label: "address",
    component: UnitsAddress,
  },
  {
    label: "saleListing",
    component: UnitsSaleListedBy,
  },
  {
    label: "leaseListing",
    component: UnitsRentListedBy,
  },
  {
    label: "saleTransactedBy",
    component: SaleTransactedBy,
  },
  {
    label: "LeaseTransactedBy",
    component: LeaseTransactedBy,
  },
];
