
import React  from "react";
import { useTranslation } from "react-i18next";
import { ButtonBase } from "@material-ui/core";

export const FooterWrapperComponent = ({
  parentTranslationPath,
  translationPath,
  isOpen,
  onClose,
  onSave,
  disabled 
}) => {
  const { t } = useTranslation(parentTranslationPath);

  return ( 
          <div className="form-builder-wrapper">
            <div className="form-builder-footer-wrapper is-dialog w-100 MuiGrid-align-items-xs-center MuiGrid-justify-xs-space-between">
              <div className="MuiDialogActions-root dialog-footer-wrapper  MuiDialogActions-spacing">
                <div className="save-cancel-wrapper d-flex-v-center-h-end flex-wrap p-2">
                  <div className="cancel-wrapper d-inline-flex-center">
                    <ButtonBase
                      className="MuiButtonBase-root MuiButton-root MuiButton-text cancel-btn-wrapper btns theme-transparent c-primary"
                      tabIndex="0"
                      id="cancel-ref-action"
                      onClick={() => onClose()}
                    >
                      <span className="MuiButton-label">
                        <span>{t(`${translationPath}cancel`)}</span>
                      </span>
                      <span className="MuiTouchRipple-root" />
                    </ButtonBase>
                  </div>
                  <div className="save-wrapper d-inline-flex-center">
                    <ButtonBase
                      className="MuiButtonBase-root MuiButton-root MuiButton-text save-btn-wrapper btns theme-solid bg-primary w-100 mx-2 mb-2"
                      tabIndex="0"
                      disabled={disabled}
                      onClick={() => {
                        onSave();
                      }}
                    >
                      <span className="MuiButton-label">
                        <span>{t(`${translationPath}save`)}</span>
                      </span>
                      <span className="MuiTouchRipple-root" />
                    </ButtonBase>
                  </div>
                </div>
              </div>
            </div>
          </div>
  );
};

