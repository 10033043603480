import { Chip } from '@material-ui/core';
import React from 'react';
import { Tables } from '../../../../../Components';
import DeActiveUserLogHistoryHeader from './DeActiveUserLogHistoryHeaderData';

export const DeActiveUserLogHistoryTable = ({
  data,
  parentTranslationPath,
}) => {
  return (
    <div className='p-3'>
      {data?.length === 0 || !data ? (
        <Chip component={'h1'} label={`no-records-found`} />
      ) : (
        <Tables
          data={data ?? []}
          parentTranslationPath={parentTranslationPath}
          headerData={DeActiveUserLogHistoryHeader}
        />
      )}
    </div>
  );
};
