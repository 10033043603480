import React, { useEffect, useState, useReducer, useRef, useCallback } from 'react';
import Joi from 'joi';
import { useTranslation } from 'react-i18next';
import { Button } from 'react-bootstrap';
import { Inputs, AutocompleteComponent } from '../../../../../Components';
import { lookupItemsGetId, lookupItemsGet } from '../../../../../Services';
import googlemaps from '../../../../../assets/images/icons/Google_Maps_icon.png';
import { MapDialog } from '../../../FormBuilder/Dialogs/MapDialog';
import 'react-quill/dist/quill.snow.css';
import { getErrorByName } from '../../../../../Helper';
import { DescriptionComponents } from './DescriptionComponents';
import { StaticLookupsIds } from '../../../../../assets/json/StaticLookupsIds';

export const LandmarkMainInformation = ({
  parentTranslationPath,
  translationPath,
  selected,
  setSelected,
  isEditView
}) => {
  const LandmarkCategoriesLookupName = 'LandmarkCategories';
  const { t } = useTranslation(parentTranslationPath);
  const searchTimer = useRef(null);

  const reducer = useCallback((state, action) => {
    if (action.id !== 'edit') return { ...state, [action.id]: action.value };
    return {
      ...action.value,
    };
  }, []);

  const [openMapDialog, setOpenMapDialog] = useState(false);
  const [data, setData] = useReducer(reducer, {
    countries: [],
    cities: [],
    districts: [],
    communities: [],
    subCommunities: [],
    landmarkCategories: [],
  });

  const getAddressLookupsById = async (lookupTypeId, lookupParentId) => {
    const response = await lookupItemsGetId({
      lookupTypeId,
      lookupParentId,
    });
    if (!(response && response.status && response.status !== 200)) {
      if (lookupTypeId === StaticLookupsIds.Country)
        setData({ id: 'countries', value: response || [] });
      else if (lookupTypeId === StaticLookupsIds.Cities)
        setData({ id: 'cities', value: response || [] });
      else if (lookupTypeId === StaticLookupsIds.District)
        setData({ id: 'districts', value: response || [] });
      else if (lookupTypeId === StaticLookupsIds.Community)
        setData({ id: 'communities', value: response || [] });
      else if (lookupTypeId === StaticLookupsIds.SubCommunity)
        setData({ id: 'subCommunities', value: response || [] });
    }
  };

  const getLookupsByName = async (lookupTypeName, searchedItem) => {
    const response = await lookupItemsGet({
      pageIndex: 1,
      pageSize: 25,
      lookupTypeName,
      searchedItem,
    });
    if (!(response && response.status && response.status !== 200)) {
      if (lookupTypeName === LandmarkCategoriesLookupName)
        setData({ id: 'landmarkCategories', value: response?.result || [] });
    }
  };

  // const schema = Joi.object({
  //   LocationName: Joi.string()
  //     .required()
  //     .messages({
  //       'string.empty': t(`${translationPath}name-is-required`),
  //     }),
  //   arabicLocationName: Joi.string()
  //     .required()
  //     .messages({
  //       'string.empty': t(`${translationPath}name-is-required`),
  //     }),
  // })
  //   .options({
  //     abortEarly: false,
  //     allowUnknown: true,
  //   })
  //   .validate(state);



  useEffect(() => {
      getAddressLookupsById(StaticLookupsIds.Country);
  }, []);




  return (
    <div className='lamdmarks-form1'>
      <div className='d-flex-column'>
        <div className='w-100 px-2 mb-4'>
          <Inputs
            idRef='landmarkEnglishNameRef'
            // helperText={getErrorByName(schema, 'landmarkEnglishName').message}
            // error={getErrorByName(schema, 'landmarkEnglishName').error}
            isWithError
            labelValue='landmark-name'
            labelClasses='Requierd-Color'
            translationPath={translationPath}
            parentTranslationPath={parentTranslationPath}
            value={selected.landmarkEnglishName || ''}
            onInputChanged={(event) => setSelected({ id: 'landmarkEnglishName', value: event.target.value })}
          />
          <Inputs
            idRef='landmarkArabicNameRef'
            labelValue='landmark-name-ar'
            // helperText={getErrorByName(schema, 'landmarkArabicName').message}
            // error={getErrorByName(schema, 'landmarkArabicName').error}
            labelClasses='Requierd-Color'
            isWithError
            translationPath={translationPath}
            parentTranslationPath={parentTranslationPath}
            value={selected.landmarkArabicName || ''}
            onInputChanged={(event) => setSelected({ id: 'landmarkArabicName', value: event.target.value })}
          />
          <div className='filter-item w-100 mb-2'>
            <AutocompleteComponent
              idRef='filterCategoryIdRef'
              inputPlaceholder={t(`${translationPath}filterCategory`)}
              labelValue='category'
              selectedValues={selected.category || null}
              multiple={false}
              data={data.landmarkCategories || []}
              onOpen={() => {
                if (data.landmarkCategories?.length == 0)
                  getLookupsByName(LandmarkCategoriesLookupName);
              }}
              displayLabel={(option) => option.lookupItemName || ''}
              withoutSearchButton
              isWithError
              parentTranslationPath={parentTranslationPath}
              translationPath={translationPath}
              onChange={(event, newValue) => {
                setSelected({
                  id: 'edit', value: {
                    ...selected,
                    category: newValue,
                    schoolNationality: null,
                    studentPercentage: '',
                    studentCount: 0,
                    studentRate: '',
                  }
                });
              }}
              onInputKeyUp={(e) => {
                const searchedValue = e?.target?.value;
                if (searchTimer.current) clearTimeout(searchTimer.current);
                searchTimer.current = setTimeout(() => {
                  getLookupsByName(LandmarkCategoriesLookupName, searchedValue);
                }, 500);
              }}
            />
          </div>
          <Inputs
            idRef='addressLine1EnglishRef'
            // helperText={getErrorByName(schema, 'addressLine1English').message}
            // error={getErrorByName(schema, 'addressLine1English').error}
            isWithError
            labelValue='addressLine1English'
            translationPath={translationPath}
            parentTranslationPath={parentTranslationPath}
            value={selected.addressLine1English || ''}
            onInputChanged={(event) => setSelected({ id: 'addressLine1English', value: event.target.value })}
          />
          <Inputs
            idRef='addressLine1ArabicRef'
            labelValue='addressLine1Arabic'
            // helperText={getErrorByName(schema, 'addressLine1Arabic').message}
            // error={getErrorByName(schema, 'addressLine1Arabic').error}
            isWithError
            translationPath={translationPath}
            parentTranslationPath={parentTranslationPath}
            value={selected.addressLine1Arabic || ''}
            onInputChanged={(event) => setSelected({ id: 'addressLine1Arabic', value: event.target.value })}
          />
          <DescriptionComponents
            parentTranslationPath={parentTranslationPath}
            translationPath={translationPath}
            enDescription={selected.enDescription || ''}
            setDescription={(newValue) => setSelected({ id: 'enDescription', value: newValue?.englishDescription || '' })}
          />
          <Inputs
            idRef='arDescriptionRef'
            labelValue={t(`${translationPath}arbic-description`)}
            wrapperClasses='mt-3'
            translationPath={translationPath}
            multiline
            inputPlaceholder={t(`${translationPath}TypeHere`)}
            rows={10}
            parentTranslationPath={parentTranslationPath}
            value={selected.arDescription || ''}
            onInputChanged={(event) => setSelected({ id: 'arDescription', value: event.target.value })}
          />
          <Inputs
            idRef='GooglecordsRef'
            labelValue='Google-cords'
            wrapperClasses='mt-2 mb-0'
            translationPath={translationPath}
            parentTranslationPath={parentTranslationPath}
            value={selected.latitude || ''}
            onInputChanged={(event) => setSelected({ id: 'latitude', value: event.target.value })}
            endAdornment={
              <>
                <div className='d-flex-v-center '>
                  <Inputs
                    idRef='premiumPercentageOfBasePriceRef'
                    labelClasses='inside-input-label'
                    wrapperClasses='mb-0'
                    value={selected.longitude || ''}
                    onInputChanged={(event) => setSelected({ id: 'longitude', value: event.target.value })}
                    parentTranslationPath={parentTranslationPath}
                    translationPath={translationPath}
                  />
                </div>
                <Button
                  onClick={() => {
                    setOpenMapDialog(true);
                  }}
                  className='googlemaps-bbt '>
                  <img
                    src={googlemaps}
                    alt={t(`${translationPath}googlemaps`)}
                    className='Open-map'
                  />
                  <span className='p-1'>{t(`${translationPath}Open-map`)}</span>
                </Button>
              </>
            }
          />
          <div className='d-flex mt-2'>
            <div className='filter-item mr-2 w-50'>
              <AutocompleteComponent
                idRef='countryRef'
                inputPlaceholder={t(`${translationPath}country`)}
                wrapperClasses='w-min-unset'
                selectedValues={selected.country || null}
                multiple={false}
                data={data.countries || []}
                getOptionSelected={(option) =>
                  option.lookupItemId === selected.country.lookupItemId
                }
                displayLabel={(option) => option.lookupItemName || ''}
                withoutSearchButton
                isWithError
                parentTranslationPath={parentTranslationPath}
                translationPath={translationPath}
                onChange={(event, newValue) => {
                  const countryId = newValue && newValue.lookupItemId;

                  setSelected({
                    id: 'edit', value: {
                      ...selected,
                      country: newValue,
                      city: null,
                      district: null,
                      community: null,
                      subCommunity: null,
                    }
                  });
                  if (countryId) getAddressLookupsById(StaticLookupsIds.Cities, countryId);
                }}
              />
            </div>
            <div className='filter-item w-50 '>
              <AutocompleteComponent
                idRef='cityRef'
                inputPlaceholder={t(`${translationPath}city`)}
                wrapperClasses='w-min-unset'
                selectedValues={selected.city || null}
                multiple={false}
                data={data.cities || []}
                onOpen={()=>{
                  const countryId = selected.country?.lookupItemId;

                  if(countryId && (data.cities?.length == 0)) getAddressLookupsById(StaticLookupsIds.Cities, countryId);
                }}
                displayLabel={(option) => option.lookupItemName || ''}
                getOptionSelected={(option) => option.lookupItemId === selected.city.lookupItemId}
                withoutSearchButton
                isWithError
                isDisabled={!selected.country}
                parentTranslationPath={parentTranslationPath}
                translationPath={translationPath}
                onChange={(event, newValue) => {
                  const cityId = newValue && newValue.lookupItemId;

                  setSelected({
                    id: 'edit', value: {
                      ...selected,
                      city: newValue,
                      district: null,
                      community: null,
                      subCommunity: null,
                    }
                  });
                  if (cityId) getAddressLookupsById(StaticLookupsIds.District, cityId);
                }}
              />
            </div>
          </div>
          <div className='d-flex mt-2'>
            <div className='filter-item mr-2 w-50 '>
              <AutocompleteComponent
                idRef='lfilterDistricteIdRef'
                inputPlaceholder={t(`${translationPath}district`)}
                wrapperClasses='w-min-unset'
                selectedValues={selected.district || null}
                multiple={false}
                data={data.districts || []}
                onOpen={()=>{
                  const cityId = selected.city?.lookupItemId;

                  if(isEditView && cityId && (data.districts?.length == 0)) getAddressLookupsById(StaticLookupsIds.District, cityId);
                }}
                displayLabel={(option) => option.lookupItemName || ''}
                withoutSearchButton
                getOptionSelected={(option) =>
                  option.lookupItemId === selected.district.lookupItemId
                }
                isWithError
                isDisabled={!selected.city}
                parentTranslationPath={parentTranslationPath}
                translationPath={translationPath}
                onChange={(event, newValue) => {
                  const districtId = newValue && newValue.lookupItemId;

                  setSelected({
                    id: 'edit', value: {
                      ...selected,
                      district: newValue,
                      community: null,
                      subCommunity: null,
                    }
                  });
                  if (districtId) getAddressLookupsById(StaticLookupsIds.Community, districtId);
                }}
              />
            </div>
            <div className='filter-item w-50'>
              <AutocompleteComponent
                idRef='lfilterCommunitiesIdRef'
                inputPlaceholder={t(`${translationPath}community`)}
                wrapperClasses='w-min-unset'
                selectedValues={selected.community || null}
                multiple={false}
                data={data.communities || []}
                onOpen={()=>{
                  const districtId = selected.district?.lookupItemId;

                  if(isEditView && districtId && (data.communities?.length == 0)) 
                    getAddressLookupsById(StaticLookupsIds.Community, districtId);
                }}
                displayLabel={(option) => option.lookupItemName || ''}
                withoutSearchButton
                isWithError
                isDisabled={!selected.district}
                parentTranslationPath={parentTranslationPath}
                translationPath={translationPath}
                onChange={(event, newValue) => {
                  const communityId = newValue && newValue.lookupItemId;

                  setSelected({
                    id: 'edit', value: {
                      ...selected,
                      community: newValue,
                      subCommunity: null,
                    }
                  });
                  if (communityId)
                    getAddressLookupsById(StaticLookupsIds.SubCommunity, communityId);
                }}
              />
            </div>
          </div>
          <div className='d-flex mt-2'>
            <div className='filter-item mr-2 w-50'>
              <AutocompleteComponent
                idRef='lfilterSubCommunitiesIdRef'
                inputPlaceholder={t(`${translationPath}SubCommunity`)}
                wrapperClasses='w-min-unset'
                selectedValues={selected.subCommunity || null}
                multiple={false}
                data={data.subCommunities || []}
                onOpen={()=>{
                  const communityId = selected.community?.lookupItemId;

                  if(isEditView && communityId && (data.subCommunities?.length == 0)) 
                    getAddressLookupsById(StaticLookupsIds.SubCommunity, communityId);
                }}
                displayLabel={(option) => option.lookupItemName || ''}
                withoutSearchButton
                isWithError
                isDisabled={!selected.community}
                parentTranslationPath={parentTranslationPath}
                translationPath={translationPath}
                onChange={(event, newValue) => {

                  setSelected({
                    id: 'edit', value: {
                      ...selected,
                      subCommunity: newValue,
                    }
                  });
                }}
              />
            </div>
          </div>


        </div>
      </div>
      {openMapDialog && (
        <MapDialog
          open={openMapDialog}
          onChange={(e) => {
            setSelected({
              id: 'edit', value: {
                ...selected,
                longitude: e.longitude,
                latitude: e.latitude
              }
            });
          }}
          initialState={{ latitude: selected.latitude, longitude: selected.longitude }}
          closeDialog={() => {
            setOpenMapDialog(false);
          }}
        />
      )}
    </div>
  );
};


