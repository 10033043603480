import React, { useCallback, useEffect, useReducer, useState } from "react";
import btnLoader from "../../../../../assets/images/GIF_images/btn-loader.gif";
import { PageHeading } from "../../../../../SharedComponents";
import { RiskRatingDuration } from "./FormFractions";
import { RatingCriteria } from "./FormFractions/RatingCriteria";
import { GetAllRiskRatingConfigration, GetLookupItemsByLookupTypeName, UpdateRiskRatingConfigration } from "../../../../../Services";
import { ButtonBase } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { GlobalHistory, showError, showSuccess } from "../../../../../Helper";
import Joi from "joi";

export const GeneralConfiguration = ({ parentTranslationPath }) => {
  const translationPath = "GeneralConfigurationForm.";
  const { t } = useTranslation(parentTranslationPath);

  const reducer = useCallback((state, action) => {
    if (action.id !== "edit") return { ...state, [action.id]: action.value };
    return {
      ...action.value,
    };
  }, []);

  const [selected, setSelected] = useReducer(reducer, {
    durationConfig: null,
    criteriaConfig: null,
  });
  const [data, setData] = useReducer(reducer, {
    durationLookups: [],
  });
  const [isLoading, setIsLoading] = useState(false);


  
  const schema = Joi.array().items(
    Joi.object({
      riskRatingDurationType: Joi.number(),
      riskRatingDurationId: Joi.number(),
      riskRatingLookupDto: Joi.object(),
    })
      .custom((value, helpers) => {
        if ((!value?.riskRatingLookupDto?.riskRatingLookupsId))
          return helpers.error('state.risk-duration');
        return value;
      }).messages({
        'state.risk-duration': t(`${translationPath}risk-duration-is-required`),
      })
      .options({
        abortEarly: false,
        allowUnknown: true,
      }))
    .validate(selected.durationConfig);


  const getRiskRatingConfigration = async () => {
    const res = await GetAllRiskRatingConfigration();
    if (!(res && res.status && res.status !== 200)) {
      setSelected({ id: "durationConfig", value: res?.riskRatingDurationDto || null });
      setSelected({ id: "criteriaConfig", value: res?.riskRatingFormLabels || null });
    }
  };

  const reloadData = () => getRiskRatingConfigration()
  

  const getRiskDurationLookups = async () => {
    const res = await GetLookupItemsByLookupTypeName({
      lookUpName: "RiskRatingDurationValue",
      pageSize: 100,
      pageIndex: 1,
    });
    if (!(res && res.status && res.status !== 200))
      setData({ id: "durationLookups", value: res?.result || [] });
    else setData({ id: "durationLookups", value: [] });
  };

  const getMappedUpdateBody = () => {
    const durationDTO = selected.durationConfig.map(item => ({
      riskRatingDurationId: item.riskRatingDurationId || null,
      riskRatingLookupsId: item.riskRatingLookupDto?.riskRatingLookupsId || null,
    }));

    let criteriaDTO = [];

    selected.criteriaConfig.forEach(category => {
      const mappedCriteriaValues = category.riskRatingFormValues.map(item => ({
        riskRatingFormLabelValueId: item.riskRatingFormLabeLValueId || null,
        riskRatingDurationType: item.riskRatingDurationType || null
      }));

      criteriaDTO = [
        ...criteriaDTO,
        ...(mappedCriteriaValues || [])
      ]
    });

    return ({
      riskRatingDurationDto: durationDTO,
      riskRatingFormValueDtos: criteriaDTO,
    })
  }
  
  const updateRiskRatingConfig = async () => {
    setIsLoading(true);
    const body = getMappedUpdateBody();
    const res = await UpdateRiskRatingConfigration(body);
    if ( res === true) {
      showSuccess(t(`${translationPath}Updated-successfully`));
      reloadData();
    } else showError(t(`${translationPath}updating-has-failed`))
    setIsLoading(false);
  };

  const saveHandler = () => {
    if (schema.error) {
      showError(t('Shared:please-fix-all-errors'));
      return;
    }

    if (!selected.durationConfig || !selected.criteriaConfig) return;
      updateRiskRatingConfig();
  }
  
  const discardClickHandler = () =>  GlobalHistory.push('/home/risk-rating-configuration/view');

  useEffect(() => {
    getRiskRatingConfigration();
  }, []);

  return (
    <div className="w-80">
      <div className="d-flex-v-center-h-between">
        <PageHeading
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          headerTitle={"GeneralConfiguration"}
          subTitle={"GeneralConfiguration-desc"}
          wrapperClasses={"px-4 w-75"}
          headerTitleClasses={"fz-16 mb-1"}
          subTitleClasses={"fz-14 m-0 ml-0"}
        />
        <div className="w-50 px-4 d-flex fj-end">
            <ButtonBase
              onClick={discardClickHandler}
              className="btns theme-propx outlined"
            >
              {t(`${translationPath}discard`)}
            </ButtonBase>
            
          <ButtonBase
            className="btns theme-propx solid mr-0"
            onClick={saveHandler}
            disabled={isLoading}
          >
            {t(`${translationPath}save`)}
            {isLoading ? (
              <img src={btnLoader} className="ml-1" width="12" height="12" />
            ) : (
              null
            )}
          </ButtonBase>
        </div>
      </div>

      <div className="propx-view-container sm m-3 d-flex-column">
        <RiskRatingDuration
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          ControlProps={{
            data,
            selected,
            onSelectedChange: (key, newValue) =>
              setSelected({ id: key, value: newValue }),
          }}
          DataSetFunctions={{
            getRiskDurationLookups,
          }}
        />
        <RatingCriteria
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          ControlProps={{
            data,
            selected,
            onSelectedChange: (key, newValue) =>
              setSelected({ id: key, value: newValue }),
          }}
        />
      </div>
    </div>
  );
};
