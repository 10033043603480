import React, { useCallback, useEffect, useState } from "react";
import { PowerBIEmbed } from "powerbi-client-react";
import { models } from "powerbi-client";
import "../MasterStyleDashborads/MasterStyleDashboards.scss";
import { MicrosoftToken } from "../../../../Services/DashboardService";
import { PowerBiEnum } from "../../../../Enums";
import { GlobalTranslate, showError } from "../../../../Helper";
import { useTitle } from "../../../../Hooks";
import { SpinnerDashboards } from "../SpinnerDashboards/SpinnerDashboards";
import { PermissionDeniedLayout } from "../../../../Layouts/PermissionDeniedLayout/PermissionDeniedLayout";
import { GetApplicationUserById } from "../../../../Services/userServices";
import { CellSpinnerBi } from "../CellSpinnerBi/CellSpinnerBi";

export const CampaigncostByBranch = () => {
  useTitle(GlobalTranslate.t("Dashboard:CampaignCost"));
  const [reporttoken, setReporttoken] = useState();
  const [teamid, setTeamid] = useState([]);
  const [render, setRender] = useState(false);
  const [ShowReport, setShowReport] = useState(false);
  const [brancid, setBranchid] = useState(null);

  const userid = JSON.parse(localStorage.getItem("session")).userId;

  const Microsoft = async (isExpired) => {
    const res = await MicrosoftToken(isExpired);
    if (!(res && res.status && res.status !== 200)) {
      setReporttoken(res);
      setRender(true);
    } else {
      setReporttoken("");
      showError("Contact Your Bi Admin For Help");
    }
  };

  const getUserById = useCallback(async () => {
    const res = await GetApplicationUserById(userid);
    if (!(res && res.status && res.status !== 200))
      setBranchid(res.branchId); else setBranchid(null);
  }, [userid]);

  const Filter = {
    $schema: 'http://powerbi.com/product/schema#basic',
    target: {
      table: 'Branches',
      column: 'BranchId',
    },
    operator: 'In',
    values: [brancid],
    filterType: models.FilterType.BasicFilter,
    requireSingleSelection: true,
  };

  useEffect(() => {

    getUserById(userid);
  }, [brancid]);
  useEffect(() => {
    setTimeout(() => {
      Microsoft(false);
    }, 500);
  
  
  }, []);



  return (
    <div className="dashboardMain-PowerBIEmbed">
      {render && brancid !== null ? (
        <div className="dashboardMain">
                   <CellSpinnerBi isActive={!ShowReport} isAbsolute blue />

          <PowerBIEmbed
            embedConfig={{
              type: "report",
              id: PowerBiEnum.CampaignCost.reportid,
              embedUrl: PowerBiEnum.CampaignCost.url,
              accessToken: reporttoken,
              filters: [Filter],
              settings: {
                customLayout: {
                  displayOption: models.DisplayOption.FitToWidth,
                },
                filterPaneEnabled: false,
                navContentPaneEnabled: false,
                panes: {
                  filters: {
                    expanded: false,
                    visible: false,
                  },
                },
                background: models.BackgroundType.Transparent,
              },
            }}
            eventHandlers={
              new Map([
                [
                  "loaded",
                  () => {
                    setShowReport(true);
                  },
                ],
                ["rendered", () => {}],
                [
                  "error",
                  (event) => {
                    const error = event.detail;

                    if (
                      error.detailedMessage === "Get report failed" ||
                      error.detailedMessage ===
                        "Access token has expired, resubmit with a new access token" ||
                      error.detailedMessage ===
                        "Fail to initialize - Could not resolve cluster"
                    ) {
                      Microsoft(true);
                      setRender(false);
                    } else console.log(error.detailedMessage);
                    showError(error.detailedMessage);
                  },
                ],
              ])
            }
            cssClassName={
              ShowReport ? "report-style-class-Show" : "report-style-class-hide"
            }
            getEmbeddedComponent={(embeddedReport) => {
              window.report = embeddedReport;
            }}
          />
        </div>
      ) : (
        <div>{render && <PermissionDeniedLayout />}</div>
      )}
    </div>
  );
};
