import React, { useReducer, useState, useCallback, useEffect } from 'react';
import { ReceiptsPerInvoiceView } from './InvoicesViewUtilities/ReceiptsPerInvoiceView';
import { InvoicesComponent } from './InvoicesComponent';
import { useLocation } from 'react-router-dom';
import { GetParams } from '../../../Helper';
import { GetInvoiceDetails } from '../../../Services';

const InvoiceViewsEnum = {
  INVOICES: 'invoices',
  RECEIPTS: 'receipts',
};
const parentTranslationPath = 'InvoicesView';
const translationPath = '';

export const InvoicesView = () => {
  const viewType = GetParams('view');
  const [view, setView] = useState(viewType ?? InvoiceViewsEnum.INVOICES);
  const [activeItem, setActiveItem] = useState(null);
  const [isFlagTrue, setIsFlagTrue] = useState(false);

  const [totalAmountPaid, setTotalAmountPaid] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  let location = useLocation();

  const reducer = useCallback((state, action) => {
    if (action.id !== 'edit') return { ...state, [action.id]: action.value };
    return {
      ...action.value,
    };
  }, []);
  const defaultState = {
    bankId: null,
    branch: null,
    remarks: null,
    draftNo: null,
    chequeNo: null,
    netAmount: null,
    invoicesIds: [],
    bondDetails: null,
    creditCardNo: null,
    paymentModeId: null,
    bankTransferNo: null,
    receiptContactTypeId: null,
    date: new Date().toISOString(),
    invoiceId: null,
    receiptDocuments: [],
  };

  const [state, setState] = useReducer(reducer, defaultState);
  const defaultSelected = {
    bankId: null,
    invoicesIds: [],
    paymentModeId: null,
    receiptContactTypeId: null,
    receiptDocuments: [],
  };

  const onStateChanged = (valueId, newValue) => {
    setState({ id: valueId, value: newValue });
  };

  const totalAmount = totalAmountPaid.reduce((a, b) => a + b, 0);
  useEffect(() => {
    onStateChanged('netAmount', totalAmount);
  }, [totalAmount, state.invoicesIds]);
  const [selected, setSelected] = useReducer(reducer, defaultSelected);
  const [isLoading, setIsLoading] = useState({
    allInvoices: false,
    branch: false,
  });
  const handleChangeView = (view) => {
    setView(view);
  };
  const onSelectedChanged = (valueId, newValue) => {
    setSelected({ id: valueId, value: newValue });
  };

  useEffect(() => {
    const invoiceId = location?.state?.invoiceId;
    const activeItem2 = location?.state?.activeItem;
    if (invoiceId) {
      setIsFlagTrue(true);
      setActiveItem(activeItem2);
    }
  }, [location]);

  return view === InvoiceViewsEnum.INVOICES ? (
    <InvoicesComponent
      handleChangeView={() => {
        handleChangeView(InvoiceViewsEnum.RECEIPTS);
      }}
      setTotalAmountPaid={setTotalAmountPaid}
      totalAmountPaid={totalAmountPaid}
      setOpenDialog={setOpenDialog}
      openDialog={openDialog}
      setSelected={setSelected}
      selected={selected}
      setIsLoading={setIsLoading}
      isLoading={isLoading}
      onStateChanged={onStateChanged}
      state={state}
      setState={setState}
      defaultState={defaultState}
      defaultSelected={defaultSelected}
      setActiveItem={setActiveItem}
      activeItem={activeItem}
      onSelectedChanged={onSelectedChanged}
      isFlagTrue={isFlagTrue}
    />
  ) : (
    <ReceiptsPerInvoiceView
      handleChangeView={() => {
        handleChangeView(InvoiceViewsEnum.INVOICES);
        setActiveItem(null);
      }}
      state={state}
      activeItem={activeItem}
      setSelected={setSelected}
      selected={selected}
      setState={setState}
      setTotalAmountPaid={setTotalAmountPaid}
      onStateChanged={onStateChanged}
      parentTranslationPath={parentTranslationPath}
      translationPath={translationPath}
      onSelectedChanged={onSelectedChanged}
      defaultState={defaultState}
      defaultSelected={defaultSelected}
    />
  );
};
