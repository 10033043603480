

import { HttpServices } from '../../Helper';
import { config } from '../../config';


export const GetAllPropertyRatingLifeStyleAPI = async () => 
 {
    const result = await HttpServices.get(
    `${config.server_address}/CrmDfm/PropertyRating/GetAllPropertyRatingLifeStyle`)
    .then((data) => data)
    .catch((error) => error.response);
    return result; 
 };


    export const UpdatePropertyRatingLifeStyleAPI = async (body) => {
        const result = await HttpServices.post(
            `${config.server_address}/CrmDfm/PropertyRating/UpdatePropertyRatingLifeStyle`, body
        )
            .then((data) => data)
            .catch((error) => error.response.data);
        return result;
    };

  
  
