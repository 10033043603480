import React, { useState, useEffect, useCallback } from 'react';
import { SubCommunityTypeIdEnum } from '../../../../../../../../../Enums';
import { AutocompleteComponent } from '../../../../../../../../../Components';
import { lookupItemsGetId } from '../../../../../../../../../Services/LookupsServices';

export const CommunityComponent = ({
  parentTranslationPath,
  translationPath,
  addressLoadings ,
  setAddressLoadings ,
  labelClasses , 
  isSubmitted,
   helperText,
   error,
   onUpdateValue , 
   setAddressList , 
   addressList , 
   propertyValues  , 
}) => {

  const GetSubCommunities = useCallback(async (cityId) => {
    setAddressLoadings((item) => ({ ...item, subCommunities:true }));
    const res = await lookupItemsGetId({
      lookupTypeId: SubCommunityTypeIdEnum.lookupTypeId,
      lookupParentId: cityId,
    });

    if (!(res && res.status && res.status !== 200)) {
      setAddressList((item) => ({ ...item, subCommunities: res }));

    } else
      setAddressList((item) => ({ ...item, subCommunities: [] }));
      setAddressLoadings((item) => ({ ...item, subCommunities:false }));
  }, []);

 
  return (
    <div>
     <AutocompleteComponent
          idRef='communityRef'
          labelValue='community'
          selectedValues={propertyValues && propertyValues.community}
          isLoading={addressLoadings.community}
          labelClasses={labelClasses}
          data={addressList && addressList.communities  || []}
          multiple={false}
          displayLabel={(option) => (option && option.lookupItemName) || ''}
          withoutSearchButton
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          onChange={(event, newValue) => {
           setAddressList((item) => ({ ...item , subCommunities:[] }));
          if(newValue)
          GetSubCommunities(newValue.lookupItemId) ; 
           onUpdateValue(newValue);
          }}
         isSubmitted={isSubmitted}
         helperText={helperText}
         error={error}
         isWithError
      />
    </div>
  );
};
