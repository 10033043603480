

export const ConsentLogPermissions = {
 
  GetContactConsentLog :{
     permissionsId: "589488f4-c4bf-4113-7968-08dcaf947ea8",
     permissionsName: "Get Contact Consent Log",
     description: null,
     componentsId: null,
     components: null
  } 
}
