import { UserOperationTypeEnum } from '../../../../../../Enums';
import { fieldTypesEnum } from '../../../../ActivitiesTypeView/ActivityTypeUpdateBulkView/ActivitesTypesFieldsData';

export const fields = [
  {
    fieldKey: 'branchId',
    fieldType: fieldTypesEnum.search,
    fieldTitle: 'branch',
    fieldValues: null,
    isRequired: false,
    searchBy: 'branches',
    multiple: false,
    returnKey: 'id',
  },
  {
    fieldKey: 'departmentId',
    fieldType: fieldTypesEnum.search,
    fieldTitle: 'department',
    fieldValues: null,
    isRequired: false,
    searchBy: 'Departments',
    multiple: false,
    lookupByName: true,
    returnKey: 'id',
  },
  {
    fieldKey: 'JobTitleId',
    fieldType: fieldTypesEnum.search,
    fieldTitle: 'job-title',
    fieldValues: null,
    isRequired: false,
    searchBy: 'Profession',
    multiple: false,
    lookupByName: true,
    returnKey: 'id',
  },
  {
    fieldKey: 'reportsToId',
    fieldType: fieldTypesEnum.search,
    fieldTitle: 'report-to',
    fieldValues: null,
    isRequired: false,
    searchBy: 'users',
    multiple: false,
    returnKey: 'userId',
  },
  {
    fieldKey: 'stationId',
    fieldType: fieldTypesEnum.search,
    fieldTitle: 'station',
    fieldValues: null,
    isRequired: false,
    searchBy: 'Stations',
    multiple: false,
    lookupByName: true,
    returnKey: 'id',
  },
  {
    fieldKey: 'country',
    fieldType: fieldTypesEnum.search,
    fieldTitle: 'country',
    fieldValues: null,
    isRequired: false,
    searchBy: 'Country',
    multiple: true,
    lookupByName: true,
  },
  {
    fieldKey: 'city',
    fieldType: fieldTypesEnum.search,
    fieldTitle: 'city',
    fieldValues: null,
    isRequired: false,
    searchBy: 'City',
    multiple: true,
    lookupByName: true,
  },
  {
    fieldKey: 'district',
    fieldType: fieldTypesEnum.search,
    fieldTitle: 'district',
    fieldValues: null,
    isRequired: false,
    searchBy: 'District',
    multiple: true,
    lookupByName: true,
  },
  {
    fieldKey: 'community',
    fieldType: fieldTypesEnum.search,
    fieldTitle: 'community',
    fieldValues: null,
    isRequired: false,
    searchBy: 'Community',
    multiple: true,
    lookupByName: true,
  },
  {
    fieldKey: 'subCommunity',
    fieldType: fieldTypesEnum.search,
    fieldTitle: 'sub-community',
    fieldValues: null,
    isRequired: false,
    searchBy: 'SubCommunity',
    multiple: true,
    lookupByName: true,
  },
  {
    fieldKey: 'languages',
    fieldType: fieldTypesEnum.search,
    fieldTitle: 'languages',
    fieldValues: null,
    isRequired: false,
    searchBy: 'language',
    multiple: true,
    lookupByName: true,
  },
  {
    fieldKey: 'dateOfJoining',
    fieldType: fieldTypesEnum.date,
    fieldTitle: 'date-of-join',
    fieldValues: null,
    isRequired: false,
    returnKey: '_d',
  },
  {
    fieldKey: 'drivingLicenseAvailability',
    fieldType: 'select',
    fieldTitle: 'driving-license-availability',
    fieldValues: null,
    isRequired: false,
    enum: [
      { id: true, name: 'Yes' },
      { id: false, name: 'No' },
    ],
    returnKey: 'id',
  },
  {
    fieldKey: 'carAvailability',
    fieldType: 'select',
    fieldTitle: 'car-availability',
    fieldValues: null,
    isRequired: false,
    enum: [
      { id: true, name: 'Yes' },
      { id: false, name: 'No' },
    ],
    returnKey: 'id',
  },
  {
    fieldKey: 'userOperationType',
    fieldType: 'select',
    fieldTitle: 'operation-type',
    fieldValues: null,
    isRequired: false,
    enum: Object.values(UserOperationTypeEnum).map((item) => ({
      ...item,
      name: item.title,
    })),
    returnKey: 'id',
  },
  {
    fieldKey: 'unitSaleTypeId',
    fieldType: fieldTypesEnum.search,
    fieldTitle: 'unit-sale-type',
    fieldValues: null,
    isRequired: false,
    searchBy: 'UnitSaleType',
    lookupByName: true,
    returnKey: 'id',
  },
  {
    fieldKey: 'developers',
    fieldType: fieldTypesEnum.search,
    fieldTitle: 'developers',
    fieldValues: null,
    isRequired: false,
    searchBy: 'developers',
    multiple: true,
  },
  {
    fieldKey: 'roles',
    fieldType: fieldTypesEnum.search,
    fieldTitle: 'roles',
    fieldValues: null,
    isRequired: false,
    searchBy: 'roles',
    multiple: true,
  },
  {
    fieldKey: 'propertyId',
    fieldType: fieldTypesEnum.search,
    fieldTitle: 'properties',
    fieldValues: null,
    isRequired: false,
    searchBy: 'properties',
    multiple: false,
    returnKey: 'id',
  },
  {
    fieldKey: 'policyId',
    fieldType: fieldTypesEnum.search,
    fieldTitle: 'policy',
    fieldValues: null,
    isRequired: false,
    searchBy: 'policies',
    multiple: false,
    returnKey: 'id',
  },
  {
    fieldKey: 'targetMin',
    fieldType: fieldTypesEnum.number,
    fieldTitle: 'minimum-target',
    fieldValues: null,
    isRequired: false,
  },
  {
    fieldKey: 'targetMax',
    fieldType: fieldTypesEnum.number,
    fieldTitle: 'maximum-target',
    fieldValues: null,
    isRequired: false,
  },
];
