import React, { useEffect, useState } from 'react';
import GenricStpeper from '../../../../Components/OLD/dfmAddEditAndDelete/typePicker/DfmAddEditAndDeleteStepper';
import {  PROPERTIES , DEVELOPERS   } from '../../../../config/pagesName';
import { GetParams } from '../../../../Helper' ; 

function DevelopersManagementView() {
  const [activeFormType, setActiveFormType] = useState(null);
  const [id, setId] = useState(null);

  useEffect(() => {
    setActiveFormType(GetParams('formType'));
    setId(GetParams('id'));
    return () => {};
  }, []);

   return (
    <div className='view-wrapper pt-3'>
      <div className='d-flex-column'>
      {!id && <GenricStpeper pageName={DEVELOPERS} withTotal isDialog={false} type={1} />}
        
      </div>
    </div>
  );
}

export { DevelopersManagementView };
