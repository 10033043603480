export const ListingShortagePermissions = {
  AddNewInquiry: {
    permissionsId: "6acb0449-9828-45a0-a5da-08db04f6cbfc",
    permissionsName: "Add New Inquiry",
    componentsId: null,
    components: null,
  },
  EditInquiry: {
    permissionsId: "9486fff2-4e51-414a-a5db-08db04f6cbfc",
    permissionsName: "Edit Inquiry",
    componentsId: null,
    components: null,
  },
  DeleteInquiry: {
    permissionsId: "8a63caf6-6091-4090-a5dc-08db04f6cbfc",
    permissionsName: "Delete Inquiry",
    componentsId: null,
    components: null,
  },
  ViewInquiry: {
    permissionsId: "2c59e11c-9b92-4257-a5dd-08db04f6cbfc",
    permissionsName: "View Inquiry",
    componentsId: null,
    components: null,
  },
  CancelInquiry: {
    permissionsId: "3b5b36f9-537c-439e-a5e1-08db04f6cbfc",
    permissionsName: "Cancel Inquiry",
    componentsId: null,
    components: null,
  },
  AssignInquiry: {
    permissionsId: "98b1c847-6467-4e37-cb70-08db7d42a751",
    permissionsName: "Assign Inquiry",
    componentsId: null,
    components: null,
  },
  ViewInquiryAssignmentTracker: {
    permissionsId: "d8c2af95-8955-46bf-794d-08dcaf947ea8",
    permissionsName: "View Inquiry Assignment Tracker",
    description: null,
    componentsId: null,
    components: null,
  },
};
