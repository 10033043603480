import React from 'react';
import { Spinner } from '../../../../Components';
import { ButtonBase } from '@material-ui/core';
import { RemoveRedEyeOutlined } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';

export const BranchUserDetails = ({
  isLoading,
  userDetails,
  onUserTypeClick,
  parentTranslationPath,
}) => {
  const { t } = useTranslation(parentTranslationPath);
  if (isLoading) {
    return <Spinner isActive={isLoading} isAbsolute />;
  }
  return (
    <ul>
      <li className='d-flex gap-3'>
        <ButtonBase title='view' onClick={() => onUserTypeClick('isCRM')}>
          <RemoveRedEyeOutlined className='mx-1' color='primary' />
          {/* View */}
        </ButtonBase>
        <h5 className='mx-2'>
          <strong>CRM</strong> -{' '}
          {`${userDetails?.numberOfActiveUserForCRM} ${t('user')}`}
        </h5>
      </li>
      <li className='d-flex gap-3'>
        <ButtonBase title='view' onClick={() => onUserTypeClick()}>
          <RemoveRedEyeOutlined className='mx-1' color='primary' />
          {/* View */}
        </ButtonBase>
        <h5 className='mx-2'>
          <strong>MyLead</strong> -{' '}
          {`${userDetails?.numberOfActiveUserForMyLead} ${t('user')}`}
        </h5>
      </li>
    </ul>
  );
};
