export const PaymentPlanStatus = {
    Active: {
      key: 1,
      value: 'Active',
      showInSelect: true,
    },
    InActive: {
      key: 2,
      value: 'InActive',
      showInSelect: true,
    },
  };  
