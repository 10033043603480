import React from 'react';
import { useTranslation } from 'react-i18next';

export const TeamManager = ({ teamsRelatedToManager, translationPath, parentTranslationPath }) => {
    const { t } = useTranslation([parentTranslationPath]);
    return (
        <div className='teams-part'>
            {teamsRelatedToManager.map((item) => (
                <div key={`${item.teamsId}`} className='user-management-team'>
                    <div>
                        <div className='firstPart'>
                            <span className='headerName'>
                                {t(`${translationPath}business-Group`)}
                                <span>
                                </span>
                            </span>
                            <span className='secondPart'>
                                &nbsp;
                                {item.businessGroups.businessGroupsName}
                            </span>

                        </div>
                        <div className='firstPart'>
                            <span className='headerName'>
                                {t(`${translationPath}teamName`)}
                                <span>
                                </span>

                            </span>
                            <span className='secondPart'>
                                &nbsp;
                                {item.teamsName}
                            </span>
                        </div>
                        {/* <span className='i-managerCrown manager-Crown' /> */}
                    </div>
                </div>
            ))}

        </div>

    );
};
