import React from "react";
import moment from "moment";
import { CopyToClipboardComponents } from "../../../../ReusableComponents/UtilityComponents";

export const OpportunitiesLogTableHeaderData = [
  {
    id: 1,
    label: "new-lead-name",
    input: "newLeadName",
    isHiddenFilter: true,
    isSortable: false,
  },
  {
    id: 2,
    label: "previous-lead-name",
    input: "oldLeadName",
    isHiddenFilter: true,
    isSortable: false,
  },
  {
    id: 3,
    label: "lead-id",
    input: "leadId",
    isHiddenFilter: true,
    isSortable: false,
    component: (item) => 
      <CopyToClipboardComponents
        data={item.leadId}
        childrenData={item.leadId}
      />
  },
  {
    id: 4,
    label: "previous-stage",
    input: "oldStageName",
    isHiddenFilter: true,
    isSortable: false,
  },
  {
    id: 5,
    label: "current-stage",
    input: "newStageName",
    isHiddenFilter: true,
    isSortable: false,
  },
  {
    id: 6,
    label: "changed-by",
    input: "createdBy",
    isHiddenFilter: true,
    isSortable: false,
  },
  {
    id: 7,
    label: "changed-at",
    isHiddenFilter: true,
    isSortable: false,
    component: (item) => moment(item.createdOn).format("DD/MM/YYYY - hh:mm A"),
  },
  {
    id: 8,
    label: "new-client-name",
    isHiddenFilter: true,
    isSortable: false,
    component: (item) =>
      `${item.newClientData.first_name} ${item.newClientData.last_name}`,
  },
  {
    id: 9,
    label: "old-client-name",
    isHiddenFilter: true,
    isSortable: false,
    component: (item) =>
      `${item.oldClientData.first_name} ${item.oldClientData.last_name}`,
  },
  {
    id: 10,
    label: "new-client-email",
    isHiddenFilter: true,
    isSortable: false,
    component: (item) => item?.newClientData?.email_address ?? "N/A",
  },
  {
    id: 11,
    label: "old-client-email",
    isHiddenFilter: true,
    isSortable: false,
    component: (item) => item?.oldClientData?.email_address ?? "N/A",
  },
  {
    id: 12,
    label: "new-client-mobile-number",
    isHiddenFilter: true,
    isSortable: false,
    component: (item) => item?.newClientData?.mobile_number ?? "N/A",
  },
  {
    id: 13,
    label: "old-client-mobile-number",
    isHiddenFilter: true,
    isSortable: false,
    component: (item) => item?.oldClientData?.mobile_number ?? "N/A",
  },
  {
    id: 14,
    label: "new-client-nationality",
    isHiddenFilter: true,
    isSortable: false,
    component: (item) =>
      item?.newClientData?.nationality?.lookupItemName ?? "N/A",
  },
  {
    id: 15,
    label: "old-client-nationality",
    isHiddenFilter: true,
    isSortable: false,
    component: (item) =>
      item?.oldClientData?.nationality?.lookupItemName ?? "N/A",
  },
  {
    id: 16,
    label: "old-client-id-card-number",
    isHiddenFilter: true,
    isSortable: false,
    component: (item) => item?.newClientData?.id_card_no ?? "N/A",
  },
  {
    id: 17,
    label: "new-client-id-card-number",
    isHiddenFilter: true,
    isSortable: false,
    component: (item) => item?.oldClientData?.id_card_no ?? "N/A",
  },
  {
    id: 18,
    label: "old-client-passport-number",
    isHiddenFilter: true,
    isSortable: false,
    component: (item) => item?.oldClientData?.passport_no ?? "N/A",
  },
  {
    id: 19,
    label: "new-client-passport-number",
    isHiddenFilter: true,
    isSortable: false,
    component: (item) => item?.oldClientData?.passport_no ?? "N/A",
  },
  {
    id: 20,
    label: "old-client-visa-type",
    isHiddenFilter: true,
    isSortable: false,
    component: (item) =>
      item?.oldClientData?.visa_type?.lookupItemName ?? "N/A",
  },
  {
    id: 21,
    label: "new-client-visa-type",
    isHiddenFilter: true,
    isSortable: false,
    component: (item) =>
      item?.newClientData?.visa_type?.lookupItemName ?? "N/A",
  },
];
