import { ContactsMergeStatus } from './Status';

const initialState = localStorage.getItem('ContactsMergeInfo') ?
  JSON.parse(localStorage.getItem('ContactsMergeInfo')) :
  {};

export const ContactsMergeReducer = (state = initialState, action) => {
  switch (action.type) {
    case ContactsMergeStatus.ERROR:
    case ContactsMergeStatus.REQUEST:
      return state;

    case ContactsMergeStatus.SUCCESS:
      return action.payload;

    case ContactsMergeStatus.RESET:
      return initialState;

    default:
      return state;
  }
};
