import React, { useEffect, useState } from 'react';
import {
  GetLookupItemsByLookupTypeName,
  lookupItemsGetId,
} from '../../../Services';
import { AutocompleteComponent } from '../AutocompleteComponent/AutocompleteComponent';

function LookupAutocomplete({
  lookupTypeId,
  lookupParentId,
  parentTranslationPath,
  translationPath,
  selectedValues,
  multiple = false,
  required,
  onChange,
  wrapperClasses,
  label,
  disabled,
  className = '',
  lookupName,
  filterSelectedOptions,
}) {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const getLookupData = async () => {
      setIsLoading(true);
      let res;
      if (lookupTypeId) {
        res = await lookupItemsGetId({ lookupTypeId, lookupParentId });
      }
      if (lookupName) {
        const result = await GetLookupItemsByLookupTypeName({
          lookUpName: lookupName,
          pageIndex: 1,
          pageSize: 50,
        });
        res = result.result;
      }
      setData(res);
      setIsLoading(false);
    };
    if (!disabled) {
      getLookupData();
    }
  }, [lookupTypeId, lookupParentId]);
  if (lookupName && lookupTypeId) {
    return (
      <h6 className='text-danger'>
        You can't provide both lookupName and lookupTypeId at the same time
      </h6>
    );
  }
  return (
    <div className={className}>
      <AutocompleteComponent
        data={data}
        filterSelectedOptions={filterSelectedOptions}
        filterOptions={(options) => {
          if (filterSelectedOptions) {
            const hasValue = (id) =>
              selectedValues.findIndex((w) => w.lookupItemId === id) === -1;
            return options.filter((w) => hasValue(w.lookupItemId));
          } else {
            return options;
          }
        }}
        selectedValues={selectedValues}
        labelClasses={required ? 'Requierd-Color' : ''}
        labelValue={label}
        multiple={multiple}
        parentTranslationPath={parentTranslationPath}
        translationPath={translationPath}
        displayLabel={(option) => option?.lookupItemName ?? ''}
        renderOption={(option) => option?.lookupItemName ?? ''}
        chipsLabel={(option) => option?.lookupItemName ?? ''}
        getOptionSelected={(option) => {
          if (multiple) {
            return !!selectedValues.find(
              (item) => option.lookupItemId === item.lookupItemId
            );
          } else {
            return selectedValues.lookupItemId === option.lookupItemId;
          }
        }}
        isLoading={isLoading}
        onChange={onChange}
        wrapperClasses={wrapperClasses}
        withoutSearchButton
        isDisabled={disabled}
      />
    </div>
  );
}

export default LookupAutocomplete;
