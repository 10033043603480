import React from 'react';
import { DialogComponent } from '../../../../../Components';
import { showError, showSuccess } from '../../../../../Helper';
import { RejectUserActivation } from '../../../../../Services';
import { useTranslation } from 'react-i18next';

export const UserActivationRejectDialog = ({
  onCancelClicked,
  onCloseClicked,
  reloadData,
  isDialogOpen,
  setIsDialogOpen,
  translationPath,
  parentTranslationPath,
  activeItem,
}) => {
  const { t } = useTranslation(parentTranslationPath);

  const deleteHandler = async () => {
    const userId = activeItem?.id || null;
    const res = await RejectUserActivation(userId);
    if (!(res && res.status && res.status !== 200)) {
      reloadData();
      showSuccess(t(`${translationPath}activate-rejection-success`));
      setIsDialogOpen(false);
    } else showError(t(`${translationPath}activate-rejection-failed`));
  };

  return (
    <DialogComponent
      titleText='activation-confirm-message'
      saveText='confirm'
      saveType='button'
      maxWidth='sm'
      dialogContent={
        <div className='d-flex-column-center'>
          <span className='mdi mdi-close-octagon c-danger mdi-48px' />
          <span>{`${t(`${translationPath}user-activation-reject-description`)} "${activeItem?.name}"?`}</span>
        </div>
      }
      saveClasses='btns theme-solid bg-danger w-100 mx-2 mb-2'
      isOpen={isDialogOpen}
      onCancelClicked={onCancelClicked}
      onCloseClicked={onCloseClicked}
      onSaveClicked={deleteHandler}
      parentTranslationPath={parentTranslationPath}
      translationPath={translationPath}
    />
  );
};
