import { config } from '../../config';
import { HttpServices } from '../../Helper';


export const CreateTaskConfig = async (body) => {

  const result = await HttpServices.post(
    `${config.server_address}/CrmDfm/TaskConfiguration/CreateTaskConfig`, body )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};


export const UpdateTaskConfig = async (id, body) => {

  const result = await HttpServices.post(
    `${config.server_address}/CrmDfm/TaskConfiguration/UpdateTaskConfig/${id}`, body )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};


export const GetTaskConfigById = async (id) => {

  const result = await HttpServices.get(
    `${config.server_address}/CrmDfm/TaskConfiguration/GetTaskConfigById/${id}`)
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

export const GetProjectTaskById = async (id) => {

  const result = await HttpServices.get(
    `${config.server_address}/CrmDfm/ProjectTasks/GetProjectTaskById/${id}`)
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};


export const GetTaskConfigurationsAsAnOptions = async ({pageIndex, pageSize, search, taskCategoryId}) => {

  const queryList = [];

  if (pageSize) queryList.push(`pageSize=${pageSize}`);
  if (pageIndex) queryList.push(`pageIndex=${pageIndex}`);
  if (search) queryList.push(`search=${search}`);
  if (taskCategoryId) queryList.push(`taskCategoryId=${taskCategoryId}`);
  
  const result = await HttpServices.get(
    `${config.server_address}/CrmDfm/TaskConfiguration/GetTaskConfigurationsAsAnOptions?${queryList.join('&')}`)
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

export const GetTaskConfigs = async (body) => {

  const result = await HttpServices.post(
    `${config.server_address}/CrmDfm/TaskConfiguration/GetTaskConfigs`, body)
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

export const SetTaskConfigAsActiveOrInActive = async (id, isActive) => {

  const result = await HttpServices.put(
    `${config.server_address}/CrmDfm/TaskConfiguration/SetTaskConfigAsActiveOrInActive/${id}?isActive=${isActive}`)
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};


export const DeleteTaskConfiguration = async (id) => {

  const result = await HttpServices.delete(
    `${config.server_address}/CrmDfm/TaskConfiguration/DeleteTaskConfiguration/${id}`)
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};


