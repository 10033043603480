import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => {
  return {
    contactItemListWrapper: {
      display: "flex",
      gap: "12px",
    },
    labelContainer: {
      display: "flex",
      gap: "8px",
    },
    label: {
      color: theme.palette.text.secondary,
      fontSize: "16px",
      lineHeight: "24px",
      fontWeight: 500,
    },
    valuesContainer: {
      display: "flex",
      flexDirection: "column",
      gap: "10px",
    },
    value: {
      color: theme.palette.text.secondary,
      fontSize: "16px",
      lineHeight: "24px",
      fontWeight: 600,
    }
  }
});
