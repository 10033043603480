import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import './TaskDetailsSideMenu.scss';
import '../RequestSideDetails/RequestSideDetails.scss';
import {
  getDownloadableLink,
  getFirstLastNameLetters,
  showError,
  GlobalHistory
} from '../../../../../Helper';
import {
  GetTaskDetails,
  GetTaskHistory,
  SetTaskStatusAsInprogress,
  SetCampaignTaskStatusAsReview,
  ApproveACampaignTask,
} from '../../../../../Services';
import { ButtonBase, Tabs, Tab, makeStyles, Avatar } from '@material-ui/core';
import {CampaignTaskRejectDialog} from './CampaignTaskRejectDialog'

export const TaskDetailsSideMenu = ({ closeSideMenu, taskId, campaignId, 
  buttonsGroup, reloadData }) => {
  const parentTranslationPath = 'Campaign';
  const translationPath = 'campaign-task.';
  const { t } = useTranslation(parentTranslationPath);
  const pathName = window.location.pathname;

  const sideMenuButtons = {
    KanbanView: {
      Complete: {
        key: 'Complete',
        buttonClasses: 'marketing-buttons outlined',
        isDisabled: false,
      },
      Start: {
        key: 'Start',
        buttonClasses: 'marketing-buttons outlined',
        isDisabled: false,
      },
      View: {
        key: 'View',
        buttonClasses: 'marketing-buttons solid',
        isDisabled: false,
      },
    },
    TasksView: {
      Reject: {
        key: 'Reject',
        buttonClasses: 'marketing-buttons outlined',
        isDisabled: false,
      },
      Approve: {
        key: 'Approve',
        buttonClasses: 'marketing-buttons solid',
        isDisabled: false,
      },
    },
  };

  const useStyles = makeStyles((theme) => ({
    tabsRoot: {
      height: '40px',
      minHeight: '40px',
      textTransform: 'capitalize',
    },
    indicator: {
      backgroundColor: '#498a8b',
    },
    selectedTab: {
      color: '#498a8b !important',
      fontWeight: 600,
    },
    tabRoot: {
      minWidth: 'fit-content',
      padding: 0,
      marginRight: 16,
      fontSize: '12px',
      textTransform: 'capitalize',
    },
  }));

  const classes = useStyles();

  const [allowedActions, setAllowedActions] = useState([]);
  const [tabIndex, setTabIndex] = useState(0);
  const [taskData, setTaskData] = useState({
    details: null,
    history: null,
  });

  const [openRejectDialog, setOpenRejectDialog] = useState(false);

  const getTaskDetails = async () => {
    const res = await GetTaskDetails({
      taskId,
    });
    if (!(res && res.status && res.status !== 200)) {
      setTaskData((prevState) => ({
        ...(prevState || {}),
        details: res,
      }));
      getAllowedActions(res);
    }
  };

  const getTaskHistory = async () => {
    const res = await GetTaskHistory({
      taskId,
    });
    if (!(res && res.status && res.status !== 200)) {
      setTaskData((prevState) => ({
        ...(prevState || {}),
        history: res?.history,
      }));
    }
  };

  const setTaskStatusAsInprogress = async () => {

    const res = await SetTaskStatusAsInprogress(taskId);
    if (!(res && res.status && res.status !== 200)) {
      reloadData();
      getTaskDetails();
    } else {
      showError(t(`${translationPath}proccess-failed`));
    }

  };

  const setCampaignTaskStatusAsReview = async () => {

    const res = await SetCampaignTaskStatusAsReview(taskId);
    if (!(res && res.status && res.status !== 200)) {
      reloadData();
      getTaskDetails();
    } else {
      showError(t(`${translationPath}proccess-failed`));
    }

  };

  const approveACampaignTask = async () => {

    const res = await ApproveACampaignTask(taskId);
    if (!(res && res.status && res.status !== 200)) {
      if(res !== 0) {
        if(pathName?.includes('campaign-requests'))
        GlobalHistory.push(`/home/campaign-requests/view`);
      }
      reloadData();
      getTaskDetails();
    } else {
      showError(t(`${translationPath}proccess-failed`));
    }

  };


  const handleTabClick = (event, newValue) => {
    setTabIndex(newValue);
  };

  const getAllowedActions = (data) => {
    let allowedActionsLocal = { ...sideMenuButtons[buttonsGroup] };

    if (buttonsGroup === 'KanbanView') {
      if (data?.showCompleteButton === false) allowedActionsLocal['Complete'].isDisabled = true;
      if (data?.showStartButton === false) allowedActionsLocal['Start'].isDisabled = true;
    }

    if (buttonsGroup === 'TasksView') {
      if (data?.showApproveButton === false) allowedActionsLocal['Approve'].isDisabled = true;
      if (data?.showRejectButton === false) allowedActionsLocal['Reject'].isDisabled = true;
    }
    setAllowedActions(Object.values(allowedActionsLocal));
  };

  const actionButtonClickHandler = (actionKey) => {
    const viewHandler = () => GlobalHistory.push(`/home/kenban-board/task-details?id=${taskId}&campaignId=${campaignId}`);
    const startHandler = () => setTaskStatusAsInprogress();
    const completeHandler = () => setCampaignTaskStatusAsReview();
    const approveHandler = () => approveACampaignTask();
    const rejectHandler = () => setOpenRejectDialog(true);

    if (actionKey === sideMenuButtons[buttonsGroup]?.View?.key) viewHandler();
    if (actionKey === sideMenuButtons[buttonsGroup]?.Start?.key) startHandler();
    else if (actionKey === sideMenuButtons[buttonsGroup]?.Complete?.key) completeHandler();
    else if (actionKey === sideMenuButtons[buttonsGroup]?.Approve?.key) approveHandler();
    else if (actionKey === sideMenuButtons[buttonsGroup]?.Reject?.key) rejectHandler();
  };

  useEffect(() => {
    if (taskId) {
      getTaskDetails();
      getTaskHistory();
    }
  }, [taskId]);

  return (
    <div className='marketing-module-view TaskDetailsSideMenu'>
      <div className='d-flex-v-center-h-between py-2 px-3'>
        <h1 className='fz-16 m-0 fw-simi-bold'>{t(`${translationPath}task-details`)}</h1>
        <span className='mdi mdi-close pointer' onClick={closeSideMenu}></span>
      </div>
      <div className='relative golden-gradiant'>
        <Avatar
          className='avatars-wrapper user-avatar'
          src={
            taskData?.details?.assignedToProfileImg
              ? getDownloadableLink(taskData?.details?.assignedToProfileImg)
              : ''
          }>
          {getFirstLastNameLetters(taskData?.details?.assignedTo || '')}
        </Avatar>
      </div>
      <div className='mt-4 pt-4 px-3'>
        <p className='fz-20 mb-1 fw-simi-bold text-primary'>{taskData?.details?.assignedTo || ''}</p>
        <p className='fz-12 mb-1 text-tertiary'>{taskData?.details?.assignedToEmail || ''}</p>
        <div className='d-flex mt-2 mb-3'>
          <span
            className='d-flex-center d-flex-default w-fit fz-10 px-2 mr-2
                    bg-gray-light border-gray-secondary radius-full pointer'>
            {taskData?.details?.campaignName || ''}
          </span>
          {taskData?.details?.taskStatus ? (
            <span
              className='d-flex-center d-flex-default w-fit relative fz-10 px-3 mr-2 
                    status-label radius-full pointer'>
              {taskData?.details?.taskStatus}
            </span>
          ) : null}
        </div>
        <div className='d-flex-center'>
          {allowedActions &&
            allowedActions.map((item) => (
              <ButtonBase
                onClick={() => actionButtonClickHandler(item.key)}
                disabled={item.isDisabled}
                className={`${item.buttonClasses} ${item.isDisabled ? 'disabled' : ''}`}>
                <span>{t(`${translationPath}${item.key}`)}</span>
              </ButtonBase>
            ))}
        </div>
      </div>
      <div className='px-3 mx-2'>
        <Tabs
          value={tabIndex}
          onChange={handleTabClick}
          aria-label='icon label tabs'
          textColor='primary'
          classes={{
            root: classes.tabsRoot,
            indicator: classes.indicator,
          }}>
          <Tab
            classes={{
              root: classes.tabRoot,
              selected: classes.selectedTab,
            }}
            label={t(`${translationPath}Tasks`)}
          />
          <Tab
            classes={{
              root: classes.tabRoot,
              selected: classes.selectedTab,
            }}
            label={t(`${translationPath}Actions`)}
          />
        </Tabs>

        {tabIndex === 0 && (
          <div className='py-3 task-details-container'>
            <div className='mb-2'>
              <h5 className='fw-simi-bold m-0 mb-1 fz-16'>{taskData?.details?.title || ''}</h5>
            </div>

            <div className='mb-2'>
              <h5 className='fw-simi-bold m-0 mb-1 fz-12'>{t(`${translationPath}description`)}</h5>
              <p className='word-break'>{taskData?.details?.description || ''}</p>
            </div>
            <div className='mb-2'>
              <h5 className='fw-simi-bold m-0 mb-1 fz-12'>{t(`${translationPath}deadline`)}</h5>
              <p>
                {taskData?.details?.deadline
                  ? moment(taskData?.details?.deadline).format('LL')
                  : ''}
              </p>
            </div>
            <div className='mb-2'>
              <h5 className='fw-simi-bold m-0 mb-1 fz-12'>{t(`${translationPath}reporter`)}</h5>
              <div className='d-flex-v-center'>
                <div className='campaign-team-f1'>
                  <Avatar
                    className='avatars-wrapper team-avatar fz-12 sm-theme'
                    src={
                      taskData?.details?.reporterProfileImg
                        ? getDownloadableLink(taskData?.details?.reporterProfileImg)
                        : ''
                    }>
                    {getFirstLastNameLetters(taskData?.details?.reporter || '')}
                  </Avatar>
                </div>
                <div className='d-flex-column'>
                  <span className='fz-12 fw-simi-bold'>{taskData?.details?.reporter || ''}</span>
                  <span className='fz-10 text-tertiary'>
                    {taskData?.details?.reporterEmail || ''}
                  </span>
                </div>
              </div>
            </div>
          </div>
        )}
        {tabIndex === 1 && (
          <div className='py-3 task-details-container'>
            {taskData?.history &&
              taskData.history.map((item) => (
                <div className='req-history-card'>
                  <div className='campaign-team-f1'>
                    <Avatar
                      className='avatars-wrapper team-avatar fz-12 m-0 sm-theme'
                      src={item?.profileImg ? getDownloadableLink(item?.profileImg) : ''}>
                      {getFirstLastNameLetters(item?.creator || '')}
                    </Avatar>
                  </div>
                  <div className='req-history-content'>
                    <div>
                      <p className='top-label'>
                        <span>{item.creator}</span>
                        <span>
                          {item.createdOn ? `${moment(item.createdOn).fromNow(true)} ago` : ''}
                        </span>
                      </p>
                      <p className='bottom-label'>{item.messageText}</p>
                    </div>
                    {/*<div className='card-capsule'>{item.status}</div>*/}
                  </div>
                </div>
              ))}
          </div>
        )}
        {openRejectDialog &&
        <CampaignTaskRejectDialog
          isOpen={openRejectDialog}
          close={() => {
            setOpenRejectDialog(false);
          }}
          taskId={taskId}
          reloadData={reloadData}
        />
      }
      </div>
    </div>
  );
};
