import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => {
  return {
    root: {
      display: "flex",
      justifyContent: "space-between",
      gap: "24px",
      flexDirection: "row",
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
        alignItems: "center",
      },
      [theme.breakpoints.up('md')]: {
        [theme.breakpoints.down('lg')]: {
          display: 'grid',
          gridTemplateColumns: 'repeat(3, 1fr)',
        },
      },
    },
  };
});