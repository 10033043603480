import { ButtonBase } from '@material-ui/core';
import Joi from 'joi';
import React, {
  useEffect,
  useReducer,
  useState,
  useCallback,
  Children,
  useMemo,
  act,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import moment from 'moment';
import {
  CheckboxesComponent,
  Spinner,
  StepperComponent,
} from '../../../../Components';
import {
  UnitsOperationTypeEnum,
  UnitsStatusEnum,
  AgentTypesEnum,
} from '../../../../Enums';
import {
  bottomBoxComponentUpdate,
  getErrorByName,
  GetParams,
  GlobalHistory,
  showError,
  showSuccess,
  returnPropsByPermissions,
} from '../../../../Helper';
import { UnitsSalesPermissions } from '../../../../Permissions';
import {
  SetUnitAsReserveOrSale,
  SetUnitAsReserveOrLease,
  unitDetailsGet,
  GetReservedUnitTransactionDataForSaleByUnitId,
  GetReservedUnitTransactionDataForLeaseByUnitId,
  GetUnitSaleDetails,
  GetReservedTransactionStepsInfo,
} from '../../../../Services';
import { UnitMapper } from '../UnitSalesMapper';
import { UnitsSaleRelatedSteps, UnitsRentRelatedSteps } from './StepsData';

const parentTranslationPath = 'UnitsStatusManagementView';
const translationPath = '';

export const UnitsSalesStatusManagementView = () => {
  const { t } = useTranslation([parentTranslationPath, 'Shared']);
  const isForDeveloper = GetParams('isForDeveloper');
  const [unitParams, setUnitParams] = useState(null);
  const [activeStep, setActiveStep] = useState(0);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [unitData, setUnitData] = useState(null);
  const [cantEditReservedSaleTransaction, setCantEditReservedSaleTransaction] =
    useState(null);
  const [unitSaleDetails, setUnitSaleDetails] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [unitTransactionId, setUnitTransactionId] = useState(null);
  const [stages, setStages] = useState([]);
  const [visibleList, setVisibleList] = useState('');
  const [
    reciveNextButtonStatusMoveStepper,
    setReciveNextButtonStatusMoveStepper,
  ] = useState(false);
  const EnableNextButtonTotalOwnership = (value) => {
    setReciveNextButtonStatusMoveStepper(value);
  };
  const loginResponse = useSelector(
    (state) => state.login && state.login.loginResponse
  );
  const reducer = useCallback((state, action) => {
    if (action.id !== 'edit') return { ...state, [action.id]: action.value };
    return {
      ...action.value,
    };
  }, []);
  const [selected, setSelected] = useReducer(reducer, {
    agents: null,
    listingAgents: null,
    buyerAgent: null,
    otherBuyerAgents: null,
    sellerAgent: null,
    otherSellerAgents: null,
    withListingAgents: false,
    referral: null,
    sellerReferral: null,
    reservationType: null,
    transactedBy: null,
    externalAgency: null,
    rentFreePeriod: null,
    tableContacts: [],
    invoices: [],
    user: null,
    leasingType: null,
    periodOfStay: null,
  });
  const [state, setState] = useReducer(reducer, {});
  const activeStageIndex = useMemo(
    () => stages.findIndex((stage) => stage.isContainMissingItems),
    [stages]
  );
  const getUnitById = useCallback(async () => {
    setIsLoading(true);
    const res = await unitDetailsGet({ id: unitParams.id });
    if (!(res && res.status && res.status !== 200)) {
      setUnitData(UnitMapper(res));
      setUnitTransactionId(res.unitTransactionId);
    } else setUnitData(null);
    setIsLoading(false);
  }, [unitParams]);

  const getUnitSaleDetails = useCallback(async () => {
    if (unitParams && unitParams.id) {
      const res = await GetUnitSaleDetails(unitParams.id);
      if (!(res && res.status && res.status !== 200)) setUnitSaleDetails(res);
      else setUnitSaleDetails(null);
    }
  }, [unitParams]);

  const getSaleRelatedStatusesInit = useCallback(
    () => ({
      agents: null,
      listingAgents: null,
      buyerAgent: null,
      contractRatified: false,
      titleDeedTransferred: false,
      deedTransferDate: null,
      referralId: null,
      contractRatifiedDate: null,
      mortgage: false,
      referralPercentage: 0,
      contractRefNo: null,
      financeComp: null,
      transactionEntryDate: moment().format('YYYY-MM-DDTHH:mm:ss'),
      reservationTypeId: null,
      paymentToExternalAgency: false,
      externalAgencyId: null,
      transactedById: null,
      observations: null,
      closingDate: null,
      externalAgencyCommission: 0,
      commission: 0,
      // start contact
      contacts: [],
      // start details
      basePrice: 0,
      premium: 0,
      // Per is a sort for percentage & need to manual calculate on init
      premiumPercentageOfBasePrice: 0,
      agencyFeeSeller: 0,
      agencyFeeSellerPercentageOfBasePriceAndPremium: 0,
      discount: 0,
      discountPercentageOfBasePrice: 0,
      sellingPrice: 0,
      admRegistrationFees: 0,
      admRegistrationFeesPercentageOfSellingPrice: 0,
      agencyFeeBuyer: 0,
      agencyFeeBuyerPercentageOfSellingPrice: 0,
      transferFeeBuyer: 0,
      transferFeeBuyerPercentageOfBasePrice: 0,
      transferFeeSeller: 0,
      transferFeeSellerPercentageOfSellingPrice: 0,
      transferFeeCompletedAndPaidProp: 0,
      transferFeeCompletedAndPaidPropOfSellingPrice: 0,
      sellerHasPaid: 0,
      sellerHasPaidPercentageOfBasePrice: 0,
      amountDueToDeveloper: 0,
      downPayment: 0,
      downPaymentPercentageOfSellingPrice: 0,
      loanTermByYears: 0,
      interestRate: 0,
      mortgagePerMonth: 0,
      downPaymentForPlanOne: 0,
      downPaymentForPlanOnePercentageOfSellingPrice: 0,
      monthlyInstallment: 0,
      monthlyInstallmentPercentageOfSellingPrice: 0,
      handoverPaymentForPlanOne: 0,
      handoverPaymentForPlanOnePercentageOfSellingPrice: 0,
      downPaymentForPlanTwo: 0,
      downPaymentForPlanTwoPercentageOfSellingPrice: 0,
      handoverPaymentForPlanTwo: 0,
      handoverPaymentForPlanTwoPercentageOfSellingPrice: 0,
      isPriceOnApplication: true,
      isNegotiable: true,
      sellingPricePerSQFT: 0,
      originalPricePerSQFT: 0,
      // Invoices
      invoicesIds: [],
      // Summery
      note: null,
    }),
    [loginResponse]
  );
  const getRentRelatedStatusesInit = useCallback(
    () => ({
      agents: null,
      buyerAgent: null,
      listingAgents: null,
      contractSigned: false,
      premises: null,
      referralId: null,
      contractDate: null,
      referralPercentage: 0,
      startDate: null,
      endDate: null,
      contractRefNo: null,
      reservationTypeId: null,
      rentFreePeriodId: null,
      transactionEntryDate: null,
      observations: null,
      occupantsAdults: 0,
      occupantsChildren: 0,
      transactedById: null,
      commission: 0,
      // start contact
      contacts: [],
      // start details
      rentPerYear: '',
      contractRent: 0,
      securityDeposit: 0,
      renewalFee: 0,
      agencyFee: 0,
      tenancyContractIssued: false,
      contractIssuedDate: null,
      contractIssuedById: null,
      receiptNo: null,
      paymentToExternalAgency: false,
      externalAgencyId: null,
      leasingTypeId: null,
      periodOfStayId: null,
      contractRenewable: false,
      printContractOn: false,
      // Invoices
      invoicesIds: [],
      // Summery
      note: null,
    }),
    [loginResponse]
  );
  const getReservedUnitTransactionDataForSaleByUnitId = async () => {
    setIsLoading(true);
    const res = await GetReservedUnitTransactionDataForSaleByUnitId(
      unitParams.id
    );
    localStorage.setItem(
      'saleTransactionDetails',
      JSON.stringify(res?.saleTransactionDetails) || null
    );
    if (res.unitId && unitSaleDetails) {
      const buyerAgent =
        res?.saleTransactionDetails?.agents &&
        res.saleTransactionDetails.agents.length > 0 &&
        res.saleTransactionDetails.agents[0];
      setState({
        id: 'edit',
        value: {
          ...state,
          ...res?.saleTransactionDetails,
          ...unitSaleDetails,
          isPriceOnApplication: unitSaleDetails.priceOnApplication,
          isNegotiable: unitSaleDetails.negotiable,
          sellingPricePerSQFT: unitSaleDetails.sellingPricePerSquareFeet,
          originalPricePerSQFT: unitSaleDetails.originalPricePerSquareFeet,
          buyerAgent,
          note: res.note,
          invoicesIds: res.invoicesIds,
          contacts: res.unitTransactionContacts,
          unitTransactionId: res.unitTransactionId,
        },
      });
      setUnitTransactionId(res.unitTransactionId);
    } else handleSellerListingAgentInit();
    setIsLoading(false);
  };
  const getReservedUnitTransactionDataForLeaseByUnitId =
    useCallback(async () => {
      setIsLoading(true);
      const res = await GetReservedUnitTransactionDataForLeaseByUnitId(
        unitParams.id
      );
      if (res.unitId && !state.listingAgents) {
        setState({
          id: 'edit',
          value: {
            ...res?.leaseTransactionDetails,
            note: res.note,
            invoicesIds: res.invoicesIds,
            contacts: res.unitTransactionContacts,
          },
        });

        setUnitTransactionId(res.unitTransactionId);
      }
      setIsLoading(false);
    }, [unitParams]);

  const handleSellerListingAgentInit = () => {
    const stateSellerAgent = unitData?.listing_agent && {
      userId: unitData.listing_agent.id,
      listingAgentName: unitData.listing_agent.name,
      isListingAgent: true,
      listingAgentType: AgentTypesEnum.Seller.value,
      listingAgentCommission: 0,
    };

    const selectedListingAgent = unitData?.listing_agent && {
      id: unitData.listing_agent.id,
      fullName: unitData.listing_agent.name,
      userName: unitData.listing_agent.userName,
      isListingAgent: true,
      listingAgentType: AgentTypesEnum.Seller.value,
      listingAgentCommission: 0,
    };

    if (stateSellerAgent)
      setState({ id: 'listingAgents', value: [stateSellerAgent] });
    if (selectedListingAgent) {
      setSelected({ id: 'listingAgents', value: [selectedListingAgent] });
      setSelected({ id: 'sellerAgent', value: selectedListingAgent });
      setSelected({ id: 'withListingAgents', value: true });
    }
  };
  const getIsSaleRelated = useCallback(() => {
    if (
      unitParams &&
      unitParams.status &&
      Object.values(UnitsStatusEnum).findIndex(
        (item) =>
          item.key === unitParams.status &&
          (!item.effectedOperationType ||
            item.effectedOperationType === UnitsOperationTypeEnum.sale.key)
      ) !== -1
    )
      return true;
    return false;
  }, [unitParams]);
  const getIsRentRelated = useCallback(() => {
    if (
      unitParams &&
      unitParams.status &&
      Object.values(UnitsStatusEnum).findIndex(
        (item) =>
          item.key === unitParams.status &&
          (!item.effectedOperationType ||
            item.effectedOperationType === UnitsOperationTypeEnum.rent.key)
      ) !== -1
    )
      return true;
    return false;
  }, [unitParams]);
  const getStateInit = useCallback(() => {
    if (getIsSaleRelated()) return getSaleRelatedStatusesInit();
    if (getIsRentRelated()) return getRentRelatedStatusesInit();
    return {};
  }, [
    getIsRentRelated,
    getIsSaleRelated,
    getRentRelatedStatusesInit,
    getSaleRelatedStatusesInit,
  ]);
  const getSaleRelatedSchema = () => ({
    buyerAgent: Joi.object()
      .required()
      .messages({
        'object.required': t(`${translationPath}agent-is-required`),
        'object.empty': t(`${translationPath}agent-is-required`),
        'object.base': t(`${translationPath}agent-is-required`),
      }),
    transactionEntryDate: Joi.any()
      .custom((value, helpers) => {
        if (!value) return helpers.error('state.transactionEntryDate');
        return value;
      })
      .messages({
        'state.transactionEntryDate': t(
          `${translationPath}transaction-entry-date-is-required`
        ),
      }),
    reservationTypeId: Joi.number()
      .required()
      .messages({
        'any.required': t(`${translationPath}reservation-type-is-required`),
        'number.base': t(`${translationPath}reservation-type-is-required`),
      }),
    transactedById: Joi.string()
      .required()
      .messages({
        'any.required': t(`${translationPath}transacted-by-is-required`),
        'string.base': t(`${translationPath}transacted-by-is-required`),
        'string.empty': t(`${translationPath}transacted-by-is-required`),
      }),
    // financeComp: Joi.string()
    //   .required()
    //   .messages({
    //     'any.required': t(`${translationPath}finance-comp-is-required`),
    //     'string.base': t(`${translationPath}finance-comp-is-required`),
    //     'string.empty': t(`${translationPath}finance-comp-is-required`),
    //   }),
    basePrice: Joi.number()
      .required()
      .greater(0)
      .messages({
        'any.required': t(`${translationPath}base-price-is-required`),
        'number.base': t(`${translationPath}base-price-is-required`),
      }),
    contacts: Joi.array()
      .min(1)
      .required()
      .messages({
        'array.min': t(`${translationPath}please-select-at-least-one-contact`),
      }),
  });
  const getRentRelatedSchema = () => ({
    contractIssuedById: Joi.string()
      .required()
      .messages({
        'any.required': t(`${translationPath}contract-issued-by-is-required`),
        'string.base': t(`${translationPath}contract-issued-by-is-required`),
        'string.empty': t(`${translationPath}contract-issued-by-is-required`),
      }),
    contractDate: Joi.any()
      .custom((value, helpers) => {
        if (!value && state.contractSigned)
          return helpers.error('state.dateRequired');
        return value;
      })
      .messages({
        'state.dateRequired': t(`${translationPath}contract-date-is-required`),
      }),
    startDate: Joi.any()
      .custom((value, helpers) => {
        if (!value) return helpers.error('state.dateRequired');
        return value;
      })
      .messages({
        'state.dateRequired': t(`${translationPath}start-date-is-required`),
      }),
    endDate: Joi.any()
      .custom((value, helpers) => {
        if (!value) return helpers.error('state.dateRequired');
        return value;
      })
      .messages({
        'state.dateRequired': t(`${translationPath}end-date-is-required`),
      }),
    transactionEntryDate: Joi.any()
      .custom((value, helpers) => {
        if (!value) return helpers.error('state.dateRequired');
        return value;
      })
      .messages({
        'state.dateRequired': t(
          `${translationPath}transaction-entry-date-is-required`
        ),
      }),
    reservationTypeId: Joi.number()
      .required()
      .messages({
        'any.required': t(`${translationPath}reservation-type-is-required`),
        'number.base': t(`${translationPath}reservation-type-is-required`),
      }),
    transactedById: Joi.string()
      .required()
      .messages({
        'any.required': t(`${translationPath}transacted-by-is-required`),
        'string.base': t(`${translationPath}transacted-by-is-required`),
        'string.empty': t(`${translationPath}transacted-by-is-required`),
      }),

    rentPerYear: Joi.number()
      .required()
      .messages({
        'any.required': t(`${translationPath}rent-per-year-is-required`),
        'number.base': t(`${translationPath}rent-per-year-is-required`),
      }),
    leasingTypeId: Joi.number()
      .required()
      .messages({
        'any.required': t(`${translationPath}leasing-type-is-required`),
        'number.base': t(`${translationPath}leasing-type-is-required`),
      }),
    contacts: Joi.array()
      .min(1)
      .required()
      .messages({
        'array.min': t(`${translationPath}please-select-at-one-contact`),
      }),
  });
  const getStatusesSchema = () => {
    if (getIsSaleRelated()) return getSaleRelatedSchema();
    if (getIsRentRelated()) return getRentRelatedSchema();
    return {};
  };

  const schema = Joi.object(getStatusesSchema())
    .options({
      abortEarly: false,
      allowUnknown: true,
    })
    .validate(state);
  const stepClickedHandler = useCallback(
    (newStepIndex) => () => {
      if (
        unitParams?.disabled &&
        UnitsSaleRelatedSteps.findIndex((step) => step.label === 'summary') ===
          newStepIndex
      ) {
        return;
      }
      setActiveStep(newStepIndex);
    },
    [unitParams?.disabled]
  );
  const isCompletedHandler = useCallback(
    (stepIndex) => {
      if (stepIndex === 0) {
        if (
          getErrorByName(schema, 'agents').error ||
          getErrorByName(schema, 'transactionEntryDate').error ||
          getErrorByName(schema, 'reservationTypeId').error ||
          getErrorByName(schema, 'transactedById').error ||
          getErrorByName(schema, 'contractDate').error ||
          getErrorByName(schema, 'startDate').error ||
          getErrorByName(schema, 'endDate').error
        )
          return false;
      } else if (stepIndex === 1) {
        if (getErrorByName(schema, 'contacts').error) return false;
      } else if (stepIndex === 2) {
        if (
          getErrorByName(schema, 'basePrice').error ||
          getErrorByName(schema, 'rentPerYear').error ||
          getErrorByName(schema, 'leasingTypeId').error ||
          getErrorByName(schema, 'contractIssuedById').error
        )
          return false;
      }
      return true;
    },
    [schema]
  );
  const isDisabledHandler = useCallback(
    (stepIndex, item) => {
      let isDisabled = false;
      if (unitParams?.disabled && item?.label === 'summary') {
        isDisabled = true;
        return;
      }
      for (let index = 0; index < stepIndex; index += 1) {
        isDisabled = !isCompletedHandler(index);
        if (isDisabled) break;
      }
      if (!isDisabled) isDisabled = !isCompletedHandler(activeStep);
      return isDisabled;
    },
    [activeStep, isCompletedHandler, unitParams?.disabled]
  );
  const backHandler = () => {
    setActiveStep((item) => item - 1);
  };
  const nextHandler = useCallback(() => {
    if (!isDisabledHandler(activeStep)) {
      setIsSubmitted(false);
      setActiveStep((item) => item + 1);
    } else setIsSubmitted(true);
    if (activeStep === 1 && getErrorByName(schema, 'contacts').error)
      showError(getErrorByName(schema, 'contacts').message);
  }, [activeStep, isDisabledHandler, schema]);
  const cancelHandler = useCallback(() => {
    if (unitParams.id && unitParams.from && +unitParams.from === 2)
      GlobalHistory.push(
        `/home/units-sales/unit-profile-edit?formType=1&id=${unitParams.id}`
      );
    else GlobalHistory.push('/home/units-sales/view');
  }, [unitParams]);

  const onStateChangedHandler = (newValue) => {
    setState(newValue);
  };
  const onSelectedChangedHandler = (newValue) => {
    setSelected(newValue);
  };

  const calculatePercentage = (value, price) => {
    return value ? (value / (price || 0)) * 100 : 0;
  };

  const fillInitialSaleDetails = () => {
    setState({
      id: 'edit',
      value: {
        ...state,
        ...unitSaleDetails,
        isPriceOnApplication: unitSaleDetails.priceOnApplication,
        isNegotiable: unitSaleDetails.negotiable,
        sellingPricePerSQFT: unitSaleDetails.sellingPricePerSquareFeet,
        originalPricePerSQFT: unitSaleDetails.originalPricePerSquareFeet,
      },
    });
  };

  const saveHandler = useCallback(async () => {
    const { search } = window.location;
    const params = new URLSearchParams(search);
    const rowVersion = params.get('rowVersion').replace(/ /g, '+');
    setIsSubmitted(true);
    if (schema.error) {
      showError(t('Shared:please-fix-all-errors'));
      return;
    }
    if (!getIsSaleRelated() && !getIsRentRelated()) {
      cancelHandler();
      return;
    }
    setIsLoading(true);
    const res =
      (getIsSaleRelated() &&
        (await SetUnitAsReserveOrSale({
          unitId: unitParams.id,
          status: unitParams.status,
          saleTransactionDetails: {
            ...state,
            listingAgents: state.listingAgents || [],
          },
          note: state.note,
          unitTransactionContacts: state.contacts,
          invoicesIds: state.invoicesIds,
          transactionDocuments: selected.transactionDocuments,
          rowVersion,
        }))) ||
      (getIsRentRelated() &&
        (await SetUnitAsReserveOrLease({
          unitId: unitParams.id,
          status: unitParams.status,
          leaseTransactionDetails: {
            ...state,
            listingAgents: state.listingAgents || [],
          },
          note: state.note,
          unitTransactionContacts: state.contacts,
          invoicesIds: state.invoicesIds,
          rowVersion,
        })));
    setIsLoading(false);
    if (!(res && res.status && res.status !== 200)) {
      showSuccess(t`${translationPath}unit-status-updated-successfully`);
      cancelHandler();
      localStorage.removeItem('saleTransactionDetails');
    } else showError(t`${translationPath}unit-status-update-failed`);
  }, [
    cancelHandler,
    getIsRentRelated,
    getIsSaleRelated,
    schema.error,
    state,
    t,
    unitParams,
  ]);

  const getReservedTransactionStepsInfo = async (id) => {
    try {
      const result = await GetReservedTransactionStepsInfo(id);
      if (!(result && result?.status === 200)) {
        setStages(Object.values(result));
      }
    } catch (error) {
      showError(error.toString());
    }
  };

  useEffect(() => {
    if (unitTransactionId) {
      getReservedTransactionStepsInfo(unitTransactionId);
    }
  }, [unitTransactionId]);
  useEffect(() => {
    if (unitParams) {
      bottomBoxComponentUpdate(
        <div className='bottom-box-two-sections'>
          <ButtonBase
            className='btns theme-transparent mb-2'
            onClick={cancelHandler}
          >
            <span className='mdi mdi-chevron-left' />
            <span className='px-2 c-warning'>
              {t(`${translationPath}cancel`)}
            </span>
            <span>
              {t(
                `${translationPath}and-back-to${
                  (unitParams.id &&
                    unitParams.from &&
                    +unitParams.from === 2 &&
                    '-unit-profile-edit') ||
                  '-unit'
                }`
              )}
            </span>
          </ButtonBase>
          <div className='d-flex-v-center flex-wrap'>
            <ButtonBase
              className='btns theme-transparent mb-2'
              disabled={activeStep === 0}
              onClick={backHandler}
            >
              <span>{t('Shared:back')}</span>
            </ButtonBase>
            {!(
              activeStep ===
              ((getIsSaleRelated() && UnitsSaleRelatedSteps.length - 1) ||
                UnitsRentRelatedSteps.length - 1)
            ) && (
              <ButtonBase
                className='btns theme-solid mb-2'
                onClick={nextHandler}
                disabled={
                  (activeStep === 1 && reciveNextButtonStatusMoveStepper) ||
                  (unitParams?.disabled &&
                    activeStageIndex >= 0 &&
                    UnitsSaleRelatedSteps[activeStep]?.label ===
                      'invoice-description')
                }
              >
                <span>{t('Shared:next')}</span>
              </ButtonBase>
            )}
            {activeStep ===
              ((getIsSaleRelated() && UnitsSaleRelatedSteps.length - 1) ||
                UnitsRentRelatedSteps.length - 1) && (
              <ButtonBase
                className='btns theme-solid mb-2'
                onClick={saveHandler}
              >
                <span>{t('Shared:save')}</span>
              </ButtonBase>
            )}
          </div>
        </div>
      );
    }
  }, [
    activeStep,
    cancelHandler,
    getIsSaleRelated,
    nextHandler,
    saveHandler,
    t,
    unitParams,
    activeStageIndex,
  ]);
  useEffect(() => {
    if (unitParams && unitParams.id) {
      getUnitById();
      getUnitSaleDetails();
    }
  }, [unitParams]);

  useEffect(() => {
    if (unitSaleDetails) fillInitialSaleDetails();
  }, [unitSaleDetails]);
  useEffect(() => {
    if (unitParams && unitParams.id) {
      if (unitParams.status === UnitsStatusEnum.ReservedLeased.key)
        getReservedUnitTransactionDataForLeaseByUnitId();
      else if (
        unitParams.status === UnitsStatusEnum.Sale.key
      )
        getReservedUnitTransactionDataForSaleByUnitId();
      else handleSellerListingAgentInit();
    }
  }, [unitSaleDetails, unitParams]);
  useEffect(() => {
    if (unitParams && unitParams.id)
      setState({ id: 'edit', value: getStateInit() });
  }, [getStateInit, unitParams]);
  useEffect(() => {
    const status = GetParams('status');
    const id = GetParams('id');
    const from = GetParams('from');
    const disabled = GetParams('disabled');
    const { search } = window.location;
    const params = new URLSearchParams(search);
    const rowVersion = params.get('rowVersion').replace(/ /g, '+');
    if (status && id && from) {
      setUnitParams({
        status: +status,
        id: +id,
        from: +from,
        rowVersion,
        disabled,
      });
    } else if (id && from && +from === 2)
      GlobalHistory.push(
        `/home/units-sales/unit-profile-edit?formType=1&id=${id}`
      );
    else GlobalHistory.push('/home/units-sales/view');
  }, []);

  useEffect(() => {
    if (
      unitData &&
      unitData.unitStatus &&
      unitData.unitStatus.key === UnitsStatusEnum.ReservedSale.key &&
      unitParams &&
      unitParams.status === UnitsStatusEnum.Sale.key
    )
      setCantEditReservedSaleTransaction(
        !returnPropsByPermissions(
          UnitsSalesPermissions.EditReservedSaleTransaction.permissionsId
        ) || unitParams?.disabled
          ? true
          : false
      );
    else setCantEditReservedSaleTransaction(null);
  }, [unitData]);

  return (
    <div className='units-status-management-wrapper view-wrapper'>
      <Spinner isActive={isLoading} />
      {stages.length > 0 && isForDeveloper && (
        <div className='d-flex px-5'>
          <ul className='stages-breadcrumb v1'>
            {Children.toArray(
              stages.map((stage, i) => (
                <li
                  className={`breadcrumb-level unit-stage 

                  ${i < activeStageIndex ? 'completed' : ''}
                  
                  `}
                  onMouseEnter={() => setVisibleList(stage.name)}
                  onMouseLeave={() => setVisibleList('')}
                >
                  <a
                    onClick={(e) => e.preventDefault()}
                    className={`${activeStageIndex === i ? 'active' : ''} ${
                      !stage.isContainMissingItems ? '' : ''
                    } unit-stage`}
                  >
                    {stage.name}
                  </a>
                  {visibleList === stage.name &&
                    stage.tableDeveloperTransactionStepsItems?.length > 0 && (
                      <div className='absolute-list'>
                        <ul>
                          {Children.toArray(
                            stage.tableDeveloperTransactionStepsItems?.map(
                              (item) => (
                                <li>
                                  <CheckboxesComponent
                                    singleChecked={item.isExist}
                                    label={
                                      item.isForContact
                                        ? item.itemName + ' (for contact)'
                                        : item.itemName
                                    }
                                    isDisabled
                                  />
                                </li>
                              )
                            )
                          )}
                        </ul>
                      </div>
                    )}
                </li>
              ))
            )}
            <li
              className={
                activeStageIndex !== -1
                  ? 'finish-disabled breadcrumb-level'
                  : 'breadcrumb-level pointer'
              }
            >
              <a
                onClick={(e) => {
                  e.preventDefault();
                  if (activeStageIndex !== -1) {
                    return;
                  }
                  saveHandler();
                }}
                className={activeStageIndex === -1 ? 'active' : ''}
              >
                {t('finish')}
              </a>
            </li>
          </ul>
        </div>
      )}

      <StepperComponent
        steps={
          (getIsSaleRelated() && UnitsSaleRelatedSteps) ||
          (getIsRentRelated() && UnitsRentRelatedSteps) ||
          []
        }
        onStepperClick={stepClickedHandler}
        completed={isCompletedHandler}
        isDisabled={isDisabledHandler}
        labelInput='label'
        hasError={schema.error !== undefined}
        isSubmitted={isSubmitted}
        activeStep={activeStep}
        isValidateOnlyActiveIndex
        parentTranslationPath={parentTranslationPath}
        translationPath={translationPath}
        dynamicComponentProps={{
          state,
          selected,
          unitData,
          unitSaleDetails,
          unitTransactionId,
          schema,
          onStateChanged: onStateChangedHandler,
          onSelectedChanged: onSelectedChangedHandler,
          isSubmitted,
          parentTranslationPath,
          translationPath,
          EnableNextButtonTotalOwnership,
          cantEditReservedSaleTransaction,
          disabled: !!unitParams?.disabled,
          propertyId: unitData?.property_name?.id,
        }}
      />
    </div>
  );
};
