
import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { LanguageEnum   } from '../../../../../../../../../../Enums';
import { lookupItemsGetId } from '../../../../../../../../../../Services/LookupsServices';
import { AutocompleteComponent} from '../../../../../../../../../../Components';
import { getErrorByName } from '../../../../../../../../../../Helper';


export const LanguageComponent = ({
  parentTranslationPath,
  translationPath,
  selected,
  setSelected,
  isSubmitted,
  setIsLoading,
  schema
}) => {

  const { t } = useTranslation([parentTranslationPath, 'Shared']);
  const [allLanguages, setAllLanguages] = useState([]);

  const getAllLanguages = useCallback(async () => {
    setIsLoading(true);

    const res = await lookupItemsGetId({
      lookupTypeId: LanguageEnum.lookupTypeId,
    });

    if (!(res && res.status && res.status !== 200)) 
       setAllLanguages(res) ;

    else
      setAllLanguages([]);

    setIsLoading(false);

  }, []);

  useEffect(() => {
    getAllLanguages();
  }, [getAllLanguages]);


  return (
    <div>
      <AutocompleteComponent
        labelClasses='Requierd-Color'
        idRef='languageRef'
        labelValue={t(`${translationPath}language`)}
        selectedValues={(selected && selected.Language) || []}
        data={allLanguages}
        displayLabel={(option) => (option && option.lookupItemName) || ''}
        multiple={false}
        withoutSearchButton
        onChange={(event, newValue) => 
        {
          setSelected(newValue) ; 

        }}
        parentTranslationPath={parentTranslationPath}
        translationPath={translationPath}
        isWithError
        isSubmitted={isSubmitted}
        helperText={getErrorByName(schema, 'languageId').message}
        error={getErrorByName(schema, 'languageId').error}
      />
    </div>
  );
};
