import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => {
  return {
    root: (props) => ({
      border: `1px solid ${theme.palette.border?.[props?.borderColor ?? 'secondary']}`,
      borderRadius: `${theme.borderRadius[props?.borderRadius ?? 0]} !important`,
    }),
  };
});
