import { put, takeEvery } from 'redux-saga/effects';
import { ContactsMergeActions } from './Actions';
import { ContactsMergeStatus } from './Status';

function* fetchContactsMergeInfo(action) {
  try {
    localStorage.setItem('ContactsMergeInfo', JSON.stringify(action.payload));
    yield put(ContactsMergeActions.contactsMergeSuccess(action.payload));
  } catch (err) {
    yield put(ContactsMergeActions.contactsMergeError(err));
  }
}
export function* watchContactsMergeInfo() {
  yield takeEvery(ContactsMergeStatus.REQUEST, fetchContactsMergeInfo);
}
