import React, { useEffect } from 'react';
import { SwitchRoute } from '../../../Components/Route/SwitchRoute';
import { LeadOwnerLeadsRoutes } from '../../../routes';

export const LeadOwnerLeadsLayout = () => {
  useEffect(() => {
    return () => {
      localStorage.removeItem("leadOwnerLeadsCurrentPageIndex");
    }
  }, []);

  return (
    <SwitchRoute routes={LeadOwnerLeadsRoutes} />
  );
};

