import moment from 'moment';
import React, {
    useRef, useState, useEffect, useCallback
} from 'react';
import { useTranslation } from 'react-i18next';
import { Spinner, PaginationComponent } from '../../../Components';
import { DateRangePickerComponent } from '../../../Components/Controls/DateRangePickerComponent/DateRangePickerComponent';
import { useTitle } from '../../../Hooks';
import { SystemNotificationsTableView } from './SystemNotificationsTableView/SystemNotificationsTableView';
import { GetParams, bottomBoxComponentUpdate } from '../../../Helper';
import { GetMyNotifications } from '../../../Services';

const parentTranslationPath = 'SystemNotifications';
const translationPath = '';
export const UserNotificationDetails = () => {
    const { t } = useTranslation(parentTranslationPath);
    useTitle(t(`${translationPath}system-notification`));
    const dateRangeDefault = {
        startDate: null,
        endDate: null,
        key: 'selection',
    };
    const [response, setResponse] = useState({
        result: [],
        totalCount: 0,
    });
    const [dateFilter, setDateFilter] = useState(dateRangeDefault);
    const [isLoading, setIsLoading] = useState(false);
    const [systemNotificationsTableFilter, setSystemNotificationsTableFilter] = useState(null);

    const [filter, setFilter] = useState({
        dateFrom: dateFilter.startDate,
        dateTo: dateFilter.endDate,
        pageIndex: 0,
        pageSize: parseInt(localStorage.getItem('Pagination')) || 25,
        isImportant: +GetParams('isImportant') || false
    });
    const onFilterValuesChanged = (newValue) => {
        setSystemNotificationsTableFilter(newValue);
        const localFilterDto = filter || {};
        if (newValue) {
            Object.values(newValue)
                .filter((item) => item.searchableKey || item.displayPath)
                .map((item) => {
                    if (localFilterDto[item.displayPath])
                        localFilterDto[item.displayPath] = item.value;
                    else if (item.value)
                        localFilterDto[item.displayPath] = item.value;

                    return undefined;
                });
        }
        setFilter(() => ({ ...localFilterDto }));
    };
    const searchTimer = useRef(null);

    const onPageIndexChanged = (pageIndex) => {
        setFilter((item) => ({ ...item, pageIndex }));
    };

    const onPageSizeChanged = (pageSize) => {
        setFilter((item) => ({ ...item, pageIndex: 0, pageSize }));
    };

    const getAllMyNotification = useCallback(async () => {
        setIsLoading(true);

        const res = await GetMyNotifications({ ...filter, pageIndex: filter.pageIndex + 1, pageSize: filter.pageSize });
        if (!(res && res.status && res.status !== 200)) {
            setResponse({
                result: ((res && res.notificationItems.result) || []).map((item) => ({
                    ...item,
                    notificationType: item && item.notificationType == 1 ? 'Email' : (item && item.notificationType == 2 ? 'SMS' : item && item.notificationType == 3 ? 'Notification' : 'N/A'),
                })),
                totalCount: (res && res.notificationItems.totalCount) || 0,
            });
        } else {
            setResponse({
                result: [],
                totalCount: 0,
            });
        }
        setIsLoading(false);
    }, [filter]);

    useEffect(() => {
        getAllMyNotification();
    }, [getAllMyNotification]);

    useEffect(() => {
        if (+GetParams('isImportant') === 1)
            setFilter((item) => ({ ...item, isImportant: true }));
        else
            setFilter((item) => ({ ...item, isImportant: false }));
    }, [+GetParams('isImportant')]);

    useEffect(() => {
        bottomBoxComponentUpdate(
          <PaginationComponent
            pageIndex={filter.pageIndex}
            pageSize={filter.pageSize}
            totalCount={response && response.totalCount}
            onPageIndexChanged={onPageIndexChanged}
            onPageSizeChanged={onPageSizeChanged}
          />
        );
    });

    useEffect(() => () => { bottomBoxComponentUpdate(null); }, []);
    useEffect(
        () => () => {
            if (searchTimer.current) clearTimeout(searchTimer.current);
        },
        []
    );

    useEffect(() => {
        setFilter((item) => ({
            ...item,
            dateFrom: !dateFilter.startDate ? dateFilter.startDate : moment(dateFilter.startDate).format('YYYY-MM-DDTHH:mm:ss'),
            dateTo: !dateFilter.endDate ? dateFilter.endDate : moment(dateFilter.endDate).format('YYYY-MM-DDTHH:mm:ss'),
        }));
    }, [dateFilter]);

    return (
      <div className='SystemNotifications-wrapper view-wrapper'>
        <Spinner isActive={isLoading} isAbsolute />
        <div className='w-100 px-2'>

          <div className='header-section '>
            <div className='d-flex-v-center-h-end m-3'>
              <DateRangePickerComponent
                onClearClicked={() => setDateFilter(dateRangeDefault)}
                ranges={[dateFilter]}
                parentTranslationPath={parentTranslationPath}
                translationPath={translationPath}
                onDateChanged={(selectedDate) => {
                                setDateFilter({
                                    startDate: selectedDate.selection && selectedDate.selection.startDate,
                                    endDate: new Date(moment(selectedDate.selection && selectedDate.selection.endDate).endOf('day')),
                                    key: 'selection',
                                });
                            }}
              />
            </div>
          </div>
          <div className='w-100 px-2'>
            <SystemNotificationsTableView
              data={response}
              filter={filter}
              setFilter={setFilter}
              translationPath={translationPath}
              parentTranslationPath={parentTranslationPath}
              systemNotificationsTableFilter={systemNotificationsTableFilter}
              onFilterValuesChanged={onFilterValuesChanged}
              onPageSizeChanged={onPageSizeChanged}
              onPageIndexChanged={onPageIndexChanged}
            />
          </div>
        </div>
      </div>
    );
};
