
export const HighlightRowKeys = {
    Contacts : {
        key: 'Contacts',
    },
    Properties : {
        key: 'Properties',
    },
    LeadSale : {
        key: 'LeadSale',
    },
    LeadLease : {
        key: 'LeadLease',
    },
    LeadContactCenter : {
        key: 'LeadContactCenter',
    },
    LeadMortgage : {
        key: 'LeadMortgage',
    },
    LeadLeadOwner : {
        key: 'LeadLeadOwner',
    },
    UnitSale : {
        key: 'UnitSale',
    },
    UnitLease : {
        key: 'UnitLease',
    },
    UnitSaleLeadOwner: {
        key: 'UnitSaleLeadOwner',
    },
    UnitLeaseLeadOwner : {
        key: 'UnitLeaseLeadOwner',
    },
}
