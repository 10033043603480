export const propertyInitValue = {
  property: {
    city: null,
    country: null,
    district: null,
    community: null,
    property_name: null,
    property_plan: null,
    property_type: null,
    property_owner: null,
    address_line_1: null,
    amenities: null,
    architect_company: null,
    assigned_real_estate_agent: null,
    available_for_non_gcc_buyer: null,
    building_gfa_sqft: null,
    builtup_area_sqft: null,
    cc_work_order_to: null,
    completion_date: null,
    construction_date: null,
    consultant_company: null,
    data_completed: null,
    design_consultant: null,
    electro_mechanical_contractor: null,
    escrow_account_number: null,
    escrow_bank: null,
    facilities: null,
    handover_date: null,
    holding_deposit: null,
    initial_deposit: null,
    isManage: null,
    land_number: null,
    land_scaping_contractor: null,
    lands_department_number: null,
    launch_date: null,
    licence_no: null,
    main_contractor: null,
    maintenance_fee_per_sqft: null,
    map: null,
    master_developer: null,
    municipality: null,
    municipality_number: null,
    number_of_floors: null,
    owner_representative_person: null,
    ownership: null,
    parking_levels: null,
    plot_area_sqft: null,
    plot_number: null,
    postcode: null,
    project_manager_company: null,
    property_code: null,
    property_documents: null,
    property_images: null,
    property_managed_by: null,
    property_type_id: null,
    property_usage: null,
    referred_by: null,
    referred_date: null,
    registration_of_property_name: null,
    reservation_expiry_days: null,
    secondary_developer: null,
    service_charge: null,
    site_engineer: null,
    site_manager: null,
    sub_community: null,
    sub_contractor: null,
    units_models: null,
    without_deposit: null,
    zone_type: null,
  },
};
