import { useLocation } from "react-router-dom";

export const useIsActiveRoute = () => {
  const location = useLocation();
  const currentPath = location?.pathname;

  const isActiveRoute = (item) => {
    return item.children.some(child => 
      currentPath?.startsWith(child.layout ? child.layout + child.path : child.path)
    );
  };

  return { isActiveRoute };
};