import { UnitsSalesPermissions } from '../../Permissions';
import { UnitsBulkAssignView, UnitsReservationView, ContactProfileManagementView, UnitTransactionsView,
   UnitTransactionParametersView, PrimaryJourneyView } from '../../Views/Home';
import {
  UnitsSalesView,
  UnitsSalesManagementView,
  UnitsSalesProfileManagementView,
  UnitsSalesStatusManagementView,
} from '../../Views/Home/UnitsSalesView';
import { DuplicatedUnitsView } from '../../Views/Home/UnitsView/DuplicatedUnits/DuplicatedUnitsView';

export const UnitsSalesRoutes = [
  {
    path: '/add',
    name: 'homeLayout.unitsManagementView.units-management-view',
    component: UnitsSalesManagementView,
    layout: '/home/units-sales',
    default: false,
    isRoute: true,
    authorize: true,
    roles: [],
    isDisabled: false,
    isExact: true,
    breadcrumbs: [
      {
        name: 'UnitsView:units',
        isDisabled: false,
        route: '/home/units-sales/view',
        groupName: 'sales',
      },
      {
        name: 'homeLayout.unitsManagementView.units-management-view',
        isDisabled: true,
        route: '/home/units-sales/add',
      },
    ],
  },
  {
    path: '/edit',
    name: 'homeLayout.unitsManagementView.units-management-view',
    component: UnitsSalesManagementView,
    layout: '/home/units-sales',
    default: false,
    isRoute: true,
    authorize: true,
    roles: [],
    isDisabled: false,
    isExact: true,
    breadcrumbs: [
      {
        name: 'UnitsView:units',
        isDisabled: false,
        route: '/home/units-sales/view',
        groupName: 'sales',
      },
      {
        name: 'homeLayout.unitsManagementView.units-management-view',
        isDisabled: true,
        route: '/home/units-sales/edit',
      },
    ],
  },
  {
    path: '/unit-profile-edit',
    name: 'UnitsProfileManagementView:unit-profile-edit',
    component: UnitsSalesProfileManagementView,
    layout: '/home/units-sales',
    default: false,
    isRoute: true,
    authorize: true,
    roles: [],
    isDisabled: false,
    isExact: true,
    breadcrumbs: [
      {
        name: 'unitsView:units',
        isDisabled: false,
        route: '/home/units-sales/view',
        groupName: 'sales',
      },
      {
        name: 'UnitsProfileManagementView:unit-profile-edit',
        isDisabled: true,
        route: '/home/units-sales/unit-profile-edit',
      },
    ],
  },
  {
    path: '/unit-status-management',
    name: 'UnitsStatusManagementView:unit-status-management',
    component: UnitsSalesStatusManagementView,
    layout: '/home/units-sales',
    default: false,
    isRoute: true,
    authorize: true,
    roles: [],
    isDisabled: false,
    isExact: true,
    breadcrumbs: [
      {
        name: 'unitsView:units',
        isDisabled: false,
        route: '/home/units-sales/view',
        groupName: 'sales',
      },
      {
        name: 'UnitsStatusManagementView:unit-status-management',
        isDisabled: true,
        route: '/home/units-sales/unit-status-management',
      },
    ],
  },
  {
    path: '/view',
    name: 'UnitsView:units',
    component: UnitsSalesView,
    layout: '/home/units-sales',
    default: true,
    isRoute: true,
    authorize: true,
    roles: Object.values(UnitsSalesPermissions),
    isDisabled: false,
    isExact: true,
    breadcrumbs: [
      {
        name: 'UnitsView:units',
        isDisabled: false,
        route: '/home/units-sales/view',
        groupName: 'sales',
      },
    ],
  },
  {
    path: '/unit-profile-reservation',
    name: 'UnitsProfileManagementView:unit-profile-reservation',
    component: UnitsReservationView,
    layout: '/home/units-sales',
    default: false,
    isRoute: true,
    authorize: true,
    roles: [],
    isDisabled: false,
    isExact: true,
    breadcrumbs: [
      {
        name: 'unitsView:units',
        isDisabled: false,
        route: '/home/units-sales/view',
        groupName: 'sales',
      },
      {
        name: 'UnitsProfileManagementView:unit-profile-reservation',
        isDisabled: true,
        route: '/home/units-sales/unit-profile-reservation',
      },
    ],
  },
  {
    path: '/unit-bulk-update',
    name: 'UnitsProfileManagementView:unit-bulk-update',
    component: UnitsBulkAssignView,
    layout: '/home/units-sales',
    default: false,
    isRoute: true,
    authorize: true,
    roles: [],
    isDisabled: false,
    isExact: true,
    breadcrumbs: [
      {
        name: 'unitsView:units',
        isDisabled: false,
        route: '/home/units-sales/view',
        groupName: 'sales',
      },
      {
        name: 'UnitsProfileManagementView:unit-bulk-update',
        isDisabled: true,
        route: '/home/units-sales/unit-bulk-update',
      },
    ],
  },
  {
    path: '/unit-transaction',
    name: 'UnitsProfileManagementView:unit-transaction:unit-transaction',
    component: UnitTransactionsView,
    layout: '/home/units-sales',
    default: false,
    isRoute: true,
    authorize: true,
    roles: [],
    isDisabled: false,
    isExact: true,
    breadcrumbs: [
      {
        name: 'unitsView:units',
        isDisabled: false,
        route: '/home/units-sales/view',
        groupName: 'sales',
      },
      {
        name: 'UnitsProfileManagementView:unit-transaction:unit-transaction',
        isDisabled: false,
        route: '/home/units-sales/unit-transaction',
      },
    ],
  },
  {
    path: '/unit-transaction-parameters',
    name: 'UnitsProfileManagementView:unit-transaction-parameters',
    component: UnitTransactionParametersView,
    layout: '/home/units-sales',
    default: false,
    isRoute: true,
    authorize: true,
    roles: [],
    isDisabled: false,
    isExact: true,
    breadcrumbs: [
      {
        name: 'unitsView:units',
        isDisabled: false,
        route: '/home/units-sales/view',
        groupName: 'sales',
      },
      {
        name: 'UnitsProfileManagementView:unit-transaction-parameters',
        isDisabled: false,
        route: '/home/units-sales/unit-transaction-parameters',
      },
    ],
  },
  {
    path: '/contact-edit',
    name: 'ContactProfileManagementView:contact-profile-edit',
    component: ContactProfileManagementView,
    layout: '/home/units-sales',
    default: false,
    isRoute: true,
    authorize: true,
    roles: [],
    isDisabled: false,
    isExact: true,
    breadcrumbs: [
      {
        name: 'unitsView:units',
        isDisabled: false,
        route: '/home/units-sales/view',
        groupName: 'sales',
      },
      {
        name: 'ContactProfileManagementView:contact-profile-edit',
        isDisabled: true,
        route: '/home/units-sales/contact-edit',
      },
    ],
  },
  {
    path: '/duplicated-units',
    name: 'UnitsView:duplicated-units',
    component: DuplicatedUnitsView,
    layout: '/home/units-sales',
    default: false,
    isRoute: true,
    authorize: true,
    isDisabled: false,
    isExact: true,
    breadcrumbs: [
      {
        name: 'UnitsView:units',
        isDisabled: false,
        route: '/home/units-sales/view',
        groupName: 'sales',
      },
      {
        name: 'UnitsView:duplicated-units',
        isDisabled: false,
        route: '/home/units-sales/duplicated-units',
      },
    ],
  },
];
