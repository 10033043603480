import { Button, ButtonBase, Chip } from '@material-ui/core';
import React from 'react';
import ActiveUserLogHistoryHeader from './ActiveUserLogHistoryHeaderData';
import { Tables } from '../../../../../Components';

export const ActiveUserLogHistoryTable = ({
  data,
  parentTranslationPath,
}) => {
  return (
    <div className='p-3'>
      {data?.length === 0 || !data ? (
        <Chip component={'h1'} label={`no-records-found`} />
      ) : (
        <Tables
          data={data ?? []}
          headerData={ActiveUserLogHistoryHeader}
          parentTranslationPath={parentTranslationPath}
        />
      )}
    </div>
  );
};
