

import React, { useState ,  useEffect , useReducer  } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Joi from 'joi';
import {
  DialogActions, DialogContent, DialogTitle, Dialog
} from '@material-ui/core';
import {
  Spinner, 
} from '../../../../../../../../../../Components';
import {  showSuccess , showError  , GetParams } from '../../../../../../../../../../Helper' ; 
import {  CreatePublishPortalUnitAPI  , UpdatePublishPortalUnitAPI , ValidatePortalUnitForPublishing  } from '../../../../../../../../../../Services' ; 
import {  PublishedOnPortalContentDialog   } from './PublishedOnPortalManagementDialog/PublishedContentDialog' ; 
import {  PublishedOnPortalFooterDialog  } from './PublishedOnPortalManagementDialog/PublishedFooterDialog' ; 


export const PublishedOnPortalManagementDialog = ({
   parentTranslationPath ,
   translationPath ,
   isOpen ,
   onClose ,
   onSave  , 
   editUnitPublishedPortal  , 
   isLoading , 
   isLoadingInfo , 
   unitMarketingId , 
   
  }) => {

  const { t } = useTranslation(parentTranslationPath);
  const [isSaveLoading , setIsSaveLoading] = useState(false) ; 
  const [isSubmitted , setIsSubmitted] = useState(false) ;
  const [canPublishedUnit , setCanPublishedUnit] = useState(false) ;
  
  const [propertyFinderDetails , setPropertyFinderDetails] = useState({
    paymentTypes: null,
    city: null,
    community: null  ,
    subCommunity: null , 
    propertyName: null , 
  }); 
  
  const reducer = (state, action) => {
    if (action.id !== 'edit') return { ...state, [action.id]: action.value };
    if (action.id === 'edit') {
      return {
        ...action.value,
      };
    }
    return undefined;
  }

  const [state, setState] = useReducer(reducer,
  {
    portalId : null ,  
    listingAgentId : null , 
    branchId : null , 
    portalAccountId	: null , 
    unitId : +GetParams('id') , 
    isActive : false ,  
    createPublishPortalExtraDetail : null ,     
  });

  const [selected , setSelected] = useState({
    listingAgent  : null  ,
    portal : null , 
    branch : null , 
    portalAccount : null ,
    unit  :  null , 
    createPublishPortalExtraDetail : null , 
   })


  const schema = Joi.object({
    listingAgentId: Joi.string()
      .required()
      .messages({
        'string.base': t(`${translationPath}listing-agent-is-required`),
        'string.empty': t(`${translationPath}listing-agent-is-required`),
      }),
      branchId : Joi.number()
       .required()
       .messages({
        'number.base': t(`${translationPath}branch-is-required`),
        'number.empty': t(`${translationPath}branch-is-required`),
      }),
     portalAccountId : Joi.number()
     .required()
     .messages({
      'number.base': t(`${translationPath}portal-account-is-required`),
      'number.empty': t(`${translationPath}portal-account-is-required`),
     }),
     portalId : Joi.number()
       .required()
       .messages({
        'number.base': t(`${translationPath}portal-is-required`),
        'number.empty': t(`${translationPath}portal-is-required`),
      }),
      unitId : Joi.number()
      .required()
      .messages({
       'number.base': t(`${translationPath}unit-is-required`),
       'number.empty': t(`${translationPath}unit-is-required`),
     }),
  })
    .options({
      abortEarly: false,
      allowUnknown: true,
    })
    .validate(state);

  const saveHandler = async () => {
    setIsSubmitted(true);
    setIsSaveLoading(true) ; 
   
    if(schema.error)
    {
      showError(t('please-fill-all-Required-field'));
      setIsSaveLoading(false) ; 
      return ;

    }
    if(!state.branchId)
    {
      showError(t('please-fill-branch-from-user-information-form'));
      setIsSaveLoading(false) ; 
      return ;

    }
    if(!state.portalId)
    {
      showError(t('please-fill-portalId'));
      setIsSaveLoading(false) ; 
      return ;

    }
    const validatePortalUnitForPublishing = {...state } ; 
    if(validatePortalUnitForPublishing &&  validatePortalUnitForPublishing.createPublishPortalExtraDetail)
    {
      delete validatePortalUnitForPublishing.createPublishPortalExtraDetail  ; 
      delete validatePortalUnitForPublishing.isActive  ; 

    }

    const res =  await ValidatePortalUnitForPublishing({...validatePortalUnitForPublishing , unitMarketingId }) ;
    if(res && res.StatusCode === 500 && res.Message )
    {
      const  backendError = res.Message ; 
      
      const errorMessage  = backendError.replace('/CrmDfm/PortalAccount/ValidatePortalUnitForPublishing : ', '');
      showError(t(errorMessage)) ; 
      setIsSaveLoading(false) ; 
      return ; 

    }
    else if(res)  
    { 
      const res = !editUnitPublishedPortal ?  await CreatePublishPortalUnitAPI(state) :
                 await UpdatePublishPortalUnitAPI( state.portalAccountId ,state);
      if(res && res.StatusCode === 500 && res.Message )
       {
         const  backendError = res.Message ; 
         const errorMessage  = !editUnitPublishedPortal ? backendError.replace('/CrmDfm/PortalAccount/CreatePublishPortalUnit', ' ') :backendError.replace('/CrmDfm/PortalAccount/UpdatePublishPortalUnit', ' ');
         showError(t(errorMessage)) ; 
         setIsSaveLoading(false) ; 
         return ; 


       }
     else if (!(res && res.status && res.status !== 200)) 
      {
      if(!editUnitPublishedPortal)
        showSuccess(t(`${translationPath}add-unit-published-portal-successfully`));
       else 
        showSuccess(t(`${translationPath}edit-unit-published-portal-successfully`));
      onSave() ;
     } 
    } 
    setIsSaveLoading(false) ; 
  };

  useEffect(() => {
    if (editUnitPublishedPortal === undefined || editUnitPublishedPortal === null) 
    {
      setState({ id: 'isActive', value: true });
      return;
  
    }
    if(editUnitPublishedPortal)
    {
      const currntState  =
      {   
          publishedPortalId  :(editUnitPublishedPortal.publishedPortalId) , 
          portalAccountId :(editUnitPublishedPortal.portalAccountDto && editUnitPublishedPortal.portalAccountDto.portalAccountId) , 
          portalId : ( editUnitPublishedPortal.portalDto && editUnitPublishedPortal.portalDto.portalId) ,  
          listingAgentId : ( editUnitPublishedPortal.portalAccountListingAgentDto && editUnitPublishedPortal.portalAccountListingAgentDto.listingAgentId) , 
          branchId : ( editUnitPublishedPortal.branchPortalAccountDto && editUnitPublishedPortal.branchPortalAccountDto.branchId) , 
          unitId : ( editUnitPublishedPortal.portalAccountUnitDto && editUnitPublishedPortal.portalAccountUnitDto.unitId) ,    
          isActive : editUnitPublishedPortal.isActive || false , 
          createPublishPortalExtraDetail : ( editUnitPublishedPortal && editUnitPublishedPortal.publishPortalExtraDetails)
  
      }
     setState({ id: 'edit', value: currntState });
     setSelected((item)=> ({...item ,
       listingAgent  : (editUnitPublishedPortal.portalAccountListingAgentDto && { id : (editUnitPublishedPortal.portalAccountListingAgentDto && editUnitPublishedPortal.portalAccountListingAgentDto.listingAgentId) , fullName :  (editUnitPublishedPortal.portalAccountListingAgentDto.fullName) } )   ,
       portal : (editUnitPublishedPortal.portalDto) ,
       branch : (editUnitPublishedPortal.branchPortalAccountDto ) , 
       portalAccount : (editUnitPublishedPortal && editUnitPublishedPortal.portalAccountDto)  , 
       unit : ( editUnitPublishedPortal && editUnitPublishedPortal.portalAccountUnitDto ) , 
       createPublishPortalExtraDetail : ( editUnitPublishedPortal && editUnitPublishedPortal.publishPortalExtraDetails)
     })) ;

    }
     
  }, [editUnitPublishedPortal]);


  return (
    <div>
      <Dialog
        open={isOpen}
        maxWidth='sm'
        onClose={() => {
          onClose();
        }}
        className='dialog-wrapper unitPublishedPortal'
        disableBackdropClick
      >
      <Spinner isActive={(isLoadingInfo ||  isSaveLoading)} isAbsolute />
          <DialogTitle id='alert-dialog-slide-title'>
            <span>
              {!editUnitPublishedPortal ?
                t(`${translationPath}add-published-unit`) :
                t(`${translationPath}edit-published-unit`)}
            </span>
          </DialogTitle>
          <DialogContent>
            <PublishedOnPortalContentDialog
              parentTranslationPath={parentTranslationPath}
              translationPath={translationPath}
              state={state} 
              setState={setState}
              schema={schema}
              isSubmitted={isSubmitted}
              selected={selected}
              setSelected={setSelected}
              editUnitPublishedPortal={editUnitPublishedPortal}
              propertyFinderDetails={propertyFinderDetails}
              setPropertyFinderDetails={setPropertyFinderDetails}
            />
          </DialogContent>
          <DialogActions>
            <PublishedOnPortalFooterDialog
              parentTranslationPath={parentTranslationPath}
              translationPath={translationPath}
              onClose={onClose}
              onSave ={saveHandler}
              state={state}
              schema={schema}
              editUnitPublishedPortal={editUnitPublishedPortal}
            />  
          </DialogActions>
      </Dialog>
    </div>
  );

};

PublishedOnPortalManagementDialog.propTypes = {
  parentTranslationPath: PropTypes.string.isRequired,
  translationPath: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  editPublishedPortal: PropTypes.instanceOf(Object),
  isLoadingPublishedPortal :PropTypes.instanceOf(Object),
};
PublishedOnPortalManagementDialog.defaultProps = {
  editPublishedPortal: undefined ,
  isLoadingPublishedPortal : undefined , 

};

