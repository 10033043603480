import React, { useEffect } from "react";
import { Box, IconButton } from "@material-ui/core";

import { useSelectedTheme, useIsDesktop } from "../../../Hooks";
import { CustomButton } from "../CustomButton";
import { CustomIconButton } from "../CustomIconButton";
import { useVerticalNav } from "../../../Contexts/VerticalNavContext";

import { CloseXIcon } from "../../../assets/icons";
import useStyles from "./styles";

const variantImages = {
  warning: "/images/contacts/warningIcon.svg",
  success: "/images/contacts/successIcon.svg",
  error: "/images/contacts/alertIcon.svg",
  primary: "/images/contacts/primaryInfo.svg",
  secondary: "/images/contacts/secondaryInfo.svg",
  tertiary: "/images/contacts/tertiaryInfo.svg",
};

const AlertBox = () => {
  const styles = useStyles();
  const {
    theme: { palette },
  } = useSelectedTheme();
  const { isDesktop } = useIsDesktop();
  const { alertBoxContent } = useVerticalNav();

  // Default image based on variant
  const imageSrc =
    variantImages[alertBoxContent?.variant] || variantImages.primary;

  useEffect(() => {
    const timer = setTimeout(() => {
      if (alertBoxContent?.onClose) {
        alertBoxContent.onClose();
      }
    }, 3000);

    return () => clearTimeout(timer);
  }, [alertBoxContent]);

  return (
    <Box
      className={styles.container}
      style={{ flexDirection: isDesktop ? "row" : "column" }}
    >
      <img
        alt={`${alertBoxContent?.variant}Icon`}
        width="38"
        height="38"
        className={styles.img}
        src={imageSrc}
      />

      <Box
        style={
          !alertBoxContent?.description
            ? {
                paddingRight: "25px",
                alignSelf: "center",
              }
            : {}
        }
      >
        <Box className={styles.text}>
          <Box className={styles.header}>
            <span>{alertBoxContent?.title || ""}</span>
            <CustomIconButton
              variant="text"
              size="none"
              boxShadow="none"
              color="tertiaryColor"
              className={styles.closeButton}
            >
              <CloseXIcon
                width="20"
                height="20"
                fill={palette.foreground.disabled}
                className={styles.closeIcon}
                onClick={alertBoxContent?.onClose}
              />
            </CustomIconButton>
          </Box>
          {alertBoxContent?.description && (
            <p>{alertBoxContent?.description || ""}</p>
          )}
        </Box>

        <Box className={styles.buttonsContainer}>
          {alertBoxContent?.showViewChangesButton ||
          alertBoxContent?.showLearnMoreButton ? (
            <>
              <CustomButton
                boxShadow="none"
                size="sm"
                variant="text"
                color="tertiaryGray"
                style={{ minWidth: "fit-content" }}
                onClick={alertBoxContent?.onClose}
              >
                Dismiss
              </CustomButton>

              <CustomButton
                boxShadow="none"
                size="sm"
                variant="text"
                color="tertiary"
                onClick={alertBoxContent?.onClick}
              >
                {alertBoxContent?.actionText || ""}
              </CustomButton>
            </>
          ) : null}
        </Box>
      </Box>
    </Box>
  );
};

export default AlertBox;
