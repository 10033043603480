import { GetAllCampaigns } from '../Services';

let oldvalue = '';
let timer = null;

export const CampaignsRule = async (item, value, setRerender, setIsLoading) => {
  if (!item.data.searchKey) return;
  if (item.data.searchKey !== 'campaign_name') return;

  if (item.value === '') return;
  if (value === '') return;
  if (timer !== null) clearTimeout(timer);
  if (oldvalue === value) return;
  oldvalue = value;

  timer = setTimeout(async () => {
    const rs = await GetAllCampaigns({
      pageIndex: 1,
      pageSize: 50,
      propertyCampaignName: value || '',
    });
    item.data.enum = [];

    if (!rs || !rs.result) return;

    rs.result.map((campaignItem) => {
      item.data.enum.push(campaignItem);
    });

    setRerender(Math.random());
  }, 500);
};

export const CampaignsDefaultRule = async (
  item,
  setRerender,
  search,
  setIsLoading
) => {
  if (item.data.searchKey !== 'campaign_name') return;
  if (item.data.enum) return;
  if (timer !== null) clearTimeout(timer);

  if (item.data.enum && item.data.enum.length > 0) return;

  item.data.enum = [];
  setIsLoading(true);
  timer = setTimeout(async () => {
    const rs = await GetAllCampaigns({
      pageIndex: 1,
      pageSize: 50,
      propertyCampaignName: search || '',
    });

    if (!rs || !rs.result) return;
    rs.result.map((campaignItem) => {
      item.data.enum.push(campaignItem);
    });
    setRerender(Math.random());
  }, 10);
  setIsLoading(false);
};
