
import { AdminAssignComponent } from '../../Views/Home/AdminAssignView/AdminAssignComponent' ;

export const LeadOwnerAdminAssignAgentRoutes = [
    {
        path: '/view',
        name: 'leadOwnerView:AdminAssign',
        component: AdminAssignComponent,
        layout: '/home/lead-owner-admin-assign-agent',
        default: true,
        isRoute: true,
        authorize: true,
        roles: [],
        isDisabled: false,
        isExact: true,
        breadcrumbs: [
          {
            name: 'leadOwnerView:AdminAssign',
            isDisabled: true,
            route: 'home/lead-owner-admin-assign-agent/view',
            groupName: 'lead-owner',
          },
        ],
    }
  ];
