import { GetAdvanceSearchContacts, PropertiesAdvanceSearchTest, UnitsAdvanceSearchTest } from '../Services';
import { UnitMapper as LeaseUnitMapper } from '../Views/Home/UnitsView/UnitMapper';
import { UnitMapper } from '../Views/Home/UnitsSalesView/UnitSalesMapper/UnitMapper';

import { UnitsOperationTypeEnum } from '../Enums';

let timer = null;

  export const userHasPolicyToDisplayContactData = async (contactId) => {
    {
       const body = {
      criteria: {
        Ids:
        [{ searchType: 1, value: contactId }]
      },
      filterBy: 'createdOn',
      orderBy: 2,
    };
    timer = setTimeout(async () => {
      const res = await GetAdvanceSearchContacts({ pageIndex: 0, pageSize: 150 }, body);
    if (!(res && res.status && res.status !== 200)) {
      if (res && res.totalCount > 0) {
        if (res && res.result && res.result.length && res.result[0].contactJson) {
          const contactJson = JSON.parse(res && res.result && res.result.length && res.result[0] && res.result[0].contactJson);
          return contactJson;
        }
      } else

        return false;
    } else
      return false;
    }, 700);
  }
};

  const userHasPolicyToDisplayPropertiesData = async (propertyId) => {
  {
    const body = {
      criteria: {
        Ids:
        [{ searchType: 1, value: propertyId }]
      },
      filterBy: 'createdOn',
      orderBy: 2,
    };

    const res = await PropertiesAdvanceSearchTest({ pageIndex: 0, pageSize: 150 }, body);
    if (!(res && res.status && res.status !== 200)) {
      if (res && res.totalCount > 0) {
        if (res && res.result && res.result.length && res.result[0].propertyJson) {
          const propertyJson = JSON.parse(res && res.result && res.result.length && res.result[0].propertyJson);
          return propertyJson;
        }
      } else

        return false;
    } else
        return false;
  }
};

  const userHasPolicyToDisplayUnitData = (async (unitId, operationType) => {
    const body = {
      OperationType: (operationType === UnitsOperationTypeEnum.sale.key) || (operationType === UnitsOperationTypeEnum.rentAndSale.key) ? 'Sale' : 'Lease',
      criteria: {
        unitId: [{ searchType: 1, value: unitId }]
      },
      filterBy: 'createdOn',
      orderBy: 2,
    };

    const res = await UnitsAdvanceSearchTest({ pageIndex: 0, pageSize: 150 }, body);
    if (!(res && res.status && res.status !== 200)) {
      if (res && res.totalCount > 0) {
        if (res && res.result && res.result.length && res.result[0] && res.result[0].unitJson) {
          const unitJson = JSON.parse(res && res.result && res.result.length && res.result[0].unitJson);
          const unitDatails = ((operationType === UnitsOperationTypeEnum.sale.key) || (operationType === UnitsOperationTypeEnum.rentAndSale.key)) ? UnitMapper({ ...unitJson, ...res.result[0] }) : LeaseUnitMapper({ ...unitJson, ...res.result[0] });
          localStorage.setItem('unitModelRelatedData', JSON.stringify(unitDatails));
          return unitJson;
        }
      } else

        return false;
    } else

        return false;
  });
