import React from "react";

function ChevronRightDouble({ fill, ...restProps }) {
  return (
    <svg 
      width="24" 
      height="24" 
      viewBox="0 0 24 24"
      fill={fill ?? ""}
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <path
        d="M5.695 6.057a1.04 1.04 0 0 0-.567.459c-.092.156-.108.229-.107.484 0 .212.022.341.074.44.04.077 1.066 1.134 2.279 2.35L9.579 12l-2.205 2.21c-1.213 1.215-2.239 2.273-2.279 2.35-.052.099-.074.228-.074.44-.001.258.015.327.112.492.28.476.9.647 1.359.373.216-.128 5.316-5.24 5.413-5.425.052-.099.074-.228.074-.44.001-.255-.015-.327-.106-.48-.061-.102-1.243-1.313-2.72-2.786-2.526-2.52-2.62-2.609-2.83-2.67-.257-.076-.404-.077-.628-.007m7 0a1.04 1.04 0 0 0-.567.459c-.092.156-.108.229-.107.484 0 .212.022.341.074.44.04.077 1.066 1.134 2.279 2.35L16.579 12l-2.205 2.21c-1.213 1.215-2.239 2.273-2.279 2.35-.052.099-.074.228-.074.44-.001.258.015.327.112.492.28.476.9.647 1.359.373.216-.128 5.316-5.24 5.413-5.425.052-.099.074-.228.074-.44.001-.255-.015-.327-.106-.48-.061-.102-1.243-1.313-2.72-2.786-2.526-2.52-2.62-2.609-2.83-2.67-.257-.076-.404-.077-.628-.007" 
        fill-rule="evenodd" 
        fill={fill ?? ""}
      />
    </svg>
  );
}

export default ChevronRightDouble;
