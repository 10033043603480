import React, { useCallback, useEffect, useState } from 'react';
import { GetAllCampaignAttachments } from '../../../../../../../../Services';
import { getDownloadableLink } from '../../../../../../../../Helper';
import { TableActions } from '../../../../../../../../Enums';
import { useSelector } from 'react-redux';
import { Tables, Spinner } from '../../../../../../../../Components';
import {AttachedFilesHeaderData} from './AttachedFilesHeaderData';
import { MediaPreviewDialog } from '../../../../../../../../Views/Home/TemplatesView/Dialogs';
import './CampaignAttachedFilesView.scss';

export function CampaignAttachedFilesView() {
  const parentTranslationPath = 'Campaign';
  const translationPath = 'campaign-attached-files.';
  const marketingCampaignsInfo = useSelector((state) => state.MarketingCampaignsReducer);

  const [isLoading, setIsLoading] = useState(false);
  const [campaignAttachments, setCampaignAttachments] = useState([]);
  const [isOpenPreviewDialog, setIsOpenPreviewDialog] = useState(false);
  const [activeItem, setActiveItem] = useState(null);
  const [filter, setFilter] = useState({
    pageIndex: 0,
    pageSize: 25,
  });

  const getTableActions = () => {
    const actions = [
      {
        enum: TableActions.openFile.key,
        isDisabled: false,
        externalComponent: null,
      },
      {
        enum: TableActions.downloadText.key,
        isDisabled: false,
        externalComponent: null,
      }
    ];
    return actions;
  };

  const tableActionClicked = useCallback((actionEnum, item) => {
    const mappedFileItem = {
      ...item,
      templateFileId: item?.fileId,
      templateFileName: item?.fileName,
    }
    setActiveItem(mappedFileItem);

    if (actionEnum === TableActions.openFile.key) setIsOpenPreviewDialog(true);
    else if (actionEnum === TableActions.downloadText.key) {
      try {
        const link = document.createElement('a');
        link.setAttribute('download', item.fileName);
        link.href = getDownloadableLink(item.fileId);
        document.body.appendChild(link);
        link.click();
        link.remove();
        setActiveItem(null);
      } catch (error) { }
    }
  }, []);

  const getAllCampaignAttachments = useCallback(async () => {
    setIsLoading(true);

    const res = await GetAllCampaignAttachments({
      campaignId: marketingCampaignsInfo?.campaignRequestId,
    });
    if (!(res && res.status && res.status !== 200)) {
      setCampaignAttachments(res || []);
    } else setCampaignAttachments([]);

    setIsLoading(false);
  }, [marketingCampaignsInfo?.campaignRequestId]);

  useEffect(() => {
    getAllCampaignAttachments();
  }, []);
  return (
    <div className='CampaignAttachedFilesView'>
      <Spinner isActive={isLoading} isAbsolute />
      <div className='my-4 p-2 w-100'>
        <Tables
          data={campaignAttachments}
          headerData={AttachedFilesHeaderData}
          actionsOptions={{
            onActionClicked: tableActionClicked,
          }}
          activePage={filter.pageIndex}
          defaultActions={getTableActions()}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
        />
      </div>
      {isOpenPreviewDialog && (
        <MediaPreviewDialog
          activeItem={activeItem}
          maintitleText='FILE-VIEW'
          isOpen={isOpenPreviewDialog}
          isOpenChanged={() => {
            setIsOpenPreviewDialog(false);
            setActiveItem(null);
          }}
          parentTranslationPath='Shared'
          translationPath={''}
        />
      )}
    </div>
  );
}
