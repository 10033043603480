import React from "react";

const WhatsappIcon = ({ fill, ...restProps }) => {
  return (
    <svg
      viewBox="0 0 21 20"
      fill={fill ?? ""}
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.5 18.75C15.3325 18.75 19.25 14.8325 19.25 10C19.25 5.16751 15.3325 1.25 10.5 1.25C5.66751 1.25 1.75 5.16751 1.75 10C1.75 11.5693 2.16312 13.0421 2.88655 14.3156L1.75 18.75L6.32179 17.6899C7.56336 18.3659 8.98682 18.75 10.5 18.75ZM10.5 17.4038C14.589 17.4038 17.9038 14.089 17.9038 10C17.9038 5.91097 14.589 2.59615 10.5 2.59615C6.41097 2.59615 3.09615 5.91097 3.09615 10C3.09615 11.5788 3.59031 13.0422 4.4324 14.2439L3.76923 16.7308L6.29996 16.0981C7.49335 16.9216 8.94036 17.4038 10.5 17.4038Z"
        fill={fill ?? ""}
      />
      <path
        d="M8.31251 5.93749C8.10447 5.51963 7.78533 5.55662 7.46292 5.55662C6.88673 5.55662 5.98828 6.2468 5.98828 7.53128C5.98828 8.58399 6.45216 9.73633 8.01526 11.4601C9.52378 13.1237 11.5059 13.9843 13.1514 13.955C14.7969 13.9257 15.1354 12.5097 15.1354 12.0315C15.1354 11.8196 15.0039 11.7138 14.9133 11.6851C14.3525 11.416 13.3183 10.9145 13.083 10.8203C12.8477 10.7261 12.7248 10.8535 12.6484 10.9229C12.435 11.1262 12.012 11.7255 11.8672 11.8604C11.7224 11.9952 11.5064 11.9269 11.4166 11.876C11.0859 11.7433 10.1893 11.3445 9.47467 10.6517C8.59083 9.79492 8.53896 9.50015 8.37244 9.23775C8.23922 9.02784 8.33698 8.89905 8.38576 8.84276C8.57619 8.62304 8.83913 8.2838 8.95705 8.11523C9.07496 7.94665 8.98135 7.6907 8.92518 7.53128C8.68361 6.84569 8.47895 6.27178 8.31251 5.93749Z"
        fill={fill ?? ""}
      />
    </svg>
  );
};

export default WhatsappIcon;
