



import React , { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { CheckboxesComponent , TabsComponent } from '../../../../../../../Components' ; 
import {  LeadsPoliciesTab  } from './LeadsPoliciesTab' ;


export const LeadsPolicies  = ({
  parentTranslationPath,
  translationPath,
   state,
   setState , 
   checkAtLeastOneSelected , 
   checkAtLeastOneSelected2 , 
   setDefaultSelected , 

}) => {
    const { t } = useTranslation(parentTranslationPath);
    const [activeTab, setActiveTab] = useState(0);

    const onTabChanged = (e, newTap) => {
      setActiveTab(newTap);
    }
    

    return (
           <div className='childs-wrapper b-0 part'>
           <TabsComponent
            data={LeadsPoliciesTab}
            labelInput='label'
            parentTranslationPath={parentTranslationPath}
            translationPath={translationPath}
            themeClasses='theme-curved'
            dynamicComponentProps={{
            parentTranslationPath,
            translationPath,
            state,
            setState , 
            checkAtLeastOneSelected ,
            checkAtLeastOneSelected2 , 
            setDefaultSelected ,  
          }}
          currentTab={activeTab}
          onTabChanged={onTabChanged}
        />  
        <div className='w-100 px-2 mt-2'>
           <CheckboxesComponent
            idRef='leads_view-sensitive-dataRef'
            label={t(`${translationPath}view-sensitive-data`)}
            singleChecked={state.leads.viewSensitive}
            parentTranslationPath={parentTranslationPath}
            translationPathForData={translationPath}
            onSelectedCheckboxClicked={()=> {
            setState({id : 'leads' , value : { ...state.leads , viewSensitive : !(state.leads.viewSensitive)} })
          }}
         />
      </div>  
      </div>
    );
};

LeadsPolicies.propTypes = {
  parentTranslationPath: PropTypes.string.isRequired,
  translationPath: PropTypes.string.isRequired,
  state:PropTypes.instanceOf(Object).isRequired,
  setState : PropTypes.func.isRequired,
  checkAtLeastOneSelected : PropTypes.func.isRequired, 
  checkAtLeastOneSelected2 :PropTypes.func.isRequired, 
  setDefaultSelected:PropTypes.func.isRequired, 
};

