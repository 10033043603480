import React from 'react';
import { DialogComponent } from '../../../../../../Components';
import { useTranslation } from 'react-i18next';

export const DeleteDialog = ({
  onCancelClicked,
  onCloseClicked,
  isDeleteDialogOpen,
  translationPath,
  parentTranslationPath,
  onConfirmDelete
}) => {
  const { t } = useTranslation(parentTranslationPath);

  return (
    <DialogComponent
      titleText='confirm-message'
      saveText='confirm'
      saveType='button'
      maxWidth='sm'
      dialogContent={
        <div className='d-flex-column-center'>
          <span className='mdi mdi-close-octagon c-danger mdi-48px' />
          <span>{`${t(`${translationPath}delete-confirm-message`)}`}</span>
        </div>
      }
      saveClasses='btns theme-solid bg-danger w-100 mx-2 mb-2'
      isOpen={isDeleteDialogOpen}
      onCancelClicked={onCancelClicked}
      onCloseClicked={onCloseClicked}
      onSaveClicked={onConfirmDelete}
      parentTranslationPath={parentTranslationPath}
      translationPath={translationPath}
    />
  );
};
