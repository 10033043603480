import {
  getUnits, GetPropertyFixturesAndAmenities, propertyDetailsGet, GetAllUnitsForLeadOwner
} from '../Services';
import { GetParams } from '../Helper';
import { LeadTypeIdEnum, UnitsOperationTypeEnum } from '../Enums';

let oldvalue = '';
let timer = null;

export async function UnitRule(item, value, setNewValue, itemList, values) {
  if (oldvalue === value) return;
  oldvalue = value;

  if (item.value === '') return;
  if (value === '') return;
  if (timer !== null) clearTimeout(timer);

  if (!item.data.searchKey) return;
  if (item.data.searchKey !== 'unit') return;
  if (value && value.length < 1) return;

  const leadOperationIndex = itemList && itemList.indexOf(itemList.find((f) => f.field.id === 'lead_operation'));
  const propertyNameIndex = itemList && itemList.indexOf(itemList.find((f) => f.field.id === 'property_name'));

  const leadOperationType =
    itemList && itemList.indexOf(itemList.find((f) => f.field.id === 'operation_type'));
  const operationType = values && values.operation_type ? (values.operation_type.lookupItemName === 'Buy' ? UnitsOperationTypeEnum.sale.key : UnitsOperationTypeEnum.rent.key) : null;

  const isUnitRefNumberForOwnerLead = (item.field.id === 'unit_ref_number' || item.field.id === 'unit_ref-number') && leadOperationIndex !== -1;

  const isUnitRefNumberForSekeerLead = ((item.field.id === 'unit_ref_number') || item.field.id === 'unit_ref-number') && propertyNameIndex !== -1;

  const unitType = !!(values && values.lead_operation === 'Seller');

  let rs = null;
  timer = setTimeout(async () => {
    if (isUnitRefNumberForOwnerLead) {
      rs = await GetAllUnitsForLeadOwner({
        pageIndex: 0, pageSize: 10, isSaleUnitType: unitType, search: value , propertyId :(values && values.property_name && values.property_name.id )
      });
    } else if (isUnitRefNumberForSekeerLead) {
      rs = values && values.operation_type ? await getUnits({
        pageIndex: 0, pageSize: 10, search: value, operationType, propertyId: (values.property_name && values.property_name.id)
      }) : null;
    } else
      rs = await getUnits({ pageIndex: 0, pageSize: 10, search: value });

    item.data.enum = [];
    if (!rs) return;
    if (!rs && !rs.result) return;
    rs && rs.result && rs.result.map((valueLoop) => {
      item.data.enum.push({
        id: valueLoop.unitId,
        name: (valueLoop && valueLoop.unit && valueLoop.unit.unit_ref_no) || (valueLoop && valueLoop.unitRefNo),
        type: (valueLoop && valueLoop.unit && valueLoop.unit.unit_type_id) || 1,
        operationType: (valueLoop && valueLoop.unit && valueLoop.unit.operation_type && valueLoop.unit.operation_type.lookupItemName) || (valueLoop && valueLoop.operationType),
        propertyId: valueLoop && valueLoop.unit && valueLoop.unit.property_name && valueLoop.unit.property_name.id,
        saleType: (valueLoop && valueLoop.unit && valueLoop.unit.sale_type) || null,

      });
    });
    setNewValue(Math.random());
  }, 500);
}

export async function UnitDefaultRule(item, setNewValue, itemList, values) {
  if (item.field.id === 'unit_ref-number' && +GetParams('formType') === LeadTypeIdEnum.Owner.leadTypeId)
    return;

  if (item.data.searchKey !== 'unit') return;
  if (item.data.enum) return;
  const isUnitRefNumberForOwnerLead = ((item.field.id === 'unit_ref-number') && (item.field.FieldType === 'searchField') && (item.field.Required === 'true'));
  const operationType = values && values.operation_type ? (values.operation_type.lookupItemName === 'Buy' ? UnitsOperationTypeEnum.sale.key : UnitsOperationTypeEnum.rent.key) : null;
  const rs = !isUnitRefNumberForOwnerLead ? await getUnits({
    pageIndex: 0, pageSize: 10, operationType, propertyId: (values && values.property_name && values.property_name.id)
  }) :
    await getUnits({
      pageIndex: 0, pageSize: 10, excludeLeadOwnerInUnits: true
    });

  item.data.enum = [];
  if (!rs || !rs.result) return;
  rs.result.map((value) => {
    item.data.enum.push({
      id: value.unitId,
      name: value.unit.unit_ref_no,
      type: value.unit.unit_type_id,
      operationType: value && value.unit && value.unit.operation_type && value.unit.operation_type.lookupItemName,
      propertyId: value && value.unit && value.unit.property_name && value.unit.property_name.id,

    });
  });

  setNewValue(Math.random());
}

export async function OnchangePropertyInUnitRule(
  item,
  v,
  itemList,
  setData,
  setRerender,
) {
  if (item && item.data && item.data.searchKey === 'property') {
    const key = 'amenities';
    const i1amenitiesIndex = itemList.findIndex((f) => f.field.id.toLowerCase() === key);
    const res = i1amenitiesIndex !== -1 && v ? await GetPropertyFixturesAndAmenities(v && v.id) : null;

    if (res && res.amenities !== null && res.amenities !== undefined)
      setData(itemList[i1amenitiesIndex].field.id, res.amenities);

    setData('unit_model', null);
  }
}
export async function OnchangePropertyInUnitRuleV2(
  item,
  v,
  setData
) {
  if (item && item.data && item.data.searchKey === 'property') {
    const key = 'amenities';
    const res = v && v.id && await GetPropertyFixturesAndAmenities(v.id);

    if (res && res.amenities)
      setData([...res.amenities], 0, key);
    setData(null, 0, 'unit_model');
  }
}

export async function OnchangePropertyOnLeadRule(
  item,
  v,
  itemList,
  setData,
  values
) {
  if (item && item.data && item.data.searchKey === 'property') {
  
    const operationType = window.location.pathname.includes('sales') && +GetParams('formType') === LeadTypeIdEnum.Seeker.leadTypeId ? UnitsOperationTypeEnum.sale.key : UnitsOperationTypeEnum.rent.key;
    let  unitType = values && (values.operation_type) ? (values.operation_type.lookupItemName === 'Buy' ? UnitsOperationTypeEnum.sale.key : UnitsOperationTypeEnum.rent.key) : null;
    if(item && item.data && item.data.DtoName === 'property_owner_lead')
    unitType= values  && values.lead_operation  ?  (values.lead_operation === 'Seller' && true ) ||(values.lead_operation === 'Landlord' && (false))  : null ; 


    const oprationTypeIndex = itemList.findIndex((f) => f.field.id.toLowerCase() === 'operation_type');
    const countryIndex = itemList.findIndex((f) => f.field.id.toLowerCase() === 'country');
    const cityIndex = itemList.findIndex((f) => f.field.id.toLowerCase() === 'city');
    const districtIndex = itemList.findIndex((f) => f.field.id.toLowerCase() === 'district');
    const communityIndex = itemList.findIndex((f) => f.field.id.toLowerCase() === 'community');
    const subcommunityIndex = itemList.findIndex((f) => f.field.id.toLowerCase() === 'sub_community');
    const unitRefNumberIndex = itemList.findIndex((f) => f.field.id.toLowerCase() === 'unit_ref-number');
    if (!v) {
      if (countryIndex !== -1)
        itemList[countryIndex].data.isReadonly = false;

      if (cityIndex !== -1)
        itemList[cityIndex].data.isReadonly = false;

      if (districtIndex !== -1)
        itemList[districtIndex].data.isReadonly = false;

      if (communityIndex !== -1)
        itemList[communityIndex].data.isReadonly = false;

      if (subcommunityIndex !== -1)
        itemList[subcommunityIndex].data.isReadonly = false;

      if (unitRefNumberIndex !== -1) {
        setData(itemList[unitRefNumberIndex].field.id, {});
        setData('unit_ref_number', {});
        itemList[unitRefNumberIndex].data.enum = [];
      }
      return;
    }
    itemList[unitRefNumberIndex].data.enum = [];

    const res = v.id && await propertyDetailsGet({ id: v.id });
    if (res) {
      if (unitRefNumberIndex !== -1 &&   item.data.DtoName === 'property_seeker_lead') {
        setData('unit_ref-number', null);
        setData('unit_ref_number', {});
      }
      if (subcommunityIndex !== -1) {
        itemList[subcommunityIndex].data.isReadonly = true;
        setData(itemList[subcommunityIndex].field.id, (res.property && res.property.sub_community  ) || {});
        
      }
      if (communityIndex !== -1) {
        itemList[communityIndex].data.isReadonly = true;
        setData(itemList[communityIndex].field.id, (res.property && res.property.community) || {});
        
      }
      
      if (districtIndex !== -1) {
        itemList[districtIndex].data.isReadonly = true;
        setData(itemList[districtIndex].field.id, (res.property && res.property.district) || {});
      }
      if (cityIndex !== -1) {
        itemList[cityIndex].data.isReadonly = true;
        setData(itemList[cityIndex].field.id, (res.property && res.property.city) || {});
      }

      if (countryIndex !== -1) {
        itemList[countryIndex].data.isReadonly = true;
        setData(itemList[countryIndex].field.id, (res.property && res.property.country) || {});
        setData('country', (res.property && res.property.country) || {});
      }
    }
  
    const propertyUnits =  itemList[unitRefNumberIndex].data.DtoName === 'unit_ref_number_sekeer_lead' ? await getUnits({
      pageIndex: 0,
      pageSize: 10,
      operationType: unitType,
      propertyId: v && v.id
    }) : ( itemList[unitRefNumberIndex].data.DtoName === 'unit_ref_number_owner_lead' ? await GetAllUnitsForLeadOwner({
      pageIndex: 0, pageSize: 10, isSaleUnitType: (unitType) ,  propertyId :(values && values.property_name && values.property_name.id )}): null);

    if (!(propertyUnits && propertyUnits.result)) return ; 
    propertyUnits &&  propertyUnits.result.map((valueLoop) => {
      itemList[unitRefNumberIndex].data.enum.push({
        id: valueLoop.unitId,
        name: (valueLoop && valueLoop.unit && valueLoop.unit.unit_ref_no) || (valueLoop && valueLoop.unitRefNo),
        type: (valueLoop && valueLoop.unit && valueLoop.unit.unit_type_id) || 1,
        operationType: (valueLoop && valueLoop.unit && valueLoop.unit.operation_type && valueLoop.unit.operation_type.lookupItemName) || (valueLoop && valueLoop.operationType),
        propertyId: valueLoop && valueLoop.unit && valueLoop.unit.property_name && valueLoop.unit.property_name.id,

      });
    });
  }
}

export async function OnChangePropertyOnLeadRule2(
  item,
  v,
  itemList,
  setData,
  setNewValue,
  values

) {
  
  if (item && item.data && item.data.searchKey === 'property') {
    const countryIndex = itemList.findIndex((f) => f.field.id.toLowerCase() === 'country');
    const cityIndex = itemList.findIndex((f) => f.field.id.toLowerCase() === 'city');
    const districtIndex = itemList.findIndex((f) => f.field.id.toLowerCase() === 'district');
    const communityIndex = itemList.findIndex((f) => f.field.id.toLowerCase() === 'community');
    const subCommunityIndex = itemList.findIndex((f) => f.field.id.toLowerCase() === 'sub_community');
    const unitRefNumberIndex = itemList.findIndex((f) => f.field.id.toLowerCase() === 'unit_ref_number');

    if (!v) {
      if (countryIndex !== -1)
        itemList[countryIndex].data.isReadonly = false;

      if (cityIndex !== -1)
        itemList[cityIndex].data.isReadonly = false;

      if (districtIndex !== -1)
        itemList[districtIndex].data.isReadonly = false;

      if (communityIndex !== -1)
        itemList[communityIndex].data.isReadonly = false;

      if (subCommunityIndex !== -1)
        itemList[subCommunityIndex].data.isReadonly = false;

      if (unitRefNumberIndex !== -1 && itemList[unitRefNumberIndex].data.DtoName === 'unit_ref_number_seeker_lead') {
        setData(null, 0, 'unit_ref_number');
        itemList[unitRefNumberIndex].data.enum = [];
      }
      return;
    }

    const res = v && v.id ? await propertyDetailsGet({ id: v.id }) : null;
    let  unitType = values && (values.operation_type) ? (values.operation_type.lookupItemName === 'Buy' ? UnitsOperationTypeEnum.sale.key : UnitsOperationTypeEnum.rent.key) : null;

    if (res) {
      if (countryIndex !== -1) {
        itemList[countryIndex].data.isReadonly = true;
        setData((res.property && res.property.country), 0, 'country');
      }

      if (cityIndex !== -1) {
        itemList[cityIndex].data.isReadonly = true;
        setData((res.property && res.property.city), 0, 'city');
      }

      if (districtIndex !== -1) {
        itemList[districtIndex].data.isReadonly = true;
        setData((res.property && res.property.district), 0, 'district');
      }

      if (communityIndex !== -1) {
        itemList[communityIndex].data.isReadonly = true;
        setData((res.property && res.property.community), 0, 'community');
      }

      if (subCommunityIndex !== -1) {
        itemList[subCommunityIndex].data.isReadonly = true;
        setData((res.property && res.property.sub_community), 0, 'sub_community');
      }
      if (unitRefNumberIndex !== -1 && itemList[unitRefNumberIndex].data.DtoName === 'unit_ref_number_seeker_lead')
        setData(null, 0, 'unit_ref_number');
    }
    if(itemList[unitRefNumberIndex].data.DtoName === 'unit_ref_number_owner_lead')
        unitType= values  && values.lead_operation  ?  (values.lead_operation === 'Seller' && (true)) ||(values.lead_operation === 'Landlord' && (false))  : null ; 

      let  propertyUnits = null ;
      

     
      propertyUnits = ( item && item.data && item.data.DtoName === 'property_seeker_lead')  ? await getUnits({
        pageIndex: 0, pageSize: 10, operationType: unitType, propertyId: v && v.id
      }) :await GetAllUnitsForLeadOwner({
        pageIndex: 0, pageSize: 10, isSaleUnitType: (unitType) , propertyId :(values && values.property_name && values.property_name.id )});
    

    itemList[unitRefNumberIndex].data.enum = [];

    propertyUnits  && propertyUnits.result && propertyUnits.result.map((valueLoop) => {
      itemList[unitRefNumberIndex].data.enum.push({
        id: valueLoop.unitId,
        name: (valueLoop && valueLoop.unit && valueLoop.unit.unit_ref_no) || (valueLoop && valueLoop.unitRefNo),
        type: (valueLoop && valueLoop.unit && valueLoop.unit.unit_type_id) || 1,
        operationType: (valueLoop && valueLoop.unit && valueLoop.unit.operation_type && valueLoop.unit.operation_type.lookupItemName) || (valueLoop && valueLoop.operationType),
        propertyId: valueLoop && valueLoop.unit && valueLoop.unit.property_name && valueLoop.unit.property_name.id,

      });
    });
  }
  setNewValue(Math.random());
}

export async function OnAddnewUnitRule(
  itemList,
  setData,
  itemsDialogValue,
) {
  //  This is function When User Add New Unit Ref Number //
  if (itemsDialogValue && itemsDialogValue.unitId !== null) {
    const fieldkey = 'unit_ref-number';
    const i1Index = itemList.findIndex((f) => f.field.id.toLowerCase() === fieldkey);
    if (i1Index && itemsDialogValue.unitId) {
      const FinalJSON = JSON.parse(itemsDialogValue.unitJson);
      setData(itemList[i1Index].field.id, {
        id: itemsDialogValue && itemsDialogValue.unitId,
        name: FinalJSON && FinalJSON.unit && FinalJSON.unit.unit_ref_no,
        type: FinalJSON && FinalJSON.unit && FinalJSON.unit.unit_type_id,
        operationType: FinalJSON.unit.operation_type && FinalJSON.unit.operation_type.lookupItemName,

      });
      setData('unit_ref_number', {
        id: itemsDialogValue && itemsDialogValue.unitId,
        name: FinalJSON && FinalJSON.unit && FinalJSON.unit.unit_ref_no,
        type: FinalJSON && FinalJSON.unit && FinalJSON.unit.unit_type_id,
        operationType: FinalJSON && FinalJSON.unit && FinalJSON.unit.operation_type && FinalJSON.unit.operation_type.lookupItemName,

      });
    }
  }
}

// export async function OnchangePropertyInUnitRuleV2(id, setData, item, itemList, value) {
//   if (value === null || value === undefined) return;
//   const key = 'amenities';
//   const res = await GetPropertyFixturesAndAmenities((value && value.id) || 0);
//   if (id === key) {
//     const i1amenitiesIndex = itemList.indexOf(
//         itemList.find((f) => f.field.id.toLowerCase() === item.data.hasEffectOn.toLowerCase())
//     );
//     if (res && res && res.amenities !== null && res.amenities !== undefined)
//       setData({ amenities: [...res.amenities] });
//   }
// }

export async function UnitForLeadOwnerRule(item, value, setNewValue, itemList, values, setData , propertyId) {
  const unitRefNumberIndex = itemList.indexOf(itemList.find((f) => f.field.id === 'unit_ref-number'));
  const saleTypeIndex = itemList.indexOf(itemList.find((f) => f.field.id === 'sale_type'));
  itemList[unitRefNumberIndex].data.enum = [];
  setData(itemList[unitRefNumberIndex].field.id, {});
  setData('unit_ref_number', {});
  if (!value)
    return;

  timer = setTimeout(async () => {
    itemList[unitRefNumberIndex].data.isReadonly = false;
    const unitType = !!(values && values.lead_operation && values.lead_operation === 'Seller');
    if (saleTypeIndex !== -1 && (value !== 'Seller' || !value))
      setData('sale_type', {});

    const unitsLeadOwner = await GetAllUnitsForLeadOwner({ pageIndex: 0, pageSize: 10, isSaleUnitType: unitType , propertyId :(values && values.property_name && values.property_name.id ) });
    if (unitsLeadOwner && unitsLeadOwner.result && unitRefNumberIndex !== -1) {
      unitsLeadOwner.result.map((value) => {
        itemList[unitRefNumberIndex].data.enum.push({
          id: value.unitId,
          name: value.unit.unit_ref_no,
          type: value.unit.unit_type_id,
          propertyId: value && value.unit && value.unit.property_name && value.unit.property_name.id,
          operationType: value && value.unit && value.unit.operation_type && value.unit.operation_type.lookupItemName,
          saleType: (value && value.unit && value.unit.sale_type) || null,
        });
      });
    }
  }, 300);
}

export async function SaleTypDependingOnRule(item, value, itemList, setNewValue) {
  const unitSaleTypeIndex = itemList.indexOf(itemList.find((f) => f.field.id === 'sale_type'));
  timer = setTimeout(async () => {
    if (unitSaleTypeIndex !== -1) {
      if (value && (value.lookupItemName === 'SaleAndRent' || value.lookupItemName === 'Sale')) {
        itemList[unitSaleTypeIndex].field.Required = 'true';
        itemList[unitSaleTypeIndex].data.title = 'Sale Type *';
      } else if (unitSaleTypeIndex !== -1) {
        itemList[unitSaleTypeIndex].field.Required = 'false';
        itemList[unitSaleTypeIndex].data.title = 'Sale Type';
      }
      setNewValue(Math.random());
    }
  }, 300);
}
