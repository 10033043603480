import React from "react";

function CheckIcon({ fill, ...restProps }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill={fill ?? ""}
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <path
        d="M19.64 5.068c-.157.053-.847.727-5.41 5.286l-5.229 5.224-2.231-2.225c-2.148-2.144-2.238-2.228-2.447-2.289-.266-.078-.378-.08-.613-.009a1.02 1.02 0 0 0-.582.461c-.091.156-.108.23-.108.484s.016.328.107.48c.135.226 5.241 5.325 5.433 5.425.099.052.228.074.44.074.255.001.327-.015.48-.106.228-.136 11.325-11.24 11.425-11.433.052-.099.074-.228.074-.44.001-.255-.015-.328-.107-.484a1.016 1.016 0 0 0-1.232-.448"
        fill-rule="evenodd"
        fill={fill ?? ""}
      />
    </svg>
  );
}

export default CheckIcon;
