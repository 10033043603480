import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => {
  return {
    rounded: {
      borderRadius: "20px",
    },
    flexRow: {
      display: "flex",
      gap: "6px",
      alignItems: "center",
      margin: "4px 0",
    },
    ContactCardWrapper: {
      alignItems: "center",
      background: theme.palette.background.paper,
      border: `1px solid ${theme.palette.border.secondary}`,
      borderRadius: "12px",
      display: "flex",
      flex: "1 0 0",
      flexDirection: "column",
      maxWidth: "339px",
      minWidth: "339px",
      padding: "20px 16px",
      width: "339px",
    },
    details: {
      alignItems: "center",
      display: "flex",
      gap: "10px",
      justifyContent: "center",
    },
    detailsWrapper: {
      display: "flex",
      flexDirection: "column",
      gap: "9px",
    },

    avatar: {
      borderRadius: "50%",
      alignSelf: "center",
      border: `1px solid ${theme.palette.border.secondary}`,
      height: "72px",
      width: "72px",
    },
    idSection: {
      alignItems: "center",
      display: "flex",
      gap: "8px",
      justifyContent: "center",
    },

    cardHeader: {
      display: "flex",
      justifyContent: "space-between",
      width: "100%",
      marginBottom: "18px",
    },
    divider: {
      width: "100%",
      borderBottom: `1px solid ${theme.palette.background.secondary}`,
    },
    nameContainer: {
      alignItems: "center",
      display: "flex",
      fontSize: "20px",
      gap: "6px",
    },
    dateContainer: {
      display: "flex",
      gap: "12px",
    },

    dateText: {
      display: "flex",
      gap: "8px",
    },
  };
});
