import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => {
  const { foreground, border, text, background } = theme.palette;

  return {
    boxContainer: {
      display: "flex",
      gap: "20px",
    },
    notificationsBadge: {
      "& .MuiBadge-badge": {
        width: "12px",
        height: "12px",
        minWidth: "10px !important",
        backgroundColor: "#EA5455",
        margin: "11px",
      },
    },
    bottomContainer: {
      borderTop: `1px solid ${border.secondary}`,
      backgroundColor: background.primary,
      alignItems: "center",
      display: "flex",
      bottom: "0",
      position: "absolute",
      padding: "12px 24px",
      width: "100%",
      height: "80px",
      zIndex: 3,
      justifyContent: "space-between",
      [theme.breakpoints.up('md')]: {
        display: "none",
      }
    },
    buttonWrapper: {
      display: "flex",
      flexDirection: "column",
      gap: "8px",
      alignItems: "center",
      justifyContent: "center",
    },
    text: {
      fontWeight: 400,
      fontSize: "14px",
      color: text.primary,

      "&.active": {
        fontWeight: 600,
      },
    },
    navigationIcon: {
      fontSize: "20px",
      color: foreground.navigation["nav-item-button-icon-fg"],
      padding: "8px",
      boxSizing: "content-box",

      "&.active": {
        color: foreground.secondary,
        borderRadius: "6px",
        backgroundColor: background.primary,
        boxShadow: "0px 0px 0px 4px rgba(152, 162, 179, 0.14)",
      },
    },
    addButton: {
      borderRadius: "50%",
      backgroundColor: background.secondary,
      display: "flex",
      width: "50px",
      height: "50px",
      padding: "8px",
      justifyContent: "center",
      alignItems: "center",
      gap: "8px",
    },
    activeText: {
      fontWeight: 500,
    },
  };
});
