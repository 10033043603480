 export const WhatsAppMessage = (ClientPhone) => {
    const user = JSON.parse(localStorage.getItem('session'));

    if (!ClientPhone)
      return null;

    const message = `Hi ${user.fullName} From Property Shop Investment. ${user.phoneNumber} ${user.email}`;
    const encodedMessage = encodeURIComponent(message);
    const whatsappLink = `https://api.whatsapp.com/send/?phone=${ClientPhone}&text=${encodedMessage}&app_absent=0`;

    return whatsappLink;
  };
// Refactoring Done 2023
