import React, { useCallback, useEffect, useReducer, useRef, useState } from 'react'
import { DatePickerComponent, Inputs, PaginationComponent, Tables } from '../../../../../../Components'
import { ListProposalLogs } from '../../../../../../Services/ListProposalLogsService'
import { GetParams, bottomBoxComponentUpdate,getPublicDownloadableLink  } from '../../../../../../Helper'
import { Spinner } from '../../../../../../Components'
import { useTranslation } from 'react-i18next'
import { TableActions } from '../../../../../../Enums'
import { ProposalHistoryViewDetailsDialog } from './ProposalHistoryUtilities'
import { OrganizationUserSearch } from '../../../../../../Services'
import moment from 'moment'
import { UnitsOperationTypeEnum } from '../../../../../../Enums'
import { AgentRoleEnum } from '../../../../../../Enums'
import { AutocompleteComponent } from '../../../../../../Components'
import { ButtonBase } from '@material-ui/core'

export const UnitProfileProposalHistoryComponent = ({
  parentTranslationPath,
  translationPath,
}) => {
  const { t } = useTranslation(parentTranslationPath);
  const searchTimer = useRef(null);
  const [loading, setLoading] = useState(false)
  const [proposalLogs, setProposalLogs] = useState([])
  const [viewDetailsProposal, setViewDetailsProposal] = useState(false)
  const [activeitem, setActiveitem] = useState(false)
  const unitId = GetParams("id")
  const operationType = +GetParams('operationType')
  const reducer = useCallback((state, action) => {
    if (action.id !== "edit") return { ...state, [action.id]: action.value };
    return {
      ...action.value,
    };
  }, []);

  const [isLoading, setIsLoading] = useReducer(reducer, {
    proposalName: false,
    date: false,
    lead: false,
    agent: false,
  });
  const [selected, setSelected] = useReducer(reducer, {
    proposalName: null,
    date: null,
    lead: null,
    agent: null
  });
  const [data, setData] = useReducer(reducer, {
    proposalName: [],
    date: [],
    lead: [],
    agent: [],
  });
  const [filter, setFilter] = useState({
    proposalName: null,
    templateName: null,
    date: null,
    lead: null,
    agent: null,
    pageIndex: 1,
    pageSize: 25,
  });

  const onPageIndexChanged = (pageIndex) => {
    setFilter((item) => ({ ...item, pageIndex }));
  };
  const onPageSizeChanged = (pageSize) => {
    setFilter((item) => ({ ...item, pageIndex: 0, pageSize }));
  };

  const tableActionClicked = useCallback((actionEnum, item) => {

    if (actionEnum === TableActions.downloadText.key) {
      try {
        const link = document.createElement('a');
        link.setAttribute('download', item.fileName);
        link.href = getPublicDownloadableLink(item.fileId ,item.sourceSystemUrl);
        document.body.appendChild(link);
        link.click();
        link.remove();
      } catch (error) {
        console.error("Error downloading file:", error);
      }
    } else if (actionEnum === TableActions.view.key) {
        setViewDetailsProposal(true);
        setActiveitem(item);
    }
  })
  const getTableActions = () => {
    const list = [];
    list.push({
      enum: TableActions.view.key,
      isDisabled: false,
      externalComponent: null,
    })
    list.push({
      enum: TableActions.downloadText.key,
      isDisabled: false,
      externalComponent: null,
    })
    return list
  }
  const focusedRowChanged = useCallback(() => { }, []);

  const listProposalLogs = useCallback(async (value) => {
    setLoading(true)
    const body = {
      ...filter,
      unitId
    }
    const res = await ListProposalLogs({ body })
    if (!(res && res.status && res.status !== 200)) {
      setProposalLogs(res)
    } else {
      setProposalLogs([])
    }
    setLoading(false)
  })

  const getAgent = useCallback(async (value) => {
    setIsLoading({ id: 'agent', value: true })
    let userTypeId;
    if (operationType === UnitsOperationTypeEnum.sale.key)
      userTypeId = AgentRoleEnum.SaleAgent.key
    else if (operationType === UnitsOperationTypeEnum.rent.key)
      userTypeId = AgentRoleEnum.LeaseAgent.key

    const body = {
      pageIndex: 0,
      pageSize: 2,
      userStatusId: 2,
      userTypeId: userTypeId,
      name: value || "",
    }
    const res = await OrganizationUserSearch({ body })
    if (!(res && res.status && res.status !== 200)) {
      setData({ id: 'agent', value: res.result });
    } else {
      setData({ id: 'agent', value: [] });
    }
    setIsLoading({ id: 'agent', value: false })
  })
  const onClearedAllFiltersClick = useCallback(() => {
    setSelected({
      id: "edit",
      value: {
        proposalName: null,
        date: null,
        lead: null,
        agent: null,
      },
    });
    setFilter({
      id: "edit",
      value: {
        agent: null,
        lead: null,
        proposalName: null,
      },
    })
  })

  useEffect(() => {
    listProposalLogs()
  }, [filter]);

  useEffect(() => {
    getAgent()
  }, []);

  useEffect(() => {
    bottomBoxComponentUpdate(
      <PaginationComponent
        pageIndex={filter.pageIndex}
        pageSize={filter.pageSize}
        totalCount={proposalLogs.totalCount || 0}
        onPageIndexChanged={onPageIndexChanged}
        onPageSizeChanged={onPageSizeChanged}
      />
    );
  });
  return (
    <>
      <Spinner isAbsolute isActive={loading} />
      <div className='mx-4 my-3'>
        <span className='c-primary my-3' style={{ fontSize: 'large' }}>{t(`Proposal-Tracking`)}</span>
        <div className='flex-v-end d-flex-column mb-5 containerSearchFileds'>
          <div className='d-flex-default pb-0 '>
            <div className='w-100 ml-2'>
              <DatePickerComponent
                idRef='activityDateRef'
                placeholder='DD/MM/YYYY'
                labelValue={t(`${translationPath}searchDate`)}
                value={
                  (selected.date && moment(selected.date).isValid() && moment(selected.date)) ||
                  null
                }
                onDateChanged={(newValue) => {
                  setFilter((item) => ({ ...item, date: (newValue && moment(newValue).format('YYYY-MM-DDTHH:mm:ss')) || null }));
                  setSelected({
                    id: 'date',
                    value: (newValue && moment(newValue).format('YYYY-MM-DDTHH:mm:ss')) || null,
                  });

                }}
              />
            </div>
            <div className='w-100 ml-2'>
              <Inputs
                idRef='AgentRef'
                inputPlaceholder={t(`${translationPath}SearchbyagentName`)}
                labelValue={t(`${translationPath}SearchbyagentName`)}
                value={(selected && selected.agent || ' ')}
                parentTranslationPath={parentTranslationPath}
                translationPath={translationPath}
                onKeyUp={(e) => {
                  const { value } = e.target;
                  const trimmedLowerCaseValue = value.trim().toLowerCase();
                  if (searchTimer.current) clearTimeout(searchTimer.current);
                  searchTimer.current = setTimeout(() => {
                    setFilter((item) => ({ ...item, agent: trimmedLowerCaseValue || null }));
                    setSelected({ id: 'agent', value: value || null });
                  }, 700);
                }}
                onInputChanged={(e) => {
                  const { value } = e.target
                  setSelected({ id: 'agent', value: value || null });
                  setFilter((item) => ({ ...item, agent: value || null }));
                }}
              />
            </div>
            <div className='w-100 ml-2'>
              <Inputs
                idRef='SearchByProposalNameRef'
                inputPlaceholder={t(`${translationPath}searchbyreceiver`)}
                labelValue={t(`${translationPath}searchbyreceiver`)}
                value={(selected && selected.lead || ' ')}
                parentTranslationPath={parentTranslationPath}
                translationPath={translationPath}
                onKeyUp={(e) => {
                  const { value } = e.target;
                  if (searchTimer.current) clearTimeout(searchTimer.current);
                  searchTimer.current = setTimeout(() => {
                    setFilter((item) => ({ ...item, lead: value || null }));
                    setSelected({ id: 'lead', value: value || null });
                  }, 700);
                }}
                onInputChanged={(e) => {
                  const { value } = e.target
                  setSelected({ id: 'lead', value: value || null });
                  setFilter((item) => ({ ...item, lead: value || null }));
                }}
              />
            </div>
          </div>
          <div className='actionSide ml-5'>
            <ButtonBase
              onClick={onClearedAllFiltersClick}
              id="onClearedAllFiltersref"
              className="btns theme-solid bg-danger clear-all-btn max-height"
            >
              <span className="mdi mdi-filter-remove m-1" />
              {t(`${translationPath}clear-filters`)}
            </ButtonBase>
          </div>
        </div>
        <Tables
          data={proposalLogs.result}
          headerData={[
            {
              id: 1,
              label: 'Agent-name',
              input: 'name',
            },
            {
              id: 2,
              label: 'Agent-Branch',
              input: 'branch',
            },
            {
              id: 3,
              label: 'Agent-mobile-number',
              input: 'mobileNumber',
            },
            {
              id: 4,
              label: 'Agent-Email',
              input: 'email',
            },
            {
              id: 5,
              label: 'date-time',
              input: 'createdOn',
              component: (item) => item.createdOn
            },
            {
              id: 6,
              label: 'source-system',
              input: 'sourceSystem',
              component: (item) => item.sourceSystem
            }
          ]}
          defaultActions={getTableActions()}
          actionsOptions={{
            onActionClicked: tableActionClicked,
          }}
          focusedRowChanged={focusedRowChanged}

          translationPath={translationPath}
          parentTranslationPath={parentTranslationPath}
          itemsPerPage={filter.pageSize}
          activePage={0}
        />
      </div>

      {viewDetailsProposal &&
        <ProposalHistoryViewDetailsDialog
          isOpen={viewDetailsProposal}
          activeitem={activeitem}
          onClose={() => {
            setViewDetailsProposal(false)
            setActiveitem(null)
          }}
          translationPath={translationPath}
          parentTranslationPath={parentTranslationPath}
          filter={filter}
        />
      }
    </>

  )
}