

import React , { useState , useEffect  , useRef ,  useCallback  } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { CheckboxesComponent , AutocompleteComponent  } from '../../../../../../../Components' ; 
import { OrganizationUserSearch } from '../../../../../../../Services' ; 


export const ContactCreatedBy  = ({
  parentTranslationPath,
  translationPath,
  state ,
  setState ,
  checkAtLeastOneSelected , 
  checkAtLeastOneSelected2 , 
  setDefaultSelected , 
}) => {
    const { t } = useTranslation(parentTranslationPath);
    const [allUsers , setAllUsers] =  useState([]) ;
    const [isLoading ,   setIsLoading ] = useState(false) ; 

    const searchTimer = useRef(null);
    let timer = null; 

    const getAllOrganizationUserSearch = useCallback(async (searchValue) => {
      setIsLoading(true);
      const res = await OrganizationUserSearch({ pageIndex: 0, pageSize: 10, name: searchValue  , userStatusId : 2 });
      if (!(res && res.status && res.status !== 200)) 
      setAllUsers(res.result.map((item) => ({
        id :  item.id , 
        fullName : item.fullName  , 
        branch : item.branch  || null  , 
      })));
       else 
       setAllUsers([]);  

       setIsLoading(false);

    }, []); 

    useEffect(() => {
      getAllOrganizationUserSearch() ;
    }, []); 

  return (
        <div className='content'>
        <div className='w-100 px-2 mt-2 checkBoxsPart'>
          <div className='checkboxesItem'>
           <CheckboxesComponent
            idRef='Contact_CreatedBy_ViewAllcontactsRef'
            label={t(`${translationPath}view-all-contacts`)}
            singleChecked={state.contacts.createdBy.allUsers}
            onSelectedCheckboxClicked={(e)=> {
               const checkIfCanSelected  = checkAtLeastOneSelected({ ...state.contacts.createdBy ,  allUsers : e.target.checked } );
               if(checkAtLeastOneSelected2(state.contacts.createdBy.specificUsers));
                else if(!checkIfCanSelected)
                  return ; 

               const contactCreatedBy =  { ...state.contacts.createdBy ,
                  allUsers : (e.target.checked)  , 
                  myUser  : false , 
                  myTeamUsers : false , 
                  myBusinessGroupUsers : false , 
                  myBranchUsers :false , 
                  myManageUsers : false ,
                  specificUsers :[] , 
                  myTeamLeadUsers : false , 
    
                } ;
             setState({id : 'contacts' , value : { ...state.contacts , createdBy :   contactCreatedBy} });
            }}
            parentTranslationPath={parentTranslationPath}
            translationPathForData={translationPath}
           />
          </div>
        <div className='checkboxesItem'>
          <CheckboxesComponent
            idRef='Contact_CreatedBy_view-my-contactsRef'
            label={t(`${translationPath}view-my-contacts`)}
            singleChecked={state.contacts.createdBy.myUser}
            onSelectedCheckboxClicked={(e)=> {
              const checkIfCanSelected  = checkAtLeastOneSelected({ ...state.contacts.createdBy ,  myUser : e.target.checked } );
              if(checkAtLeastOneSelected2(state.contacts.createdBy.specificUsers));
                else if(!checkIfCanSelected)
                     return ;    

               const contactCreatedBy =  { ...state.contacts.createdBy ,
                  allUsers : false  , 
                  myUser  : (e.target.checked) , 
                  myTeamUsers : false , 
                  myBusinessGroupUsers : false , 
                } ;
             setState({id : 'contacts' , value : { ...state.contacts , createdBy :   contactCreatedBy} });
            }}
            parentTranslationPath={parentTranslationPath}
            translationPathForData={translationPath}
         />
        </div>
        <div className='checkboxesItem'>
          <CheckboxesComponent
            idRef='ContactCreatedByviewMyTeamContactsRef'
            label={t(`${translationPath}view-my-team-contacts`)}
            singleChecked={state.contacts.createdBy.myTeamUsers}
            parentTranslationPath={parentTranslationPath}
            translationPathForData={translationPath}
            onSelectedCheckboxClicked={(e)=> {
              const checkIfCanSelected  = checkAtLeastOneSelected({ ...state.contacts.createdBy ,  myTeamUsers : e.target.checked } );
              if(checkAtLeastOneSelected2(state.contacts.createdBy.specificUsers));
               else if(!checkIfCanSelected)
                return ; 
              
               const contactCreatedBy =  { ...state.contacts.createdBy ,
                  allUsers : false  , 
                  myUser  : false , 
                  myTeamUsers :(e.target.checked)  , 
                  myBusinessGroupUsers : false , 
                } ;

             setState({id : 'contacts' , value : { ...state.contacts , createdBy :   contactCreatedBy} })}}
         />
        </div>
        <div className='checkboxesItem'>
           <CheckboxesComponent
             idRef='ContactCreatedByViewMybusinessGroupContactsRef'
             label={t(`${translationPath}view-my-business-group-contacts`)}
             parentTranslationPath={parentTranslationPath}
             translationPathForData={translationPath}
             singleChecked={state.contacts.createdBy.myBusinessGroupUsers}
             onSelectedCheckboxClicked={(e)=> {
              const checkIfCanSelected  = checkAtLeastOneSelected({ ...state.contacts.createdBy ,  myBusinessGroupUsers : e.target.checked } );

              if(checkAtLeastOneSelected2(state.contacts.createdBy.specificUsers));
                else if(!checkIfCanSelected)
                     return ;
               
               const contactCreatedBy =  { ...state.contacts.createdBy ,
                  allUsers : false  , 
                  myUser  : false , 
                  myTeamUsers : false , 
                  myBusinessGroupUsers :  (e.target.checked) , 
                } ;
             setState({id : 'contacts' , value : { ...state.contacts , createdBy :   contactCreatedBy} }) ;
            }}
         />
         </div>
         <div className='checkboxesItem'>
           <CheckboxesComponent
            idRef='Contact_CreatedBy_view-My-Branch-ContactsRef'
            label={t(`${translationPath}view-my-branch-contacts`)}
            singleChecked={state.contacts.createdBy.myBranchUsers}
            parentTranslationPath={parentTranslationPath}
            translationPathForData={translationPath}
            onSelectedCheckboxClicked={(e)=> {
              const checkIfCanSelected  = checkAtLeastOneSelected({ ...state.contacts.createdBy ,  myBranchUsers : e.target.checked } );
            if(checkAtLeastOneSelected2(state.contacts.createdBy.specificUsers));
                else if(!checkIfCanSelected)
                     return ; 
              
               const contactCreatedBy =  { ...state.contacts.createdBy ,
                  allUsers : false  , 
                  myBranchUsers :  (e.target.checked)
                } ;
             setState({id : 'contacts' , value : { ...state.contacts , createdBy :   contactCreatedBy} });

            }}
         />
         </div>
         <div className='checkboxesItem'>
           <CheckboxesComponent
            idRef='Contact_CreatedBy_view-my-managed-team-contactsRef'
            label={t(`${translationPath}view-my-managed-team-contacts`)}
            singleChecked={state.contacts.createdBy.myManageUsers}
            parentTranslationPath={parentTranslationPath}
            translationPathForData={translationPath}
            onSelectedCheckboxClicked={(e)=> {
              const checkIfCanSelected  = checkAtLeastOneSelected({ ...state.contacts.createdBy ,  myManageUsers : e.target.checked } );
              
              if(checkAtLeastOneSelected2(state.contacts.createdBy.specificUsers));
                else if(!checkIfCanSelected)
                     return ;
              
               const contactCreatedBy =  { ...state.contacts.createdBy ,
                  allUsers : false  , 
                  myManageUsers : (e.target.checked) ,
                } ;
              setState({id : 'contacts' , value : { ...state.contacts , createdBy :   contactCreatedBy} });
                }}
             />
         </div>
         <div className='checkboxesItem'>
           <CheckboxesComponent
            idRef='contact_createdBy-my-team-lead-users-Ref'
            label={t(`${translationPath}view-my-team-lead-users`)}
            singleChecked={state.contacts.createdBy.myTeamLeadUsers}
            parentTranslationPath={parentTranslationPath}
            translationPathForData={translationPath}
            onSelectedCheckboxClicked={(e)=> {
              const checkIfCanSelected  = checkAtLeastOneSelected({ ...state.contacts.createdBy ,  myTeamLeadUsers : e.target.checked } );
              
              if(checkAtLeastOneSelected2(state.contacts.createdBy.specificUsers));
                else if(!checkIfCanSelected)
                     return ;
              
               const contactCreatedBy =  { ...state.contacts.createdBy ,
                  allUsers : false  , 
                  myTeamLeadUsers : (e.target.checked) ,
                } ;
                setState({id : 'contacts' , value : { ...state.contacts , createdBy :   contactCreatedBy} });
                }}
             />
         </div>
         <div className='w-100 px-2 mt-2 mb-2'>
          <AutocompleteComponent
              idRef='contactSpecificsUsersRef'
              labelValue={t(`${translationPath}specific_users`)}
              data={ allUsers || []}
              selectedValues={state.contacts.createdBy.specificUsers}
              multiple
              displayLabel={(option) =>
              (option && option.fullName) || ''}
              chipsLabel={(option) => (option && option.fullName) || ''}
              withoutSearchButton
              parentTranslationPath={parentTranslationPath}
              translationPath={translationPath}
              onInputKeyUp={(e) => {
                const { value } = e.target;
                 if (searchTimer.current) clearTimeout(searchTimer.current);
                   searchTimer.current = setTimeout(() => {
                    getAllOrganizationUserSearch(value) ;  
                }, 700); 
                }}
                renderOption={
                  ((option) =>
                  <div className='d-flex-column'>
                   <div className='d-flex-v-center-h-between w-100 texts-truncate'>
                    {option.fullName}
                   </div>
                   <span className='c-gray-secondary'> {option.branch} </span>
                 </div>
               )}
              isDisabled={state.contacts.createdBy.allUsers}
              value={state.contacts.createdBy.specificUsers}
              onChange={(e, newValues) => 
              {
                let contactCreatedBy = { ...state.contacts.createdBy  , specificUsers : newValues };
                if( !newValues.length && setDefaultSelected({ ...state.contacts.createdBy , specificUsers :false }))
                  contactCreatedBy = { ...state.contacts.createdBy  , specificUsers : newValues , allUsers : true }
                
                setState({ id: 'contacts', value: { ...state.contacts , createdBy  : contactCreatedBy } })
              }}
             isLoading={isLoading} 
             withLoader
             filterOptions={(options) => {
              const isFind = (id) => state.contacts.createdBy.specificUsers.findIndex((w) => w.id === id) === -1;
             return options.filter((w) => isFind(w.id));
            }}
         /> 
        </div>

       </div>
      </div>
  );
};

ContactCreatedBy.propTypes = {
  parentTranslationPath: PropTypes.string.isRequired,
  translationPath: PropTypes.string.isRequired,
  state:PropTypes.instanceOf(Object).isRequired,
  setState : PropTypes.func.isRequired,
};

