import React from "react";

function StarOneIcon({ fill, ...restProps }) {
  return (
    <svg
      fill={fill ?? ""}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <path
        d="M11.477 1.732a1.454 1.454 0 0 0-.664.517c-.136.186-1.068 2.028-2.115 4.181-.249.511-.47.93-.491.93-.152 0-5.314.776-5.487.825-.63.178-1.013.582-1.124 1.187a.975.975 0 0 0 .018.532c.036.148.113.352.17.453.064.112.617.685 1.43 1.482.729.714 1.637 1.605 2.017 1.98l.691.681-.448 2.58c-.515 2.969-.54 3.236-.347 3.649.15.32.422.597.724.736.214.098.297.114.589.113.246-.001.404-.025.571-.087.128-.047 1.294-.645 2.592-1.328 1.298-.684 2.377-1.243 2.397-1.243.02 0 1.099.559 2.397 1.243 1.298.683 2.464 1.281 2.592 1.328.167.062.325.086.571.087.292.001.375-.015.589-.113.302-.139.574-.416.724-.736.194-.415.169-.681-.347-3.649l-.448-2.58.711-.701c.391-.386 1.299-1.277 2.017-1.98.8-.784 1.346-1.35 1.41-1.462.057-.101.134-.305.17-.453a.988.988 0 0 0 .018-.536c-.111-.602-.493-1.003-1.125-1.183-.121-.034-1.407-.235-2.859-.447-1.452-.211-2.651-.396-2.665-.411-.014-.015-.527-1.044-1.139-2.287-1.313-2.665-1.34-2.716-1.585-2.968-.274-.283-.567-.408-.991-.422-.284-.01-.377.004-.563.082m1.592 4.698c.573 1.16 1.098 2.186 1.167 2.28.156.212.39.384.665.487.134.05 1.077.206 2.565.424 1.294.189 2.357.348 2.362.353.005.005-.782.779-1.748 1.719-1.602 1.56-1.768 1.734-1.888 1.978-.211.427-.195.619.261 3.246.212 1.22.378 2.225.369 2.235-.01.009-.959-.477-2.11-1.081a112.821 112.821 0 0 0-2.232-1.154c-.083-.034-.279-.057-.48-.057-.201 0-.397.023-.48.057-.077.031-1.082.55-2.232 1.154-1.151.604-2.101 1.09-2.111 1.08-.01-.01.156-1.016.369-2.235.457-2.62.472-2.812.262-3.244-.119-.245-.276-.409-1.887-1.978a117.41 117.41 0 0 1-1.749-1.72c.005-.005 1.068-.164 2.362-.353 1.488-.218 2.431-.374 2.565-.424.275-.103.51-.275.665-.487.068-.094.593-1.12 1.167-2.28.573-1.16 1.054-2.11 1.069-2.11.015 0 .496.95 1.069 2.11"
        fill-rule="evenodd"
        fill={fill ?? ""}
      />
    </svg>
  );
}

export default StarOneIcon;
