import React, { useEffect } from 'react';
import { SwitchRoute } from '../../../Components/Route/SwitchRoute';
import { LeadsMortgageRoutes } from '../../../routes';

export const LeadsMortgageLayout = () => {
  useEffect(() => {
    return () => {
        localStorage.removeItem("mortgageLeadsCurrentPageIndex");
    }
  }, []);

  return (
    <SwitchRoute routes={LeadsMortgageRoutes} />
  );
};

