import React, { useCallback, useEffect, useReducer, useState } from "react";
import {
  DialogComponent,
  GalleryComponent,
  LoadableImageComponant,
  Spinner,
} from "../../../../Components";
import { ButtonBase, DialogActions } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import {
  GetAdvanceSearchContacts,
  GetScopeCategoryDocuments,
} from "../../../../Services";
import { config } from "../../../../config";
import { getDownloadableLink } from "../../../../Helper";
import { LoadableImageEnum } from "../../../../Enums";
import { MultipleTypesDocumentsUploader } from "../../../../SharedComponents/MultipleTypesDocumentsUploader/MultipleTypesDocumentsUploader";
import { ImagesGalleryPhotosCardComponent } from "../../ImagesGallery/Sections/ImagesGalleryPhotosComponent/ImagesGalleryPhotosCardComponent";

function LeadDocumentsPreviewDialog({
  openPreviewDialog,
  activeItem,
  filter,
  setOpenPreviewDialog,
  translationPath,
  parentTranslationPath,
}) {
  const reducer = useCallback((state, action) => {
    if (action.id !== "edit") return { ...state, [action.id]: action.value };
    return {
      ...action.value,
    };
  }, []);
  const { t } = useTranslation(parentTranslationPath);
  const [isLoading, setIsLoading] = useState(false);
  const [contactId, setContactId] = useState(null);
  const [selected, setSelected] = useReducer(reducer, {
    documents: {
      IdDocuments: [],
      passportDocuments: [],
      KYCDocuments: [],
    },
  });
  const IdCategoryLookup = {
    id: config.ContactDocsCategoryIds_IDCard,
    name: "ID",
  };

  const passportCategoryLookup = {
    id: config.ContactDocsCategoryIds_Passport,
    name: "Passport",
  };

  const KYCCategoryLookup = {
    id: config.ContactDocsCategoryIds_KYC,
    name: "KYC",
  };

  const getContactIdAPI = useCallback(async () => {
    setIsLoading(true);
    const body = {
      contactClasses: [],
      criteria: {
        first_name: [
          {
            searchType: 2,
            value: activeItem?.newClientData?.first_name,
          },
        ],
        last_name: [
          {
            searchType: 2,
            value: activeItem?.newClientData?.last_name,
          },
        ],
        lead_id: [
          {
            searchType: 2,
            value: `${activeItem?.leadId}`,
          },
        ],
        mobile: [
          {
            searchType: 2,
            value: activeItem?.newClientData?.mobile_number,
          },
        ],
      },
      filterBy: "createdOn",
      LeadClasses: [],
      orderBy: 2,
    };

    const res = await GetAdvanceSearchContacts(
      {
        pageSize: filter?.pageSize ?? 25,
        pageIndex: filter?.pageIndex ?? 1,
      },
      body
    );
    if (!(res && res.status && res.status !== 200)) {
      setContactId(res?.result[0]?.contactId);
    } else 
    setIsLoading(false);
  });

  const getContactOldDocuments = useCallback(async () => {
    setIsLoading(true);

    const body = {
      scopeId: contactId,
      pageSize: filter?.pageSize ?? 25,
      pageIndex: filter?.pageIndex + 1 ?? 1,
    };

    try {
      const [IdDocsResponse, passportDocsResponse, KYCDocsResponse] =
        await Promise.all([
          GetScopeCategoryDocuments({
            ...body,
            categoryId: IdCategoryLookup.id,
          }),
          GetScopeCategoryDocuments({
            ...body,
            categoryId: passportCategoryLookup.id,
          }),
          GetScopeCategoryDocuments({
            ...body,
            categoryId: KYCCategoryLookup.id,
          }),
        ]);

      if (
        !(
          IdDocsResponse &&
          IdDocsResponse.status &&
          IdDocsResponse.status !== 200
        ) &&
        !(
          passportDocsResponse &&
          passportDocsResponse.status &&
          passportDocsResponse.status !== 200
        ) &&
        !(
          KYCDocsResponse &&
          KYCDocsResponse.status &&
          KYCDocsResponse.status !== 200
        )
      ) {
        const mappedIdDocuments = IdDocsResponse?.result?.map((item) => ({
          uuid: item.documentId,
          fileName: item.documentName,
        }));

        const mappedPassportDocuments = passportDocsResponse?.result?.map(
          (item) => ({
            uuid: item.documentId,
            fileName: item.documentName,
          })
        );
        const mappedKYCDocuments = KYCDocsResponse?.result?.map((item) => ({
          uuid: item.documentId,
          fileName: item.documentName,
        }));

        setSelected({
          id: "documents",
          value: {
            IdDocuments: mappedIdDocuments || [],
            passportDocuments: mappedPassportDocuments || [],
            KYCDocuments: mappedKYCDocuments || [],
          },
        });
      } else {
        setSelected({
          id: "documents",
          value: {
            IdDocuments: [],
            passportDocuments: [],
            KYCDocuments: [],
          },
        });
        setIsLoading(true);
      }
    } catch (error) {
      console.error("Error fetching documents:", error);
    } finally {
      setIsLoading(false);
    }
  });

  const onCloseHandler = () => {
    setOpenPreviewDialog(false);
  };
  const onSaveHandler = () => {};

  useEffect(() => {
    getContactIdAPI();
  }, []);

  useEffect(() => {
    if (contactId) {
      getContactOldDocuments();
    } else {
      setIsLoading(false);
    }
  }, [contactId]);

  return (
    <>
      <Spinner isActive={isLoading} isAbsolute />
      <DialogComponent
        titleText="documents"
        maxWidth="md"
        isOpen={openPreviewDialog}
        translationPath={translationPath}
        parentTranslationPath={parentTranslationPath}
        dialogContent={
          <>
            <div>
              <h3>{t("id-documents")}</h3>
              <ImagesGalleryPhotosCardComponent
                data={selected?.documents?.IdDocuments || []}
                parentTranslationPath={parentTranslationPath}
                translationPath={translationPath}
                imageInput={"uuid"}
                altInput={"fileName"}
              />
            </div>
            <div>
              <h3>{t("passport-documents")}</h3>
              <ImagesGalleryPhotosCardComponent
                data={selected?.documents?.passportDocuments || []}
                parentTranslationPath={parentTranslationPath}
                translationPath={translationPath}
                imageInput={"uuid"}
                altInput={"fileName"}
              />
            </div>
            <div>
              <h3>{t("KYC-documents")}</h3>
              <ImagesGalleryPhotosCardComponent
                data={selected?.documents?.KYCDocuments || []}
                parentTranslationPath={parentTranslationPath}
                translationPath={translationPath}
                imageInput={"uuid"}
                altInput={"fileName"}
              />
            </div>

            <DialogActions>
              <div className="unit-template-action-btns">
                <ButtonBase
                  className="MuiButtonBase-root btns bg-cancel"
                  onClick={onCloseHandler}
                >
                  {t(`${translationPath}cancel`)}
                </ButtonBase>
                <ButtonBase
                  className="MuiButtonBase-root btns theme-solid "
                  onClick={onSaveHandler}
                >
                  {t(`${translationPath}save`)}
                </ButtonBase>
              </div>
            </DialogActions>
          </>
        }
      />
    </>
  );
}

export default LeadDocumentsPreviewDialog;
