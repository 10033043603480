/* eslint-disable no-unused-vars */
import { OrganizationUserSearch } from "../Services";
import { AgentRoleEnum } from "../Enums/AgentRoleEnum";
import { GetParams } from "../Helper";
import { LeadTypeIdEnum } from "../Enums";

let oldvalue = "";
let timer = null;
export const getUserTypeId = (
  itemList,
  values,
  fieldId,
  activeFormType,
  item
) => {
  const leadOpration = values && values.lead_operation;
  const operationType = values && values.operation_type;
  const shortageIn = values && values.shortage_in;

  if (
    leadOpration &&
    fieldId === "referredto" &&
    item &&
    item.data &&
    item.data.DtoName === LeadTypeIdEnum.Owner.name
  )
    return leadOpration === "Landlord"
      ? AgentRoleEnum.LeaseListingAgent.value
      : AgentRoleEnum.SaleListingAgent.value;

  if (operationType) {
    if (
      operationType.lookupItemName === "Buy" &&
      fieldId &&
      fieldId === "listing_agent"
    )
      return AgentRoleEnum.SaleListingAgent.value;

    if (
      operationType.lookupItemName === "Sale" &&
      fieldId &&
      fieldId === "listing_agent"
    )
      return AgentRoleEnum.SaleListingAgent.value;

    if (
      operationType.lookupItemName === "Rent" &&
      fieldId &&
      fieldId === "listing_agent"
    )
      return AgentRoleEnum.LeaseListingAgent.value;

    if (
      operationType.lookupItemName === "Sale" &&
      fieldId &&
      fieldId === "referredto"
    )
      return AgentRoleEnum.SaleAgent.value;

    if (
      fieldId &&
      fieldId === "referredto" &&
      item &&
      item.data &&
      item.data.DtoName === LeadTypeIdEnum.Seeker.name &&
      operationType.lookupItemName === "Buy"
    )
      return AgentRoleEnum.SaleAgent.value;

    if (
      fieldId &&
      fieldId === "referredto" &&
      item &&
      item.data &&
      item.data.DtoName === LeadTypeIdEnum.Seeker.name &&
      operationType.lookupItemName === "Rent"
    )
      return AgentRoleEnum.LeaseAgent.value;

    if (
      (operationType.lookupItemName === "Buy" ||
        operationType.lookupItemName === "Sale") &&
      fieldId &&
      fieldId === "referredto" &&
      item &&
      item.data &&
      item.data.DtoName === LeadTypeIdEnum.Owner.name
    )
      return AgentRoleEnum.SaleListingAgent.value;

    if (
      operationType.lookupItemName === "Rent" &&
      fieldId &&
      fieldId === "referredto" &&
      item &&
      item.data &&
      item.data.DtoName === LeadTypeIdEnum.Owner.name
    )
      return AgentRoleEnum.LeaseListingAgent.value;
  }

  if (fieldId === "listing_agent") return AgentRoleEnum.SaleListingAgent.value;
  if (fieldId === "rent_listing_agent")
    return AgentRoleEnum.LeaseListingAgent.value;
  if (fieldId === "rent_listing-agent")
    return AgentRoleEnum.LeaseListingAgent.value;

  // if (fieldId === 'assign_to' && activeFormType === '1')
  //   return AgentRoleEnum.SaleListingAgent.value;

  // if (fieldId === 'assign_to' && activeFormType === '2')
  //   return AgentRoleEnum.LeaseListingAgent.value;

  // if (shortageIn) {
  //   if (fieldId === 'assign_to' && (shortageIn === 'Sale'))
  //     return AgentRoleEnum.SaleListingAgent.value;

  //   if (fieldId === 'assign_to' && (shortageIn === 'Leasing'))
  //     return AgentRoleEnum.LeaseListingAgent.value;
  // }

  return null;
};

export const getRolesIds = (values, fieldId, activeFormType) => {
  const shortageIn = values && values.shortage_in;
  if (!shortageIn && fieldId === "assign_to" && activeFormType === "1")
    return [
      AgentRoleEnum.SaleListingAgent.value,
      AgentRoleEnum.SaleAgent.value,
    ];

  if (!shortageIn && fieldId === "assign_to" && activeFormType === "2")
    return [
      AgentRoleEnum.LeaseListingAgent.value,
      AgentRoleEnum.LeaseAgent.value,
    ];

  if (shortageIn) {
    if (fieldId === "assign_to" && shortageIn === "Sale")
      return [
        AgentRoleEnum.SaleListingAgent.value,
        AgentRoleEnum.SaleAgent.value,
      ];

    if (fieldId === "assign_to" && shortageIn === "Leasing")
      return [
        AgentRoleEnum.LeaseListingAgent.value,
        AgentRoleEnum.LeaseAgent.value,
      ];
  }

  return null;
};

export const UserRule = async (
  item,
  value,
  setRerender,
  itemList,
  values,
  activeFormType,
  formType,
  setData
) => {
  if (!item.data.searchKey) return;
  if (item.data.searchKey !== "User") return;
  if (item.value === "") return;
  if (value === "") return;
  if (timer !== null) clearTimeout(timer);
  if (oldvalue === value) return;
  oldvalue = value;

  let userTypeId = null;
  userTypeId =
    itemList && values
      ? getUserTypeId(itemList, values, item.field.id, activeFormType, item)
      : null;
  // if (values && values.shortage_in)
  //   userTypeId = values.shortage_in === 'Sale' ? AgentRoleEnum.SaleListingAgent.value : AgentRoleEnum.LeaseListingAgent.value;
  const roles = getRolesIds(values, item.field.id, activeFormType);

  const filter = roles
    ? {
        pageSize: 25,
        pageIndex: 1,
        name: value,
        userName: null,
        phoneNumber: null,
        email: null,
        userStatusId: 2,
        userTypeId,
        rolesIds: roles,
      }
    : {
        pageSize: 25,
        pageIndex: 1,
        name: value,
        userName: null,
        phoneNumber: null,
        email: null,
        userStatusId: 2,
        userTypeId,
      };
  timer = setTimeout(async () => {
    const rs = await OrganizationUserSearch({ ...filter });

    item.data.enum = [];
    if (!rs || !rs.result) return;
    rs.result.map((element) => {
      item.data.enum.push({
        id: element.id,
        name: element.fullName,
        phone: element.phoneNumber,
        email: element.email,
        userName: element.userName,
        branch: element.branch,
      });
    });
    if (
      item.data.valueToEdit &&
      item.data.valueToEdit.id &&
      item.data.enum.findIndex(
        (element) =>
          element.id === item.data.valueToEdit && item.data.valueToEdit.id
      ) === -1
    )
      item.data.enum.push(item.data.valueToEdit && item.data.valueToEdit);

    setRerender(Math.random());
  }, 500);
};

export const UserDefaultRule = async (
  item,
  setRerender,
  itemList,
  values,
  activeFormType
) => {
  if (item.data.searchKey !== "User") return;
  if (item.data.enum) return;

  let userTypeId = null;
  userTypeId =
    itemList && values
      ? getUserTypeId(itemList, values, item.field.id, activeFormType, item)
      : null;

  const roles = getRolesIds(values, item.field.id, activeFormType);

  const filter = roles
    ? {
        pageSize: 25,
        pageIndex: 1,
        name: null,
        userName: null,
        phoneNumber: null,
        email: null,
        userStatusId: 2,
        userTypeId,
        rolesIds: roles,
      }
    : {
        pageSize: 25,
        pageIndex: 1,
        name: null,
        userName: null,
        phoneNumber: null,
        email: null,
        userStatusId: 2,
        userTypeId,
      };

  const rs = await OrganizationUserSearch({ ...filter });
  item.data.enum = [];
  if (!rs || !rs.result) return;
  rs.result.map((value) => {
    item.data.enum.push({
      id: value.id,
      name: value.fullName,
      phone: value.phoneNumber,
      email: value.email,
      userName: value.userName,
      branch: value.branch,
    });
  });

  setRerender(Math.random());
};

export const ShortageInRule = (itemList, setData, fromType) => {
  const assignToIndex = itemList.indexOf(
    itemList.find((f) => f.field.id === "assign_to")
  );
  const inquiryUnitModelIndex = itemList.indexOf(
    itemList.find(
      (f) =>
        f.field.id === "inquiry_unit-model" ||
        f.field.id === "inquiry_unit_model"
    )
  );
  const listingPriceFromIndex = itemList.indexOf(
    itemList.find(
      (f) =>
        f.field.id === "listing_price-from" ||
        f.field.id === "listing_price_from"
    )
  );
  const listingPriceToIndex = itemList.indexOf(
    itemList.find(
      (f) =>
        f.field.id === "listing_price-to" || f.field.id === "listing_price_to"
    )
  );

  if (fromType === 1) {
    if (assignToIndex !== -1) {
      itemList[assignToIndex].data.enum = [];
      setData("assign_to", {});
    }
    if (inquiryUnitModelIndex !== -1) {
      setData(itemList[inquiryUnitModelIndex].field.id, null);
      setData("inquiry_unit_model", {});
    }
    // if(listingPriceFromIndex !== -1)
    // {
    //   setData(itemList[listingPriceFromIndex].field.id , ) ;
    //   setData('listing_price_from' ,  '0' ) ;

    // }
    // if(listingPriceToIndex !== -1)
    // {
    //   setData(itemList[listingPriceToIndex].field.id , null) ;
    //   setData('listing_price_to' , '0') ;
    // }
  } else if (fromType === 2) {
    if (assignToIndex !== -1) {
      itemList[assignToIndex].data.enum = [];
      setData(null, 0, "assign_to");
    }
    if (inquiryUnitModelIndex !== -1) setData(null, 0, "inquiry_unit_model");

    // if(listingPriceFromIndex !== -1)
    // {
    //   setData( null   , 0  , 'listing_price_from') ;

    // }
    // if(listingPriceToIndex !== -1)
    // {
    //   setData( null   , 0  , 'listing_price_to') ;
    // }
  }
};
