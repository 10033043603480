
export const CallCenterTeamLeadDashboardPermissions = {
  CallCenterTeamLeadDashboard :
  {
    permissionsId:'396b99ac-2b55-4bf1-a89a-08da6e2b177d',
    permissionsName:'Call Center Team Lead',
    componentsId:null,
    components:null
  }
  } ;
  