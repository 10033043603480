

import React , { useState , useEffect  , useRef ,  useCallback  } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { CheckboxesComponent   } from '../../../../../../../Components' ; 


export const PolicyDependsOnOtherPolicy = ({
  parentTranslationPath,
  translationPath,
  state ,
  setState , 
}) => {
    const { t } = useTranslation(parentTranslationPath);
    
  return (
        <div className='content'>
        <div className='w-100 px-2 mt-2 checkBoxsPart'>
          <div className='checkboxesItem'>
           <CheckboxesComponent
            idRef='Contact-is-related-with-lead-policy-Ref'
            label={t(`${translationPath}related-with-lead-policy`)}
            singleChecked={state.contacts.basedOnOtherPolicy && state.contacts.basedOnOtherPolicy.relatedWithLeadPolicy}
            onSelectedCheckboxClicked={(e)=> {
               const contactPolicyDependsOnOtherPolicy =   
                {...state.contacts.basedOnOtherPolicy  ,
                  relatedWithLeadPolicy  : (e.target.checked) } ;  
               setState({id : 'contacts' , value : { ...state.contacts , basedOnOtherPolicy : contactPolicyDependsOnOtherPolicy} });
            }}
            parentTranslationPath={parentTranslationPath}
            translationPathForData={translationPath}
           />
          </div>
          
       </div>
      </div>
  );
};

PolicyDependsOnOtherPolicy.propTypes = {
  parentTranslationPath: PropTypes.string.isRequired,
  translationPath: PropTypes.string.isRequired,
  state:PropTypes.instanceOf(Object).isRequired,
  setState : PropTypes.func.isRequired,
};

