import { UnitsOperationTypeEnum } from "./UnitsOperationTypeEnum";

export const UnitsStatusEnum = {
  Available: {
    key: 1,
    value: "available",
    name: "Available",
    showInSelect: true,
    classes: "c-success-light",
    saleCategory: {
      key: 21424,
      value: "sale-available",
    },
    leaseCategory: {
      key: 21428,
      value: "leased-available",
    },
  },
  ReservedLeased: {
    key: 2,
    value: "reserved-for-lease",
    name: "ReservedLeased",
    showInSelect: true,
    effectedOperationType: UnitsOperationTypeEnum.rent.key,
    classes: "c-primary",
    category: {
      key: 21429,
      value: "leased-reserved",
    },
  },
  Leased: {
    key: 3,
    value: "leased",
    name: "Leased",
    showInSelect: true,
    effectedOperationType: UnitsOperationTypeEnum.rent.key,
    classes: "c-primary",
    category: {
      key: 21430,
      value: "leased-leased",
    },
  },
  LeasedByThirdParty: {
    key: 4,
    value: "leased-by-third-party",
    name: "LeasedByThirdParty",
    showInSelect: true,
    effectedOperationType: UnitsOperationTypeEnum.rent.key,
    classes: "c-primary",
  },
  ReservedSale: {
    key: 5,
    value: "reserved-for-sale",
    name: "ReservedSale",
    showInSelect: true,
    effectedOperationType: UnitsOperationTypeEnum.sale.key,
    classes: "c-primary",
    category: {
      key: 21425,
      value: "sale-reserved",
    },
  },
  Sale: {
    key: 6,
    value: "sale",
    name: "Sale",
    showInSelect: true,
    effectedOperationType: UnitsOperationTypeEnum.sale.key,
    classes: "c-primary",
    category: {
      key: 21426,
      value: "sale-sold",
    },
  },
  SaleByThirdParty: {
    key: 7,
    value: "sale-by-third-party",
    name: "SaleByThirdParty",
    effectedOperationType: UnitsOperationTypeEnum.sale.key,
    classes: "c-primary",
  },
  Blocked: {
    key: 8,
    value: "blocked",
    classes: "c-black-light",
    name: "Blocked",
  },
  Upcoming: {
    key: 9,
    value: "upcoming",
    classes: "c-warning-light",
    name: "Upcoming",
  },
  Draft: {
    key: 10,
    value: "draft",
    name: "Draft",
    classes: "globel-Gry-style",
    showInSelect: true,
    saleCategory: {
      key: 21423,
      value: "sale-draft",
    },
    leaseCategory: {
      key: 21427,
      value: "leased-draft",
    },
  },
  PendingReserveLease: {
    key: 11,
    value: "Pendin_Reserve_Lease",
    classes: "c-pending",
    name: "PendingReserveLease",
  },
  PendingReservedLease: {
    key: 11,
    value: "Pendin_Reserve_Lease",
    classes: "c-pending",
    name: "PendingReservedLease",
  },
  PendingLease: {
    key: 12,
    value: "Pending_Lease",
    classes: "c-pending",
    name: "PendingLease",
  },
  PendingLeased: {
    key: 12,
    value: "Pending_Lease",
    classes: "c-pending",
    name: "PendingLeased",
  },
  PendingReserveSale: {
    key: 13,
    value: "Pendin_Reserve_Sale",
    classes: "c-pending",
    name: "PendingReserveSale",
  },
  PendingSale: {
    key: 14,
    value: "PendingSale",
    classes: "c-pending",
    name: "PendingSale",
  },
  UnderOffer: {
    key: 15,
    value: "UnderOffer",
    classes: "c-pending",
    name: "UnderOffer",
  },
  OfferSigned: {
    key: 16,
    value: "OfferSigned",
    classes: "c-pending",
    name: "OfferSigned",
  },
  UnderContract: {
    key: 17,
    value: "UnderContract",
    classes: "c-pending",
    name: "UnderContract",
  },
  ContractSigned : {
    key: 18,
    value: "ContractSigned",
    classes: "c-pending",
    name: "ContractSigned",
  },
};
