import React, { useCallback, useState } from "react"
import { Spinner, Tables } from "../../../../../../../../Components"
import { ActionsEnum, TableActions } from "../../../../../../../../Enums";
import { SelectContactDialog } from "../Dialogs/SelectContactDialog";
import { DeleteUnitTenant } from "../../../../../../../../Services";
import { showError, showSuccess } from "../../../../../../../../Helper";
import { useTranslation } from "react-i18next";

export const UnitProfileTenantTable = ({
    translationPath,
    parentTranslationPath,
    data,
    filter,
    totalItems,
    onSave
}) => {
    const { t } = useTranslation(parentTranslationPath);
    const [openSelectContact, setOpenSelectContact] = useState(false);
    const [activeItem, setActiveItem] = useState();
    const [loadings, setLoadings] = useState(false);

    const deleteUnitTenant = useCallback(async (unitTenantId) => {
        setLoadings(true)
        const res = await DeleteUnitTenant(unitTenantId);
        if (!(res && res.status && res.status !== 200)) {
            onSave()
        } else {
            showError(t`${translationPath}Failed_deleting`);
        }
        setLoadings(false)
    })

    const tableActionClicked = useCallback((actionEnum, item) => {
        if (actionEnum === TableActions.editText.key) {
            setActiveItem(item);
            setOpenSelectContact(true);
        }
        if (actionEnum === TableActions.delete.key) {
            setActiveItem(item);
            deleteUnitTenant(item?.unitTenantId)
        }

    }, []);

    return (
        <>
            <Spinner isActive={loadings} isAbsolute />
            <Tables
                className="containerTableTenant"
                data={data}
                headerData={[
                    {
                        id: 1,
                        label: 'Tenant-name',
                        input: 'tenantName',
                        component: ((item) => (
                            <span>{item.tenantName ? item.tenantName : 'N/A'}</span>
                        )
                        )
                    },
                    {
                        id: 2,
                        label: 'Email',
                        input: 'email',
                        component: ((item) => (
                            <span>{item.email ? item.email : 'N/A'}</span>
                        )
                        )
                    },
                    {
                        id: 3,
                        label: 'Mobile-Number',
                        input: 'mobileNumber',
                        component: ((item) => (
                            <span>{item.mobileNumber ? item.mobileNumber : 'N/A'}</span>
                        )
                        )
                    },
                    {
                        id: 4,
                        label: 'Contract-start-date',
                        input: 'contractStartDate',
                        component: ((item) => (
                            <span>{item.contractStartDate ? item.contractStartDate : 'N/A'}</span>
                        )
                        )
                    },
                    {
                        id: 5,
                        label: 'Contract-end-date',
                        input: 'contractEndDate',
                        component: ((item) => (
                            <span>{item.contractEndDate ? item.contractEndDate : 'N/A'}</span>
                        )
                        )
                    },
                    {
                        id: 6,
                        label: 'Nationality',
                        input: 'nationality',
                        component: ((item) => (
                            <span>{item.nationality ? item.nationality : 'N/A'}</span>
                        )
                        )
                    },
                    {
                        id: 7,
                        label: 'Contact-ID',
                        input: 'contactId',
                        component: ((item) => (
                            <span>{item.contactId ? item.contactId : 'N/A'}</span>
                        )
                        )
                    },
                    {
                        id: 8,
                        label: 'Created-by',
                        input: 'createdByName',
                        component: ((item) => (
                            <span>{item.createdByName ? item.createdByName : 'N/A'}</span>
                        )
                        )
                    },
                    {
                        id: 9,
                        label: 'Creation-Date',
                        input: 'createDate',
                        component: ((item) => (
                            <span>{item.createDate ? item.createDate : 'N/A'}</span>
                        )
                        )
                    },
                    {
                        id: 9,
                        label: 'Current-tenant',
                        input: 'isCurrentTenant',
                        component: ((item) => (
                           <span>{item.isCurrentTenant ? 'Yes' : 'No'}</span>
                        )
                        )
                    },

                ]}
                parentTranslationPath={parentTranslationPath}
                translationPath={translationPath}
                totalItems={totalItems && totalItems}
                activePage={filter.pageIndex}
                itemsPerPage={filter.pageSize}
                actionsOptions={{
                    onActionClicked: tableActionClicked,
                }}
                defaultActions={[
                    {
                        enum: TableActions.editText.key,
                    },
                    {
                        enum: TableActions.delete.key,
                    },
                ]}
            />
            {
                openSelectContact && (
                    <SelectContactDialog
                        isOpen={openSelectContact}
                        onClose={() => {
                            setOpenSelectContact(false)
                        }}
                        parentTranslationPath={parentTranslationPath}
                        translationPath={translationPath}
                        activeItem={activeItem}
                        isEditingView={true}
                        onSave={
                            () => {
                                setOpenSelectContact(false)
                                onSave()
                            }
                        }
                    />
                )
            }
        </>
    )
}