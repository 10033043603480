import React, { Children, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DatePickerComponent } from '../../../../../../../Components';
import { SearchFieldComponent } from '../../../../../ActivitiesTypeView/ActivityTypeUpdateBulkView/Sections/SearchFieldComponent';
import { TextFieldComponent } from '../../../../../ActivitiesTypeView/ActivityTypeUpdateBulkView/Sections/TextFieldComponent';

function NewValuesViewer({
  formData,
  userDetails,
  setUserDetails,
  parentTranslationPath,
}) {
  const { t } = useTranslation(parentTranslationPath);

  const updateItemValue = (item, newValue) => {
    setUserDetails((items) => {
      items[item.fieldKey] = newValue;
      return { ...items };
    });
  };

  return (
    <>
      <div className='bulk-header-section'>{t(`new-values`)}</div>
      <div className='bulk-sub-header-section'>
        {t(`add-new-value-to-the-following-fields`)}
      </div>
      <div className='bulked-units-section new-value-section mt-3'>
        {Children.toArray(
          formData?.map((item) => (
            <div className='mb-3'>
              {(item.fieldType === 'search' || item.fieldType === 'select') && (
                <SearchFieldComponent
                  key={item.fieldKey}
                  translationPath={''}
                  parentTranslationPath={parentTranslationPath}
                  item={item}
                  activitiesTypesDetails={userDetails}
                  onValueChanged={(value) => {
                    updateItemValue(item, value);
                  }}
                />
              )}
              {(item.fieldType === 'text' || item.fieldType === 'number') && (
                <TextFieldComponent
                  item={item}
                  parentTranslationPath={parentTranslationPath}
                  translationPath={''}
                  activitiesTypesDetails={userDetails}
                  onValueChanged={(value) => {
                    updateItemValue(item, value);
                  }}
                  setActivitiesTypesDetails={setUserDetails}
                />
              )}
              {item.fieldType === 'date' && (
                <DatePickerComponent
                  value={userDetails[item.fieldKey]}
                  onDateChanged={(value) => updateItemValue(item, value)}
                />
              )}
            </div>
          ))
        )}
      </div>
    </>
  );
}

export default NewValuesViewer;
