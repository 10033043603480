import React, { useState, useCallback , useEffect  } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
 DialogActions, DialogContent, DialogTitle, Dialog, ButtonBase
} from '@material-ui/core';
import { showError, showSuccess, getErrorByName  } from '../../../../Helper';
import { SelectComponet, Spinner , AutocompleteComponent , Inputs  } from '../../../../Components';
import { UpdateBulkAgentsInRotationStatus , GetLookupItemsByLookupTypeName } from '../../../../Services';

export const BulkStatusUpdateDialog = ({
  isOpen,
  onClose,
  parentTranslationPath,
  translationPath,
  onSave,
  agentsId,
}) => {
  const { t } = useTranslation(parentTranslationPath);

  const [isLoading, setIsLoading] = useState(false);
  const [statusValue, setStatusValue] = useState(null);
  const [offStatusReasonList , setOffStatusReasonList] = useState([]) ; 
  const [offStatus , setOffStatus] = useState({ reason : null  , notes : ''  }) ; 


  const onSubmit = async () => {
    let isInRotation = null;
    if (statusValue === 'ON') isInRotation = true;
    if (statusValue === 'OFF') isInRotation = false;
    if (statusValue === 'OFF' && !offStatus.reason )
    {
      showError(t(`${translationPath}please-select-off-status-reason`));
      return ; 
    }
    if (agentsId && (isInRotation !== null)) updateBulkInRotationStatus(isInRotation);
    
  };

  const GetLookupItemsByLookupTypeNameAPI = useCallback(async () => 
  {
     setIsLoading(true);
      const res = await GetLookupItemsByLookupTypeName({ lookUpName : 'OffStatusReason' , pageIndex :1 , pageSize :150});
      if (!((res && res.data && res.data.ErrorId) || !res)) {
        setOffStatusReasonList(res?.result || []);
      } 
      else 
      setOffStatusReasonList([]) ; 
      setIsLoading(false) ; 
  });

  const updateBulkInRotationStatus = useCallback(async (isInRotation) => {
    setIsLoading(true);
    const body = isInRotation ? { agentIds : agentsId, isInRotation , reasonId : null}  :  {agentIds : agentsId, isInRotation , reasonId : (offStatus?.reason?.lookupItemId ) , notes : (offStatus?.notes ) } ;
    const result = await UpdateBulkAgentsInRotationStatus(body);
    if (!(result && result.status && result.status !== 200)) {
      showSuccess(t(`${translationPath}in-rotation-status-bulk-updated-successfully`));
      onSave();
    } else
      showError(t(`${translationPath}bulk-updating-in-rotation-status-has-failed`));

    setIsLoading(false);
  }, [agentsId , offStatus]);

  
  useEffect(() => {
    GetLookupItemsByLookupTypeNameAPI();
  }, []);
  
  return (
    <div>
      <Dialog
        open={isOpen}
        maxWidth='sm'
        onClose={() => onClose()}
        className='activities-management-dialog-wrapper'
        disableBackdropClick
      >
        <DialogTitle id='alert-dialog-slide-title'>{t(`${translationPath}bulk-status-update`)}</DialogTitle>
        <DialogContent>
          <div className='dialog-content-wrapper'>
            <Spinner isActive={isLoading} isAbsolute />
              <div className='dialog-content-item w-100 mt-2 mb-2'>
              <SelectComponet
                data={[
                  { id: 'ON', name: 'on' },
                  { id: 'OFF', name: 'off' },
                ]}
                emptyItem={{ value: null, text: 'select-status', isDisabled: false }}
                value={statusValue}
                valueInput='id'
                textInput='name'
                labelValue='in-rotation-status'
                onSelectChanged={(value) => {
                  setStatusValue(value);
                }}
                wrapperClasses='w-auto'
                themeClass='theme-default test'
                idRef='Lead_Status'
                parentTranslationPath={parentTranslationPath}
                translationPath={translationPath}
                translationPathForData={translationPath}
              />
            </div>
           { statusValue && statusValue === 'OFF' && (
             <>
             <div className='dialog-content-item w-100 mt-2 mb-2'>
             <AutocompleteComponent
                  idRef={`OffStatusReasonRef`}
                  multiple={false}
                  labelValue='OffStatusReason'
                  data={offStatusReasonList}
                  displayLabel={(option) => option.lookupItemName || ''}
                  renderOption={(option) => (option.lookupItemName || '')}   
                  withoutSearchButton
                  isLoading={isLoading}
                  withLoader
                  parentTranslationPath={parentTranslationPath}
                  translationPath={translationPath}
                  onChange={(event, newValue) => 
                  { 
                    setOffStatus((item) => ({ ...item, reason : newValue }));
                  }}
                  inputPlaceholder={'select-off-status-reason'}
                  labelClasses='Requierd-Color'
                  helperText={t(`${translationPath}please-select-off-status-reason`)}
                  error={statusValue && statusValue === 'OFF' && !(offStatus.reason)}
                  isWithError
                />
               </div>  
               <div className='dialog-content-item w-100 mt-2 mb-2'>
               <Inputs
                 idRef='remarkRef'
                 labelValue='remarks'
                 multiline
                 rows={4}
                 parentTranslationPath={parentTranslationPath}
                 translationPath={translationPath}
                 onInputChanged={(event) => 
                 {
                  const value = event && event.target && event.target.value;
                  setOffStatus((item) => ({ ...item,  notes : value  }));

                 }}
               /> 
             </div>  
             </>
           ) } 
          </div>
        </DialogContent>
        <DialogActions>
          <>
            <div className='cancel-wrapper d-inline-flex-center'>
              <ButtonBase
                className='MuiButtonBase-root MuiButton-root MuiButton-text cancel-btn-wrapper btns theme-transparent c-primary'
                tabIndex='0'
                id='cancel-ref-action'
                onClick={() => onClose()}
              >
                <span className='MuiButton-label'>
                  <span>{t(`${translationPath}cancel`)}</span>
                </span>
                <span className='MuiTouchRipple-root' />
              </ButtonBase>
            </div>
            <div className='save-wrapper d-inline-flex-center'>
              <ButtonBase
                className='MuiButtonBase-root MuiButton-root MuiButton-text save-btn-wrapper btns theme-solid bg-primary w-100 mx-2 mb-2'
                tabIndex='0'
                onClick={() => {
                  onSubmit();
                }}
                disabled={!(statusValue === 'ON' || statusValue == 'OFF')}
              >
                <span className='MuiButton-label'>
                  <span>{t(`${translationPath}save`)}</span>
                </span>
                <span className='MuiTouchRipple-root' />
              </ButtonBase>
            </div>
          </>
        </DialogActions>
      </Dialog>
    </div>
  );
};

BulkStatusUpdateDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  setIsLoading: PropTypes.func.isRequired,
};
