
export const PrimaryUnitsPermissions =
{
    ViewPrimaryUnits : {
        permissionsId: "6fdc4e64-6ff6-4021-f462-08dc3da1d8ed",
        permissionsName: "View Primary Units",
        description: null,
        componentsId: null,
        components: null
    },
    AddPrimaryUnits : {
        permissionsId: "f809b3d2-fda9-4dcb-f463-08dc3da1d8ed",
        permissionsName: "Add Primary Units",
        description: null,
        componentsId: null,
        components: null
    },
    SendProposal : {
        permissionsId: "652ad87b-6c44-4057-f464-08dc3da1d8ed",
        permissionsName: "Send Proposal",
        description: null,
        componentsId: null,
        components: null
    } , 
    QuickTransaction :{ 
    components : null ,
    componentsId : null , 
    description : null , 
    permissionsId : "614f6427-61a7-41e5-794a-08dcaf947ea8" , 
    permissionsName : "Quick transaction" 
    }
};
