import React, {
  useState,
  useCallback,
  useReducer,
  useEffect,
  useRef,
} from 'react';
import { useTranslation } from 'react-i18next';
import Joi from 'joi';
import { ButtonBase, DialogActions } from '@material-ui/core';
import { ApplicationUserSearch } from '../../../../Services/userServices';
import {
  AutocompleteComponent,
  CheckboxesComponent,
  DialogComponent,
  SelectComponet,
  Spinner,
} from '../../../../Components';
import {
  GetAdvanceSearchContacts,
  GetLeads,
  ShareInvoice,
} from '../../../../Services';
import { showError, showSuccess } from '../../../../Helper';

export const ShareInvoiceDialog = ({
  isForHistory,
  branchId,
  isOpen,
  closeHandler,
  parentTranslationPath,
  translationPath,
}) => {
  const { t } = useTranslation(parentTranslationPath);
  const searchTimer = useRef(null);

  const reducer = useCallback((state, action) => {
    if (action.id !== 'edit') return { ...state, [action.id]: action.value };
    return {
      ...action.value,
    };
  }, []);
  const [state, setState] = useReducer(reducer, {
    systemUsersId: [],
    contactsId: [],
  });
  const [selected, setSelected] = useReducer(reducer, {
    systemUsersId: [],
    contactsId: [],
  });

  const [isLoading, setIsLoading] = useReducer(reducer, {
    sending: false,
    users: false,
    contacts: false,
  });

  const [data, setData] = useReducer(reducer, {
    users: [],
    contacts: [],
  });

  const getContactsWithMissingEmails = () => {
    const contactsList = selected.contactsId
      ? selected.contactsId.filter((item) => !item.contactEmailAddress)
      : [];

    if (contactsList.length != 0) {
      const contactNamesList = contactsList.map(
        (item) => item.lead?.contact_name?.name
      );
      const contactNamesString = contactNamesList.reduce(
        (result, item) => result + item + ' , ',
        ''
      );
      return contactNamesString;
    }
  };

  const getUsersWithMissingEmails = () => {
    const usersList = selected.systemUsersId
      ? selected.systemUsersId.filter((item) => !item.email)
      : [];

    if (usersList.length != 0) {
      const userNamesList = usersList.map((item) => item.fullName);
      const userNamesString = userNamesList.reduce(
        (result, item) => result + item + ' , ',
        ''
      );
      return userNamesString;
    }
  };

  const schema = Joi.object({
    contactsId: Joi.required()
      .custom((value, helpers) => {
        if (
          state.systemUsersId?.length === 0
        )
          return helpers.error('send-to-error-message-value');

        if (getContactsWithMissingEmails())
          return helpers.error('contacts-With-Missing-Emails-error');
        return value;
      })
      .messages({
        'send-to-error-message-value': t(
          `${translationPath}send-to-is-required`
        ),
        'contacts-With-Missing-Emails-error': `${t(
          `${translationPath}there's-contacts-With-Missing-Emails`
        )} ${getContactsWithMissingEmails()}`,
      }),
    systemUsersId: Joi.required()
      .custom((value, helpers) => {
        if (
          state.systemUsersId?.length === 0
        )
          return helpers.error('send-to-error-message-value');

        if (getUsersWithMissingEmails())
          return helpers.error('users-With-Missing-Emails-error');

        return value;
      })
      .messages({
        'send-to-error-message-value': t(
          `${translationPath}send-to-is-required`
        ),
        'users-With-Missing-Emails-error': `${t(
          `${translationPath}there's-users-With-Missing-Emails`
        )} ${getUsersWithMissingEmails()}`,
      }),
  })
    .options({
      abortEarly: false,
      allowUnknown: true,
    })
    .validate(state);

  const getAllUsers = useCallback(async (searchValue) => {
    setIsLoading({ id: 'users', value: true });
    const res = await ApplicationUserSearch({
      pageIndex: 0,
      pageSize: 25,
      name: searchValue || '',
    });
    if (!(res && res.status && res.status !== 200)) {
      const localValue = (res && res.result) || [];
      if (localValue.length > 0) {
        setData({
          id: 'users',
          value: localValue,
        });
      } else {
        setData({
          id: 'users',
          value: [],
        });
      }

      setIsLoading({ id: 'users', value: false });
    }
  }, []);

  const sendInvoiceByEmail = useCallback(async () => {
    setIsLoading({ id: 'sending', value: true });

    const body = {
      branchId,
      isForHistory,
      body: state.systemUsersId,
    };
    const response = await ShareInvoice(body);
    if (response === true) {
      showSuccess(t('invoice-email-sent-successfully'));
      closeHandler();
    } else {
      showError(t('invoice-email-sent-failed'));
    }

    setIsLoading({ id: 'sending', value: false });
  }, [state.contactsId, state.systemUsersId]);

  const saveHandler = async () => {
    if (schema?.error?.message) {
      showError(schema.error.message);
      return;
    }

    sendInvoiceByEmail();
  };

  useEffect(() => {
    getAllUsers();
  }, []);

  return (
    <DialogComponent
      titleText='share-invoice'
      saveText='send'
      maxWidth='lg'
      dialogContent={
        <div className='share-template-dialog'>
          <Spinner isActive={isLoading.sending} isAbsolute />
          <fieldset className='send-to-fieldset my-3'>
            <legend className='send-to-legend'>
              {t(`${translationPath}send-to`)}
            </legend>
            <div className='my-2'>
              <AutocompleteComponent
                idRef='systemUsersIdRef'
                labelValue='system-users'
                selectedValues={selected.systemUsersId}
                multiple={true}
                data={data.users || []}
                displayLabel={(option) => option.fullName || ''}
                chipsLabel={(option) => option.fullName || ''}
                renderOption={(option) =>
                  ((option.userName || option.fullName) &&
                    `${option.fullName} (${option.userName})`) ||
                  ''
                }
                getOptionSelected={(option) =>
                  selected.systemUsersId.findIndex(
                    (item) => item.usersId === option.usersId
                  ) !== -1 || false
                }
                withoutSearchButton
                isLoading={isLoading.users}
                onKeyDown={() => {
                  setSelected({ id: 'systemUsersId', value: null });
                }}
                onInputKeyUp={(e) => {
                  const searchText = e?.target?.value;
                  if (searchTimer.current) clearTimeout(searchTimer.current);
                  searchTimer.current = setTimeout(() => {
                    getAllUsers(searchText);
                  }, 700);
                }}
                isWithError
                parentTranslationPath={parentTranslationPath}
                translationPath={translationPath}
                onChange={(event, newValue) => {
                  const systemUsersIds =
                    (newValue && newValue.map((item) => item?.usersId)) || [];
                  setState({
                    id: 'systemUsersId',
                    value: systemUsersIds,
                  });
                  setSelected({ id: 'systemUsersId', value: newValue });
                }}
              />
            </div>
          </fieldset>

          <DialogActions>
            <div className='unit-template-action-btns'>
              <ButtonBase
                className='MuiButtonBase-root btns bg-cancel'
                onClick={() => closeHandler()}
              >
                {t(`${translationPath}cancel`)}
              </ButtonBase>
              <ButtonBase
                className='MuiButtonBase-root btns theme-solid '
                onClick={() => saveHandler()}
              >
                {t(`${translationPath}send`)}
                <span className='mdi mdi-send ml-2'></span>
              </ButtonBase>
            </div>
          </DialogActions>
        </div>
      }
      isOpen={isOpen}
      parentTranslationPath={parentTranslationPath}
      translationPath={translationPath}
    />
  );
};
