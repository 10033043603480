import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { DialogComponent } from "../../../../Components";
import { GlobalHistory } from "../../../../Helper";
import { LOGOUt } from "../../../../store/login/Actions";
import NEWNEWS from "../../../../assets/images/defaults/NEWNEWS.png";
import { UpdateUserVersionNumber } from "../../../../Services";
import { config } from "../../../../config";

const translationPath = "userMenuView.";
const parentTranslationPath = "HeaderView";
export const NewReleaseNotification = ({
  isOpen,
  setNewReleaseNotificationState,
  
}) => {
  const { t } = useTranslation("HeaderView");
  const [isOpenRelode, setisOpenRelode] = useState(false);
  const dispatch = useDispatch();
  const versionFromBackend = JSON.parse(
    localStorage.getItem("session")
  ).versionNo;
  const HanrdRelodeFun = useCallback(async () => {
    const res = await UpdateUserVersionNumber(
      JSON.parse(localStorage.getItem("session")).userId,
      config.version
    );

    setTimeout(() => {
      if (!(res && res.status && res.status !== 200)) {
        localStorage.removeItem("session");
        window.localStorage.clear();
        dispatch(LOGOUt());

        GlobalHistory.push("/account/login");

        window.location.reload();
      }
    }, 3000);
  }, [dispatch]);

  return (
    <div className="NewReleaseNotification-DialogComponent">
      <DialogComponent
        contentClasses="NewReleaseNotification-wrapperClasses"
        isOpen={isOpen}
        // onCancelClicked={() => setNewReleaseNotificationState(false)}
        // onCloseClicked={() => setNewReleaseNotificationState(false)}
        SmothMove
        translationPath={translationPath}
        saveText="lestGO"
        parentTranslationPath={parentTranslationPath}
        titleText="Updates-alert"
        saveClasses="btns theme-solid bg-success w-100 mx-2 mb-2"
        onSubmit={(e) => {
          e.preventDefault();
          HanrdRelodeFun();
        }}
        maxWidth="sm"
        dialogContent={
          <>
            <div className="NewReleaseNotification d-flex">
              <div>
                <div className="main-title">
                  {" "}
                  {t(`${translationPath}new-news-s`)}
                </div>{" "}
                <span>
                  {t(`${translationPath}Release`)}{" "}
                  <span className="fw-simi-bold fz-15px c-primary">
                    {" "}
                    {config.version}
                  </span>{" "}
                  {t(`${translationPath}Release2`)}
                </span>
                <span style={{ fontsize: "29px;", color: "white" }}>
                  {"back "}
                  {versionFromBackend} === {"front "}
                  {config.version}{" "}
                </span>
                <div className="read-more-title mt-4">
                  <a
                    href="/share/release-note"
                    target="_blank"
                    rel="release noreferrer"
                  >
                    {" "}
                    {t(`${translationPath}read-more`)}
                  </a>
                </div>
              </div>

              <div>
                {" "}
                <img
                  src={NEWNEWS}
                  alt="arrowImage"
                  className="img-container-NewReleaseNotification"
                />{" "}
              </div>
            </div>
            <div className="save-cancel-wrapper d-flex-v-center-h-end flex-wrap p-2 mt-3">
              <div className="save-wrapper d-inline-flex-center">
                <button
                  className="MuiButtonBase-root MuiButton-root MuiButton-text save-btn-wrapper btns theme-solid bg-success w-100 mx-2 mb-2"
                  type="submit"
                >
                  <span className="MuiButton-label">
                    <span> {t(`${translationPath}lestGO`)}</span>
                  </span>
                  <span className="MuiTouchRipple-root" />
                </button>
              </div>
            </div>
          </>
        }
      />

      <DialogComponent
        isOpen={isOpenRelode}
        translationPath={translationPath}
        onCloseClicked={() => setisOpenRelode(false)}
        saveText="lestdoit"
        parentTranslationPath={parentTranslationPath}
        titleText="fty-d"
        saveClasses="btns theme-solid w-100 mx-2 mb-2"
        onSubmit={(e) => {
          e.preventDefault();
          HanrdRelodeFun();
        }}
        maxWidth="sm"
        dialogContent={
          <>
            <div className="d-flex-column-center">
              <span className="mdi mdi-alert-decagram c-primary mdi-48px" />
              <span
                className="pt-3"
                style={{ textAlignVertical: "center", textAlign: "center" }}
              >{`${t(`${translationPath}Relode-description`)}`}</span>
            </div>
            <div className="save-cancel-wrapper d-flex-v-center-h-end flex-wrap p-2 mt-4">
              <div className="save-wrapper d-inline-flex-center">
                <button
                  className="MuiButtonBase-root MuiButton-root MuiButton-text save-btn-wrapper btns theme-solid w-100 mx-2 mb-2"
                  tabIndex="0"
                  type="submit"
                >
                  <span className="MuiButton-label">
                    <span>
                      {`${t(`${translationPath}lestdoit`)}`}{" "}
                      <span className="mdi mdi-reload" />
                    </span>
                  </span>
                  <span className="MuiTouchRipple-root" />
                </button>
              </div>
            </div>
          </>
        }
      />
    </div>
  );
};
NewReleaseNotification.propTypes = {
  setNewReleaseNotificationState: PropTypes.func.isRequired,
  isOpen: PropTypes.func.isRequired,
};
NewReleaseNotification.defaultProps = {
  //   translationPath: '',
};
