import React, {
  useState,
  useCallback,
  useEffect,
  useRef,
  useReducer,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import { ButtonBase } from '@material-ui/core';
import {
  sideMenuIsOpenUpdate,
  sideMenuComponentUpdate,
  GlobalHistory,
  bottomBoxComponentUpdate,
  getIsAllowedPermission,
  getSideMenuStatus,
  havePermissionToViewDetails,
  havePermissionToEditInformation,
  getSideMenuComponent,
  returnPropsByPermissions,
} from '../../../Helper';

import {
  ActionsButtonsComponent,
  ViewTypes,
  AutocompleteComponent,
  SelectComponet,
  Spinner,
  PaginationComponent,
  PermissionsComponent,
  Inputs,
} from '../../../Components';
import {
  ActionsEnum,
  ViewTypesEnum,
  ContactTypeEnum,
  ActionsButtonsEnum,
  TableActions,
  PropertyStatusEnum,
  TableFilterTypesEnum,
  TableFilterOperatorsEnum,
  DateFilterTypesEnum,
  FormsIdsEnum,
  SearchCriteriaEnum,
  PropertyRatingScoresFilter
} from '../../../Enums';
import {
  CardDetailsComponent,
  PropertiesCardsComponent,
  PropertiesTableComponent,
  PropertiesMapper,
} from './PropertiesUtilities';
import {
  GetAllSearchableFormFieldsByFormId,
  PropertiesAdvanceSearchTest,
  GetAdvanceSearchPropertyManagement,
  GetAllFormFieldsByFormId,
  GetLookupItems,
} from '../../../Services';
import { DatePickerComponent } from '../../../Components';
import { AdvanceSearch } from '../../../SharedComponents/AdvanceSearch/AdvanceSearch';
import { AdvanceSearchSecondVersion } from '../../../SharedComponents/AdvanceSearchSecondVersion';
import { DateRangePickerComponent } from '../../../Components/Controls/DateRangePickerComponent/DateRangePickerComponent';
import { PropertiesImportDialog } from './PropertiesUtilities/Dialogs/PropertiesImportDialog/PropertiesImportDialog';
import { ActiveItemActions } from '../../../store/ActiveItem/ActiveItemActions';
import { GlobalAdvanceSearchActions } from '../../../store/GlobalAdvanceSearch/GlobalAdvanceSearchActions';
import { useTitle } from '../../../Hooks';
import { GlobalOrderFilterActions } from '../../../store/GlobalOrderFilter/GlobalOrderFilterActions';
import { PropertiesPermissionsCRM } from '../../../Permissions/PropertiesPermissions';
import { PropertyManagementListPermissions } from '../../../Permissions/PropertyManagement/PropertyManagementList.Permissions';
import { PropertiesTableHeaderData } from './PropertiesUtilities/PropertiesTableComponent/PropertiesTableHeaderData';
import {
  PropertiesVerticalTabsData,
  PropertiesVerticalTabsData2,
} from '../Common/OpenFileView/OpenFileUtilities/OpenFileData/PropertiesVerticalTabsData';
import { useScrollToElement } from '../../../Hooks/useScrollToElement';
import MapView from './MapView/MapView';
import { object } from 'joi';

const parentTranslationPath = 'PropertiesView';
const translationPath = '';

export const PropertiesView = () => {
  const { t } = useTranslation(parentTranslationPath);
  const pathName = window.location.pathname
    .split('/home/')[1]
    .split('/view')[0];
  useTitle(t('Shared:property-management'));

  const isPropertyManagementView = pathName === 'properties';
  const dispatch = useDispatch();
  const [viewAllData, setViewAllData] = useState(null);
  const [propertyPlanList, setPropertyPlanList] = useState(null);
  const [propertyScores, setPropertyScores] = useState({ fromRating: null, toRating: null, value: null });
  const [displyOpenFileButton, setDisplyOpenFileButton] = useState(false);
  const [displyEditeButton, setDisplyEditeButton] = useState(false);
  const [activeSelectedAction, setActiveSelectedAction] = useState('');
  const [map, setMap] = useState(null);

  const orderFilter = useSelector((state) => state.GlobalOrderFilterReducer);

  const advanceSearch = useSelector(
    (state) => state.GlobalAdvanceSearchReducer
  );
  const [propertiesTableFilter, setPropertiesTableFilter] = useState(
    (advanceSearch && advanceSearch.PropertyFilter) || null
  );
  const reducer = useCallback((state, action) => {
    if (action.id !== 'edit') return { ...state, [action.id]: action.value };
    return {
      ...action.value,
    };
  }, []);
  const [selected, setSelected] = useReducer(reducer, {
    date: null,
 
  });
  const [state, setState] = useReducer(reducer, {
    date: null,

  });
  const [isLoading, setIsLoading] = useState(false);
  const [isAllFormFieldsLoading, setIsAllFormFieldsLoading] = useState(false);
  const [isFirst, setFirst] = useState(false);
  const [isFirst1, setFirst1] = useState(false);
  const [isDatePickerChanged, setIsDatePickerChanged] = useState(false);
  const searchTimer = useRef(null);
  const [advanceSearchBtn, setAdvanceSearchBtn] = useState(true);
  const loginResponse = useSelector((state) => state.login.loginResponse);
  const [activeActionType, setActiveActionType] = useState(
    (localStorage.getItem('ViewType') &&
      JSON.parse(localStorage.getItem('ViewType')).propertiesView) ||
    ViewTypesEnum.cards.key
  );
  const [selectFieldsOnAdvanceSearch, setSelectFieldsOnAdvanceSearch] =
    useState([]);
  const [filterValuesFromAdvanceSearch, setFilterValuesFromAdvanceSearch] =
    useState(null);
  const [isClearFiltersClicked, setIsClearFiltersClicked] = useState(false);
  const [isAdvanceSearchCleared, setIsAdvanceSearchCleared] = useState(false);
  const [isAdvanceSearchActive, setIsAdvanceSearchActive] = useState(false);
  const [isExpanded, setIsExpanded] = useState(
    activeActionType === ViewTypesEnum.cardsExpanded.key
  );
  const [searchedItem, setSearchedItem] = useState('');
  const [, setOrderByToggler] = useState(false);
  const [isSelect] = useState(false);
  const [activeCard, setActiveCard] = useState(null);
  const [isOpenImportDialog, setIsOpenImportDialog] = useState(false);
  const [allFormFields, setAllFormFields] = useState([]);
  const [selectedOrderBy, setSelectedOrderBy] = useState(
    (pathName === 'properties-lease' && {
      filterBy: orderFilter.propertiesLeaseFilter.filterBy,
      orderBy: orderFilter.propertiesLeaseFilter.orderBy,
    }) ||
    (pathName === 'properties-sales' && {
      filterBy: orderFilter.propertiesSalesFilter.filterBy,
      orderBy: orderFilter.propertiesSalesFilter.orderBy,
    }) ||
    (pathName === 'properties' && {
      filterBy: orderFilter.propertiesSalesFilter.filterBy,
      orderBy: orderFilter.propertiesSalesFilter.orderBy,
    }) ||
    (pathName === 'Properties-CRM' && {
      filterBy:
        (orderFilter &&
          orderFilter.propertiesCrmFilter &&
          orderFilter.propertiesCrmFilter.filterBy) ||
        null,
      orderBy: orderFilter?.propertiesCrmFilter?.orderBy || null,
    })
  );

  const dateRangeDefault = {
    startDate: null,
    endDate: null,
    key: 'selection',
    selectedDateType: 1,
  };
  const [dateFilter, setDateFilter] = useState(
    (orderFilter &&
      orderFilter.PropertyFilterDate &&
      orderFilter.PropertyFilterDate.startDate &&
      orderFilter.PropertyFilterDate.endDat !== null && {
      startDate: new Date(
        (orderFilter &&
          orderFilter.PropertyFilterDate &&
          orderFilter.PropertyFilterDate.startDate) ||
        null
      ),
      endDate: new Date(
        (orderFilter &&
          orderFilter.PropertyFilterDate &&
          orderFilter.PropertyFilterDate.endDate) ||
        null
      ),
      key: new Date(
        (orderFilter &&
          orderFilter.PropertyFilterDate &&
          orderFilter.PropertyFilterDate.key) ||
        null
      ),
      selectedDateType:
        (orderFilter &&
          orderFilter.PropertyFilterDate &&
          orderFilter.PropertyFilterDate.selectedDateType) ||
        1,
    }) ||
    dateRangeDefault
  );

  const [orderBy, setOrderBy] = useState(
    selectedOrderBy.filterBy
      ? selectedOrderBy
      : { filterBy: 'createdOn', orderBy: 2 }
  );

  const [checkedDetailedCards, setCheckedDetailedCards] = useState([]);
  const [location, setLocation] = useState(null);
  const [detailsPropertiesList, setDetailsPropertiesList] = useState(() => ({
    result: [],
    totalCount: 0,
  }));
  const { setHash } = useScrollToElement(!!detailsPropertiesList.result.length);
  const [searchableFormFields, setSearchableFormFields] = useState([]);
  const [searchData, setSearchData] = useState([]);
  const [filterSearchDto, setFilterSearchDto] = useState(null);
  const [checkedCards, setCheckedCards] = useState([]);
  const [searchInputValue, setSearchInputValue] = useState('');
  const [activeFormType, setActiveFormType] = useState(0);
  const [puplishStatus, setPuplishStatus] = useState(0);
  const [filter, setFilter] = useState({
    pageSize: parseInt(localStorage.getItem('Pagination')) || 25,
    pageIndex:
      JSON.parse(localStorage.getItem('propertiesCrmCurrentPageIndex')) || 0,
    search: '',
  });
  // End New Code

  const changeActiveFormType = (value) => {
    setActiveFormType(value);
  };
  const changepuplishStatus = (value) => {
    setPuplishStatus(value);
  };

  const changePropertyRatingScoresFilter = (value) => {
    let rating = null;
    if (value)
      rating = PropertyRatingScoresFilter[value];
    setPropertyScores((r) => ({ ...r, fromRating: (value ? (rating && rating.from) || 0 : null), toRating: (value ? rating && rating.to : null), value: value }));

  };

  const onTypeChanged = useCallback(
    (activeType) => {
      const ViewTypeData = JSON.parse(localStorage.getItem('ViewType'));
      if (ViewTypeData) {
        ViewTypeData.propertiesView = activeType;
        localStorage.setItem('ViewType', JSON.stringify(ViewTypeData));
      } else
        localStorage.setItem(
          'ViewType',
          JSON.stringify({ propertiesView: activeType })
        );

      setActiveActionType(activeType);
      setIsExpanded(activeType === ViewTypesEnum.cardsExpanded.key);
      sideMenuIsOpenUpdate(false);
      sideMenuComponentUpdate(null);
    },
    [setActiveActionType]
  );
  const onPageIndexChanged = (pageIndex) => {
    localStorage.setItem(
      'propertiesCrmCurrentPageIndex',
      JSON.stringify(pageIndex)
    );
    setFilter((item) => ({ ...item, pageIndex }));
    setActiveCard(null);
    sideMenuComponentUpdate(null);
    sideMenuIsOpenUpdate(false);
  };

  const restorePageIndex = useCallback(() => {
    const currentPageIndex = localStorage.getItem(
      'propertiesCrmCurrentPageIndex'
    );

    if (currentPageIndex) {
      const pageIndex = JSON.parse(currentPageIndex);
      setFilter((item) => ({ ...item, pageIndex }));
    }
  }, []);

  useEffect(() => {
    restorePageIndex();
  }, []);

  const onPageSizeChanged = (pageSize) => {
    setFilter((item) => ({ ...item, pageIndex: 0, pageSize }));
    setActiveCard(null);
    sideMenuComponentUpdate(null);
    sideMenuIsOpenUpdate(false);
  };
  const filterOnChange = (event, newValue) => {
    const emptyKeyIndex = newValue.findIndex((item) => !item.value);
    if (!searchInputValue && emptyKeyIndex !== -1) {
      newValue.splice(emptyKeyIndex, 1);
      return;
    }
    if (emptyKeyIndex !== -1) newValue[emptyKeyIndex].value = searchInputValue;
    if (
      filterSearchDto &&
      Object.keys(filterSearchDto).length > 0 &&
      newValue.length === 0
    ) {
      onPageIndexChanged(0);
      setFilterSearchDto(null);
    }
    setSearchData([...newValue]);
  };

  const searchAdvanceSearch = (newFilter) => {
    const searchObj = newFilter ? { ...newFilter } : null;
    if (!(newFilter && newFilter.length))
      localStorage.removeItem('PropertyFilter');

    dispatch(
      GlobalAdvanceSearchActions.globalAdvanceSearchRequest({
        ...advanceSearch,
        PropertyFilter: searchObj || null,
      })
    );
    setPropertiesTableFilter(searchObj);
  };

  const getAllSearchableFormFieldsByFormId = useCallback(async () => {
    const result = await GetAllSearchableFormFieldsByFormId(3);
    if (!(result && result.status && result.status !== 200)) {
      const list = [];
      list.push({ key: 'All', title: 'All' });
      list.push({ key: 'Ids', title: 'Property Id' });
      list.push({ key: 'Address', title: 'Address' });
      result
        .filter((item) => item.isSearchable)
        .map((item) =>{
          if (item.searchableKey !== 'map' && item.formFieldTitle !== 'Map')
          list.push({
            key: item.searchableKey,
            title: item.formFieldTitle,
          })
        }
        );
      setSearchableFormFields(list);
    } else setSearchableFormFields([]);
  }, []);
  const getAllFormFieldsByFormId = useCallback(async () => {
    setIsAllFormFieldsLoading(true);
    const result = await GetAllFormFieldsByFormId(FormsIdsEnum.properties.id);
    if (
      !((result && result.data && result.data.ErrorId) || !result) &&
      Array.isArray(result)
    ) {
      const list = result
        .filter(
          (e) =>
            e.formFieldName !== 'amenities' &&
            e.formFieldName !== 'map' &&
            e.formFieldName !== 'facilities' &&
            e.formFieldName !== 'property_name' &&
            e.formFieldName !== 'property_owner' &&
            e.formFieldName !== 'unit_types_in_property'
        )
        .map((field) => ({
          id: field.formFieldId || null,
          key: field.formFieldKey || null,
          isDate: field.uiWidgetType === 'alt-date' || false,
          label:
            (field.formFieldTitle && field.formFieldTitle.replace('*', '')) ||
            '',
          input: field.displayPath || '',
          isNumber:
            (field.propertyJson &&
              JSON.parse(field.propertyJson).schema &&
              field.propertyJson &&
              JSON.parse(field.propertyJson).schema.specialKey ===
              'currency') ||
            (field.propertyJson &&
              JSON.parse(field.propertyJson).schema &&
              field.propertyJson &&
              JSON.parse(field.propertyJson).schema.specialKey === 'decimal') ||
            (field.propertyJson &&
              JSON.parse(field.propertyJson).schema &&
              field.propertyJson &&
              JSON.parse(field.propertyJson).schema.specialKey === 'size'),
          isSortable: true,
          searchableKey: field.searchableKey,
          data: JSON.parse(field.propertyJson).schema,
          fieldType:
            (field.propertyJson &&
              JSON.parse(field.propertyJson).schema.uiType) ||
            (field.propertyJson &&
              JSON.parse(field.propertyJson).uiSchema['ui:widget']) ||
            (field.uiWidgetType === null && 'text'),
        }));
      setAllFormFields([...list]);
    } else setAllFormFields([]);
    setIsAllFormFieldsLoading(false);
  }, []);

  const getLookupItem = useCallback(async () => {
    setIsAllFormFieldsLoading(true);
    const res = await GetLookupItems(1, 25, 'Property Plan');
    if (!(res && res.data && res.data.ErrorId)) {
      setPropertyPlanList(res && res.result);
    } else setPropertyPlanList([]);
  }, []);

  const getPropertiesData = useCallback(
    async (f) => {
      if (activeActionType === ViewTypesEnum.map.key && !map) return;
      setIsLoading(true);
      if (
        activeActionType === ViewTypesEnum.map.key &&
        filter.pageSize !== 1000
      ) {
        setFilter((item) => ({ ...item, pageSize: 1000 }));
        filter.pageSize = 1000;
      } else if (
        activeActionType !== ViewTypesEnum.map.key &&
        filter.pageSize !== +localStorage.getItem('Pagination')
      ) {
        setFilter((item) => ({
          ...item,
          pageSize: +localStorage.getItem('Pagination'),
        }));
      }
      const isViewAllData =
        pathName === 'properties'
          ? returnPropsByPermissions(
            PropertyManagementListPermissions
              .ViewandsearchinPropertyManagementProperties.permissionsId
          )
          : returnPropsByPermissions(
            PropertiesPermissionsCRM.ViewAndSearchProperties.permissionsId
          );
      if (isViewAllData) {
        const localFilterDto = f ?? filterSearchDto ?? {};

        if (propertyScores && propertyScores.value) {

          localFilterDto['fromRating'] = [
            {
              searchType: TableFilterOperatorsEnum.greaterThan.key,
              value: propertyScores.value ? propertyScores && propertyScores.fromRating : null,
            },
          ];
          localFilterDto['toRating'] = [
            {
              searchType: TableFilterOperatorsEnum.lessThan.key,
              value: (propertyScores && propertyScores.toRating) || null,
            },
          ];
        }

        if (activeFormType) {
          localFilterDto['property_plan.lookupItemId'] = [
            {
              searchType: TableFilterOperatorsEnum.equal.key,
              value: activeFormType,
            },
          ];
        }
        if (puplishStatus === 1) {
          localFilterDto['webPublished'] = [
            {
              searchType: TableFilterOperatorsEnum.equal.key,
              value: true,
            },
          ];
        }
        if (puplishStatus === 2) {
          localFilterDto['webPublished'] = [
            {
              searchType: TableFilterOperatorsEnum.equal.key,
              value: false,
            },
          ];
        }
        if (state.date) {
          localFilterDto['webPublishedDate'] = [
            {
              searchType: TableFilterOperatorsEnum.equalData.key,
              value: state.date,
            },
          ];
        }
        if (propertiesTableFilter) {
          Object.values(propertiesTableFilter)
            .filter((item) => item.searchableKey || item.displayPath)
            .map((item) => {
              if (localFilterDto[item.searchableKey || item.displayPath]) {
                localFilterDto[item.searchableKey || item.displayPath].push({
                  searchType: item.operator,
                  value: item.value,
                });
              } else if (item.value) {
                localFilterDto[item.searchableKey || item.displayPath] = [
                  {
                    searchType: item.operator,
                    value: item.value,
                  },
                ];
              } else if (
                !item.value &&
                (item.operator === TableFilterOperatorsEnum.isNotBlank.key ||
                  item.operator === TableFilterOperatorsEnum.isBlank.key)
              ) {
                localFilterDto[item.searchableKey || item.displayPath] = [
                  {
                    searchType: item.operator,
                    value: null,
                  },
                ];
              }
              return undefined;
            });
        }
        if (Object.values(localFilterDto).length)
          setIsAdvanceSearchActive(true);
        else setIsAdvanceSearchActive(false);
        const body = {
          criteria: localFilterDto,
          ...orderBy,
          map,
        };

        setIsDatePickerChanged(false);

        if (dateFilter && dateFilter.startDate && dateFilter.endDate) {
          const fromDate = moment(dateFilter.startDate)
            .startOf('day')
            .format('YYYY-MM-DDTHH:mm:ss');
          const toDate = moment(dateFilter.endDate).format(
            'YYYY-MM-DDTHH:mm:ss'
          );

          if (
            dateFilter.selectedDateType ===
            DateFilterTypesEnum.CreatedOnDate.key
          ) {
            body.fromDate = fromDate;
            body.toDate = toDate;
          } else if (
            dateFilter.selectedDateType ===
            DateFilterTypesEnum.UpdatedOnDate.key
          ) {
            body.updatedFromDate = fromDate;
            body.updatedToDate = toDate;
          }
        }

        const res =
          pathName === 'properties'
            ? await GetAdvanceSearchPropertyManagement(filter, body)
            : await PropertiesAdvanceSearchTest(filter, body);
        if (!(res && res.status && res.status !== 200)) {
          setDetailsPropertiesList({
            result: ((res && res.result) || []).map(
              (item) =>
                item.propertyJson &&
                PropertiesMapper(item, JSON.parse(item.propertyJson).property)
            ),
            totalCount: (res && res.totalCount) || 0,
          });
        } else {
          setDetailsPropertiesList({
            result: [],
            totalCount: 0,
          });
        }
      }
      setIsLoading(false);
    },
    [
      activeFormType,
      puplishStatus,
      filter,
      filterSearchDto,
      dateFilter,
      orderBy,
      pathName,
      propertiesTableFilter,
      map,
      state,
      selectedOrderBy,
      propertyScores
    ]
  );
  
  const searchHandler = (data) => {
    const newValue = [
      {
        key: 'Address',
        title: 'Address',
        value: data,
      },
    ];

    if (searchTimer.current) clearTimeout(searchTimer.current);
    if (data === '') {
      const index = searchData.findIndex((item) => item.key === 'All');
      if (index !== -1) searchData.splice(index, 1);
      setFilterSearchDto(null);
      localStorage.removeItem('PropertiesFilter');
    } else {
      searchTimer.current = setTimeout(() => {
        setSearchData([...newValue]);
        setFilterSearchDto({
          All: [
            {
              value: data,
            },
          ],
        });
      }, 1300);
    }
  };
  const searchClicked = async () => {
    if (searchData.length === 0) return;
    localStorage.setItem('PropertiesFilter', JSON.stringify(searchData));
    setFilterSearchDto(
      searchData.reduce((total, item) => {
        if (total[item.key]) {
          total[item.key].push({
            searchType: TableFilterTypesEnum.textInput.defaultSelectedOperator,
            value: item.value,
          });
        } else {
          total[item.key] = [
            {
              searchType:
                TableFilterTypesEnum.textInput.defaultSelectedOperator,
              value: item.value,
            },
          ];
        }
        return total;
      }, {})
    );
    onPageIndexChanged(0);
  };

  const searchchachedClickedWithoutFilter = async (data) => {
    if (data.length === 0) return;
    const oldfilter = data.reduce((total, item) => {
      if (total[item.key]) {
        total[item.key].push({
          searchType: TableFilterTypesEnum.textInput.defaultSelectedOperator,
          value: item.value,
        });
      } else {
        total[item.key] = [
          {
            searchType: TableFilterTypesEnum.textInput.defaultSelectedOperator,
            value: item.value,
          },
        ];
      }
      return total;
    }, {});
    getPropertiesData(oldfilter);
  };

  const tableActionClicked = useCallback(
    (actionEnum, item) => {
      dispatch(ActiveItemActions.activeItemRequest(item));
      if (actionEnum === TableActions.openFile.key) {
        if (pathName === 'properties-sales') {
          GlobalHistory.push(
            `/home/properties-sales/property-profile-edit?formType=${item.propertyTypeId}&id=${item.id}`
          );
        } else if (pathName === 'properties-lease') {
          GlobalHistory.push(
            `/home/properties-lease/property-profile-edit?formType=${item.propertyTypeId}&id=${item.id}`
          );
        } else if (pathName === 'Properties-CRM') {
          GlobalHistory.push(
            `/home/Properties-CRM/property-profile-edit?formType=${item.propertyTypeId}&id=${item.id}`
          );
        }
      } else if (actionEnum === TableActions.editText.key) {
        if (pathName === 'properties-sales') {
          GlobalHistory.push(
            `/home/properties-sales/edit?formType=${item.propertyTypeId}&id=${item.id}`
          );
        } else if (pathName === 'properties-lease') {
          GlobalHistory.push(
            `/home/properties-lease/edit?formType=${item.propertyTypeId}&id=${item.id}`
          );
        } else if (pathName === 'Properties-CRM') {
          GlobalHistory.push(
            `/home/Properties-CRM/edit?formType=${item.propertyTypeId}&id=${item.id}`
          );
        }
      }
    },
    [dispatch, pathName]
  );
  const displayedLabel = (option) => `${option.title}: ${searchInputValue}`;
  const disabledOptions = (option) => option.disabledOnSelect;
  const chipsLabel = (option) => `${option.title}: ${option.value}`;
  // const chipsDisabled = (option) => option.disabledOnTag;
  const inputValueChanged = (event, newInputValue) => {
    setSearchInputValue(newInputValue);
  };
  const onActionsButtonClicked = (activeAction) => {
    if (activeAction === ActionsButtonsEnum[3].id) setIsOpenImportDialog(true);
    if (activeAction === ActionsButtonsEnum[1].id) {
      if (pathName === 'properties-sales')
        GlobalHistory.push('/home/properties-sales/add');
      else if (pathName === 'properties')
        GlobalHistory.push('/home/properties/add');
      else if (pathName === 'Properties-CRM')
        GlobalHistory.push('/home/Properties-CRM/add');
      else GlobalHistory.push('/home/properties-lease/add');
    } else if (activeAction === ActionsButtonsEnum[4].id) {
      GlobalHistory.push('/home/Properties-CRM/property-bulk-update');
    }
  };
  const onActionButtonChanged = (activeAction) => {
    setActiveSelectedAction(activeAction);
    setCheckedCards([]);
  };

  const detailedCardSideActionClicked = useCallback(
    (actionEnum, activeData) => async (event) => {
      setHash(activeData.id);
      event.stopPropagation();
      dispatch(ActiveItemActions.activeItemRequest(activeData));
      if (actionEnum === ActionsEnum.reportEdit.key) {
        if (pathName === 'properties-sales') {
          GlobalHistory.push(
            `/home/properties-sales/edit?formType=${activeData.propertyTypeId}&id=${activeData.id}`
          );
        } else if (pathName === 'properties') {
          GlobalHistory.push(
            `/home/properties/edit?formType=${activeData.propertyTypeId}&id=${activeData.id}`
          );
        } else if (pathName === 'Properties-CRM') {
          GlobalHistory.push(
            `/home/Properties-CRM/edit?formType=${activeData.propertyTypeId}&id=${activeData.id}`
          );
        } else {
          GlobalHistory.push(
            `/home/properties-lease/edit?formType=${activeData.propertyTypeId}&id=${activeData.id}`
          );
        }
      } else if (actionEnum === ActionsEnum.folder.key) {
        if (pathName === 'properties-sales') {
          GlobalHistory.push(
            `/home/properties-sales/property-profile-edit?formType=${activeData.propertyTypeId}&id=${activeData.id}`
          );
        } else if (pathName === 'properties') {
          GlobalHistory.push(
            `/home/properties/property-profile-edit?formType=${activeData.propertyTypeId}&id=${activeData.id}`
          );
        } else if (pathName === 'Properties-CRM') {
          GlobalHistory.push(
            `/home/Properties-CRM/property-profile-edit?formType=${activeData.propertyTypeId}&id=${activeData.id}`
          );
        } else {
          GlobalHistory.push(
            `/home/properties-lease/property-profile-edit?formType=${activeData.propertyTypeId}&id=${activeData.id}`
          );
        }
      }
    },
    [dispatch, pathName]
  );
  const filterByChanged = (value) => {
    setSelectedOrderBy((item) => ({ ...item, filterBy: value }));
  };
  const orderByChanged = (value) => {
    setSelectedOrderBy((item) => ({ ...item, orderBy: value }));
  };
  const orderBySubmitted = (event) => {
    event.preventDefault();
    if (!selectedOrderBy.filterBy || !selectedOrderBy.orderBy) {
      if (orderBy.filterBy || orderBy.orderBy) setOrderBy({});
      return;
    }
    dispatch(
      GlobalOrderFilterActions.globalOrderFilterRequest({
        ...orderFilter,
        propertiesCrmFilter: {
          filterBy: selectedOrderBy.filterBy,
          orderBy: selectedOrderBy.orderBy,
        },
      })
    );
    setOrderBy({
      filterBy: selectedOrderBy.filterBy,
      orderBy: selectedOrderBy.orderBy,
    });
    setOrderByToggler(false);
  };
  const cardCheckboxClicked = useCallback((itemIndex, element) => {
    setCheckedDetailedCards((items) => {
      const index = items.findIndex((item) => item === element.id);
      if (index !== -1) items.splice(index, 1);
      else items.push(element.id);
      return [...items];
    });

    setCheckedCards((items) => {
      const index = items.findIndex((item) => item.id === element.id);
      if (index !== -1) items.splice(index, 1);
      else items.push(element);
      return [...items];
    });
  }, []);

  useEffect(() => {
    localStorage.setItem('bulk-assign-ids', JSON.stringify(checkedCards));
  }, [checkedCards]);
  const reloadData = useCallback(
    (pageSize) => {
      setFilter((item) => ({ ...item, pageIndex: 0, pageSize }));
      setActiveCard(null);
      getPropertiesData();
    },
    [getPropertiesData]
  );

  const onCardClick = useCallback(
    (item, selectedIndex) => (event) => {
      event.stopPropagation();
      event.preventDefault();
      setActiveCard(item);
      if (getSideMenuStatus()) setAdvanceSearchBtn(true);
      dispatch(ActiveItemActions.activeItemRequest(item));
      sideMenuComponentUpdate(
        <CardDetailsComponent
          activeData={detailsPropertiesList.result[selectedIndex]}
          cardDetailsActionClicked={detailedCardSideActionClicked}
          loginResponse={loginResponse}
          relodedata={reloadData}
          displyOpenFileButton={displyOpenFileButton}
          displyEditeButton={displyEditeButton}
        />
      );
      sideMenuIsOpenUpdate(true);
    },
    [
      detailedCardSideActionClicked,
      detailsPropertiesList.result,
      loginResponse,
      reloadData,
    ]
  );
  const onFilterValuesChanged = (newValue) => {
    setPropertiesTableFilter(newValue);
  };

  const checkBulkDisabled = (enums) => {
    if (enums === ActionsButtonsEnum[4].id)
      return !(checkedCards && checkedCards.length >= 2);

    return false;
  };

  const onSelectClicked = useCallback(
    (row, rowIndex) => {
      setCheckedCards((items) => {
        const index = items.findIndex((item) => item.id === row.id);
        if (index !== -1) items.splice(index, 1);
        else items.push(row);
        return [...items];
      });
    },
    [checkedCards]
  );

  const getIsSelected = useCallback(
    (row) =>
      checkedCards &&
      checkedCards.findIndex((item) => item.id === row.id) !== -1,
    [checkedCards]
  );
  const focusedRowChanged = useCallback(
    (rowIndex) => {
      if (rowIndex !== -1) {
        sideMenuComponentUpdate(
          <CardDetailsComponent
            activeData={detailsPropertiesList.result[rowIndex]}
            cardDetailsActionClicked={detailedCardSideActionClicked}
            loginResponse={loginResponse}
            relodedata={reloadData}
            displyOpenFileButton={displyOpenFileButton}
            displyEditeButton={displyEditeButton}
          />
        );
        sideMenuIsOpenUpdate(true);
      } else {
        sideMenuComponentUpdate(<></>);
        sideMenuIsOpenUpdate(false);
      }
    },
    [
      detailedCardSideActionClicked,
      detailsPropertiesList.result,
      loginResponse,
      reloadData,
      advanceSearchBtn,
    ]
  );

  const GetSideMenuStatus = useCallback(() => {
    const isMenuOpen = getSideMenuStatus();
    const activeComponentOpen =
      getSideMenuComponent() && getSideMenuComponent().type;

    if (!isMenuOpen) setAdvanceSearchBtn(true);
    else if (activeComponentOpen === AdvanceSearch) setAdvanceSearchBtn(false);
  });

  const onAdvanceSearchClick = useCallback(
    (event) => {
      event.stopPropagation();
      event.preventDefault();

      if (advanceSearchBtn) {
        sideMenuIsOpenUpdate(true);
        setAdvanceSearchBtn(false);
      } else {
        sideMenuIsOpenUpdate(false);
        setAdvanceSearchBtn(true);
      }

      sideMenuComponentUpdate(
        <AdvanceSearchSecondVersion
          filterData={[...PropertiesTableHeaderData, ...allFormFields]}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          onFilterValuesChanged={onFilterValuesChanged}
          localStorageKey='PropertyFilter'
          isAdvanceSearchCleared={isAdvanceSearchCleared}
          setIsAdvanceSearchCleared={setIsAdvanceSearchCleared}
          setOrderBy={setOrderBy}
          searchCriteriaTypeId={SearchCriteriaEnum.Property.typeId}
          setIsAdvanceSearchActive={setIsAdvanceSearchActive}
          fields={[...PropertiesTableHeaderData, ...allFormFields]}
          selectFieldsOnAdvanceSearch={selectFieldsOnAdvanceSearch}
          setSelectFieldsOnAdvanceSearch={setSelectFieldsOnAdvanceSearch}
          setFilterValuesFromAdvanceSearch={setFilterValuesFromAdvanceSearch}
          filterValuesFromAdvanceSearch={filterValuesFromAdvanceSearch}
          searchAdvanceSearch={searchAdvanceSearch}
        />
      );
    },
    [
      isAllFormFieldsLoading,
      allFormFields,
      advanceSearchBtn,
      isAdvanceSearchCleared,
      selectFieldsOnAdvanceSearch,
    ]
  );

  const onClearedAllFiltersClick = useCallback(() => {
    setSelectedOrderBy((item) => ({ ...item, filterBy: null, orderBy: null }));
    setOrderBy({ filterBy: 'createdOn', orderBy: 2 });
    setSearchData([]);
    setDateFilter(dateRangeDefault);
    changeActiveFormType(0);
    setSelected({
      id: "edit",
      value: {
        date: null,
        propertyRating: null
      },
    });
    setState({
      id: "edit",
      value: {
        date: null,
        propertyRating: null
      },
    });
    setState({ id: 'date', value: null });
    setIsClearFiltersClicked(true);
    setIsAdvanceSearchCleared(true);
    setPropertiesTableFilter(null);
    setFilterSearchDto(null);
    sideMenuIsOpenUpdate(false);
    setIsAdvanceSearchActive(false);
    setFilterValuesFromAdvanceSearch();
    setSelectFieldsOnAdvanceSearch([]);
    setPuplishStatus(0);
    setLocation(null);
    dispatch(
      GlobalOrderFilterActions.globalOrderFilterRequest({
        ...orderFilter,
        propertiesCrmFilter: {
          filterBy: null,
          orderBy: null,
        },
      })
    );
    setPropertyScores((r) => ({ ...r, fromRating: null, toRating: null, value: null }));

    dispatch(
      GlobalAdvanceSearchActions.globalAdvanceSearchRequest({
        ...advanceSearch,
        PropertyFilter: null,
      })
    );
  }, []);

  const onOpenDuplicatedPage = () => {
    GlobalHistory.push('/home/Properties-CRM/duplicated-properties');
  };

  useEffect(() => {
    if (pathName === 'Properties-CRM')
      localStorage.setItem('PropertiesPropertyManagement', false);
    else if (pathName === 'properties')
      localStorage.setItem('PropertiesPropertyManagement', true);
  }, [pathName]);

  useEffect(() => {
    getLookupItem();
  }, [getLookupItem]);

  useEffect(() => {
    getAllSearchableFormFieldsByFormId();
  }, [getAllSearchableFormFieldsByFormId]);
  useEffect(() => {
    const isViewAllData =
      pathName === 'properties'
        ? returnPropsByPermissions(
          PropertyManagementListPermissions
            .ViewandsearchinPropertyManagementProperties.permissionsId
        )
        : returnPropsByPermissions(
          PropertiesPermissionsCRM.ViewAndSearchProperties.permissionsId
        );
    if (isViewAllData && activeActionType !== ViewTypesEnum.map.key) {
      const currentPageIndex = localStorage.getItem(
        'propertiesCrmCurrentPageIndex'
      );
      const pageIndex = JSON.parse(currentPageIndex);
      bottomBoxComponentUpdate(
        <PaginationComponent
          pageIndex={filter.pageIndex}
          pageSize={filter.pageSize}
          totalCount={detailsPropertiesList.totalCount}
          onPageIndexChanged={onPageIndexChanged}
          onPageSizeChanged={onPageSizeChanged}
        />
      );
    }
  });

  useEffect(
    () => () => {
      bottomBoxComponentUpdate(null);
      sideMenuComponentUpdate(null);
      sideMenuIsOpenUpdate(false);
    },
    []
  );

  useEffect(() => {
    const data = localStorage.getItem('PropertiesFilter');

    if (data) {
      setSearchData(JSON.parse(data));
      searchchachedClickedWithoutFilter(JSON.parse(data));
    } else getPropertiesData();
  }, []);

  useEffect(() => {
    if (isFirst1) {
      if (searchData && searchData.length === 0) {
        localStorage.removeItem('PropertiesFilter');
        setSearchedItem('');
      } else
        localStorage.setItem('PropertiesFilter', JSON.stringify(searchData));
    } else setFirst1(true);
  }, [searchData]);

  useEffect(() => {
    if (!isFirst) setFirst(true);
    else {
      const data = localStorage.getItem('PropertiesFilter');
      if (data) searchchachedClickedWithoutFilter(JSON.parse(data));
      else if (searchData && searchData.length === 0) getPropertiesData();
    }
  }, [
    activeFormType,
    puplishStatus,
    filter,
    filterSearchDto,
    orderBy,
    pathName,
    propertiesTableFilter,
    searchData,
    map,
    state,
    propertyScores
  ]);

  useEffect(() => {
    if (isFirst && isDatePickerChanged) {
      const searchDataFilter = searchData.reduce((total, item) => {
        if (total[item.key]) {
          total[item.key].push({
            searchType: TableFilterTypesEnum.textInput.defaultSelectedOperator,
            value: item.value,
          });
        } else {
          total[item.key] = [
            {
              searchType:
                TableFilterTypesEnum.textInput.defaultSelectedOperator,
              value: item.value,
            },
          ];
        }
        return total;
      }, {});
      getPropertiesData(searchDataFilter);
    }
  }, [dateFilter, isDatePickerChanged, searchData]);

  useEffect(() => {
    dispatch(
      GlobalOrderFilterActions.globalOrderFilterRequest({
        ...orderFilter,
        PropertyFilterDate: dateFilter || dateRangeDefault,
      })
    );
    // getPropertiesData();
  }, [dateFilter]);

  useEffect(() => {
    getAllFormFieldsByFormId();
  }, [getAllFormFieldsByFormId]);

  useEffect(() => {
    GetSideMenuStatus();
  }, [GetSideMenuStatus]);

  useEffect(() => {
    const openFileWithPermissions = !isPropertyManagementView
      ? PropertiesVerticalTabsData
      : PropertiesVerticalTabsData2;
    const editFileWithPermissions = !isPropertyManagementView
      ? PropertiesPermissionsCRM.EditPropertyDetails.permissionsId
      : PropertyManagementListPermissions.UpdateProperty.permissionsId;
    setDisplyOpenFileButton(
      havePermissionToViewDetails(openFileWithPermissions)
    );
    setDisplyEditeButton(
      havePermissionToEditInformation(editFileWithPermissions)
    );
  }, [isFirst]);

  useEffect(() => {
    if (
      map &&
      !searchData.length &&
      activeActionType === ViewTypesEnum.map.key
    ) {
      getPropertiesData();
    }
  }, [activeActionType]);

  useEffect(() => {
    if (activeActionType !== ViewTypesEnum.map.key) {
      setMap(null);
    }
  }, [activeActionType]);

  return (
    <div className='view-wrapper property-view'>
      <Spinner
        isActive={isLoading && ViewTypesEnum.map.key !== activeActionType}
      />
      <div className='d-flex-column'>
        <div className='header-section'>
          <div className='filter-section'>
            <div className='section'>
              <ActionsButtonsComponent
                permissionsList={
                  !isPropertyManagementView
                    ? Object.values(PropertiesPermissionsCRM)
                    : Object.values(PropertyManagementListPermissions)
                }
                addPermissionsId={
                  !isPropertyManagementView
                    ? PropertiesPermissionsCRM.AddNewProperty.permissionsId
                    : PropertyManagementListPermissions.AddNewProperty
                      .permissionsId
                }
                selectPermissionsId={
                  !isPropertyManagementView
                    ? [
                      PropertiesPermissionsCRM.AddNewProperty.permissionsId,
                      PropertiesPermissionsCRM.ImportProperty.permissionsId,
                    ]
                    : [
                      PropertyManagementListPermissions.ImportProperty
                        .permissionsId,
                      PropertyManagementListPermissions.AddNewProperty
                        .permissionsId,
                    ]
                }
                enableImport={
                  !isPropertyManagementView
                    ? getIsAllowedPermission(
                      Object.values(PropertiesPermissionsCRM),
                      loginResponse,
                      PropertiesPermissionsCRM.ImportProperty.permissionsId
                    )
                    : getIsAllowedPermission(
                      Object.values(PropertyManagementListPermissions),
                      loginResponse,
                      PropertyManagementListPermissions.ImportProperty
                        .permissionsId
                    )
                }
                enableBulk
                onActionsButtonClicked={onActionsButtonClicked}
                onActionButtonChanged={onActionButtonChanged}
                checkDisable={checkBulkDisabled}
              />
            </div>
            <div className='section autocomplete-section'>
              <div className='d-flex-column px-2 w-100 p-relative'>
                <PermissionsComponent
                  permissionsList={
                    !isPropertyManagementView
                      ? Object.values(PropertiesPermissionsCRM)
                      : Object.values(PropertyManagementListPermissions)
                  }
                  permissionsId={
                    !isPropertyManagementView
                      ? PropertiesPermissionsCRM.ViewAndSearchProperties
                        .permissionsId
                      : PropertyManagementListPermissions
                        .ViewandsearchinPropertyManagementProperties
                        .permissionsId
                  }
                >
                  <div className='d-flex-column w-100'>
                    <AutocompleteComponent
                      data={searchableFormFields.map((item) => ({
                        key: item.key,
                        title: item.title,
                      }))}
                      selectedValues={searchData}
                      displayLabel={displayedLabel}
                      disabledOptions={disabledOptions}
                      onChange={filterOnChange}
                      searchClicked={searchClicked}
                      chipsLabel={chipsLabel}
                      getOptionSelected={(option) =>
                        searchData.findIndex(
                          (item) =>
                            item.key === option.key &&
                            item.value === searchInputValue
                        ) !== -1
                      }
                      tagValues={searchData}
                      inputValue={searchInputValue}
                      onInputChange={inputValueChanged}
                      inputLabel='filter'
                      translationPath={translationPath}
                      translationPathForData={translationPath}
                      parentTranslationPath={parentTranslationPath}
                      inputPlaceholder='search-properties'
                    />
                  </div>
                  <div className='search-properties mt-1 pl-5-reversed'>
                    <div className='mr-1-reversed w-100'>
                      <SelectComponet
                        data={propertyPlanList}
                        emptyItem={{
                          value: 0,
                          text: 'select-property-plan',
                          isDisabled: false,
                        }}
                        value={activeFormType}
                        valueInput='lookupItemId'
                        textInput='lookupItemName'
                        onSelectChanged={changeActiveFormType}
                        themeClass='theme-default'
                        idRef='Select_Type'
                        parentTranslationPath={parentTranslationPath}
                        translationPath={translationPath}
                        translationPathForData={translationPath}
                      />
                    </div>
                    <div className='mt-2 w-100'>
                      <Inputs
                        value={
                          activeActionType === ViewTypesEnum.map.key
                            ? location
                            : searchedItem
                        }
                        onKeyUp={(e) =>
                          activeActionType === ViewTypesEnum.map.key
                            ? setLocation(e.target.value)
                            : searchHandler(e.target.value)
                        }
                        idRef='activitiesSearchRef'
                        labelClasses='mt-4'
                        onInputChanged={(e) =>
                          activeActionType === ViewTypesEnum.map.key
                            ? setLocation(e.target.value)
                            : setSearchedItem(e.target.value)
                        }
                        inputPlaceholder={t(`${translationPath}address-filter`)}
                      />
                    </div>
                    <div className='w-30 customDatePicker'>
                      <div className='date-type-select'>
                        <SelectComponet
                          data={[
                            {
                              key: 1,
                              value: `${translationPath}created-date`,
                            },
                            {
                              key: 2,
                              value: `${translationPath}update-on-date`,
                            },
                            {
                              key: 5,
                              value: `${translationPath}published_date`,
                            },
                          ]}
                          defaultValue={{
                            key: 1,
                            value: `${translationPath}created-date`,
                          }}
                          value={dateFilter.selectedDateType || 1}
                          valueInput='key'
                          textInput='value'
                          isDisabled={isLoading}
                          onSelectChanged={(value) => {
                            setIsDatePickerChanged(true);
                            setDateFilter((f) => ({
                              ...f,
                              selectedDateType: value,
                            }));
                          }}
                          wrapperClasses='w-auto'
                          themeClass='theme-transparent'
                          idRef='Date_Select'
                          parentTranslationPath={parentTranslationPath}
                          translationPath={translationPath}
                          translationPathForData={translationPath}
                        />
                      </div>
                      {dateFilter.selectedDateType === 1 ||
                        dateFilter.selectedDateType === 2 ? (
                        <DateRangePickerComponent
                          onClearClicked={() => {
                            setDateFilter(dateRangeDefault);
                            setIsDatePickerChanged(true);
                          }}
                          onDialogClose={() => {
                            setIsDatePickerChanged(true);
                          }}
                          ranges={[dateFilter]}
                          parentTranslationPath={parentTranslationPath}
                          translationPath={translationPath}
                          onDateChanged={(selectedDate) => {
                            setDateFilter((item) => ({
                              ...item,
                              startDate: selectedDate.selection?.startDate,
                              endDate: new Date(
                                moment(selectedDate.selection?.endDate).endOf(
                                  'day'
                                )
                              ),
                              key: 'selection',
                            }));
                          }}
                        />
                      ) : (
                        <DatePickerComponent
                          idRef='DateOfBirthRef'
                          placeholder='DD/MM/YYYY'
                          value={selected.date}
                          onDateChanged={(newValue) => {
                            setState({
                              id: 'date',
                              value:
                                (newValue &&
                                  moment(newValue).format(
                                    'YYYY-MM-DDTHH:mm:ss'
                                  )) ||
                                null,
                            });
                            setSelected({
                              id: 'date',
                              value:
                                (newValue &&
                                  moment(newValue).format(
                                    'YYYY-MM-DDTHH:mm:ss'
                                  )) ||
                                null,
                            });
                          }}
                        />
                      )}
                    </div>
                  </div>
                </PermissionsComponent>
                <div className='search-properties mt-1 pl-5-reversed '>
                  <div className='mr-1-reversed w-50 d-flex-inline'>
                    <SelectComponet
                      data={[
                        { key: 1, name: 'published' },
                        { key: 2, name: 'unpublished' },
                      ]}
                      emptyItem={{
                        value: 0,
                        text: 'select-publish-status',
                        isDisabled: false,
                      }}
                      value={puplishStatus}
                      valueInput='key'
                      textInput='name'
                      onSelectChanged={changepuplishStatus}
                      themeClass='theme-default'
                      idRef='Select_Type'
                      parentTranslationPath={parentTranslationPath}
                      translationPath={translationPath}
                      translationPathForData={translationPath}
                    />
                  </div>
                  <div className='mr-1-reversed w-50 d-flex-inline'>
                    <SelectComponet
                      data={Object.values(PropertyRatingScoresFilter)}
                      emptyItem={{
                        value: null,
                        text: 'select-rating-score',
                        isDisabled: false,
                      }}
                      value={propertyScores?.value}
                      valueInput='key'
                      textInput='key'
                      onSelectChanged={changePropertyRatingScoresFilter}
                      themeClass='theme-default'
                      idRef='property_rating_scores_filter_ref'
                      parentTranslationPath={parentTranslationPath}
                      translationPath={translationPath}
                      translationPathForData={translationPath}
                    />
                  </div>
                </div>
                <div className='search-properties mt-1 pl-5-reversed '>
                </div>
              </div>
              <div className='view-search-wrapper'>
                <PermissionsComponent
                  permissionsList={
                    !isPropertyManagementView
                      ? Object.values(PropertiesPermissionsCRM)
                      : Object.values(PropertyManagementListPermissions)
                  }
                  permissionsId={
                    !isPropertyManagementView
                      ? PropertiesPermissionsCRM.ViewAndSearchProperties
                        .permissionsId
                      : PropertyManagementListPermissions
                        .ViewandsearchinPropertyManagementProperties
                        .permissionsId
                  }
                >
                  <ViewTypes
                    onTypeChanged={onTypeChanged}
                    initialActiveType={activeActionType}
                    className='mb-3'
                    withMap
                  />
                  <span className='advance-search'>
                    <ButtonBase
                      onClick={onAdvanceSearchClick}
                      id='AdvanceSearch_bbt'
                      disabled={isAllFormFieldsLoading}
                      className={`btns theme-solid ${isAdvanceSearchActive &&
                        !isAllFormFieldsLoading &&
                        'is-active-filter'
                        }`}
                    >
                      <span
                        className={`${advanceSearchBtn
                          ? 'mdi mdi-arrow-expand-left'
                          : 'mdi mdi-arrow-expand-right'
                          } mr-1`}
                      />
                      {t(`${translationPath}advance-search`)}
                    </ButtonBase>
                  </span>
                  <ButtonBase
                    onClick={onClearedAllFiltersClick}
                    id='onClearedAllFiltersref'
                    disabled={isAllFormFieldsLoading}
                    className='btns theme-solid bg-danger clear-all-btn'
                  >
                    <span className='mdi mdi-filter-remove m-1' />
                    {t(`${translationPath}clear-filters`)}
                  </ButtonBase>
                </PermissionsComponent>
                <PermissionsComponent
                  permissionsList={
                    !isPropertyManagementView
                      ? Object.values(PropertiesPermissionsCRM)
                      : Object.values(PropertyManagementListPermissions)
                  }
                  permissionsId={
                    !isPropertyManagementView
                      ? PropertiesPermissionsCRM.PropertyDuplications
                        .permissionsId
                      : PropertyManagementListPermissions
                        .ViewandsearchinPropertyManagementProperties
                        .permissionsId
                  }
                >
                  <ButtonBase
                    onClick={onOpenDuplicatedPage}
                    id='onOpenDuplicatedPageRef'
                    disabled={isAllFormFieldsLoading}
                    className='btns theme-solid w-100 m-2'
                  >
                    {t(`${translationPath}duplicates`)}
                  </ButtonBase>
                </PermissionsComponent>
              </div>
            </div>
          </div>
          <PermissionsComponent
            permissionsList={
              !isPropertyManagementView
                ? Object.values(PropertiesPermissionsCRM)
                : Object.values(PropertyManagementListPermissions)
            }
            permissionsId={
              !isPropertyManagementView
                ? PropertiesPermissionsCRM.ViewAndSearchProperties.permissionsId
                : PropertyManagementListPermissions
                  .ViewandsearchinPropertyManagementProperties.permissionsId
            }
          >
            <div className='d-flex px-2'>
              <span className='mx-2 mt-1'>
                {t(`${translationPath}properties`)}
              </span>
              <span className='separator-v s-primary s-reverse s-h-25px mt-1' />
              <span className='px-2 d-flex'>
                <span className='texts-large mt-1'>
                  {t(`${translationPath}order-by`)}:
                </span>
                <div className='px-2'>
                  <SelectComponet
                    idRef='filterByRef'
                    data={[
                      { id: 'createdOn', filterBy: 'created-on' },
                      { id: 'updateOn', filterBy: 'last-updated' },
                    ]}
                    value={selectedOrderBy.filterBy}
                    onSelectChanged={filterByChanged}
                    wrapperClasses='mb-3'
                    isRequired
                    valueInput='id'
                    textInput='filterBy'
                    emptyItem={{
                      value: null,
                      text: 'select-filter-by',
                      isDisabled: false,
                    }}
                    parentTranslationPath={parentTranslationPath}
                    translationPath={translationPath}
                    translationPathForData={translationPath}
                  />
                </div>
                <div className='px-2'>
                  <SelectComponet
                    idRef='orderByRef'
                    data={[
                      { id: 1, orderBy: 'ascending' },
                      { id: 2, orderBy: 'descending' },
                    ]}
                    value={selectedOrderBy.orderBy}
                    onSelectChanged={orderByChanged}
                    wrapperClasses='mb-3'
                    isRequired
                    valueInput='id'
                    textInput='orderBy'
                    emptyItem={{
                      value: null,
                      text: 'select-sort-by',
                      isDisabled: false,
                    }}
                    parentTranslationPath={parentTranslationPath}
                    translationPath={translationPath}
                    translationPathForData={translationPath}
                  />
                </div>
                <div className='mt-1'>
                  <ButtonBase
                    className='btns theme-solid'
                    id='action_apply'
                    onClick={orderBySubmitted}
                    disabled={
                      !selectedOrderBy.filterBy || !selectedOrderBy.orderBy
                    }
                  >
                    <span>{t(`${translationPath}apply`)}</span>
                  </ButtonBase>
                </div>
              </span>
            </div>
          </PermissionsComponent>
        </div>
        {[ViewTypesEnum.cards.key, ViewTypesEnum.cardsExpanded.key].includes(
          activeActionType
        ) && (
            <PermissionsComponent
              permissionsList={
                !isPropertyManagementView
                  ? Object.values(PropertiesPermissionsCRM)
                  : Object.values(PropertyManagementListPermissions)
              }
              permissionsId={
                !isPropertyManagementView
                  ? PropertiesPermissionsCRM.ViewAndSearchProperties.permissionsId
                  : PropertyManagementListPermissions
                    .ViewandsearchinPropertyManagementProperties.permissionsId
              }
            >
              <div className='body-section'>
                <PropertiesCardsComponent
                  data={detailsPropertiesList}
                  isExpanded={isExpanded}
                  onCardClicked={onCardClick}
                  onFooterActionsClicked={detailedCardSideActionClicked}
                  checkedDetailedCards={checkedDetailedCards}
                  parentTranslationPath={parentTranslationPath}
                  translationPath={translationPath}
                  withCheckbox={activeSelectedAction === 'bulk-update'}
                  onCardCheckboxClick={cardCheckboxClicked}
                  activeCard={activeCard}
                  displyOpenFileButton={displyOpenFileButton}
                  displyEditeButton={displyEditeButton}
                />
              </div>
            </PermissionsComponent>
          )}
        {activeActionType === ViewTypesEnum.tableView.key && (
          <PermissionsComponent
            permissionsList={
              !isPropertyManagementView
                ? Object.values(PropertiesPermissionsCRM)
                : Object.values(PropertyManagementListPermissions)
            }
            permissionsId={
              !isPropertyManagementView
                ? PropertiesPermissionsCRM.ViewAndSearchProperties.permissionsId
                : PropertyManagementListPermissions
                  .ViewandsearchinPropertyManagementProperties.permissionsId
            }
          >
            <PropertiesTableComponent
              detailsPropertiesList={detailsPropertiesList}
              tableActionClicked={tableActionClicked}
              onPageIndexChanged={onPageIndexChanged}
              onPageSizeChanged={onPageSizeChanged}
              propertiesTableFilter={propertiesTableFilter}
              onFilterValuesChanged={onFilterValuesChanged}
              filter={filter}
              parentTranslationPath={parentTranslationPath}
              focusedRowChanged={focusedRowChanged}
              setOrderBy={setOrderBy}
              isClearFiltersClicked={isClearFiltersClicked}
              setIsClearFiltersClicked={setIsClearFiltersClicked}
              displyOpenFileButton={displyOpenFileButton}
              displyEditeButton={displyEditeButton}
              localStorageKey='PropertyFilter'
              activeSelectedAction={activeSelectedAction}
              checkedCardsIds={checkedCards}
              setCheckedCards={setCheckedCards}
              onSelectClicked={onSelectClicked}
              getIsSelected={getIsSelected}
            />
          </PermissionsComponent>
        )}
        {activeActionType === ViewTypesEnum.map.key && (
          <MapView
            locations={detailsPropertiesList.result
              .filter((item) => !!item.map)
              .map((property, index) => {
                let [latitude, longitude] = property.map.split('-');
                latitude = latitude.trim();
                longitude = longitude.trim();
                return {
                  latitude,
                  longitude,
                  name: property.property_name,
                  id: property.id,
                  propertyTypeId: property.propertyTypeId,
                  image: property.allpropertyImages?.[0]?.fileId,
                  type: property.type,
                  createdOn: property.createdOn,
                  propertyOwner: property.property_owner,
                  community: property.community?.lookupItemName,
                  propertyStatus: property.propertyStatus,
                  index,
                };
              })}
            location={location}
            setMap={setMap}
            onPinClick={onCardClick}
            isLoading={isLoading}
            totalCount={detailsPropertiesList.totalCount}
          />
        )}
      </div>
      <PropertiesImportDialog
        isOpen={isOpenImportDialog}
        isOpenChanged={() => setIsOpenImportDialog(false)}
      />
    </div>
  );
};
