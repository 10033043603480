import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { AutocompleteComponent } from '../../../../../../Components';
import { MyLeadsTypesEnum } from '../../../../../../Enums';
import { getErrorByName } from '../../../../../../Helper';

export const LeadTypeComponent = ({
  parentTranslationPath,
  translationPath,
  onStateChanged,
  state,
  schema,
  isSubmitted,
  currentSelected,
  setCurrentSelected,
  validation,
}) => {
  const { t } = useTranslation([parentTranslationPath, 'Shared']);
  const leadsTypes = [];

  MyLeadsTypesEnum.map((e) => {
    if (e.value === 'landlord' || e.value === 'seller') leadsTypes.push(e);
  });

  return (
    <div className='m-2'>
      <AutocompleteComponent
        idRef='leadTypeRef'
        labelValue={t(`${translationPath}leadType`)}
        value={state.rotationSchemaLeadsType || []}
        data={leadsTypes || []}
        multiple
        displayLabel={(option) => (option && option.value) || (option && option.leadClass) || ''}
        chipsLabel={(option) => (option && option.value) || (option && option.leadClass) || ''}
        withoutSearchButton
        selectedValues={currentSelected && currentSelected.rotationSchemaLeadsType || []}
        parentTranslationPath={parentTranslationPath}
        translationPath={translationPath}
        filterOptions={(options) => {
          const isFind = (value) => currentSelected && currentSelected.rotationSchemaLeadsType.findIndex((item) => (item.leadClass && item.leadClass.toLowerCase() === value) || (item.value && item.value.toLowerCase() === value)) === -1;
          return options.filter((el) => isFind((el.value) || (el.leadClass)));
        }}
        onChange={(event, newValue) => {
          const localNewValue = {
            id: 'rotationSchemaLeadsType',
            value: newValue.map((el) => ({ leadClass: el.id || el.leadClass })),
          };
          onStateChanged(localNewValue);
          setCurrentSelected((e) => ({ ...e, rotationSchemaLeadsType: newValue }));
        }}
        isSubmitted={isSubmitted}
        error={validation && getErrorByName(schema, 'rotationSchemaLeadsType').error}
        helperText={validation && getErrorByName(schema, 'rotationSchemaLeadsType').message || ''}
      />
    </div>
  );
};
const convertJsonValueShape = PropTypes.oneOfType([
  PropTypes.number,
  PropTypes.number,
  PropTypes.number,
  PropTypes.number,
  PropTypes.number,
  PropTypes.number,
  PropTypes.number,
  PropTypes.number,
  PropTypes.number,
  PropTypes.number,
  PropTypes.number,
  PropTypes.number,
  PropTypes.number,
  PropTypes.number,
  PropTypes.number,
  PropTypes.number,
  PropTypes.number,
  PropTypes.number,
]);
LeadTypeComponent.propTypes = {
  parentTranslationPath: PropTypes.string.isRequired,
  translationPath: PropTypes.string.isRequired,
  onStateChanged: PropTypes.func.isRequired,
  state: PropTypes.objectOf(convertJsonValueShape).isRequired,
  schema: PropTypes.instanceOf(Object).isRequired,
  isSubmitted: PropTypes.bool.isRequired,
  currentSelected: PropTypes.instanceOf(Object).isRequired,
  setCurrentSelected: PropTypes.func.isRequired,
};
