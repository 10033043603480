import React , {useRef} from 'react';
import { Inputs } from '../../../../Components' ; 
import { useTranslation } from 'react-i18next';


export const ContactsFilter = ({
    translationPath,
    parentTranslationPath,
    filter,
    setFilter , 
    selected , 
    setSelected , 
}) => {

    const searchTimer = useRef(null);
    const { t } = useTranslation(parentTranslationPath);

    return (
      <>
        <div className='d-inline-flex'>
          <div className='mr-2 ml-2'>
              <Inputs
               idRef='ContactsNameRef'
               inputPlaceholder={t('contactName')}
               value={(selected && selected.contactName)}
               parentTranslationPath={parentTranslationPath}
               translationPath={translationPath}
               onKeyUp={(e)=> {
                const { value } = e.target;
                if (searchTimer.current) clearTimeout(searchTimer.current);
                  searchTimer.current = setTimeout(() => {
                  setFilter((item) => ({ ...item, contactName: value }));
                 }, 700);
               }}
               onInputChanged={(e)=> 
                {
                  setSelected({ id: 'contactName', value: e.target.value }); 

                }}
            />  
            </div>
            <div className='mr-2 ml-2'>
             <Inputs
               idRef='ContactsIdRef'
               inputPlaceholder={t('contactId')}
               value={(selected && selected.contactId)}
               parentTranslationPath={parentTranslationPath}
               translationPath={translationPath}
               type={'number'}
               onKeyUp={(e)=> {
                const { value } = e.target;
                if (searchTimer.current) clearTimeout(searchTimer.current);
                  searchTimer.current = setTimeout(() => {
                  setFilter((item) => ({ ...item,contactId: (value ? +(value) : null) }));
                 }, 700);
               }}
               onInputChanged={(e)=> 
                {
                  setSelected({ id: 'contactId', value: e.target.value }); 

                }}
            /> 
             </div> 
            </div>
         </>
    );
};
