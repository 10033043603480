import React from "react";
import { useTranslation } from "react-i18next";
import { AutocompleteComponent } from "../../../../Components";
import { UnitsFilterStatusEnum } from "../../../../Enums";
const parentTranslationPath = "UnitsView";
const translationPath = "";

export const UnitStatusFilter = ({
  setResaleFilters,
  resaleFilters,
  resaleChangeFilters,
  parentTranslationPath ,
  translationPath ,
  translationPathForData ,
  selected , 
  setSelected ,
}) => {
  const { t } = useTranslation(parentTranslationPath);

  return (
    <div className="w-50 m-1">
      <AutocompleteComponent
        idRef="unitStatusRef"
        value={selected.unitStatus}
        selectedValues={selected.unitStatus}
        wrapperClasses="w-min-unset m-2 mr-1"
        inputPlaceholder={t(`${translationPath}select-status`)}
        labelValue={t(`${translationPath}Unit_Status`)}
        data={Object.values(UnitsFilterStatusEnum.sale)}
        displayLabel={(option) => (option && t(`${translationPath}${option.title}`)) || ""}
        multiple={false}
        withoutSearchButton
        onChange={(e, newValue) => {
          resaleChangeFilters("StringStatus", newValue && newValue.value);
          setSelected({id :'unitStatus'  , value : newValue })
        }}
        parentTranslationPath={parentTranslationPath}
        translationPath={translationPath}
        translationPathForData={translationPath}
      />
    </div>
  );
};
