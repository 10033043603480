export const TableFilterOptionsSelectSystemNotificationsEnum =
    [
        {
            key: 1,
            value: 'Email',
        },
        {
            key: 2,
            value: 'SMS',
        },
        {
            key: 3,
            value: 'Notification',
        },
    ];
