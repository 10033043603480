import React, { useCallback, useEffect, useState } from 'react';
import { ButtonBase } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { TableFilterTypesEnum, TableFilterOperatorsEnum } from '../../Enums';
import { PopoverComponent } from '../../Components'

export const OperatorsComponent = ({
    selectedValue,
    setActiveItem,
    item,
    clearOperator,
    searchValue,
    setSearchValue,
    setFilterValue,
    activeItem,
    translationPath,
    onFilterValuesChanged,
}) => {
    const { t } = useTranslation('Shared');
    const [filterAttachedWith, setFilterAttachedWith] = useState(null);
    const [operator, setOperator] = useState([]);

    const getOperatorValue = useCallback(
        (key) =>
            (Object.values(TableFilterOperatorsEnum).findIndex((item) => item.key === key) !== -1 &&
                Object.values(TableFilterOperatorsEnum).find((item) => item.key === key)) ||
            '',
        []
    );
    const handleClose = useCallback(() => {
        setFilterAttachedWith(null)
    }, []);

    const getOperatorOpetion = useCallback((e, item) => {
        setFilterAttachedWith(e.currentTarget);
        if (item.isDate) {
            setOperator(TableFilterTypesEnum.datePicker.defaultOperators)
        } else if (item.isNumber) {
            setOperator(TableFilterTypesEnum.numberInput.defaultOperators)
        } else {
            setOperator(TableFilterTypesEnum.textInput.defaultOperators)
        }
    }, [operator]);

    return (
        <>
            <ButtonBase className={`operatoer-btn ${(selectedValue) && 'is-active-filter' || ''}`}
                onClick={(event) => {
                    setActiveItem(item);
                    getOperatorOpetion(event, item)
                }}>
                <span className={selectedValue && getOperatorValue(selectedValue.operator).icon || 'mdi mdi-filter-outline'} />
            </ButtonBase>

            <PopoverComponent
                idRef='tableFilterOperatorPopRef'
                attachedWith={filterAttachedWith}
                popoverClasses='table-filter-operator-popover advanceSearch-popOver'
                handleClose={handleClose}
                component={(
                    <>
                        {operator.map((selectItem, index) => (
                            <ButtonBase
                                key={`tableFilterOperatorItemRef${index + 1}${selectItem.key}`}
                                className={` btns theme-transparent table-filter-operator-wrapper`}
                                disabled={selectItem.isDisabled}
                                onClick={() => {
                                    if (!activeItem.selector) activeItem['selector'] = getOperatorValue(selectItem.key)
                                    else activeItem.selector = getOperatorValue(selectItem.key)

                                    let activeFilterKey = activeItem.searchableKey || activeItem.displayPath || activeItem.input;
                                    let updateOpratorValue = searchValue && searchValue[activeFilterKey] && { ...searchValue[activeFilterKey], operator: activeItem.selector.key }
                                    if (selectedValue) setSearchValue((item) => (updateOpratorValue && { ...item, [activeFilterKey]: updateOpratorValue } || { ...item }));

                                    let isBlank = activeItem.selector.key === TableFilterOperatorsEnum.isNotBlank.key
                                        || activeItem.selector.key === TableFilterOperatorsEnum.isBlank.key

                                    if (isBlank) {
                                        const localFilterValues = {
                                            [activeItem.input || activeItem.displayPath]: {
                                                value: activeItem.isDate || activeItem.uiWidgetType === 'alt-date' ? null : '',
                                                operator: activeItem.selector.key,
                                                displayPath: activeItem.displayPath || activeItem.input,
                                                searchableKey: activeItem.searchableKey || activeItem.input
                                            }
                                        }

                                        setSearchValue({ ...searchValue, ...localFilterValues });
                                        setFilterValue({ ...searchValue, ...localFilterValues });
                                        onFilterValuesChanged({ ...searchValue, ...localFilterValues })
                                    }

                                    setFilterAttachedWith(null);
                                }}
                            >
                                {t(`${translationPath}${getOperatorValue(selectItem.key).value}`)}
                                <span className={getOperatorValue(selectItem.key).icon} />
                            </ButtonBase>
                        ))}
                        <ButtonBase
                            className='MuiButtonBase-root btns theme-solid theme-solid mt-2 mb-2'
                            onClick={clearOperator}
                        >
                            {t(`${translationPath}reset`)}

                        </ButtonBase>
                    </>
                )}
            />
        </>
    );
};
