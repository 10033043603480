import React from 'react';
export const unitApprovalHeaderData = [
  {
    id: 1,
    label: 'branch-name',
    component: (item) => <span>{item?.branchName ?? 'N/A'}</span>,
  },
  {
    id: 2,
    label: 'expiration-details',
    component: (item) => (
      <span>{`${item?.expirationPeriod} ${item?.durationTypeName} `}</span>
    ),
  },
  {
    id: 7,
    label: 'approver-names',
    component: (item) => (
      <span>
        {item?.approverNames.map((element) => (
          <span className='table-capsule mr-1 fw-simi-bold'>
            {element.approverName || ''}
          </span>
        ))}
      </span>
    ),
  },
  {
    id: 8,
    label: 'excluded-roleNames',
    component: (item) => (
      <span>
        {item?.excludedRoleNames.map((element) => (
          <span className='table-capsule mr-1 fw-simi-bold'>
            {element.roleName || ''}
          </span>
        ))}
      </span>
    ),
  },
  {
    id: 9,
    label: 'excluded-userNames',
    component: (item) => (
      <span>
        {item.excludedUserNames.map((element) => (
          <span className='table-capsule mr-1 fw-simi-bold'>
            {element.excludedUserName || ''}
          </span>
        ))}
      </span>
    ),
  },
  {
    id: 3,
    label: 'apply-on-publish',
    component: (item) => <span>{item?.isApplyOnpublish ? 'Yes' : 'No'}</span>,
  },
  {
    id: 4,
    label: 'apply-on-unpublish',
    component: (item) => <span>{item?.isApplyOnUnpublish ? 'Yes' : 'No'}</span>,
  },
  {
    id: 5,
    label: 'send-notification',
    component: (item) => <span>{item?.isSendNotification ? 'Yes' : 'No'}</span>,
  },
  {
    id: 6,
    label: 'send-email',
    component: (item) => <span>{item?.isSendEmail ? 'Yes' : 'No'}</span>,
  },
];
