import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Fab from '@material-ui/core/Fab';
import PropTypes from 'prop-types';
import { Tooltip } from '@material-ui/core';
import ClientSegmentation from '../../../../assets/images/defaults/Segmentation-3.png';
import { GlobalHistory } from '../../../../Helper';
import { PermissionsComponent } from '../../../../Components';
import { ClientsSegmentationPermissions } from '../../../../Permissions';

import { DeleteClientSegmentationDialog } from '../DeleteClientSegmentation';

export const CardClientSegmentation = ({
  parentTranslationPath, translationPath, reportDto, setActiveItem, setFilter, activeItem

}) => {
  const { t } = useTranslation(parentTranslationPath);
  const [isOpenDeleteDialog, setIsOpenDeleteDialog] = useState(false);
  const [clientSegmentationId, setClientSegmentationId] = useState(null);

  return (
    <div className='CardReportsBuilder-wraper'>

      {reportDto && reportDto &&
        reportDto.map((item, index) => (
          <div index={index} className='a-box'>
            <div className=' img-container'>
              <div className='img-inner'>
                <div className='inner-skew'>
                  <img src={ClientSegmentation} alt='arrowImage' />
                </div>
              </div>
            </div>
            <div className='text-container'>
              <PermissionsComponent
                permissionsList={Object.values(ClientsSegmentationPermissions)}
                permissionsId={ClientsSegmentationPermissions.EditClientSegmentation.permissionsId}
              >
                <div className='opation-1'>
                  <Tooltip
                    size='small'
                    placement='right-end'
                    title={t(`${translationPath}edit`)}
                  >
                    <Fab
                      onClick={() => {
                        GlobalHistory.push(`/home/clients-segmentation/edit?clientSegmentationId=${item.clientSegmentationId}`);
                      }}
                      id='lead-pencil-ref'
                      size='small'
                      color='primary'
                      aria-label='add'
                    >
                      <span className='table-action-icon mdi mdi-lead-pencil c-white' />
                    </Fab>
                  </Tooltip>
                </div>
              </PermissionsComponent>
              <PermissionsComponent
                permissionsList={Object.values(ClientsSegmentationPermissions)}
                permissionsId={ClientsSegmentationPermissions.RunClientSegmentation.permissionsId}
              >
                <div className='opation-2'>
                  <Tooltip
                    size='small'
                    placement='right-end'
                    title={t(`${translationPath}Run`)}
                  >
                    <Fab
                      onClick={() => {
                        setActiveItem(item);
                        window.open(`/share/ClientSegmentationRunView?clientSegmentationId=${item.clientSegmentationId}`, '', 'width=1400,height=1000');
                      }}
                      size='small'
                      color='secondary'
                      aria-label='add'
                    >
                      <span className='table-action-icon  mdi mdi-webhook c-white' />
                    </Fab>
                  </Tooltip>
                </div>
              </PermissionsComponent>
              <div className='opation-3'>
                <Tooltip
                  size='small'
                  placement='right-end'
                  title={t(`${translationPath}delete`)}
                >
                  <Fab
                    onClick={() => {
                    setActiveItem(item);
                    setClientSegmentationId(item.clientSegmentationId);
                    setIsOpenDeleteDialog(true);
                  }}
                    size='small'
                    color='secondary'
                    aria-label='add'
                  >
                    <span className='table-action-icon mdi mdi-trash-can' />
                  </Fab>
                </Tooltip>
              </div>
              <Tooltip
                size='small'
                placement='right-end'
                title={item.clientSegmentationName}
              >
                <h3>{item.clientSegmentationName}</h3>
              </Tooltip>
              <div className='d-flex'>
                <span className='title-l'>{t(`${translationPath}description`)}</span>
                {' '}
                {' '}
                :
                {' '}
                <Tooltip
                  size='small'
                  placement='right-end'
                  title={item.description}
                >
                  <div className=''>{item.description}</div>
                </Tooltip>

              </div>
              <div className='d-flex'>
                <span className='title-l'>{t(`${translationPath}Owner`)}</span>
                {' '}
                {' '}
                :
                {' '}
                <Tooltip
                  size='small'
                  placement='right-end'
                  title={item.owner}
                >
                  <div className=''>{item.owner}</div>
                </Tooltip>

              </div>
            </div>
          </div>
        ))}
      {' '}
      {isOpenDeleteDialog && (
        <DeleteClientSegmentationDialog
          open={isOpenDeleteDialog}
          close={() => {
            setIsOpenDeleteDialog(false);
          }}
          onSave={() => {
            setIsOpenDeleteDialog(false);
            setFilter();
          }}
          clientSegmentation={clientSegmentationId}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          activeItem={activeItem}
        />
      )}
    </div>
  );
};
CardClientSegmentation.propTypes = {
  translationPath: PropTypes.string,
  parentTranslationPath: PropTypes.string,
  reportDto: PropTypes.instanceOf(Array),
};

CardClientSegmentation.defaultProps = {
  translationPath: '',
  parentTranslationPath: '',
  reportDto: [],
};
