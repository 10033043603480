import {  InquiryRotationView } from '../../Views/Home/InquiryRotationView' ;
import InquirySchemeManagement from '../../Views/Home/InquiryRotationView/InquirySchemeManagement';


export const InquiryRotationRoutes = [
  {
    path: '/view',
    name: 'InquiryRotation:inquiry-rotation',
    component: InquiryRotationView,
    layout: '/home/inquiry-rotation',
    default: true,
    isRoute: true,
    authorize: true,
    roles: [],
    isDisabled: false,
    isExact: true,
    breadcrumbs: [
      {
        name: 'InquiryRotation:inquiry-rotation',
        isDisabled: false,
        route: '/home/inquiry-rotation/view',
        groupName: 'inquiries',
      },
    ],
  },
  
  {
    path: '/add',
    name: 'InquiryRotation:inquiry-rotation',
    component: InquirySchemeManagement,
    layout: '/home/inquiry-rotation',
    default: false,
    isRoute: true,
    authorize: true,
    roles: [],
    isDisabled: false,
    isExact: true,
    breadcrumbs: [
      {
        name: 'InquiryRotation:inquiry-rotation',
        isDisabled: false,
        route: '/home/inquiry-rotation/view',
        groupName: 'inquiries',
      },
      {
        name: 'InquiryRotation:inquiry-rotation-management-view',
        isDisabled: true,
        route: '/home/inquiry-rotation/add',
      },
    ],
  },
  {
    path: '/edit/:id',
    name: 'InquiryRotation:inquiry-rotation',
    component: InquirySchemeManagement,
    layout: '/home/inquiry-rotation',
    default: false,
    isRoute: true,
    authorize: true,
    roles: [],
    isDisabled: false,
    isExact: true,
    breadcrumbs: [
      {
        name: 'InquiryRotation:inquiry-rotation',
        isDisabled: false,
        route: '/home/inquiry-rotation/view',
        groupName: 'inquiries',
      },
      {
        name: 'InquiryRotation:inquiry-rotation-management-view',
        isDisabled: true,
        route: '/home/inquiry-rotation/edit',
      },
    ],
  },
];
