export const ProcessReportBuilderLogStatusEnum = {
  1:
  {
    processkey: 1,
    name: 'Run',
  },
  2: {
    processkey: 2,
    name: 'Export',
  },
  3: {
    processkey: 3,
    name: 'Save',
  },
  4: {
    processkey: 4,
    name: 'Send Email',
    key: 4,
  }
};
