import React, { useState, useEffect, useCallback } from 'react'
import { DialogComponent, AutocompleteComponent, Inputs, Spinner } from "../../Components";
import { CreateUserSearchCriteria } from '../../Services';
import { showError, showSuccess } from '../../Helper';
import { useTranslation } from 'react-i18next';

export const AddFilter = ({
    isOpen,
    data,
    setIsLoading,
    searchCriteriaTypeId,
    setIsOpenAddFilter,
    isLoading,
}) => {

    const { t } = useTranslation('Shared');
    const [filterName, setFilterName] = useState(null);
    const [saveIsDisabled, setSaveIsDisabled] = useState(true);
    const saveSearchCriteria = async () => {
        setIsLoading(true);
        const body = {
            searchCriteriaName: filterName,
            searchCriteria: JSON.stringify(data),
            typeId: searchCriteriaTypeId
        }
        const res = await CreateUserSearchCriteria(body);
        if (!(res && res.status && res.status !== 200)) {
            showSuccess(t(`add-filter-successfully`));
        } else {
            showError(t(`failed-to-add-filter`));
        }
        setIsOpenAddFilter(false)
        setIsLoading(false);
    }

    return (
        <>
            <div>
                <DialogComponent
                    isOpen={isOpen}
                    titleText={t('add-filter')}
                    maxWidth='sm'
                    onSaveClicked={saveSearchCriteria}
                    saveText={t('save')}
                    onCancelClicked={() => {
                        setIsOpenAddFilter(false);
                    }}
                    disableBackdropClick
                    saveIsDisabled={isLoading||saveIsDisabled}
                    saveClasses={'btns theme-solid'}
                    dialogContent={(
                        <>
                            <div >
                                <Inputs
                                    value={filterName}
                                    inputPlaceholder={t('filter-name')}
                                    onInputChanged={(e) => {
                                        let { value } = e.target
                                        if (value) setSaveIsDisabled(false);
                                        else setSaveIsDisabled(true);
                                        setFilterName(value);
                                    }}

                                />
                            </div>
                        </>
                    )}
                />
            </div>
        </>
    );
}
