import React, { useCallback, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { InputAdornment } from "@material-ui/core";
import moment from "moment";
import {
  AutocompleteComponent,
  DatePickerComponent,
  Inputs,
  RadiosGroupComponent,
  Spinner,
  DataFileAutocompleteComponent,
} from "../../../../../../../Components";
import { StaticLookupsIds } from "../../../../../../../assets/json/StaticLookupsIds";
import { floatHandler, getErrorByName } from "../../../../../../../Helper";
import {
  GetContacts,
  lookupItemsGetId,
  GetUserByApplicationUserId,
  OrganizationUserSearch,
  contactsDetailsGet,
  GetAllContactsByClassificationIds,
  SearchUsersByName,
} from "../../../../../../../Services";
import { GetApplicationUserById } from "../../../../../../../Services/userServices";
import { AgentRoleEnum } from "../../../../../../../Enums/AgentRoleEnum";
import { AgentTypesEnum } from "../../../../../../../Enums";

const parentTranslationPath = "UnitsStatusManagementView";
export const AgentInfoSaleRelatedComponent = ({
  state,
  selected,
  unitData,
  onSelectedChanged,
  schema,
  unitTransactionId,
  isSubmitted,
  onStateChanged,
  isReadOnly,
  translationPath,
  withListingAgents,
  cantEditReservedSaleTransaction,
}) => {
  const path =
    window.location.pathname.split("/home/")[1] &&
    window.location.pathname.split("/home/")[1].split("/").length > 1
      ? window.location.pathname.split("/home/")[1].split("/")[1]
      : window.location.pathname.split("/home/")[1];
  const [isUnitProfileReservation, setIsUnitProfileReservation] = useState(
    path === "unit-profile-reservation"
  );
  const [isLoading, setIsLoading] = useState(false);
  const searchTimer = useRef(null);
  const [loadings, setLoadings] = useState({
    agents: false,
    referrals: false,
    reservationTypes: false,
    transactedBy: false,
    externalAgencies: false,
  });
  const [filter] = useState({
    pageIndex: 0,
    pageSize: 10,
  });
  const [agents, setAgents] = useState([]);
  const [referrals, setReferrals] = useState([]);
  const [reservationTypes, setReservationTypes] = useState([]);
  const [transactedBy, setTransactedBy] = useState([]);
  const [externalAgencies, setExternalAgencies] = useState([]);
  const getAllAgents = useCallback(
    async (value, selectedValues) => {
      setLoadings((items) => ({ ...items, agents: true }));
      const res = await OrganizationUserSearch({
        ...filter,
        userTypeId: AgentRoleEnum.SaleAgent.value,
        userStatusId: 2,
        name: value,
      });
      if (!(res && res.status && res.status !== 200)) {
        let tempHolder = res.result || [];

        setAgents(tempHolder);
      } else setAgents([]);
      setLoadings((items) => ({ ...items, agents: false }));
    },
    [filter]
  );
  const getAllTransactedBy = useCallback(
    async (value, selectedValue) => {
      setLoadings((items) => ({ ...items, transactedBy: true }));
      const res = await OrganizationUserSearch({
        ...filter,
        name: value,
        userStatusId: 2,
      });
      if (!(res && res.status && res.status !== 200)) {
        setTransactedBy(
          (selectedValue &&
            ((res && res.result && [...res.result, selectedValue]) || [
              selectedValue,
            ])) ||
            (res && res.result) ||
            []
        );
      } else setTransactedBy([]);
      setLoadings((items) => ({ ...items, transactedBy: false }));
    },
    [filter]
  );
  const getAllReferrals = useCallback(
    async (value, selectedValue) => {
      setLoadings((items) => ({ ...items, referrals: true }));
      const res = await SearchUsersByName(value, 1, 50);
      if (!(res && res.status && res.status !== 200))
        setReferrals(res && res.result);
      else setReferrals([]);
      setLoadings((items) => ({ ...items, referrals: false }));
    },
    [filter]
  );
  const getAllExternalAgencies = useCallback(
    async (value) => {
      setLoadings((items) => ({ ...items, externalAgencies: true }));
      const res = await GetAllContactsByClassificationIds({
        ...filter,
        userStatusId: 2,
        search: value,
        classificationIds: [20713, 20714],
      });
      if (!(res && res.status && res.status !== 200))
        setExternalAgencies((res && res.result) || []);
      else setExternalAgencies([]);
      setLoadings((items) => ({ ...items, externalAgencies: false }));
    },
    [filter]
  );
  const getAllReservationTypes = useCallback(async () => {
    setLoadings((items) => ({ ...items, reservationTypes: true }));
    const res = await lookupItemsGetId({
      lookupTypeId: StaticLookupsIds.ReservationType,
    });
    if (!(res && res.status && res.status !== 200))
      setReservationTypes(res || []);
    else setReservationTypes([]);
    setLoadings((items) => ({ ...items, reservationTypes: false }));
  }, []);

  const getUserById = useCallback(async (id) => {
    setIsLoading(true);
    const res = await GetUserByApplicationUserId(id);
    setIsLoading(false);
    if (!(res && res.status && res.status !== 200)) return res;
    return null;
  }, []);

  const getUserByGUID = useCallback(async (id) => {
    setIsLoading(true);
    const res = await GetApplicationUserById(id);
    setIsLoading(false);
    if (!(res && res.status && res.status !== 200)) return res;
    return null;
  }, []);

  const getContactById = useCallback(async (id) => {
    setIsLoading(true);
    const res = await contactsDetailsGet({ id });
    setIsLoading(false);
    if (!(res && res.status && res.status !== 200)) return res;
    return null;
  }, []);

  const handleAgentsEditInit = useCallback(async () => {
    if (state.agents && !selected.agents) {
      const selectedAgents = state.agents.map((item) => ({
        id: item.userId,
        fullName: item.agentName,
        isOriginalAgent: item.isOriginalAgent,
        agentType: item.agentType,
      }));

      const buyerAgent =
        selectedAgents.find((item) => item.isOriginalAgent == true) || null;
      const otherBuyerAgents =
        selectedAgents.filter((item) => item.isOriginalAgent == false) || [];

      onSelectedChanged({ id: "agents", value: selectedAgents });
      onSelectedChanged({ id: "buyerAgent", value: buyerAgent });
      onSelectedChanged({
        id: "otherBuyerAgents",
        value: otherBuyerAgents.length > 0 && otherBuyerAgents,
      });
    }

    if (
      state.listingAgents &&
      !selected.listingAgents &&
      unitData?.listing_agent
    ) {
      const selectedListingAgents = state.listingAgents.map((item) => ({
        id: item.userId,
        fullName: item.listingAgentName,
        isListingAgent: item.isListingAgent,
        listingAgentType: item.listingAgentType,
        listingAgentCommission: item.listingAgentCommission,
      }));

      const sellerAgentCommission =
        selectedListingAgents.length > 0
          ? selectedListingAgents[0].listingAgentCommission
          : 0;
      const unitSellerAgent = unitData?.listing_agent && {
        id: unitData.listing_agent.id,
        fullName: unitData.listing_agent.name,
        isListingAgent: true,
        listingAgentType: AgentTypesEnum.Seller.value,
        listingAgentCommission: sellerAgentCommission,
      };
      selectedListingAgents.shift();
      selectedListingAgents.unshift(unitSellerAgent);

      const stateListingAgents = selectedListingAgents.map((item) => ({
        userId: item.id,
        listingAgentName: item.fullName,
        isListingAgent: item.isListingAgent,
        listingAgentType: item.listingAgentType,
        listingAgentCommission: item.listingAgentCommission,
      }));
      const otherSellerAgents =
        selectedListingAgents.filter((item) => item.isListingAgent == false) ||
        [];

      onStateChanged({ id: "listingAgents", value: stateListingAgents });
      onSelectedChanged({ id: "withListingAgents", value: true });
      onSelectedChanged({ id: "listingAgents", value: selectedListingAgents });
      onSelectedChanged({ id: "sellerAgent", value: unitSellerAgent });
      onSelectedChanged({
        id: "otherSellerAgents",
        value: otherSellerAgents.length > 0 && otherSellerAgents,
      });
    }
  }, [
    state.agents,
    selected.agents,
    selected.listingAgents,
    state.listingAgents,
    unitData,
  ]);

  const getBuyerReferralEditInit = useCallback(async () => {
    if (state.referralId && !selected.referral && referrals.length > 0) {
      const referralIndex = referrals.findIndex(
        (item) => item.id === state.referralId
      );
      if (referralIndex !== -1) selected.referral = referrals[referralIndex];
      else {
        const res = await getUserById(state.referralId);
        if (res) {
          selected.referral = res;

          setReferrals((items) => {
            items.push(res);
            return [...items];
          });
        } else onStateChanged({ id: "referralId", value: null });
      }
      if (onSelectedChanged) onSelectedChanged({ id: "edit", value: selected });
    }
  }, [referrals, state.referralId]);
  const getSellerReferralEditInit = useCallback(async () => {
    if (
      state.sellerInternalReferralId &&
      !selected.sellerReferral &&
      referrals.length > 0
    ) {
      const referralIndex = referrals.findIndex(
        (item) => item.id === state.sellerInternalReferralId
      );
      if (referralIndex !== -1)
        selected.sellerReferral = referrals[referralIndex];
      else {
        const res = await getUserById(state.sellerInternalReferralId);
        if (res) {
          selected.sellerReferral = res;

          setReferrals((items) => {
            items.push(res);
            return [...items];
          });
        } else onStateChanged({ id: "sellerInternalReferralId", value: null });
      }
      if (onSelectedChanged) onSelectedChanged({ id: "edit", value: selected });
    }
  }, [referrals]);

  const getTransactedByEditInit = useCallback(async () => {
    if (
      state.transactedById &&
      !selected.transactedBy &&
      transactedBy.length > 0
    ) {
      const transactedByIndex = transactedBy.findIndex(
        (item) => item.id === state.transactedById
      );
      if (transactedByIndex !== -1)
        selected.transactedBy = transactedBy[transactedByIndex];
      else {
        const res = await getUserByGUID(state.transactedById);
        if (res) {
          selected.transactedBy = res;

          setTransactedBy((items) => {
            items.push(res);
            return [...items];
          });
        } else onStateChanged({ id: "transactedById", value: null });
      }
      if (onSelectedChanged) onSelectedChanged({ id: "edit", value: selected });
    }
  }, [state.transactedById, transactedBy]);

  const getEditInit = useCallback(async () => {
    if (
      state.reservationTypeId &&
      !selected.reservationType &&
      reservationTypes.length > 0
    ) {
      const reservationTypeIndex = reservationTypes.findIndex(
        (item) => item.lookupItemId === state.reservationTypeId
      );
      if (reservationTypeIndex !== -1) {
        selected.reservationType = reservationTypes[reservationTypeIndex];
        if (onSelectedChanged)
          onSelectedChanged({ id: "edit", value: selected });
      } else onStateChanged({ id: "reservationTypeId", value: null });
    }
    if (
      state.externalAgencyId &&
      !selected.externalAgency &&
      externalAgencies.length > 0
    ) {
      const externalAgencyIndex = externalAgencies.findIndex(
        (item) => item.contactsId === state.externalAgencyId
      );
      if (externalAgencyIndex !== -1)
        selected.externalAgency = externalAgencies[externalAgencyIndex];
      else {
        const res = await getContactById(state.externalAgencyId);
        if (res) {
          selected.externalAgency = res;

          setExternalAgencies((items) => {
            items.push(res);
            return [...items];
          });
        } else onStateChanged({ id: "externalAgencyId", value: null });
      }
      if (onSelectedChanged) onSelectedChanged({ id: "edit", value: selected });
    }
  }, [
    externalAgencies,
    onSelectedChanged,
    onStateChanged,
    state.externalAgencyId,
    state.reservationTypeId,
  ]);

  useEffect(() => {
    getAllAgents();
    getAllReferrals();
    getAllReservationTypes();
    getAllTransactedBy();
    getAllExternalAgencies();
  }, [
    getAllAgents,
    getAllExternalAgencies,
    getAllReferrals,
    getAllReservationTypes,
    getAllTransactedBy,
  ]);
  useEffect(() => {
    if (unitTransactionId) getEditInit();
  }, [getEditInit, unitTransactionId]);

  useEffect(() => {
    if (unitTransactionId) getBuyerReferralEditInit();
  }, [getBuyerReferralEditInit, unitTransactionId]);

  useEffect(() => {
    if (unitTransactionId) getSellerReferralEditInit();
  }, [getSellerReferralEditInit, unitTransactionId]);

  useEffect(() => {
    if (unitTransactionId) getTransactedByEditInit();
  }, [getTransactedByEditInit, unitTransactionId]);

  useEffect(() => {
    if (unitTransactionId) handleAgentsEditInit();
  }, [handleAgentsEditInit, unitTransactionId]);

  return (
    <div className="unit-status-agent-info-wapper childs-wrapper p-relative agent-info-sale-related-component-wrapper">
      <Spinner isActive={isLoading} isAbsolute />
      <div className="form-item">
        <AutocompleteComponent
          idRef="buyer-agentIdRef"
          labelValue="buyer-agent"
          labelClasses="Requierd-Color"
          selectedValues={selected.buyerAgent}
          isDisabled={cantEditReservedSaleTransaction}
          multiple={false}
          data={agents || []}
          displayLabel={(option) => option.fullName || ""}
          renderOption={(option) =>
            ((option.userName || option.fullName) &&
              `${option.fullName} (${option.userName})`) ||
            ""
          }
          withoutSearchButton
          helperText={getErrorByName(schema, "buyerAgent").message}
          error={getErrorByName(schema, "buyerAgent").error}
          isLoading={loadings.agents}
          onInputKeyUp={(e) => {
            const { value } = e.target;
            if (searchTimer.current) clearTimeout(searchTimer.current);
            searchTimer.current = setTimeout(() => {
              getAllAgents(value, selected.agents);
            }, 1200);
          }}
          isWithError
          isSubmitted={isSubmitted}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          onChange={(event, newValue) => {
            let newBuyerAgent =
              (newValue && {
                id: newValue.id,
                fullName: newValue.fullName,
                isOriginalAgent: true,
                agentType: AgentTypesEnum.Buyer.value,
                userName: newValue?.userName,
              }) ||
              null;
            let newSelected = [];
            if (newBuyerAgent && !selected.otherBuyerAgents) {
              newSelected.push(newBuyerAgent);
            }

            if (
              newBuyerAgent &&
              !selected.buyerAgent &&
              selected.otherBuyerAgents
            ) {
              newSelected = [newBuyerAgent, ...selected.agents];
            }

            if (
              newBuyerAgent &&
              selected.buyerAgent &&
              selected.otherBuyerAgents
            ) {
              const agentsList = [...selected.agents];
              agentsList.shift();
              newSelected = [newBuyerAgent, ...agentsList];
            }

            if (
              !newBuyerAgent &&
              selected.buyerAgent &&
              selected.otherBuyerAgents
            ) {
              const agentsList = [...selected.agents];
              agentsList.shift();
              newSelected = agentsList;
            }

            const mappedStateAgents =
              (newSelected.length > 0 &&
                newSelected.map((item) => ({
                  userId: item.id,
                  agentCommission: 0,
                  isOriginalAgent: item.id == newBuyerAgent?.id,
                  agentType: AgentTypesEnum.Buyer.value,
                }))) ||
              null;

            const mappedBuyerAgent = newBuyerAgent && {
              userId: newBuyerAgent.id,
              agentCommission: 0,
              isOriginalAgent: true,
              agentType: AgentTypesEnum.Buyer.value,
              agentName: newBuyerAgent.fullName,
            };

            if (newSelected.length > 0) {
              onSelectedChanged({ id: "agents", value: newSelected });
              onStateChanged({ id: "agents", value: mappedStateAgents });
            } else {
              onSelectedChanged({ id: "agents", value: [] });
              onStateChanged({ id: "agents", value: null });
            }

            onSelectedChanged({ id: "buyerAgent", value: newBuyerAgent });
            onStateChanged({ id: "buyerAgent", value: mappedBuyerAgent });
          }}
        />
      </div>
      <div className="form-item">
        <AutocompleteComponent
          idRef="other-buyer-agentsIdRef"
          labelValue="other-buyer-agents"
          selectedValues={selected.otherBuyerAgents || []}
          multiple
          isDisabled={
            cantEditReservedSaleTransaction || isUnitProfileReservation
          }
          data={agents || []}
          displayLabel={(option) => option.fullName || ""}
          chipsLabel={(option) => option.fullName || ""}
          renderOption={(option) =>
            ((option.userName || option.fullName) &&
              `${option.fullName} (${option.userName})`) ||
            ""
          }
          getOptionSelected={(option) =>
            (selected.otherBuyerAgents &&
              selected.otherBuyerAgents.findIndex(
                (item) => item.id === option.id
              ) !== -1) ||
            ""
          }
          withoutSearchButton
          isLoading={loadings.agents}
          onInputKeyUp={(e) => {
            const { value } = e.target;
            if (searchTimer.current) clearTimeout(searchTimer.current);
            searchTimer.current = setTimeout(() => {
              getAllAgents(value, selected.agents);
            }, 1200);
          }}
          isSubmitted={isSubmitted}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          onChange={(event, newValue) => {
            if (cantEditReservedSaleTransaction) return;
            let newSelected = [];
            let newOtherBuyerAgents = newValue.map((item) => ({
              id: item.id,
              fullName: item.fullName,
              isOriginalAgent: false,
              agentType: AgentTypesEnum.Buyer.value,
              userName: newValue?.userName,
            }));
            if (!selected.buyerAgent) {
              newSelected = newOtherBuyerAgents;
            } else {
              newSelected = [selected.buyerAgent, ...newOtherBuyerAgents];
            }

            const mappedStateAgents =
              (newSelected &&
                newSelected.length > 0 &&
                newSelected.map((item) => ({
                  userId: item.id,
                  agentCommission: 0,
                  isOriginalAgent: item.isOriginalAgent,
                  agentType: AgentTypesEnum.Buyer.value,
                }))) ||
              null;

            if (newSelected.length > 0) {
              onStateChanged({ id: "agents", value: mappedStateAgents });
              onSelectedChanged({ id: "agents", value: newSelected });
              onSelectedChanged({
                id: "otherBuyerAgents",
                value: newOtherBuyerAgents,
              });
            } else {
              onSelectedChanged({ id: "agents", value: [] });
              onStateChanged({ id: "agents", value: null });
              onSelectedChanged({ id: "otherBuyerAgents", value: null });
            }
          }}
        />
      </div>
      <div className="form-item">
        <AutocompleteComponent
          idRef="seller-agentIdRef"
          labelValue="seller-agent"
          selectedValues={selected.sellerAgent}
          multiple={false}
          data={agents || []}
          isDisabled={
            cantEditReservedSaleTransaction || selected.withListingAgents
          }
          displayLabel={(option) => option.fullName || ""}
          renderOption={(option) =>
            ((option.userName || option.fullName) &&
              `${option.fullName} (${option.userName})`) ||
            ""
          }
          withoutSearchButton
          helperText={getErrorByName(schema, "sellerAgent").message}
          error={getErrorByName(schema, "sellerAgent").error}
          isLoading={loadings.agents}
          onInputKeyUp={(e) => {
            const { value } = e.target;
            if (searchTimer.current) clearTimeout(searchTimer.current);
            searchTimer.current = setTimeout(() => {
              getAllAgents(value, selected.listingAgents);
            }, 1200);
          }}
          isWithError
          isSubmitted={isSubmitted}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          onChange={(event, newValue) => {
            let newSelected = [];
            let newSellerAgent =
              (newValue && {
                id: newValue.id,
                fullName: newValue.fullName,
                isListingAgent: true,
                listingAgentType: AgentTypesEnum.Seller.value,
              }) ||
              null;
            if (newSellerAgent && !selected.otherSellerAgents) {
              newSelected.push(newSellerAgent);
            }

            if (
              newSellerAgent &&
              !selected.sellerAgent &&
              selected.otherSellerAgents
            ) {
              newSelected = [newSellerAgent, ...selected.listingAgents];
            }

            if (
              newSellerAgent &&
              selected.sellerAgent &&
              selected.otherSellerAgents
            ) {
              const agentsList = [...selected.listingAgents];
              agentsList.shift();
              newSelected = [newSellerAgent, ...agentsList];
            }

            if (
              !newSellerAgent &&
              selected.sellerAgent &&
              selected.otherSellerAgents
            ) {
              const agentsList = [...selected.listingAgents];
              agentsList.shift();
              newSelected = agentsList;
            }

            const mappedStateAgents =
              (newSelected.length > 0 &&
                newSelected.map((item) => ({
                  userId: item.id,
                  listingAgentCommission: 0,
                  isListingAgent: item.id == newSellerAgent?.id,
                  listingAgentType: AgentTypesEnum.Seller.value,
                }))) ||
              null;

            if (newSelected.length > 0) {
              onSelectedChanged({ id: "listingAgents", value: newSelected });
              onStateChanged({ id: "listingAgents", value: mappedStateAgents });
            } else {
              onSelectedChanged({ id: "listingAgents", value: [] });
              onStateChanged({ id: "listingAgents", value: null });
            }

            onSelectedChanged({ id: "sellerAgent", value: newSellerAgent });
            onStateChanged({ id: "sellerAgent", value: newSellerAgent });
          }}
        />
      </div>
      <div className="form-item">
        <AutocompleteComponent
          idRef="other-seller-agentIdRef"
          labelValue="other-seller-agents"
          selectedValues={selected.otherSellerAgents || []}
          multiple
          isDisabled={
            cantEditReservedSaleTransaction || isUnitProfileReservation
          }
          data={agents || []}
          displayLabel={(option) => option.fullName || ""}
          chipsLabel={(option) => option.fullName || ""}
          renderOption={(option) =>
            ((option.userName || option.fullName) &&
              `${option.fullName} (${option.userName})`) ||
            ""
          }
          getOptionSelected={(option) =>
            (selected.otherSellerAgents &&
              selected.otherSellerAgents.findIndex(
                (item) => item.id === option.id
              ) !== -1) ||
            ""
          }
          withoutSearchButton
          isLoading={loadings.agents}
          onInputKeyUp={(e) => {
            const { value } = e.target;
            if (searchTimer.current) clearTimeout(searchTimer.current);
            searchTimer.current = setTimeout(() => {
              getAllAgents(value, selected.listingAgents);
            }, 1200);
          }}
          isSubmitted={isSubmitted}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          onChange={(event, newValue) => {
            if (cantEditReservedSaleTransaction) return;

            let newSelected = [];
            let newOtherSellerAgents = newValue.map((item) => ({
              id: item.id,
              fullName: item.fullName,
              isListingAgent: false,
              listingAgentType: AgentTypesEnum.Seller.value,
            }));
            if (!selected.sellerAgent) {
              newSelected = newOtherSellerAgents;
            } else {
              newSelected = [selected.sellerAgent, ...newOtherSellerAgents];
            }

            const mappedStateAgents =
              (newSelected &&
                newSelected.length > 0 &&
                newSelected.map((item) => ({
                  userId: item.id,
                  listingAgentCommission: 0,
                  isListingAgent: item.isListingAgent,
                  listingAgentType: AgentTypesEnum.Seller.value,
                }))) ||
              null;

            if (newSelected.length > 0) {
              onStateChanged({ id: "listingAgents", value: mappedStateAgents });
              onSelectedChanged({ id: "listingAgents", value: newSelected });
              onSelectedChanged({
                id: "otherSellerAgents",
                value: newOtherSellerAgents,
              });
            } else {
              onSelectedChanged({ id: "listingAgents", value: [] });
              onStateChanged({ id: "listingAgents", value: null });
              onSelectedChanged({ id: "otherSellerAgents", value: null });
            }
          }}
        />
      </div>
      <div className="form-item">
        <RadiosGroupComponent
          idRef="contractRatifiedRef"
          labelValue="is-contract-ratified"
          data={[
            {
              key: true,
              value: "yes",
            },
            {
              key: false,
              value: "no",
            },
          ]}
          value={state.contractRatified}
          parentTranslationPath={parentTranslationPath}
          translationPathForData={translationPath}
          isDisabled={
            cantEditReservedSaleTransaction || isUnitProfileReservation
          }
          translationPath={translationPath}
          labelInput="value"
          valueInput="key"
          onSelectedRadioChanged={(e, newValue) =>
            onStateChanged({
              id: "contractRatified",
              value: newValue === "true",
            })
          }
        />
      </div>
      <div className="form-item">
        <RadiosGroupComponent
          idRef="titleDeedTransferredRef"
          labelValue="is-transferred-deed"
          data={[
            {
              key: true,
              value: "yes",
            },
            {
              key: false,
              value: "no",
            },
          ]}
          value={state.titleDeedTransferred}
          isDisabled={
            cantEditReservedSaleTransaction || isUnitProfileReservation
          }
          parentTranslationPath={parentTranslationPath}
          translationPathForData={translationPath}
          translationPath={translationPath}
          labelInput="value"
          valueInput="key"
          onSelectedRadioChanged={(event, newValue) =>
            onStateChanged({
              id: "titleDeedTransferred",
              value: newValue === "true",
            })
          }
        />
      </div>
      <div className="form-item">
        <RadiosGroupComponent
          idRef="mortgageRef"
          labelValue="is-mortgage"
          data={[
            {
              key: true,
              value: "yes",
            },
            {
              key: false,
              value: "no",
            },
          ]}
          value={state.mortgage}
          isDisabled={
            cantEditReservedSaleTransaction || isUnitProfileReservation
          }
          parentTranslationPath={parentTranslationPath}
          translationPathForData={translationPath}
          translationPath={translationPath}
          labelInput="value"
          valueInput="key"
          onSelectedRadioChanged={(e, newValue) =>
            onStateChanged({ id: "mortgage", value: newValue === "true" })
          }
        />
      </div>
      <div className="form-item">
        <AutocompleteComponent
          idRef="referralIdRef"
          labelValue="trans-description-buyer"
          selectedValues={selected.referral}
          multiple={false}
          data={referrals || []}
          displayLabel={(option) =>
            (option.firstName && `${option.firstName} (${option.lastName})`) ||
            ""
          }
          isDisabled={
            cantEditReservedSaleTransaction || isUnitProfileReservation
          }
          renderOption={(option) =>
            (option.firstName && `${option.firstName} (${option.lastName})`) ||
            ""
          }
          getOptionSelected={(option) => option.id === state.referralId}
          withoutSearchButton
          helperText={getErrorByName(schema, "referralId").message}
          error={getErrorByName(schema, "referralId").error}
          isLoading={loadings.referrals}
          onInputKeyUp={(e) => {
            const { value } = e.target;
            if (searchTimer.current) clearTimeout(searchTimer.current);
            searchTimer.current = setTimeout(() => {
              getAllReferrals(value, selected.referral);
            }, 700);
          }}
          isWithError
          isSubmitted={isSubmitted}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          onChange={(event, newValue) => {
            onSelectedChanged({ id: "referral", value: newValue });
            onStateChanged({
              id: "referralId",
              value: (newValue && newValue.applicationUserId) || null,
            });
          }}
        />
      </div>
      <div className="form-item">
        <Inputs
          idRef="referralPercentageRef"
          labelValue="trans-percentage-description-buyer"
          value={state.referralPercentage || 0}
          helperText={getErrorByName(schema, "referralPercentage").message}
          error={getErrorByName(schema, "referralPercentage").error}
          isWithError
          isSubmitted={isSubmitted}
          isDisabled={
            cantEditReservedSaleTransaction || isUnitProfileReservation
          }
          type="number"
          min={0}
          max={100}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          onInputChanged={(event) => {
            let value = +floatHandler(event.target.value, 3);
            if (value > 100) value = 100;

            onStateChanged({
              id: "referralPercentage",
              value: parseInt(value),
            });
          }}
        />
      </div>
      <div className="form-item">
        <AutocompleteComponent
          idRef="sellerInternalReferralIdRef"
          labelValue="trans-description-seller"
          selectedValues={selected.sellerReferral}
          multiple={false}
          data={referrals || []}
          displayLabel={(option) =>
            (option.firstName && `${option.firstName} (${option.lastName})`) ||
            ""
          }
          isDisabled={
            cantEditReservedSaleTransaction || isUnitProfileReservation
          }
          renderOption={(option) =>
            (option.firstName && `${option.firstName} (${option.lastName})`) ||
            ""
          }
          getOptionSelected={(option) => option.id === state.referralId}
          withoutSearchButton
          helperText={
            getErrorByName(schema, "sellerInternalReferralId").message
          }
          error={getErrorByName(schema, "sellerInternalReferralId").error}
          isLoading={loadings.referrals}
          onInputKeyUp={(e) => {
            const { value } = e.target;
            if (searchTimer.current) clearTimeout(searchTimer.current);
            searchTimer.current = setTimeout(() => {
              getAllReferrals(value, selected.sellerReferral);
            }, 700);
          }}
          isWithError
          isSubmitted={isSubmitted}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          onChange={(event, newValue) => {
            onSelectedChanged({ id: "sellerReferral", value: newValue });
            onStateChanged({
              id: "sellerInternalReferralId",
              value: (newValue && newValue.applicationUserId) || null,
            });
          }}
        />
      </div>
      <div className="form-item">
        <Inputs
          idRef="sellerInternalReferralPercentageRef"
          labelValue="trans-percentage-description-seller"
          value={state.sellerInternalReferralPercentage || 0}
          helperText={
            getErrorByName(schema, "sellerInternalReferralPercentage").message
          }
          error={
            getErrorByName(schema, "sellerInternalReferralPercentage").error
          }
          isWithError
          isSubmitted={isSubmitted}
          isDisabled={
            cantEditReservedSaleTransaction || isUnitProfileReservation
          }
          type="number"
          min={0}
          max={100}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          onInputChanged={(event) => {
            let value = +floatHandler(event.target.value, 3);
            if (value > 100) value = 100;
            onStateChanged({
              id: "sellerInternalReferralPercentage",
              value: parseInt(value),
            });
          }}
        />
      </div>
      <div className="form-item">
        <DatePickerComponent
          idRef="contractRatifiedDateRef"
          labelValue="ratified-date"
          placeholder="DD/MM/YYYY"
          value={state.contractRatifiedDate}
          isDisabled={
            cantEditReservedSaleTransaction || isUnitProfileReservation
          }
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          onDateChanged={(newValue) => {
            onStateChanged({ id: "contractRatifiedDate", value: newValue });
          }}
        />
      </div>
      <div className="form-item">
        <Inputs
          idRef="contractRefNoRef"
          labelValue="contract-ref-no"
          value={state.contractRefNo || ""}
          helperText={getErrorByName(schema, "contractRefNo").message}
          error={getErrorByName(schema, "contractRefNo").error}
          isWithError
          isDisabled={
            cantEditReservedSaleTransaction || isUnitProfileReservation
          }
          isSubmitted={isSubmitted}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          onInputChanged={(event) => {
            onStateChanged({ id: "contractRefNo", value: event.target.value });
          }}
        />
      </div>
      <div className="form-item">
        <Inputs
          idRef="financeCompRef"
          labelValue="finance-comp"
          value={state.financeComp || ""}
          helperText={getErrorByName(schema, "financeComp").message}
          error={getErrorByName(schema, "financeComp").error}
          isWithError
          isDisabled={
            cantEditReservedSaleTransaction || isUnitProfileReservation
          }
          isSubmitted={isSubmitted}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          onInputChanged={(event) => {
            onStateChanged({ id: "financeComp", value: event.target.value });
          }}
        />
      </div>
      <div className="form-item">
        <DatePickerComponent
          idRef="transactionEntryDateRef"
          labelValue="transaction-entry-date"
          labelClasses="Requierd-Color"
          placeholder="DD/MM/YYYY"
          value={state.transactionEntryDate}
          minDate={moment().format("YYYY-MM-DDTHH:mm:ss")}
          helperText={getErrorByName(schema, "transactionEntryDate").message}
          error={getErrorByName(schema, "transactionEntryDate").error}
          isDisabled={
            cantEditReservedSaleTransaction || isUnitProfileReservation
          }
          isSubmitted={isSubmitted}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          onDateChanged={(newValue) => {
            onStateChanged({ id: "transactionEntryDate", value: newValue });
          }}
        />
      </div>
      <div className="form-item">
        <AutocompleteComponent
          idRef="reservationTypeIdRef"
          labelValue="reservation-type"
          labelClasses="Requierd-Color"
          selectedValues={selected.reservationType}
          multiple={false}
          data={reservationTypes || []}
          displayLabel={(option) => option.lookupItemName || ""}
          getOptionSelected={(option) =>
            option.lookupItemId === state.reservationTypeId
          }
          withoutSearchButton
          helperText={getErrorByName(schema, "reservationTypeId").message}
          error={getErrorByName(schema, "reservationTypeId").error}
          isWithError
          isLoading={loadings.reservationTypes}
          isDisabled={
            cantEditReservedSaleTransaction || isUnitProfileReservation
          }
          isSubmitted={isSubmitted}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          onChange={(event, newValue) => {
            onSelectedChanged({ id: "reservationType", value: newValue });
            onStateChanged({
              id: "reservationTypeId",
              value: (newValue && newValue.lookupItemId) || null,
            });
          }}
        />
      </div>
      <div className="form-item">
        <RadiosGroupComponent
          idRef="paymentToExternalAgencyRef"
          labelValue="is-payment-to-external-agency"
          data={[
            {
              key: true,
              value: "yes",
            },
            {
              key: false,
              value: "no",
            },
          ]}
          isDisabled={
            cantEditReservedSaleTransaction || isUnitProfileReservation
          }
          value={state.paymentToExternalAgency}
          parentTranslationPath={parentTranslationPath}
          translationPathForData={translationPath}
          translationPath={translationPath}
          labelInput="value"
          valueInput="key"
          onSelectedRadioChanged={(e, newValue) => {
            if (state.externalAgencyId) {
              onStateChanged({ id: "externalAgencyId", value: null });
              onSelectedChanged({ id: "externalAgency", value: null });
            }
            onStateChanged({
              id: "paymentToExternalAgency",
              value: newValue === "true",
            });
          }}
        />
      </div>
      {state.paymentToExternalAgency && (
        <div className="form-item">
          <DataFileAutocompleteComponent
            idRef="externalAgencyIdRef"
            labelValue="external-agency"
            selectedValues={selected.externalAgency}
            isDisabled={
              cantEditReservedSaleTransaction || isUnitProfileReservation
            }
            multiple={false}
            data={externalAgencies || []}
            displayLabel={(option) =>
              (option.contact &&
                (option.contact.first_name || option.contact.last_name) &&
                `${option.contact.first_name} ${option.contact.last_name}`) ||
              option.contact.company_name ||
              ""
            }
            // renderOption={(option) =>
            //   (option.contact && (
            //     <div className='d-flex-column'>
            //       <div className='d-flex-v-center-h-between w-100 texts-truncate'>
            //         {(option.contact.first_name || option.contact.last_name) && (
            //           <span>{`${option.contact.first_name} ${option.contact.last_name}`}</span>
            //         )}
            //         {option.contact.company_name && <span>{option.contact.company_name}</span>}
            //       </div>
            //       <span className='c-gray-secondary'>
            //         {(option.contact.contact_type_id === 1 &&
            //           (option.contact.mobile.phone || 'N/A')) ||
            //           option.contact.landline_number.phone ||
            //           'N/A'}
            //       </span>
            //     </div>
            //   )) ||
            //   'N/A'}
            getOptionSelected={(option) =>
              option.contactsId === state.externalAgencyId
            }
            withoutSearchButton
            helperText={getErrorByName(schema, "externalAgencyId").message}
            error={getErrorByName(schema, "externalAgencyId").error}
            isWithError
            isSubmitted={isSubmitted}
            isLoading={loadings.externalAgencies}
            onInputKeyUp={(e) => {
              const { value } = e.target;
              if (searchTimer.current) clearTimeout(searchTimer.current);
              searchTimer.current = setTimeout(() => {
                getAllExternalAgencies(value);
              }, 700);
            }}
            parentTranslationPath={parentTranslationPath}
            translationPath={translationPath}
            onChange={(event, newValue) => {
              onSelectedChanged({ id: "externalAgency", value: newValue });
              onStateChanged({
                id: "externalAgencyId",
                value: (newValue && newValue.contactsId) || null,
              });
            }}
          />
        </div>
      )}
      {state.paymentToExternalAgency && (
        <div className="form-item">
          <Inputs
            idRef="externalAgencyCommissionRef"
            labelValue="externalAgencyCommission"
            value={state.externalAgencyCommission}
            endAdornment={
              <InputAdornment position="end" className="px-2">
                %
              </InputAdornment>
            }
            type="number"
            isDisabled={
              cantEditReservedSaleTransaction || isUnitProfileReservation
            }
            min={0}
            max={100}
            parentTranslationPath={parentTranslationPath}
            translationPath={translationPath}
            onInputChanged={(event) => {
              let value = +floatHandler(event.target.value, 3);
              if (value > 100) value = 100;

              onStateChanged({
                id: "externalAgencyCommission",
                value: parseInt(value),
              });
            }}
          />
        </div>
      )}

      <div className="form-item">
        <AutocompleteComponent
          idRef="transactedByIdRef"
          labelValue="transacted-by-sale-description"
          labelClasses="Requierd-Color"
          selectedValues={selected.transactedBy}
          multiple={false}
          data={transactedBy || []}
          isDisabled={
            cantEditReservedSaleTransaction || isUnitProfileReservation
          }
          displayLabel={(option) => option.fullName || ""}
          renderOption={(option) =>
            ((option.userName || option.fullName) &&
              `${option.fullName} (${option.userName})`) ||
            ""
          }
          getOptionSelected={(option) => option.id === state.transactedById}
          withoutSearchButton
          helperText={getErrorByName(schema, "transactedById").message}
          error={getErrorByName(schema, "transactedById").error}
          isWithError
          isSubmitted={isSubmitted}
          isLoading={loadings.transactedBy}
          onInputKeyUp={(e) => {
            const { value } = e.target;
            if (searchTimer.current) clearTimeout(searchTimer.current);
            searchTimer.current = setTimeout(() => {
              getAllTransactedBy(value, selected.transactedBy);
            }, 700);
          }}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          onChange={(event, newValue) => {
            onSelectedChanged({ id: "transactedBy", value: newValue });
            onStateChanged({
              id: "transactedById",
              value: (newValue && newValue.id) || null,
            });
          }}
        />
      </div>
      <div className="form-item">
        <Inputs
          idRef="observationsRef"
          labelValue="observations"
          value={state.observations || ""}
          helperText={getErrorByName(schema, "observations").message}
          error={getErrorByName(schema, "observations").error}
          isDisabled={
            cantEditReservedSaleTransaction || isUnitProfileReservation
          }
          isWithError
          isSubmitted={isSubmitted}
          multiline
          rows={4}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          onInputChanged={(event) => {
            onStateChanged({ id: "observations", value: event.target.value });
          }}
        />
      </div>
      <div className="form-item">
        <DatePickerComponent
          idRef="closingDateRef"
          labelValue="closing-date"
          placeholder="DD/MM/YYYY"
          value={state.closingDate}
          helperText={getErrorByName(schema, "closingDate").message}
          error={getErrorByName(schema, "closingDate").error}
          isDisabled={
            cantEditReservedSaleTransaction || isUnitProfileReservation
          }
          isSubmitted={isSubmitted}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          onDateChanged={(newValue) => {
            onStateChanged({ id: "closingDate", value: newValue });
          }}
        />
      </div>
    </div>
  );
};
AgentInfoSaleRelatedComponent.propTypes = {
  state: PropTypes.instanceOf(Object).isRequired,
  selected: PropTypes.instanceOf(Object).isRequired,
  schema: PropTypes.instanceOf(Object),
  unitTransactionId: PropTypes.number,
  onStateChanged: PropTypes.func.isRequired,
  onSelectedChanged: PropTypes.func.isRequired,
  isSubmitted: PropTypes.bool,
  parentTranslationPath: PropTypes.string.isRequired,
  translationPath: PropTypes.string.isRequired,
  isReadOnly: PropTypes.bool,
};
AgentInfoSaleRelatedComponent.defaultProps = {
  unitTransactionId: undefined,
  schema: undefined,
  isReadOnly: false,
  isSubmitted: false,
};
