import React, { useCallback, useEffect, useReducer, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import Joi from "joi";
import {
  getErrorByName,
  GetParams,
  showError,
  showSuccess,
} from "../../../../../../../Helper";
import {
  DialogComponent,
  Inputs,
  Spinner,
} from "../../../../../../../Components";
import { StaticLookupsIds } from "../../../../../../../assets/json/StaticLookupsIds";
import { DeveloperProfileDocumentLookupsAutocomplete } from "../Controls";
import { ScopeDocumentEnum } from "../../../../../../../Enums";
import {
  CreateScopeDocument,
  UpdateScopeDocument,
  GetScopeCategoryDocuments,
} from "../../../../../../../Services";
import { DocumentsUploader } from "../../../../../../../SharedComponents/DocumentsUploader/DocumentsUploader";

export const DeveloperProfileDocumentManagementDialog = ({
  developerId,
  activeItem,
  onSave,
  isOpen,
  isOpenChanged,
  parentTranslationPath,
  translationPath,
  isDeveloperDocuments,
  isDeveloperContact,
  onSaveDeveloper,
  developerFiles,
  otherSubmitted,
  isCanUploadFiles 
}) => {
  const { t } = useTranslation([parentTranslationPath, "Shared"]);
  const reducer = useCallback((state, action) => {
    if (action.id !== "edit") return { ...state, [action.id]: action.value };
    return {
      ...action.value,
    };
  }, []);
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isEdit] = useState(!!activeItem);
  const [categoryDocumentsData, setCategoryDocumentsData] = useState([]);
  const [isUploading, setIsUploading] = useState(false);
  const [state, setState] = useReducer(reducer, {
    categoryId: null,
    developerId,
    files: [],
    documentTitle: null,
    documentRemarks: null,
  });
  const onStateChanged = (newValue) => {
    setState(newValue);
  };
  const schema = Joi.object({
    categoryId: Joi.number()
      .required()
      .messages({
        "number.base": t(`${translationPath}category-is-required`),
        "number.empty": t(`${translationPath}category-is-required`),
      }),
    documentTitle: isDeveloperContact
      ? Joi.string()
          .required()
          .messages({
            "string.base": t(`${translationPath}document-title-is-required`),
            "string.empty": t(`${translationPath}document-title-is-required`),
          })
      : Joi.any(),
    files: Joi.array()
      .required()
      .min(1)
      .messages({
        "array.base": t(
          `${translationPath}please-select-at-least-one-document`
        ),
        "array.empty": t(
          `${translationPath}please-select-at-least-one-document`
        ),
        "array.min": t(`${translationPath}please-select-at-least-one-document`),
      }),
  })
    .options({
      abortEarly: false,
      allowUnknown: true,
    })
    .validate(state);
  const onSaveClicked = async (event) => {
    event.preventDefault();
    setIsSubmitted(true);
    setIsLoading(true);
    if (getErrorByName(schema, "files").error) {
      showError(getErrorByName(schema, "files").message);
      setIsLoading(false);
      return;
    }
    if (schema.error) {
      showError(t("Shared:please-fix-all-errors"));
      setIsLoading(false);
      return;
    }
    const addOrEditDocument = {
      scopeId: +developerId,
      scopeTypeId: isDeveloperDocuments
        ? ScopeDocumentEnum.DeveloperDocument.scopeTypeId
        : ScopeDocumentEnum.Contact.scopeTypeId,
      categoryFiles: [
        {
          categoryId: state && state.categoryId,
          files: state.files,
        },
      ],
    };

    setIsLoading(true);
    const res =
      (activeItem &&
        activeItem.categoryId &&
        (await UpdateScopeDocument(addOrEditDocument))) ||
      (await CreateScopeDocument(addOrEditDocument));
    setIsLoading(false);

    setIsLoading(true);
    if (!(res && res.data && res.data.ErrorId)) {
      showSuccess(
        t(`${translationPath}developer-document-created-successfully`)
      );
      onSave();
    } else if (
      res &&
      res.data &&
      res.data.Message &&
      res.data.Message ===
        "/CrmDfm/ScopeDocument/CreateScopeDocument : SCOPE_IMAGE_ALREADY_EXISTS_PLEASE_UPDATE_THIS_UNIT_IMAGE"
    )
      showError(t("SCOPE_IMAGE_ALREADY_EXISTS_PLEASE_UPDATE_THIS_UNIT_IMAGE"));
    else showError(t(`${translationPath}developer-document-create-failed`));
    setIsLoading(false);
  };

  useEffect(() => {
    if (activeItem) {
      const files = getActiveItemFiles();

      setState({
        id: "edit",
        value: !isDeveloperContact
          ? {
              files,
              categoryId: activeItem && activeItem.categoryId,
            }
          : {
              files,
              categoryId: activeItem && activeItem.categoryId,
              documentTitle: activeItem.documentTitle,
              documentRemarks: activeItem.documentRemarks,
            },
      });
    }
  }, [activeItem, categoryDocumentsData]);

  const getScopeCategoryDocuments = useCallback(async () => {
    setIsLoading(true);

    const body = {
      categoryId: +(activeItem && activeItem.categoryId),
      scopeId: developerId,
      pageSize: 25,
      pageIndex: 0,
    };

    const res = await GetScopeCategoryDocuments(body);

    if (!(res && res.status && res.status !== 200))
      setCategoryDocumentsData(res.result);
    else setCategoryDocumentsData([]);

    setIsLoading(false);
  });

  useEffect(() => {
    if (isEdit && developerId && !isDeveloperContact)
      getScopeCategoryDocuments();
  }, [activeItem, developerId]);

  const getActiveItemFiles = () => {
    let files = [];
    if (isDeveloperContact && activeItem && developerFiles)
      files =
        (developerFiles &&
          developerFiles.length > 0 &&
          developerFiles.map((item) => ({
            uuid: item.fileId,
            fileName: item.fileName,
            documentLink: item.documentLink,
          }))) ||
        [];
    else if (!isDeveloperContact && activeItem) {
      files =
        (categoryDocumentsData &&
          categoryDocumentsData.length > 0 &&
          categoryDocumentsData.map((item) => ({
            uuid: item.documentId,
            fileName: item.documentName,
            documentLink: item.documentLink,
          }))) ||
        [];
    }
    return files;
  };

  return (
    <DialogComponent
      titleText={
        (isEdit && t(`${translationPath}edit-documents`)) ||
        t(`${translationPath}upload-documents`)
      }
      saveText={
        (isEdit && t(`${translationPath}save`)) || t(`${translationPath}upload`)
      }
      saveType="button"
      maxWidth="md"
      disableBackdropClick
      saveIsDisabled={(state && state.categoryId === null) || isUploading || (!isCanUploadFiles && isDeveloperContact)}
      dialogContent={
        <div className="contact-profile-document-management-dialog d-flex-column-center">
          <Spinner isActive={isLoading} isAbsolute />
          {isDeveloperContact && (
            <div className="mb-3 ml-5 w-75">
              <Inputs
                idRef="documentRemarksRef"
                labelValue="documentName"
                value={state.documentTitle || ""}
                parentTranslationPath={parentTranslationPath}
                translationPath={translationPath}
                onInputChanged={(e) => {
                  const { value } = e.target;
                  setState({ id: "documentTitle", value: value });
                }}
                isWithError
                isLoading={isLoading}
                isSubmitted={isSubmitted || otherSubmitted}
                helperText={getErrorByName(schema, "documentTitle").message}
                error={getErrorByName(schema, "documentTitle").error}
              />
            </div>
          )}
          <div className="mb-3 ml-5 w-75">
            <DeveloperProfileDocumentLookupsAutocomplete
              idRef="categoryIdRef"
              labelValue="category"
              stateValue={state && state.categoryId}
              schema={schema}
              lookupTypeId={
                GetParams("formType") == "2"
                  ? StaticLookupsIds.CompanyUploadDocumentsType
                  : StaticLookupsIds.ContactUploadDocumentsType
              }
              isSubmitted={isSubmitted}
              stateKey="categoryId"
              onStateChanged={onStateChanged}
              parentTranslationPath={parentTranslationPath}
              translationPath={translationPath}
              isDisabled={isDeveloperContact ? false : isEdit}
              isDeveloperDocuments={isDeveloperDocuments}
            />
          </div>
          <div className="mb-3 ml-5 w-75">
            <Inputs
              idRef="documentRemarksRef"
              labelValue="documentRemarks"
              multiline
              row={5}
              stateKey="documentRemarks"
              parentTranslationPath={parentTranslationPath}
              translationPath={translationPath}
              isDeveloperDocuments={isDeveloperDocuments}
              onInputChanged={(e) => {
                const { value } = e.target;
                setState({ id: "documentRemarks", value: value });
              }}
            />
          </div>
          <DocumentsUploader
            initUploaded={
              (state.files &&
                state.files.length > 0 &&
                state.files.map((item) => ({
                  uuid: item.uuid || item.fileId,
                  fileName: item.fileName,
                  documentLink: item.documentLink,
                }))) ||
              []
            }
            docTypes={{
              images: 0,
              videos: 1,
              docs: 2,
              links: 3,
            }}
            isUploading={isUploading}
            setIsUploading={setIsUploading}
            uploadedChanged={(files) =>
              setState({
                id: "files",
                value:
                  (files &&
                    files.map((file) => ({
                      fileId: file.uuid || file.fileId || null,
                      fileName: file.fileName,
                      documentLink: file.documentLink || null,
                    }))) ||
                  [],
              })
            }
            open
            translationPath={translationPath}
            parentTranslationPath={parentTranslationPath}
          />
        </div>
      }
      saveClasses="btns theme-solid  w-100 mx-2 mb-2"
      isOpen={isOpen}
      onSaveClicked={() =>
        !isDeveloperContact ? onSaveClicked : onSaveDeveloper(state)
      }
      onCloseClicked={isOpenChanged}
      onCancelClicked={isOpenChanged}
    />
  );
};

DeveloperProfileDocumentManagementDialog.propTypes = {
  developerId: PropTypes.number.isRequired,
  activeItem: PropTypes.instanceOf(Object),
  onSave: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  isOpenChanged: PropTypes.func.isRequired,
  parentTranslationPath: PropTypes.string.isRequired,
  translationPath: PropTypes.string.isRequired,
  isDeveloperDocuments: PropTypes.bool,
};
DeveloperProfileDocumentManagementDialog.defaultProps = {
  activeItem: null,
  isDeveloperDocuments: false,
};
