import React, { useCallback, useEffect, useState } from 'react'; 
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { showError, showSuccess } from '../../../../../../../Helper';
import { SetUnitAsAvailableOrDraft, GetAllUnitTransactionReasons } from '../../../../../../../Services';
import { UnitsOperationTypeEnum, UnitsStatusEnum } from '../../../../../../../Enums';
import { DialogComponent, Inputs, Spinner, AutocompleteComponent } from '../../../../../../../Components';

export const UnitStatusDraftDialog = ({
  activeItem,
  isOpen,
  reloadData,
  isOpenChanged,
  translationPath,
  parentTranslationPath,
}) => {
  const { t } = useTranslation([parentTranslationPath, 'Shared']);
  const [isLoading, setIsLoading] = useState(false);
  const [notes, setNotes] = useState('');
  const [cancelReasonData, setCancelReasonData] = useState([]);
  const [selectedCancelReason, setSelectedCancelReason] = useState(null);
  
  const saveHandler = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    const body = {
      unitId: activeItem?.id,
      status: UnitsStatusEnum.Draft.key,
      note: notes,
      rowVersion: activeItem?.rowVersion,
      OperationType: UnitsOperationTypeEnum.sale.key,
      unitTransactionReasonId: selectedCancelReason?.unitTransactionReasonId,
    }

    const res = await SetUnitAsAvailableOrDraft(body);
    if (!(res && res.status && res.status !== 200)) {
      showSuccess(t`${translationPath}unit-status-updated-successfully`);
      if (reloadData) reloadData();
    } else {
       if(res?.data?.Message) showError(t(`${translationPath}${res.data.Message.split(': ')[1]}`))
       else showError(t(`unit-status-update-failed`))
    }
      setIsLoading(false);
  };

  const getAllUnitTransactionReasons = useCallback( async ()=>{

    setIsLoading(true);
    const currentStatus = activeItem?.unitStatus?.key;
    const destinationStatus = UnitsStatusEnum.Draft.key;

    const res = await GetAllUnitTransactionReasons(currentStatus, destinationStatus);

    setCancelReasonData(res)
    if (!(res && res.status && res.status !== 200)) {
      setCancelReasonData(res)
    }
    setIsLoading(false);
  },[activeItem.unitStatus])


  useEffect(()=>{
    getAllUnitTransactionReasons();
  },[getAllUnitTransactionReasons])

  return (
    <DialogComponent
      titleText='draft-transaction'
      saveText='save'
      maxWidth='sm'
      dialogContent={(
        <div className='unit-status-draft-dialog-wrapper view-wrapper'>
          <Spinner isActive={isLoading} isAbsolute />
          <div className='title-box-wrapper'>
            <div className='box-wrapper'>
              <div className='box-item-wrapper'>
                <span className='box-title'>{t(`${translationPath}ref-no`)}</span>
                <span className='box-value'>{activeItem?.refNo || 'N/A'}</span>
              </div>
              <div className='box-item-wrapper'>
                <span className='box-title'>{t(`${translationPath}unit-no`)}</span>
                <span className='box-value'>
                  {activeItem?.unit_number || 'N/A'}
                </span>
              </div>
              <div className='box-item-wrapper'>
                <span className='box-title'>{t(`${translationPath}community`)}</span>
                <span className='box-value'>{(activeItem?.community?.lookupItemName) || 'N/A'}</span>
              </div>
              <div className='box-item-wrapper'>
                <span className='box-title'>{t(`${translationPath}property`)}</span>
                <span className='box-value'>{(activeItem?.propertyName) || 'N/A'}</span>
              </div>
            </div>
          </div>
          <div className='w-100 px-3 mb-3'>
            <Inputs
              idRef='notesRef'
              labelValue='notes'
              value={notes || ''}
              multiline
              rows={6}
              parentTranslationPath={parentTranslationPath}
              translationPath={translationPath}
              onInputChanged={(event) => setNotes(event.target.value)}
            />
          </div>
          {
          (activeItem?.unitStatus?.key !== UnitsStatusEnum.Available.key) &&
            <div className='w-100 px-3 mb-3'>
              <AutocompleteComponent
                idRef='cancellation-reasonRef'
                labelValue='cancellation-reason'
                selectedValues={selectedCancelReason}
                data={cancelReasonData || []}
                displayLabel={(option) => t(`${option.reasonName || ''}`)}
                getOptionSelected={(option) =>
                  selectedCancelReason.unitTransactionReasonId === option.unitTransactionReasonId !== -1 || ''}
                withoutSearchButton
                isWithError
                parentTranslationPath={parentTranslationPath}
                translationPath={translationPath}
                multiple={false}
                onChange={(event, newValue) => {
                  setSelectedCancelReason(newValue);
                }}
              />
            </div>
          }
        </div>
      )}
      isOpen={isOpen}
      onSubmit={saveHandler}
      onCancelClicked={isOpenChanged}
      parentTranslationPath={parentTranslationPath}
      translationPath={translationPath}
    />
  );
};

UnitStatusDraftDialog.propTypes = {
  activeItem: PropTypes.instanceOf(Object),
  reloadData: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  isOpenChanged: PropTypes.func.isRequired,
  parentTranslationPath: PropTypes.string,
  translationPath: PropTypes.string,
};
UnitStatusDraftDialog.defaultProps = {
  activeItem: null,
  parentTranslationPath: 'UnitsStatusManagementView',
  translationPath: '',
};
