
export const HubspotContactsPermissions = {

    ViewHubspotContacts : {
         permissionsId: "4538898f-4c51-450d-7970-08dcaf947ea8",
         permissionsName: "View Hubspot Contacts",
         description: null,
         componentsId: null,
         components: null
    }
  };
  