import React, { useEffect, useState } from 'react';
import { ProgressComponet, Spinner } from '../../../Components';
import { GetParams, showError } from '../../../Helper';
import { useSelector } from 'react-redux';
import { GetUserLMSProfileOverview } from '../../../Services';
import { LearningUserProfileLocalePath } from './i18n/LearningUserProfileLocale';
import { useTranslation } from 'react-i18next';
import NoSearchResult from '../../../assets/images/defaults/NoContent.png';
import UserLearningCard from './components/UserLearningCard';
import UserLearningContainer from './components/UserLearningContainer';
import CertificatesTable from './components/CertificatesTable';
import CoursesTable from './components/CoursesTable';

const tabs = {
  COURSES: 'Courses',
  TESTS: 'Tests',
  CERTIFICATES: 'Certificates',
};

function LearningUserProfile() {
  const { t } = useTranslation(LearningUserProfileLocalePath);
  const [activeTab, setActiveTab] = useState(tabs.COURSES);
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState(null);
  const email = GetParams('email');
  const currentUserEmail = useSelector(
    (state) => state.login.loginResponse.email
  );

  const getUserLMSProfileOverview = async () => {
    try {
      const result = await GetUserLMSProfileOverview(email ?? currentUserEmail);
      setData({
        ...result,
        averageScore: parseInt(result.averageScore)
      });
    } catch (error) {
      showError(t('user-not-exist'));
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getUserLMSProfileOverview();
  }, []);
  if (isLoading) {
    return <Spinner isAbsolute isActive />;
  }
  return (
    <div className='learning-profile'>
      {data ? (
        <>
          <UserLearningContainer title={t('user-profile')}>
            <div className='learning-cards-container'>
              <UserLearningCard title={t('username')} text={data.userName} />
              <UserLearningCard title={t('email-address')} text={data.email} />
              <UserLearningCard
                title={t('branch-name')}
                text={data.branchName}
              />
              <UserLearningCard title={t('team-name')} text={data.teamNames} />
              <UserLearningCard
                title={t('line-manager')}
                text={data.lineManager}
              />
            </div>
          </UserLearningContainer>
          <UserLearningContainer title={t('performance-dashboard')}>
            <div className='learning-cards-container'>
              <UserLearningCard
                title={t('number-of-courses')}
                text={data.numberOfCompletedCourses}
                isNumber
              />
              <UserLearningCard
                title={t('number-of-certificates')}
                text={data.numberOfCertificates}
                isNumber
              />
              <UserLearningCard
                title={t('average-score')}
                text={`${data.averageScore}%`}
                isNumber
                footer={
                  <ProgressComponet
                    progressText={`${data.averageScore}%`}
                    value={data.averageScore}
                  />
                }
                // hidden
              />
            </div>
          </UserLearningContainer>
          <UserLearningContainer title={t('details')}>
            <div className='learning-tab'>
              <button
                className='tab-btn'
                active={String(activeTab === tabs.COURSES)}
                onClick={() => setActiveTab(tabs.COURSES)}
              >
                {t('courses')}
              </button>
              <button
                className='tab-btn hidden'
                active={String(activeTab === tabs.TESTS)}
                onClick={() => setActiveTab(tabs.TESTS)}
              >
                {t('tests')}
              </button>
              <button
                className='tab-btn'
                active={String(activeTab === tabs.CERTIFICATES)}
                onClick={() => setActiveTab(tabs.CERTIFICATES)}
              >
                {t('certificates')}
              </button>
            </div>
          </UserLearningContainer>
          {activeTab === tabs.COURSES && (
            <CoursesTable courses={data.courseDetails} />
          )}
          {activeTab === tabs.CERTIFICATES && (
            <CertificatesTable certificates={data.certificates} />
          )}
        </>
      ) : (
        <div className='flex flex-col gap-1 fa-center fj-center'>
          <img src={NoSearchResult} alt='' />
          <h1 className='text-center no-content-title'>
            {t('user-not-found')}
          </h1>
        </div>
      )}
    </div>
  );
}

export default LearningUserProfile;
