import { createAction } from '@reduxjs/toolkit';
const FETCH_FILE_BY_PROCESS_ID_S = createAction('FETCH_FILE_BY_PROCESS_ID_S');
const FETCH_FILE_BY_PROCESS_ID_S_SUCCESS = createAction('FETCH_FILE_BY_PROCESS_ID_S_SUCCESS');
const FETCH_FILE_BY_PROCESS_ID_S_FAIL = createAction('FETCH_FILE_BY_PROCESS_ID_S_FAIL');
const FETCH_FILE_BY_PROCESS_ID_F = createAction('FETCH_FILE_BY_PROCESS_ID_F');
const FETCH_FILE_BY_PROCESS_ID_F_SUCCESS = createAction('FETCH_FILE_BY_PROCESS_ID_F_SUCCESS');
const FETCH_FILE_BY_PROCESS_ID_F_FAIL = createAction('FETCH_FILE_BY_PROCESS_ID_F_FAIL');
const FETCH_APPROVE_IMPORTED_FILE = createAction('FETCH_APPROVE_IMPORTED_FILE');
const FETCH_APPROVE_IMPORTED_FILE_SUCCESS = createAction('FETCH_APPROVE_IMPORTED_FILE_SUCCESS');
const FETCH_APPROVE_IMPORTED_FILE_FAIL = createAction('FETCH_APPROVE_IMPORTED_FILE_FAIL');
export {
	FETCH_FILE_BY_PROCESS_ID_S, FETCH_FILE_BY_PROCESS_ID_S_SUCCESS, FETCH_FILE_BY_PROCESS_ID_S_FAIL,
	FETCH_FILE_BY_PROCESS_ID_F,FETCH_FILE_BY_PROCESS_ID_F_SUCCESS,FETCH_FILE_BY_PROCESS_ID_F_FAIL,
	FETCH_APPROVE_IMPORTED_FILE,FETCH_APPROVE_IMPORTED_FILE_SUCCESS,FETCH_APPROVE_IMPORTED_FILE_FAIL,
};
