import React from "react";

function TrashOne({ fill, ...restProps }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill={fill ?? ""}
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <path
        d="M9.749 1.045c-.978.094-1.712.512-2.222 1.265-.365.538-.483.99-.515 1.98L6.989 5H4.941c-2.223 0-2.275.004-2.559.221-.08.061-.195.194-.254.295-.091.156-.108.23-.108.484 0 .256.016.328.111.489.125.213.318.375.539.454.098.035.353.054.74.055L4 7v5.87c0 6.498.005 6.654.245 7.45.332 1.102 1.333 2.103 2.435 2.435.759.229 1.108.245 5.32.245s4.561-.016 5.32-.245c1.102-.332 2.103-1.333 2.435-2.435.24-.796.245-.952.245-7.45V7l.59-.002c.387-.001.642-.02.74-.055.221-.079.414-.241.539-.454.095-.161.111-.233.111-.489 0-.254-.017-.328-.108-.484a1.209 1.209 0 0 0-.254-.295C21.334 5.004 21.282 5 19.06 5h-2.048l-.024-.71c-.034-.994-.151-1.444-.515-1.98a2.882 2.882 0 0 0-1.144-.989c-.31-.153-.623-.23-1.149-.279-.515-.049-3.919-.047-4.431.003m4.625 2.031c.202.066.488.354.544.55.024.085.055.428.069.764l.025.61H9v-.37c.001-.606.063-1.02.179-1.196.154-.232.368-.354.687-.393.444-.054 4.325-.024 4.508.035m3.615 10.034c-.015 6.657-.005 6.366-.237 6.842-.126.259-.562.686-.834.816-.441.212-.45.212-4.918.212-4.461 0-4.473-.001-4.918-.209-.27-.128-.705-.554-.834-.819-.232-.476-.222-.185-.237-6.842L5.996 7h12.008l-.015 6.11M9.6 10.584a1.078 1.078 0 0 0-.505.516l-.094.2v5.4l.094.2c.181.381.5.588.905.588s.724-.207.905-.588l.094-.2v-5.4l-.094-.2a1.06 1.06 0 0 0-.514-.518c-.188-.079-.608-.078-.791.002m4 0a1.078 1.078 0 0 0-.505.516l-.094.2v5.4l.094.2c.181.381.5.588.905.588s.724-.207.905-.588l.094-.2v-5.4l-.094-.2a1.06 1.06 0 0 0-.514-.518c-.188-.079-.608-.078-.791.002"
        fill-rule="evenodd"
        fill={fill ?? ""}
      />
    </svg>
  );
}

export default TrashOne;
