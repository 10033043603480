import React from 'react';
import moment from 'moment';
import { LoadableDocumentComponent } from '../../../../../../../../Components';

export const AttachedFilesHeaderData = [
  {
    id: 1,
    label: 'file-name',
    input: 'fileName',
    isHiddenFilter: true,
    component: (item)=> <span className='file-icon-wrapper d-flex-center'>
      <span className='mr-1'>
      <LoadableDocumentComponent
        classes='file-icon'
        fileName={item.fileName}
        alt={`cover-image`}
      />
      </span>
      <span className='d-flex-column'>
      <span className='file-title'>{item.fileName}</span>
      <span className='file-size'>{item.fileSize}</span>
      </span>
    </span>
  },
  {
    id: 2,
    label: 'file-size',
    input: 'fileSize',
    isHiddenFilter: true,
  },
  {
    id: 3,
    label: 'date-uploaded',
    input: 'createdOn',
    isHiddenFilter: true,
    component: (item)=> <span>{item.createdOn ? moment(item.createdOn).format('LL'): ''}</span>
  },
  {
    id: 4,
    label: 'time-uploaded',
    input: 'createdOn',
    isHiddenFilter: true,
    component: (item)=> <span>{item.createdOn ? moment(item.createdOn).format('LT'): ''}</span>
  },
  {
    id: 5,
    label: 'last-update',
    input: 'updateOn',
    isHiddenFilter: true,
    component: (item)=> <span>{item.createdOn ? moment(item.createdOn).format('LL'): ''}</span>
  },
  {
    id: 6,
    label: 'uploaded-by',
    input: 'uploader',
    isHiddenFilter: true,
  },
];
