export const AgentTypesEnum = {
    Seller: {
        key: 1,
        value: 'Seller',
    },
    Landlord: {
        key: 2,
        value: 'Landlord',
    },
    Buyer: {
        key: 3,
        value: 'Buyer',
    },
    Tenant: {
        key: 4,
        value: 'Tenant',
    },
};
