import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { TableColumnsFilterComponent, Tables } from '../../../../../Components';
import {
  ColumnsFilterPagesEnum,
  FormsIdsEnum,
  TableActions,
  TableFilterTypesEnum,
  ListingAgentIdsEnum,
  HighlightRowKeys
} from '../../../../../Enums';
import { GetAllFormFieldsByFormId } from '../../../../../Services';
import { returnPropsByPermissions } from '../../../../../Helper' ; 
import { LeadOwnerUnitSalePermissions } from '../../../../../Permissions' ; 
import { UnitsSalesTableHeaderData } from './UnitsSalesTableHeaderData';
import { TableColumnsFilterActions } from '../../../../../store/TableColumnsFilter/TableColumnsFilterActions';

export const UnitsSalesTableComponent = ({
  detailsUnitsList,
  tableActionClicked,
  onPageIndexChanged,
  onPageSizeChanged,
  filter,
  parentTranslationPath,
  focusedRowChanged,
  unitsTableFilter,
  onFilterValuesChanged,
  checkedCardsIds,
  getIsSelected,
  onSelectClicked,
  activeSelectedAction,
  setCheckedCards,
  setOrderBy,
  isClearFiltersClicked,
  setIsClearFiltersClicked,
  displyOpenFileButton,
  displyMatchingButton,
  localStorageKey
}) => {
  const dispatch = useDispatch();
  const [allFormFields, setAllFormFields] = useState([]);
  const [sortBy, setSortBy] = useState(null);
  const [tableColumns, setTableColumns] = useState([]);
  const [tableFilterData, setTableFilterData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [tableActionsWithPermissions, setTableActionsWithPermissions] = useState([]);
  const [selectedTableFilterColumns, setSelectedTableFilterColumns] = useState(
    UnitsSalesTableHeaderData.filter((item) => item.isDefaultFilterColumn).map(
      (column) => column.id
    )
  );
  const tableColumnsFilterResponse = useSelector((state) => state.TableColumnsFilterReducer);
  const getAllFormFieldsByFormId = useCallback(async () => {
    setIsLoading(true);
    const result = await GetAllFormFieldsByFormId(FormsIdsEnum.units.id);
    if (!((result && result.data && result.data.ErrorId) || !result) && Array.isArray(result)) {
      const list = result.filter(e =>
        e.formFieldId !== ListingAgentIdsEnum.RentListingAgent.formFieldId &&
        e.formFieldName !== 'amenities' &&
        e.formFieldName !== 'fitting_and_fixtures' &&
        e.formFieldName !== 'unit_model' &&
        e.formFieldName !== 'primary_view' &&
        e.formFieldName !== 'secondary_view' &&
        e.formFieldName !== 'rent_price_fees' &&
        e.formFieldName !== 'selling_price_agency_fee' &&
        e.formFieldName !== 'owner'
      )
      setAllFormFields(list)
    }
    else setAllFormFields([]);
    setIsLoading(false);
  }, []);

  const getIsSelectedAll = useCallback(
    () => {
      const returnSelect = (checkedCardsIds &&
        detailsUnitsList.result.findIndex((item) => !checkedCardsIds.includes(item.id)) === -1) || false;
      return returnSelect;
    }
  );

  const onSelectAllClicked = () => {
    const cardItmes = [];
    if (!getIsSelectedAll()) {
      detailsUnitsList.result.map((item) => {
        if (!getIsSelected(item)) {
          checkedCardsIds.push(item.id);
          cardItmes.push({ ...item });
        }
      });
    } else {
      detailsUnitsList.result.map((item) => {
        if (getIsSelected(item)) {
          const isSelectedIndex = checkedCardsIds.findIndex(
            (element) => element === item.id
          );
          if (isSelectedIndex !== -1) checkedCardsIds.splice(isSelectedIndex, 1);
        }
      });
    }
    setCheckedCards(cardItmes);
  };
  useEffect(() => {
    getAllFormFieldsByFormId();
  }, [getAllFormFieldsByFormId]);
  useEffect(() => {
    if (
      tableColumnsFilterResponse &&
      tableColumnsFilterResponse[ColumnsFilterPagesEnum.unitsSales.key]
    ) {
      setSelectedTableFilterColumns(
        tableColumnsFilterResponse[ColumnsFilterPagesEnum.unitsSales.key]
      );
    }
  }, [tableColumnsFilterResponse]);
  useEffect(() => {
    setTableColumns([
      ...UnitsSalesTableHeaderData.filter(
        (item) => selectedTableFilterColumns.findIndex((element) => element === item.id) !== -1
      ),
      ...allFormFields
        .filter(
          (item) =>
            selectedTableFilterColumns.findIndex((element) => element === item.formFieldId) !== -1
        )
        .map((field) => ({
          id: field.formFieldId,
          key: field.formFieldKey,
          isDate: field.uiWidgetType === 'alt-date',
          label: (field.formFieldTitle === "Lead Owner" ? field.formFieldTitle = "Unit Owner" : field.formFieldTitle && field.formFieldTitle.replace('*', '')) || '',
          input: field.displayPath || undefined,
          searchableKey: field.searchableKey,
          isSortable: true,
          isNumber:
            field.propertyJson && JSON.parse(field.propertyJson).schema &&
            field.propertyJson && JSON.parse(field.propertyJson).schema.specialKey === 'currency' ||
            field.propertyJson && JSON.parse(field.propertyJson).schema &&
            field.propertyJson && JSON.parse(field.propertyJson).schema.specialKey === 'decimal' ||
            field.propertyJson && JSON.parse(field.propertyJson).schema &&
            field.propertyJson && JSON.parse(field.propertyJson).schema.specialKey === 'size'

        })),
    ]);
  }, [allFormFields, selectedTableFilterColumns]);
  useEffect(() => {
    setTableFilterData(
      tableColumns.map((column) => ({
        key: column.key || column.fieldKey || column.id,
        filterType:
          (column.isDate && TableFilterTypesEnum.datePicker.key) ||
          (column.isNumber && TableFilterTypesEnum.numberInput.key) ||
          TableFilterTypesEnum.textInput.key,
        isHiddenFilter: column.isHiddenFilter,
        isHidden: column.isHidden,
        textInputType: column.textInputType,
        textInputMax: column.textInputMax,
        textInputMin: column.textInputMin,
        searchableKey: column.searchableKey || column.input,
        displayPath:
          (column.key && column.input) ||
          (column.fieldKey &&
            allFormFields &&
            allFormFields.findIndex((item) => item.formFieldKey === column.fieldKey) !== -1 &&
            allFormFields.find((item) => item.formFieldKey === column.fieldKey).displayPath) ||
          undefined,
      }))
    );
  }, [allFormFields, tableColumns]);
  useEffect(() => {
    if (sortBy)
      setOrderBy((item) => ({ ...item, filterBy: sortBy.filterBy && sortBy.filterBy[0].toLowerCase() + sortBy.filterBy.slice(1), orderBy: sortBy.orderBy, fieldType: sortBy.fieldType }));
  }, [sortBy]);


  useEffect(() => {
    let list = [];
    if (displyOpenFileButton)
      list.push({ enum: TableActions.openFile.key });

      if (returnPropsByPermissions(LeadOwnerUnitSalePermissions.AddNewActivity.permissionsId))
      list.push({ enum: TableActions.addActivity.key });

    setTableActionsWithPermissions(list);

  }, [ displyOpenFileButton]);

  return (
    <div className='w-100 px-3'>
      <TableColumnsFilterComponent
        columns={UnitsSalesTableHeaderData.filter(item => !item.isHidden ).concat(
          allFormFields.filter(
            (item) =>
              UnitsSalesTableHeaderData.findIndex(
                (element) => element.fieldKey === item.formFieldKey
              ) === -1
          )
        ).map((item) => ({
          key: item.formFieldId || item.id,
          value: (item.formFieldTitle === "Lead Owner" ? item.formFieldTitle = "Unit Owner" : item.formFieldTitle && item.formFieldTitle.replace('*', '')) || item.label,
        }))}
        isLoading={isLoading}
        selectedColumns={selectedTableFilterColumns}
        onSelectedColumnsChanged={(newValue) => {
          setSelectedTableFilterColumns(newValue);
          let localTableColumnsFilterResponse = tableColumnsFilterResponse;
          if (localTableColumnsFilterResponse)
            localTableColumnsFilterResponse[ColumnsFilterPagesEnum.unitsSales.key] = newValue;
          else {
            localTableColumnsFilterResponse = {
              [ColumnsFilterPagesEnum.unitsSales.key]: newValue,
            };
          }
          dispatch(
            TableColumnsFilterActions.TableColumnsFilterRequest(localTableColumnsFilterResponse)
          );
        }}
        parentTranslationPath={parentTranslationPath}
        translationPath=''
      />

      <Tables
        data={detailsUnitsList.result}
        headerData={tableColumns}
        filterValues={unitsTableFilter}
        onFilterValuesChanged={onFilterValuesChanged}
        filterData={tableFilterData}
        isWithFilter
        setSortBy={setSortBy}
        selectAllOptions={
          ((activeSelectedAction === 'bulk-update') && {
            selectedRows: checkedCardsIds,
            getIsSelected,
            disabledRows: [],
            withCheckAll: true,
            onSelectAllClicked,
            isSelectAll: getIsSelectedAll(),
            onSelectClicked,
          }) ||
          undefined
        }
        onPageIndexChanged={onPageIndexChanged}
        onPageSizeChanged={onPageSizeChanged}
        actionsOptions={{
          onActionClicked: tableActionClicked,
        }}
        defaultActions={tableActionsWithPermissions}
        parentTranslationPath={parentTranslationPath}
        itemsPerPage={filter.pageSize}
        activePage={filter.pageIndex}
        focusedRowChanged={focusedRowChanged}
        totalItems={detailsUnitsList ? detailsUnitsList.totalCount : 0}
        isClearFiltersClicked={isClearFiltersClicked}
        setIsClearFiltersClicked={setIsClearFiltersClicked}
        isLoading={isLoading}
        localStorageKey={localStorageKey}
        highlightedRowKey={HighlightRowKeys.UnitSaleLeadOwner.key}
      />
    </div>
  );
};

UnitsSalesTableComponent.propTypes = {
  detailsUnitsList: PropTypes.instanceOf(Object).isRequired,
  tableActionClicked: PropTypes.func.isRequired,
  onPageIndexChanged: PropTypes.func.isRequired,
  onPageSizeChanged: PropTypes.func.isRequired,
  filter: PropTypes.instanceOf(Object).isRequired,
  parentTranslationPath: PropTypes.string.isRequired,
  focusedRowChanged: PropTypes.func.isRequired,
  onFilterValuesChanged: PropTypes.func.isRequired,
  unitsTableFilter: PropTypes.instanceOf(Object),
  displyOpenFileButton: PropTypes.bool,
  displyMatchingButton: PropTypes.bool,
};
UnitsSalesTableComponent.defaultProps = {
  unitsTableFilter: undefined,
  displyOpenFileButton: false,
  displyMatchingButton: false,
};
