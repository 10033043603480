import React from "react";
import { Box } from "@material-ui/core";
import { SummaryCard } from "../index";


// styles
import useStyles from "./styles";

function SummaryCardList({ cardTitles }) {
  const styles = useStyles();

  return (
    <Box className={styles.root}>
      <SummaryCard 
        title={cardTitles[0]}
        subTitle="26,000"
        isSelected={false}
      />
      <SummaryCard 
        title={cardTitles[1]}
        subTitle="500"
        isSelected
      />
      <SummaryCard 
        title={cardTitles[2]}
        subTitle="13,786"
        isSelected={false}
      />
      <SummaryCard 
        title={cardTitles[3]}
        subTitle="5,102"
        isSelected={false}
      />
      <SummaryCard 
        title={cardTitles[4]}
        subTitle="5,102"
        isSelected={false}
      />
    </Box>
  );
}

export default SummaryCardList;
