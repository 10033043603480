import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => {
  return {
    socialProfilesWrapper: {
      display: "flex",
      flexDirection: "column",
      gap: "8px",
      marginTop: "16px",
    },
    socialProfileItem: {
      display: "flex",
      alignItems: "center",
    },
    socialIcon: {
      marginInlineEnd: "8px",
      minWidth: "25px",
      minHeight: "25px",
    },
    socialProfileText: {
      fontWeight: 700,
      fontSize: "16px",
      lineHeight: "24px",
      color: theme.palette.text.tertiary,
      marginInlineEnd: "12px",
      whiteSpace: "nowrap",
    },
    socialLink: {
      color: "#3B72D9",
      fontSize: "16px",
      fontWeight: 500,
      lineHeight: "24px",
      // maxWidth: "250px",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      "&:hover": {
        color: "#3B72D9",
      },
      "&:active": {
        color: "#3B72D9",
      },
      "&:focus": {
        outline: "none",
        color: "#3B72D9",
      },
    },
    emptyState: {
      fontSize: "18px",
      marginTop: "16px",
    },
  };
});
