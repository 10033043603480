import React from "react";

function ArrowUpRight({ fill, ...restProps }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill={fill ?? ""}
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <path
        d="M6.695 6.057a1.04 1.04 0 0 0-.567.459c-.091.156-.108.23-.108.484 0 .256.016.328.111.489.125.213.318.375.539.454.121.043.893.054 4.03.055L14.579 8l-4.205 4.21c-2.313 2.315-4.239 4.273-4.279 4.35-.052.099-.074.228-.074.44-.001.258.015.327.112.492.13.222.369.409.603.473.202.054.53.024.709-.066.074-.037 2.029-1.959 4.344-4.272l4.209-4.205.011 3.942.011 3.942.121.197a.998.998 0 0 0 1.718 0l.121-.197V6.7l-.108-.184a1.02 1.02 0 0 0-.582-.461c-.262-.079-10.344-.077-10.595.002"
        fill-rule="evenodd"
        fill={fill ?? ""}
      />
    </svg>
  );
}

export default ArrowUpRight;
