import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  modalContainer: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 1000,
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
  modalPaper: {
    display: "flex",
    flexDirection: "column",
    maxWidth: "1000px",
    borderRadius: "12px",
    background: theme.palette.background.paper,
    boxShadow: "0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03)",
    padding: "24px",
  },
  dialogHeadingContainer: {
    display: "flex",
    gap: "16px",
    marginBottom: "32px",
  },
  dialogHeading: {
    display: "flex",
    gap: "4px",
    flexDirection: "column",
  },
  alertIconWrapper: {
    minWidth: "48px",
    minHeight: "48px",
    maxWidth: "48px",
    maxHeight: "48px",
    display: "inline-flex",
    justifyContent: "center",
    alignItems: "center",
    background: theme.palette.background.errorSecondary,
    borderRadius: "50%",
  },
  titleWithIcon: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  title: {
    fontSize: "18px",
    fontWeight: 600,
    lineHeight: "28px",
    color: theme.palette.text.primary,
  },
  subTitle: {
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "20px",
    color: theme.palette.text.tertiary,
  },
  dialogContent: {
    display: "flex",
    justifyContent: "flex-end",
  },
  actions: {
    display: "flex",
    gap: "12px",
  }
}));
