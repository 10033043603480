import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  ButtonBase,
  Tooltip,
} from '@material-ui/core';
import { CheckboxesComponent, LoadableImageComponant, HighPofileManagementDialog } from '../../../../Components';

import { LoadableImageEnum } from '../../../../Enums';
import { getDownloadableLink  , showSuccess , showError} from '../../../../Helper';
import { DefaultImagesEnum } from '../../../../Enums/DefaultImages.Enum';
import { GetActivityById,  UpdateContactToAngryBird } from '../../../../Services';
import { ActivitiesManagementDialog } from '../../../../SharedComponents/ActivitiesManagementDialog/ActivitiesManagementDialog';
import { ActionsEnum } from '../../../../Enums/Actions.Enum';
import { TableActions } from '../../../../Enums/TableActions.Enum';
import { ActivityHistory } from '../../ActivitiesView/ActivityHistory/ActivityHistory';
import { CopyToClipboardComponents } from '../../../../ReusableComponents/UtilityComponents/CopyToClipboardComponents/CopyToClipboardComponents';

export const UnqualifiedLeadCardsComponent = ({
  onFooterActionsClicked,
  data,
  isExpanded,
  onCardClicked,
  parentTranslationPath,
  translationPath,
  QACardsReload,
  activeCard,
  setIsOpenReassign,
  setselectedLeadIds,
  setActiveItemCard,
  selectedLeadIds,
  onCardCheckboxClick,
  SelectedAction,
  setselectedLeadItem,
  setIsOpenC , 
  onPageIndexChanged , 
}) => {
  const { t } = useTranslation([parentTranslationPath]);
  const [activeItem, setActiveItem] = useState();
  const [open, setOpen] = useState(false);
  const [edit, setisEdit] = useState(false);
  const [withCheckbox, setwithCheckbox] = useState(true);
  const [openHistory, setOpenHistory] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [activityHistory, setActivityHistory] = useState([]);
  const [updateAngryBird , setUpdateAngryBird] = useState(false);
  const [leadId , setLeadId] = useState(null) ; 


  useEffect(() => {
    if (SelectedAction.action !== null)
      setwithCheckbox(true);
    else setwithCheckbox(false);
  }, [SelectedAction, setselectedLeadIds]);

  const handleActiveItem = useCallback(async (activityId, key) => {
    if (key === TableActions.replyText.key) {
      setselectedLeadItem(activityId);
      setIsOpenReassign(true);
}
    if (key === TableActions.close.key) {
      setIsOpenC(true);
      setselectedLeadIds([activityId.leadId]);
    } else {
      setisEdit(true);
      setIsLoading(true);
      const res = await GetActivityById(activityId);
      if (!(res && res.status && res.status !== 200)) setActiveItem(res);
      setIsLoading(false);
    }
  }, []);


  const UpdateAngryBirdHandler =   useCallback(async () => 
  {
     setIsLoading(true);
       const res =  await UpdateContactToAngryBird(leadId)  ; 
       if (!(res && res.status && res.status !== 200)) {
         showSuccess(t('Shared:update-to-angry-bird-successfully'));
         onPageIndexChanged();
       } else showError(t('Shared:failed-update-to-angry-bird'));  

       setIsLoading(false);
  },[leadId]);


  const getIsSelectedCard = useCallback(
    (itemIndex) => selectedLeadIds.findIndex((item) => item === itemIndex.leadId) !== -1,
    [selectedLeadIds]
  );

  return (
    <div className='UnqualifiedLeadCardsComponent childs-wrapper '>
      {(data && data).map((item, index) => (
        <div key={`contactsCardItemRef${index + 1}`} className={`qa-sub-card-wrapper ${(activeCard === item && 'is-open') || ''}`}>
          <a onClick={onCardClicked && onCardClicked(item, index)} className='cards-wrapper'>
            <div className='cards-body-wrapper'>
              <div className='card-body-section'>
                <div className='merge-wrapper'>
                  <LoadableImageComponant
                    classes='cover-image'
                    type={LoadableImageEnum.div.key}
                    alt={t(`${translationPath}contact-image`)}
                    src={
                      (item && item.contactImage && item.contactImage.fileId) ?
                        getDownloadableLink(item.contactImage.fileId) :
                        DefaultImagesEnum.man.defaultImg
                    }
                  />
                  {withCheckbox && (
                    <div className='card-checkbox-Unqualified'>
                      <CheckboxesComponent
                        idRef={`nqualifiedCheckboxItemRef${index + 1}`}
                        singleChecked={getIsSelectedCard(item)}
                        onSelectedCheckboxClicked={(event) => {
                          event.preventDefault();
                          onCardCheckboxClick(index, item);
                        }}
                      />
                    </div>
                  )}
                </div>

              </div>
              <div className={`card-body-section${isExpanded ? ' is-expanded' : ''}`}>
                <div className='body-title-wrapper'>
                  <Tooltip title={(item && item.leadName) || 'N/A'} placement='top'>
                    <span className='body-title'>
                      {' '}
                      {item.leadName}
                    </span>
                  </Tooltip>
                  <div className='body-Date'>
                    {' '}
                    <Tooltip
                      title={t(`${translationPath}creationDate`)}
                      placement='top'
                    >
                      <div>
                        <span className='details-icon mdi mdi-calendar mdi-16px' />
                        {' '}
                        {item.creationDate}
                      </div>
                    </Tooltip>
                    <Tooltip
                      title={t(`${translationPath}unqualifiedDate`)}
                      placement='bottom'
                    >
                      <div>
                        <span className='mdi mdi-calendar-blank mdi-16px' />
                        {' '}
                        {item.unqualifiedDate}
                      </div>
                    </Tooltip>

                  </div>

                </div>
              </div>
              <div className='contact-Id'>
                <>
                  {' '}
                  <div className='contact'>
                    {t(`${translationPath}leadId`)}
                  </div>
                  <div className='id'>
                    {(
                      item && item.leadId && (
                        <CopyToClipboardComponents
                          data={item.leadId}
                          childrenData={item.leadId}
                          CustomizationClassName='block-container low-space'
                        />
                      )) ||
                      'N/A'}

                    {' '}
                  </div>

                  <div className='contact mt-2'>
                    {t(`${translationPath}leadType`)}
                  </div>
                  <div className='id'>
                    {item.leadType || 'N/A'}
                    {' '}
                  </div>
                  {' '}

                </>

              </div>
            </div>
            <div className='Data-detels-conenaer'>
              <div>
                {' '}
                <span className='main-t'>
                  {t(`${translationPath}contactPreferences`)}
                  :
                  {' '}
                </span>
                <span className='body-side'>{item.contactPreferences}</span>
              </div>
              <div>
                {' '}
                <span className='main-t'>
                  {t(`${translationPath}unqualifiedBy`)}
                  :
                  {' '}

                </span>
                <span className='body-side'>{item.unqualifiedBy}</span>
              </div>
              <div>
                {' '}
                <span className='main-t'>

                  {t(`${translationPath}countOfUnqualificationLead`)}
                  :
                  {' '}

                </span>
                <span className='body-side'>{item.numberOfUnqualified}</span>
              </div>
              <div className=' d-flex '>
                {' '}
                <span className='main-t'>

                  {t(`${translationPath}reasonName`)}
                  :
                  {' '}

                </span>
                <span className='body-side-s'>
                  {' '}
                  {' '}
                  {item.reasonName || 'N/A'}
                </span>
              </div>
            </div>
            <div className='cards-footer-wrapper'>
              {item.leadStatus !== 'Closed' && (
                <ButtonBase
                  className='btns theme-transparent w-100 mx-0'
                  id='replyText-ref'
                  disabled={SelectedAction.action !== null}
                  onClick={() => {
                    handleActiveItem(item, TableActions.replyText.key);
                  }}
                >
                  <span className='mdi mdi-reply-outline' />
                  <span className='px-1'>{t('Reassign')}</span>
                </ButtonBase>
              )}
              <ButtonBase
                id='open-file-ref'
                className='btns theme-transparent w-100 mx-0'
                onClick={onFooterActionsClicked(ActionsEnum.reportEdit.key, item, index)}
              >
                <span className='mdi mdi-pencil-outline' />
                <span className='px-1'>{t('open-file')}</span>
              </ButtonBase>
              {item.leadStatus !== 'Closed' && (
                <ButtonBase
                  className='btns theme-transparent w-100 mx-0'
                  disabled={SelectedAction.action !== null}
                  id='replyText-ref'
                  onClick={() => {
                handleActiveItem(item, TableActions.close.key);
              }}
                >
                  <span className='mdi mdi mdi-close' />
                  <span className='px-1'>{t('close')}</span>
                </ButtonBase>
              )}

              { 
                item.numberOfAngryBirds !== 0 && (item.numberOfAngryBirds !== undefined  || item.numberOfAngryBirds !== null) && item.numberOfAngryBirds >= 3 &&   (
                <ButtonBase
                  className='btns theme-transparent w-100 mx-0'
                  disabled={SelectedAction.action !== null}
                  id='angry-birde-ref'
                  onClick={() => 
                 {
                    setLeadId(item.leadId) ; 
                    setUpdateAngryBird(true); 
                  
                 }}
                >
                  <span className='px-1'>{t('Angry-Bird')}</span>
                </ButtonBase>
              )}


            </div>
          </a>
        </div>
      ))}
      {open && (
        <ActivitiesManagementDialog
          open={open}
          activeItem={activeItem}
          isLoading={isLoading}
          isEdit={edit}
          onSave={() => {
            setOpen(false);
            setActiveItem(null);
            setisEdit(false);
            QACardsReload();
          }}
          close={() => {
            setActiveItem(null);
            setOpen(false);
            setisEdit(false);
          }}
          translationPath={translationPath}
          parentTranslationPath='ActivitiesView'
          isGeneralDialog
        />
      )}
      {openHistory && (
        <ActivityHistory
          isLoading={isLoading}
          open={openHistory}
          close={() => {
            setActivityHistory([]);
            setOpenHistory(false);
          }}
          data={activityHistory.reverse()}
          translationPath={translationPath}
          parentTranslationPath='ActivitiesView'
        />
      )}
      

     { updateAngryBird  && (
        <HighPofileManagementDialog
          translationPath={translationPath}
          isOpenDialog={updateAngryBird}
          onClose={()=> setUpdateAngryBird(false)}
          onSave={()=> {UpdateAngryBirdHandler()}}
        />
      )}
      

    </div>
  );
};

UnqualifiedLeadCardsComponent.propTypes = {
  data: PropTypes.shape({ result: PropTypes.instanceOf(Array), totalCount: PropTypes.number }).isRequired,
  onFooterActionsClicked: PropTypes.func.isRequired,
  isExpanded: PropTypes.bool.isRequired,
  onCardClicked: PropTypes.func.isRequired,
  parentTranslationPath: PropTypes.string.isRequired,
  translationPath: PropTypes.string.isRequired,
};
