import React from 'react';
import { useTranslation } from 'react-i18next';
import { Tabs, Tab, makeStyles } from '@material-ui/core';
import { UploaderComponent } from '../../Components';
import { LinkUploader } from '../LinkUploader/LinkUploader';
import { VideoUploader } from '../VideoUploader/VideoUploader';
import { ImageUploader } from '../ImageUploader/ImageUploader';
import { DocFilesUploader } from '../DocFilesUploader/DocFilesUploader';
import { getDownloadableLink } from '../../Helper';
import './DocumentsUploader.scss';

export const DocumentsUploader = ({
  uploadedChanged,
  initUploaded,
  setIsUploading,
  translationPath,
  parentTranslationPath,
  open,
  docTypes,
  isUploading,
  oneDocument
}) => {
  const useStyles = makeStyles((theme) => ({
    root: {
      padding: '0px',
      paddingBottom: '5px',
    },
    indicator: {
      backgroundColor: 'var(--c-primary)',
    },
    labelIcon: {
      minHeight: 'unset',
    },
    wrapper: {
      width: '65%',
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'row',
      justifyContent: 'space-evenly',
    },
  }));

  const classes = useStyles();
  const { t } = useTranslation([parentTranslationPath, 'Shared']);
  const [tabIndex, setTabIndex] = React.useState(0);

  const handleChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  return (
    <div className='uploader-wrapper w-100 mb-2'>
      <div className='d-flex'>
        <div className='tab-wrapper'>

          <Tabs
            value={tabIndex}
            onChange={handleChange}
            aria-label='icon label tabs'
            sx={{ borderRight: 1, borderColor: 'divider' }}
            textColor='primary'
            orientation='vertical'
            classes={{
              indicator: classes.indicator,
            }}
          >
            {docTypes.images + 1 && (
            <Tab
              classes={{
                root: classes.root,
                wrapper: classes.wrapper,
                labelIcon: classes.labelIcon,
              }}
              value={docTypes.images}
              icon={<span className='mdi mdi-folder-image' />}
              label={t(`${translationPath}images`)}
            />
)}
            {docTypes.videos + 1 && (
            <Tab
              classes={{
                root: classes.root,
                wrapper: classes.wrapper,
                labelIcon: classes.labelIcon,
              }}
              icon={<span className='mdi mdi-file-video' />}
              label={t(`${translationPath}videos`)}
              value={docTypes.videos}
            />
)}
            {docTypes.docs && (
            <Tab
              classes={{
                root: classes.root,
                wrapper: classes.wrapper,
                labelIcon: classes.labelIcon,
              }}
              icon={<span className='mdi mdi-file-document' />}
              label={t(`${translationPath}Docs`)}
              value={docTypes.docs}
            />
)}
            {docTypes.links && (
            <Tab
              classes={{
                root: classes.root,
                wrapper: classes.wrapper,
                labelIcon: classes.labelIcon,
              }}
              icon={<span className='mdi mdi-link-variant' />}
              label={t(`${translationPath}links`)}
              value={docTypes.links}
            />
)}
          </Tabs>
        </div>

        {tabIndex === docTypes.images && (
          <div className='uploader-type px-5 pt-3 w-100 '>
            <ImageUploader
              initUploadedFiles={initUploaded || []}
              uploadedChanged={uploadedChanged}
              setIsUploading={setIsUploading}
              isUploading={isUploading}
              multiple
              isOpenGallery
              accept={'image/*'}
              idRef='importFileRef'
              viewUploadedFilesCount
              openGallery={open}
              isDocuments
              chipHandler={(value) => () => {
                const link = document.createElement('a');
                link.setAttribute('download', value.fileName);
                link.href = getDownloadableLink(value.uuid);
                document.body.appendChild(link);
                link.click();
                link.remove();
              }}
            />
          </div>
        )}

        {tabIndex === docTypes.videos && (
          <div className='uploader-type px-5 pt-3 w-100'>
            <VideoUploader
              idRef='importFileRef'
              multiple
              initUploadedFiles={initUploaded || []}
              uploadedChanged={uploadedChanged}
              viewUploadedFilesCount
              setIsUploading={setIsUploading}
              isUploading={isUploading}
              chipHandler={(value) => () => {
                const link = document.createElement('a');
                link.setAttribute('download', value.fileName);
                link.href = getDownloadableLink(value.uuid);
                document.body.appendChild(link);
                link.click();
                link.remove();
              }}
            />
          </div>
        )}
        {tabIndex === docTypes.docs && (
          <div className='uploader-type px-5 pt-3 w-100 '>
            <DocFilesUploader
              idRef='importFileRef'
              multiple
              initUploadedFiles={initUploaded || []}
              uploadedChanged={uploadedChanged}
              viewUploadedFilesCount
              chipHandler={(value) => () => {
                const link = document.createElement('a');
                link.setAttribute('download', value.fileName);
                link.href = getDownloadableLink(value.uuid);
                document.body.appendChild(link);
                link.click();
                link.remove();
              }}
            />
          </div>
        )}
        {tabIndex === docTypes.links && (
          <div className='uploader-type px-5 pt-3 w-100 '>
            <LinkUploader
              initUploaded={initUploaded}
              uploadedChanged={uploadedChanged}
              // translationPath={translationPath}
              // parentTranslationPath={parentTranslationPath}
            />
          </div>
        )}
      </div>
    </div>
  );
};
