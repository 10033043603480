export const ConvoloRotationPermissions = {
    ViewConvoloRotationSchemes : {
        permissionsId: "44df8205-64d3-4e52-5dc3-08dc7403ebc2",
        permissionsName: "View Convolo Rotation Schemes",
        description: null,
        componentsId: null,
        components: null
    }       
   

};
